@font-face {
    font-family: 'Material Symbols Outlined';
    font-style: normal;
    src: url(./MaterialSymbolsOutlined[FILL,GRAD,opsz,wght].woff2) format('woff2');
}

.material-symbols-outlined {
    font-family: 'Material Symbols Outlined';
    font-weight: 300;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
}