@charset "UTF-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project                        :   NOWA - HTML5 Bootstrap Admin Template
Create Date                    :   08/03/2022
Author & Copyright Ownership   :   Spruko Technologies Private Limited
Author URL                     :   https://themeforest.net/user/spruko
Support		                   :   https://support.spruko.com/
License Details                :   https://spruko.com/licenses-details

-------------------------------------------------------------------*/
/*
1.  Accordions
2.  Alerts
3.  Badges
4.  Breadcrumbs
5.  Buttons
6.  Card
7.  Carousel
8.  Dropdown
9.  Forms
10. Grid
11. Input-group
12. Modals
13. Navigation
14. Pagination
15. Popover
16. Progress
17. Tables
18. Toast
19. Tooltip
20. Tabs
21. Main-content
22. Header
23. Horizontal-menu
24. Profile
25. Iconbar
26. Datatable
27. Date-picker
28. Date-time-picker
29. Jqvmap
30. Chart
31. Parsely
32. Rangeslider
33. Select2
34. Spectrum
35. Calendar
36. Chat
37 Contacts
38. Error
39. Invoice
40. Mail
41. Profile
42. Background
43. Border
44. Height
45. Margin
46. Opacity
47. Padding
48. Position
49. Typography
50. Width
51. Tags
52. Navbar
53. Wizard
54. P-scroll
55. Rating
56. Timeline
57. Countdown
58. Image
59. List
60. Checkbox
61  Radio
62. Toggle
63  Listgroup
65  Custom-control
66  Custom-styles
67  Tabs
68  Wizard
*/
/* ######## FONT ######## */
/* ############### FONTS IMPORT ############### */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* ######## BOOTSTRAP ######## */
/* COLOR PALETTE */
/* SECONDARY PALETTE */
/* TEXT COLOR - NEUTRALS */
/* COLOR PALETTE - SEMANTICS */
/* SUCCESS */
/* ERROR */
/* WARNING */
/* INFO */
/* BACKGROUND */
/*gradient variables*/
/*gray variables*/
/*white variables*/
/*black variables*/
/*shadow variables*/
/*alerts*/
/*navigation*/
/*dark colors*/
/* ###### 9.8 Typography   ###### */
/* Estilos de cabeceras */
.heading-1,
.heading-1-bold,
.heading-1-medium {
    font-size: 60px;
    line-height: 68px;
    font-weight: 400;
}

.heading-1-medium {
    font-weight: 500;
}

.heading-1-bold {
    font-weight: 700;
}

.heading-2,
.heading-2-bold,
.heading-2-medium {
    font-size: 40px;
    line-height: 48px;
    font-weight: 400;
}

.heading-2-medium {
    font-weight: 500;
}

.heading-2-bold {
    font-weight: 700;
}

.heading-3,
.heading-3-bold,
.heading-3-medium {
    font-size: 32px;
    line-height: 40px;
    font-weight: 400;
}

.heading-3-medium {
    font-weight: 500;
}

.heading-3-bold {
    font-weight: 700;
}

.heading-4,
.heading-4-bold,
.heading-4-medium {
    font-size: 22px;
    line-height: 30px;
    font-weight: 400;
}

.heading-4-medium {
    font-weight: 500;
}

.heading-4-bold {
    font-weight: 700;
}

.heading-5,
.heading-5-bold,
.heading-5-medium {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
}

.heading-5-medium {
    font-weight: 500;
}

.heading-5-bold {
    font-weight: 700;
}

.heading-6,
.heading-6-bold,
.heading-6-medium {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
}

.heading-6-medium {
    font-weight: 500;
}

.heading-6-bold {
    font-weight: 700;
}

/* BODY STYLES */
.tx-body-1,
.ngx-datatable.custom-datatable .datatable-body .datatable-body-cell,
.table tbody tr td,
.tx-body-1-bold,
.ngx-datatable.custom-datatable .datatable-header .datatable-header-cell,
.table tbody tr th,
.table thead th,
.table thead td,
.panel-heading1 .panel-title1 a,
.accordion-button,
.tx-body-1-medium {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}

.tx-body-1-medium {
    font-weight: 500;
}

.tx-body-1-bold,
.ngx-datatable.custom-datatable .datatable-header .datatable-header-cell,
.table tbody tr th,
.table thead th,
.table thead td,
.panel-heading1 .panel-title1 a,
.accordion-button {
    font-weight: 700;
}

.tx-body-2,
.pagination .page-link,
.tx-body-2-bold,
.modal-accordion .accordion-button,
.tx-body-2-medium {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
}

.tx-body-2-medium {
    font-weight: 500;
}

.tx-body-2-bold,
.modal-accordion .accordion-button {
    font-weight: 700;
}

/* TAG STYLES */
.tx-tag {
    font-size: 10px;
    line-height: 14px;
    font-weight: 400;
}

.tx-tag-medium {
    font-size: 10px;
    line-height: 14px;
    font-weight: 500;
}

.tx-tag-card {
    font-size: 8px;
    line-height: 10px;
    font-weight: 400;
}

.tx-tag-card-medium {
    font-size: 8px;
    line-height: 10px;
    font-weight: 500;
}

/* FORM STYLES */
.tx-button {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
}

.tx-black {
    font-weight: 900;
}

.tx-bold {
    font-weight: 700;
}

.tx-semibold {
    font-weight: 600;
}

.tx-medium {
    font-weight: 500;
}

.tx-normal {
    font-weight: 400;
}

.tx-light {
    font-weight: 300;
}

.tx-thin {
    font-weight: 200;
}

.tx-xthin {
    font-weight: 100;
}

.tx-sserif {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.tx-roboto {
    font-family: "Roboto", sans-serif;
}

.tx-white {
    color: #fff;
}

.tx-facebook {
    color: #3b5998;
}

.tx-pinterest {
    color: #cd2029;
}

.tx-google-plus {
    color: #d34836;
}

.tx-twitter {
    color: #429cd6;
}

.tx-black {
    color: #000;
}

.tx-primary {
    color: var(--primary);
}

.tx-primary-75 {
    color: var(--primary-75);
}

.tx-success {
    color: var(--success);
}

.tx-warning {
    color: var(--warning);
}

.tx-danger {
    color: #f34343;
}

.tx-info {
    color: var(--info);
}

.tx-inverse {
    color: #7987a1;
}

.tx-teal {
    color: #29ddee;
}

.tx-dark {
    color: #323251;
}

.tx-indigo {
    color: var(--primary);
}

.tx-purple {
    color: #ad4ffa;
}

.tx-orange {
    color: #fd7e14;
}

.tx-pink {
    color: #f754fa;
}

.tx-gray-100 {
    color: #f9f9fb;
}

.tx-gray-200 {
    color: #f0f0f8;
}

.tx-gray-300 {
    color: #e1e1ef;
}

.tx-gray-400 {
    color: #d6d6e6;
}

.tx-gray-500 {
    color: #949eb7;
}

.tx-gray-600 {
    color: #7987a1;
}

.tx-gray-700 {
    color: #4d5875;
}

.tx-gray-800 {
    color: #383853;
}

.tx-gray-900 {
    color: #323251;
}

.tx-white-2 {
    color: rgba(255, 255, 255, 0.2);
}

.tx-white-3 {
    color: rgba(255, 255, 255, 0.3);
}

.tx-white-4 {
    color: rgba(255, 255, 255, 0.4);
}

.tx-white-5 {
    color: rgba(255, 255, 255, 0.5);
}

.tx-white-6 {
    color: rgba(255, 255, 255, 0.6);
}

.tx-white-7 {
    color: rgba(255, 255, 255, 0.7);
}

.tx-white-8 {
    color: rgba(255, 255, 255, 0.8);
}

.tx-neutral {
    color: var(--neutral-text);
}

.tx-neutral-20 {
    color: var(--neutral-20);
}

.tx-neutral-30 {
    color: var(--neutral-30);
}

.tx-neutral-40 {
    color: var(--neutral-40);
}

.tx-neutral-50 {
    color: var(--neutral-50);
}

.tx-neutral-60 {
    color: var(--neutral-60);
}

.tx-neutral-70 {
    color: var(--neutral-70);
}

.tx-neutral-80 {
    color: var(--neutral-80);
}

.tx-neutral-90 {
    color: var(--neutral-90);
}

.tx-neutral-darker {
    color: var(--neutral-darker);
}

.tx-spacing-1 {
    letter-spacing: 0.5px;
}

.tx-spacing-2 {
    letter-spacing: 1px;
}

.tx-spacing-3 {
    letter-spacing: 1.5px;
}

.tx-spacing-4 {
    letter-spacing: 2px;
}

.tx-spacing-5 {
    letter-spacing: 2.5px;
}

.tx-spacing-6 {
    letter-spacing: 3px;
}

.tx-spacing-7 {
    letter-spacing: 3.5px;
}

.tx-spacing-8 {
    letter-spacing: 4px;
}

.tx-spacing--1 {
    letter-spacing: -0.5px;
}

.tx-spacing--2 {
    letter-spacing: -1px;
}

.tx-spacing--3 {
    letter-spacing: -1.5px;
}

.tx-spacing--4 {
    letter-spacing: -2px;
}

.tx-spacing--5 {
    letter-spacing: -2.5px;
}

.tx-spacing--6 {
    letter-spacing: -3px;
}

.tx-spacing--7 {
    letter-spacing: -3.5px;
}

.tx-spacing--8 {
    letter-spacing: -4px;
}

.tx-uppercase {
    text-transform: uppercase;
}

.tx-lowercase {
    text-transform: lowercase;
}

.tx-transform-none {
    text-transform: none;
}

.tx-center {
    text-align: center;
}

.tx-right {
    text-align: right;
}

.tx-left {
    text-align: left;
}

.tx-center-f {
    text-align: center;
}

.tx-right-f {
    text-align: right;
}

.tx-left-f {
    text-align: left;
}

.tx-italic {
    font-style: italic;
}

.tx-style-normal {
    font-style: normal;
}

.lh-base {
    line-height: 1.5;
}

.lh-normal {
    line-height: normal;
}

.lh-0 {
    line-height: 0;
}

.lh--1 {
    line-height: 0.1;
}

.lh--2 {
    line-height: 0.2;
}

.lh--3 {
    line-height: 0.3;
}

.lh--4 {
    line-height: 0.4;
}

.lh--5 {
    line-height: 0.5;
}

.lh--6 {
    line-height: 0.6;
}

.lh--7 {
    line-height: 0.7;
}

.lh--8 {
    line-height: 0.8;
}

.lh--9 {
    line-height: 0.9;
}

.lh-1 {
    line-height: 1.1;
}

.lh-2 {
    line-height: 1.2;
}

.lh-3 {
    line-height: 1.3;
}

.lh-4 {
    line-height: 1.4;
}

.lh-5 {
    line-height: 1.5;
}

.lh-6 {
    line-height: 1.6;
}

.lh-7 {
    line-height: 1.7;
}

.lh-8 {
    line-height: 1.8;
}

.lh-9 {
    line-height: 1.9;
}

.lh-10 {
    line-height: 2;
}

.lh-11 {
    line-height: 2.1;
}

.lh-12 {
    line-height: 2.2;
}

.lh-13 {
    line-height: 2.3;
}

.lh-14 {
    line-height: 2.4;
}

.lh-15 {
    line-height: 2.5;
}

.valign-top {
    vertical-align: top;
}

.valign-middle {
    vertical-align: middle;
}

.valign-bottom {
    vertical-align: baseline;
}

.valign-top-f {
    vertical-align: top;
}

.valign-middle-f {
    vertical-align: middle;
}

.valign-bottom-f {
    vertical-align: baseline;
}

.tx-base {
    font-size: 0.875rem;
}

.tx-8 {
    font-size: 8px;
}

.tx-8-f {
    font-size: 8px;
}

.tx-9 {
    font-size: 9px;
}

.tx-9-f {
    font-size: 9px;
}

.tx-10 {
    font-size: 10px;
}

.tx-10-f {
    font-size: 10px;
}

.tx-11 {
    font-size: 11px;
}

.tx-11-f {
    font-size: 11px;
}

.tx-12 {
    font-size: 12px;
}

.tx-12-f {
    font-size: 12px;
}

.tx-13 {
    font-size: 13px;
}

.tx-13-f {
    font-size: 13px;
}

.tx-14 {
    font-size: 14px;
}

.tx-14-f {
    font-size: 14px;
}

.tx-15 {
    font-size: 15px !important;
}

.tx-15-f {
    font-size: 15px;
}

.tx-16 {
    font-size: 16px;
}

.tx-16-f {
    font-size: 16px;
}

.tx-17 {
    font-size: 17px;
}

.tx-18 {
    font-size: 18px !important;
}

.tx-18-f {
    font-size: 18px;
}

.tx-20 {
    font-size: 20px !important;
}

.tx-20-f {
    font-size: 20px;
}

.tx-21 {
    font-size: 21px;
}

.tx-21-f {
    font-size: 21px;
}

.tx-22 {
    font-size: 22px;
}

.tx-22-f {
    font-size: 22px;
}

.tx-24 {
    font-size: 24px;
}

.tx-24-f {
    font-size: 24px;
}

.tx-26 {
    font-size: 26px;
}

.tx-26-f {
    font-size: 26px;
}

.tx-28 {
    font-size: 28px;
}

.tx-28-f {
    font-size: 28px;
}

.tx-30 {
    font-size: 30px;
}

.tx-30-f {
    font-size: 30px;
}

.tx-32 {
    font-size: 32px;
}

.tx-32-f {
    font-size: 32px;
}

.tx-34 {
    font-size: 34px;
}

.tx-34-f {
    font-size: 34px;
}

.tx-36 {
    font-size: 36px;
}

.tx-36-f {
    font-size: 36px;
}

.tx-38 {
    font-size: 38px;
}

.tx-38-f {
    font-size: 38px;
}

.tx-40 {
    font-size: 40px;
}

.tx-40-f {
    font-size: 40px;
}

.tx-42 {
    font-size: 42px;
}

.tx-42-f {
    font-size: 42px;
}

.tx-44 {
    font-size: 44px;
}

.tx-44-f {
    font-size: 44px;
}

.tx-46 {
    font-size: 46px;
}

.tx-46-f {
    font-size: 46px;
}

.tx-48 {
    font-size: 48px;
}

.tx-48-f {
    font-size: 48px;
}

.tx-50 {
    font-size: 50px;
}

.tx-50-f {
    font-size: 50px;
}

.tx-52 {
    font-size: 52px;
}

.tx-52-f {
    font-size: 52px;
}

.tx-54 {
    font-size: 54px;
}

.tx-54-f {
    font-size: 54px;
}

.tx-56 {
    font-size: 56px;
}

.tx-56-f {
    font-size: 56px;
}

.tx-58 {
    font-size: 58px;
}

.tx-58-f {
    font-size: 58px;
}

.tx-60 {
    font-size: 60px;
}

.tx-60-f {
    font-size: 60px;
}

.tx-62 {
    font-size: 62px;
}

.tx-62-f {
    font-size: 62px;
}

.tx-64 {
    font-size: 64px;
}

.tx-64-f {
    font-size: 64px;
}

.tx-66 {
    font-size: 66px;
}

.tx-66-f {
    font-size: 66px;
}

.tx-68 {
    font-size: 68px;
}

.tx-68-f {
    font-size: 68px;
}

.tx-70 {
    font-size: 70px;
}

.tx-70-f {
    font-size: 70px;
}

.tx-72 {
    font-size: 72px;
}

.tx-72-f {
    font-size: 72px;
}

.tx-74 {
    font-size: 74px;
}

.tx-74-f {
    font-size: 74px;
}

.tx-76 {
    font-size: 76px;
}

.tx-76-f {
    font-size: 76px;
}

.tx-78 {
    font-size: 78px;
}

.tx-78-f {
    font-size: 78px;
}

.tx-80 {
    font-size: 80px;
}

.tx-80-f {
    font-size: 80px;
}

.tx-82 {
    font-size: 82px;
}

.tx-82-f {
    font-size: 82px;
}

.tx-84 {
    font-size: 84px;
}

.tx-84-f {
    font-size: 84px;
}

.tx-86 {
    font-size: 86px;
}

.tx-86-f {
    font-size: 86px;
}

.tx-88 {
    font-size: 88px;
}

.tx-88-f {
    font-size: 88px;
}

.tx-90 {
    font-size: 90px;
}

.tx-90-f {
    font-size: 90px;
}

.tx-92 {
    font-size: 92px;
}

.tx-92-f {
    font-size: 92px;
}

.tx-94 {
    font-size: 94px;
}

.tx-94-f {
    font-size: 94px;
}

.tx-96 {
    font-size: 96px;
}

.tx-96-f {
    font-size: 96px;
}

.tx-98 {
    font-size: 98px;
}

.tx-98-f {
    font-size: 98px;
}

.tx-100 {
    font-size: 100px;
}

.tx-100-f {
    font-size: 100px;
}

.tx-102 {
    font-size: 102px;
}

.tx-102-f {
    font-size: 102px;
}

.tx-104 {
    font-size: 104px;
}

.tx-104-f {
    font-size: 104px;
}

.tx-106 {
    font-size: 106px;
}

.tx-106-f {
    font-size: 106px;
}

.tx-108 {
    font-size: 108px;
}

.tx-108-f {
    font-size: 108px;
}

.tx-110 {
    font-size: 110px;
}

.tx-110-f {
    font-size: 110px;
}

.tx-112 {
    font-size: 112px;
}

.tx-112-f {
    font-size: 112px;
}

.tx-114 {
    font-size: 114px;
}

.tx-114-f {
    font-size: 114px;
}

.tx-116 {
    font-size: 116px;
}

.tx-116-f {
    font-size: 116px;
}

.tx-118 {
    font-size: 118px;
}

.tx-118-f {
    font-size: 118px;
}

.tx-120 {
    font-size: 120px;
}

.tx-120-f {
    font-size: 120px;
}

.tx-122 {
    font-size: 122px;
}

.tx-122-f {
    font-size: 122px;
}

.tx-124 {
    font-size: 124px;
}

.tx-124-f {
    font-size: 124px;
}

.tx-126 {
    font-size: 126px;
}

.tx-126-f {
    font-size: 126px;
}

.tx-128 {
    font-size: 128px;
}

.tx-128-f {
    font-size: 128px;
}

.tx-130 {
    font-size: 130px;
}

.tx-130-f {
    font-size: 130px;
}

.tx-132 {
    font-size: 132px;
}

.tx-132-f {
    font-size: 132px;
}

.tx-134 {
    font-size: 134px;
}

.tx-134-f {
    font-size: 134px;
}

.tx-136 {
    font-size: 136px;
}

.tx-136-f {
    font-size: 136px;
}

.tx-138 {
    font-size: 138px;
}

.tx-138-f {
    font-size: 138px;
}

.tx-140 {
    font-size: 140px;
}

.tx-140-f {
    font-size: 140px;
}

@media (min-width: 480px) {
    .tx-xs-8 {
        font-size: 8px;
    }

    .tx-xs-8-f {
        font-size: 8px;
    }

    .tx-xs-9 {
        font-size: 9px;
    }

    .tx-xs-9-f {
        font-size: 9px;
    }

    .tx-xs-10 {
        font-size: 10px;
    }

    .tx-xs-10-f {
        font-size: 10px;
    }

    .tx-xs-11 {
        font-size: 11px;
    }

    .tx-xs-11-f {
        font-size: 11px;
    }

    .tx-xs-12 {
        font-size: 12px;
    }

    .tx-xs-12-f {
        font-size: 12px;
    }

    .tx-xs-13 {
        font-size: 13px;
    }

    .tx-xs-13-f {
        font-size: 13px;
    }

    .tx-xs-14 {
        font-size: 14px;
    }

    .tx-xs-14-f {
        font-size: 14px;
    }

    .tx-xs-15 {
        font-size: 15px;
    }

    .tx-xs-15-f {
        font-size: 15px;
    }

    .tx-xs-16 {
        font-size: 16px;
    }

    .tx-xs-16-f {
        font-size: 16px;
    }

    .tx-xs-18 {
        font-size: 18px;
    }

    .tx-xs-18-f {
        font-size: 18px;
    }

    .tx-xs-20 {
        font-size: 20px;
    }

    .tx-xs-20-f {
        font-size: 20px;
    }

    .tx-xs-22 {
        font-size: 22px;
    }

    .tx-xs-22-f {
        font-size: 22px;
    }

    .tx-xs-24 {
        font-size: 24px;
    }

    .tx-xs-24-f {
        font-size: 24px;
    }

    .tx-xs-26 {
        font-size: 26px;
    }

    .tx-xs-26-f {
        font-size: 26px;
    }

    .tx-xs-28 {
        font-size: 28px;
    }

    .tx-xs-28-f {
        font-size: 28px;
    }

    .tx-xs-30 {
        font-size: 30px;
    }

    .tx-xs-30-f {
        font-size: 30px;
    }

    .tx-xs-32 {
        font-size: 32px;
    }

    .tx-xs-32-f {
        font-size: 32px;
    }

    .tx-xs-34 {
        font-size: 34px;
    }

    .tx-xs-34-f {
        font-size: 34px;
    }

    .tx-xs-36 {
        font-size: 36px;
    }

    .tx-xs-36-f {
        font-size: 36px;
    }

    .tx-xs-38 {
        font-size: 38px;
    }

    .tx-xs-38-f {
        font-size: 38px;
    }

    .tx-xs-40 {
        font-size: 40px;
    }

    .tx-xs-40-f {
        font-size: 40px;
    }

    .tx-xs-42 {
        font-size: 42px;
    }

    .tx-xs-42-f {
        font-size: 42px;
    }

    .tx-xs-44 {
        font-size: 44px;
    }

    .tx-xs-44-f {
        font-size: 44px;
    }

    .tx-xs-46 {
        font-size: 46px;
    }

    .tx-xs-46-f {
        font-size: 46px;
    }

    .tx-xs-48 {
        font-size: 48px;
    }

    .tx-xs-48-f {
        font-size: 48px;
    }

    .tx-xs-50 {
        font-size: 50px;
    }

    .tx-xs-50-f {
        font-size: 50px;
    }

    .tx-xs-52 {
        font-size: 52px;
    }

    .tx-xs-52-f {
        font-size: 52px;
    }

    .tx-xs-54 {
        font-size: 54px;
    }

    .tx-xs-54-f {
        font-size: 54px;
    }

    .tx-xs-56 {
        font-size: 56px;
    }

    .tx-xs-56-f {
        font-size: 56px;
    }

    .tx-xs-58 {
        font-size: 58px;
    }

    .tx-xs-58-f {
        font-size: 58px;
    }

    .tx-xs-60 {
        font-size: 60px;
    }

    .tx-xs-60-f {
        font-size: 60px;
    }

    .tx-xs-62 {
        font-size: 62px;
    }

    .tx-xs-62-f {
        font-size: 62px;
    }

    .tx-xs-64 {
        font-size: 64px;
    }

    .tx-xs-64-f {
        font-size: 64px;
    }

    .tx-xs-66 {
        font-size: 66px;
    }

    .tx-xs-66-f {
        font-size: 66px;
    }

    .tx-xs-68 {
        font-size: 68px;
    }

    .tx-xs-68-f {
        font-size: 68px;
    }

    .tx-xs-70 {
        font-size: 70px;
    }

    .tx-xs-70-f {
        font-size: 70px;
    }

    .tx-xs-72 {
        font-size: 72px;
    }

    .tx-xs-72-f {
        font-size: 72px;
    }

    .tx-xs-74 {
        font-size: 74px;
    }

    .tx-xs-74-f {
        font-size: 74px;
    }

    .tx-xs-76 {
        font-size: 76px;
    }

    .tx-xs-76-f {
        font-size: 76px;
    }

    .tx-xs-78 {
        font-size: 78px;
    }

    .tx-xs-78-f {
        font-size: 78px;
    }

    .tx-xs-80 {
        font-size: 80px;
    }

    .tx-xs-80-f {
        font-size: 80px;
    }

    .tx-xs-82 {
        font-size: 82px;
    }

    .tx-xs-82-f {
        font-size: 82px;
    }

    .tx-xs-84 {
        font-size: 84px;
    }

    .tx-xs-84-f {
        font-size: 84px;
    }

    .tx-xs-86 {
        font-size: 86px;
    }

    .tx-xs-86-f {
        font-size: 86px;
    }

    .tx-xs-88 {
        font-size: 88px;
    }

    .tx-xs-88-f {
        font-size: 88px;
    }

    .tx-xs-90 {
        font-size: 90px;
    }

    .tx-xs-90-f {
        font-size: 90px;
    }

    .tx-xs-92 {
        font-size: 92px;
    }

    .tx-xs-92-f {
        font-size: 92px;
    }

    .tx-xs-94 {
        font-size: 94px;
    }

    .tx-xs-94-f {
        font-size: 94px;
    }

    .tx-xs-96 {
        font-size: 96px;
    }

    .tx-xs-96-f {
        font-size: 96px;
    }

    .tx-xs-98 {
        font-size: 98px;
    }

    .tx-xs-98-f {
        font-size: 98px;
    }

    .tx-xs-100 {
        font-size: 100px;
    }

    .tx-xs-100-f {
        font-size: 100px;
    }

    .tx-xs-102 {
        font-size: 102px;
    }

    .tx-xs-102-f {
        font-size: 102px;
    }

    .tx-xs-104 {
        font-size: 104px;
    }

    .tx-xs-104-f {
        font-size: 104px;
    }

    .tx-xs-106 {
        font-size: 106px;
    }

    .tx-xs-106-f {
        font-size: 106px;
    }

    .tx-xs-108 {
        font-size: 108px;
    }

    .tx-xs-108-f {
        font-size: 108px;
    }

    .tx-xs-110 {
        font-size: 110px;
    }

    .tx-xs-110-f {
        font-size: 110px;
    }

    .tx-xs-112 {
        font-size: 112px;
    }

    .tx-xs-112-f {
        font-size: 112px;
    }

    .tx-xs-114 {
        font-size: 114px;
    }

    .tx-xs-114-f {
        font-size: 114px;
    }

    .tx-xs-116 {
        font-size: 116px;
    }

    .tx-xs-116-f {
        font-size: 116px;
    }

    .tx-xs-118 {
        font-size: 118px;
    }

    .tx-xs-118-f {
        font-size: 118px;
    }

    .tx-xs-120 {
        font-size: 120px;
    }

    .tx-xs-120-f {
        font-size: 120px;
    }

    .tx-xs-122 {
        font-size: 122px;
    }

    .tx-xs-122-f {
        font-size: 122px;
    }

    .tx-xs-124 {
        font-size: 124px;
    }

    .tx-xs-124-f {
        font-size: 124px;
    }

    .tx-xs-126 {
        font-size: 126px;
    }

    .tx-xs-126-f {
        font-size: 126px;
    }

    .tx-xs-128 {
        font-size: 128px;
    }

    .tx-xs-128-f {
        font-size: 128px;
    }

    .tx-xs-130 {
        font-size: 130px;
    }

    .tx-xs-130-f {
        font-size: 130px;
    }

    .tx-xs-132 {
        font-size: 132px;
    }

    .tx-xs-132-f {
        font-size: 132px;
    }

    .tx-xs-134 {
        font-size: 134px;
    }

    .tx-xs-134-f {
        font-size: 134px;
    }

    .tx-xs-136 {
        font-size: 136px;
    }

    .tx-xs-136-f {
        font-size: 136px;
    }

    .tx-xs-138 {
        font-size: 138px;
    }

    .tx-xs-138-f {
        font-size: 138px;
    }

    .tx-xs-140 {
        font-size: 140px;
    }

    .tx-xs-140-f {
        font-size: 140px;
    }

    .tx-xs-base {
        font-size: 0.875rem;
    }

    .tx-xs-bold {
        font-weight: 700;
    }

    .tx-xs-semibold {
        font-weight: 600;
    }

    .tx-xs-medium {
        font-weight: 500;
    }

    .tx-xs-normal {
        font-weight: 400;
    }

    .tx-xs-light {
        font-weight: 300;
    }

    .tx-xs-thin {
        font-weight: 200;
    }

    .tx-xs-xthin {
        font-weight: 100;
    }

    .lh-xs-0 {
        line-height: 0;
    }

    .lh-xs-1 {
        line-height: 1.1;
    }

    .lh-xs-2 {
        line-height: 1.2;
    }

    .lh-xs-3 {
        line-height: 1.3;
    }

    .lh-xs-4 {
        line-height: 1.4;
    }

    .lh-xs-5 {
        line-height: 1.5;
    }

    .lh-xs-6 {
        line-height: 1.6;
    }

    .lh-xs-7 {
        line-height: 1.7;
    }

    .lh-xs-8 {
        line-height: 1.8;
    }

    .lh-xs-9 {
        line-height: 1.9;
    }

    .lh-xs-10 {
        line-height: 2;
    }

    .lh-xs-11 {
        line-height: 2.1;
    }

    .lh-xs-12 {
        line-height: 2.2;
    }

    .lh-xs-13 {
        line-height: 2.3;
    }

    .lh-xs-14 {
        line-height: 2.4;
    }

    .lh-xs-15 {
        line-height: 2.5;
    }

    .tx-xs-center {
        text-align: center;
    }

    .tx-xs-right {
        text-align: right;
    }

    .tx-xs-left {
        text-align: left;
    }

    .tx-xs-center-f {
        text-align: center;
    }

    .tx-xs-right-f {
        text-align: right;
    }

    .tx-xs-left-f {
        text-align: left;
    }
}

@media (min-width: 576px) {
    .tx-sm-8 {
        font-size: 8px;
    }

    .tx-sm-8-f {
        font-size: 8px;
    }

    .tx-sm-9 {
        font-size: 9px;
    }

    .tx-sm-9-f {
        font-size: 9px;
    }

    .tx-sm-10 {
        font-size: 10px;
    }

    .tx-sm-10-f {
        font-size: 10px;
    }

    .tx-sm-11 {
        font-size: 11px;
    }

    .tx-sm-11-f {
        font-size: 11px;
    }

    .tx-sm-12 {
        font-size: 12px;
    }

    .tx-sm-12-f {
        font-size: 12px;
    }

    .tx-sm-13 {
        font-size: 13px;
    }

    .tx-sm-13-f {
        font-size: 13px;
    }

    .tx-sm-14 {
        font-size: 14px;
    }

    .tx-sm-14-f {
        font-size: 14px;
    }

    .tx-sm-15 {
        font-size: 15px;
    }

    .tx-sm-15-f {
        font-size: 15px;
    }

    .tx-sm-16 {
        font-size: 16px;
    }

    .tx-sm-16-f {
        font-size: 16px;
    }

    .tx-sm-18 {
        font-size: 18px;
    }

    .tx-sm-18-f {
        font-size: 18px;
    }

    .tx-sm-20 {
        font-size: 20px;
    }

    .tx-sm-20-f {
        font-size: 20px;
    }

    .tx-sm-22 {
        font-size: 22px;
    }

    .tx-sm-22-f {
        font-size: 22px;
    }

    .tx-sm-24 {
        font-size: 24px;
    }

    .tx-sm-24-f {
        font-size: 24px;
    }

    .tx-sm-26 {
        font-size: 26px;
    }

    .tx-sm-26-f {
        font-size: 26px;
    }

    .tx-sm-28 {
        font-size: 28px;
    }

    .tx-sm-28-f {
        font-size: 28px;
    }

    .tx-sm-30 {
        font-size: 30px;
    }

    .tx-sm-30-f {
        font-size: 30px;
    }

    .tx-sm-32 {
        font-size: 32px;
    }

    .tx-sm-32-f {
        font-size: 32px;
    }

    .tx-sm-34 {
        font-size: 34px;
    }

    .tx-sm-34-f {
        font-size: 34px;
    }

    .tx-sm-36 {
        font-size: 36px;
    }

    .tx-sm-36-f {
        font-size: 36px;
    }

    .tx-sm-38 {
        font-size: 38px;
    }

    .tx-sm-38-f {
        font-size: 38px;
    }

    .tx-sm-40 {
        font-size: 40px;
    }

    .tx-sm-40-f {
        font-size: 40px;
    }

    .tx-sm-42 {
        font-size: 42px;
    }

    .tx-sm-42-f {
        font-size: 42px;
    }

    .tx-sm-44 {
        font-size: 44px;
    }

    .tx-sm-44-f {
        font-size: 44px;
    }

    .tx-sm-46 {
        font-size: 46px;
    }

    .tx-sm-46-f {
        font-size: 46px;
    }

    .tx-sm-48 {
        font-size: 48px;
    }

    .tx-sm-48-f {
        font-size: 48px;
    }

    .tx-sm-50 {
        font-size: 50px;
    }

    .tx-sm-50-f {
        font-size: 50px;
    }

    .tx-sm-52 {
        font-size: 52px;
    }

    .tx-sm-52-f {
        font-size: 52px;
    }

    .tx-sm-54 {
        font-size: 54px;
    }

    .tx-sm-54-f {
        font-size: 54px;
    }

    .tx-sm-56 {
        font-size: 56px;
    }

    .tx-sm-56-f {
        font-size: 56px;
    }

    .tx-sm-58 {
        font-size: 58px;
    }

    .tx-sm-58-f {
        font-size: 58px;
    }

    .tx-sm-60 {
        font-size: 60px;
    }

    .tx-sm-60-f {
        font-size: 60px;
    }

    .tx-sm-62 {
        font-size: 62px;
    }

    .tx-sm-62-f {
        font-size: 62px;
    }

    .tx-sm-64 {
        font-size: 64px;
    }

    .tx-sm-64-f {
        font-size: 64px;
    }

    .tx-sm-66 {
        font-size: 66px;
    }

    .tx-sm-66-f {
        font-size: 66px;
    }

    .tx-sm-68 {
        font-size: 68px;
    }

    .tx-sm-68-f {
        font-size: 68px;
    }

    .tx-sm-70 {
        font-size: 70px;
    }

    .tx-sm-70-f {
        font-size: 70px;
    }

    .tx-sm-72 {
        font-size: 72px;
    }

    .tx-sm-72-f {
        font-size: 72px;
    }

    .tx-sm-74 {
        font-size: 74px;
    }

    .tx-sm-74-f {
        font-size: 74px;
    }

    .tx-sm-76 {
        font-size: 76px;
    }

    .tx-sm-76-f {
        font-size: 76px;
    }

    .tx-sm-78 {
        font-size: 78px;
    }

    .tx-sm-78-f {
        font-size: 78px;
    }

    .tx-sm-80 {
        font-size: 80px;
    }

    .tx-sm-80-f {
        font-size: 80px;
    }

    .tx-sm-82 {
        font-size: 82px;
    }

    .tx-sm-82-f {
        font-size: 82px;
    }

    .tx-sm-84 {
        font-size: 84px;
    }

    .tx-sm-84-f {
        font-size: 84px;
    }

    .tx-sm-86 {
        font-size: 86px;
    }

    .tx-sm-86-f {
        font-size: 86px;
    }

    .tx-sm-88 {
        font-size: 88px;
    }

    .tx-sm-88-f {
        font-size: 88px;
    }

    .tx-sm-90 {
        font-size: 90px;
    }

    .tx-sm-90-f {
        font-size: 90px;
    }

    .tx-sm-92 {
        font-size: 92px;
    }

    .tx-sm-92-f {
        font-size: 92px;
    }

    .tx-sm-94 {
        font-size: 94px;
    }

    .tx-sm-94-f {
        font-size: 94px;
    }

    .tx-sm-96 {
        font-size: 96px;
    }

    .tx-sm-96-f {
        font-size: 96px;
    }

    .tx-sm-98 {
        font-size: 98px;
    }

    .tx-sm-98-f {
        font-size: 98px;
    }

    .tx-sm-100 {
        font-size: 100px;
    }

    .tx-sm-100-f {
        font-size: 100px;
    }

    .tx-sm-102 {
        font-size: 102px;
    }

    .tx-sm-102-f {
        font-size: 102px;
    }

    .tx-sm-104 {
        font-size: 104px;
    }

    .tx-sm-104-f {
        font-size: 104px;
    }

    .tx-sm-106 {
        font-size: 106px;
    }

    .tx-sm-106-f {
        font-size: 106px;
    }

    .tx-sm-108 {
        font-size: 108px;
    }

    .tx-sm-108-f {
        font-size: 108px;
    }

    .tx-sm-110 {
        font-size: 110px;
    }

    .tx-sm-110-f {
        font-size: 110px;
    }

    .tx-sm-112 {
        font-size: 112px;
    }

    .tx-sm-112-f {
        font-size: 112px;
    }

    .tx-sm-114 {
        font-size: 114px;
    }

    .tx-sm-114-f {
        font-size: 114px;
    }

    .tx-sm-116 {
        font-size: 116px;
    }

    .tx-sm-116-f {
        font-size: 116px;
    }

    .tx-sm-118 {
        font-size: 118px;
    }

    .tx-sm-118-f {
        font-size: 118px;
    }

    .tx-sm-120 {
        font-size: 120px;
    }

    .tx-sm-120-f {
        font-size: 120px;
    }

    .tx-sm-122 {
        font-size: 122px;
    }

    .tx-sm-122-f {
        font-size: 122px;
    }

    .tx-sm-124 {
        font-size: 124px;
    }

    .tx-sm-124-f {
        font-size: 124px;
    }

    .tx-sm-126 {
        font-size: 126px;
    }

    .tx-sm-126-f {
        font-size: 126px;
    }

    .tx-sm-128 {
        font-size: 128px;
    }

    .tx-sm-128-f {
        font-size: 128px;
    }

    .tx-sm-130 {
        font-size: 130px;
    }

    .tx-sm-130-f {
        font-size: 130px;
    }

    .tx-sm-132 {
        font-size: 132px;
    }

    .tx-sm-132-f {
        font-size: 132px;
    }

    .tx-sm-134 {
        font-size: 134px;
    }

    .tx-sm-134-f {
        font-size: 134px;
    }

    .tx-sm-136 {
        font-size: 136px;
    }

    .tx-sm-136-f {
        font-size: 136px;
    }

    .tx-sm-138 {
        font-size: 138px;
    }

    .tx-sm-138-f {
        font-size: 138px;
    }

    .tx-sm-140 {
        font-size: 140px;
    }

    .tx-sm-140-f {
        font-size: 140px;
    }

    .tx-sm-base {
        font-size: 0.875rem;
    }

    .tx-sm-bold {
        font-weight: 700;
    }

    .tx-sm-semibold {
        font-weight: 600;
    }

    .tx-sm-medium {
        font-weight: 500;
    }

    .tx-sm-normal {
        font-weight: 400;
    }

    .tx-sm-light {
        font-weight: 300;
    }

    .tx-sm-thin {
        font-weight: 200;
    }

    .tx-sm-xthin {
        font-weight: 100;
    }

    .lh-sm-0 {
        line-height: 0;
    }

    .lh-sm-1 {
        line-height: 1.1;
    }

    .lh-sm-2 {
        line-height: 1.2;
    }

    .lh-sm-3 {
        line-height: 1.3;
    }

    .lh-sm-4 {
        line-height: 1.4;
    }

    .lh-sm-5 {
        line-height: 1.5;
    }

    .lh-sm-6 {
        line-height: 1.6;
    }

    .lh-sm-7 {
        line-height: 1.7;
    }

    .lh-sm-8 {
        line-height: 1.8;
    }

    .lh-sm-9 {
        line-height: 1.9;
    }

    .lh-sm-10 {
        line-height: 2;
    }

    .lh-sm-11 {
        line-height: 2.1;
    }

    .lh-sm-12 {
        line-height: 2.2;
    }

    .lh-sm-13 {
        line-height: 2.3;
    }

    .lh-sm-14 {
        line-height: 2.4;
    }

    .lh-sm-15 {
        line-height: 2.5;
    }

    .tx-sm-center {
        text-align: center;
    }

    .tx-sm-right {
        text-align: right;
    }

    .tx-sm-left {
        text-align: left;
    }

    .tx-sm-center-f {
        text-align: center;
    }

    .tx-sm-right-f {
        text-align: right;
    }

    .tx-sm-left-f {
        text-align: left;
    }
}

@media (min-width: 768px) {
    .tx-md-8 {
        font-size: 8px;
    }

    .tx-md-8-f {
        font-size: 8px;
    }

    .tx-md-9 {
        font-size: 9px;
    }

    .tx-md-9-f {
        font-size: 9px;
    }

    .tx-md-10 {
        font-size: 10px;
    }

    .tx-md-10-f {
        font-size: 10px;
    }

    .tx-md-11 {
        font-size: 11px;
    }

    .tx-md-11-f {
        font-size: 11px;
    }

    .tx-md-12 {
        font-size: 12px;
    }

    .tx-md-12-f {
        font-size: 12px;
    }

    .tx-md-13 {
        font-size: 13px;
    }

    .tx-md-13-f {
        font-size: 13px;
    }

    .tx-md-14 {
        font-size: 14px;
    }

    .tx-md-14-f {
        font-size: 14px;
    }

    .tx-md-15 {
        font-size: 15px;
    }

    .tx-md-15-f {
        font-size: 15px;
    }

    .tx-md-16 {
        font-size: 16px;
    }

    .tx-md-16-f {
        font-size: 16px;
    }

    .tx-md-18 {
        font-size: 18px;
    }

    .tx-md-18-f {
        font-size: 18px;
    }

    .tx-md-20 {
        font-size: 20px;
    }

    .tx-md-20-f {
        font-size: 20px;
    }

    .tx-md-22 {
        font-size: 22px;
    }

    .tx-md-22-f {
        font-size: 22px;
    }

    .tx-md-24 {
        font-size: 24px;
    }

    .tx-md-24-f {
        font-size: 24px;
    }

    .tx-md-26 {
        font-size: 26px;
    }

    .tx-md-26-f {
        font-size: 26px;
    }

    .tx-md-28 {
        font-size: 28px;
    }

    .tx-md-28-f {
        font-size: 28px;
    }

    .tx-md-30 {
        font-size: 30px;
    }

    .tx-md-30-f {
        font-size: 30px;
    }

    .tx-md-32 {
        font-size: 32px;
    }

    .tx-md-32-f {
        font-size: 32px;
    }

    .tx-md-34 {
        font-size: 34px;
    }

    .tx-md-34-f {
        font-size: 34px;
    }

    .tx-md-36 {
        font-size: 36px;
    }

    .tx-md-36-f {
        font-size: 36px;
    }

    .tx-md-38 {
        font-size: 38px;
    }

    .tx-md-38-f {
        font-size: 38px;
    }

    .tx-md-40 {
        font-size: 40px;
    }

    .tx-md-40-f {
        font-size: 40px;
    }

    .tx-md-42 {
        font-size: 42px;
    }

    .tx-md-42-f {
        font-size: 42px;
    }

    .tx-md-44 {
        font-size: 44px;
    }

    .tx-md-44-f {
        font-size: 44px;
    }

    .tx-md-46 {
        font-size: 46px;
    }

    .tx-md-46-f {
        font-size: 46px;
    }

    .tx-md-48 {
        font-size: 48px;
    }

    .tx-md-48-f {
        font-size: 48px;
    }

    .tx-md-50 {
        font-size: 50px;
    }

    .tx-md-50-f {
        font-size: 50px;
    }

    .tx-md-52 {
        font-size: 52px;
    }

    .tx-md-52-f {
        font-size: 52px;
    }

    .tx-md-54 {
        font-size: 54px;
    }

    .tx-md-54-f {
        font-size: 54px;
    }

    .tx-md-56 {
        font-size: 56px;
    }

    .tx-md-56-f {
        font-size: 56px;
    }

    .tx-md-58 {
        font-size: 58px;
    }

    .tx-md-58-f {
        font-size: 58px;
    }

    .tx-md-60 {
        font-size: 60px;
    }

    .tx-md-60-f {
        font-size: 60px;
    }

    .tx-md-62 {
        font-size: 62px;
    }

    .tx-md-62-f {
        font-size: 62px;
    }

    .tx-md-64 {
        font-size: 64px;
    }

    .tx-md-64-f {
        font-size: 64px;
    }

    .tx-md-66 {
        font-size: 66px;
    }

    .tx-md-66-f {
        font-size: 66px;
    }

    .tx-md-68 {
        font-size: 68px;
    }

    .tx-md-68-f {
        font-size: 68px;
    }

    .tx-md-70 {
        font-size: 70px;
    }

    .tx-md-70-f {
        font-size: 70px;
    }

    .tx-md-72 {
        font-size: 72px;
    }

    .tx-md-72-f {
        font-size: 72px;
    }

    .tx-md-74 {
        font-size: 74px;
    }

    .tx-md-74-f {
        font-size: 74px;
    }

    .tx-md-76 {
        font-size: 76px;
    }

    .tx-md-76-f {
        font-size: 76px;
    }

    .tx-md-78 {
        font-size: 78px;
    }

    .tx-md-78-f {
        font-size: 78px;
    }

    .tx-md-80 {
        font-size: 80px;
    }

    .tx-md-80-f {
        font-size: 80px;
    }

    .tx-md-82 {
        font-size: 82px;
    }

    .tx-md-82-f {
        font-size: 82px;
    }

    .tx-md-84 {
        font-size: 84px;
    }

    .tx-md-84-f {
        font-size: 84px;
    }

    .tx-md-86 {
        font-size: 86px;
    }

    .tx-md-86-f {
        font-size: 86px;
    }

    .tx-md-88 {
        font-size: 88px;
    }

    .tx-md-88-f {
        font-size: 88px;
    }

    .tx-md-90 {
        font-size: 90px;
    }

    .tx-md-90-f {
        font-size: 90px;
    }

    .tx-md-92 {
        font-size: 92px;
    }

    .tx-md-92-f {
        font-size: 92px;
    }

    .tx-md-94 {
        font-size: 94px;
    }

    .tx-md-94-f {
        font-size: 94px;
    }

    .tx-md-96 {
        font-size: 96px;
    }

    .tx-md-96-f {
        font-size: 96px;
    }

    .tx-md-98 {
        font-size: 98px;
    }

    .tx-md-98-f {
        font-size: 98px;
    }

    .tx-md-100 {
        font-size: 100px;
    }

    .tx-md-100-f {
        font-size: 100px;
    }

    .tx-md-102 {
        font-size: 102px;
    }

    .tx-md-102-f {
        font-size: 102px;
    }

    .tx-md-104 {
        font-size: 104px;
    }

    .tx-md-104-f {
        font-size: 104px;
    }

    .tx-md-106 {
        font-size: 106px;
    }

    .tx-md-106-f {
        font-size: 106px;
    }

    .tx-md-108 {
        font-size: 108px;
    }

    .tx-md-108-f {
        font-size: 108px;
    }

    .tx-md-110 {
        font-size: 110px;
    }

    .tx-md-110-f {
        font-size: 110px;
    }

    .tx-md-112 {
        font-size: 112px;
    }

    .tx-md-112-f {
        font-size: 112px;
    }

    .tx-md-114 {
        font-size: 114px;
    }

    .tx-md-114-f {
        font-size: 114px;
    }

    .tx-md-116 {
        font-size: 116px;
    }

    .tx-md-116-f {
        font-size: 116px;
    }

    .tx-md-118 {
        font-size: 118px;
    }

    .tx-md-118-f {
        font-size: 118px;
    }

    .tx-md-120 {
        font-size: 120px;
    }

    .tx-md-120-f {
        font-size: 120px;
    }

    .tx-md-122 {
        font-size: 122px;
    }

    .tx-md-122-f {
        font-size: 122px;
    }

    .tx-md-124 {
        font-size: 124px;
    }

    .tx-md-124-f {
        font-size: 124px;
    }

    .tx-md-126 {
        font-size: 126px;
    }

    .tx-md-126-f {
        font-size: 126px;
    }

    .tx-md-128 {
        font-size: 128px;
    }

    .tx-md-128-f {
        font-size: 128px;
    }

    .tx-md-130 {
        font-size: 130px;
    }

    .tx-md-130-f {
        font-size: 130px;
    }

    .tx-md-132 {
        font-size: 132px;
    }

    .tx-md-132-f {
        font-size: 132px;
    }

    .tx-md-134 {
        font-size: 134px;
    }

    .tx-md-134-f {
        font-size: 134px;
    }

    .tx-md-136 {
        font-size: 136px;
    }

    .tx-md-136-f {
        font-size: 136px;
    }

    .tx-md-138 {
        font-size: 138px;
    }

    .tx-md-138-f {
        font-size: 138px;
    }

    .tx-md-140 {
        font-size: 140px;
    }

    .tx-md-140-f {
        font-size: 140px;
    }

    .tx-md-base {
        font-size: 0.875rem;
    }

    .tx-md-bold {
        font-weight: 700;
    }

    .tx-md-semibold {
        font-weight: 600;
    }

    .tx-md-medium {
        font-weight: 500;
    }

    .tx-md-normal {
        font-weight: 400;
    }

    .tx-md-light {
        font-weight: 300;
    }

    .tx-md-thin {
        font-weight: 200;
    }

    .tx-md-xthin {
        font-weight: 100;
    }

    .lh-md-0 {
        line-height: 0;
    }

    .lh-md-1 {
        line-height: 1.1;
    }

    .lh-md-2 {
        line-height: 1.2;
    }

    .lh-md-3 {
        line-height: 1.3;
    }

    .lh-md-4 {
        line-height: 1.4;
    }

    .lh-md-5 {
        line-height: 1.5;
    }

    .lh-md-6 {
        line-height: 1.6;
    }

    .lh-md-7 {
        line-height: 1.7;
    }

    .lh-md-8 {
        line-height: 1.8;
    }

    .lh-md-9 {
        line-height: 1.9;
    }

    .lh-md-10 {
        line-height: 2;
    }

    .lh-md-11 {
        line-height: 2.1;
    }

    .lh-md-12 {
        line-height: 2.2;
    }

    .lh-md-13 {
        line-height: 2.3;
    }

    .lh-md-14 {
        line-height: 2.4;
    }

    .lh-md-15 {
        line-height: 2.5;
    }

    .tx-md-center {
        text-align: center;
    }

    .tx-md-right {
        text-align: right;
    }

    .tx-md-left {
        text-align: left;
    }

    .tx-md-center-f {
        text-align: center;
    }

    .tx-md-right-f {
        text-align: right;
    }

    .tx-md-left-f {
        text-align: left;
    }
}

@media (min-width: 992px) {
    .tx-lg-8 {
        font-size: 8px;
    }

    .tx-lg-8-f {
        font-size: 8px;
    }

    .tx-lg-9 {
        font-size: 9px;
    }

    .tx-lg-9-f {
        font-size: 9px;
    }

    .tx-lg-10 {
        font-size: 10px;
    }

    .tx-lg-10-f {
        font-size: 10px;
    }

    .tx-lg-11 {
        font-size: 11px;
    }

    .tx-lg-11-f {
        font-size: 11px;
    }

    .tx-lg-12 {
        font-size: 12px;
    }

    .tx-lg-12-f {
        font-size: 12px;
    }

    .tx-lg-13 {
        font-size: 13px;
    }

    .tx-lg-13-f {
        font-size: 13px;
    }

    .tx-lg-14 {
        font-size: 14px;
    }

    .tx-lg-14-f {
        font-size: 14px;
    }

    .tx-lg-15 {
        font-size: 15px;
    }

    .tx-lg-15-f {
        font-size: 15px;
    }

    .tx-lg-16 {
        font-size: 16px;
    }

    .tx-lg-16-f {
        font-size: 16px;
    }

    .tx-lg-18 {
        font-size: 18px;
    }

    .tx-lg-18-f {
        font-size: 18px;
    }

    .tx-lg-20 {
        font-size: 20px;
    }

    .tx-lg-20-f {
        font-size: 20px;
    }

    .tx-lg-22 {
        font-size: 22px;
    }

    .tx-lg-22-f {
        font-size: 22px;
    }

    .tx-lg-24 {
        font-size: 24px;
    }

    .tx-lg-24-f {
        font-size: 24px;
    }

    .tx-lg-26 {
        font-size: 26px;
    }

    .tx-lg-26-f {
        font-size: 26px;
    }

    .tx-lg-28 {
        font-size: 28px;
    }

    .tx-lg-28-f {
        font-size: 28px;
    }

    .tx-lg-30 {
        font-size: 30px;
    }

    .tx-lg-30-f {
        font-size: 30px;
    }

    .tx-lg-32 {
        font-size: 32px;
    }

    .tx-lg-32-f {
        font-size: 32px;
    }

    .tx-lg-34 {
        font-size: 34px;
    }

    .tx-lg-34-f {
        font-size: 34px;
    }

    .tx-lg-36 {
        font-size: 36px;
    }

    .tx-lg-36-f {
        font-size: 36px;
    }

    .tx-lg-38 {
        font-size: 38px;
    }

    .tx-lg-38-f {
        font-size: 38px;
    }

    .tx-lg-40 {
        font-size: 40px;
    }

    .tx-lg-40-f {
        font-size: 40px;
    }

    .tx-lg-42 {
        font-size: 42px;
    }

    .tx-lg-42-f {
        font-size: 42px;
    }

    .tx-lg-44 {
        font-size: 44px;
    }

    .tx-lg-44-f {
        font-size: 44px;
    }

    .tx-lg-46 {
        font-size: 46px;
    }

    .tx-lg-46-f {
        font-size: 46px;
    }

    .tx-lg-48 {
        font-size: 48px;
    }

    .tx-lg-48-f {
        font-size: 48px;
    }

    .tx-lg-50 {
        font-size: 50px;
    }

    .tx-lg-50-f {
        font-size: 50px;
    }

    .tx-lg-52 {
        font-size: 52px;
    }

    .tx-lg-52-f {
        font-size: 52px;
    }

    .tx-lg-54 {
        font-size: 54px;
    }

    .tx-lg-54-f {
        font-size: 54px;
    }

    .tx-lg-56 {
        font-size: 56px;
    }

    .tx-lg-56-f {
        font-size: 56px;
    }

    .tx-lg-58 {
        font-size: 58px;
    }

    .tx-lg-58-f {
        font-size: 58px;
    }

    .tx-lg-60 {
        font-size: 60px;
    }

    .tx-lg-60-f {
        font-size: 60px;
    }

    .tx-lg-62 {
        font-size: 62px;
    }

    .tx-lg-62-f {
        font-size: 62px;
    }

    .tx-lg-64 {
        font-size: 64px;
    }

    .tx-lg-64-f {
        font-size: 64px;
    }

    .tx-lg-66 {
        font-size: 66px;
    }

    .tx-lg-66-f {
        font-size: 66px;
    }

    .tx-lg-68 {
        font-size: 68px;
    }

    .tx-lg-68-f {
        font-size: 68px;
    }

    .tx-lg-70 {
        font-size: 70px;
    }

    .tx-lg-70-f {
        font-size: 70px;
    }

    .tx-lg-72 {
        font-size: 72px;
    }

    .tx-lg-72-f {
        font-size: 72px;
    }

    .tx-lg-74 {
        font-size: 74px;
    }

    .tx-lg-74-f {
        font-size: 74px;
    }

    .tx-lg-76 {
        font-size: 76px;
    }

    .tx-lg-76-f {
        font-size: 76px;
    }

    .tx-lg-78 {
        font-size: 78px;
    }

    .tx-lg-78-f {
        font-size: 78px;
    }

    .tx-lg-80 {
        font-size: 80px;
    }

    .tx-lg-80-f {
        font-size: 80px;
    }

    .tx-lg-82 {
        font-size: 82px;
    }

    .tx-lg-82-f {
        font-size: 82px;
    }

    .tx-lg-84 {
        font-size: 84px;
    }

    .tx-lg-84-f {
        font-size: 84px;
    }

    .tx-lg-86 {
        font-size: 86px;
    }

    .tx-lg-86-f {
        font-size: 86px;
    }

    .tx-lg-88 {
        font-size: 88px;
    }

    .tx-lg-88-f {
        font-size: 88px;
    }

    .tx-lg-90 {
        font-size: 90px;
    }

    .tx-lg-90-f {
        font-size: 90px;
    }

    .tx-lg-92 {
        font-size: 92px;
    }

    .tx-lg-92-f {
        font-size: 92px;
    }

    .tx-lg-94 {
        font-size: 94px;
    }

    .tx-lg-94-f {
        font-size: 94px;
    }

    .tx-lg-96 {
        font-size: 96px;
    }

    .tx-lg-96-f {
        font-size: 96px;
    }

    .tx-lg-98 {
        font-size: 98px;
    }

    .tx-lg-98-f {
        font-size: 98px;
    }

    .tx-lg-100 {
        font-size: 100px;
    }

    .tx-lg-100-f {
        font-size: 100px;
    }

    .tx-lg-102 {
        font-size: 102px;
    }

    .tx-lg-102-f {
        font-size: 102px;
    }

    .tx-lg-104 {
        font-size: 104px;
    }

    .tx-lg-104-f {
        font-size: 104px;
    }

    .tx-lg-106 {
        font-size: 106px;
    }

    .tx-lg-106-f {
        font-size: 106px;
    }

    .tx-lg-108 {
        font-size: 108px;
    }

    .tx-lg-108-f {
        font-size: 108px;
    }

    .tx-lg-110 {
        font-size: 110px;
    }

    .tx-lg-110-f {
        font-size: 110px;
    }

    .tx-lg-112 {
        font-size: 112px;
    }

    .tx-lg-112-f {
        font-size: 112px;
    }

    .tx-lg-114 {
        font-size: 114px;
    }

    .tx-lg-114-f {
        font-size: 114px;
    }

    .tx-lg-116 {
        font-size: 116px;
    }

    .tx-lg-116-f {
        font-size: 116px;
    }

    .tx-lg-118 {
        font-size: 118px;
    }

    .tx-lg-118-f {
        font-size: 118px;
    }

    .tx-lg-120 {
        font-size: 120px;
    }

    .tx-lg-120-f {
        font-size: 120px;
    }

    .tx-lg-122 {
        font-size: 122px;
    }

    .tx-lg-122-f {
        font-size: 122px;
    }

    .tx-lg-124 {
        font-size: 124px;
    }

    .tx-lg-124-f {
        font-size: 124px;
    }

    .tx-lg-126 {
        font-size: 126px;
    }

    .tx-lg-126-f {
        font-size: 126px;
    }

    .tx-lg-128 {
        font-size: 128px;
    }

    .tx-lg-128-f {
        font-size: 128px;
    }

    .tx-lg-130 {
        font-size: 130px;
    }

    .tx-lg-130-f {
        font-size: 130px;
    }

    .tx-lg-132 {
        font-size: 132px;
    }

    .tx-lg-132-f {
        font-size: 132px;
    }

    .tx-lg-134 {
        font-size: 134px;
    }

    .tx-lg-134-f {
        font-size: 134px;
    }

    .tx-lg-136 {
        font-size: 136px;
    }

    .tx-lg-136-f {
        font-size: 136px;
    }

    .tx-lg-138 {
        font-size: 138px;
    }

    .tx-lg-138-f {
        font-size: 138px;
    }

    .tx-lg-140 {
        font-size: 140px;
    }

    .tx-lg-140-f {
        font-size: 140px;
    }

    .tx-lg-base {
        font-size: 0.875rem;
    }

    .tx-lg-bold {
        font-weight: 700;
    }

    .tx-lg-semibold {
        font-weight: 600;
    }

    .tx-lg-medium {
        font-weight: 500;
    }

    .tx-lg-normal {
        font-weight: 400;
    }

    .tx-lg-light {
        font-weight: 300;
    }

    .tx-lg-thin {
        font-weight: 200;
    }

    .tx-lg-xthin {
        font-weight: 100;
    }

    .lh-lg-0 {
        line-height: 0;
    }

    .lh-lg-1 {
        line-height: 1.1;
    }

    .lh-lg-2 {
        line-height: 1.2;
    }

    .lh-lg-3 {
        line-height: 1.3;
    }

    .lh-lg-4 {
        line-height: 1.4;
    }

    .lh-lg-5 {
        line-height: 1.5;
    }

    .lh-lg-6 {
        line-height: 1.6;
    }

    .lh-lg-7 {
        line-height: 1.7;
    }

    .lh-lg-8 {
        line-height: 1.8;
    }

    .lh-lg-9 {
        line-height: 1.9;
    }

    .lh-lg-10 {
        line-height: 2;
    }

    .lh-lg-11 {
        line-height: 2.1;
    }

    .lh-lg-12 {
        line-height: 2.2;
    }

    .lh-lg-13 {
        line-height: 2.3;
    }

    .lh-lg-14 {
        line-height: 2.4;
    }

    .lh-lg-15 {
        line-height: 2.5;
    }

    .tx-lg-center {
        text-align: center;
    }

    .tx-lg-right {
        text-align: right;
    }

    .tx-lg-left {
        text-align: left;
    }

    .tx-lg-center-f {
        text-align: center;
    }

    .tx-lg-right-f {
        text-align: right;
    }

    .tx-lg-left-f {
        text-align: left;
    }
}

@media (min-width: 1200px) {
    .tx-xl-8 {
        font-size: 8px;
    }

    .tx-xl-8-f {
        font-size: 8px;
    }

    .tx-xl-9 {
        font-size: 9px;
    }

    .tx-xl-9-f {
        font-size: 9px;
    }

    .tx-xl-10 {
        font-size: 10px;
    }

    .tx-xl-10-f {
        font-size: 10px;
    }

    .tx-xl-11 {
        font-size: 11px;
    }

    .tx-xl-11-f {
        font-size: 11px;
    }

    .tx-xl-12 {
        font-size: 12px;
    }

    .tx-xl-12-f {
        font-size: 12px;
    }

    .tx-xl-13 {
        font-size: 13px;
    }

    .tx-xl-13-f {
        font-size: 13px;
    }

    .tx-xl-14 {
        font-size: 14px;
    }

    .tx-xl-14-f {
        font-size: 14px;
    }

    .tx-xl-15 {
        font-size: 15px;
    }

    .tx-xl-15-f {
        font-size: 15px;
    }

    .tx-xl-16 {
        font-size: 16px;
    }

    .tx-xl-16-f {
        font-size: 16px;
    }

    .tx-xl-18 {
        font-size: 18px;
    }

    .tx-xl-18-f {
        font-size: 18px;
    }

    .tx-xl-20 {
        font-size: 20px;
    }

    .tx-xl-20-f {
        font-size: 20px;
    }

    .tx-xl-22 {
        font-size: 22px;
    }

    .tx-xl-22-f {
        font-size: 22px;
    }

    .tx-xl-24 {
        font-size: 24px;
    }

    .tx-xl-24-f {
        font-size: 24px;
    }

    .tx-xl-26 {
        font-size: 26px;
    }

    .tx-xl-26-f {
        font-size: 26px;
    }

    .tx-xl-28 {
        font-size: 28px;
    }

    .tx-xl-28-f {
        font-size: 28px;
    }

    .tx-xl-30 {
        font-size: 30px;
    }

    .tx-xl-30-f {
        font-size: 30px;
    }

    .tx-xl-32 {
        font-size: 32px;
    }

    .tx-xl-32-f {
        font-size: 32px;
    }

    .tx-xl-34 {
        font-size: 34px;
    }

    .tx-xl-34-f {
        font-size: 34px;
    }

    .tx-xl-36 {
        font-size: 36px;
    }

    .tx-xl-36-f {
        font-size: 36px;
    }

    .tx-xl-38 {
        font-size: 38px;
    }

    .tx-xl-38-f {
        font-size: 38px;
    }

    .tx-xl-40 {
        font-size: 40px;
    }

    .tx-xl-40-f {
        font-size: 40px;
    }

    .tx-xl-42 {
        font-size: 42px;
    }

    .tx-xl-42-f {
        font-size: 42px;
    }

    .tx-xl-44 {
        font-size: 44px;
    }

    .tx-xl-44-f {
        font-size: 44px;
    }

    .tx-xl-46 {
        font-size: 46px;
    }

    .tx-xl-46-f {
        font-size: 46px;
    }

    .tx-xl-48 {
        font-size: 48px;
    }

    .tx-xl-48-f {
        font-size: 48px;
    }

    .tx-xl-50 {
        font-size: 50px;
    }

    .tx-xl-50-f {
        font-size: 50px;
    }

    .tx-xl-52 {
        font-size: 52px;
    }

    .tx-xl-52-f {
        font-size: 52px;
    }

    .tx-xl-54 {
        font-size: 54px;
    }

    .tx-xl-54-f {
        font-size: 54px;
    }

    .tx-xl-56 {
        font-size: 56px;
    }

    .tx-xl-56-f {
        font-size: 56px;
    }

    .tx-xl-58 {
        font-size: 58px;
    }

    .tx-xl-58-f {
        font-size: 58px;
    }

    .tx-xl-60 {
        font-size: 60px;
    }

    .tx-xl-60-f {
        font-size: 60px;
    }

    .tx-xl-62 {
        font-size: 62px;
    }

    .tx-xl-62-f {
        font-size: 62px;
    }

    .tx-xl-64 {
        font-size: 64px;
    }

    .tx-xl-64-f {
        font-size: 64px;
    }

    .tx-xl-66 {
        font-size: 66px;
    }

    .tx-xl-66-f {
        font-size: 66px;
    }

    .tx-xl-68 {
        font-size: 68px;
    }

    .tx-xl-68-f {
        font-size: 68px;
    }

    .tx-xl-70 {
        font-size: 70px;
    }

    .tx-xl-70-f {
        font-size: 70px;
    }

    .tx-xl-72 {
        font-size: 72px;
    }

    .tx-xl-72-f {
        font-size: 72px;
    }

    .tx-xl-74 {
        font-size: 74px;
    }

    .tx-xl-74-f {
        font-size: 74px;
    }

    .tx-xl-76 {
        font-size: 76px;
    }

    .tx-xl-76-f {
        font-size: 76px;
    }

    .tx-xl-78 {
        font-size: 78px;
    }

    .tx-xl-78-f {
        font-size: 78px;
    }

    .tx-xl-80 {
        font-size: 80px;
    }

    .tx-xl-80-f {
        font-size: 80px;
    }

    .tx-xl-82 {
        font-size: 82px;
    }

    .tx-xl-82-f {
        font-size: 82px;
    }

    .tx-xl-84 {
        font-size: 84px;
    }

    .tx-xl-84-f {
        font-size: 84px;
    }

    .tx-xl-86 {
        font-size: 86px;
    }

    .tx-xl-86-f {
        font-size: 86px;
    }

    .tx-xl-88 {
        font-size: 88px;
    }

    .tx-xl-88-f {
        font-size: 88px;
    }

    .tx-xl-90 {
        font-size: 90px;
    }

    .tx-xl-90-f {
        font-size: 90px;
    }

    .tx-xl-92 {
        font-size: 92px;
    }

    .tx-xl-92-f {
        font-size: 92px;
    }

    .tx-xl-94 {
        font-size: 94px;
    }

    .tx-xl-94-f {
        font-size: 94px;
    }

    .tx-xl-96 {
        font-size: 96px;
    }

    .tx-xl-96-f {
        font-size: 96px;
    }

    .tx-xl-98 {
        font-size: 98px;
    }

    .tx-xl-98-f {
        font-size: 98px;
    }

    .tx-xl-100 {
        font-size: 100px;
    }

    .tx-xl-100-f {
        font-size: 100px;
    }

    .tx-xl-102 {
        font-size: 102px;
    }

    .tx-xl-102-f {
        font-size: 102px;
    }

    .tx-xl-104 {
        font-size: 104px;
    }

    .tx-xl-104-f {
        font-size: 104px;
    }

    .tx-xl-106 {
        font-size: 106px;
    }

    .tx-xl-106-f {
        font-size: 106px;
    }

    .tx-xl-108 {
        font-size: 108px;
    }

    .tx-xl-108-f {
        font-size: 108px;
    }

    .tx-xl-110 {
        font-size: 110px;
    }

    .tx-xl-110-f {
        font-size: 110px;
    }

    .tx-xl-112 {
        font-size: 112px;
    }

    .tx-xl-112-f {
        font-size: 112px;
    }

    .tx-xl-114 {
        font-size: 114px;
    }

    .tx-xl-114-f {
        font-size: 114px;
    }

    .tx-xl-116 {
        font-size: 116px;
    }

    .tx-xl-116-f {
        font-size: 116px;
    }

    .tx-xl-118 {
        font-size: 118px;
    }

    .tx-xl-118-f {
        font-size: 118px;
    }

    .tx-xl-120 {
        font-size: 120px;
    }

    .tx-xl-120-f {
        font-size: 120px;
    }

    .tx-xl-122 {
        font-size: 122px;
    }

    .tx-xl-122-f {
        font-size: 122px;
    }

    .tx-xl-124 {
        font-size: 124px;
    }

    .tx-xl-124-f {
        font-size: 124px;
    }

    .tx-xl-126 {
        font-size: 126px;
    }

    .tx-xl-126-f {
        font-size: 126px;
    }

    .tx-xl-128 {
        font-size: 128px;
    }

    .tx-xl-128-f {
        font-size: 128px;
    }

    .tx-xl-130 {
        font-size: 130px;
    }

    .tx-xl-130-f {
        font-size: 130px;
    }

    .tx-xl-132 {
        font-size: 132px;
    }

    .tx-xl-132-f {
        font-size: 132px;
    }

    .tx-xl-134 {
        font-size: 134px;
    }

    .tx-xl-134-f {
        font-size: 134px;
    }

    .tx-xl-136 {
        font-size: 136px;
    }

    .tx-xl-136-f {
        font-size: 136px;
    }

    .tx-xl-138 {
        font-size: 138px;
    }

    .tx-xl-138-f {
        font-size: 138px;
    }

    .tx-xl-140 {
        font-size: 140px;
    }

    .tx-xl-140-f {
        font-size: 140px;
    }

    .tx-xl-base {
        font-size: 0.875rem;
    }

    .tx-xl-bold {
        font-weight: 700;
    }

    .tx-xl-semibold {
        font-weight: 600;
    }

    .tx-xl-medium {
        font-weight: 500;
    }

    .tx-xl-normal {
        font-weight: 400;
    }

    .tx-xl-light {
        font-weight: 300;
    }

    .tx-xl-thin {
        font-weight: 200;
    }

    .tx-xl-xthin {
        font-weight: 100;
    }

    .lh-xl-0 {
        line-height: 0;
    }

    .lh-xl-1 {
        line-height: 1.1;
    }

    .lh-xl-2 {
        line-height: 1.2;
    }

    .lh-xl-3 {
        line-height: 1.3;
    }

    .lh-xl-4 {
        line-height: 1.4;
    }

    .lh-xl-5 {
        line-height: 1.5;
    }

    .lh-xl-6 {
        line-height: 1.6;
    }

    .lh-xl-7 {
        line-height: 1.7;
    }

    .lh-xl-8 {
        line-height: 1.8;
    }

    .lh-xl-9 {
        line-height: 1.9;
    }

    .lh-xl-10 {
        line-height: 2;
    }

    .lh-xl-11 {
        line-height: 2.1;
    }

    .lh-xl-12 {
        line-height: 2.2;
    }

    .lh-xl-13 {
        line-height: 2.3;
    }

    .lh-xl-14 {
        line-height: 2.4;
    }

    .lh-xl-15 {
        line-height: 2.5;
    }

    .tx-xl-center {
        text-align: center;
    }

    .tx-xl-right {
        text-align: right;
    }

    .tx-xl-left {
        text-align: left;
    }

    .tx-xl-center-f {
        text-align: center;
    }

    .tx-xl-right-f {
        text-align: right;
    }

    .tx-xl-left-f {
        text-align: left;
    }
}

.text-nowrap {
    white-space: nowrap !important;
}

@media (max-width: 576px) {
    .text-sm-nowrap {
        white-space: nowrap;
    }
}

@media (max-width: 768px) {
    .text-md-nowrap {
        white-space: nowrap;
    }
}

@media (max-width: 992px) {
    .text-lg-nowrap {
        white-space: nowrap;
    }
}

@media (max-width: 1000px) {
    .text-xl-nowrap {
        white-space: nowrap;
    }
}

.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
    text-align: justify !important;
}

.text-wrap {
    white-space: normal !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-start {
    text-align: left !important;
}

.text-end {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

@media (min-width: 576px) {
    .text-sm-left {
        text-align: left;
    }

    .text-sm-right {
        text-align: right;
    }

    .text-sm-center {
        text-align: center;
    }
}

@media (min-width: 768px) {
    .text-md-left {
        text-align: left;
    }

    .text-md-right {
        text-align: right;
    }

    .text-md-center {
        text-align: center;
    }
}

@media (min-width: 992px) {
    .text-lg-left {
        text-align: left;
    }

    .text-lg-right {
        text-align: right;
    }

    .text-lg-center {
        text-align: center;
    }
}

@media (min-width: 1200px) {
    .text-xl-left {
        text-align: left;
    }

    .text-xl-right {
        text-align: right;
    }

    .text-xl-center {
        text-align: center;
    }
}

.text-lowercase {
    text-transform: lowercase;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.font-weight-light {
    font-weight: 300;
}

.font-weight-lighter {
    font-weight: lighter;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-weight-bolder {
    font-weight: bolder;
}

.font-italic {
    font-style: italic;
}

.text-white {
    color: #fff !important;
}

.text-white-80 {
    color: #fff;
    opacity: 0.8;
}

.text-primary {
    color: var(--primary) !important;
}

.text-primary-dark {
    color: var(--primary-darker) !important;
}

.text-primary-75 {
    color: var(--primary-75) !important;
}

.text-primary-50 {
    color: var(--primary-50) !important;
}

.text-primary-25 {
    color: var(--primary-25) !important;
}

a.text-primary:hover,
a.text-primary:focus {
    color: var(--primary-75) !important;
}

.text-secondary {
    color: var(--secondary) !important;
}

.text-secondary-dark {
    color: var(--secondary-darker) !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
    color: var(--secondary-50) !important;
}

.text-success {
    color: var(--success) !important;
}

.text-success-dark {
    color: var(--success-darker) !important;
}

a.text-success:hover,
a.text-success:focus {
    color: var(--success-50) !important;
}

.text-error {
    color: var(--error) !important;
}

.text-error-dark {
    color: var(--error-darker) !important;
}

.text-info {
    color: var(--info) !important;
}

.text-info-dark {
    color: var(--info-darker) !important;
}

a.text-info:hover,
a.text-info:focus {
    color: var(--info-50) !important;
}

.text-warning {
    color: var(--warning) !important;
}

.text-warning-dark {
    color: var(--warning-darker) !important;
}

a.text-warning:hover,
a.text-warning:focus {
    color: var(--warning-50) !important;
}

.text-purple {
    color: #ad4ffa !important;
}

a.text-purple:hover,
a.text-purple:focus {
    color: #ad4ffa !important;
}

.text-danger {
    color: var(--error) !important;
}

a.text-danger:hover,
a.text-danger:focus {
    color: var(--error-50) !important;
}

.text-pink {
    color: #f754fa !important;
}

a.text-pink:hover,
a.text-pink:focus {
    color: #e61078 !important;
}

.text-orange {
    color: #fd7e14 !important;
}

a.text-orange:hover,
a.text-orange:focus {
    color: #e46f0e !important;
}

.text-light {
    color: #f9f9fb !important;
}

a.text-light:hover,
a.text-light:focus {
    color: #c5cbda !important;
}

.text-teal {
    color: #29ddee !important;
}

a.text-teal:hover,
a.text-teal:focus {
    color: #06c1c1 !important;
}

.text-dark {
    color: #383853 !important;
}

a.text-dark:hover,
a.text-dark:focus {
    color: #1e2533 !important;
}

.text-body {
    color: #4a4a69;
}

.text-muted {
    color: #7987a1 !important;
}

.text-black-50 {
    color: rgba(0, 0, 0, 0.5);
}

.text-white-50 {
    color: rgba(255, 255, 255, 0.5);
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.text-decoration-none {
    text-decoration: none;
}

.text-break {
    word-break: break-word;
    overflow-wrap: break-word;
}

.text-reset {
    color: inherit;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden;
}

.accordion>.card:not(:first-of-type) .card-header:first-child {
    border-radius: 0;
}

.accordion>.card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0;
}

.accordion>.card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion>.card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.accordion .card {
    border-width: 0;
}

.accordion .card+.card {
    margin-top: 1px;
}

.accordion .card-header {
    padding: 0;
    background-color: transparent;
    font-weight: 400;
}

.accordion .card-header a {
    display: block;
    padding: 12px;
    color: #4a4a69;
    position: relative;
    background-color: var(--primary-50);
    border: 1px solid #ededf5;
    border-radius: 5px;
}

.accordion .card-header a[aria-expanded=true] {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.accordion .card-header a:hover,
.accordion .card-header a:focus {
    color: var(--primary);
}

.accordion .card-header a.collapsed {
    color: #4d5875;
    background-color: #fff;
    border-bottom-color: #ededf5;
}

.accordion .card-header a.collapsed:hover,
.accordion .card-header a.collapsed:focus {
    color: #323251;
    background-color: var(--primary-50);
}

.accordion .card-body {
    padding: 20px 20px;
    background-color: #fff;
    transition: none;
    border: 1px solid #ededf5;
    border-top: 0px;
    border-radius: 5px;
}

.accordion .collapse.show .card-body {
    border-radius: 0px 0px 5px 5px;
}

.card .card {
    box-shadow: none !important;
}

.accordion>.card {
    border: 0;
}

/* ###### 3.1 Accordion ###### */
.accordion-indigo .card {
    border-color: var(--primary);
}

.accordion-indigo .card-header a {
    color: var(--primary);
}

.accordion-indigo .card-header a:hover,
.accordion-indigo .card-header a:focus {
    color: var(--primary);
}

.accordion-indigo .card-header a.collapsed {
    background-color: var(--primary);
    color: rgba(255, 255, 255, 0.8);
    font-weight: 400;
}

.accordion-indigo .card-header a.collapsed:hover,
.accordion-indigo .card-header a.collapsed:focus {
    background-color: #452efa;
    color: #fff;
}

.accordion-blue .card {
    border-color: var(--primary);
}

.accordion-blue .card-header a {
    color: var(--primary);
}

.accordion-blue .card-header a:hover,
.accordion-blue .card-header a:focus {
    color: var(--primary);
}

.accordion-blue .card-header a.collapsed {
    background-color: var(--primary);
    color: rgba(255, 255, 255, 0.8);
    font-weight: 400;
}

.accordion-blue .card-header a.collapsed:hover,
.accordion-blue .card-header a.collapsed:focus {
    background-color: #1a53ff;
    color: #fff;
}

.accordion-dark .card {
    background-color: #ebe2fc;
}

.accordion-dark .card-header a {
    color: #4a4a69;
}

.accordion-dark .card-header a:hover,
.accordion-dark .card-header a:focus {
    color: var(--primary);
}

.accordion-dark .card-header a.collapsed {
    background-color: #ffffff;
    color: #4d5875;
    font-weight: 400;
}

.accordion-dark .card-header a.collapsed:hover,
.accordion-dark .card-header a.collapsed:focus {
    background-color: var(--primary-50);
    color: var(--primary);
}

.accordion-gray .card {
    border-color: #7987a1;
}

.accordion-gray .card-header a {
    color: #7987a1;
}

.accordion-gray .card-header a:hover,
.accordion-gray .card-header a:focus {
    color: #7987a1;
}

.accordion-gray .card-header a.collapsed {
    background-color: var(--primary-50);
    color: rgba(255, 255, 255, 0.8);
    font-weight: 400;
}

.accordion-gray .card-header a.collapsed:hover,
.accordion-gray .card-header a.collapsed:focus {
    background-color: #6a7a96;
    color: #fff;
}

/* ############### CUSTOM ELEMENT STYLES ############### */
.main-accordion .accordion-item {
    background-color: #f9f9fb;
    border: 1px solid #dee2ef;
    position: relative;
    transition: all 0.2s ease-in-out;
}

.main-accordion .accordion-item:hover,
.main-accordion .accordion-item:focus {
    cursor: pointer;
    background-color: #f1f2f6;
    border-color: #bcc4de;
    z-index: 4;
}

.main-accordion .accordion-item.active {
    background-color: #fff;
    z-index: 5;
    border-color: #bcc4de;
}

.main-accordion .accordion-item.active .accordion-title {
    color: var(--primary);
}

.main-accordion .accordion-item.active .accordion-body {
    display: block;
}

.main-accordion .accordion-item+.accordion-item {
    margin-top: -1px;
}

.main-accordion .accordion-header {
    display: flex;
    align-items: center;
    padding: 15px 20px;
}

.main-accordion .accordion-title {
    font-weight: 700;
    color: #4a4a69;
    margin: 0;
    transition: all 0.2s ease-in-out;
}

.main-accordion .accordion-body {
    display: none;
    padding: 0 20px 20px;
}

@media (prefers-reduced-motion: reduce) {
    .main-accordion .accordion-item {
        transition: none;
    }
}

@media (prefers-reduced-motion: reduce) {
    .main-accordion .accordion-title {
        transition: none;
    }
}

.accordion-button {
    background-color: var(--neutral-40) !important;
    border-radius: 10px 10px 0 0;
    color: var(--primary-75);
}

.accordion-button:not(.collapsed) {
    color: var(--primary-75);
    box-shadow: none;
}

.accordion-item {
    border: none;
}

.accordion-item:not(:first-child) {
    margin-top: 32px;
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.accordion-body {
    padding: 20px;
    border-radius: 0 0 10px 10px;
    border: 1px solid var(--primary-10);
    border-top: none;
}

.panel-heading1 .panel-title1 a {
    display: block;
}

.panel-title1 {
    margin-bottom: 0;
}

.panel-heading1 {
    display: block;
    padding: 16px;
    background: var(--neutral-40);
    border-radius: 10px 10px 0 0;
    color: var(--primary-75);
}

.panel-title1 a {
    display: block;
    color: #fff;
    padding: 15px;
    position: relative;
    font-weight: 400;
    font-size: 15px;
}

.panel-heading1.collapsed:before {
    content: "expand_more";
    position: absolute;
    font-family: "Material Symbols Outlined";
    right: 10px;
    top: 6px;
    font-size: 20px;
    transition: all 0.5s;
    transform: scale(1);
}

.accordion-button:after {
    content: "expand_more";
    position: absolute;
    font-family: "Material Symbols Outlined";
    right: 16px;
    top: 16px;
    font-size: 20px;
    transition: all 0.5s;
    transform: scale(1);
    background-image: none;
}

.accordion-button:not(.collapsed):after {
    content: "expand_more";
    font-family: "Material Symbols Outlined";
    transform: rotate(180deg);
    background-image: none;
}

.panel-group1 .panel-collapse.collapse.show:before {
    content: "\e994";
    position: absolute;
    font-family: "feather";
    right: 10px;
    top: 6px;
    font-size: 20px;
    transition: all 0.5s;
    transform: scale(1);
    color: var(--primary);
}

.accor.bg-primary h4 a {
    color: #fff;
}

.accor div a {
    padding: 12px;
    display: block;
    border-bottom: 1px solid rgb(251, 251, 253);
    background: var(--primary-50);
    color: #4a4a69;
    border-radius: 5px;
}

.accor div a[aria-expanded=true] {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

#accordion11 .panel-body {
    padding: 15px;
    border: 1px solid #ededf5;
    border-radius: 0px 0px 5px 5px;
}

.panel-heading2 {
    display: block;
    padding: 12px;
    background: #ffffff;
    color: #4a4a69;
}

.panel-heading2.collapsed:before {
    content: "\e92d";
    position: absolute;
    font-family: "feather";
    right: 10px;
    top: 6px;
    font-size: 16px;
    line-height: 2.3;
    transition: all 0.5s;
    transform: scale(1);
}

.panel-group2 .panel-collapse.collapse.show:before {
    content: "\e930";
    position: absolute;
    font-family: "feather";
    right: 10px;
    top: 6px;
    font-size: 16px;
    line-height: 2.3;
    transition: all 0.5s;
    transform: scale(1);
    color: var(--primary);
}

.accstyle {
    border-radius: 5px;
}

.collapse.show .accstyle {
    border-radius: 0px 0px 5px 5px;
}

.modal-accordion .accordion-button {
    background-color: #fff !important;
    color: var(--primary-50) !important;
}

.modal-accordion .accordion-item:not(:first-child) {
    margin-top: 0px;
    border-top: 1px solid var(--neutral-50);
}

.modal-accordion .accordion-body {
    border: none !important;
}

.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 3px;
}

.alert-heading {
    color: inherit;
}

.alert-link {
    font-weight: 700;
}

.alert-dismissible {
    padding-right: 3.8125rem;
}

.alert-dismissible .btn-close {
    position: absolute;
    color: inherit;
    font-size: 24px;
    background-image: none !important;
    padding: 7px !important;
    line-height: 1;
    top: 4px;
}

.alert-primary {
    color: var(--primary);
    background-color: var(--primary-50);
    border-color: var(--primary-50);
}

.alert-primary hr {
    border-top-color: var(--primary);
}

.alert-primary .alert-link {
    color: var(--primary);
}

.alert-secondary {
    color: var(--secondary);
    background-color: rgba(247, 79, 117, 0.2);
    border-color: rgba(247, 79, 117, 0.2);
}

.alert-secondary hr {
    border-top-color: var(--secondary);
}

.alert-secondary .alert-link {
    color: #f72959;
}

.alert-success {
    color: #1a9c86;
    background-color: rgba(26, 156, 134, 0.2);
    border-color: rgba(26, 156, 134, 0.2);
}

.alert-success hr {
    border-top-color: var(--success);
}

.alert-success .alert-link {
    color: #059c83;
}

.alert-info {
    color: #4ec2f0;
    background-color: rgba(var(--info), 0.2);
    border-color: rgba(var(--info), 0.2);
}

.alert-info hr {
    border-top-color: var(--info);
}

.alert-info .alert-link {
    color: #1fb3ee;
}

.alert-warning {
    color: #ffbd5a;
    background-color: rgba(255, 189, 90, 0.2);
    border-color: rgba(255, 189, 90, 0.2);
}

.alert-warning hr {
    border-top-color: var(--warning);
}

.alert-warning .alert-link {
    color: #fca626;
}

.alert-danger {
    color: #f34343;
    background-color: rgba(243, 67, 67, 0.2);
    border-color: rgba(243, 67, 67, 0.2);
}

.alert-danger hr {
    border-top-color: #f34343;
}

.alert-danger .alert-link {
    color: #f11a1a;
}

.alert-light {
    color: #7f7f81;
    background-color: #fdfdfe;
    border-color: #d8dae0;
}

.alert-light hr {
    border-top-color: #ededf3;
}

.alert-light .alert-link {
    color: #666667;
}

.alert-dark {
    color: #1f2533;
    background-color: #d8dae0;
    border-color: #c8ccd3;
}

.alert-dark hr {
    border-top-color: #babfc8;
}

.alert-dark .alert-link {
    color: #0c0e13;
}

.alert-default {
    color: #7f7f81;
    background-color: #fdfdfe;
    border-color: #d8dae0;
}

.alert-default hr {
    border-top-color: #babfc8;
}

.alert-default .alert-link {
    color: #666667;
}

/* ###### 3.2 Alerts ###### */
.alert {
    border-width: 1px;
    padding: 12px 15px;
    border-radius: 7px;
}

.alert .close {
    text-shadow: none;
    opacity: 0.5;
}

.alert strong {
    font-weight: 600;
}

[class*=alert-outline-] {
    border: 1px solid transparent;
}

.alert-outline-success {
    border-color: #1a9c86;
    color: var(--success);
}

.alert-outline-info {
    border-color: #4ec2f0;
    color: var(--info);
}

.alert-outline-warning {
    border-color: #ffbd5a;
    color: var(--warning);
}

.alert-outline-danger {
    border-color: #f34343;
    color: #f34343;
}

[class*=alert-solid-] {
    border-width: 0;
    color: #fff;
}

.alert-solid-success {
    background-color: var(--success);
}

.alert-solid-info {
    background-color: var(--info);
}

.alert-solid-warning {
    background-color: var(--warning);
}

.alert-solid-danger {
    background-color: #f34343;
}

.badge-light {
    background-color: #e1e1ef;
}

.badge-pill {
    padding-left: 8px;
    padding-right: 8px;
}

/*////////////////////badgelight//////////////////*/
.badge-primary-transparent {
    color: var(--primary);
    background-color: var(--primary-50);
}

.badge-success-transparent {
    background-color: rgba(var(--success), 0.15) !important;
    color: var(--success);
}

.badge-teal-transparent {
    background-color: rgba(41, 221, 238, 0.15) !important;
    color: #29ddee;
}

.badge-warning-transparent {
    background-color: rgba(var(--warning), 0.15) !important;
    color: var(--warning);
}

.badge-danger-transparent {
    color: #f34343;
    background-color: rgba(243, 67, 67, 0.15);
}

.badge-purple-transparent {
    color: #ad4ffa;
    background-color: rgba(173, 79, 250, 0.15);
}

.badge-info-transparent {
    color: #0a7ffb;
    background-color: rgba(var(--info), 0.15);
}

.badge-pink-transparent {
    color: #f754fa;
    background-color: rgba(247, 84, 250, 0.15);
}

.badge-light-transparent {
    color: #7987a1;
    background-color: rgba(121, 135, 161, 0.15);
}

.badge {
    display: inline-block;
    padding: 4px 6px 4px 6px;
    font-weight: 400;
    line-height: 1.1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 3px;
    font-size: 75%;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .badge {
        transition: none;
    }
}

a.badge:hover,
a.badge:focus {
    text-decoration: none;
}

.badge:empty {
    display: none;
}

.btn .badge,
.sp-container button .badge {
    position: relative;
    top: -1px;
}

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

.badge-primary {
    color: #fff;
    background-color: var(--primary);
}

a.badge-primary:hover {
    color: #fff;
    background-color: #0040ff;
}

a.badge-primary:focus {
    color: #fff;
    background-color: #0040ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(51, 102, 255, 0.5);
}

a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(51, 102, 255, 0.5);
}

.badge-secondary {
    color: #fff;
    background-color: var(--secondary);
}

a.badge-secondary:hover {
    color: #fff;
    background-color: var(--secondary);
}

a.badge-secondary:focus {
    color: #fff;
    background-color: var(--secondary);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(247, 79, 117, 0.5);
}

a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(247, 79, 117, 0.5);
}

.badge-success {
    color: #fff;
    background-color: var(--success);
}

a.badge-success:hover {
    color: #fff;
    background-color: #2a7d01;
}

a.badge-success:focus {
    color: #fff;
    background-color: #2a7d01;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(59, 176, 1, 0.5);
}

a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(59, 176, 1, 0.5);
}

.badge-info {
    color: #fff;
    background-color: var(--info);
}

a.badge-info:hover {
    color: #fff;
    background-color: var(--info);
}

a.badge-info:focus {
    color: #fff;
    background-color: var(--info);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
    color: #f5f5f5;
    background-color: var(--warning);
}

a.badge-warning:hover,
a.badge-warning:focus {
    color: #323251;
    background-color: #d39e00;
}

.badge-purple {
    color: #fff;
    background-color: 7571f9;
}

a.badge-purple:hover {
    color: #fff;
    background-color: #6536bb;
}

a.badge-purple:focus {
    color: #fff;
    background-color: #6536bb;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(101, 54, 187, 0.5);
}

a.badge-purple.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(101, 54, 187, 0.5);
}

.badge-teal {
    color: #fff;
    background-color: #29ddee;
}

a.badge-teal:hover,
a.badge-teal:focus {
    color: #323251;
    background-color: #29ddee;
}

a.badge-warning:focus,
a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
    color: #fff;
    background-color: #f34343;
}

a.badge-danger:hover {
    color: #fff;
    background-color: #bd2130;
}

a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-pink {
    color: #fff;
    background-color: #f754fa;
}

a.badge-pink:hover {
    color: #fff;
    background-color: #e20b73;
}

a.badge-pink:focus {
    color: #fff;
    background-color: #e20b73;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(241, 0, 117, 0.5);
}

a.badge-pink.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(241, 0, 117, 0.5);
}

.badge-purple {
    color: #fff;
    background-color: #ad4ffa;
}

a.badge-purple:hover {
    color: #fff;
    background-color: #582ea2;
}

a.badge-purple:focus {
    color: #fff;
    background-color: #582ea2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(103, 58, 183, 0.5);
}

a.badge-purple.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(103, 58, 183, 0.5);
}

.bg-light {
    color: #323251;
    background-color: #f9f9fb;
}

a.badge-light:hover {
    color: #323251;
    background-color: #d5d9e4;
}

a.badge-light:focus {
    color: #323251;
    background-color: #d5d9e4;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 245, 248, 0.5);
}

a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 245, 248, 0.5);
}

.badge-dark {
    color: #fff;
    background-color: #383853;
}

a.badge-dark:hover {
    color: #fff;
    background-color: #283143;
}

a.badge-dark:focus {
    color: #fff;
    background-color: #283143;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(59, 72, 99, 0.5);
}

a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(59, 72, 99, 0.5);
}

@media print {
    .badge {
        border: 1px solid #000;
    }
}

.badge.badge-orange {
    color: #fff;
    background-color: #fd7e14;
}

.badge.badge-cyan {
    color: #fff;
    background-color: #ac50bb;
}

.badge.bg-white {
    color: #000 !important;
}

btn-outline-primary:hover .bg-primary {
    background: #fff !important;
    color: #000;
}

.btn-outline-primary:hover .bg-primary {
    background: #fff !important;
    color: #000;
}

.btn-outline-success:hover .bg-success {
    background: #fff !important;
    color: #000;
}

.btn-outline-secondary:hover .bg-secondary {
    background: #fff !important;
    color: #000;
}

.btn-outline-info:hover .bg-info {
    background: #fff !important;
    color: #000;
}

.breadcrumb-header {
    display: flex;
    margin-top: 20px;
    margin-bottom: 16px;
    width: 100%;
}

.breadcrumb-header .breadcrumb {
    background: transparent !important;
    padding: 0;
    margin-bottom: 0;
    font-size: 13px;
}

.breadcrumb-3 ol {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
    padding: 10px;
    border-radius: 7px;
}

.breadcrumb-3 li {
    display: contents;
    text-align: center;
}

.breadcrumb-4 ol {
    position: relative;
    display: block;
    width: 100%;
    text-align: right;
}

.breadcrumb-4 li {
    display: contents;
    text-align: right;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: ">";
}

@media (max-width: 633px) {
    .breadcrumb-header {
        display: block;
    }
}

@media (max-width: 380px) {
    .breadcrumb-right .btn-outline-primary {
        display: none;
    }
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    border-radius: 7px;
}

.breadcrumb-item a {
    color: #7987a1;
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: rgba(227, 230, 232, 0.6901960784);
    content: "/";
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: underline;
    text-decoration: none;
}

.breadcrumb-item.active {
    color: var(--primary);
}

.breadcrumb-style1,
.breadcrumb-style2,
.breadcrumb-style3 {
    border-radius: 7px;
}

.breadcrumb-style1 .breadcrumb-item a,
.breadcrumb-style2 .breadcrumb-item a,
.breadcrumb-style3 .breadcrumb-item a {
    color: #6c7b9a;
}

.breadcrumb-style1 .breadcrumb-item a:hover,
.breadcrumb-style1 .breadcrumb-item a:focus {
    color: var(--primary);
}

.breadcrumb-style2 .breadcrumb-item a:hover,
.breadcrumb-style2 .breadcrumb-item a:focus {
    color: var(--primary);
}

.breadcrumb-style3 .breadcrumb-item a:hover,
.breadcrumb-style3 .breadcrumb-item a:focus {
    color: var(--primary);
}

.breadcrumb-style1 .breadcrumb-item.active,
.breadcrumb-style2 .breadcrumb-item.active,
.breadcrumb-style3 .breadcrumb-item.active {
    color: var(--primary);
}

.breadcrumb-style1 .breadcrumb-item+.breadcrumb-item::before,
.breadcrumb-style2 .breadcrumb-item+.breadcrumb-item::before,
.breadcrumb-style3 .breadcrumb-item+.breadcrumb-item::before {
    color: #cfd4e1;
}

.breadcrumb-style2 .breadcrumb-item+.breadcrumb-item::before,
.breadcrumb-style3 .breadcrumb-item+.breadcrumb-item::before {
    width: 16px;
    text-align: center;
    font-family: "Ionicons" !important;
}

.breadcrumb-style2 .breadcrumb-item+.breadcrumb-item::before {
    content: "\f3d1";
}

.breadcrumb-style3 .breadcrumb-item+.breadcrumb-item::before {
    content: "\f287";
}

.main-content-breadcrumb {
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #949eb7;
    margin-bottom: 10px;
}

.main-content-breadcrumb span {
    position: relative;
}

.main-content-breadcrumb span+span::before {
    content: "\f3d1";
    font-family: "Ionicons";
    font-size: 12px;
    font-weight: 300;
    display: inline;
    margin: 0 5px;
    color: #d6d6e6;
    line-height: 0;
    position: relative;
    top: 1px;
}

.main-content-breadcrumb span:last-child {
    color: #4d5875;
}

.main-breadcrumbs {
    padding: 0;
    background-color: transparent;
    margin-bottom: 0;
}

.main-breadcrumbs .breadcrumb-item {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 700;
}

.main-breadcrumbs .breadcrumb-item a {
    color: #4a4a69;
    transition: all 0.2s ease-in-out;
}

.main-breadcrumbs .breadcrumb-item a:hover {
    color: #949eb7;
}

.main-breadcrumbs .breadcrumb-item.active {
    color: var(--primary);
}

.main-breadcrumbs .breadcrumb-item+.breadcrumb-item {
    padding-left: 8px;
}

.main-breadcrumbs .breadcrumb-item+.breadcrumb-item::before {
    color: #949eb7;
    padding-right: 8px;
    font-weight: 400;
}

@media (prefers-reduced-motion: reduce) {
    .main-breadcrumbs .breadcrumb-item a {
        transition: none;
    }
}

.breadcrumb-item+.breadcrumb-item::before {
    float: none;
    padding-right: 0.5rem;
    color: #d6dfe7;
    content: var(--bs-breadcrumb-divider, "\e933");
    font-family: "feather" !important;
}

.btn,
.sp-container button {
    display: flex;
    font-weight: 400;
    min-width: 118px;
    max-width: 400px;
    color: #4a4a69;
    align-items: center;
    justify-content: center;
    user-select: none;
    background-color: transparent;
    padding: 0.5rem 0.78rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 10px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn span,
.sp-container button span {
    font-size: 0.875rem;
    margin-right: 5px;
}

@media (prefers-reduced-motion: reduce) {

    .btn,
    .sp-container button {
        transition: none;
    }
}

.btn:hover,
.sp-container button:hover {
    color: #4a4a69;
    text-decoration: none;
}

.btn:focus,
.sp-container button:focus,
.btn.focus,
.sp-container button.focus {
    outline: 0;
    box-shadow: none;
}

.btn.disabled,
.sp-container button.disabled,
.btn:disabled,
.sp-container button:disabled {
    color: var(--neutral-70) !important;
    background-color: var(--primary-10) !important;
    border: none;
}

.btn.disabled-outline {
    color: var(--neutral-70) !important;
    background-color: transparent !important;
    border-color: var(--neutral-70) !important;
    pointer-events: none !important;
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}

.btn-primary {
    color: var(--neutral-20) !important;
    background-color: var(--primary);
    /* &:focus, &.focus {
    box-shadow: 0 0 0 0.15rem rgba(119, 210, 191, 0.5);
  } */
    /* &.disabled, &:disabled {
    color: $white;
    background-color: $primary!important;
    border-color: $primary  !important;
  } */
    /*
    &:not(:disabled):not(.disabled) {
      &:active, &.active {
        color: $white;
        background-color: $primary;
        border-color: $primary;
      }
    } */
}

.btn-primary:hover {
    color: var(--neutral-20);
    background-color: var(--secondary-darker) !important;
}

.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem var(--primary-25);
}

.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(119, 210, 191, 0.2);
}

.btn-secondary {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary-50);
    /* &:focus, &.focus {
    box-shadow:0 0 0 0.2rem $secondary !important;
  }

  &.disabled, &:disabled {
    color: $white;
    background-color: $secondary;
    border-color: $secondary;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $secondary;
      border-color: $secondary-25;
    }
  } */
}

.btn-secondary:hover {
    color: #fff;
    background-color: var(--secondary-75);
    border-color: var(--secondary-75);
}

.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.1rem var(--secondary);
}

.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem var(--secondary-darker);
}

.btn-success {
    color: #fff;
    background-color: var(--success);
    border-color: var(--success);
    /* &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(26, 156, 134, 0.5);
  }

  &.disabled, &:disabled {
    color: $white;
    background-color: $success;
    border-color: $success;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $success;
      border-color: $success;
    }
  } */
}

.btn-success:hover {
    color: #fff;
    background-color: var(--success-darker);
    border-color: var(--success-darker);
}

.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: var(--success);
    border-color: var(--success);
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(26, 156, 134, 0.5);
}

.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(26, 156, 134, 0.5);
}

.btn-info {
    color: #fff;
    background-color: var(--info);
    border-color: var(--info);
    /* &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(78, 194, 240, 0.5);
  }

  &.disabled, &:disabled {
    color: $white;
    background-color: $info;
    border-color: $info;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $info;
      border-color: $info;
    }
  } */
}

.btn-info:hover {
    color: #fff;
    background-color: var(--info-darker);
    border-color: var(--info-darker);
}

.show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: var(--info);
    border-color: var(--info);
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(78, 194, 240, 0.5);
}

.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(78, 194, 240, 0.5);
}

.btn-warning {
    color: #fff;
    background-color: var(--warning);
    border-color: var(--warning);
    /* &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 189, 90, 0.5);
  }

  &.disabled, &:disabled {
    color: $white;
    background-color: $warning !important;
    border-color: $warning !important;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $warning;
      border-color: $warning;
    }
  } */
}

.btn-warning:hover {
    color: #fff;
    background-color: var(--warning-darker);
    border-color: var(--warning-darker);
}

.show>.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: var(--warning);
    border-color: var(--warning);
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 189, 90, 0.5);
}

.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 189, 90, 0.5);
}

.btn-danger {
    color: #fff !important;
    background-color: var(--error-75);
    border-color: var(--error-75);
    /* &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 67, 67, 0.2);
  }

  &.disabled, &:disabled {
    color: $white;
    background-color: $danger;
    border-color: $danger;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $danger;
      border-color: $danger;
    }
  } */
}

.btn-danger:hover {
    color: #fff;
    background-color: var(--error);
    border-color: var(--error);
}

.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #f34343;
    border-color: #f34343;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 67, 67, 0.5);
}

.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 67, 67, 0.5);
}

.btn-teal {
    color: #fff;
    background-color: #29ddee;
    border-color: #05d6d6;
}

.btn-teal:hover {
    color: #fff;
    background-color: #04bfbf;
    border-color: #04bfbf;
}

.btn-teal:focus,
.btn-teal.focus {
    box-shadow: 0 0 0 0.2rem rgba(25, 192, 192, 0.5);
}

.btn-teal.disabled,
.btn-teal:disabled {
    color: #fff;
    background-color: #29ddee;
    border-color: #29ddee;
}

.btn-teal:not(:disabled):not(.disabled):active,
.btn-teal:not(:disabled):not(.disabled).active {
    color: #fff;
    background-color: #29ddee;
    border-color: #29ddee;
}

.show>.btn-teal.dropdown-toggle {
    color: #fff;
    background-color: #29ddee;
    border-color: #29ddee;
}

.btn-teal:not(:disabled):not(.disabled):active:focus,
.btn-teal:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(25, 192, 192, 0.5);
}

.show>.btn-teal.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(25, 192, 192, 0.5);
}

.btn-white {
    color: #323251;
    background-color: #fff;
    border-color: rgba(233, 233, 255, 0.5098039216);
}

.btn-white:hover {
    color: #323251;
    background-color: #fff;
    border-color: #d5d9e4;
}

.btn-white:focus,
.btn-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 214, 220, 0.5);
    background-color: #fff !important;
    border-color: #ededf5 !important;
}

.btn-white.disabled,
.btn-white:disabled {
    color: #323251;
    background-color: #fff;
    border-color: #f9f9fb;
}

.btn-white:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled).active {
    color: #323251;
    background-color: #fff;
    border-color: #cdd2df;
}

.btn-light {
    color: #323251;
    background-color: #f9f9fb;
    border-color: rgba(233, 233, 255, 0.5098039216);
}

.btn-light:hover {
    color: #323251;
    background-color: #dde0e9;
    border-color: #d5d9e4;
}

.btn-light:focus,
.btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 214, 220, 0.5);
    background-color: #f4f4fb !important;
    border-color: #ededf5 !important;
}

.btn-light.disabled,
.btn-light:disabled {
    color: #323251;
    background-color: #f9f9fb;
    border-color: #f9f9fb;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active {
    color: #323251;
    background-color: #d5d9e4;
    border-color: #cdd2df;
}

.show>.btn-light.dropdown-toggle {
    color: #323251;
    background-color: #d5d9e4;
    border-color: #cdd2df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 214, 220, 0.5);
}

.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 214, 220, 0.5);
}

.btn-dark {
    color: #fff;
    background-color: #383853;
    border-color: #383853;
}

.btn-dark:hover {
    color: #fff;
    background-color: #2d374b;
    border-color: #283143;
}

.btn-dark:focus,
.btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(88, 99, 122, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #383853;
    border-color: #383853;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active {
    color: #fff;
    background-color: #383853;
    border-color: #383853;
}

.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #383853;
    border-color: #383853;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(88, 99, 122, 0.5);
}

.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(88, 99, 122, 0.5);
}

.btn-outline-white {
    color: #fff !important;
    border-color: #fff;
}

.btn-outline-white:hover {
    color: #fff !important;
    border-color: #fff;
}

.btn-outline-primary {
    color: var(--primary-75) !important;
    border-color: var(--primary-75);
    /* &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba($primary,0.5);
  }

  &.disabled, &:disabled {
    color: $primary;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white !important;
      background-color: $primary;
      border-color: $primary;
    }
  } */
}

.btn-outline-primary:hover {
    color: var(--primary-darker) !important;
    background-color: transparent !important;
    border-color: var(--primary-darker);
}

.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--primary), 0.5);
    background: var(--primary) !important;
}

.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--primary), 0.5);
}

.btn-outline-secondary {
    color: var(--secondary) !important;
    border-color: var(--secondary);
    /* &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 79, 117, 0.5) !important;
  }

  &.disabled, &:disabled {
    color: $gray-600;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $secondary;
      border-color:$secondary;
    }
  } */
}

.btn-outline-secondary:hover {
    color: var(--secondary-darker) !important;
    background-color: transparent !important;
    border-color: var(--secondary-darker);
}

.show>.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #7987a1;
    border-color: #7987a1;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem var(--secondary);
}

.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem var(--secondary-50);
}

.btn-outline-success {
    color: var(--success);
    border-color: var(--success);
    /* &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(26, 156, 134, 0.5);
  }

  &.disabled, &:disabled {
    color: $success;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $success;
      border-color: $success;
    }
  } */
}

.btn-outline-success:hover {
    color: var(--success-darker);
    background-color: transparent !important;
    border-color: var(--success-darker);
}

.show>.btn-outline-success.dropdown-toggle {
    color: #fff !important;
    background-color: var(--success);
    border-color: var(--success);
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(26, 156, 134, 0.5);
}

.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(26, 156, 134, 0.5);
}

.btn-outline-info {
    color: var(--info) !important;
    border-color: var(--info);
    /* &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(78, 194, 240, 0.5);
  }

  &.disabled, &:disabled {
    color: $info;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $info;
      border-color: $info;
    }
  } */
}

.btn-outline-info:hover {
    color: var(--info-darker) !important;
    background-color: transparent !important;
    border-color: var(--info-darker);
}

.show>.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: var(--info);
    border-color: var(--info);
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(78, 194, 240, 0.5);
}

.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(78, 194, 240, 0.5);
}

.btn-outline-warning {
    color: var(--warning) !important;
    border-color: var(--warning);
    /* &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 189, 90, 0.5);
  }

  &.disabled, &:disabled {
    color: $warning;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $warning;
      border-color: $warning;
    }
  } */
}

.btn-outline-warning:hover {
    color: var(--warning-darker) !important;
    background-color: transparent !important;
    border-color: var(--warning-darker);
}

.show>.btn-outline-warning.dropdown-toggle {
    color: #323251;
    background-color: var(--warning);
    border-color: var(--warning);
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 189, 90, 0.5);
}

.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 189, 90, 0.5);
}

.btn-outline-danger {
    color: var(--error-75) !important;
    border-color: var(--error-75);
    /*   &:focus, &.focus {
      box-shadow: 0 0 0 0.2rem rgba(243, 67, 67, 0.5);
    }

    &.disabled, &:disabled {
      color: $danger;
      background-color: transparent;
    }

    &:not(:disabled):not(.disabled) {
      &:active, &.active {
        color: $white;
        background-color: $danger;
        border-color: $danger;
      }
    } */
}

.btn-outline-danger:hover {
    color: var(--error) !important;
    background-color: transparent !important;
    border-color: var(--error);
}

.show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f34343;
    border-color: #f34343;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 67, 67, 0.5);
}

.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 67, 67, 0.5);
}

.btn-outline-light {
    color: #323251;
    border-color: #f9f9fb;
}

.btn-outline-light:hover {
    color: #323251;
    background-color: #f9f9fb;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 245, 248, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #949eb7;
    background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active {
    color: #323251;
    background-color: #f9f9fb;
    border-color: #f9f9fb;
}

.show>.btn-outline-light.dropdown-toggle {
    color: #323251;
    background-color: #f9f9fb;
    border-color: #f9f9fb;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(205, 211, 228, 0.5);
}

.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 245, 248, 0.5);
}

.btn-outline-dark {
    color: #383853;
    border-color: #383853;
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #383853;
    border-color: #383853;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(59, 72, 99, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #383853;
    background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active {
    color: #fff;
    background-color: #383853;
    border-color: #383853;
}

.show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #383853;
    border-color: #383853;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(59, 72, 99, 0.5);
}

.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(59, 72, 99, 0.5);
}

.btn-link-primary {
    font-size: 14px;
    text-decoration: none;
    color: var(--primary-75);
}

.btn-link-primary span {
    font-size: 14px;
    margin-right: 5px;
    vertical-align: middle;
}

.btn-link-primary:hover {
    color: var(--primary-darker);
    text-decoration: none;
}

.btn-link-primary:focus {
    text-decoration: none;
    box-shadow: none;
}

.btn-link-primary:disabled,
.btn-link-primary.disabled {
    color: var(--neutral-70);
    pointer-events: none;
}

.btn-link-success {
    text-decoration: none;
    color: var(--success);
    font-size: 14px;
}

.btn-link-success span {
    font-size: 14px;
    margin-right: 5px;
    vertical-align: middle;
}

.btn-link-success:hover {
    color: var(--success-darker);
    text-decoration: none;
}

.btn-link-success:focus {
    text-decoration: none;
    box-shadow: none;
}

.btn-link-success:disabled,
.btn-link-success.disabled {
    color: var(--neutral-70);
    pointer-events: none;
}

.btn-link-secondary {
    font-size: 14px;
    text-decoration: none;
    color: var(--secondary);
}

.btn-link-secondary span {
    font-size: 14px;
    margin-right: 5px;
    vertical-align: middle;
}

.btn-link-secondary:hover {
    color: var(--secondary-darker);
    text-decoration: none;
}

.btn-link-secondary:focus {
    text-decoration: none;
    box-shadow: none;
}

.btn-link-secondary:disabled,
.btn-link-secondary.disabled {
    color: var(--neutral-70);
    pointer-events: none;
}

.btn-link-info {
    font-size: 14px;
    text-decoration: none;
    color: var(--info-75);
}

.btn-link-info span {
    font-size: 14px;
    margin-right: 5px;
    vertical-align: middle;
}

.btn-link-info:hover {
    color: var(--info);
    text-decoration: none;
}

.btn-link-info:focus {
    text-decoration: none;
    box-shadow: none;
}

.btn-link-info:disabled,
.btn-link-info.disabled {
    color: var(--neutral-70);
    pointer-events: none;
}

.btn-link-warning {
    font-size: 14px;
    text-decoration: none;
    color: var(--warning);
}

.btn-link-warning span {
    font-size: 14px;
    margin-right: 5px;
    vertical-align: middle;
}

.btn-link-warning:hover {
    color: var(--warning-darker);
    text-decoration: none;
}

.btn-link-warning:focus {
    text-decoration: none;
    box-shadow: none;
}

.btn-link-warning:disabled,
.btn-link-warning.disabled {
    color: var(--neutral-70);
    pointer-events: none;
}

.btn-link-danger {
    font-size: 14px;
    text-decoration: none;
    color: var(--error-75);
}

.btn-link-danger span {
    font-size: 14px;
    margin-right: 5px;
    vertical-align: middle;
}

.btn-link-danger:hover {
    color: var(--error);
    text-decoration: none;
}

.btn-link-danger:focus {
    text-decoration: none;
    box-shadow: none;
}

.btn-link-danger:disabled,
.btn-link-danger.disabled {
    color: var(--neutral-70);
    pointer-events: none;
}

.btn-link {
    font-size: 14px;
    font-weight: 400;
    color: var(--primary);
    text-decoration: none;
    background: transparent;
    border: none;
}

.btn-link span {
    font-size: 14px;
    margin-right: 5px;
    vertical-align: middle;
}

.btn-link:hover {
    color: #8485fb;
    text-decoration: none;
}

.btn-link:focus,
.btn-link.focus {
    text-decoration: none;
    box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
    color: #7987a1;
    pointer-events: none;
}

.btn-lg,
.btn-group-lg>.btn,
.sp-container .btn-group-lg>button {
    padding: 0.5rem 1rem !important;
    font-size: 1.02375rem !important;
    border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm>.btn,
.sp-container .btn-group-sm>button {
    font-size: 0.725rem !important;
    padding: 0.28rem 0.51rem !important;
    border-radius: 0.2rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-block+.btn-block {
    margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
    width: 100%;
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
}

.btn-group>.btn,
.sp-container .btn-group>button,
.btn-group-vertical>.btn,
.sp-container .btn-group-vertical>button {
    position: relative;
    flex: 1 1 auto;
}

.btn-group>.btn:hover,
.sp-container .btn-group>button:hover,
.btn-group-vertical>.btn:hover,
.sp-container .btn-group-vertical>button:hover,
.btn-group>.btn:focus,
.sp-container .btn-group>button:focus,
.btn-group>.btn:active,
.sp-container .btn-group>button:active,
.btn-group>.btn.active,
.sp-container .btn-group>button.active,
.btn-group-vertical>.btn:focus,
.sp-container .btn-group-vertical>button:focus,
.btn-group-vertical>.btn:active,
.sp-container .btn-group-vertical>button:active,
.btn-group-vertical>.btn.active,
.sp-container .btn-group-vertical>button.active {
    z-index: 1;
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.btn-toolbar .input-group {
    width: auto;
}

.btn-group>.btn:not(:first-child),
.sp-container .btn-group>button:not(:first-child) {
    margin-left: -1px;
}

.btn-group>.btn-group:not(:first-child) {
    margin-left: -1px;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.sp-container .btn-group>button:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn,
.sp-container .btn-group>.btn-group:not(:last-child)>button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split,
.sp-container .btn-group-sm>button+.dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split,
.sp-container .btn-group-lg>button+.dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.btn-group-vertical>.btn {
    width: 100%;
}

.sp-container .btn-group-vertical>button {
    width: 100%;
}

.btn-group-vertical>.btn-group {
    width: 100%;
}

.btn-group-vertical>.btn:not(:first-child) {
    margin-top: -1px;
}

.sp-container .btn-group-vertical>button:not(:first-child) {
    margin-top: -1px;
}

.btn-group-vertical>.btn-group:not(:first-child) {
    margin-top: -1px;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.sp-container .btn-group-vertical>button:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.sp-container .btn-group-vertical>.btn-group:not(:last-child)>button {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn:not(:first-child),
.sp-container .btn-group-vertical>button:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.sp-container .btn-group-vertical>.btn-group:not(:first-child)>button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.btn-group-toggle>.btn,
.sp-container .btn-group-toggle>button,
.btn-group-toggle>.btn-group>.btn,
.sp-container .btn-group-toggle>.btn-group>button {
    margin-bottom: 0;
}

.btn-group-toggle>.btn input[type=radio],
.sp-container .btn-group-toggle>button input[type=radio],
.btn-group-toggle>.btn input[type=checkbox],
.sp-container .btn-group-toggle>button input[type=checkbox],
.btn-group-toggle>.btn-group>.btn input[type=radio],
.sp-container .btn-group-toggle>.btn-group>button input[type=radio],
.btn-group-toggle>.btn-group>.btn input[type=checkbox],
.sp-container .btn-group-toggle>.btn-group>button input[type=checkbox] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

/* ###### 3.3 Buttons ###### */
.btn:active,
.sp-container button:active,
.btn:focus,
.sp-container button:focus {
    box-shadow: none;
}

.show>.btn-light.dropdown-toggle {
    color: #323251;
    background-color: #ededf5;
    border-color: #ededf5;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgb(240, 239, 244);
}

.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgb(240, 239, 244);
}

.btn-indigo {
    color: #fff;
    background-color: #b057bf;
    border-color: #b057bf;
}

.btn-indigo:hover {
    color: #fff;
    background-color: #d556b7;
    border-color: #d556b7;
}

.btn-indigo:focus,
.btn-indigo.focus {
    box-shadow: 0 0 0 0.2rem rgba(172, 80, 187, 0.5);
}

.btn-indigo.disabled,
.btn-indigo:disabled {
    color: #fff;
    background-color: #b057bf;
    border-color: #b057bf;
}

.btn-indigo:not(:disabled):not(.disabled):active,
.btn-indigo:not(:disabled):not(.disabled).active {
    color: #fff;
    background-color: #b057bf;
    border-color: #b057bf;
}

.show>.btn-indigo.dropdown-toggle {
    color: #fff;
    background-color: #b057bf;
    border-color: #b057bf;
}

.btn-indigo:not(:disabled):not(.disabled):active:focus,
.btn-indigo:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(172, 80, 187, 0.5);
}

.show>.btn-indigo.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(172, 80, 187, 0.5);
}

.btn-purple {
    color: #fff;
    background-color: #ad4ffa;
    border-color: #643ab0;
}

.btn-purple:hover {
    color: #fff;
    background-color: #5e37a6;
    border-color: #4e2d89;
}

.btn-purple:focus,
.btn-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(123, 87, 188, 0.5);
}

.btn-purple.disabled,
.btn-purple:disabled {
    color: #fff;
    background-color: #ad4ffa;
    border-color: #643ab0;
}

.btn-purple:not(:disabled):not(.disabled):active,
.btn-purple:not(:disabled):not(.disabled).active {
    color: #fff;
    background-color: #59339d;
    border-color: #482a80;
}

.show>.btn-purple.dropdown-toggle {
    color: #fff;
    background-color: #59339d;
    border-color: #482a80;
}

.btn-purple:not(:disabled):not(.disabled):active:focus,
.btn-purple:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(123, 87, 188, 0.5);
}

.show>.btn-purple.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(123, 87, 188, 0.5);
}

.btn-pink {
    color: #fff;
    background-color: #f754fa;
    border-color: #cc30ce;
}

.btn-pink:hover {
    color: #fff;
    background-color: #cc30ce;
    border-color: #cc30ce;
}

.btn-pink:focus,
.btn-pink.focus {
    box-shadow: 0 0 0 0.2rem rgba(221, 38, 127, 0.5);
}

.btn-pink.disabled,
.btn-pink:disabled {
    color: #fff;
    background-color: #f754fa;
    border-color: #d80069;
}

.btn-pink:not(:disabled):not(.disabled):active,
.btn-pink:not(:disabled):not(.disabled).active {
    color: #fff;
    background-color: #be005c;
    border-color: #98004a;
}

.show>.btn-pink.dropdown-toggle {
    color: #fff;
    background-color: #be005c;
    border-color: #98004a;
}

.btn-pink:not(:disabled):not(.disabled):active:focus,
.btn-pink:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(221, 38, 127, 0.5);
}

.show>.btn-pink.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(221, 38, 127, 0.5);
}

.btn-gray-500 {
    color: #323251;
    background-color: #949eb7;
    border-color: #8896af;
}

.btn-gray-500:hover {
    color: #fff;
    background-color: #808faa;
    border-color: #697b9a;
}

.btn-gray-500:focus,
.btn-gray-500.focus {
    box-shadow: 0 0 0 0.2rem rgba(120, 133, 158, 0.5);
}

.btn-gray-500.disabled,
.btn-gray-500:disabled {
    color: #323251;
    background-color: #949eb7;
    border-color: #8896af;
}

.btn-gray-500:not(:disabled):not(.disabled):active,
.btn-gray-500:not(:disabled):not(.disabled).active {
    color: #fff;
    background-color: #7988a4;
    border-color: #637493;
}

.show>.btn-gray-500.dropdown-toggle {
    color: #fff;
    background-color: #7988a4;
    border-color: #637493;
}

.btn-gray-500:not(:disabled):not(.disabled):active:focus,
.btn-gray-500:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(120, 133, 158, 0.5);
}

.show>.btn-gray-500.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(120, 133, 158, 0.5);
}

.btn-gray-700 {
    color: #fff;
    background-color: #4d5875;
    border-color: #4f5c73;
}

.btn-gray-700:hover {
    color: #fff;
    background-color: #49566b;
    border-color: #3a4455;
}

.btn-gray-700:focus,
.btn-gray-700.focus {
    box-shadow: 0 0 0 0.2rem rgba(105, 116, 136, 0.5);
}

.btn-gray-700.disabled,
.btn-gray-700:disabled {
    color: #fff;
    background-color: #4d5875;
    border-color: #4f5c73;
}

.btn-gray-700:not(:disabled):not(.disabled):active,
.btn-gray-700:not(:disabled):not(.disabled).active {
    color: #fff;
    background-color: #445064;
    border-color: #353e4d;
}

.show>.btn-gray-700.dropdown-toggle {
    color: #fff;
    background-color: #445064;
    border-color: #353e4d;
}

.btn-gray-700:not(:disabled):not(.disabled):active:focus,
.btn-gray-700:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(105, 116, 136, 0.5);
}

.show>.btn-gray-700.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(105, 116, 136, 0.5);
}

.btn-outline-indigo {
    border-color: #b057bf;
    color: #b057bf;
}

.btn-outline-indigo:hover,
.btn-outline-indigo:focus {
    background-color: #b057bf;
}

/* ###### 5.1 Buttons  ###### */
.btn-main-primary {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-main-primary:hover {
    color: #fff;
    background-color: #6056f5 !important;
    border-color: #8077f6;
}

.btn-main-primary:focus,
.btn-main-primary.focus {
    box-shadow: 0 0 0 0.15rem rgba(var(--primary), 0.5);
}

.btn-main-primary.disabled,
.btn-main-primary:disabled {
    color: #fff;
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
}

.btn-main-primary:not(:disabled):not(.disabled):active,
.btn-main-primary:not(:disabled):not(.disabled).active {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}

.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem var(--primary-25);
}

.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--primary), 0.2);
}

.btn-main-secondary {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-main-secondary:hover {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-main-secondary:focus,
.btn-main-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(119, 210, 191, 0.5);
}

.btn-main-secondary.disabled,
.btn-main-secondary:disabled {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-main-secondary:not(:disabled):not(.disabled):active,
.btn-main-secondary:not(:disabled):not(.disabled).active {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}

.show>.btn-main-secondary.dropdown-toggle {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-main-secondary:not(:disabled):not(.disabled):active:focus,
.btn-main-secondary:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(60, 109, 255, 0.5);
}

.show>.btn-main-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(60, 109, 255, 0.5);
}

.btn-main-secondary {
    color: #fff;
    background-color: var(--primary);
    border-color: #1a53ff;
}

.btn-main-secondary:hover {
    color: #fff;
    background-color: #0d49ff;
    border-color: #8485fb;
}

.btn-main-secondary:focus,
.btn-main-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(60, 109, 255, 0.5);
}

.btn-main-secondary.disabled,
.btn-main-secondary:disabled {
    color: #fff;
    background-color: var(--primary);
    border-color: #1a53ff;
}

.btn-main-secondary:not(:disabled):not(.disabled):active,
.btn-main-secondary:not(:disabled):not(.disabled).active {
    color: #fff;
    background-color: #0040ff;
    border-color: #0036d9;
}

.show>.btn-main-secondary.dropdown-toggle {
    color: #fff;
    background-color: #0040ff;
    border-color: #0036d9;
}

.btn-main-secondary:not(:disabled):not(.disabled):active:focus,
.btn-main-secondary:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(60, 109, 255, 0.5);
}

.show>.btn-main-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(60, 109, 255, 0.5);
}

.btn-rounded {
    border-radius: 50px;
}

.btn-with-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
}

.btn-with-icon i {
    line-height: 0;
    font-size: 20px;
    margin-right: 5px;
}

.btn-with-icon i.typcn {
    line-height: 0.6;
}

.btn-with-icon i.fas {
    font-size: 15px;
}

.btn-with-icon i.icon {
    font-size: 18px;
}

.btn-icon {
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 4px;
}

.btn-icon.btn-icon-sm {
    min-width: 30px;
    max-width: 30px;
    min-height: 30px;
    max-height: 30px;
}

.btn-icon span {
    margin: 0;
    font-size: 1.5rem;
}

.btn-icon.edit {
    background-color: var(--neutral-50);
    color: var(--info);
}

.btn-icon.edit:hover {
    background-color: var(--info);
    color: white;
}

.btn-icon.delete {
    background-color: var(--error-10);
    color: var(--error-75);
}

.btn-icon.delete:hover {
    background-color: var(--error-75);
    color: white;
}

.btn-icon.view {
    background-color: var(--secondary-25);
    color: var(--secondary);
}

.btn-icon.view:hover {
    background-color: var(--secondary);
    color: white;
}

.btn-icon.btn-icon-link.edit {
    background-color: #fff;
    color: var(--info);
}

.btn-icon.btn-icon-link.delete {
    background-color: #fff;
    color: var(--error-75);
}

.btn-icon.btn-icon-link.view {
    background-color: #fff;
    color: var(--secondary);
}

.btn-icon-list {
    display: flex;
    align-items: center;
}

.btn-icon-list .btn+.btn,
.btn-icon-list .sp-container button+.btn {
    margin-left: 5px;
}

.sp-container .btn-icon-list button+.btn,
.btn-icon-list .sp-container .btn+button,
.sp-container .btn-icon-list .btn+button,
.btn-icon-list .sp-container button+button,
.sp-container .btn-icon-list button+button {
    margin-left: 5px;
}

@media (max-width: 991px) {
    .btn.btn-default.nav-link {
        margin-top: 0;
    }
}

.nav-link#bs-example-navbar-collapse-1 {
    padding: 0rem 0.3rem;
}

/* ###### 4.8 button-light ###### */
.btn-warning-light {
    color: #FDAC41;
    background-color: rgba(253, 172, 65, 0.2);
    border-color: rgba(253, 172, 65, 0.2);
}

.btn-warning-light:hover {
    color: #fff;
    background-color: #FDAC41;
    border-color: #FDAC41;
}

.btn-warning-light.disabled,
.btn-warning-light:disabled {
    color: #323251;
    background-color: #FDAC41;
    border-color: #FDAC41;
}

.btn-warning-light:not(:disabled):not(.disabled):active,
.btn-warning-light:not(:disabled):not(.disabled).active {
    color: #323251;
    background-color: #FDAC41;
    border-color: #FDAC41;
}

.show>.btn-warning-light.dropdown-toggle {
    color: #323251;
    background-color: #FDAC41;
    border-color: #FDAC41;
}

.btn-warning-light:not(:disabled):not(.disabled):active:focus,
.btn-warning-light:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem #ffc793;
}

.show>.btn-warning-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #ffc793;
}

.btn-primary-gradient {
    color: #fff;
    background: linear-gradient(to right, var(--primary) 0%, var(--primary-25) 100%);
    border: 1px solid var(--primary);
}

.btn-primary-gradient:hover {
    color: #fff !important;
}

.btn-danger-gradient {
    color: #fff;
    background: linear-gradient(to right, var(--secondary) 0%, rgba(var(--secondary), 0.5) 100%);
    border: 1px solid #f34343;
}

.btn-danger-gradient:hover {
    color: #fff !important;
}

.btn-pink-gradient {
    color: #fff;
    background: linear-gradient(to right, #f754fa 0%, rgba(247, 84, 250, 0.5) 100%);
    border: 1px solid #f754fa;
}

.btn-pink-gradient:hover {
    color: #fff !important;
}

.btn-purple-gradient {
    color: #fff;
    background: linear-gradient(to right, #ad4ffa 0%, rgba(173, 79, 250, 0.5) 100%);
    border: 1px solid #ad4ffa;
}

.btn-purple-gradient:hover {
    color: #fff !important;
}

.btn-warning-gradient {
    color: #fff;
    background: linear-gradient(to right, var(--warning) 0%, rgba(var(--warning), 0.5) 100%);
    border: 1px solid var(--warning);
}

.btn-warning-gradient:hover {
    color: #fff !important;
}

.btn-success-gradient {
    color: #fff;
    background: linear-gradient(to right, var(--success) 0%, rgba(var(--success), 0.5) 100%);
    border: 1px solid var(--success);
}

.btn-success-gradient:hover {
    color: #fff !important;
}

.btn-info-gradient {
    color: #fff;
    background: linear-gradient(to right, var(--info) 0%, rgba(var(--info), 0.5) 100%);
    border: 1px solid var(--info);
}

.btn-info-gradient:hover {
    color: #fff !important;
}

.btn-light-gradient {
    color: #000;
    background: linear-gradient(120deg, #cdd2de 0%, #f9f9fb 100%);
    border: 1px solid #cdd2de;
}

.btn-light-gradient:hover {
    color: #000;
    background: linear-gradient(120deg, #c1c7d6 0%, #e0e5f1 100%);
    border-color: #cdd2de;
}

.btn-light-gradient:focus,
.btn-light-gradient.focus {
    background: linear-gradient(120deg, #c1c7d6 0%, #e0e5f1 100%);
}

.btn-dark-gradient {
    color: #fff;
    background: linear-gradient(60deg, #29323c 0%, #485563 100%);
    border: 1px solid #29323c;
}

.btn-dark-gradient:hover {
    color: #fff;
    background: linear-gradient(60deg, #212931 0%, #424f5d 100%);
    border-color: #29323c;
}

.btn-dark-gradient:focus,
.btn-dark-gradient.focus {
    background: linear-gradient(60deg, #212931 0%, #424f5d 100%);
}

.btn-secondary-gradient {
    color: #fff;
    background: linear-gradient(to right, var(--secondary) 0%, rgba(var(--secondary), 0.5) 100%);
    border: 1px solid var(--secondary);
}

.btn-secondary-gradient:hover {
    color: #fff !important;
}

.btn-primary.focus,
.btn-primary:focus {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-warning.focus,
.btn-warning:focus {
    color: #fff;
    background-color: var(--warning);
    border-color: var(--warning);
}

.btn-square {
    border-radius: 0;
}

/* .btn-list>.btn:not(:last-child), .btn-list>.dropdown:not(:last-child) {
    margin-right: .4rem;
} */
.btn-list>.btn,
.btn-list>.dropdown {
    margin-bottom: 0.3rem;
}

.row>* {
    width: auto;
}

.btn-outline-light:hover {
    border-color: #ededf5;
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
}

.nav.panel-tabs .nav-item:focus-visible {
    outline: none !important;
}

.tabs-style-1 .main-nav-line .nav-link.active {
    /* color: #242f48; */
    border: 1px solid;
    border-color: #ededf5 #ededf5 #fff;
}

.btn-animation .btn-loaders:after {
    content: "";
    -webkit-animation: loader 500ms infinite linear;
    animation: loader 500ms infinite linear;
    border: 2px solid #fff;
    border-radius: 50%;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    display: block;
    height: 1.4em;
    width: 1.4em;
    position: absolute;
    right: 10px !important;
    top: calc(50% - 0.7em);
    -webkit-transform-origin: center;
    transform-origin: center;
    position: absolute !important;
}

.btn-animation .btn-spiner:after {
    content: "";
    -webkit-animation: sk-bounce 1.2s infinite ease-in-out;
    animation: sk-bounce 1.2s infinite ease-in-out;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
    border-radius: 50%;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    display: block;
    height: 0.5em;
    width: 0.5em;
    position: absolute;
    right: 12px !important;
    top: calc(50% - 0.7em);
    -webkit-transform-origin: center;
    transform-origin: center;
    position: absolute !important;
    background: #ffffff;
    width: 14%;
    height: 45%;
}

.btn-animation .btn-loaders {
    color: #fff !important;
    pointer-events: none;
    position: relative;
}

.btn-animation .btn-spiner {
    color: #fff !important;
    pointer-events: none;
    position: relative;
}

.btn-animation .loading {
    margin-right: 30px;
}

@-webkit-keyframes loader {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.btn-primary-light {
    color: var(--primary);
    background-color: var(--primary-50);
    border-color: var(--primary-50);
}

.btn-primary-light:hover {
    color: #fff;
    background-color: var(--primary) !important;
    border-color: var(--primary);
}

.btn-primary-light:focus,
.btn-primary-light.focus {
    box-shadow: 0 0 0 0.2rem var(--primary-50);
}

.btn-secondary-light {
    color: var(--secondary);
    background-color: var(--secondary-10);
    border-color: var(--secondary-50);
}

.btn-secondary-light:hover {
    color: #fff;
    background-color: var(--secondary) !important;
    border-color: var(--secondary);
}

.btn-secondary-light:focus,
.btn-secondary-light.focus {
    box-shadow: 0 0 0 0.2rem var(--secondary-25);
}

.btn-success-light {
    color: #1a9c86;
    background-color: rgba(26, 156, 134, 0.2);
    border-color: rgba(26, 156, 134, 0.2);
}

.btn-success-light:hover {
    color: #fff;
    background-color: var(--success) !important;
    border-color: var(--success);
}

.btn-success-light:focus,
.btn-success-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(26, 156, 134, 0.2);
}

.btn-danger-light {
    color: #f34343 !important;
    background-color: rgba(243, 67, 67, 0.2);
    border-color: rgba(243, 67, 67, 0.2);
}

.btn-danger-light:hover {
    color: #fff !important;
    background-color: #f34343 !important;
    border-color: #f34343;
}

.btn-danger-light:focus,
.btn-danger-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 67, 67, 0.2);
}

.btn-info-light {
    color: #4ec2f0;
    background-color: rgba(25, 192, 192, 0.2);
    border-color: rgba(25, 192, 192, 0.2);
}

.btn-info-light:hover {
    color: #fff !important;
    background-color: var(--info) !important;
    border-color: var(--info);
}

.btn-info-light:focus,
.btn-info-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(25, 192, 192, 0.2);
}

.btn-dark-light {
    color: #383853;
    background-color: rgba(56, 56, 83, 0.1411764706);
    border-color: #c8c8da;
}

.btn-dark-light:hover {
    color: #fff !important;
    background-color: #3b4863 !important;
    border-color: rgba(200, 200, 218, 0.2);
}

.btn-dark-light:focus,
.btn-dark-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(88, 99, 122, 0.2);
}

/* .btn-check:active+.btn-outline-primary, .btn-check:checked+.btn-outline-primary, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show, .btn-outline-primary:active {
    color: #fff !important;
    background-color:  $primary;
    border-color:  $primary;
}
.btn-check:active+.btn-outline-primary:focus, .btn-check:checked+.btn-outline-primary:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus, .btn-outline-primary:active:focus{
  box-shadow: 0 0 0 0.2rem rgba($primary,0.5) !important;
}
.btn-check:focus+.btn-outline-primary, .btn-outline-primary:focus {
   box-shadow: none !important;
}
.btn-check:active+.btn-outline-warning, .btn-check:checked+.btn-outline-warning, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show, .btn-outline-warning:active {
    color: #fff !important;
    background-color: #ffc107;
    border-color: #ffc107;
}
.btn-check:active+.btn-outline-info, .btn-check:checked+.btn-outline-info, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show, .btn-outline-info:active {
    color: #fff !important;
    background-color: #0dcaf0;
    border-color: #0dcaf0;
}
.btn-check:active+.btn-outline-danger, .btn-check:checked+.btn-outline-danger, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show, .btn-outline-danger:active {
    color: #fff !important;
    background-color: #dc3545;
    border-color: #dc3545;
} */
.simple-button {
    border: none;
    background-color: unset;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background: #fff !important;
    background-clip: border-box;
    border-radius: 5px;
    border: 0px solid transparent;
    margin-bottom: 1.3rem;
    box-shadow: 1px 1px 7px rgba(154, 154, 204, 0.1);
}

.card>hr {
    margin-right: 0;
    margin-left: 0;
}

.card>.list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
}

.card-title {
    margin-bottom: 0.75rem;
}

.card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link:hover {
    text-decoration: none;
}

.card-link+.card-link {
    margin-left: 1.25rem;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
    border-radius: 2px 2px 0 0;
}

.card-header+.list-group .list-group-item:first-child {
    border-top: 0;
}

.card-footer {
    padding: 1.25rem 1.25rem;
    background-color: rgb(255, 255, 255);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
    border-radius: 0 0 2px 2px;
}

.card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
}

.card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
    overflow: auto;
}

.card-img {
    width: 100%;
    border-radius: 2px;
}

.card-img-top {
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.card-img-bottom {
    width: 100%;
    border-bottom-right-radius: 9px;
    border-bottom-left-radius: 9px;
}

.card-deck {
    display: flex;
    flex-direction: column;
}

.card-deck .card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-deck {
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;
    }
}

.card-group {
    display: flex;
    flex-direction: column;
}

.card-group>.card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-group {
        flex-flow: row wrap;
    }

    .card-group>.card {
        flex: 1 0 0%;
        margin-bottom: 0;
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0;
    }

    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .card-group>.card:not(:last-child) .card-img-top,
    .card-group>.card:not(:last-child) .card-header {
        border-top-right-radius: 0;
    }

    .card-group>.card:not(:last-child) .card-img-bottom,
    .card-group>.card:not(:last-child) .card-footer {
        border-bottom-right-radius: 0;
    }

    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .card-group>.card:not(:first-child) .card-img-top,
    .card-group>.card:not(:first-child) .card-header {
        border-top-left-radius: 0;
    }

    .card-group>.card:not(:first-child) .card-img-bottom,
    .card-group>.card:not(:first-child) .card-footer {
        border-bottom-left-radius: 0;
    }
}

.card-columns .card {
    margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
    .card-columns {
        column-count: 3;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }

    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}

.card {
    border-radius: 5px;
}

.card.text-white .card-title,
.card.tx-white .card-title {
    color: #fff;
}

.card-header,
.card-footer {
    position: relative;
    border-color: #ededf5;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 1.25rem;
}

.card-header {
    border-bottom: 0;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}

.card-header:first-child {
    border-radius: 0;
}

.card-footer {
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
}

.card-header-tab {
    border-bottom: 0;
    padding: 0;
}

.card-title {
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
}

.card-item-desc .card-item-desc-1 dt,
dd {
    display: inline-block;
}

.card-item-desc .card-item-desc-1 dt {
    font-weight: 500;
    font-size: 13px;
}

.card-item-desc .card-item-desc-1 dd {
    font-size: 13px;
    color: #7987a1;
    margin-bottom: 0;
}

.card-item-desc dl {
    margin-bottom: 0;
}

.card .card-header .card-header-right .card-option {
    width: 35px;
    height: 20px;
    overflow: hidden;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.card .card-header .card-header-right .card-option li {
    display: inline-block;
}

.card-category {
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    letter-spacing: 0.05em;
    margin: 0 0 0.5rem;
    background: #f9f9fb;
    padding: 7px 0;
    border-radius: 4px;
}

.card-dashboard-audience-metrics .flot-chart .flot-x-axis>div {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    color: #7987a1;
    display: none;
}

.card-minimal-two .nav-pills .nav-link.active {
    border-radius: 2px;
    background-color: var(--primary);
}

.card-minimal-two .nav-link {
    display: block;
    padding: 8px 30px;
    margin: 2px;
    background-color: #f0f0f8;
}

.card-body-top {
    top: 63px;
    left: -5px;
    position: absolute;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    z-index: 10;
}

.card-body-top a {
    color: #323251;
    font-weight: 700;
    width: 20px;
    text-align: right;
    display: inline-block;
}

.card-minimal-four .card-footer .nav-link {
    flex: 1;
    display: block;
    text-align: center;
    background-color: #f0f0f8;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
}

.card-progress {
    display: flex;
    align-items: center;
}

.card-progress .progress {
    height: 3px;
    flex: 1;
    margin: 0 5px;
}

.card.card-primary {
    border-top: 2px solid var(--primary) !important;
}

.card.card-secondary {
    border-top: 2px solid var(--secondary) !important;
}

.card.card-success {
    border-top: 2px solid #22e840 !important;
}

.card.card-danger {
    border-top: 2px solid #f34343 !important;
}

.card.card-warning {
    border-top: 2px solid #ffb209 !important;
}

.card.card-info {
    border-top: 2px solid #01b8ff !important;
}

.card.card-purple {
    border-top: 2px solid #ad4ffa !important;
}

.card.card-dark {
    border-top: 2px solid #343a40 !important;
}

.card-img-top.card-img-bottom {
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
}

.card-category1 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.05em;
    margin: 0 0 0.5rem;
    background: transparent;
    padding: 0;
    border-radius: 4px;
    color: #383853;
}

.Choose1 {
    border: #ededf5 solid 2px;
    padding: 8px 19px;
    border-radius: 5px;
}

.pricing-card:hover .card-category.style-1 {
    background: var(--primary);
    color: #eee;
    transition: all 0.5s ease 0s;
}

.pricing-card:hover .Choose1 {
    background: var(--primary);
    color: #eee;
    transition: all 0.5s ease 0s;
    padding: 8px 19px;
}

.error-bg1 {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.error-bg1::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
}

.card-sigin {
    background: #fff;
    padding: 50px;
    border-radius: 15px;
}

.square-box div:nth-child(1) {
    top: 12%;
    left: 42%;
    animation: animate 10s linear infinite;
}

.square-box div {
    position: absolute;
    width: 50px;
    height: 50px;
    background: transparent;
    border: 5px solid rgba(255, 255, 255, 0.3);
    border-radius: 35px;
}

.square-box div:nth-child(2) {
    top: 70%;
    left: 50%;
    animation: animate 7s linear infinite;
}

.square-box div:nth-child(4) {
    top: 20%;
    left: 60%;
    animation: animate 10s linear infinite;
}

.square-box div:nth-child(3) {
    top: 17%;
    left: 6%;
    animation: animate 9s linear infinite;
}

.square-box div:nth-child(5) {
    top: 88%;
    left: 10%;
    animation: animate 6s linear infinite;
}

.square-box div:nth-child(6) {
    top: 80%;
    left: 70%;
    animation: animate 12s linear infinite;
}

.square-box div:nth-child(7) {
    top: 60%;
    left: 80%;
    animation: animate 15s linear infinite;
}

.square-box div:nth-child(8) {
    top: 32%;
    left: 25%;
    animation: animate 16s linear infinite;
}

.square-box div:nth-child(9) {
    top: 20%;
    left: 80%;
    animation: animate 10s linear infinite;
}

.square-box div:nth-child(10) {
    top: 40%;
    left: 72%;
    animation: animate 5s linear infinite;
}

.square-box div:nth-child(11) {
    top: 50%;
    left: 30%;
    animation: animate 6s linear infinite;
}

.square-box div:nth-child(12) {
    top: 65%;
    left: 16%;
    animation: animate 9s linear infinite;
}

.square-box div:nth-child(13) {
    top: 45%;
    left: 3%;
    animation: animate 14s linear infinite;
}

.square-box div:nth-child(14) {
    top: 47%;
    left: 55%;
    animation: animate 3s linear infinite;
}

.square-box div:nth-child(15) {
    top: 79%;
    left: 90%;
    animation: animate 8s linear infinite;
}

@-webkit-keyframes animate {
    0% {
        -webkit-transform: scale(0) translateY(0) rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.3) translateY(-90px) rotate(360deg);
        opacity: 0;
    }
}

@media (max-width: 767px) {
    .card-sigin-main {
        padding: 25px;
    }
}

@media (max-width: 767px) {
    .card-sigin {
        padding: 25px;
    }
}

.card-collapsed> :not(.card-header):not(.card-status) {
    display: none;
}

.card-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    margin: 0;
}

.card-title:before {
    content: "";
    width: 3px;
    height: 16px;
    background: var(--primary);
    position: absolute;
    left: 11px;
    display: block;
    top: 21px;
}

.card-title2:before {
    content: "";
    background: #f32b71;
}

.card-title3:before {
    content: "";
    background: #1590be;
}

.card-img-top-1 .card-title:before {
    display: none;
}

.card-img-left {
    width: 100%;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.custom-title:before {
    content: "";
    width: 3px;
    height: 16px;
    background: #fff;
    position: absolute;
    left: 11px;
    display: block;
    top: 19px;
}

.primary-custom-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    overflow: visible !important;
    word-wrap: break-word;
    overflow: hidden;
    background-clip: border-box;
    border-radius: 5px;
    margin-bottom: 1.3rem;
    box-shadow: 0px 4px 7px rgba(154, 154, 204, 0.1);
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center;
}

.primary-custom-card:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.2;
    left: 0;
    right: 0;
    border-radius: 5px;
    top: 0;
    bottom: 0;
}

.primary-custom-card1 {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    overflow: visible !important;
    word-wrap: break-word;
    overflow: hidden;
    background-clip: border-box;
    border-radius: 5px;
    margin-bottom: 1.3rem;
    box-shadow: 0px 4px 7px rgba(154, 154, 204, 0.1);
    background-image: url(../../assets/img/png/chatbg.png) !important;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center;
}

.primary-custom-card1:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.2;
    left: 0;
    right: 0;
    border-radius: 5px;
    top: 0;
    bottom: 0;
}

.bg-primary-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background: var(--primary) !important;
    background-clip: border-box;
    border-radius: 5px;
    margin-bottom: 1.3rem;
    box-shadow: 0px 4px 7px rgba(154, 154, 204, 0.1);
    color: #fff !important;
}

.bg-primary-card .card-header {
    background-color: var(--primary) !important;
}

.bg-primary-card .card-header .card-title:before {
    background: #fff;
    color: #fff !important;
}

/* Thumb Carousel */
.carousel-slider #carousel {
    margin: 0;
}

.carousel-slider #thumbcarousel {
    margin: 10px 0 0 0;
    padding: 0;
}

.carousel-slider #thumbcarousel .carousel-item {
    text-align: center;
}

.carousel-slider #thumbcarousel .carousel-item .thumb {
    width: 100%;
    margin: 0px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    max-width: 100px;
}

.carousel-slider .carousel-item img {
    width: 100%;
    border-radius: 3px;
}

.carousel-control:active,
.carousel-control:focus,
.carousel-control:hover {
    color: #333;
}

#basicSlider img {
    width: 100%;
}

#thumbcarousel .carousel-control-prev {
    left: 0;
    background: rgba(0, 0, 0, 0.8);
}

#thumbcarousel .carousel-control-next {
    right: 0;
    background: rgba(0, 0, 0, 0.8);
}

.owl-carousel button.owl-dot {
    background: rgba(105, 99, 255, 0.3);
    opacity: 7;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
}

.owl-nav button:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #2098d1;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.carousel {
    position: relative;
}

.carousel.pointer-event {
    touch-action: pan-y;
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 5px;
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: "";
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
}

.carousel-item.active {
    display: block;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-item {
        transition: none;
    }
}

.carousel-item-next,
.carousel-item-prev {
    display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
    transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
    transform: translateX(-100%);
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none;
}

.carousel-fade .carousel-item.active {
    z-index: 0;
    opacity: 1;
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: 0s 0.6s opacity;
}

@media (prefers-reduced-motion: reduce) {

    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
        transition: none;
    }
}

.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    top: 22px;
    bottom: 0;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    color: #fff;
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.15s ease;
    border-radius: 35px;
}

@media (prefers-reduced-motion: reduce) {

    .carousel-control-prev,
    .carousel-control-next {
        transition: none;
    }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
}

.carousel-control-next:hover,
.carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
}

.carousel-indicators .active {
    opacity: 1;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-indicators li {
        transition: none;
    }
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-image: none;
    display: block;
    width: auto;
    height: auto;
}

.carousel-control-prev-icon svg,
.carousel-control-next-icon svg {
    width: 48px;
    height: 48px;
}

.carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 100%;
}

.dashboard-carousel .btn-icons {
    display: inline-block;
    background: #eff2f6 !important;
    background: no-repeat 50%/100% 100%;
    text-align: center;
    line-height: 15px;
    margin: 0 auto;
    align-items: center;
    padding: 0.375rem 0.75rem;
    min-height: inherit;
}

.dashboard-carousel .btn-icons i {
    font-size: 15px;
    margin: 0 auto;
    text-align: center;
}

.carousel-indicators [data-bs-target] {
    width: 8px;
    height: 8px;
}

#carousel .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.8;
    font-size: 30px;
}

#carousel .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.8;
    font-size: 30px;
}

#carouselExample2 .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.8;
    font-size: 30px;
}

#carouselExample2 .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.8;
    font-size: 30px;
}

html {
    font-family: "Poppins", sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-size: 0.81568rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4a4a69;
    text-align: left;
    background-color: rgba(234, 243, 241, 0.2784313725);
}

[tabindex="-1"]:focus {
    outline: 0 !important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    text-decoration-skip-ink: none;
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

a {
    color: #4a4a69;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #4a4a69;
    text-decoration: none;
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none;
    outline: 0;
}

pre,
code,
kbd,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em;
}

pre {
    margin-top: 0;
    margin-bottom: 0rem;
}

figure {
    margin: 0 0 1rem;
}

img {
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

table {
    border-collapse: collapse;
}

caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #7987a1;
    text-align: left;
    caption-side: bottom;
}

th {
    text-align: inherit;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

button {
    border-radius: 0;
}

button:focus {
    outline: none !important;
    outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button {
    text-transform: none;
}

select {
    text-transform: none;
    word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
    -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
    cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type=radio],
input[type=checkbox] {
    box-sizing: border-box;
    padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
    -webkit-appearance: listbox;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto;
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
    cursor: pointer;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

h1,
.h1 {
    font-size: 2.1875rem;
}

h2,
.h2 {
    font-size: 1.75rem;
}

h3,
.h3 {
    font-size: 1.53125rem;
}

h4,
.h4 {
    font-size: 1.3125rem;
}

h5,
.h5 {
    font-size: 1.09375rem;
}

h6,
.h6 {
    font-size: 0.875rem;
}

.lead {
    font-size: 1.09375rem;
    font-weight: 300;
}

.display-1 {
    font-size: calc(1.625rem + 4.5vw);
    font-weight: 300;
    line-height: 1.2;
}

.display-2 {
    font-size: calc(1.575rem + 3.9vw);
    font-weight: 300;
    line-height: 1.2;
}

.display-3 {
    font-size: calc(1.525rem + 3.3vw);
    font-weight: 300;
    line-height: 1.2;
}

.display-41,
.display-42,
.display-43,
.display-44 {
    font-size: 3rem;
    font-weight: 400;
    line-height: 1.2;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
    font-size: 80%;
    font-weight: 400;
    opacity: 0.6;
}

mark,
.mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}

.list-unstyled,
.list-inline {
    padding-left: 0;
    list-style: none;
}

.list-inline-item {
    display: inline-block;
}

.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}

.initialism {
    font-size: 90%;
    text-transform: uppercase;
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.09375rem;
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #7987a1;
}

.blockquote-footer::before {
    content: "— ";
}

.figure {
    display: inline-block;
}

.figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
}

.figure-caption {
    font-size: 90%;
    color: #7987a1;
}

code {
    font-size: 13px;
    color: #212529;
    word-break: break-word;
}

a>code {
    color: inherit;
}

kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #323251;
    border-radius: 0.2rem;
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
}

pre {
    display: block;
    font-size: 87.5%;
    color: #323251;
}

pre code {
    font-size: inherit;
    color: #2d91fb;
    word-break: normal;
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
}

.container,
.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }

    .responsive-logo {
        display: none;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

.col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}

.col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}

.col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}

.col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

.order-first {
    order: -1;
}

.order-last {
    order: 13;
}

.order-0 {
    order: 0;
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.order-3 {
    order: 3;
}

.order-4 {
    order: 4;
}

.order-5 {
    order: 5;
}

.order-6 {
    order: 6;
}

.order-7 {
    order: 7;
}

.order-8 {
    order: 8;
}

.order-9 {
    order: 9;
}

.order-10 {
    order: 10;
}

.order-11 {
    order: 11;
}

.order-12 {
    order: 12;
}

.offset-1 {
    margin-left: 8.33333%;
}

.offset-2 {
    margin-left: 16.66667%;
}

.offset-3 {
    margin-left: 25%;
}

.offset-4 {
    margin-left: 33.33333%;
}

.offset-5 {
    margin-left: 41.66667%;
}

.offset-6 {
    margin-left: 50%;
}

.offset-7 {
    margin-left: 58.33333%;
}

.offset-8 {
    margin-left: 66.66667%;
}

.offset-9 {
    margin-left: 75%;
}

.offset-10 {
    margin-left: 83.33333%;
}

.offset-11 {
    margin-left: 91.66667%;
}

@media (min-width: 576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-sm-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-sm-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-sm-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-sm-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-sm-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-sm-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-sm-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-sm-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-sm-first {
        order: -1;
    }

    .order-sm-last {
        order: 13;
    }

    .order-sm-0 {
        order: 0;
    }

    .order-sm-1 {
        order: 1;
    }

    .order-sm-2 {
        order: 2;
    }

    .order-sm-3 {
        order: 3;
    }

    .order-sm-4 {
        order: 4;
    }

    .order-sm-5 {
        order: 5;
    }

    .order-sm-6 {
        order: 6;
    }

    .order-sm-7 {
        order: 7;
    }

    .order-sm-8 {
        order: 8;
    }

    .order-sm-9 {
        order: 9;
    }

    .order-sm-10 {
        order: 10;
    }

    .order-sm-11 {
        order: 11;
    }

    .order-sm-12 {
        order: 12;
    }

    .offset-sm-0 {
        margin-left: 0;
    }

    .offset-sm-1 {
        margin-left: 8.33333%;
    }

    .offset-sm-2 {
        margin-left: 16.66667%;
    }

    .offset-sm-3 {
        margin-left: 25%;
    }

    .offset-sm-4 {
        margin-left: 33.33333%;
    }

    .offset-sm-5 {
        margin-left: 41.66667%;
    }

    .offset-sm-6 {
        margin-left: 50%;
    }

    .offset-sm-7 {
        margin-left: 58.33333%;
    }

    .offset-sm-8 {
        margin-left: 66.66667%;
    }

    .offset-sm-9 {
        margin-left: 75%;
    }

    .offset-sm-10 {
        margin-left: 83.33333%;
    }

    .offset-sm-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-md-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-md-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-md-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-md-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-md-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-md-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-md-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-md-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-md-first {
        order: -1;
    }

    .order-md-last {
        order: 13;
    }

    .order-md-0 {
        order: 0;
    }

    .order-md-1 {
        order: 1;
    }

    .order-md-2 {
        order: 2;
    }

    .order-md-3 {
        order: 3;
    }

    .order-md-4 {
        order: 4;
    }

    .order-md-5 {
        order: 5;
    }

    .order-md-6 {
        order: 6;
    }

    .order-md-7 {
        order: 7;
    }

    .order-md-8 {
        order: 8;
    }

    .order-md-9 {
        order: 9;
    }

    .order-md-10 {
        order: 10;
    }

    .order-md-11 {
        order: 11;
    }

    .order-md-12 {
        order: 12;
    }

    .offset-md-0 {
        margin-left: 0;
    }

    .offset-md-1 {
        margin-left: 8.33333%;
    }

    .offset-md-2 {
        margin-left: 16.66667%;
    }

    .offset-md-3 {
        margin-left: 25%;
    }

    .offset-md-4 {
        margin-left: 33.33333%;
    }

    .offset-md-5 {
        margin-left: 41.66667%;
    }

    .offset-md-6 {
        margin-left: 50%;
    }

    .offset-md-7 {
        margin-left: 58.33333%;
    }

    .offset-md-8 {
        margin-left: 66.66667%;
    }

    .offset-md-9 {
        margin-left: 75%;
    }

    .offset-md-10 {
        margin-left: 83.33333%;
    }

    .offset-md-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-lg-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-lg-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-lg-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-lg-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-lg-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-lg-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-lg-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-lg-first {
        order: -1;
    }

    .order-lg-last {
        order: 13;
    }

    .order-lg-0 {
        order: 0;
    }

    .order-lg-1 {
        order: 1;
    }

    .order-lg-2 {
        order: 2;
    }

    .order-lg-3 {
        order: 3;
    }

    .order-lg-4 {
        order: 4;
    }

    .order-lg-5 {
        order: 5;
    }

    .order-lg-6 {
        order: 6;
    }

    .order-lg-7 {
        order: 7;
    }

    .order-lg-8 {
        order: 8;
    }

    .order-lg-9 {
        order: 9;
    }

    .order-lg-10 {
        order: 10;
    }

    .order-lg-11 {
        order: 11;
    }

    .order-lg-12 {
        order: 12;
    }

    .offset-lg-0 {
        margin-left: 0;
    }

    .offset-lg-1 {
        margin-left: 8.33333%;
    }

    .offset-lg-2 {
        margin-left: 16.66667%;
    }

    .offset-lg-3 {
        margin-left: 25%;
    }

    .offset-lg-4 {
        margin-left: 33.33333%;
    }

    .offset-lg-5 {
        margin-left: 41.66667%;
    }

    .offset-lg-6 {
        margin-left: 50%;
    }

    .offset-lg-7 {
        margin-left: 58.33333%;
    }

    .offset-lg-8 {
        margin-left: 66.66667%;
    }

    .offset-lg-9 {
        margin-left: 75%;
    }

    .offset-lg-10 {
        margin-left: 83.33333%;
    }

    .offset-lg-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-xl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-xl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-xl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-xl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-xl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-xl-first {
        order: -1;
    }

    .order-xl-last {
        order: 13;
    }

    .order-xl-0 {
        order: 0;
    }

    .order-xl-1 {
        order: 1;
    }

    .order-xl-2 {
        order: 2;
    }

    .order-xl-3 {
        order: 3;
    }

    .order-xl-4 {
        order: 4;
    }

    .order-xl-5 {
        order: 5;
    }

    .order-xl-6 {
        order: 6;
    }

    .order-xl-7 {
        order: 7;
    }

    .order-xl-8 {
        order: 8;
    }

    .order-xl-9 {
        order: 9;
    }

    .order-xl-10 {
        order: 10;
    }

    .order-xl-11 {
        order: 11;
    }

    .order-xl-12 {
        order: 12;
    }

    .offset-xl-0 {
        margin-left: 0;
    }

    .offset-xl-1 {
        margin-left: 8.33333%;
    }

    .offset-xl-2 {
        margin-left: 16.66667%;
    }

    .offset-xl-3 {
        margin-left: 25%;
    }

    .offset-xl-4 {
        margin-left: 33.33333%;
    }

    .offset-xl-5 {
        margin-left: 41.66667%;
    }

    .offset-xl-6 {
        margin-left: 50%;
    }

    .offset-xl-7 {
        margin-left: 58.33333%;
    }

    .offset-xl-8 {
        margin-left: 66.66667%;
    }

    .offset-xl-9 {
        margin-left: 75%;
    }

    .offset-xl-10 {
        margin-left: 83.33333%;
    }

    .offset-xl-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 1400px) {
    .col-xxl-auto {
        flex: 0 0 auto;
        width: auto;
    }

    .col-xxl-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }

    .col-xxl-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }

    .col-xxl-3 {
        flex: 0 0 auto;
        width: 25%;
    }

    .col-xxl-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }

    .col-xxl-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }

    .col-xxl-6 {
        flex: 0 0 auto;
        width: 50%;
    }

    .col-xxl-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }

    .col-xxl-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }

    .col-xxl-9 {
        flex: 0 0 auto;
        width: 75%;
    }

    .col-xxl-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }

    .col-xxl-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }

    .col-xxl-12 {
        flex: 0 0 auto;
        width: 100%;
    }

    .offset-xxl-0 {
        margin-left: 0;
    }

    .offset-xxl-1 {
        margin-left: 8.33333333%;
    }

    .offset-xxl-2 {
        margin-left: 16.66666667%;
    }

    .offset-xxl-3 {
        margin-left: 25%;
    }

    .offset-xxl-4 {
        margin-left: 33.33333333%;
    }

    .offset-xxl-5 {
        margin-left: 41.66666667%;
    }

    .offset-xxl-6 {
        margin-left: 50%;
    }

    .offset-xxl-7 {
        margin-left: 58.33333333%;
    }

    .offset-xxl-8 {
        margin-left: 66.66666667%;
    }

    .offset-xxl-9 {
        margin-left: 75%;
    }

    .offset-xxl-10 {
        margin-left: 83.33333333%;
    }

    .offset-xxl-11 {
        margin-left: 91.66666667%;
    }

    .g-xxl-0,
    .gx-xxl-0 {
        --bs-gutter-x: 0;
    }

    .g-xxl-0,
    .gy-xxl-0 {
        --bs-gutter-y: 0;
    }

    .g-xxl-1,
    .gx-xxl-1 {
        --bs-gutter-x: 0.25rem;
    }

    .g-xxl-1,
    .gy-xxl-1 {
        --bs-gutter-y: 0.25rem;
    }

    .g-xxl-2,
    .gx-xxl-2 {
        --bs-gutter-x: 0.5rem;
    }

    .g-xxl-2,
    .gy-xxl-2 {
        --bs-gutter-y: 0.5rem;
    }

    .g-xxl-3,
    .gx-xxl-3 {
        --bs-gutter-x: 1rem;
    }

    .g-xxl-3,
    .gy-xxl-3 {
        --bs-gutter-y: 1rem;
    }

    .g-xxl-4,
    .gx-xxl-4 {
        --bs-gutter-x: 1.5rem;
    }

    .g-xxl-4,
    .gy-xxl-4 {
        --bs-gutter-y: 1.5rem;
    }

    .g-xxl-5,
    .gx-xxl-5 {
        --bs-gutter-x: 3rem;
    }

    .g-xxl-5,
    .gy-xxl-5 {
        --bs-gutter-y: 3rem;
    }
}

.align-baseline {
    vertical-align: baseline !important;
}

.align-top {
    vertical-align: top !important;
}

.align-middle {
    vertical-align: middle !important;
}

.align-bottom {
    vertical-align: bottom !important;
}

.align-text-bottom {
    vertical-align: text-bottom !important;
}

.align-text-top {
    vertical-align: text-top !important;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.d-none {
    display: none !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex {
    display: flex !important;
}

.d-inline-flex {
    display: inline-flex !important;
}

@media (min-width: 576px) {
    .d-sm-none {
        display: none !important;
    }

    .d-sm-inline {
        display: inline !important;
    }

    .d-sm-inline-block {
        display: inline-block !important;
    }

    .d-sm-block {
        display: block !important;
    }

    .d-sm-table {
        display: table !important;
    }

    .d-sm-table-row {
        display: table-row !important;
    }

    .d-sm-table-cell {
        display: table-cell !important;
    }

    .d-sm-flex {
        display: flex !important;
    }

    .d-sm-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 768px) {
    .d-md-none {
        display: none !important;
    }

    .d-md-inline {
        display: inline !important;
    }

    .d-md-inline-block {
        display: inline-block !important;
    }

    .d-md-block {
        display: block !important;
        margin-left: 3px;
    }

    .d-md-table {
        display: table !important;
    }

    .d-md-table-row {
        display: table-row !important;
    }

    .d-md-table-cell {
        display: table-cell !important;
    }

    .d-md-flex {
        display: flex !important;
    }

    .d-md-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 992px) {
    .d-lg-none {
        display: none !important;
    }

    .d-lg-inline {
        display: inline !important;
    }

    .d-lg-inline-block {
        display: inline-block !important;
    }

    .d-lg-block {
        display: block !important;
    }

    .d-lg-table {
        display: table !important;
    }

    .d-lg-table-row {
        display: table-row !important;
    }

    .d-lg-table-cell {
        display: table-cell !important;
    }

    .d-lg-flex {
        display: flex !important;
    }

    .d-lg-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 1200px) {
    .d-xl-none {
        display: none !important;
    }

    .d-xl-inline {
        display: inline !important;
    }

    .d-xl-inline-block {
        display: inline-block !important;
    }

    .d-xl-block {
        display: block !important;
    }

    .d-xl-table {
        display: table !important;
    }

    .d-xl-table-row {
        display: table-row !important;
    }

    .d-xl-table-cell {
        display: table-cell !important;
    }

    .d-xl-flex {
        display: flex !important;
    }

    .d-xl-inline-flex {
        display: inline-flex !important;
    }
}

@media print {
    .d-print-none {
        display: none !important;
    }

    .d-print-inline {
        display: inline !important;
    }

    .d-print-inline-block {
        display: inline-block !important;
    }

    .d-print-block {
        display: block !important;
    }

    .d-print-table {
        display: table !important;
    }

    .d-print-table-row {
        display: table-row !important;
    }

    .d-print-table-cell {
        display: table-cell !important;
    }

    .d-print-flex {
        display: flex !important;
    }

    .d-print-inline-flex {
        display: inline-flex !important;
    }
}

.flex-row {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-row-reverse {
    flex-direction: row-reverse !important;
}

.flex-column-reverse {
    flex-direction: column-reverse !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

.flex-fill {
    flex: 1 1 auto !important;
}

.flex-grow-0 {
    flex-grow: 0 !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.flex-shrink-0 {
    flex-shrink: 0 !important;
}

.flex-shrink-1 {
    flex-shrink: 1 !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-around {
    justify-content: space-around !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-baseline {
    align-items: baseline !important;
}

.align-items-stretch {
    align-items: stretch !important;
}

.align-content-start {
    align-content: flex-start !important;
}

.align-content-end {
    align-content: flex-end !important;
}

.align-content-center {
    align-content: center !important;
}

.align-content-between {
    align-content: space-between !important;
}

.align-content-around {
    align-content: space-around !important;
}

.align-content-stretch {
    align-content: stretch !important;
}

.align-self-auto {
    align-self: auto !important;
}

.align-self-start {
    align-self: flex-start !important;
}

.align-self-end {
    align-self: flex-end !important;
}

.align-self-center {
    align-self: center !important;
}

.align-self-baseline {
    align-self: baseline !important;
}

.align-self-stretch {
    align-self: stretch !important;
}

@media (min-width: 576px) {
    .flex-sm-row {
        flex-direction: row !important;
    }

    .flex-sm-column {
        flex-direction: column !important;
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important;
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-sm-fill {
        flex: 1 1 auto !important;
    }

    .flex-sm-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-sm-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-sm-start {
        justify-content: flex-start !important;
    }

    .justify-content-sm-end {
        justify-content: flex-end !important;
    }

    .justify-content-sm-center {
        justify-content: center !important;
    }

    .justify-content-sm-between {
        justify-content: space-between !important;
    }

    .justify-content-sm-around {
        justify-content: space-around !important;
    }

    .align-items-sm-start {
        align-items: flex-start !important;
    }

    .align-items-sm-end {
        align-items: flex-end !important;
    }

    .align-items-sm-center {
        align-items: center !important;
    }

    .align-items-sm-baseline {
        align-items: baseline !important;
    }

    .align-items-sm-stretch {
        align-items: stretch !important;
    }

    .align-content-sm-start {
        align-content: flex-start !important;
    }

    .align-content-sm-end {
        align-content: flex-end !important;
    }

    .align-content-sm-center {
        align-content: center !important;
    }

    .align-content-sm-between {
        align-content: space-between !important;
    }

    .align-content-sm-around {
        align-content: space-around !important;
    }

    .align-content-sm-stretch {
        align-content: stretch !important;
    }

    .align-self-sm-auto {
        align-self: auto !important;
    }

    .align-self-sm-start {
        align-self: flex-start !important;
    }

    .align-self-sm-end {
        align-self: flex-end !important;
    }

    .align-self-sm-center {
        align-self: center !important;
    }

    .align-self-sm-baseline {
        align-self: baseline !important;
    }

    .align-self-sm-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 768px) {
    .flex-md-row {
        flex-direction: row !important;
    }

    .flex-md-column {
        flex-direction: column !important;
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-md-wrap {
        flex-wrap: wrap !important;
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-md-fill {
        flex: 1 1 auto !important;
    }

    .flex-md-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-md-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-md-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-md-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-md-start {
        justify-content: flex-start !important;
    }

    .justify-content-md-end {
        justify-content: flex-end !important;
    }

    .justify-content-md-center {
        justify-content: center !important;
    }

    .justify-content-md-between {
        justify-content: space-between !important;
    }

    .justify-content-md-around {
        justify-content: space-around !important;
    }

    .align-items-md-start {
        align-items: flex-start !important;
    }

    .align-items-md-end {
        align-items: flex-end !important;
    }

    .align-items-md-center {
        align-items: center !important;
    }

    .align-items-md-baseline {
        align-items: baseline !important;
    }

    .align-items-md-stretch {
        align-items: stretch !important;
    }

    .align-content-md-start {
        align-content: flex-start !important;
    }

    .align-content-md-end {
        align-content: flex-end !important;
    }

    .align-content-md-center {
        align-content: center !important;
    }

    .align-content-md-between {
        align-content: space-between !important;
    }

    .align-content-md-around {
        align-content: space-around !important;
    }

    .align-content-md-stretch {
        align-content: stretch !important;
    }

    .align-self-md-auto {
        align-self: auto !important;
    }

    .align-self-md-start {
        align-self: flex-start !important;
    }

    .align-self-md-end {
        align-self: flex-end !important;
    }

    .align-self-md-center {
        align-self: center !important;
    }

    .align-self-md-baseline {
        align-self: baseline !important;
    }

    .align-self-md-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 992px) {
    .flex-lg-row {
        flex-direction: row !important;
    }

    .flex-lg-column {
        flex-direction: column !important;
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important;
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-lg-fill {
        flex: 1 1 auto !important;
    }

    .flex-lg-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-lg-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-lg-start {
        justify-content: flex-start !important;
    }

    .justify-content-lg-end {
        justify-content: flex-end !important;
    }

    .justify-content-lg-center {
        justify-content: center !important;
    }

    .justify-content-lg-between {
        justify-content: space-between !important;
    }

    .justify-content-lg-around {
        justify-content: space-around !important;
    }

    .align-items-lg-start {
        align-items: flex-start !important;
    }

    .align-items-lg-end {
        align-items: flex-end !important;
    }

    .align-items-lg-center {
        align-items: center !important;
    }

    .align-items-lg-baseline {
        align-items: baseline !important;
    }

    .align-items-lg-stretch {
        align-items: stretch !important;
    }

    .align-content-lg-start {
        align-content: flex-start !important;
    }

    .align-content-lg-end {
        align-content: flex-end !important;
    }

    .align-content-lg-center {
        align-content: center !important;
    }

    .align-content-lg-between {
        align-content: space-between !important;
    }

    .align-content-lg-around {
        align-content: space-around !important;
    }

    .align-content-lg-stretch {
        align-content: stretch !important;
    }

    .align-self-lg-auto {
        align-self: auto !important;
    }

    .align-self-lg-start {
        align-self: flex-start !important;
    }

    .align-self-lg-end {
        align-self: flex-end !important;
    }

    .align-self-lg-center {
        align-self: center !important;
    }

    .align-self-lg-baseline {
        align-self: baseline !important;
    }

    .align-self-lg-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 1200px) {
    .flex-xl-row {
        flex-direction: row !important;
    }

    .flex-xl-column {
        flex-direction: column !important;
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important;
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-xl-fill {
        flex: 1 1 auto !important;
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-xl-start {
        justify-content: flex-start !important;
    }

    .justify-content-xl-end {
        justify-content: flex-end !important;
    }

    .justify-content-xl-center {
        justify-content: center !important;
    }

    .justify-content-xl-between {
        justify-content: space-between !important;
    }

    .justify-content-xl-around {
        justify-content: space-around !important;
    }

    .align-items-xl-start {
        align-items: flex-start !important;
    }

    .align-items-xl-end {
        align-items: flex-end !important;
    }

    .align-items-xl-center {
        align-items: center !important;
    }

    .align-items-xl-baseline {
        align-items: baseline !important;
    }

    .align-items-xl-stretch {
        align-items: stretch !important;
    }

    .align-content-xl-start {
        align-content: flex-start !important;
    }

    .align-content-xl-end {
        align-content: flex-end !important;
    }

    .align-content-xl-center {
        align-content: center !important;
    }

    .align-content-xl-between {
        align-content: space-between !important;
    }

    .align-content-xl-around {
        align-content: space-around !important;
    }

    .align-content-xl-stretch {
        align-content: stretch !important;
    }

    .align-self-xl-auto {
        align-self: auto !important;
    }

    .align-self-xl-start {
        align-self: flex-start !important;
    }

    .align-self-xl-end {
        align-self: flex-end !important;
    }

    .align-self-xl-center {
        align-self: center !important;
    }

    .align-self-xl-baseline {
        align-self: baseline !important;
    }

    .align-self-xl-stretch {
        align-self: stretch !important;
    }
}

.float-start {
    float: left !important;
}

.float-end {
    float: right !important;
}

.float-none {
    float: none !important;
}

@media (min-width: 576px) {
    .float-sm-left {
        float: left !important;
    }

    .float-sm-right {
        float: right !important;
    }

    .float-sm-none {
        float: none !important;
    }
}

@media (min-width: 768px) {
    .float-md-left {
        float: left !important;
    }

    .float-md-right {
        float: right !important;
    }

    .float-md-none {
        float: none !important;
    }
}

@media (min-width: 992px) {
    .float-lg-left {
        float: left !important;
    }

    .float-lg-right {
        float: right !important;
    }

    .float-lg-none {
        float: none !important;
    }
}

@media (min-width: 1200px) {
    .float-xl-left {
        float: left !important;
    }

    .float-xl-right {
        float: right !important;
    }

    .float-xl-none {
        float: none !important;
    }
}

/*----- Typography ------*/
.heading-primary {
    background-color: var(--primary);
    color: #fff;
    padding: 5px;
}

.heading-inverse {
    background-color: #333;
    color: #fff;
    padding: 5px;
}

.heading-secondary {
    background-color: var(--secondary);
    color: #fff;
    padding: 5px;
}

.heading-success {
    background-color: var(--success);
    color: #fff;
    padding: 5px;
}

.heading-info {
    background-color: var(--info);
    color: #fff;
    padding: 5px;
}

.heading-warning {
    background-color: var(--warning);
    color: #fff;
    padding: 5px;
}

.heading-danger {
    background-color: #f34343;
    color: #fff;
    padding: 5px;
}

.tab-content>.active {
    display: block;
}

.tab-content>.tab-pane {
    display: none;
}

.dropup,
.dropright,
.dropdown,
.dropleft {
    position: relative;
}

.split-dropdown.dropdown-toggle {
    white-space: nowrap;
}

.split-dropdown.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.split-dropdown.dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 999;
    display: none;
    float: left;
    min-width: 10rem !important;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 0.875rem;
    color: #4a4a69;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ededf5;
    border-radius: 5px !important;
    box-shadow: 0px 16px 18px rgba(135, 135, 182, 0.1) !important;
}

.dropdown-menu-left {
    right: auto;
    left: 0;
}

.dropdown-menu-right {
    right: 0;
    left: auto;
}

@media (min-width: 576px) {
    .dropdown-menu-sm-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-sm-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 768px) {
    .dropdown-menu-md-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-md-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 992px) {
    .dropdown-menu-lg-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-lg-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 1200px) {
    .dropdown-menu-xl-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-xl-right {
        right: 0;
        left: auto;
    }
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropright .dropdown-toggle::after {
    vertical-align: 0;
}

.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    display: none;
}

.dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropleft .dropdown-toggle::before {
    vertical-align: 0;
}

.dropdown-menu[data-popper-placement^=top],
.dropdown-menu[data-popper-placement^=right],
.dropdown-menu[data-popper-placement^=bottom],
.dropdown-menu[data-popper-placement^=left] {
    right: auto;
    bottom: auto;
}

.dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #f0f0f8;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #323251;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: #141c2b;
    text-decoration: none;
    background-color: #fcfcff;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #141c2b;
    text-decoration: none;
    background-color: #fcfcff;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #7987a1;
    pointer-events: none;
    background-color: transparent;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.76562rem;
    color: #7987a1;
    white-space: nowrap;
}

.dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #323251;
}

.dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem;
}

.dropdown-toggle-split::after {
    margin-left: 0;
}

.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
    margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
    margin-right: 0;
}

/* ###### 3.5 Dropdown ###### */
.dropdown-menu {
    padding: 0;
    border-width: 1px;
    border-radius: 5px;
}

.dropdown-item {
    padding: 8px 15px;
    font-size: 13px;
}

.dropdown-header {
    padding-left: 15px;
    padding-right: 15px;
}

@media (min-width: 768px) {

    .dropdown.d-cart .dropdown-menu,
    .dropdown.message .dropdown-menu {
        width: 20.5rem !important;
    }
}

.user-lock .dropdown {
    position: absolute;
    right: 20px;
}

.main-dropdown-form-demo .static-dropdown {
    padding: 20px;
    background-color: #f0f0f8;
    display: inline-flex;
    justify-content: center;
}

.main-dropdown-form-demo .static-dropdown .dropdown-menu {
    display: block;
    position: static;
    float: none;
}

.main-dropdown-form-demo .dropdown-menu {
    padding: 20px;
}

.main-dropdown-form-demo .dropdown-title {
    font-size: 20px;
    font-weight: 700;
    color: #323251;
    margin-bottom: 5px;
}

@media (min-width: 576px) {
    .main-dropdown-form-demo .static-dropdown {
        padding: 40px;
    }
}

@media (min-width: 576px) {
    .main-dropdown-form-demo .dropdown-menu {
        width: 300px;
        padding: 30px 25px;
    }
}

/* styles.scss o el archivo global de estilos */
/* COLOR PALETTE */
/* SECONDARY PALETTE */
/* TEXT COLOR - NEUTRALS */
/* COLOR PALETTE - SEMANTICS */
/* SUCCESS */
/* ERROR */
/* WARNING */
/* INFO */
/* BACKGROUND */
/*gradient variables*/
/*gray variables*/
/*white variables*/
/*black variables*/
/*shadow variables*/
/*alerts*/
/*navigation*/
/*dark colors*/
.disabled .ng-arrow-wrapper {
    background-color: var(--neutral-20) !important;
}

.disabled .ng-arrow-wrapper::after {
    color: var(--primary-25) !important;
}

.disabled {
    pointer-events: none;
    color: var(--neutral-70);
}

.disabled .ng-select-container {
    background-color: var(--neutral-20);
}

.ng-arrow {
    display: none !important;
}

.disabled .ng-value-container {
    color: var(--neutral-60);
}

.disabled .ng-clear-wrapper {
    display: none !important;
}

.ng-clear-wrapper {
    display: block;
}

.ng-select .ng-clear-wrapper:hover .ng-clear {
    color: #999 !important;
}

.ng-select .ng-clear-wrapper:hover {
    color: #999 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
    font-weight: normal !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    background-color: var(--info-10) !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: var(--info-10) !important;
}

.ng-dropdown-panel-items div:first-child {
    border-top-left-radius: 5px !important;
}

.ng-dropdown-panel-items div:last-child {
    border-bottom-left-radius: 5px !important;
}

.ng-value.ng-star-inserted {
    display: flex;
    flex-direction: row-reverse;
}

.ng-clear-wrapper .ng-star-inserted {
    display: block !important;
}

.ng-select .ng-clear-wrapper .ng-clear {
    display: block !important;
}

.arrow-icon-dropdown.ng-select-opened .ng-select-container {
    border: 1px solid var(--primary) !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    border-right: 0px !important;
}

.ng-dropdown-panel {
    margin-bottom: 2px !important;
    margin-top: 2px !important;
    border-radius: 6px;
}

.ng-option {
    border-bottom: solid 1px var(--neutral-50);
    border-radius: 5px;
}

.ng-value-container .ng-input {
    padding-bottom: 0px !important;
}

.ng-placeholder {
    padding-bottom: 0px !important;
    top: 7px !important;
}

/* .arrow-icon-dropdown {
    max-width: 500px;
} */
.ng-arrow-wrapper {
    background-color: var(--neutral-30);
    padding-right: 0px !important;
    margin-right: 3px !important;
    border-radius: 8px;
    max-height: 28px;
    height: 28px;
    width: 28px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-sm .ng-arrow-wrapper {
    max-height: 22px !important;
    height: 22px !important;
    width: 22px !important;
}

.ng-arrow-wrapper::after {
    content: "expand_more";
    font-family: "Material Icons";
    font-style: normal;
    font-weight: normal;
    font-display: block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    color: var(--primary-75);
    font-size: 20px;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    transition: transform 0.3s;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    color: var(--neutral-90) !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
    font-weight: normal !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked .ng-option-label {
    color: var(--neutral-90) !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    color: var(--neutral-90) !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
    color: var(--neutral-70) !important;
}

.ng-select.ng-select-opened .ng-arrow-wrapper::after {
    transform: rotate(180deg);
}

.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.ng-select.multiple .ng-option {
    display: flex !important;
    align-items: center;
    padding-left: 20px;
}

.ng-select.multiple .ng-option input[type=checkbox] {
    margin-right: 8px;
}

/* COLOR PALETTE */
/* SECONDARY PALETTE */
/* TEXT COLOR - NEUTRALS */
/* COLOR PALETTE - SEMANTICS */
/* SUCCESS */
/* ERROR */
/* WARNING */
/* INFO */
/* BACKGROUND */
/*gradient variables*/
/*gray variables*/
/*white variables*/
/*black variables*/
/*shadow variables*/
/*alerts*/
/*navigation*/
/*dark colors*/
.ng-select .ng-option {
    display: flex;
    align-items: center;
    position: relative;
}

.ng-select.multiple .ng-option .ng-option-label {
    padding-left: 20px;
}

.ng-select.multiple .ng-option::before {
    content: "";
    display: block;
    position: absolute;
    left: 8px;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.ng-select .ng-option.selected::before {
    background-color: var(--info-10);
}

.ng-select.multiple .ng-option input[type=checkbox] {
    position: absolute;
    left: 8px;
    opacity: 0;
    border-radius: 3px;
    cursor: pointer;
}

.ng-select.multiple .ng-option[aria-selected=true]::before {
    content: "";
    font-family: "FontAwesome";
    color: #fff;
    background-color: var(--secondary);
    border-radius: 3px;
    font-size: 10px;
    border: 1px solid var(--secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: 55% 45%;
    background-repeat: no-repeat;
    line-height: 17px;
    width: 16px;
    height: 16px;
    background-size: 65%;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.ng-select .ng-input {
    display: none;
}

/* ###### 3.6 Forms ###### */
.error-div {
    font-size: 12px;
    align-items: center;
    color: #f34343;
    padding-left: 1rem;
}

.error-div span {
    padding: 0;
    margin-right: 5px;
    font-size: 14px;
}

.form-control {
    height: 38px;
    border-radius: 0;
}

.form-control:focus {
    border-color: #949eb7;
    box-shadow: none;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
    -webkit-appearance: none;
}

.label-disabled {
    color: var(--neutral-60) !important;
}

.search-icon-disabled {
    color: var(--neutral-70) !important;
    cursor: default !important;
}

.form-group.has-danger .form-label,
.error-message {
    padding-left: 0;
}

.error-message {
    margin: 0;
    display: flex;
    align-items: center;
}

.form-group.has-danger .error-message-icon {
    width: fit-content;
    color: #f34343;
    padding-right: 0;
}

.informative {
    border: 0px !important;
    pointer-events: none;
}

.close-search-bar-icon {
    margin-right: 1rem;
    pointer-events: default;
    cursor: pointer;
    font-size: 20px !important;
}

.search-bar-icon {
    margin-left: 1rem;
    pointer-events: default;
    cursor: pointer;
    font-size: 20px !important;
}

.search-bar-all-icons {
    pointer-events: auto;
    z-index: 3;
}

.icon-hidden {
    display: none;
}

.search-bar-input:placeholder-shown~.close-search-bar-icon-container .close-search-bar-icon {
    color: transparent;
}

.close-search-bar-icon-container {
    width: 100% !important;
    position: absolute;
    display: flex !important;
    pointer-events: none;
    justify-content: space-between !important;
    height: 100%;
    align-items: center;
    /* z-index: 5; */
    top: 0;
}

.search-bar-input {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
    border-radius: 10px !important;
}

.search-bar-input:disabled {
    background-color: var(--primary-10) !important;
    border: 1px solid var(--primary-10) !important;
}

.search-bar-input:focus {
    border: 2px solid var(--secondary) !important;
}

.relative-position {
    position: relative;
}

.hidden-icon {
    display: none !important;
}

.form-control {
    display: block;
    width: 100%;
    height: 40px;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 20px;
    color: var(--neutral-text);
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid var(--neutral-70);
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}

.form-control:focus {
    background-color: #fff;
    border-color: var(--neutral-text);
}

.form-control::placeholder {
    color: var(--neutral-60);
    opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: transparent;
    opacity: 1;
    border: 1px solid var(--neutral-60);
    color: var(--neutral-70);
}

.form-control.ng-dirty.ng-invalid {
    border-color: var(--error);
}

.form-control[type=file]::file-selector-button {
    display: none;
}

@media (prefers-reduced-motion: reduce) {
    .form-control {
        transition: none;
    }
}

select.form-control:focus::-ms-value {
    color: #4d5875;
    background-color: #fff;
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%;
}

.col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}

.col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.09375rem;
    line-height: 1.5;
}

.col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.76562rem;
    line-height: 1.5;
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    margin-bottom: 0;
    line-height: 1.5;
    color: #4a4a69;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0;
}

.form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.375rem;
}

.form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

select.form-control[size],
select.form-control[multiple] {
    height: auto;
}

textarea.form-control {
    height: auto;
}

.form-group {
    margin-bottom: 1rem;
}

.form-text {
    display: block;
    margin-top: 0.25rem;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.form-row>.col,
.form-row>[class*=col-] {
    padding-right: 5px;
    padding-left: 5px;
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
}

.form-check-input:disabled~.form-check-label {
    color: #7987a1;
}

.form-check-label {
    margin-bottom: 0;
}

.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: var(--success);
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.76562rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(59, 176, 1, 0.9);
    border-radius: 3px;
}

.was-validated .form-control:valid .form-label,
.form-control.is-valid .form-label,
input.form-control.parsley-success .form-label,
textarea.form-control.parsley-success .form-label {
    color: var(--success) !important;
}

.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip {
    display: block;
}

.form-control.is-valid~.valid-feedback,
input.form-control.parsley-success~.valid-feedback,
textarea.form-control.parsley-success~.valid-feedback,
.form-control.is-valid~.valid-tooltip,
input.form-control.parsley-success~.valid-tooltip,
textarea.form-control.parsley-success~.valid-tooltip {
    display: block;
}

.was-validated textarea.form-control:valid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

textarea.form-control.is-valid,
textarea.form-control.parsley-success {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid,
input.custom-select.parsley-success,
textarea.custom-select.parsley-success {
    padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300cccc ' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-tooltip {
    display: block;
}

.custom-select.is-valid~.valid-feedback,
input.custom-select.parsley-success~.valid-feedback,
textarea.custom-select.parsley-success~.valid-feedback,
.custom-select.is-valid~.valid-tooltip,
input.custom-select.parsley-success~.valid-tooltip,
textarea.custom-select.parsley-success~.valid-tooltip {
    display: block;
}

.was-validated .form-control-file:valid~.valid-feedback,
.was-validated .form-control-file:valid~.valid-tooltip {
    display: block;
}

.form-control-file.is-valid~.valid-feedback,
input.form-control-file.parsley-success~.valid-feedback,
textarea.form-control-file.parsley-success~.valid-feedback,
.form-control-file.is-valid~.valid-tooltip,
input.form-control-file.parsley-success~.valid-tooltip,
textarea.form-control-file.parsley-success~.valid-tooltip {
    display: block;
}

.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip {
    display: block;
}

.form-check-input.is-valid~.valid-feedback,
input.form-check-input.parsley-success~.valid-feedback,
textarea.form-check-input.parsley-success~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip,
input.form-check-input.parsley-success~.valid-tooltip,
textarea.form-check-input.parsley-success~.valid-tooltip {
    display: block;
}

.was-validated .custom-control-input:valid~.custom-control-label,
.custom-control-input.is-valid~.custom-control-label,
input.custom-control-input.parsley-success~.custom-control-label,
textarea.custom-control-input.parsley-success~.custom-control-label {
    color: var(--success);
}

.was-validated .custom-control-input:valid~.valid-feedback,
.was-validated .custom-control-input:valid~.valid-tooltip {
    display: block;
}

.custom-control-input.is-valid~.valid-feedback,
input.custom-control-input.parsley-success~.valid-feedback,
textarea.custom-control-input.parsley-success~.valid-feedback,
.custom-control-input.is-valid~.valid-tooltip,
input.custom-control-input.parsley-success~.valid-tooltip,
textarea.custom-control-input.parsley-success~.valid-tooltip {
    display: block;
}

.was-validated .custom-control-input:valid:focus~.custom-control-label::before,
.custom-control-input.is-valid:focus~.custom-control-label::before,
input.custom-control-input.parsley-success:focus~.custom-control-label::before,
textarea.custom-control-input.parsley-success:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(59, 176, 1, 0.25);
}

.was-validated .custom-file-input:valid~.valid-feedback,
.was-validated .custom-file-input:valid~.valid-tooltip {
    display: block;
}

.custom-file-input.is-valid~.valid-feedback,
input.custom-file-input.parsley-success~.valid-feedback,
textarea.custom-file-input.parsley-success~.valid-feedback,
.custom-file-input.is-valid~.valid-tooltip,
input.custom-file-input.parsley-success~.valid-tooltip,
textarea.custom-file-input.parsley-success~.valid-tooltip {
    display: block;
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #f34343;
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.76562rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.9);
    border-radius: 3px;
}

.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip {
    display: block;
}

.form-control.is-invalid~.invalid-feedback,
input.form-control.parsley-error~.invalid-feedback,
textarea.form-control.parsley-error~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip,
input.form-control.parsley-error~.invalid-tooltip,
textarea.form-control.parsley-error~.invalid-tooltip {
    display: block;
}

.was-validated textarea.form-control:invalid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

textarea.form-control.is-invalid,
textarea.form-control.parsley-error {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid,
input.custom-select.parsley-error,
textarea.custom-select.parsley-error {
    padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fa5c7c' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23fa5c7c' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-tooltip {
    display: block;
}

.custom-select.is-invalid~.invalid-feedback,
input.custom-select.parsley-error~.invalid-feedback,
textarea.custom-select.parsley-error~.invalid-feedback,
.custom-select.is-invalid~.invalid-tooltip,
input.custom-select.parsley-error~.invalid-tooltip,
textarea.custom-select.parsley-error~.invalid-tooltip {
    display: block;
}

.was-validated .form-control-file:invalid~.invalid-feedback,
.was-validated .form-control-file:invalid~.invalid-tooltip {
    display: block;
}

.form-control-file.is-invalid~.invalid-feedback,
input.form-control-file.parsley-error~.invalid-feedback,
textarea.form-control-file.parsley-error~.invalid-feedback,
.form-control-file.is-invalid~.invalid-tooltip,
input.form-control-file.parsley-error~.invalid-tooltip,
textarea.form-control-file.parsley-error~.invalid-tooltip {
    display: block;
}

.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip {
    display: block;
}

.form-check-input.is-invalid~.invalid-feedback,
input.form-check-input.parsley-error~.invalid-feedback,
textarea.form-check-input.parsley-error~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip,
input.form-check-input.parsley-error~.invalid-tooltip,
textarea.form-check-input.parsley-error~.invalid-tooltip {
    display: block;
}

.was-validated .custom-control-input:invalid~.invalid-feedback,
.was-validated .custom-control-input:invalid~.invalid-tooltip {
    display: block;
}

.custom-control-input.is-invalid~.invalid-feedback,
input.custom-control-input.parsley-error~.invalid-feedback,
textarea.custom-control-input.parsley-error~.invalid-feedback,
.custom-control-input.is-invalid~.invalid-tooltip,
input.custom-control-input.parsley-error~.invalid-tooltip,
textarea.custom-control-input.parsley-error~.invalid-tooltip {
    display: block;
}

.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
.custom-control-input.is-invalid:checked~.custom-control-label::before,
input.custom-control-input.parsley-error:checked~.custom-control-label::before,
textarea.custom-control-input.parsley-error:checked~.custom-control-label::before {
    border-color: #e4606d;
    background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
.custom-control-input.is-invalid:focus~.custom-control-label::before,
input.custom-control-input.parsley-error:focus~.custom-control-label::before,
textarea.custom-control-input.parsley-error:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-file-input:invalid~.invalid-feedback,
.was-validated .custom-file-input:invalid~.invalid-tooltip {
    display: block;
}

.custom-file-input.is-invalid~.invalid-feedback,
input.custom-file-input.parsley-error~.invalid-feedback,
textarea.custom-file-input.parsley-error~.invalid-feedback,
.custom-file-input.is-invalid~.invalid-tooltip,
input.custom-file-input.parsley-error~.invalid-tooltip,
textarea.custom-file-input.parsley-error~.invalid-tooltip {
    display: block;
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.form-inline .form-check {
    width: 100%;
}

@media (min-width: 576px) {
    .form-inline label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
    }

    .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0;
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    .form-inline .form-control-plaintext {
        display: inline-block;
    }

    .form-inline .input-group,
    .form-inline .custom-select {
        width: auto;
    }

    .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0;
    }

    .form-inline .form-check-input {
        position: relative;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: 0.25rem;
        margin-left: 0;
    }

    .form-inline .custom-control {
        align-items: center;
        justify-content: center;
    }

    .form-inline .custom-control-label {
        margin-bottom: 0;
    }
}

/* ###### 5.3 Forms   ###### */
.main-form-group {
    padding: 12px 15px;
    border: 1px solid #ededf5;
    border-radius: 5px;
}

.main-form-group.focus {
    border-color: #d6d6e6;
    box-shadow: 0 0 0 2px rgba(91, 71, 251, 0.16);
}

.main-form-group .form-label {
    font-size: 13px;
    margin-bottom: 2px;
    font-weight: 400;
    color: #949eb7;
}

.main-form-group .form-control {
    padding: 0;
    border-width: 0;
    height: 25px;
    color: #323251;
    font-weight: 500;
}

.main-form-group .form-control:focus {
    box-shadow: none;
}

.formgroup-wrapper .form-control {
    height: 40px !important;
}

.formgroup-wrapper .main-form-group .form-label {
    margin-bottom: 0px;
}

.main-header-center .form-control {
    padding: 1rem;
}

.main-header-center .form-control::placeholder {
    padding-left: 0rem;
    padding-right: 0rem;
}

.browse-file.form-control {
    background-color: transparent;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.file-browser .btn-default {
    background: #f1f4fb;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
    padding: 0.6rem 15px;
    color: #68769a;
    border-radius: 0;
    border: 1px solid #ededf5;
}

.form-select {
    border: 1px solid #ededf5;
}

.SumoSelect>.CaptionCont {
    border: 1px solid #ededf5;
}

.form-control::-webkit-file-upload-button {
    background-color: rgba(234, 243, 241, 0.2784313725);
}

.form-control:hover:not(disabled):not([readonly])::-webkit-file-upload-button {
    background-color: rgba(234, 243, 241, 0.2784313725) !important;
}

.input-container {
    position: relative;
}

.input-container .label-input-hover {
    display: inline-block;
}

.input-container .tooltip {
    position: absolute;
    top: 25px;
    left: 10px;
    background-color: #5E7FCD;
    border-radius: 5px;
    color: white;
    padding: 5px;
    font-size: 12px;
    visibility: hidden;
    opacity: 0;
    height: fit-content;
    transition: opacity 0.5s ease-in-out;
    z-index: 100;
}

.input-container .tooltip::after {
    content: "";
    position: absolute;
    top: -36%;
    left: 15px;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #5E7FCD transparent;
}

.input-container .rtl .tooltip {
    left: 2%;
}

.input-container .label-input-hover:hover+.tooltip,
.input-container .label-input-hover:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

.input-container .form-control.is-valid .was-validated .form-control:valid {
    border-color: none !important;
}

/* ###### 3.7 Grid ###### */
.row-sm {
    margin-left: -10px;
    margin-right: -10px;
}

.row-sm>div {
    padding-left: 10px;
    padding-right: 10px;
}

.row-xs {
    margin-left: -5px;
    margin-right: -5px;
}

.row-xs>div {
    padding-left: 5px;
    padding-right: 5px;
}

@media (min-width: 576px) {
    .row-xs--sm {
        margin-left: -5px;
        margin-right: -5px;
    }

    .row-xs--sm>div {
        padding-left: 5px;
        padding-right: 5px;
    }

    .row-sm--sm {
        margin-left: -10px;
        margin-right: -10px;
    }

    .row-sm--sm>div {
        padding-left: 10px;
        padding-right: 10px;
    }

    .row--sm {
        margin-left: -15px;
        margin-right: -15px;
    }

    .row--sm>div {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (min-width: 768px) {
    .row-xs--md {
        margin-left: -5px;
        margin-right: -5px;
    }

    .row-xs--md>div {
        padding-left: 5px;
        padding-right: 5px;
    }

    .row-sm--md {
        margin-left: -10px;
        margin-right: -10px;
    }

    .row-sm--md>div {
        padding-left: 10px;
        padding-right: 10px;
    }

    .row--md {
        margin-left: -15px;
        margin-right: -15px;
    }

    .row--md>div {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (min-width: 992px) {
    .row-xs--lg {
        margin-left: -5px;
        margin-right: -5px;
    }

    .row-xs--lg>div {
        padding-left: 5px;
        padding-right: 5px;
    }

    .row-sm--lg {
        margin-left: -10px;
        margin-right: -10px;
    }

    .row-sm--lg>div {
        padding-left: 10px;
        padding-right: 10px;
    }

    .row--lg {
        margin-left: -15px;
        margin-right: -15px;
    }

    .row--lg>div {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (min-width: 1200px) {
    .row-xs--xl {
        margin-left: -5px;
        margin-right: -5px;
    }

    .row-xs--xl>div {
        padding-left: 5px;
        padding-right: 5px;
    }

    .row-sm--xl {
        margin-left: -10px;
        margin-right: -10px;
    }

    .row-sm--xl>div {
        padding-left: 10px;
        padding-right: 10px;
    }

    .row--xl {
        margin-left: -15px;
        margin-right: -15px;
    }

    .row--xl>div {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.input-group>.form-control,
.input-group>.form-control-plaintext,
.input-group>.custom-select,
.input-group>.custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

.input-group>.form-control+.form-control,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.custom-file {
    margin-left: -1px;
}

.input-group>.form-control-plaintext+.form-control,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.custom-file {
    margin-left: -1px;
}

.input-group>.custom-select+.form-control,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.custom-file {
    margin-left: -1px;
}

.input-group>.custom-file+.form-control,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.custom-file {
    margin-left: -1px;
}

.input-group>.form-control:focus,
.input-group>.custom-select:focus {
    z-index: 3;
}

.input-group>.custom-file .custom-file-input:focus {
    z-index: 4;
}

.input-group>.custom-file .custom-file-input:focus~.custom-file-label {
    z-index: 3;
}

.input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
}

.input-group>.form-control:not(:first-child),
.input-group>.custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
}

.input-group>.custom-file {
    display: flex;
    align-items: center;
}

.input-group>.custom-file:not(:last-child) .custom-file-label {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
    display: flex;
}

.input-group-prepend .btn,
.input-group-prepend .sp-container button {
    position: relative;
    z-index: 2;
}

.sp-container .input-group-prepend button {
    position: relative;
    z-index: 2;
}

.input-group-append .btn,
.input-group-append .sp-container button {
    position: relative;
    z-index: 0;
}

.sp-container .input-group-append button {
    position: relative;
    z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-prepend .sp-container button:focus {
    z-index: 3;
}

.sp-container .input-group-prepend button:focus {
    z-index: 3;
}

.input-group-append .btn:focus,
.input-group-append .sp-container button:focus {
    z-index: 3;
}

.sp-container .input-group-append button:focus {
    z-index: 3;
}

.input-group-prepend .btn+.btn,
.input-group-prepend .sp-container button+.btn {
    margin-left: -1px;
}

.sp-container .input-group-prepend button+.btn,
.input-group-prepend .sp-container .btn+button,
.sp-container .input-group-prepend .btn+button,
.input-group-prepend .sp-container button+button,
.sp-container .input-group-prepend button+button {
    margin-left: -1px;
}

.input-group-prepend .btn+.input-group-text,
.input-group-prepend .sp-container button+.input-group-text {
    margin-left: -1px;
}

.sp-container .input-group-prepend button+.input-group-text {
    margin-left: -1px;
}

.input-group-prepend .input-group-text+.input-group-text,
.input-group-prepend .input-group-text+.btn {
    margin-left: -1px;
}

.input-group-prepend .sp-container .input-group-text+button {
    margin-left: -1px;
}

.sp-container .input-group-prepend .input-group-text+button {
    margin-left: -1px;
}

.input-group-append .btn+.btn,
.input-group-append .sp-container button+.btn {
    margin-left: -1px;
}

.sp-container .input-group-append button+.btn,
.input-group-append .sp-container .btn+button,
.sp-container .input-group-append .btn+button,
.input-group-append .sp-container button+button,
.sp-container .input-group-append button+button {
    margin-left: -1px;
}

.input-group-append .btn+.input-group-text,
.input-group-append .sp-container button+.input-group-text {
    margin-left: -1px;
}

.sp-container .input-group-append button+.input-group-text {
    margin-left: -1px;
}

.input-group-append .input-group-text+.input-group-text,
.input-group-append .input-group-text+.btn {
    margin-left: -1px;
}

.input-group-append .sp-container .input-group-text+button {
    margin-left: -1px;
}

.sp-container .input-group-append .input-group-text+button {
    margin-left: -1px;
}

.input-group-prepend {
    margin-right: -1px;
}

.input-group-append {
    margin-left: -1px;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 0;
    color: var(--neutral-darker);
    text-align: center;
    white-space: nowrap;
    background-color: var(--neutral-30);
    border: 1px solid var(--neutral-70);
    border-radius: 0.375rem;
}

.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
    margin-top: 0;
}

.input-group-text.icon-text {
    background-color: #fff;
}

.input-group-text:has(+ .form-control:focus) {
    border-color: var(--neutral-text);
}

.form-control:focus+.input-group-text {
    border-color: var(--neutral-text);
}

.input-group-lg>.form-control:not(textarea),
.input-group-lg>.custom-select {
    height: calc(1.5em + 1rem + 2px);
}

.input-group-lg>.form-control,
.input-group-lg>.custom-select,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn {
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.sp-container .input-group-lg>.input-group-prepend>button,
.input-group-lg>.input-group-append>.btn,
.sp-container .input-group-lg>.input-group-append>button {
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.input-group-sm>.form-control:not(textarea),
.input-group-sm>.custom-select {
    height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm>.form-control,
.input-group-sm>.custom-select,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.76562rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.sp-container .input-group-sm>.input-group-prepend>button,
.input-group-sm>.input-group-append>.btn,
.sp-container .input-group-sm>.input-group-append>button {
    padding: 0.25rem 0.5rem;
    font-size: 0.76562rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
    padding-right: 1.75rem;
}

.input-group>.input-group-prepend>.btn,
.sp-container .input-group>.input-group-prepend>button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.input-group-prepend>.input-group-text,
.input-group>.input-group-append:not(:last-child)>.btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:not(:first-child)>.btn {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.sp-container .input-group>.input-group-append:not(:last-child)>button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.sp-container .input-group>.input-group-append:last-child>button:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.input-group-append>.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.sp-container .input-group>.input-group-append>button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.sp-container .input-group>.input-group-prepend:not(:first-child)>button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.sp-container .input-group>.input-group-prepend:first-child>button:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

/* ###### 3.8 Input Group ###### */
.input-group-prepend,
.input-group-append {
    border-radius: 0;
}

.input-group-text {
    padding-top: 0;
    padding-bottom: 0;
    align-items: center;
    border-radius: 0;
    border-radius: 5px;
}

.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:not(:first-child)>.btn {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

/*------ Modal -------*/
.modal-open {
    overflow: hidden;
    padding: 0 !important;
    display: block;
    overflow: inherit;
}

.modal-open {
    overflow: auto !important;
    padding: 0px !important;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 0px !important;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
    display: none;
    overflow: hidden;
    outline: 0;
    padding-right: 0 !important;
    margin: 0 !important;
}

.modal-open,
.modal-backdrop.fade.show {
    padding-right: 0 !important;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

.modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
    transform: translate(0, -25%);
}

.modal.show .modal-dialog {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}

@media screen and (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none;
    }
}

.modal-dialog-centered {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    min-height: calc(100% - 1rem);
}

.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid var(--primary-10);
    border-radius: 16px;
    outline: 0;
}

.modal.bg-primary-darker .modal-content {
    background-color: var(--primary-darker);
    border: none;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999999;
    background-color: var(--primary-darker);
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0.75;
}

.modal {
    z-index: 99999999;
}

.modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: left;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 20px 20px 12px;
    border: none;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.modal-header .btn-close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
    background-image: none !important;
    font-size: 25px;
}

.modal-header .close span {
    font-size: 24px;
}

.modal-header .modal-icon-container {
    position: absolute;
    height: 150px;
    width: 150px;
    top: -88px;
    left: calc(50% - 75px);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-header .modal-icon-container span {
    font-size: 88px;
}

.modal-header .modal-icon-container.alter-container {
    border-radius: 0;
    background: none !important;
}

.modal-header .modal-icon-container.alter-container img {
    position: absolute;
    z-index: 1;
}

.modal-header .modal-icon-container.alter-container span {
    margin-top: 36px;
    z-index: 2;
}

.modal-title {
    color: var(--primary);
}

.modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
}

.modal-body p:last-child {
    margin-bottom: 0;
}

.modal-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: right;
    justify-content: flex-right;
    padding: 12px 20px;
    border-top: none;
}

.modal-footer> :not(:first-child) {
    margin-left: 0.25rem;
}

.modal-footer> :not(:last-child) {
    margin-right: 0.25rem;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }

    .modal-xl {
        max-width: 500px;
    }

    .modal-lg {
        max-width: 460px;
    }

    .modal-sm {
        max-width: 300px;
    }

    .modal-md {
        max-width: 400px;
    }
}

@media (min-width: 992px) {
    .modal-xl {
        max-width: 900px;
    }

    .modal-lg {
        max-width: 800px;
    }

    .modal-md {
        max-width: 640px;
    }

    .modal-sm {
        max-width: 500px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}

.modal.animated .modal-dialog {
    transform: translate(0, 0);
}

.modal.effect-scale .modal-dialog {
    transform: scale(0.7);
    opacity: 0;
    transition: all 0.3s;
}

.modal.effect-scale.show .modal-dialog {
    transform: scale(1);
    opacity: 1;
}

.modal.effect-slide-in-right .modal-dialog {
    transform: translateX(20%);
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

.modal.effect-slide-in-right.show .modal-dialog {
    transform: translateX(0);
    opacity: 1;
}

.modal.effect-slide-in-bottom .modal-dialog {
    transform: translateY(20%);
    opacity: 0;
    transition: all 0.3s;
}

.modal.effect-slide-in-bottom.show .modal-dialog {
    transform: translateY(0);
    opacity: 1;
}

.modal.effect-newspaper .modal-dialog {
    transform: scale(0) rotate(720deg);
    opacity: 0;
}

.modal.effect-newspaper.show~.modal-backdrop,
.modal.effect-newspaper .modal-dialog {
    transition: all 0.5s;
}

.modal.effect-newspaper.show .modal-dialog {
    transform: scale(1) rotate(0deg);
    opacity: 1;
}

.modal.effect-fall {
    -webkit-perspective: 1300px;
    -moz-perspective: 1300px;
    perspective: 1300px;
}

.modal.effect-fall .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: translateZ(600px) rotateX(20deg);
    opacity: 0;
}

.modal.effect-fall.show .modal-dialog {
    transition: all 0.3s ease-in;
    transform: translateZ(0px) rotateX(0deg);
    opacity: 1;
}

.modal.effect-flip-horizontal {
    perspective: 1300px;
}

.modal.effect-flip-horizontal .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: rotateY(-70deg);
    transition: all 0.3s;
    opacity: 0;
}

.modal.effect-flip-horizontal.show .modal-dialog {
    transform: rotateY(0deg);
    opacity: 1;
}

.modal.effect-flip-vertical {
    perspective: 1300px;
}

.modal.effect-flip-vertical .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: rotateX(-70deg);
    transition: all 0.3s;
    opacity: 0;
}

.modal.effect-flip-vertical.show .modal-dialog {
    transform: rotateX(0deg);
    opacity: 1;
}

.modal.effect-super-scaled .modal-dialog {
    transform: scale(2);
    opacity: 0;
    transition: all 0.3s;
}

.modal.effect-super-scaled.show .modal-dialog {
    transform: scale(1);
    opacity: 1;
}

.modal.effect-sign {
    perspective: 1300px;
}

.modal.effect-sign .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: rotateX(-60deg);
    transform-origin: 50% 0;
    opacity: 0;
    transition: all 0.3s;
}

.modal.effect-sign.show .modal-dialog {
    transform: rotateX(0deg);
    opacity: 1;
}

.modal.effect-rotate-bottom {
    perspective: 1300px;
}

.modal.effect-rotate-bottom .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: translateY(100%) rotateX(90deg);
    transform-origin: 0 100%;
    opacity: 0;
    transition: all 0.3s ease-out;
}

.modal.effect-rotate-bottom.show .modal-dialog {
    transform: translateY(0%) rotateX(0deg);
    opacity: 1;
}

.modal.effect-rotate-left {
    perspective: 1300px;
}

.modal.effect-rotate-left .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: translateZ(100px) translateX(-30%) rotateY(90deg);
    transform-origin: 0 100%;
    opacity: 0;
    transition: all 0.3s;
}

.modal.effect-rotate-left.show .modal-dialog {
    transform: translateZ(0px) translateX(0%) rotateY(0deg);
    opacity: 1;
}

.modal.effect-just-me .modal-dialog {
    transform: scale(0.8);
    opacity: 0;
    transition: all 0.3s;
}

.modal.effect-just-me .modal-content {
    background-color: transparent;
    border: 1px solid #4d4e50;
    padding: 0 9px;
}

.modal.effect-just-me .btn-close {
    text-shadow: none;
    color: #fff;
}

.modal.effect-just-me .modal-header {
    background-color: transparent;
    border-bottom-color: rgba(255, 255, 255, 0.1);
    padding-left: 0;
    padding-right: 0;
}

.modal.effect-just-me .modal-header h6 {
    color: #fff;
    font-weight: 500;
}

.modal.effect-just-me .modal-body {
    color: rgba(255, 255, 255, 0.8);
    padding-left: 0;
    padding-right: 0;
}

.modal.effect-just-me .modal-body h6 {
    color: #fff;
}

.modal.effect-just-me .modal-footer {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    border-top-color: rgba(255, 255, 255, 0.1);
}

.modal.effect-just-me.show~.modal-backdrop {
    opacity: 0.96;
}

.modal.effect-just-me.show .modal-dialog {
    transform: scale(1);
    opacity: 1;
}

.stop-scrolling {
    overflow: auto;
}

.custom-dropzone-container {
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    min-height: 300px;
    border-radius: 16px !important;
    border: 2px dotted var(--neutral-50) !important;
}

.custom-dropzone-container .custom_dropzone_icon {
    height: 80px;
    width: 80px;
    background-color: var(--info-25);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--info);
}

.custom-dropzone-container .custom_dropzone_icon span {
    font-size: 44px;
}

.custom-dropzone-container h6 {
    color: var(--info);
}

.custom-dropzone-container p {
    color: var(--neutral-80);
}

.custom-files-dropzone .input-file {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    background-color: var(--neutral-20);
    border-radius: 10px;
}

.custom-files-dropzone .input-file .input-file-description {
    display: flex;
    align-items: center;
}

.custom-files-dropzone .input-file .input-file-description span,
.custom-files-dropzone .input-file .input-file-description .file-name {
    color: var(--info);
}

.custom-files-dropzone .input-file .input-file-description .file-size {
    color: var(--neutral-70);
}

.custom-files-dropzone .input-file .input-file-state.success-state {
    color: var(--success);
}

.custom-files-dropzone .input-file .input-file-state.error-state {
    color: var(--error-75);
}

.custom-files-dropzone .input-file:not(:first-child) {
    margin-top: 12px;
}

/* ###### 3.10 Nav ###### */
.nav-link {
    transition: 0.3s color;
}

.nav-link:hover,
.nav-link:focus,
.nav-link:active {
    outline: none;
}

.nav-pills .nav-link {
    color: #4d5875;
}

.nav-pills .nav-link:hover,
.nav-pills .nav-link:focus {
    color: #323251;
}

.nav-pills .nav-link.active {
    border-radius: 5px;
    background-color: var(--primary);
}

.nav-pills .nav-link.active:hover,
.nav-pills .nav-link.active:focus {
    color: #fff;
}

.nav-dark .nav-link {
    color: #949eb7;
}

.nav-dark .nav-link:hover,
.nav-dark .nav-link:focus {
    color: #fff;
}

.nav-tabs {
    border-bottom-width: 0;
}

.nav-tabs .nav-link {
    background-color: rgba(255, 255, 255, 0.5);
    border-width: 0;
    border-radius: 0;
    padding: 10px 15px;
    line-height: 1.428;
    color: #4d5875;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    background-color: rgba(255, 255, 255, 0.5);
    color: #323251;
}

.nav-tabs .nav-link+.nav-link {
    margin-left: 1px;
}

.nav-tabs .nav-link.active {
    background-color: #fff;
    color: #323251;
    font-weight: 500;
    letter-spacing: -0.1px;
}

@media (min-width: 768px) {
    .nav-tabs .nav-link {
        padding: 10px 20px;
    }
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    display: block;
    padding: 0.2rem 0rem 0rem 0.2rem;
}

.nav-link:hover,
.nav-link:focus {
    text-decoration: none;
}

.nav-link.disabled {
    color: #7987a1;
    pointer-events: none;
    cursor: default;
}

.latest-tasks .nav-tabs {
    border-bottom: 0;
}

.nav-tabs {
    border-bottom: 1px solid #ededf5;
}

.nav-tabs .nav-item {
    margin-bottom: -1px;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-color: #f0f0f8 #f0f0f8 #f0f0f8;
}

.nav-tabs .nav-link.disabled {
    color: #7987a1;
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs .nav-link.active {
    color: #4d5875;
    background-color: #fff;
    border-color: #fff;
}

.nav-tabs .nav-item.show .nav-link {
    color: #4d5875;
    background-color: #fff;
    border-color: #e1e1ef #e1e1ef #fff;
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-pills .nav-link {
    border-radius: 5px;
}

.nav-pills .nav-link.active {
    color: #fff;
    background-color: var(--primary);
}

.nav-pills .show>.nav-link {
    color: #fff;
    background-color: var(--primary);
}

.nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center;
}

.nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

/* ###### 5.7 Nav   ###### */
@media (min-width: 768px) {
    .main-nav {
        align-items: center;
    }
}

.main-nav .nav-link {
    display: block;
    color: #4d5875;
    padding: 0;
    position: relative;
    line-height: normal;
}

.main-nav .nav-link:hover,
.main-nav .nav-link:focus {
    color: #323251;
}

.main-nav .nav-link+.nav-link {
    padding-top: 12px;
    margin-top: 12px;
    border-top: 1px solid #ededf5;
}

.main-nav .nav-link.active {
    color: var(--primary) !important;
}

@media (min-width: 768px) {
    .main-nav .nav-link+.nav-link {
        padding-top: 0;
        margin-top: 0;
        border-top: 0;
        padding-left: 15px;
        margin-left: 15px;
        border-left: 1px solid #ededf5;
    }
}

.main-nav-column {
    flex-direction: column;
}

.main-nav-column .nav-link {
    padding: 0;
    height: 38px;
    color: #7987a1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 7px 14px;
}

.main-nav-column .nav-link i {
    font-size: 24px;
    line-height: 0;
    width: 20px;
    margin-right: 12px;
    text-align: center;
    transition: all 0.2s ease-in-out;
}

.main-nav-column .nav-link i:not([class*=" tx-"]) {
    color: #7987a1;
}

.main-nav-column .nav-link i.typcn {
    line-height: 0.9;
}

.main-nav-column .nav-link span {
    font-weight: 400;
    font-size: 13px;
    color: #949eb7;
    margin-left: auto;
}

.main-nav-column .nav-link:hover,
.main-nav-column .nav-link:focus,
.main-nav-column .nav-link:hover i:not([class*=" tx-"]),
.main-nav-column .nav-link:focus i:not([class*=" tx-"]) {
    color: #323251;
}

.main-nav-column .nav-link.active {
    position: relative;
    background: var(--primary02);
    border-radius: 5px;
    color: var(--primary);
}

.main-nav-column .nav-link.active::before {
    content: "";
    position: absolute;
    top: 6px;
    bottom: 6px;
    left: -28px;
    width: 3px;
    background-color: var(--primary);
    border-radius: 3px;
    display: none;
}

.main-nav-column .nav-link.active:hover,
.main-nav-column .nav-link.active:focus,
.main-nav-column .nav-link.active i,
.main-nav-column .nav-link.active:hover i,
.main-nav-column .nav-link.active:focus i {
    color: var(--primary);
}

.main-nav-column.sm .nav-link {
    font-size: 0.875rem;
    font-weight: 400;
    padding: 10px 0;
}

.main-nav-column.sm .nav-link i {
    font-size: 21px;
}

@media (prefers-reduced-motion: reduce) {
    .main-nav-column .nav-link i {
        transition: none;
    }
}

.main-nav-dark .nav-link {
    color: rgba(255, 255, 255, 0.7);
}

.main-nav-dark .nav-link:hover,
.main-nav-dark .nav-link:focus {
    color: #fff;
}

.main-nav-dark .nav-link+.nav-link {
    border-color: #4d5875;
}

.main-nav-dark .nav-link.active {
    color: var(--primary);
}

.main-nav-colored-bg .nav-link+.nav-link {
    border-color: rgba(180, 166, 166, 0.4);
}

.main-nav-colored-bg .nav-link.active {
    color: #fff !important;
}

.main-nav-line {
    position: relative;
}

.main-nav-line .nav-link {
    padding: 0;
    color: #4d5875;
    position: relative;
    padding: 0px 10px 0px 10px;
    color: #37326d;
    position: relative;
}

.main-nav-line .nav-link:hover,
.main-nav-line .nav-link:focus {
    color: #323251;
}

.main-nav-line .nav-link.active {
    color: #323251;
}

.main-nav-line.main-nav-dark .nav-link {
    color: rgba(255, 255, 255, 0.7);
}

.main-nav-line.main-nav-dark .nav-link:hover,
.main-nav-line.main-nav-dark .nav-link:focus {
    color: #fff;
}

.main-nav-line.main-nav-dark .nav-link.active {
    color: #fff;
}

.main-nav-line.main-nav-dark .nav-link.active::before {
    background-color: #fff;
}

.main-nav-tabs {
    padding: 15px 15px 0;
    background-color: #e1e1ef;
}

.main-nav-tabs .lSSlideOuter {
    position: relative;
    padding-left: 32px;
    padding-right: 35px;
}

.main-nav-tabs .lSSlideWrapper {
    overflow: visible;
}

.main-nav-tabs .lSAction>a {
    display: block;
    height: 40px;
    top: 16px;
    opacity: 1;
    background-color: #d6d6e6;
    background-image: none;
}

.main-nav-tabs .lSAction>a:hover,
.main-nav-tabs .lSAction>a:focus {
    background-color: #a5afc4;
}

.main-nav-tabs .lSAction>a::before {
    font-family: "Ionicons";
    font-size: 18px;
    position: absolute;
    top: -4px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-nav-tabs .lSAction>a.lSPrev {
    left: -32px;
}

.main-nav-tabs .lSAction>a.lSPrev::before {
    content: "\f3cf";
}

.main-nav-tabs .lSAction>a.lSNext {
    right: -35px;
}

.main-nav-tabs .lSAction>a.lSNext::before {
    content: "\f3d1";
}

.main-nav-tabs .lSAction>a.disabled {
    background-color: #f0f0f8;
    color: #fff;
}

.main-nav-tabs .lightSlider {
    display: flex;
}

.main-nav-tabs .tab-item {
    flex-shrink: 0;
    display: block;
    float: none;
    min-width: 150px;
}

.main-nav-tabs .tab-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    line-height: 1.428;
    color: #4d5875;
    white-space: nowrap;
    background-color: #f0f0f8;
}

.main-nav-tabs .tab-link:hover,
.main-nav-tabs .tab-link:focus {
    background-color: #f9f9fb;
}

.main-nav-tabs .tab-link.active {
    background-color: #fff;
    color: #323251;
    font-weight: 500;
}

.main-tab-pane {
    display: none;
}

.main-tab-pane.active {
    display: block;
}

.nav .nav-item .dropdown-menu {
    top: 48px;
    border-radius: 0px 0px 5px 5px;
    min-width: 245px;
    -webkit-box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
    box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
}

.nav .nav-item .dropdown-menu .dropdown-item.dropdown-header {
    background: #1b2d8f;
    height: 85px;
    color: #fff;
}

.nav-link.with-sub .angle {
    right: 25px;
    top: 18px;
}

.nav-link.with-sub .side-badge {
    right: 44px;
    position: absolute;
}

.nav-link .side-badge {
    right: 20px;
    position: absolute;
}

.nav-sub-item .sub-angle {
    right: 25px;
    top: 18px;
    margin-left: auto;
    font-size: 11px;
}

.nav-wrapper.crypto .label {
    font-weight: 500;
}

.main-nav-column .nav-link.active span {
    color: var(--primary);
    font-size: 14px;
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
}

@media (max-width: 320px) {
    .main-toggle.on span {
        left: 29px;
    }
}

.icon-navbar .nav-pills-circle .nav-link {
    padding: 0.75rem 1rem;
}

@media (min-width: 768px) {
    .bg-primary .main-nav .nav-link+.nav-link {
        border-left: 1px solid #07b79c !important;
    }

    .bg-secondary .main-nav .nav-link+.nav-link {
        border-left: 1px solid #f52756 !important;
    }

    .bg-info .main-nav .nav-link+.nav-link {
        border-left: 1px solid #26b7f0 !important;
    }
}

/* ###### 9.8 Typography   ###### */
/* Estilos de cabeceras */
.heading-1,
.heading-1-medium,
.heading-1-bold {
    font-size: 60px;
    line-height: 68px;
    font-weight: 400;
}

.heading-1-medium {
    font-weight: 500;
}

.heading-1-bold {
    font-weight: 700;
}

.heading-2,
.heading-2-medium,
.heading-2-bold {
    font-size: 40px;
    line-height: 48px;
    font-weight: 400;
}

.heading-2-medium {
    font-weight: 500;
}

.heading-2-bold {
    font-weight: 700;
}

.heading-3,
.heading-3-medium,
.heading-3-bold {
    font-size: 32px;
    line-height: 40px;
    font-weight: 400;
}

.heading-3-medium {
    font-weight: 500;
}

.heading-3-bold {
    font-weight: 700;
}

.heading-4,
.heading-4-medium,
.heading-4-bold {
    font-size: 22px;
    line-height: 30px;
    font-weight: 400;
}

.heading-4-medium {
    font-weight: 500;
}

.heading-4-bold {
    font-weight: 700;
}

.heading-5,
.heading-5-medium,
.heading-5-bold {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
}

.heading-5-medium {
    font-weight: 500;
}

.heading-5-bold {
    font-weight: 700;
}

.heading-6,
.heading-6-medium,
.heading-6-bold {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
}

.heading-6-medium {
    font-weight: 500;
}

.heading-6-bold {
    font-weight: 700;
}

/* BODY STYLES */
.tx-body-1,
.ngx-datatable.custom-datatable .datatable-body .datatable-body-cell,
.table tbody tr td,
.tx-body-1-medium,
.tx-body-1-bold,
.ngx-datatable.custom-datatable .datatable-header .datatable-header-cell,
.table tbody tr th,
.table thead th,
.table thead td,
.accordion-button,
.panel-heading1 .panel-title1 a {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}

.tx-body-1-medium {
    font-weight: 500;
}

.tx-body-1-bold,
.ngx-datatable.custom-datatable .datatable-header .datatable-header-cell,
.table tbody tr th,
.table thead th,
.table thead td,
.accordion-button,
.panel-heading1 .panel-title1 a {
    font-weight: 700;
}

.tx-body-2,
.pagination .page-link,
.tx-body-2-medium,
.tx-body-2-bold,
.modal-accordion .accordion-button {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
}

.tx-body-2-medium {
    font-weight: 500;
}

.tx-body-2-bold,
.modal-accordion .accordion-button {
    font-weight: 700;
}

/* TAG STYLES */
.tx-tag {
    font-size: 10px;
    line-height: 14px;
    font-weight: 400;
}

.tx-tag-medium {
    font-size: 10px;
    line-height: 14px;
    font-weight: 500;
}

.tx-tag-card {
    font-size: 8px;
    line-height: 10px;
    font-weight: 400;
}

.tx-tag-card-medium {
    font-size: 8px;
    line-height: 10px;
    font-weight: 500;
}

/* FORM STYLES */
.tx-button {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
}

.tx-black {
    font-weight: 900;
}

.tx-bold {
    font-weight: 700;
}

.tx-semibold {
    font-weight: 600;
}

.tx-medium {
    font-weight: 500;
}

.tx-normal {
    font-weight: 400;
}

.tx-light {
    font-weight: 300;
}

.tx-thin {
    font-weight: 200;
}

.tx-xthin {
    font-weight: 100;
}

.tx-sserif {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.tx-roboto {
    font-family: "Roboto", sans-serif;
}

.tx-white {
    color: #fff;
}

.tx-facebook {
    color: #3b5998;
}

.tx-pinterest {
    color: #cd2029;
}

.tx-google-plus {
    color: #d34836;
}

.tx-twitter {
    color: #429cd6;
}

.tx-black {
    color: #000;
}

.tx-primary {
    color: var(--primary);
}

.tx-primary-75 {
    color: var(--primary-75);
}

.tx-success {
    color: var(--success);
}

.tx-warning {
    color: var(--warning);
}

.tx-danger {
    color: #f34343;
}

.tx-info {
    color: var(--info);
}

.tx-inverse {
    color: #7987a1;
}

.tx-teal {
    color: #29ddee;
}

.tx-dark {
    color: #323251;
}

.tx-indigo {
    color: var(--primary);
}

.tx-purple {
    color: #ad4ffa;
}

.tx-orange {
    color: #fd7e14;
}

.tx-pink {
    color: #f754fa;
}

.tx-gray-100 {
    color: #f9f9fb;
}

.tx-gray-200 {
    color: #f0f0f8;
}

.tx-gray-300 {
    color: #e1e1ef;
}

.tx-gray-400 {
    color: #d6d6e6;
}

.tx-gray-500 {
    color: #949eb7;
}

.tx-gray-600 {
    color: #7987a1;
}

.tx-gray-700 {
    color: #4d5875;
}

.tx-gray-800 {
    color: #383853;
}

.tx-gray-900 {
    color: #323251;
}

.tx-white-2 {
    color: rgba(255, 255, 255, 0.2);
}

.tx-white-3 {
    color: rgba(255, 255, 255, 0.3);
}

.tx-white-4 {
    color: rgba(255, 255, 255, 0.4);
}

.tx-white-5 {
    color: rgba(255, 255, 255, 0.5);
}

.tx-white-6 {
    color: rgba(255, 255, 255, 0.6);
}

.tx-white-7 {
    color: rgba(255, 255, 255, 0.7);
}

.tx-white-8 {
    color: rgba(255, 255, 255, 0.8);
}

.tx-neutral {
    color: var(--neutral-text);
}

.tx-neutral-20 {
    color: var(--neutral-20);
}

.tx-neutral-30 {
    color: var(--neutral-30);
}

.tx-neutral-40 {
    color: var(--neutral-40);
}

.tx-neutral-50 {
    color: var(--neutral-50);
}

.tx-neutral-60 {
    color: var(--neutral-60);
}

.tx-neutral-70 {
    color: var(--neutral-70);
}

.tx-neutral-80 {
    color: var(--neutral-80);
}

.tx-neutral-90 {
    color: var(--neutral-90);
}

.tx-neutral-darker {
    color: var(--neutral-darker);
}

.tx-spacing-1 {
    letter-spacing: 0.5px;
}

.tx-spacing-2 {
    letter-spacing: 1px;
}

.tx-spacing-3 {
    letter-spacing: 1.5px;
}

.tx-spacing-4 {
    letter-spacing: 2px;
}

.tx-spacing-5 {
    letter-spacing: 2.5px;
}

.tx-spacing-6 {
    letter-spacing: 3px;
}

.tx-spacing-7 {
    letter-spacing: 3.5px;
}

.tx-spacing-8 {
    letter-spacing: 4px;
}

.tx-spacing--1 {
    letter-spacing: -0.5px;
}

.tx-spacing--2 {
    letter-spacing: -1px;
}

.tx-spacing--3 {
    letter-spacing: -1.5px;
}

.tx-spacing--4 {
    letter-spacing: -2px;
}

.tx-spacing--5 {
    letter-spacing: -2.5px;
}

.tx-spacing--6 {
    letter-spacing: -3px;
}

.tx-spacing--7 {
    letter-spacing: -3.5px;
}

.tx-spacing--8 {
    letter-spacing: -4px;
}

.tx-uppercase {
    text-transform: uppercase;
}

.tx-lowercase {
    text-transform: lowercase;
}

.tx-transform-none {
    text-transform: none;
}

.tx-center {
    text-align: center;
}

.tx-right {
    text-align: right;
}

.tx-left {
    text-align: left;
}

.tx-center-f {
    text-align: center;
}

.tx-right-f {
    text-align: right;
}

.tx-left-f {
    text-align: left;
}

.tx-italic {
    font-style: italic;
}

.tx-style-normal {
    font-style: normal;
}

.lh-base {
    line-height: 1.5;
}

.lh-normal {
    line-height: normal;
}

.lh-0 {
    line-height: 0;
}

.lh--1 {
    line-height: 0.1;
}

.lh--2 {
    line-height: 0.2;
}

.lh--3 {
    line-height: 0.3;
}

.lh--4 {
    line-height: 0.4;
}

.lh--5 {
    line-height: 0.5;
}

.lh--6 {
    line-height: 0.6;
}

.lh--7 {
    line-height: 0.7;
}

.lh--8 {
    line-height: 0.8;
}

.lh--9 {
    line-height: 0.9;
}

.lh-1 {
    line-height: 1.1;
}

.lh-2 {
    line-height: 1.2;
}

.lh-3 {
    line-height: 1.3;
}

.lh-4 {
    line-height: 1.4;
}

.lh-5 {
    line-height: 1.5;
}

.lh-6 {
    line-height: 1.6;
}

.lh-7 {
    line-height: 1.7;
}

.lh-8 {
    line-height: 1.8;
}

.lh-9 {
    line-height: 1.9;
}

.lh-10 {
    line-height: 2;
}

.lh-11 {
    line-height: 2.1;
}

.lh-12 {
    line-height: 2.2;
}

.lh-13 {
    line-height: 2.3;
}

.lh-14 {
    line-height: 2.4;
}

.lh-15 {
    line-height: 2.5;
}

.valign-top {
    vertical-align: top;
}

.valign-middle {
    vertical-align: middle;
}

.valign-bottom {
    vertical-align: baseline;
}

.valign-top-f {
    vertical-align: top;
}

.valign-middle-f {
    vertical-align: middle;
}

.valign-bottom-f {
    vertical-align: baseline;
}

.tx-base {
    font-size: 0.875rem;
}

.tx-8 {
    font-size: 8px;
}

.tx-8-f {
    font-size: 8px;
}

.tx-9 {
    font-size: 9px;
}

.tx-9-f {
    font-size: 9px;
}

.tx-10 {
    font-size: 10px;
}

.tx-10-f {
    font-size: 10px;
}

.tx-11 {
    font-size: 11px;
}

.tx-11-f {
    font-size: 11px;
}

.tx-12 {
    font-size: 12px;
}

.tx-12-f {
    font-size: 12px;
}

.tx-13 {
    font-size: 13px;
}

.tx-13-f {
    font-size: 13px;
}

.tx-14 {
    font-size: 14px;
}

.tx-14-f {
    font-size: 14px;
}

.tx-15 {
    font-size: 15px !important;
}

.tx-15-f {
    font-size: 15px;
}

.tx-16 {
    font-size: 16px;
}

.tx-16-f {
    font-size: 16px;
}

.tx-17 {
    font-size: 17px;
}

.tx-18 {
    font-size: 18px !important;
}

.tx-18-f {
    font-size: 18px;
}

.tx-20 {
    font-size: 20px !important;
}

.tx-20-f {
    font-size: 20px;
}

.tx-21 {
    font-size: 21px;
}

.tx-21-f {
    font-size: 21px;
}

.tx-22 {
    font-size: 22px;
}

.tx-22-f {
    font-size: 22px;
}

.tx-24 {
    font-size: 24px;
}

.tx-24-f {
    font-size: 24px;
}

.tx-26 {
    font-size: 26px;
}

.tx-26-f {
    font-size: 26px;
}

.tx-28 {
    font-size: 28px;
}

.tx-28-f {
    font-size: 28px;
}

.tx-30 {
    font-size: 30px;
}

.tx-30-f {
    font-size: 30px;
}

.tx-32 {
    font-size: 32px;
}

.tx-32-f {
    font-size: 32px;
}

.tx-34 {
    font-size: 34px;
}

.tx-34-f {
    font-size: 34px;
}

.tx-36 {
    font-size: 36px;
}

.tx-36-f {
    font-size: 36px;
}

.tx-38 {
    font-size: 38px;
}

.tx-38-f {
    font-size: 38px;
}

.tx-40 {
    font-size: 40px;
}

.tx-40-f {
    font-size: 40px;
}

.tx-42 {
    font-size: 42px;
}

.tx-42-f {
    font-size: 42px;
}

.tx-44 {
    font-size: 44px;
}

.tx-44-f {
    font-size: 44px;
}

.tx-46 {
    font-size: 46px;
}

.tx-46-f {
    font-size: 46px;
}

.tx-48 {
    font-size: 48px;
}

.tx-48-f {
    font-size: 48px;
}

.tx-50 {
    font-size: 50px;
}

.tx-50-f {
    font-size: 50px;
}

.tx-52 {
    font-size: 52px;
}

.tx-52-f {
    font-size: 52px;
}

.tx-54 {
    font-size: 54px;
}

.tx-54-f {
    font-size: 54px;
}

.tx-56 {
    font-size: 56px;
}

.tx-56-f {
    font-size: 56px;
}

.tx-58 {
    font-size: 58px;
}

.tx-58-f {
    font-size: 58px;
}

.tx-60 {
    font-size: 60px;
}

.tx-60-f {
    font-size: 60px;
}

.tx-62 {
    font-size: 62px;
}

.tx-62-f {
    font-size: 62px;
}

.tx-64 {
    font-size: 64px;
}

.tx-64-f {
    font-size: 64px;
}

.tx-66 {
    font-size: 66px;
}

.tx-66-f {
    font-size: 66px;
}

.tx-68 {
    font-size: 68px;
}

.tx-68-f {
    font-size: 68px;
}

.tx-70 {
    font-size: 70px;
}

.tx-70-f {
    font-size: 70px;
}

.tx-72 {
    font-size: 72px;
}

.tx-72-f {
    font-size: 72px;
}

.tx-74 {
    font-size: 74px;
}

.tx-74-f {
    font-size: 74px;
}

.tx-76 {
    font-size: 76px;
}

.tx-76-f {
    font-size: 76px;
}

.tx-78 {
    font-size: 78px;
}

.tx-78-f {
    font-size: 78px;
}

.tx-80 {
    font-size: 80px;
}

.tx-80-f {
    font-size: 80px;
}

.tx-82 {
    font-size: 82px;
}

.tx-82-f {
    font-size: 82px;
}

.tx-84 {
    font-size: 84px;
}

.tx-84-f {
    font-size: 84px;
}

.tx-86 {
    font-size: 86px;
}

.tx-86-f {
    font-size: 86px;
}

.tx-88 {
    font-size: 88px;
}

.tx-88-f {
    font-size: 88px;
}

.tx-90 {
    font-size: 90px;
}

.tx-90-f {
    font-size: 90px;
}

.tx-92 {
    font-size: 92px;
}

.tx-92-f {
    font-size: 92px;
}

.tx-94 {
    font-size: 94px;
}

.tx-94-f {
    font-size: 94px;
}

.tx-96 {
    font-size: 96px;
}

.tx-96-f {
    font-size: 96px;
}

.tx-98 {
    font-size: 98px;
}

.tx-98-f {
    font-size: 98px;
}

.tx-100 {
    font-size: 100px;
}

.tx-100-f {
    font-size: 100px;
}

.tx-102 {
    font-size: 102px;
}

.tx-102-f {
    font-size: 102px;
}

.tx-104 {
    font-size: 104px;
}

.tx-104-f {
    font-size: 104px;
}

.tx-106 {
    font-size: 106px;
}

.tx-106-f {
    font-size: 106px;
}

.tx-108 {
    font-size: 108px;
}

.tx-108-f {
    font-size: 108px;
}

.tx-110 {
    font-size: 110px;
}

.tx-110-f {
    font-size: 110px;
}

.tx-112 {
    font-size: 112px;
}

.tx-112-f {
    font-size: 112px;
}

.tx-114 {
    font-size: 114px;
}

.tx-114-f {
    font-size: 114px;
}

.tx-116 {
    font-size: 116px;
}

.tx-116-f {
    font-size: 116px;
}

.tx-118 {
    font-size: 118px;
}

.tx-118-f {
    font-size: 118px;
}

.tx-120 {
    font-size: 120px;
}

.tx-120-f {
    font-size: 120px;
}

.tx-122 {
    font-size: 122px;
}

.tx-122-f {
    font-size: 122px;
}

.tx-124 {
    font-size: 124px;
}

.tx-124-f {
    font-size: 124px;
}

.tx-126 {
    font-size: 126px;
}

.tx-126-f {
    font-size: 126px;
}

.tx-128 {
    font-size: 128px;
}

.tx-128-f {
    font-size: 128px;
}

.tx-130 {
    font-size: 130px;
}

.tx-130-f {
    font-size: 130px;
}

.tx-132 {
    font-size: 132px;
}

.tx-132-f {
    font-size: 132px;
}

.tx-134 {
    font-size: 134px;
}

.tx-134-f {
    font-size: 134px;
}

.tx-136 {
    font-size: 136px;
}

.tx-136-f {
    font-size: 136px;
}

.tx-138 {
    font-size: 138px;
}

.tx-138-f {
    font-size: 138px;
}

.tx-140 {
    font-size: 140px;
}

.tx-140-f {
    font-size: 140px;
}

@media (min-width: 480px) {
    .tx-xs-8 {
        font-size: 8px;
    }

    .tx-xs-8-f {
        font-size: 8px;
    }

    .tx-xs-9 {
        font-size: 9px;
    }

    .tx-xs-9-f {
        font-size: 9px;
    }

    .tx-xs-10 {
        font-size: 10px;
    }

    .tx-xs-10-f {
        font-size: 10px;
    }

    .tx-xs-11 {
        font-size: 11px;
    }

    .tx-xs-11-f {
        font-size: 11px;
    }

    .tx-xs-12 {
        font-size: 12px;
    }

    .tx-xs-12-f {
        font-size: 12px;
    }

    .tx-xs-13 {
        font-size: 13px;
    }

    .tx-xs-13-f {
        font-size: 13px;
    }

    .tx-xs-14 {
        font-size: 14px;
    }

    .tx-xs-14-f {
        font-size: 14px;
    }

    .tx-xs-15 {
        font-size: 15px;
    }

    .tx-xs-15-f {
        font-size: 15px;
    }

    .tx-xs-16 {
        font-size: 16px;
    }

    .tx-xs-16-f {
        font-size: 16px;
    }

    .tx-xs-18 {
        font-size: 18px;
    }

    .tx-xs-18-f {
        font-size: 18px;
    }

    .tx-xs-20 {
        font-size: 20px;
    }

    .tx-xs-20-f {
        font-size: 20px;
    }

    .tx-xs-22 {
        font-size: 22px;
    }

    .tx-xs-22-f {
        font-size: 22px;
    }

    .tx-xs-24 {
        font-size: 24px;
    }

    .tx-xs-24-f {
        font-size: 24px;
    }

    .tx-xs-26 {
        font-size: 26px;
    }

    .tx-xs-26-f {
        font-size: 26px;
    }

    .tx-xs-28 {
        font-size: 28px;
    }

    .tx-xs-28-f {
        font-size: 28px;
    }

    .tx-xs-30 {
        font-size: 30px;
    }

    .tx-xs-30-f {
        font-size: 30px;
    }

    .tx-xs-32 {
        font-size: 32px;
    }

    .tx-xs-32-f {
        font-size: 32px;
    }

    .tx-xs-34 {
        font-size: 34px;
    }

    .tx-xs-34-f {
        font-size: 34px;
    }

    .tx-xs-36 {
        font-size: 36px;
    }

    .tx-xs-36-f {
        font-size: 36px;
    }

    .tx-xs-38 {
        font-size: 38px;
    }

    .tx-xs-38-f {
        font-size: 38px;
    }

    .tx-xs-40 {
        font-size: 40px;
    }

    .tx-xs-40-f {
        font-size: 40px;
    }

    .tx-xs-42 {
        font-size: 42px;
    }

    .tx-xs-42-f {
        font-size: 42px;
    }

    .tx-xs-44 {
        font-size: 44px;
    }

    .tx-xs-44-f {
        font-size: 44px;
    }

    .tx-xs-46 {
        font-size: 46px;
    }

    .tx-xs-46-f {
        font-size: 46px;
    }

    .tx-xs-48 {
        font-size: 48px;
    }

    .tx-xs-48-f {
        font-size: 48px;
    }

    .tx-xs-50 {
        font-size: 50px;
    }

    .tx-xs-50-f {
        font-size: 50px;
    }

    .tx-xs-52 {
        font-size: 52px;
    }

    .tx-xs-52-f {
        font-size: 52px;
    }

    .tx-xs-54 {
        font-size: 54px;
    }

    .tx-xs-54-f {
        font-size: 54px;
    }

    .tx-xs-56 {
        font-size: 56px;
    }

    .tx-xs-56-f {
        font-size: 56px;
    }

    .tx-xs-58 {
        font-size: 58px;
    }

    .tx-xs-58-f {
        font-size: 58px;
    }

    .tx-xs-60 {
        font-size: 60px;
    }

    .tx-xs-60-f {
        font-size: 60px;
    }

    .tx-xs-62 {
        font-size: 62px;
    }

    .tx-xs-62-f {
        font-size: 62px;
    }

    .tx-xs-64 {
        font-size: 64px;
    }

    .tx-xs-64-f {
        font-size: 64px;
    }

    .tx-xs-66 {
        font-size: 66px;
    }

    .tx-xs-66-f {
        font-size: 66px;
    }

    .tx-xs-68 {
        font-size: 68px;
    }

    .tx-xs-68-f {
        font-size: 68px;
    }

    .tx-xs-70 {
        font-size: 70px;
    }

    .tx-xs-70-f {
        font-size: 70px;
    }

    .tx-xs-72 {
        font-size: 72px;
    }

    .tx-xs-72-f {
        font-size: 72px;
    }

    .tx-xs-74 {
        font-size: 74px;
    }

    .tx-xs-74-f {
        font-size: 74px;
    }

    .tx-xs-76 {
        font-size: 76px;
    }

    .tx-xs-76-f {
        font-size: 76px;
    }

    .tx-xs-78 {
        font-size: 78px;
    }

    .tx-xs-78-f {
        font-size: 78px;
    }

    .tx-xs-80 {
        font-size: 80px;
    }

    .tx-xs-80-f {
        font-size: 80px;
    }

    .tx-xs-82 {
        font-size: 82px;
    }

    .tx-xs-82-f {
        font-size: 82px;
    }

    .tx-xs-84 {
        font-size: 84px;
    }

    .tx-xs-84-f {
        font-size: 84px;
    }

    .tx-xs-86 {
        font-size: 86px;
    }

    .tx-xs-86-f {
        font-size: 86px;
    }

    .tx-xs-88 {
        font-size: 88px;
    }

    .tx-xs-88-f {
        font-size: 88px;
    }

    .tx-xs-90 {
        font-size: 90px;
    }

    .tx-xs-90-f {
        font-size: 90px;
    }

    .tx-xs-92 {
        font-size: 92px;
    }

    .tx-xs-92-f {
        font-size: 92px;
    }

    .tx-xs-94 {
        font-size: 94px;
    }

    .tx-xs-94-f {
        font-size: 94px;
    }

    .tx-xs-96 {
        font-size: 96px;
    }

    .tx-xs-96-f {
        font-size: 96px;
    }

    .tx-xs-98 {
        font-size: 98px;
    }

    .tx-xs-98-f {
        font-size: 98px;
    }

    .tx-xs-100 {
        font-size: 100px;
    }

    .tx-xs-100-f {
        font-size: 100px;
    }

    .tx-xs-102 {
        font-size: 102px;
    }

    .tx-xs-102-f {
        font-size: 102px;
    }

    .tx-xs-104 {
        font-size: 104px;
    }

    .tx-xs-104-f {
        font-size: 104px;
    }

    .tx-xs-106 {
        font-size: 106px;
    }

    .tx-xs-106-f {
        font-size: 106px;
    }

    .tx-xs-108 {
        font-size: 108px;
    }

    .tx-xs-108-f {
        font-size: 108px;
    }

    .tx-xs-110 {
        font-size: 110px;
    }

    .tx-xs-110-f {
        font-size: 110px;
    }

    .tx-xs-112 {
        font-size: 112px;
    }

    .tx-xs-112-f {
        font-size: 112px;
    }

    .tx-xs-114 {
        font-size: 114px;
    }

    .tx-xs-114-f {
        font-size: 114px;
    }

    .tx-xs-116 {
        font-size: 116px;
    }

    .tx-xs-116-f {
        font-size: 116px;
    }

    .tx-xs-118 {
        font-size: 118px;
    }

    .tx-xs-118-f {
        font-size: 118px;
    }

    .tx-xs-120 {
        font-size: 120px;
    }

    .tx-xs-120-f {
        font-size: 120px;
    }

    .tx-xs-122 {
        font-size: 122px;
    }

    .tx-xs-122-f {
        font-size: 122px;
    }

    .tx-xs-124 {
        font-size: 124px;
    }

    .tx-xs-124-f {
        font-size: 124px;
    }

    .tx-xs-126 {
        font-size: 126px;
    }

    .tx-xs-126-f {
        font-size: 126px;
    }

    .tx-xs-128 {
        font-size: 128px;
    }

    .tx-xs-128-f {
        font-size: 128px;
    }

    .tx-xs-130 {
        font-size: 130px;
    }

    .tx-xs-130-f {
        font-size: 130px;
    }

    .tx-xs-132 {
        font-size: 132px;
    }

    .tx-xs-132-f {
        font-size: 132px;
    }

    .tx-xs-134 {
        font-size: 134px;
    }

    .tx-xs-134-f {
        font-size: 134px;
    }

    .tx-xs-136 {
        font-size: 136px;
    }

    .tx-xs-136-f {
        font-size: 136px;
    }

    .tx-xs-138 {
        font-size: 138px;
    }

    .tx-xs-138-f {
        font-size: 138px;
    }

    .tx-xs-140 {
        font-size: 140px;
    }

    .tx-xs-140-f {
        font-size: 140px;
    }

    .tx-xs-base {
        font-size: 0.875rem;
    }

    .tx-xs-bold {
        font-weight: 700;
    }

    .tx-xs-semibold {
        font-weight: 600;
    }

    .tx-xs-medium {
        font-weight: 500;
    }

    .tx-xs-normal {
        font-weight: 400;
    }

    .tx-xs-light {
        font-weight: 300;
    }

    .tx-xs-thin {
        font-weight: 200;
    }

    .tx-xs-xthin {
        font-weight: 100;
    }

    .lh-xs-0 {
        line-height: 0;
    }

    .lh-xs-1 {
        line-height: 1.1;
    }

    .lh-xs-2 {
        line-height: 1.2;
    }

    .lh-xs-3 {
        line-height: 1.3;
    }

    .lh-xs-4 {
        line-height: 1.4;
    }

    .lh-xs-5 {
        line-height: 1.5;
    }

    .lh-xs-6 {
        line-height: 1.6;
    }

    .lh-xs-7 {
        line-height: 1.7;
    }

    .lh-xs-8 {
        line-height: 1.8;
    }

    .lh-xs-9 {
        line-height: 1.9;
    }

    .lh-xs-10 {
        line-height: 2;
    }

    .lh-xs-11 {
        line-height: 2.1;
    }

    .lh-xs-12 {
        line-height: 2.2;
    }

    .lh-xs-13 {
        line-height: 2.3;
    }

    .lh-xs-14 {
        line-height: 2.4;
    }

    .lh-xs-15 {
        line-height: 2.5;
    }

    .tx-xs-center {
        text-align: center;
    }

    .tx-xs-right {
        text-align: right;
    }

    .tx-xs-left {
        text-align: left;
    }

    .tx-xs-center-f {
        text-align: center;
    }

    .tx-xs-right-f {
        text-align: right;
    }

    .tx-xs-left-f {
        text-align: left;
    }
}

@media (min-width: 576px) {
    .tx-sm-8 {
        font-size: 8px;
    }

    .tx-sm-8-f {
        font-size: 8px;
    }

    .tx-sm-9 {
        font-size: 9px;
    }

    .tx-sm-9-f {
        font-size: 9px;
    }

    .tx-sm-10 {
        font-size: 10px;
    }

    .tx-sm-10-f {
        font-size: 10px;
    }

    .tx-sm-11 {
        font-size: 11px;
    }

    .tx-sm-11-f {
        font-size: 11px;
    }

    .tx-sm-12 {
        font-size: 12px;
    }

    .tx-sm-12-f {
        font-size: 12px;
    }

    .tx-sm-13 {
        font-size: 13px;
    }

    .tx-sm-13-f {
        font-size: 13px;
    }

    .tx-sm-14 {
        font-size: 14px;
    }

    .tx-sm-14-f {
        font-size: 14px;
    }

    .tx-sm-15 {
        font-size: 15px;
    }

    .tx-sm-15-f {
        font-size: 15px;
    }

    .tx-sm-16 {
        font-size: 16px;
    }

    .tx-sm-16-f {
        font-size: 16px;
    }

    .tx-sm-18 {
        font-size: 18px;
    }

    .tx-sm-18-f {
        font-size: 18px;
    }

    .tx-sm-20 {
        font-size: 20px;
    }

    .tx-sm-20-f {
        font-size: 20px;
    }

    .tx-sm-22 {
        font-size: 22px;
    }

    .tx-sm-22-f {
        font-size: 22px;
    }

    .tx-sm-24 {
        font-size: 24px;
    }

    .tx-sm-24-f {
        font-size: 24px;
    }

    .tx-sm-26 {
        font-size: 26px;
    }

    .tx-sm-26-f {
        font-size: 26px;
    }

    .tx-sm-28 {
        font-size: 28px;
    }

    .tx-sm-28-f {
        font-size: 28px;
    }

    .tx-sm-30 {
        font-size: 30px;
    }

    .tx-sm-30-f {
        font-size: 30px;
    }

    .tx-sm-32 {
        font-size: 32px;
    }

    .tx-sm-32-f {
        font-size: 32px;
    }

    .tx-sm-34 {
        font-size: 34px;
    }

    .tx-sm-34-f {
        font-size: 34px;
    }

    .tx-sm-36 {
        font-size: 36px;
    }

    .tx-sm-36-f {
        font-size: 36px;
    }

    .tx-sm-38 {
        font-size: 38px;
    }

    .tx-sm-38-f {
        font-size: 38px;
    }

    .tx-sm-40 {
        font-size: 40px;
    }

    .tx-sm-40-f {
        font-size: 40px;
    }

    .tx-sm-42 {
        font-size: 42px;
    }

    .tx-sm-42-f {
        font-size: 42px;
    }

    .tx-sm-44 {
        font-size: 44px;
    }

    .tx-sm-44-f {
        font-size: 44px;
    }

    .tx-sm-46 {
        font-size: 46px;
    }

    .tx-sm-46-f {
        font-size: 46px;
    }

    .tx-sm-48 {
        font-size: 48px;
    }

    .tx-sm-48-f {
        font-size: 48px;
    }

    .tx-sm-50 {
        font-size: 50px;
    }

    .tx-sm-50-f {
        font-size: 50px;
    }

    .tx-sm-52 {
        font-size: 52px;
    }

    .tx-sm-52-f {
        font-size: 52px;
    }

    .tx-sm-54 {
        font-size: 54px;
    }

    .tx-sm-54-f {
        font-size: 54px;
    }

    .tx-sm-56 {
        font-size: 56px;
    }

    .tx-sm-56-f {
        font-size: 56px;
    }

    .tx-sm-58 {
        font-size: 58px;
    }

    .tx-sm-58-f {
        font-size: 58px;
    }

    .tx-sm-60 {
        font-size: 60px;
    }

    .tx-sm-60-f {
        font-size: 60px;
    }

    .tx-sm-62 {
        font-size: 62px;
    }

    .tx-sm-62-f {
        font-size: 62px;
    }

    .tx-sm-64 {
        font-size: 64px;
    }

    .tx-sm-64-f {
        font-size: 64px;
    }

    .tx-sm-66 {
        font-size: 66px;
    }

    .tx-sm-66-f {
        font-size: 66px;
    }

    .tx-sm-68 {
        font-size: 68px;
    }

    .tx-sm-68-f {
        font-size: 68px;
    }

    .tx-sm-70 {
        font-size: 70px;
    }

    .tx-sm-70-f {
        font-size: 70px;
    }

    .tx-sm-72 {
        font-size: 72px;
    }

    .tx-sm-72-f {
        font-size: 72px;
    }

    .tx-sm-74 {
        font-size: 74px;
    }

    .tx-sm-74-f {
        font-size: 74px;
    }

    .tx-sm-76 {
        font-size: 76px;
    }

    .tx-sm-76-f {
        font-size: 76px;
    }

    .tx-sm-78 {
        font-size: 78px;
    }

    .tx-sm-78-f {
        font-size: 78px;
    }

    .tx-sm-80 {
        font-size: 80px;
    }

    .tx-sm-80-f {
        font-size: 80px;
    }

    .tx-sm-82 {
        font-size: 82px;
    }

    .tx-sm-82-f {
        font-size: 82px;
    }

    .tx-sm-84 {
        font-size: 84px;
    }

    .tx-sm-84-f {
        font-size: 84px;
    }

    .tx-sm-86 {
        font-size: 86px;
    }

    .tx-sm-86-f {
        font-size: 86px;
    }

    .tx-sm-88 {
        font-size: 88px;
    }

    .tx-sm-88-f {
        font-size: 88px;
    }

    .tx-sm-90 {
        font-size: 90px;
    }

    .tx-sm-90-f {
        font-size: 90px;
    }

    .tx-sm-92 {
        font-size: 92px;
    }

    .tx-sm-92-f {
        font-size: 92px;
    }

    .tx-sm-94 {
        font-size: 94px;
    }

    .tx-sm-94-f {
        font-size: 94px;
    }

    .tx-sm-96 {
        font-size: 96px;
    }

    .tx-sm-96-f {
        font-size: 96px;
    }

    .tx-sm-98 {
        font-size: 98px;
    }

    .tx-sm-98-f {
        font-size: 98px;
    }

    .tx-sm-100 {
        font-size: 100px;
    }

    .tx-sm-100-f {
        font-size: 100px;
    }

    .tx-sm-102 {
        font-size: 102px;
    }

    .tx-sm-102-f {
        font-size: 102px;
    }

    .tx-sm-104 {
        font-size: 104px;
    }

    .tx-sm-104-f {
        font-size: 104px;
    }

    .tx-sm-106 {
        font-size: 106px;
    }

    .tx-sm-106-f {
        font-size: 106px;
    }

    .tx-sm-108 {
        font-size: 108px;
    }

    .tx-sm-108-f {
        font-size: 108px;
    }

    .tx-sm-110 {
        font-size: 110px;
    }

    .tx-sm-110-f {
        font-size: 110px;
    }

    .tx-sm-112 {
        font-size: 112px;
    }

    .tx-sm-112-f {
        font-size: 112px;
    }

    .tx-sm-114 {
        font-size: 114px;
    }

    .tx-sm-114-f {
        font-size: 114px;
    }

    .tx-sm-116 {
        font-size: 116px;
    }

    .tx-sm-116-f {
        font-size: 116px;
    }

    .tx-sm-118 {
        font-size: 118px;
    }

    .tx-sm-118-f {
        font-size: 118px;
    }

    .tx-sm-120 {
        font-size: 120px;
    }

    .tx-sm-120-f {
        font-size: 120px;
    }

    .tx-sm-122 {
        font-size: 122px;
    }

    .tx-sm-122-f {
        font-size: 122px;
    }

    .tx-sm-124 {
        font-size: 124px;
    }

    .tx-sm-124-f {
        font-size: 124px;
    }

    .tx-sm-126 {
        font-size: 126px;
    }

    .tx-sm-126-f {
        font-size: 126px;
    }

    .tx-sm-128 {
        font-size: 128px;
    }

    .tx-sm-128-f {
        font-size: 128px;
    }

    .tx-sm-130 {
        font-size: 130px;
    }

    .tx-sm-130-f {
        font-size: 130px;
    }

    .tx-sm-132 {
        font-size: 132px;
    }

    .tx-sm-132-f {
        font-size: 132px;
    }

    .tx-sm-134 {
        font-size: 134px;
    }

    .tx-sm-134-f {
        font-size: 134px;
    }

    .tx-sm-136 {
        font-size: 136px;
    }

    .tx-sm-136-f {
        font-size: 136px;
    }

    .tx-sm-138 {
        font-size: 138px;
    }

    .tx-sm-138-f {
        font-size: 138px;
    }

    .tx-sm-140 {
        font-size: 140px;
    }

    .tx-sm-140-f {
        font-size: 140px;
    }

    .tx-sm-base {
        font-size: 0.875rem;
    }

    .tx-sm-bold {
        font-weight: 700;
    }

    .tx-sm-semibold {
        font-weight: 600;
    }

    .tx-sm-medium {
        font-weight: 500;
    }

    .tx-sm-normal {
        font-weight: 400;
    }

    .tx-sm-light {
        font-weight: 300;
    }

    .tx-sm-thin {
        font-weight: 200;
    }

    .tx-sm-xthin {
        font-weight: 100;
    }

    .lh-sm-0 {
        line-height: 0;
    }

    .lh-sm-1 {
        line-height: 1.1;
    }

    .lh-sm-2 {
        line-height: 1.2;
    }

    .lh-sm-3 {
        line-height: 1.3;
    }

    .lh-sm-4 {
        line-height: 1.4;
    }

    .lh-sm-5 {
        line-height: 1.5;
    }

    .lh-sm-6 {
        line-height: 1.6;
    }

    .lh-sm-7 {
        line-height: 1.7;
    }

    .lh-sm-8 {
        line-height: 1.8;
    }

    .lh-sm-9 {
        line-height: 1.9;
    }

    .lh-sm-10 {
        line-height: 2;
    }

    .lh-sm-11 {
        line-height: 2.1;
    }

    .lh-sm-12 {
        line-height: 2.2;
    }

    .lh-sm-13 {
        line-height: 2.3;
    }

    .lh-sm-14 {
        line-height: 2.4;
    }

    .lh-sm-15 {
        line-height: 2.5;
    }

    .tx-sm-center {
        text-align: center;
    }

    .tx-sm-right {
        text-align: right;
    }

    .tx-sm-left {
        text-align: left;
    }

    .tx-sm-center-f {
        text-align: center;
    }

    .tx-sm-right-f {
        text-align: right;
    }

    .tx-sm-left-f {
        text-align: left;
    }
}

@media (min-width: 768px) {
    .tx-md-8 {
        font-size: 8px;
    }

    .tx-md-8-f {
        font-size: 8px;
    }

    .tx-md-9 {
        font-size: 9px;
    }

    .tx-md-9-f {
        font-size: 9px;
    }

    .tx-md-10 {
        font-size: 10px;
    }

    .tx-md-10-f {
        font-size: 10px;
    }

    .tx-md-11 {
        font-size: 11px;
    }

    .tx-md-11-f {
        font-size: 11px;
    }

    .tx-md-12 {
        font-size: 12px;
    }

    .tx-md-12-f {
        font-size: 12px;
    }

    .tx-md-13 {
        font-size: 13px;
    }

    .tx-md-13-f {
        font-size: 13px;
    }

    .tx-md-14 {
        font-size: 14px;
    }

    .tx-md-14-f {
        font-size: 14px;
    }

    .tx-md-15 {
        font-size: 15px;
    }

    .tx-md-15-f {
        font-size: 15px;
    }

    .tx-md-16 {
        font-size: 16px;
    }

    .tx-md-16-f {
        font-size: 16px;
    }

    .tx-md-18 {
        font-size: 18px;
    }

    .tx-md-18-f {
        font-size: 18px;
    }

    .tx-md-20 {
        font-size: 20px;
    }

    .tx-md-20-f {
        font-size: 20px;
    }

    .tx-md-22 {
        font-size: 22px;
    }

    .tx-md-22-f {
        font-size: 22px;
    }

    .tx-md-24 {
        font-size: 24px;
    }

    .tx-md-24-f {
        font-size: 24px;
    }

    .tx-md-26 {
        font-size: 26px;
    }

    .tx-md-26-f {
        font-size: 26px;
    }

    .tx-md-28 {
        font-size: 28px;
    }

    .tx-md-28-f {
        font-size: 28px;
    }

    .tx-md-30 {
        font-size: 30px;
    }

    .tx-md-30-f {
        font-size: 30px;
    }

    .tx-md-32 {
        font-size: 32px;
    }

    .tx-md-32-f {
        font-size: 32px;
    }

    .tx-md-34 {
        font-size: 34px;
    }

    .tx-md-34-f {
        font-size: 34px;
    }

    .tx-md-36 {
        font-size: 36px;
    }

    .tx-md-36-f {
        font-size: 36px;
    }

    .tx-md-38 {
        font-size: 38px;
    }

    .tx-md-38-f {
        font-size: 38px;
    }

    .tx-md-40 {
        font-size: 40px;
    }

    .tx-md-40-f {
        font-size: 40px;
    }

    .tx-md-42 {
        font-size: 42px;
    }

    .tx-md-42-f {
        font-size: 42px;
    }

    .tx-md-44 {
        font-size: 44px;
    }

    .tx-md-44-f {
        font-size: 44px;
    }

    .tx-md-46 {
        font-size: 46px;
    }

    .tx-md-46-f {
        font-size: 46px;
    }

    .tx-md-48 {
        font-size: 48px;
    }

    .tx-md-48-f {
        font-size: 48px;
    }

    .tx-md-50 {
        font-size: 50px;
    }

    .tx-md-50-f {
        font-size: 50px;
    }

    .tx-md-52 {
        font-size: 52px;
    }

    .tx-md-52-f {
        font-size: 52px;
    }

    .tx-md-54 {
        font-size: 54px;
    }

    .tx-md-54-f {
        font-size: 54px;
    }

    .tx-md-56 {
        font-size: 56px;
    }

    .tx-md-56-f {
        font-size: 56px;
    }

    .tx-md-58 {
        font-size: 58px;
    }

    .tx-md-58-f {
        font-size: 58px;
    }

    .tx-md-60 {
        font-size: 60px;
    }

    .tx-md-60-f {
        font-size: 60px;
    }

    .tx-md-62 {
        font-size: 62px;
    }

    .tx-md-62-f {
        font-size: 62px;
    }

    .tx-md-64 {
        font-size: 64px;
    }

    .tx-md-64-f {
        font-size: 64px;
    }

    .tx-md-66 {
        font-size: 66px;
    }

    .tx-md-66-f {
        font-size: 66px;
    }

    .tx-md-68 {
        font-size: 68px;
    }

    .tx-md-68-f {
        font-size: 68px;
    }

    .tx-md-70 {
        font-size: 70px;
    }

    .tx-md-70-f {
        font-size: 70px;
    }

    .tx-md-72 {
        font-size: 72px;
    }

    .tx-md-72-f {
        font-size: 72px;
    }

    .tx-md-74 {
        font-size: 74px;
    }

    .tx-md-74-f {
        font-size: 74px;
    }

    .tx-md-76 {
        font-size: 76px;
    }

    .tx-md-76-f {
        font-size: 76px;
    }

    .tx-md-78 {
        font-size: 78px;
    }

    .tx-md-78-f {
        font-size: 78px;
    }

    .tx-md-80 {
        font-size: 80px;
    }

    .tx-md-80-f {
        font-size: 80px;
    }

    .tx-md-82 {
        font-size: 82px;
    }

    .tx-md-82-f {
        font-size: 82px;
    }

    .tx-md-84 {
        font-size: 84px;
    }

    .tx-md-84-f {
        font-size: 84px;
    }

    .tx-md-86 {
        font-size: 86px;
    }

    .tx-md-86-f {
        font-size: 86px;
    }

    .tx-md-88 {
        font-size: 88px;
    }

    .tx-md-88-f {
        font-size: 88px;
    }

    .tx-md-90 {
        font-size: 90px;
    }

    .tx-md-90-f {
        font-size: 90px;
    }

    .tx-md-92 {
        font-size: 92px;
    }

    .tx-md-92-f {
        font-size: 92px;
    }

    .tx-md-94 {
        font-size: 94px;
    }

    .tx-md-94-f {
        font-size: 94px;
    }

    .tx-md-96 {
        font-size: 96px;
    }

    .tx-md-96-f {
        font-size: 96px;
    }

    .tx-md-98 {
        font-size: 98px;
    }

    .tx-md-98-f {
        font-size: 98px;
    }

    .tx-md-100 {
        font-size: 100px;
    }

    .tx-md-100-f {
        font-size: 100px;
    }

    .tx-md-102 {
        font-size: 102px;
    }

    .tx-md-102-f {
        font-size: 102px;
    }

    .tx-md-104 {
        font-size: 104px;
    }

    .tx-md-104-f {
        font-size: 104px;
    }

    .tx-md-106 {
        font-size: 106px;
    }

    .tx-md-106-f {
        font-size: 106px;
    }

    .tx-md-108 {
        font-size: 108px;
    }

    .tx-md-108-f {
        font-size: 108px;
    }

    .tx-md-110 {
        font-size: 110px;
    }

    .tx-md-110-f {
        font-size: 110px;
    }

    .tx-md-112 {
        font-size: 112px;
    }

    .tx-md-112-f {
        font-size: 112px;
    }

    .tx-md-114 {
        font-size: 114px;
    }

    .tx-md-114-f {
        font-size: 114px;
    }

    .tx-md-116 {
        font-size: 116px;
    }

    .tx-md-116-f {
        font-size: 116px;
    }

    .tx-md-118 {
        font-size: 118px;
    }

    .tx-md-118-f {
        font-size: 118px;
    }

    .tx-md-120 {
        font-size: 120px;
    }

    .tx-md-120-f {
        font-size: 120px;
    }

    .tx-md-122 {
        font-size: 122px;
    }

    .tx-md-122-f {
        font-size: 122px;
    }

    .tx-md-124 {
        font-size: 124px;
    }

    .tx-md-124-f {
        font-size: 124px;
    }

    .tx-md-126 {
        font-size: 126px;
    }

    .tx-md-126-f {
        font-size: 126px;
    }

    .tx-md-128 {
        font-size: 128px;
    }

    .tx-md-128-f {
        font-size: 128px;
    }

    .tx-md-130 {
        font-size: 130px;
    }

    .tx-md-130-f {
        font-size: 130px;
    }

    .tx-md-132 {
        font-size: 132px;
    }

    .tx-md-132-f {
        font-size: 132px;
    }

    .tx-md-134 {
        font-size: 134px;
    }

    .tx-md-134-f {
        font-size: 134px;
    }

    .tx-md-136 {
        font-size: 136px;
    }

    .tx-md-136-f {
        font-size: 136px;
    }

    .tx-md-138 {
        font-size: 138px;
    }

    .tx-md-138-f {
        font-size: 138px;
    }

    .tx-md-140 {
        font-size: 140px;
    }

    .tx-md-140-f {
        font-size: 140px;
    }

    .tx-md-base {
        font-size: 0.875rem;
    }

    .tx-md-bold {
        font-weight: 700;
    }

    .tx-md-semibold {
        font-weight: 600;
    }

    .tx-md-medium {
        font-weight: 500;
    }

    .tx-md-normal {
        font-weight: 400;
    }

    .tx-md-light {
        font-weight: 300;
    }

    .tx-md-thin {
        font-weight: 200;
    }

    .tx-md-xthin {
        font-weight: 100;
    }

    .lh-md-0 {
        line-height: 0;
    }

    .lh-md-1 {
        line-height: 1.1;
    }

    .lh-md-2 {
        line-height: 1.2;
    }

    .lh-md-3 {
        line-height: 1.3;
    }

    .lh-md-4 {
        line-height: 1.4;
    }

    .lh-md-5 {
        line-height: 1.5;
    }

    .lh-md-6 {
        line-height: 1.6;
    }

    .lh-md-7 {
        line-height: 1.7;
    }

    .lh-md-8 {
        line-height: 1.8;
    }

    .lh-md-9 {
        line-height: 1.9;
    }

    .lh-md-10 {
        line-height: 2;
    }

    .lh-md-11 {
        line-height: 2.1;
    }

    .lh-md-12 {
        line-height: 2.2;
    }

    .lh-md-13 {
        line-height: 2.3;
    }

    .lh-md-14 {
        line-height: 2.4;
    }

    .lh-md-15 {
        line-height: 2.5;
    }

    .tx-md-center {
        text-align: center;
    }

    .tx-md-right {
        text-align: right;
    }

    .tx-md-left {
        text-align: left;
    }

    .tx-md-center-f {
        text-align: center;
    }

    .tx-md-right-f {
        text-align: right;
    }

    .tx-md-left-f {
        text-align: left;
    }
}

@media (min-width: 992px) {
    .tx-lg-8 {
        font-size: 8px;
    }

    .tx-lg-8-f {
        font-size: 8px;
    }

    .tx-lg-9 {
        font-size: 9px;
    }

    .tx-lg-9-f {
        font-size: 9px;
    }

    .tx-lg-10 {
        font-size: 10px;
    }

    .tx-lg-10-f {
        font-size: 10px;
    }

    .tx-lg-11 {
        font-size: 11px;
    }

    .tx-lg-11-f {
        font-size: 11px;
    }

    .tx-lg-12 {
        font-size: 12px;
    }

    .tx-lg-12-f {
        font-size: 12px;
    }

    .tx-lg-13 {
        font-size: 13px;
    }

    .tx-lg-13-f {
        font-size: 13px;
    }

    .tx-lg-14 {
        font-size: 14px;
    }

    .tx-lg-14-f {
        font-size: 14px;
    }

    .tx-lg-15 {
        font-size: 15px;
    }

    .tx-lg-15-f {
        font-size: 15px;
    }

    .tx-lg-16 {
        font-size: 16px;
    }

    .tx-lg-16-f {
        font-size: 16px;
    }

    .tx-lg-18 {
        font-size: 18px;
    }

    .tx-lg-18-f {
        font-size: 18px;
    }

    .tx-lg-20 {
        font-size: 20px;
    }

    .tx-lg-20-f {
        font-size: 20px;
    }

    .tx-lg-22 {
        font-size: 22px;
    }

    .tx-lg-22-f {
        font-size: 22px;
    }

    .tx-lg-24 {
        font-size: 24px;
    }

    .tx-lg-24-f {
        font-size: 24px;
    }

    .tx-lg-26 {
        font-size: 26px;
    }

    .tx-lg-26-f {
        font-size: 26px;
    }

    .tx-lg-28 {
        font-size: 28px;
    }

    .tx-lg-28-f {
        font-size: 28px;
    }

    .tx-lg-30 {
        font-size: 30px;
    }

    .tx-lg-30-f {
        font-size: 30px;
    }

    .tx-lg-32 {
        font-size: 32px;
    }

    .tx-lg-32-f {
        font-size: 32px;
    }

    .tx-lg-34 {
        font-size: 34px;
    }

    .tx-lg-34-f {
        font-size: 34px;
    }

    .tx-lg-36 {
        font-size: 36px;
    }

    .tx-lg-36-f {
        font-size: 36px;
    }

    .tx-lg-38 {
        font-size: 38px;
    }

    .tx-lg-38-f {
        font-size: 38px;
    }

    .tx-lg-40 {
        font-size: 40px;
    }

    .tx-lg-40-f {
        font-size: 40px;
    }

    .tx-lg-42 {
        font-size: 42px;
    }

    .tx-lg-42-f {
        font-size: 42px;
    }

    .tx-lg-44 {
        font-size: 44px;
    }

    .tx-lg-44-f {
        font-size: 44px;
    }

    .tx-lg-46 {
        font-size: 46px;
    }

    .tx-lg-46-f {
        font-size: 46px;
    }

    .tx-lg-48 {
        font-size: 48px;
    }

    .tx-lg-48-f {
        font-size: 48px;
    }

    .tx-lg-50 {
        font-size: 50px;
    }

    .tx-lg-50-f {
        font-size: 50px;
    }

    .tx-lg-52 {
        font-size: 52px;
    }

    .tx-lg-52-f {
        font-size: 52px;
    }

    .tx-lg-54 {
        font-size: 54px;
    }

    .tx-lg-54-f {
        font-size: 54px;
    }

    .tx-lg-56 {
        font-size: 56px;
    }

    .tx-lg-56-f {
        font-size: 56px;
    }

    .tx-lg-58 {
        font-size: 58px;
    }

    .tx-lg-58-f {
        font-size: 58px;
    }

    .tx-lg-60 {
        font-size: 60px;
    }

    .tx-lg-60-f {
        font-size: 60px;
    }

    .tx-lg-62 {
        font-size: 62px;
    }

    .tx-lg-62-f {
        font-size: 62px;
    }

    .tx-lg-64 {
        font-size: 64px;
    }

    .tx-lg-64-f {
        font-size: 64px;
    }

    .tx-lg-66 {
        font-size: 66px;
    }

    .tx-lg-66-f {
        font-size: 66px;
    }

    .tx-lg-68 {
        font-size: 68px;
    }

    .tx-lg-68-f {
        font-size: 68px;
    }

    .tx-lg-70 {
        font-size: 70px;
    }

    .tx-lg-70-f {
        font-size: 70px;
    }

    .tx-lg-72 {
        font-size: 72px;
    }

    .tx-lg-72-f {
        font-size: 72px;
    }

    .tx-lg-74 {
        font-size: 74px;
    }

    .tx-lg-74-f {
        font-size: 74px;
    }

    .tx-lg-76 {
        font-size: 76px;
    }

    .tx-lg-76-f {
        font-size: 76px;
    }

    .tx-lg-78 {
        font-size: 78px;
    }

    .tx-lg-78-f {
        font-size: 78px;
    }

    .tx-lg-80 {
        font-size: 80px;
    }

    .tx-lg-80-f {
        font-size: 80px;
    }

    .tx-lg-82 {
        font-size: 82px;
    }

    .tx-lg-82-f {
        font-size: 82px;
    }

    .tx-lg-84 {
        font-size: 84px;
    }

    .tx-lg-84-f {
        font-size: 84px;
    }

    .tx-lg-86 {
        font-size: 86px;
    }

    .tx-lg-86-f {
        font-size: 86px;
    }

    .tx-lg-88 {
        font-size: 88px;
    }

    .tx-lg-88-f {
        font-size: 88px;
    }

    .tx-lg-90 {
        font-size: 90px;
    }

    .tx-lg-90-f {
        font-size: 90px;
    }

    .tx-lg-92 {
        font-size: 92px;
    }

    .tx-lg-92-f {
        font-size: 92px;
    }

    .tx-lg-94 {
        font-size: 94px;
    }

    .tx-lg-94-f {
        font-size: 94px;
    }

    .tx-lg-96 {
        font-size: 96px;
    }

    .tx-lg-96-f {
        font-size: 96px;
    }

    .tx-lg-98 {
        font-size: 98px;
    }

    .tx-lg-98-f {
        font-size: 98px;
    }

    .tx-lg-100 {
        font-size: 100px;
    }

    .tx-lg-100-f {
        font-size: 100px;
    }

    .tx-lg-102 {
        font-size: 102px;
    }

    .tx-lg-102-f {
        font-size: 102px;
    }

    .tx-lg-104 {
        font-size: 104px;
    }

    .tx-lg-104-f {
        font-size: 104px;
    }

    .tx-lg-106 {
        font-size: 106px;
    }

    .tx-lg-106-f {
        font-size: 106px;
    }

    .tx-lg-108 {
        font-size: 108px;
    }

    .tx-lg-108-f {
        font-size: 108px;
    }

    .tx-lg-110 {
        font-size: 110px;
    }

    .tx-lg-110-f {
        font-size: 110px;
    }

    .tx-lg-112 {
        font-size: 112px;
    }

    .tx-lg-112-f {
        font-size: 112px;
    }

    .tx-lg-114 {
        font-size: 114px;
    }

    .tx-lg-114-f {
        font-size: 114px;
    }

    .tx-lg-116 {
        font-size: 116px;
    }

    .tx-lg-116-f {
        font-size: 116px;
    }

    .tx-lg-118 {
        font-size: 118px;
    }

    .tx-lg-118-f {
        font-size: 118px;
    }

    .tx-lg-120 {
        font-size: 120px;
    }

    .tx-lg-120-f {
        font-size: 120px;
    }

    .tx-lg-122 {
        font-size: 122px;
    }

    .tx-lg-122-f {
        font-size: 122px;
    }

    .tx-lg-124 {
        font-size: 124px;
    }

    .tx-lg-124-f {
        font-size: 124px;
    }

    .tx-lg-126 {
        font-size: 126px;
    }

    .tx-lg-126-f {
        font-size: 126px;
    }

    .tx-lg-128 {
        font-size: 128px;
    }

    .tx-lg-128-f {
        font-size: 128px;
    }

    .tx-lg-130 {
        font-size: 130px;
    }

    .tx-lg-130-f {
        font-size: 130px;
    }

    .tx-lg-132 {
        font-size: 132px;
    }

    .tx-lg-132-f {
        font-size: 132px;
    }

    .tx-lg-134 {
        font-size: 134px;
    }

    .tx-lg-134-f {
        font-size: 134px;
    }

    .tx-lg-136 {
        font-size: 136px;
    }

    .tx-lg-136-f {
        font-size: 136px;
    }

    .tx-lg-138 {
        font-size: 138px;
    }

    .tx-lg-138-f {
        font-size: 138px;
    }

    .tx-lg-140 {
        font-size: 140px;
    }

    .tx-lg-140-f {
        font-size: 140px;
    }

    .tx-lg-base {
        font-size: 0.875rem;
    }

    .tx-lg-bold {
        font-weight: 700;
    }

    .tx-lg-semibold {
        font-weight: 600;
    }

    .tx-lg-medium {
        font-weight: 500;
    }

    .tx-lg-normal {
        font-weight: 400;
    }

    .tx-lg-light {
        font-weight: 300;
    }

    .tx-lg-thin {
        font-weight: 200;
    }

    .tx-lg-xthin {
        font-weight: 100;
    }

    .lh-lg-0 {
        line-height: 0;
    }

    .lh-lg-1 {
        line-height: 1.1;
    }

    .lh-lg-2 {
        line-height: 1.2;
    }

    .lh-lg-3 {
        line-height: 1.3;
    }

    .lh-lg-4 {
        line-height: 1.4;
    }

    .lh-lg-5 {
        line-height: 1.5;
    }

    .lh-lg-6 {
        line-height: 1.6;
    }

    .lh-lg-7 {
        line-height: 1.7;
    }

    .lh-lg-8 {
        line-height: 1.8;
    }

    .lh-lg-9 {
        line-height: 1.9;
    }

    .lh-lg-10 {
        line-height: 2;
    }

    .lh-lg-11 {
        line-height: 2.1;
    }

    .lh-lg-12 {
        line-height: 2.2;
    }

    .lh-lg-13 {
        line-height: 2.3;
    }

    .lh-lg-14 {
        line-height: 2.4;
    }

    .lh-lg-15 {
        line-height: 2.5;
    }

    .tx-lg-center {
        text-align: center;
    }

    .tx-lg-right {
        text-align: right;
    }

    .tx-lg-left {
        text-align: left;
    }

    .tx-lg-center-f {
        text-align: center;
    }

    .tx-lg-right-f {
        text-align: right;
    }

    .tx-lg-left-f {
        text-align: left;
    }
}

@media (min-width: 1200px) {
    .tx-xl-8 {
        font-size: 8px;
    }

    .tx-xl-8-f {
        font-size: 8px;
    }

    .tx-xl-9 {
        font-size: 9px;
    }

    .tx-xl-9-f {
        font-size: 9px;
    }

    .tx-xl-10 {
        font-size: 10px;
    }

    .tx-xl-10-f {
        font-size: 10px;
    }

    .tx-xl-11 {
        font-size: 11px;
    }

    .tx-xl-11-f {
        font-size: 11px;
    }

    .tx-xl-12 {
        font-size: 12px;
    }

    .tx-xl-12-f {
        font-size: 12px;
    }

    .tx-xl-13 {
        font-size: 13px;
    }

    .tx-xl-13-f {
        font-size: 13px;
    }

    .tx-xl-14 {
        font-size: 14px;
    }

    .tx-xl-14-f {
        font-size: 14px;
    }

    .tx-xl-15 {
        font-size: 15px;
    }

    .tx-xl-15-f {
        font-size: 15px;
    }

    .tx-xl-16 {
        font-size: 16px;
    }

    .tx-xl-16-f {
        font-size: 16px;
    }

    .tx-xl-18 {
        font-size: 18px;
    }

    .tx-xl-18-f {
        font-size: 18px;
    }

    .tx-xl-20 {
        font-size: 20px;
    }

    .tx-xl-20-f {
        font-size: 20px;
    }

    .tx-xl-22 {
        font-size: 22px;
    }

    .tx-xl-22-f {
        font-size: 22px;
    }

    .tx-xl-24 {
        font-size: 24px;
    }

    .tx-xl-24-f {
        font-size: 24px;
    }

    .tx-xl-26 {
        font-size: 26px;
    }

    .tx-xl-26-f {
        font-size: 26px;
    }

    .tx-xl-28 {
        font-size: 28px;
    }

    .tx-xl-28-f {
        font-size: 28px;
    }

    .tx-xl-30 {
        font-size: 30px;
    }

    .tx-xl-30-f {
        font-size: 30px;
    }

    .tx-xl-32 {
        font-size: 32px;
    }

    .tx-xl-32-f {
        font-size: 32px;
    }

    .tx-xl-34 {
        font-size: 34px;
    }

    .tx-xl-34-f {
        font-size: 34px;
    }

    .tx-xl-36 {
        font-size: 36px;
    }

    .tx-xl-36-f {
        font-size: 36px;
    }

    .tx-xl-38 {
        font-size: 38px;
    }

    .tx-xl-38-f {
        font-size: 38px;
    }

    .tx-xl-40 {
        font-size: 40px;
    }

    .tx-xl-40-f {
        font-size: 40px;
    }

    .tx-xl-42 {
        font-size: 42px;
    }

    .tx-xl-42-f {
        font-size: 42px;
    }

    .tx-xl-44 {
        font-size: 44px;
    }

    .tx-xl-44-f {
        font-size: 44px;
    }

    .tx-xl-46 {
        font-size: 46px;
    }

    .tx-xl-46-f {
        font-size: 46px;
    }

    .tx-xl-48 {
        font-size: 48px;
    }

    .tx-xl-48-f {
        font-size: 48px;
    }

    .tx-xl-50 {
        font-size: 50px;
    }

    .tx-xl-50-f {
        font-size: 50px;
    }

    .tx-xl-52 {
        font-size: 52px;
    }

    .tx-xl-52-f {
        font-size: 52px;
    }

    .tx-xl-54 {
        font-size: 54px;
    }

    .tx-xl-54-f {
        font-size: 54px;
    }

    .tx-xl-56 {
        font-size: 56px;
    }

    .tx-xl-56-f {
        font-size: 56px;
    }

    .tx-xl-58 {
        font-size: 58px;
    }

    .tx-xl-58-f {
        font-size: 58px;
    }

    .tx-xl-60 {
        font-size: 60px;
    }

    .tx-xl-60-f {
        font-size: 60px;
    }

    .tx-xl-62 {
        font-size: 62px;
    }

    .tx-xl-62-f {
        font-size: 62px;
    }

    .tx-xl-64 {
        font-size: 64px;
    }

    .tx-xl-64-f {
        font-size: 64px;
    }

    .tx-xl-66 {
        font-size: 66px;
    }

    .tx-xl-66-f {
        font-size: 66px;
    }

    .tx-xl-68 {
        font-size: 68px;
    }

    .tx-xl-68-f {
        font-size: 68px;
    }

    .tx-xl-70 {
        font-size: 70px;
    }

    .tx-xl-70-f {
        font-size: 70px;
    }

    .tx-xl-72 {
        font-size: 72px;
    }

    .tx-xl-72-f {
        font-size: 72px;
    }

    .tx-xl-74 {
        font-size: 74px;
    }

    .tx-xl-74-f {
        font-size: 74px;
    }

    .tx-xl-76 {
        font-size: 76px;
    }

    .tx-xl-76-f {
        font-size: 76px;
    }

    .tx-xl-78 {
        font-size: 78px;
    }

    .tx-xl-78-f {
        font-size: 78px;
    }

    .tx-xl-80 {
        font-size: 80px;
    }

    .tx-xl-80-f {
        font-size: 80px;
    }

    .tx-xl-82 {
        font-size: 82px;
    }

    .tx-xl-82-f {
        font-size: 82px;
    }

    .tx-xl-84 {
        font-size: 84px;
    }

    .tx-xl-84-f {
        font-size: 84px;
    }

    .tx-xl-86 {
        font-size: 86px;
    }

    .tx-xl-86-f {
        font-size: 86px;
    }

    .tx-xl-88 {
        font-size: 88px;
    }

    .tx-xl-88-f {
        font-size: 88px;
    }

    .tx-xl-90 {
        font-size: 90px;
    }

    .tx-xl-90-f {
        font-size: 90px;
    }

    .tx-xl-92 {
        font-size: 92px;
    }

    .tx-xl-92-f {
        font-size: 92px;
    }

    .tx-xl-94 {
        font-size: 94px;
    }

    .tx-xl-94-f {
        font-size: 94px;
    }

    .tx-xl-96 {
        font-size: 96px;
    }

    .tx-xl-96-f {
        font-size: 96px;
    }

    .tx-xl-98 {
        font-size: 98px;
    }

    .tx-xl-98-f {
        font-size: 98px;
    }

    .tx-xl-100 {
        font-size: 100px;
    }

    .tx-xl-100-f {
        font-size: 100px;
    }

    .tx-xl-102 {
        font-size: 102px;
    }

    .tx-xl-102-f {
        font-size: 102px;
    }

    .tx-xl-104 {
        font-size: 104px;
    }

    .tx-xl-104-f {
        font-size: 104px;
    }

    .tx-xl-106 {
        font-size: 106px;
    }

    .tx-xl-106-f {
        font-size: 106px;
    }

    .tx-xl-108 {
        font-size: 108px;
    }

    .tx-xl-108-f {
        font-size: 108px;
    }

    .tx-xl-110 {
        font-size: 110px;
    }

    .tx-xl-110-f {
        font-size: 110px;
    }

    .tx-xl-112 {
        font-size: 112px;
    }

    .tx-xl-112-f {
        font-size: 112px;
    }

    .tx-xl-114 {
        font-size: 114px;
    }

    .tx-xl-114-f {
        font-size: 114px;
    }

    .tx-xl-116 {
        font-size: 116px;
    }

    .tx-xl-116-f {
        font-size: 116px;
    }

    .tx-xl-118 {
        font-size: 118px;
    }

    .tx-xl-118-f {
        font-size: 118px;
    }

    .tx-xl-120 {
        font-size: 120px;
    }

    .tx-xl-120-f {
        font-size: 120px;
    }

    .tx-xl-122 {
        font-size: 122px;
    }

    .tx-xl-122-f {
        font-size: 122px;
    }

    .tx-xl-124 {
        font-size: 124px;
    }

    .tx-xl-124-f {
        font-size: 124px;
    }

    .tx-xl-126 {
        font-size: 126px;
    }

    .tx-xl-126-f {
        font-size: 126px;
    }

    .tx-xl-128 {
        font-size: 128px;
    }

    .tx-xl-128-f {
        font-size: 128px;
    }

    .tx-xl-130 {
        font-size: 130px;
    }

    .tx-xl-130-f {
        font-size: 130px;
    }

    .tx-xl-132 {
        font-size: 132px;
    }

    .tx-xl-132-f {
        font-size: 132px;
    }

    .tx-xl-134 {
        font-size: 134px;
    }

    .tx-xl-134-f {
        font-size: 134px;
    }

    .tx-xl-136 {
        font-size: 136px;
    }

    .tx-xl-136-f {
        font-size: 136px;
    }

    .tx-xl-138 {
        font-size: 138px;
    }

    .tx-xl-138-f {
        font-size: 138px;
    }

    .tx-xl-140 {
        font-size: 140px;
    }

    .tx-xl-140-f {
        font-size: 140px;
    }

    .tx-xl-base {
        font-size: 0.875rem;
    }

    .tx-xl-bold {
        font-weight: 700;
    }

    .tx-xl-semibold {
        font-weight: 600;
    }

    .tx-xl-medium {
        font-weight: 500;
    }

    .tx-xl-normal {
        font-weight: 400;
    }

    .tx-xl-light {
        font-weight: 300;
    }

    .tx-xl-thin {
        font-weight: 200;
    }

    .tx-xl-xthin {
        font-weight: 100;
    }

    .lh-xl-0 {
        line-height: 0;
    }

    .lh-xl-1 {
        line-height: 1.1;
    }

    .lh-xl-2 {
        line-height: 1.2;
    }

    .lh-xl-3 {
        line-height: 1.3;
    }

    .lh-xl-4 {
        line-height: 1.4;
    }

    .lh-xl-5 {
        line-height: 1.5;
    }

    .lh-xl-6 {
        line-height: 1.6;
    }

    .lh-xl-7 {
        line-height: 1.7;
    }

    .lh-xl-8 {
        line-height: 1.8;
    }

    .lh-xl-9 {
        line-height: 1.9;
    }

    .lh-xl-10 {
        line-height: 2;
    }

    .lh-xl-11 {
        line-height: 2.1;
    }

    .lh-xl-12 {
        line-height: 2.2;
    }

    .lh-xl-13 {
        line-height: 2.3;
    }

    .lh-xl-14 {
        line-height: 2.4;
    }

    .lh-xl-15 {
        line-height: 2.5;
    }

    .tx-xl-center {
        text-align: center;
    }

    .tx-xl-right {
        text-align: right;
    }

    .tx-xl-left {
        text-align: left;
    }

    .tx-xl-center-f {
        text-align: center;
    }

    .tx-xl-right-f {
        text-align: right;
    }

    .tx-xl-left-f {
        text-align: left;
    }
}

.text-nowrap {
    white-space: nowrap !important;
}

@media (max-width: 576px) {
    .text-sm-nowrap {
        white-space: nowrap;
    }
}

@media (max-width: 768px) {
    .text-md-nowrap {
        white-space: nowrap;
    }
}

@media (max-width: 992px) {
    .text-lg-nowrap {
        white-space: nowrap;
    }
}

@media (max-width: 1000px) {
    .text-xl-nowrap {
        white-space: nowrap;
    }
}

.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
    text-align: justify !important;
}

.text-wrap {
    white-space: normal !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-start {
    text-align: left !important;
}

.text-end {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

@media (min-width: 576px) {
    .text-sm-left {
        text-align: left;
    }

    .text-sm-right {
        text-align: right;
    }

    .text-sm-center {
        text-align: center;
    }
}

@media (min-width: 768px) {
    .text-md-left {
        text-align: left;
    }

    .text-md-right {
        text-align: right;
    }

    .text-md-center {
        text-align: center;
    }
}

@media (min-width: 992px) {
    .text-lg-left {
        text-align: left;
    }

    .text-lg-right {
        text-align: right;
    }

    .text-lg-center {
        text-align: center;
    }
}

@media (min-width: 1200px) {
    .text-xl-left {
        text-align: left;
    }

    .text-xl-right {
        text-align: right;
    }

    .text-xl-center {
        text-align: center;
    }
}

.text-lowercase {
    text-transform: lowercase;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.font-weight-light {
    font-weight: 300;
}

.font-weight-lighter {
    font-weight: lighter;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-weight-bolder {
    font-weight: bolder;
}

.font-italic {
    font-style: italic;
}

.text-white {
    color: #fff !important;
}

.text-white-80 {
    color: #fff;
    opacity: 0.8;
}

.text-primary {
    color: var(--primary) !important;
}

.text-primary-dark {
    color: var(--primary-darker) !important;
}

.text-primary-75 {
    color: var(--primary-75) !important;
}

.text-primary-50 {
    color: var(--primary-50) !important;
}

.text-primary-25 {
    color: var(--primary-25) !important;
}

a.text-primary:hover,
a.text-primary:focus {
    color: var(--primary-75) !important;
}

.text-secondary {
    color: var(--secondary) !important;
}

.text-secondary-dark {
    color: var(--secondary-darker) !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
    color: var(--secondary-50) !important;
}

.text-success {
    color: var(--success) !important;
}

.text-success-dark {
    color: var(--success-darker) !important;
}

a.text-success:hover,
a.text-success:focus {
    color: var(--success-50) !important;
}

.text-error {
    color: var(--error) !important;
}

.text-error-dark {
    color: var(--error-darker) !important;
}

.text-info {
    color: var(--info) !important;
}

.text-info-dark {
    color: var(--info-darker) !important;
}

a.text-info:hover,
a.text-info:focus {
    color: var(--info-50) !important;
}

.text-warning {
    color: var(--warning) !important;
}

.text-warning-dark {
    color: var(--warning-darker) !important;
}

a.text-warning:hover,
a.text-warning:focus {
    color: var(--warning-50) !important;
}

.text-purple {
    color: #ad4ffa !important;
}

a.text-purple:hover,
a.text-purple:focus {
    color: #ad4ffa !important;
}

.text-danger {
    color: var(--error) !important;
}

a.text-danger:hover,
a.text-danger:focus {
    color: var(--error-50) !important;
}

.text-pink {
    color: #f754fa !important;
}

a.text-pink:hover,
a.text-pink:focus {
    color: #e61078 !important;
}

.text-orange {
    color: #fd7e14 !important;
}

a.text-orange:hover,
a.text-orange:focus {
    color: #e46f0e !important;
}

.text-light {
    color: #f9f9fb !important;
}

a.text-light:hover,
a.text-light:focus {
    color: #c5cbda !important;
}

.text-teal {
    color: #29ddee !important;
}

a.text-teal:hover,
a.text-teal:focus {
    color: #06c1c1 !important;
}

.text-dark {
    color: #383853 !important;
}

a.text-dark:hover,
a.text-dark:focus {
    color: #1e2533 !important;
}

.text-body {
    color: #4a4a69;
}

.text-muted {
    color: #7987a1 !important;
}

.text-black-50 {
    color: rgba(0, 0, 0, 0.5);
}

.text-white-50 {
    color: rgba(255, 255, 255, 0.5);
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.text-decoration-none {
    text-decoration: none;
}

.text-break {
    word-break: break-word;
    overflow-wrap: break-word;
}

.text-reset {
    color: inherit;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden;
}

/* ###### 3.11 Pagination ###### */
.page-item:first-child .page-link,
.page-item:last-child .page-link {
    border-radius: 0;
}

.page-item+.page-item {
    margin-left: 1px;
}

.page-item.active .page-link {
    background-color: var(--primary);
}

.page-link {
    color: var(--primary);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 38px;
    border-width: 0;
    background-color: #f0f0f8;
    margin-left: 0;
    font-weight: 500;
}

.page-link i {
    font-size: 18px;
    line-height: 0;
}

.page-link:hover,
.page-link:focus {
    color: #323251;
    background-color: #e1e1ef;
}

.pagination-dark .page-item.active .page-link {
    background-color: var(--primary) !important;
    color: #fff;
}

.pagination-dark .page-link {
    font-weight: 400;
    background-color: rgba(255, 255, 255, 0.05);
    color: rgba(255, 255, 255, 0.8);
}

.pagination-dark .page-link:hover,
.pagination-dark .page-link:focus {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.08);
}

.pagination-primary .page-item.active .page-link {
    background-color: var(--primary);
}

.pagination-success .page-item.active .page-link {
    background-color: var(--success);
    border-color: var(--success);
}

.pagination-circled .page-item+.page-item {
    margin-left: 5px;
}

.pagination-circled .page-item:first-child .page-link,
.pagination-circled .page-item:last-child .page-link {
    border-radius: 100%;
}

.pagination-circled .page-link {
    padding: 0;
    width: 38px;
    border-radius: 100%;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 3px;
}

.page-link {
    position: relative;
    display: flex;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #596882;
    background-color: #e3e7ed;
}

.page-link:hover {
    z-index: 2;
    color: #f9f9fb;
    text-decoration: none;
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
}

.page-link:focus {
    z-index: 2;
    outline: 0;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.page-item:last-child .page-link {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
}

.page-item.disabled .page-link {
    color: var(--neutral-70);
    pointer-events: none;
    cursor: auto;
}

.pagination-lg .page-link {
    padding: 1.2rem 1.3rem;
    font-size: 1.05rem;
    line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.76562rem;
    line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
}

.page-prev .page-link,
.page-next .page-link {
    width: auto !important;
    padding: 0 10px;
}

.page-prev .page-link,
.page-next .page-link {
    width: auto !important;
    padding: 10px 15px;
}

.pagination li a {
    margin-right: 4px;
    border-radius: 4px;
}

.pagination .page-link {
    color: var(--primary);
    background-color: #fff;
}

.pagination .page-link:hover {
    background-color: var(--secondary-darker) !important;
    border: 1px solid var(--secondary-darker) !important;
    color: #fff;
}

.pagination .page-item:last-child .page-link,
.pagination .page-item:first-child .page-link {
    border-radius: 4px;
}

.pagination.pagination-circled .page-link {
    border-radius: 50% !important;
}

.pagination-dark.pagination .page-link {
    background-color: #455377 !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.pagination-dark.pagination .page-link:hover {
    background: var(--primary) !important;
    color: #fff;
    border: 1px solid var(--primary) !important;
}

.pagination-success .page-link:hover {
    background-color: var(--success) !important;
    border-color: var(--success) !important;
}

.pagination-circle {
    margin-top: 0;
    border-radius: 50px;
    background: #f0f0ff;
    padding: 7px;
}

.page-link:focus {
    box-shadow: none;
}

.page-link-outline {
    color: #3b4863;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border: 1px solid #ededf5;
    background-color: #fff;
    margin-left: 0;
    font-weight: 500;
}

.page-link-outline i {
    font-size: 18px;
    line-height: 0;
}

.page-item.active .page-link-outline {
    z-index: 1;
    background-color: #fff;
    border: 1px solid var(--primary);
    border-radius: 5px;
    color: var(--primary);
}

/* ###### 3.12 Popover ###### */
.popover {
    font-size: 12px;
    padding: 0;
    border-radius: 0;
}

.popover-header {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: #383853;
    letter-spacing: 0.5px;
    padding: 12px 15px;
    background-color: #fff;
    border-color: rgba(28, 39, 60, 0.2);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.popover-header::before {
    display: none !important;
}

.popover-body {
    padding: 15px;
}

.popover-body p:last-child {
    margin-bottom: 0;
}

.popover-head-primary .popover-header {
    color: #fff;
    background-color: var(--primary);
}

.popover-head-primary.bs-popover-top .popover-arrow,
.popover-head-primary.bs-popover-auto[data-popper-placement^=top] .popover-arrow {
    bottom: -7px;
}

.popover-head-primary.bs-popover-start .popover-arrow,
.popover-head-primary.bs-popover-auto[data-popper-placement^=left] .popover-arrow {
    right: -7px;
}

.popover-head-primary.bs-popover-end .popover-arrow,
.popover-head-primary.bs-popover-auto[data-popper-placement^=right] .popover-arrow {
    left: -7px;
}

.popover-head-primary.bs-popover-bottom .popover-arrow::after,
.popover-head-primary.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
    border-bottom-color: var(--primary) !important;
}

.popover-head-secondary .popover-header {
    color: #fff;
    background-color: var(--primary);
}

.popover-head-secondary.bs-popover-top .popover-arrow,
.popover-head-secondary.bs-popover-auto[data-popper-placement^=top] .popover-arrow {
    bottom: -7px;
}

.popover-head-secondary.bs-popover-start .popover-arrow,
.popover-head-secondary.bs-popover-auto[data-popper-placement^=left] .popover-arrow {
    right: -7px;
}

.popover-head-secondary.bs-popover-end .popover-arrow,
.popover-head-secondary.bs-popover-auto[data-popper-placement^=right] .popover-arrow {
    left: -7px;
}

.popover-head-secondary.bs-popover-bottom .popover-arrow::after,
.popover-head-secondary.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
    border-bottom-color: var(--primary);
}

.popover-head-primary,
.popover-head-secondary {
    border: 0;
}

.popover-head-primary .popover-header,
.popover-head-secondary .popover-header {
    border: 0;
}

.popover-head-primary .popover-body,
.popover-head-secondary .popover-body {
    border-top-width: 0;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}

.popover-head-primary.bs-popover-bottom .popover-arrow::before,
.popover-head-primary.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
    display: none;
}

.popover-head-secondary.bs-popover-bottom .popover-arrow::before,
.popover-head-secondary.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
    display: none;
}

.popover-primary {
    background-color: var(--primary) !important;
    border-width: 0;
    padding: 15px;
    border: 1px solid var(--primary) !important;
}

.popover-primary .popover-header {
    background-color: transparent;
    border-bottom-width: 0;
    padding: 0 0 15px;
    color: #fff;
}

.popover-primary .popover-body {
    padding: 0;
    color: rgba(255, 255, 255, 0.75);
}

.popover-primary .popover-arrow::before {
    display: none;
}

.popover-primary.bs-popover-top .popover-arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^=top] .popover-arrow::after {
    border-top-color: var(--primary) !important;
}

.popover-primary.bs-popover-bottom .popover-arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
    border-bottom-color: var(--primary);
}

.popover-primary.bs-popover-start .popover-arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^=left] .popover-arrow::after {
    border-left-color: var(--primary);
}

.popover-primary.bs-popover-end .popover-arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^=right] .popover-arrow::after {
    border-right-color: var(--primary);
}

.popover-secondary {
    background-color: var(--secondary) !important;
    border-width: 0;
    padding: 15px;
    border: 1px solid var(--secondary) !important;
}

.popover-secondary .popover-header {
    background-color: transparent;
    border-bottom-width: 0;
    padding: 0 0 15px;
    color: #fff;
}

.popover-secondary .popover-body {
    padding: 0;
    color: rgba(255, 255, 255, 0.75);
}

.popover-secondary .popover-arrow::before {
    display: none;
}

.popover-secondary.bs-popover-top .popover-arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^=top] .popover-arrow::after {
    border-top-color: var(--secondary);
}

.popover-secondary.bs-popover-bottom .popover-arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
    border-bottom-color: var(--secondary) !important;
}

.popover-secondary.bs-popover-start .popover-arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^=left] .popover-arrow::after {
    border-left-color: var(--primary);
}

.popover-secondary.bs-popover-end .popover-arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^=right] .popover-arrow::after {
    border-right-color: var(--primary);
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    display: block;
    max-width: unset;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.76562rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 5px;
    border: 1px solid #ededf5;
    box-shadow: -8px 12px 18px 0 rgba(154, 154, 204, 0.1);
}

.popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
}

.popover .popover-arrow::before,
.popover .popover-arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[data-popper-placement^=top] {
    margin-bottom: 0.5rem;
}

.bs-popover-top>.popover-arrow,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow {
    bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #ededf5;
}

.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after {
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff;
}

.bs-popover-auto[data-popper-placement^=right] {
    margin-left: 0.8rem !important;
}

.bs-popover-end>.popover-arrow,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0 0;
}

.bs-popover-end>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #ededf5;
}

.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[data-popper-placement^=bottom] {
    margin-top: 0.5rem;
}

.bs-popover-bottom>.popover-arrow,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow {
    top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #ededf5;
}

.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7;
}

.bs-popover-start>.popover-arrow,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0rem 0;
}

.bs-popover-start>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #ededf5;
}

.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff;
}

.popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    background-color: #fff;
    border-bottom: 1px solid #ededf5;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
    display: none;
}

.popover-body {
    padding: 0.5rem 0.75rem;
    color: #4a4a69;
}

.popover-static-demo {
    background-color: #f0f0f8;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
}

.popover-static-demo .popover {
    z-index: 0;
    opacity: 1;
    position: relative;
    display: inline-block;
    margin: 0 10px;
    box-shadow: -8px 12px 18px 0 rgba(154, 154, 204, 0.1);
    border: 1px solid #ededf5;
}

.popover-static-demo .bs-popover-top .popover-arrow,
.popover-static-demo .bs-popover-auto[data-popper-placement^=top] .popover-arrow,
.popover-static-demo .bs-popover-bottom .popover-arrow,
.popover-static-demo .bs-popover-auto[data-popper-placement^=bottom] .popover-arrow {
    left: 50%;
    margin-left: -5px;
}

.popover-static-demo .bs-popover-start,
.popover-static-demo .bs-popover-auto[data-popper-placement^=left],
.popover-static-demo .bs-popover-end,
.popover-static-demo .bs-popover-auto[data-popper-placement^=right] {
    margin-top: 5px;
}

.popover-static-demo .bs-popover-start .popover-arrow,
.popover-static-demo .bs-popover-auto[data-popper-placement^=left] .popover-arrow,
.popover-static-demo .bs-popover-end .popover-arrow,
.popover-static-demo .bs-popover-auto[data-popper-placement^=right] .popover-arrow {
    top: 50%;
    margin-top: -5px;
}

/* ###### 3.13 Progress ###### */
.progress {
    height: auto;
    border-radius: 0;
}

.progress-bar {
    border-radius: 3px;
    height: 13px;
    font-size: 12px;
}

.progress-bar-xs {
    height: 5px;
}

.progress-bar-sm {
    height: 8px;
}

.progress-bar-lg {
    height: 15px;
}

.progress {
    display: flex;
    overflow: hidden;
    font-size: 0.65625rem;
    background-color: #f0f0f8;
    border-radius: 3px;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: var(--primary);
    transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none;
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
}

.progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
        animation: none;
    }
}

.progress-sm .progress-bar {
    border-radius: 1px;
}

/* ###### 9.8 Typography   ###### */
/* Estilos de cabeceras */
.heading-1,
.heading-1-medium,
.heading-1-bold {
    font-size: 60px;
    line-height: 68px;
    font-weight: 400;
}

.heading-1-medium {
    font-weight: 500;
}

.heading-1-bold {
    font-weight: 700;
}

.heading-2,
.heading-2-medium,
.heading-2-bold {
    font-size: 40px;
    line-height: 48px;
    font-weight: 400;
}

.heading-2-medium {
    font-weight: 500;
}

.heading-2-bold {
    font-weight: 700;
}

.heading-3,
.heading-3-medium,
.heading-3-bold {
    font-size: 32px;
    line-height: 40px;
    font-weight: 400;
}

.heading-3-medium {
    font-weight: 500;
}

.heading-3-bold {
    font-weight: 700;
}

.heading-4,
.heading-4-medium,
.heading-4-bold {
    font-size: 22px;
    line-height: 30px;
    font-weight: 400;
}

.heading-4-medium {
    font-weight: 500;
}

.heading-4-bold {
    font-weight: 700;
}

.heading-5,
.heading-5-medium,
.heading-5-bold {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
}

.heading-5-medium {
    font-weight: 500;
}

.heading-5-bold {
    font-weight: 700;
}

.heading-6,
.heading-6-medium,
.heading-6-bold {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
}

.heading-6-medium {
    font-weight: 500;
}

.heading-6-bold {
    font-weight: 700;
}

/* BODY STYLES */
.tx-body-1,
.ngx-datatable.custom-datatable .datatable-body .datatable-body-cell,
.table tbody tr td,
.tx-body-1-medium,
.tx-body-1-bold,
.ngx-datatable.custom-datatable .datatable-header .datatable-header-cell,
.table tbody tr th,
.table thead th,
.table thead td,
.accordion-button,
.panel-heading1 .panel-title1 a {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}

.tx-body-1-medium {
    font-weight: 500;
}

.tx-body-1-bold,
.ngx-datatable.custom-datatable .datatable-header .datatable-header-cell,
.table tbody tr th,
.table thead th,
.table thead td,
.accordion-button,
.panel-heading1 .panel-title1 a {
    font-weight: 700;
}

.tx-body-2,
.tx-body-2-medium,
.tx-body-2-bold,
.modal-accordion .accordion-button,
.pagination .page-link {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
}

.tx-body-2-medium {
    font-weight: 500;
}

.tx-body-2-bold,
.modal-accordion .accordion-button {
    font-weight: 700;
}

/* TAG STYLES */
.tx-tag {
    font-size: 10px;
    line-height: 14px;
    font-weight: 400;
}

.tx-tag-medium {
    font-size: 10px;
    line-height: 14px;
    font-weight: 500;
}

.tx-tag-card {
    font-size: 8px;
    line-height: 10px;
    font-weight: 400;
}

.tx-tag-card-medium {
    font-size: 8px;
    line-height: 10px;
    font-weight: 500;
}

/* FORM STYLES */
.tx-button {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
}

.tx-black {
    font-weight: 900;
}

.tx-bold {
    font-weight: 700;
}

.tx-semibold {
    font-weight: 600;
}

.tx-medium {
    font-weight: 500;
}

.tx-normal {
    font-weight: 400;
}

.tx-light {
    font-weight: 300;
}

.tx-thin {
    font-weight: 200;
}

.tx-xthin {
    font-weight: 100;
}

.tx-sserif {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.tx-roboto {
    font-family: "Roboto", sans-serif;
}

.tx-white {
    color: #fff;
}

.tx-facebook {
    color: #3b5998;
}

.tx-pinterest {
    color: #cd2029;
}

.tx-google-plus {
    color: #d34836;
}

.tx-twitter {
    color: #429cd6;
}

.tx-black {
    color: #000;
}

.tx-primary {
    color: var(--primary);
}

.tx-primary-75 {
    color: var(--primary-75);
}

.tx-success {
    color: var(--success);
}

.tx-warning {
    color: var(--warning);
}

.tx-danger {
    color: #f34343;
}

.tx-info {
    color: var(--info);
}

.tx-inverse {
    color: #7987a1;
}

.tx-teal {
    color: #29ddee;
}

.tx-dark {
    color: #323251;
}

.tx-indigo {
    color: var(--primary);
}

.tx-purple {
    color: #ad4ffa;
}

.tx-orange {
    color: #fd7e14;
}

.tx-pink {
    color: #f754fa;
}

.tx-gray-100 {
    color: #f9f9fb;
}

.tx-gray-200 {
    color: #f0f0f8;
}

.tx-gray-300 {
    color: #e1e1ef;
}

.tx-gray-400 {
    color: #d6d6e6;
}

.tx-gray-500 {
    color: #949eb7;
}

.tx-gray-600 {
    color: #7987a1;
}

.tx-gray-700 {
    color: #4d5875;
}

.tx-gray-800 {
    color: #383853;
}

.tx-gray-900 {
    color: #323251;
}

.tx-white-2 {
    color: rgba(255, 255, 255, 0.2);
}

.tx-white-3 {
    color: rgba(255, 255, 255, 0.3);
}

.tx-white-4 {
    color: rgba(255, 255, 255, 0.4);
}

.tx-white-5 {
    color: rgba(255, 255, 255, 0.5);
}

.tx-white-6 {
    color: rgba(255, 255, 255, 0.6);
}

.tx-white-7 {
    color: rgba(255, 255, 255, 0.7);
}

.tx-white-8 {
    color: rgba(255, 255, 255, 0.8);
}

.tx-neutral {
    color: var(--neutral-text);
}

.tx-neutral-20 {
    color: var(--neutral-20);
}

.tx-neutral-30 {
    color: var(--neutral-30);
}

.tx-neutral-40 {
    color: var(--neutral-40);
}

.tx-neutral-50 {
    color: var(--neutral-50);
}

.tx-neutral-60 {
    color: var(--neutral-60);
}

.tx-neutral-70 {
    color: var(--neutral-70);
}

.tx-neutral-80 {
    color: var(--neutral-80);
}

.tx-neutral-90 {
    color: var(--neutral-90);
}

.tx-neutral-darker {
    color: var(--neutral-darker);
}

.tx-spacing-1 {
    letter-spacing: 0.5px;
}

.tx-spacing-2 {
    letter-spacing: 1px;
}

.tx-spacing-3 {
    letter-spacing: 1.5px;
}

.tx-spacing-4 {
    letter-spacing: 2px;
}

.tx-spacing-5 {
    letter-spacing: 2.5px;
}

.tx-spacing-6 {
    letter-spacing: 3px;
}

.tx-spacing-7 {
    letter-spacing: 3.5px;
}

.tx-spacing-8 {
    letter-spacing: 4px;
}

.tx-spacing--1 {
    letter-spacing: -0.5px;
}

.tx-spacing--2 {
    letter-spacing: -1px;
}

.tx-spacing--3 {
    letter-spacing: -1.5px;
}

.tx-spacing--4 {
    letter-spacing: -2px;
}

.tx-spacing--5 {
    letter-spacing: -2.5px;
}

.tx-spacing--6 {
    letter-spacing: -3px;
}

.tx-spacing--7 {
    letter-spacing: -3.5px;
}

.tx-spacing--8 {
    letter-spacing: -4px;
}

.tx-uppercase {
    text-transform: uppercase;
}

.tx-lowercase {
    text-transform: lowercase;
}

.tx-transform-none {
    text-transform: none;
}

.tx-center {
    text-align: center;
}

.tx-right {
    text-align: right;
}

.tx-left {
    text-align: left;
}

.tx-center-f {
    text-align: center;
}

.tx-right-f {
    text-align: right;
}

.tx-left-f {
    text-align: left;
}

.tx-italic {
    font-style: italic;
}

.tx-style-normal {
    font-style: normal;
}

.lh-base {
    line-height: 1.5;
}

.lh-normal {
    line-height: normal;
}

.lh-0 {
    line-height: 0;
}

.lh--1 {
    line-height: 0.1;
}

.lh--2 {
    line-height: 0.2;
}

.lh--3 {
    line-height: 0.3;
}

.lh--4 {
    line-height: 0.4;
}

.lh--5 {
    line-height: 0.5;
}

.lh--6 {
    line-height: 0.6;
}

.lh--7 {
    line-height: 0.7;
}

.lh--8 {
    line-height: 0.8;
}

.lh--9 {
    line-height: 0.9;
}

.lh-1 {
    line-height: 1.1;
}

.lh-2 {
    line-height: 1.2;
}

.lh-3 {
    line-height: 1.3;
}

.lh-4 {
    line-height: 1.4;
}

.lh-5 {
    line-height: 1.5;
}

.lh-6 {
    line-height: 1.6;
}

.lh-7 {
    line-height: 1.7;
}

.lh-8 {
    line-height: 1.8;
}

.lh-9 {
    line-height: 1.9;
}

.lh-10 {
    line-height: 2;
}

.lh-11 {
    line-height: 2.1;
}

.lh-12 {
    line-height: 2.2;
}

.lh-13 {
    line-height: 2.3;
}

.lh-14 {
    line-height: 2.4;
}

.lh-15 {
    line-height: 2.5;
}

.valign-top {
    vertical-align: top;
}

.valign-middle {
    vertical-align: middle;
}

.valign-bottom {
    vertical-align: baseline;
}

.valign-top-f {
    vertical-align: top;
}

.valign-middle-f {
    vertical-align: middle;
}

.valign-bottom-f {
    vertical-align: baseline;
}

.tx-base {
    font-size: 0.875rem;
}

.tx-8 {
    font-size: 8px;
}

.tx-8-f {
    font-size: 8px;
}

.tx-9 {
    font-size: 9px;
}

.tx-9-f {
    font-size: 9px;
}

.tx-10 {
    font-size: 10px;
}

.tx-10-f {
    font-size: 10px;
}

.tx-11 {
    font-size: 11px;
}

.tx-11-f {
    font-size: 11px;
}

.tx-12 {
    font-size: 12px;
}

.tx-12-f {
    font-size: 12px;
}

.tx-13 {
    font-size: 13px;
}

.tx-13-f {
    font-size: 13px;
}

.tx-14 {
    font-size: 14px;
}

.tx-14-f {
    font-size: 14px;
}

.tx-15 {
    font-size: 15px !important;
}

.tx-15-f {
    font-size: 15px;
}

.tx-16 {
    font-size: 16px;
}

.tx-16-f {
    font-size: 16px;
}

.tx-17 {
    font-size: 17px;
}

.tx-18 {
    font-size: 18px !important;
}

.tx-18-f {
    font-size: 18px;
}

.tx-20 {
    font-size: 20px !important;
}

.tx-20-f {
    font-size: 20px;
}

.tx-21 {
    font-size: 21px;
}

.tx-21-f {
    font-size: 21px;
}

.tx-22 {
    font-size: 22px;
}

.tx-22-f {
    font-size: 22px;
}

.tx-24 {
    font-size: 24px;
}

.tx-24-f {
    font-size: 24px;
}

.tx-26 {
    font-size: 26px;
}

.tx-26-f {
    font-size: 26px;
}

.tx-28 {
    font-size: 28px;
}

.tx-28-f {
    font-size: 28px;
}

.tx-30 {
    font-size: 30px;
}

.tx-30-f {
    font-size: 30px;
}

.tx-32 {
    font-size: 32px;
}

.tx-32-f {
    font-size: 32px;
}

.tx-34 {
    font-size: 34px;
}

.tx-34-f {
    font-size: 34px;
}

.tx-36 {
    font-size: 36px;
}

.tx-36-f {
    font-size: 36px;
}

.tx-38 {
    font-size: 38px;
}

.tx-38-f {
    font-size: 38px;
}

.tx-40 {
    font-size: 40px;
}

.tx-40-f {
    font-size: 40px;
}

.tx-42 {
    font-size: 42px;
}

.tx-42-f {
    font-size: 42px;
}

.tx-44 {
    font-size: 44px;
}

.tx-44-f {
    font-size: 44px;
}

.tx-46 {
    font-size: 46px;
}

.tx-46-f {
    font-size: 46px;
}

.tx-48 {
    font-size: 48px;
}

.tx-48-f {
    font-size: 48px;
}

.tx-50 {
    font-size: 50px;
}

.tx-50-f {
    font-size: 50px;
}

.tx-52 {
    font-size: 52px;
}

.tx-52-f {
    font-size: 52px;
}

.tx-54 {
    font-size: 54px;
}

.tx-54-f {
    font-size: 54px;
}

.tx-56 {
    font-size: 56px;
}

.tx-56-f {
    font-size: 56px;
}

.tx-58 {
    font-size: 58px;
}

.tx-58-f {
    font-size: 58px;
}

.tx-60 {
    font-size: 60px;
}

.tx-60-f {
    font-size: 60px;
}

.tx-62 {
    font-size: 62px;
}

.tx-62-f {
    font-size: 62px;
}

.tx-64 {
    font-size: 64px;
}

.tx-64-f {
    font-size: 64px;
}

.tx-66 {
    font-size: 66px;
}

.tx-66-f {
    font-size: 66px;
}

.tx-68 {
    font-size: 68px;
}

.tx-68-f {
    font-size: 68px;
}

.tx-70 {
    font-size: 70px;
}

.tx-70-f {
    font-size: 70px;
}

.tx-72 {
    font-size: 72px;
}

.tx-72-f {
    font-size: 72px;
}

.tx-74 {
    font-size: 74px;
}

.tx-74-f {
    font-size: 74px;
}

.tx-76 {
    font-size: 76px;
}

.tx-76-f {
    font-size: 76px;
}

.tx-78 {
    font-size: 78px;
}

.tx-78-f {
    font-size: 78px;
}

.tx-80 {
    font-size: 80px;
}

.tx-80-f {
    font-size: 80px;
}

.tx-82 {
    font-size: 82px;
}

.tx-82-f {
    font-size: 82px;
}

.tx-84 {
    font-size: 84px;
}

.tx-84-f {
    font-size: 84px;
}

.tx-86 {
    font-size: 86px;
}

.tx-86-f {
    font-size: 86px;
}

.tx-88 {
    font-size: 88px;
}

.tx-88-f {
    font-size: 88px;
}

.tx-90 {
    font-size: 90px;
}

.tx-90-f {
    font-size: 90px;
}

.tx-92 {
    font-size: 92px;
}

.tx-92-f {
    font-size: 92px;
}

.tx-94 {
    font-size: 94px;
}

.tx-94-f {
    font-size: 94px;
}

.tx-96 {
    font-size: 96px;
}

.tx-96-f {
    font-size: 96px;
}

.tx-98 {
    font-size: 98px;
}

.tx-98-f {
    font-size: 98px;
}

.tx-100 {
    font-size: 100px;
}

.tx-100-f {
    font-size: 100px;
}

.tx-102 {
    font-size: 102px;
}

.tx-102-f {
    font-size: 102px;
}

.tx-104 {
    font-size: 104px;
}

.tx-104-f {
    font-size: 104px;
}

.tx-106 {
    font-size: 106px;
}

.tx-106-f {
    font-size: 106px;
}

.tx-108 {
    font-size: 108px;
}

.tx-108-f {
    font-size: 108px;
}

.tx-110 {
    font-size: 110px;
}

.tx-110-f {
    font-size: 110px;
}

.tx-112 {
    font-size: 112px;
}

.tx-112-f {
    font-size: 112px;
}

.tx-114 {
    font-size: 114px;
}

.tx-114-f {
    font-size: 114px;
}

.tx-116 {
    font-size: 116px;
}

.tx-116-f {
    font-size: 116px;
}

.tx-118 {
    font-size: 118px;
}

.tx-118-f {
    font-size: 118px;
}

.tx-120 {
    font-size: 120px;
}

.tx-120-f {
    font-size: 120px;
}

.tx-122 {
    font-size: 122px;
}

.tx-122-f {
    font-size: 122px;
}

.tx-124 {
    font-size: 124px;
}

.tx-124-f {
    font-size: 124px;
}

.tx-126 {
    font-size: 126px;
}

.tx-126-f {
    font-size: 126px;
}

.tx-128 {
    font-size: 128px;
}

.tx-128-f {
    font-size: 128px;
}

.tx-130 {
    font-size: 130px;
}

.tx-130-f {
    font-size: 130px;
}

.tx-132 {
    font-size: 132px;
}

.tx-132-f {
    font-size: 132px;
}

.tx-134 {
    font-size: 134px;
}

.tx-134-f {
    font-size: 134px;
}

.tx-136 {
    font-size: 136px;
}

.tx-136-f {
    font-size: 136px;
}

.tx-138 {
    font-size: 138px;
}

.tx-138-f {
    font-size: 138px;
}

.tx-140 {
    font-size: 140px;
}

.tx-140-f {
    font-size: 140px;
}

@media (min-width: 480px) {
    .tx-xs-8 {
        font-size: 8px;
    }

    .tx-xs-8-f {
        font-size: 8px;
    }

    .tx-xs-9 {
        font-size: 9px;
    }

    .tx-xs-9-f {
        font-size: 9px;
    }

    .tx-xs-10 {
        font-size: 10px;
    }

    .tx-xs-10-f {
        font-size: 10px;
    }

    .tx-xs-11 {
        font-size: 11px;
    }

    .tx-xs-11-f {
        font-size: 11px;
    }

    .tx-xs-12 {
        font-size: 12px;
    }

    .tx-xs-12-f {
        font-size: 12px;
    }

    .tx-xs-13 {
        font-size: 13px;
    }

    .tx-xs-13-f {
        font-size: 13px;
    }

    .tx-xs-14 {
        font-size: 14px;
    }

    .tx-xs-14-f {
        font-size: 14px;
    }

    .tx-xs-15 {
        font-size: 15px;
    }

    .tx-xs-15-f {
        font-size: 15px;
    }

    .tx-xs-16 {
        font-size: 16px;
    }

    .tx-xs-16-f {
        font-size: 16px;
    }

    .tx-xs-18 {
        font-size: 18px;
    }

    .tx-xs-18-f {
        font-size: 18px;
    }

    .tx-xs-20 {
        font-size: 20px;
    }

    .tx-xs-20-f {
        font-size: 20px;
    }

    .tx-xs-22 {
        font-size: 22px;
    }

    .tx-xs-22-f {
        font-size: 22px;
    }

    .tx-xs-24 {
        font-size: 24px;
    }

    .tx-xs-24-f {
        font-size: 24px;
    }

    .tx-xs-26 {
        font-size: 26px;
    }

    .tx-xs-26-f {
        font-size: 26px;
    }

    .tx-xs-28 {
        font-size: 28px;
    }

    .tx-xs-28-f {
        font-size: 28px;
    }

    .tx-xs-30 {
        font-size: 30px;
    }

    .tx-xs-30-f {
        font-size: 30px;
    }

    .tx-xs-32 {
        font-size: 32px;
    }

    .tx-xs-32-f {
        font-size: 32px;
    }

    .tx-xs-34 {
        font-size: 34px;
    }

    .tx-xs-34-f {
        font-size: 34px;
    }

    .tx-xs-36 {
        font-size: 36px;
    }

    .tx-xs-36-f {
        font-size: 36px;
    }

    .tx-xs-38 {
        font-size: 38px;
    }

    .tx-xs-38-f {
        font-size: 38px;
    }

    .tx-xs-40 {
        font-size: 40px;
    }

    .tx-xs-40-f {
        font-size: 40px;
    }

    .tx-xs-42 {
        font-size: 42px;
    }

    .tx-xs-42-f {
        font-size: 42px;
    }

    .tx-xs-44 {
        font-size: 44px;
    }

    .tx-xs-44-f {
        font-size: 44px;
    }

    .tx-xs-46 {
        font-size: 46px;
    }

    .tx-xs-46-f {
        font-size: 46px;
    }

    .tx-xs-48 {
        font-size: 48px;
    }

    .tx-xs-48-f {
        font-size: 48px;
    }

    .tx-xs-50 {
        font-size: 50px;
    }

    .tx-xs-50-f {
        font-size: 50px;
    }

    .tx-xs-52 {
        font-size: 52px;
    }

    .tx-xs-52-f {
        font-size: 52px;
    }

    .tx-xs-54 {
        font-size: 54px;
    }

    .tx-xs-54-f {
        font-size: 54px;
    }

    .tx-xs-56 {
        font-size: 56px;
    }

    .tx-xs-56-f {
        font-size: 56px;
    }

    .tx-xs-58 {
        font-size: 58px;
    }

    .tx-xs-58-f {
        font-size: 58px;
    }

    .tx-xs-60 {
        font-size: 60px;
    }

    .tx-xs-60-f {
        font-size: 60px;
    }

    .tx-xs-62 {
        font-size: 62px;
    }

    .tx-xs-62-f {
        font-size: 62px;
    }

    .tx-xs-64 {
        font-size: 64px;
    }

    .tx-xs-64-f {
        font-size: 64px;
    }

    .tx-xs-66 {
        font-size: 66px;
    }

    .tx-xs-66-f {
        font-size: 66px;
    }

    .tx-xs-68 {
        font-size: 68px;
    }

    .tx-xs-68-f {
        font-size: 68px;
    }

    .tx-xs-70 {
        font-size: 70px;
    }

    .tx-xs-70-f {
        font-size: 70px;
    }

    .tx-xs-72 {
        font-size: 72px;
    }

    .tx-xs-72-f {
        font-size: 72px;
    }

    .tx-xs-74 {
        font-size: 74px;
    }

    .tx-xs-74-f {
        font-size: 74px;
    }

    .tx-xs-76 {
        font-size: 76px;
    }

    .tx-xs-76-f {
        font-size: 76px;
    }

    .tx-xs-78 {
        font-size: 78px;
    }

    .tx-xs-78-f {
        font-size: 78px;
    }

    .tx-xs-80 {
        font-size: 80px;
    }

    .tx-xs-80-f {
        font-size: 80px;
    }

    .tx-xs-82 {
        font-size: 82px;
    }

    .tx-xs-82-f {
        font-size: 82px;
    }

    .tx-xs-84 {
        font-size: 84px;
    }

    .tx-xs-84-f {
        font-size: 84px;
    }

    .tx-xs-86 {
        font-size: 86px;
    }

    .tx-xs-86-f {
        font-size: 86px;
    }

    .tx-xs-88 {
        font-size: 88px;
    }

    .tx-xs-88-f {
        font-size: 88px;
    }

    .tx-xs-90 {
        font-size: 90px;
    }

    .tx-xs-90-f {
        font-size: 90px;
    }

    .tx-xs-92 {
        font-size: 92px;
    }

    .tx-xs-92-f {
        font-size: 92px;
    }

    .tx-xs-94 {
        font-size: 94px;
    }

    .tx-xs-94-f {
        font-size: 94px;
    }

    .tx-xs-96 {
        font-size: 96px;
    }

    .tx-xs-96-f {
        font-size: 96px;
    }

    .tx-xs-98 {
        font-size: 98px;
    }

    .tx-xs-98-f {
        font-size: 98px;
    }

    .tx-xs-100 {
        font-size: 100px;
    }

    .tx-xs-100-f {
        font-size: 100px;
    }

    .tx-xs-102 {
        font-size: 102px;
    }

    .tx-xs-102-f {
        font-size: 102px;
    }

    .tx-xs-104 {
        font-size: 104px;
    }

    .tx-xs-104-f {
        font-size: 104px;
    }

    .tx-xs-106 {
        font-size: 106px;
    }

    .tx-xs-106-f {
        font-size: 106px;
    }

    .tx-xs-108 {
        font-size: 108px;
    }

    .tx-xs-108-f {
        font-size: 108px;
    }

    .tx-xs-110 {
        font-size: 110px;
    }

    .tx-xs-110-f {
        font-size: 110px;
    }

    .tx-xs-112 {
        font-size: 112px;
    }

    .tx-xs-112-f {
        font-size: 112px;
    }

    .tx-xs-114 {
        font-size: 114px;
    }

    .tx-xs-114-f {
        font-size: 114px;
    }

    .tx-xs-116 {
        font-size: 116px;
    }

    .tx-xs-116-f {
        font-size: 116px;
    }

    .tx-xs-118 {
        font-size: 118px;
    }

    .tx-xs-118-f {
        font-size: 118px;
    }

    .tx-xs-120 {
        font-size: 120px;
    }

    .tx-xs-120-f {
        font-size: 120px;
    }

    .tx-xs-122 {
        font-size: 122px;
    }

    .tx-xs-122-f {
        font-size: 122px;
    }

    .tx-xs-124 {
        font-size: 124px;
    }

    .tx-xs-124-f {
        font-size: 124px;
    }

    .tx-xs-126 {
        font-size: 126px;
    }

    .tx-xs-126-f {
        font-size: 126px;
    }

    .tx-xs-128 {
        font-size: 128px;
    }

    .tx-xs-128-f {
        font-size: 128px;
    }

    .tx-xs-130 {
        font-size: 130px;
    }

    .tx-xs-130-f {
        font-size: 130px;
    }

    .tx-xs-132 {
        font-size: 132px;
    }

    .tx-xs-132-f {
        font-size: 132px;
    }

    .tx-xs-134 {
        font-size: 134px;
    }

    .tx-xs-134-f {
        font-size: 134px;
    }

    .tx-xs-136 {
        font-size: 136px;
    }

    .tx-xs-136-f {
        font-size: 136px;
    }

    .tx-xs-138 {
        font-size: 138px;
    }

    .tx-xs-138-f {
        font-size: 138px;
    }

    .tx-xs-140 {
        font-size: 140px;
    }

    .tx-xs-140-f {
        font-size: 140px;
    }

    .tx-xs-base {
        font-size: 0.875rem;
    }

    .tx-xs-bold {
        font-weight: 700;
    }

    .tx-xs-semibold {
        font-weight: 600;
    }

    .tx-xs-medium {
        font-weight: 500;
    }

    .tx-xs-normal {
        font-weight: 400;
    }

    .tx-xs-light {
        font-weight: 300;
    }

    .tx-xs-thin {
        font-weight: 200;
    }

    .tx-xs-xthin {
        font-weight: 100;
    }

    .lh-xs-0 {
        line-height: 0;
    }

    .lh-xs-1 {
        line-height: 1.1;
    }

    .lh-xs-2 {
        line-height: 1.2;
    }

    .lh-xs-3 {
        line-height: 1.3;
    }

    .lh-xs-4 {
        line-height: 1.4;
    }

    .lh-xs-5 {
        line-height: 1.5;
    }

    .lh-xs-6 {
        line-height: 1.6;
    }

    .lh-xs-7 {
        line-height: 1.7;
    }

    .lh-xs-8 {
        line-height: 1.8;
    }

    .lh-xs-9 {
        line-height: 1.9;
    }

    .lh-xs-10 {
        line-height: 2;
    }

    .lh-xs-11 {
        line-height: 2.1;
    }

    .lh-xs-12 {
        line-height: 2.2;
    }

    .lh-xs-13 {
        line-height: 2.3;
    }

    .lh-xs-14 {
        line-height: 2.4;
    }

    .lh-xs-15 {
        line-height: 2.5;
    }

    .tx-xs-center {
        text-align: center;
    }

    .tx-xs-right {
        text-align: right;
    }

    .tx-xs-left {
        text-align: left;
    }

    .tx-xs-center-f {
        text-align: center;
    }

    .tx-xs-right-f {
        text-align: right;
    }

    .tx-xs-left-f {
        text-align: left;
    }
}

@media (min-width: 576px) {
    .tx-sm-8 {
        font-size: 8px;
    }

    .tx-sm-8-f {
        font-size: 8px;
    }

    .tx-sm-9 {
        font-size: 9px;
    }

    .tx-sm-9-f {
        font-size: 9px;
    }

    .tx-sm-10 {
        font-size: 10px;
    }

    .tx-sm-10-f {
        font-size: 10px;
    }

    .tx-sm-11 {
        font-size: 11px;
    }

    .tx-sm-11-f {
        font-size: 11px;
    }

    .tx-sm-12 {
        font-size: 12px;
    }

    .tx-sm-12-f {
        font-size: 12px;
    }

    .tx-sm-13 {
        font-size: 13px;
    }

    .tx-sm-13-f {
        font-size: 13px;
    }

    .tx-sm-14 {
        font-size: 14px;
    }

    .tx-sm-14-f {
        font-size: 14px;
    }

    .tx-sm-15 {
        font-size: 15px;
    }

    .tx-sm-15-f {
        font-size: 15px;
    }

    .tx-sm-16 {
        font-size: 16px;
    }

    .tx-sm-16-f {
        font-size: 16px;
    }

    .tx-sm-18 {
        font-size: 18px;
    }

    .tx-sm-18-f {
        font-size: 18px;
    }

    .tx-sm-20 {
        font-size: 20px;
    }

    .tx-sm-20-f {
        font-size: 20px;
    }

    .tx-sm-22 {
        font-size: 22px;
    }

    .tx-sm-22-f {
        font-size: 22px;
    }

    .tx-sm-24 {
        font-size: 24px;
    }

    .tx-sm-24-f {
        font-size: 24px;
    }

    .tx-sm-26 {
        font-size: 26px;
    }

    .tx-sm-26-f {
        font-size: 26px;
    }

    .tx-sm-28 {
        font-size: 28px;
    }

    .tx-sm-28-f {
        font-size: 28px;
    }

    .tx-sm-30 {
        font-size: 30px;
    }

    .tx-sm-30-f {
        font-size: 30px;
    }

    .tx-sm-32 {
        font-size: 32px;
    }

    .tx-sm-32-f {
        font-size: 32px;
    }

    .tx-sm-34 {
        font-size: 34px;
    }

    .tx-sm-34-f {
        font-size: 34px;
    }

    .tx-sm-36 {
        font-size: 36px;
    }

    .tx-sm-36-f {
        font-size: 36px;
    }

    .tx-sm-38 {
        font-size: 38px;
    }

    .tx-sm-38-f {
        font-size: 38px;
    }

    .tx-sm-40 {
        font-size: 40px;
    }

    .tx-sm-40-f {
        font-size: 40px;
    }

    .tx-sm-42 {
        font-size: 42px;
    }

    .tx-sm-42-f {
        font-size: 42px;
    }

    .tx-sm-44 {
        font-size: 44px;
    }

    .tx-sm-44-f {
        font-size: 44px;
    }

    .tx-sm-46 {
        font-size: 46px;
    }

    .tx-sm-46-f {
        font-size: 46px;
    }

    .tx-sm-48 {
        font-size: 48px;
    }

    .tx-sm-48-f {
        font-size: 48px;
    }

    .tx-sm-50 {
        font-size: 50px;
    }

    .tx-sm-50-f {
        font-size: 50px;
    }

    .tx-sm-52 {
        font-size: 52px;
    }

    .tx-sm-52-f {
        font-size: 52px;
    }

    .tx-sm-54 {
        font-size: 54px;
    }

    .tx-sm-54-f {
        font-size: 54px;
    }

    .tx-sm-56 {
        font-size: 56px;
    }

    .tx-sm-56-f {
        font-size: 56px;
    }

    .tx-sm-58 {
        font-size: 58px;
    }

    .tx-sm-58-f {
        font-size: 58px;
    }

    .tx-sm-60 {
        font-size: 60px;
    }

    .tx-sm-60-f {
        font-size: 60px;
    }

    .tx-sm-62 {
        font-size: 62px;
    }

    .tx-sm-62-f {
        font-size: 62px;
    }

    .tx-sm-64 {
        font-size: 64px;
    }

    .tx-sm-64-f {
        font-size: 64px;
    }

    .tx-sm-66 {
        font-size: 66px;
    }

    .tx-sm-66-f {
        font-size: 66px;
    }

    .tx-sm-68 {
        font-size: 68px;
    }

    .tx-sm-68-f {
        font-size: 68px;
    }

    .tx-sm-70 {
        font-size: 70px;
    }

    .tx-sm-70-f {
        font-size: 70px;
    }

    .tx-sm-72 {
        font-size: 72px;
    }

    .tx-sm-72-f {
        font-size: 72px;
    }

    .tx-sm-74 {
        font-size: 74px;
    }

    .tx-sm-74-f {
        font-size: 74px;
    }

    .tx-sm-76 {
        font-size: 76px;
    }

    .tx-sm-76-f {
        font-size: 76px;
    }

    .tx-sm-78 {
        font-size: 78px;
    }

    .tx-sm-78-f {
        font-size: 78px;
    }

    .tx-sm-80 {
        font-size: 80px;
    }

    .tx-sm-80-f {
        font-size: 80px;
    }

    .tx-sm-82 {
        font-size: 82px;
    }

    .tx-sm-82-f {
        font-size: 82px;
    }

    .tx-sm-84 {
        font-size: 84px;
    }

    .tx-sm-84-f {
        font-size: 84px;
    }

    .tx-sm-86 {
        font-size: 86px;
    }

    .tx-sm-86-f {
        font-size: 86px;
    }

    .tx-sm-88 {
        font-size: 88px;
    }

    .tx-sm-88-f {
        font-size: 88px;
    }

    .tx-sm-90 {
        font-size: 90px;
    }

    .tx-sm-90-f {
        font-size: 90px;
    }

    .tx-sm-92 {
        font-size: 92px;
    }

    .tx-sm-92-f {
        font-size: 92px;
    }

    .tx-sm-94 {
        font-size: 94px;
    }

    .tx-sm-94-f {
        font-size: 94px;
    }

    .tx-sm-96 {
        font-size: 96px;
    }

    .tx-sm-96-f {
        font-size: 96px;
    }

    .tx-sm-98 {
        font-size: 98px;
    }

    .tx-sm-98-f {
        font-size: 98px;
    }

    .tx-sm-100 {
        font-size: 100px;
    }

    .tx-sm-100-f {
        font-size: 100px;
    }

    .tx-sm-102 {
        font-size: 102px;
    }

    .tx-sm-102-f {
        font-size: 102px;
    }

    .tx-sm-104 {
        font-size: 104px;
    }

    .tx-sm-104-f {
        font-size: 104px;
    }

    .tx-sm-106 {
        font-size: 106px;
    }

    .tx-sm-106-f {
        font-size: 106px;
    }

    .tx-sm-108 {
        font-size: 108px;
    }

    .tx-sm-108-f {
        font-size: 108px;
    }

    .tx-sm-110 {
        font-size: 110px;
    }

    .tx-sm-110-f {
        font-size: 110px;
    }

    .tx-sm-112 {
        font-size: 112px;
    }

    .tx-sm-112-f {
        font-size: 112px;
    }

    .tx-sm-114 {
        font-size: 114px;
    }

    .tx-sm-114-f {
        font-size: 114px;
    }

    .tx-sm-116 {
        font-size: 116px;
    }

    .tx-sm-116-f {
        font-size: 116px;
    }

    .tx-sm-118 {
        font-size: 118px;
    }

    .tx-sm-118-f {
        font-size: 118px;
    }

    .tx-sm-120 {
        font-size: 120px;
    }

    .tx-sm-120-f {
        font-size: 120px;
    }

    .tx-sm-122 {
        font-size: 122px;
    }

    .tx-sm-122-f {
        font-size: 122px;
    }

    .tx-sm-124 {
        font-size: 124px;
    }

    .tx-sm-124-f {
        font-size: 124px;
    }

    .tx-sm-126 {
        font-size: 126px;
    }

    .tx-sm-126-f {
        font-size: 126px;
    }

    .tx-sm-128 {
        font-size: 128px;
    }

    .tx-sm-128-f {
        font-size: 128px;
    }

    .tx-sm-130 {
        font-size: 130px;
    }

    .tx-sm-130-f {
        font-size: 130px;
    }

    .tx-sm-132 {
        font-size: 132px;
    }

    .tx-sm-132-f {
        font-size: 132px;
    }

    .tx-sm-134 {
        font-size: 134px;
    }

    .tx-sm-134-f {
        font-size: 134px;
    }

    .tx-sm-136 {
        font-size: 136px;
    }

    .tx-sm-136-f {
        font-size: 136px;
    }

    .tx-sm-138 {
        font-size: 138px;
    }

    .tx-sm-138-f {
        font-size: 138px;
    }

    .tx-sm-140 {
        font-size: 140px;
    }

    .tx-sm-140-f {
        font-size: 140px;
    }

    .tx-sm-base {
        font-size: 0.875rem;
    }

    .tx-sm-bold {
        font-weight: 700;
    }

    .tx-sm-semibold {
        font-weight: 600;
    }

    .tx-sm-medium {
        font-weight: 500;
    }

    .tx-sm-normal {
        font-weight: 400;
    }

    .tx-sm-light {
        font-weight: 300;
    }

    .tx-sm-thin {
        font-weight: 200;
    }

    .tx-sm-xthin {
        font-weight: 100;
    }

    .lh-sm-0 {
        line-height: 0;
    }

    .lh-sm-1 {
        line-height: 1.1;
    }

    .lh-sm-2 {
        line-height: 1.2;
    }

    .lh-sm-3 {
        line-height: 1.3;
    }

    .lh-sm-4 {
        line-height: 1.4;
    }

    .lh-sm-5 {
        line-height: 1.5;
    }

    .lh-sm-6 {
        line-height: 1.6;
    }

    .lh-sm-7 {
        line-height: 1.7;
    }

    .lh-sm-8 {
        line-height: 1.8;
    }

    .lh-sm-9 {
        line-height: 1.9;
    }

    .lh-sm-10 {
        line-height: 2;
    }

    .lh-sm-11 {
        line-height: 2.1;
    }

    .lh-sm-12 {
        line-height: 2.2;
    }

    .lh-sm-13 {
        line-height: 2.3;
    }

    .lh-sm-14 {
        line-height: 2.4;
    }

    .lh-sm-15 {
        line-height: 2.5;
    }

    .tx-sm-center {
        text-align: center;
    }

    .tx-sm-right {
        text-align: right;
    }

    .tx-sm-left {
        text-align: left;
    }

    .tx-sm-center-f {
        text-align: center;
    }

    .tx-sm-right-f {
        text-align: right;
    }

    .tx-sm-left-f {
        text-align: left;
    }
}

@media (min-width: 768px) {
    .tx-md-8 {
        font-size: 8px;
    }

    .tx-md-8-f {
        font-size: 8px;
    }

    .tx-md-9 {
        font-size: 9px;
    }

    .tx-md-9-f {
        font-size: 9px;
    }

    .tx-md-10 {
        font-size: 10px;
    }

    .tx-md-10-f {
        font-size: 10px;
    }

    .tx-md-11 {
        font-size: 11px;
    }

    .tx-md-11-f {
        font-size: 11px;
    }

    .tx-md-12 {
        font-size: 12px;
    }

    .tx-md-12-f {
        font-size: 12px;
    }

    .tx-md-13 {
        font-size: 13px;
    }

    .tx-md-13-f {
        font-size: 13px;
    }

    .tx-md-14 {
        font-size: 14px;
    }

    .tx-md-14-f {
        font-size: 14px;
    }

    .tx-md-15 {
        font-size: 15px;
    }

    .tx-md-15-f {
        font-size: 15px;
    }

    .tx-md-16 {
        font-size: 16px;
    }

    .tx-md-16-f {
        font-size: 16px;
    }

    .tx-md-18 {
        font-size: 18px;
    }

    .tx-md-18-f {
        font-size: 18px;
    }

    .tx-md-20 {
        font-size: 20px;
    }

    .tx-md-20-f {
        font-size: 20px;
    }

    .tx-md-22 {
        font-size: 22px;
    }

    .tx-md-22-f {
        font-size: 22px;
    }

    .tx-md-24 {
        font-size: 24px;
    }

    .tx-md-24-f {
        font-size: 24px;
    }

    .tx-md-26 {
        font-size: 26px;
    }

    .tx-md-26-f {
        font-size: 26px;
    }

    .tx-md-28 {
        font-size: 28px;
    }

    .tx-md-28-f {
        font-size: 28px;
    }

    .tx-md-30 {
        font-size: 30px;
    }

    .tx-md-30-f {
        font-size: 30px;
    }

    .tx-md-32 {
        font-size: 32px;
    }

    .tx-md-32-f {
        font-size: 32px;
    }

    .tx-md-34 {
        font-size: 34px;
    }

    .tx-md-34-f {
        font-size: 34px;
    }

    .tx-md-36 {
        font-size: 36px;
    }

    .tx-md-36-f {
        font-size: 36px;
    }

    .tx-md-38 {
        font-size: 38px;
    }

    .tx-md-38-f {
        font-size: 38px;
    }

    .tx-md-40 {
        font-size: 40px;
    }

    .tx-md-40-f {
        font-size: 40px;
    }

    .tx-md-42 {
        font-size: 42px;
    }

    .tx-md-42-f {
        font-size: 42px;
    }

    .tx-md-44 {
        font-size: 44px;
    }

    .tx-md-44-f {
        font-size: 44px;
    }

    .tx-md-46 {
        font-size: 46px;
    }

    .tx-md-46-f {
        font-size: 46px;
    }

    .tx-md-48 {
        font-size: 48px;
    }

    .tx-md-48-f {
        font-size: 48px;
    }

    .tx-md-50 {
        font-size: 50px;
    }

    .tx-md-50-f {
        font-size: 50px;
    }

    .tx-md-52 {
        font-size: 52px;
    }

    .tx-md-52-f {
        font-size: 52px;
    }

    .tx-md-54 {
        font-size: 54px;
    }

    .tx-md-54-f {
        font-size: 54px;
    }

    .tx-md-56 {
        font-size: 56px;
    }

    .tx-md-56-f {
        font-size: 56px;
    }

    .tx-md-58 {
        font-size: 58px;
    }

    .tx-md-58-f {
        font-size: 58px;
    }

    .tx-md-60 {
        font-size: 60px;
    }

    .tx-md-60-f {
        font-size: 60px;
    }

    .tx-md-62 {
        font-size: 62px;
    }

    .tx-md-62-f {
        font-size: 62px;
    }

    .tx-md-64 {
        font-size: 64px;
    }

    .tx-md-64-f {
        font-size: 64px;
    }

    .tx-md-66 {
        font-size: 66px;
    }

    .tx-md-66-f {
        font-size: 66px;
    }

    .tx-md-68 {
        font-size: 68px;
    }

    .tx-md-68-f {
        font-size: 68px;
    }

    .tx-md-70 {
        font-size: 70px;
    }

    .tx-md-70-f {
        font-size: 70px;
    }

    .tx-md-72 {
        font-size: 72px;
    }

    .tx-md-72-f {
        font-size: 72px;
    }

    .tx-md-74 {
        font-size: 74px;
    }

    .tx-md-74-f {
        font-size: 74px;
    }

    .tx-md-76 {
        font-size: 76px;
    }

    .tx-md-76-f {
        font-size: 76px;
    }

    .tx-md-78 {
        font-size: 78px;
    }

    .tx-md-78-f {
        font-size: 78px;
    }

    .tx-md-80 {
        font-size: 80px;
    }

    .tx-md-80-f {
        font-size: 80px;
    }

    .tx-md-82 {
        font-size: 82px;
    }

    .tx-md-82-f {
        font-size: 82px;
    }

    .tx-md-84 {
        font-size: 84px;
    }

    .tx-md-84-f {
        font-size: 84px;
    }

    .tx-md-86 {
        font-size: 86px;
    }

    .tx-md-86-f {
        font-size: 86px;
    }

    .tx-md-88 {
        font-size: 88px;
    }

    .tx-md-88-f {
        font-size: 88px;
    }

    .tx-md-90 {
        font-size: 90px;
    }

    .tx-md-90-f {
        font-size: 90px;
    }

    .tx-md-92 {
        font-size: 92px;
    }

    .tx-md-92-f {
        font-size: 92px;
    }

    .tx-md-94 {
        font-size: 94px;
    }

    .tx-md-94-f {
        font-size: 94px;
    }

    .tx-md-96 {
        font-size: 96px;
    }

    .tx-md-96-f {
        font-size: 96px;
    }

    .tx-md-98 {
        font-size: 98px;
    }

    .tx-md-98-f {
        font-size: 98px;
    }

    .tx-md-100 {
        font-size: 100px;
    }

    .tx-md-100-f {
        font-size: 100px;
    }

    .tx-md-102 {
        font-size: 102px;
    }

    .tx-md-102-f {
        font-size: 102px;
    }

    .tx-md-104 {
        font-size: 104px;
    }

    .tx-md-104-f {
        font-size: 104px;
    }

    .tx-md-106 {
        font-size: 106px;
    }

    .tx-md-106-f {
        font-size: 106px;
    }

    .tx-md-108 {
        font-size: 108px;
    }

    .tx-md-108-f {
        font-size: 108px;
    }

    .tx-md-110 {
        font-size: 110px;
    }

    .tx-md-110-f {
        font-size: 110px;
    }

    .tx-md-112 {
        font-size: 112px;
    }

    .tx-md-112-f {
        font-size: 112px;
    }

    .tx-md-114 {
        font-size: 114px;
    }

    .tx-md-114-f {
        font-size: 114px;
    }

    .tx-md-116 {
        font-size: 116px;
    }

    .tx-md-116-f {
        font-size: 116px;
    }

    .tx-md-118 {
        font-size: 118px;
    }

    .tx-md-118-f {
        font-size: 118px;
    }

    .tx-md-120 {
        font-size: 120px;
    }

    .tx-md-120-f {
        font-size: 120px;
    }

    .tx-md-122 {
        font-size: 122px;
    }

    .tx-md-122-f {
        font-size: 122px;
    }

    .tx-md-124 {
        font-size: 124px;
    }

    .tx-md-124-f {
        font-size: 124px;
    }

    .tx-md-126 {
        font-size: 126px;
    }

    .tx-md-126-f {
        font-size: 126px;
    }

    .tx-md-128 {
        font-size: 128px;
    }

    .tx-md-128-f {
        font-size: 128px;
    }

    .tx-md-130 {
        font-size: 130px;
    }

    .tx-md-130-f {
        font-size: 130px;
    }

    .tx-md-132 {
        font-size: 132px;
    }

    .tx-md-132-f {
        font-size: 132px;
    }

    .tx-md-134 {
        font-size: 134px;
    }

    .tx-md-134-f {
        font-size: 134px;
    }

    .tx-md-136 {
        font-size: 136px;
    }

    .tx-md-136-f {
        font-size: 136px;
    }

    .tx-md-138 {
        font-size: 138px;
    }

    .tx-md-138-f {
        font-size: 138px;
    }

    .tx-md-140 {
        font-size: 140px;
    }

    .tx-md-140-f {
        font-size: 140px;
    }

    .tx-md-base {
        font-size: 0.875rem;
    }

    .tx-md-bold {
        font-weight: 700;
    }

    .tx-md-semibold {
        font-weight: 600;
    }

    .tx-md-medium {
        font-weight: 500;
    }

    .tx-md-normal {
        font-weight: 400;
    }

    .tx-md-light {
        font-weight: 300;
    }

    .tx-md-thin {
        font-weight: 200;
    }

    .tx-md-xthin {
        font-weight: 100;
    }

    .lh-md-0 {
        line-height: 0;
    }

    .lh-md-1 {
        line-height: 1.1;
    }

    .lh-md-2 {
        line-height: 1.2;
    }

    .lh-md-3 {
        line-height: 1.3;
    }

    .lh-md-4 {
        line-height: 1.4;
    }

    .lh-md-5 {
        line-height: 1.5;
    }

    .lh-md-6 {
        line-height: 1.6;
    }

    .lh-md-7 {
        line-height: 1.7;
    }

    .lh-md-8 {
        line-height: 1.8;
    }

    .lh-md-9 {
        line-height: 1.9;
    }

    .lh-md-10 {
        line-height: 2;
    }

    .lh-md-11 {
        line-height: 2.1;
    }

    .lh-md-12 {
        line-height: 2.2;
    }

    .lh-md-13 {
        line-height: 2.3;
    }

    .lh-md-14 {
        line-height: 2.4;
    }

    .lh-md-15 {
        line-height: 2.5;
    }

    .tx-md-center {
        text-align: center;
    }

    .tx-md-right {
        text-align: right;
    }

    .tx-md-left {
        text-align: left;
    }

    .tx-md-center-f {
        text-align: center;
    }

    .tx-md-right-f {
        text-align: right;
    }

    .tx-md-left-f {
        text-align: left;
    }
}

@media (min-width: 992px) {
    .tx-lg-8 {
        font-size: 8px;
    }

    .tx-lg-8-f {
        font-size: 8px;
    }

    .tx-lg-9 {
        font-size: 9px;
    }

    .tx-lg-9-f {
        font-size: 9px;
    }

    .tx-lg-10 {
        font-size: 10px;
    }

    .tx-lg-10-f {
        font-size: 10px;
    }

    .tx-lg-11 {
        font-size: 11px;
    }

    .tx-lg-11-f {
        font-size: 11px;
    }

    .tx-lg-12 {
        font-size: 12px;
    }

    .tx-lg-12-f {
        font-size: 12px;
    }

    .tx-lg-13 {
        font-size: 13px;
    }

    .tx-lg-13-f {
        font-size: 13px;
    }

    .tx-lg-14 {
        font-size: 14px;
    }

    .tx-lg-14-f {
        font-size: 14px;
    }

    .tx-lg-15 {
        font-size: 15px;
    }

    .tx-lg-15-f {
        font-size: 15px;
    }

    .tx-lg-16 {
        font-size: 16px;
    }

    .tx-lg-16-f {
        font-size: 16px;
    }

    .tx-lg-18 {
        font-size: 18px;
    }

    .tx-lg-18-f {
        font-size: 18px;
    }

    .tx-lg-20 {
        font-size: 20px;
    }

    .tx-lg-20-f {
        font-size: 20px;
    }

    .tx-lg-22 {
        font-size: 22px;
    }

    .tx-lg-22-f {
        font-size: 22px;
    }

    .tx-lg-24 {
        font-size: 24px;
    }

    .tx-lg-24-f {
        font-size: 24px;
    }

    .tx-lg-26 {
        font-size: 26px;
    }

    .tx-lg-26-f {
        font-size: 26px;
    }

    .tx-lg-28 {
        font-size: 28px;
    }

    .tx-lg-28-f {
        font-size: 28px;
    }

    .tx-lg-30 {
        font-size: 30px;
    }

    .tx-lg-30-f {
        font-size: 30px;
    }

    .tx-lg-32 {
        font-size: 32px;
    }

    .tx-lg-32-f {
        font-size: 32px;
    }

    .tx-lg-34 {
        font-size: 34px;
    }

    .tx-lg-34-f {
        font-size: 34px;
    }

    .tx-lg-36 {
        font-size: 36px;
    }

    .tx-lg-36-f {
        font-size: 36px;
    }

    .tx-lg-38 {
        font-size: 38px;
    }

    .tx-lg-38-f {
        font-size: 38px;
    }

    .tx-lg-40 {
        font-size: 40px;
    }

    .tx-lg-40-f {
        font-size: 40px;
    }

    .tx-lg-42 {
        font-size: 42px;
    }

    .tx-lg-42-f {
        font-size: 42px;
    }

    .tx-lg-44 {
        font-size: 44px;
    }

    .tx-lg-44-f {
        font-size: 44px;
    }

    .tx-lg-46 {
        font-size: 46px;
    }

    .tx-lg-46-f {
        font-size: 46px;
    }

    .tx-lg-48 {
        font-size: 48px;
    }

    .tx-lg-48-f {
        font-size: 48px;
    }

    .tx-lg-50 {
        font-size: 50px;
    }

    .tx-lg-50-f {
        font-size: 50px;
    }

    .tx-lg-52 {
        font-size: 52px;
    }

    .tx-lg-52-f {
        font-size: 52px;
    }

    .tx-lg-54 {
        font-size: 54px;
    }

    .tx-lg-54-f {
        font-size: 54px;
    }

    .tx-lg-56 {
        font-size: 56px;
    }

    .tx-lg-56-f {
        font-size: 56px;
    }

    .tx-lg-58 {
        font-size: 58px;
    }

    .tx-lg-58-f {
        font-size: 58px;
    }

    .tx-lg-60 {
        font-size: 60px;
    }

    .tx-lg-60-f {
        font-size: 60px;
    }

    .tx-lg-62 {
        font-size: 62px;
    }

    .tx-lg-62-f {
        font-size: 62px;
    }

    .tx-lg-64 {
        font-size: 64px;
    }

    .tx-lg-64-f {
        font-size: 64px;
    }

    .tx-lg-66 {
        font-size: 66px;
    }

    .tx-lg-66-f {
        font-size: 66px;
    }

    .tx-lg-68 {
        font-size: 68px;
    }

    .tx-lg-68-f {
        font-size: 68px;
    }

    .tx-lg-70 {
        font-size: 70px;
    }

    .tx-lg-70-f {
        font-size: 70px;
    }

    .tx-lg-72 {
        font-size: 72px;
    }

    .tx-lg-72-f {
        font-size: 72px;
    }

    .tx-lg-74 {
        font-size: 74px;
    }

    .tx-lg-74-f {
        font-size: 74px;
    }

    .tx-lg-76 {
        font-size: 76px;
    }

    .tx-lg-76-f {
        font-size: 76px;
    }

    .tx-lg-78 {
        font-size: 78px;
    }

    .tx-lg-78-f {
        font-size: 78px;
    }

    .tx-lg-80 {
        font-size: 80px;
    }

    .tx-lg-80-f {
        font-size: 80px;
    }

    .tx-lg-82 {
        font-size: 82px;
    }

    .tx-lg-82-f {
        font-size: 82px;
    }

    .tx-lg-84 {
        font-size: 84px;
    }

    .tx-lg-84-f {
        font-size: 84px;
    }

    .tx-lg-86 {
        font-size: 86px;
    }

    .tx-lg-86-f {
        font-size: 86px;
    }

    .tx-lg-88 {
        font-size: 88px;
    }

    .tx-lg-88-f {
        font-size: 88px;
    }

    .tx-lg-90 {
        font-size: 90px;
    }

    .tx-lg-90-f {
        font-size: 90px;
    }

    .tx-lg-92 {
        font-size: 92px;
    }

    .tx-lg-92-f {
        font-size: 92px;
    }

    .tx-lg-94 {
        font-size: 94px;
    }

    .tx-lg-94-f {
        font-size: 94px;
    }

    .tx-lg-96 {
        font-size: 96px;
    }

    .tx-lg-96-f {
        font-size: 96px;
    }

    .tx-lg-98 {
        font-size: 98px;
    }

    .tx-lg-98-f {
        font-size: 98px;
    }

    .tx-lg-100 {
        font-size: 100px;
    }

    .tx-lg-100-f {
        font-size: 100px;
    }

    .tx-lg-102 {
        font-size: 102px;
    }

    .tx-lg-102-f {
        font-size: 102px;
    }

    .tx-lg-104 {
        font-size: 104px;
    }

    .tx-lg-104-f {
        font-size: 104px;
    }

    .tx-lg-106 {
        font-size: 106px;
    }

    .tx-lg-106-f {
        font-size: 106px;
    }

    .tx-lg-108 {
        font-size: 108px;
    }

    .tx-lg-108-f {
        font-size: 108px;
    }

    .tx-lg-110 {
        font-size: 110px;
    }

    .tx-lg-110-f {
        font-size: 110px;
    }

    .tx-lg-112 {
        font-size: 112px;
    }

    .tx-lg-112-f {
        font-size: 112px;
    }

    .tx-lg-114 {
        font-size: 114px;
    }

    .tx-lg-114-f {
        font-size: 114px;
    }

    .tx-lg-116 {
        font-size: 116px;
    }

    .tx-lg-116-f {
        font-size: 116px;
    }

    .tx-lg-118 {
        font-size: 118px;
    }

    .tx-lg-118-f {
        font-size: 118px;
    }

    .tx-lg-120 {
        font-size: 120px;
    }

    .tx-lg-120-f {
        font-size: 120px;
    }

    .tx-lg-122 {
        font-size: 122px;
    }

    .tx-lg-122-f {
        font-size: 122px;
    }

    .tx-lg-124 {
        font-size: 124px;
    }

    .tx-lg-124-f {
        font-size: 124px;
    }

    .tx-lg-126 {
        font-size: 126px;
    }

    .tx-lg-126-f {
        font-size: 126px;
    }

    .tx-lg-128 {
        font-size: 128px;
    }

    .tx-lg-128-f {
        font-size: 128px;
    }

    .tx-lg-130 {
        font-size: 130px;
    }

    .tx-lg-130-f {
        font-size: 130px;
    }

    .tx-lg-132 {
        font-size: 132px;
    }

    .tx-lg-132-f {
        font-size: 132px;
    }

    .tx-lg-134 {
        font-size: 134px;
    }

    .tx-lg-134-f {
        font-size: 134px;
    }

    .tx-lg-136 {
        font-size: 136px;
    }

    .tx-lg-136-f {
        font-size: 136px;
    }

    .tx-lg-138 {
        font-size: 138px;
    }

    .tx-lg-138-f {
        font-size: 138px;
    }

    .tx-lg-140 {
        font-size: 140px;
    }

    .tx-lg-140-f {
        font-size: 140px;
    }

    .tx-lg-base {
        font-size: 0.875rem;
    }

    .tx-lg-bold {
        font-weight: 700;
    }

    .tx-lg-semibold {
        font-weight: 600;
    }

    .tx-lg-medium {
        font-weight: 500;
    }

    .tx-lg-normal {
        font-weight: 400;
    }

    .tx-lg-light {
        font-weight: 300;
    }

    .tx-lg-thin {
        font-weight: 200;
    }

    .tx-lg-xthin {
        font-weight: 100;
    }

    .lh-lg-0 {
        line-height: 0;
    }

    .lh-lg-1 {
        line-height: 1.1;
    }

    .lh-lg-2 {
        line-height: 1.2;
    }

    .lh-lg-3 {
        line-height: 1.3;
    }

    .lh-lg-4 {
        line-height: 1.4;
    }

    .lh-lg-5 {
        line-height: 1.5;
    }

    .lh-lg-6 {
        line-height: 1.6;
    }

    .lh-lg-7 {
        line-height: 1.7;
    }

    .lh-lg-8 {
        line-height: 1.8;
    }

    .lh-lg-9 {
        line-height: 1.9;
    }

    .lh-lg-10 {
        line-height: 2;
    }

    .lh-lg-11 {
        line-height: 2.1;
    }

    .lh-lg-12 {
        line-height: 2.2;
    }

    .lh-lg-13 {
        line-height: 2.3;
    }

    .lh-lg-14 {
        line-height: 2.4;
    }

    .lh-lg-15 {
        line-height: 2.5;
    }

    .tx-lg-center {
        text-align: center;
    }

    .tx-lg-right {
        text-align: right;
    }

    .tx-lg-left {
        text-align: left;
    }

    .tx-lg-center-f {
        text-align: center;
    }

    .tx-lg-right-f {
        text-align: right;
    }

    .tx-lg-left-f {
        text-align: left;
    }
}

@media (min-width: 1200px) {
    .tx-xl-8 {
        font-size: 8px;
    }

    .tx-xl-8-f {
        font-size: 8px;
    }

    .tx-xl-9 {
        font-size: 9px;
    }

    .tx-xl-9-f {
        font-size: 9px;
    }

    .tx-xl-10 {
        font-size: 10px;
    }

    .tx-xl-10-f {
        font-size: 10px;
    }

    .tx-xl-11 {
        font-size: 11px;
    }

    .tx-xl-11-f {
        font-size: 11px;
    }

    .tx-xl-12 {
        font-size: 12px;
    }

    .tx-xl-12-f {
        font-size: 12px;
    }

    .tx-xl-13 {
        font-size: 13px;
    }

    .tx-xl-13-f {
        font-size: 13px;
    }

    .tx-xl-14 {
        font-size: 14px;
    }

    .tx-xl-14-f {
        font-size: 14px;
    }

    .tx-xl-15 {
        font-size: 15px;
    }

    .tx-xl-15-f {
        font-size: 15px;
    }

    .tx-xl-16 {
        font-size: 16px;
    }

    .tx-xl-16-f {
        font-size: 16px;
    }

    .tx-xl-18 {
        font-size: 18px;
    }

    .tx-xl-18-f {
        font-size: 18px;
    }

    .tx-xl-20 {
        font-size: 20px;
    }

    .tx-xl-20-f {
        font-size: 20px;
    }

    .tx-xl-22 {
        font-size: 22px;
    }

    .tx-xl-22-f {
        font-size: 22px;
    }

    .tx-xl-24 {
        font-size: 24px;
    }

    .tx-xl-24-f {
        font-size: 24px;
    }

    .tx-xl-26 {
        font-size: 26px;
    }

    .tx-xl-26-f {
        font-size: 26px;
    }

    .tx-xl-28 {
        font-size: 28px;
    }

    .tx-xl-28-f {
        font-size: 28px;
    }

    .tx-xl-30 {
        font-size: 30px;
    }

    .tx-xl-30-f {
        font-size: 30px;
    }

    .tx-xl-32 {
        font-size: 32px;
    }

    .tx-xl-32-f {
        font-size: 32px;
    }

    .tx-xl-34 {
        font-size: 34px;
    }

    .tx-xl-34-f {
        font-size: 34px;
    }

    .tx-xl-36 {
        font-size: 36px;
    }

    .tx-xl-36-f {
        font-size: 36px;
    }

    .tx-xl-38 {
        font-size: 38px;
    }

    .tx-xl-38-f {
        font-size: 38px;
    }

    .tx-xl-40 {
        font-size: 40px;
    }

    .tx-xl-40-f {
        font-size: 40px;
    }

    .tx-xl-42 {
        font-size: 42px;
    }

    .tx-xl-42-f {
        font-size: 42px;
    }

    .tx-xl-44 {
        font-size: 44px;
    }

    .tx-xl-44-f {
        font-size: 44px;
    }

    .tx-xl-46 {
        font-size: 46px;
    }

    .tx-xl-46-f {
        font-size: 46px;
    }

    .tx-xl-48 {
        font-size: 48px;
    }

    .tx-xl-48-f {
        font-size: 48px;
    }

    .tx-xl-50 {
        font-size: 50px;
    }

    .tx-xl-50-f {
        font-size: 50px;
    }

    .tx-xl-52 {
        font-size: 52px;
    }

    .tx-xl-52-f {
        font-size: 52px;
    }

    .tx-xl-54 {
        font-size: 54px;
    }

    .tx-xl-54-f {
        font-size: 54px;
    }

    .tx-xl-56 {
        font-size: 56px;
    }

    .tx-xl-56-f {
        font-size: 56px;
    }

    .tx-xl-58 {
        font-size: 58px;
    }

    .tx-xl-58-f {
        font-size: 58px;
    }

    .tx-xl-60 {
        font-size: 60px;
    }

    .tx-xl-60-f {
        font-size: 60px;
    }

    .tx-xl-62 {
        font-size: 62px;
    }

    .tx-xl-62-f {
        font-size: 62px;
    }

    .tx-xl-64 {
        font-size: 64px;
    }

    .tx-xl-64-f {
        font-size: 64px;
    }

    .tx-xl-66 {
        font-size: 66px;
    }

    .tx-xl-66-f {
        font-size: 66px;
    }

    .tx-xl-68 {
        font-size: 68px;
    }

    .tx-xl-68-f {
        font-size: 68px;
    }

    .tx-xl-70 {
        font-size: 70px;
    }

    .tx-xl-70-f {
        font-size: 70px;
    }

    .tx-xl-72 {
        font-size: 72px;
    }

    .tx-xl-72-f {
        font-size: 72px;
    }

    .tx-xl-74 {
        font-size: 74px;
    }

    .tx-xl-74-f {
        font-size: 74px;
    }

    .tx-xl-76 {
        font-size: 76px;
    }

    .tx-xl-76-f {
        font-size: 76px;
    }

    .tx-xl-78 {
        font-size: 78px;
    }

    .tx-xl-78-f {
        font-size: 78px;
    }

    .tx-xl-80 {
        font-size: 80px;
    }

    .tx-xl-80-f {
        font-size: 80px;
    }

    .tx-xl-82 {
        font-size: 82px;
    }

    .tx-xl-82-f {
        font-size: 82px;
    }

    .tx-xl-84 {
        font-size: 84px;
    }

    .tx-xl-84-f {
        font-size: 84px;
    }

    .tx-xl-86 {
        font-size: 86px;
    }

    .tx-xl-86-f {
        font-size: 86px;
    }

    .tx-xl-88 {
        font-size: 88px;
    }

    .tx-xl-88-f {
        font-size: 88px;
    }

    .tx-xl-90 {
        font-size: 90px;
    }

    .tx-xl-90-f {
        font-size: 90px;
    }

    .tx-xl-92 {
        font-size: 92px;
    }

    .tx-xl-92-f {
        font-size: 92px;
    }

    .tx-xl-94 {
        font-size: 94px;
    }

    .tx-xl-94-f {
        font-size: 94px;
    }

    .tx-xl-96 {
        font-size: 96px;
    }

    .tx-xl-96-f {
        font-size: 96px;
    }

    .tx-xl-98 {
        font-size: 98px;
    }

    .tx-xl-98-f {
        font-size: 98px;
    }

    .tx-xl-100 {
        font-size: 100px;
    }

    .tx-xl-100-f {
        font-size: 100px;
    }

    .tx-xl-102 {
        font-size: 102px;
    }

    .tx-xl-102-f {
        font-size: 102px;
    }

    .tx-xl-104 {
        font-size: 104px;
    }

    .tx-xl-104-f {
        font-size: 104px;
    }

    .tx-xl-106 {
        font-size: 106px;
    }

    .tx-xl-106-f {
        font-size: 106px;
    }

    .tx-xl-108 {
        font-size: 108px;
    }

    .tx-xl-108-f {
        font-size: 108px;
    }

    .tx-xl-110 {
        font-size: 110px;
    }

    .tx-xl-110-f {
        font-size: 110px;
    }

    .tx-xl-112 {
        font-size: 112px;
    }

    .tx-xl-112-f {
        font-size: 112px;
    }

    .tx-xl-114 {
        font-size: 114px;
    }

    .tx-xl-114-f {
        font-size: 114px;
    }

    .tx-xl-116 {
        font-size: 116px;
    }

    .tx-xl-116-f {
        font-size: 116px;
    }

    .tx-xl-118 {
        font-size: 118px;
    }

    .tx-xl-118-f {
        font-size: 118px;
    }

    .tx-xl-120 {
        font-size: 120px;
    }

    .tx-xl-120-f {
        font-size: 120px;
    }

    .tx-xl-122 {
        font-size: 122px;
    }

    .tx-xl-122-f {
        font-size: 122px;
    }

    .tx-xl-124 {
        font-size: 124px;
    }

    .tx-xl-124-f {
        font-size: 124px;
    }

    .tx-xl-126 {
        font-size: 126px;
    }

    .tx-xl-126-f {
        font-size: 126px;
    }

    .tx-xl-128 {
        font-size: 128px;
    }

    .tx-xl-128-f {
        font-size: 128px;
    }

    .tx-xl-130 {
        font-size: 130px;
    }

    .tx-xl-130-f {
        font-size: 130px;
    }

    .tx-xl-132 {
        font-size: 132px;
    }

    .tx-xl-132-f {
        font-size: 132px;
    }

    .tx-xl-134 {
        font-size: 134px;
    }

    .tx-xl-134-f {
        font-size: 134px;
    }

    .tx-xl-136 {
        font-size: 136px;
    }

    .tx-xl-136-f {
        font-size: 136px;
    }

    .tx-xl-138 {
        font-size: 138px;
    }

    .tx-xl-138-f {
        font-size: 138px;
    }

    .tx-xl-140 {
        font-size: 140px;
    }

    .tx-xl-140-f {
        font-size: 140px;
    }

    .tx-xl-base {
        font-size: 0.875rem;
    }

    .tx-xl-bold {
        font-weight: 700;
    }

    .tx-xl-semibold {
        font-weight: 600;
    }

    .tx-xl-medium {
        font-weight: 500;
    }

    .tx-xl-normal {
        font-weight: 400;
    }

    .tx-xl-light {
        font-weight: 300;
    }

    .tx-xl-thin {
        font-weight: 200;
    }

    .tx-xl-xthin {
        font-weight: 100;
    }

    .lh-xl-0 {
        line-height: 0;
    }

    .lh-xl-1 {
        line-height: 1.1;
    }

    .lh-xl-2 {
        line-height: 1.2;
    }

    .lh-xl-3 {
        line-height: 1.3;
    }

    .lh-xl-4 {
        line-height: 1.4;
    }

    .lh-xl-5 {
        line-height: 1.5;
    }

    .lh-xl-6 {
        line-height: 1.6;
    }

    .lh-xl-7 {
        line-height: 1.7;
    }

    .lh-xl-8 {
        line-height: 1.8;
    }

    .lh-xl-9 {
        line-height: 1.9;
    }

    .lh-xl-10 {
        line-height: 2;
    }

    .lh-xl-11 {
        line-height: 2.1;
    }

    .lh-xl-12 {
        line-height: 2.2;
    }

    .lh-xl-13 {
        line-height: 2.3;
    }

    .lh-xl-14 {
        line-height: 2.4;
    }

    .lh-xl-15 {
        line-height: 2.5;
    }

    .tx-xl-center {
        text-align: center;
    }

    .tx-xl-right {
        text-align: right;
    }

    .tx-xl-left {
        text-align: left;
    }

    .tx-xl-center-f {
        text-align: center;
    }

    .tx-xl-right-f {
        text-align: right;
    }

    .tx-xl-left-f {
        text-align: left;
    }
}

.text-nowrap {
    white-space: nowrap !important;
}

@media (max-width: 576px) {
    .text-sm-nowrap {
        white-space: nowrap;
    }
}

@media (max-width: 768px) {
    .text-md-nowrap {
        white-space: nowrap;
    }
}

@media (max-width: 992px) {
    .text-lg-nowrap {
        white-space: nowrap;
    }
}

@media (max-width: 1000px) {
    .text-xl-nowrap {
        white-space: nowrap;
    }
}

.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
    text-align: justify !important;
}

.text-wrap {
    white-space: normal !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-start {
    text-align: left !important;
}

.text-end {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

@media (min-width: 576px) {
    .text-sm-left {
        text-align: left;
    }

    .text-sm-right {
        text-align: right;
    }

    .text-sm-center {
        text-align: center;
    }
}

@media (min-width: 768px) {
    .text-md-left {
        text-align: left;
    }

    .text-md-right {
        text-align: right;
    }

    .text-md-center {
        text-align: center;
    }
}

@media (min-width: 992px) {
    .text-lg-left {
        text-align: left;
    }

    .text-lg-right {
        text-align: right;
    }

    .text-lg-center {
        text-align: center;
    }
}

@media (min-width: 1200px) {
    .text-xl-left {
        text-align: left;
    }

    .text-xl-right {
        text-align: right;
    }

    .text-xl-center {
        text-align: center;
    }
}

.text-lowercase {
    text-transform: lowercase;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.font-weight-light {
    font-weight: 300;
}

.font-weight-lighter {
    font-weight: lighter;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-weight-bolder {
    font-weight: bolder;
}

.font-italic {
    font-style: italic;
}

.text-white {
    color: #fff !important;
}

.text-white-80 {
    color: #fff;
    opacity: 0.8;
}

.text-primary {
    color: var(--primary) !important;
}

.text-primary-dark {
    color: var(--primary-darker) !important;
}

.text-primary-75 {
    color: var(--primary-75) !important;
}

.text-primary-50 {
    color: var(--primary-50) !important;
}

.text-primary-25 {
    color: var(--primary-25) !important;
}

a.text-primary:hover,
a.text-primary:focus {
    color: var(--primary-75) !important;
}

.text-secondary {
    color: var(--secondary) !important;
}

.text-secondary-dark {
    color: var(--secondary-darker) !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
    color: var(--secondary-50) !important;
}

.text-success {
    color: var(--success) !important;
}

.text-success-dark {
    color: var(--success-darker) !important;
}

a.text-success:hover,
a.text-success:focus {
    color: var(--success-50) !important;
}

.text-error {
    color: var(--error) !important;
}

.text-error-dark {
    color: var(--error-darker) !important;
}

.text-info {
    color: var(--info) !important;
}

.text-info-dark {
    color: var(--info-darker) !important;
}

a.text-info:hover,
a.text-info:focus {
    color: var(--info-50) !important;
}

.text-warning {
    color: var(--warning) !important;
}

.text-warning-dark {
    color: var(--warning-darker) !important;
}

a.text-warning:hover,
a.text-warning:focus {
    color: var(--warning-50) !important;
}

.text-purple {
    color: #ad4ffa !important;
}

a.text-purple:hover,
a.text-purple:focus {
    color: #ad4ffa !important;
}

.text-danger {
    color: var(--error) !important;
}

a.text-danger:hover,
a.text-danger:focus {
    color: var(--error-50) !important;
}

.text-pink {
    color: #f754fa !important;
}

a.text-pink:hover,
a.text-pink:focus {
    color: #e61078 !important;
}

.text-orange {
    color: #fd7e14 !important;
}

a.text-orange:hover,
a.text-orange:focus {
    color: #e46f0e !important;
}

.text-light {
    color: #f9f9fb !important;
}

a.text-light:hover,
a.text-light:focus {
    color: #c5cbda !important;
}

.text-teal {
    color: #29ddee !important;
}

a.text-teal:hover,
a.text-teal:focus {
    color: #06c1c1 !important;
}

.text-dark {
    color: #383853 !important;
}

a.text-dark:hover,
a.text-dark:focus {
    color: #1e2533 !important;
}

.text-body {
    color: #4a4a69;
}

.text-muted {
    color: #7987a1 !important;
}

.text-black-50 {
    color: rgba(0, 0, 0, 0.5);
}

.text-white-50 {
    color: rgba(255, 255, 255, 0.5);
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.text-decoration-none {
    text-decoration: none;
}

.text-break {
    word-break: break-word;
    overflow-wrap: break-word;
}

.text-reset {
    color: inherit;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden;
}

/* ###### 3.14 Table ###### */
.table {
    border-radius: 10px 10px 0 0;
    border-collapse: separate;
    border-spacing: 0;
    background-color: #fff;
    table-layout: fixed;
}

.table thead th,
.table thead td {
    background-color: var(--secondary-10);
    border-bottom-width: 1px;
    border-top-width: 0;
    border-collapse: separate;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    text-align: center;
    padding: 16px;
    color: var(--secondary-darker);
}

.table thead th:first-child,
.table thead td:first-child {
    border-radius: 10px 0 0 0;
}

.table thead th:last-child,
.table thead td:last-child {
    border-radius: 0 10px 0 0;
}

.table tbody tr {
    background-color: rgba(255, 255, 255, 0.5);
}

.table tbody tr th {
    color: var(--primary);
}

.table tbody tr td {
    color: var(--primary);
}

.table tbody tr th,
.table tbody tr td {
    text-align: center;
}

.table th,
.table td {
    padding: 20px 16px;
    line-height: 1.462;
    font-size: 16px;
}

.table tbody tr:first-child th,
.table tbody tr:first-child td {
    border-top: none;
}

.table tr.disabled {
    pointer-events: none;
    background-color: var(--neutral-30);
}

.table tr.disabled th,
.table tr.disabled td {
    color: var(--neutral-70);
}

.table-striped tbody tr:nth-of-type(4n+2) {
    background-color: var(--neutral-20);
}

.table-bordered thead th,
.table-bordered thead td {
    border-top-width: 1px;
    padding-top: 11px;
    padding-bottom: 11px;
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #4a4a69;
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid var(--neutral-60);
}

.table thead th {
    vertical-align: bottom;
}

.table-sm th,
.table-sm td {
    padding: 0.3rem;
}

.table-bordered {
    border: 1px solid var(--neutral-60);
}

.table-bordered th,
.table-bordered td {
    border: 1px solid var(--neutral-60);
}

.table-bordered tr th:not(:first-child),
.table-bordered tr td:not(:first-child) {
    border-left: none;
}

.table-bordered tr:not(:first-child) th,
.table-bordered tr:not(:first-child) td {
    border-top: none;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody+tbody {
    border: 0;
}

.table-striped tbody tr:nth-of-type(even) {
    background-color: #f9f9fb;
}

.table-hover tbody tr:hover {
    background-color: #fcfcff;
    color: #4a4a69;
}

.table-primary {
    background-color: #c6d4ff;
}

.table-primary>th,
.table-primary>td {
    background-color: #c6d4ff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody+tbody {
    border-color: #95afff;
}

.table-hover .table-primary:hover {
    background-color: #adc1ff;
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #adc1ff;
}

.table-secondary {
    background-color: #d9dde5;
}

.table-secondary>th,
.table-secondary>td {
    background-color: #d9dde5;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody+tbody {
    border-color: #b9c1ce;
}

.table-hover .table-secondary:hover {
    background-color: #cacfdb;
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #cacfdb;
}

.table-success {
    background-color: #c8e9b8;
}

.table-success>th,
.table-success>td {
    background-color: #c8e9b8;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody+tbody {
    border-color: #99d67b;
}

.table-hover .table-success:hover {
    background-color: #b9e3a5;
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #b9e3a5;
}

.table-info {
    background-color: #bee5eb;
}

.table-info>th,
.table-info>td {
    background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody+tbody {
    border-color: #86cfda;
}

.table-hover .table-info:hover {
    background-color: #abdde5;
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #abdde5;
}

.table-warning {
    background-color: #ffeeba;
}

.table-warning>th,
.table-warning>td {
    background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody+tbody {
    border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
    background-color: #ffe8a1;
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #ffe8a1;
}

.table-danger {
    background-color: #f5c6cb;
}

.table-danger>th,
.table-danger>td {
    background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody+tbody {
    border-color: #ed969e;
}

.table-hover .table-danger:hover {
    background-color: #f1b0b7;
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #f1b0b7;
}

.table-light {
    background-color: #fcfcfd;
}

.table-light>th,
.table-light>td {
    background-color: #fcfcfd;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody+tbody {
    border-color: #f9fafb;
}

.table-hover .table-light:hover {
    background-color: #ededf3;
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #ededf3;
}

.table-dark {
    background-color: #c8ccd3;
}

.table-dark>th,
.table-dark>td {
    background-color: #c8ccd3;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody+tbody {
    border-color: #99a0ae;
}

.table-hover .table-dark:hover {
    background-color: #babfc8;
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #babfc8;
}

.table-active {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-active>th,
.table-active>td {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
    color: #fff;
    background-color: #383853;
    border-color: #49597b;
}

.table .thead-light th {
    color: #4d5875;
    background-color: #f0f0f8;
    border-color: #e1e1ef;
}

.table-dark {
    color: #fff;
    background-color: #383853;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
    border-color: #49597b;
}

.table-dark.table-bordered {
    border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-sm>.table-bordered {
        border: 0;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-md>.table-bordered {
        border: 0;
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-lg>.table-bordered {
        border: 0;
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-xl>.table-bordered {
        border: 0;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table-responsive>.table-bordered {
    border: 0;
}

@media print {
    .table {
        border-collapse: collapse !important;
    }

    .table td,
    .table th {
        background-color: #fff !important;
    }

    .table-bordered th,
    .table-bordered td {
        border: 1px solid #ededf5 !important;
    }

    .table-dark {
        color: inherit;
    }

    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody+tbody {
        border-color: #e1e1ef;
    }

    .table .thead-dark th {
        color: inherit;
        border-color: var(--neutral-60);
    }
}

.table.table-clean td .value {
    font-size: 0.9rem;
    line-height: 1.6;
    font-weight: 500;
}

.table.table-clean td .sub-value {
    font-size: 0.72rem;
    color: #7987a1;
}

.table.table-clean tr:first-child td {
    border-top: none;
}

.table.table-clean td {
    padding-left: 0px;
    padding-right: 0px;
    border-top-color: var(--neutral-60);
}

.table.table-clean th,
.table.table-clean td {
    padding: 7px 20px !important;
}

@media (max-width: 1199px) {
    .table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

.table> :not(:last-child)> :last-child>* {
    border-bottom-color: #ededf5;
}

.table-bordered> :not(caption)>* {
    border-width: 0;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: rgb(255, 255, 255);
    color: var(--primary);
}

tbody,
td,
tfoot,
th,
thead,
tr {
    border-color: var(--neutral-60);
    border-style: none;
    border-width: 0;
}

.table-hover>tbody>tr:hover {
    --bs-table-accent-bg: none !important;
    background: #fcfcff;
}

@media (min-width: 768px) {
    .table-responsive.deleted-table .data-table-btn {
        position: absolute;
        left: 185px;
        z-index: 999;
    }
}

@media (min-width: 1319px) {
    .table-responsive.export-table .dt-buttons.btn-group {
        position: absolute;
        top: 0;
        left: 172px;
    }
}

.table> :not(:first-child) {
    border-top: none !important;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: $white !important;
}

@media (max-width: 583px) {

    .dt-buttons .btn,
    .dt-buttons .sp-container button {
        padding: 0.5rem 0.1rem;
    }
}

.ngx-datatable.custom-datatable {
    box-shadow: none !important;
}

.ngx-datatable.custom-datatable .datatable-body-row.active {
    background-color: unset !important;
}

.ngx-datatable.custom-datatable .datatable-body-row.active .datatable-row-group {
    background-color: unset !important;
}

.ngx-datatable.custom-datatable .datatable-header {
    border-bottom: 0;
}

.ngx-datatable.custom-datatable .datatable-header .datatable-header-cell {
    background-color: var(--secondary-10);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px;
    color: var(--secondary-darker);
}

.ngx-datatable.custom-datatable .datatable-header .datatable-header-cell:first-child {
    border-radius: 10px 0 0 0;
}

.ngx-datatable.custom-datatable .datatable-header .datatable-header-cell:last-child {
    border-radius: 0 10px 0 0;
}

.ngx-datatable.custom-datatable .datatable-header .datatable-header-inner {
    height: 100%;
}

.ngx-datatable.custom-datatable .datatable-header .datatable-row-right {
    display: flex;
}

.ngx-datatable.custom-datatable .datatable-header .datatable-row-right .datatable-header-cell {
    height: 100%;
    display: flex;
    align-items: center;
}

.ngx-datatable.custom-datatable .datatable-header .datatable-row-right .datatable-header-cell .datatable-header-cell-template-wrap {
    display: flex;
    align-items: center;
}

.ngx-datatable.custom-datatable .datatable-header .datatable-row-right .datatable-header-cell:first-child {
    border-radius: 0;
}

.ngx-datatable.custom-datatable .datatable-header .datatable-row-right .datatable-header-cell:last-child {
    border-radius: 0 10px 0 0;
}

.ngx-datatable.custom-datatable .datatable-body .datatable-body-cell {
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    white-space: normal !important;
    padding: 12px;
    color: var(--primary);
}

.ngx-datatable.custom-datatable .datatable-body .datatable-row-wrapper .datatable-body-row {
    border: 1px solid var(--neutral-60);
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.ngx-datatable.custom-datatable .datatable-body .datatable-row-wrapper .datatable-body-row:hover {
    background-color: #fff !important;
}

.ngx-datatable.custom-datatable .datatable-body .datatable-row-wrapper .datatable-body-row.invalid {
    background-color: var(--neutral-30) !important;
}

.ngx-datatable.custom-datatable .datatable-body .datatable-row-wrapper .datatable-body-row.invalid .datatable-body-cell {
    color: var(--neutral-70) !important;
}

.ngx-datatable.custom-datatable .datatable-body .datatable-row-wrapper:last-child .datatable-body-row {
    border-radius: 0 0 10px 10px;
}

.ngx-datatable.custom-datatable .datatable-body .datatable-row-even {
    background-color: #fff !important;
}

.ngx-datatable.custom-datatable .datatable-body .datatable-row-even .datatable-row-right {
    background-color: #fff !important;
}

.ngx-datatable.custom-datatable .datatable-body .datatable-row-odd {
    background-color: var(--neutral-20) !important;
}

.ngx-datatable.custom-datatable .datatable-body .datatable-row-odd .datatable-row-right {
    background-color: var(--neutral-20) !important;
}

.ngx-datatable.custom-datatable .datatable-body .datatable-body-cell-label {
    display: flex;
    align-items: flex-start;
    text-align: start;
}

.ngx-datatable.custom-datatable .datatable-body .datatable-row-right {
    border-left: 1px solid var(--primary-10);
}

.ngx-datatable.custom-datatable .datatable-body .datatable-row-right .datatable-body-cell {
    display: flex;
    align-items: center;
    justify-content: center !important;
}

.ngx-datatable.custom-datatable .datatable-footer {
    border: none;
    overflow: visible;
}

.toast {
    background-color: rgba(255, 255, 255, 0.95);
    border-color: rgba(28, 39, 60, 0.16);
    box-shadow: 0 2px 3px rgba(28, 39, 60, 0.06);
    border-radius: 3px;
}

.toast-header {
    border-bottom-color: rgba(205, 212, 224, 0.4);
}

.toast-header .close {
    font-weight: 300;
}

.toast {
    max-width: 240px;
    overflow: hidden;
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgb(229, 232, 243);
    box-shadow: -8px 12px 18px 0 rgba(244, 244, 251, 0.4509803922);
    backdrop-filter: blur(10px);
    opacity: 0;
    border-radius: 0.25rem;
}

.toast:not(:last-child) {
    margin-bottom: 0.75rem;
}

.toast.showing {
    opacity: 1;
}

.toast.show {
    display: block;
    opacity: 1;
}

.toast.hide {
    display: none;
}

.toast-header {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    color: #2c3e56;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
    padding: 0.75rem;
    color: #607086;
}

.demo-static-toast .toast {
    opacity: 1;
}

/* ###### 3.15 Tooltip ###### */
.tooltip {
    top: 0;
}

.tooltip-inner {
    font-size: 12px;
    border-radius: 2px;
}

.tooltip-primary .tooltip-inner {
    background-color: var(--primary);
    color: #fff;
}

.tooltip-primary.bs-tooltip-top .tooltip-arrow::before,
.tooltip-primary.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
    border-top-color: var(--primary);
}

.tooltip-primary.bs-tooltip-bottom .tooltip-arrow::before,
.tooltip-primary.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
    border-bottom-color: var(--primary);
}

.tooltip-primary.bs-tooltip-start .tooltip-arrow::before,
.tooltip-primary.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
    border-left-color: var(--primary);
}

.tooltip-primary.bs-tooltip-end .tooltip-arrow::before,
.tooltip-primary.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
    border-right-color: var(--primary);
}

.tooltip-primary1 .tooltip-inner {
    background-color: var(--primary);
    color: #fff;
}

.tooltip-primary1.bs-tooltip-top .tooltip-arrow::before,
.tooltip-primary1.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
    border-top-color: var(--primary);
}

.tooltip-primary1.bs-tooltip-bottom .tooltip-arrow::before,
.tooltip-primary1.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
    border-bottom-color: var(--primary);
}

.tooltip-primary1.bs-tooltip-start .tooltip-arrow::before,
.tooltip-primary1.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
    border-left-color: var(--primary);
}

.tooltip-primary1.bs-tooltip-end .tooltip-arrow::before,
.tooltip-primary1.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
    border-right-color: var(--primary);
}

.tooltip-secondary .tooltip-inner {
    background-color: var(--primary);
    color: #fff;
}

.tooltip-secondary.bs-tooltip-top .tooltip-arrow::before,
.tooltip-secondary.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
    border-top-color: var(--primary);
}

.tooltip-secondary.bs-tooltip-bottom .tooltip-arrow::before,
.tooltip-secondary.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
    border-bottom-color: var(--primary);
}

.tooltip-secondary.bs-tooltip-start .tooltip-arrow::before,
.tooltip-secondary.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
    border-left-color: var(--primary);
}

.tooltip-secondary.bs-tooltip-end .tooltip-arrow::before,
.tooltip-secondary.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
    border-right-color: var(--primary);
}

.tooltip-secondary1 .tooltip-inner {
    background-color: var(--primary);
    color: #fff;
}

.tooltip-secondary1.bs-tooltip-top .tooltip-arrow::before,
.tooltip-secondary1.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
    border-top-color: var(--primary);
}

.tooltip-secondary1.bs-tooltip-bottom .tooltip-arrow::before,
.tooltip-secondary1.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
    border-bottom-color: var(--primary);
}

.tooltip-secondary1.bs-tooltip-start .tooltip-arrow::before,
.tooltip-secondary1.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
    border-left-color: var(--primary);
}

.tooltip-secondary1.bs-tooltip-end .tooltip-arrow::before,
.tooltip-secondary1.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
    border-right-color: var(--primary);
}

.tooltip {
    position: absolute;
    z-index: 999;
    display: block;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.76562rem;
    word-wrap: break-word;
    opacity: 0;
}

.tooltip.show {
    opacity: 0.9;
}

.tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^=top] {
    padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
    bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
}

.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^=bottom] {
    padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
    top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000;
}

.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000;
}

.tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 3px;
}

.tooltip-static-demo {
    text-align: center;
    background-color: #f0f0f8;
    padding: 20px;
}

.tooltip-static-demo .tooltip {
    z-index: 0;
    opacity: 1;
    position: relative;
    display: inline-block;
    margin: 0 10px;
}

.tooltip-static-demo .bs-tooltip-top .tooltip-arrow,
.tooltip-static-demo .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow,
.tooltip-static-demo .bs-tooltip-bottom .tooltip-arrow,
.tooltip-static-demo .bs-tooltip-auto[data-popper-placement^=bottom] {
    left: 50%;
    margin-left: -2px;
}

.tooltip-static-demo .bs-tooltip-start,
.tooltip-static-demo .bs-tooltip-auto[data-popper-placement^=left],
.tooltip-static-demo .bs-tooltip-end,
.tooltip-static-demo .bs-tooltip-auto[data-popper-placement^=right] {
    margin-top: 5px;
}

.tooltip-static-demo .tooltip-primary1.bs-tooltip-start .tooltip-arrow,
.tooltip-static-demo .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow,
.tooltip-static-demo .bs-tooltip-end .tooltip-arrow,
.tooltip-static-demo .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
    top: 50%;
    margin-top: -6px;
}

.tooltip-static-demo .bs-tooltip-start .tooltip-arrow,
.tooltip-static-demo .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow,
.tooltip-static-demo .bs-tooltip-end .tooltip-arrow,
.tooltip-static-demo .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
    top: 50%;
    margin-top: -6px;
}

/* ######### Res-Tabs #########*/
.tabs-style-1 .main-nav-line .nav-link {
    padding: 10px 18px 10px 18px;
    background: transparent;
}

.tabs-style-1 .main-nav-line .nav-link.active::before {
    bottom: 0;
}

.tabs-style-2 .main-nav-line .nav-link {
    padding: 0.7rem 2rem;
    background: #f9f9fb;
}

.tabs-style-2 .main-nav-line .nav-link.active {
    background: transparent;
    color: var(--primary);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.tabs-style-2 .main-nav-line .nav-link.active::before {
    display: none;
}

.tabs-style-2 .main-nav-line .nav-link {
    padding: 0.7rem 2rem;
    background: #f9f9fb;
    border: 1px solid #ededf5;
    border-bottom: 0;
    border-right: 0;
}

.tabs-style-2 .main-nav-line:first-child {
    border-left: 0;
}

.tabs-style-2 li .nav-link.active {
    background: none;
    box-shadow: inset 0 3px 1px var(--primary);
    color: var(--primary);
    border-top: 0;
}

.tabs-style-3 {
    padding: 20px;
    border: 1px solid #e3e3e3;
}

.tabs-style-3 .nav.panel-tabs li a {
    padding: 10px 20px 10px 20px;
    background: #ffffff;
    border-radius: 5px;
    margin: 0 3px 3px 0;
    text-align: center;
    display: block;
    color: #4a4a69;
    border: 1px solid #ededf5;
}

.tabs-style-3 .tabs-menu-body {
    margin-top: 20px;
}

.tabs-style-3 .nav.panel-tabs li a.active {
    background: var(--primary);
    color: #fff;
}

.tabs-style-4 .nav.panel-tabs li {
    display: block;
    width: 100%;
}

.tabs-style-4 .tabs-menu-body {
    padding: 20px;
    border: 1px solid #e3e7f3;
    border-radius: 4px;
}

.tabs-style-4 .nav.panel-tabs li a {
    padding: 10px 20px 10px 20px;
    background: #fff;
    border-radius: 5px;
    margin: 0 0 16px 0;
    text-align: center;
    display: block;
    color: #4a4a69;
    width: 100%;
    border: 1px solid #ededf5;
}

.tabs-style-4 .nav.panel-tabs li a.active {
    background: var(--primary);
    color: #fff;
}

@media (min-width: 768px) {
    .tabs-style-4 .tab-menu-heading {
        width: 200px;
    }
}

.panel-primary.tabs-style-1 .main-nav-line .nav-link.active::before {
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    width: auto;
}

.tab_wrapper {
    display: inline-block;
    width: 100%;
    position: relative;
}

.tab_wrapper.right_side>ul {
    width: 25%;
    float: right;
    padding-left: 0;
    border-bottom: 1px solid #f0f2f7;
}

.tab_wrapper.right_side>ul li {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 12px 18px;
    border-width: 1px;
    border-right: 0;
    border-left: 0;
    text-align: right;
    list-style-type: none;
}

.tab_wrapper.right_side>ul li:after {
    right: 0;
    left: inherit;
    top: 0;
    content: "";
    position: absolute;
    width: 5px;
    height: 100%;
    background: #f0f2f7;
}

.tab_wrapper.right_side>ul li.active {
    border-color: #f0f2f7;
}

.tab_wrapper.right_side>ul li.active:after {
    right: 0;
    left: inherit;
    top: 0;
    width: 5px;
    height: 100%;
    content: "";
}

.tab_wrapper.right_side>ul li.active:before {
    left: -2px;
    right: inherit;
    position: absolute;
    top: 0;
    content: "";
    width: 2px;
    height: 100%;
    background: #fff;
}

.tab_wrapper.right_side .content_wrapper {
    width: 75%;
    float: left;
    border: 1px solid #f0f2f7;
}

.tab_wrapper.right_side .controller {
    top: -50px;
}

.tab_wrapper>ul li.active {
    border-color: var(--primary);
    background: var(--primary);
    color: #fff;
}

.tab_wrapper * {
    box-sizing: border-box;
}

.tab_wrapper>ul {
    clear: both;
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid rgba(0, 40, 100, 0.12);
    margin-bottom: 0;
}

.tab_wrapper>ul li {
    float: left;
    cursor: pointer;
    font-weight: 500;
    padding: 8px 18px;
    text-transform: uppercase;
    border: 1px solid #f0f2f7;
    font-size: 1.05em;
    border-bottom: 0;
    position: relative;
    border-top: 3px solid #f0f2f7;
    margin: 0 5px 0 0;
}

.tab_wrapper>ul li.active:after {
    content: "";
    position: absolute;
    bottom: -1px;
    height: 1px;
    left: 0;
    background: #fff;
    width: 100%;
}

.tab_wrapper .controller {
    display: block;
    overflow: hidden;
    clear: both;
    position: absolute;
    top: 0;
    right: 0;
}

.tab_wrapper .controller span {
    padding: 8px 22px;
    text-transform: uppercase;
    display: inline-block;
}

.tab_wrapper .controller span.next {
    float: right;
    margin-left: 10px;
}

.tab_wrapper .content_wrapper {
    float: left;
    width: 100%;
    border: 1px solid rgba(0, 40, 100, 0.12);
    border-top: 0;
    box-sizing: border-box;
}

.tab_wrapper .content_wrapper .tab_content {
    display: none;
    padding: 15px 20px;
}

.tab_wrapper .content_wrapper .tab_content.active {
    /* display: block; */
}

.tab_wrapper .content_wrapper .tab_content .error {
    color: red;
}

.tab_wrapper .content_wrapper .accordian_header {
    display: none;
    padding: 10px 20px;
    text-transform: uppercase;
    font-weight: bold;
    border-top: 1px solid #f0f2f7;
    border-bottom: 1px solid #fff;
    position: relative;
    cursor: pointer;
}

.tab_wrapper .content_wrapper .accordian_header.active {
    border: 1px solid #f0f2f7;
}

.tab_wrapper .content_wrapper .accordian_header.active:after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 5px;
    height: 100%;
}

.tab_wrapper .content_wrapper .accordian_header .arrow {
    float: right;
    display: inline-block;
    width: 12px;
    height: 12px;
    position: relative;
    border-radius: 2px;
    transform: rotate(-135deg);
    background: #fff;
    border-top: 3px solid #cdd2e0;
    border-left: 3px solid #cdd2e0;
}

.tab_wrapper .content_wrapper .accordian_header.active .arrow {
    transform: rotate(45deg);
    margin-top: 5px;
}

.tab_wrapper.left_side>ul {
    width: 25%;
    float: left;
    padding-left: 0;
    border-bottom: 1px solid #f0f2f7;
}

.tab_wrapper.left_side>ul li {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 12px 18px;
    border-width: 1px;
    border-right: 0;
    border-left: 0;
    list-style-type: none;
}

.tab_wrapper.left_side>ul li:after {
    left: 0;
    top: 0;
    content: "";
    position: absolute;
    width: 5px;
    height: 100%;
    background: #f0f2f7;
}

.tab_wrapper.left_side>ul li.active {
    border-color: #f0f2f7;
}

.tab_wrapper.left_side>ul li.active:after {
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
}

.tab_wrapper.left_side>ul li.active:before {
    right: -1px;
    position: absolute;
    top: 0;
    content: "";
    width: 2px;
    height: 100%;
    background: #fff;
}

.tab_wrapper.left_side .content_wrapper {
    width: 75%;
    border: 1px solid #f0f2f7;
    float: left;
}

.tab_wrapper.left_side .controller {
    top: -50px;
}

.tab_wrapper.right_side>ul {
    width: 25%;
    float: right;
    border-bottom: 1px solid #f0f2f7;
}

.tab_wrapper.right_side>ul li {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 12px 18px;
    border-width: 1px;
    border-right: 0;
    border-left: 0;
    text-align: right;
}

.tab_wrapper.right_side>ul li:after {
    right: 0;
    left: inherit;
    top: 0;
    content: "";
    position: absolute;
    width: 5px;
    height: 100%;
    background: #f0f2f7;
}

.tab_wrapper.right_side>ul li.active {
    border-color: #f0f2f7;
}

.tab_wrapper.right_side>ul li.active:after {
    right: 0;
    left: inherit;
    top: 0;
    width: 5px;
    height: 100%;
    content: "";
}

.tab_wrapper.right_side>ul li.active:before {
    left: -2px;
    right: inherit;
    position: absolute;
    top: 0;
    content: "";
    width: 2px;
    height: 100%;
    background: #fff;
}

.tab_wrapper.right_side .content_wrapper {
    width: 75%;
    float: left;
    border: 1px solid #f0f2f7;
}

.tab_wrapper.right_side .controller {
    top: -50px;
}

.tab_wrapper.accordion .content_wrapper {
    border-color: #f0f2f7;
}

.tab_wrapper.accordion .content_wrapper .accordian_header {
    display: block;
}

.tab_wrapper.accordion>ul {
    display: none;
}

.tab_wrapper .active_tab {
    display: none;
    position: relative;
    background: #000;
    padding: 12px;
    text-align: center;
    padding-right: 50px;
    color: #fff;
    cursor: pointer;
    border-radius: 30px;
}

.tab_wrapper .active_tab .arrow {
    width: 30px;
    height: 30px;
    overflow: hidden;
    line-height: 30px;
    text-align: center;
    background: #fff;
    position: absolute;
    right: 6px;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
}

.tab_wrapper .active_tab .arrow:after {
    content: "";
    width: 10px;
    display: inline-block;
    height: 10px;
    border-right: 3px solid #000;
    border-bottom: 3px solid #000;
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -6px;
    margin-top: -8px;
}

.tab_wrapper .active_tab .text {
    text-indent: 39px;
    display: block;
}

@media only screen and (max-width: 768px) {
    .tab_wrapper>ul {
        display: none;
    }

    .tab_wrapper .content_wrapper {
        border: 0;
    }

    .tab_wrapper .content_wrapper .accordian_header {
        display: block;
    }

    .tab_wrapper.left_side .content_wrapper,
    .tab_wrapper.right_side .content_wrapper {
        width: 100%;
        border-top: 0;
    }

    .tab_wrapper .controller {
        display: none;
    }

    .tab_wrapper.show-as-dropdown>.tab_list {
        border: 0;
        box-shadow: 0 0 8px #f0f2f7;
        position: absolute;
        z-index: 3;
        background: #fff;
        border-radius: 5px;
        margin-top: 15px;
    }

    .tab_wrapper.show-as-dropdown>.tab_list:before {
        content: "";
        position: absolute;
        top: -6px;
        left: 50%;
        width: 14px;
        height: 14px;
        background: #fff;
        transform: rotate(45deg);
        box-shadow: -3px -4px 5px #efefef;
        margin-left: -7px;
    }

    .tab_wrapper.show-as-dropdown>.tab_list li {
        border: 0;
        border-bottom: 1px solid #f0f2f7;
    }

    .tab_wrapper.show-as-dropdown>.tab_list li.active:after {
        display: none;
    }

    .tab_wrapper.show-as-dropdown .active_tab {
        display: block;
    }

    .tab_wrapper.show-as-dropdown>.tab_list,
    .tab_wrapper.show-as-dropdown>.content_wrapper>.accordian_header {
        display: none;
    }

    .tab_wrapper.show-as-dropdown>.tab_list li {
        display: block;
        float: none;
        width: 100%;
    }
}

@media only screen and (min-width: 769px) {
    .tab_wrapper.show-as-dropdown>.tab_list {
        display: block !important;
        overflow: hidden;
    }
}

.dark-theme .tabs-style-2 .main-nav-line .nav-link {
    background: #30385f;
}

.dark-theme .tabs-style-2 .main-nav-line .nav-link.active {
    background: var(--primary) !important;
    color: #fff;
}

.dark-theme .tabs-style-1 .main-nav-line .nav-link {
    background: #191f3a;
}

.dark-theme .tabs-style-3 {
    border: 1px solid rgba(227, 227, 227, 0.1);
}

.dark-theme .tabs-style-3 .nav.panel-tabs li a {
    background: rgba(239, 239, 245, 0.3);
    color: #fff;
}

.dark-theme .tabs-style-4 .tabs-menu-body {
    border: 1px solid rgba(227, 227, 227, 0.15);
}

.dark-theme .tabs-style-3 .nav.panel-tabs li a.active {
    background: var(--primary);
    color: #fff;
}

.dark-theme .tabs-style-4 .nav.panel-tabs li a {
    background: rgba(239, 239, 245, 0.2);
    color: #fff;
}

.dark-theme .tabs-style-4 .nav.panel-tabs li a.active {
    background: var(--primary);
    color: #fff;
}

.dark-theme .tab_wrapper.right_side .content_wrapper,
.dark-theme .tab_wrapper.left_side .content_wrapper,
.dark-theme .tab_wrapper>ul li {
    border: 1px solid rgba(240, 242, 247, 0.12);
}

.dark-theme .tab_wrapper.right_side>ul li.active:before,
.dark-theme .tab_wrapper.left_side>ul li.active:before,
.dark-theme .tab_wrapper.right_side>ul li:after,
.dark-theme .tab_wrapper.left_side>ul li:after {
    background: var(--primary);
}

.dark-theme .tab_wrapper.right_side>ul li.active,
.dark-theme .tab_wrapper.left_side>ul li.active {
    border-color: rgba(240, 242, 247, 0.1);
}

.dark-theme .tab_wrapper.right_side>ul,
.dark-theme .tab_wrapper.left_side>ul {
    border-bottom: 1px solid rgba(240, 242, 247, 0.1);
}

.tab_wrapper .content_wrapper .accordian_header.active {
    color: var(--primary);
}

.tab_wrapper .content_wrapper .accordian_header.active:after {
    background: var(--primary);
}

.tab_wrapper .content_wrapper .accordian_header.active .arrow {
    transform: rotate(45deg);
    margin-top: 5px;
}

.dark-theme .tab_wrapper .content_wrapper .accordian_header.active {
    border: 1px solid rgba(240, 242, 247, 0.1);
}

.dark-theme .tab_wrapper .content_wrapper .accordian_header {
    border-top: 1px solid rgba(240, 242, 247, 0.1);
    border-bottom: 1px solid rgba(240, 242, 247, 0.1);
}

.dark-theme .tab_wrapper .content_wrapper .accordian_header .arrow {
    background: transparent;
    border-top: 3px solid rgba(240, 242, 247, 0.4);
    border-left: 3px solid rgba(240, 242, 247, 0.4);
}

.tabs-style-1 .panel-tabs {
    border-bottom: 1px solid #ededf5;
}

.tabs-style-1 .main-nav-line .nav-link.active {
    color: #323251;
    border: 1px solid;
    border-color: #ededf5 #ededf5 #fff;
    transition: none;
}

.tabs-style-1 .main-nav-line .nav-item {
    margin-bottom: -1px;
}

.tabs-style-1 .panel-tabs.main-nav-line .nav-link.active::before {
    background: transparent !important;
}

.tabs-menu1 ul li a {
    padding: 10px 20px 11px 20px;
    display: block;
    color: #282f53;
}

.tabs-menu1 ul li .active {
    border-bottom: 2px solid var(--primary);
}

.nav.panel-tabs a.active {
    color: var(--primary);
}

.tab-menu-heading {
    padding: 0px;
    font-size: 16px;
    font-weight: 500;
}

/* ######### Res-Tabs #########*/
.tabs-style-custom .panel-tabs {
    border-bottom: 0px solid #ededf5;
}

.tabs-style-custom .main-nav-line .nav-link.active {
    color: #5E7FCD;
    background: #eff1fe;
    border: 1px solid #d7f2f2;
    border-color: #D7DEF2;
    transition: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.tabs-style-custom .main-nav-line .nav-link {
    color: #ADBDE4;
    position: relative;
    border: 1px solid #D7DEF2;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.tabs-style-custom .main-nav-line .nav-link:hover,
.tabs-style-custom .main-nav-line .nav-link:focus {
    color: #5E7FCD;
    background: #eff1fe;
    border: 1px solid #d7f2f2;
    border-color: #D7DEF2;
    transition: none;
}

.tabs-style-custom .tabs-menu-body {
    border: 0px solid #ededf5 !important;
}

.tabs-style-custom .panel-body {
    border: 0px !important;
    padding: 0px;
}

/*  ######## CUSTOM-STYLES ######## */
/* Checkbox */
.ckbox {
    font-weight: normal;
    position: relative;
    display: block;
    line-height: 1;
    margin-bottom: 0;
    width: fit-content;
}

.ckbox span {
    cursor: pointer;
}

.ckbox>span {
    padding-left: 15px;
    height: 16px;
}

.ckbox>span:empty {
    float: left;
}

.ckbox>span:before,
.ckbox>span:after {
    line-height: 18px;
    position: absolute;
}

.ckbox>span:before {
    content: "";
    width: 16px;
    height: 16px;
    background-color: var(--neutral-30);
    border: 1px solid var(--neutral-60);
    top: 2px;
    left: 0;
    border-radius: 3px;
}

.ckbox>span:after {
    top: 2px;
    left: 0;
    width: 16px;
    height: 16px;
    content: "check";
    font-family: "Material Symbols Outlined";
    color: #fff;
    font-size: 16px;
    line-height: 16px;
    display: none;
    border-radius: 3px;
}

.ckbox>span:empty {
    padding-left: 0;
    width: 3px;
}

.ckbox input[type=checkbox] {
    opacity: 0;
    margin: 0;
}

.ckbox input[type=checkbox][disabled]+span:before {
    background-color: var(--primary-10);
}

.ckbox input[type=checkbox]:checked+span:before {
    background-color: var(--secondary);
    border-color: var(--secondary);
}

.ckbox input[type=checkbox]:checked+span:after {
    display: block;
}

.ckbox input[type=checkbox]:checked[disabled]+span:before {
    background-color: var(--secondary-25);
    border-color: var(--secondary-25);
}

.ckbox input[type=checkbox]:checked[disabled]+span:after {
    color: var(--secondary);
}

.ckbox-inline {
    display: inline-block;
}

/*---Count-down---*/
#count-down {
    position: relative;
    text-align: center;
    display: inline-block;
}

#count-down .clock-presenter {
    height: 80px;
    padding: 0px;
    text-align: center;
    width: 68px;
    height: 68px;
    float: left;
    margin: 0 20px 0 0;
    border-radius: 3px;
    background-size: cover;
    position: relative;
}

#count-down .clock-presenter .digit {
    margin-top: 5px;
    font-size: 1.5rem;
    color: #fff;
    line-height: 60px;
    height: 60px;
    display: inline-block;
    overflow: hidden;
    text-align: center;
    position: relative;
    cursor: default;
    font-weight: 600;
    border-radius: 50%;
}

#count-down .clock-presenter:before {
    content: "";
    position: absolute;
    background: linear-gradient(to left, var(--primary) 0%, var(--primary) 100%) !important;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: block;
    border-radius: 15%;
}

#count-down .clock-presenter .note {
    position: relative;
    margin-bottom: 0px;
    cursor: default;
    font-size: 16px;
    opacity: 0.7;
}

@media (max-width: 568px) {
    #count-down .clock-presenter {
        float: none !important;
        margin-bottom: 45px !important;
    }
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.3125rem;
    padding-left: 1.5rem;
}

.custom-control-inline {
    display: inline-flex;
    margin-right: 1rem;
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: var(--primary);
    background-color: var(--primary);
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(51, 102, 255, 0.25);
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #b3c6ff;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
    color: #fff;
    background-color: #e6ecff;
    border-color: #e6ecff;
}

.custom-control-input:disabled~.custom-control-label {
    color: #7987a1;
}

.custom-control-input:disabled~.custom-control-label::before {
    background-color: #f0f0f8;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

.custom-control-label::before {
    position: absolute;
    top: 0;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #cdd4e2 solid 1px;
}

.custom-control-label::after {
    position: absolute;
    top: 0;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 3px;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    border-color: var(--primary);
    background-color: var(--primary);
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before,
.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
    background-color: rgba(51, 102, 255, 0.5);
}

.custom-radio .custom-control-label::before {
    border-radius: 50%;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(51, 102, 255, 0.5);
}

.custom-switch {
    padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
    top: calc(0.15625rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #949eb7;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(51, 102, 255, 0.5);
}

@media (prefers-reduced-motion: reduce) {
    .custom-switch .custom-control-label::after {
        transition: none;
    }
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4d5875;
    vertical-align: middle;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
    background-color: #fff;
    border: 1px solid #ededf5;
    border-radius: 3px;
    appearance: none;
}

.custom-select:focus {
    border-color: #b3c6ff;
    outline: 0;
}

.custom-select:focus::-ms-value {
    color: #4d5875;
    background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none;
}

.custom-select:disabled {
    color: #7987a1;
    background-color: #f0f0f8;
}

.custom-select::-ms-expand {
    display: none;
}

.custom-select-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.76562rem;
}

.custom-select-lg {
    height: calc(1.5em + 1rem + 2px);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1.09375rem;
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin-bottom: 0;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin: 0;
    opacity: 0;
}

.custom-file-input:focus~.custom-file-label {
    border-color: #b3c6ff;
    box-shadow: 0 0 0 0.2rem rgba(51, 102, 255, 0.25);
}

.custom-file-input:disabled~.custom-file-label {
    background-color: #f0f0f8;
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Browse";
}

.custom-file-input~.custom-file-label[data-browse]::after {
    content: attr(data-browse);
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4d5875;
    background-color: #fff;
    border: 1px solid #ededf5;
    border-radius: 3px;
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #4d5875;
    content: "Browse";
    background-color: #f0f0f8;
    border-left: inherit;
    border-radius: 0 3px 3px 0;
}

.custom-range {
    width: 100%;
    height: 1.4rem;
    padding: 0;
    background-color: transparent;
    appearance: none;
}

.custom-range:focus {
    outline: none;
}

.custom-range:focus::-webkit-slider-thumb,
.custom-range:focus::-moz-range-thumb,
.custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(51, 102, 255, 0.25);
}

.custom-range::-moz-focus-outer {
    border: 0;
}

.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: var(--primary);
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}

.custom-range::-webkit-slider-thumb:active {
    background-color: #e6ecff;
}

.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e1e1ef;
    border-color: transparent;
    border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: var(--primary);
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}

.custom-range::-moz-range-thumb:active {
    background-color: #e6ecff;
}

.custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e1e1ef;
    border-color: transparent;
    border-radius: 1rem;
}

.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: var(--primary);
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}

.custom-range::-ms-thumb:active {
    background-color: #e6ecff;
}

.custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
    background-color: #e1e1ef;
    border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #e1e1ef;
    border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
    background-color: #949eb7;
}

.custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
    background-color: #949eb7;
}

.custom-range:disabled::-moz-range-track {
    cursor: default;
}

.custom-range:disabled::-ms-thumb {
    background-color: #949eb7;
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-webkit-slider-thumb {
        transition: none;
    }
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-moz-range-thumb {
        transition: none;
    }
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-ms-thumb {
        transition: none;
    }
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {

    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
        transition: none;
    }
}

.custom-file,
.custom-file-input {
    height: 38px;
}

.custom-file-label {
    height: 38px;
    line-height: 1.8;
    border-radius: 0;
}

.custom-file-label::after {
    line-height: 1.8;
    border-radius: 0;
    height: auto;
}

.custom-select {
    background: none;
}

.custom-control-label-lg::before {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 2rem;
    height: 2rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #cdd4e2 solid 1px;
    border-radius: 35px;
}

.custom-control-label-lg::after {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 2rem;
    height: 2rem;
    content: "";
    background: no-repeat 50%/50% 50%;
    border-radius: 35px;
}

.custom-control-label-md::before,
.custom-control-label-md::after {
    top: 0.25rem !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
}

.custom-control-label-md::before,
.custom-control-label-md::after {
    top: 0.25rem !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
}

.custom-control-md {
    min-height: 2rem !important;
}

.custom-control-label-md {
    padding-left: 8px;
    padding-top: 6px;
}

.custom-control-label-lg::before,
.custom-control-label-lg::after {
    top: 0.25rem !important;
    width: 2rem !important;
    height: 2rem !important;
}

.custom-control-label-lg::before,
.custom-control-label-lg::after {
    top: 0.25rem !important;
    width: 2rem !important;
    height: 2rem !important;
}

.custom-control-lg {
    min-height: 2rem !important;
}

.custom-control-label-lg {
    padding-left: 15px;
    padding-top: 10px;
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: var(--primary);
    opacity: 0.5;
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: var(--primary);
    opacity: 0.5;
}

.custom-switch-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom-switch-indicator {
    display: inline-block;
    height: 1.25rem;
    width: 2.25rem;
    background: #f0f0f2;
    border-radius: 50px;
    position: relative;
    vertical-align: bottom;
    transition: 0.3s border-color, 0.3s background-color;
}

.custom-switch-indicator:before {
    content: "";
    position: absolute;
    height: calc(1.25rem - 4px);
    width: calc(1.25rem - 4px);
    top: 1px;
    left: 1px;
    background: #fff;
    border-radius: 50%;
    transition: 0.3s left;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
}

.custom-switch-input:checked~.custom-switch-indicator {
    background: var(--secondary-25);
}

.custom-switch-input:checked~.custom-switch-indicator:before {
    left: calc(1rem + 1px);
}

.custom-square {
    border-radius: 0px !important;
}

.custom-square:before {
    border-radius: 0px !important;
}

.custom-switch-indicator-lg {
    display: inline-block;
    height: 1.5rem;
    width: 3rem;
    background: #f5f6fb;
    border-radius: 50px;
    position: relative;
    vertical-align: bottom;
    border: 1px solid #ebeaf1;
    transition: 0.3s border-color, 0.3s background-color;
}

.custom-switch-indicator-lg:before {
    content: "";
    position: absolute;
    height: calc(1.5rem - 4px);
    width: calc(1.5rem - 4px);
    top: 1px;
    left: 1px;
    background: #fff;
    border-radius: 50%;
    transition: 0.3s left;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
}

.custom-switch-input:checked~.custom-switch-indicator-lg:before {
    left: calc(1.46rem + 1px);
}

.custom-switch-indicator-xl {
    display: inline-block;
    height: 2rem;
    width: 4rem;
    background: #f5f6fb;
    border-radius: 50px;
    position: relative;
    vertical-align: bottom;
    border: 1px solid #ebeaf1;
    transition: 0.3s border-color, 0.3s background-color;
}

.custom-switch-indicator-xl:before {
    content: "";
    position: absolute;
    height: calc(2rem - 4px);
    width: calc(2rem - 4px);
    top: 1px;
    left: 1px;
    background: #fff;
    border-radius: 50%;
    transition: 0.3s left;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
}

.custom-switch-input:checked~.custom-switch-indicator-xl:before {
    left: calc(1.96rem + 1px) !important;
}

.form-select:focus {
    box-shadow: none;
}

.item-card .cardprice-2 span.type--strikethrough {
    opacity: 0.5;
    text-decoration: line-through;
    font-size: 14px;
    font-weight: 400;
}

.item-card .cardprice-2 span {
    display: block;
    font-size: 18px;
    font-weight: 500;
}

.item-card .cardprice-2 {
    position: absolute;
    right: 15px;
}

/* ###### 5.4 Image   ###### */
.main-img-user {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    text-align: center;
}

.main-img-user img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    border-radius: 100%;
}

.p-text {
    display: block;
    margin-left: 12px;
    margin-right: 10px;
}

.p-text .p-name {
    color: #3e464e;
    font-size: 14px;
    vertical-align: middle;
    margin-bottom: 0;
    display: block;
    padding-bottom: 4px;
    line-height: 1.8;
}

.p-text .p-sub-text {
    line-height: 0;
    display: block;
    color: #7987a1 !important;
}

/* ###### 5.4 Image   ###### */
.main-img-user {
    display: inline-block;
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    text-align: center;
}

.main-img-user img {
    object-fit: cover;
    border-radius: 100%;
}

.demo-avatar-group .main-img-user {
    width: 40px;
    height: 40px;
}

.example .demo-avatar-group .main-img-user::after {
    right: 3px;
    bottom: 3px;
}

.demo-avatar-group.main-avatar-list-stacked .main-img-user::after {
    right: 1px;
    bottom: 3px;
}

.main-img-user::after {
    content: "";
    position: absolute;
    bottom: 3px;
    right: 0px;
    width: 6px;
    height: 6px;
    background-color: #17b86a;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95);
    border-radius: 100%;
    display: block !important;
}

.main-img-user.online::after {
    background-color: #0ba360;
}

.main-avatar {
    position: relative;
    width: 36px;
    height: 36px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    background-color: #4d5875;
}

.main-avatar::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 3px;
    width: 6px;
    height: 6px;
    background-color: #949eb7;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95);
    border-radius: 100%;
}

.main-avatar.online::after {
    background-color: #0ba360;
}

.avatar-xs {
    width: 24px !important;
    height: 24px !important;
    font-size: 11px !important;
}

.avatar-xs::after {
    width: 5px;
    height: 5px;
}

.avatar-sm {
    width: 32px !important;
    height: 32px !important;
    font-size: 14px !important;
}

.avatar-sm::after {
    width: 7px;
    height: 7px;
}

.avatar-md {
    width: 48px !important;
    height: 48px !important;
    font-size: 20px !important;
}

.avatar-md::after {
    width: 9px;
    height: 9px;
    right: 2px;
    bottom: 2px;
}

.avatar-lg {
    width: 64px !important;
    height: 64px !important;
    font-size: 28px !important;
}

.avatar-lg::after {
    width: 10px;
    height: 10px;
    bottom: 3px;
    right: 4px;
}

.avatar-xl {
    width: 72px !important;
    height: 72px !important;
    font-size: 36px !important;
}

.avatar-xl::after {
    width: 11px;
    height: 11px;
    bottom: 4px;
    right: 5px;
    box-shadow: 0 0 0 2.5px #fff;
}

.avatar-xxl {
    width: 100px !important;
    height: 100px !important;
    font-size: 56px !important;
}

.avatar-xxl::after {
    width: 13px;
    height: 13px;
    bottom: 6px;
    right: 7px;
    box-shadow: 0 0 0 3px #fff;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #ededf5;
    border-radius: 10px;
    max-width: 100%;
    height: auto;
}

.demo-avatar-group {
    display: flex;
    align-items: center;
}

.demo-avatar-group .main-img-user,
.demo-avatar-group .main-avatar {
    flex-shrink: 0;
}

.demo-avatar-group .main-img-user+.main-img-user,
.demo-avatar-group .main-img-user+.main-avatar {
    margin-left: 5px;
}

.demo-avatar-group .main-avatar+.main-img-user,
.demo-avatar-group .main-avatar+.main-avatar {
    margin-left: 5px;
}

@media (min-width: 576px) {

    .demo-avatar-group .main-img-user+.main-img-user,
    .demo-avatar-group .main-img-user+.main-avatar {
        margin-left: 5px;
    }

    .demo-avatar-group .main-avatar+.main-img-user,
    .demo-avatar-group .main-avatar+.main-avatar {
        margin-left: -5px;
    }

    .avatar-list .avatar:not(:last-child) {
        margin-right: 5px !important;
    }
}

.img-xs {
    width: 32px;
    min-width: 32px;
    height: 32px;
}

.user-pic img {
    width: 60px;
    height: 60px;
    font-size: 36px;
}

.main-img-user .avatar {
    width: 38px !important;
    height: 38px !important;
    border-radius: 4px !important;
}

.main-img-user::after {
    display: none;
}

.wrapper.image-group .img-xs.rounded-circle {
    margin-right: -5px;
}

.user-lock img {
    width: 70px;
    height: 70px;
    margin: 0 auto;
}

.avatar {
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
}

.avatar-list .avatar:not(:last-child) {
    margin-right: 0.5rem;
}

.img-thumbnail .caption {
    padding: 15px;
}

.img-sm {
    width: 43px;
    min-width: 43px;
    height: 43px;
}

.square {
    border-radius: 0 !important;
}

.radius {
    border-radius: 10px !important;
}

.main-img-user1,
.main-img-user3 {
    position: relative;
}

.main-img-user1.avatar-sm .number-badge {
    position: absolute;
    top: -7px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 1;
    right: -7px;
    border: 1px solid #ededf5;
}

.main-img-user1.avatar-md .number-badge {
    position: absolute;
    top: -7px;
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    line-height: 1;
    right: -7px;
    border: 1px solid #ededf5;
}

.main-img-user1.avatar-lg .number-badge {
    position: absolute;
    top: -7px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 1;
    right: -7px;
    border: 1px solid #ededf5;
}

.main-img-user3.avatar-sm .icon-badgeavatar {
    position: absolute;
    bottom: -4px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 1;
    right: -4px;
    border: 2px solid #ededf5;
}

.main-img-user3.avatar-sm .icon-badgeavatar i {
    font-size: 10px;
    line-height: 1.4 !important;
}

.main-img-user3.avatar-md .icon-badgeavatar {
    position: absolute;
    bottom: -4px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 1;
    right: -4px;
    border: 2px solid #ededf5;
}

.main-img-user3.avatar-md .icon-badgeavatar i {
    font-size: 11px;
    line-height: 1.4 !important;
}

.main-img-user3.avatar-lg .icon-badgeavatar {
    position: absolute;
    bottom: -4px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 1;
    right: -4px;
    border: 2px solid #ededf5;
}

.main-img-user3.avatar-lg .icon-badgeavatar i {
    font-size: 12px;
    line-height: 1.4 !important;
}

.avatar-list {
    margin: 0 0 -0.5rem;
    padding: 0;
    font-size: 0;
}

.avatar-list-stacked .avatar1 {
    box-shadow: 0 0 0 2px #fff;
    margin-right: -0.8em !important;
}

.avatar1 {
    width: 3rem;
    height: 3rem;
    line-height: 2.85rem;
    position: relative;
    text-align: center;
    display: inline-block;
    color: #fff;
    font-weight: 600;
    vertical-align: bottom;
    font-size: 0.875rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.avatar1 {
    background: var(--primary) no-repeat center/cover;
}

.avatar1 i {
    line-height: 3.1;
}

/* ###### 5.5 List   ###### */
.main-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}

.main-list-item+.main-list-item {
    border-top: 1px solid #f0f0f8;
}

.main-list-item:last-child {
    padding-bottom: 0;
}

.main-list-item h6 {
    margin-bottom: 2px;
    font-weight: 600;
}

.main-list-item>div:first-child h6 {
    color: #323251;
}

.main-list-item>div span {
    color: #7987a1;
    font-size: 12px;
}

.main-list-item>div:last-child {
    text-align: right;
}

.main-list-item>div:last-child h6 {
    line-height: 1;
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}

.list-group-item-action {
    width: 100%;
    color: #434f63;
    text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
    z-index: 0;
    color: #4a4a69;
    text-decoration: none;
    background-color: #fcfcff;
}

.list-group-item-action:active {
    color: #4a4a69;
    background-color: var(--primary02);
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgb(231, 235, 243);
}

.list-group-item:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #7987a1;
    pointer-events: none;
    background-color: #fff;
}

.list-group-item.active {
    z-index: 2;
    color: #4a4a69;
    background-color: var(--primary02) !important;
    border-color: #ededf5 !important;
}

.list-group-horizontal {
    flex-direction: row;
}

.list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
}

.list-group-horizontal .list-group-item:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
    .list-group-horizontal-sm {
        flex-direction: row;
    }

    .list-group-horizontal-sm .list-group-item {
        margin-right: -1px;
        margin-bottom: 0;
    }

    .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 0;
    }
}

@media (min-width: 768px) {
    .list-group-horizontal-md {
        flex-direction: row;
    }

    .list-group-horizontal-md .list-group-item {
        margin-right: -1px;
        margin-bottom: 0;
    }

    .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 0;
    }
}

@media (min-width: 992px) {
    .list-group-horizontal-lg {
        flex-direction: row;
    }

    .list-group-horizontal-lg .list-group-item {
        margin-right: -1px;
        margin-bottom: 0;
    }

    .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 0;
    }
}

@media (min-width: 1200px) {
    .list-group-horizontal-xl {
        flex-direction: row;
    }

    .list-group-horizontal-xl .list-group-item {
        margin-right: -1px;
        margin-bottom: 0;
    }

    .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 0;
    }
}

.list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}

.list-group-flush .list-group-item:last-child {
    margin-bottom: -1px;
}

.list-group-flush:first-child .list-group-item:first-child {
    border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom: 0;
}

.list-group-item-primary {
    color: var(--primary);
    background: var(--primary-50);
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
    color: var(--primary);
    background-color: var(--primary-50);
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}

.list-group-item-secondary {
    color: var(--secondary);
    background-color: rgba(247, 79, 117, 0.13);
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
    color: #3f4654;
    background-color: #cacfdb;
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #3f4654;
    border-color: #3f4654;
}

.list-group-item-success {
    color: var(--success);
    background-color: rgba(26, 156, 134, 0.13);
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
    color: #1f5c01;
    background-color: #b9e3a5;
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #1f5c01;
    border-color: #1f5c01;
}

.list-group-item-info {
    color: var(--info);
    background-color: rgba(25, 192, 192, 0.13);
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460;
}

.list-group-item-warning {
    color: var(--warning);
    background-color: rgba(255, 189, 90, 0.13);
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404;
}

.list-group-item-danger {
    color: #f34343;
    background-color: rgba(243, 67, 67, 0.13);
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24;
}

.list-group-item-light {
    color: #7f7f81;
    background-color: #fcfcfd;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
    color: #7f7f81;
    background-color: #ededf3;
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #7f7f81;
    border-color: #7f7f81;
}

.list-group-item-dark {
    color: #1f2533;
    background-color: #c8ccd3;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
    color: #1f2533;
    background-color: #babfc8;
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1f2533;
    border-color: #1f2533;
}

.listgroup-example ul {
    list-style-type: none;
}

.listgroup-example ul ul {
    list-style-type: square;
    margin: 10px 0 0 0;
}

.listgroup-example ul li {
    padding: 5px 5px;
}

.listgroup-example2 ul {
    list-style-type: none;
}

.listgroup-example2 ul ul {
    list-style-type: circle;
    margin: 10px 0 0 0;
}

.listgroup-example2 ul li {
    padding: 5px 5px;
}

.users-list-group .btn-icon {
    width: 34px;
    height: 34px;
}

.users-list-group .btn-icon i {
    line-height: 34px;
    line-height: 34px;
}

.users-list-group .list-group-item {
    padding: 0.85rem 1.25rem;
}

.list-style-1 {
    list-style: inherit;
    padding-left: inherit;
}

.list-style-1 ul {
    list-style: inherit;
    padding-left: 30px;
}

.list-style-1 li {
    line-height: 30px;
}

.list-style2 {
    list-style: decimal;
    padding-left: inherit;
}

.list-style2 ul {
    list-style: decimal;
    padding-left: 30px;
}

.list-style2 li {
    line-height: 30px;
}

.list-style3 {
    list-style: circle !important;
    padding-left: inherit;
}

.list-style3 ul {
    list-style: decimal;
    padding-left: 30px;
}

.list-style3 li {
    line-height: 30px;
}

.list-style4 {
    list-style: lower-alpha;
    padding-left: inherit;
}

.list-style4 ul {
    list-style: decimal;
    padding-left: 30px;
}

.list-style4 li {
    line-height: 30px;
}

.list-style5 {
    list-style: square;
    padding-left: inherit;
}

.list-style5 ul {
    list-style: decimal;
    padding-left: 30px;
}

.list-style5 li {
    line-height: 30px;
}

.list-style6 {
    list-style: lower-roman;
    padding-left: inherit;
}

.list-style6 ul {
    list-style: decimal;
    padding-left: 30px;
}

.list-style6 li {
    line-height: 30px;
}

.order-list li {
    line-height: 30px;
}

.paragraph-list {
    list-style: lower-roman;
    padding-left: inherit;
    list-style-type: inline;
    padding-left: 20px;
}

/* ###### 6.6 Navbar  ###### */
@media (max-width: 991.98px) {
    .main-navbar-show {
        overflow: hidden;
    }

    .main-navbar-show .main-navbar-backdrop {
        visibility: visible;
        opacity: 1;
    }

    .main-navbar-show .main-navbar {
        transform: translateX(0);
        visibility: visible;
    }
}

.main-navbar-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.89);
    z-index: 900;
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s;
}

@media (prefers-reduced-motion: reduce) {
    .main-navbar-backdrop {
        transition: none;
    }
}

.main-navbar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 240px;
    overflow-y: auto;
    background-color: #fff;
    z-index: 1000;
    visibility: hidden;
    transform: translateX(-240px);
    transition: all 0.4s;
}

.main-navbar>.container,
.main-navbar>.container-fluid {
    display: flex;
    flex-direction: column;
}

.main-navbar .nav {
    flex-direction: column;
    padding: 20px;
    margin-bottom: 20px;
}

.main-navbar .nav-label {
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    color: #7987a1;
    letter-spacing: 1px;
    display: block;
    margin-bottom: 10px;
}

.main-navbar .nav-item {
    position: relative;
}

.main-navbar .nav-item+.nav-item {
    border-top: 1px dotted #f0f0f8;
}

.main-navbar .nav-item.active .nav-link {
    color: var(--primary);
}

.main-navbar .nav-item.show>.nav-sub {
    display: block;
}

.main-navbar .nav-item-mega {
    position: static;
}

.main-navbar .nav-link {
    padding: 0;
    color: #323251;
    font-size: 15px;
    font-weight: 500;
    position: relative;
    display: flex;
    align-items: center;
    height: 42px;
    outline: none;
}

.main-navbar .nav-link i {
    font-size: 26px;
    margin-right: 10px;
}

.main-navbar .nav-link i.typcn {
    line-height: 1;
    width: 20px;
}

.main-navbar .nav-link i.typcn::before {
    width: auto;
}

.main-navbar .nav-link.with-sub::after {
    content: "\f3d0";
    font-family: "Ionicons";
    font-size: 11px;
    font-weight: 400;
    display: inline-block;
    position: relative;
    margin-left: auto;
    color: #949eb7;
}

.main-navbar .nav-link.with-sub::before {
    content: "";
    position: absolute;
    top: 39px;
    left: 50%;
    margin-left: -7px;
    width: 15px;
    height: 15px;
    border: 2px solid transparent;
    border-top-color: rgba(28, 39, 60, 0.12);
    border-left-color: rgba(28, 39, 60, 0.12);
    transform: rotate(45deg);
    background-color: #fff;
    z-index: 901;
    display: none;
}

.main-navbar .nav-sub {
    display: none;
    border-top: 1px dotted #f0f0f8;
    list-style: none;
    padding: 0;
    margin: 0;
}

.main-navbar .nav-sub-item {
    position: relative;
    display: block;
    margin-left: 30px;
}

.main-navbar .nav-sub-item+.nav-sub-item {
    border-top: 1px dotted #f0f0f8;
}

.main-navbar .nav-sub-item>.nav-sub {
    left: 176px;
    top: -10px;
    display: none;
}

.main-navbar .nav-sub-item.show>.nav-sub {
    display: block;
}

.main-navbar .nav-sub-item.active>.nav-sub-link {
    color: var(--primary);
}

.main-navbar .nav-sub-link {
    position: relative;
    font-size: 0.875rem;
    color: #4d5875;
    display: flex;
    align-items: center;
    height: 34px;
}

.main-navbar .nav-sub-link:hover,
.main-navbar .nav-sub-link:focus {
    color: var(--primary);
    outline: none;
}

.main-navbar .nav-sub-link.with-sub {
    justify-content: space-between;
}

.main-navbar .nav-sub-link.with-sub::after {
    content: "\f3d0";
    font-family: "Ionicons";
    font-size: 11px;
    font-weight: 400;
    display: inline-block;
    position: relative;
    margin-left: auto;
    color: #949eb7;
}

.main-navbar .nav-sub-mega {
    top: 57px;
    left: 0;
    right: 0;
    background-color: transparent;
    padding: 7px 0;
    border: 0;
    width: auto;
    border-top: 1px dotted #f0f0f8;
    pointer-events: none;
}

.main-navbar .nav-sub-mega .container,
.main-navbar .nav-sub-mega .container-fluid {
    padding: 0;
    pointer-events: auto;
}

.main-navbar .nav-sub-mega .container>div+div,
.main-navbar .nav-sub-mega .container-fluid>div+div {
    margin-top: 25px;
}

.main-navbar .nav-sub-mega .nav {
    min-width: 120px;
    flex-direction: column;
    align-items: stretch;
    padding: 0;
    margin: 0;
}

.main-navbar .nav-sub-mega .nav li:not(.nav-sub-item) {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    color: #323251;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    margin-top: 30px;
    margin-left: 30px;
}

.main-navbar .nav-sub-mega .nav li:not(.nav-sub-item):first-of-type {
    margin-top: 0;
}

.main-navbar .nav-sub-mega .nav-sub-item+.nav-sub-item {
    padding-top: 7px;
    margin-top: 7px;
}

.main-navbar .nav-sub-mega .nav-sub-link {
    height: auto;
}

@media (prefers-reduced-motion: reduce) {
    .main-navbar {
        transition: none;
    }
}

@media (min-width: 992px) {
    .main-navbar {
        position: relative;
        top: auto;
        left: auto;
        bottom: auto;
        width: auto;
        padding: 0;
        border-right: 0;
        overflow-y: inherit;
        display: block;
        border-bottom: 1px solid rgba(28, 39, 60, 0.12);
        visibility: visible;
        transform: none;
        z-index: auto;
    }
}

@media (max-width: 991.98px) {

    .main-navbar>.container,
    .main-navbar>.container-fluid {
        padding: 0;
    }
}

@media (min-width: 992px) {

    .main-navbar>.container,
    .main-navbar>.container-fluid {
        flex-direction: row;
        align-items: center;
        height: 48px;
    }
}

@media (min-width: 992px) {
    .main-navbar>.container {
        padding: 0;
    }
}

@media (min-width: 992px) {
    .main-navbar>.container-fluid {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav {
        flex-direction: row;
        align-items: center;
        margin-bottom: 0;
        padding: 0;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav-label {
        display: none;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav-item+.nav-item {
        border-top: 0;
        margin-left: 15px;
        padding-left: 15px;
        border-left: 1px dotted #f0f0f8;
    }
}

@media (min-width: 1200px) {
    .main-navbar .nav-item+.nav-item {
        margin-left: 20px;
        padding-left: 20px;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav-item.show .nav-link::before {
        display: block;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav-link {
        height: auto;
        font-size: 14px;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav-link i {
        margin-right: 8px;
        font-size: 22px;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav-link i.typcn {
        line-height: 0.9;
        width: auto;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav-link.with-sub::after {
        margin-left: 4px;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav-sub {
        position: absolute;
        top: 44.5px;
        left: 0;
        width: 200px;
        background-color: #fff;
        border: 2px solid rgba(28, 39, 60, 0.12);
        padding: 8px 20px;
        z-index: 900;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav-sub-item {
        margin-left: 0;
    }
}

@media (max-width: 991.98px) {
    .main-navbar .nav-sub-item>.nav-sub>.nav-sub-item {
        margin-left: 37px;
    }

    .main-navbar .nav-sub-item>.nav-sub>.nav-sub-item>.nav-sub-link {
        padding-left: 10px;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav-sub-link {
        font-size: 0.8125rem;
        height: 36px;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav-sub-link.with-sub::after {
        margin-left: 4px;
        content: "\f3d1";
        font-size: 12px;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav-sub-mega {
        padding: 0 25px;
        border-top: 0;
    }
}

@media (max-width: 991.98px) {

    .main-navbar .nav-sub-mega .container,
    .main-navbar .nav-sub-mega .container-fluid {
        max-width: none;
    }
}

@media (min-width: 992px) {

    .main-navbar .nav-sub-mega .container,
    .main-navbar .nav-sub-mega .container-fluid {
        display: flex;
        background-color: #fff;
        border: 2px solid rgba(28, 39, 60, 0.12);
    }
}

@media (min-width: 992px) {

    .main-navbar .nav-sub-mega .container>div,
    .main-navbar .nav-sub-mega .container-fluid>div {
        display: flex;
        padding: 20px;
        flex: 1;
        margin-top: 0;
    }
}

@media (min-width: 1200px) {

    .main-navbar .nav-sub-mega .container>div,
    .main-navbar .nav-sub-mega .container-fluid>div {
        padding: 20px 25px;
    }
}

@media (min-width: 992px) {

    .main-navbar .nav-sub-mega .container>div+div,
    .main-navbar .nav-sub-mega .container-fluid>div+div {
        margin-top: 0;
        border-left: 1px solid rgba(28, 39, 60, 0.05);
    }
}

@media (min-width: 1200px) {
    .main-navbar .nav-sub-mega .nav {
        min-width: 140px;
    }
}

@media (max-width: 991.98px) {
    .main-navbar .nav-sub-mega .nav+.nav .nav-sub-item:first-child {
        margin-top: 6px;
        padding-top: 6px;
        border-top: 1px dotted #f0f0f8;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav-sub-mega .nav+.nav {
        margin-left: 20px;
        margin-top: 31px;
        padding-top: 0;
        border-top: 0;
    }
}

@media (min-width: 1200px) {
    .main-navbar .nav-sub-mega .nav+.nav {
        margin-left: 25px;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav-sub-mega .nav li:not(.nav-sub-item) {
        margin-bottom: 15px;
        margin-left: 0;
    }
}

.main-navbar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border-bottom: 1px solid #f0f0f8;
    height: 60px;
}

@media (min-width: 992px) {
    .main-navbar-header {
        display: none;
    }
}

.main-navbar-search {
    position: relative;
    padding: 0 20px;
    border-bottom: 1px solid #f0f0f8;
}

.main-navbar-search .form-control {
    border-width: 0;
    padding: 0;
}

.main-navbar-search .form-control:focus {
    box-shadow: none !important;
    border-color: #e1e1ef;
}

.main-navbar-search .btn,
.main-navbar-search .sp-container button {
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    height: 38px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    line-height: 1;
}

@media (min-width: 992px) {
    .main-navbar-search {
        display: none;
    }
}

.sp-container .main-navbar-search button {
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    height: 38px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    line-height: 1;
}

/* ###### 6.7 Navbar Variant  ###### */
.main-navbar-two .nav-item.active {
    position: relative;
}

.main-navbar-two .nav-item.active::before {
    content: "";
    position: absolute;
}

.main-navbar-two .nav-item.show .nav-link::before {
    display: none;
}

.main-navbar-two .nav-link {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
}

.main-navbar-two .nav-sub {
    border-width: 1px;
    top: 35px;
    border-top-width: 0;
}

.main-navbar-two .nav-sub-item>.nav-sub {
    left: 178px;
    top: -8px;
    border-top-width: 1px;
}

.main-navbar-two .nav-sub-mega {
    top: 48px;
}

@media (min-width: 992px) {
    .main-navbar-two .nav-item.active::before {
        top: 33px;
        left: 0;
        right: 12px;
        bottom: auto;
        border-bottom: 2px solid var(--primary);
        z-index: 5;
    }
}

.main-navbar-three .nav-item {
    display: block;
}

.main-navbar-three .nav-item+.nav-item {
    border-left-width: 0;
    padding-left: 0;
}

.main-navbar-three .nav-item.show .nav-link::before {
    display: none;
}

.main-navbar-three .nav-item.active .nav-link {
    color: var(--primary);
    font-weight: 700;
}

.main-navbar-three .nav-link {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    color: #383853;
    position: relative;
}

.main-navbar-three .nav-link:hover,
.main-navbar-three .nav-link:focus {
    color: #323251;
}

.main-navbar-three .nav-sub {
    top: 35px;
    border-width: 0;
    padding-bottom: 5px;
}

.main-navbar-three .nav-sub-item+.nav-sub-item {
    border-top: 0;
}

.main-navbar-three .nav-sub-item .nav-sub {
    top: -8px;
    left: 182px;
}

.main-navbar-three .nav-sub-link {
    height: 30px;
}

@media (min-width: 992px) {
    .main-navbar-three .nav-item+.nav-item {
        margin-left: 25px;
    }
}

@media (min-width: 992px) {
    .main-navbar-three .nav-link {
        display: block;
    }
}

@media (min-width: 992px) {
    .main-navbar-three .nav-link i {
        display: none;
    }
}

@media (min-width: 992px) {
    .main-navbar-three .nav-sub {
        box-shadow: 0 0 0 2px rgba(180, 189, 206, 0.5);
        padding-bottom: 8px;
    }
}

@media (min-width: 992px) {
    .main-navbar-three .nav-sub-link {
        height: 36px;
    }
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.5rem;
}

.navbar>.container,
.navbar>.container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.navbar-brand {
    display: inline-block;
    padding-top: 0.33594rem;
    padding-bottom: 0.33594rem;
    margin-right: 1rem;
    font-size: 1.09375rem;
    line-height: inherit;
    white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
    text-decoration: none;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none;
}

.navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.navbar-toggler {
    padding: 0.4rem 0 0.45rem 0.75rem;
    font-size: 1.09375rem;
    color: #272746;
    position: relative;
    line-height: 35px;
    outline: none;
    height: 37px;
    width: 37px;
    text-align: center;
    border: 0px solid #dfd7e9;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
    text-decoration: none;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.7em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
    color: #7987a1;
}

@media (max-width: 575.98px) {

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-sm .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        flex-wrap: nowrap;
    }

    .navbar-expand-sm .main-header {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-sm .navbar-toggler {
        display: none;
    }
}

@media (max-width: 767.98px) {

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-md .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        flex-wrap: nowrap;
    }

    .navbar-expand-md .main-header {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-md .navbar-toggler {
        display: none;
    }
}

@media (max-width: 991.98px) {

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        flex-wrap: nowrap;
    }

    .navbar-expand-lg .main-header {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}

@media (max-width: 1199.98px) {

    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-xl .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid {
        flex-wrap: nowrap;
    }

    .navbar-expand-xl .main-header {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-xl .navbar-toggler {
        display: none;
    }
}

.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    padding-right: 0;
    padding-left: 0;
}

.navbar-expand .navbar-nav {
    flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    flex-wrap: nowrap;
}

.navbar-expand .main-header {
    display: flex !important;
    flex-basis: auto;
}

.navbar-expand .navbar-toggler {
    display: none;
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='$black-5' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
    color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
    color: #fff;
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='$white-5' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
    color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
    color: #fff;
}

@media print {
    .navbar {
        display: none;
    }
}

.navbar-form.active .input-group-btn {
    position: absolute;
    right: 12px;
    z-index: 9999;
    top: 4.3rem;
}

.navbar-form.nav-item.active .btn.btn-default.nav-link {
    display: none;
}

@media (min-width: 992px) {
    .navbar-form {
        display: none;
    }
}

@media (max-width: 991px) {
    .navbar-form.active .btn.btn-default.nav-link.resp-btn {
        display: none !important;
    }
}

@media (min-width: 768px) {
    .navbar-expand-lg.responsive-navbar .navbar-collapse {
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        background: transparent;
    }
}

#verticallpill .nav-pills .nav-link {
    padding: 0.75rem 0rem 0.75rem 0.75rem;
}

/* ###### 4.9 Perfect Scrollbar ###### */
.ps {
    overflow: hidden;
}

.ps>.ps__rail-y {
    width: 8px;
    background-color: transparent !important;
    z-index: 10;
    position: absolute;
    left: auto !important;
    right: 0;
    opacity: 0;
    transition: opacity 0.2s;
}

.ps>.ps__rail-y>.ps__thumb-y {
    position: absolute;
    border-radius: 0;
    width: 5px;
    left: 4px;
}

.ps.ps--active-y:hover>.ps__rail-y,
.ps.ps--active-y:focus>.ps__rail-y {
    opacity: 1;
}

@media (prefers-reduced-motion: reduce) {
    .ps>.ps__rail-y {
        transition: none;
    }
}

.ps__rail-y:hover>.ps__thumb-y,
.ps__rail-y:focus>.ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
    width: 8px;
}

.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y:focus>.ps__thumb-y,
.ps__rail-y:hover>.ps__thumb-y {
    background-color: rgb(207, 211, 225) !important;
    width: 8px !important;
}

/*
 * Container style
 */
.ps {
    overflow: hidden !important;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto;
}

/*
 * Scrollbar rail styles
 */
.ps__rail-x {
    display: none;
    opacity: 0;
    transition: background-color 0.2s linear, opacity 0.2s linear;
    -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
    height: 2px;
    /* there must be 'bottom' or 'top' for ps__rail-x */
    bottom: 0px;
    /* please don't change 'position' */
    position: absolute;
}

.ps__rail-y {
    display: none;
    opacity: 1;
    transition: background-color 0.2s linear, opacity 0.2s linear;
    -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
    width: 5px;
    /* there must be 'right' or 'left' for ps__rail-y */
    right: 0;
    /* please don't change 'position' */
    position: absolute;
}

.ps--active-x>.ps__rail-x,
.ps--active-y>.ps__rail-y {
    display: block;
    background-color: transparent;
}

.ps:hover>.ps__rail-x,
.ps:hover>.ps__rail-y,
.ps--focus>.ps__rail-x,
.ps--focus>.ps__rail-y,
.ps--scrolling-x>.ps__rail-x,
.ps--scrolling-y>.ps__rail-y {
    opacity: 0.6;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    background-color: #fff;
    opacity: 0.9;
}

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
    background-color: rgba(170, 170, 170, 0.1);
    border-radius: 6px;
    transition: background-color 0.2s linear, height 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
    height: 6px;
    /* there must be 'bottom' for ps__thumb-x */
    bottom: 2px;
    /* please don't change 'position' */
    position: absolute;
}

.ps__thumb-y {
    background-color: rgb(234, 233, 241);
    border-radius: 6px;
    transition: background-color 0.2s linear, width 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
    width: 5px;
    /* there must be 'right' for ps__thumb-y */
    right: 0px !important;
    /* please don't change 'position' */
    position: absolute;
}

.ps__rail-x:hover>.ps__thumb-x,
.ps__rail-x:focus>.ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
    background-color: rgba(153, 153, 153, 0.1);
    height: 11px;
}

.ps__rail-y:hover>.ps__thumb-y,
.ps__rail-y:focus>.ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: rgb(234, 233, 241);
    width: 5px;
}

/* MS supports */
@supports (-ms-overflow-style: none) {
    .ps {
        overflow: auto !important;
    }
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .ps {
        overflow: auto !important;
    }
}

.ps__rail-y:hover>.ps__thumb-y,
.ps__rail-y:focus>.ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: rgb(207, 211, 225);
    width: 8px;
}

.rtl {
    /*
  * Container style
  */
    /*
  * Scrollbar rail styles
  */
    /*
  * Scrollbar thumb styles
  */
    /* MS supports */
}

.rtl .ps {
    overflow: hidden !important;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto;
}

.rtl .ps__rail-x {
    display: none;
    opacity: 0;
    transition: background-color 0.2s linear, opacity 0.2s linear;
    -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
    height: 15px;
    /* there must be 'bottom' or 'top' for ps__rail-x */
    bottom: 0px;
    /* please don't change 'position' */
    position: absolute;
}

.rtl .ps__rail-y {
    display: none;
    opacity: 0;
    transition: background-color 0.2s linear, opacity 0.2s linear;
    -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
    width: 15px;
    /* there must be 'right' or 'left' for ps__rail-y */
    left: 0;
    /* please don't change 'position' */
    position: absolute;
}

.rtl .ps--active-x>.ps__rail-x,
.rtl .ps--active-y>.ps__rail-y {
    display: block;
    background-color: transparent;
}

.rtl .ps:hover>.ps__rail-x,
.rtl .ps:hover>.ps__rail-y,
.rtl .ps--focus>.ps__rail-x,
.rtl .ps--focus>.ps__rail-y,
.rtl .ps--scrolling-x>.ps__rail-x,
.rtl .ps--scrolling-y>.ps__rail-y {
    opacity: 0.6;
}

.rtl .ps .ps__rail-x:hover,
.rtl .ps .ps__rail-y:hover,
.rtl .ps .ps__rail-x:focus,
.rtl .ps .ps__rail-y:focus,
.rtl .ps .ps__rail-x.ps--clicking,
.rtl .ps .ps__rail-y.ps--clicking {
    background-color: transparent;
    opacity: 0.9;
}

.rtl .ps__thumb-x {
    background-color: rgba(67, 87, 133, 0.09);
    border-radius: 6px;
    transition: background-color 0.2s linear, height 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
    height: 6px;
    /* there must be 'bottom' for ps__thumb-x */
    bottom: 2px;
    /* please don't change 'position' */
    position: absolute;
}

.rtl .ps__thumb-y {
    background-color: rgba(67, 87, 133, 0.09);
    border-radius: 6px;
    transition: background-color 0.2s linear, width 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
    width: 3px;
    /* there must be 'right' for ps__thumb-y */
    left: 2px;
    right: 10px !important;
    /* please don't change 'position' */
    position: absolute;
}

.rtl .ps__rail-x:hover>.ps__thumb-x,
.rtl .ps__rail-x:focus>.ps__thumb-x,
.rtl .ps__rail-x.ps--clicking .ps__thumb-x {
    background-color: rgba(67, 87, 133, 0.09);
    height: 11px;
}

.rtl .ps__rail-y:hover>.ps__thumb-y,
.rtl .ps__rail-y:focus>.ps__thumb-y,
.rtl .ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: rgba(67, 87, 133, 0.09);
    width: 3px;
}

@supports (-ms-overflow-style: none) {
    .rtl .ps {
        overflow: auto !important;
    }
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .rtl .ps {
        overflow: auto !important;
    }
}

/* Radio Box */
.rdiobox {
    font-weight: normal;
    position: relative;
    display: block;
    line-height: 18px;
}

.rdiobox span {
    padding-left: 22px;
    /* Ajusta según sea necesario para alinear el texto correctamente */
}

.rdiobox span:before {
    content: "";
    width: 16px;
    height: 16px;
    border: 1px solid var(--neutral-60);
    border-radius: 50%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: var(--neutral-30);
    /* Color de fondo predeterminado del círculo */
}

.rdiobox span:after {
    content: "";
    width: 16px;
    height: 16px;
    border-radius: 50%;
    top: 0;
    left: 0;
    position: absolute;
    display: none;
    /* Inicialmente oculto */
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
    background-size: 50%;
    /* Ajusta para que el icono se ajuste bien dentro del círculo */
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--secondary);
    /* Este será el color de fondo cuando esté seleccionado */
}

.rdiobox input[type=radio] {
    opacity: 0;
    margin: 0;
    position: absolute;
    cursor: pointer;
}

.rdiobox input[type=radio][disabled]+span:before {
    background-color: var(--primary-10);
}

.rdiobox input[type=radio][disabled]+span:after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2351BFE7' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
    background-color: var(--secondary-25);
}

.rdiobox input[type=radio]:checked+span:after {
    display: block;
    /* Muestra el :after con el icono cuando está seleccionado */
}

/*-- rating--*/
.rating-stars {
    width: 100%;
    text-align: center;
}

.rating-stars i {
    padding: 5px;
}

.rating-stars .rating-stars-container {
    font-size: 0px;
}

.rating-stars .rating-stars-container .rating-star {
    display: inline-block;
    font-size: 26px;
    cursor: pointer;
    padding: 4px 8px;
    color: var(--primary02);
}

.rating-stars .rating-stars-container .rating-star.is--active .fa-heart,
.rating-stars .rating-stars-container .rating-star.is--hover .fa-heart {
    color: var(--secondary);
}

.rating-stars .rating-stars-container .rating-star.sm {
    display: inline-block;
    font-size: 14px;
    color: #eaedf1;
    cursor: pointer;
    padding: 5px;
}

.rating-stars .rating-stars-container .rating-star.is--active,
.rating-stars .rating-stars-container .rating-star.is--hover {
    color: #ffc102;
}

.rating-stars .rating-stars-container .rating-star.is--no-hover,
.rating-stars .rating-stars-container .rating-star .fa-heart .is--no-hover {
    color: #ededf5;
}

.rating-stars .rating-stars-container .rating-star.is--active,
.rating-stars .rating-stars-container .rating-star.is--hover {
    color: #ffc102;
}

/*-----Br Themes-----*/
.br-theme-bars-horizontal .br-widget a {
    background-color: var(--primary02);
}

.br-theme-bars-horizontal .br-widget a.br-active,
.br-theme-bars-horizontal .br-widget a.br-selected {
    background-color: var(--primary);
}

.br-theme-bars-horizontal .br-widget .br-current-rating {
    color: var(--primary);
}

.br-theme-bars-pill .br-widget a {
    background-color: var(--primary02);
    color: var(--primary);
}

.br-theme-bars-pill .br-widget a.br-active,
.br-theme-bars-pill .br-widget a.br-selected {
    background-color: var(--primary);
    color: white;
}

.br-theme-bars-square .br-widget a {
    border: 2px solid var(--primary02);
    background-color: #fff;
    color: #334151;
}

.br-theme-bars-square .br-widget a.br-active,
.br-theme-bars-square .br-widget a.br-selected {
    border: 2px solid var(--primary02);
    color: var(--primary);
}

.br-theme-bars-movie .br-widget a {
    background-color: var(--primary02);
}

.br-theme-bars-movie .br-widget a.br-active,
.br-theme-bars-movie .br-widget a.br-selected {
    background-color: var(--primary);
}

.br-theme-bars-movie .br-widget .br-current-rating {
    color: var(--primary);
}

.br-theme-bars-1to10 .br-widget a {
    background-color: var(--primary02);
}

.br-theme-bars-1to10 .br-widget a.br-active,
.br-theme-bars-1to10 .br-widget a.br-selected {
    background-color: var(--primary);
}

.br-theme-bars-1to10 .br-widget .br-current-rating {
    color: var(--primary);
}

.br-theme-fontawesome-stars .br-widget a.br-active:after,
.br-theme-fontawesome-stars .br-widget a.br-selected:after {
    color: var(--primary);
}

/*-----Br Themes-----*/
.rating-stars input {
    display: block;
    margin: 0 auto;
    text-align: center;
    padding: 0.375rem 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.6;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ededf5;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.rating-table.table th,
.rating-table.table td {
    padding: 7px 6px !important;
    line-height: 1.462;
}

.rating-table tr {
    padding: 10px 0;
}

/*---- Tags-----*/
.tag {
    font-size: 0.75rem;
    color: #8189b6;
    border: 1px solid #ededf5;
    border-radius: 3px;
    padding: 2px 0.55rem;
    line-height: 2em;
    display: -ms-inline-flexbox;
    display: inline-flex;
    cursor: default;
    font-weight: 400;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

a.tag {
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s color, 0.3s background;
}

a.tag:hover {
    background-color: rgba(110, 118, 135, 0.2);
    color: inherit;
}

.tag-addon {
    display: inline-block;
    padding: 0 0.5rem;
    color: inherit;
    text-decoration: none;
    background: #f9f9fb;
    margin: 0 -0.4rem 0 0.5rem;
    text-align: center;
    min-width: 1.5rem;
}

.tag-addon:last-child {
    border-radius: 3px;
}

.tag-addon i {
    vertical-align: middle;
    margin: 0 -0.25rem;
    line-height: 1.5px;
    font-size: 13px;
}

.tag-addon span {
    vertical-align: middle;
    margin: 0 -0.25rem;
    line-height: 1.5px;
    font-size: 13px;
}

a.tag-addon {
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s color, 0.3s background;
}

a.tag-addon:hover {
    background: rgb(238, 238, 255);
    color: inherit;
}

.tag-avatar {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 3px 0 0 3px;
    margin: 0 0.5rem 0 -0.5rem;
}

.tag-blue {
    background-color: #467fcf !important;
    color: #fff;
}

.tag-indigo {
    background-color: #5b67c7 !important;
    color: #fff;
}

.tag-purple {
    background-color: #ad4ffa !important;
    color: #fff;
}

.tag-pink {
    background-color: #bc22be !important;
    color: #fff;
}

.tag-red {
    background-color: #f34343 !important;
    color: #fff;
}

.tag-orange {
    background-color: #fd7e14 !important;
    color: #fff;
}

.tag-yellow {
    background-color: #fdb901 !important;
    color: #fff;
}

.tag-green {
    background-color: #0fa751 !important;
    color: #fff;
}

.tag-teal {
    background-color: #29ddee !important;
    color: #fff;
}

.tag-cyan {
    background-color: var(--info) !important;
    color: #fff;
}

.tag-white {
    background-color: #fff !important;
    color: #fff;
}

.tag-gray {
    background-color: #868e96 !important;
    color: #fff;
}

.tag-gray-dark {
    background-color: #343a40 !important;
    color: #fff;
}

.tag-azure {
    background-color: #17c1f4 !important;
    color: #fff;
}

.tag-lime {
    background-color: #7bd235 !important;
    color: #fff;
}

.tag-primary {
    background-color: #467fcf !important;
    color: #fff;
}

.tag-secondary {
    background-color: #868e96 !important;
    color: #fff;
}

.tag-success {
    background-color: #0fa751 !important;
    color: #fff;
}

.tag-info {
    background-color: #17c1f4 !important;
    color: #fff;
}

.tag-warning {
    background-color: #fdb901 !important;
    color: #fff;
}

.tag-danger {
    background-color: #ec2d38 !important;
    color: #fff;
}

.tag-light {
    background-color: #f8f9fa !important;
    color: #fff;
}

.tag-dark {
    background-color: #343a40 !important;
    color: #fff;
}

.tag-rounded {
    border-radius: 50px;
}

.tag-rounded .tag-avatar {
    border-radius: 50px;
}

.tags {
    margin-bottom: -0.5rem;
    font-size: 0;
}

.tags>.tag {
    margin-bottom: 0.5rem;
}

.tags>.tag:not(:last-child) {
    margin-right: 0.2rem;
}

@media (min-width: 459px) {
    .text-wrap .example .form-group {
        margin-bottom: 0;
    }
}

.tag-primary {
    background-color: var(--primary) !important;
    color: #fff;
}

.tag-secondary {
    background-color: var(--secondary) !important;
    color: #fff;
}

.tag-success {
    background-color: var(--success) !important;
    color: #fff;
}

.tag-danger {
    background-color: #f34343 !important;
    color: #fff;
}

.tag-warning {
    background-color: var(--warning) !important;
    color: #fff;
}

.tag-info {
    background-color: var(--info) !important;
    color: #fff;
}

.tag-teal {
    background-color: #29ddee !important;
    color: #fff;
}

.simple-tag {
    padding: 8px 16px;
    display: -ms-inline-flexbox;
    display: inline-flex;
    border-radius: 4px;
}

.simple-tag.pending {
    background-color: var(--primary-10);
    color: var(--neutral-80);
}

.simple-tag.progress {
    background-color: var(--info-10);
    color: var(--info);
}

.simple-tag.delay {
    background-color: var(--error-10);
    color: var(--error);
}

.simple-tag.finish {
    background-color: var(--success-10);
    color: var(--success);
}

.simple-tag.warning {
    background-color: #FED63A;
    color: #F2C103;
}

/* Timeline */
/*--- Vertical --*/
.vtimeline {
    list-style: none;
    padding: 0;
    position: relative;
    margin-bottom: 20px;
}

.vtimeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: #ededf5;
    left: 50%;
    margin-left: -1.5px;
}

.vtimeline .timeline-wrapper {
    display: block;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    padding-right: 90px;
}

.vtimeline .timeline-wrapper:before {
    content: " ";
    display: table;
}

.vtimeline .timeline-wrapper:after {
    content: " ";
    display: table;
    clear: both;
}

.vtimeline .timeline-wrapper .timeline-panel {
    border-radius: 2px;
    padding: 20px;
    position: relative;
    background: #f9f9fb;
    border-radius: 0px;
    box-shadow: none;
    width: 35%;
    margin-left: 15%;
}

.vtimeline .timeline-wrapper .timeline-panel:before {
    position: absolute;
    top: 0;
    width: 100%;
    height: 2px;
    content: "";
    left: 0;
    right: 0;
}

.vtimeline .timeline-wrapper .timeline-panel:after {
    position: absolute;
    top: 25px;
    right: -14px;
    display: inline-block;
    border-top: 14px solid transparent;
    border-left: 14px solid #f9f9fb;
    border-right: 0 solid #f9f9fb;
    border-bottom: 14px solid transparent;
    content: " ";
}

.vtimeline .timeline-wrapper .timeline-panel .timeline-title {
    font-size: 1rem;
    font-weight: 700;
    margin: 0 0 0.625rem;
}

.vtimeline .timeline-wrapper .timeline-panel .timeline-body p+p {
    margin-top: 5px;
}

.timeline-body img {
    max-width: 100%;
}

.vtimeline .timeline-wrapper .timeline-panel .timeline-body ul {
    margin-bottom: 0;
}

.vtimeline .timeline-wrapper .timeline-panel .timeline-footer span {
    font-size: 0.6875rem;
}

.vtimeline .timeline-wrapper .timeline-badge {
    width: 45px;
    height: 45px;
    position: absolute;
    top: 16px;
    left: calc(50% - 20px);
    z-index: 0;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    border: 0px solid #fff;
    border-radius: 50%;
}

.vtimeline .timeline-wrapper .timeline-badge i {
    color: #fff;
    text-align: center;
    font-size: 25px;
    line-height: 1.75;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    left: 0;
    right: 0;
    padding: 1px 9px;
}

.vtimeline .timeline-wrapper.timeline-inverted {
    padding-right: 0;
    padding-left: 90px;
}

.vtimeline .timeline-wrapper.timeline-inverted .timeline-panel {
    margin-left: auto;
    margin-right: 15%;
}

.vtimeline .timeline-wrapper.timeline-inverted .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
}

.timeline-wrapper-primary .timeline-panel:before,
.timeline-wrapper-primary .timeline-badge {
    background: var(--primary);
}

.timeline-wrapper-secondary .timeline-panel:before,
.timeline-wrapper-secondary .timeline-badge {
    background: #eb6f33;
}

.timeline-wrapper-success .timeline-panel:before,
.timeline-wrapper-success .timeline-badge {
    background: var(--success);
}

.timeline-wrapper-info .timeline-panel:before,
.timeline-wrapper-info .timeline-badge {
    background: #01b8ff;
}

.timeline-wrapper-warning .timeline-panel:before,
.timeline-wrapper-warning .timeline-badge {
    background: var(--warning);
}

.timeline-wrapper-danger .timeline-panel:before,
.timeline-wrapper-danger .timeline-badge {
    background: #f34343;
}

.timeline-wrapper-light .timeline-panel:before,
.timeline-wrapper-light .timeline-badge {
    background: #f1f2f9;
}

.timeline-wrapper-dark .timeline-panel:before,
.timeline-wrapper-dark .timeline-badge {
    background: #323251;
}

@media (max-width: 992px) {
    .timeline .btn {
        font-size: 12px;
    }

    .vtimeline:before {
        left: 26px !important;
    }

    .vtimeline .timeline-wrapper {
        padding-right: 0 !important;
    }

    .vtimeline .timeline-wrapper .timeline-badge {
        left: 0 !important;
    }

    .vtimeline .timeline-wrapper .timeline-panel {
        width: auto !important;
        margin-left: 16% !important;
    }

    .vtimeline .timeline-wrapper .timeline-panel:after {
        border-right: 14px solid #f9f9fb !important;
        border-left: 0 solid #f9f9fb !important;
        left: -14px !important;
        right: auto !important;
        top: 25px;
    }

    .vtimeline .timeline-wrapper.timeline-inverted {
        padding-left: 0 !important;
    }

    .vtimeline .timeline-wrapper.timeline-inverted .timeline-panel {
        margin-right: 0 !important;
        width: auto !important;
    }

    .profile-user .p-text {
        display: none;
    }
}

/*latetes news timeline */
.latest-timeline-1 ul.timeline-1 {
    list-style-type: none;
    position: relative;
}

.latest-timeline-1 ul.timeline-1:before {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20px;
    border-left: 2px dotted #c0ccda;
}

.latest-timeline-1 ul.timeline-1>li {
    margin: 25px 0;
    padding-left: 0.9rem;
}

.latest-timeline-1 ul.timeline-1>li a {
    color: #000;
}

.latest-timeline-1 ul.timeline-1>li .date {
    font-size: 10px;
}

.latest-timeline-1 ul.timeline-1>li p {
    font-size: 13px;
}

.latest-timeline-1 ul.timeline-1>li .product-icon {
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    left: 1px;
    background-color: #f3f6f7;
    font-size: 20px;
    line-height: 37px;
    font-size: 21px;
}

.latest-timeline-1 ul.timeline-1>li:nth-child(2):before {
    border: 3px solid #fb1c52;
}

.latest-timeline-1 ul.timeline-1>li:nth-child(3):before {
    border: 3px solid #e9b307;
}

.latest-timeline-1 ul.timeline-1>li:nth-child(4):before {
    border: 3px solid #4481eb;
}

.latest-timeline-1 ul.timeline-1>li:last-child:before {
    border: 3px solid #27af06;
}

.projects-stat .table-bordered th,
.projects-stat .table-bordered td {
    border: transparent;
}

.projects-stat .table th,
.projects-stat .table td {
    padding: 7px 7px !important;
    vertical-align: baseline;
}

.projects-stat .project-names h6 {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    color: #fff;
    line-height: 2.5;
    margin-bottom: 0;
    font-weight: 600;
    padding: 3px;
}

.review-project .project-contain {
    display: inline-block;
}

.review-project .table th,
.review-project .table td {
    vertical-align: middle;
    padding: 7px 15px;
}

.review-project.card tbody>tr>th:not(:first-child),
.review-project.card tbody>tr>td:not(:first-child) {
    font-family: roboto;
    font-size: 13px !important;
}

.review-project img {
    vertical-align: top;
    width: 35px;
    min-width: 32px;
    height: 35px;
}

.latest-tasks .tasks {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    margin: 0 0 1.15rem 0;
}

.latest-tasks .tasks .span {
    margin-left: 1.3rem;
    font-weight: 500;
    color: #323251;
}

.latest-tasks .task-line:before {
    position: absolute;
    display: block;
    width: 0.2rem;
    top: 0.2rem;
    content: "";
    border-radius: 0.2rem;
    height: 2rem;
}

.latest-tasks .label {
    color: #4a4a69;
    margin-left: 1.3rem;
    font-weight: 500;
}

.latest-tasks .time {
    margin-left: 1.3rem;
    font-size: 13px;
    color: #7987a1;
}

.latest-tasks .task-line.primary:before {
    background: var(--primary);
}

.latest-tasks .task-line.pink:before {
    background: #f754fa;
}

.latest-tasks .task-line.success:before {
    background: var(--success);
}

.latest-tasks .task-line.teal:before {
    background: #29ddee;
}

.latest-tasks .task-line.warning:before {
    background: var(--warning);
}

.latest-tasks .task-line.orange:before {
    background: #fd7e14;
}

.latest-tasks .task-line.info:before {
    background: var(--info);
}

.latest-tasks .task-line.purple:before {
    background: #ad4ffa;
}

.latest-tasks .task-line.danger:before {
    background: #f34343;
}

.latest-tasks .check-box .ckbox span:before {
    content: "";
    width: 16px;
    height: 16px;
    background-color: rgba(190, 206, 255, 0.05);
    border: 1px solid #d9e2ff;
    top: 1px;
    left: 0;
    border-radius: 2px;
}

.latest-tasks .check-box .ckbox span:after {
    border-radius: 2px;
}

.latest-tasks .nav-tabs .nav-link {
    color: #7987a1;
}

.latest-tasks .nav-tabs .nav-link.active,
.latest-tasks .nav-tabs .nav-link:hover,
.latest-tasks .nav-tabs .nav-link:focus {
    color: var(--primary);
    border: 0;
}

.image-grouped img {
    margin-right: -0.6em !important;
}

.background-text {
    position: relative;
    z-index: 10;
}

.project-countdown #launch_date {
    margin: 0;
}

.project-countdown .countdown {
    padding: 0;
    margin: 0;
}

.project-countdown .countdown li {
    width: 65px;
    height: 65px;
    z-index: 0;
    padding: 0px;
    line-height: 30px;
}

.project-countdown .countdown .number {
    font-size: 20px;
    font-weight: 700;
    line-height: 14px;
    padding-top: 11px;
}

.project-countdown .countdown .time {
    padding-top: 0px;
    font-size: 11px !important;
}

.project-countdown .countdown span {
    display: block;
    color: #fff;
}

.project-countdown .countdown li {
    background: rgb(40, 92, 247);
    border: 5px solid rgb(40, 92, 247);
}

@media (min-width: 992px) {
    .project-countdown .countdown li {
        margin: 2px 5px 7px 5px;
    }
}

.Activity-scroll {
    max-height: 349px;
}

.activity .added-project {
    font-weight: 700;
    font-size: 12px;
    color: var(--primary);
}

.sparkline .tooltip {
    width: 20px;
    height: 20px;
}

.Project-scroll {
    max-height: 367px;
    position: relative;
}

.rating-scroll {
    max-height: 344px;
    position: relative;
}

.coming-events .icon {
    height: 47px;
    width: 47px;
    border-radius: 50%;
    text-align: center;
}

.coming-events .icon span {
    display: block;
    font-size: 12px;
}

.coming-events .icon .date {
    line-height: 10px;
    padding-top: 11px;
}

.coming-events .icon .month {
    font-size: 11px;
}

.coming-events .pb-3 {
    padding-bottom: 0.8rem !important;
}

.coming-events .pt-3 {
    padding-top: 0.8rem !important;
}

.activity {
    position: relative;
    padding: 1.25rem 1.25rem 0 1.25rem;
}

.activity-list {
    position: relative;
}

.activity-list:before {
    content: " ";
    border: 1px solid #eaf2f9;
    margin: 20px 20px 0 22px;
    display: inline-block;
    position: absolute;
    width: 2px;
    height: 100%;
    z-index: 0;
}

.activity .img-activity {
    width: 42px;
    height: 42px;
    text-align: center;
    line-height: 34px;
    border-radius: 50%;
    position: absolute;
    -webkit-box-shadow: 0 0 0 0.5px #f8f9fa;
    box-shadow: 0 0 0 0.5px #f8f9fa;
}

.activity .item-activity {
    margin-left: 60px;
    margin-bottom: 22px;
}

.timeline-image {
    border-radius: 50%;
}

@media (max-width: 576px) {
    .vtimeline .timeline-wrapper .timeline-panel {
        width: auto !important;
        margin-left: 24% !important;
    }
}

.main-toggle {
    width: 60px;
    height: 25px;
    background-color: #d6d6e6;
    padding: 2px;
    position: relative;
    overflow: hidden;
}

.main-toggle span {
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    display: block;
    width: 20px;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.main-toggle span::before,
.main-toggle span::after {
    position: absolute;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #fff;
    top: 2px;
    line-height: 1.38;
}

.main-toggle span::before {
    content: "on";
    left: -25px;
}

.main-toggle span::after {
    content: "off";
    right: -29px;
}

.main-toggle.on {
    background-color: var(--primary) !important;
}

.main-toggle.on span {
    left: 37px;
}

@media (prefers-reduced-motion: reduce) {
    .main-toggle span {
        transition: none;
    }
}

.main-toggle-secondary.on {
    background-color: var(--primary) !important;
}

.main-toggle-success.on {
    background-color: var(--success) !important;
}

.main-toggle-dark.on {
    background-color: #323251 !important;
}

.main-toggle-group-demo {
    display: flex;
}

.main-toggle-group-demo .main-toggle+.main-toggle {
    margin-left: 10px;
}

.vikingo-toggle input[type=checkbox] {
    height: 0;
    width: 0;
    display: none;
}

.vikingo-toggle label {
    cursor: pointer;
}

.vikingo-toggle label:first-of-type {
    height: 16px;
    width: 40px;
    min-width: 40px;
    background: var(--neutral-60);
    display: block;
    border-radius: 50em;
    position: relative;
    transition: 0.4s;
    box-sizing: border-box;
    margin: 0;
}

.vikingo-toggle label:first-of-type::after {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    top: -4px;
    background: #fff;
    border-radius: 50em;
    transition: 0.4s;
    box-shadow: 0px 0px 2px 0px #000000;
}

.vikingo-toggle input:active+label:first-of-type {
    background: var(--neutral-60);
}

.vikingo-toggle input:checked+label:first-of-type {
    background: var(--secondary-25);
}

.vikingo-toggle input:checked+label:first-of-type::after {
    transform: translateX(100%);
    background: var(--secondary);
}

:root {
    /* COLOR PALETTE - PRIMARY */
    --primary: #001E6D;
    --primary-75: #425490;
    --primary-50: #818EB5;
    --primary-25: #BFC6DA;
    --primary-10: #E6E8F0;
    --primary-darker: #001149;
    --primary-transparent: rgba(0, 26, 114, 0.2);
    /* COLOR PALETTE - SECONDARY */
    --secondary: #51BFE7;
    --secondary-75: #8ECEEA;
    --secondary-50: #B2DDF1;
    --secondary-25: #D7EEF8;
    --secondary-10: #EFF8FC;
    --secondary-darker: #3C8FB2;
    /* COLOR PALETTE - NEUTRALS */
    --neutral-text: #4A4C58;
    --neutral-90: #6A6C78;
    --neutral-80: #7E808D;
    --neutral-70: #A8AAB8;
    --neutral-60: #C6C8D6;
    --neutral-50: #E8EAF9;
    --neutral-40: #EFF1FE;
    --neutral-30: #F4F6FE;
    --neutral-20: #F9FBFF;
    --neutral-darker: #282A35;
    /* COLOR PALETTE - SEMANTICS */
    /* SUCCESS */
    --success: #06A77D;
    --success-75: #67BA9F;
    --success-50: #94D0BE;
    --success-25: #C8E7DE;
    --success-10: #EAF6F2;
    --success-darker: #058261;
    /* ERROR */
    --error: #E83800;
    --error-75: #EE6A40;
    --error-50: #F39B7F;
    --error-25: #F9CDBF;
    --error-10: #FDECE6;
    --error-darker: #B32E04;
    /* WARNING */
    --warning: #FED63A;
    --warning-75: #FBE07D;
    --warning-50: #FBE9A5;
    --warning-25: #FEF4D1;
    --warning-10: #FEFBED;
    --warning-darker: #F2C103;
    /* INFO */
    --info: #5E7FCD;
    --info-75: #869FD9;
    --info-50: #ADBDE4;
    --info-25: #D7DEF2;
    --info-10: #F0F2FA;
    --info-darker: #2D4786;
    --primary-bg-color: var(--primary);
    --primary-bg-hover: var(--primary-75);
    --primary-bg-border: var(--primary);
    --primary02: var(--primary-50);
    --primary05: var(--primary-25);
    --primary-transparentcolor: rgba(28, 213, 174, 0.2);
    --transparentprimary-transparentcolor: rgba(255, 255, 255, 0.05);
    --transparent-body: var(--primary);
    --transparent-theme: rgba(0, 0, 0, 0.1);
    --blue: var(--primary);
    --indigo: var(--primary);
    --purple: #ad4ffa;
    --pink: #f754fa;
    --red: #f34343;
    --orange: #fd7e14;
    --yellow: #ffbd5a;
    --green: #016a59;
    --teal: #29ddee;
    --cyan: #4ec2f0;
    --white: #fff;
    --gray: #7987a1;
    --gray-dark: #383853;
    --danger: #f34343;
    --light: #f9f9fb;
    --dark: #383853;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

* {
    box-sizing: border-box;
}

*::before,
*::after {
    box-sizing: border-box;
}

#global-loader {
    position: fixed;
    z-index: 50000;
    background: #fff;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    text-align: center;
}

.loader-img {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    transform: translateY(-50%);
}

.fade {
    transition: opacity 0.15s linear;
}

.fade:not(.show) {
    opacity: 0;
}

@media (prefers-reduced-motion: reduce) {
    .fade {
        transition: none;
    }
}

.collapse:not(.show) {
    display: none;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
    .collapsing {
        transition: none;
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }

    to {
        background-position: 0 0;
    }
}

.media {
    display: flex;
    align-items: center;
}

.media-body {
    flex: 1;
}

.close {
    font-size: 1.3125rem;
    font-weight: 400;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
    float: right;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
    opacity: 0.75;
}

@media (max-width: 767px) {
    .close {
        font-size: 1.3125rem;
        font-weight: 400;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        opacity: 0.5;
        position: absolute;
        right: 10px;
    }
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    appearance: none;
    color: inherit;
}

a.close.disabled {
    pointer-events: none;
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg);
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        opacity: 0;
    }

    50% {
        -webkit-transform: scale(0.2);
        opacity: 1;
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.embed-responsive::before {
    display: block;
    content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.embed-responsive-21by9::before {
    padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.embed-responsive-4by3::before {
    padding-top: 75%;
}

.embed-responsive-1by1::before {
    padding-top: 100%;
}

.overflow-auto {
    overflow: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}

@supports (position: sticky) {
    .sticky-top {
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
}

.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09) !important;
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
    box-shadow: none !important;
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
}

@media print {
    * {
        text-shadow: none !important;
        box-shadow: none !important;
    }

    *::before,
    *::after {
        text-shadow: none !important;
        box-shadow: none !important;
    }

    a:not(.btn) {
        text-decoration: underline;
    }

    abbr[title]::after {
        content: " ('attr(title) ')";
    }

    pre {
        white-space: pre-wrap !important;
        border: 1px solid #949eb7;
        page-break-inside: avoid;
    }

    blockquote {
        border: 1px solid #949eb7;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    @page {
        size: a3;
    }

    body,
    .container {
        min-width: 992px !important;
    }
}

/* ########## BOOTSTRAP OVERRIDES ########## */
/* ############### PAGE LAYOUT STYLES ############### */
/* ###### 6.3 Global  ###### */
body {
    font-family: "Poppins", sans-serif;
}

.main-body,
.main-dashboard {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
}

.main-body::before {
    content: "main";
    position: fixed;
    top: 45%;
    left: 70%;
    transform: translate3d(-50%, -58%, 0);
    font-size: 1300px;
    font-weight: 600;
    letter-spacing: -10px;
    line-height: 0.5;
    opacity: 0.02;
    z-index: -1;
    display: none;
}

.main-table-reference {
    background-color: #fff;
    width: 100% !important;
    margin-bottom: 16px;
}

.main-table-reference>thead>tr>th,
.main-table-reference>thead>tr>td {
    padding: 8px 10px;
    border: 1px solid #ededf5;
    font-size: 13px;
}

.main-table-reference>tbody>tr>th,
.main-table-reference>tbody>tr>td {
    padding: 8px 10px;
    border: 1px solid #ededf5;
    font-size: 13px;
}

.main-table-reference>thead>tr>th,
.main-table-reference>thead>tr>td {
    background-color: #f9f9fb;
    color: #7987a1;
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 0;
}

/* ############### TEMPLATE STYLES ############### */
/* ###### 7.1 Demo  ###### */
.main-home-slider {
    position: relative;
    z-index: 4;
    width: 100%;
    height: 600px;
    max-height: 1800px;
    overflow: hidden;
    background-color: #1904be;
}

.main-home-slider .chart-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -20px;
    z-index: 3;
    display: flex;
    align-items: flex-end;
    opacity: 0.2;
}

.main-home-slider .chart-wrapper .flot-chart {
    width: 100%;
    height: 400px;
}

.main-home-slider .card-columns {
    column-gap: 5px;
    column-count: 3;
    width: 500px;
    transform: rotate(37deg);
    position: absolute;
    top: 9%;
    left: 24%;
    z-index: 4;
    opacity: 0.25;
}

.main-home-slider .card-columns .card {
    position: relative;
    border-width: 0;
    background-color: transparent;
    margin-bottom: 5px;
}

@media (min-width: 576px) {
    .main-home-slider {
        height: 600px;
    }
}

@media (min-width: 1200px) {
    .main-home-slider {
        height: 100vh;
        max-height: 780px;
    }
}

@media (min-width: 1440px) {
    .main-home-slider {
        max-height: 900px;
    }
}

@media (min-width: 1680px) {
    .main-home-slider {
        max-height: 1050px;
    }
}

@media (min-width: 1920px) {
    .main-home-slider {
        max-height: 1200px;
    }
}

@media (min-width: 2560px) {
    .main-home-slider {
        max-height: 1600px;
    }
}

@media (min-width: 2880px) {
    .main-home-slider {
        max-height: 1800px;
    }
}

@media (min-width: 768px) {
    .main-home-slider .chart-wrapper {
        opacity: 1;
    }
}

@media (min-width: 992px) {
    .main-home-slider .chart-wrapper .flot-chart {
        height: 500px;
    }

    .userlist-table .table {
        white-space: nowrap !important;
    }

    .nav-link#bs-example-navbar-collapse-1 {
        display: none;
    }
}

@media (min-width: 576px) {
    .main-home-slider .card-columns {
        width: 120%;
        left: 16%;
        transform: rotate(44deg);
    }
}

@media (min-width: 768px) {
    .main-home-slider .card-columns {
        transform: rotate(45deg);
        width: 100%;
        top: 0;
        left: auto;
        right: -45%;
        opacity: 1;
    }
}

@media (min-width: 992px) {
    .main-home-slider .card-columns {
        width: 80%;
        top: -10%;
        right: -30%;
        column-gap: 10px;
    }
}

@media (min-width: 1200px) {
    .main-home-slider .card-columns {
        width: 70%;
        right: -15%;
        top: -15%;
    }
}

@media (min-width: 1300px) {
    .main-home-slider .card-columns {
        right: -5%;
    }
}

@media (min-width: 992px) {
    .main-home-slider .card-columns .card {
        margin-bottom: 10px;
    }
}

.main-home-content {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 5;
    color: rgba(255, 255, 255, 0.7);
    right: 20px;
}

.main-home-content .main-logo {
    font-size: 30px;
    font-weight: 700;
    display: flex;
    line-height: 1;
    color: #fff;
    margin-bottom: 5px;
    text-indent: -1px;
    color: #fff;
    letter-spacing: -0.5px;
}

.main-home-content h5 {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.2px;
    margin-bottom: 25px;
}

.main-home-content .logo-group {
    margin-bottom: 60px;
    display: flex;
}

.main-home-content .logo-group i {
    font-weight: 400;
    font-size: 32px;
    line-height: 0.2;
}

.main-home-content .logo-group i+i {
    margin-left: 10px;
}

.main-home-content h1 {
    color: #fff;
    font-weight: 300;
    font-size: 24px;
    margin-bottom: 20px;
}

.main-home-content p:first-of-type {
    font-size: 14px;
    line-height: 1.7;
    margin-bottom: 25px;
}

.main-home-content p:last-of-type {
    display: flex;
    margin-bottom: 100px;
}

.main-home-content p:last-of-type .btn,
.main-home-content p:last-of-type .sp-container button {
    flex: 1;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    min-height: inherit;
    letter-spacing: 1px;
    padding: 10px 15px;
}

@media (min-width: 576px) {
    .main-home-content {
        top: 40px;
        left: 40px;
        right: auto;
        width: 400px;
    }
}

@media (min-width: 768px) {
    .main-home-content {
        width: 410px;
    }
}

@media (min-width: 576px) {
    .main-home-content .main-logo {
        font-size: 36px;
        letter-spacing: -1px;
    }
}

@media (min-width: 576px) {
    .main-home-content h5 {
        font-size: 15px;
    }
}

@media (min-width: 576px) {
    .main-home-content h1 {
        font-size: 2.03125rem;
    }
}

.sp-container .main-home-content p:last-of-type button {
    flex: 1;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    min-height: inherit;
    letter-spacing: 1px;
    padding: 10px 15px;
}

@media (min-width: 768px) {

    .main-home-content p:last-of-type .btn,
    .main-home-content p:last-of-type .sp-container button {
        padding: 10px 25px;
        flex: none;
    }

    .sp-container .main-home-content p:last-of-type button {
        padding: 10px 25px;
        flex: none;
    }
}

@media (min-width: 992px) {

    .main-home-content p:last-of-type .btn,
    .main-home-content p:last-of-type .sp-container button {
        flex: 1;
    }

    .sp-container .main-home-content p:last-of-type button {
        flex: 1;
    }
}

.main-home-content p:last-of-type .btn+.btn,
.main-home-content p:last-of-type .sp-container button+.btn {
    margin-left: 5px;
}

.sp-container .main-home-content p:last-of-type button+.btn,
.main-home-content p:last-of-type .sp-container .btn+button,
.sp-container .main-home-content p:last-of-type .btn+button,
.main-home-content p:last-of-type .sp-container button+button,
.sp-container .main-home-content p:last-of-type button+button {
    margin-left: 5px;
}

.main-home-content nav:last-child {
    display: flex;
}

.main-home-content nav:last-child a {
    font-size: 24px;
    color: rgba(255, 255, 255, 0.5);
}

.main-home-content nav:last-child a:hover,
.main-home-content nav:last-child a:focus {
    color: #fff;
}

.main-home-content nav:last-child a+a {
    margin-left: 8px;
}

.main-icon-group {
    text-align: center;
    padding: 20px;
    color: #383853;
}

.font-awesome.main-icon-group i {
    font-size: 14px;
}

.main-icon-group i {
    line-height: 3;
    font-size: 24px;
    font-size: 18px;
}

.main-page-header {
    position: relative;
    padding: 30px 0 40px;
    border-bottom: 1px solid #dee2ef;
    margin-bottom: 40px;
}

.main-page-title {
    font-weight: 700;
    font-size: 40px;
    font-family: "Raleway", sans-serif;
    margin-bottom: 15px;
    line-height: 1;
    letter-spacing: -1.5px;
}

.main-page-text {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 30px;
}

.main-page-content-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.brround {
    border-radius: 50%;
}

.main-page-content-list li {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
}

.main-page-content-list li+li {
    margin-top: 5px;
}

.main-page-content-list li i {
    margin-right: 10px;
    font-size: 10px;
    line-height: 0;
    color: #949eb7;
    text-indent: 1px;
}

.main-page-label {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
    letter-spacing: -0.2px;
}

.main-syntax-wrapper {
    position: relative;
}

.main-syntax-wrapper .badge {
    padding: 4px 5px;
    position: absolute;
    top: 5px;
    right: 5px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 9px;
    letter-spacing: normal;
    border-radius: 1px;
}

.main-syntax-wrapper pre {
    border-color: #e1e1ef;
    background-color: #fff;
    padding: 15px;
    font-size: 13px;
}

.main-syntax-wrapper code[class*=language-],
.main-syntax-wrapper pre[class*=language-] {
    font-family: "Roboto Mono", monospace;
}

/* ############### UTILITIES/HELPER CLASSES ############### */
.horizontal-nav .mega-dropdown-menu {
    box-shadow: 0 25px 100px rgba(115, 77, 191, 0.2);
    border: 0;
    margin: -2px;
    width: 100%;
}

.horizontal-nav .mega-dropdown-menu:before {
    content: "";
    position: absolute;
    top: -10px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
    right: auto;
    left: 16px;
}

.mega-dropdown-menu:after {
    right: auto;
    left: 16px;
}

.horizontal-nav .dropdown-menu {
    min-width: 12.5rem;
}

.drop-icon-wrap .drop-icon-item {
    display: inline-block;
    padding-bottom: 10px;
    padding-top: 10px;
    text-align: center;
    color: #020202;
    text-transform: capitalize;
    width: 33.3%;
    float: left;
}

.h-6 {
    height: 2rem !important;
}

.dropgroupicons .dropdown-menu {
    width: 260px !important;
    padding: 5px !important;
    top: 52px !important;
    border-radius: 6px;
    -webkit-box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
    box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
}

.flag .dropdown-menu {
    top: 52px !important;
    border-radius: 6px;
    -webkit-box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
    box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
}

.flag>a::before {
    content: "";
    position: absolute;
    top: 47px;
    left: 50%;
    margin-left: -7px;
    width: 14px;
    height: 14px;
    border: 2px solid transparent;
    border-top-color: #e1e1ef;
    border-left-color: #e1e1ef;
    transform: rotate(45deg);
    background-color: #fff;
    z-index: 901;
    display: none;
}

.dropdown-toggle-1::after {
    top: 10px;
    right: 9px;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    content: "\f3d0";
    font-family: "Ionicons";
    position: absolute;
    display: inline-block;
    color: #7a8eb1;
}

.dropdown-toggle-2::after {
    top: 13px;
    right: 0px;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    content: "\f3d0";
    font-family: "Ionicons";
    position: absolute;
    display: inline-block;
    color: #7a8eb1;
}

.dropgroupicons .nav-link.icon i {
    font-size: 21px;
}

.drop-icon-wrap a:hover {
    background: #eff2f6;
}

.drop-icon-wrap .drop-icon-item .drop-font {
    font-size: 12px;
}

.country-Flag img {
    width: 1.5rem;
    height: 1rem;
    line-height: 0.1rem;
    font-size: 0.75rem;
}

.dropdown.flag .dropdown-item span {
    font-size: 14px;
}

.btn.btn-default.nav-link {
    height: 38px;
    width: 38px;
    text-align: center;
    border-radius: 50%;
    line-height: 1;
    font-size: 17px;
    padding: 1px;
}

.d-md-block.nav.nav-item.nav-link {
    padding: 0.5rem 0.7rem;
}

.mega-dropdown-menu {
    width: 65rem;
    padding: 0.7rem 0.8rem 0.5rem 0.8rem;
}

.mega-dropdown-menu .dropdown-item {
    padding: 0.5rem 1rem;
}

.mega-dropdown-menu .dropdown-item:hover {
    color: var(--primary);
    background: transparent;
}

.btn-absolute {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    bottom: 12px;
    padding: 20px;
}

.arrow-ribbon {
    padding: 6px 8px;
    position: absolute;
    top: 10px;
    left: 0px;
    z-index: 999;
    font-size: 14px;
    line-height: 17px;
    background: #000;
    color: #fff;
}

.arrow-ribbon:before {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
    right: -15px;
    border-top: 14px solid transparent;
    border-left: 15px solid #000;
    border-bottom: 15px solid transparent;
    width: 0;
}

.header-megamenu-dropdown .nav-item .btn-link {
    color: #4a4a69;
}

.wordpress-project {
    border-radius: 5px;
    width: 55px;
    height: 55px;
}

.mega-dropdown-menu .media-body .progress-bar {
    height: 4px;
}

.header-megamenu-dropdown .dropdown-menu {
    top: 8px !important;
}

.mega-menu:before {
    content: "";
    position: absolute;
    top: -10px;
    left: 14px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
}

.mega-menu:after {
    content: "";
    position: absolute;
    top: -10px;
    left: 14px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #c9cddb transparent;
    border-color: transparent transparent rgba(64, 82, 128, 0.9) transparent;
    z-index: -99;
}

.drop-menu-header {
    padding: 1.5rem;
    color: #fff;
}

.dropdown-menu .dropdown-header {
    text-transform: uppercase;
    font-size: 0.73333333rem;
    font-weight: 500;
    border-bottom: 1px solid #ededf5;
}

.flag-text {
    color: #4a4a69;
    font-weight: 400;
}

.flag-text:before {
    content: "\f3d0";
    font-family: "Ionicons";
    font-weight: 400;
    font-size: 12px;
    position: absolute;
    top: 10px;
    right: 0;
    color: #4a4a69;
}

.drop-flag {
    margin-right: 2.3rem !important;
}

.flag-notification a {
    display: block;
    font-size: 22px;
    color: #323251;
    position: relative;
    line-height: 1.5;
    outline: none;
    padding: 0.5rem 0.5rem;
}

.flag-img {
    width: 2rem;
    height: 1.3rem;
}

.drop-flag .dropdown-menu {
    width: 150px;
    position: absolute;
    top: 52px;
    left: auto;
    right: -10px;
    bottom: auto;
    padding: 10px 10px 10px;
    border-color: #e1e1ef;
    border-width: 2px;
}

.drop-flag .dropdown-menu .dropdown-item {
    font-size: 14px;
}

.setting-menu-footer li {
    list-style-type: none;
}

.setting-menu-btn {
    padding: 0.5rem 1rem;
}

.setting-menu-footer .divider {
    margin: 0.5rem 0;
    height: 1px;
    overflow: hidden;
    background: #dee2e6;
}

.setting-menu .dropdown-item {
    padding: 0.4rem 1.5rem;
}

.setting-scroll {
    max-height: 162px;
    position: relative;
}

.header-img {
    background: linear-gradient(rgba(109, 110, 243, 0.7), rgba(109, 110, 243, 0.7)), url(../img/20.jpg);
    background-size: cover;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 20px 0 20px 0;
}

#dropdownMenuButton2 .badge-pill {
    padding: 5px 7px;
    font-size: 10.5px;
}

.bx-flip-horizontal {
    transform: scaleX(-1);
}

.bx-tada {
    -webkit-animation: tada 1.5s ease infinite;
    animation: tada 1.5s ease infinite;
}

.drop-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
    position: relative;
}

.dropdown-item .content {
    margin-left: 15px;
    width: 200px;
    white-space: normal;
}

.avatar-status {
    content: "";
    position: absolute;
    bottom: 0;
    right: 5px;
    width: 6px;
    height: 6px;
    background-color: #949eb7;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95);
    border-radius: 100%;
    bottom: 4px;
}

.user-pro-body .profile-status {
    position: absolute;
    display: block;
    bottom: 0;
    right: 18px;
    width: 8px;
    height: 8px;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95);
    border-radius: 100%;
    background: var(--success);
}

.user-pro-body .profile-status:before {
    content: "";
    right: -6px;
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    background: rgba(34, 192, 60, 0.5);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(34, 192, 60, 0.9);
    animation: pulse 1s linear infinite;
    top: -6px;
}

.main-header .nav-link .header-badge,
.main-header .nav-item .header-badge {
    position: absolute;
    top: 0px;
    right: 0px;
    display: block !important;
    padding: 3px 5px !important;
    font-size: 10px;
    z-index: 9999;
    border-radius: 50px;
    line-height: 1.1;
}

.pulse {
    display: block;
    position: absolute;
    top: 6px;
    right: 8px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: var(--success);
}

.pulse:before {
    content: "";
    top: -4px;
    right: -4px;
    width: 15px;
    height: 15px;
    position: absolute;
    border-radius: 50%;
    background: rgba(34, 192, 60, 0.6);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(34, 192, 60, 0.9);
    animation: pulse 1s linear infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    8% {
        transform: scale(0);
        opacity: 0;
    }

    15% {
        transform: scale(0.1);
        opacity: 1;
    }

    30% {
        transform: scale(0.5);
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: scale(1.1);
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(0);
        opacity: 0;
    }

    8% {
        -webkit-transform: scale(0);
        opacity: 0;
    }

    15% {
        -webkit-transform: scale(0.1);
        opacity: 1;
    }

    30% {
        -webkit-transform: scale(0.5);
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(1.1);
    }
}

@-moz-keyframes pulse {
    0% {
        -webkit-transform: scale(0);
        opacity: 0;
    }

    8% {
        -webkit-transform: scale(0);
        opacity: 0;
    }

    15% {
        -webkit-transform: scale(0.1);
        opacity: 1;
    }

    30% {
        -webkit-transform: scale(0.5);
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(1.1);
    }
}

.pulse-danger {
    display: block;
    position: absolute;
    top: 6px;
    right: 5px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #ee335e;
}

.pulse-danger:before {
    content: "";
    top: -4px;
    right: -4px;
    width: 15px;
    height: 15px;
    position: absolute;
    border-radius: 50%;
    background: rgba(238, 51, 94, 0.8);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(238, 51, 94, 0.9);
    animation: pulse 1s linear infinite;
}

.main-message-list a .desc {
    font-size: 11px;
    color: #4a4a69;
    margin-left: 10px;
    white-space: break-spaces;
}

.main-message-list a .name {
    font-size: 0.8rem;
    transition: all 0.2s ease-in-out;
    border-radius: 2px;
    color: #4a4a69;
    margin-left: 10px;
}

.main-message-list a .time {
    font-size: 11px;
    color: #7987a1;
    font-weight: 400;
}

.menu-header-content {
    padding: 1.2rem;
}

.chat-scroll,
.Notification-scroll,
.chat-scroll1,
.Notification-scroll1 {
    max-height: 280px;
    position: relative;
}

.cover-image {
    background-size: cover !important;
}

.notifyimg {
    border-radius: 25%;
}

.notifyimg i {
    color: #000;
    float: left;
    height: 40px;
    line-height: 40px;
    text-align: center;
    vertical-align: middle;
    width: 40px;
    font-size: 21px;
}

.notification-label {
    font-size: 13.5px;
    transition: all 0.2s ease-in-out;
    border-radius: 2px;
    color: #4a4a69;
}

.notification-label:hover {
    color: var(--primary);
}

.main-message-list .name:hover {
    color: var(--primary);
}

.notification-subtext {
    font-size: 12px;
    color: #7987a1;
}

/* ###### 4.8 Box-shadows ###### */
.box-shadow-primary {
    box-shadow: 0 5px 10px rgba(118, 109, 249, 0.25);
}

.box-shadow-success {
    box-shadow: 0 5px 10px rgba(26, 156, 134, 0.25);
}

.box-shadow-warning {
    box-shadow: 0 5px 10px rgba(255, 189, 90, 0.25);
}

.box-shadow-danger {
    box-shadow: 0 5px 10px rgba(220, 53, 69, 0.25);
}

.box-shadow-pink {
    box-shadow: 0 5px 10px rgba(241, 0, 117, 0.25);
}

.box-shadow-info {
    box-shadow: 0 5px 10px rgba(78, 194, 240, 0.25);
}

.main-notification-list .las {
    font-size: 13px;
}

.main-notification-list a:hover {
    background: #fcfcff;
    color: #4a4a69;
}

.main-message-list a:hover {
    background: #fcfcff;
    color: #4a4a69;
}

.page-header-icon i {
    margin: 0 auto;
    color: var(--primary);
    text-shadow: -2px 2px 2px rgba(34, 5, 191, 0.2);
}

.nav-badge {
    margin-left: auto !important;
    z-index: 9;
    padding: 5px 7px;
    font-size: 10.5px;
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.09), 0 1px 3px rgba(0, 0, 0, 0.08);
    position: absolute;
    right: 12px;
}

.nav-text-badge {
    margin-left: auto !important;
    z-index: 9;
    position: absolute;
    right: 7px;
}

.widget-icons {
    height: 45px;
    width: 45px;
    padding: 7px;
    border-radius: 50%;
    font-size: 23px;
    text-align: center;
    line-height: 28px;
}

.widget-icons i {
    margin: 0 auto;
    text-align: center;
    align-items: center;
}

.line-list {
    padding-left: 30px;
    margin-bottom: 0;
    position: relative;
    list-style-type: none;
}

.line-list li:before {
    content: "";
    position: absolute;
    border-radius: 100%;
    width: 12px;
    height: 12px;
    left: 0;
    border: 3px solid var(--primary);
    margin-right: 15px;
    z-index: 2;
    background: #fff;
}

.line-list li:after {
    content: "";
    border: 1px solid #e6edf7;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
}

.image-grouped .profile-img {
    width: 30px;
    height: 30px;
}

.increase i,
.decrease i {
    height: 35px;
    width: 35px;
    margin: 0 auto;
    text-align: center;
    line-height: 35px;
    font-size: 16px;
}

.sales-bar {
    min-height: 180px !important;
    position: relative;
    bottom: -21px;
}

.progress-sm {
    height: 4px;
}

.expansion-value strong:first-child {
    color: #323251;
}

.expansion-value strong:last-child {
    color: #7987a1;
}

.card-chart {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 3px;
}

.sales-details {
    position: absolute;
    width: 100%;
}

.table-dashboard-one {
    width: 100%;
    font-size: 13px;
}

.table-dashboard-one td {
    vertical-align: top;
    line-height: 1;
    padding: 9px 0;
}

.table-dashboard-one td:first-child {
    padding-left: 0;
}

.table-dashboard-one td:last-child {
    color: #8392a5;
}

.table-dashboard-one tr+tr td {
    border-top: 1px solid #e5e9f2;
}

.browser-stats i {
    height: 32px;
    width: 32px;
    text-align: center;
    border-radius: 50%;
    line-height: 32px;
}

.browser-stats .item {
    padding: 11px 1rem;
}

.browser-stats h6 {
    font-size: 13px;
    margin-bottom: 2px;
}

.browser-stats .sub-text {
    display: block;
    color: #949eb7;
    font-size: 10.6px;
}

.sales-card .badge {
    border-radius: 30px;
    background-color: #c6d8ff;
    padding: 4px 15px;
    color: #4680ff;
    font-weight: 600;
}

.sales-card .sales-icon {
    color: #fff;
    font-size: 18px;
    padding: 40px 40px 20px 20px;
    border-radius: 50%;
    position: absolute;
    top: -30px;
    right: -30px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.sales-card:hover .sales-icon {
    font-size: 25px;
}

.aligner-wrapper {
    position: relative;
}

.aligner-wrapper .absolute.absolute-center {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    position: absolute;
}

.media img {
    width: 35px;
    height: 35px;
}

.sales-dash-header {
    position: absolute;
    left: 20px;
    right: 20px;
    padding: 0;
    background-color: transparent;
    z-index: 5;
}

.sales-session {
    margin-left: 0;
    padding-left: 0;
}

.sales-session li {
    list-style-type: none;
    margin-bottom: 1.2rem;
}

.stats .icon {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 45px;
    border-radius: 3px;
}

.stats .icon i {
    font-size: 20px;
}

.ecommerce-card .icons {
    height: 50px;
    width: 50px;
    line-height: 63px;
    border-radius: 50px;
    text-align: center;
    background: #e9eefe;
}

.ecommerce-card .icons.primary {
    background: rgba(0, 0, 0, 0.2);
}

.ecommerce-card .icons.primary i {
    color: rgba(255, 255, 255, 0.8) !important;
}

.ecommerce-card .icons i {
    color: rgba(0, 0, 0, 0.5);
    font-size: 30px;
}

.ecommerce-card .icons.pink {
    background: rgba(241, 0, 117, 0.08) !important;
    color: #f754fa;
}

.ecommerce-card .icons.pink i {
    color: #f754fa;
    opacity: 0.8;
}

.ecommerce-card:hover .icons.pink {
    background: #f754fa !important;
}

.ecommerce-card:hover .icons.pink i {
    color: #fff;
    opacity: 1;
}

.ecommerce-card .icons.success {
    background: rgba(34, 192, 60, 0.1);
    color: var(--success);
}

.ecommerce-card .icons.success i {
    color: var(--success);
    opacity: 0.8;
}

.ecommerce-card:hover .icons.success {
    background: var(--success) !important;
}

.ecommerce-card:hover .icons.success i {
    color: #fff;
    opacity: 1;
}

.ecommerce-card .icons.warning {
    background: rgba(255, 193, 7, 0.12);
    color: var(--warning);
}

.ecommerce-card .icons.warning i {
    color: var(--warning);
    opacity: 0.8;
}

.ecommerce-card:hover .icons.warning {
    background: var(--warning) !important;
}

.ecommerce-card:hover .icons.warning i {
    color: #fff;
    opacity: 1;
}

.product-timeline ul.timeline-1 {
    list-style-type: none;
    position: relative;
}

.product-timeline .name {
    color: #4a4a69;
    font-size: 15px;
}

.product-timeline ul.timeline-1:before {
    content: " ";
    margin: 20px 20px 0 22px;
    display: inline-block;
    position: absolute;
    left: -2px;
    height: 94%;
    z-index: 0;
    content: "";
    position: absolute;
    bottom: 0;
    border-left: 2px dotted #c0ccda;
}

.product-timeline ul.timeline-1>li {
    margin: 20.7px 0;
    padding-left: 1rem;
}

.product-timeline ul.timeline-1>li .product-icon {
    width: 37px;
    height: 37px;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    left: 3px;
    background-color: #f3f6f7;
    font-size: 20px;
    line-height: 37px;
    font-size: 17px;
}

.font-weight-semibold {
    font-weight: 500 !important;
}

.bounceIn {
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn;
}

@keyframes bounceIn {

    from,
    20%,
    40%,
    60%,
    80%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.order-list .list {
    padding-left: 0;
}

.order-list .list .list-item:first-child {
    padding-top: 0;
}

.order-list .list.list-noborders .list-item {
    border: none;
}

.order-list .list .list-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 0 19px 0;
}

.order-list .list-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid #dee2e6;
}

.top-selling-product img {
    border-radius: 6px;
}

.top-selling-product .table th,
.top-selling-product .table td {
    padding: 7px 7px 7px 13px !important;
    vertical-align: baseline;
}

.top-selling-product .table-bordered thead th,
.top-selling-product .table-bordered thead td {
    border-top-width: 1px;
    padding-top: 11px !important;
    padding-bottom: 11px !important;
}

.out-of-stock {
    position: relative;
}

.out-of-stock:before {
    position: absolute;
    content: "";
    background: rgba(239, 242, 246, 0.8) !important;
    top: 0;
    left: 0;
    width: 43px;
    min-width: 43px;
    height: 43px;
    bottom: 0;
    background-size: cover;
    display: block;
}

.top-selling-product .text-default {
    color: #cdd4e8 !important;
}

.order-list img {
    border-radius: 6px;
}

.traffic-resource .table th,
.traffic-resource .table td {
    padding: 15px 0px !important;
    border-top: 0;
    border-bottom: 1px solid #ededf5;
}

.traffic-resource h6 {
    margin-bottom: 0;
}

.traffic-resource .table-hover tbody tr:hover {
    color: #4a4a69;
    background-color: #fcfcff;
}

@keyframes ani {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1);
    }

    30% {
        transform: scale(1.4);
    }

    50% {
        transform: scale(1.2);
    }

    70% {
        transform: scale(1.4);
    }

    90% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    }
}

.row-cards>.col,
.row-cards>[class*=col-] {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}

.row-deck>.col,
.row-deck>[class*=col-] {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.row-deck>.col .card,
.row-deck>[class*=col-] .card {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.semi-circle {
    background: rgba(234, 239, 254, 0.5);
    border-radius: 0 0 300px 300px;
}

.project-card {
    overflow: hidden;
}

.project-card:hover i {
    color: rgb(40, 92, 247);
    opacity: 0.16;
}

.project-card i {
    font-size: 65px;
    margin: 0 24px 0 -30px;
    position: relative;
    opacity: 0.07;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.project-card .project-content {
    width: 100%;
}

.project-card .project-content ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.project-card .project-content ul strong {
    color: #949eb7;
    font-size: 12px;
    font-weight: 400;
}

.project-card .project-content ul li {
    line-height: 20px;
}

.project-card .project-content ul span {
    color: #323232;
    float: right;
    font-weight: 600;
    text-align: right;
    margin-left: auto;
}

.project-card .primary {
    fill: var(--primary);
}

.project-card:hover .primary {
    fill: var(--primary);
    opacity: 1;
}

.project-card .teal,
.project-card:hover .teal {
    fill: #29ddee;
}

.project-card .pink {
    fill: #f754fa;
}

.project-card:hover .pink {
    fill: #f754fa;
    opacity: 1;
}

.project-card .purple {
    fill: #ad4ffa;
}

.project-card:hover .purple {
    fill: #ad4ffa;
    opacity: 1;
}

.area.chart-legend {
    top: 125px;
}

.recent-operations-card .operation-icon {
    width: 40px;
    height: 40px;
    text-align: center;
    padding: 0;
    font-size: 18px;
    color: #fff;
    border-radius: 5px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.crypto-scroll {
    max-height: 291px;
    position: relative;
}

.transcation-scroll {
    max-height: 400px;
    position: relative;
}

.transcation-scroll .list p a {
    color: #4d5875;
}

.crypto #flotChart3,
.crypto #flotChart5,
.crypto #flotChart1 {
    position: relative;
    margin: 0 -40px;
    margin-bottom: -40px;
}

.crypto .media {
    position: absolute;
}

.crypto .card-footer {
    background-color: #fff;
    padding: 0;
}

.crypto .card-footer .nav-link:hover,
.crypto .card-footer .nav-link:focus {
    background-color: #fcfcff;
}

.crypto .card-footer .nav-link span {
    display: block;
}

.crypto .card-footer .nav-link span:first-child {
    color: #949eb7;
    font-weight: 700;
    font-size: 11px;
}

.crypto .card-footer .nav-link span:last-child {
    color: #4a4a69;
    font-weight: 500;
    font-size: 11px;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.crypto .card-footer .nav-link+.nav-link {
    border-left: 1px solid #ededf5;
}

.crypto.card-footer .nav-link.active {
    background-color: var(--primary02);
}

.crypto .card-footer .nav-link {
    display: block;
    padding: 0.5rem 1.49rem;
}

.crypto .card-footer .nav-link.active::before {
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    z-index: 5;
}

.crypto .nav-link+.nav-link {
    border-left: 1px solid #ededf5;
}

.crypto .card-footer .nav-link {
    flex: 1;
    display: block;
    text-align: center;
    background-color: rgba(227, 231, 237, 0.45);
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
}

.crypto .coin-logo {
    width: 42px;
    height: 42px;
    color: #fff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    margin-right: 15px;
}

.crypto h6 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 2px;
    color: #323251;
}

.market-values .btn {
    min-height: initial;
}

.fs-name {
    font-size: 0.92rem;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 3px;
}

.task-box {
    background-color: #eff2f6;
    margin-bottom: 15px;
    padding: 10px;
}

.task-box.primary p,
.task-box.pink p {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    margin-bottom: 5px;
    letter-spacing: 0.5px;
}

.task-box.primary {
    background-color: rgba(217, 232, 254, 0.6) !important;
    color: var(--primary) !important;
    border: 1px dashed #7da7e4;
}

.task-box.pink {
    background-color: rgba(254, 229, 241, 0.5) !important;
    color: #f754fa !important;
    border: 1px dashed #f196c3;
}

.task-stat .tasks {
    color: #4a4a69;
    padding: 0.65rem 1.25rem;
    font-weight: 500;
    border-bottom: 1px solid #e7ebf3;
}

.svg-icons {
    fill: #4a4a69;
    height: 20px;
}

.eve-icon {
    height: 20px;
}

.ui-bottom-data {
    position: relative;
    bottom: -10px;
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 10px rgba(24, 28, 33, 0.2);
    box-shadow: 0 1px 10px rgba(24, 28, 33, 0.2);
}

.project-list .list-unstyled li {
    margin-bottom: 20px;
    padding: 14px;
    border-left: 1px solid #000;
}

.project-list .list-unstyled li .media-body {
    margin-left: 13px;
}

.pricing-card .list-unstyled {
    padding-left: 0;
    list-style: none;
}

.pricing-card .display-5 {
    font-size: 2.3rem;
    line-height: 1.2;
    padding: 25px 0 0 !important;
}

.pricing-card .list-unstyled li {
    display: flow-root;
    margin-bottom: 0;
    padding: 9px 0;
}

/*------ Pricing Styles ---------*/
.panel.price {
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
}

.panel.price>.panel-heading {
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
}

.panel.price h3 {
    margin-bottom: 0;
    padding: 20px 0;
}

.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    background: #fff;
}

.panel.price {
    margin-bottom: 1.5rem;
}

.panel.price>.panel-heading {
    color: #3b4863;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.panel.price .list-group-item:last-child {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.panel.price .list-group-item:first-child {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}

.price .panel-footer {
    border-bottom: 0px;
    background-color: #fff;
    border-left: 0;
    border-right: 0;
}

.price.panel-color>.panel-body {
    background-color: #fff;
}

.panel-body {
    padding: 20px;
    border-radius: 0 0 10px 10px;
    border: 1px solid var(--primary-10);
    border-top: none;
}

.panel-body .lead {
    font-size: 20px;
    margin-bottom: 0;
    padding: 10px 0;
}

.panel-body .lead strong {
    font-size: 40px;
    margin-bottom: 0;
}

.panel-footer {
    padding: 10px 15px;
    background-color: #fff;
    border-top: 1px solid #ededf5;
    border-left: 1px solid #ededf5;
    border-right: 1px solid #ededf5;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.panel.price .btn {
    border: 0px;
}

.pricing .list-unstyled li {
    display: flow-root;
    margin-bottom: 0;
    padding: 15px 0;
}

.demo-gallery>ul>li a {
    border-radius: 3px;
    display: block;
    overflow: hidden;
    position: relative;
    float: left;
    height: 100%;
    width: 100%;
}

.demo-gallery>ul>li a>img {
    opacity: 1;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    width: 100% !important;
}

.demo-gallery>ul>li a:hover>img,
.demo-gallery>ul>li a:hover .demo-gallery-poster>img {
    opacity: 0.5;
}

.demo-gallery>ul>li a .demo-gallery-poster {
    background-color: rgba(0, 0, 0, 0.1);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: background-color 0.15s ease 0s;
    -o-transition: background-color 0.15s ease 0s;
    transition: background-color 0.15s ease 0s;
}

.demo-gallery>ul>li a .demo-gallery-poster>img {
    left: 50%;
    margin-left: -10px;
    margin-top: -10px;
    opacity: 0;
    position: absolute;
    top: 50%;
    -webkit-transition: opacity 0.3s ease 0s;
    -o-transition: opacity 0.3s ease 0s;
    transition: opacity 0.3s ease 0s;
}

.demo-gallery>ul>li a:hover .demo-gallery-poster {
    background-color: rgba(0, 0, 0, 0.5);
}

.demo-gallery .justified-gallery>a>img {
    -webkit-transition: -webkit-transform 0.15s ease 0s;
    -moz-transition: -moz-transform 0.15s ease 0s;
    -o-transition: -o-transform 0.15s ease 0s;
    transition: transform 0.15s ease 0s;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    height: 100%;
    width: 100%;
}

.demo-gallery .justified-gallery>a:hover>img {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
}

.demo-gallery .justified-gallery>a:hover .demo-gallery-poster>img {
    opacity: 1;
}

.demo-gallery .justified-gallery>a .demo-gallery-poster {
    background-color: rgba(0, 0, 0, 0.1);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: background-color 0.15s ease 0s;
    -o-transition: background-color 0.15s ease 0s;
    transition: background-color 0.15s ease 0s;
}

.demo-gallery .justified-gallery>a .demo-gallery-poster>img {
    left: 50%;
    margin-left: -10px;
    margin-top: -10px;
    opacity: 0;
    position: absolute;
    top: 50%;
    -webkit-transition: opacity 0.3s ease 0s;
    -o-transition: opacity 0.3s ease 0s;
    transition: opacity 0.3s ease 0s;
}

.demo-gallery .justified-gallery>a:hover .demo-gallery-poster {
    background-color: rgba(0, 0, 0, 0.5);
}

.demo-gallery .video .demo-gallery-poster img {
    height: 48px;
    margin-left: -24px;
    margin-top: -24px;
    opacity: 0.8;
    width: 48px;
}

.demo-gallery.dark>ul>li a {
    border: 3px solid #04070a;
}

.card-body+.card-body {
    border-top: 1px solid #ededf5;
}

.left-side-product-box img {
    width: 100%;
}

.left-side-product-box .sub-img img {
    margin-top: 5px;
    width: 83px;
    height: 100px;
}

.right-side-pro-detail span {
    font-size: 15px;
}

.right-side-pro-detail p {
    font-size: 25px;
    color: #a1a1a1;
}

.right-side-pro-detail .price-pro {
    color: #E45641;
}

.right-side-pro-detail .tag-section {
    font-size: 18px;
    color: #5D4C46;
}

.pro-box-section .pro-box img {
    width: 100%;
    height: 200px;
}

@media (min-width: 360px) and (max-width: 640px) {
    .pro-box-section .pro-box img {
        height: auto;
    }
}

.product-details .media img {
    width: 60px;
    height: 60px;
    margin-right: 20px;
    border-radius: 5px;
    max-width: inherit;
    border: 1px solid #d4daea;
}

.preview-pic {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.preview-thumbnail.nav-tabs {
    border: none;
    margin-top: 15px;
}

.preview-thumbnail.nav-tabs li {
    width: 18%;
    margin-right: 2.5%;
}

.preview-thumbnail.nav-tabs li img {
    max-width: 100%;
    display: block;
}

.preview-thumbnail.nav-tabs li a {
    padding: 0;
    margin: 0;
}

.preview-thumbnail.nav-tabs li:last-of-type {
    margin-right: 0;
}

.tab-content {
    overflow: hidden;
}

.tab-content img {
    width: 100%;
}

.details {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.colors {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.product-title,
.price,
.sizes,
.colors {
    font-weight: 500;
}

.checked {
    color: #ff9f1a;
}

.price span {
    color: #031b4e;
}

.product-title,
.rating,
.product-description,
.price,
.vote,
.sizes {
    margin-bottom: 15px;
}

.product-title {
    margin-top: 0;
}

.size {
    margin-right: 10px;
}

.size:first-of-type {
    margin-left: 40px;
}

.details .rdiobox span:after {
    top: 5px;
}

.details .rdiobox span:before {
    top: 0px;
}

@-webkit-keyframes opacity {
    0% {
        opacity: 0;
        -webkit-transform: scale(3);
        transform: scale(3);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes opacity {
    0% {
        opacity: 0;
        -webkit-transform: scale(3);
        transform: scale(3);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.colorinput {
    margin: 0;
    position: relative;
    cursor: pointer;
}

.colorinput-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.colorinput-color {
    display: inline-block;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 3px;
    border: 1px solid #eaf0f7;
    color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.colorinput-color:before {
    content: "";
    opacity: 0;
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    height: 1.25rem;
    width: 1.25rem;
    transition: 0.3s opacity;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center center/50% 50%;
}

.colorinput-input:checked~.colorinput-color:before {
    opacity: 1;
}

.feature2 i {
    line-height: 3rem;
    font-size: 20px;
    margin: 0 0 15px 0;
}

.item-card .cardtitle span {
    display: block;
    font-size: 0.75rem;
}

.item-card .cardtitle a {
    color: #1f252d;
    font-weight: 500;
    text-decoration: none;
}

.item-card .cardprice {
    position: absolute;
    top: 17px;
    right: 15px;
}

.item-card .cardprice span {
    display: block;
    color: #1f252d;
}

.item-card .cardprice span.type--strikethrough {
    opacity: 0.7;
    text-decoration: line-through;
}

.item-card .relative {
    position: relative;
}

/*------icons-list---------*/
.main-icon-list {
    padding: 0;
}

.icons-list-item i {
    font-size: 1rem !important;
    line-height: 3;
}

.icons-list {
    list-style: none;
    margin: 0 -1px -1px 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.icons-list-item {
    text-align: center;
    height: 3rem;
    width: 3rem;
    line-height: 3.3;
    display: block;
    align-items: center;
    justify-content: center;
    border: 1px solid #e9ebfa;
    margin: 4px;
    border-radius: 5px;
    color: #6c757e;
    fill: #6c757e;
    flex: 1 0 3rem;
}

.icons-list-item .bs-tooltip-start {
    padding: 10px 0.4rem;
}

.flag {
    margin: 8px auto !important;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    display: block;
    vertical-align: middle;
    box-shadow: none;
}

.payment {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    margin-top: 9px;
    display: block;
    vertical-align: middle;
    box-shadow: none;
}

.icons-list .flag-icon {
    border-radius: 0;
}

/*------icons-list---------*/
.example {
    padding: 1rem;
    border: 1px solid #ededf5;
    font-size: 0.8175rem;
    border-radius: 7px;
}

.example+.highlight {
    border-top: none;
    margin-top: 0;
}

.text-wrap> :last-child {
    margin-bottom: 0;
}

.highlight {
    margin: 0 0 2rem;
    border: 1px solid #23241f;
    border-radius: 0px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    font-size: 0.9375rem;
    background: #edeff7;
    border-top-right-radius: 8px;
    position: relative !important;
}

.highlight pre {
    margin-bottom: 0;
    background-color: #23241f;
    max-height: 20rem;
}

pre {
    color: #3d3f4e;
    padding: 1rem;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #edeff7;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    border-top-right-radius: 7px;
}

/*------icons-list---------*/
.clip-widget {
    position: relative;
}

.clipboard-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 25px;
    background: #fff;
    border: 1px solid #ededf5;
    border-radius: 8px;
    cursor: pointer;
    height: 35px;
    width: 35px;
    text-align: center;
    line-height: 33px;
}

*::-webkit-scrollbar {
    width: 0.375rem;
    height: 0.625rem;
    transition: 0.3s background;
}

::-webkit-scrollbar-track {
    background: var(--neutral-30);
    border-radius: 100vw;
    margin-block: 0.5rem;
}

::-webkit-scrollbar-thumb {
    background: var(--neutral-60);
    border-radius: 100vw;
    border: 0.0625rem solid var(--neutral-30);
}

*:hover::-webkit-scrollbar-thumb {
    background: #adb5bd;
}

@media (max-width: 568px) {
    .alert-dismissible .close {
        padding: 0.55rem 0.2rem;
        color: inherit;
    }

    .main-nav .nav-link+.nav-link {
        border-top: 0px solid #ededf5;
    }

    .main-nav .nav-link+.nav-link {
        border-left: 0px !important;
    }
}

@media (max-width: 768px) {
    .responsive-logo .header-logo {
        display: block;
        margin: 0 auto;
        left: 0;
        right: 0;
        text-align: center;
        position: absolute;
        top: 10px;
    }
}

.angle {
    color: #9ca2a9 !important;
}

.pro-img-box img {
    border: 1px solid #ededf5 !important;
}

.banner {
    background: rgba(109, 110, 243, 0.1);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    margin: 1.5rem 0;
    padding: 14px 15px;
    border: 1px solid rgb(219, 219, 247);
}

.nav-sub-item .sub-slide-item {
    color: #fff;
}

@media (max-width: 633px) {
    .right-content {
        margin-top: 20px !important;
    }
}

@media (max-width: 1199px) {
    .total-revenue {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #ededf5 !important;
    }
}

.sub-menu li a:before {
    margin-right: 8px;
    content: "";
    width: inherit;
    height: inherit;
    border-width: 0;
    position: absolute;
    left: 8px;
    top: 15px;
    z-index: 99;
    border-color: #bec5d0;
    content: "\e92f";
    font-family: "feather" !important;
    position: absolute;
    top: 9px;
    left: 8px;
    font-size: 9px;
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a:hover {
    color: #696af1;
}

.mega-menubg.hor-mega-menu li a:before {
    position: absolute;
    z-index: 99;
    border-color: #bec5d0;
    content: "\f22d";
    font-family: "Font Awesome 5 Free" !important;
    top: 11px;
    left: 10px;
    font-size: 12px;
    background: transparent;
    color: #7b7a7d !important;
}

.slide.active.is-expanded {
    background: rgba(238, 238, 247, 0.5);
}

@media (max-width: 1134px) {
    .p-text {
        display: none;
    }
}

@media (max-width: 992px) {
    .fullscreen-button {
        display: none;
    }
}

.horizontalMenucontainer.main-header form[role=search] {
    width: inherit;
}

.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: center;
}

.prev-price {
    text-decoration: line-through;
}

.qunatity-list {
    margin-left: 0;
    padding-left: 6px;
}

.qunatity-list li {
    list-style-type: none;
}

.chips .chip {
    margin: 0 0.5rem 0.5rem 0;
}

.chip {
    display: inline-block;
    height: 2rem;
    line-height: 2rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #6e7687;
    padding: 0 0.75rem;
    border-radius: 1rem;
    background-color: #f5f6fb;
    transition: 0.3s background;
}

.chip .avatar {
    float: left;
    margin: 0 0.5rem 0 -0.75rem;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
}

.team i {
    margin-left: 10px;
    float: right;
}

.feature .project {
    display: flex;
    width: 45px;
    height: 45px;
    line-height: 2em;
    vertical-align: middle;
    padding-top: 0px;
    color: #fff;
    font-size: 20px;
    border-radius: 50px;
    position: relative;
    align-items: center;
    text-align: center;
    left: 0;
    right: 0;
    justify-content: center;
    font-weight: 500;
}

.plan-icon {
    font-size: 25px;
    width: 80px;
    height: 80px;
    line-height: 80px !important;
    overflow: hidden;
    border: 1px solid #ebeaf1;
    border-radius: 50%;
    background: #f5f6fb;
    transition: all 0.3s;
}

.country-table .table th,
.country-table .table td {
    padding: 10px 15px;
    line-height: 1.462;
}

/*-----Back to Top-----*/
#back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99;
    display: none;
    text-align: center;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -o-border-radius: 4px;
    z-index: 1000;
    height: 50px;
    width: 50px;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-color 0.1s linear;
    -moz-transition: background-color 0.1s linear;
    -webkit-transition: background-color 0.1s linear;
    -o-transition: background-color 0.1s linear;
    background: var(--primary);
    color: #fff;
    border-radius: 5px;
}

#back-to-top i {
    padding-top: 0;
    font-size: 20px;
    line-height: 2.4;
}

#compositeline,
#compositeline2 {
    position: relative;
    bottom: -1px;
}

@media (max-width: 480px) {
    .sales-bar {
        padding-top: 50px;
    }
}

@media (max-width: 578px) {
    .main-logo {
        display: none !important;
    }

    .total-revenue .media {
        display: none;
    }
}

.email-button {
    background: #009a3e;
    color: #fff;
    position: fixed;
    bottom: 80px;
    line-height: 50px;
    right: 20px;
    z-index: 99;
    text-align: center;
    border-radius: 50%;
    z-index: 1000;
    height: 50px;
    width: 50px;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-color 0.1s linear;
    -moz-transition: background-color 0.1s linear;
    -webkit-transition: background-color 0.1s linear;
    -o-transition: background-color 0.1s linear;
}

.email-button:hover {
    background: var(--primary02);
    color: #009a3e;
    border: 2px solid #009a3e;
}

.box-shadow {
    box-shadow: none;
}

@media (max-width: 480px) {
    .sales-bar {
        padding-top: 50px;
    }
}

@media (max-width: 575px) {
    .sales-bar {
        padding-top: 50px;
    }
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a:hover:before {
    border-color: var(--primary);
    color: var(--primary);
}

.main-logo-pages {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 auto;
    height: 2.3rem;
}

@media (max-width: 578px) {
    .desktop-logo.logo-light .main-logo {
        display: block;
    }
}

@media (max-width: 767px) {
    .icons-list-item {
        margin: 4px 4px;
    }
}

/* ############### DASHBOARD STYLES ############### */
/* ###### 8.1 Dashboard One  ###### */
.main-content-dashboard {
    padding-top: 20px;
}

@media (min-width: 992px) {
    .main-content-dashboard {
        padding-top: 30px;
    }
}

.main-dashboard-one-title {
    margin-bottom: 20px;
}

@media (min-width: 576px) {
    .main-dashboard-one-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

@media (min-width: 992px) {
    .main-dashboard-one-title {
        margin-bottom: 30px;
    }
}

.main-dashboard-title {
    font-size: 22px;
    font-weight: 700;
    color: #1c273c;
    letter-spacing: -1px;
    margin-bottom: 3px;
}

.main-dashboard-text {
    font-size: 13px;
    margin-bottom: 0;
}

.main-dashboard-date {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.main-dashboard-date .date {
    display: flex;
}

.main-dashboard-date .date>div:first-child {
    font-size: 32px;
    font-family: Arial, sans-serif;
    font-weight: 500;
    color: #1c273c;
    line-height: 0.9;
}

.main-dashboard-date .date>div:last-child {
    margin-left: 5px;
}

.main-dashboard-date .date>div:last-child span {
    display: block;
    color: #1c273c;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: -0.5px;
}

.main-dashboard-date .date>div:last-child span:last-child {
    color: #7987a1;
    font-weight: 400;
}

.main-dashboard-date>i {
    font-size: 21px;
    line-height: 0;
    display: block;
    margin: 0 15px;
    color: #97a3b9;
}

.main-dashboard-date .wallet {
    border-radius: 3px;
}

.main-dashboard-date .wallet i {
    line-height: 40px;
}

@media (min-width: 576px) {
    .main-dashboard-date {
        margin-top: 0;
    }
}

@media (min-width: 768px) {
    .main-dashboard-date .date>div:first-child {
        font-size: 36px;
    }
}

@media (min-width: 768px) {
    .main-dashboard-date .date>div:last-child span {
        font-size: 13px;
    }
}

@media (min-width: 768px) {
    .main-dashboard-date>i {
        margin: 0 20px;
        font-size: 24px;
    }
}

.main-dashboard-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ededf5;
    margin-bottom: 20px;
}

.main-dashboard-nav .nav-link {
    font-size: 12px;
    padding: 5px 10px 8px;
    display: flex;
    align-items: center;
    line-height: 1;
}

.main-dashboard-nav .nav-link:hover,
.main-dashboard-nav .nav-link:focus {
    color: var(--primary);
}

.main-dashboard-nav .nav-link+.nav-link {
    border-left: 1px solid #ededf5;
}

.main-dashboard-nav .nav:first-child .nav-link {
    color: #1c273c;
    display: none;
}

.main-dashboard-nav .nav:first-child .nav-link:hover,
.main-dashboard-nav .nav:first-child .nav-link:focus,
.main-dashboard-nav .nav:first-child .nav-link.active {
    color: var(--primary);
}

.main-dashboard-nav .nav:first-child .nav-link:first-child {
    padding-left: 0;
    display: block;
}

.main-dashboard-nav .nav:first-child .nav-link:last-child {
    display: block;
}

.main-dashboard-nav .nav:last-child .nav-link {
    color: #596882;
    display: none;
}

.main-dashboard-nav .nav:last-child .nav-link:hover,
.main-dashboard-nav .nav:last-child .nav-link:focus {
    color: var(--primary);
}

.main-dashboard-nav .nav:last-child .nav-link i {
    font-size: 16px;
    margin-right: 7px;
    line-height: 0;
}

.main-dashboard-nav .nav:last-child .nav-link:last-child {
    padding-right: 0;
    display: block;
}

.main-dashboard-nav .nav:last-child .nav-link:last-child i {
    margin-right: 0;
}

@media (min-width: 576px) {
    .main-dashboard-nav .nav-link {
        font-size: 0.875rem;
    }
}

@media (min-width: 992px) {
    .main-dashboard-nav .nav-link {
        padding: 5px 15px 10px;
    }
}

@media (min-width: 576px) {
    .main-dashboard-nav .nav:first-child .nav-link {
        display: block;
    }
}

@media (min-width: 768px) {
    .main-dashboard-nav .nav:last-child .nav-link {
        display: block;
    }
}

@media (min-width: 768px) {
    .main-dashboard-nav .nav:last-child .nav-link:last-child {
        display: none;
    }
}

.card-dashboard-one {
    position: relative;
    border-color: #ededf5;
}

.card-dashboard-one .card-header {
    padding: 20px;
    background-color: transparent;
}

.card-dashboard-one .card-header .card-title {
    color: #1c273c;
    font-weight: 700;
    font-size: 14px;
    line-height: 1;
    margin-bottom: 3px;
}

.card-dashboard-one .card-header .card-text {
    margin-bottom: 0;
}

.card-dashboard-one .card-header .btn-group {
    margin-top: 15px;
}

.card-dashboard-one .card-header .btn-group .btn,
.card-dashboard-one .card-header .btn-group .sp-container button {
    font-size: 12px;
    font-weight: 500;
    padding: 5px 10px;
    min-height: inherit;
    background-color: #fff;
    color: #7987a1;
    border: 1px solid #ededf5;
}

@media (min-width: 576px) {
    .card-dashboard-one .card-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
}

@media (min-width: 576px) {
    .card-dashboard-one .card-header .btn-group {
        margin-top: 0;
    }
}

.sp-container .card-dashboard-one .card-header .btn-group button {
    font-size: 12px;
    font-weight: 500;
    padding: 5px 10px;
    min-height: inherit;
    background-color: #fff;
    color: #7987a1;
    border: 1px solid #ededf5;
}

.card-dashboard-one .card-header .btn-group .btn:hover,
.card-dashboard-one .card-header .btn-group .sp-container button:hover {
    background-color: #f4f5f8;
    border-color: #ededf5;
}

.sp-container .card-dashboard-one .card-header .btn-group button:hover {
    background-color: #f4f5f8;
    border-color: #ededf5;
}

.card-dashboard-one .card-header .btn-group .btn:focus,
.card-dashboard-one .card-header .btn-group .sp-container button:focus {
    background-color: #f4f5f8;
    border-color: #ededf5;
}

.sp-container .card-dashboard-one .card-header .btn-group button:focus {
    background-color: #f4f5f8;
    border-color: #ededf5;
}

.card-dashboard-one .card-header .btn-group .btn+.btn,
.card-dashboard-one .card-header .btn-group .sp-container button+.btn {
    margin-left: -1px;
}

.sp-container .card-dashboard-one .card-header .btn-group button+.btn,
.card-dashboard-one .card-header .btn-group .sp-container .btn+button,
.sp-container .card-dashboard-one .card-header .btn-group .btn+button,
.card-dashboard-one .card-header .btn-group .sp-container button+button,
.sp-container .card-dashboard-one .card-header .btn-group button+button {
    margin-left: -1px;
}

.card-dashboard-one .card-header .btn-group .btn.active,
.card-dashboard-one .card-header .btn-group .sp-container button.active {
    color: #1c273c;
    background-color: #e3e7ed;
    border-color: #ededf5;
}

.sp-container .card-dashboard-one .card-header .btn-group button.active {
    color: #1c273c;
    background-color: #e3e7ed;
    border-color: #ededf5;
}

.card-dashboard-one .card-body {
    padding: 10px 0 20px;
    position: relative;
    overflow: hidden;
}

.card-dashboard-one .card-body .flot-chart-wrapper {
    position: relative;
    margin-left: -28px;
    margin-right: -20px;
}

.card-dashboard-one .card-body .flot-chart {
    width: 100%;
    height: 150px;
}

.card-dashboard-one .card-body .flot-chart .flot-y-axis>div {
    transform: translateX(50px);
    text-shadow: 1px 1px rgba(255, 255, 255, 0.75);
    color: #4a4a69;
    font-weight: 700;
    font-size: 11px;
}

.card-dashboard-one .card-body .flot-chart .flot-x-axis>div {
    color: #97a3b9;
    font-weight: 500;
    font-size: 11px;
}

.card-dashboard-one .card-body-top {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-left: 20px;
}

.card-dashboard-one .card-body-top>div {
    flex-basis: 50%;
}

.card-dashboard-one .card-body-top label {
    font-size: 12px;
    margin-bottom: 3px;
}

.card-dashboard-one .card-body-top h2 {
    font-size: 20px;
    font-weight: 600;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
}

@media (min-width: 576px) {
    .card-dashboard-one .card-body .flot-chart {
        height: 275px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .card-dashboard-one .card-body .flot-chart {
        height: 252px;
    }
}

@media (min-width: 576px) {
    .card-dashboard-one .card-body-top {
        position: absolute;
        top: -5px;
        left: 20px;
        z-index: 5;
        padding-left: 0;
    }
}

@media (min-width: 576px) {
    .card-dashboard-one .card-body-top>div {
        flex-basis: auto;
    }
}

@media (min-width: 576px) {
    .card-dashboard-one .card-body-top>div+div {
        margin-left: 30px;
    }
}

@media (min-width: 576px) {
    .card-dashboard-one .card-body-top label {
        font-size: 0.875rem;
    }
}

@media (min-width: 576px) {
    .card-dashboard-one .card-body-top h2 {
        font-size: 24px;
        letter-spacing: -0.5px;
    }
}

.card-dashboard-two {
    height: 100%;
    border-radius: 0;
    border-color: #ededf5;
    background-color: rgba(255, 255, 255, 0.5);
}

.card-dashboard-two .card-header {
    background-color: transparent;
    padding: 20px 20px 0;
}

.card-dashboard-two .card-header h6 {
    font-size: 28px;
    font-weight: 600;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    display: flex;
    align-items: baseline;
    margin-bottom: 2px;
    line-height: 1;
    letter-spacing: -1px;
}

.card-dashboard-two .card-header h6 i {
    margin-left: 10px;
    font-size: 24px;
}

.card-dashboard-two .card-header h6 small {
    font-size: 12px;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    margin-left: 5px;
    color: #7987a1;
    letter-spacing: normal;
}

.card-dashboard-two .card-header p {
    margin-bottom: 0;
    font-size: 13px;
}

.card-dashboard-two .card-body {
    padding: 0;
    overflow: hidden;
}

.card-dashboard-two .chart-wrapper {
    position: relative;
    margin: 0 -10px -10px;
}

.card-dashboard-two .flot-chart {
    width: 100%;
    height: 100px;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .card-dashboard-two .card-header h6 {
        font-size: 24px;
    }
}

.card-dashboard-three {
    height: 150px;
    border-radius: 0;
    border-color: #ededf5;
    background-color: rgba(255, 255, 255, 0.5);
}

.card-dashboard-three .card-header {
    background-color: transparent;
    padding: 20px;
    position: absolute;
    max-width: 50%;
}

.card-dashboard-three .card-header h6 {
    font-size: 28px;
    font-weight: 600;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    display: flex;
    align-items: baseline;
    margin-bottom: 15px;
    line-height: 1;
    letter-spacing: -1px;
}

.card-dashboard-three .card-header h6 small {
    font-size: 12px;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    margin-left: 5px;
    letter-spacing: normal;
}

.card-dashboard-three .card-header p {
    margin-bottom: 10px;
    font-weight: 700;
    text-transform: uppercase;
    color: #1c273c;
}

.card-dashboard-three .card-header>small {
    font-size: 11px;
    display: none;
    color: #7987a1;
}

.card-dashboard-three .card-body {
    padding: 0 0 0 20px;
    display: flex;
    justify-content: flex-end;
}

.card-dashboard-three .chart {
    width: 70%;
    height: 150px;
}

@media (min-width: 768px) {
    .card-dashboard-three {
        height: 170px;
    }
}

@media (min-width: 992px) {
    .card-dashboard-three {
        height: 100%;
    }
}

@media (min-width: 1200px) {
    .card-dashboard-three .card-header h6 {
        font-size: 32px;
    }
}

@media (min-width: 768px) {
    .card-dashboard-three .card-header>small {
        display: block;
    }
}

@media (min-width: 375px) {
    .card-dashboard-three .chart {
        width: auto;
    }
}

@media (min-width: 768px) {
    .card-dashboard-three .chart {
        height: 170px;
    }
}

@media (min-width: 992px) {
    .card-dashboard-three .chart {
        height: 200px;
        width: 80%;
    }
}

.card-dashboard-pageviews {
    border-color: #ededf5;
    border-radius: 0;
    padding: 20px;
}

.card-dashboard-pageviews .card-header {
    background-color: transparent;
    padding: 0 0 10px;
}

.card-dashboard-pageviews .card-title {
    font-weight: 700;
    font-size: 14px;
    color: #1c273c;
    margin-bottom: 5px;
}

.card-dashboard-pageviews .card-text {
    font-size: 13px;
    margin-bottom: 0;
}

.card-dashboard-pageviews .card-body {
    padding: 0;
}

.card-dashboard-four {
    border-radius: 0;
    border-color: #ededf5;
    padding: 20px;
    height: 100%;
}

.card-dashboard-four .card-header {
    padding: 0 0 20px;
    background-color: transparent;
}

.card-dashboard-four .card-title {
    font-weight: 700;
    font-size: 14px;
    color: #1c273c;
    margin-bottom: 0;
}

.card-dashboard-four .card-body {
    padding: 0;
}

.card-dashboard-four .chart {
    width: calc(100vw - 80px);
    height: 200px;
}

@media (min-width: 992px) {
    .card-dashboard-four .card-body {
        padding: 0 20px;
    }
}

@media (min-width: 375px) {
    .card-dashboard-four .chart {
        width: auto;
    }
}

@media (min-width: 992px) {
    .card-dashboard-four .chart {
        height: 85%;
    }
}

.card-dashboard-five {
    border-color: #ededf5;
    padding: 20px;
}

.card-dashboard-five .card-header {
    padding: 0;
    background-color: transparent;
}

.card-dashboard-five .card-title {
    font-weight: 700;
    font-size: 14px;
    color: #1c273c;
    margin-bottom: 5px;
}

.card-dashboard-five .card-text {
    display: block;
    font-size: 12px;
}

.card-dashboard-five .card-body {
    padding: 20px 0 5px;
}

.card-dashboard-five .card-body label {
    display: block;
    margin-bottom: 2px;
    font-size: 12px;
}

.card-dashboard-five .card-body h4 {
    color: #1c273c;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    letter-spacing: -0.5px;
    margin-bottom: 0;
}

.card-dashboard-five .card-chart {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

@media (min-width: 576px) {
    .card-dashboard-five .card-chart {
        margin-bottom: 0;
        margin-right: 10px;
    }
}

.card-table-one {
    padding: 20px 20px 10px;
    border-color: #ededf5;
}

.card-table-one .card-title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
}

.card-table-one .table-responsive {
    width: calc(100vw - 80px);
}

.card-table-one .table {
    margin-bottom: 0;
}

.card-table-one .table thead tr>th,
.card-table-one .table thead tr>td {
    vertical-align: middle;
    white-space: nowrap;
    padding-right: 2px;
}

.card-table-one .table tbody tr>th,
.card-table-one .table tbody tr>td {
    vertical-align: middle;
    white-space: nowrap;
    padding-right: 2px;
}

.card-table-one .table thead tr>th:nth-child(3),
.card-table-one .table thead tr>th:nth-child(4),
.card-table-one .table thead tr>th:nth-child(5) {
    text-align: right;
}

.card-table-one .table thead tr>td:nth-child(3),
.card-table-one .table thead tr>td:nth-child(4),
.card-table-one .table thead tr>td:nth-child(5) {
    text-align: right;
}

.card-table-one .table tbody tr>th:nth-child(3),
.card-table-one .table tbody tr>th:nth-child(4),
.card-table-one .table tbody tr>th:nth-child(5) {
    text-align: right;
}

.card-table-one .table tbody tr>td:nth-child(3),
.card-table-one .table tbody tr>td:nth-child(4),
.card-table-one .table tbody tr>td:nth-child(5) {
    text-align: right;
}

.card-table-one .table thead tr>th strong,
.card-table-one .table thead tr>td strong {
    color: #1c273c;
}

.card-table-one .table tbody tr>th strong,
.card-table-one .table tbody tr>td strong {
    color: #1c273c;
}

.card-table-one .flag-icon {
    font-size: 20px;
    line-height: 1;
    border-radius: 100%;
}

@media (min-width: 576px) {
    .card-table-one .table-responsive {
        width: 100%;
    }
}

@media (min-width: 768px) {

    .card-table-one .table thead tr>th,
    .card-table-one .table thead tr>td {
        padding-left: 0;
        padding-right: 0;
    }

    .card-table-one .table tbody tr>th,
    .card-table-one .table tbody tr>td {
        padding-left: 0;
        padding-right: 0;
    }
}

/* ###### 8.2 Dashboard Two  ###### */
.main-content-dashboard-two {
    background-color: #eff2f6;
    transition: all 0.4s;
}

.main-content-dashboard-two .main-content-title {
    letter-spacing: -0.5px;
}

.main-content-dashboard-two .main-content-body {
    padding: 0 20px;
}

.main-content-dashboard-two .main-header-menu-icon {
    margin-right: 0;
}

@media (prefers-reduced-motion: reduce) {
    .main-content-dashboard-two {
        transition: none;
    }
}

@media (min-width: 992px) {
    .main-content-dashboard-two .main-content-body {
        padding: 0 20px 20px;
    }
}

.main-dashboard-header-right {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}

.main-dashboard-header-right>div label {
    display: block;
    margin-bottom: 0;
    color: #78829a;
}

.main-dashboard-header-right>div h5 {
    font-size: 20px;
    font-weight: 700;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    margin-bottom: 0;
    line-height: 1;
}

.main-dashboard-header-right>div:first-child {
    flex-basis: 100%;
}

.main-dashboard-header-right>div+div {
    margin-top: 10px;
}

@media (min-width: 576px) {
    .main-dashboard-header-right {
        flex-wrap: nowrap;
    }
}

@media (min-width: 768px) {
    .main-dashboard-header-right {
        margin-top: 0;
    }
}

@media (min-width: 768px) {
    .main-dashboard-header-right>div {
        text-align: right;
    }
}

@media (max-width: 575.98px) {
    .main-dashboard-header-right>div+div {
        flex-basis: 50%;
    }
}

@media (min-width: 576px) {
    .main-dashboard-header-right>div+div {
        margin-left: 25px;
        margin-top: 0;
        flex-shrink: 0;
    }
}

@media (min-width: 992px) {
    .main-dashboard-header-right>div+div {
        margin-left: 50px;
    }
}

.main-star {
    display: flex;
    align-items: center;
}

.main-star i {
    font-size: 20px;
    line-height: 0;
    color: #97a3b9;
    display: inline-block;
}

.main-star i.typcn {
    line-height: 0.9;
}

.main-star i.active {
    color: var(--warning);
}

.main-star span {
    font-size: 13px;
    display: inline-block;
    margin-left: 5px;
}

.card-dashboard-seven {
    border-color: #ededf5;
    margin-bottom: 20px;
    width: 100vw;
    border-left-width: 0;
    border-right-width: 0;
    position: relative;
    margin-left: -20px;
    margin-right: -20px;
}

.card-dashboard-seven .card-header {
    background-color: transparent;
    padding: 20px;
    border-bottom: 1px solid #ededf5;
    background-color: #f4f5f8;
}

.card-dashboard-seven .card-header .media {
    align-items: center;
}

.card-dashboard-seven .card-header .media>div:first-child {
    line-height: 0;
    font-size: 16px;
    margin-right: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 34px;
    background-color: #97a3b9;
}

.card-dashboard-seven .card-header .media-body {
    margin-top: 3px;
}

.card-dashboard-seven .card-header label {
    margin-bottom: 0;
    display: block;
    line-height: 1;
    font-size: 11px;
    color: #97a3b9;
}

.card-dashboard-seven .card-header .date {
    font-weight: 500;
    display: flex;
    align-items: center;
}

.card-dashboard-seven .card-header .date span {
    display: block;
}

.card-dashboard-seven .card-header .date a {
    margin-left: 5px;
    font-size: 14px;
    color: #97a3b9;
    display: block;
}

.card-dashboard-seven .card-header .date a i {
    line-height: 0;
    position: relative;
    top: 1px;
}

.card-dashboard-seven .card-body {
    padding: 20px;
}

.card-dashboard-seven .card-body .row>div+div {
    position: relative;
}

.card-dashboard-seven .card-body .row>div+div::before {
    content: "";
    position: absolute;
    top: 0;
    left: -1px;
    bottom: 0;
    border-left: 1px dotted #ededf5;
    display: none;
}

.card-dashboard-seven .card-body .main-content-label,
.card-dashboard-seven .card-body .card-table-two .card-title {
    text-transform: none;
    color: #4a4a69;
    font-weight: 500;
    font-size: 0.875rem;
    margin-bottom: 10px;
}

@media (min-width: 576px) {
    .card-dashboard-seven {
        margin-bottom: 20px;
        margin-left: 0;
        margin-right: 0;
        width: auto;
        border-left-width: 1px;
        border-right-width: 1px;
    }
}

@media (min-width: 576px) {
    .card-dashboard-seven .card-header {
        padding: 20px;
    }
}

@media (min-width: 576px) {
    .card-dashboard-seven .card-header .media>div:first-child {
        width: 34px;
        font-size: 24px;
        margin-right: 8px;
    }
}

@media (min-width: 992px) {
    .card-dashboard-seven .card-body .row>div+div::before {
        display: block;
    }
}

.card-table-two .card-dashboard-seven .card-body .card-title,
.card-dashboard-seven .card-body .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-seven .card-body .card-title {
    text-transform: none;
    color: #4a4a69;
    font-weight: 500;
    font-size: 0.875rem;
    margin-bottom: 10px;
}

.card-dashboard-seven .card-body h2 {
    color: #1c273c;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;
    font-size: 24px;
    letter-spacing: -1px;
    margin-bottom: 5px;
    line-height: 1;
}

.card-dashboard-seven .card-body h2 span {
    font-weight: 400;
    color: #4a4a69;
    letter-spacing: normal;
}

.card-dashboard-seven .card-body .desc {
    display: flex;
    align-items: flex-end;
    margin-bottom: 15px;
}

.card-dashboard-seven .card-body .desc>i {
    line-height: 0;
    font-size: 18px;
}

.card-dashboard-seven .card-body .desc span {
    display: block;
    line-height: 1;
    margin-left: 5px;
}

.card-dashboard-seven .card-body .desc span strong {
    color: #1c273c;
}

.card-dashboard-seven .card-body .desc.up>i {
    color: var(--success);
}

.card-dashboard-seven .card-body .desc.down>i {
    color: #f34343;
}

@media (min-width: 576px) {
    .card-dashboard-seven .card-body h2 {
        font-size: 32px;
    }
}

.card-dashboard-six {
    border-color: #ededf5;
    padding: 20px;
}

.card-dashboard-six .card-header {
    padding: 0;
    background-color: transparent;
    margin-bottom: 20px;
}

.card-dashboard-six .main-content-label,
.card-dashboard-six .card-table-two .card-title {
    margin-bottom: 5px;
    font-size: 0.875rem;
}

@media (min-width: 992px) {
    .card-dashboard-six {
        height: 100%;
    }
}

@media (min-width: 576px) {
    .card-dashboard-six .card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

@media (min-width: 992px) {
    .card-dashboard-six .card-header {
        margin-bottom: 30px;
    }
}

.card-table-two .card-dashboard-six .card-title,
.card-dashboard-six .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-six .card-title {
    margin-bottom: 5px;
    font-size: 0.875rem;
}

.card-dashboard-six .chart-legend {
    margin-top: 20px;
    display: flex;
}

.card-dashboard-six .chart-legend>div {
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: 500;
}

.card-dashboard-six .chart-legend>div+div {
    margin-top: 2px;
}

.card-dashboard-six .chart-legend>div span:last-child {
    width: 8px;
    height: 8px;
    margin-left: 5px;
}

.card-dashboard-six .chart-legend>div span:first-child {
    width: 100px;
    text-align: right;
}

@media (min-width: 576px) {
    .card-dashboard-six .chart-legend {
        display: block;
        margin-top: 0;
    }
}

@media (min-width: 576px) {
    .card-dashboard-six .chart-legend>div span:last-child {
        margin-left: 10px;
        width: 20px;
    }
}

.card-dashboard-map-one {
    border-color: #ededf5;
    padding: 20px;
}

.card-dashboard-map-one .main-content-label,
.card-dashboard-map-one .card-table-two .card-title {
    font-size: 0.875rem;
}

.card-table-two .card-dashboard-map-one .card-title,
.card-dashboard-map-one .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-map-one .card-title {
    font-size: 0.875rem;
}

.card-dashboard-map-one .vmap-wrapper {
    height: 200px;
}

@media (min-width: 576px) {
    .card-dashboard-map-one .vmap-wrapper {
        height: 300px;
    }
}

@media (min-width: 992px) {
    .card-dashboard-map-one .vmap-wrapper {
        height: 270px;
    }
}

.card-table-two {
    border-color: #ededf5;
    padding: 20px;
}

.card-table-two .card-title {
    font-size: 13px;
    margin-bottom: 5px;
}

.card-table-two .table-responsive {
    width: calc(100vw - 62px);
}

@media (min-width: 576px) {
    .card-table-two .table-responsive {
        width: 100%;
    }
}

.table-dashboard-two thead>tr>th,
.table-dashboard-two thead>tr>td {
    white-space: nowrap;
}

.table-dashboard-two tbody>tr>th,
.table-dashboard-two tbody>tr>td {
    white-space: nowrap;
}

.table-dashboard-two tbody>tr>th:not(:first-child),
.table-dashboard-two tbody>tr>td:not(:first-child) {
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;
    font-size: 13px;
}

.card-dashboard-eight {
    border-color: #ededf5;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 20px;
}

.card-dashboard-eight .card-title {
    font-size: 13px;
    margin-bottom: 5px;
}

.card-dashboard-eight .list-group-item {
    padding: 10px 0;
    border-left-width: 0;
    border-right-width: 0;
    border-style: dotted;
    border-color: #ededf5;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.card-dashboard-eight .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.card-dashboard-eight .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.card-dashboard-eight .list-group-item p {
    margin-bottom: 0;
    margin-left: 10px;
}

.card-dashboard-eight .list-group-item span {
    display: block;
    margin-left: auto;
    font-weight: 600;
    font-family: "Archivo", Arial, sans-serif;
    color: #1c273c;
}

.card-dashboard-eight .flag-icon {
    font-size: 24px;
    line-height: 1;
    border-radius: 100%;
}

/* ###### 8.3 Dashboard Three  ###### */
.main-content-dashboard-three {
    position: relative;
    padding: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #fdfdfd;
}

@media (min-width: 992px) {
    .main-content-dashboard-three {
        border-left: 1px solid #ededf5;
        margin-left: 64px;
    }
}

.main-header-dashboard-three {
    height: auto;
}

.main-header-dashboard-three .container-fluid {
    padding: 15px 20px;
}

.main-header-dashboard-three .main-header-center {
    margin-left: 0;
}

.main-header-dashboard-three .main-header-right {
    margin-left: auto;
}

@media (min-width: 992px) {
    .main-header-dashboard-three .main-header-left {
        display: none;
    }

    .main-header-left .btn {
        position: absolute;
        top: 0;
        right: 11px;
        background-color: transparent;
        height: 40px;
        color: #fff;
        transition: none;
        font-size: 16px;
        padding: 5px 15px;
    }
}

.main-content-body-dashboard-three {
    padding: 20px;
    position: relative;
    width: 100%;
}

.main-content-body-dashboard-three .main-content-title {
    letter-spacing: -0.5px;
}

.card-dashboard-nine {
    border-color: #ededf5;
    background-color: rgba(255, 255, 255, 0.5);
    position: relative;
    padding: 20px 20px 15px;
}

.card-dashboard-nine .card-header {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    padding: 0;
    background-color: transparent;
    z-index: 5;
}

.card-dashboard-nine .card-header h1 {
    font-size: 32px;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    margin-bottom: 8px;
    line-height: 1;
    letter-spacing: -0.5px;
    color: #1c273c;
}

.card-dashboard-nine .main-content-label,
.card-dashboard-nine .card-table-two .card-title {
    font-size: 13px;
    margin-bottom: 10px;
    color: #4a4a69;
}

.card-table-two .card-dashboard-nine .card-title,
.card-dashboard-nine .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-nine .card-title {
    font-size: 13px;
    margin-bottom: 10px;
    color: #4a4a69;
}

.card-dashboard-nine .card-title {
    font-weight: 600;
}

.card-dashboard-nine .main-content-text {
    color: #7987a1;
}

.card-dashboard-nine .bar-chart {
    position: relative;
    width: auto;
    height: 200px;
}

@media (min-width: 992px) {
    .card-dashboard-nine .bar-chart {
        height: 210px;
    }
}

.card-dashboard-ten {
    border-width: 0;
    padding: 20px;
}

.card-dashboard-ten+.card-dashboard-ten {
    margin-top: 20px;
}

.card-dashboard-ten .main-content-label,
.card-dashboard-ten .card-table-two .card-title {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 15px;
    line-height: 1.05;
    color: #fff;
}

@media (min-width: 576px) {
    .card-dashboard-ten {
        width: calc(50% - 11px);
        display: inline-block;
    }
}

@media (min-width: 992px) {
    .card-dashboard-ten {
        width: auto;
        display: block;
    }
}

@media (min-width: 576px) {
    .card-dashboard-ten+.card-dashboard-ten {
        margin-top: 0;
        margin-left: 18px;
    }
}

@media (min-width: 992px) {
    .card-dashboard-ten+.card-dashboard-ten {
        margin-left: 0;
        margin-top: 20px;
    }
}

.card-table-two .card-dashboard-ten .card-title,
.card-dashboard-ten .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-ten .card-title {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 15px;
    line-height: 1.05;
    color: #fff;
}

.card-dashboard-ten .card-body {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-dashboard-ten .card-body>div {
    flex: 1;
}

.card-dashboard-ten .card-body>div label {
    font-size: 13px;
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.75);
}

.card-dashboard-ten .card-body>div h6 {
    margin-bottom: 2px;
    color: #fff;
    font-size: 32px;
    font-weight: 600;
    line-height: 0.975;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.card-dashboard-ten .card-body>div h6 span {
    font-size: 13px;
}

.card-dashboard-ten .card-body>div h6 .percent {
    font-size: 20px;
}

@media (min-width: 992px) {
    .card-dashboard-ten .card-body>div {
        flex: none;
    }
}

@media (min-width: 1360px) {
    .card-dashboard-ten .card-body>div:first-child {
        flex-basis: 60%;
    }

    .card-dashboard-ten .card-body>div:last-child {
        flex-basis: 40%;
    }
}

@media (min-width: 992px) {
    .card-dashboard-ten .card-body>div h6 {
        font-size: 24px;
    }
}

.card-dashboard-eleven {
    border-color: #ededf5;
    position: relative;
    overflow: hidden;
}

.card-dashboard-eleven .card-header {
    padding: 20px;
    background-color: transparent;
    position: relative;
    z-index: 200;
}

.card-dashboard-eleven .main-content-label,
.card-dashboard-eleven .card-table-two .card-title {
    display: block;
    font-size: 13px;
    margin-bottom: 5px;
    color: #3b4863;
    line-height: 1.4;
}

.card-table-two .card-dashboard-eleven .card-title,
.card-dashboard-eleven .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-eleven .card-title {
    display: block;
    font-size: 13px;
    margin-bottom: 5px;
    color: #3b4863;
    line-height: 1.4;
}

.card-dashboard-eleven .card-text {
    color: #7987a1;
}

.card-dashboard-eleven .card-body {
    padding: 0 0 5px;
    margin-left: 20px;
    position: relative;
    z-index: 200;
}

.card-dashboard-eleven .flot-chart {
    width: 100%;
    height: 250px;
}

.card-dashboard-eleven .card-footer {
    z-index: 200;
    background-color: transparent;
    padding: 0;
    border-width: 0;
}

.card-dashboard-eleven .card-footer>div {
    flex: 1;
    padding: 15px 20px;
    border-top: 1px solid #ededf5;
    position: relative;
}

.card-dashboard-eleven .card-footer label {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #596882;
    display: block;
}

.card-dashboard-eleven .card-footer h6 {
    font-size: 28px;
    font-weight: 600;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    line-height: 0.7;
    letter-spacing: -1px;
    margin-bottom: 10px;
    display: flex;
    align-items: baseline;
}

.card-dashboard-eleven .card-footer h6 span {
    margin-left: 5px;
    font-size: 11px;
    font-weight: 500;
    display: block;
    padding: 4px 6px;
    color: #fff;
    line-height: 0.8;
    position: relative;
    bottom: 3px;
    letter-spacing: normal;
}

.card-dashboard-eleven .card-footer h6 span.up {
    background-color: var(--success);
}

.card-dashboard-eleven .card-footer h6 span.down {
    background-color: #f34343;
}

.card-dashboard-eleven .card-footer small {
    display: block;
    font-size: 13px;
    color: #596882;
}

.card-dashboard-eleven .jqvmap {
    position: absolute;
    top: 10%;
    left: 0;
    width: 100%;
    height: 300px;
}

@media (min-width: 768px) {
    .card-dashboard-eleven .card-footer {
        display: flex;
        align-items: stretch;
    }
}

@media (min-width: 768px) {
    .card-dashboard-eleven .card-footer>div+div {
        border-left: 1px solid #ededf5;
    }
}

@media (min-width: 992px) {
    .card-dashboard-eleven .jqvmap {
        height: 80%;
    }
}

/* ###### 8.4 Dashboard Four  ###### */
.main-navbar-dashboard-four {
    box-shadow: 0 0 30px rgba(28, 39, 60, 0.08);
}

@media (min-width: 992px) {
    .main-navbar-dashboard-four .container-fluid {
        padding-left: 25px;
        padding-right: 25px;
    }
}

.main-content-dashboard-four {
    padding: 20px;
}

.main-content-dashboard-four~.main-footer .container,
.main-content-dashboard-four~.main-footer .container-fluid {
    padding-left: 25px;
    padding-right: 25px;
}

.main-content-dashboard-four>.media-dashboard {
    display: block;
}

.main-content-dashboard-four>.media-dashboard .media-aside {
    margin-top: 20px;
}

.main-content-dashboard-four .main-content-header {
    height: auto;
    margin-bottom: 20px;
    align-items: flex-start;
    padding: 0;
}

@media (min-width: 992px) {
    .main-content-dashboard-four {
        flex: 1;
        padding: 30px 25px;
    }
}

@media (min-width: 1200px) {
    .main-content-dashboard-four>.media-dashboard {
        display: flex;
        align-items: stretch;
        height: 100%;
    }
}

@media (min-width: 1200px) {
    .main-content-dashboard-four>.media-dashboard .media-aside {
        margin-top: 0;
        display: block;
        width: 320px;
    }
}

@media (min-width: 1200px) {
    .main-content-dashboard-four>.media-dashboard>.media-body {
        padding: 0 20px 0 0;
    }
}

@media (min-width: 768px) {
    .main-content-dashboard-four .main-content-header {
        display: flex;
    }
}

.main-content-header-right {
    display: none;
}

.main-content-header-right .media label {
    margin-bottom: 2px;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #97a3b9;
}

.main-content-header-right .media h6 {
    color: #1c273c;
    margin-bottom: 0;
}

.main-content-header-right .media+.media {
    margin-left: 20px;
    padding-left: 20px;
    border-left: 1px solid #ededf5;
}

.main-content-header-right .btn:first-of-type,
.main-content-header-right .sp-container button:first-of-type {
    margin-left: 30px;
}

@media (min-width: 992px) {
    .main-content-header-right {
        display: flex;
        align-items: center;
    }
}

.sp-container .main-content-header-right button:first-of-type {
    margin-left: 30px;
}

.main-content-header-right .btn+.btn,
.main-content-header-right .sp-container button+.btn {
    margin-left: 5px;
}

.sp-container .main-content-header-right button+.btn,
.main-content-header-right .sp-container .btn+button,
.sp-container .main-content-header-right .btn+button,
.main-content-header-right .sp-container button+button,
.sp-container .main-content-header-right button+button {
    margin-left: 5px;
}

.card-dashboard-calendar {
    border-color: #ededf5;
    padding: 20px;
    box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);
}

.card-dashboard-calendar .card-title {
    color: #1c273c;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.card-dashboard-calendar .card-body {
    padding: 0;
}

.card-dashboard-calendar .ui-datepicker-inline {
    border-width: 0;
    max-width: none;
    padding: 0;
    margin: 0;
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-header {
    border-bottom: 1px solid #ededf5;
    padding: 10px;
    margin-bottom: 5px;
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next::before,
.card-dashboard-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-prev::before {
    top: 8px;
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-month {
    color: var(--primary);
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar {
    width: calc(100% - 20px);
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar th {
    font-size: 10px;
    text-align: center;
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar td {
    text-align: center;
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar td a {
    display: block;
    width: 34px;
    height: 34px;
    font-size: 12px;
    font-family: "Archivo", sans-serif;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a {
    background-color: transparent;
    border: 2px solid var(--primary);
    color: var(--primary);
    font-weight: 700;
}

@media (min-width: 576px) {
    .card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar {
        width: 100%;
    }
}

.card-dashboard-twelve {
    border-color: #ededf5;
    box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);
}

.card-dashboard-twelve .card-header {
    background-color: transparent;
    border-bottom: 1px solid #e3e7ed;
    padding: 20px;
}

.card-dashboard-twelve .card-title {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 20px;
}

.card-dashboard-twelve .card-title span {
    font-weight: 400;
    color: #4a4a69;
    text-transform: capitalize;
}

.card-dashboard-twelve .sales-overview {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.card-dashboard-twelve .sales-overview .media {
    align-items: flex-end;
    flex-shrink: 0;
    flex-basis: calc(50% - 10px);
}

.card-dashboard-twelve .sales-overview .media+.media {
    margin-left: 20px;
}

.card-dashboard-twelve .sales-overview .media:last-child {
    display: none;
}

.card-dashboard-twelve .sales-overview .media-icon {
    width: 45px;
    height: 45px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -5px;
    margin-right: 15px;
    border-radius: 100%;
    display: none;
}

.card-dashboard-twelve .sales-overview .media-icon .typcn {
    line-height: 0.9;
    font-size: 24px;
}

.card-dashboard-twelve .sales-overview label {
    text-transform: uppercase;
    font-size: 9px;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: #4a4a69;
    margin-bottom: 8px;
}

.card-dashboard-twelve .sales-overview h4 {
    font-size: 20px;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;
    color: #1c273c;
    line-height: 1;
    letter-spacing: -1px;
    margin-bottom: 3px;
}

.card-dashboard-twelve .sales-overview h4 span {
    color: #4a4a69;
    font-weight: 400;
}

.card-dashboard-twelve .sales-overview .media-body>span {
    display: block;
    font-size: 10px;
    color: #97a3b9;
}

.card-dashboard-twelve .sales-overview .media-body>span strong {
    font-weight: 500;
    color: #4a4a69;
}

.card-dashboard-twelve .sales-overview p {
    font-size: 11px;
    margin-bottom: 0;
    color: #97a3b9;
}

.card-dashboard-twelve .card-body {
    position: relative;
    padding: 20px 5px;
}

@media (min-width: 992px) {
    .card-dashboard-twelve .sales-overview {
        margin-bottom: 10px;
        flex-wrap: nowrap;
    }
}

@media (min-width: 576px) {
    .card-dashboard-twelve .sales-overview .media {
        flex-basis: auto;
    }
}

@media (min-width: 576px) {
    .card-dashboard-twelve .sales-overview .media+.media {
        margin-left: 40px;
    }
}

@media (min-width: 992px) {
    .card-dashboard-twelve .sales-overview .media+.media {
        margin-left: 50px;
    }
}

@media (max-width: 575.98px) {
    .card-dashboard-twelve .sales-overview .media:nth-child(3) {
        margin-left: 0;
        margin-top: 15px;
    }
}

@media (min-width: 768px) {
    .card-dashboard-twelve .sales-overview .media:last-child {
        flex: 1;
        display: block;
    }
}

@media (min-width: 576px) {
    .card-dashboard-twelve .sales-overview label {
        font-size: 10px;
        margin-bottom: 10px;
    }
}

@media (min-width: 576px) {
    .card-dashboard-twelve .sales-overview h4 {
        font-size: 28px;
    }
}

@media (min-width: 576px) {
    .card-dashboard-twelve .sales-overview .media-body>span {
        font-size: 11px;
    }
}

@media (min-width: 576px) {
    .card-dashboard-twelve .card-body {
        padding: 20px;
    }
}

.chart-legend {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    z-index: 10;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.chart-legend div {
    display: flex;
    align-items: center;
}

.chart-legend div+div {
    margin-left: 15px;
}

.chart-legend span {
    width: 10px;
    height: 4px;
    display: block;
    margin-right: 5px;
}

@media (min-width: 576px) {
    .chart-legend div+div {
        margin-left: 30px;
    }
}

@media (min-width: 576px) {
    .chart-legend span {
        width: 40px;
        margin-right: 10px;
    }
}

.chart-wrapper {
    position: relative;
    margin-left: -13px;
    margin-right: -13px;
}

@media (min-width: 576px) {
    .chart-wrapper {
        margin-left: -10px;
        margin-right: -15px;
    }
}

.card-dashboard-twelve .flot-chart {
    width: 100%;
    height: 200px;
    position: relative;
}

.card-dashboard-twelve .flot-chart .flot-x-axis>div span:first-child {
    display: block;
    text-transform: uppercase;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 500;
    font-size: 10px;
    color: #7987a1;
}

.card-dashboard-twelve .flot-chart .flot-x-axis>div span:last-child {
    display: block;
    font-size: 16px;
    font-weight: 700;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    line-height: 1;
}

@media (min-width: 768px) {
    .card-dashboard-twelve .flot-chart {
        height: 250px;
    }
}

@media (min-width: 992px) {
    .card-dashboard-twelve .flot-chart {
        height: 300px;
    }
}

.main-media-date h1 {
    font-size: 42px;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    line-height: 0.95;
}

.main-media-date .media-body {
    margin-left: 5px;
}

.main-media-date p {
    color: #1c273c;
    font-weight: 700;
    margin-bottom: 0;
    text-transform: uppercase;
}

.main-media-date span {
    display: block;
    line-height: 1.2;
}

.card-dashboard-thirteen {
    border-color: #ededf5;
    padding: 20px;
    box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);
}

.card-dashboard-thirteen .main-content-label,
.card-dashboard-thirteen .card-table-two .card-title {
    font-size: 13px;
}

.card-table-two .card-dashboard-thirteen .card-title,
.card-dashboard-thirteen .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-thirteen .card-title {
    font-size: 13px;
}

.card-dashboard-thirteen .media {
    margin-bottom: 15px;
}

.card-dashboard-thirteen .media-icon {
    font-size: 45px;
    line-height: 1;
    margin-right: 10px;
    display: flex;
}

.card-dashboard-thirteen .media-icon i {
    line-height: 0;
}

.card-dashboard-thirteen .media-body h6 {
    font-size: 22px;
    font-weight: 600;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    letter-spacing: -0.5px;
    margin-bottom: 2px;
}

.card-dashboard-thirteen .media-body h6 span {
    font-weight: 400;
    color: #4a4a69;
}

.card-dashboard-thirteen .media-body label {
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 0;
    display: block;
    letter-spacing: 0.5px;
}

.card-dashboard-events .card-header {
    padding: 20px;
    background-color: transparent;
}

.card-dashboard-events .card-subtitle {
    color: #1c273c;
    font-weight: 400;
    font-size: 18px;
}

.card-dashboard-events .card-body {
    padding: 0;
}

.card-dashboard-events .list-group-item {
    padding: 15px 20px 15px 40px;
    border-left-width: 0;
    border-right-width: 0;
    position: relative;
}

.card-dashboard-events .list-group-item:first-child,
.card-dashboard-events .list-group-item:last-child {
    border-radius: 0;
}

.card-dashboard-events .list-group-item label {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    color: #596882;
    margin-bottom: 5px;
    letter-spacing: 0.5px;
}

.card-dashboard-events .list-group-item label span {
    color: #97a3b9;
    font-weight: 500;
}

.card-dashboard-events .list-group-item h6 {
    font-size: 14px;
    font-weight: 700;
    color: #1c273c;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}

.card-dashboard-events .list-group-item p {
    margin-bottom: 5px;
}

.card-dashboard-events .list-group-item p strong {
    font-weight: 500;
}

.card-dashboard-events .list-group-item small {
    font-size: 11px;
}

.card-dashboard-events .list-group-item small span {
    font-weight: 700;
    text-transform: uppercase;
}

.card-dashboard-events .event-indicator {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 21px;
    left: 20px;
    border-radius: 100%;
}

.card-dashboard-events .event-user {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.card-dashboard-events .event-user .main-img-user {
    width: 28px;
    height: 28px;
}

.card-dashboard-events .event-user .main-img-user::after {
    display: none;
}

.main-img-user.online::after {
    background-color: var(--success);
}

.card-dashboard-events .event-user .main-img-user+.main-img-user {
    margin-left: -6px;
}

.card-dashboard-events .event-user a:last-child {
    margin-left: 10px;
    color: #7987a1;
    font-weight: 500;
    font-size: 11px;
}

.card-dashboard-events .event-user a:last-child:hover,
.card-dashboard-events .event-user a:last-child:focus {
    color: #596882;
}

.card-dashboard-fourteen {
    border-color: #ededf5;
    box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);
    padding: 20px;
}

.card-dashboard-fourteen .main-content-label,
.card-dashboard-fourteen .card-table-two .card-title {
    font-size: 0.875rem;
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .card-dashboard-fourteen {
        height: 100%;
    }
}

.card-table-two .card-dashboard-fourteen .card-title,
.card-dashboard-fourteen .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-fourteen .card-title {
    font-size: 0.875rem;
    margin-bottom: 20px;
}

.card-dashboard-fourteen .main-content-label span,
.card-dashboard-fourteen .card-table-two .card-title span {
    font-weight: 400;
    color: #97a3b9;
    text-transform: none;
}

.card-table-two .card-dashboard-fourteen .card-title span,
.card-dashboard-fourteen .card-dashboard-eight .card-title span,
.card-dashboard-eight .card-dashboard-fourteen .card-title span {
    font-weight: 400;
    color: #97a3b9;
    text-transform: none;
}

.card-dashboard-fourteen .card-body {
    padding: 0;
    min-height: 100px;
}

.card-dashboard-fourteen .card-body h1 {
    color: #1c273c;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;
    letter-spacing: -1px;
    margin-bottom: 0;
}

.card-dashboard-fourteen .card-body h1 span {
    color: #97a3b9;
    letter-spacing: normal;
    font-weight: 400;
    margin-right: 5px;
}

.card-dashboard-fourteen .card-body h4 {
    color: #1c273c;
    font-weight: 700;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    margin-bottom: 0;
    letter-spacing: -0.5px;
}

.card-dashboard-fourteen .card-body .progress {
    margin-bottom: 2px;
}

.card-dashboard-fourteen .card-body small {
    font-size: 11px;
    color: #97a3b9;
}

.card-dashboard-fourteen .sparkline-wrapper canvas {
    width: 100% !important;
}

.card-dashboard-fourteen .card-footer {
    background-color: transparent;
    padding: 0;
    margin-top: 15px;
    border-top-width: 0;
}

.card-dashboard-fourteen .card-footer h6 {
    color: #1c273c;
    font-size: 20px;
    font-weight: 700;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    margin-bottom: 0;
    letter-spacing: -0.5px;
}

.card-dashboard-fourteen .card-footer label {
    display: block;
    margin-bottom: 0;
    color: #97a3b9;
    font-size: 11px;
}

@media (min-width: 576px) {
    .card-dashboard-fourteen .card-footer label {
        font-size: 0.875rem;
    }
}

/* ###### 8.5 Dashboard Five  ###### */
.main-body-sidebar.main-light .main-sidebar {
    background-color: #f4f5f8;
    border-right-color: #ededf5;
    box-shadow: 2px 0 3px rgba(28, 39, 60, 0.04);
}

.main-content-dashboard-five {
    background-color: #eeeff4;
    transition: all 0.4s;
}

.main-content-dashboard-five .main-header {
    background-color: var(--primary02);
    box-shadow: 0 2px 3px rgba(28, 39, 60, 0.05);
}

.main-content-dashboard-five .main-header-menu-icon {
    margin-right: 0;
}

.main-content-dashboard-five .main-header-center .form-control {
    border-color: #ededf5;
}

.main-content-dashboard-five .main-content-header {
    padding: 20px 20px 0;
}

.main-content-dashboard-five .main-content-body {
    padding: 20px;
}

.main-content-dashboard-five .card {
    background-color: var(--primary02);
    border-color: #ededf5;
    box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);
}

.main-content-dashboard-five .card-header {
    padding: 20px 20px 0;
    background-color: transparent;
}

.main-content-dashboard-five .card-text {
    font-size: 12px;
    line-height: 1.4;
    color: #97a3b9;
    margin-bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
    .main-content-dashboard-five {
        transition: none;
    }
}

@media (min-width: 1200px) {
    .main-content-dashboard-five .main-content-header {
        padding: 25px 25px 0;
    }
}

.card-dashboard-fifteen {
    padding: 20px 20px 0;
    overflow: hidden;
}

.card-dashboard-fifteen h1 {
    font-size: 40px;
    font-weight: 500;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    margin-bottom: 0;
    line-height: 1;
}

.card-dashboard-fifteen h1 span {
    display: inline;
    font-size: 18px;
    color: #7987a1;
}

.card-dashboard-fifteen label {
    font-size: 14px;
    font-weight: 500;
    display: block;
}

.card-dashboard-fifteen span {
    color: #97a3b9;
    display: block;
    font-size: 12px;
}

.card-dashboard-fifteen .chart-wrapper {
    position: relative;
    margin-left: -32px;
    margin-right: -32px;
    margin-bottom: -10px;
}

.card-dashboard-fifteen .flot-chart {
    width: 100%;
    height: 127px;
    position: relative;
    margin-top: 20px;
}

.card-dashboard-fifteen .flot-chart .flot-x-axis>div:nth-child(4) {
    color: #1c273c !important;
}

.table-talk-time {
    margin-bottom: 0;
}

.table-talk-time thead>tr th,
.table-talk-time thead>tr td {
    padding: 7px 10px;
}

.table-talk-time tbody>tr th,
.table-talk-time tbody>tr td {
    padding: 7px 10px;
}

.table-talk-time thead>tr th:first-child,
.table-talk-time thead>tr td:first-child {
    padding-left: 20px;
}

.table-talk-time tbody>tr th:first-child,
.table-talk-time tbody>tr td:first-child {
    padding-left: 20px;
}

.card-dashboard-sixteen .main-img-user::after {
    display: none;
}

.card-dashboard-sixteen .table tbody>tr {
    background-color: transparent;
}

.card-dashboard-sixteen .table tbody>tr th,
.card-dashboard-sixteen .table tbody>tr td {
    padding: 10px 10px 10px 0;
    vertical-align: middle;
    white-space: nowrap;
}

.card-dashboard-sixteen .table tbody>tr th:first-child,
.card-dashboard-sixteen .table tbody>tr td:first-child {
    width: 10%;
}

.card-dashboard-sixteen .table tbody>tr th:nth-child(2),
.card-dashboard-sixteen .table tbody>tr td:nth-child(2) {
    width: 60%;
}

.card-dashboard-sixteen .table tbody>tr th:last-child,
.card-dashboard-sixteen .table tbody>tr td:last-child {
    width: 30%;
}

.dashboard-five-stacked-chart {
    height: 200px;
}

@media (min-width: 768px) {
    .dashboard-five-stacked-chart {
        height: 240px;
    }
}

@media (min-width: 847px) {
    .dashboard-five-stacked-chart {
        height: 260px;
    }
}

@media (min-width: 992px) {
    .dashboard-five-stacked-chart {
        height: 240px;
    }
}

@media (min-width: 1067px) {
    .dashboard-five-stacked-chart {
        height: 260px;
    }
}

/* ###### 8.6 Dashboard Six  ###### */
.main-light .main-iconbar,
.main-light .main-iconbar-aside {
    background-color: #e3e7ed;
}

.main-light .main-iconbar-body .nav {
    border-left-color: #ededf5;
}

.main-light .main-iconbar-body .nav-item+.nav-item,
.main-light .main-iconbar-body .nav-sub,
.main-light .main-iconbar-body .nav-sub-item+.nav-sub-item {
    border-top-color: #bdc6d6;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .main-light .main-iconbar-aside.show+.main-content {
        box-shadow: none;
    }
}

.main-content-dashboard-six {
    border-left-color: #ededf5;
    box-shadow: 0 0 7px rgba(28, 39, 60, 0.08);
    position: relative;
    padding: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #f4f5f8;
}

@media (min-width: 992px) {
    .main-content-dashboard-six {
        border-left: 1px solid #ededf5;
        margin-left: 64px;
        z-index: 550;
    }
}

.main-header-dashboard-six {
    background-color: #fff;
    border-bottom: 1px solid #ededf5;
    box-shadow: 0 0 10px rgba(28, 39, 60, 0.06);
}

.main-header-dashboard-six .main-header-menu-icon {
    margin-right: 0;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .main-header-dashboard-six .main-header-center {
        margin-left: 5px;
    }
}

@media (min-width: 1200px) {
    .main-header-dashboard-six .main-header-center {
        margin-left: 0;
    }
}

@media (min-width: 992px) {
    .main-header-dashboard-six .main-header-menu-icon {
        display: none;
    }
}

.main-content-body-dashboard-six {
    padding: 25px;
}

.main-content-body-dashboard-six .main-content-title {
    letter-spacing: -0.8px;
}

.main-content-body-dashboard-six .card {
    border-color: #ededf5;
    box-shadow: 0 0 10px rgba(28, 39, 60, 0.06);
}

.main-content-body-dashboard-six .card-header {
    background-color: transparent;
    padding: 20px 20px 0;
}

.main-content-body-dashboard-six .card-title {
    text-transform: uppercase;
    font-weight: 700;
}

.card-dashboard-donut .card-body {
    padding: 25px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-dashboard-donut .main-donut-chart.chart1 {
    width: 140px;
    height: 140px;
    background: #ededf5;
}

.card-dashboard-donut .main-donut-chart.chart1 .slice.one {
    clip: rect(0 140px 70px 0);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    background: #6f42c1;
}

.card-dashboard-donut .main-donut-chart.chart1 .slice.two {
    clip: rect(0 70px 140px 0);
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    background: #6f42c1;
}

.card-dashboard-donut .main-donut-chart.chart1 .chart-center {
    top: 10px;
    left: 10px;
    width: 120px;
    height: 120px;
    background: #fff;
}

.card-dashboard-donut .main-donut-chart.chart1 .chart-center span {
    font-size: 40px;
    line-height: 120px;
    color: #6f42c1;
}

.card-dashboard-donut .main-donut-chart.chart1 .chart-center span:after {
    content: "75%";
}

.card-dashboard-donut .main-donut-chart.chart2 {
    width: 140px;
    height: 140px;
    background: #ededf5;
}

.card-dashboard-donut .main-donut-chart.chart2 .slice.one {
    clip: rect(0 140px 70px 0);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    background: var(--primary);
}

.card-dashboard-donut .main-donut-chart.chart2 .slice.two {
    clip: rect(0 70px 140px 0);
    -webkit-transform: rotate(244.8deg);
    transform: rotate(244.8deg);
    background: var(--primary);
}

.card-dashboard-donut .main-donut-chart.chart2 .chart-center {
    top: 10px;
    left: 10px;
    width: 120px;
    height: 120px;
    background: #fff;
}

.card-dashboard-donut .main-donut-chart.chart2 .chart-center span {
    font-size: 40px;
    line-height: 120px;
    color: var(--primary);
}

.card-dashboard-donut .main-donut-chart.chart2 .chart-center span:after {
    content: "68%";
}

.card-dashboard-donut .main-donut-chart.chart1 .chart-center span,
.card-dashboard-donut .main-donut-chart.chart2 .chart-center span {
    font-weight: 600;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 32px;
}

.card-dashboard-balance {
    position: relative;
    overflow: hidden;
}

.card-dashboard-balance .card-body {
    padding: 20px;
    position: relative;
    z-index: 5;
}

.card-dashboard-balance .fab {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 48px;
    color: var(--primary);
    z-index: 5;
    background-color: #fff;
}

.card-dashboard-balance .main-content-label,
.card-dashboard-balance .card-table-two .card-title {
    color: #7987a1;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px;
}

@media (min-width: 576px) {
    .card-dashboard-balance {
        min-height: 260px;
        height: 100%;
    }
}

@media (min-width: 576px) {
    .card-dashboard-balance .card-body {
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.card-table-two .card-dashboard-balance .card-title,
.card-dashboard-balance .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-balance .card-title {
    color: #7987a1;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px;
}

@media (min-width: 576px) {

    .card-dashboard-balance .main-content-label,
    .card-dashboard-balance .card-table-two .card-title {
        font-size: 11px;
    }

    .card-table-two .card-dashboard-balance .card-title,
    .card-dashboard-balance .card-dashboard-eight .card-title,
    .card-dashboard-eight .card-dashboard-balance .card-title {
        font-size: 11px;
    }
}

.card-dashboard-balance .balance-amount {
    color: #1c273c;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;
    font-size: 28px;
    letter-spacing: -1px;
    margin-bottom: 20px;
}

.card-dashboard-balance .balance-amount span {
    color: #97a3b9;
    font-weight: 400;
    letter-spacing: normal;
}

.card-dashboard-balance .account-number {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    letter-spacing: -1px;
    margin-bottom: 20px;
}

.card-dashboard-balance .account-number span {
    display: flex;
    align-items: center;
    line-height: 1;
}

.card-dashboard-balance .account-number span+span {
    margin-left: 20px;
}

.card-dashboard-balance .account-number span i {
    width: 5px;
    height: 5px;
    background-color: #1c273c;
    border-radius: 100%;
}

.card-dashboard-balance .account-number span i+i {
    margin-left: 5px;
}

.card-dashboard-balance .account-name {
    color: #1c273c;
    font-weight: 400;
    margin-bottom: 0;
}

.card-dashboard-balance .chart-wrapper {
    position: absolute;
    top: 20px;
    bottom: 10px;
    left: -10px;
    right: -10px;
    opacity: 0.3;
    z-index: 4;
}

.card-dashboard-balance .flot-chart {
    width: 100%;
    height: 100%;
}

@media (min-width: 576px) {
    .card-dashboard-balance .balance-amount {
        font-size: 36px;
    }
}

@media (min-width: 576px) {
    .card-dashboard-balance .account-number {
        font-size: 28px;
    }
}

@media (min-width: 576px) {
    .card-dashboard-balance .account-number span+span {
        margin-left: 30px;
    }
}

@media (min-width: 1200px) {
    .card-dashboard-balance .account-number span+span {
        margin-left: 50px;
    }
}

@media (min-width: 576px) {
    .card-dashboard-balance .account-number span i {
        width: 10px;
        height: 10px;
    }
}

@media (min-width: 1200px) {
    .card-dashboard-ratio {
        height: 350px;
    }
}

.card-dashboard-ratio .card-body {
    display: flex;
    align-items: center;
}

.card-dashboard-ratio .card-body+.card-body {
    padding-top: 0px;
}

.card-dashboard-ratio .card-body>div:first-child {
    width: 140px;
    display: none;
    align-items: center;
    justify-content: center;
}

.card-dashboard-ratio .card-body>div:last-child {
    flex: 1;
}

.card-dashboard-ratio .card-body h5 {
    font-size: 28px;
    font-weight: 600;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    letter-spacing: -1px;
    margin-bottom: 2px;
}

.card-dashboard-ratio .card-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ededf5;
    font-size: 140px;
}

.card-dashboard-ratio .card-icon .typcn {
    line-height: 1;
}

.card-dashboard-ratio .card-icon .typcn::before {
    width: auto;
}

@media (min-width: 576px) {
    .card-dashboard-ratio .card-body>div:first-child {
        display: flex;
    }
}

@media (min-width: 576px) {
    .card-dashboard-ratio .card-body>div:last-child {
        margin-left: 30px;
    }
}

.card-dashboard-finance {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.card-dashboard-finance .card-title {
    margin-bottom: 20px;
}

.card-dashboard-finance h2 {
    line-height: 1;
    color: #1c273c;
    font-weight: 600;
    font-size: 30px;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    letter-spacing: -1px;
    margin-top: 10px;
    margin-bottom: 2px;
}

.card-dashboard-finance h2 span {
    letter-spacing: normal;
    color: #97a3b9;
    font-weight: 400;
    margin-right: 5px;
}

@media (min-width: 992px) {
    .card-dashboard-finance {
        min-height: 172px;
    }
}

@media (min-width: 1200px) {
    .card-dashboard-finance {
        min-height: 165px;
    }
}

@media (min-width: 992px) {
    .card-dashboard-finance .card-title {
        margin-bottom: auto;
    }
}

.card-dashboard-table-six .card-title {
    padding: 15px 20px;
    margin-bottom: 0;
    border-bottom: 1px solid #ededf5;
}

.card-dashboard-table-six .table-responsive {
    width: calc(100vw - 42px);
}

.card-dashboard-table-six .table {
    margin-bottom: 0;
}

.card-dashboard-table-six .table thead tr th {
    white-space: nowrap;
}

.card-dashboard-table-six .table thead tr:first-child {
    background-color: #e3e7ed;
}

.card-dashboard-table-six .table thead tr:first-child th {
    padding: 10px 12px;
    line-height: 1;
}

.card-dashboard-table-six .table thead tr:first-child th:last-child {
    border-left: 1px solid #ededf5;
}

.card-dashboard-table-six .table thead tr:last-child th {
    width: 13%;
    padding: 10px 12px;
    line-height: 1;
    text-align: right;
}

.card-dashboard-table-six .table thead tr:last-child th:first-child {
    width: 22%;
}

.card-dashboard-table-six .table thead tr:last-child th:nth-child(2),
.card-dashboard-table-six .table thead tr:last-child th:nth-child(6) {
    border-left: 1px solid #ededf5;
}

.card-dashboard-table-six .table tbody tr td {
    text-align: right;
    white-space: nowrap;
}

.card-dashboard-table-six .table tbody tr td:first-child {
    text-align: left;
}

.card-dashboard-table-six .table tbody tr td:nth-child(2),
.card-dashboard-table-six .table tbody tr td:nth-child(6) {
    border-left: 1px solid #ededf5;
}

.card-dashboard-table-six .table tbody tr:last-child td {
    font-weight: 500;
    color: #1c273c;
}

@media (min-width: 992px) {
    .card-dashboard-table-six .table-responsive {
        width: 100%;
    }
}

/* ###### 8.7 Dashboard Seven  ###### */
.main-minimal {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #fff;
}

.main-minimal .main-navbar-three .nav-item.active::before {
    bottom: -3px;
}

.main-minimal .main-navbar-three .nav-item.active .nav-link {
    font-weight: 700;
}

.main-minimal .main-navbar-three .nav-link {
    font-size: 13px;
}

.main-minimal.main-navbar-show .main-header {
    z-index: auto;
}

@media (min-width: 1200px) {
    .main-minimal {
        padding: 10px 0 0;
    }
}

.main-content-title-minimal {
    color: #1c273c;
    font-weight: 500;
    font-size: 22px;
    letter-spacing: -1px;
    line-height: 1;
    margin-bottom: 8px;
}

.main-content-title-minimal span {
    font-weight: 700;
}

.main-content-text-minimal {
    font-weight: 400;
    color: #596882;
    margin-bottom: 20px;
}

.media-dashboard-one {
    display: block;
}

.media-dashboard-one .media-right {
    width: 100%;
    flex-shrink: 0;
}

.media-dashboard-one .media-body {
    margin-bottom: 20px;
}

@media (min-width: 992px) {
    .media-dashboard-one {
        display: flex;
        align-items: stretch;
    }
}

@media (min-width: 992px) {
    .media-dashboard-one .media-right {
        width: 280px;
    }
}

@media (min-width: 992px) {
    .media-dashboard-one .media-body {
        margin-right: 20px;
        margin-bottom: 0;
    }
}

.card-minimal-one {
    border-color: #ededf5;
    min-height: 320px;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.card-minimal-one .card-body {
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 5;
}

.card-minimal-one .card-body>label {
    font-weight: 700;
    font-size: 14px;
    color: var(--primary);
    text-transform: uppercase;
    margin-bottom: 5px;
    display: block;
    line-height: 1;
}

.card-minimal-one .card-body>label span {
    font-weight: 500;
    color: #596882;
}

.card-minimal-one .card-body>small {
    font-size: 12px;
    display: block;
    color: #97a3b9;
}

.card-minimal-one .card-body>h6 {
    color: #1c273c;
    font-size: 36px;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;
    letter-spacing: -2px;
    display: flex;
    line-height: 1;
    align-items: flex-end;
    margin: 15px 0;
}

.card-minimal-one .card-body>h6 span {
    font-weight: 400;
    margin-right: 3px;
    color: #4a4a69;
}

.card-minimal-one .value-info {
    display: flex;
    align-items: center;
}

.card-minimal-one .value-info+.value-info {
    margin-top: 5px;
}

.card-minimal-one .value-info p {
    margin-bottom: 0;
    color: #1c273c;
    font-weight: 700;
    font-family: "Archivo", sans-serif;
    font-size: 14px;
    letter-spacing: -0.35px;
}

.card-minimal-one .value-info p span {
    letter-spacing: normal;
    margin-right: 2px;
    color: #4a4a69;
}

.card-minimal-one .value-info label {
    display: block;
    margin-bottom: 0;
    font-weight: 700;
    width: 40px;
    position: relative;
    top: -2px;
}

.card-minimal-one .open-info {
    display: flex;
    align-items: flex-start;
    margin-top: auto;
    margin-bottom: 25px;
}

.card-minimal-one .open-info>div+div {
    margin-left: 25px;
}

.card-minimal-one .open-info label {
    font-size: 12px;
    margin-bottom: 2px;
    color: #7f8da9;
}

.card-minimal-one .open-info h6 {
    margin-bottom: 0;
    color: #1c273c;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    font-size: 13px;
}

.card-minimal-one .open-info h6 span {
    font-weight: 400;
}

.card-minimal-one .chart-wrapper {
    position: absolute;
    left: -9px;
    right: -8px;
    bottom: -13px;
}

.card-minimal-one .chart-wrapper .flot-x-axis>div {
    transform: translate(20px, -23px);
}

.card-minimal-one .flot-chart {
    width: 100%;
    height: 250px;
    position: relative;
}

.card-minimal-two {
    height: 72px;
    border-color: #ededf5;
    background-color: transparent;
    overflow: hidden;
}

.card-minimal-two .card-header {
    background-color: transparent;
    padding: 15px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ededf5;
}

.card-minimal-two .card-header>.nav .nav-link {
    padding: 8px 18px;
    font-weight: 500;
    background-color: #e3e7ed;
}

.card-minimal-two .card-header>.nav .nav-link+.nav-link {
    margin-left: 2px;
}

.card-minimal-two .card-header>.nav .nav-link:hover,
.card-minimal-two .card-header>.nav .nav-link:focus {
    background-color: #ededf5;
}

.card-minimal-two .card-header>.nav .nav-link.active {
    background-color: var(--primary);
    color: #fff;
}

.card-minimal-two .card-header-right {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.card-minimal-two .card-header-right .nav {
    justify-content: flex-end;
    width: 100%;
}

.card-minimal-two .card-header-right .nav-link {
    padding: 5px 10px;
    text-transform: capitalize;
}

.card-minimal-two .card-header-right .nav-link+.nav-link {
    margin-left: 2px;
}

.card-minimal-two .card-header-right .nav-link span:first-child {
    display: none;
}

.card-minimal-two .card-header-right .nav-link span:last-child {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
}

.card-minimal-two .card-header-right .nav-link:hover,
.card-minimal-two .card-header-right .nav-link:focus {
    background-color: #e3e7ed;
}

.card-minimal-two .card-header-right .nav-link.active {
    background-color: #97a3b9;
    color: #fff;
}

.card-minimal-two .card-body {
    position: relative;
    overflow: hidden;
    padding: 0;
}

.card-minimal-two .flot-wrapper {
    position: relative;
    bottom: -14px;
    margin-right: -9px;
    margin-left: -31px;
    margin-top: -25px;
}

.card-minimal-two .flot-wrapper .flot-x-axis>div {
    transform: translate(22px, -23px);
}

.card-minimal-two .flot-wrapper .flot-y-axis>div {
    transform: translateX(-10px);
}

.card-minimal-two .flot-chart {
    width: 100%;
    height: 250px;
    position: relative;
}

.card-minimal-two .more-info {
    position: absolute;
    top: 10px;
    right: 10px;
}

.card-minimal-two .more-info div {
    display: flex;
    align-items: flex-start;
    font-size: 13px;
}

.card-minimal-two .more-info span:first-child {
    width: 80px;
    text-align: right;
    color: #7987a1;
    display: block;
    line-height: 1.35;
}

.card-minimal-two .more-info span:last-child {
    margin-left: 15px;
    color: #1c273c;
    font-weight: 700;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 14px;
}

.card-minimal-two .card-footer {
    padding: 20px;
    position: relative;
    z-index: 2;
    overflow-y: hidden;
    width: calc(100vw - 40px);
    margin-bottom: -8px;
}

@media (min-width: 576px) {
    .card-minimal-two .card-header {
        display: flex;
        padding: 15px 20px;
    }
}

@media (min-width: 992px) {
    .card-minimal-two .card-header>.nav .nav-link {
        padding: 8px 20px;
    }
}

@media (min-width: 1200px) {
    .card-minimal-two .card-header>.nav .nav-link {
        padding: 8px 30px;
    }
}

@media (min-width: 576px) {
    .card-minimal-two .card-header-right {
        margin-top: 0;
    }
}

@media (min-width: 576px) {
    .card-minimal-two .card-header-right .nav-link {
        padding: 8px 10px;
    }
}

@media (min-width: 768px) {
    .card-minimal-two .card-header-right .nav-link span:first-child {
        display: inline;
    }
}

@media (min-width: 768px) {
    .card-minimal-two .card-header-right .nav-link span:last-child {
        display: none;
    }
}

@media (min-width: 992px) {
    .card-minimal-two .card-body {
        padding: 0 20px;
    }
}

@media (min-width: 992px) {
    .card-minimal-two .flot-wrapper {
        margin-right: -9px;
        margin-left: 10px;
        margin-top: 0;
    }
}

@media (min-width: 992px) {
    .card-minimal-two .flot-chart {
        height: 300px;
    }
}

@media (min-width: 576px) {
    .card-minimal-two .more-info {
        right: 20px;
        top: 30px;
    }
}

@media (min-width: 992px) {
    .card-minimal-two .more-info {
        right: 30px;
        top: 50px;
    }
}

@media (min-width: 992px) {
    .card-minimal-two .card-footer {
        width: auto;
    }
}

.card-bitcoin .media-icon {
    color: #f7931a;
    font-size: 42px;
    line-height: 0;
}

.card-bitcoin .media-body {
    margin-left: 20px;
    margin-bottom: 10px;
}

.card-bitcoin .row {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
}

.card-bitcoin .row>div {
    flex-shrink: 0;
}

.card-bitcoin label {
    margin-bottom: 2px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.5px;
    color: #7987a1;
}

.card-bitcoin p {
    margin-bottom: 0;
    color: #1c273c;
    font-weight: 700;
    font-size: 16px;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.card-minimal-three {
    border-color: #ededf5;
    width: calc(100vw - 38px);
}

.card-minimal-three .card-header {
    border-bottom: 1px solid #ededf5;
    background-color: transparent;
    padding: 20px 20px 0;
}

.card-minimal-three .card-header .main-content-text {
    margin-bottom: 20px;
}

.card-minimal-three .main-nav-tabs {
    padding: 0;
    background-color: transparent;
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 3px;
}

.card-minimal-three .main-nav-tabs .lSAction>a {
    background-color: #ededf5;
}

.card-minimal-three .main-nav-tabs .lSAction>a::before {
    top: -4px;
}

.card-minimal-three .main-nav-tabs .lSAction>a.lSPrev {
    left: -35px;
}

.card-minimal-three .main-nav-tabs .tab-item {
    min-width: 203px;
}

.card-minimal-three .main-nav-tabs .tab-link {
    background-color: #e3e7ed;
    font-weight: 500;
    align-items: flex-start;
    padding-top: 8px;
}

.card-minimal-three .main-nav-tabs .tab-link:hover {
    background-color: #ededf5;
}

.card-minimal-three .main-nav-tabs .tab-link.active {
    background-color: #596882;
    color: #fff;
}

.card-minimal-three .main-nav-tabs .tab-link.active:hover,
.card-minimal-three .main-nav-tabs .tab-link.active:focus {
    background-color: #596882;
    color: #fff;
}

.card-minimal-three .card-body {
    padding: 0;
}

.card-minimal-three .tab-pane-header {
    padding: 20px;
    background-color: #fff;
    border-bottom: 1px solid #ededf5;
}

.card-minimal-three .tab-pane-header .main-content-label,
.card-minimal-three .tab-pane-header .card-table-two .card-title {
    margin-bottom: 3px;
}

@media (min-width: 992px) {
    .card-minimal-three {
        width: 634px;
    }
}

@media (min-width: 1200px) {
    .card-minimal-three {
        width: 753px;
    }
}

@media (min-width: 576px) {
    .card-minimal-three .main-nav-tabs .tab-item {
        min-width: 150px;
    }
}

@media (min-width: 576px) {
    .card-minimal-three .tab-pane-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.card-table-two .card-minimal-three .tab-pane-header .card-title,
.card-minimal-three .tab-pane-header .card-dashboard-eight .card-title,
.card-dashboard-eight .card-minimal-three .tab-pane-header .card-title {
    margin-bottom: 3px;
}

.card-minimal-three .tab-pane-header>div:last-child {
    display: flex;
    margin-top: 15px;
}

.card-minimal-three .tab-pane-header>div:last-child a {
    flex: 1;
}

.card-minimal-three .tab-pane-header>div:last-child a+a {
    margin-left: 10px;
}

.card-minimal-three .tab-pane-body {
    padding: 20px;
}

.card-minimal-three .tab-pane-body .table thead tr th,
.card-minimal-three .tab-pane-body .table thead tr td {
    white-space: nowrap;
}

.card-minimal-three .tab-pane-body .table tbody tr th,
.card-minimal-three .tab-pane-body .table tbody tr td {
    white-space: nowrap;
}

.card-minimal-three .no-transactions-panel {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.card-minimal-three .no-transactions-panel .icon-wrapper {
    width: 80px;
    height: 80px;
    background-color: #e3e7ed;
    color: #97a3b9;
    font-size: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    border-radius: 100%;
}

.card-minimal-three .no-transactions-panel .icon-wrapper i {
    line-height: 0;
}

.card-minimal-three .no-transactions-panel h6 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 15px;
    color: #1c273c;
    letter-spacing: 1px;
    margin-bottom: 2px;
}

.card-minimal-three .table tbody tr td {
    vertical-align: middle;
}

.card-minimal-three .table tbody tr td span {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
}

.card-minimal-three .table tbody tr td:last-child {
    font-weight: 500;
    color: #1c273c;
}

.card-crypto-portfolio {
    width: 100%;
    border-color: #ededf5;
    margin-top: 20px;
}

.card-crypto-portfolio .card-title {
    text-transform: uppercase;
}

.card-crypto-portfolio .table {
    margin-bottom: 0;
}

.card-crypto-portfolio .table thead tr th,
.card-crypto-portfolio .table thead tr td {
    padding: 10px 0;
    text-align: right;
    white-space: nowrap;
}

.card-crypto-portfolio .table tbody tr th,
.card-crypto-portfolio .table tbody tr td {
    padding: 10px 0;
    text-align: right;
    white-space: nowrap;
}

.card-crypto-portfolio .table thead tr th:first-child,
.card-crypto-portfolio .table thead tr td:first-child {
    text-align: left;
}

.card-crypto-portfolio .table tbody tr th:first-child,
.card-crypto-portfolio .table tbody tr td:first-child {
    text-align: left;
}

.card-crypto-portfolio .table thead tr th {
    font-size: 10px;
}

.card-crypto-portfolio .table tbody tr td:first-child {
    font-weight: 500;
    color: #1c273c;
}

@media (min-width: 992px) {
    .card-crypto-portfolio {
        width: calc(33.33333% - 13px);
        margin-top: 0;
    }
}

.card-minimal-four {
    border-color: #ededf5;
}

.card-minimal-four .card-header {
    background-color: transparent;
    border-bottom: 1px solid #ededf5;
}

.card-minimal-four .card-header h6 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 2px;
    color: #1c273c;
}

.card-minimal-four .card-header p {
    margin-bottom: 0;
    white-space: nowrap;
}

.card-minimal-four .card-header p span {
    color: #f34343;
}

.card-minimal-four .card-header .media {
    align-items: center;
}

.card-minimal-four .card-header .media-body {
    margin-left: 12px;
}

.card-minimal-four .coin-logo {
    width: 42px;
    height: 42px;
    color: #fff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}

.card-minimal-four .coin-logo i {
    line-height: 0;
}

.card-minimal-four .card-body {
    padding: 0;
    overflow: hidden;
    position: relative;
    background-color: var(--primary02);
}

.card-minimal-four .card-body-top {
    top: 15px;
    left: 10px;
    position: absolute;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    z-index: 10;
}

.card-minimal-four .card-body-top a {
    color: #1c273c;
    font-weight: 700;
    width: 20px;
    text-align: right;
    display: inline-block;
}

.card-minimal-four .card-body-top a:hover,
.card-minimal-four .card-body-top a:focus {
    color: var(--primary);
}

.card-minimal-four .card-body-top div+div {
    margin-top: 5px;
}

.card-minimal-four .chart-wrapper {
    position: relative;
    margin-left: -20px;
    margin-right: -9px;
    margin-bottom: -17px;
}

.card-minimal-four .flot-chart {
    width: 100%;
    height: 150px;
}

.card-minimal-four .card-footer {
    background-color: transparent;
    border-top-color: #ededf5;
    padding: 0;
}

.card-minimal-four .card-footer .nav-link {
    flex: 1;
    display: block;
    text-align: center;
    background-color: #e3e7ed;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
}

.card-minimal-four .card-footer .nav-link:hover,
.card-minimal-four .card-footer .nav-link:focus {
    background-color: #eeeff4;
}

.card-minimal-four .card-footer .nav-link span {
    display: block;
}

.card-minimal-four .card-footer .nav-link span:first-child {
    color: #97a3b9;
    font-weight: 700;
    font-size: 11px;
}

.card-minimal-four .card-footer .nav-link span:last-child {
    color: #4a4a69;
    font-weight: 500;
    font-size: 11px;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.card-minimal-four .card-footer .nav-link+.nav-link {
    border-left: 1px solid #ededf5;
}

.card-minimal-four .card-footer .nav-link.active {
    background-color: var(--primary02);
}

.card-minimal-four .card-footer .nav-link.active::before {
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    border-top: 1px solid #f7f7f7;
    z-index: 5;
}

.card-minimal-four .card-footer .nav-link.active span:first-child {
    color: #1c273c;
}

/* ###### 8.8 Dashboard Eight  ###### */
.main-dashboard-eight {
    background-color: #dde2e9;
}

.main-header-primary {
    background-color: #0040ff;
}

.main-header-primary .main-header-center .form-control {
    background-color: #0033cc;
    border-width: 0;
    height: 38px;
    border-radius: 2px;
    color: #fff;
}

.main-header-primary .main-header-center .form-control::placeholder {
    color: rgba(255, 255, 255, 0.4);
}

.main-header-primary .main-header-center .form-control:focus+.btn {
    color: #fff;
}

.main-header-primary .main-header-center .sp-container .form-control:focus+button {
    color: #fff;
}

.sp-container .main-header-primary .main-header-center .form-control:focus+button {
    color: #fff;
}

.main-header-primary .main-header-center .btn,
.main-header-primary .main-header-center .sp-container button {
    font-size: 14px;
}

.sp-container .main-header-primary .main-header-center button {
    font-size: 14px;
}

.main-header-primary .main-header-center .btn:hover,
.main-header-primary .main-header-center .sp-container button:hover {
    color: #fff;
}

.sp-container .main-header-primary .main-header-center button:hover {
    color: #fff;
}

.main-header-primary .main-header-center .btn:focus,
.main-header-primary .main-header-center .sp-container button:focus {
    color: #fff;
}

.sp-container .main-header-primary .main-header-center button:focus {
    color: #fff;
}

.main-header-primary .main-logo {
    color: #fff;
    font-weight: 600;
    letter-spacing: -1.2px;
}

.main-header-primary .main-logo span {
    color: rgba(255, 255, 255, 0.65);
}

.main-header-primary .main-header-menu-icon span {
    background-color: rgba(255, 255, 255, 0.65);
}

.main-header-primary .main-header-menu-icon span::before,
.main-header-primary .main-header-menu-icon span::after {
    background-color: rgba(255, 255, 255, 0.65);
}

.main-header-primary .main-header-message>a {
    color: rgba(255, 255, 255, 0.75);
}

.main-header-primary .main-header-notification>a {
    color: rgba(255, 255, 255, 0.75);
}

.main-header-primary .main-header-notification>a::after {
    display: none;
}

.main-header-primary .main-header-notification .dropdown-menu {
    border-width: 0;
    box-shadow: 0 0 5px rgba(28, 39, 60, 0.1);
}

.main-header-primary .main-profile-menu .dropdown-menu {
    border-width: 0;
    box-shadow: 0 0 5px rgba(28, 39, 60, 0.1);
}

.main-header-primary .main-profile-menu.show .main-img-user::before {
    display: none;
}

@media (min-width: 576px) {
    .main-header-primary .main-header-notification .dropdown-menu {
        top: 43px;
    }
}

@media (min-width: 992px) {
    .main-header-primary .main-header-notification .dropdown-menu {
        top: 45px;
    }
}

@media (min-width: 576px) {
    .main-header-primary .main-profile-menu .dropdown-menu {
        top: 46px;
    }
}

@media (min-width: 992px) {
    .main-header-primary .main-profile-menu .dropdown-menu {
        top: 48px;
    }
}

.main-navbar-dashboard-eight {
    background-color: #f9f9f9;
    border-bottom-width: 0;
}

.main-navbar-dashboard-eight .main-navbar-header {
    background-color: #0040ff;
    border-bottom-width: 0;
    color: #fff;
}

.main-navbar-dashboard-eight .main-navbar-header .main-logo {
    color: #fff;
}

.main-navbar-dashboard-eight .nav-item.active::before {
    border-bottom-color: #1a53ff;
}

.main-navbar-dashboard-eight .nav-item.active .nav-link {
    color: #1a53ff;
}

.main-navbar-dashboard-eight .nav-link {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 14px;
}

.main-navbar-dashboard-eight .nav-sub:not(.nav-sub-mega) {
    background-color: #f9f9f9;
    border-width: 0;
}

.main-navbar-dashboard-eight .nav-sub:not(.nav-sub-mega)::before {
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    height: 5px;
    background-color: #f9f9f9;
    z-index: 4;
}

.main-navbar-dashboard-eight .nav-sub-item>.nav-sub {
    left: 180px;
}

.main-navbar-dashboard-eight .nav-sub-item>.nav-sub::before {
    left: -5px;
    top: -5px;
    bottom: -5px;
    right: auto;
    width: 5px;
    height: auto;
}

.main-navbar-dashboard-eight .nav-sub-item.active .nav-sub-link {
    color: var(--primary);
}

.main-navbar-dashboard-eight .nav-sub-link:hover {
    color: var(--primary);
}

.main-navbar-dashboard-eight .nav-sub-mega .container,
.main-navbar-dashboard-eight .nav-sub-mega .container-fluid {
    background-color: #f9f9f9;
    border-width: 0;
}

@media (min-width: 992px) {
    .main-navbar-dashboard-eight .nav-sub:not(.nav-sub-mega) {
        box-shadow: 0 0 5px rgba(28, 39, 60, 0.12);
    }
}

@media (min-width: 992px) {

    .main-navbar-dashboard-eight .nav-sub-mega .container,
    .main-navbar-dashboard-eight .nav-sub-mega .container-fluid {
        box-shadow: 0 5px 3px -3px rgba(28, 39, 60, 0.12);
    }
}

.main-content-dashboard-eight {
    padding-top: 25px;
}

.main-content-dashboard-eight .main-content-title {
    letter-spacing: -0.8px;
}

.main-content-dashboard-eight .card {
    border-width: 0;
    border-radius: 2px;
    box-shadow: 0 0 5px rgba(28, 39, 60, 0.1);
}

.main-content-dashboard-eight .card-title {
    font-weight: 500;
}

@media (max-width: 575px) {
    .main-content-dashboard-eight .container {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.card-dashboard-seventeen {
    position: relative;
}

.card-dashboard-seventeen .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 180px;
    position: relative;
    z-index: 10;
}

.card-dashboard-seventeen .card-body h4 {
    margin-bottom: 0;
    font-weight: 700;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
}

.card-dashboard-seventeen .card-body span {
    font-size: 11px;
}

.card-dashboard-seventeen .card-title {
    line-height: 1.3;
}

.card-dashboard-seventeen.bg-primary-dark {
    background-color: #0033cc;
}

.card-dashboard-seventeen .chart-wrapper {
    position: absolute;
    right: -8px;
    bottom: -8px;
    left: -8px;
}

.card-dashboard-seventeen .flot-chart {
    width: 100%;
    height: 180px;
}

.card-dashboard-eighteen {
    display: block;
    padding: 20px;
}

.card-dashboard-eighteen .card-title {
    font-weight: 700;
    text-transform: uppercase;
}

.card-dashboard-eighteen .card-body {
    padding: 20px 0 34px;
    align-items: flex-start;
}

.card-dashboard-eighteen .card-body h6 {
    color: #1c273c;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;
    font-size: 21px;
    letter-spacing: -0.8px;
    margin-bottom: 2px;
    position: relative;
    padding-left: 23px;
}

.card-dashboard-eighteen .card-body h6 span {
    font-weight: 400;
    color: #97a3b9;
    letter-spacing: normal;
}

.card-dashboard-eighteen .card-body h6::before {
    content: "";
    position: absolute;
    display: block;
    top: calc(50% - 8px);
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 4px solid transparent;
}

.card-dashboard-eighteen .card-body h6.dot-primary::before {
    border-color: var(--primary);
}

.card-dashboard-eighteen .card-body h6.dot-purple::before {
    border-color: #6f42c1;
}

.card-dashboard-eighteen .card-body h6.dot-teal::before {
    border-color: #29ddee;
}

.card-dashboard-eighteen .card-body h6.dot-dark-blue::before {
    border-color: #0033cc;
}

.card-dashboard-eighteen .card-body label {
    color: #7987a1;
    margin-bottom: 0;
    display: block;
}

.card-dashboard-eighteen .chartjs-wrapper {
    height: 309px;
    position: relative;
    margin-left: -10px;
    margin-right: -5px;
}

.card-dashboard-nineteen {
    overflow: hidden;
    position: relative;
    background-color: #f9f9f9;
}

.card-dashboard-nineteen .card-header {
    padding: 20px 20px 0;
    background-color: transparent;
}

.card-dashboard-nineteen .card-header .row {
    display: block;
}

.card-dashboard-nineteen .card-header .row>div {
    max-width: none;
}

.card-dashboard-nineteen .card-header .row>div+div {
    margin-top: 20px;
}

.card-dashboard-nineteen .card-header h4 {
    font-size: 24px;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;
    letter-spacing: -1px;
    color: #1c273c;
    margin-bottom: 10px;
}

.card-dashboard-nineteen .card-header h4 span {
    font-weight: 400;
    letter-spacing: normal;
    color: #97a3b9;
}

.card-dashboard-nineteen .card-header .main-content-label,
.card-dashboard-nineteen .card-header .card-table-two .card-title {
    text-transform: none;
    line-height: 1.3;
    margin-bottom: 5px;
    font-size: 0.875rem;
}

@media (min-width: 375px) {
    .card-dashboard-nineteen .card-header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
    }
}

@media (min-width: 375px) {
    .card-dashboard-nineteen .card-header .row {
        display: flex;
    }
}

@media (min-width: 375px) {
    .card-dashboard-nineteen .card-header .row>div+div {
        margin-top: 0;
    }
}

@media (min-width: 576px) {
    .card-dashboard-nineteen .card-header h4 {
        font-size: 30px;
    }
}

.card-table-two .card-dashboard-nineteen .card-header .card-title,
.card-dashboard-nineteen .card-header .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-nineteen .card-header .card-title {
    text-transform: none;
    line-height: 1.3;
    margin-bottom: 5px;
    font-size: 0.875rem;
}

@media (min-width: 992px) {

    .card-dashboard-nineteen .card-header .main-content-label,
    .card-dashboard-nineteen .card-header .card-table-two .card-title {
        font-size: 14px;
    }

    .card-table-two .card-dashboard-nineteen .card-header .card-title,
    .card-dashboard-nineteen .card-header .card-dashboard-eight .card-title,
    .card-dashboard-eight .card-dashboard-nineteen .card-header .card-title {
        font-size: 14px;
    }
}

.card-dashboard-nineteen .card-header p {
    font-size: 11px;
    color: #7987a1;
    margin-bottom: 0;
}

.card-dashboard-nineteen .card-header .btn,
.card-dashboard-nineteen .card-header .sp-container button {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.5px;
    padding: 5px 20px;
    min-height: inherit;
    border-width: 2px;
}

@media (min-width: 576px) {
    .card-dashboard-nineteen .card-header p {
        font-size: 12px;
    }
}

.sp-container .card-dashboard-nineteen .card-header button {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.5px;
    padding: 5px 20px;
    min-height: inherit;
    border-width: 2px;
}

.card-dashboard-nineteen .card-title {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 15px;
    color: #0040ff;
}

.card-dashboard-nineteen .chart-legend {
    margin-top: 22px;
    display: flex;
    align-items: center;
}

.card-dashboard-nineteen .chart-legend>div {
    position: relative;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.card-dashboard-nineteen .chart-legend>div::before {
    content: "";
    position: relative;
    top: 1.5px;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
}

.card-dashboard-nineteen .chart-legend>div:first-child::before {
    background-color: #05478f;
}

.card-dashboard-nineteen .chart-legend>div:nth-child(2)::before {
    background-color: #2f93fe;
}

.card-dashboard-nineteen .chart-legend>div:last-child::before {
    background-color: #bbdafc;
}

.card-dashboard-nineteen .chart-legend>div+div {
    margin-left: 10px;
}

.card-dashboard-nineteen .card-body {
    padding: 0;
    position: relative;
}

.card-dashboard-nineteen .flot-chart-wrapper {
    position: relative;
    margin: -30px -8px -16px -47px;
}

.card-dashboard-nineteen .flot-chart {
    width: 100%;
    height: 250px;
}

.card-dashboard-nineteen .flot-chart .flot-x-axis {
    transform: translate(18px, -25px);
    color: #fff;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    opacity: 0.5;
}

.card-dashboard-nineteen .flot-chart .flot-y-axis {
    transform: translate(66px, -13px);
    color: rgba(0, 0, 0, 0.65);
    font-weight: 400;
    font-size: 10px;
    letter-spacing: 0.5px;
}

@media (min-width: 768px) {
    .card-dashboard-nineteen .chart-legend {
        margin-left: 70px;
    }
}

@media (min-width: 375px) {
    .card-dashboard-nineteen .chart-legend>div {
        letter-spacing: 1px;
    }
}

@media (min-width: 375px) {
    .card-dashboard-nineteen .chart-legend>div+div {
        margin-left: 30px;
    }
}

@media (min-width: 375px) and (max-width: 575px) {
    .card-dashboard-nineteen .card-body {
        padding-top: 170px;
    }
}

@media (min-width: 576px) {
    .card-dashboard-nineteen .card-body {
        padding-top: 130px;
    }
}

@media (min-width: 768px) {
    .card-dashboard-nineteen .card-body {
        padding-top: 95px;
    }
}

@media (min-width: 375px) {
    .card-dashboard-nineteen .flot-chart-wrapper {
        margin-top: 0;
    }
}

@media (min-width: 576px) {
    .card-dashboard-nineteen .flot-chart {
        height: 314px;
    }
}

.card-dashboard-twenty .card-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.card-dashboard-twenty .chartjs-wrapper {
    width: 100%;
    height: 230px;
}

.card-dashboard-twenty .expansion-value {
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    font-weight: 700;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    letter-spacing: -0.5px;
    margin-bottom: 5px;
    margin-top: auto;
}

.card-dashboard-twenty .expansion-value strong:first-child {
    color: #1c273c;
}

.card-dashboard-twenty .expansion-value strong:last-child {
    color: #7987a1;
}

.card-dashboard-twenty .progress {
    margin-bottom: 3px;
    height: 4px;
}

.card-dashboard-twenty .expansion-label {
    display: flex;
    justify-content: space-between;
}

.card-dashboard-twenty .expansion-label span {
    font-weight: 400;
    font-size: 11px;
    color: #97a3b9;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .card-dashboard-twenty .chartjs-wrapper {
        margin-top: auto;
    }
}

.card-dashboard-progress .progress-legend {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
}

.card-dashboard-progress .progress-legend li {
    position: relative;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    padding-left: 15px;
    line-height: 0.95;
}

.card-dashboard-progress .progress-legend li::before {
    content: "";
    position: absolute;
    top: 1px;
    left: 0;
    width: 8px;
    height: 8px;
}

.card-dashboard-progress .progress-legend li:first-child::before {
    background-color: var(--primary);
}

.card-dashboard-progress .progress-legend li:last-child::before {
    background-color: #29ddee;
}

.card-dashboard-progress .progress-legend li+li {
    margin-left: 20px;
}

.card-dashboard-progress .media {
    display: block;
}

.card-dashboard-progress .media>label {
    width: 50px;
    display: block;
    margin-bottom: 0;
}

.card-dashboard-progress .media+.media {
    margin-top: 15px;
}

.card-dashboard-progress .media-body {
    margin-top: 5px;
}

.card-dashboard-progress .progress {
    background-color: #e3e7ed;
}

.card-dashboard-progress .progress-bar {
    height: 15px;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;
}

.card-dashboard-progress .progress-bar+.progress-bar {
    margin-left: 1px;
}

@media (min-width: 576px) {
    .card-dashboard-progress .media {
        display: flex;
        align-items: center;
    }
}

@media (min-width: 576px) {
    .card-dashboard-progress .media-body {
        margin-top: 0;
        margin-left: 15px;
    }
}

/* ###### 8.9 Dashboard Nine  ###### */
.main-body-dashboard-nine {
    background-color: #ededf5;
}

@media (min-width: 992px) {
    .main-body-dashboard-nine .main-header .container-fluid {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (min-width: 992px) {
    .main-body-dashboard-nine.main-sidebar-hide .main-header-dashboard-nine {
        left: 0;
    }
}

.main-sidebar-indigo-dark {
    background-color: #4130c5;
    border-right-width: 0;
}

.main-sidebar-indigo-dark .main-logo {
    color: #fff;
    letter-spacing: -1.4px;
}

.main-sidebar-indigo-dark .main-sidebar-loggedin .media-body h6 {
    color: #fff;
    font-weight: 500;
}

.main-sidebar-indigo-dark .main-sidebar-loggedin .media-body span {
    color: rgba(255, 255, 255, 0.5);
}

.main-sidebar-indigo-dark .main-img-user::after {
    box-shadow: none;
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-label {
    border-bottom-color: rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.35);
    font-weight: 400;
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-item+.nav-item {
    margin-top: -1px;
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-item+.nav-item::before {
    border-top-color: rgba(255, 255, 255, 0.12);
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-item.active {
    position: relative;
    z-index: 5;
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-item.active::before {
    content: "";
    position: absolute;
    top: -1px;
    left: -20px;
    right: -20px;
    background-color: #33269c;
    height: 41px;
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-item.active .nav-link {
    font-weight: 500;
    color: #fff;
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-item.active .nav-link:hover,
.main-sidebar-indigo-dark .main-sidebar-body .nav-item.active .nav-link:focus {
    font-weight: 500;
    color: #fff;
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-item.active .nav-link i,
.main-sidebar-indigo-dark .main-sidebar-body .nav-item.active .nav-link:hover i,
.main-sidebar-indigo-dark .main-sidebar-body .nav-item.active .nav-link:focus i {
    color: #fff;
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-link {
    color: rgba(255, 255, 255, 0.45);
    font-weight: 400;
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-link i {
    color: rgba(255, 255, 255, 0.75);
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-link.with-sub::after {
    opacity: 0.3;
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-link:hover,
.main-sidebar-indigo-dark .main-sidebar-body .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-sub {
    border-left-color: rgba(255, 255, 255, 0.1);
    padding-left: 0;
    padding-bottom: 0;
    position: relative;
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-sub::before {
    content: "";
    position: absolute;
    top: 0;
    left: -32px;
    right: -20px;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 4;
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-sub-item.active .nav-sub-link {
    color: #fff;
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-sub-link {
    padding-left: 21px;
    color: rgba(255, 255, 255, 0.4);
    border-top-color: rgba(255, 255, 255, 0.1);
    position: relative;
    z-index: 5;
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-sub-link:hover,
.main-sidebar-indigo-dark .main-sidebar-body .nav-sub-link:focus {
    color: #fff;
}

.main-header-dashboard-nine {
    background-color: #fff;
}

.main-header-dashboard-nine .main-header-menu-icon {
    margin-right: 0;
}

@media (min-width: 992px) {
    .main-header-dashboard-nine {
        position: fixed;
        top: 0;
        left: 220px;
        right: 0;
        box-shadow: 0 0 15px rgba(28, 39, 60, 0.1);
    }
}

@media (min-width: 1200px) {
    .main-header-dashboard-nine {
        left: 240px;
    }
}

.main-content-1 {
    position: relative;
}

.main-content-1 .main-content-header {
    display: block;
    padding: 20px;
    background-color: #fff;
    border: 0;
    border-bottom: 1px solid #e3e8f1;
}

.main-content-1 .main-content-header .nav {
    justify-content: flex-start;
}

.main-content-1 .main-content-header .nav-link {
    color: #596882;
    font-weight: 500;
}

.main-content-1 .main-content-header .nav-link:hover,
.main-content-1 .main-content-header .nav-link:focus {
    color: #1c273c;
}

.main-content-1 .main-content-header .nav-link.active {
    color: var(--primary);
}

.main-content-1 .main-content-header .nav-link.active::before {
    top: 34px;
    bottom: auto;
    background-color: var(--primary);
}

.main-content-1 .main-content-header .nav-link+.nav-link {
    margin-top: 0;
    margin-left: 25px;
}

.main-content-1 .main-content-header .nav-link:last-child {
    position: relative;
}

.main-content-1 .main-content-header .nav-link:last-child::after {
    content: "";
    position: relative;
    display: inline-block;
    width: 25px;
}

.main-content-1 .main-content-header-top {
    margin-bottom: 20px;
}

.main-content-1 .main-content-title {
    letter-spacing: -0.7px;
    font-size: 24px;
}

.main-content-1 .main-content-body {
    padding: 20px;
}

.main-content-1 .card {
    border-width: 0;
    box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);
}

@media (min-width: 992px) {
    .main-content-1 {
        margin-top: 64px;
    }
}

@media (min-width: 992px) {
    .main-content-1 .main-content-header {
        padding: 15.7px 21px;
        margin: 0 0 1.3rem 0;
    }
}

@media (max-width: 991.98px) {
    .main-content-1 .main-content-header .nav-wrapper {
        overflow: hidden;
        width: 100%;
        height: 20px;
    }
}

@media (max-width: 991.98px) {
    .main-content-1 .main-content-header .nav {
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
        width: calc(100vw - 20px);
    }
}

@media (max-width: 991.98px) {
    .main-content-1 .main-content-header .nav-link {
        white-space: nowrap;
        padding-bottom: 10px;
    }
}

@media (min-width: 992px) {

    .main-content-1 .main-content-header .nav-link:nth-child(5),
    .main-content-1 .main-content-header .nav-link:last-child {
        font-weight: 400;
    }
}

@media (min-width: 992px) {
    .main-content-1 .main-content-header .nav-link:nth-child(5) {
        margin-left: auto;
    }
}

@media (min-width: 768px) {
    .main-content-1 .main-content-header .nav-link:last-child::after {
        display: none;
    }
}

@media (min-width: 576px) {
    .main-content-1 .main-content-header-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
}

.card-dashboard-twentyone {
    position: relative;
}

.card-dashboard-twentyone .card-body {
    background-color: #f4f5f8;
    padding: 20px;
    height: 100%;
}

.card-dashboard-twentyone .card-body .main-content-label,
.card-dashboard-twentyone .card-body .card-table-two .card-title {
    margin-bottom: 8px;
    text-transform: capitalize;
    font-size: 14px;
}

.card-table-two .card-dashboard-twentyone .card-body .card-title,
.card-dashboard-twentyone .card-body .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-twentyone .card-body .card-title {
    margin-bottom: 8px;
    text-transform: capitalize;
    font-size: 14px;
}

@media (min-width: 768px) {
    .card-dashboard-twentyone .list-group {
        margin-bottom: 10px;
    }
}

.card-dashboard-twentyone .list-group-item {
    padding: 8px 0;
    border-width: 0;
    border-right-width: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    background-color: transparent;
}

.card-dashboard-twentyone .list-group-item span {
    flex-basis: 50%;
    max-width: 50%;
}

.card-dashboard-twentyone .list-group-item span:last-of-type {
    text-align: right;
    color: #1c273c;
    font-size: 12px;
    font-weight: 700;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.card-dashboard-twentyone .list-group-item .progress {
    margin-top: 5px;
    margin-bottom: 0;
    flex-basis: 100%;
    max-width: 100%;
    height: 5px;
    background-color: #b4bdce;
}

.card-dashboard-twentyone .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.card-dashboard-twentyone .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.card-dashboard-twentyone .vmap-wrapper {
    width: 100%;
    height: 180px;
}

.card-dashboard-twentyone .vmap-wrapper .jqvmap-zoomin,
.card-dashboard-twentyone .vmap-wrapper .jqvmap-zoomout {
    width: 24px;
    height: 24px;
    left: 15px;
    border-radius: 100%;
    background-color: #1c273c;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    opacity: 0.2;
}

.card-dashboard-twentyone .vmap-wrapper .jqvmap-zoomin:hover,
.card-dashboard-twentyone .vmap-wrapper .jqvmap-zoomin:focus {
    opacity: 0.8;
}

.card-dashboard-twentyone .vmap-wrapper .jqvmap-zoomout:hover,
.card-dashboard-twentyone .vmap-wrapper .jqvmap-zoomout:focus {
    opacity: 0.8;
}

.card-dashboard-twentyone .vmap-wrapper .jqvmap-zoomin {
    top: 15px;
}

.card-dashboard-twentyone .vmap-wrapper .jqvmap-zoomout {
    top: 45px;
}

@media (min-width: 576px) {
    .card-dashboard-twentyone .vmap-wrapper {
        height: 250px;
    }
}

@media (min-width: 768px) {
    .card-dashboard-twentyone .vmap-wrapper {
        height: 100%;
    }
}

.card-dashboard-twentytwo {
    background-color: #fff;
    position: relative;
    height: 120px;
}

.card-dashboard-twentytwo .media {
    padding: 20px;
    position: relative;
    z-index: 5;
}

.card-dashboard-twentytwo .media-icon {
    width: 45px;
    height: 45px;
    color: #fff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-dashboard-twentytwo .media-icon i {
    font-size: 24px;
    line-height: 0;
}

.card-dashboard-twentytwo .media-icon i.typcn {
    line-height: 0.9;
}

.card-dashboard-twentytwo .media-body {
    margin-left: 15px;
    padding-top: 5px;
}

.card-dashboard-twentytwo .media-body h6 {
    margin-bottom: 5px;
    line-height: 0.7;
    color: #1c273c;
    font-size: 26px;
    font-weight: 700;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    letter-spacing: -0.5px;
    display: flex;
    align-items: flex-end;
}

.card-dashboard-twentytwo .media-body h6>small {
    color: #fff;
    padding: 2px 3px;
    font-size: 9px;
    font-weight: 500;
    line-height: 1.1;
    margin-left: 5px;
    letter-spacing: normal;
}

.card-dashboard-twentytwo .media-body h6>small.up {
    background-color: var(--success);
}

.card-dashboard-twentytwo .media-body h6>small.down {
    background-color: #f34343;
}

.card-dashboard-twentytwo .media-body>span {
    display: block;
}

.card-dashboard-twentytwo .media-body>small {
    display: block;
    font-size: 11px;
    color: #97a3b9;
}

.card-dashboard-twentytwo .media-body>small strong {
    font-weight: 500;
}

.card-dashboard-twentytwo .chart-wrapper {
    position: absolute;
    right: -9px;
    left: -9px;
    opacity: 0.3;
}

.card-dashboard-twentytwo .flot-chart {
    width: 100%;
    height: 120px;
}

.card-dashboard-twentythree {
    background-color: #f4f5f8;
}

.card-dashboard-twentythree .main-donut-chart.chart1 {
    width: 130px;
    height: 130px;
    background: #b4bdce;
}

.card-dashboard-twentythree .main-donut-chart.chart1 .slice.one {
    clip: rect(0 130px 65px 0);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    background: var(--primary);
}

.card-dashboard-twentythree .main-donut-chart.chart1 .slice.two {
    clip: rect(0 65px 130px 0);
    -webkit-transform: rotate(234deg);
    transform: rotate(234deg);
    background: var(--primary);
}

.card-dashboard-twentythree .main-donut-chart.chart1 .chart-center {
    top: 15px;
    left: 15px;
    width: 100px;
    height: 100px;
    background: #f4f5f8;
}

.card-dashboard-twentythree .main-donut-chart.chart1 .chart-center span {
    font-size: 40px;
    line-height: 100px;
    color: var(--primary);
    font-weight: 700;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 32px;
}

.card-dashboard-twentythree .main-donut-chart.chart1 .chart-center span:after {
    content: "65%";
}

.card-dashboard-twentythree label {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 10px;
    line-height: 1;
    letter-spacing: 0.5px;
}

.card-dashboard-twentythree label span {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-right: 5px;
}

.card-dashboard-twentythree h5 {
    font-size: 24px;
    font-weight: 700;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    letter-spacing: -1px;
}

.card-dashboard-audience-metrics {
    background-color: #f4f5f8;
    position: relative;
    overflow: hidden;
    height: 250px;
}

.card-dashboard-audience-metrics .card-header {
    padding: 20px 20px 0;
    background-color: #fff;
    position: relative;
    z-index: 3;
}

.card-dashboard-audience-metrics .card-title {
    font-size: 14px;
    margin-bottom: 5px;
}

.card-dashboard-audience-metrics .chart-wrapper {
    position: absolute;
    right: 0;
    bottom: -20px;
    left: 0;
    padding: 5px 5px 0;
}

.card-dashboard-audience-metrics .flot-chart {
    width: 100%;
    height: 180px;
}

.card-dashboard-audience-metrics .flot-chart .flot-x-axis>div {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    color: #7987a1;
    display: none;
}

.card-dashboard-audience-metrics .flot-chart .flot-x-axis>div span:last-child {
    padding-left: 2px;
    font-weight: 700;
    color: #1c273c;
}

.card-dashboard-audience-metrics .card-body {
    background-color: #fff;
    display: flex;
    padding: 0 20px 20px;
    flex: none;
    position: relative;
    z-index: 3;
}

.card-dashboard-audience-metrics .card-body>div+div {
    margin-left: 10px;
    padding-left: 10px;
}

.card-dashboard-audience-metrics .card-body h4 {
    font-weight: 700;
    font-size: 17px;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    letter-spacing: -0.5px;
    margin-bottom: 3px;
}

.card-dashboard-audience-metrics .card-body label {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    font-size: 11px;
}

.card-dashboard-audience-metrics .card-body label span {
    display: inline-block;
    width: 9px;
    height: 9px;
    margin-right: 5px;
    border-radius: 100%;
}

@media (min-width: 576px) {
    .card-dashboard-audience-metrics {
        height: 270px;
    }
}

@media (min-width: 576px) {
    .card-dashboard-audience-metrics .chart-wrapper {
        padding-bottom: 10px;
        bottom: 10px;
    }
}

@media (min-width: 576px) {
    .card-dashboard-audience-metrics .flot-chart .flot-x-axis>div {
        display: block;
    }
}

@media (min-width: 576px) {
    .card-dashboard-audience-metrics .card-body>div+div {
        margin-left: 20px;
        padding-left: 20px;
    }
}

@media (min-width: 576px) {
    .card-dashboard-audience-metrics .card-body h4 {
        font-size: 21px;
    }
}

@media (min-width: 576px) {
    .card-dashboard-audience-metrics .card-body label {
        font-size: 0.875rem;
    }
}

/* ###### 8.10 Dashboard Ten  ###### */
.card-dashboard-twentyfour .card-header {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 0;
}

.card-dashboard-twentyfour .card-title {
    font-size: 14px;
    margin-bottom: 0;
}

.card-dashboard-twentyfour .card-body {
    padding: 15px 20px 20px;
}

.card-dashboard-twentyfour .card-body-top {
    display: flex;
    margin-bottom: 20px;
}

.card-dashboard-twentyfour .card-body-top h6 {
    color: #1c273c;
    font-size: 18px;
    font-weight: 600;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    margin-bottom: 0;
    letter-spacing: -0.75px;
}

.card-dashboard-twentyfour .card-body-top h6 small {
    font-weight: 600;
}

.card-dashboard-twentyfour .card-body-top h6 span {
    color: #97a3b9;
    font-weight: 400;
    letter-spacing: normal;
}

.card-dashboard-twentyfour .card-body-top label {
    display: block;
    margin-bottom: 0;
    color: #7987a1;
    font-size: 11px;
}

.card-dashboard-twentyfour .card-body-top>div+div {
    margin-left: 30px;
}

.card-dashboard-twentyfour .main-content-label,
.card-dashboard-twentyfour .card-table-two .card-title {
    font-size: 10px;
    color: #4a4a69;
    letter-spacing: 0.5px;
    margin-bottom: 0;
}

@media (min-width: 576px) {
    .card-dashboard-twentyfour .card-body-top h6 {
        font-size: 22px;
    }
}

@media (min-width: 576px) {
    .card-dashboard-twentyfour .card-body-top label {
        font-size: 0.875rem;
    }

    .card-dashboard-audience-metrics .flot-chart .flot-x-axis .flot-tick-label {
        display: block;
    }
}

.card-table-two .card-dashboard-twentyfour .card-title,
.card-dashboard-twentyfour .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-twentyfour .card-title {
    font-size: 10px;
    color: #4a4a69;
    letter-spacing: 0.5px;
    margin-bottom: 0;
}

.card-dashboard-twentyfour .chart-legend {
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    line-height: 0.6;
}

.card-dashboard-twentyfour .chart-legend span {
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-right: 5px;
}

.card-dashboard-twentyfour .chart-legend>div {
    display: flex;
    align-items: center;
}

.card-dashboard-twentyfour .chart-legend>div+div {
    margin-left: 20px;
}

.card-dashboard-twentyfour .chart-wrapper {
    position: relative;
    margin-right: -10px;
}

.card-dashboard-twentyfour .flot-chart {
    width: 100%;
    height: 130px;
}

.card-dashboard-twentyfive .card-title {
    font-size: 14px;
    margin-bottom: 15px;
}

.card-dashboard-twentyfive .col+.col {
    border-left: 1px solid #ededf5;
}

.card-dashboard-twentyfive .card-label {
    display: block;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-bottom: 2px;
    color: #97a3b9;
    white-space: nowrap;
}

.card-dashboard-twentyfive .card-value {
    font-size: 22px;
    font-weight: 600;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    margin-bottom: 0;
    letter-spacing: -1px;
}

.card-dashboard-twentyfive .chart-wrapper {
    position: relative;
    margin: 0 -8px -5px;
}

.card-dashboard-twentyfive .flot-chart {
    width: 100%;
    height: 35px;
}

#flotChart2 {
    width: 100%;
    height: 35px;
}

.card-dashboard-twentysix .card-header {
    padding: 15px 15px 10px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-dashboard-twentysix .card-title {
    margin-bottom: 0;
    text-transform: uppercase;
}

.card-dashboard-twentysix .chart-legend {
    display: flex;
    align-items: center;
}

.card-dashboard-twentysix .chart-legend>div {
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.card-dashboard-twentysix .chart-legend>div+div {
    margin-left: 15px;
}

.card-dashboard-twentysix .chart-legend span {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 2px;
}

.card-dashboard-twentysix .card-body {
    padding: 0;
    position: relative;
    overflow: hidden;
}

.card-dashboard-twentysix .card-body h6 {
    margin-bottom: 0;
    color: #1c273c;
    font-size: 22px;
    font-weight: 600;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    letter-spacing: -0.5px;
    line-height: 1;
}

.card-dashboard-twentysix .card-body h6 span {
    font-weight: 400;
    font-size: 18px;
    letter-spacing: -1px;
}

.card-dashboard-twentysix .card-body label {
    font-size: 12px;
    margin-bottom: 0;
    color: #97a3b9;
}

.card-dashboard-twentysix .chart-wrapper {
    position: relative;
    margin: -30px -18px -15px -18px;
}

.card-dashboard-twentysix .flot-chart {
    width: 100%;
    height: 120px;
}

.card-dashboard-twentysix .flot-chart .flot-x-axis>div {
    font-size: 10px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.75);
    transform: translateY(-20px);
}

.card-dashboard-twentysix .flot-chart .flot-y-axis>div {
    font-size: 10px;
    transform: translateX(33px);
    color: rgba(28, 39, 60, 0.5);
}

.card-dashboard-twentysix.card-dark-one .card-title,
.card-dashboard-twentysix.card-dark-two .card-title,
.card-dashboard-twentysix.card-dark-one .card-body h6,
.card-dashboard-twentysix.card-dark-two .card-body h6 {
    color: #fff;
}

.card-dashboard-twentysix.card-dark-one .card-body h6 span,
.card-dashboard-twentysix.card-dark-two .card-body h6 span {
    color: rgba(255, 255, 255, 0.5);
}

.card-dashboard-twentysix.card-dark-one .card-body label,
.card-dashboard-twentysix.card-dark-two .card-body label {
    color: rgba(255, 255, 255, 0.7);
}

.card-dashboard-twentysix.card-dark-one .flot-chart .flot-y-axis>div,
.card-dashboard-twentysix.card-dark-two .flot-chart .flot-y-axis>div {
    color: rgba(255, 255, 255, 0.2);
}

.card-dashboard-twentysix.card-dark-one {
    background-color: #8485fb;
    background-image: linear-gradient(to bottom, #1f05f0 0%, #8485fb 100%);
    background-repeat: repeat-x;
}

.card-dashboard-twentysix.card-dark-two {
    background-color: #0040ff;
    background-image: linear-gradient(to bottom, #0a47ff 0%, var(--primary) 100%);
    background-repeat: repeat-x;
}

.main-rating-value {
    font-size: 40px;
    font-weight: 400;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    margin-bottom: 0;
    letter-spacing: -0.5px;
    line-height: 0.7;
}

.main-rating-label {
    margin-bottom: 10px;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.main-media-list-reviews .media+.media {
    margin-top: 25px;
}

.main-media-list-reviews .main-img-user {
    width: 32px;
    height: 32px;
}

.main-media-list-reviews .main-img-user::after {
    display: none;
}

.main-media-list-reviews .media-body {
    margin-left: 15px;
}

.main-media-list-reviews .media-body h6 {
    margin-bottom: 2px;
    line-height: 1;
}

.main-media-list-reviews .media-body small {
    display: inline-block;
    font-size: 12px;
    color: #97a3b9;
    line-height: 1;
}

.main-star-group {
    display: flex;
    align-items: center;
}

.main-star-group span:last-child {
    display: block;
    font-weight: 500;
    font-size: 11px;
    margin-left: 5px;
    color: #7987a1;
}

.main-star-item {
    color: var(--primary);
    font-size: 14px;
    position: relative;
}

.main-star-item+.main-star-item {
    margin-left: 3px;
}

.main-media-list-activity .media+.media {
    margin-top: 23px;
}

.main-media-list-activity .media-icon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 100%;
}

.main-media-list-activity .media-icon i {
    font-size: 21px;
    line-height: 0;
}

.main-media-list-activity .media-icon i.typcn {
    line-height: 0.9;
}

.main-media-list-activity .media-body {
    margin-left: 15px;
}

.main-media-list-activity .media-body h6 {
    margin-bottom: 2px;
    color: #1c273c;
}

.main-media-list-activity .media-body span {
    display: block;
    font-size: 11px;
    color: #97a3b9;
}

.main-media-list-activity .media-right {
    font-size: 11px;
    color: #97a3b9;
}

.dark-theme.app.sidebar-mini .desktop-logo.logo-dark {
    display: block;
}

.dropdown-menu-left {
    box-shadow: 0px 0px 15px 1px rgb(188, 181, 214);
}

.dropdown-menu-left .dropdown-item {
    padding: 4px 19px;
}

.dropdown-menu-left img {
    width: 31px;
    height: 20px;
}

.main-header-profile {
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
}

.dropdown-menu-arrow:after {
    content: "";
    position: absolute;
    top: -8.5px;
    right: 16px;
    border-bottom: 9px solid #fff;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
}

.dropdown-menu-left {
    right: 0 !important;
    left: auto !important;
    bottom: auto !important;
}

.right-content .btn-sm,
.right-content .btn-group-sm>.btn {
    padding: 0.4rem 0.8rem;
}

.total-revenue {
    display: flex;
    padding: 0 10px 20px;
    flex: none;
    position: absolute;
    z-index: 9;
}

.total-revenue h4 {
    font-size: 21px;
    font-weight: 700;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    letter-spacing: -0.5px;
    margin-bottom: 3px;
}

.total-revenue label {
    font-size: 0.875rem;
}

.total-revenue label span {
    display: inline-block;
    width: 9px;
    height: 9px;
    margin-right: 5px;
    border-radius: 100%;
}

.total-revenue>div+div {
    margin-left: 20px;
    padding-left: 20px;
}

.customers .list-group-item .media img {
    width: 35px !important;
    height: 35px !important;
}

.list-group-item-action {
    width: 100%;
    color: #1d2635;
    text-align: inherit;
}

.progress-style .table th,
.progress-style .table td {
    padding: 0.75rem;
    vertical-align: middle;
}

.progress-style.progress {
    overflow: visible !important;
}

.progress-style.progress .progress-bar:after {
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    background: #fff;
    position: absolute;
    right: -5px;
    top: -5px;
}

.progress-style .bg-primary-gradient.progress-bar:after,
.progress-style .bg-primary.progress-bar:after {
    border: 4px solid var(--primary);
}

.progress-style .bg-secondary.progress-bar:after {
    border: 4px solid var(--secondary);
}

.progress-style .bg-danger.progress-bar:after {
    border: 4px solid #f34343;
}

.progress-style .bg-teal.progress-bar:after {
    border: 4px solid #29ddee;
}

.progress-style .bg-pink.progress-bar:after {
    border: 4px solid #f754fa;
}

.progress-style .bg-success.progress-bar:after {
    border: 4px solid var(--success);
}

.progress-style .bg-danger-gradient.progress-bar:after {
    border: 4px solid #f34343;
}

.progress-style .bg-warning.progress-bar:after {
    border: 4px solid var(--warning);
}

.progress-style .bg-info.progress-bar:after {
    border: 4px solid var(--info);
}

.progress-style.progress .progress-bar {
    box-shadow: none;
    border-radius: 0;
    position: relative;
    -webkit-animation: animate-positive 2s;
    animation: animate-positive 2s;
}

.progress-style .progress-bar {
    float: left;
    height: 100%;
    font-size: 12px;
    border-radius: 20px !important;
    line-height: 20px;
    overflow: visible !important;
    color: #fff;
    text-align: center;
    background-color: #337ab7;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-transition: width 0.6s ease;
    -o-transition: width 0.6s ease;
    transition: width 0.6s ease;
}

.customers .list-group-item-action:hover,
.customers .list-group-item-action:focus {
    color: #4a4a69;
    background-color: #fff;
}

.customers .list-group-item {
    padding: 0.82rem 1.25rem;
}

.sales-info h3,
.card-table h4 {
    font-size: 21px;
    font-weight: 700;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
}

.sales-infomation {
    position: absolute;
    bottom: 0;
}

#chart {
    margin-bottom: 4.5rem;
}

.br-br-7 {
    border-bottom-right-radius: 7px !important;
}

.br-bl-7 {
    border-bottom-left-radius: 7px !important;
}

.header-icon-svgs {
    width: 20px;
    height: 20px;
    color: #7b8191;
    fill: #7b8191;
}

.fullscreen-button .full-screen-link {
    line-height: 33px !important;
}

.country-flag1 strong {
    color: #7282a9;
    display: none;
}

@media (max-width: 767px) {
    .app-sidebar__toggle {
        text-align: center;
    }
}

@media (max-width: 920px) {
    .sidebar-mini .main-dashboard-header-right {
        flex-wrap: nowrap;
        margin-top: 20px;
    }

    .sidebar-mini .breadcrumb-header {
        display: block !important;
    }

    .sidebar-mini .main-dashboard-header-right>div label {
        text-align: left;
    }

    .sidebar-mini .main-dashboard-header-right>div:first-child {
        flex-basis: 100%;
    }
}

@media (max-width: 576px) {
    .sidebar-mini .main-dashboard-header-right {
        flex-wrap: wrap !important;
    }

    .country-flag1 {
        display: none !important;
    }

    .total-revenue {
        padding: 0 !important;
    }

    .total-revenue>div+div {
        margin-left: 20px !important;
        padding-left: 3px !important;
    }

    .square-box {
        display: none;
    }
}

.nav-link.html-code.active {
    border-color: transparent;
    background-color: #23241f;
    color: white;
    margin-top: 1rem;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom: 0;
}

.html-source.nav-tabs .nav-item {
    margin-bottom: -2px;
}

.nav-link.html-code {
    background: #f9f9fb;
    display: inline-flex;
    -webkit-box-align: center;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    align-items: center;
}

.counter-icon {
    margin-bottom: 0;
    display: inline-flex;
    padding: 1.3rem 1.4rem;
    border-radius: 50%;
    text-align: center;
    background: rgba(21, 22, 23, 0.2);
}

.counter-icon i {
    color: #fff;
    font-size: 22px;
}

.success-widget h3 {
    text-align: center;
}

.success-widget h3:before {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #ccead1;
}

.success-widget h3:after {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #ccead1;
    left: 0.5em;
}

.success-widget h3:before {
    right: 0.5em;
}

.danger-widget h3 {
    text-align: center;
}

.danger-widget h3:before,
.danger-widget h3:after {
    background: #cad8a9;
}

.danger-widget h3:before {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #f5dce2;
}

.danger-widget h3:after {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #f5dce2;
    left: 0.5em;
}

.danger-widget h3:before {
    right: 0.5em;
}

.warning-widget h3 {
    text-align: center;
}

.warning-widget h3:before,
.warning-widget h3:after {
    background: #cad8a9;
}

.warning-widget h3:before {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #f3e7c6;
}

.warning-widget h3:after {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #f3e7c6;
    left: 0.5em;
}

.warning-widget h3:before {
    right: 0.5em;
}

.card .box {
    position: relative;
    top: 50%;
    left: 0;
    text-align: center;
    padding: 30px;
    box-sizing: border-box;
    border: 1px solid #e9f1f1;
    border-radius: 4px;
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.3);
    width: 100%;
}

.card .box .img {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
}

.card .box h2 {
    font-size: 20px;
    margin: 20px auto;
}

.card .box ul li a {
    display: block;
    margin: 0 10px;
    font-size: 20px;
    transition: 0.5s;
    text-align: center;
}

.card .box h2 span {
    background: var(--primary);
    font-size: 14px;
    color: #fff;
    display: inline-block;
    padding: 4px 10px;
    border-radius: 15px;
    margin-top: 10px;
}

.userlist-table .table th,
.userlist-table .table td {
    padding: 0.75rem;
    vertical-align: middle;
    display: table-cell;
}

.userlist-table .table-bordered thead th,
.userlist-table .table-bordered thead td {
    padding-top: 12px;
    padding-bottom: 12px;
}

.userlist-table .btn-sm,
.userlist-table .btn-group-sm>.btn {
    font-size: 0.875rem;
    padding: 0.35rem 0.55rem;
    line-height: 0;
    border-radius: 0.2rem;
}

.userlist-table .user-link {
    font-weight: 500;
    color: #000;
    margin-right: 5px;
}

.userlist-table .user-subhead {
    color: #8992a9;
    display: none;
}

.dot-label {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    animation: ani 1s linear infinite;
    left: 7px;
    bottom: -12px;
    position: relative;
}

@keyframes ani {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1);
    }

    30% {
        transform: scale(1.4);
    }

    50% {
        transform: scale(1.2);
    }

    70% {
        transform: scale(1.4);
    }

    90% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    }
}

.search .page-link {
    background-color: #fff;
}

.main-card-signin,
.modal-content .card {
    box-shadow: none;
}

@media (max-width: 920px) {
    .right-content {
        margin-top: 1rem !important;
    }
}

@media (max-width: 576px) {
    .profile.navtab-custom {
        padding: 11px 0 0 0 !important;
        width: 100%;
    }

    .profile.navtab-custom li {
        display: flex;
        width: 100%;
        border-radius: 0;
    }

    .profile.navtab-custom li a {
        border-radius: 0;
        width: 100%;
        margin-right: 0 !important;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }
}

.table-vcenter td,
.table-vcenter th {
    vertical-align: middle;
    border-top: 1px solid #f1f5fa;
}

.table-center td,
.table-center th {
    text-align: center;
}

.table {
    width: 100% !important;
    margin-bottom: 1rem;
    color: #22252f;
}

.userlist-table .dot-label {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    animation: ani 1s linear infinite;
    left: -6px;
    bottom: -7px;
    position: relative;
}

.userlist-table img {
    max-width: inherit;
}

.product-pagination .page-link {
    background-color: #fff !important;
    border: 1px solid #ededf5;
}

.product-pagination .page-item.active .page-link {
    background: var(--primary) !important;
    border-color: var(--primary);
}

.main-logo1 {
    font-family: poppins;
    font-weight: 600;
}

@media (min-width: 576px) {
    .dropdown.nav-itemd-none .dropdown-menu:after {
        border-bottom: 9px solid #fff;
    }
}

@media (min-width: 992px) {
    .top-header .header-brand {
        display: none;
    }

    .top-header .main-header-center {
        margin-left: 0 !important;
    }

    .top-header .header-brand.header-brand2 {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        top: 17px;
    }
}

.btn-outline-danger:focus {
    color: #f34343;
}

.featured_icon i {
    transform: rotate(-43deg);
    position: absolute;
    top: 3px;
    text-align: center;
    right: 0;
    left: 0;
    color: #fff;
    font-size: 6px;
}

.fa-circle:before {
    content: "\f111";
}

.featured_icon.danger {
    border: 3px solid #ee6161;
    background-image: linear-gradient(-38deg, #f32828 0%, #f32828 100%) !important;
}

.featured_icon {
    width: 17px;
    height: 17px;
    line-height: 60px;
    margin-left: -35px !important;
    background-image: linear-gradient(-38deg, var(--primary) 0%, #6922be 100%);
    color: #000;
    font-size: 10px;
    border: 3px solid #a36de3;
    position: relative;
    margin-top: -40px;
    border-radius: 18px;
    top: 20px;
}

ul.timeline:before {
    content: " ";
    background: #ededf5;
    display: inline-block;
    position: absolute;
    left: 25px;
    width: 1.5px;
    height: 76%;
    z-index: 0;
    top: 61px;
}

.latest-timeline .timeline li.activity {
    margin-bottom: 2.5rem;
    padding: 0;
}

.latest-timeline .timeline li.activity:last-child {
    margin-bottom: 0;
}

.featured_icon.success {
    border: 3px solid #25de8c;
    background-image: linear-gradient(-38deg, #0ba360 0%, #3cba92 100%) !important;
}

.featured_icon.warning {
    border: 3px solid #f09819;
    background-image: linear-gradient(-38deg, #ff5858 0%, #f09819 100%) !important;
}

.featured_icon.teal {
    border: 3px solid #29ddee;
    background-image: linear-gradient(-38deg, #265b5b 0%, #29ddee 100%) !important;
}

.progress-style .bg-warning-gradient.progress-bar:after {
    border: 4px solid #f67536;
}

.img-card .img-card-circle {
    position: absolute;
    top: 68px;
    margin-right: -48px;
    text-align: right;
    right: 0;
    opacity: 0.6;
    z-index: 3;
    transform: rotate(25deg);
}

.fa-genderless:before {
    content: "\f22d";
}

.fs-12 {
    font-size: 12px;
}

.fs-15 {
    font-size: 15px;
}

.btn-icon {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: center;
}

.main-body #chartLine {
    height: 255px !important;
}

.country-card {
    padding: 20px;
}

@media (max-width: 767px) {
    .responsive-navbar.navbar .navbar-collapse {
        padding: 0px;
        width: 100%;
        background: #fff;
        margin-top: 0;
        z-index: 9999;
        top: 63px;
        border-bottom: 1px solid #f0f0ff;
        border-top: 1px solid #f0f0ff;
        position: fixed;
        left: 0;
        right: 0;
    }

    .responsive-navbar.navbar {
        position: relative !important;
        display: -ms-flexbox;
        display: flex;
        top: -5px;
        margin: 0;
        margin-bottom: 0 !important;
        padding: 0;
    }

    .responsive-navbar.navbar .new.nav-link {
        margin: 10px 6px !important;
    }

    .mobile-main-header .navbar {
        padding: 0 !important;
    }

    .responsive-logo .mobile-logo {
        margin-top: 3px !important;
    }
}

@media (min-width: 768px) {
    .mobile-header {
        display: none;
        height: 0;
    }

    .navbar-toggler.navresponsive-toggler {
        display: none;
    }
}

.feature-1 {
    position: absolute;
    right: 40px;
    top: 30px;
}

.feature .text-start .text-success {
    background: #c1ecab;
    padding: 10px;
    border-radius: 10px;
    margin-left: -104px;
}

.feature .text-danger {
    background: #fba5b9;
    padding: 10px;
    border-radius: 10px;
    margin-left: -104px;
}

.img-card-circle1 {
    position: absolute;
    top: -5px;
    margin-right: -5px;
    text-align: right;
    right: 0;
    opacity: 0.1;
}

.img-card-circle2 {
    position: absolute;
    top: -5px;
    margin-right: 1px;
    text-align: right;
    right: 0;
    opacity: 0.1;
}

.img-card-circle3 {
    position: absolute;
    top: -6px;
    margin-right: 4px;
    right: 0;
    opacity: 0.2;
}

.img-card-circle4 {
    position: absolute;
    top: -1px;
    margin-right: 0px;
    right: 0;
    opacity: 0.4;
}

.sidenav-toggled .responsive-navbar.navbar .navbar-collapse {
    z-index: 999 !important;
}

.product-grid6 .icons li a {
    color: #fff;
}

.product-grid6 .icons {
    padding: 0;
    margin: 0;
    list-style: none;
    opacity: 0;
    left: 0;
    right: 0;
    text-align: center;
    position: absolute;
    bottom: -3px;
    z-index: 1;
    transition: all 0.5s ease 0s;
    display: block;
    margin: 0 auto;
}

.product-grid6 .icons li .primary-gradient {
    font-size: 13px;
    line-height: 35px;
    text-align: center;
    height: 35px;
    width: 35px;
    margin: 0 auto;
    border-radius: 4px;
    display: block;
    position: relative;
    transition: all 0.3s ease-in-out;
    background: linear-gradient(to right, var(--primary) 0%, var(--primary-25) 100%);
    color: #fff;
}

.product-grid6 .icons li .secondary-gradient {
    font-size: 13px;
    line-height: 35px;
    text-align: center;
    height: 35px;
    width: 35px;
    margin: 0 auto;
    border-radius: 4px;
    display: block;
    position: relative;
    transition: all 0.3s ease-in-out;
    background: linear-gradient(to right, var(--secondary) 0%, rgba(var(--secondary), 0.5) 100%);
}

.product-grid6 .icons li .info-gradient {
    font-size: 13px;
    line-height: 35px;
    text-align: center;
    height: 35px;
    width: 35px;
    margin: 0 auto;
    border-radius: 4px;
    display: block;
    position: relative;
    transition: all 0.3s ease-in-out;
    background: linear-gradient(to right, var(--info) 0%, rgba(var(--info), 0.5) 100%);
}

.product-grid6:hover .icons {
    opacity: 1;
    bottom: 12px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
}

.product-grid6 .icons li a:hover:after,
.product-grid6 .icons li a:hover:before {
    opacity: 1;
}

.product-image .pic-1 {
    transition: all 0.3s ease;
}

.product-grid6 .product-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.product-image .pic-2 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s ease;
    bottom: 0;
    right: 0;
    margin: 0 auto;
}

.product-grid6 .product-link a:nth-child(2n) {
    border-right: none;
}

.product-grid6 .product-image a.image {
    display: block;
}

.product-grid6:hover .product-image .pic-2 {
    opacity: 0;
}

.product-grid6:hover .icons li {
    opacity: 1;
}

.product-grid6 .icons li {
    margin: 0 auto;
    display: inline-block;
    opacity: 0;
    transition: all 0.4s ease;
}

.handle-counter {
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.counter-minus.btn {
    padding: 9px 15px !important;
}

.handle-counter .btn {
    padding: 9px 10px !important;
}

.handle-counter input {
    float: left;
    text-align: center;
    height: 41px;
    border-radius: 0;
    width: 70px;
    border: 1px solid #ededf5;
    outline: none;
    border-left: 0;
    border-right: 0;
}

.handle-counter .counter-minus,
.handle-counter .handle-counter .counter-plus {
    float: left;
    text-align: center;
    border-radius: 1px;
}

.handle-counter .counter-minus {
    border-radius: 3px 0px 0px 3px !important;
}

.handle-counter .counter-plus {
    border-radius: 0px 3px 3px 0px !important;
}

.counter-minus {
    border-radius: 0px 0 0 0px !important;
}

.counter-plus {
    border-radius: 0 0px 0px 0 !important;
}

.file-image-1 {
    width: 100px;
    height: 100px;
    display: inline-table;
    margin: 20px;
    position: relative;
    border: 1px solid #ededf5;
    border-radius: 5px;
}

.file-image-1 .product-image img {
    width: 100px;
    height: 100px;
    background-size: cover;
    background-repeat: no-repeat;
}

.file-image-md .product-image img {
    width: 150px;
    height: 150px;
    background-size: cover;
    background-repeat: no-repeat;
}

.file-image-lg .product-image img {
    width: 200px;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
}

.file-image-1 .icons {
    padding: 0;
    margin: 0;
    list-style: none;
    opacity: 0;
    right: 7px;
    top: 6px;
    text-align: center;
    position: absolute;
    bottom: 0;
    z-index: 1;
    transition: all 0.5s ease 0s;
    text-align: right;
}

.file-image-1:hover .icons {
    opacity: 1;
    bottom: 33px;
}

.file-image-1 .icons li a {
    font-size: 12px;
    line-height: 28px;
    text-align: center;
    height: 25px;
    width: 25px;
    margin: 4px 1px;
    padding: 0;
    border-radius: 50px;
    display: block;
    position: relative;
    transition: all 0.3s ease-in-out;
    color: #fff;
}

.file-image-1 .file-name-1 {
    position: absolute;
    right: 0;
    left: 0;
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 10px;
}

.file-image-md {
    width: 150px;
    height: 150px;
}

.file-image-md .icons li a {
    font-size: 13px;
    line-height: 30px;
    height: 28px;
    width: 28px;
}

.file-image-lg {
    width: 200px;
    height: 200px;
}

.file-image-lg .icons li a {
    font-size: 15px;
    line-height: 35px;
    height: 35px;
    width: 35px;
}

.edit {
    display: inline-block;
    color: var(--primary);
    font-size: 18px;
    line-height: 1;
    text-decoration: none;
}

.dlist-align {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.mobile-main-header .main-header-message .dropdown-menu {
    width: 100px;
    position: absolute;
    top: 52px;
    left: auto;
    right: -5px;
    bottom: auto;
    z-index: 9;
}

.card-options a:not(.btn) {
    margin-left: 0;
    color: #505069;
    display: inline-block;
    min-width: 1rem;
    padding: 0px 8px;
}

.card-options {
    margin-left: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-order: 100;
    order: 100;
    margin-right: -0.5rem;
    color: #a8afc7;
    -ms-flex-item-align: center;
    align-self: center;
}

.product-carousel .carousel-inner .carousel-item {
    border: 0px solid #ededf5;
    padding: 35px;
    border-radius: 5px;
    background: transparent;
}

.carousel-inner .carousel-item .thumb {
    padding: 0.55rem;
    border: 1px solid #ededf5;
    border-radius: 5px;
}

.carousel-inner .carousel-item .thumb.active {
    border: 1px solid #ededf5;
    background: var(--primary02);
}

/* ######  Loaders ###### */
/* ###### circleloader ###### */
.lds-circle {
    display: inline-block;
    transform: translateZ(1px);
}

.lds-circle>div {
    background: var(--primary);
}

.lds-circle>div {
    display: inline-block;
    width: 51px;
    height: 51px;
    margin: 6px;
    border-radius: 50%;
    animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes lds-circle {

    0%,
    100% {
        animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }

    0% {
        transform: rotateY(0deg);
    }

    50% {
        transform: rotateY(1800deg);
        animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }

    100% {
        transform: rotateY(3600deg);
    }
}

/* ###### dual-ring loader ###### */
.lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
}

.lds-dual-ring:after {
    border: 5px solid var(--primary);
    border-color: var(--primary) transparent var(--primary) transparent;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    animation: lds-dual-ring 1.2s linear infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* ###### heart loader ###### */
.lds-heart {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
    transform: rotate(45deg);
    transform-origin: 32px 32px;
}

.lds-heart div {
    background: var(--primary);
}

.lds-heart div:after,
.lds-heart div:before,
.lds-heart div:after,
.lds-heart div:before {
    background: var(--primary);
}

.lds-heart div:before {
    content: " ";
    position: absolute;
    display: block;
    width: 26px;
    height: 26px;
    left: -17px;
    border-radius: 50% 0 0 50%;
}

.lds-heart div:after,
.lds-heart div:before,
.lds-heart div:after,
.lds-heart div:before {
    background: var(--primary);
}

.lds-heart div:after {
    top: -17px;
    border-radius: 50% 50% 0 0;
}

.lds-heart div:after {
    content: " ";
    position: absolute;
    display: block;
    width: 26px;
    height: 26px;
}

.lds-heart div:after {
    content: " ";
    position: absolute;
    display: block;
    width: 26px;
    height: 26px;
}

.lds-heart div {
    background: var(--primary);
}

.lds-heart div {
    top: 23px;
    left: 19px;
    position: absolute;
    width: 26px;
    height: 26px;
    animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

@keyframes lds-heart {
    0% {
        transform: scale(0.95);
    }

    5% {
        transform: scale(1.1);
    }

    39% {
        transform: scale(0.85);
    }

    45% {
        transform: scale(1);
    }

    60% {
        transform: scale(0.95);
    }

    100% {
        transform: scale(0.9);
    }
}

/* ###### ripple loader ###### */
.lds-ripple {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}

.lds-ripple div {
    border: 4px solid var(--primary);
}

.lds-ripple div {
    position: absolute;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div {
    position: absolute;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes lds-ripple {
    0% {
        top: 28px;
        left: 28px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: -1px;
        left: -1px;
        width: 58px;
        height: 58px;
        opacity: 0;
    }
}

/* ###### Spinner loader ###### */
.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}

.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.lds-spinner div {
    transform-origin: 32px 32px;
    animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.lds-spinner div:after {
    background: var(--primary);
}

.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 29px;
    width: 5px;
    height: 14px;
    border-radius: 20%;
}

.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

@keyframes lds-spinner {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/* ######  lines loader  ###### */
.lds-facebook {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}

.lds-facebook div:nth-child(1) {
    left: 6px;
    animation-delay: -0.24s;
}

.lds-facebook div {
    background: var(--primary);
}

.lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 6px;
    width: 13px;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(2) {
    left: 26px;
    animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
    left: 45px;
    animation-delay: -0.12s;
}

@keyframes lds-facebook {
    0% {
        top: 6px;
        height: 51px;
    }

    50%,
    100% {
        top: 19px;
        height: 26px;
    }
}

.Accordion-Style02 {
    border-radius: 0px;
}

@media (max-width: 767.98px) {

    .main-header-message .dropdown-menu,
    .main-header-notification .dropdown-menu,
    .main-profile-menu .dropdown-menu {
        width: 95% !important;
        left: 5px !important;
        right: 5px !important;
        top: 60px !important;
        margin: 0 auto;
    }

    .menu-header-content {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }

    .main-header-message,
    .main-header-notification,
    .main-profile-menu,
    .nav-link.icon {
        position: inherit !important;
    }

    .main-header-profile {
        border-radius: 0px;
    }
}

.btn.Sub {
    padding: 5px 20px !important;
    transition: none;
}

.bxl-instagram.tx-prime,
.bxl-linkedin.tx-prime,
.bxl-twitter.tx-prime,
.bxl-facebook.tx-prime {
    color: var(--primary);
    display: block;
    font-size: 22px;
    position: relative;
    line-height: 35px !important;
    outline: none;
    height: 37px;
    width: 37px;
    background: #fff;
    margin: auto;
    text-align: center;
    border: 1px solid #f1ecf7;
    box-shadow: none;
    border-radius: 35px;
}

@media (max-width: 568px) {
    .feature-1 {
        right: 4px;
    }

    .btn,
    .sp-container button {
        padding: 0.5rem 0.5rem;
        transition: none;
        margin-right: 0px;
        margin-top: 0px;
    }

    .main-contact-action {
        right: 0px !important;
    }
}

.main-sidemenu .slide.is-expanded i.angle {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    position: absolute;
    top: 13px;
    right: 20px;
    font-size: 15px;
}

.main-sidemenu i.angle {
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    position: absolute;
    top: 13px;
    right: 20px;
    font-size: 15px;
}

@media (max-width: 600px) {
    .main-nav-line .nav-link+.nav-link {
        margin-top: 13px;
        margin-left: 0;
    }
}

@media (max-width: 768px) {
    .nav.main-nav-line {
        display: block;
    }

    .profile-cover__img {
        top: 200px !important;
    }
}

@media (min-width: 481px) {
    .profile-cover__action>.btn {
        min-width: 125px;
    }
}

@media (max-width: 991px) {
    .main-error-wrapper.wrapper-1 {
        margin-top: 0rem !important;
    }

    .profile-cover__info .nav li {
        width: 100% !important;
    }

    .profile-cover__info .nav li:not(:first-child) {
        margin-left: 0px !important;
    }
}

@media (max-width: 568px) {

    .btn.btn-rounded.plus,
    .btn.btn-rounded.comment {
        padding: 8px 12px;
        transition: none;
        margin-right: -25px;
        margin-top: 9px;
    }

    .main-error-wrapper h1 {
        line-height: 1;
        font-size: 95px !important;
    }
}

.mail-option .chk-all,
.mail-option .btn-group a.btn {
    border: 1px solid #ebecf1;
    border-radius: 3px !important;
    display: inline-block;
    min-height: 0;
    padding: 5px 10px;
}

ul.inbox-pagination {
    float: right;
    list-style-type: none;
    display: flex;
}

.mail-option .dropdown-menu>li>a {
    display: block;
    padding: 0.5rem 0.75rem;
    clear: both;
    font-weight: 400;
    line-height: 1.428571429;
    color: #7987a1;
    white-space: nowrap;
}

.inbox-pagination a.np-btn {
    border: 1px solid #ebecf1;
    border-radius: 3px !important;
    display: inline-block;
    padding: 5px 10px;
    margin-left: 5px;
    vertical-align: middle;
    min-height: 0;
}

.inbox-pagination li span {
    display: inline-block;
    margin-right: 5px;
    margin-top: 10px;
}

.remove-icons .remove-heart i {
    border: 1px solid var(--primary);
    border-radius: 35px;
    padding: 6px;
    color: var(--primary);
}

.remove-icons .remove-thumb i {
    border: 1px solid var(--secondary);
    border-radius: 35px;
    padding: 6px;
    color: var(--secondary);
}

.nav.panel-tabs .side-menu__icon {
    margin-right: 5px;
    width: 23px;
    height: 23px;
    color: #7987a1;
    fill: #7987a1;
}

.nav.panel-tabs .active .side-menu__icon {
    fill: #fff;
}

.user-profile {
    position: relative;
    width: 72px !important;
    height: 72px !important;
    margin: 0 auto;
}

.app.sidenav-toggled .user-profile {
    width: 55px !important;
    height: 55px !important;
    position: relative;
    margin: 0 auto;
}

.sidenav-toggled.sidenav-toggled-open .user-profile {
    position: relative;
    width: 72px !important;
    height: 72px !important;
    margin: 0 auto;
}

@media (max-width: 767px) {
    .app.sidenav-toggled .user-profile {
        position: relative;
        width: 72px !important;
        height: 72px !important;
        margin: 0 auto;
    }
}

hr:not([size]) {
    height: 0px;
}

ul.timeline li {
    list-style: none !important;
}

.btn-check:focus+.btn-info,
.btn-info:focus {
    color: #fff;
}

.light-layout {
    display: none;
}

@media (max-width: 767px) {
    .header-icons .new.nav-link {
        position: relative;
        margin: auto 6px !important;
    }

    .responsive-navbar.navbar .dropdown {
        position: initial;
    }
}

@media (max-width: 768px) {
    .responsive-navbar .collapse.show .dropdown {
        position: inherit;
    }

    .responsive-navbar .collapse.show .dropdown .dropdown-menu.header-search {
        width: 100%;
        left: 0;
        right: 0;
    }

    .responsive-navbar .collapse.show .dropdown .dropdown-menu.header-search .form-control {
        border-radius: 5px;
    }

    .responsive-navbar .collapse.show .dropdown .dropdown-menu:before,
    .responsive-navbar .collapse.show .dropdown .dropdown-menu:after {
        display: none;
    }

    .responsive-navbar .collapse.show .dropdown .show.dropdown-menu {
        top: 57px !important;
        left: 5px !important;
        right: 5px !important;
    }
}

@media (width: 768px) {
    .main-header-message .dropdown-menu {
        width: 16rem !important;
    }
}

.header-brand .desktop-dark {
    display: none;
}

.responsive-logo .mobile-logo.dark-logo-1 {
    display: none;
}

hr {
    border-top: 1px solid #ededf5;
}

.circle-icon.widget i {
    line-height: 1.9 !important;
}

.circle-icon {
    height: 40px;
    width: 40px;
    position: absolute;
    top: 40px;
    border-radius: 5px;
    right: 40px;
}

.circle-icon i {
    line-height: 2.4 !important;
}

.lh-lg {
    line-height: 2.2 !important;
}

.chart-dropshadow {
    -webkit-filter: drop-shadow(-6px 12px 4px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(-6px 12px 4px rgba(0, 0, 0, 0.1));
}

@media (min-width: 992px) {
    .main-content .main-header .container-fluid {
        padding: 0px;
    }
}

.dot-label-1 {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 100%;
    margin-right: 5px;
}

@media (max-width: 767px) {
    form[role=search].active input {
        top: 0.09rem !important;
    }

    .navbar-form.active .input-group-btn {
        top: 1rem !important;
    }

    .demo-icon .nav-link {
        padding: 12px 4px 4px 10px !important;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .search-open .main-header {
        z-index: 9999 !important;
    }

    .search-open form[role=search].active {
        position: fixed;
        top: 0;
    }

    .search-open form[role=search].active input {
        top: 0;
        transition: none;
        box-shadow: none;
    }

    .search-open form[role=search].active .input-group-btn {
        top: 0.75rem;
    }

    .main-header-right .input-group-btn .btn {
        height: 30px !important;
        width: 30px !important;
        line-height: 18px !important;
    }
}

.spinner1 {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 100px auto;
}

@-webkit-keyframes sk-bounce {

    0%,
    100% {
        -webkit-transform: scale(0);
    }

    50% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bounce {

    0%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }

    50% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

.row.user-social-detail {
    position: absolute;
}

.pos-absolute.bg-black-9 {
    border-radius: 0px 5px 5px 0px;
}

.main-mail-star .typcn-star:before {
    content: "\e109";
    font-size: 20px;
    line-height: 0.8;
}

.main-content-label {
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 10px;
    letter-spacing: 0.2px;
}

#morrisDonut1.morris-donut-wrapper-demo svg text {
    font-size: 12px !important;
    font-weight: 500 !important;
}

.form-label {
    display: block;
    margin-bottom: 0.25rem;
    margin-left: 1rem;
    font-weight: 500;
    font-size: 0.875rem;
    color: var(--primary);
    line-height: 1.25rem;
}

.form-label:has(+ .ng-dirty.ng-invalid),
.form-label:has(+ .ng-touched.ng-invalid) {
    color: var(--error);
}

.form-label.floating-label {
    position: absolute;
    top: -4px;
    left: 12px;
    padding: 0 2px;
    background-color: #fff;
    z-index: 1002;
    margin: 0;
    font-size: 8px;
    line-height: 10px;
    font-weight: 400;
}

.form-label:has(+ .form-control:disabled),
.form-label:has(+ .form-control[readonly]) {
    color: var(--neutral-70);
}

.form-group {
    margin-bottom: 1rem;
    display: block;
    position: relative;
}

div.dt-button-info {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 400px;
    margin-top: -100px;
    margin-left: -200px;
    background-color: white;
    border: 1px solid #ededf5;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 1px;
    text-align: center;
    z-index: 21;
}

div.dt-button-info h2 {
    padding: 0.5em;
    margin: 0;
    font-weight: normal;
    border-bottom: 1px solid #ddd;
    background-color: #f4f4fb;
    font-size: 22px;
}

.file-manger-icon img {
    width: 70px;
    height: 70px;
}

.file-manger-icon {
    position: relative;
}

@media (min-width: 768px) {
    .main-footer {
        padding: 0.9rem 1rem 0.9rem 250px !important;
    }
}

@media (max-width: 768px) {
    .main-footer {
        padding: 0.9rem 1rem 0.9rem 10px;
    }
}

@media (max-width: 767px) {
    .handle-counter input {
        height: 41;
        width: 48px;
    }

    .handle-counter .btn {
        padding: 5px 6px !important;
    }
}

.item7-card-img {
    border-radius: 5px !important;
}

@media (max-width: 320px) {
    .sweet-alert button {
        padding: 0.45rem 0.5rem;
    }
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none !important;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}

.bootstrap-tagsinput .badge [data-role=remove]:after {
    content: "×";
    margin-left: 6px;
}

.btn-close {
    box-sizing: content-box;
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5;
    padding: 5px;
    line-height: 1;
    font-size: 24px;
    background-image: none;
    color: #000;
}

.btn-close:focus {
    box-shadow: none !important;
}

@media (max-width: 576px) {
    .alert {
        padding: 12px 30px;
    }

    .intl-tel-input input {
        width: 180px;
    }
}

.typcn:before {
    height: auto !important;
    line-height: 2 !important;
}

.img-card-circle1 {
    position: absolute;
    top: 0;
    margin-right: 0px;
    text-align: right;
    right: 0;
    opacity: 0.05;
}

.demo-icon {
    margin: auto 6px !important;
    margin-right: 20px !important;
}

.search-icon {
    margin: auto 6px !important;
}

.file-manager-icon {
    width: 28px;
    height: 28px;
}

.progress-dark {
    background-color: rgba(255, 255, 255, 0.5) !important;
    height: 8px;
}

.pricing-body {
    list-style-type: none;
}

.pricing-card {
    transition: box-shadow 0.3s;
}

.pricing-card:hover {
    box-shadow: 0px 16px 32px rgba(33, 33, 33, 0.15);
}

.border-primary-transparent {
    border: 1px solid #e8e7fe;
}

.pricing-tabs ul.nav-price {
    text-align: center;
    margin: 0 auto;
    display: inline-flex;
    margin-top: 30px;
    margin-bottom: 40px;
    border: 1px solid #e6ebf1;
    border-radius: 5px;
    background: #fff;
    overflow: hidden;
}

.pricing-tabs ul.nav-price li {
    display: block;
}

.pricing-tabs ul.nav-price li a.active {
    background: var(--primary);
}

.pricing-tabs ul.nav-price li a.active {
    color: #fff;
    transition: 0.3s;
}

.pricing-tabs ul.nav-price li a {
    padding: 12px 22px;
    display: block;
    background: #fff;
    font-size: 16px;
    border-radius: 0px;
    transition: 0.3s;
}

#user-datatable thead th {
    background-image: none;
    background-color: #ffffff !important;
    position: relative;
}

#user-datatable td {
    padding: 0.8rem;
}

#user-datatable thead .sorting::after {
    content: "\f3d0" !important;
    top: 49%;
    display: none !important;
}

#user-datatable input.form-control {
    width: 25rem !important;
    border-radius: 4px !important;
    background-color: rgba(118, 109, 249, 0.13) !important;
}

.demo-icon.nav-link {
    padding: 0rem 0rem 0rem 0.2rem !important;
}

.theme-layout {
    cursor: pointer;
}

.file-manger-icon1 img {
    width: 70px;
    height: 70px;
    border-radius: 10px;
}

.intl-tel-input .flag-dropdown .selected-flag {
    padding: 10px 18px 9px 7px;
}

.lg-outer .lg-thumb-item {
    border: 0px solid #FFF !important;
}

.form-control::placeholder {
    opacity: 0.6;
}

.productdesc .tab-menu-heading {
    border-bottom: 0px !important;
}

.productdesc .panel-body {
    border: 0px solid #ededf5;
}

.background-image-blog {
    background-image: url(../../assets/img/photos/error.jpg) !important;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    height: 320px;
    position: relative;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
    border-radius: 0px;
}

.custom-blog-content {
    position: absolute;
    font-weight: 600;
    color: #fff;
    font-size: 19px;
    bottom: 0;
}

a.custom-blog-content:hover {
    color: #fff !important;
}

.white-space-nowrap {
    white-space: nowrap;
}

.attached-file-grid6 .icons li {
    margin: 0 auto;
    display: inline-block;
    opacity: 0;
    transition: all 0.4s ease;
}

.attached-file-grid6 .icons li a:hover {
    color: #fff;
}

.attached-file-grid6 .icons li a:hover {
    border: var(--primary);
    background: var(--primary);
}

.attached-file-grid6 .file-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.attached-file-image .pic-1 {
    transition: all 0.3s ease;
}

.attached-file-image .pic-2 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s ease;
    bottom: 0;
    right: 0;
    margin: 0 auto;
}

.attached-file-grid6:hover .attached-file-image .pic-2 {
    opacity: 0;
}

.attached-file-grid6:hover .icons {
    opacity: 1;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    padding: 10px;
}

.attached-file-grid6:hover .icons li {
    opacity: 1;
}

.image-pic {
    position: absolute;
    right: 0;
    left: 0;
    top: 0px;
    color: #fff;
    font-size: 17px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent 100%);
    padding: 0px 0px 20px 10px;
    border-radius: 5px;
}

.tag.tag-attachments {
    padding: 4px 15px 2px 15px;
    font-size: 13px;
    background-color: #ffffff;
    border: 1px solid #e8e8f7;
}

.tag.tag-attachments-lg {
    padding: 5px 17px 3px 17px;
    font-size: 17px;
    background-color: #ffffff;
    border: 1px solid #e8e8f7;
}

.tag.tag-attachments-sm {
    padding: 3px 10px 1px 10px;
    font-size: 13px;
    background-color: #ffffff;
    border: 1px solid #e8e8f7;
}

.tags .tag-files span {
    position: absolute;
    top: 21px;
    margin-right: 104px;
    left: 51px;
    font-size: 12px;
    color: #a5b1d9;
}

.attached-file-grid6 .icons li a {
    font-size: 13px;
    line-height: 30px;
    text-align: center;
    height: 30px;
    width: 30px;
    margin: 0 auto;
    border-radius: 5px;
    display: block;
    position: relative;
    transition: all 0.3s ease-in-out;
    background: #fff;
}

.attached-file-grid6 .icons li a {
    color: var(--primary);
    border: 1px solid #fff;
}

.attached-file-grid6 .icons {
    padding: 0;
    margin: 0;
    list-style: none;
    opacity: 0;
    left: 0;
    right: 0;
    text-align: center;
    position: absolute;
    bottom: -3px;
    z-index: 1;
    transition: all 0.5s ease 0s;
    display: block;
    margin: 0 auto;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent 100%);
}

.pro-img-box {
    position: relative;
}

.theme-container button {
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    padding: 0.5rem 1rem;
    background-color: #fff;
    border: 1px solid #ededf5;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 5px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.theme-container1 button {
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    padding: 0.5rem 1rem;
    background-color: #fff;
    border: 1px solid #ededf5;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 5px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.theme-container2 button {
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    padding: 0.5rem 1rem;
    background-color: #fff;
    border: 1px solid #ededf5;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 5px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.pickr {
    text-align: center;
    margin-top: 10px;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
    background-color: #fff;
    opacity: 7;
    color: inherit;
    border: none;
    padding: 0p !important;
    font: inherit;
}

.owl-nav .owl-next {
    position: absolute;
    top: 50%;
    right: -25px;
    margin-top: -1.65em;
}

.owl-nav .owl-prev {
    position: absolute;
    top: 50%;
    left: -25px;
    margin-top: -1.65em;
}

.owl-nav button {
    display: block;
    font-size: 1.3rem !important;
    line-height: 2em;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    text-align: center;
    background: #fff !important;
    opacity: 0.5 !important;
    border: 1px solid #e8ebf3 !important;
    z-index: 99;
    box-shadow: 0 4px 15px rgba(67, 67, 67, 0.15);
}

@media (min-width: 992px) {
    .main-settings-menu .nav-link {
        font-size: 14px;
    }

    .main-settings-menu .nav-link i {
        font-size: 18px;
    }
}

.settings-main-icon {
    display: flex;
    text-align: center;
}

.settings-main-icon i {
    width: 3rem;
    font-size: 18px;
    line-height: 3rem;
    height: 3rem;
    background-color: var(--primary-50);
    color: var(--primary);
    border-radius: 50%;
}

.leading-normal {
    line-height: 1.5 !important;
}

.lead-1 {
    font-size: 1.6rem;
    font-weight: 500;
}

.aboutlist {
    list-style-type: none;
}

.about-icons {
    height: 60px;
    width: 60px;
}

.about-team {
    width: 80px;
    height: 80px;
    right: 0;
    left: 0;
    margin: 0 auto;
}

.about-main,
.about-motto {
    display: flex;
    align-items: center;
    justify-content: center;
}

.motto-icon {
    width: 30px;
    height: 30px;
}

.prime-card {
    display: flex;
    align-items: center;
    justify-content: center;
}

.prime-card img {
    height: 160px;
    transform: scale(1.9) translate(3px, -12px);
}

@media (max-width: 1600px) {
    .prime-card img {
        height: 250px;
        transform: scale(1);
    }
}

.apexcharts-yaxis-texts-g text {
    fill: #adb5be !important;
}

.apexcharts-xaxis-texts-g text {
    fill: #adb5be !important;
}

#sales {
    justify-content: center;
    align-items: center;
}

#sales div {
    margin: 0 auto;
}

#Viewers .apexcharts-canvas {
    margin: 0 auto;
}

.apexcharts-legend-marker {
    margin-right: 10px !important;
}

.apexcharts-legend-series {
    margin: 0px 20px !important;
}

.under-countdown .countdown {
    padding: 20px;
    border-radius: 5px;
}

.countdown span:first-child {
    font-size: 30px;
    font-weight: 500;
}

.notification {
    list-style-type: none;
    padding: 0;
    position: relative;
}

.notification:before {
    content: "";
    position: absolute;
    top: 40px;
    bottom: 5px;
    width: 4px;
    background-color: var(--primary-50);
    left: 20%;
    margin-left: -2.5px;
}

.notification>li {
    position: relative;
    min-height: 50px;
    padding: 15px 0;
}

.notification .notification-time {
    position: absolute;
    left: 0;
    width: 18%;
    text-align: right;
    top: 30px;
}

.notification .notification-time .date,
.notification .notification-time .time {
    display: block;
    font-weight: 500;
}

.notification .notification-time .date {
    line-height: 16px;
    font-size: 11px;
    margin-bottom: 4px;
    color: #7987a1;
}

.notification .notification-time .time {
    line-height: 24px;
    font-size: 18px;
    color: #7987a1;
}

.notification .notification-icon {
    left: 15%;
    position: absolute;
    width: 10%;
    text-align: center;
    top: 51px;
}

.notification .notification-icon a {
    text-decoration: none;
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 50%;
    background: #fff;
    line-height: 10px;
    color: #fff;
    font-size: 14px;
    border: 3px solid var(--primary);
    transition: border-color 0.2s linear;
}

.notification .notification-body {
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.05);
    margin-left: 24%;
    margin-right: 18%;
    background: #fff;
    position: relative;
    padding: 14px 20px;
    border-radius: 6px;
}

.notification .notification-body:before {
    content: "";
    display: block;
    position: absolute;
    border: 10px solid transparent;
    border-right-color: #fff;
    left: -20px;
    top: 32px;
}

.notification .notification-body>div+div {
    margin-top: 15px;
}

.notification-badge {
    background-color: #eff1f5;
    color: #7987a1;
}

@media (max-width: 576px) {
    .notification .notification-body:before {
        display: none;
    }

    .notification .notification-icon a {
        display: none;
    }

    .notification:before {
        display: none;
    }

    .notification-body .media {
        flex-direction: column;
    }

    .notification-body .media .main-img-user {
        margin-bottom: 10px !important;
    }

    .notification .notification-time {
        z-index: 99;
        width: 100%;
        right: 5px !important;
        position: absolute;
        top: 20px !important;
    }

    .notification .notification-body {
        margin-left: 0px;
        margin-right: 0px;
        position: relative;
    }

    .notification-badge {
        position: absolute;
        left: 10px;
        top: 8px;
    }

    .notification .notification-time .date,
    .notification .notification-time .time {
        display: inline;
    }

    .notification .notification-time .time {
        line-height: 16px;
        font-size: 11px;
        margin-left: 5px;
        margin-right: 10px;
        color: #b6bfcf;
    }
}

.country-selector img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

#country-selector .modal-header {
    background-color: rgba(59, 72, 99, 0.1);
    border-bottom: 1px solid #dae5e7 !important;
}

#country-selector .modal-body {
    background-image: url(../../assets/img/png/countrymap.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

#country-selector .modal-body:before {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    background-color: rgba(59, 72, 99, 0.1);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.btn-country {
    border: 1px solid rgba(var(--primary), 0.2);
    box-shadow: none !important;
    text-align: justify !important;
    font-size: 13px !important;
    line-height: 1;
    border-radius: 12em;
    border: 1px solid transparent;
    padding: 0.45rem 0.45rem !important;
    transition: none !important;
}

.btn-country:hover {
    border: 1px solid var(--primary) !important;
}

.btn-country .country-selector img {
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.2) !important;
}

.btn-check:checked+.btn-country,
.btn-country.active,
.btn-country:active {
    border: 1px solid var(--primary) !important;
    font-weight: 600;
    color: #000;
    background-color: #fff;
}

#login-otp {
    display: none;
}

.apexcharts-toolbar {
    z-index: 1 !important;
}

.app-sidebar.sidebar-scroll.open.ps--scrolling-y .main-content.app-content {
    position: fixed !important;
}

.apexcharts-legend .apexcharts-legend-series .apexcharts-legend-text {
    font-size: 14px !important;
    font-weight: 500 !important;
}

@supports (-webkit-touch-callout: none) {
    @media screen and (max-device-width: 991px) and (orientation: portrait) {

        .ps.ps--active-y:hover>.ps__rail-y,
        .ps.ps--active-y:focus>.ps__rail-y {
            opacity: 0;
        }
    }

    @media screen and (max-device-width: 991px) and (orientation: landscape) {

        .ps.ps--active-y:hover>.ps__rail-y,
        .ps.ps--active-y:focus>.ps__rail-y {
            opacity: 0;
        }
    }
}

.main-rocket {
    fill: var(--primary);
}

.tree li i {
    color: var(--primary);
}

.bootstrap-tagsinput .badge {
    background-color: var(--primary);
    border: 1px solid var(--primary);
}

.sweet-alert button {
    background-color: var(--primary) !important;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: var(--primary);
}

.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
    fill: var(--primary);
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: var(--primary);
}

.ql-snow a {
    color: var(--primary);
}

.lg-actions .lg-next,
.lg-actions .lg-prev {
    background-color: var(--primary-25);
    border: 1px solid var(--primary);
}

.lg-toolbar {
    background-color: var(--primary-25);
}

.datepicker .datepicker-switch {
    color: var(--primary);
}

.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
    color: var(--primary) !important;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
    color: var(--primary);
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
    background-image: -moz-linear-gradient(to bottom, var(--primary), var(--primary));
    background-image: -ms-linear-gradient(to bottom, var(--primary), var(--primary));
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(var(--primary)), to(var(--primary)));
    background-image: -webkit-linear-gradient(to bottom, var(--primary), var(--primary));
    background-image: -o-linear-gradient(to bottom, var(--primary), var(--primary));
    background-image: linear-gradient(to bottom, var(--primary), var(--primary));
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$primary", endColorstr="$primary", GradientType=0);
    border-color: var(--primary) #002a80;
}

.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
    background: var(--primary);
}

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
    background: none;
    color: var(--primary);
}

.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
    background-image: -moz-linear-gradient(to bottom, var(--primary), var(--primary));
    background-image: -ms-linear-gradient(to bottom, var(--primary), var(--primary));
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(var(--primary)), to(var(--primary)));
    background-image: -webkit-linear-gradient(to bottom, var(--primary), var(--primary));
    background-image: -o-linear-gradient(to bottom, var(--primary), var(--primary));
    background-image: linear-gradient(to bottom, var(--primary), var(--primary));
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$primary", endColorstr="$primary", GradientType=0);
    border-color: var(--primary) #002a80;
}

.datepicker table tr td span.old,
.datepicker table tr td span.new {
    color: var(--primary);
}

.datepicker table tr td span.old:hover,
.datepicker table tr td span.new:hover {
    color: #fff !important;
}

@media (max-width: 767.98px) {
    .error-page1 .demo-icon svg {
        position: absolute;
        left: auto;
        right: 60px;
        top: 60px;
        fill: var(--primary);
        width: 25px;
        height: 25px;
        z-index: 99999;
    }
}

.error-page1.dark-theme .main-card-signin {
    box-shadow: none !important;
}

.error-page1.dark-theme .main-card-signin {
    background-color: transparent;
    border: 1px solid transparent;
}

.error-page1 .demo-icon svg {
    position: absolute;
    left: auto;
    right: 60px;
    top: 60px;
    fill: #fff;
    width: 25px;
    height: 25px;
}

.error-page1 .tabs-menu1 ul li a.active {
    border-bottom: 3px solid var(--primary) !important;
}

.error-page1 .tabs-menu1 ul li a {
    border-bottom: 3px solid #e6ebf1 !important;
}

@media (max-width: 991.98px) {
    .error-page1 .demo-icon svg {
        position: absolute;
        left: auto;
        right: 50px;
        top: 20px;
        width: 25px;
        z-index: 999;
        height: 25px;
    }
}

.carousel-inner.slider {
    position: relative;
    height: 400px;
}

.carousel-inner.slider .carousel-item {
    position: absolute;
    left: 0;
    top: 0;
}

.sidebar-mini .slide-left,
.sidebar-mini .slide-right {
    display: none !important;
}

#videomodal .modal-content,
#audiomodal .modal-content {
    box-shadow: 0 1px 15px 1px rgba(156, 156, 168, 0.5);
}

.main-content-app .option-dots {
    position: relative;
    right: 15px;
    z-index: 999;
    margin: 0 auto;
}

.masonry .brick {
    width: auto;
    margin-bottom: 20px;
}

.brick img {
    border-radius: 5px;
}

.smartphoto-img.active {
    border-radius: 5px;
}

.smartphoto-arrows li {
    padding: 8px;
    border-radius: 5px;
    background: var(--primary-50);
}

.smartphoto-arrows a {
    width: 50% !important;
    height: 50% !important;
    margin: 7px;
}

.smartphoto-dismiss {
    width: 15px !important;
    height: 15px !important;
    right: 22px !important;
    top: 18px !important;
}

.smartphoto {
    background-color: rgba(0, 0, 0, 0.8) !important;
}

.smartphoto-arrow-left {
    left: 15px !important;
}

.smartphoto-arrow-right {
    right: 15px !important;
}

.smartphoto-nav li {
    width: 70px !important;
    height: 70px !important;
    margin-left: 5px;
}

.smartphoto-nav {
    bottom: 10px !important;
}

.smartphoto-nav a {
    border-radius: 5px;
    opacity: 0.6 !important;
    border: #ededf5;
}

.smartphoto-list li {
    display: table !important;
}

.ck.ck-button,
a.ck.ck-button {
    border: 1px solid #ededf5;
}

.theme-switch {
    position: relative;
    padding: 10px;
    border-radius: 50px;
    background-color: var(--primary-50);
    height: 50px;
    width: 50px;
    display: flex !important;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.theme-switch .demo-icon {
    margin: 0 auto !important;
}

.theme-switch a {
    position: absolute;
}

.theme-switch a svg {
    fill: var(--primary);
}

@media (max-width: 576px) {

    .wizard>.steps .current a .title,
    .wizard>.steps .current a:hover .title,
    .wizard>.steps .current a:active .title {
        display: none !important;
    }
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,
table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
    background-color: var(--primary) !important;
}

.twentytwenty-container {
    border-radius: 5px !important;
}

.pcr-app[data-theme=classic] {
    border-radius: 5px !important;
}

.pcr-app[data-theme=monolith] {
    border-radius: 5px !important;
}

.pcr-app[data-theme=nano] {
    border-radius: 5px !important;
}

.file-detailimg img {
    width: 1000%;
    height: 100%;
}

#gallery img {
    width: 360px;
}

.chart-circle {
    display: block;
    height: 6rem;
    width: 6rem;
    position: relative;
}

.chart-circle canvas {
    margin: 0 auto;
    display: block;
    max-width: 100%;
    max-height: 100%;
}

.chart-circle .chart-circle-value.circle-style {
    position: absolute;
    top: 18px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column;
    line-height: 1;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border: 2px dotted var(--primary);
    border-radius: 50%;
    background: transparent;
}

.chart-circle-value {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column;
    line-height: 1;
}

.chart-circle[data-color] {
    color: var(--primary);
}

@media screen and (max-width: 1024px) {
    .cardbody {
        padding-left: 0;
    }

    .item-card .product-grid6 .cardprice {
        top: 20px;
        right: 0;
    }

    .rtl .cardbody {
        padding-left: inherit;
        padding-right: 0;
    }

    .rtl .item-card .product-grid6 .cardprice {
        top: 20px;
        left: 0;
        right: inherit;
    }
}

@media (orientation: landscape) and (max-width: 767px) {
    .smartphoto-img {
        width: 250px !important;
        left: 80%;
        display: block;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .smartphoto-img-wrap {
        left: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 33%;
        right: 0;
        transform: none !important;
        justify-content: center;
        align-items: center;
    }
}

@media (orientation: landscape) and (min-width: 768px) and (max-width: 991px) {
    .smartphoto-img {
        width: 350px !important;
        left: 75%;
        display: block;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .smartphoto-img-wrap {
        left: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20%;
        right: 0;
        transform: none !important;
        justify-content: center;
        align-items: center;
    }
}

.SumoSelect.disabled>.CaptionCont {
    background-color: #f9fbfb;
}

.SumoSelect.disabled .SlectBox,
.SumoSelect.disabled .testselect2 {
    display: none;
}

.index1 .circle-icon {
    height: 35px;
    width: 35px;
    position: initial;
    border-radius: 50%;
}

.index1 .circle-icon i {
    line-height: 2.2 !important;
}

.transaction-icon {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.featured_icon1.danger {
    border: 2px solid #f34343;
}

.featured_icon1.success {
    border: 2px solid var(--success);
}

.featured_icon1.warning {
    border: 2px solid var(--warning);
}

.featured_icon1.teal {
    border: 2px solid #29ddee;
}

.featured_icon1.info {
    border: 2px solid var(--info);
}

.featured_icon1.secondary {
    border: 2px solid var(--secondary);
}

.featured_icon1 {
    width: 15px;
    height: 15px;
    line-height: 60px;
    margin-left: -34px !important;
    color: #000;
    font-size: 10px;
    border: 2px solid var(--primary-bg-color);
    position: relative;
    margin-top: -40px;
    border-radius: 18px;
    top: 20px;
}

.table-edit,
.table-delete {
    fill: #fff;
}

.apexcharts-xaxistooltip.apexcharts-xaxistooltip-bottom.light.active {
    display: none !important;
}

.apexcharts-tooltip.light {
    border: 1px solid var(--primary) !important;
    background: var(--primary) !important;
    color: #fff !important;
}

.apexcharts-tooltip.light .apexcharts-tooltip-title {
    background: var(--primary) !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.apexcharts-tooltip-marker {
    background-color: #fff !important;
}

.lh-maincard {
    line-height: 1.6 !important;
}

#country-selector .modal-body ul li {
    list-style-type: none;
}

#owl-demo2 img {
    width: 100%;
}

/*-----IE issues-----*/
*::-ms-backdrop,
.main-header-center {
    display: inline-table !important;
    width: 350px;
}

*::-ms-backdrop,
.main-header-center .form-control {
    padding: 0.5rem 1.5rem;
}

@media (min-width: 991px) {

    *::-ms-backdrop,
    .main-header-right .nav-link.btn-default.btn {
        margin-top: 10px;
    }
}

@media (max-width: 991px) {

    *::-ms-backdrop,
    .card-img-top {
        height: 300px;
    }

    *::-ms-backdrop,
    .main-header-right .nav-link.btn-default.btn {
        margin-top: 7px;
    }
}

*::-ms-backdrop,
.pricing .list-unstyled li {
    display: block;
}

*::-ms-backdrop,
.main-content-body.main-content-body-mail,
*::-ms-backdrop,
.main-content-body.main-content-body-chat {
    flex: inherit;
}

*::-ms-backdrop,
.breadcrumb-3 li,
*::-ms-backdrop,
.breadcrumb-4 li {
    display: inline-block;
}

*::-ms-backdrop,
.main-footer {
    width: 100%;
    position: relative;
    bottom: -5px;
    margin-top: 1.5rem;
    margin-bottom: -10px;
    height: inherit !important;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
}

*::-ms-backdrop,
.horizontalMenucontainer .main-header-left {
    margin-left: 0 !important;
}

*::-ms-backdrop,
.main-content {
    height: 100%;
}

*::-ms-backdrop,
.horizontalMenucontainer .main-footer- {
    padding-bottom: 10px;
}

*::-ms-backdrop,
.media-list.media.d-block {
    display: inline !important;
}

*::-ms-backdrop,
.chat .action-header {
    padding: 10px 13px 1px 17px;
}

*::-ms-backdrop,
.sidebar-mini .main-header-left .btn {
    margin-left: 60px;
    left: 250px;
}

*::-ms-backdrop,
.sidebar-mini .main-content.app-content .main-header-left {
    margin-left: 0;
}

*::-ms-backdrop,
.sidebar.sidebar-open {
    z-index: 9999 !important;
}

*::-ms-backdrop,
.p-4.border-bottom {
    height: 100%;
}

*::-ms-backdrop,
.img-fluid,
*::-ms-backdrop,
.table,
*::-ms-backdrop,
.text-wrap table {
    max-width: 100%;
}

*::-ms-backdrop,
.sidebar {
    z-index: 0 !important;
}

*::-ms-backdrop,
.d-block.media.media-list {
    display: inline !important;
}

*::-ms-backdrop,
.main-header-message>a,
*::-ms-backdrop,
.main-header-notification>a,
*::-ms-backdrop,
.full-screen.nav-item>a,
*::-ms-backdrop,
.fullscreen-button .full-screen-link {
    line-height: 49px !important;
}

*::-ms-backdrop,
.datetimepicker table th.prev span,
*::-ms-backdrop,
.datetimepicker table th.next span {
    top: 20px;
}

*::-ms-backdrop,
.datetimepicker table th.next {
    border-top-right-radius: 0;
}

*::-ms-backdrop,
.datetimepicker table th.prev {
    border-top-left-radius: 0;
}

*::-ms-backdrop,
.main-header-center .btn,
*::-ms-backdrop,
.main-header-center .sp-container button,
*::-ms-backdrop,
.sp-container .main-header-center button {
    top: 0;
}

@media (min-width: 992px) {

    *::-ms-backdrop,
    .sidebar-mini .main-header-left {
        margin-left: 80px;
    }

    *::-ms-backdrop,
    .sidebar-mini .main-header .main-header-left .header-brand {
        margin-right: 80px;
        width: auto;
    }

    *::-ms-backdrop,
    .card-img-top {
        height: 250px;
    }
}

*::-ms-backdrop,
.btn-icon-list .btn {
    display: table;
    vertical-align: middle;
    text-align: center;
    margin-right: 10px;
}

*::-ms-backdrop,
.side-badge {
    top: 14px;
}

*::-ms-backdrop,
.media.d-sm-flex {
    display: initial;
}

*::-ms-backdrop,
.btn,
.sp-container button {
    padding: 8.5px;
}

*::-ms-backdrop,
.page-h {
    height: 100%;
}

*::-ms-backdrop,
p {
    font-size: 13px;
}

*::-ms-backdrop,
.breadcrumb-right .btn {
    padding: 0.4rem 1rem;
}

*::-ms-backdrop,
.horizontalMenucontainer .main-footer {
    padding-bottom: 10px;
}

*::-ms-backdrop,
.media-list.media.d-sm-flex {
    display: initial;
}

*::-ms-backdrop,
.main-msg-wrapper {
    display: inline-table;
}

*::-ms-backdrop,
.p-text .p-name {
    display: block;
}

*::-ms-backdrop,
.sidebar .tab-menu-heading {
    position: absolute !important;
}

*::-ms-backdrop,
.card--calendar .ui-datepicker-inline {
    border-width: 1px !important;
}

*::-ms-backdrop,
.fc-datepicker.main-datepicker.hasDatepicker {
    border-right: 0 !important;
    border-top: 0 !important;
    border-bottom: 0 !important;
}

*::-ms-backdrop,
.second-sidemenu .resp-tabs-container .slide-menu.submenu-list li {
    display: flex;
}

@media (min-width: 992px) {

    *::-ms-backdrop,
    .pulse-danger,
    *::-ms-backdrop,
    .pulse {
        top: 13px;
    }

    *::-ms-backdrop,
    .card.custom-card .box {
        position: static;
    }

    *::-ms-backdrop,
    .thumb .thumb-img {
        height: 139px;
    }

    *::-ms-backdrop,
    .main-chat-body .media-body .main-msg-wrapper.right,
    *::-ms-backdrop,
    .main-chat-body .media-body .main-msg-wrapper.left {
        display: inline-table;
    }

    *::-ms-backdrop,
    .main-dashboard-header-right>div:first-child {
        flex-basis: 150px;
    }

    *::-ms-backdrop,
    .tasks .ckbox {
        margin-right: 10px;
    }

    *::-ms-backdrop,
    .app-sidebar__toggle {
        margin-right: 30px !important;
    }

    *::-ms-backdrop,
    .product-details.table-responsive.text-nowrap .media-body {
        flex: none;
    }
}

@media (min-width: 767px) {

    *::-ms-backdrop,
    .bg-primary-transparent .row.wd-100p.mx-auto.text-center {
        margin-top: 4rem;
    }

    *::-ms-backdrop,
    .login.d-flex.align-items-center.py-2 {
        margin-top: 7rem;
    }
}

@media (max-width: 767px) {

    *::-ms-backdrop,
    .dropdown.nav-item.main-header-message,
    *::-ms-backdrop,
    .dropdown.nav-item.main-header-notification,
    *::-ms-backdrop,
    .dropdown.main-header-message.right-toggle {
        margin-top: 7px !important;
    }

    *::-ms-backdrop,
    .main-header-left .responsive-logo {
        margin-left: 40px !important;
    }

    *::-ms-backdrop,
    .sidebar-mini .main-header .main-header-left .header-brand {
        margin-left: 30px;
    }
}

*::-ms-backdrop,
html.fullscreen-button {
    width: 100%;
}

*::-ms-backdrop,
html.fullscreen-button .main-content,
*::-ms-backdrop,
html.fullscreen-button .app-content {
    overflow: scroll;
    overflow-x: hidden;
    height: 100vh;
}

*::-ms-backdrop,
.error-page1 {
    -ms-overflow-y: hidden;
}

*::-ms-backdrop,
#list1 .avatar-md {
    width: inherit !important;
}

*::-ms-backdrop,
#list3 .me-4 {
    margin-right: 0 !important;
}

*::-ms-backdrop,
.app-sidebar .slide .side-menu__item.active::before {
    top: 0;
}

*::-ms-backdrop,
.tab-content.border-bottom.p-4 {
    height: inherit !important;
}

*::-ms-backdrop,
.datetimepicker table th.prev span,
*::-ms-backdrop,
.datetimepicker table th.next span {
    top: 20px !important;
}

@media (min-width: 1025px) {

    *::-ms-backdrop,
    .btn,
    .sp-container button {
        padding: 12px;
    }
}

.adtocart {
    background: linear-gradient(45deg, #f93a5a, #f7778c) !important;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    color: #fff;
    display: inline-block;
    text-align: center;
    border: 3px solid #fff;
    left: 40%;
    bottom: -24px;
    position: absolute;
}

.adtocart i {
    color: #fff;
    font-size: 32px;
    line-height: 42px;
}

.pro-title {
    color: #5A5A5A;
    display: inline-block;
    margin-top: 20px;
    font-size: 16px;
}

.product-list .price {
    color: #fc5959;
    font-size: 15px;
}

.pro-img-box {
    position: relative;
}

.prod-cat li ul li a:hover,
.prod-cat li ul li a:focus {
    background: none;
    color: #f34343;
}

.prod-cat li ul li.active a {
    background: none;
    color: #f34343;
}

.prod-cat li a:hover,
.prod-cat li a:focus,
.prod-cat li a.active {
    background: none;
    color: #f34343;
}

.nav.prod-cat {
    position: relative;
    display: block;
    padding: 10px 5px;
}

.prod-cat li ul {
    margin-left: 44px;
    display: block;
}

.prod-cat li ul li {
    padding: 10px 15px;
}

.nav.prod-cat li {
    padding: 10px 0;
}

.product-pagination .page-link {
    background-color: #f9f9fb;
    border: 1px solid #ededf5;
}

.product-pagination .page-item.disabled .page-link {
    border-color: #ededf5;
}

.nav.prod-cat li a {
    color: #031b4e;
}

.nav.prod-cat li a i {
    margin-right: 14px;
}

.product-sale {
    position: absolute;
    width: 100%;
    top: 10px;
    left: 13px;
    right: 5px;
}

.product-sale .badge {
    color: #fff;
    font-size: 12px !important;
    margin-top: 10px;
}

.product-sale .wishlist {
    float: right;
    right: 26px;
    position: absolute;
    font-size: 18px !important;
    top: 5px;
}

.shopping-cart,
.wishlist-table,
.order-table {
    margin-bottom: 20px;
}

.shopping-cart .table,
.wishlist-table .table,
.order-table .table {
    margin-bottom: 0;
}

.shopping-cart .btn,
.wishlist-table .btn,
.order-table .btn {
    margin: 0;
}

.shopping-cart>table>thead>tr>th,
.shopping-cart>table>thead>tr>td {
    vertical-align: middle !important;
}

.shopping-cart>table>tbody>tr>th,
.shopping-cart>table>tbody>tr>td {
    vertical-align: middle !important;
}

.wishlist-table>table>thead>tr>th,
.wishlist-table>table>thead>tr>td {
    vertical-align: middle !important;
}

.wishlist-table>table>tbody>tr>th,
.wishlist-table>table>tbody>tr>td {
    vertical-align: middle !important;
}

.order-table>table>thead>tr>th,
.order-table>table>thead>tr>td {
    vertical-align: middle !important;
}

.order-table>table>tbody>tr>th,
.order-table>table>tbody>tr>td {
    vertical-align: middle !important;
}

.shopping-cart>table thead th,
.wishlist-table>table thead th,
.order-table>table thead th {
    padding-top: 17px;
    padding-bottom: 17px;
    border-width: 1px;
}

.shopping-cart .count-input,
.wishlist-table .count-input,
.order-table .count-input {
    display: inline-block;
    width: 100%;
    width: 86px;
}

.shopping-cart .product-item,
.wishlist-table .product-item,
.order-table .product-item {
    display: table;
    width: 100%;
    min-width: 150px;
    margin-top: 5px;
    margin-bottom: 3px;
}

.shopping-cart .product-item .product-thumb,
.shopping-cart .product-item .product-info {
    display: table-cell;
    vertical-align: top;
}

.wishlist-table .product-item .product-thumb,
.wishlist-table .product-item .product-info {
    display: table-cell;
    vertical-align: top;
}

.order-table .product-item .product-thumb,
.order-table .product-item .product-info {
    display: table-cell;
    vertical-align: top;
}

.shopping-cart .product-item .product-thumb,
.wishlist-table .product-item .product-thumb,
.order-table .product-item .product-thumb {
    width: 109px;
    padding-right: 0;
}

.shopping-cart .product-item .product-thumb>img,
.wishlist-table .product-item .product-thumb>img,
.order-table .product-item .product-thumb>img {
    display: block;
    width: 85px;
    height: 85px;
}

@media screen and (max-width: 860px) {

    .shopping-cart .product-item .product-thumb,
    .wishlist-table .product-item .product-thumb,
    .order-table .product-item .product-thumb {
        display: none;
    }
}

.shopping-cart .product-item .product-info span,
.wishlist-table .product-item .product-info span,
.order-table .product-item .product-info span {
    display: block;
    font-size: 13px;
    padding: 3px 0;
}

.shopping-cart .product-item .product-info span>em,
.wishlist-table .product-item .product-info span>em,
.order-table .product-item .product-info span>em {
    font-weight: 500;
    font-style: normal;
}

.shopping-cart .product-item .product-title,
.wishlist-table .product-item .product-title,
.order-table .product-item .product-title {
    margin-bottom: 6px;
    padding-top: 5px;
    font-size: 15px;
    font-weight: 500;
}

.table-responsive.shopping-cart {
    overflow-y: inherit;
    overflow-x: scroll;
}

.shopping-cart .product-item .product-title>a,
.wishlist-table .product-item .product-title>a,
.order-table .product-item .product-title>a {
    transition: color 0.3s;
    color: #374250;
    line-height: 1.5;
    text-decoration: none;
}

.shopping-cart .product-item .product-title>a:hover,
.wishlist-table .product-item .product-title>a:hover,
.order-table .product-item .product-title>a:hover {
    color: var(--primary);
}

.shopping-cart .product-item .product-title small,
.wishlist-table .product-item .product-title small,
.order-table .product-item .product-title small {
    display: inline;
    margin-left: 6px;
    font-weight: 500;
}

.wishlist-table .product-item .product-thumb {
    display: table-cell !important;
}

@media screen and (max-width: 576px) {
    .wishlist-table .product-item .product-thumb {
        display: none !important;
    }
}

.shopping-cart-footer {
    display: table;
    width: 100%;
    border-top: 1px solid #e1e7ec;
}

.shopping-cart-footer>.column {
    display: table-cell;
    padding: 0;
    vertical-align: middle;
}

.shopping-cart-footer>.column:last-child {
    text-align: right;
}

.shopping-cart-footer>.column:last-child .btn {
    margin-right: 0;
    margin-left: 5px;
}

@media (max-width: 768px) {
    .shopping-cart-footer>.column {
        display: block;
        width: 100%;
    }

    .shopping-cart-footer>.column:last-child {
        text-align: center;
    }

    .shopping-cart-footer>.column .btn {
        width: 100%;
        margin: 5px 0 !important;
    }
}

.coupon-form .form-control {
    display: inline-block;
    width: 100%;
    max-width: 235px;
    margin-right: 12px;
}

.product-details tr>td {
    vertical-align: middle !important;
}

.product-details>table thead th {
    padding-top: 17px;
    padding-bottom: 17px;
    border-width: 1px;
    text-align: center;
}

.product-details .media {
    display: flex;
    align-items: flex-start;
    padding: 0.75rem;
}

@media screen and (max-width: 850px) {
    .shopping-cart-footer>.column {
        display: flow-root;
    }
}

@media screen and (max-width: 767px) {
    .coupon-form .form-control {
        max-width: inherit !important;
    }
}

@media (min-width: 768px) {
    #checkoutsteps .checkoutline {
        height: 3px;
        background-color: #eaedf7;
        -webkit-transform: translateY(1.7rem);
        transform: translateY(1.7rem);
        margin: 0 11%;
        position: relative;
        z-index: 0;
        top: 28px;
    }
}

#checkoutsteps ul {
    list-style: none !important;
    padding: 0;
    margin: 0;
}

#checkoutsteps ul[role=tablist] li {
    display: flex;
    z-index: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    flex-basis: auto;
    font-weight: 500;
    font-size: 1.1rem;
}

#checkoutsteps>.steps a {
    display: block;
    width: auto;
    background-color: transparent;
    color: #2d2c40;
    font-size: 16px;
    font-weight: 400;
    padding: 14px 0;
    text-decoration: none;
    border-radius: 30px;
    cursor: default;
    text-align: center;
    outline: none;
}

.wizard>.steps>ul li .current-info {
    display: none;
}

#checkoutsteps>.steps .current a .number {
    background: var(--primary);
    color: #ffffff;
}

#checkoutsteps .item .thumb {
    display: inline-flex;
    width: 100px;
    height: 90px;
    justify-content: center;
    align-items: center;
    border: 1px solid #f2f2f2;
}

#checkoutsteps .item .left {
    display: flex;
    align-items: center;
}

#checkoutsteps .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
    border-bottom: 1px solid #ededf5;
    margin-bottom: 30px;
}

#checkoutsteps .item .purchase {
    text-decoration: none;
}

#checkoutsteps .item .purchase {
    display: inline-block;
    margin-left: 21px;
}

#checkoutsteps .total {
    float: right;
    color: var(--primary);
}

#checkoutsteps>.steps {
    position: relative;
    display: block;
    width: 100%;
    border-radius: 3px;
    padding: 0;
}

#checkoutsteps.wizard {
    border-radius: 10px;
}

.wizard>.content>.title {
    font-size: 18px;
    color: #8f9cc0;
    font-weight: 700;
    margin-bottom: 5px;
    display: none;
}

.wizard>.content>.body {
    float: none;
    position: static;
    width: auto;
    height: auto;
}

.wizard>.content>.title.current {
    display: none !important;
}

.card-pay .tabs-menu.nav li {
    border-right: 1px solid #d3dfea;
}

.card-pay .tabs-menu li {
    width: 33.3%;
    display: block;
}

.card-pay .tabs-menu li a.active {
    background: var(--primary);
    color: #ffffff;
}

.card-pay .tabs-menu li a {
    padding: 0.7rem 1rem;
    display: block;
    text-align: center;
}

.card-pay .tabs-menu.nav {
    background: #f0f3ff;
}

.card-pay .tabs-menu {
    margin-bottom: 25px;
    border-radius: 5px;
    overflow: hidden;
}

@media (min-width: 768px) {
    #checkoutsteps>.steps .number {
        background-color: #eaedf7;
        color: #9197ab;
        font-size: 17px;
        font-weight: 400;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 15px auto;
    }
}

@media (max-width: 767px) {
    #checkoutsteps>.steps .number {
        background-color: #eaedf7;
        color: #9197ab;
        font-size: 15px;
        font-weight: 600;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 15px auto;
    }
}

@media (max-width: 767px) {
    #checkoutsteps>.steps a {
        font-size: 12px !important;
    }
}

@media (max-width: 767px) {
    #checkoutsteps .checkoutline {
        height: 3px;
        background-color: #eaedf7;
        -webkit-transform: translateY(1.7rem);
        transform: translateY(1.7rem);
        margin: 0 11%;
        position: relative;
        z-index: 0;
        top: 20px;
    }
}

.cart-img {
    height: 55px !important;
    width: 55px;
    border-radius: 10px !important;
}

.widget-user .widget-user-header {
    padding: 20px;
    height: 120px;
}

.widget-user .widget-user-username {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 25px;
    font-weight: 300;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    color: #fff;
}

.widget-user .widget-user-desc {
    margin-top: 0;
    color: #fff;
}

.widget-user .widget-user-image {
    position: absolute;
    top: 65px;
    left: 50%;
    margin-left: -45px;
}

.widget-user .widget-user-image>img {
    width: 90px;
    height: auto;
    border: 3px solid #fff;
}

.widget-user .user-wideget-footer {
    padding-top: 30px;
}

.user-wideget-footer {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 1px solid #f4f4f4;
    padding: 10px;
    background-color: #fff;
}

.user-wideget .border-right {
    border-right: 1px solid #f4f4f4;
}

.description-block {
    display: block;
    margin: 10px 0;
    text-align: center;
}

.description-block>.description-header {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 16px;
}

.description-block>.description-text {
    text-transform: uppercase;
    color: #a3b1c9;
}

/* HORIZONTAL WIZARD */
.mat-stepper-horizontal .mat-horizontal-stepper-header-container .mat-step-header .mat-step-icon {
    height: 32px;
    width: 32px;
    background-color: var(--neutral-50);
    border: 2px solid var(--neutral-60);
}

.mat-stepper-horizontal .mat-horizontal-stepper-header-container .mat-step-header .mat-step-icon .mat-step-icon-content {
    color: var(--neutral-50);
}

.mat-stepper-horizontal .mat-horizontal-stepper-header-container .mat-step-header .mat-step-icon .mat-step-icon-content span {
    font-weight: 200;
}

.mat-stepper-horizontal .mat-horizontal-stepper-header-container .mat-step-header .mat-step-icon-selected {
    background-color: var(--secondary);
    border: none;
}

.mat-stepper-horizontal .mat-horizontal-stepper-header-container .mat-step-header .mat-step-label {
    font-family: "Poppins", sans-serif;
}

.mat-stepper-horizontal .mat-horizontal-stepper-header-container .mat-step-header .mat-step-icon-state-passed {
    background-color: var(--secondary-25);
    border: 2px solid var(--secondary);
}

.mat-stepper-horizontal .mat-horizontal-stepper-header-container .mat-step-header .mat-step-icon-state-passed .mat-step-icon-content {
    color: var(--secondary);
}

.mat-stepper-horizontal .mat-horizontal-stepper-header-container .mat-stepper-horizontal-line {
    border: 1px solid var(--neutral-60);
}

.mat-stepper-horizontal.completed .mat-horizontal-stepper-header-container .mat-step-header .mat-step-icon {
    background-color: var(--success);
    border: 2px solid var(--success);
}

.mat-stepper-horizontal.completed .mat-horizontal-stepper-header-container .mat-step-header .mat-step-icon .mat-step-icon-content {
    color: #fff;
}

.mat-stepper-horizontal.completed .mat-horizontal-stepper-header-container .mat-stepper-horizontal-line {
    border: 1px solid var(--success-50) !important;
}

.mat-stepper-label-position-bottom.inverse-label .mat-stepper-horizontal-line {
    margin: 0;
    min-width: 0;
    position: relative;
    top: -40px;
    align-self: flex-end;
}

.mat-stepper-label-position-bottom.inverse-label .mat-horizontal-stepper-header {
    flex-direction: column-reverse;
}

.mat-stepper-label-position-bottom.inverse-label .mat-horizontal-stepper-header:after,
.mat-stepper-label-position-bottom.inverse-label .mat-horizontal-stepper-header:before {
    top: auto;
    bottom: 40px;
}

.mat-stepper-label-position-bottom.inverse-label .mat-horizontal-stepper-header:not(:last-child):after,
.mat-stepper-label-position-bottom.inverse-label .mat-horizontal-stepper-header:not(:first-child):before {
    border: 1px solid var(--neutral-60);
}

.mat-stepper-label-position-bottom.inverse-label .mat-horizontal-stepper-header:has(.mat-step-icon-state-passed)+.mat-stepper-horizontal-line,
.mat-stepper-label-position-bottom.inverse-label .mat-horizontal-stepper-header:has(.mat-step-icon-state-edit):before,
.mat-stepper-label-position-bottom.inverse-label .mat-horizontal-stepper-header:has(.mat-step-icon-state-passed):not(:last-child):after,
.mat-stepper-label-position-bottom.inverse-label .mat-horizontal-stepper-header:has(.mat-step-icon-state-passed):before {
    border: 1px solid var(--secondary-50);
}

.mat-stepper-label-position-bottom.inverse-label .mat-horizontal-stepper-header:has(.mat-step-icon-state-edit)+.mat-stepper-horizontal-line,
.mat-stepper-label-position-bottom.inverse-label .mat-horizontal-stepper-header:has(.mat-step-icon-state-edit):after,
.mat-stepper-label-position-bottom.inverse-label .mat-horizontal-stepper-header:has(.mat-step-icon-state-edit)+*+.mat-horizontal-stepper-header:before {
    border: 1px dashed var(--secondary);
}

.mat-stepper-label-position-bottom.inverse-label .mat-horizontal-stepper-header .mat-step-label {
    padding: 0;
}

.mat-stepper-label-position-bottom.inverse-label.completed .mat-horizontal-stepper-header:not(:last-child):after,
.mat-stepper-label-position-bottom.inverse-label.completed .mat-horizontal-stepper-header:not(:first-child):before {
    border: 1px solid var(--success-50);
}

/* ######## LAYOUT-STYLES ######## */
/* ###### 6.2 Footer  ###### */
.main-footer {
    background-color: #fff;
    border-top: 1px solid #ededf5;
    margin-top: auto;
}

@media (min-width: 992px) {
    .main-footer {
        display: block;
    }

    .sticky {
        width: 100%;
    }
}

.side-header {
    position: sticky;
    width: 100%;
    z-index: 10 !important;
    border-bottom: 1px solid #ededf5;
}

.main-footer .container {
    font-size: 14px;
    padding: 38px 20px 0;
    align-items: center;
    justify-content: space-between;
    color: #7987a1;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
}

.main-footer .container-fluid {
    font-size: 14px;
    padding: 38px 20px 0;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
}

@media (min-width: 992px) {
    .main-footer .container {
        padding: 10px 0 0;
    }
}

.main-footer-app .container,
.main-footer-app .container-fluid {
    border-top-width: 0;
}

@media (max-width: 480px) {
    .main-footer .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 380px) {
    .main-footer .container-fluid {
        font-size: 12px;
    }
}

.main-footer-demo {
    background-color: #0c025b;
    color: rgba(255, 255, 255, 0.4);
    height: 60px;
}

.main-footer-demo .container {
    border-top-width: 0;
}

.main-footer-demo a {
    color: rgba(255, 255, 255, 0.5);
}

.main-footer-demo a:hover,
.main-footer-demo a:focus {
    color: #fff;
}

/* ###### 6.4 Header  ###### */
@media (max-width: 991.98px) {
    .main-header-menu-show {
        overflow: hidden;
    }

    .main-header-menu-show .main-header-menu {
        border-right: 1px solid rgba(28, 39, 60, 0.08);
        box-shadow: 0 0 30px rgba(28, 39, 60, 0.08);
        transform: translateX(0);
    }

    .main-header-menu-show .main-navbar-backdrop {
        visibility: visible;
        opacity: 1;
    }
}

.main-header {
    background-color: transparent;
    z-index: 100;
    height: 64px;
    background: #fff;
    border-bottom: 1px solid #ededf5;
    z-index: 1025 !important;
    width: 100% !important;
    position: fixed;
    margin-bottom: 20px;
    box-shadow: 0px 7px 26px rgba(154, 154, 204, 0.1);
}

.main-header>.container,
.main-header>.container-fluid {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
}

.main-header .dropdown-menu {
    border: 1px solid #ededf5 !important;
}

.main-header .dropdown-menu .main-message-list .dropdown-item {
    padding: 0.75rem;
}

.main-header .dropdown-menu .main-message-list .dropdown-item:last-of-type {
    border-bottom: 0 !important;
}

.main-header .dropdown-menu .main-notification-list a:last-of-type {
    border-bottom: 0 !important;
}

@media (min-width: 992px) {

    .main-header>.container,
    .main-header>.container-fluid {
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media (min-width: 992px) {
    .main-header>.container {
        padding: 0;
    }
}

@media (max-width: 991.98px) {
    .main-header>.container {
        max-width: none;
    }
}

@media (max-width: 1158px) {
    .main-header-left #dropdownMenuButton {
        display: none;
    }
}

@media (max-width: 1052px) {
    .main-header-left #dropdownMenuButton2 {
        display: none;
    }
}

@media (max-width: 633px) {
    .profile-name.ms-2 {
        display: none;
        margin-left: 0;
    }
}

.main-profile-menu.nav-link {
    padding: 0rem 0rem !important;
    margin: auto 6px;
}

.main-header .main-header-left .main-logo {
    display: none;
}

@media (min-width: 992px) {
    .main-header .main-header-left .main-logo {
        display: flex;
    }
}

.main-header-left {
    display: flex;
    align-items: center;
}

.main-header-left .list-items {
    margin-bottom: 0;
}

.main-header-left .list-items li {
    display: inline-block;
}

.main-header-left .list-items li i {
    display: block;
    font-size: 24px;
    color: #323251;
    position: relative;
    line-height: 0.9;
    outline: none;
    margin-right: 15px;
}

.main-header-left .main-header-arrow {
    display: none;
    position: relative;
    top: -2px;
}

.main-logo {
    display: flex;
    align-items: center;
    position: relative;
    height: 2.5rem;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
}

.main-body.main-sidebar-hide .logo-icon {
    display: block;
    height: 2.3rem;
    text-align: center;
    margin: 0 auto;
}

.main-body.main-sidebar-hide .main-logo {
    display: none;
}

.main-logo:hover,
.main-logo:focus {
    color: var(--primary);
}

.main-logo i {
    display: block;
    line-height: 0.9;
    margin-right: 8px;
    font-size: 40px;
}

.main-logo i::before {
    width: auto;
    height: auto;
}

.main-header-menu-icon {
    margin-right: 10px;
    width: 20px;
    height: 30px;
    display: flex;
    align-items: center;
}

.main-header-menu-icon span {
    display: block;
    position: relative;
    width: 15px;
    height: 2px;
    background-color: #323251;
    border-radius: 3px;
    transition: all 0.2s ease-in-out;
}

.main-header-menu-icon span::before,
.main-header-menu-icon span::after {
    content: "";
    position: absolute;
    left: 0;
    width: 20px;
    height: 2px;
    background-color: #323251;
    border-radius: 3px;
    transition: all 0.2s ease-in-out;
}

.main-header-menu-icon span::before {
    top: -6px;
}

.main-header-menu-icon span::after {
    bottom: -6px;
}

@media (min-width: 992px) {
    .main-header-menu-icon {
        margin-right: 25px;
    }
}

@media (prefers-reduced-motion: reduce) {
    .main-header-menu-icon span {
        transition: none;
    }
}

@media (prefers-reduced-motion: reduce) {

    .main-header-menu-icon span::before,
    .main-header-menu-icon span::after {
        transition: none;
    }
}

.main-header-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 240px;
    background-color: #fff;
    overflow-y: auto;
    z-index: 1000;
    transform: translateX(-240px);
    transition: all 0.35s;
}

.main-header-menu>.nav {
    flex-direction: column;
    padding: 20px;
}

.main-header-menu .nav-item::before {
    content: "";
    position: absolute;
    top: 67px;
    margin-left: 25px;
    width: 15px;
    height: 15px;
    border-top: 2px solid rgba(28, 39, 60, 0.12);
    border-left: 2px solid rgba(28, 39, 60, 0.12);
    background-color: #fff;
    transform: rotate(45deg);
    z-index: 100;
    display: none;
}

.main-header-menu .nav-item+.nav-item {
    margin-top: 10px;
}

.main-header-menu .nav-item>.nav-link {
    display: flex;
    align-items: center;
    position: relative;
    color: #4a4a69;
    font-size: 15px;
    font-weight: 500;
    padding: 0;
    transition: all 0.2s ease-in-out;
}

.main-header-menu .nav-item>.nav-link:hover {
    color: var(--primary);
}

.main-header-menu .nav-item>.nav-link:hover i {
    color: var(--primary);
}

.main-header-menu .nav-item>.nav-link.with-sub::after {
    content: "\f3d0";
    display: inline-block;
    font-family: "Ionicons";
    font-size: 12px;
    font-weight: 400;
    margin-left: auto;
    margin-top: 3px;
    opacity: 0.5;
}

.main-header-menu .nav-item>.nav-link .typcn {
    font-size: 20px;
    line-height: 0.95;
    margin-right: 15px;
    width: 16px;
}

.main-header-menu .nav-item>.nav-link .typcn::before {
    width: auto;
}

.main-header-menu .nav-item.active>.nav-link {
    color: var(--primary);
    position: relative;
}

.main-header-menu .nav-item.active>.nav-link::before {
    content: "";
    position: absolute;
    bottom: 0;
    top: 0;
    left: -20px;
    width: 2px;
    background-color: var(--primary);
    border-radius: 3px;
}

.main-header-menu .nav-item.show .main-menu-sub {
    display: block;
}

@media (prefers-reduced-motion: reduce) {
    .main-header-menu {
        transition: none;
    }
}

@media (min-width: 992px) {
    .main-header-menu {
        background-color: transparent;
        border-right: 0;
        position: static;
        width: auto;
        overflow-y: visible;
        transform: none;
        z-index: auto;
    }
}

@media (min-width: 992px) {
    .main-header-menu>.nav {
        padding: 0;
        flex-direction: row;
        align-items: center;
    }
}

@media (min-width: 992px) {
    .main-header-menu .nav-item+.nav-item {
        margin-top: 0;
        margin-left: 30px;
    }
}

@media (prefers-reduced-motion: reduce) {
    .main-header-menu .nav-item>.nav-link {
        transition: none;
    }
}

@media (min-width: 992px) {
    .main-header-menu .nav-item>.nav-link {
        font-size: 0.875rem;
    }
}

@media (min-width: 992px) {
    .main-header-menu .nav-item>.nav-link.with-sub::after {
        margin-left: 5px;
    }
}

@media (min-width: 992px) {
    .main-header-menu .nav-item>.nav-link .typcn {
        font-size: 18px;
        margin-right: 7px;
    }
}

@media (min-width: 992px) {
    .main-header-menu .nav-item.active>.nav-link::before {
        top: auto;
        bottom: -22px;
        left: 0;
        right: 0;
        width: auto;
        height: 2px;
    }
}

@media (min-width: 992px) {
    .main-header-menu .nav-item.show::before {
        display: block;
    }
}

.main-header-menu-header {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border-bottom: 1px solid rgba(28, 39, 60, 0.05);
}

.main-header-menu-header .close {
    font-weight: 300;
    font-size: 28px;
}

@media (min-width: 992px) {
    .main-header-menu-header {
        display: none;
    }
}

.main-menu-sub {
    font-size: 13px;
    margin-left: 32px;
    margin-top: 12px;
    display: none;
}

.main-menu-sub .nav {
    flex-direction: column;
}

.main-menu-sub .nav-link {
    padding: 0;
    display: block;
    color: #4d5875;
}

.main-menu-sub .nav-link+.nav-link {
    margin-top: 6px;
    padding-top: 6px;
    border-top: 1px dotted #f0f0f8;
}

.main-menu-sub .nav-link:hover,
.main-menu-sub .nav-link:focus {
    color: var(--primary);
}

.main-menu-sub .nav-link.active {
    color: var(--primary);
    font-weight: 500;
}

@media (min-width: 992px) {
    .main-menu-sub {
        position: absolute;
        top: 74px;
        background-color: #fff;
        padding: 15px 20px;
        border: 2px solid rgba(28, 39, 60, 0.12);
        min-width: 200px;
        margin-left: 0;
        margin-top: 0;
    }
}

@media (min-width: 992px) {
    .main-menu-sub .nav-link.active {
        font-weight: 400;
    }
}

.main-menu-sub-mega {
    left: 0;
    right: 0;
    background-color: transparent;
    padding: 0;
    border: 0;
}

.main-menu-sub-mega .container {
    padding: 0;
}

.main-menu-sub-mega .container>div+div {
    margin-top: 25px;
}

.main-menu-sub-mega .nav {
    min-width: 120px;
    flex-direction: column;
}

.main-menu-sub-mega .nav+.nav {
    border-top: 1px dotted #f0f0f8;
    margin-top: 6px;
    padding-top: 6px;
}

.main-menu-sub-mega .nav>span {
    display: block;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    color: #323251;
    letter-spacing: 0.5px;
    margin-bottom: 15px;
    margin-top: 30px;
}

.main-menu-sub-mega .nav>span:first-of-type {
    margin-top: 0;
}

@media (max-width: 991.98px) {
    .main-menu-sub-mega .container {
        max-width: none;
    }
}

@media (min-width: 992px) {
    .main-menu-sub-mega .container {
        display: flex;
        background-color: #fff;
        border: 2px solid rgba(28, 39, 60, 0.12);
    }
}

@media (min-width: 992px) {
    .main-menu-sub-mega .container>div {
        display: flex;
        padding: 20px;
        flex: 1;
        margin-top: 0;
    }
}

@media (min-width: 1200px) {
    .main-menu-sub-mega .container>div {
        padding: 20px 25px;
    }
}

@media (min-width: 992px) {
    .main-menu-sub-mega .container>div+div {
        margin-top: 0;
        border-left: 1px solid rgba(28, 39, 60, 0.05);
    }
}

@media (min-width: 1200px) {
    .main-menu-sub-mega .nav {
        min-width: 140px;
    }
}

@media (min-width: 992px) {
    .main-menu-sub-mega .nav+.nav {
        margin-left: 20px;
        margin-top: 31px;
        padding-top: 0;
        border-top: 0;
    }
}

@media (min-width: 1200px) {
    .main-menu-sub-mega .nav+.nav {
        margin-left: 25px;
    }
}

@media (min-width: 992px) {
    .main-menu-sub-mega .nav>span {
        font-size: 12px;
    }
}

.main-header-center {
    flex: 1;
    margin: 0 50px;
    position: relative;
    display: none;
}

@media (min-width: 992px) {
    .main-header-center {
        display: block;
    }
}

.main-header-right .dropdown-menu:before {
    content: "";
    width: 13px;
    height: 13px;
    background: #fff;
    position: absolute;
    top: -7px;
    right: 28px;
    transform: rotate(45deg);
    border-top: 1px solid #e9edf4;
    border-left: 1px solid #e9edf4;
}

.main-profile-menu .dropdown-menu:before {
    right: 15px;
}

.main-header-right {
    display: flex;
    align-items: center;
}

.main-header-right .btn-social {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #949eb7;
    border-radius: 3px;
    transition: all 0.2s ease-in-out;
}

.main-header-right .btn-social:hover,
.main-header-right .btn-social:focus {
    color: #4d5875;
}

.main-header-right .btn-social i {
    font-size: 18px;
    line-height: 0;
}

.main-header-right .btn-social+.btn-social {
    margin-left: 10px;
}

.main-header-right .btn-buy {
    text-transform: uppercase;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 11px;
    font-weight: 500;
    background-color: var(--primary);
    color: #fff;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    border-radius: 3px;
    transition: all 0.2s ease-in-out;
    margin-left: 20px;
}

.main-header-right .btn-buy:hover,
.main-header-right .btn-buy:focus {
    background-color: #452efa;
}

@media (prefers-reduced-motion: reduce) {
    .main-header-right .btn-social {
        transition: none;
    }
}

@media (prefers-reduced-motion: reduce) {
    .main-header-right .btn-buy {
        transition: none;
    }
}

.main-header-search-link {
    font-size: 18px;
    color: #4d5875;
    line-height: 1;
    margin-right: 15px;
}

.main-header-arrow {
    font-size: 27px;
    color: #323251;
    display: inline-block;
    line-height: 0;
}

.main-header-arrow i {
    line-height: 0;
}

.main-header-arrow:hover,
.main-header-arrow:focus {
    color: #4d5875;
}

.header-icons .new.nav-link {
    position: relative;
    margin: auto 6px !important;
}

.header-icons .new.nav-link {
    display: block;
    font-size: 22px;
    color: var(--primary02);
    position: relative;
    line-height: 33px;
    outline: none;
    height: 37px;
    width: 37px;
    margin: auto;
    text-align: center;
}

.profile-name {
    margin-top: 3px;
}

.main-profile-menu>.profile-name::after {
    content: "";
    position: absolute;
    top: 46px;
    left: 50%;
    margin-left: -7px;
    width: 14px;
    height: 14px;
    border: 2px solid transparent;
    border-top-color: var(--primary);
    border-left-color: var(--primary);
    transform: rotate(45deg);
    background-color: var(--primary);
    z-index: 7;
    display: none;
}

@media (min-width: 992px) {

    .main-header-notification>a::after,
    .main-header-message>a::after {
        bottom: -36px;
    }
}

.main-header-notification .dropdown-menu {
    background-color: #fff;
    width: 100%;
    position: fixed;
    border-radius: 0;
    width: 300px;
    position: absolute;
    top: 52px;
    left: auto;
    right: -10px;
    bottom: auto;
    padding: 0;
    z-index: 9;
}

.main-header-message .dropdown-menu {
    width: 300px;
    position: absolute;
    top: 52px;
    left: auto;
    right: -10px;
    bottom: auto;
    z-index: 9;
}

.main-header-notification .dropdown-footer {
    text-align: center;
    padding-top: 10px;
    font-size: 13px;
    border-top: 1px dotted #d6d6e6;
}

.main-header-notification.show .dropdown-menu {
    display: block;
}

@media (min-width: 576px) {

    .main-header-notification.show>a::after,
    .main-header-message.show>a::after,
    .drop-flag.show>a::after {
        display: block;
    }
}

.main-header-message.show .dropdown-menu,
.drop-flag.show .dropdown-menu {
    display: block;
}

.main-notification-title {
    font-size: 18px;
    font-weight: 700;
    color: #323251;
    margin-bottom: 5px;
    line-height: 1;
}

.main-notification-text {
    font-size: 13px;
    margin-bottom: 15px;
}

.main-notification-list .media {
    padding: 10px 0;
    position: relative;
}

.main-notification-list .media+.media {
    border-top: 1px dotted #d6d6e6;
}

.main-notification-list .media.new {
    color: #323251;
}

.main-notification-list .media:hover,
.main-notification-list .media:focus {
    z-index: 1;
}

.main-notification-list .media:hover::before,
.main-notification-list .media:focus::before {
    content: "";
    position: absolute;
    top: -1px;
    left: -20px;
    bottom: -1px;
    right: -20px;
    background-color: #f9f9fb;
    border-top: 1px solid #ededf5;
    border-bottom: 1px solid #ededf5;
    z-index: -1;
}

.main-notification-list .media-body {
    margin-left: 15px;
    font-size: 13px;
}

.main-notification-list .media-body p {
    margin-bottom: 5px;
    line-height: 1.3;
}

.main-notification-list .media-body strong {
    font-weight: 500;
}

.main-notification-list .media-body span {
    display: block;
    font-size: 11px;
    color: #949eb7;
}

.main-profile-menu>.main-img-user {
    outline: none;
    width: 32px;
    height: 32px;
}

.main-profile-menu>.main-img-user::before {
    content: "";
    position: absolute;
    bottom: -20px;
    right: -90px;
    margin-left: -7px;
    width: 14px;
    height: 14px;
    border: 2px solid transparent;
    border-top-color: #e1e1ef;
    border-left-color: #e1e1ef;
    transform: rotate(45deg);
    background-color: #fff;
    z-index: 901;
    display: none;
}

.main-profile-menu .dropdown-menu {
    background-color: #fff;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    border-width: 0;
    z-index: 900;
    border-radius: 0;
    position: absolute;
    top: 56px;
    left: auto;
    right: 0px;
    bottom: auto;
    width: 230px;
}

.main-profile-menu .dropdown-item {
    position: relative;
    padding: 20px 16px;
    height: 38px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #7a839b;
}

.main-profile-menu .dropdown-item i {
    font-size: 17px;
    margin-right: 10px;
    width: 24px;
    text-align: center;
}

.main-profile-menu .dropdown-item i.typcn {
    line-height: 0.9;
}

.main-profile-menu .dropdown-item:hover,
.main-profile-menu .dropdown-item:focus {
    background-color: transparent;
    color: var(--primary);
}

.main-profile-menu .dropdown-item+.dropdown-item {
    border-top: 1px solid #e2eaf9;
}

.main-profile-menu .dropdown-item:last-child {
    margin-bottom: 10px;
}

.main-profile-menu.show .dropdown-menu {
    display: block;
    top: 50px;
}

@media (min-width: 576px) {
    .main-profile-menu.show .main-img-user::before {
        display: block;
    }
}

.main-header-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-header-profile .main-img {
    margin-bottom: 10px;
    margin-top: 10px;
}

.main-header-profile .main-img img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.main-header-profile h6 {
    font-weight: 700;
    color: #323251;
    margin-bottom: 3px;
    font-size: 16px;
    color: #fff;
}

.main-header-profile span {
    display: block;
    font-size: 12px;
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.6);
}

@media (min-width: 576px) {
    .main-header-profile .main-img-user {
        width: 80px;
        height: 80px;
    }
}

.main-header-sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

/*--Search--*/
.main-header-right .input-group-btn .btn {
    border: 0px solid #ebeaf1 !important;
    background: transparent;
    border-radius: 3px;
    color: #3c4858;
    height: 38px;
    width: 38px;
    line-height: 24px;
    border-radius: 50px;
    font-size: 22px;
}

.main-header-right .input-group-btn .btn:hover {
    border-radius: 50%;
    color: var(--primary);
}

.main-header-right .input-group-btn .btn:hover i {
    color: #2205bf;
}

.main-header-right .input-group-btn .btn i {
    font-size: 16px;
    color: #3c4858;
}

.main-header-right .navbar-form.active .input-group-btn .btn i {
    font-size: 14px;
    line-height: 27px;
}

.main-header-right form[role=search] {
    top: 0px;
    right: 0;
    width: 100%;
    padding: 0px;
    margin: 0px;
    z-index: 0;
}

.main-header-right form[role=search] button {
    padding: 5px 12px;
    border-radius: 0px;
    border-width: 0px;
    color: #6f7282;
    background-color: rgb(248, 248, 248);
    border-color: rgb(231, 231, 231);
    box-shadow: none;
    outline: none;
}

.main-header-right form[role=search] input {
    padding: 5px 12px;
    border-radius: 0px;
    border-width: 0px;
    color: #6f7282;
    background-color: rgb(248, 248, 248);
    border-color: rgb(231, 231, 231);
    box-shadow: none;
    outline: none;
    padding: 16px 12px;
    font-size: 14px;
    color: #807b90;
    box-shadow: none;
}

.main-header-right form[role=search] button[type=reset] {
    display: none;
    background: #fff;
    border: 0 !important;
}

.main-header-right .input-group-btn .btn i {
    font-size: 18px;
    color: #7d7d86;
}

.main-header-message.right-toggle .nav-link {
    padding: 0;
}

@media (min-width: 992px) {
    .main-header-menu-icon {
        margin-right: 25px;
        margin-left: auto;
        margin-right: 8px !important;
    }
}

form[role=search] input {
    font-size: 16px;
    opacity: 0;
    display: none;
    height: 66px;
    transition: all 5s;
}

form[role=search].active {
    width: 100%;
    z-index: 9999;
    right: 0;
    border-left: 0px solid #e3e3f7;
    position: absolute;
    left: -1px;
    display: block;
}

form[role=search].active button {
    display: table-cell !important;
    opacity: 1;
}

form[role=search].active input {
    display: table-cell;
    opacity: 1;
    width: 100%;
    height: 64px;
    background: #fff;
    padding-left: 20px;
    border-radius: 0 0 5px 5px;
    transition: all 5s;
    box-shadow: 5px 6px 6px -3px #e2e1f5;
    top: 3.6rem;
}

.main-header-message i,
.main-header-notification i,
.nav-item.full-screen i {
    height: 37px;
    width: 37px;
    text-align: center;
    line-height: 37px;
    font-size: 17px;
}

.main-header-message .dropdown-footer,
.main-header-notification .dropdown-footer {
    border-top: 1px solid #ededf5;
    padding: 10px;
    background: var(--primary02);
    box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.1);
    border-radius: 0px 0px 10px 10px;
}

.main-header-message .dropdown-footer a {
    font-size: 13px;
}

.main-header-center {
    display: flex;
}

.header .form-control {
    border: 2px solid #e1e1ef;
}

@media (min-width: 992px) {
    .main-header.fixed-header {
        position: fixed;
        z-index: 999;
        margin-top: 0;
    }

    .main-header-left .form-control {
        height: 35px;
        border-radius: 5px;
        background: #fff;
        border: 1px solid #ededf5;
    }

    .main-header-left .btn {
        position: absolute;
        top: 0;
        right: 0px;
        background-color: #fff;
        height: 35px;
        color: #7b8191;
        transition: none;
        font-size: 14px;
        padding: 4px 15px;
        border: 1px solid #ededf5;
        border-left: 0px !important;
        border-radius: 0px 5px 5px 0px;
    }
}

.main-header-message .nav-link i,
.main-header-notification .nav-link i,
.nav-item.full-screen .nav-link i {
    text-align: center;
    line-height: 40px;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    text-align: center;
    line-height: 40px;
    font-size: 1.2rem;
    color: #39455d;
}

.header-icons .new.nav-link {
    padding: 0;
    border-radius: 25px;
}

.header-megamenu-dropdown i {
    font-size: 1.2rem;
    margin-right: 3px;
}

.main-header.hor-header {
    box-shadow: none;
    margin-bottom: 0;
}

.main-header.hor-header>.container-fluid {
    padding-left: 25px;
    padding-right: 25px;
    width: 1200px;
}

.main-header .main-header-left .main-logo {
    display: flex;
    margin: 0;
}

@media (max-width: 1134px) {
    .main-header-left .form-control {
        width: 175px;
    }
}

@media (min-width: 1400px) {
    .main-header-left .form-control {
        width: 400px !important;
    }
}

.main-header-arrow {
    display: none;
    position: relative;
    right: auto;
    display: none;
    position: relative;
    right: auto;
}

@media (max-width: 480px) {
    .main-header-right .nav .nav-item .dropdown-menu {
        left: auto;
        right: auto;
        justify-content: center;
        margin: 0 auto;
        width: 93%;
        position: fixed;
        left: 5px;
        right: 5px;
        margin-top: 61px;
    }
}

@media (min-width: 991px) {
    .header {
        width: 230px;
    }
}

@media (max-width: 991px) {

    .main-header-left .form-control,
    .main-header-center .btn {
        display: none;
    }
}

@media (max-width: 578px) {

    .main-header-message>a,
    .main-header-notification>a,
    .main-header-message>a,
    .nav-link.icon {
        padding: 0px;
    }

    .profile-user {
        padding: 0px;
    }

    .main-header-center {
        display: none;
    }

    .main-header-message.right-toggle .nav-link {
        padding: 0 0;
    }

    .main-header-message>a,
    .main-header-notification>a,
    .nav-item.full-screen>a,
    .nav-link.icon {
        line-height: 33px;
    }

    .nav-link.icon {
        top: 10px;
    }
}

@media (min-width: 768px) {
    .main-header {
        z-index: 1000 !important;
        width: 100% !important;
        left: 0;
        padding-left: 250px;
        position: fixed;
    }

    .app.sidenav-toggled .main-header {
        padding-left: 80px;
        padding-left: 80px;
    }

    .app.sidenav-toggled .main-sidebar-header {
        width: 80px;
    }
}

.right-toggle {
    cursor: pointer;
}

.horizontalMenucontainer .main-header.hor-header {
    padding-left: 0;
}

@media (max-width: 633px) and (min-width: 321px) {
    .main-profile-menu {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-right: 7px !important;
        padding-left: 7px !important;
    }
}

@media (max-width: 320px) {
    .main-profile-menu {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-right: 2px !important;
        padding-left: 2px !important;
    }

    .total-revenue label {
        font-size: 0.775rem !important;
    }

    .total-revenue h4 {
        font-size: 17px !important;
    }
}

@media (max-width: 767px) {
    .btn.btn-default.nav-link.resp-btn {
        display: block;
        font-size: 22px;
        color: #272746;
        position: relative;
        line-height: 18px;
        outline: none;
        height: 34px;
        width: 34px;
        margin: auto;
        text-align: center;
        border: 0px solid #dfd7e9;
        padding: 2px;
    }
}

.main-header .main-header-right .nav-link.icon {
    margin: 0px;
    text-align: center;
    font-size: 1.2rem;
    position: relative;
}

.nav-link.icon i {
    color: #7a839b !important;
    font-size: 20px;
    position: relative;
}

@media (max-width: 768px) {
    .responsive-navbar .collapsing .dropdown-menu {
        display: none !important;
    }

    .responsive-navbar .collapse.show .dropdown-menu.show {
        display: block !important;
    }
}

@media (max-width: 360px) {
    .responsive-navbar.navbar .new.nav-link {
        margin: 10px 2px !important;
    }
}

@media (min-width: 768px) {
    .horizontal .horizontal-main .sub-slide2 .sub-slide-menu1 {
        left: 180px;
        top: 8px;
        width: 190px;
        position: absolute;
        background-color: #fff;
        z-index: 9999;
        border-radius: 5px;
        padding: 5px;
        min-width: fit-content;
        box-shadow: 0px 16px 18px rgba(135, 135, 182, 0.1) !important;
        border: 1px solid #e7eaf3;
    }

    .horizontal .app-sidebar {
        border-bottom: 0px !important;
    }

    .horizontal .main-footer {
        padding: 0.9rem 1rem 0.9rem 1rem !important;
    }

    .horizontal.dark-theme .horizontal-main .slide .slide-menu,
    .horizontal.dark-theme .horizontal-main .slide .sub-slide-menu,
    .horizontal.dark-theme .horizontal-main .sub-slide2 .sub-slide-menu1 {
        background-color: #2a2e3f !important;
        border: 1px solid #404353;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2) !important;
    }

    .horizontal .horizontal-main {
        z-index: 999 !important;
    }

    .horizontal.dark-theme .logo-1 {
        display: none !important;
    }

    .horizontal.dark-theme .dark-logo-1 {
        display: block !important;
    }

    .horizontal .hor-header .container,
    .horizontal .horizontal-main .container,
    .horizontal .main-content.horizontal-content .container {
        max-width: 85% !important;
        padding: 0;
    }

    .horizontal .app-sidebar .side-item.side-item-category {
        display: none;
    }

    .horizontal .side-menu {
        display: flex;
        overflow: hidden;
    }

    .horizontal .main-sidemenu {
        margin-top: 0;
    }

    .horizontal .main-sidebar-header {
        display: none;
    }

    .horizontal .horizontal-main {
        width: 100%;
        position: relative;
        bottom: initial;
    }

    .horizontal .horizontal-main.ps {
        overflow: visible !important;
    }

    .horizontal .horizontal-main .slide .slide-menu,
    .horizontal .horizontal-main .slide .sub-slide-menu {
        width: 190px;
        position: absolute;
        background-color: #fff;
        z-index: 9999;
        border-radius: 5px;
        padding: 5px;
        min-width: fit-content;
        box-shadow: 0px 16px 18px rgba(135, 135, 182, 0.1) !important;
        border: 1px solid #e7eaf3;
    }

    .horizontal .horizontal-main .slide .slide-menu .slide-item:before,
    .horizontal .horizontal-main .slide .sub-slide-menu .slide-item:before {
        left: 20px;
        top: 11px;
        margin-right: 8px;
    }

    .horizontal.horizontal-hover .horizontal-main .slide .slide-menu,
    .horizontal .horizontal-main .slide .slide-menu,
    .horizontal .horizontal-main .slide .sub-slide-menu {
        padding: 0 !important;
    }

    .horizontal .slide-item {
        padding: 8px 35px;
    }

    .horizontal .slide.is-expanded .sub-slide-item2:before {
        left: 20px !important;
    }

    .horizontal .sub-slide-menu .sub-slide-item2 {
        padding: 8px 20px 8px 35px !important;
    }

    .horizontal .sub-slide .sub-angle {
        right: -20px;
        left: auto;
        margin-right: 0 !important;
        position: relative;
    }

    .horizontal .sub-slide .sub-slide-menu {
        position: absolute;
        background-color: #fff;
        z-index: 9999;
        box-shadow: 5px 5px 5px #b9b9b9;
        border-radius: 5px;
        padding: 0px;
        min-width: fit-content;
        border: 1px solid #f0f0f8;
        left: 100%;
        top: 13px;
    }

    .horizontal .side-menu>li>a {
        display: flex;
        margin: 0px -4px !important;
        text-decoration: none;
        position: relative;
        color: #7b8191;
        padding: 17px 13px 17px 13px !important;
        font-weight: 400;
    }

    .horizontal .main-sidemenu i.angle {
        position: relative;
        top: 0px;
        right: 0;
        transform: rotate(90deg);
        font-size: 15px;
    }

    .horizontal .main-sidemenu .slide.is-expanded i.angle {
        transform: rotate(-90deg);
        position: relative;
        top: 0px;
        right: 0;
        font-size: 15px;
    }

    .horizontal .breadcrumb-header {
        margin-top: 20px;
    }

    .horizontal .side-menu .slide .side-menu__item.active {
        border-bottom: 3px solid var(--primary);
        border-right: none;
    }

    .horizontal .slide {
        margin: 0 3px;
    }

    .horizontal .logo-horizontal {
        display: block;
    }

    .horizontal .app-sidebar__toggle {
        display: none;
    }

    .horizontal .mobile-logo.dark-logo-1 {
        display: none;
    }

    .horizontal .main-content {
        margin-left: 0px !important;
    }

    .horizontal .main-sidemenu .slide-right {
        right: 0;
    }

    .horizontal .main-sidemenu .slide-left,
    .horizontal .main-sidemenu .slide-right {
        position: absolute;
        top: 0px;
        padding: 17px;
        color: #fff;
        bottom: 0;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        cursor: pointer;
    }

    .horizontal .transparent-theme .slide-rightRTL svg,
    .horizontal .transparent-theme .slide-leftRTL svg {
        fill: #fff;
    }

    .horizontal .main-sidemenu .slide-left {
        left: 0;
    }

    .horizontal .horizontal-main {
        position: relative;
        margin: 0 auto;
    }

    .horizontal .main-sidemenu {
        overflow: hidden;
    }

    .horizontal .sticky-pin {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 2;
    }

    .horizontal-hover.horizontal .slide:hover ul.slide-menu {
        display: block !important;
    }

    .horizontal-hover.horizontal .sub-slide:hover .sub-slide-menu {
        display: block !important;
    }
}

.logo-horizontal {
    display: none;
}

@media (max-width: 767.98px) {
    .horizontal .app-sidebar.horizontal-main {
        left: -300px;
    }

    .horizontal .main-header.hor-header {
        box-shadow: 0px 7px 26px rgba(154, 154, 204, 0.1);
    }

    .horizontal .main-sidemenu {
        padding: 0;
    }

    .horizontal .horizontalMenucontainer .main-header.hor-header {
        position: fixed;
    }

    .horizontal .slide-left,
    .horizontal .slide-right {
        display: none !important;
    }
}

.horizontal .main-sidemenu.container {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

@media (min-width: 768px) {
    .horizontal-hover.horizontal .sub-slide2:hover .sub-slide-menu1 {
        display: block !important;
    }

    .horizontal-hover.horizontal .app-sidebar .slide-menu .sub-slide-menu1 a:before {
        left: 20px !important;
        top: 11px;
    }

    .horizontal-hover.horizontal .sub-slide-menu .sub-side-menu__item:before {
        content: "\f22d";
        font-family: "Font Awesome 5 Free";
        position: absolute;
        left: 20px !important;
        top: 10px !important;
        font-size: 9px;
        color: #7b8191;
        font-weight: 900;
    }
}

/* ###### 6.5 Iconbar  ###### */
@media (max-width: 991.98px) {
    .main-iconbar-show .main-iconbar {
        display: flex;
    }

    .main-iconbar-show .main-iconbar-aside {
        display: block;
    }
}

.main-iconbar {
    width: 64px;
    display: none;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #fff;
    z-index: 500;
}

.main-iconbar .nav {
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding: 5px 0;
}

.main-iconbar .nav-link {
    position: relative;
    padding: 0;
    color: #4a4a69;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-iconbar .nav-link i {
    font-size: 28px;
}

.main-iconbar .nav-link i.typcn {
    line-height: 1;
}

.main-iconbar .nav-link i.typcn::before {
    width: auto;
}

.main-iconbar .nav-link i.typcn-device-laptop,
.main-iconbar .nav-link i.typcn-th-large-outline {
    font-size: 25px;
}

.main-iconbar .nav-link i.fa-desktop {
    font-size: 23px;
}

.main-iconbar .nav-link:hover,
.main-iconbar .nav-link:focus {
    color: #4d5875;
}

.main-iconbar .nav-link.active {
    color: var(--primary);
}

.main-iconbar .nav-link+.nav-link {
    margin-top: 10px;
}

@media (min-width: 992px) {
    .main-iconbar {
        display: flex;
    }
}

.main-iconbar-logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    line-height: 0.9;
    color: #323251;
    flex-shrink: 0;
    margin-bottom: 20px;
    position: relative;
}

.main-iconbar-logo::after {
    content: "";
    position: absolute;
    bottom: 6px;
    left: 20px;
    height: 3px;
    width: 24px;
    background-color: var(--primary);
    border-radius: 2px;
}

.main-iconbar-logo:hover,
.main-iconbar-logo:focus {
    color: #323251;
}

.main-iconbar-bottom {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-iconbar-help {
    line-height: 0;
    font-size: 22px;
    color: #7987a1;
    margin-bottom: 20px;
}

.main-iconbar-help:hover,
.main-iconbar-help:focus {
    color: #323251;
}

.main-iconbar-aside {
    width: 230px;
    height: 100%;
    background-color: #fff;
    border-right: 1px solid #d6d6e6;
    position: fixed;
    top: 0;
    left: 64px;
    overflow-y: auto;
    z-index: 600;
    display: none;
}

@media (min-width: 1200px) {
    .main-iconbar-aside {
        border-right: 0;
        z-index: 500;
    }
}

@media (min-width: 992px) {
    .main-iconbar-aside.show {
        display: block;
    }
}

@media (min-width: 1200px) {
    .main-iconbar-aside.show+.main-content {
        margin-left: 294px;
    }
}

.main-iconbar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    padding-left: 0;
}

.main-iconbar-header .main-logo {
    line-height: 1;
}

.main-iconbar-toggle-menu {
    display: block;
    position: relative;
    line-height: 0;
    color: #323251;
    font-size: 28px;
    opacity: 0.5;
}

.main-iconbar-toggle-menu:hover,
.main-iconbar-toggle-menu:focus {
    opacity: 1;
    color: #323251;
}

.main-iconbar-toggle-menu i:first-child {
    display: none;
}

@media (min-width: 1200px) {
    .main-iconbar-toggle-menu {
        font-size: 24px;
    }

    .main-iconbar-toggle-menu::before {
        content: "";
        position: absolute;
        top: 3px;
        bottom: 3px;
        left: -1px;
        border-left: 2px solid #323251;
    }

    .main-iconbar-toggle-menu i:first-child {
        display: block;
    }

    .main-iconbar-toggle-menu i:last-child {
        display: none;
    }
}

.main-iconbar-body {
    padding: 20px;
    padding-left: 0;
    height: calc(100vh - 68px);
}

.main-iconbar-body .nav {
    flex-direction: column;
    border-left: 2px solid #f0f0f8;
    padding-top: 10px;
    padding-left: 20px;
}

.main-iconbar-body .nav-item {
    position: relative;
    display: block;
}

.main-iconbar-body .nav-item+.nav-item {
    margin-top: -1px;
    border-top: 1px dotted #e1e1ef;
}

.main-iconbar-body .nav-item.active .nav-link {
    color: var(--primary);
}

.main-iconbar-body .nav-item.active .nav-link i {
    color: var(--primary);
}

.main-iconbar-body .nav-item.show .nav-sub {
    display: block;
}

.main-iconbar-body .nav-link {
    position: relative;
    color: #4a4a69;
    font-size: 13px;
    padding: 0;
    display: flex;
    align-items: center;
    height: 38px;
}

.main-iconbar-body .nav-link i {
    color: #949eb7;
    font-size: 16px;
    font-weight: 400;
    line-height: 0;
    margin-right: 10px;
}

.main-iconbar-body .nav-link i.typcn {
    line-height: 0.9;
}

.main-iconbar-body .nav-link i.typcn::before {
    width: auto;
}

.main-iconbar-body .nav-link:hover,
.main-iconbar-body .nav-link:focus,
.main-iconbar-body .nav-link:hover i,
.main-iconbar-body .nav-link:focus i {
    color: var(--primary);
}

.main-iconbar-body .nav-link.with-sub {
    justify-content: space-between;
}

.main-iconbar-body .nav-link.with-sub::after {
    content: "\f3d0";
    font-family: "Ionicons";
    font-size: 11px;
    font-weight: 400;
    display: inline-block;
    position: relative;
    margin-left: auto;
    color: #949eb7;
}

.main-iconbar-body .nav-sub {
    list-style: none;
    padding: 0;
    margin: 0;
    border-top: 1px dotted #e1e1ef;
    display: none;
}

.main-iconbar-body .nav-sub .nav-sub-item {
    position: relative;
    display: block;
    margin-left: 15px;
}

.main-iconbar-body .nav-sub .nav-sub-item+.nav-sub-item {
    border-top: 1px dotted #e1e1ef;
}

.main-iconbar-body .nav-sub .nav-sub-item.active>.nav-sub-link {
    color: var(--primary);
}

.main-iconbar-body .nav-sub .nav-sub-link {
    display: flex;
    align-items: center;
    height: 36px;
    color: #4a4a69;
}

.main-iconbar-body .nav-sub .nav-sub-link:hover,
.main-iconbar-body .nav-sub .nav-sub-link:focus {
    color: var(--primary);
}

@media (min-width: 992px) {
    .main-iconbar-body .nav-link.with-sub::after {
        margin-left: 4px;
        font-size: 12px;
    }
}

.main-iconbar-title {
    font-size: 20px;
    font-weight: 700;
    color: #323251;
    margin-bottom: 10px;
    line-height: 1;
}

.main-iconbar-text {
    display: block;
    font-size: 12px;
    color: #7987a1;
    margin-bottom: 12px;
}

.main-iconbar-pane {
    margin-bottom: 20px;
    display: none;
}

.main-iconbar-pane.show {
    display: block;
}

/* ###### 6.1 Content  ###### */
@media (max-width: 575px) {
    .main-content-left-show {
        overflow: hidden;
    }
}

@media (max-width: 991.98px) {
    .main-content-left-show .main-content-left {
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 800;
        border-right: 1px solid #ededf5;
        box-shadow: 0 0 3px rgba(28, 39, 60, 0.1);
        overflow-y: auto;
    }
}

@media (max-width: 575px) {
    .main-content-left-show .main-content-body {
        display: none;
    }
}

@media (max-width: 991.98px) {
    .main-content-body-show .main-header .main-header-menu-icon {
        display: none;
    }

    .main-content-body-show .main-header .main-header-left .main-header-arrow {
        display: block;
    }

    .main-content-body-show .main-content-left {
        display: none;
    }

    .main-content-body-show .main-content-body {
        display: block;
    }
}

.main-content .container,
.main-content .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
}

@media (min-width: 992px) {
    .main-content .container {
        padding: 0;
    }
}

@media (max-width: 991.98px) {
    .main-content .container {
        max-width: none;
    }

    .browser-stats .sub-text {
        display: none;
    }

    .browser-stats h6 {
        line-height: 2;
    }
}

.main-content-app {
    padding-top: 0;
}

.main-content-app .container,
.main-content-app .container-fluid {
    height: 100%;
    padding: 0;
}

@media (min-width: 992px) {
    .main-content-app {
        padding-top: 0;
    }
}

.main-content-left {
    width: 100%;
    position: relative;
    flex-shrink: 0;
}

@media (min-width: 992px) {
    .main-content-left {
        display: block;
        padding: 0;
    }
}

.main-content-header {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-content-header .main-logo {
    flex: 1;
    text-align: center;
}

@media (min-width: 992px) {
    .main-content-header {
        display: none;
    }
}

.main-content-body {
    flex: 1;
}

.main-content-right {
    padding-left: 25px;
    margin-left: 25px;
    width: 200px;
    display: none;
}

@media (min-width: 992px) {
    .main-content-right {
        display: block;
    }
}

.main-content-title {
    color: #323251;
    font-weight: 500;
    font-size: 18px;
    text-indent: -1px;
    line-height: 2;
    position: relative;
    margin-bottom: 20px;
}

@media (min-width: 992px) {
    .main-content-title {
        margin-bottom: 40px;
    }
}

.card-table-two .card-title,
.card-dashboard-eight .card-title {
    color: #323251;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 10px;
    letter-spacing: 0.2px;
}

.main-content-label-sm {
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    color: #949eb7;
    letter-spacing: 0.5px;
}

.main-content-text {
    font-size: 12px;
    line-height: 1.4;
    display: block;
    color: #7987a1;
}

.main-content-1 .main-content-header {
    display: block;
    padding: 20px;
    background-color: transparent;
    border: 0;
}

.main-content .header {
    border-radius: 8px;
    margin: 20px;
}

@media (min-width: 992px) {
    .main-content {
        padding-top: 0 !important;
        margin-left: 240px;
    }

    .main-body.main-sidebar-hide .main-content {
        margin-left: 80px !important;
        transition: margin-left 0.3s ease;
    }
}

@media (max-width: 991px) {
    .main-content-body-show .main-header-arrow {
        display: block;
        padding: 10px 20px;
        background: var(--primary);
        color: #fff;
    }
}

@media (min-width: 992px) {
    .main-content-body-show .main-header-arrow {
        display: none;
    }
}

@media (max-width: 635px) {
    .main-content .breadcrumb-header .breadcrumb-right {
        margin-top: 12px !important;
    }
}

.main-content-left-components {
    border-right: 1px solid #f0f0f8;
    width: 200px;
}

.main-content-left-components .component-item label {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.5px;
}

.main-content-left-components .component-item label~label {
    margin-top: 30px;
}

.main-content-left-components .component-item .nav-link {
    padding: 0;
    display: block;
    font-size: 13px;
    color: #4d5875;
}

.main-content-left-components .component-item .nav-link+.nav-link {
    margin-top: 5px;
}

.main-content-left-components .component-item .nav-link:hover {
    color: var(--primary);
}

.main-content-left-components .component-item .nav-link.active {
    color: var(--primary);
    font-weight: 500;
}

.main-content-choose-demo {
    position: relative;
    z-index: 5;
    background-color: #0f0373;
    color: rgba(255, 255, 255, 0.5);
    padding: 0;
}

.main-content-choose-demo .container {
    display: block;
    padding: 20px;
}

.main-content-choose-demo .title-label {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 10px;
    color: var(--primary);
    margin-bottom: 3px;
}

.main-content-choose-demo .title {
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    letter-spacing: -0.5px;
    margin-bottom: 10px;
}

.main-content-choose-demo .title-text {
    font-weight: 300;
    margin-bottom: 20px;
}

.main-content-choose-demo .card {
    border-width: 0;
    margin-bottom: 10px;
    height: 200px;
    overflow: hidden;
    position: relative;
}

.main-content-choose-demo .card img {
    transition: all 0.2s ease-in-out;
}

.main-content-choose-demo .card figure {
    margin-bottom: 0;
}

.main-content-choose-demo .card figure:hover img,
.main-content-choose-demo .card figure:focus img {
    transform: scale(1.75, 1.75);
}

.main-content-choose-demo .card figure:hover figcaption,
.main-content-choose-demo .card figure:focus figcaption {
    opacity: 1;
}

.main-content-choose-demo .card figcaption {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(25, 4, 190, 0.75);
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.main-content-choose-demo .card figcaption .btn,
.main-content-choose-demo .card figcaption .sp-container button {
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 11px;
    font-weight: 500;
    min-height: inherit;
    padding: 10px 20px;
    border-radius: 2px;
}

@media (min-width: 576px) {
    .main-content-choose-demo .container {
        padding: 40px;
    }
}

@media (min-width: 992px) {
    .main-content-choose-demo .container {
        padding: 100px 40px;
    }
}

@media (min-width: 1200px) {
    .main-content-choose-demo .container {
        padding: 100px 0;
    }
}

@media (min-width: 768px) {
    .main-content-choose-demo .title-label {
        font-size: 11px;
    }
}

@media (min-width: 768px) {
    .main-content-choose-demo .title {
        font-size: 30px;
    }
}

@media (min-width: 576px) {
    .main-content-choose-demo .title-text {
        margin-bottom: 40px;
    }
}

@media (min-width: 768px) {
    .main-content-choose-demo .title-text {
        font-size: 16px;
    }
}

@media (min-width: 992px) {
    .main-content-choose-demo .title-text {
        margin-bottom: 60px;
    }
}

@media (min-width: 576px) {
    .main-content-choose-demo .card {
        height: 155px;
    }
}

@media (min-width: 768px) {
    .main-content-choose-demo .card {
        height: 215px;
        margin-bottom: 15px;
    }
}

@media (min-width: 1200px) {
    .main-content-choose-demo .card {
        height: 255px;
    }
}

@media (prefers-reduced-motion: reduce) {
    .main-content-choose-demo .card img {
        transition: none;
    }
}

@media (prefers-reduced-motion: reduce) {
    .main-content-choose-demo .card figcaption {
        transition: none;
    }
}

.sp-container .main-content-choose-demo .card figcaption button {
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 11px;
    font-weight: 500;
    min-height: inherit;
    padding: 10px 20px;
    border-radius: 2px;
}

.main-content-choose-demo .card.coming-soon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #323251;
    background-color: #f0f0f8;
}

.main-content-choose-demo .card.coming-soon .typcn {
    line-height: 0.9;
    font-size: 48px;
    margin-bottom: 10px;
}

.main-content-choose-demo .card.coming-soon h6 {
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 1px;
}

.main-content-choose-demo .main-content-label,
.main-content-choose-demo .card-table-two .card-title {
    color: #7987a1;
    margin-bottom: 5px;
    letter-spacing: 1px;
}

.card-table-two .main-content-choose-demo .card-title,
.main-content-choose-demo .card-dashboard-eight .card-title,
.card-dashboard-eight .main-content-choose-demo .card-title {
    color: #7987a1;
    margin-bottom: 5px;
    letter-spacing: 1px;
}

.main-content-choose-demo .main-content-title {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
    color: #fff;
}

@media (min-width: 768px) {
    .main-content-choose-demo .main-content-title {
        font-size: 17px;
    }
}

.main-content-left.main-content-left-mail.card-body {
    padding: 1.25rem;
}

.main-header-profile .main-img-user {
    display: inline-block;
    position: relative;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    text-align: center;
}

.main-header-profile .main-img-user::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 6px;
    width: 6px;
    height: 6px;
    background-color: #17b86a;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95);
    border-radius: 100%;
    bottom: 3px;
    display: block !important;
    top: 35px;
}

.main-profile-menu .profile-user img {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    margin-left: 0;
    width: 37px;
    height: 37px;
    box-shadow: 0px 4px 15px 0px #dbe4f9;
}

.main-profile-overview .main-img-user::after {
    display: none !important;
}

/* ###### 7.8 Profile  ###### */
.main-content-profile {
    flex: 1;
}

@media (max-width: 991.98px) {

    .main-content-profile .container,
    .main-content-profile .container-fluid {
        display: block;
    }
}

.main-content-left-profile {
    padding-left: 0;
    padding-right: 0;
    display: block;
    border-bottom: 1px solid #ededf5;
    padding-bottom: 25px;
    width: auto;
}

@media (min-width: 992px) {
    .main-content-left-profile {
        width: 270px;
        padding-right: 20px;
        padding-bottom: 0;
        border-right: 1px solid #ededf5;
        border-bottom: 0;
    }
}

@media (min-width: 1200px) {
    .main-content-left-profile {
        padding-right: 25px;
    }
}

.main-profile-overview .main-img-user {
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
}

.main-profile-overview .btn-icon-list .btn,
.main-profile-overview .btn-icon-list .sp-container button {
    border-radius: 100%;
}

.sp-container .main-profile-overview .btn-icon-list button {
    border-radius: 100%;
}

.main-profile-name {
    color: #323251;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 2px;
}

.main-profile-name-text {
    color: #7987a1;
    font-size: 13px;
    margin-bottom: 0;
}

.main-profile-bio {
    font-size: 13px;
    margin-bottom: 20px;
}

.main-profile-social-list .media {
    align-items: center;
}

.main-profile-social-list .media+.media {
    margin-top: 20px;
}

.main-profile-social-list .media-icon {
    font-size: 34px;
    width: 30px;
    line-height: 0;
}

.main-profile-social-list .media-body {
    margin-left: 20px;
}

.main-profile-social-list .media-body span {
    display: block;
    font-size: 12px;
}

.main-profile-social-list .media-body a {
    font-size: 13px;
}

.main-content-body-profile .nav {
    flex-direction: column;
    padding: 20px 20px 20px 15px;
    border-bottom: 1px solid #ededf5;
    margin-bottom: 1.3rem;
}

.main-content-body-profile .main-nav-line .nav-link {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    color: #383853;
}

.main-content-body-profile .main-nav-line .nav-link.active {
    font-weight: 700;
    color: var(--primary);
}

.main-content-body-profile .main-nav-line .nav-link.active::before {
    background-color: var(--primary);
}

@media (min-width: 576px) {
    .main-content-body-profile .nav {
        flex-direction: row;
        align-items: center;
    }

    .main-content-body-profile .nav {
        padding: 18px 14px 17px 30px;
    }
}

@media (min-width: 992px) {
    .main-content-body-profile .nav {
        padding-left: 20px;
    }
}

@media (min-width: 1200px) {
    .main-content-body-profile .nav {
        padding-left: 25px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .main-content-body-profile .main-nav-line .nav-link.active::before {
        width: auto;
        top: auto;
        left: 0;
        right: 0;
        height: 2px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .main-content-body-profile .main-nav-line .nav-link+.nav-link {
        margin-top: 0;
        margin-left: 20px;
    }
}

.main-profile-body {
    padding: 15px 0 0;
}

@media (min-width: 576px) {
    .main-profile-body {
        padding-top: 20px;
    }
}

@media (min-width: 992px) {
    .main-profile-body {
        padding: 25px 0 0 20px;
    }
}

@media (min-width: 1200px) {
    .main-profile-body {
        padding-left: 25px;
    }
}

.main-profile-view-chart {
    position: relative;
    width: calc(100% - 10px);
    height: 200px;
}

@media (min-width: 375px) {
    .main-profile-view-chart {
        width: 100%;
    }
}

@media (min-width: 576px) {
    .main-profile-view-chart {
        height: 250px;
    }
}

.main-profile-view-info {
    position: absolute;
    top: 0;
    left: 0;
}

.main-profile-view-info h6 {
    font-size: 32px;
    font-weight: 500;
    color: #323251;
    margin-bottom: 0;
}

.main-profile-view-info span {
    font-size: 12px;
    color: #f754fa;
    margin-left: 5px;
}

.main-profile-view-info p {
    font-size: 13px;
    margin-bottom: 0;
}

.main-traffic-detail-item>div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    margin-bottom: 7px;
}

.main-traffic-detail-item>div:first-child>span:first-child {
    color: #7987a1;
}

.main-traffic-detail-item>div:first-child>span:last-child {
    font-size: 11px;
    font-weight: 700;
    color: #323251;
}

.main-traffic-detail-item>div:first-child>span:last-child span {
    color: #7987a1;
    font-weight: 400;
}

.main-traffic-detail-item+.main-traffic-detail-item {
    margin-top: 25px;
}

.main-traffic-detail-item .progress {
    height: 8px;
}

.main-profile-work-list .media+.media {
    margin-top: 25px;
}

.main-profile-work-list .media-logo {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    color: #fff;
    font-size: 21px;
    position: relative;
    top: 2px;
    border-radius: 100%;
}

.main-profile-work-list .media-body {
    margin-left: 20px;
}

.main-profile-work-list .media-body h6 {
    color: #323251;
    font-weight: 500;
    margin-bottom: 2px;
}

.main-profile-work-list .media-body span {
    display: block;
    margin-bottom: 5px;
}

.main-profile-work-list .media-body p {
    margin-bottom: 0;
    font-size: 12px;
    color: #7987a1;
}

.main-profile-contact-list .media {
    align-items: center;
}

.main-profile-contact-list .media+.media {
    margin-top: 25px;
}

.main-profile-contact-list .media-icon {
    line-height: 0;
    font-size: 36px;
    width: 35px;
    text-align: center;
}

.main-profile-contact-list .media-body {
    margin-left: 25px;
}

.main-profile-contact-list .media-body span {
    font-size: 12px;
    color: #7987a1;
    display: block;
    line-height: 1.3;
}

.main-profile-contact-list .media-body div {
    font-weight: 500;
    color: #323251;
}

@media (max-width: 1134px) {
    .main-profile-body .media.profile-footer {
        display: block !important;
    }

    .main-profile-body .media.profile-footer .media-body {
        padding: 3px 0 15px 0;
    }
}

@media (max-width: 578px) {
    .main-profile-menu .profile-user img {
        margin-left: 0;
    }
}

.main-profile-work-list .media-logo,
.main-profile-contact-list .media-icon,
.main-profile-social-list .media-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    color: #fff;
    font-size: 21px;
    position: relative;
    top: 2px;
    border-radius: 100%;
}

.profile-user .profile-edit {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    line-height: 30px;
    right: 0;
    background: #d5d4e0;
    margin: 0 auto;
    text-align: center;
}

.main-profile-body .card-header {
    padding-top: 0;
    padding-left: 0;
}

.drop-down-profile {
    position: relative;
}

.profile-footer a {
    width: 34px;
    height: 34px;
    background: #efeff5;
    color: #000;
    margin: 1px auto;
    text-align: center;
    line-height: 34px;
    display: inline-block;
    border-radius: 50%;
    font-size: 12px;
}

.drop-down-profile span.assigned-task {
    position: absolute;
    font-size: 12px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    color: #fff;
    top: 0;
    margin: 0 auto;
    text-align: center;
    border-radius: 50%;
    right: 0;
}

@media (max-width: 575px) {
    .main-content-body-profile .main-nav-line .nav-link.active::before {
        left: 0px;
    }
}

@media (max-width: 767.98px) {
    .main-profile-menu .profile-user img {
        margin-left: 0 !important;
    }
}

.profile-name {
    margin-top: 8px;
}

.profile.navtab-custom {
    padding: 11px 0 10px 0;
}

.profile.navtab-custom li a {
    padding: 13px;
    color: #000;
    background: transparent;
    margin-right: 7px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border: 1px solid #e3e6f0;
    border-bottom: 0;
    font-size: 13px;
}

.profile.navtab-custom .active a,
.profile.navtab-custom a.active {
    background: #f9f9fb;
    border-bottom: 0;
    color: #000;
}

.ga-border {
    height: 3px;
    width: 40px;
    background-color: var(--primary);
    margin: 10px auto 5px auto;
}

.profile-cover__action {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    padding: 216px 30px 10px 185px;
    border-radius: 5px 5px 0 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    overflow: hidden;
    background: url(../img/media/bg-img.jpg) no-repeat;
    background-size: cover;
}

.profile-cover__img {
    position: absolute;
    top: 230px !important;
    left: 50px;
    text-align: center;
    z-index: 1;
}

.profile-cover__img>img {
    max-width: 120px;
    border-radius: 50%;
}

.profile-cover__img>img+.h3 {
    margin-top: 6px;
}

.profile-cover__img>.h3 {
    color: #393939;
    font-size: 20px;
    line-height: 30px;
}

@media (min-width: 481px) {
    .profile-cover__action>.btn {
        min-width: 125px;
    }

    .profile-cover__action>.btn {
        margin-bottom: 10px !important;
    }
}

@media (min-width: 601px) {
    .profile-cover__info .nav {
        padding: 15px 0 10px 170px;
        color: #999;
        font-size: 16px;
        line-height: 26px;
        font-weight: 300;
        text-align: center;
    }
}

.profile-cover__info .nav li {
    font-size: 14px;
    color: #464461;
    margin-bottom: 13px;
}

.profile-cover__info,
.profile-tab .main-nav-line .nav-link.active {
    color: var(--primary);
    padding: 15px;
}

.profile-cover__info .nav span {
    display: block;
    margin-bottom: 10px;
    font-size: 25px;
}

.profile-cover__info .nav li:not(:first-child) {
    margin-left: 30px;
    padding-left: 30px;
    border-left: 1px solid rgba(238, 238, 238, 0.2);
}

.profile-tab .main-nav-line .nav-link {
    font-weight: 500;
}

.main-nav-line .nav-link {
    color: #3c4858;
    position: relative;
    border: 0px solid #ededf5;
    padding: 7px;
}

.main-content-body-profile .main-nav-line .nav-link {
    color: #3c4858;
    position: relative;
    padding: 8px 25px;
}

.profile-tab.tab-menu-heading .main-nav-line .nav-link {
    position: relative;
    padding: 4px 20px;
}

.profile-tab .main-nav-line .nav-link {
    font-weight: 500;
}

@media (min-width: 769px) {
    .main-nav-line .nav-link+.nav-link {
        margin-top: 0;
        margin-left: 5px;
        padding: 8px 25px;
    }
}

.card-header:first-child {
    border-radius: 11px 11px 0 0;
}

.user-lock img {
    width: 70px;
    height: 70px;
    margin: 0 auto;
}

.main-img-user img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
}

@media (max-width: 768px) {
    .main-nav-line .nav-link+.nav-link {
        margin-top: 10px !important;
        margin-left: 0 !important;
    }

    .profile-cover__img {
        top: 260px !important;
        left: 0;
        right: 0;
    }
}

.panel-tabs.main-nav-line .nav-link {
    border-radius: 0px;
}

.profile-image img {
    position: relative;
    width: 180px;
    height: 180px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.profile-online {
    right: -10px;
    position: absolute;
    border: 3px solid #fff;
    padding: 6px;
    margin-top: 6px;
}

.profile-tab.tab-menu-heading {
    font-size: 14px !important;
}

.bg-white-transparent {
    background-color: rgba(255, 255, 255, 0.4);
}

a.option-dots {
    display: inline-block;
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    text-align: center;
    border-radius: 100%;
    font-size: 18px;
    border-radius: 50px;
    border: 1px solid #ededf5;
    vertical-align: middle;
    margin-right: 5px;
    padding: 0;
}

/* ###### 6.8 Sidebar  ###### */
@media (max-width: 991.98px) {
    .main-sidebar-show {
        overflow: hidden;
    }

    .main-sidebar-show .main-sidebar {
        display: flex;
        overflow-y: auto;
        transform: translateX(0);
        visibility: visible;
    }

    .main-sidebar-show .main-content {
        transform: translateX(240px);
    }

    .main-sidebar-show .main-navbar-backdrop {
        visibility: visible;
        opacity: 1;
    }
}

.main-body-sidebar {
    display: block;
}

.main-sidebar-body .nav-sub-item {
    margin-left: 55px;
}

@media (min-width: 992px) {
    .main-body-sidebar {
        display: flex;
        flex-direction: row;
    }
}

.main-body-sidebar .main-content {
    flex: 1;
    padding: 0;
    display: flex;
    flex-direction: column;
    transition: margin-left 0.3s ease;
}

.main-body-sidebar .main-content-header {
    height: auto;
    padding: 15px 20px;
    background: #fff;
    margin: 21px;
    border: 1px solid #e3e8f1;
}

.main-body-sidebar .main-footer {
    margin-top: auto;
}

@media (min-width: 992px) {
    .main-body-sidebar .main-header .container-fluid {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.main-sidebar {
    width: 240px;
    flex-direction: column;
    flex-shrink: 0;
    border-right: 0 solid #d8dfea;
    transform: translateX(-240px);
    visibility: hidden;
}

@media (prefers-reduced-motion: reduce) {
    .main-sidebar {
        transition: none;
    }
}

@media (max-width: 991.98px) {
    .main-sidebar {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 1000;
        background-color: #fff;
    }
}

@media (min-width: 992px) {
    .main-sidebar {
        position: fixed;
        display: flex;
        transform: none;
        z-index: 99;
        border-right-width: 1px;
        visibility: visible;
        background: #fff;
    }

    .main-sidebar-header {
        width: 240px;
        z-index: 999;
    }

    .main-sidebar-hide .main-sidebar-body .nav-link {
        border-radius: 0;
        margin-right: 0;
    }
}

.main-sidebar-sticky {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
}

.main-sidebar-sticky .main-sidebar-body {
    height: calc(100vh - 122px);
    position: relative;
}

@media (min-width: 992px) {
    .main-sidebar-sticky+.main-content {
        margin-left: 220px;
    }
}

.main-sidebar-loggedin {
    padding: 20px 20px;
    display: flex;
    flex-shrink: 0;
    border-bottom: 1px solid #ededf5;
    margin-top: 64px;
}

.main-sidebar .nav-pills-circle {
    position: relative;
    margin: 0 auto;
    text-align: center;
}

.main-sidebar .nav-pills-circle.nav .nav-item .dropdown-menu {
    left: -30px;
    min-width: 200px;
}

.main-sidebar .nav-pills-circle.nav .nav-item .dropdown-menu .dropdown-item i {
    font-size: 14px;
    margin-right: 3px;
}

.main-sidebar .nav-pills-circle .nav-pill {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #ededf5 !important;
    color: #68798b !important;
    border-radius: 5px;
    margin: 0 4px;
    padding: 0.4rem 0.7rem;
}

.main-sidebar-hide .main-sidebar-loggedin .main-img-user {
    margin: auto;
}

.main-sidebar-loggedin .main-img-user {
    width: 38px;
    height: 38px;
}

.main-sidebar-loggedin .media-body {
    margin-left: 12px;
    transition: left 0.4s ease, width 0.4s ease;
}

.main-sidebar-loggedin .media-body h6 {
    font-weight: 700;
    font-size: 14px;
    color: #323251;
    margin-bottom: 2px;
}

.main-sidebar-loggedin .media-body span {
    display: block;
    color: #7987a1;
    font-size: 13px;
}

@media (min-width: 1200px) {
    .main-sidebar-body {
        padding: 0;
    }
}

.main-sidebar-hide .side-badge {
    display: none;
}

.main-sidebar-body .nav {
    flex-direction: column;
}

.main-sidebar-body .nav-item.active .nav-link .nav-badge {
    right: 12px !important;
}

.main-sidebar-body .nav-sub {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 0.9s ease;
    -o-transition: max-height 0.9s ease;
    transition: max-height 0.9s ease;
    transition: all 0.9s;
    margin-left: 32px;
}

.main-sidebar-body .nav-item+.nav-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 30px;
    right: 0;
    border-top: 1px dotted #e2eaf9;
}

.main-sidebar-body .nav-link {
    height: 45px;
    display: flex;
    align-items: center;
    padding: 0;
    font-weight: 400;
    font-size: 14px;
    color: #323251;
    padding-bottom: 2px;
}

.main-sidebar-hide .main-sidebar-body .nav-link .leftmenu-icon {
    margin: auto;
}

.main-sidebar-hide .nav-text-badge {
    display: none;
}

.main-sidebar-body .nav-link i.typcn::before {
    width: auto;
}

.nav-link.with-sub .angle {
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    position: absolute;
    top: 17px;
    right: 23px;
    font-size: 12px;
}

.nav-link.with-sub .side-badge {
    right: 23px;
    position: absolute;
}

.main-sidebar-body .nav-link .leftmenu-icon .eve-icon {
    fill: #4a4a69;
}

.main-sidebar-body .nav-sub {
    list-style: none;
    padding: 0;
    margin: 0 0 0 7px;
    position: relative;
}

.main-sidebar-body .nav-sub-item .nav-sub {
    padding: 0 0 0 18px;
    border-left-width: 1px;
    margin-left: 0;
}

.main-sidebar-body .nav-sub-item .nav-sub .nav-sub-link {
    height: auto;
    padding: 7px 0;
}

.main-sidebar-body .nav-sub-item.active>.nav-sub-link {
    color: var(--primary);
    border-radius: 1px;
}

.main-sidebar-body .nav-sub-item.show .nav-sub {
    display: block;
}

.main-sidebar-body .nav-sub-link {
    height: 34px;
    display: flex;
    align-items: center;
    color: #6c7293;
    font-size: 13px;
    webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.main-sidebar-body .nav-sub-link.with-sub {
    justify-content: space-between;
}

.main-sidebar-body .nav-sub-link.with-sub::after {
    content: "\f3d0";
    font-family: "Ionicons";
    font-size: 12px;
    font-weight: 400;
    display: inline-block;
    position: relative;
    margin-left: auto;
    color: #e1e1ef;
}

.main-sidebar-body .nav-sub-link+.nav-sub-link {
    border-top: 1px dotted #e1e1ef;
}

@media (min-width: 992px) {
    .main-sidebar-body .nav-sub-link.with-sub::after {
        margin-left: 4px;
        font-size: 12px;
    }
}

.main-sidebar-open .main-sidebar {
    width: 240px;
    transition: left 0.3s ease;
}

.main-sidebar {
    background: var(--primary);
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.13);
}

.main-sidebar-body .nav-label {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 1px;
    opacity: 1;
    padding: 20px 29px 10px;
    text-transform: capitalize;
    width: 100%;
    color: rgba(255, 255, 255, 0.4) !important;
    position: relative;
}

.main-sidebar-body .nav-link {
    height: 45px;
    display: flex;
    align-items: center;
    padding: 2px 15px;
    font-size: 15px;
    color: #334151;
}

.main-sidebar-body .nav-link:hover,
.main-sidebar-body .nav-link:focus {
    color: #fff;
}

.main-sidebar-body .nav-sub li .nav-sub-link::after {
    content: "\e92f";
    font-family: "feather" !important;
    position: absolute;
    top: 7px;
    left: -30px;
    font-size: 12px;
}

.main-sidebar-body .nav-item+.nav-item::before {
    border-top: 0;
}

.main-sidebar-body .nav-link .leftmenu-icon {
    font-size: 18px;
    line-height: 0;
    margin-right: 4px;
    width: 34px;
    height: 34px;
    line-height: 34px;
    border-radius: 3px;
    text-align: center;
}

.main-sidebar-body .nav-label:after {
    content: "--";
    position: absolute;
    left: 13px;
    top: 18px;
}

@media (min-width: 992px) {
    .main-sidebar-hide .main-sidebar-body .nav-link i.typcn {
        line-height: 1.69;
        font-size: 1.125rem;
        margin-right: 0;
    }

    .main-body.main-sidebar-hide .main-sidebar {
        width: 80px;
    }

    .main-body.main-sidebar-hide .main-sidebar-loggedin .media-body,
    .main-body.main-sidebar-hide .sidemenu-label {
        display: none;
    }

    .main-body.main-sidebar-hide .main-sidebar-body .nav-label {
        display: none;
    }

    .main-body.main-sidebar-hide .main-sidebar-body .nav-link .leftmenu-icon {
        margin: 0;
    }

    .main-body.main-sidebar-hide .main-sidebar-body .nav-item {
        margin: 0 auto;
        text-align: center;
    }

    .main-body.main-sidebar-hide .main-sidebar-body .angle {
        display: none;
    }

    .main-body.main-sidebar-hide .sidebar-navs,
    .main-body.main-sidebar-hide .user-info {
        display: none;
    }

    .main-body.main-sidebar-hide .user-pic img {
        width: 40px !important;
        height: 40px;
        font-size: 36px !important;
        border-radius: 4px !important;
    }

    .main-body.main-sidebar-hide .main-sidebar-loggedin {
        padding: 20px;
    }
}

.sidebar-right .main-nav-line .nav-link {
    padding: 10px 18px 10px 21px;
    background: #f0f0f8;
}

.sidebar-right .main-nav-line .nav-link.active::before {
    bottom: 0;
}

.main-sidebar-body .nav-item.show>.nav-sub {
    max-height: 100vh;
    -webkit-transition: max-height 2s ease;
    -o-transition: max-height 2s ease;
    transition: max-height 2s ease;
    transition: all 2s;
}

.main-sidebar-body .nav-item.active .nav-link {
    color: #fff;
    font-weight: 500;
    border-top: 0;
    background-color: rgb(94, 95, 224);
}

.main-sidebar-body .nav-item.active .angle {
    color: #fff !important;
}

.main-sidebar-body .nav-sub-item.active>.nav-sub-link {
    color: #14c6e2;
    border-radius: 1px;
}

.main-sidebar-body .nav-link {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    white-space: nowrap;
    padding: 7px 12px;
    color: #fff;
    -webkit-transition-duration: 0.45s;
    -moz-transition-duration: 0.45s;
    -o-transition-duration: 0.45s;
    transition-duration: 0.45s;
    transition-property: color;
    -webkit-transition-property: color;
    position: relative;
    font-size: 0.875rem;
    line-height: 1;
    vertical-align: middle;
    font-weight: 400;
}

.main-sidebar-body .nav-sub-item {
    margin-left: 0;
    text-align: left;
    padding: 2.2px 25px 2.2px 52px;
}

.main-sidebar-body .nav-item.show>.nav-sub {
    position: relative;
}

.main-sidebar-body .nav-sub li.active .nav-sub-link::after {
    border-color: var(--primary);
    color: #14c6e2;
}

.main-sidebar-hide .nav-sub {
    display: none;
}

.main-sidebar-body .nav-sub {
    list-style: none;
    padding: 0;
    margin: 0 !important;
    position: relative;
}

.main-sidebar-body .nav-item {
    position: relative;
}

.main-sidebar-body .nav-item.active {
    border-top: 0;
}

.right-page .value {
    color: #161537;
    font-size: 1rem;
    display: block;
    font-weight: 700;
    line-height: 1;
    margin-top: 1px;
}

.right-page .label {
    color: #979a9f;
    font-size: 12px;
    line-height: 1;
}

.main-sidebar-body .nav-sub-link {
    height: 34px;
    display: flex;
    align-items: center;
    color: #fff;
    position: relative;
    font-size: 14px;
    font-weight: 200;
    webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.main-sidebar-body .nav-sub-link:hover {
    color: #14c6e2;
}

@media (min-width: 992px) {
    .main-sidebar-hide .main-header-menu-icon {
        margin: 0 auto !important;
        text-align: center;
    }

    .main-sidebar-hide .main-sidebar-body .nav-item {
        border-top: 0;
    }

    .main-sidebar-hide .main-sidebar-body .nav-item.active {
        background: transparent;
    }

    .main-sidebar-hide .main-sidebar-body .nav-link {
        border-radius: 0;
        margin-right: 0;
        width: 38px;
        height: 38px;
        margin: 5px 0;
        border-radius: 5px;
    }

    .main-sidebar-hide .nav-search {
        display: none;
    }

    .main-sidebar-hide .main-sidebar-body .nav-link {
        height: 40px;
        display: flex;
        align-items: center;
        padding: 2px 10px;
    }

    .animated-arrow span {
        transition: all 500ms ease-in-out;
        transition: all 500ms ease-in-out;
    }

    .animated-arrow span:before,
    .animated-arrow span:after,
    .animated-arrow span:before,
    .animated-arrow span:after {
        transition: all 500ms ease-in-out;
    }
}

.app-sidebar__user {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #a8a8a8;
    width: 100%;
    display: inline-block;
    background-size: cover;
    background-position: left;
}

.user-info .text-dark {
    color: #4a4a69 !important;
    font-weight: 600;
    font-size: 14px;
    margin-top: 13px;
}

.user-info .text-muted {
    color: #f2f5fb !important;
    font-size: 13px;
}

.sidebar-navs .nav-pills-circle .nav-link {
    display: block;
    padding: 0.4rem 0.6rem;
    font-size: 16px;
    border: 1px solid #f0f0f8;
    border-radius: 4px;
    line-height: 1;
}

.main-sidebar-loggedin {
    padding: 0 20px;
    display: flex;
    flex-shrink: 0;
    border-bottom: 0;
    margin-top: 0;
    padding: 20px 20px 15px;
    transition: left 0.3s ease;
}

.main-sidebar-loggedin .media-body h6 {
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 5px;
}

.nav-search .input-group-text {
    background: transparent;
}

.nav-search .form-control {
    color: #fff;
    background-color: #7878eb;
    border: 1px solid rgba(226, 232, 245, 0.25);
}

.nav-search .input-group-text {
    color: #fff;
    background-color: #7878eb;
    border: 1px solid rgba(226, 232, 245, 0.22);
}

.main-sidebar-loggedin .media-body {
    margin-left: 13px;
    transition: left 0.4s ease, width 0.4s ease;
}

.main-sidebar .nav-pills-circle {
    margin-bottom: 10px !important;
    text-align: center;
    margin: 0 auto;
    justify-content: center;
}

.main-sidebar-body .nav-item.show .nav-link.with-sub .angle {
    transform: rotate(90deg);
}

.main-sidebar {
    width: 240px;
}

@media screen and (min-width: 997px) {
    .main-sidebar-open .main-sidebar {
        width: 240px;
        transition: left 0.3s ease;
    }
}

.main-sidebar-body .nav-item.active:before {
    content: "";
    position: absolute;
    left: 0px;
    display: block;
    height: 45px;
    width: 3px;
    left: 0px;
    z-index: 99;
}

.main-sidebar-body .nav .nav-item .nav-sub:before {
    content: "";
    position: absolute;
    left: 0px;
    display: block;
    height: 10%;
    width: 3px;
    left: 0px;
    z-index: 99;
    height: 100%;
}

@media (min-width: 768px) {
    .sidebar-mini.sidenav-toggled .main-sidebar-header .logo-icon.dark-theme {
        display: none;
    }
}

.main-sidebar-hide .nav-badge {
    position: absolute;
    top: 5px;
    display: block !important;
    padding: 3px 5px !important;
    box-shadow: 0px 8px 14.72px 1.28px rgba(154, 154, 204, 0.1);
}

.main-sidebar-body .nav-sub .nav-sub-item .nav-sub-link:before::hover {
    content: "";
    position: absolute;
    left: 0px;
    display: block;
    height: 10%;
    width: 1px;
    background: var(--primary);
    left: -27px;
    z-index: 99;
    height: 34px;
}

.main-sidebar .search {
    position: relative;
    padding: 0 10px;
}

.main-sidebar .search .form-control {
    display: block;
    width: 214px;
    height: 40px;
    border-radius: 25px;
}

.main-sidebar .search .btn {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    height: 40px;
    color: #d6d6e6;
    transition: none;
    font-size: 14px;
    padding-right: 31px;
}

.app-sidebar__user .user-pro-body {
    display: block;
    padding: 15px 0;
}

.app-sidebar__user .user-pro-body img {
    display: block;
    margin: 0 auto 0px;
    border: 2px solid #c9d2e8;
    box-shadow: 0px 5px 5px 0px rgba(44, 44, 44, 0.2);
    padding: 3px;
    background: #fff;
}

.app-sidebar__user .user-pro-body .u-dropdown {
    color: #97999f;
}

.app-sidebar__user .user-pro-body .user-info span.text-gray {
    color: #969696 !important;
}

.app-sidebar__user .user-info {
    margin: 0 auto;
    text-align: center;
}

.app-sidebar__user .user-info h4 {
    color: #5c626f;
    font-size: 15px;
}

.sidebar {
    position: fixed;
    display: block;
    top: 0px;
    width: 320px;
    bottom: 0;
    z-index: 9999;
    height: 100%;
    background: #fff;
    box-shadow: 0px 18px 36px 10px rgba(154, 154, 204, 0.1);
    border-left: 1px solid #ededf5;
}

.sidebar .tab-menu-heading {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    display: flex;
    margin-bottom: 27px;
    background: #f9f9fb;
}

.sidebar .tabs-menu-body {
    margin-top: 56px;
}

.sidebar .tabs-menu ul li .active {
    background: var(--primary);
    color: #fff;
    border: 1px solid var(--primary);
}

.sidebar .tabs-menu ul li a {
    padding: 8px 8px;
    font-size: 12px;
    display: inline-block;
    margin-bottom: 10px;
    border-radius: 3px;
    border: 1px solid #ebeaf1;
    font-weight: 500;
    background: transparent;
    color: #4a4a69;
    margin: 0 2px;
}

.sidebar .tabs-menu ul li:last-child a {
    margin-bottom: 0px;
}

.sidebar .nav.panel-tabs {
    padding: 15px;
}

.sidebar .avatar-md {
    width: 40px !important;
    height: 40px !important;
    font-size: 15px !important;
}

.sidebar .avatar-lg {
    width: 50px !important;
    height: 50px !important;
}

.sidebar .card {
    box-shadow: none;
    border: 0 !important;
}

.sidebar .list-group-item {
    margin-bottom: -2px;
}

@media (max-width: 480px) {
    .sidebar .tab-menu-heading .tabs-menu ul li {
        width: auto;
        margin: 0;
    }
}

.sidebar-right .list a {
    color: #4a4a69;
}

.tabs-menu ul li a {
    padding: 10px 12px 11px 13px;
    display: block;
}

.sidebar .nav.panel-tabs .side-menu__icon {
    margin-right: 2px;
    width: 16px;
    height: 16px;
}

.sidebar .tab-pane b {
    font-weight: 500;
}

.sidebar-mini .side-menu {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.app-sidebar .mCSB_draggerContainer {
    right: -11px !important;
}

.sub-slide-menu .sub-side-menu__item.active {
    color: var(--primary);
}

.side-menu__label1 {
    display: none !important;
}

@media (min-width: 768px) {
    .app.sidenav-toggled .app-content {
        margin-left: 80px;
    }

    .app.sidenav-toggled .app-sidebar {
        left: 0;
        width: 80px !important;
    }

    .app.sidenav-toggled .app-sidebar__overlay {
        visibility: hidden;
    }

    .app.sidenav-toggled .slide.is-expanded .slide-menu {
        display: none;
    }

    .side-badge {
        display: none;
    }

    .side-header {
        width: 240px;
        left: 0;
        right: 0;
        top: 0;
        position: fixed;
        z-index: 1;
    }
}

@media (max-width: 767px) {
    .app {
        overflow-x: hidden;
    }

    .app .app-sidebar {
        left: -240px;
    }

    .app .app-sidebar__overlay {
        visibility: hidden;
    }

    .app.sidenav-toggled .app-content {
        margin-left: 0;
    }

    .app.sidenav-toggled .app-sidebar {
        left: 0;
    }

    .app.sidenav-toggled .app-sidebar__overlay {
        visibility: visible;
    }

    .app.sidebar-gone.sidenav-toggled .app-sidebar {
        left: 0;
    }

    .app.sidenav-toggled .side-menu .side-menu__icon {
        margin-right: 13px !important;
        margin-left: 0;
    }
}

.app-content {
    min-height: calc(100vh - 114px);
    margin-bottom: 0 !important;
    -webkit-transition: margin-left 320ms ease;
    -o-transition: margin-left 320ms ease;
    transition: margin-left 320ms ease;
}

.app-content .side-app {
    padding: 0px 30px 0 30px;
}

.footer .container {
    width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-right: auto;
    margin-left: auto;
}

.main-sidebar-header .header-logo .desktop-dark,
.main-sidebar-header .header-logo .mobile-logo,
.main-sidebar-header .header-logo .mobile-dark {
    display: none;
}

@media (min-width: 768px) {
    .app-content {
        margin-left: 240px;
    }

    .app.sidenav-toggled .avatar-xl {
        width: 55px !important;
        height: 55px !important;
    }

    .app.sidebar-mini.sidenav-toggled .main-sidebar-header .header-logo .desktop-logo {
        display: none;
    }

    .app.sidebar-mini.sidenav-toggled .main-sidebar-header .header-logo .mobile-logo {
        display: block;
    }

    .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .mobile-logo {
        display: none !important;
    }

    .app.sidebar-mini.sidenav-toggled .side-menu .side-menu__icon {
        line-height: 19px;
    }

    .app.sidebar-mini.sidenav-toggled .side-item.side-item-category {
        display: none;
    }

    .app.sidebar-mini.sidenav-toggled .side-item.side-item-category {
        display: none;
    }

    .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-item.side-item-category {
        display: block;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    .desktop-logo {
        height: auto !important;
        text-align: inherit !important;
        margin: 0 !important;
        justify-content: inherit !important;
    }
}

@media (max-width: 767px) {
    .app-content {
        min-width: 100%;
    }
}

@media print {
    .app-content {
        margin: 0;
        padding: 0;
        background-color: #fff;
    }
}

.light-text {
    font-weight: 300 !important;
}

.semibold-text {
    font-weight: 600 !important;
}

.line-head {
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
}

/*----- Componant: Top Navigation Bar ----- */
.app-header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    z-index: 999;
    padding-right: 15px;
    padding: 5px 0;
    box-shadow: 0 2px 17px 2px rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background: #5e2dd8;
}

@media (min-width: 768px) {
    .app-header {
        padding-right: 30px;
    }
}

@media print {
    .app-header {
        display: none;
    }
}

.app-header__logo {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    color: #fff;
    text-align: center;
    font-family: "Niconne";
    padding: 0 15px;
    font-size: 26px;
    font-weight: 400;
    line-height: 50px;
}

.app-header__logo:focus,
.app-header__logo:hover {
    text-decoration: none;
}

@media (min-width: 768px) {
    .app-header__logo {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        display: block;
        width: 230px;
    }
}

.app-sidebar__toggle {
    color: #fff;
    -webkit-transition: background-color 320ms ease;
    -o-transition: background-color 320ms ease;
    transition: background-color 320ms ease;
    z-index: 1;
    margin-right: 10px;
    display: block;
    font-size: 22px;
    color: #7b8191;
    position: relative;
    line-height: 21px;
    outline: none;
    height: 39px;
    width: 39px;
    margin: auto;
    text-align: center;
}

.app-sidebar__toggle:hover {
    color: #fff;
}

.app-sidebar__toggle:focus,
.app-sidebar__toggle:hover {
    text-decoration: none;
}

/*@media (max-width: 360px) {
    .app-sidebar__toggle {
      line-height: 2;
    }
  }*/
@media (max-width: 767px) {
    .app-sidebar__toggle {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }

    .main-sidebar-header {
        display: none !important;
    }

    .main-sidemenu {
        margin-top: 20px !important;
    }
}

.app-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

@media (min-width: 768px) {
    .app-nav {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
    }
}

.app-nav__item {
    display: block;
    padding: 15px;
    line-height: 20px;
    color: #7b8191;
    -webkit-transition: background-color 320ms ease;
    -o-transition: background-color 320ms ease;
    transition: background-color 320ms ease;
}

.app-nav__item:hover,
.app-nav__item:focus {
    background: rgba(0, 0, 0, 0.1);
    color: #f6f6f6;
}

.app-search {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-item-align: center;
    align-self: center;
    margin-right: 15px;
    padding: 10px 0;
}

@media (max-width: 480px) {
    .app-search {
        display: none;
    }
}

.app-search__input {
    border: 0;
    padding: 5px 10px;
    padding-right: 30px;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-transition: background-color 320ms ease;
    -o-transition: background-color 320ms ease;
    transition: background-color 320ms ease;
}

.app-search__input::-webkit-input-placeholder,
.app-search__input:-ms-input-placeholder,
.app-search__input::-ms-input-placeholder,
.app-search__input::placeholder {
    color: rgba(0, 0, 0, 0.4);
}

.app-search__button {
    position: absolute;
    right: 0;
    top: 10px;
    bottom: 10px;
    padding: 0 10px;
    border: 0;
    color: rgba(0, 0, 0, 0.8);
    background: none;
    cursor: pointer;
}

.app-notification {
    min-width: 270px;
}

.app-notification__title {
    padding: 8px 20px;
    text-align: center;
    background-color: rgba(0, 150, 136, 0.4);
    color: #333;
}

.app-notification__footer {
    padding: 8px 20px;
    text-align: center;
    background-color: #eee;
}

.app-notification__content {
    max-height: 220px;
    overflow-y: auto;
}

.app-notification__content::-webkit-scrollbar {
    width: 6px;
}

.app-notification__content::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
}

.app-notification__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 8px 20px;
    color: inherit;
    border-bottom: 1px solid #ddd;
    -webkit-transition: background-color 320ms ease;
    -o-transition: background-color 320ms ease;
    transition: background-color 320ms ease;
}

.app-notification__item:focus,
.app-notification__item:hover {
    color: inherit;
    text-decoration: none;
    background-color: #e0e0e0;
}

.app-notification__message,
.app-notification__meta {
    margin-bottom: 0;
}

.app-notification__icon {
    padding-right: 10px;
}

.app-notification__message {
    line-height: 1.2;
}

.main-sidemenu .side-menu {
    -webkit-transition: left 320ms ease, width 320ms ease;
    -o-transition: left 320ms ease, width 320ms ease;
    transition: left 320ms ease, width 320ms ease;
}

.sidebar-mini .app-sidebar {
    width: 240px !important;
}

.app-sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    color: #14112d;
    max-height: 100%;
    z-index: 1024;
    background: rgb(255, 255, 255);
    -webkit-box-shadow: 0px 8px 14.72px 1.28px rgba(154, 154, 204, 0.1);
    box-shadow: 0px 8px 14.72px 1.28px rgba(154, 154, 204, 0.1);
    border-right: 1px solid #ededf5;
    -webkit-transition: left 320ms ease, width 320ms ease;
    -o-transition: left 320ms ease, width 320ms ease;
    transition: left 320ms ease, width 320ms ease;
}

.app-sidebar::-webkit-scrollbar {
    width: 6px;
}

.app-sidebar::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
}

@media print {
    .app-sidebar {
        display: none;
    }
}

@media (max-width: 767px) {
    .app-sidebar__overlay {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 9;
    }
}

.app-sidebar__user {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #7b8191;
    width: 100%;
    display: inline-block;
}

.app-sidebar__user .dropdown-menu {
    top: 10px !important;
}

.app-sidebar__user img {
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.1);
    border: 2px solid rgb(255, 255, 255);
    box-shadow: 0px 5px 5px 0px rgba(44, 44, 44, 0.2);
}

.app-sidebar__user-avatar {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-right: 15px;
}

.app-sidebar__user-name {
    font-size: 17px;
    line-height: 1.3;
    white-space: normal;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    margin-bottom: 0;
    overflow: hidden;
    font-weight: 600;
    color: #e5e9ec;
    font-size: 15px;
    margin-top: 5px !important;
}

.app-sidebar__user-name.text-sm {
    font-size: 12px;
    font-weight: 400;
}

.app-sidebar__user-designation {
    white-space: normal;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    margin-bottom: 0;
    overflow: hidden;
    font-weight: 600;
    color: #e5e9ec;
    font-size: 15px;
    margin-top: 5px !important;
}

.side-menu {
    margin-bottom: 0;
    padding: 0;
    list-style: none;
}

.side-menu .slide .side-menu__item {
    padding: 10px 91px 10px 30px;
    margin: 0;
    border-radius: 0;
}

slide is-expanded side-menu__item {
    background: rgba(238, 238, 247, 0.9);
}

.slide {
    margin: 0;
}

.side-menu .slide .side-menu__item.active {
    background-color: #fff;
    border-right: 3px solid var(--primary);
}

.side-menu .slide .side-menu__item.active .side-menu__icon {
    color: var(--primary) !important;
}

.side-menu .slide .side-menu__item.active .side-menu__label {
    color: #4a4a69 !important;
    font-weight: 600;
}

.side-menu__item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
}

.side-menu__item.active,
.side-menu__item:hover,
.side-menu__item:focus {
    text-decoration: none;
    color: var(--primary);
}

.side-menu__item.active .side-menu__label {
    color: var(--primary);
}

.side-menu__item.active .angle {
    color: var(--primary);
}

.side-menu__item.active .side-menu__icon {
    fill: var(--primary) !important;
}

.side-menu__item.active .side-menu__icon,
.side-menu__item:hover .side-menu__icon,
.side-menu__item:focus .side-menu__icon {
    color: var(--primary);
}

@media (min-width: 768px) {
    .app.sidebar-mini.sidenav-toggled .profile-status {
        right: 12px;
    }

    .app.sidenav-toggled .side-menu__label {
        display: none !important;
        position: relative;
        padding: 0;
        margin: 0;
        left: 0;
        color: rgba(255, 255, 255, 0.8);
        opacity: 1;
        background: transparent;
        font-size: 12px;
        box-shadow: none;
    }
}

.slide:hover .side-menu__label,
.slide:hover .angle,
.slide:hover .side-menu__icon {
    color: #7b8191;
    fill: #7b8191;
}

.slide-item.active,
.slide-item:hover,
.slide-item:focus {
    text-decoration: none;
    color: #b5c1d2;
}

.slide-item.active,
.slide-item:hover,
.slide-item:focus {
    text-decoration: none;
    color: var(--primary) !important;
}

.slide-item .app-sidebar .slide-menu .slide-item:before:hover {
    color: var(--primary) !important;
}

.slide.is-expanded .slide-menu a:hover:before {
    color: var(--primary) !important;
}

.slide-menu .sub-slide-menu {
    padding: 0 10px;
}

.sub-slide .sub-angle {
    margin-left: auto;
    float: right;
    font-size: 12px;
    margin-top: 3px;
}

.slide-menu li {
    position: relative;
}

.slide.is-expanded a {
    color: #7b8191;
    text-decoration: none;
}

.slide.is-expanded .sub-side-menu__item:before {
    content: "\f22d";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    top: 10px;
    left: 15px;
    font-size: 9px;
    color: #7b8191;
    font-weight: 900;
}

.sub-slide-menu1 .sub-slide-item2:before {
    content: "\f22d";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    left: 35px !important;
    font-size: 9px;
    color: #7b8191;
    font-weight: 900;
}

.side-menu .side-menu__icon {
    font-size: 16px;
    line-height: 0;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 2px;
    text-align: center;
    color: #7b8191;
    fill: #7b8191;
}

.side-menu__icon {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25px;
}

.side-menu__label {
    white-space: nowrap;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    color: #7b8191;
    position: relative;
    font-size: 14px;
    line-height: 1;
    vertical-align: middle;
    font-weight: 400;
}

.app-sidebar .slide .side-menu__item.active::before {
    content: "";
    width: 2px;
    height: 42px;
    background: #f2f7ff;
    position: absolute;
    left: 0;
    display: none;
}

.app-sidebar .side-item.side-item-category {
    color: #7b8191;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-bottom: 12px;
    height: 15px;
    padding: 0 20px 0 25px;
    opacity: 0.6;
    white-space: nowrap;
}

.app-sidebar .side-item.side-item-category:not(:first-child) {
    margin-top: 25px;
}

.slide-menu {
    display: none;
    padding-left: 29px;
    list-style: none;
}

.slide.is-expanded .slide-menu {
    display: block;
}

.slide-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 13px;
    padding: 8px 25px 8px 35px;
    font-weight: 400;
    color: #7b8191;
}

.slide-item .icon {
    margin-right: 5px;
}

.angle {
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transition: -webkit-transform 320ms ease;
    transition: -webkit-transform 320ms ease;
    -o-transition: transform 320ms ease;
    transition: transform 320ms ease;
    transition: transform 320ms ease, -webkit-transform 320ms ease;
}

@media (max-width: 848px) {
    .profile-user .p-text {
        display: none;
    }
}

@media (min-width: 768px) {

    .sidenav-toggled .app-sidebar__user-name,
    .sidenav-toggled .app-sidebar__user-designation,
    .sidenav-toggled .angle,
    .sidenav-toggled .app-sidebar__user-name,
    .sidenav-toggled .user-notification,
    .sidenav-toggled .app-sidebar__user-name,
    .sidenav-toggled .user-info {
        display: none;
    }

    .sidenav-toggled .sidenav-toggled.user-notification::before {
        background: transparent;
        display: none;
    }

    .sidenav-toggled .app-sidebar__user-name,
    .sidenav-toggled .avatar-xl {
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
        font-size: 1rem;
        margin-bottom: 0px !important;
    }

    .sidenav-toggled .app-sidebar__user,
    .sidenav-toggled .side-menu {
        margin-top: 0px;
    }

    .sidenav-toggled .app-sidebar__user-avatar {
        width: 25px;
        height: 25px;
    }

    .sidenav-toggled .app-sidebar__user {
        padding: 8px 0px 8px 0;
        margin-bottom: 0px;
        border-bottom: 0;
    }

    .sidenav-toggled .profile-img {
        top: 0px;
        right: 19px;
    }

    .sidenav-toggled .app-content {
        margin-left: 0;
    }

    .sidenav-toggled .app-sidebar {
        left: 0;
    }

    .sidenav-toggled .app-sidebar:hover {
        overflow: visible;
    }

    .sidenav-toggled .side-menu__item {
        overflow: hidden;
    }

    .sidenav-toggled .side-menu__item:hover {
        overflow: visible;
    }

    .sidenav-toggled .side-menu__item:hover .side-menu__label {
        opacity: 1;
    }

    .sidenav-toggled .side-menu__item:hover+.slide-menu {
        visibility: visible;
    }

    .sidenav-toggled .side-menu__label {
        display: block;
        position: absolute;
        top: 0;
        left: 50px;
        padding: 12px 5px 12px 20px;
        margin-left: -3px;
        line-height: 1;
        opacity: 0;
        background: #fff;
        color: #7b8191;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        -webkit-box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
    }

    .sidenav-toggled .slide:hover .side-menu__label {
        opacity: 1;
    }

    .sidenav-toggled .slide:hover .slide-menu {
        max-height: 100%;
        opacity: 1;
        visibility: visible;
        z-index: 10;
    }

    .sidenav-toggled .slide .side-menu__label {
        border-bottom-right-radius: 0;
    }

    .sidenav-toggled .slide-menu {
        position: absolute;
        left: 90px;
        min-width: 180px;
        opacity: 0;
        border-bottom-right-radius: 4px;
        z-index: 9;
        visibility: hidden;
        -webkit-transition: visibility 320ms ease;
        -o-transition: visibility 320ms ease;
        transition: visibility 320ms ease;
        -webkit-box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
    }

    .app.sidenav-toggled .side-menu__item {
        display: block;
        padding: 11px 0 11px 0;
        margin: 0 auto;
        text-align: center;
        border-left: 0;
    }

    .app.sidenav-toggled .side-menu_label {
        display: block;
        font-size: 12px;
    }

    .app.sidenav-toggled .side-menu__label {
        display: block;
        position: relative;
        padding: 0;
        margin: 0;
        left: 0;
        color: #7b8191;
        opacity: 1;
        background: transparent;
        font-size: 12px;
        box-shadow: none;
    }

    .sidenav-toggled .app-sidebar__user .avatar-md {
        margin: 0 auto;
    }

    .app.sidenav-toggled .nav-badge {
        position: absolute;
        top: 8px;
        right: 28px;
        padding: 0.2rem 0.4rem;
        font-size: 11px;
    }
}

.app.sidenav-toggled .side-menu .side-menu__icon {
    margin-right: 0;
    margin-left: 0;
}

.app .side-menu_label {
    display: none;
}

.dropdown-menu {
    border-radius: 0;
}

.dropdown-menu.dropdown-menu-right {
    left: auto;
}

.dropdown-item .fa,
.dropdown-item .icon {
    vertical-align: middle;
}

.app-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    background-color: #fff;
    margin: -30px -30px 30px;
    padding: 20px 30px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.app-title h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 400;
}

.app-title p {
    margin-bottom: 0;
    font-style: italic;
}

@media print {
    .app-title {
        display: none;
    }
}

@media (max-width: 480px) {
    .app-title {
        margin: -15px -15px 15px;
        padding: 20px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}

@media (max-width: 480px) {
    .app-title p {
        display: none;
    }
}

.app-breadcrumb {
    margin-bottom: 0;
    text-align: right;
    font-weight: 500;
    font-size: 13px;
    text-transform: capitalize;
    padding: 0;
    text-align: left;
    padding: 0;
    background-color: transparent;
}

@media (max-width: 480px) {
    .app-breadcrumb {
        margin-top: 10px;
    }
}

.tile {
    position: relative;
    background: #fff;
    border-radius: 3px;
    padding: 20px;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    margin-bottom: 30px;
    -webkit-transition: all 320ms ease-in-out;
    -o-transition: all 320ms ease-in-out;
    transition: all 320ms ease-in-out;
}

@media print {
    .tile {
        border: 1px solid #ddd;
    }
}

.sidenav-toggled .app-sidebar__user .avatar-md {
    line-height: 2rem;
    font-size: 1rem;
}

.app-sidebar .mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background: rgba(0, 0, 0, 0.05);
}

.app-sidebar .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.app-sidebar .mCSB_scrollTools .mCSB_draggerRail {
    background: rgba(255, 255, 255, 0.2);
}

.sidenav-toggled .main-wrapper .side-menu .side-menu__item .nav-badge {
    position: absolute;
    top: 5px;
    left: 57px;
    display: block !important;
    padding: 3px 5px !important;
}

.sidenav-toggled .main-wrapper .side-menu .side-menu__item .nav-badge1 {
    display: none;
}

.nav-badge {
    border-radius: 30px;
    padding: 0.4em 0.6em;
    font-size: 12px;
}

.user-info .text-dark {
    color: #fff !important;
    font-weight: 400;
    font-size: 16px;
}

.user-info .text-muted {
    color: rgba(255, 255, 255, 0.4) !important;
}

.side-header .header-brand1 {
    text-align: center;
    display: table;
}

.side-header .header-brand-img.desktop-logo {
    max-height: 2.5rem;
    text-align: center;
    display: block;
    margin-right: 0;
}

.sidenav-toggled .app-sidebar .side-header .header-brand-img.desktop-logo,
.side-header .header-brand-img.toggle-logo {
    display: none;
}

.sidenav-toggled .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: none;
    margin-right: 0;
}

.sidenav-toggled .user-pic {
    margin-bottom: 0;
}

.sidenav-toggled .sidebar-navs {
    display: none;
}

.side-header {
    display: flex;
    border-bottom: 1px solid #ededf5;
    -webkit-transition: left 320ms ease;
    -o-transition: left 320ms ease;
    transition: left 320ms ease;
}

.sidenav-toggled .app-sidebar__toggle {
    display: block;
}

.user-pic {
    margin-bottom: 0.8rem;
}

.sidebar-navs a {
    background: rgba(255, 255, 255, 0.07);
    border: 1px solid rgba(255, 255, 255, 0.07) !important;
    color: #fff !important;
    border-radius: 5px;
    padding: 0.8rem !important;
}

.app-sidebar .sidebar-navs {
    padding: 0 10px 10px 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar-navs .nav li:last-child a {
    margin-right: 0 !important;
}

.side-menu h3 {
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 1px;
    opacity: 1;
    padding: 20px 34px 10px;
    text-transform: capitalize;
    width: 100%;
    position: relative;
    color: #90909e;
    /* color: $white-4!important; */
    position: relative;
}

.side-menu h3:after {
    content: "--";
    position: absolute;
    left: 21px;
    top: 20px;
}

.sidenav-toggled .side-menu h3 {
    display: none;
}

.slide.is-expanded .slide-menu,
.sub-slide {
    display: block;
}

/*-- Sub-slide--**/
.sub-side-menu__item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 7px 0;
    font-size: 12px;
}

.sub-side-menu__label {
    white-space: normal;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    font-weight: 400;
}

.sub-slide .sub-angle {
    transform-origin: center;
}

.sub-slide.is-expanded .sub-angle {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.sub-slide2 .sub-angle {
    transform-origin: center;
}

.sub-slide2.is-expanded .sub-angle2 {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.sub-slide-menu {
    list-style: none;
    padding: 0;
}

.sub-slide-item {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    list-style: none;
    padding: 8px 0 8px 25px !important;
}

.sub-slide-menu {
    display: none;
    font-size: 0.8rem !important;
    padding-left: 0;
    list-style: none;
}

.sub-slide.is-expanded .sub-slide-menu {
    display: none;
}

.slide-menu .sub-slide.is-expanded {
    display: block;
}

.sub-side-menu__item {
    padding-left: 40px !important;
    padding-right: 20px !important;
}

body:not(.transparent-theme) .sub-side-menu__item:hover {
    color: var(--primary) !important;
}

.transparent-theme .sub-side-menu__item:hover {
    color: #fff !important;
}

.sub-slide.is-expanded .sub-slide-item {
    padding-left: 42px !important;
}

.sub-slide-menu1 .sub-slide-item2 {
    padding: 6px 0px 6px 48px !important;
    display: block;
    font-size: 11px;
}

.sub-slide .sub-angle2 {
    margin-left: 68px;
}

.sub-slide-menu1 .sub-slide-item2:hover {
    color: var(--primary) !important;
}

.app-sidebar .side-menu__item.active:hover {
    color: #7282a9 !important;
}

.app-sidebar .slide-menu .sub-slide-menu a:before {
    left: 25px;
}

.side-menu .slid.active .sub-slide.is-expanded .sub-slide-menu {
    max-height: 0;
}

.app-sidebar .slide-menu .slide-item:before {
    content: "\f22d";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    top: 9px;
    left: 20px;
    font-size: 10px;
    color: #7b8191;
    font-weight: 900;
    opacity: 0.5;
}

.app-sidebar__toggle .close-toggle,
.sidebar-mini.sidenav-toggled .open-toggle {
    display: none;
}

.header-icon {
    text-align: center;
    line-height: 40px;
    width: 30px;
    height: 30px;
    border-radius: 25px;
    text-align: center;
    line-height: 1.8;
    font-size: 1.3rem;
    color: #7b8191;
    border: 0px solid #eae3f3;
    padding: 20px;
}

.sidebar-mini.sidenav-toggled .close-toggle {
    display: block;
    color: #000;
    font-size: 18px;
    line-height: 42px;
    text-align: center;
}

.sidenav-toggled .app-content {
    transition: margin-left 320ms ease;
}

.sub-slide-menu .active .sub-slide-item.active {
    color: #f34343;
}

.app-sidebar__user .user-info .text-muted {
    color: #8991a5 !important;
    font-size: 13px;
}

.app-sidebar .slide-menu a.active:before {
    color: var(--primary);
}

@media (min-width: 768px) {
    .app.sidebar-mini.sidenav-toggled-open .app-sidebar {
        left: 0;
        width: 240px !important;
    }

    .app.sidebar-mini.sidenav-toggled-open .side-menu__item {
        display: flex;
        align-items: center;
        padding: 12px 14px;
        margin: 2px 0;
    }

    .app.sidebar-mini.sidenav-toggled-open .side-menu .slide .side-menu__item {
        padding: 10px 20px 10px 22px;
        margin: 0;
        text-align: left;
        border-radius: 0;
    }

    .app.sidebar-mini.sidenav-toggled-open .side-menu .slide .side-menu__item.active {
        margin: 0px 10px !important;
        padding: 11px 15px 11px 15px !important;
        width: inherit;
        height: inherit;
    }

    .app.sidebar-mini.sidenav-toggled-open .side-menu__label {
        white-space: normal;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        opacity: 1;
        display: block !important;
        position: initial;
        font-size: 13px;
        line-height: 1;
        font-weight: 400;
        color: #7b8191;
    }

    .app.sidebar-mini.sidenav-toggled-open .angle {
        display: block;
    }

    .app.sidebar-mini.sidenav-toggled-open .app-sidebar__user .user-info {
        margin: 0 auto;
        display: block !important;
        text-align: center;
    }

    .app.sidebar-mini.sidenav-toggled-open .side-menu .side-menu__icon {
        margin-right: 12px;
    }

    .app.sidebar-mini.sidenav-toggled-open .ps>.ps__rail-y>.ps__thumb-y {
        left: 3px;
        background-color: rgb(207, 211, 225);
    }

    .app.sidebar-mini.sidenav-toggled-open .main-sidebar-header {
        width: 240px;
    }

    .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .desktop-logo {
        display: block !important;
    }

    .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .profile-status {
        right: 19px;
    }

    .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .main-logo.dark-theme {
        display: none !important;
    }

    .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .desktop-logo.logo-dark {
        display: none !important;
    }

    .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .main-logo.dark-theme {
        display: none !important;
    }

    .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-badge {
        display: block !important;
    }

    .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu {
        left: 0;
    }

    .sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu__item:hover+.slide-menu {
        visibility: visible;
        opacity: inherit;
    }

    .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide.is-expanded .slide-menu {
        display: block;
        box-shadow: none;
    }

    .sidenav-toggled .slide-menu {
        box-shadow: none !important;
    }

    .sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu {
        position: inherit;
        visibility: visible;
        opacity: inherit !important;
        z-index: 0;
        cursor: pointer;
    }

    .sidebar-mini.sidenav-toggled .side-menu .slide .side-menu__item.active {
        padding: 10px;
    }
}

@media (min-width: 767px) and (max-width: 992px) {
    .main-sidebar-header .main-logo {
        display: block;
    }
}

@media (min-width: 1200px) {
    .main-sidebar-sticky+.main-content {
        margin-left: 240px;
    }
}

.main-sidebar-header {
    height: 64px;
    padding: 12px 20px;
    width: 240px;
    background: #fff;
    border-bottom: 1px solid #ededf5;
    position: fixed;
    border-right: 1px solid #ededf5;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    -webkit-transition: left 320ms ease, width 320ms ease;
    -o-transition: left 320ms ease, width 320ms ease;
    transition: left 320ms ease, width 320ms ease;
    outline: none;
}

.main-sidemenu {
    margin-top: 85px;
    height: 90%;
}

.sidenav-toggled-open .side-menu .slide.active .side-menu__label,
.side-menu .slide.active .side-menu__icon {
    color: #ffffff !important;
}

.sub-slide .sub-angle2 {
    margin-left: auto;
    float: right;
    font-size: 12px;
    margin-top: 3px;
    transform-origin: center;
    opacity: 0.8;
}

.side-menu .side-menu__icon:before {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    top: 12px;
    bottom: 0;
    border-radius: 50%;
    right: 3px;
    background: rgba(108, 95, 252, 0.26);
}

.side-menu__item:hover .side-menu__icon,
.side-menu__item:hover .side-menu__label,
.side-menu__item:hover .angle {
    color: var(--primary) !important;
    fill: var(--primary) !important;
}

.sidebar-mini.sidenav-toggled .side-menu .slide .side-menu__item.active {
    border-right: 0 !important;
}

.sidebar-mini .side-menu {
    margin-left: 0 !important;
}

@media (min-width: 768px) {
    .icontext-menu.app.sidenav-toggled .side-menu .side-menu__icon {
        margin-bottom: 5px;
    }

    .icontext-menu.sidenav-toggled .side-menu__label {
        display: block !important;
        font-size: 11px !important;
        position: relative !important;
    }

    .icontext-menu.app.sidenav-toggled .side-menu__label {
        font-size: 13px !important;
    }

    .icontext-menu.app.sidenav-toggled .app-sidebar {
        width: 110px !important;
    }

    .icontext-menu.app.sidenav-toggled .main-sidebar-header {
        width: 110px !important;
    }

    .icontext-menu.app.sidenav-toggled .main-header {
        padding-left: 110px !important;
    }

    .icontext-menu.app.sidenav-toggled .app-content {
        margin-left: 110px;
    }

    .icontext-menu.app.sidebar-mini.sidenav-toggled-open .app-sidebar {
        width: 240px !important;
    }

    .icontext-menu.app.sidebar-mini.sidenav-toggled-open .main-sidebar-header {
        width: 240px !important;
    }

    .icontext-menu.app.sidebar-mini.sidenav-toggled-open .close-toggle {
        display: none !important;
    }

    .icontext-menu.sidebar-mini.sidenav-toggled-open .side-menu .slide .side-menu__item.active {
        width: inherit;
        height: inherit;
    }
}

@media (min-width: 768px) {
    .closed-menu.app.sidenav-toggled .app-sidebar {
        display: none !important;
    }

    .closed-menu.app.sidenav-toggled .app-content {
        margin-left: 0px !important;
    }

    .closed-menu.app.sidenav-toggled .main-header {
        padding-left: 0px !important;
    }
}

@media (min-width: 768px) {
    .hover-submenu.sidenav-toggled-open .slide-menu {
        border-radius: 0px 5px 0px 5px !important;
    }

    .hover-submenu.rtl.sidenav-toggled-open .slide-menu {
        border-radius: 5px 0px 0px 5px !important;
    }

    .hover-submenu .slide-item {
        padding: 8px 18px 8px 35px;
    }

    .hover-submenu .slide-menu {
        border-radius: 0px 5px 5px 0px;
    }

    .hover-submenu.dark-theme.app.sidenav-toggled-open .slide-menu {
        background: #1d1d2a;
        border: 1px solid #444566;
        box-shadow: 8px 8px 17px rgba(255, 255, 255, 0.1);
    }

    .hover-submenu.dark-theme .side-menu__label {
        color: #a9abbd;
    }

    .hover-submenu.dark-theme .app-sidebar .slide-menu .slide-item:before {
        color: #a9abbd;
    }

    .hover-submenu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
        position: absolute;
        left: 108px;
        min-width: 180px;
        opacity: 1;
        z-index: 9999;
        visibility: hidden;
        top: 0;
        display: block;
        max-height: inherit;
    }

    .hover-submenu .app-sidebar .slide {
        position: relative;
        display: block;
    }

    .hover-submenu.app.sidenav-toggled-open .ps>.ps__rail-y {
        display: none !important;
    }

    .hover-submenu.app.sidenav-toggled-open .slide-menu {
        padding-left: 0 !important;
        padding: 10px 0px !important;
        position: absolute;
        background: #fff;
        opacity: 1;
        visibility: visible;
        z-index: 999;
        width: 200px;
        left: 110px;
        border: 1px solid #ededf5;
        transition: none !important;
        display: block !important;
        box-shadow: 8px 8px 17px rgba(0, 0, 0, 0.1);
        top: 0;
    }

    .hover-submenu.app.sidenav-toggled-open .slide:hover .slide-menu {
        visibility: visible;
        display: block !important;
        height: auto !important;
    }

    .hover-submenu.app.sidenav-toggled-open .app-sidebar {
        overflow: visible !important;
        position: absolute !important;
    }

    .hover-submenu.app.sidenav-toggled-open .side-menu__label {
        white-space: nowrap;
        display: block;
        position: relative;
        padding: 0;
        margin: 0;
        left: 0;
        color: #7b8191;
        opacity: 1;
        background: transparent;
        font-size: 12px;
        box-shadow: none;
    }

    .hover-submenu.app.sidenav-toggled-open .side-menu .side-menu__icon {
        margin-bottom: 5px !important;
    }

    .hover-submenu.app.sidenav-toggled-open .side-menu .slide .side-menu__item.active {
        border-right: 0 !important;
        padding: 10px;
    }

    .hover-submenu.app.sidenav-toggled-open .main-sidebar-header .desktop-logo,
    .hover-submenu.app.sidenav-toggled-open .main-sidebar-header .desktop-dark,
    .hover-submenu.app.sidenav-toggled-open .main-sidebar-header .mobile-dark {
        display: none !important;
    }

    .hover-submenu.app.sidenav-toggled-open .main-sidebar-header .mobile-logo {
        display: block !important;
    }

    .hover-submenu.app.sidenav-toggled-open .side-item.side-item-category {
        display: none !important;
    }

    .hover-submenu.app.sidenav-toggled-open .side-menu__icon {
        margin-right: 0;
        margin-left: 0;
        margin: 0 auto !important;
    }

    .hover-submenu.app.sidenav-toggled-open .side-menu .slide .side-menu__item.active {
        display: block;
        margin: 0 auto !important;
        padding: 10px !important;
        border-radius: 0;
        text-align: center;
    }

    .hover-submenu.app.sidenav-toggled-open .side-menu .slide .side-menu__item {
        display: block;
        margin: 0 auto !important;
        padding: 11px 0px 11px 0px !important;
        border-radius: 0;
        text-align: center;
    }

    .hover-submenu.app.sidenav-toggled-open .side-menu .slide .side-menu__item i {
        display: none !important;
    }

    .hover-submenu.app.sidenav-toggled .main-content {
        min-height: 1300px;
    }

    .hover-submenu.app.sidenav-toggled .side-menu__label {
        font-size: 11px !important;
    }

    .hover-submenu.app.sidenav-toggled .app-sidebar {
        position: absolute !important;
    }

    .hover-submenu.app.sidenav-toggled .side-menu .side-menu__icon {
        margin-bottom: 5px;
    }

    .hover-submenu.app.sidenav-toggled .side-menu__label {
        display: block !important;
    }

    .hover-submenu.app.sidenav-toggled .app-sidebar__toggle .close-toggle {
        display: block;
    }

    .hover-submenu.app.sidenav-toggled .main-header {
        padding-left: 110px;
    }

    .hover-submenu.app.sidenav-toggled .main-sidebar-header {
        width: 110px;
    }

    .hover-submenu.app.sidenav-toggled .app-content {
        margin-left: 110px;
    }

    .hover-submenu.app.sidenav-toggled .app-sidebar {
        left: 0;
        width: 110px !important;
    }

    .hover-submenu.app.sidenav-toggled .app-sidebar__overlay {
        visibility: hidden;
    }

    .hover-submenu.app.sidenav-toggled .slide.is-expanded .slide-menu {
        display: none;
    }

    .hover-submenu .side-badge {
        display: none;
    }

    .hover-submenu .side-header {
        width: 240px;
        left: 0;
        right: 0;
        top: 0;
        position: fixed;
        z-index: 1;
    }
}

.hover-submenu .sub-slide-menu .sub-side-menu__item:before {
    content: "\f22d";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    left: 25px !important;
    font-size: 9px;
    color: #7b8191;
    font-weight: 900;
}

@media (min-width: 768px) {
    .hover-submenu1.sidenav-toggled-open .slide-menu {
        border-radius: 0px 5px 0px 5px !important;
    }

    .hover-submenu1.rtl.sidenav-toggled-open .slide-menu {
        border-radius: 5px 0px 0px 5px !important;
    }

    .hover-submenu1 .slide-item {
        padding: 8px 18px 8px 35px;
    }

    .hover-submenu1 .side-menu__label1 {
        display: block !important;
    }

    .hover-submenu1.dark-theme.app.sidenav-toggled-open .side-menu__label1 {
        border-bottom: 1px solid #404353;
    }

    .hover-submenu1.dark-theme.app.sidenav-toggled-open .slide-menu {
        background: #1d1d2a;
        border: 1px solid #444566;
        box-shadow: 8px 8px 17px rgba(255, 255, 255, 0.1);
    }

    .hover-submenu1.dark-theme .side-menu__label {
        color: #a9abbd;
    }

    .hover-submenu1.dark-theme .app-sidebar .slide-menu .slide-item:before {
        color: #a9abbd;
    }

    .hover-submenu1.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
        position: absolute;
        left: 108px;
        min-width: 180px;
        opacity: 1;
        z-index: 9999;
        visibility: hidden;
        top: 0;
        display: block;
        max-height: inherit;
    }

    .hover-submenu1 .app-sidebar .slide {
        position: relative;
        display: block;
    }

    .hover-submenu1.app.sidenav-toggled-open .ps>.ps__rail-y {
        display: none !important;
    }

    .hover-submenu1.app.sidenav-toggled-open .side-menu__label1 {
        display: block;
        font-size: 15px;
        font-weight: 500;
        border-bottom: 1px solid #ededf5;
        padding: 10px 20px !important;
        margin-bottom: 6px;
    }

    .hover-submenu1.app.sidenav-toggled-open .side-menu__label1 a {
        color: var(--primary) !important;
    }

    .hover-submenu1.app.sidenav-toggled-open .slide-menu {
        padding-left: 0 !important;
        position: absolute;
        background: #fff;
        opacity: 1;
        visibility: visible;
        z-index: 999;
        left: 110px;
        width: 200px;
        border: 1px solid #ededf5;
        transition: none !important;
        display: block !important;
        box-shadow: 8px 8px 17px rgba(0, 0, 0, 0.1);
        top: 0;
    }

    .hover-submenu1.app.sidenav-toggled-open .slide:hover .slide-menu {
        visibility: visible;
        display: block !important;
        height: auto !important;
    }

    .hover-submenu1.app.sidenav-toggled-open .app-sidebar {
        overflow: visible !important;
        position: absolute !important;
    }

    .hover-submenu1.app.sidenav-toggled-open .side-menu__label {
        display: none !important;
        white-space: nowrap;
        display: block;
        position: relative;
        padding: 0;
        margin: 0;
        left: 0;
        color: #7b8191;
        opacity: 1;
        background: transparent;
        font-size: 12px;
        box-shadow: none;
    }

    .hover-submenu1.app.sidenav-toggled-open .side-menu .side-menu__icon {
        margin-bottom: 5px !important;
    }

    .hover-submenu1.app.sidenav-toggled-open .side-menu .slide .side-menu__item.active {
        border-right: 0 !important;
        padding: 10px;
    }

    .hover-submenu1.app.sidenav-toggled-open .main-sidebar-header .desktop-logo,
    .hover-submenu1.app.sidenav-toggled-open .main-sidebar-header .desktop-dark,
    .hover-submenu1.app.sidenav-toggled-open .main-sidebar-header .mobile-dark {
        display: none !important;
    }

    .hover-submenu1.app.sidenav-toggled-open .main-sidebar-header .mobile-logo {
        display: block !important;
    }

    .hover-submenu1.app.sidenav-toggled-open .side-item.side-item-category {
        display: none !important;
    }

    .hover-submenu1.app.sidenav-toggled-open .side-menu__icon {
        margin-right: 0;
        margin-left: 0;
        margin: 0 auto !important;
    }

    .hover-submenu1.app.sidenav-toggled-open .side-menu .slide .side-menu__item.active {
        display: block;
        margin: 0 auto !important;
        padding: 10px !important;
        border-radius: 0;
        text-align: center;
    }

    .hover-submenu1.app.sidenav-toggled-open .side-menu .slide .side-menu__item {
        display: block;
        margin: 0 auto !important;
        padding: 11px 0px 11px 0px !important;
        border-radius: 0;
        text-align: center;
    }

    .hover-submenu1.app.sidenav-toggled-open .side-menu .slide .side-menu__item i {
        display: none !important;
    }

    .hover-submenu1.app.sidenav-toggled-open .side-menu__label {
        display: none !important;
    }

    .hover-submenu1.app.sidenav-toggled .main-content {
        min-height: 1300px;
    }

    .hover-submenu1.app.sidenav-toggled .side-menu__label {
        display: none !important;
    }

    .hover-submenu1.app.sidenav-toggled .side-menu__label {
        font-size: 11px !important;
    }

    .hover-submenu1.app.sidenav-toggled .app-sidebar {
        position: absolute !important;
    }

    .hover-submenu1.app.sidenav-toggled .side-menu .side-menu__icon {
        margin-bottom: 5px;
    }

    .hover-submenu1.app.sidenav-toggled .app-sidebar__toggle .close-toggle {
        display: block;
    }

    .hover-submenu1.app.sidenav-toggled .main-header {
        padding-left: 110px;
    }

    .hover-submenu1.app.sidenav-toggled .main-sidebar-header {
        width: 110px;
    }

    .hover-submenu1.app.sidenav-toggled .app-content {
        margin-left: 110px;
    }

    .hover-submenu1.app.sidenav-toggled .app-sidebar {
        left: 0;
        width: 110px !important;
    }

    .hover-submenu1.app.sidenav-toggled .app-sidebar__overlay {
        visibility: hidden;
    }

    .hover-submenu1.app.sidenav-toggled .slide.is-expanded .slide-menu {
        display: none;
    }

    .hover-submenu1 .side-badge {
        display: none;
    }

    .hover-submenu1 .side-header {
        width: 240px;
        left: 0;
        right: 0;
        top: 0;
        position: fixed;
        z-index: 1;
    }
}

.hover-submenu1 .sub-slide-menu .sub-side-menu__item:before {
    content: "\f22d";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    left: 25px !important;
    font-size: 9px;
    color: #7b8191;
    font-weight: 900;
}

.sub-slide2.is-expanded .sub-slide-menu1 {
    display: none;
}

.slide-menu .sub-slide-menu1 {
    padding: 0 10px;
}

.sub-slide-menu1 {
    display: none;
    font-size: 0.8rem !important;
    padding-left: 0;
    list-style: none;
}

.main-sidebar-header {
    backdrop-filter: blur(0.25rem);
}

.rtl {
    /* ###### 3.7 Grid ###### */
    /* ###### 5.7 Nav   ###### */
    /* ######### Res-Tabs #########*/
    /*latetes news timeline */
    /*-----Back to Top-----*/
    /* ###### 8.5 Dashboard Five  ###### */
    /* ###### 8.10 Dashboard Ten  ###### */
    /*==============================================================================
                                Start Mobile CSS
  ===============================================================================*/
    /* ================== Mobile Menu Change Brake Point ================== */
    /* Extra @Media Query*/
    /* ###### 3.2 Datepicker ###### */
    /* ###### 7.6 Mail  ###### */
    /* ######## UTILITIES-STYLES ######## */
    /* ###### 9.4 Margin   ###### */
    /* ###### 9.6 Padding   ###### */
    /*----- Componant: Top Navigation Bar ----- */
}

@media (max-width: 480px) {
    .rtl .border-end.bd-xs-e-0 {
        border-left: 0 !important;
        border-right: inherit !important;
    }

    .rtl .border-end.bd-xs-s-0 {
        border-right: 0 !important;
        border-left: inherit !important;
    }
}

@media (max-width: 991px) {
    .rtl .border-end.bd-md-e-0 {
        border-left: 0 !important;
        border-right: inherit !important;
    }
}

@media (max-width: 1024px) {
    .rtl .border-end.bd-lg-e-0 {
        border-left: 0 !important;
        border-right: inherit !important;
    }
}

@media (max-width: 1366px) {
    .rtl .border-end.bd-xl-e-0 {
        border-left: 0 !important;
        border-right: inherit !important;
    }
}

.rtl .panel-group1 .panel-collapse.collapse.show:before {
    left: 10px;
    right: inherit;
}

.rtl .nav.nav-item.header-icons.navbar-nav-right {
    padding-right: 0;
}

@media (min-width: 768px) {
    .rtl .float-md-end {
        float: left !important;
    }
}

@media (min-width: 768px) {
    .rtl.horizontal .main-sidemenu .slide-right {
        right: initial !important;
        left: 0px;
        transform: rotate(180deg);
    }

    .rtl.horizontal .main-sidemenu .slide-left {
        left: initial !important;
        right: 0px;
        transform: rotate(180deg);
    }
}

.rtl .button-icon i {
    float: right;
    margin-top: 1px;
}

@media (min-width: 768px) {
    .rtl.horizontal .main-footer {
        padding: 0.9rem 1rem 0.9rem 1rem !important;
    }
}

.rtl.transparent-theme .sidebar.sidebar-right {
    box-shadow: none !important;
}

.rtl.transparent-theme .app-sidebar {
    border-left: 1px solid rgba(255, 255, 255, 0.15);
}

.rtl.transparent-theme .main-sidebar-header {
    border-left-color: rgba(255, 255, 255, 0.15);
}

@media (min-width: 992px) {
    .rtl.transparent-theme .main-header-left .btn {
        border-left: 1px solid rgba(255, 255, 255, 0.15) !important;
    }
}

.rtl .la-angle-right:before {
    content: "\f104";
}

.rtl .la-angle-left:before {
    content: "\f105";
}

.rtl .pri-tabs-heading .nav {
    padding-right: 0 !important;
}

.rtl .demo_changer {
    z-index: 999999;
}

.rtl .prof-details span {
    display: inline-flex;
}

.rtl .prof-details .me-3 {
    margin-left: 1rem !important;
    margin-right: auto !important;
}

.rtl .prof-details .me-2 {
    margin-left: 0.5rem !important;
    margin-right: auto !important;
}

@media (min-width: 768px) {
    .rtl #checkoutsteps .checkoutline {
        margin: 0px 8%;
    }
}

.rtl .select2-container--default .select2-selection--single .select2-selection__arrow {
    left: 1px;
    right: inherit;
}

.rtl .dt-buttons.btn-group {
    flex-wrap: unset !important;
}

.rtl .table> :not(:first-child) {
    border-top: 1px solid #404353 !important;
}

.rtl canvas {
    width: 100% !important;
}

@media (min-width: 1400px) {
    .rtl.layout-boxed .app-sidebar {
        right: auto !important;
        left: inherit !important;
    }
}

@media (min-width: 768px) {
    .rtl.closed-menu.app.sidenav-toggled .app-content {
        margin-right: 0px !important;
    }

    .rtl.closed-menu.app.sidenav-toggled .main-header {
        padding-right: 0px !important;
    }

    .rtl.closed-menu .app-sidebar {
        position: fixed;
    }

    .rtl.closed-menu .side-header {
        position: fixed;
    }
}

@media (min-width: 768px) {
    .rtl.icontext-menu.app.sidenav-toggled .main-header {
        padding-right: 110px !important;
        padding-left: inherit !important;
    }

    .rtl.icontext-menu.app.sidenav-toggled .app-content {
        margin-right: 110px;
        margin-left: inherit;
    }
}

@media (min-width: 768px) {

    .rtl.hover-submenu.app.sidenav-toggled.sidenav-toggled-open .side-menu .slide .side-menu__item.active,
    .rtl.hover-submenu.app.sidenav-toggled .side-menu .slide .side-menu__item.active {
        padding: 11px 0px 11px 0px !important;
    }

    .rtl.hover-submenu .side-menu .slide .side-menu__item {
        padding: 10px 22px 10px 20px !important;
    }

    .rtl.hover-submenu.app.sidenav-toggled-open .side-menu .slide .side-menu__item {
        text-align: center !important;
    }

    .rtl.hover-submenu.app.sidenav-toggled .app-sidebar {
        position: absolute !important;
    }

    .rtl.hover-submenu .side-header {
        position: fixed;
    }

    .rtl.hover-submenu .slide-item {
        padding: 8px 35px 8px 18px !important;
    }

    .rtl.hover-submenu .slide-menu {
        border-radius: 5px 0px 0px 5px;
    }

    .rtl.hover-submenu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
        right: 108px;
        left: inherit;
    }

    .rtl.hover-submenu.app.sidenav-toggled-open .slide-menu {
        padding-right: 0 !important;
        right: 110px;
    }

    .rtl.hover-submenu.app.sidenav-toggled-open .side-menu__label {
        right: 0;
        left: inherit;
    }

    .rtl.hover-submenu.app.sidenav-toggled-open .side-menu .slide .side-menu__item.active {
        border-left: 0 !important;
        border-right: inherit;
    }

    .rtl.hover-submenu.app.sidenav-toggled-open .side-menu .slide .side-menu__item {
        padding: 11px 0px 11px 0px !important;
    }

    .rtl.hover-submenu.app.sidenav-toggled .main-header {
        padding-right: 110px;
        padding-left: inherit;
    }

    .rtl.hover-submenu.app.sidenav-toggled .app-content {
        margin-right: 110px;
        margin-left: inherit;
    }

    .rtl.hover-submenu.app.sidenav-toggled .app-sidebar {
        right: 0;
        left: inherit;
    }
}

@media (min-width: 768px) {

    .rtl.hover-submenu1.app.sidenav-toggled.sidenav-toggled-open.light-menu .desktop-logo,
    .rtl.hover-submenu1.app.sidenav-toggled.sidenav-toggled-open.light-menu .mobile-dark,
    .rtl.hover-submenu1.app.sidenav-toggled.sidenav-toggled-open.light-menu .mobile-logo {
        display: none !important;
    }

    .rtl.hover-submenu1.app.sidenav-toggled.sidenav-toggled-open.light-menu .desktop-dark {
        display: block !important;
    }

    .rtl.hover-submenu1.app.sidenav-toggled.sidenav-toggled-open .side-menu .slide .side-menu__item.active,
    .rtl.hover-submenu1.app.sidenav-toggled .side-menu .slide .side-menu__item.active {
        padding: 11px 0px 11px 0px !important;
    }

    .rtl.hover-submenu1 .side-menu .slide .side-menu__item {
        padding: 10px 22px 10px 20px !important;
    }

    .rtl.hover-submenu1.app.sidenav-toggled-open .side-menu .slide .side-menu__item {
        text-align: center !important;
    }

    .rtl.hover-submenu1.app.sidenav-toggled .app-sidebar {
        position: absolute !important;
    }

    .rtl.hover-submenu1 .side-header {
        position: fixed;
    }

    .rtl.hover-submenu1 .slide-item {
        padding: 8px 35px 8px 18px !important;
    }

    .rtl.hover-submenu1 .slide-menu {
        border-radius: 5px 0px 0px 5px;
    }

    .rtl.hover-submenu1.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
        right: 108px;
        left: inherit;
    }

    .rtl.hover-submenu1.app.sidenav-toggled-open .slide-menu {
        padding-right: 0 !important;
        right: 110px;
    }

    .rtl.hover-submenu1.app.sidenav-toggled-open .side-menu__label {
        right: 0;
        left: inherit;
    }

    .rtl.hover-submenu1.app.sidenav-toggled-open .side-menu .slide .side-menu__item.active {
        border-left: 0 !important;
        border-right: inherit;
    }

    .rtl.hover-submenu1.app.sidenav-toggled-open .side-menu .slide .side-menu__item {
        padding: 11px 0px 11px 0px !important;
    }

    .rtl.hover-submenu1.app.sidenav-toggled .main-header {
        padding-right: 110px;
        padding-left: inherit;
    }

    .rtl.hover-submenu1.app.sidenav-toggled .app-content {
        margin-right: 110px;
        margin-left: inherit;
    }

    .rtl.hover-submenu1.app.sidenav-toggled .app-sidebar {
        right: 0;
        left: inherit;
    }
}

@media (min-width: 768px) {
    .rtl.horizontal .side-menu>li>a {
        padding: 17px 13px 17px 13px !important;
    }

    .rtl.horizontal .main-sidemenu i.angle {
        left: 0;
        right: inherit;
        transform: rotate(270deg);
    }
}

.rtl ul.timeline {
    padding-right: 2rem !important;
    padding-left: inherit !important;
}

.rtl .dt-button.dropdown-item.buttons-columnVisibility.active {
    text-align: right;
}

.rtl .dt-button.dropdown-item.buttons-columnVisibility {
    text-align: right;
}

.rtl .btn-group>.btn-group:not(:first-child)>.btn,
.rtl .btn-group>.btn:nth-child(n+3),
.rtl .btn-group> :not(.btn-check)+.btn {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.rtl .ql-snow .ql-tooltip {
    margin-right: 20rem;
    margin-left: inherit;
}

.rtl .richText .richText-toolbar ul li a .richText-dropdown-outer ul.richText-dropdown li.inline {
    float: right;
}

.rtl .richText .richText-toolbar ul li a .richText-dropdown-outer .richText-dropdown .richText-dropdown-close {
    left: 10px;
    right: inherit;
}

.rtl .richText .richText-toolbar ul li {
    float: right;
}

.rtl .richText .richText-toolbar ul {
    padding-right: 0;
}

.rtl .note-dropdown-menu.dropdown-menu.note-check.dropdown-fontname.show {
    text-align: right !important;
}

.rtl .note-editor .note-toolbar .note-color .dropdown-toggle,
.rtl .note-popover .popover-content .note-color .dropdown-toggle {
    padding-right: 5px !important;
    padding-left: inherit !important;
}

.rtl .was-validated .form-control:invalid,
.rtl .form-control.is-invalid,
.rtl input.form-control.parsley-error,
.rtl textarea.form-control.parsley-error {
    padding-left: calc(1.5em + 0.75rem);
    padding-right: 0.75rem;
    background-position: top calc(0.375em + 0.1875rem) left calc(0.375em + 0.1875rem);
}

.rtl .form-select {
    padding: 0.375rem 0.75rem 0.375rem 2.25rem;
}

@media (min-width: 768px) {
    .rtl.app.sidebar-mini.sidenav-toggled-open .side-menu .slide .side-menu__item.active {
        padding: 11px 15px 11px 15px !important;
    }
}

.rtl .SumoSelect .select-all {
    border-radius: 3px 0px 0 3px;
    padding: 8px 35px 3px 0px;
}

.rtl .SumoSelect>.optWrapper.multiple>.options li.opt {
    padding-right: 35px;
}

.rtl .SumoSelect>.optWrapper.multiple>.options li.opt span,
.rtl .SumoSelect .select-all>span {
    margin-right: -35px;
}

.rtl .dropify-wrapper .dropify-clear {
    left: 10px;
    right: inherit;
}

.rtl .intl-tel-input .flag-dropdown .country-list .flag1,
.rtl .intl-tel-input .flag-dropdown .country-list .country-name {
    margin-left: 6px;
}

.rtl .intl-tel-input input {
    padding-right: 47px;
    border-radius: 0px 5px 5px 0px;
}

.rtl .intl-tel-input .flag-dropdown .selected-flag {
    border-radius: 0px 5px 5px 0px;
}

.rtl .form-select.is-invalid:not([multiple]):not([size]),
.rtl .form-select.is-invalid:not([multiple])[size="1"],
.rtl .was-validated .form-select:invalid:not([multiple]):not([size]),
.rtl .was-validated .form-select:invalid:not([multiple])[size="1"] {
    padding-left: 4.125rem;
    padding-right: 0.75rem;
    background-position: top calc(0.375em + 0.1875rem) left calc(0.375em + 0.1875rem);
}

.rtl .was-validated .form-control:valid,
.rtl .form-control.is-valid,
.rtl input.form-control.parsley-success,
.rtl textarea.form-control.parsley-success {
    padding-left: calc(1.5em + 0.75rem);
    padding-right: 0.75rem;
    background-position: top calc(0.375em + 0.1875rem) left calc(0.375em + 0.1875rem);
}

.rtl .input-group.has-validation>.dropdown-toggle:nth-last-child(n+4),
.rtl .input-group.has-validation> :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.rtl .telephone-input .btn {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.rtl .intl-tel-input .flag-dropdown .selected-flag .down-arrow {
    right: 20px;
    left: inherit;
}

.rtl .SumoSelect>.CaptionCont>span {
    padding-left: 30px;
    padding-right: inherit;
}

.rtl .SumoSelect>.CaptionCont>label {
    left: 0;
    right: inherit;
}

.rtl .SumoSelect>.CaptionCont>label>i:before {
    margin-right: 0.255em;
    margin-left: inherit;
    border-right: 0.3em solid transparent;
    border-left: 0.3em solid transparent;
}

.rtl .form-select {
    background-position: left 0.75rem center !important;
}

.rtl .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
.rtl .input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.rtl .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-right: -1px;
    margin-left: inherit;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.rtl .form-control {
    border-radius: 5px !important;
}

.rtl .main-icon-group ul,
.rtl .icons-list-wrap ul {
    padding-right: 0;
}

.rtl .main-content-app .option-dots {
    left: 15px;
    right: inherit;
}

.rtl .chat-profile {
    padding: 8px !important;
}

.rtl .emai-img h6 small {
    float: left;
}

.rtl .product-details .media img {
    margin-left: 20px;
    margin-right: inherit;
}

.rtl.error-page1 .demo-icon svg {
    left: 60px;
    right: inherit;
}

.rtl .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_actions button.ff_fileupload_start_upload {
    margin-left: 0.5em;
    margin-right: inherit;
}

.rtl .select2-results__options {
    padding-right: 0rem;
    padding-left: inherit;
}

.rtl div.dataTables_wrapper div.dataTables_length label {
    text-align: right;
}

.rtl .carousel {
    overflow: hidden;
}

.rtl .carousel-control-prev {
    left: 0;
    right: inherit;
}

.rtl .carousel-control-next {
    right: 0;
    left: inherit;
}

.rtl .active.carousel-item-start,
.rtl .carousel-item-prev:not(.carousel-item-end) {
    transform: translateX(100%) !important;
}

.rtl .active.carousel-item-end {
    transform: translateX(-100%) !important;
}

.rtl .carousel-item-next:not(.carousel-item-start) {
    transform: translateX(-100%) !important;
    transform: translateX(-100%) !important;
}

.rtl .accordion i {
    float: right;
}

.rtl .panel-heading1.collapsed::before {
    left: 10px;
    right: inherit;
}

.rtl .tabs-style-2 .main-nav-line .nav-link {
    padding: 0.7rem 2rem;
}

.rtl .main-nav-column .nav-link {
    padding: 7px 14px;
}

.rtl .list-group-horizontal>.list-group-item+.list-group-item {
    border-left-width: 1px;
    border-right-width: 0px;
}

.rtl .list-group-horizontal-sm>.list-group-item+.list-group-item {
    border-left-width: 1px;
    border-right-width: 0px;
}

.rtl .list-group-horizontal-md>.list-group-item+.list-group-item {
    border-left-width: 1px;
    border-right-width: 0px;
}

.rtl .list-group-horizontal-lg>.list-group-item+.list-group-item {
    border-left-width: 1px;
    border-right-width: 0px;
}

.rtl .list-group-horizontal-xl>.list-group-item+.list-group-item {
    border-left-width: 1px;
    border-right-width: 0px;
}

.rtl .alert-inner--icon i {
    float: right;
    padding-left: 5px;
}

.rtl .alert-heading i {
    float: right;
}

.rtl .alert-dismissible .btn-close {
    left: 0;
    right: inherit;
}

.rtl .main-content-left-mail .card-header i {
    float: right;
}

.rtl .tree li i {
    margin-left: 10px;
    margin-right: inherit;
    float: right;
    padding-top: 5px;
}

.rtl .dropdown-item i {
    float: right;
}

.rtl .dropdown-item {
    text-align: right;
}

.rtl .ps>.ps__rail-y {
    width: 3px;
}

@media (min-width: 768px) {
    .rtl .main-nav-line-chat .nav-link {
        padding: 8px 20px !important;
    }

    .rtl.horizontal .main-sidemenu .slide.is-expanded i.angle {
        left: 0px !important;
        right: inherit;
    }

    .rtl.horizontal .sub-slide .sub-slide-menu {
        right: 100%;
        left: inherit;
    }

    .rtl.horizontal .sub-slide .sub-angle {
        left: -20px;
        right: auto;
        margin-left: 0 !important;
    }

    .rtl.horizontal .horizontal-main .slide .slide-menu .slide-item:before,
    .rtl .horizontal .horizontal-main .slide .sub-slide-menu .slide-item:before {
        right: 15px;
        left: inherit;
        margin-left: 8px;
        margin-right: inherit;
    }

    .rtl.horizontal .main-sidemenu i.angle {
        left: 0 !important;
        right: inherit;
    }

    .rtl.horizontal .side-menu .slide .side-menu__item.active {
        border-left: none !important;
        border-right: inherit;
    }
}

.rtl .main-profile-menu.nav-link {
    padding: 0rem 0rem !important;
    margin: auto 6px;
}

@media (max-width: 767px) {
    .rtl.app.sidenav-toggled .side-menu .side-menu__icon {
        margin-left: 13px !important;
        margin-right: 0 !important;
    }

    .rtl.app .app-sidebar {
        right: -240px;
    }

    .rtl.app.sidebar-gone.sidenav-toggled .app-sidebar {
        right: 0;
    }
}

.rtl .side-menu {
    margin-bottom: 0;
    padding: 0;
    list-style: none;
}

.rtl .apexcharts-yaxis-title {
    transform: translate(-35px, 10px);
}

.rtl .apexcharts-tooltip-marker {
    margin-left: 10px;
    margin-right: inherit;
}

.rtl .sub-slide.is-expanded .sub-angle {
    transform: rotate(90deg);
}

.rtl .sub-slide2.is-expanded .sub-angle2 {
    transform: rotate(90deg);
}

.rtl .main-sidemenu .slide.is-expanded i.angle {
    left: 20px !important;
    right: inherit;
    transform: rotate(90deg);
}

.rtl .modal-header .btn-close {
    margin: -1rem auto -1rem -1rem;
}

@media (min-width: 992px) {

    .rtl .main-header>.container,
    .rtl .main-header>.container-fluid {
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media (min-width: 768px) {
    .rtl.app.sidebar-mini.sidenav-toggled-open .side-menu .slide .side-menu__item {
        padding: 10px 22px 10px 20px !important;
        text-align: right;
    }

    .rtl.app.sidebar-mini.sidenav-toggled-open .side-menu .side-menu__icon {
        margin-left: 12px;
        margin-right: inherit;
    }

    .rtl.app.sidenav-toggled .side-menu__item {
        padding: 11px 0 11px 0 !important;
    }
}

.rtl.sidebar-mini.sidenav-toggled .side-menu .slide .side-menu__item.active {
    border-left: 0px !important;
    border-right: inherit !important;
}

.rtl .sidebar.sidebar-right {
    transform: translate(-100%, 0);
}

.rtl .sidebar.sidebar-right.sidebar-open {
    transform: translate(0, 0);
}

.rtl .sidebar .tabs-menu ul li a {
    padding: 8px 8px !important;
}

.rtl .sidebar-right {
    left: 0;
    right: inherit;
}

.rtl .fe-chevron-right:before {
    content: "\e92e";
}

.rtl .fe-chevron-left:before {
    content: "\e92f";
}

.rtl .accordion>.card:first-of-type {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .accordion>.card:last-of-type {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.rtl .accordion .card-header a[aria-expanded=true] {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.rtl .accordion .collapse.show .card-body {
    border-radius: 0px 5px 5px 0px;
}

.rtl .main-accordion .accordion-body {
    padding: 0 20px 20px;
}

.rtl .accor div a[aria-expanded=true] {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.rtl #accordion11 .panel-body {
    border-radius: 0px 0px 5px 5px;
}

.rtl .panel-heading2.collapsed:before {
    left: 10px;
    right: inherit !important;
}

.rtl .panel-group2 .panel-collapse.collapse.show:before {
    left: 10px;
    right: inherit;
}

.rtl .collapse.show .accstyle {
    border-radius: 0px 0px 5px 5px;
}

.rtl .alert-dismissible {
    padding-left: 3.8125rem;
}

.rtl .badge-pill {
    padding-right: 8px;
    padding-left: 8px;
}

.rtl .badge {
    padding: 4px 6px 4px 6px;
}

@media (prefers-reduced-motion: reduce) {
    .rtl .badge {
        -webkit-transition: none;
        transition: none;
    }
}

.rtl .badge-pill {
    padding-left: 0.6em;
    padding-right: 0.6em;
}

.rtl .breadcrumb-4 ol {
    text-align: left;
}

.rtl .breadcrumb-4 li {
    text-align: left;
}

.rtl .breadcrumb-item+.breadcrumb-item {
    padding-right: 0.5rem;
}

.rtl .breadcrumb-item+.breadcrumb-item::before {
    padding-left: 0.5rem;
}

.rtl .main-breadcrumbs .breadcrumb-item+.breadcrumb-item {
    padding-right: 8px;
}

.rtl .main-breadcrumbs .breadcrumb-item+.breadcrumb-item::before {
    padding-left: 8px;
}

.rtl .breadcrumb-item+.breadcrumb-item::before {
    padding-left: 0.5rem;
}

.rtl .btn-group {
    border-radius: 5px !important;
}

.rtl .btn-group>.btn:not(:first-child),
.rtl .sp-container .btn-group>button:not(:first-child) {
    margin-right: -1px;
    margin-left: 0;
}

.rtl .btn-group>.btn-group:not(:first-child) {
    margin-right: -1px;
}

.rtl .btn-group>.btn:not(:first-child),
.rtl .note-editor.note-frame .btn-group>.btn-group:not(:first-child)>.btn {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.rtl .btn {
    border-radius: 5px !important;
}

.rtl .btn-group>.btn:not(:first-child) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.rtl .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.rtl .btn-group>.btn:not(:first-child):not(.dropdown-toggle) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.rtl .btn-rounded {
    border-radius: 50px !important;
}

.rtl .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.rtl .btn-sm+.dropdown-toggle-split,
.rtl .btn-group-sm>.btn+.dropdown-toggle-split,
.rtl .sp-container .btn-group-sm>button+.dropdown-toggle-split {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
}

.rtl .btn-lg+.dropdown-toggle-split,
.rtl .btn-group-lg>.btn+.dropdown-toggle-split,
.rtl .sp-container .btn-group-lg>button+.dropdown-toggle-split {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.rtl .btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .sp-container .btn-group-vertical>button:not(:last-child):not(.dropdown-toggle),
.rtl .btn-group-vertical>.btn-group:not(:last-child)>.btn,
.rtl .sp-container .btn-group-vertical>.btn-group:not(:last-child)>button {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .btn-group-vertical>.btn:not(:first-child),
.rtl .sp-container .btn-group-vertical>button:not(:first-child),
.rtl .btn-group-vertical>.btn-group:not(:first-child)>.btn,
.rtl .sp-container .btn-group-vertical>.btn-group:not(:first-child)>button {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.rtl .btn-with-icon i {
    margin-left: 5px;
}

.rtl .btn-icon-list .btn+.btn,
.rtl .btn-icon-list .sp-container button+.btn {
    margin-right: 5px;
}

.rtl .sp-container .btn-icon-list button+.btn,
.rtl .btn-icon-list .sp-container .btn+button,
.rtl .sp-container .btn-icon-list .btn+button,
.rtl .btn-icon-list .sp-container button+button,
.rtl .sp-container .btn-icon-list button+button {
    margin-right: 5px;
}

.rtl .btn-list>.btn:not(:last-child),
.rtl .btn-list>.dropdown:not(:last-child) {
    margin-left: 0.4rem;
}

.rtl .btn-animation .btn-loaders:after {
    left: 10px !important;
    right: inherit !important;
}

.rtl .btn-animation .btn-spiner:after {
    left: 12px !important;
    right: inherit !important;
}

.rtl .btn-animation .loading {
    margin-left: 30px;
}

.rtl .card>hr {
    margin-left: 0;
    margin-right: 0;
}

.rtl .card>.list-group:first-child .list-group-item:first-child {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.rtl .card>.list-group:last-child .list-group-item:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.rtl .card-link+.card-link {
    margin-right: 1.25rem;
}

.rtl .card-header-tabs {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
}

.rtl .card-header-pills {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
}

.rtl .card-img-overlay {
    left: 0;
    right: 0;
}

.rtl .card-img-top {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.rtl .card-img-bottom {
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
}

@media (min-width: 576px) {
    .rtl .card-deck {
        margin-left: -15px;
        margin-right: -15px;
    }

    .rtl .card-deck .card {
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media (min-width: 576px) {
    .rtl .card-group>.card+.card {
        margin-right: 0;
        border-right: 0;
    }

    .rtl .card-group>.card:not(:last-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .rtl .card-group>.card:not(:last-child) .card-img-top,
    .rtl .card-group>.card:not(:last-child) .card-header {
        border-top-left-radius: 0;
    }

    .rtl .card-group>.card:not(:last-child) .card-img-bottom,
    .rtl .card-group>.card:not(:last-child) .card-footer {
        border-bottom-left-radius: 0;
    }

    .rtl .card-group>.card:not(:first-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .rtl .card-group>.card:not(:first-child) .card-img-top,
    .rtl .card-group>.card:not(:first-child) .card-header {
        border-top-right-radius: 0;
    }

    .rtl .card-group>.card:not(:first-child) .card-img-bottom,
    .rtl .card-group>.card:not(:first-child) .card-footer {
        border-bottom-right-radius: 0;
    }
}

.rtl .card-header {
    border-top-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
}

.rtl .card-footer {
    border-bottom-right-radius: 7px !important;
    border-bottom-left-radius: 7px !important;
}

.rtl .card-category {
    margin: 0 0 0.5rem;
}

.rtl .card-minimal-four .card-footer .nav-link {
    padding-right: 10px;
    padding-left: 10px;
}

.rtl .card-img-top.card-img-bottom {
    border-bottom-right-radius: 9px;
    border-bottom-left-radius: 9px;
}

.rtl .square-box div:nth-child(1) {
    right: 42%;
}

.rtl .square-box div:nth-child(2) {
    right: 50%;
}

.rtl .square-box div:nth-child(4) {
    right: 60%;
}

.rtl .square-box div:nth-child(3) {
    right: 6%;
}

.rtl .square-box div:nth-child(5) {
    right: 10%;
}

.rtl .square-box div:nth-child(6) {
    right: 70%;
}

.rtl .square-box div:nth-child(7) {
    right: 80%;
}

.rtl .square-box div:nth-child(8) {
    right: 25%;
}

.rtl .square-box div:nth-child(9) {
    right: 80%;
}

.rtl .square-box div:nth-child(10) {
    right: 72%;
}

.rtl .square-box div:nth-child(11) {
    right: 30%;
}

.rtl .square-box div:nth-child(12) {
    right: 16%;
}

.rtl .square-box div:nth-child(13) {
    right: 3%;
}

.rtl .square-box div:nth-child(14) {
    right: 55%;
}

.rtl .square-box div:nth-child(15) {
    right: 90%;
}

.rtl .card-fullscreen {
    right: 0;
    left: 0;
}

.rtl .card-title:before {
    right: 11px;
}

.rtl .card-img-left {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

.rtl .custom-title:before {
    right: 11px;
}

.rtl .primary-custom-card:before {
    right: 0;
    left: 0;
}

.rtl .carousel-slider #thumbcarousel {
    margin: 10px 0 0 0;
}

.rtl #thumbcarousel .carousel-control-prev {
    right: 0;
}

.rtl #thumbcarousel .carousel-control-next {
    left: 0;
}

.rtl .owl-nav button:before {
    right: 0;
    left: 0;
}

.rtl .carousel-item {
    width: 100%;
    float: right;
    margin-left: -100%;
    margin-right: inherit;
}

.rtl .carousel-indicators {
    left: 0;
    right: 0;
    padding-right: 0;
    margin-left: 15%;
    margin-right: 15%;
}

.rtl .carousel-indicators li {
    margin-left: 3px;
    margin-right: 3px;
}

.rtl .carousel-caption {
    left: 15%;
    right: 15%;
}

.rtl dd {
    margin-right: 0;
}

.rtl blockquote {
    margin: 0 0 1rem;
}

.rtl figure {
    margin: 0 0 1rem;
}

.rtl caption {
    text-align: right;
}

.rtl .list-unstyled,
.rtl .list-inline {
    padding-right: 0;
}

.rtl .list-inline-item:not(:last-child) {
    margin-left: 0.5rem;
}

.rtl .container,
.rtl .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
}

.rtl .row {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
}

.rtl .no-gutters {
    margin-left: 0;
    margin-right: 0;
}

.rtl .no-gutters>.col,
.rtl .no-gutters>[class*=col-] {
    padding-left: 0;
    padding-right: 0;
}

.rtl .col-1,
.rtl .col-2,
.rtl .col-3,
.rtl .col-4,
.rtl .col-5,
.rtl .col-6,
.rtl .col-7,
.rtl .col-8,
.rtl .col-9,
.rtl .col-10,
.rtl .col-11,
.rtl .col-12,
.rtl .col,
.rtl .col-auto,
.rtl .col-sm-1,
.rtl .col-sm-2,
.rtl .col-sm-3,
.rtl .col-sm-4,
.rtl .col-sm-5,
.rtl .col-sm-6,
.rtl .col-sm-7,
.rtl .col-sm-8,
.rtl .col-sm-9,
.rtl .col-sm-10,
.rtl .col-sm-11,
.rtl .col-sm-12,
.rtl .col-sm,
.rtl .col-sm-auto,
.rtl .col-md-1,
.rtl .col-md-2,
.rtl .col-md-3,
.rtl .col-md-4,
.rtl .col-md-5,
.rtl .col-md-6,
.rtl .col-md-7,
.rtl .col-md-8,
.rtl .col-md-9,
.rtl .col-md-10,
.rtl .col-md-11,
.rtl .col-md-12,
.rtl .col-md,
.rtl .col-md-auto,
.rtl .col-lg-1,
.rtl .col-lg-2,
.rtl .col-lg-3,
.rtl .col-lg-4,
.rtl .col-lg-5,
.rtl .col-lg-6,
.rtl .col-lg-7,
.rtl .col-lg-8,
.rtl .col-lg-9,
.rtl .col-lg-10,
.rtl .col-lg-11,
.rtl .col-lg-12,
.rtl .col-lg,
.rtl .col-lg-auto,
.rtl .col-xl-1,
.rtl .col-xl-2,
.rtl .col-xl-3,
.rtl .col-xl-4,
.rtl .col-xl-5,
.rtl .col-xl-6,
.rtl .col-xl-7,
.rtl .col-xl-8,
.rtl .col-xl-9,
.rtl .col-xl-10,
.rtl .col-xl-11,
.rtl .col-xl-12,
.rtl .col-xl,
.rtl .col-xl-auto {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.rtl .offset-1 {
    margin-right: 8.33333%;
}

.rtl .offset-2 {
    margin-right: 16.66667%;
}

.rtl .offset-3 {
    margin-right: 25%;
}

.rtl .offset-4 {
    margin-right: 33.33333%;
}

.rtl .offset-5 {
    margin-right: 41.66667%;
}

.rtl .offset-6 {
    margin-right: 50%;
}

.rtl .offset-7 {
    margin-right: 58.33333%;
}

.rtl .offset-8 {
    margin-right: 66.66667%;
}

.rtl .offset-9 {
    margin-right: 75%;
}

.rtl .offset-10 {
    margin-right: 83.33333%;
}

.rtl .offset-11 {
    margin-right: 91.66667%;
}

@media (min-width: 576px) {
    .rtl .offset-sm-0 {
        margin-right: 0;
    }

    .rtl .offset-sm-1 {
        margin-right: 8.33333%;
    }

    .rtl .offset-sm-2 {
        margin-right: 16.66667%;
    }

    .rtl .offset-sm-3 {
        margin-right: 25%;
    }

    .rtl .offset-sm-4 {
        margin-right: 33.33333%;
    }

    .rtl .offset-sm-5 {
        margin-right: 41.66667%;
    }

    .rtl .offset-sm-6 {
        margin-right: 50%;
    }

    .rtl .offset-sm-7 {
        margin-right: 58.33333%;
    }

    .rtl .offset-sm-8 {
        margin-right: 66.66667%;
    }

    .rtl .offset-sm-9 {
        margin-right: 75%;
    }

    .rtl .offset-sm-10 {
        margin-right: 83.33333%;
    }

    .rtl .offset-sm-11 {
        margin-right: 91.66667%;
    }
}

@media (min-width: 768px) {
    .rtl .offset-md-0 {
        margin-right: 0;
    }

    .rtl .offset-md-1 {
        margin-right: 8.33333%;
    }

    .rtl .offset-md-2 {
        margin-right: 16.66667%;
    }

    .rtl .offset-md-3 {
        margin-right: 25%;
    }

    .rtl .offset-md-4 {
        margin-right: 33.33333%;
    }

    .rtl .offset-md-5 {
        margin-right: 41.66667%;
    }

    .rtl .offset-md-6 {
        margin-right: 50%;
    }

    .rtl .offset-md-7 {
        margin-right: 58.33333%;
    }

    .rtl .offset-md-8 {
        margin-right: 66.66667%;
    }

    .rtl .offset-md-9 {
        margin-right: 75%;
    }

    .rtl .offset-md-10 {
        margin-right: 83.33333%;
    }

    .rtl .offset-md-11 {
        margin-right: 91.66667%;
    }
}

@media (min-width: 992px) {
    .rtl .offset-lg-0 {
        margin-right: 0;
    }

    .rtl .offset-lg-1 {
        margin-right: 8.33333%;
    }

    .rtl .offset-lg-2 {
        margin-right: 16.66667%;
    }

    .rtl .offset-lg-3 {
        margin-right: 25%;
    }

    .rtl .offset-lg-4 {
        margin-right: 33.33333%;
    }

    .rtl .offset-lg-5 {
        margin-right: 41.66667%;
    }

    .rtl .offset-lg-6 {
        margin-right: 50%;
    }

    .rtl .offset-lg-7 {
        margin-right: 58.33333%;
    }

    .rtl .offset-lg-8 {
        margin-right: 66.66667%;
    }

    .rtl .offset-lg-9 {
        margin-right: 75%;
    }

    .rtl .offset-lg-10 {
        margin-right: 83.33333%;
    }

    .rtl .offset-lg-11 {
        margin-right: 91.66667%;
    }
}

@media (min-width: 1200px) {
    .rtl .offset-xl-0 {
        margin-right: 0;
    }

    .rtl .offset-xl-1 {
        margin-right: 8.33333%;
    }

    .rtl .offset-xl-2 {
        margin-right: 16.66667%;
    }

    .rtl .offset-xl-3 {
        margin-right: 25%;
    }

    .rtl .offset-xl-4 {
        margin-right: 33.33333%;
    }

    .rtl .offset-xl-5 {
        margin-right: 41.66667%;
    }

    .rtl .offset-xl-6 {
        margin-right: 50%;
    }

    .rtl .offset-xl-7 {
        margin-right: 58.33333%;
    }

    .rtl .offset-xl-8 {
        margin-right: 66.66667%;
    }

    .rtl .offset-xl-9 {
        margin-right: 75%;
    }

    .rtl .offset-xl-10 {
        margin-right: 83.33333%;
    }

    .rtl .offset-xl-11 {
        margin-right: 91.66667%;
    }
}

@media (min-width: 1400px) {
    .rtl .offset-xxl-0 {
        margin-right: 0;
    }

    .rtl .offset-xxl-1 {
        margin-right: 8.33333333%;
    }

    .rtl .offset-xxl-2 {
        margin-right: 16.66666667%;
    }

    .rtl .offset-xxl-3 {
        margin-right: 25%;
    }

    .rtl .offset-xxl-4 {
        margin-right: 33.33333333%;
    }

    .rtl .offset-xxl-5 {
        margin-right: 41.66666667%;
    }

    .rtl .offset-xxl-6 {
        margin-right: 50%;
    }

    .rtl .offset-xxl-7 {
        margin-right: 58.33333333%;
    }

    .rtl .offset-xxl-8 {
        margin-right: 66.66666667%;
    }

    .rtl .offset-xxl-9 {
        margin-right: 75%;
    }

    .rtl .offset-xxl-10 {
        margin-right: 83.33333333%;
    }

    .rtl .offset-xxl-11 {
        margin-right: 91.66666667%;
    }
}

.rtl .float-start {
    float: right !important;
}

.rtl .float-end {
    float: left !important;
}

@media (min-width: 576px) {
    .rtl .float-sm-left {
        float: right !important;
    }

    .rtl .float-sm-right {
        float: left !important;
    }

    .rtl .float-sm-none {
        float: none !important;
    }
}

@media (min-width: 768px) {
    .rtl .float-md-left {
        float: left !important;
    }

    .rtl .float-md-right {
        float: right !important;
    }

    .rtl .float-md-none {
        float: none !important;
    }
}

@media (min-width: 992px) {
    .rtl .float-lg-left {
        float: left !important;
    }

    .rtl .float-lg-right {
        float: right !important;
    }

    .rtl .float-lg-none {
        float: none !important;
    }
}

@media (min-width: 1200px) {
    .rtl .float-xl-left {
        float: left !important;
    }

    .rtl .float-xl-right {
        float: right !important;
    }

    .rtl .float-xl-none {
        float: none !important;
    }
}

.rtl .dropdown-toggle::after {
    margin-right: 0.255em;
    border-left: 0.3em solid transparent;
    border-right: 0.3em solid transparent;
}

.rtl .dropdown-toggle:empty::after {
    margin-right: 0;
}

.rtl .dropdown-menu-left {
    left: auto;
    right: 0;
}

.rtl .dropdown-menu-right {
    left: 0;
    right: auto;
}

@media (min-width: 576px) {
    .rtl .dropdown-menu-sm-left {
        left: auto;
        right: 0;
    }

    .rtl .dropdown-menu-sm-right {
        left: 0;
        right: auto;
    }
}

@media (min-width: 768px) {
    .rtl .dropdown-menu-md-left {
        left: auto;
        right: 0;
    }

    .rtl .dropdown-menu-md-right {
        left: 0;
        right: auto;
    }
}

@media (min-width: 992px) {
    .rtl .dropdown-menu-lg-left {
        left: auto;
        right: 0;
    }

    .rtl .dropdown-menu-lg-right {
        left: 0;
        right: auto;
    }
}

@media (min-width: 1200px) {
    .rtl .dropdown-menu-xl-left {
        left: auto;
        right: 0;
    }

    .rtl .dropdown-menu-xl-right {
        left: 0;
        right: auto;
    }
}

.rtl .dropup .dropdown-toggle::after {
    margin-right: 0.255em;
    border-left: 0.3em solid transparent;
    border-right: 0.3em solid transparent;
}

.rtl .dropup .dropdown-toggle:empty::after {
    margin-right: 0;
}

.rtl .dropright .dropdown-menu {
    left: auto;
    right: 100%;
    margin-right: 0.125rem;
}

.rtl .dropright .dropdown-toggle::after {
    margin-right: 0.255em;
    border-left: 0;
    border-right: 0.3em solid;
}

.rtl .dropright .dropdown-toggle:empty::after {
    margin-right: 0;
}

.rtl .dropleft .dropdown-menu {
    left: 100%;
    right: auto;
    margin-left: 0.125rem;
}

.rtl .dropleft .dropdown-toggle::after {
    margin-right: 0.255em;
}

.rtl .dropleft .dropdown-toggle::before {
    margin-left: 0.255em;
    border-left: 0.3em solid;
}

.rtl .dropleft .dropdown-toggle:empty::after {
    margin-right: 0;
}

.rtl .dropdown-menu[data-popper-placement^=top],
.rtl .dropdown-menu[data-popper-placement^=right],
.rtl .dropdown-menu[data-popper-placement^=bottom],
.rtl .dropdown-menu[data-popper-placement^=left] {
    left: auto;
}

.rtl .dropdown-toggle-split {
    padding-left: 0.5625rem;
    padding-right: 0.5625rem;
}

.rtl .dropdown-toggle-split::after {
    margin-right: 0;
}

.rtl .dropup .dropdown-toggle-split::after,
.rtl .dropright .dropdown-toggle-split::after {
    margin-right: 0;
}

.rtl .dropleft .dropdown-toggle-split::before {
    margin-left: 0;
}

.rtl .dropdown-header {
    padding-right: 15px;
    padding-left: 15px;
}

.rtl .user-lock .dropdown {
    left: 20px;
}

.rtl .form-control-plaintext.form-control-sm,
.rtl .form-control-plaintext.form-control-lg {
    padding-left: 0;
    padding-right: 0;
}

.rtl .form-row {
    margin-left: -5px;
    margin-right: -5px;
}

.rtl .form-row>.col,
.rtl .form-row>[class*=col-] {
    padding-left: 5px;
    padding-right: 5px;
}

.rtl .form-check {
    padding-right: 1.25rem;
}

.rtl .form-check-input {
    margin-right: -1.25rem;
}

.rtl .form-check-inline {
    padding-right: 0;
    margin-left: 0.75rem;
}

.rtl .form-check-inline .form-check-input {
    margin-left: 0.3125rem;
    margin-right: 0;
}

.rtl .was-validated textarea.form-control:valid {
    padding-left: calc(1.5em + 0.75rem);
    padding-right: 0.75rem;
}

.rtl textarea.form-control.is-valid,
.rtl textarea.form-control.parsley-success {
    padding-left: calc(1.5em + 0.75rem);
    padding-right: 0.75rem;
}

.rtl .was-validated .custom-select:valid,
.rtl .custom-select.is-valid,
.rtl input.custom-select.parsley-success,
.rtl textarea.custom-select.parsley-success {
    padding-left: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
}

.rtl .was-validated .form-control:invalid,
.rtl .form-control.is-invalid,
.rtl input.form-control.parsley-error,
.rtl textarea.form-control.parsley-error {
    padding-left: calc(1.5em + 0.75rem);
}

.rtl .was-validated textarea.form-control:invalid {
    padding-left: calc(1.5em + 0.75rem);
}

.rtl textarea.form-control.is-invalid,
.rtl textarea.form-control.parsley-error {
    padding-left: calc(1.5em + 0.75rem);
}

.rtl .was-validated .custom-select:invalid,
.rtl .custom-select.is-invalid,
.rtl input.custom-select.parsley-error,
.rtl textarea.custom-select.parsley-error {
    padding-left: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
}

@media (min-width: 576px) {
    .rtl .form-inline .form-check {
        padding-right: 0;
    }

    .rtl .form-inline .form-check-input {
        margin-left: 0.25rem;
        margin-right: 0;
    }
}

.rtl .main-header-center .form-control::-webkit-input-placeholder {
    padding-right: 0rem;
    padding-left: 0rem;
}

.rtl .main-header-center .form-control:-ms-input-placeholder {
    padding-right: 0rem;
    padding-left: 0rem;
}

.rtl .main-header-center .form-control::-ms-input-placeholder {
    padding-right: 0rem;
    padding-left: 0rem;
}

.rtl .main-header-center .form-control::placeholder {
    padding-right: 0rem;
    padding-left: 0rem;
}

.rtl .browse-file.form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .file-browser .btn-default {
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
}

.rtl .row-sm {
    margin-right: -10px;
    margin-left: -10px;
}

.rtl .row-sm>div {
    padding-right: 10px;
    padding-left: 10px;
}

.rtl .row-xs {
    margin-right: -5px;
    margin-left: -5px;
}

.rtl .row-xs>div {
    padding-right: 5px;
    padding-left: 5px;
}

@media (min-width: 576px) {
    .rtl .row-xs--sm {
        margin-right: -5px;
        margin-left: -5px;
    }

    .rtl .row-xs--sm>div {
        padding-right: 5px;
        padding-left: 5px;
    }

    .rtl .row-sm--sm {
        margin-right: -10px;
        margin-left: -10px;
    }

    .rtl .row-sm--sm>div {
        padding-right: 10px;
        padding-left: 10px;
    }

    .rtl .row--sm {
        margin-right: -15px;
        margin-left: -15px;
    }

    .rtl .row--sm>div {
        padding-right: 15px;
        padding-left: 15px;
    }
}

@media (min-width: 768px) {
    .rtl .row-xs--md {
        margin-right: -5px;
        margin-left: -5px;
    }

    .rtl .row-xs--md>div {
        padding-right: 5px;
        padding-left: 5px;
    }

    .rtl .row-sm--md {
        margin-right: -10px;
        margin-left: -10px;
    }

    .rtl .row-sm--md>div {
        padding-right: 10px;
        padding-left: 10px;
    }

    .rtl .row--md {
        margin-right: -15px;
        margin-left: -15px;
    }

    .rtl .row--md>div {
        padding-right: 15px;
        padding-left: 15px;
    }
}

@media (min-width: 992px) {
    .rtl .row-xs--lg {
        margin-right: -5px;
        margin-left: -5px;
    }

    .rtl .row-xs--lg>div {
        padding-right: 5px;
        padding-left: 5px;
    }

    .rtl .row-sm--lg {
        margin-right: -10px;
        margin-left: -10px;
    }

    .rtl .row-sm--lg>div {
        padding-right: 10px;
        padding-left: 10px;
    }

    .rtl .row--lg {
        margin-right: -15px;
        margin-left: -15px;
    }

    .rtl .row--lg>div {
        padding-right: 15px;
        padding-left: 15px;
    }
}

@media (min-width: 1200px) {
    .rtl .row-xs--xl {
        margin-right: -5px;
        margin-left: -5px;
    }

    .rtl .row-xs--xl>div {
        padding-right: 5px;
        padding-left: 5px;
    }

    .rtl .row-sm--xl {
        margin-right: -10px;
        margin-left: -10px;
    }

    .rtl .row-sm--xl>div {
        padding-right: 10px;
        padding-left: 10px;
    }

    .rtl .row--xl {
        margin-right: -15px;
        margin-left: -15px;
    }

    .rtl .row--xl>div {
        padding-right: 15px;
        padding-left: 15px;
    }
}

.rtl .input-group>.form-control+.form-control,
.rtl .input-group>.form-control+.custom-select,
.rtl .input-group>.form-control+.custom-file {
    margin-right: -1px;
}

.rtl .input-group>.form-control-plaintext+.form-control,
.rtl .input-group>.form-control-plaintext+.custom-select,
.rtl .input-group>.form-control-plaintext+.custom-file {
    margin-right: -1px;
}

.rtl .input-group>.custom-select+.form-control,
.rtl .input-group>.custom-select+.custom-select,
.rtl .input-group>.custom-select+.custom-file {
    margin-right: -1px;
}

.rtl .input-group>.custom-file+.form-control,
.rtl .input-group>.custom-file+.custom-select,
.rtl .input-group>.custom-file+.custom-file {
    margin-right: -1px;
}

.rtl .input-group>.form-control:not(:last-child),
.rtl .input-group>.custom-select:not(:last-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .input-group>.form-control:not(:first-child),
.rtl .input-group>.custom-select:not(:first-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .input-group>.custom-file:not(:last-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .input-group>.custom-file:not(:last-child) .custom-file-label::after {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .input-group>.custom-file:not(:first-child) .custom-file-label {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .input-group-prepend .btn+.btn,
.rtl .input-group-prepend .sp-container button+.btn {
    margin-right: -1px;
}

.rtl .sp-container .input-group-prepend button+.btn,
.rtl .input-group-prepend .sp-container .btn+button,
.rtl .sp-container .input-group-prepend .btn+button,
.rtl .input-group-prepend .sp-container button+button,
.rtl .sp-container .input-group-prepend button+button {
    margin-right: -1px;
}

.rtl .input-group-prepend .btn+.input-group-text,
.rtl .input-group-prepend .sp-container button+.input-group-text {
    margin-right: -1px;
}

.rtl .sp-container .input-group-prepend button+.input-group-text {
    margin-right: -1px;
}

.rtl .input-group-prepend .input-group-text+.input-group-text,
.rtl .input-group-prepend .input-group-text+.btn {
    margin-right: -1px;
}

.rtl .input-group-prepend .sp-container .input-group-text+button {
    margin-right: -1px;
}

.rtl .sp-container .input-group-prepend .input-group-text+button {
    margin-right: -1px;
}

.rtl .input-group-append .btn+.btn,
.rtl .input-group-append .sp-container button+.btn {
    margin-right: -1px;
}

.rtl .sp-container .input-group-append button+.btn,
.rtl .input-group-append .sp-container .btn+button,
.rtl .sp-container .input-group-append .btn+button,
.rtl .input-group-append .sp-container button+button,
.rtl .sp-container .input-group-append button+button {
    margin-right: -1px;
}

.rtl .input-group-append .btn+.input-group-text,
.rtl .input-group-append .sp-container button+.input-group-text {
    margin-right: -1px;
}

.rtl .sp-container .input-group-append button+.input-group-text {
    margin-right: -1px;
}

.rtl .input-group-append .input-group-text+.input-group-text,
.rtl .input-group-append .input-group-text+.btn {
    margin-right: -1px;
}

.rtl .input-group-append .sp-container .input-group-text+button {
    margin-right: -1px;
}

.rtl .sp-container .input-group-append .input-group-text+button {
    margin-right: -1px;
}

.rtl .input-group-prepend {
    margin-left: -1px;
}

.rtl .input-group-append {
    margin-right: -1px;
}

.rtl .input-group-sm>.form-control:not(textarea),
.rtl .input-group-sm>.custom-select {
    height: calc(1.5em + 0.5rem + 2px);
}

.rtl .input-group-lg>.custom-select,
.rtl .input-group-sm>.custom-select {
    padding-left: 1.75rem;
}

.rtl .input-group>.input-group-prepend>.btn,
.rtl .sp-container .input-group>.input-group-prepend>button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .input-group>.input-group-prepend>.input-group-text,
.rtl .input-group>.input-group-append:not(:last-child)>.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.rtl .input-group>.input-group-append>.input-group-text,
.rtl .input-group>.input-group-prepend:not(:first-child)>.btn {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.rtl .sp-container .input-group>.input-group-append:not(:last-child)>button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .input-group>.input-group-append:not(:last-child)>.input-group-text,
.rtl .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .sp-container .input-group>.input-group-append:last-child>button:not(:last-child):not(.dropdown-toggle) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .input-group>.input-group-append>.btn {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.rtl .sp-container .input-group>.input-group-append>button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .sp-container .input-group>.input-group-prepend:not(:first-child)>button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.rtl .input-group>.input-group-prepend:first-child>.btn:not(:first-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .sp-container .input-group>.input-group-prepend:first-child>button:not(:first-child),
.rtl .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .input-group>.input-group-append>.input-group-text,
.rtl .input-group>.input-group-prepend:not(:first-child)>.btn {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.rtl .modal {
    right: 0;
}

.rtl .modal-backdrop {
    right: 0;
}

.rtl .modal-header {
    border-top-right-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
}

.rtl .modal-footer {
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.rtl .modal-footer> :not(:first-child) {
    margin-right: 0.25rem;
}

.rtl #modaldemo8 .modal-footer> :not(:first-child) {
    margin-left: 14px;
}

.rtl .modal-footer> :not(:last-child) {
    margin-left: 0.25rem;
}

.rtl .modal.effect-just-me .modal-content {
    background-color: #000;
}

.rtl .modal.effect-just-me .modal-header {
    padding-right: 0;
    padding-left: 0;
}

.rtl .modal.effect-just-me .modal-body {
    padding-right: 0;
    padding-left: 0;
}

.rtl .modal.effect-just-me .modal-footer {
    padding-right: 0;
    padding-left: 0;
}

.rtl .nav-tabs .nav-link+.nav-link {
    margin-right: 1px;
}

.rtl .nav-link {
    padding: 0.2rem 0.2rem 0rem 0rem;
}

.rtl .nav-tabs .nav-link {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.rtl .nav-tabs .dropdown-menu {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

@media (min-width: 768px) {
    .rtl .main-nav .nav-link+.nav-link {
        padding-right: 15px;
        padding-left: inherit;
        margin-right: 15px;
        margin-left: inherit;
        border-right: 1px solid #ededf5;
        border-left: inherit;
    }
}

.rtl .main-nav-column .nav-link i {
    margin-left: 12px;
    margin-right: inherit;
}

.rtl .main-nav-column .nav-link span {
    margin-right: auto;
    margin-left: inherit;
}

.rtl .main-nav-column .nav-link.active::before {
    right: -28px;
}

.rtl .main-nav-tabs {
    padding: 15px 15px 0;
}

.rtl .main-nav-tabs .lSSlideOuter {
    padding-right: 32px;
    padding-left: 35px;
}

.rtl .main-nav-tabs .lSAction>a::before {
    right: 0;
    left: 0;
}

.rtl .main-nav-tabs .lSAction>a.lSPrev {
    right: -32px;
}

.rtl .main-nav-tabs .lSAction>a.lSNext {
    left: -35px;
}

.rtl .nav .nav-item .dropdown-menu {
    border-radius: 0px 5px 5px 0px;
}

.rtl .nav-link.with-sub .angle {
    left: 25px;
}

.rtl .nav-link.with-sub .side-badge {
    left: 44px;
}

.rtl .nav-link .side-badge {
    left: 20px;
}

.rtl .nav-sub-item .sub-angle {
    left: 25px;
    margin-right: auto;
}

@media (max-width: 320px) {
    .rtl .main-toggle.on span {
        right: 29px;
    }
}

@media (min-width: 768px) {
    .rtl .bg-primary .main-nav .nav-link+.nav-link {
        border-right: 1px solid #07b79c !important;
        border-left: inherit !important;
    }

    .rtl .bg-secondary .main-nav .nav-link+.nav-link {
        border-right: 1px solid #f52756 !important;
        border-left: inherit !important;
    }

    .rtl .bg-info .main-nav .nav-link+.nav-link {
        border-right: 1px solid #26b7f0 !important;
        border-left: inherit !important;
    }
}

.rtl .page-item+.page-item {
    margin-right: 1px;
}

.rtl .page-link {
    margin-right: 0;
}

.rtl .pagination-circled .page-item+.page-item {
    margin-right: 5px;
}

.rtl .pagination li a {
    margin-left: 4px !important;
    margin-right: inherit;
}

.rtl .pagination {
    padding-right: 0;
}

.rtl .page-link {
    margin-right: -1px;
}

.rtl .page-item:first-child .page-link {
    margin-right: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.rtl .page-item:last-child .page-link {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.rtl .pagination-lg .page-item:first-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.rtl .pagination-lg .page-item:last-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.rtl .pagination-sm .page-item:first-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
}

.rtl .pagination-sm .page-item:last-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
}

.rtl .page-link-outline {
    margin-right: 0;
}

.rtl .popover-header {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.rtl .popover-head-primary.bs-popover-start .popover-arrow,
.rtl .popover-head-primary.bs-popover-auto[data-popper-placement^=start] .popover-arrow {
    left: -7px;
}

.rtl .popover-head-primary.bs-popover-end .popover-arrow,
.rtl .popover-head-primary.bs-popover-auto[data-popper-placement^=end] .popover-arrow {
    right: -7px;
}

.rtl .popover-head-secondary.bs-popover-start .popover-arrow,
.rtl .popover-head-secondary.bs-popover-auto[data-popper-placement^=start] .popover-arrow {
    left: -7px;
}

.rtl .popover-head-secondary.bs-popover-end .popover-arrow,
.rtl .popover-head-secondary.bs-popover-auto[data-popper-placement^=end] .popover-arrow {
    right: -7px;
}

.rtl .popover-head-primary .popover-body,
.rtl .popover-head-secondary .popover-body {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

.rtl .popover-primary.bs-popover-start .popover-arrow::after,
.rtl .popover-primary.bs-popover-auto[data-popper-placement^=start] .popover-arrow::after {
    border-left-color: #38cab3;
}

.rtl .popover-primary.bs-popover-end .popover-arrow::after,
.rtl .popover-primary.bs-popover-auto[data-popper-placement^=end] .popover-arrow::after {
    border-right-color: #38cab3;
}

.rtl .popover-secondary .popover-header {
    padding: 0 0 15px;
}

.rtl .popover-secondary.bs-popover-start .popover-arrow::after,
.rtl .popover-secondary.bs-popover-auto[data-popper-placement^=start] .popover-arrow::after {
    border-left-color: #38cab3;
}

.rtl .popover-secondary.bs-popover-end .popover-arrow::after,
.rtl .popover-secondary.bs-popover-auto[data-popper-placement^=end] .popover-arrow::after {
    border-right-color: #38cab3;
}

.rtl .popover {
    right: 0;
    text-align: right;
    text-align: start;
}

.rtl .bs-popover-end,
.rtl .bs-popover-auto[data-popper-placement^=end] {
    margin-right: 0.5rem;
}

.rtl .bs-popover-end>.popover-arrow,
.rtl .bs-popover-auto[data-popper-placement^=end]>.popover-arrow {
    left: calc((0.5rem + 1px) * -1);
}

.rtl .bs-popover-end>.popover-arrow::before,
.rtl .bs-popover-auto[data-popper-placement^=end]>.popover-arrow::before {
    right: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #ededf5;
}

.rtl .bs-popover-end>.popover-arrow::after,
.rtl .bs-popover-auto[data-popper-placement^=end]>.popover-arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff;
}

.rtl .bs-popover-bottom .popover-header::before,
.rtl .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
    right: 50%;
    margin-right: -0.5rem;
}

.rtl .bs-popover-start,
.rtl .bs-popover-auto[data-popper-placement^=left] {
    margin-left: 0.5rem;
}

.rtl .bs-popover-start>.popover-arrow,
.rtl .bs-popover-auto[data-popper-placement^=start]>.popover-arrow {
    left: calc((0.5rem + 1px) * -1);
}

.rtl .bs-popover-start>.popover-arrow::before,
.rtl .bs-popover-auto[data-popper-placement^=start]>.popover-arrow::before {
    left: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #ededf5;
}

.rtl .bs-popover-start>.popover-arrow::after,
.rtl .bs-popover-auto[data-popper-placement^=start]>.popover-arrow::after {
    left: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff;
}

.rtl .popover-header {
    border-top-right-radius: calc(0.3rem - 1px);
    border-top-left-radius: calc(0.3rem - 1px);
}

.rtl .popover-static-demo .bs-popover-top .popover-arrow,
.rtl .popover-static-demo .bs-popover-auto[data-popper-placement^=top] .popover-arrow,
.rtl .popover-static-demo .bs-popover-bottom .popover-arrow,
.rtl .popover-static-demo .bs-popover-auto[data-popper-placement^=bottom] .popover-arrow {
    right: 50%;
    margin-right: -5px;
}

.rtl .table thead th,
.rtl .table thead td {
    padding: 10px 15px 10px;
}

.rtl .table.table-clean td {
    padding-right: 0px;
    padding-left: 0px;
}

@media (min-width: 768px) {
    .rtl .table-responsive.deleted-table .data-table-btn {
        right: 185px;
        left: inherit;
    }
}

@media (min-width: 1200px) {
    .rtl .table-responsive.export-table .dt-buttons.btn-group {
        right: 172px;
    }
}

.rtl .tooltip-primary.bs-tooltip-start .tooltip-arrow::before,
.rtl .tooltip-primary.bs-tooltip-auto[data-popper-placement^=start] .tooltip-arrow::before {
    border-left-color: #38cab3;
}

.rtl .tooltip-primary.bs-tooltip-end .tooltip-arrow::before,
.rtl .tooltip-primary.bs-tooltip-auto[data-popper-placement^=end] .tooltip-arrow::before {
    border-right-color: #38cab3;
}

.rtl .tooltip-primary1.bs-tooltip-start .tooltip-arrow::before,
.rtl .tooltip-primary1.bs-tooltip-auto[data-popper-placement^=start] .tooltip-arrow::before {
    border-left-color: #38cab3;
}

.rtl .tooltip-primary1.bs-tooltip-end .tooltip-arrow::before,
.rtl .tooltip-primary1.bs-tooltip-auto[data-popper-placement^=end] .tooltip-arrow::before {
    border-right-color: #38cab3;
}

.rtl .tooltip-secondary.bs-tooltip-start .tooltip-arrow::before,
.rtl .tooltip-secondary.bs-tooltip-auto[data-popper-placement^=start] .tooltip-arrow::before {
    border-left-color: #38cab3;
}

.rtl .tooltip-secondary.bs-tooltip-end .tooltip-arrow::before,
.rtl .tooltip-secondary.bs-tooltip-auto[data-popper-placement^=end] .tooltip-arrow::before {
    border-right-color: #38cab3;
}

.rtl .tooltip-secondary1.bs-tooltip-start .tooltip-arrow::before,
.rtl .tooltip-secondary1.bs-tooltip-auto[data-popper-placement^=start] .tooltip-arrow::before {
    border-left-color: #38cab3;
}

.rtl .tooltip-secondary1.bs-tooltip-end .tooltip-arrow::before,
.rtl .tooltip-secondary1.bs-tooltip-auto[data-popper-placement^=end] .tooltip-arrow::before {
    border-right-color: #38cab3;
}

.rtl .tooltip {
    text-align: right;
    text-align: start;
}

.rtl .bs-tooltip-top .tooltip-arrow::before,
.rtl .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
    border-width: 0.4rem 0.4rem 0;
}

.rtl .bs-tooltip-end .tooltip-arrow::before,
.rtl .bs-tooltip-auto[data-popper-placement^=start] .tooltip-arrow::before {
    left: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
}

.rtl .bs-tooltip-bottom .tooltip-arrow::before,
.rtl .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
    border-width: 0 0.4rem 0.4rem;
}

.rtl .bs-tooltip-start .tooltip-arrow,
.rtl .bs-tooltip-auto[data-popper-placement^=start] .tooltip-arrow {
    left: 0;
}

.rtl .bs-tooltip-start .tooltip-arrow::before,
.rtl .bs-tooltip-auto[data-popper-placement^=start] .tooltip-arrow::before {
    right: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000;
}

.rtl .tooltip-static-demo .bs-tooltip-top .tooltip-arrow,
.rtl .tooltip-static-demo .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow,
.rtl .tooltip-static-demo .bs-tooltip-bottom .tooltip-arrow,
.rtl .tooltip-static-demo .bs-tooltip-auto[data-popper-placement^=bottom] {
    right: 50%;
    margin-right: -2px;
}

.rtl .tooltip-static-demo .bs-tooltip-start,
.rtl .tooltip-static-demo .bs-tooltip-auto[data-popper-placement^=start],
.rtl .tooltip-static-demo .bs-tooltip-end,
.rtl .tooltip-static-demo .bs-tooltip-auto[data-popper-placement^=end] {
    margin-top: 5px;
}

.rtl .tooltip-static-demo .tooltip-primary1.bs-tooltip-start .tooltip-arrow,
.rtl .tooltip-static-demo .bs-tooltip-auto[data-popper-placement^=start] .tooltip-arrow,
.rtl .tooltip-static-demo .bs-tooltip-end .tooltip-arrow,
.rtl .tooltip-static-demo .bs-tooltip-auto[data-popper-placement^=end] .tooltip-arrow {
    margin-top: -6px;
}

.rtl .tooltip-static-demo .bs-tooltip-start .tooltip-arrow,
.rtl .tooltip-static-demo .bs-tooltip-auto[data-popper-placement^=start] .tooltip-arrow,
.rtl .tooltip-static-demo .bs-tooltip-end .tooltip-arrow,
.rtl .tooltip-static-demo .bs-tooltip-auto[data-popper-placement^=end] .tooltip-arrow {
    margin-top: -6px;
}

.rtl .tabs-style-1 .main-nav-line .nav-link {
    padding: 10px 18px 10px 18px;
}

.rtl .tabs-style-2 .main-nav-line .nav-link.active {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.rtl .tabs-style-2 .main-nav-line .nav-link {
    border-left: 0;
}

.rtl .tabs-style-2 .main-nav-line:first-child {
    border-right: 0;
}

.rtl .tabs-style-3 .nav.panel-tabs li a {
    padding: 10px 20px 10px 20px;
    margin: 0 0px 3px 3px;
}

.rtl .tabs-style-4 .nav.panel-tabs li a {
    padding: 10px 20px 10px 20px;
    margin: 0 0 16px 0;
}

.rtl .panel-primary.tabs-style-1 .main-nav-line .nav-link.active::before {
    right: 0;
    left: 0;
}

.rtl .tab_wrapper.right_side>ul {
    float: left;
    padding-right: 0;
}

.rtl .tab_wrapper.right_side>ul li {
    border-left: 0;
    border-right: 0;
    text-align: left;
}

.rtl .tab_wrapper.right_side>ul li:after {
    left: 0;
    right: inherit;
}

.rtl .tab_wrapper.right_side>ul li.active:after {
    left: 0;
    right: inherit;
}

.rtl .tab_wrapper.right_side>ul li.active:before {
    right: -2px;
    left: inherit;
}

.rtl .tab_wrapper.right_side .content_wrapper {
    float: right;
}

.rtl .tab_wrapper>ul li {
    float: right;
    margin: 0 0 0 5px;
}

.rtl .tab_wrapper>ul li.active:after {
    right: 0;
}

.rtl .tab_wrapper .controller {
    left: 0;
}

.rtl .tab_wrapper .controller span.next {
    float: left;
    margin-right: 10px;
}

.rtl .tab_wrapper .content_wrapper {
    float: right;
}

.rtl .tab_wrapper .content_wrapper .accordian_header.active:after {
    top: 0;
    right: 0;
}

.rtl .tab_wrapper .content_wrapper .accordian_header .arrow {
    float: left;
    border-right: 3px solid #cdd2e0;
    border-left: inherit;
}

.rtl .tab_wrapper.left_side>ul {
    float: right;
    padding-right: 0;
}

.rtl .tab_wrapper.left_side>ul li {
    border-left: 0;
    border-right: 0;
}

.rtl .tab_wrapper.left_side>ul li:after {
    right: 0;
}

.rtl .tab_wrapper.left_side>ul li.active:after {
    right: 0;
}

.rtl .tab_wrapper.left_side>ul li.active:before {
    left: -1px;
}

.rtl .tab_wrapper.left_side .content_wrapper {
    float: right;
}

.rtl .tab_wrapper.right_side>ul {
    float: left;
}

.rtl .tab_wrapper.right_side>ul li {
    border-left: 0;
    border-right: 0;
    text-align: left;
}

.rtl .tab_wrapper.right_side>ul li:after {
    left: 0;
    right: inherit;
}

.rtl .tab_wrapper.right_side>ul li.active:after {
    left: 0;
    right: inherit;
}

.rtl .tab_wrapper.right_side>ul li.active:before {
    right: -2px;
    left: inherit;
}

.rtl .tab_wrapper.right_side .content_wrapper {
    float: right;
}

.rtl .tab_wrapper .active_tab {
    padding-left: 50px;
}

.rtl .tab_wrapper .active_tab .arrow {
    left: 6px;
}

.rtl .tab_wrapper .active_tab .arrow:after {
    border-left: 3px solid #000;
    right: 50%;
    margin-right: -6px;
}

@media only screen and (max-width: 768px) {
    .rtl .tab_wrapper.show-as-dropdown>.tab_list:before {
        right: 50%;
        margin-right: -7px;
    }
}

.rtl .dark-theme .tab_wrapper .content_wrapper .accordian_header .arrow {
    border-right: 3px solid rgba(240, 242, 247, 0.4);
}

.rtl .tabs-menu1 ul li a {
    padding: 10px 20px 11px 20px;
}

.rtl .ckbox span {
    padding-right: 15px;
    padding-left: inherit;
}

.rtl .ckbox span:empty {
    float: right;
}

.rtl .ckbox span:before {
    right: 0;
}

.rtl .ckbox span:after {
    right: 0;
}

.rtl .ckbox span:empty {
    padding-right: 0;
    padding-left: inherit;
}

.rtl #count-down .clock-presenter {
    float: right;
    margin: 0 0px 0 20px;
}

.rtl #count-down .clock-presenter:before {
    right: 0;
    left: 0;
}

.rtl .custom-control {
    padding-right: 1.5rem;
}

.rtl .custom-control-inline {
    margin-left: 1rem;
}

.rtl .custom-control-label::before {
    right: -1.5rem;
    display: block;
}

.rtl .custom-control-label::after {
    right: -1.5rem;
}

.rtl .custom-switch {
    padding-right: 2.25rem;
    padding-left: inherit;
}

.rtl .custom-switch .custom-control-label::before {
    right: -2.25rem;
}

.rtl .custom-switch .custom-control-label::after {
    right: calc(-2.25rem + 2px);
}

.rtl .custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(51, 102, 255, 0.5);
}

.rtl .custom-select[multiple],
.rtl .custom-select[size]:not([size="1"]) {
    padding-left: 0.75rem;
}

.rtl .custom-select-sm {
    padding-right: 0.5rem;
}

.rtl .custom-select-lg {
    padding-right: 1rem;
}

.rtl .custom-file-label {
    left: 0;
    right: 0;
}

.rtl .custom-file-label::after {
    left: 0;
    border-right: inherit;
    border-radius: 0 0px 3px 3px;
}

.rtl .custom-range::-ms-thumb {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
}

.rtl .custom-range::-ms-fill-upper {
    margin-left: 15px;
}

.rtl .custom-control-label-lg::before {
    right: -l 0.5rem;
}

.rtl .custom-control-label-lg::after {
    right: -l 0.5rem;
}

.rtl .custom-control-label-md {
    padding-right: 8px;
}

.rtl .custom-control-label-lg {
    padding-right: 15px;
}

.rtl .custom-switch-indicator:before {
    right: 1px;
}

.rtl .custom-switch-input:checked~.custom-switch-indicator:before {
    right: calc(1rem + 1px);
}

.rtl .custom-switch-indicator-lg:before {
    right: 1px;
}

.rtl .custom-switch-input:checked~.custom-switch-indicator-lg:before {
    right: calc(1.46rem + 1px);
}

.rtl .custom-switch-indicator-xl:before {
    right: 1px;
}

.rtl .custom-switch-input:checked~.custom-switch-indicator-xl:before {
    right: calc(1.96rem + 1px) !important;
}

.rtl .item-card .cardprice-2 {
    left: 15px;
    right: inherit;
}

.rtl .p-text {
    margin-right: 12px;
    margin-left: 10px;
}

.rtl .example .demo-avatar-group .main-img-user::after {
    left: 3px;
}

.rtl .demo-avatar-group.main-avatar-list-stacked .main-img-user::after {
    left: 1px;
}

.rtl .main-img-user::after {
    left: 0px;
}

.rtl .main-avatar::after {
    left: 3px;
}

.rtl .avatar-md::after {
    left: 2px;
}

.rtl .avatar-lg::after {
    left: 4px;
}

.rtl .avatar-xl::after {
    left: 5px;
}

.rtl .avatar-xxl::after {
    left: 7px;
}

.rtl .demo-avatar-group .main-img-user+.main-img-user,
.rtl .demo-avatar-group .main-img-user+.main-avatar {
    margin-right: 5px;
}

.rtl .demo-avatar-group .main-avatar+.main-img-user,
.rtl .demo-avatar-group .main-avatar+.main-avatar {
    margin-right: 5px;
}

@media (min-width: 576px) {

    .rtl .demo-avatar-group .main-img-user+.main-img-user,
    .rtl .demo-avatar-group .main-img-user+.main-avatar {
        margin-right: 5px;
        margin-left: inherit;
    }

    .rtl .demo-avatar-group .main-avatar+.main-img-user,
    .rtl .demo-avatar-group .main-avatar+.main-avatar {
        margin-right: -5px;
    }

    .rtl .avatar-list .avatar:not(:last-child) {
        margin-left: 5px !important;
    }
}

.rtl .wrapper.image-group .img-xs.rounded-circle {
    margin-left: -5px;
}

.rtl .avatar-list .avatar:not(:last-child) {
    margin-left: 0.5rem;
}

.rtl .main-img-user1.avatar-sm .number-badge {
    left: -7px;
}

.rtl .main-img-user1.avatar-md .number-badge {
    left: -7px;
}

.rtl .main-img-user1.avatar-lg .number-badge {
    left: -7px;
}

.rtl .main-img-user3.avatar-sm .icon-badgeavatar {
    left: -4px;
}

.rtl .main-img-user3.avatar-md .icon-badgeavatar {
    left: -4px;
}

.rtl .main-img-user3.avatar-lg .icon-badgeavatar {
    left: -4px;
}

.rtl .avatar-list {
    margin: 0 0 -0.5rem;
}

.rtl .avatar-list-stacked .avatar1 {
    margin-left: -0.8em !important;
}

.rtl .main-list-item>div:last-child {
    text-align: left;
}

.rtl .list-group {
    padding-right: 0;
    padding-left: 0;
}

.rtl .list-group-item:first-child {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.rtl .list-group-item:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.rtl .list-group-horizontal .list-group-item {
    margin-left: -1px;
}

.rtl .list-group-horizontal .list-group-item:first-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 0;
}

.rtl .list-group-horizontal .list-group-item:last-child {
    margin-left: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 0;
}

@media (min-width: 576px) {
    .rtl .list-group-horizontal-sm .list-group-item {
        margin-left: -1px;
    }

    .rtl .list-group-horizontal-sm .list-group-item:first-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-left-radius: 0;
    }

    .rtl .list-group-horizontal-sm .list-group-item:last-child {
        margin-left: 0;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 0;
    }
}

@media (min-width: 768px) {
    .rtl .list-group-horizontal-md .list-group-item {
        margin-left: -1px;
    }

    .rtl .list-group-horizontal-md .list-group-item:first-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-left-radius: 0;
    }

    .rtl .list-group-horizontal-md .list-group-item:last-child {
        margin-left: 0;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 0;
    }
}

@media (min-width: 992px) {
    .rtl .list-group-horizontal-lg .list-group-item {
        margin-left: -1px;
    }

    .rtl .list-group-horizontal-lg .list-group-item:first-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-left-radius: 0;
    }

    .rtl .list-group-horizontal-lg .list-group-item:last-child {
        margin-left: 0;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 0;
    }
}

@media (min-width: 1200px) {
    .rtl .list-group-horizontal-xl .list-group-item {
        margin-left: -1px;
    }

    .rtl .list-group-horizontal-xl .list-group-item:first-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-left-radius: 0;
    }

    .rtl .list-group-horizontal-xl .list-group-item:last-child {
        margin-left: 0;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 0;
    }
}

.rtl .list-group-flush .list-group-item {
    border-left: 0;
    border-right: 0;
}

.rtl .listgroup-example ul ul {
    margin: 10px 0 0 0;
}

.rtl .listgroup-example2 ul ul {
    margin: 10px 0 0 0;
}

.rtl .list-style-1 ul {
    padding-right: 30px;
}

.rtl .list-style2 ul {
    padding-right: 30px;
}

.rtl .list-style3 {
    padding-right: inherit;
}

.rtl .list-style3 ul {
    padding-right: 30px;
}

.rtl .list-style4 {
    padding-right: inherit;
}

.rtl .list-style4 ul {
    padding-right: 30px;
}

.rtl .list-style5 {
    padding-right: inherit;
}

.rtl .list-style5 ul {
    padding-right: 30px;
}

.rtl .list-style6 {
    padding-right: inherit;
}

.rtl .list-style6 ul {
    padding-right: 30px;
}

.rtl .paragraph-list {
    padding-right: 20px;
}

.rtl .main-navbar-backdrop {
    right: 0;
    left: 0;
}

.rtl .main-navbar {
    right: 0;
}

.rtl .main-navbar .nav-link i {
    margin-left: 10px;
}

.rtl .main-navbar .nav-link.with-sub::after {
    margin-right: auto;
}

.rtl .main-navbar .nav-link.with-sub::before {
    right: 50%;
    margin-right: -7px;
}

.rtl .main-navbar .nav-sub-item {
    margin-right: 30px;
}

.rtl .main-navbar .nav-sub-item>.nav-sub {
    right: 176px;
}

.rtl .main-navbar .nav-sub-link.with-sub::after {
    margin-right: auto;
}

.rtl .main-navbar .nav-sub-mega {
    right: 0;
    left: 0;
}

.rtl .main-navbar .nav-sub-mega .nav li:not(.nav-sub-item) {
    margin-right: 30px;
}

@media (min-width: 992px) {
    .rtl .main-navbar {
        right: auto;
    }
}

@media (min-width: 992px) {
    .rtl .main-navbar>.container-fluid {
        padding-right: 20px;
        padding-left: 20px;
    }
}

@media (min-width: 992px) {
    .rtl .main-navbar .nav-item+.nav-item {
        margin-right: 15px;
        padding-right: 15px;
        border-right: 1px dotted #f0f0f8;
    }
}

@media (min-width: 1200px) {
    .rtl .main-navbar .nav-item+.nav-item {
        margin-right: 20px;
        padding-right: 20px;
    }
}

@media (min-width: 992px) {
    .rtl .main-navbar .nav-link i {
        margin-left: 8px;
    }
}

@media (min-width: 992px) {
    .rtl .main-navbar .nav-link.with-sub::after {
        margin-right: 4px;
    }
}

@media (min-width: 992px) {
    .rtl .main-navbar .nav-sub {
        right: 0;
    }
}

@media (min-width: 992px) {
    .rtl .main-navbar .nav-sub-item {
        margin-right: 0;
    }
}

@media (max-width: 991.98px) {
    .rtl .main-navbar .nav-sub-item>.nav-sub>.nav-sub-item {
        margin-right: 37px;
    }

    .rtl .main-navbar .nav-sub-item>.nav-sub>.nav-sub-item>.nav-sub-link {
        padding-right: 10px;
    }
}

@media (min-width: 992px) {
    .rtl .main-navbar .nav-sub-link {
        font-size: 0.8125rem;
        height: 36px;
    }
}

@media (min-width: 992px) {
    .rtl .main-navbar .nav-sub-link.with-sub::after {
        margin-right: 4px;
    }
}

@media (min-width: 992px) {

    .rtl .main-navbar .nav-sub-mega .container>div+div,
    .rtl .main-navbar .nav-sub-mega .container-fluid>div+div {
        border-right: 1px solid rgba(28, 39, 60, 0.05);
    }
}

@media (min-width: 992px) {
    .rtl .main-navbar .nav-sub-mega .nav+.nav {
        margin-right: 20px;
    }
}

@media (min-width: 1200px) {
    .rtl .main-navbar .nav-sub-mega .nav+.nav {
        margin-right: 25px;
    }
}

@media (min-width: 992px) {
    .rtl .main-navbar .nav-sub-mega .nav li:not(.nav-sub-item) {
        margin-right: 0;
    }
}

.rtl .main-navbar-search .btn,
.rtl .main-navbar-search .sp-container button {
    left: 0;
    padding-right: 20px;
    padding-left: 20px;
}

.rtl .sp-container .main-navbar-search button {
    left: 0;
    padding-right: 20px;
    padding-left: 20px;
}

.rtl .main-navbar-two .nav-sub-item>.nav-sub {
    right: 178px;
}

@media (min-width: 992px) {
    .rtl .main-navbar-two .nav-item.active::before {
        right: 0;
        left: 12px;
    }
}

.rtl .main-navbar-three .nav-item+.nav-item {
    border-left-width: 0;
    padding-right: 0;
}

.rtl .main-navbar-three .nav-sub-item .nav-sub {
    right: 182px;
}

@media (min-width: 992px) {
    .rtl .main-navbar-three .nav-item+.nav-item {
        margin-right: 25px;
    }
}

.rtl .navbar-nav {
    padding-right: 0;
}

.rtl .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0;
}

@media (max-width: 575.98px) {

    .rtl .navbar-expand-sm>.container,
    .rtl .navbar-expand-sm>.container-fluid {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (min-width: 576px) {
    .rtl .navbar-expand-sm .navbar-nav .nav-link {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

@media (max-width: 767.98px) {

    .rtl .navbar-expand-md>.container,
    .rtl .navbar-expand-md>.container-fluid {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (min-width: 768px) {
    .rtl .navbar-expand-md .navbar-nav .nav-link {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

@media (max-width: 991.98px) {

    .rtl .navbar-expand-lg>.container,
    .rtl .navbar-expand-lg>.container-fluid {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (min-width: 992px) {
    .rtl .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

@media (max-width: 1199.98px) {

    .rtl .navbar-expand-xl>.container,
    .rtl .navbar-expand-xl>.container-fluid {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (min-width: 1200px) {
    .rtl .navbar-expand-xl .navbar-nav .nav-link {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

.rtl .navbar-expand>.container,
.rtl .navbar-expand>.container-fluid {
    padding-left: 0;
    padding-right: 0;
}

.rtl .navbar-expand .navbar-nav .nav-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.rtl .navbar-form.active .input-group-btn {
    left: 12px;
    right: inherit;
}

.rtl #verticallpill .nav-pills .nav-link {
    padding: 0.75rem 0.75rem 0.75rem 0rem;
}

.rtl .ps>.ps__rail-y {
    right: auto !important;
    left: 0;
}

.rtl .ps>.ps__rail-y>.ps__thumb-y {
    right: 4px;
}

.rtl .rdiobox span {
    padding-right: 4px;
}

.rtl .rdiobox span:before {
    right: 0;
}

.rtl .rdiobox span:after {
    right: 5px;
}

.rtl .rdiobox span:empty {
    padding-right: 0;
}

.rtl .form-group-rdiobox .rdiobox+.rdiobox {
    margin-right: 30px;
}

.rtl .form-group-rdiobox .rdiobox span {
    padding-right: 0;
}

.rtl .tag-addon {
    margin: 0 0.5rem 0 -0.4rem;
}

.rtl .tag-avatar {
    border-radius: 3px 3px 0 0px;
    margin: 0 -0.5rem 0 0.5rem;
}

.rtl .tags>.tag:not(:last-child) {
    margin-left: 0.2rem;
}

.rtl .vtimeline:before {
    right: 50%;
    margin-right: -1.5px;
}

.rtl .vtimeline .timeline-wrapper .timeline-panel {
    margin-right: 15%;
    margin-left: inherit;
}

.rtl .vtimeline .timeline-wrapper .timeline-panel:before {
    right: 0;
    left: 0;
}

.rtl .vtimeline .timeline-wrapper .timeline-panel:after {
    left: -14px;
    right: inherit;
    border-right: 14px solid #f9f9fb;
    border-left: 0 solid #f9f9fb;
}

.rtl .vtimeline .timeline-wrapper .timeline-panel .timeline-title {
    margin: 0 0 0.625rem;
}

.rtl .vtimeline .timeline-wrapper .timeline-badge {
    right: calc(50% - 20px);
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
}

.rtl .vtimeline .timeline-wrapper .timeline-badge i {
    right: 0;
    left: 0;
}

.rtl .vtimeline .timeline-wrapper.timeline-inverted {
    padding-left: 0;
    padding-right: 90px;
}

.rtl .vtimeline .timeline-wrapper.timeline-inverted .timeline-panel {
    margin-right: auto;
    margin-left: 15%;
}

.rtl .vtimeline .timeline-wrapper.timeline-inverted .timeline-panel:after {
    border-left-width: 14px;
    border-right-width: 0px;
    right: -14px;
    left: auto;
}

@media (max-width: 992px) {
    .rtl .vtimeline:before {
        right: 26px !important;
    }

    .rtl .vtimeline .timeline-wrapper {
        padding-left: 0 !important;
    }

    .rtl .vtimeline .timeline-wrapper .timeline-badge {
        right: 0 !important;
    }

    .rtl .vtimeline .timeline-wrapper .timeline-panel {
        margin-right: 16% !important;
    }

    .rtl .vtimeline .timeline-wrapper .timeline-panel:after {
        border-left: 14px solid #f9f9fb !important;
        border-right: 0 solid #f9f9fb !important;
        right: -14px !important;
        left: auto !important;
    }

    .rtl .vtimeline .timeline-wrapper.timeline-inverted {
        padding-right: 0 !important;
    }
}

.rtl .latest-timeline-1 ul.timeline-1:before {
    right: 20px;
    border-right: 2px dotted #c0ccda;
}

.rtl .latest-timeline-1 ul.timeline-1>li {
    padding-right: 0.9rem;
}

.rtl .latest-timeline-1 ul.timeline-1>li .product-icon {
    right: 1px;
}

.rtl .latest-tasks .tasks {
    margin: 0 0 1.15rem 0;
}

.rtl .latest-tasks .tasks .span {
    margin-right: 1.3rem;
}

.rtl .latest-tasks .label {
    margin-right: 1.3rem;
}

.rtl .latest-tasks .time {
    margin-right: 1.3rem;
}

.rtl .latest-tasks .check-box .ckbox span:before {
    right: 0;
}

.rtl .image-grouped img {
    margin-left: -0.6em !important;
}

@media (min-width: 992px) {
    .rtl .project-countdown .countdown li {
        margin: 2px 5px 7px 5px;
    }
}

.rtl .activity {
    padding: 1.25rem 1.25rem 0 1.25rem;
}

.rtl .activity-list:before {
    margin: 20px 22px 0 20px;
}

.rtl .activity .item-activity {
    margin-right: 60px;
}

@media (max-width: 576px) {
    .rtl .vtimeline .timeline-wrapper .timeline-panel {
        margin-right: 24% !important;
    }
}

.rtl .main-toggle span {
    right: 3px;
    left: inherit;
}

.rtl .main-toggle span::before {
    right: -25px;
    left: inherit;
}

.rtl .main-toggle span::after {
    right: 24px;
}

.rtl .main-toggle.on span {
    right: 37px;
    left: inherit;
}

.rtl .main-toggle-group-demo .main-toggle+.main-toggle {
    margin-right: 10px;
    margin-left: inherit;
}

.rtl #global-loader {
    right: 0;
    left: 0;
}

.rtl .loader-img {
    left: 0;
    right: 0;
}

.rtl .close {
    float: left;
}

@media (max-width: 767px) {
    .rtl .close {
        left: 10px;
        right: inherit;
    }
}

.rtl .spinner-border {
    border-right-color: transparent;
}

.rtl .embed-responsive .embed-responsive-item,
.rtl .embed-responsive iframe,
.rtl .embed-responsive embed,
.rtl .embed-responsive object,
.rtl .embed-responsive video {
    right: 0;
    left: inherit;
}

.rtl .fixed-top {
    left: 0;
    right: 0;
}

.rtl .fixed-bottom {
    left: 0;
    right: 0;
}

.rtl .stretched-link::after {
    left: 0;
    right: 0;
}

.rtl .main-body::before {
    right: 70%;
}

.rtl .main-home-slider .chart-wrapper {
    right: 0;
    left: 0;
}

.rtl .main-home-slider .chart-wrapper .flot-chart {
    width: 100%;
    height: 400px;
}

.rtl .main-home-slider .card-columns {
    right: 24%;
}

@media (min-width: 576px) {
    .rtl .main-home-slider .card-columns {
        right: 16%;
    }
}

@media (min-width: 768px) {
    .rtl .main-home-slider .card-columns {
        right: auto;
        left: -45%;
    }
}

@media (min-width: 992px) {
    .rtl .main-home-slider .card-columns {
        left: -30%;
    }
}

@media (min-width: 1200px) {
    .rtl .main-home-slider .card-columns {
        left: -15%;
    }
}

@media (min-width: 1300px) {
    .rtl .main-home-slider .card-columns {
        left: -5%;
    }
}

.rtl .main-home-content {
    right: 20px;
    left: 20px;
}

.rtl .main-home-content .logo-group i+i {
    margin-right: 10px;
}

@media (min-width: 576px) {
    .rtl .main-home-content {
        right: 40px;
        left: auto;
    }
}

.rtl .main-home-content p:last-of-type .btn+.btn,
.rtl .main-home-content p:last-of-type .sp-container button+.btn {
    margin-right: 5px;
}

.rtl .sp-container .main-home-content p:last-of-type button+.btn,
.rtl .main-home-content p:last-of-type .sp-container .btn+button,
.rtl .sp-container .main-home-content p:last-of-type .btn+button,
.rtl .main-home-content p:last-of-type .sp-container button+button,
.rtl .sp-container .main-home-content p:last-of-type button+button {
    margin-right: 5px;
}

.rtl .main-home-content nav:last-child a+a {
    margin-right: 8px;
}

.rtl .main-page-content-list li i {
    margin-left: 10px;
}

.rtl .main-syntax-wrapper .badge {
    left: 5px;
}

.rtl .horizontal-nav .mega-dropdown-menu:before {
    border-width: 0 10px 10px 10px;
    left: auto;
    right: 16px;
}

.rtl .mega-dropdown-menu:after {
    left: auto;
    right: 16px;
}

.rtl .drop-icon-wrap .drop-icon-item {
    float: right;
}

.rtl .flag>a::before {
    right: 50%;
    margin-right: -7px;
}

.rtl .dropdown-toggle-1::after {
    left: 9px;
    border-left: 0;
    border-right: 0;
}

.rtl .dropdown-toggle-2::after {
    left: 0px;
    border-left: 0;
    border-right: 0;
}

.rtl .mega-dropdown-menu {
    padding: 0.7rem 0.8rem 0.5rem 0.8rem;
}

.rtl .btn-absolute {
    right: 0;
    left: 0;
}

.rtl .arrow-ribbon {
    right: 0px;
}

.rtl .arrow-ribbon:before {
    left: 0;
    left: -15px;
    border-right: 15px solid #000;
}

.rtl .mega-menu:before {
    right: 14px;
}

.rtl .mega-menu:after {
    right: 14px;
}

.rtl .flag-text:before {
    left: 0;
}

.rtl .drop-flag {
    margin-left: 2.3rem !important;
}

.rtl .drop-flag .dropdown-menu {
    right: auto;
    left: -10px;
    padding: 10px 10px 10px;
}

.rtl .header-img {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 20px 0 20px 0;
}

.rtl .dropdown-item .content {
    margin-right: 15px;
}

.rtl .avatar-status {
    left: 5px;
}

.rtl .user-pro-body .profile-status {
    left: 18px;
}

.rtl .user-pro-body .profile-status:before {
    left: -6px;
}

.rtl .pulse {
    left: 8px;
}

.rtl .pulse:before {
    left: -4px;
}

.rtl .pulse-danger {
    left: 5px;
}

.rtl .pulse-danger:before {
    left: -4px;
}

.rtl .main-message-list a .desc {
    margin-right: 10px;
}

.rtl .main-message-list a .name {
    margin-right: 10px;
}

.rtl .notifyimg i {
    float: right;
}

.rtl .nav-badge {
    margin-right: auto !important;
    left: 12px;
}

.rtl .nav-text-badge {
    margin-right: auto !important;
    left: 7px;
}

.rtl .line-list {
    padding-right: 30px;
}

.rtl .line-list li:before {
    right: 0;
    margin-left: 15px;
}

.rtl .line-list li:after {
    right: 0;
}

.rtl .table-dashboard-one td:first-child {
    padding-right: 0;
}

.rtl .sales-card .sales-icon {
    padding: 40px 20px 20px 10px;
    left: -30px;
}

.rtl .aligner-wrapper .absolute.absolute-center {
    left: 0;
    right: 0;
}

.rtl .sales-dash-header {
    right: 20px;
    left: 20px;
}

.rtl .sales-session {
    margin-right: 0;
    padding-right: 0;
}

.rtl .product-timeline ul.timeline-1:before {
    margin: 20px 22px 0 20px;
    right: -2px;
}

.rtl .product-timeline ul.timeline-1>li {
    padding-right: 1rem;
}

.rtl .product-timeline ul.timeline-1>li .product-icon {
    right: 3px;
}

.rtl .order-list .list {
    padding-right: 0;
}

.rtl .order-list .list .list-item {
    padding: 0 0 19px 0;
}

.rtl .top-selling-product .table th,
.rtl .top-selling-product .table td {
    padding: 7px 13px 7px 7px !important;
}

.rtl .out-of-stock:before {
    right: 0;
}

.rtl .semi-circle {
    border-radius: 0 300px 300px 0px;
}

.rtl .project-card i {
    margin: 0 -30px 0 24px;
}

.rtl .project-card .project-content ul span {
    float: left;
    text-align: left;
    margin-right: auto;
}

.rtl .crypto .card-footer .nav-link.active::before {
    right: 0;
    left: 0;
}

.rtl .crypto .nav-link+.nav-link {
    border-right: 1px solid #ededf5;
}

.rtl .crypto .card-footer .nav-link {
    padding-right: 10px;
    padding-left: 10px;
}

.rtl .crypto .coin-logo {
    margin-left: 15px;
}

.rtl .project-list .list-unstyled li {
    border-right: 1px solid #000;
}

.rtl .project-list .list-unstyled li .media-body {
    margin-right: 13px;
}

.rtl .pricing-card .list-unstyled {
    padding-right: 0;
}

.rtl .pricing-card .display-5 {
    padding: 25px 0 0 !important;
}

.rtl .panel.price>.panel-heading {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.rtl .panel.price .list-group-item:last-child {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.rtl .panel.price .list-group-item:first-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.rtl .price .panel-footer {
    border-right: 0;
    border-left: 0;
}

.rtl .panel-footer {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.rtl .demo-gallery>ul>li a {
    float: right;
}

.rtl .demo-gallery>ul>li a .demo-gallery-poster {
    right: 0;
    left: 0;
}

.rtl .demo-gallery>ul>li a .demo-gallery-poster>img {
    right: 50%;
    margin-right: -10px;
}

.rtl .demo-gallery .justified-gallery>a .demo-gallery-poster {
    right: 0;
    left: 0;
}

.rtl .demo-gallery .justified-gallery>a .demo-gallery-poster>img {
    right: 50%;
    margin-right: -10px;
}

.rtl .preview-thumbnail.nav-tabs li {
    margin-left: 2.5%;
}

.rtl .preview-thumbnail.nav-tabs li:last-of-type {
    margin-left: 0;
}

.rtl .size {
    margin-left: 10px;
}

.rtl .size:first-of-type {
    margin-right: 40px;
}

.rtl .colorinput-color:before {
    right: 0.25rem;
}

.rtl .feature2 i {
    margin: 0 0 15px 0;
}

.rtl .item-card .cardprice {
    left: 15px;
    right: inherit;
}

.rtl .icons-list {
    margin: 0 0px -1px -1px;
}

.rtl .icons-list-item .bs-tooltip-start {
    padding: 10px 0.4rem;
}

.rtl .highlight {
    margin: 0 0 2rem;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    border-top-left-radius: 8px;
}

.rtl pre {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 7px;
}

.rtl .clipboard-icon {
    left: 10px;
}

@media (max-width: 568px) {
    .rtl .main-nav .nav-link+.nav-link {
        border-right: 0px !important;
    }
}

@media (max-width: 768px) {
    .rtl .responsive-logo .header-logo {
        right: 0;
        left: 0;
    }
}

.rtl .sub-menu li a:before {
    margin-left: 8px;
    right: 8px;
}

.rtl .mega-menubg.hor-mega-menu li a:before {
    right: 10px;
}

.rtl .qunatity-list {
    margin-right: 0;
    padding-right: 6px;
}

.rtl .chips .chip {
    margin: 0 0 0.5rem 0.5rem;
}

.rtl .chip .avatar {
    float: right;
    margin: 0 -0.75rem 0 0.5rem;
}

.rtl .team i {
    margin-right: 10px;
    float: left;
}

.rtl .feature .project {
    right: 0;
    left: 0;
}

.rtl #back-to-top {
    left: 20px !important;
    right: inherit !important;
}

.rtl .email-button {
    left: 20px;
    right: inherit;
}

.rtl .main-dashboard-date .date>div:last-child {
    margin-right: 5px;
    margin-right: inherit;
}

.rtl .main-dashboard-nav .nav-link+.nav-link {
    border-right: 1px solid #ededf5;
}

.rtl .main-dashboard-nav .nav:first-child .nav-link:first-child {
    padding-right: 0;
}

.rtl .main-dashboard-nav .nav:last-child .nav-link i {
    margin-left: 7px;
    margin-right: inherit;
}

.rtl .main-dashboard-nav .nav:last-child .nav-link:last-child {
    padding-left: 0;
}

.rtl .main-dashboard-nav .nav:last-child .nav-link:last-child i {
    margin-left: 0;
    margin-right: inherit;
}

@media (min-width: 992px) {
    .rtl .main-dashboard-nav .nav-link {
        padding: 5px 15px 10px;
    }
}

.rtl .card-dashboard-one .card-header .btn-group .btn+.btn,
.rtl .card-dashboard-one .card-header .btn-group .sp-container button+.btn {
    margin-right: -1px;
    margin-left: inherit;
}

.rtl .sp-container .card-dashboard-one .card-header .btn-group button+.btn,
.rtl .card-dashboard-one .card-header .btn-group .sp-container .btn+button,
.rtl .sp-container .card-dashboard-one .card-header .btn-group .btn+button,
.rtl .card-dashboard-one .card-header .btn-group .sp-container button+button,
.rtl .sp-container .card-dashboard-one .card-header .btn-group button+button {
    margin-right: -1px;
}

.rtl .card-dashboard-one .card-body {
    padding: 10px 0 20px;
}

.rtl .card-dashboard-one .card-body .flot-chart-wrapper {
    margin-right: -28px;
    margin-left: -20px;
}

@media (min-width: 576px) {
    .rtl .card-dashboard-one .card-body-top {
        right: 20px;
        padding-right: 0;
    }
}

@media (min-width: 576px) {
    .rtl .card-dashboard-one .card-body-top>div+div {
        margin-right: 30px;
    }
}

.rtl .card-dashboard-two .card-header {
    padding: 20px 20px 0;
}

.rtl .card-dashboard-two .card-header h6 i {
    margin-right: 10px;
}

.rtl .card-dashboard-two .card-header h6 small {
    margin-right: 5px;
}

.rtl .card-dashboard-two .chart-wrapper {
    margin: 0 -10px -10px;
}

.rtl .card-dashboard-three .card-header h6 small {
    margin-right: 5px;
}

.rtl .card-dashboard-three .card-body {
    padding: 0 20px 0 0px;
}

.rtl .card-dashboard-pageviews .card-header {
    padding: 0 0 10px;
}

.rtl .card-dashboard-four .card-header {
    padding: 0 0 20px;
}

.rtl .card-dashboard-five .card-body {
    padding: 20px 0 5px;
}

@media (min-width: 576px) {
    .rtl .card-dashboard-five .card-chart {
        margin-left: 10px;
    }
}

.rtl .card-table-one {
    padding: 20px 20px 10px;
}

.rtl .card-table-one .table thead tr>th,
.rtl .card-table-one .table thead tr>td {
    padding-left: 2px;
}

.rtl .card-table-one .table tbody tr>th,
.rtl .card-table-one .table tbody tr>td {
    padding-left: 2px;
}

.rtl .card-table-one .table thead tr>th:nth-child(3),
.rtl .card-table-one .table thead tr>th:nth-child(4),
.rtl .card-table-one .table thead tr>th:nth-child(5) {
    text-align: left;
}

.rtl .card-table-one .table thead tr>td:nth-child(3),
.rtl .card-table-one .table thead tr>td:nth-child(4),
.rtl .card-table-one .table thead tr>td:nth-child(5) {
    text-align: left;
}

.rtl .card-table-one .table tbody tr>th:nth-child(3),
.rtl .card-table-one .table tbody tr>th:nth-child(4),
.rtl .card-table-one .table tbody tr>th:nth-child(5) {
    text-align: left;
}

.rtl .card-table-one .table tbody tr>td:nth-child(3),
.rtl .card-table-one .table tbody tr>td:nth-child(4),
.rtl .card-table-one .table tbody tr>td:nth-child(5) {
    text-align: left;
}

@media (min-width: 768px) {

    .rtl .card-table-one .table thead tr>th,
    .rtl .card-table-one .table thead tr>td {
        padding-right: 0;
        padding-left: 0;
    }

    .rtl .card-table-one .table tbody tr>th,
    .rtl .card-table-one .table tbody tr>td {
        padding-right: 0;
        padding-left: 0;
    }
}

.rtl .main-content-dashboard-two .main-header-menu-icon {
    margin-left: 0;
}

@media (min-width: 992px) {
    .rtl .main-content-dashboard-two .main-content-body {
        padding: 0 20px 20px;
    }
}

@media (min-width: 768px) {
    .rtl .main-dashboard-header-right>div {
        text-align: left;
    }
}

@media (min-width: 576px) {
    .rtl .main-dashboard-header-right>div+div {
        margin-right: 25px;
    }
}

@media (min-width: 992px) {
    .rtl .main-dashboard-header-right>div+div {
        margin-right: 50px;
    }
}

.rtl .main-star span {
    margin-right: 5px;
}

.rtl .card-dashboard-seven {
    border-left-width: 0;
    border-right-width: 0;
    margin-right: -20px;
    margin-left: -20px;
}

.rtl .card-dashboard-seven .card-header .media>div:first-child {
    margin-left: 5px;
}

.rtl .card-dashboard-seven .card-header .date a {
    margin-right: 5px;
}

.rtl .card-dashboard-seven .card-body .row>div+div::before {
    right: -1px;
    border-right: 1px dotted #ededf5;
}

@media (min-width: 576px) {
    .rtl .card-dashboard-seven {
        margin-right: 0;
        margin-left: 0;
        border-left-width: 1px;
        border-right-width: 1px;
    }
}

@media (min-width: 576px) {
    .rtl .card-dashboard-seven .card-header .media>div:first-child {
        margin-left: 8px;
    }
}

.rtl .card-dashboard-seven .card-body .desc span {
    margin-right: 5px;
}

.rtl .card-dashboard-six .chart-legend>div span:last-child {
    margin-right: 5px;
}

.rtl .card-dashboard-six .chart-legend>div span:first-child {
    text-align: left;
}

@media (min-width: 576px) {
    .rtl .card-dashboard-six .chart-legend>div span:last-child {
        margin-right: 10px;
    }
}

.rtl .card-dashboard-eight .list-group-item {
    border-left-width: 0;
    border-right-width: 0;
}

.rtl .card-dashboard-eight .list-group-item:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.rtl .card-dashboard-eight .list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .card-dashboard-eight .list-group-item p {
    margin-right: 10px;
}

.rtl .card-dashboard-eight .list-group-item span {
    margin-right: auto;
}

@media (min-width: 992px) {
    .rtl .main-content-dashboard-three {
        border-right: 1px solid #ededf5;
        margin-right: 64px;
    }
}

.rtl .main-header-dashboard-three .main-header-center {
    margin-right: 0;
}

.rtl .main-header-dashboard-three .main-header-right {
    margin-right: auto;
}

@media (min-width: 992px) {
    .rtl .main-header-left .btn {
        left: 11px;
    }
}

.rtl .card-dashboard-nine {
    padding: 20px 20px 15px;
}

.rtl .card-dashboard-nine .card-header {
    right: 20px;
    left: 20px;
}

@media (min-width: 576px) {
    .rtl .card-dashboard-ten+.card-dashboard-ten {
        margin-top: 0;
        margin-right: 18px;
    }
}

@media (min-width: 992px) {
    .rtl .card-dashboard-ten+.card-dashboard-ten {
        margin-right: 0;
        margin-top: 20px;
    }
}

.rtl .card-dashboard-eleven .card-body {
    padding: 0 0 5px;
    margin-right: 20px;
}

.rtl .card-dashboard-eleven .card-footer h6 span {
    margin-right: 5px;
}

.rtl .card-dashboard-eleven .jqvmap {
    right: 0;
}

@media (min-width: 768px) {
    .rtl .card-dashboard-eleven .card-footer>div+div {
        border-right: 1px solid #ededf5;
    }
}

@media (min-width: 992px) {
    .rtl .main-navbar-dashboard-four .container-fluid {
        padding-right: 25px;
        padding-left: 25px;
    }
}

.rtl .main-content-dashboard-four~.main-footer .container,
.rtl .main-content-dashboard-four~.main-footer .container-fluid {
    padding-right: 25px;
    padding-left: 25px;
}

@media (min-width: 1200px) {
    .rtl .main-content-dashboard-four>.media-dashboard>.media-body {
        padding: 0 0px 0 20px;
    }
}

.rtl .main-content-header-right .media+.media {
    margin-right: 20px;
    padding-right: 20px;
    border-right: 1px solid #ededf5;
}

.rtl .main-content-header-right .btn:first-of-type,
.rtl .main-content-header-right .sp-container button:first-of-type {
    margin-right: 30px;
}

.rtl .sp-container .main-content-header-right button:first-of-type {
    margin-right: 30px;
}

.rtl .main-content-header-right .btn+.btn,
.rtl .main-content-header-right .sp-container button+.btn {
    margin-right: 5px;
}

.rtl .sp-container .main-content-header-right button+.btn,
.rtl .main-content-header-right .sp-container .btn+button,
.rtl .sp-container .main-content-header-right .btn+button,
.rtl .main-content-header-right .sp-container button+button,
.rtl .sp-container .main-content-header-right button+button {
    margin-right: 5px;
}

.rtl .card-dashboard-twelve .sales-overview .media+.media {
    margin-right: 20px;
}

.rtl .card-dashboard-twelve .sales-overview .media:last-child {
    display: none;
}

.rtl .card-dashboard-twelve .sales-overview .media-icon {
    margin-left: 15px;
}

@media (min-width: 576px) {
    .rtl .card-dashboard-twelve .sales-overview .media+.media {
        margin-right: 40px;
    }
}

@media (min-width: 992px) {
    .rtl .card-dashboard-twelve .sales-overview .media+.media {
        margin-right: 50px;
    }
}

@media (max-width: 575.98px) {
    .rtl .card-dashboard-twelve .sales-overview .media:nth-child(3) {
        margin-right: 0;
    }
}

.rtl .chart-legend {
    right: 0;
    left: 0;
}

.rtl .chart-legend div+div {
    margin-right: 15px;
}

.rtl .chart-legend span {
    margin-left: 5px;
}

@media (min-width: 576px) {
    .rtl .chart-legend div+div {
        margin-right: 30px;
    }
}

@media (min-width: 576px) {
    .rtl .chart-legend span {
        margin-left: 10px;
    }
}

.rtl .chart-wrapper {
    margin-right: -13px;
    margin-left: -13px;
}

@media (min-width: 576px) {
    .rtl .chart-wrapper {
        margin-right: -10px;
        margin-left: -15px;
    }
}

.rtl .main-media-date .media-body {
    margin-right: 5px;
}

.rtl .card-dashboard-thirteen .media-icon {
    margin-left: 10px;
}

.rtl .card-dashboard-events .list-group-item {
    padding: 15px 40px 15px 20px;
    border-left-width: 0;
    border-right-width: 0;
}

.rtl .card-dashboard-events .event-indicator {
    right: 20px;
}

.rtl .card-dashboard-events .event-user .main-img-user+.main-img-user {
    margin-right: -6px;
}

.rtl .card-dashboard-events .event-user a:last-child {
    margin-right: 10px;
}

.rtl .card-dashboard-fourteen .card-body h1 span {
    margin-left: 5px;
}

.rtl .main-body-sidebar.main-light .main-sidebar {
    border-right-color: #ededf5;
}

.rtl .main-content-dashboard-five .main-header-menu-icon {
    margin-left: 0;
}

.rtl .main-content-dashboard-five .main-content-header {
    padding: 20px 20px 0;
}

.rtl .main-content-dashboard-five .card-header {
    padding: 20px 20px 0;
}

@media (min-width: 1200px) {
    .rtl .main-content-dashboard-five .main-content-header {
        padding: 25px 25px 0;
    }
}

.rtl .card-dashboard-fifteen {
    padding: 20px 20px 0;
}

.rtl .card-dashboard-fifteen .chart-wrapper {
    margin-right: -32px;
    margin-left: -32px;
}

.rtl .table-talk-time thead>tr th:first-child,
.rtl .table-talk-time thead>tr td:first-child {
    padding-right: 20px;
}

.rtl .table-talk-time tbody>tr th:first-child,
.rtl .table-talk-time tbody>tr td:first-child {
    padding-right: 20px;
}

.rtl .card-dashboard-sixteen .table tbody>tr th,
.rtl .card-dashboard-sixteen .table tbody>tr td {
    padding: 10px 0px 10px 10px;
}

.rtl .main-light .main-iconbar-body .nav {
    border-left-color: #ededf5;
}

.rtl .main-light .main-iconbar-body .nav-item+.nav-item,
.rtl .main-light .main-iconbar-body .nav-sub,
.rtl .main-light .main-iconbar-body .nav-sub-item+.nav-sub-item {
    border-top-color: #bdc6d6;
}

.rtl .main-content-dashboard-six {
    border-left-color: #ededf5;
}

@media (min-width: 992px) {
    .rtl .main-content-dashboard-six {
        border-right: 1px solid #ededf5;
        margin-right: 64px;
    }
}

.rtl .main-header-dashboard-six .main-header-menu-icon {
    margin-left: 0;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .rtl .main-header-dashboard-six .main-header-center {
        margin-right: 5px;
    }
}

@media (min-width: 1200px) {
    .rtl .main-header-dashboard-six .main-header-center {
        margin-right: 0;
    }
}

.rtl .main-content-body-dashboard-six .card-header {
    padding: 20px 20px 0;
}

.rtl .card-dashboard-donut .main-donut-chart.chart1 .chart-center {
    right: 10px;
}

.rtl .card-dashboard-donut .main-donut-chart.chart2 .chart-center {
    right: 10px;
}

.rtl .card-dashboard-balance .fab {
    left: 20px;
}

.rtl .card-dashboard-balance .account-number span+span {
    margin-right: 20px;
}

.rtl .card-dashboard-balance .account-number span i+i {
    margin-right: 5px;
}

.rtl .card-dashboard-balance .chart-wrapper {
    right: -10px;
    left: -10px;
}

@media (min-width: 576px) {
    .rtl .card-dashboard-balance .account-number span+span {
        margin-right: 30px;
    }
}

@media (min-width: 1200px) {
    .rtl .card-dashboard-balance .account-number span+span {
        margin-right: 50px;
    }
}

@media (min-width: 576px) {
    .rtl .card-dashboard-ratio .card-body>div:last-child {
        margin-right: 30px;
    }
}

.rtl .card-dashboard-finance h2 span {
    margin-left: 5px;
}

.rtl .card-dashboard-table-six .table thead tr:first-child th:last-child {
    border-right: 1px solid #ededf5;
}

.rtl .card-dashboard-table-six .table thead tr:last-child th:nth-child(2),
.rtl .card-dashboard-table-six .table thead tr:last-child th:nth-child(6) {
    border-right: 1px solid #ededf5;
}

.rtl .card-dashboard-table-six .table tbody tr td {
    text-align: left;
}

.rtl .card-dashboard-table-six .table tbody tr td:first-child {
    text-align: right;
}

.rtl .card-dashboard-table-six .table tbody tr td:nth-child(2),
.rtl .card-dashboard-table-six .table tbody tr td:nth-child(6) {
    border-right: 1px solid #ededf5;
}

@media (min-width: 1200px) {
    .rtl .main-minimal {
        padding: 10px 0 0;
    }
}

@media (min-width: 992px) {
    .rtl .media-dashboard-one .media-body {
        margin-left: 20px;
    }
}

.rtl .card-minimal-one .value-info p span {
    margin-left: 2px;
}

.rtl .card-minimal-one .open-info>div+div {
    margin-right: 25px;
}

.rtl .card-minimal-one .chart-wrapper {
    right: -9px;
    left: -8px;
}

.rtl .card-minimal-two .card-header>.nav .nav-link+.nav-link {
    margin-right: 2px;
}

.rtl .card-minimal-two .card-header-right .nav-link+.nav-link {
    margin-right: 2px;
}

.rtl .card-minimal-two .flot-wrapper {
    margin-left: -9px;
    margin-right: -31px;
}

.rtl .card-minimal-two .more-info {
    left: 10px;
}

.rtl .card-minimal-two .more-info span:first-child {
    text-align: left;
}

.rtl .card-minimal-two .more-info span:last-child {
    margin-right: 15px;
}

@media (min-width: 992px) {
    .rtl .card-minimal-two .flot-wrapper {
        margin-left: -9px;
        margin-right: 10px;
    }
}

@media (min-width: 576px) {
    .rtl .card-minimal-two .more-info {
        left: 20px;
    }
}

@media (min-width: 992px) {
    .rtl .card-minimal-two .more-info {
        left: 30px;
    }
}

.rtl .card-bitcoin .media-body {
    margin-right: 20px;
}

.rtl .card-minimal-three .card-header {
    padding: 20px 20px 0;
}

.rtl .card-minimal-three .main-nav-tabs {
    margin-right: -15px;
    margin-left: -15px;
}

.rtl .card-minimal-three .main-nav-tabs .lSAction>a.lSPrev {
    right: -35px;
}

.rtl .card-minimal-three .tab-pane-header>div:last-child a+a {
    margin-right: 10px;
}

.rtl .card-crypto-portfolio .table thead tr th,
.rtl .card-crypto-portfolio .table thead tr td {
    text-align: left;
}

.rtl .card-crypto-portfolio .table tbody tr th,
.rtl .card-crypto-portfolio .table tbody tr td {
    text-align: left;
}

.rtl .card-crypto-portfolio .table thead tr th:first-child,
.rtl .card-crypto-portfolio .table thead tr td:first-child {
    text-align: right;
}

.rtl .card-crypto-portfolio .table tbody tr th:first-child,
.rtl .card-crypto-portfolio .table tbody tr td:first-child {
    text-align: right;
}

.rtl .card-minimal-four .card-header .media-body {
    margin-right: 12px;
}

.rtl .card-minimal-four .card-body-top {
    right: 10px;
}

.rtl .card-minimal-four .card-body-top a {
    text-align: left;
}

.rtl .card-minimal-four .chart-wrapper {
    margin-right: -20px;
    margin-left: -9px;
}

.rtl .card-minimal-four .card-footer .nav-link {
    padding-right: 10px;
    padding-left: 10px;
}

.rtl .card-minimal-four .card-footer .nav-link+.nav-link {
    border-right: 1px solid #ededf5;
}

.rtl .card-minimal-four .card-footer .nav-link.active::before {
    right: 0;
    left: 0;
}

.rtl .main-navbar-dashboard-eight .nav-sub:not(.nav-sub-mega)::before {
    right: -5px;
    left: -5px;
}

.rtl .main-navbar-dashboard-eight .nav-sub-item>.nav-sub {
    right: 180px;
}

.rtl .main-navbar-dashboard-eight .nav-sub-item>.nav-sub::before {
    right: -5px;
    left: auto;
}

@media (max-width: 575px) {
    .rtl .main-content-dashboard-eight .container {
        padding-right: 20px;
        padding-left: 20px;
    }
}

.rtl .card-dashboard-seventeen .chart-wrapper {
    left: -8px;
    right: -8px;
}

.rtl .card-dashboard-eighteen .card-body {
    padding: 20px 0 34px;
}

.rtl .card-dashboard-eighteen .card-body h6 {
    padding-right: 23px;
}

.rtl .card-dashboard-eighteen .card-body h6::before {
    right: 0;
}

.rtl .card-dashboard-eighteen .chartjs-wrapper {
    margin-right: -10px;
    margin-left: -5px;
}

.rtl .card-dashboard-nineteen .card-header {
    padding: 20px 20px 0;
}

@media (min-width: 375px) {
    .rtl .card-dashboard-nineteen .card-header {
        right: 0;
    }
}

.rtl .card-dashboard-nineteen .chart-legend>div::before {
    margin-left: 5px;
}

.rtl .card-dashboard-nineteen .chart-legend>div+div {
    margin-right: 10px;
}

.rtl .card-dashboard-nineteen .flot-chart-wrapper {
    margin: -30px -47px -16px -8px;
}

@media (min-width: 768px) {
    .rtl .card-dashboard-nineteen .chart-legend {
        margin-right: 70px;
    }
}

@media (min-width: 375px) {
    .rtl .card-dashboard-nineteen .chart-legend>div+div {
        margin-right: 30px;
    }
}

.rtl .card-dashboard-progress .progress-legend li {
    padding-right: 15px;
}

.rtl .card-dashboard-progress .progress-legend li::before {
    right: 0;
}

.rtl .card-dashboard-progress .progress-legend li+li {
    margin-right: 20px;
}

.rtl .card-dashboard-progress .progress-bar+.progress-bar {
    margin-right: 1px;
}

@media (min-width: 576px) {
    .rtl .card-dashboard-progress .media-body {
        margin-right: 15px;
    }
}

@media (min-width: 992px) {
    .rtl .main-body-dashboard-nine .main-header .container-fluid {
        padding-right: 20px;
        padding-left: 20px;
    }
}

@media (min-width: 992px) {
    .rtl .main-body-dashboard-nine.main-sidebar-hide .main-header-dashboard-nine {
        right: 0;
    }
}

.rtl .main-sidebar-indigo-dark {
    border-right-width: 0;
}

.rtl .main-sidebar-indigo-dark .main-sidebar-body .nav-item.active::before {
    right: -20px;
    left: -20px;
}

.rtl .main-sidebar-indigo-dark .main-sidebar-body .nav-sub {
    border-left-color: rgba(255, 255, 255, 0.1);
    padding-right: 0;
}

.rtl .main-sidebar-indigo-dark .main-sidebar-body .nav-sub::before {
    right: -32px;
    left: -20px;
}

.rtl .main-sidebar-indigo-dark .main-sidebar-body .nav-sub-link {
    padding-right: 21px;
}

.rtl .main-header-dashboard-nine .main-header-menu-icon {
    margin-left: 0;
}

@media (min-width: 992px) {
    .rtl .main-header-dashboard-nine {
        right: 220px;
        left: 0;
    }
}

@media (min-width: 1200px) {
    .rtl .main-header-dashboard-nine {
        right: 240px;
    }
}

.rtl .main-content-1 .main-content-header .nav-link+.nav-link {
    margin-right: 25px;
}

@media (min-width: 992px) {
    .rtl .main-content-1 .main-content-header {
        margin: 0 0 1.3rem 0;
    }
}

@media (min-width: 992px) {
    .rtl .main-content-1 .main-content-header .nav-link:nth-child(5) {
        margin-right: auto;
    }
}

.rtl .card-dashboard-twentyone .list-group-item {
    border-right-width: 0;
}

.rtl .card-dashboard-twentyone .list-group-item span:last-of-type {
    text-align: left;
}

.rtl .card-dashboard-twentyone .list-group-item:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.rtl .card-dashboard-twentyone .list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .card-dashboard-twentyone .vmap-wrapper .jqvmap-zoomin,
.rtl .card-dashboard-twentyone .vmap-wrapper .jqvmap-zoomout {
    right: 15px;
}

.rtl .card-dashboard-twentytwo .media-body {
    margin-right: 15px;
}

.rtl .card-dashboard-twentytwo .media-body h6>small {
    margin-right: 5px;
}

.rtl .card-dashboard-twentytwo .chart-wrapper {
    left: -9px;
    right: -9px;
}

.rtl .card-dashboard-twentythree .main-donut-chart.chart1 .chart-center {
    right: 15px;
}

.rtl .card-dashboard-twentythree label span {
    margin-left: 5px;
}

.rtl .card-dashboard-audience-metrics .card-header {
    padding: 20px 20px 0;
}

.rtl .card-dashboard-audience-metrics .chart-wrapper {
    left: 0;
    right: 0;
    padding: 5px 5px 0;
}

.rtl .card-dashboard-audience-metrics .flot-chart .flot-x-axis>div span:last-child {
    padding-right: 2px;
}

.rtl .card-dashboard-audience-metrics .card-body>div+div {
    margin-right: 10px;
    padding-right: 10px;
}

.rtl .card-dashboard-audience-metrics .card-body label span {
    margin-left: 5px;
}

@media (min-width: 576px) {
    .rtl .card-dashboard-audience-metrics .card-body>div+div {
        margin-right: 20px;
        padding-right: 20px;
    }
}

.rtl .card-dashboard-twentyfour .card-header {
    padding: 20px 20px 0;
}

.rtl .card-dashboard-twentyfour .card-body {
    padding: 15px 20px 20px;
}

.rtl .card-dashboard-twentyfour .card-body-top>div+div {
    margin-right: 30px;
}

.rtl .card-dashboard-twentyfour .chart-legend span {
    margin-left: 5px;
}

.rtl .card-dashboard-twentyfour .chart-legend>div+div {
    margin-right: 20px;
}

.rtl .card-dashboard-twentyfour .chart-wrapper {
    margin-left: -10px;
}

.rtl .card-dashboard-twentyfive .col+.col {
    border-right: 1px solid #ededf5;
}

.rtl .card-dashboard-twentyfive .chart-wrapper {
    margin: 0 -8px -5px;
}

.rtl .card-dashboard-twentysix .card-header {
    padding: 15px 15px 10px;
}

.rtl .card-dashboard-twentysix .chart-legend>div+div {
    margin-right: 15px;
}

.rtl .card-dashboard-twentysix .chart-legend span {
    margin-left: 2px;
}

.rtl .card-dashboard-twentysix .chart-wrapper {
    margin: -30px -18px -15px -18px;
}

.rtl .main-media-list-reviews .media-body {
    margin-right: 15px;
}

.rtl .main-star-item+.main-star-item {
    margin-right: 3px;
}

.rtl .main-media-list-activity .media-body {
    margin-right: 15px;
}

.rtl .main-header-profile {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.rtl .dropdown-menu-arrow:after {
    left: 16px;
    border-right: 9px solid transparent;
    border-left: 9px solid transparent;
}

.rtl .dropdown-menu-left {
    left: 0 !important;
    right: auto !important;
}

.rtl .total-revenue label span {
    margin-left: 5px;
}

.rtl .total-revenue>div+div {
    margin-right: 20px;
    padding-right: 20px;
}

.rtl .progress-style.progress .progress-bar:after {
    left: -5px;
    right: inherit;
    top: -5px;
}

.rtl .progress-style .progress-bar {
    float: right;
}

.rtl .br-br-7 {
    border-bottom-left-radius: 7px !important;
}

.rtl .br-bl-7 {
    border-bottom-right-radius: 7px !important;
}

@media (max-width: 920px) {
    .rtl .sidebar-mini .main-dashboard-header-right>div label {
        text-align: right;
    }
}

@media (max-width: 576px) {
    .rtl .total-revenue>div+div {
        margin-right: 20px !important;
        padding-right: 3px !important;
    }
}

.rtl .nav-link.html-code.active {
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
}

.rtl .success-widget h3:after {
    right: 0.5em;
    left: inherit;
}

.rtl .success-widget h3:before {
    left: 0.5em;
    right: inherit;
}

.rtl .danger-widget h3:after {
    right: 0.5em;
    left: inherit;
}

.rtl .danger-widget h3:before {
    left: 0.5em;
    right: inherit;
}

.rtl .warning-widget h3:after {
    right: 0.5em;
    left: inherit;
}

.rtl .warning-widget h3:before {
    left: 0.5em;
    right: inherit;
}

.rtl .card .box {
    right: 0;
    left: inherit;
}

.rtl .userlist-table .user-link {
    margin-left: 5px;
    margin-right: inherit;
}

.rtl .dot-label {
    right: 7px;
}

@media (max-width: 576px) {
    .rtl .profile.navtab-custom {
        padding: 11px 0 0 0 !important;
    }

    .rtl .profile.navtab-custom li a {
        margin-left: 0 !important;
        border-top-right-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }
}

.rtl .userlist-table .dot-label {
    right: -6px;
}

@media (min-width: 992px) {
    .rtl .top-header .main-header-center {
        margin-right: 0 !important;
    }

    .rtl .top-header .header-brand.header-brand2 {
        right: 0;
        left: 0;
    }
}

.rtl .featured_icon i {
    left: 0;
    right: 0;
}

.rtl .featured_icon {
    margin-right: -35px !important;
}

.rtl ul.timeline:before {
    right: 25px;
}

.rtl .img-card .img-card-circle {
    margin-left: -48px;
    text-align: left;
    left: 0;
}

.rtl .btn-icon {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

@media (max-width: 767px) {
    .rtl .responsive-navbar.navbar .navbar-collapse {
        right: 0;
        left: 0;
    }
}

.rtl .feature-1 {
    left: 40px;
    right: inherit;
}

.rtl .img-card-circle1 {
    margin-left: -5px;
    text-align: left;
    left: 0;
}

.rtl .img-card-circle2 {
    margin-left: 1px;
    text-align: left;
    left: 0;
}

.rtl .img-card-circle3 {
    margin-left: 4px;
    left: 0;
}

.rtl .img-card-circle4 {
    margin-left: 0px;
    left: 0;
}

.rtl .product-grid6 .icons {
    right: 0;
    left: 0;
}

.rtl .product-grid6:hover .icons {
    right: 0;
    left: 0;
}

.rtl .product-image .pic-2 {
    right: 0;
    left: 0;
}

.rtl .product-grid6 .product-link a:nth-child(2n) {
    border-left: none;
}

.rtl .handle-counter input {
    float: right;
    border-right: 0;
    border-left: 0;
}

.rtl .handle-counter .counter-minus,
.rtl .handle-counter .handle-counter .counter-plus {
    float: right;
}

.rtl .handle-counter .counter-minus {
    border-radius: 3px 3px 0px 0px !important;
}

.rtl .handle-counter .counter-plus {
    border-radius: 0px 0px 3px 3px !important;
}

.rtl .counter-minus {
    border-radius: 0px 0 0 0px !important;
}

.rtl .counter-plus {
    border-radius: 0 0px 0px 0 !important;
}

.rtl .edit {
    margin-right: 30px;
}

.rtl .mobile-main-header .main-header-message .dropdown-menu {
    right: auto;
    left: -5px;
}

.rtl .card-options a:not(.btn) {
    margin-right: 0;
    margin-left: inherit;
}

.rtl .card-options {
    margin-right: auto;
    margin-left: inherit;
}

.rtl .lds-heart div:before {
    right: -17px;
    left: inherit;
    border-radius: 0 50% 50% 0;
}

.rtl .lds-heart div {
    right: 19px;
    left: inherit;
}

.rtl .lds-heart {
    transform: rotate(313deg);
}

@-webkit-keyframes lds-ripple {
    0% {
        right: 28px;
    }

    100% {
        right: -1px;
    }
}

@keyframes lds-ripple {
    0% {
        right: 28px;
    }

    100% {
        right: -1px;
    }
}

.rtl .lds-spinner div:after {
    right: 29px;
}

.rtl .lds-facebook div:nth-child(1) {
    right: 6px;
}

.rtl .lds-facebook div {
    right: 6px;
}

.rtl .lds-facebook div:nth-child(2) {
    right: 26px;
}

.rtl .lds-facebook div:nth-child(3) {
    right: 45px;
}

@media (max-width: 767.98px) {

    .rtl .main-header-message .dropdown-menu,
    .rtl .main-header-notification .dropdown-menu,
    .rtl .main-profile-menu .dropdown-menu {
        right: 5px !important;
        left: 5px !important;
    }

    .rtl .menu-header-content {
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
    }
}

@media (max-width: 568px) {
    .rtl .feature-1 {
        left: 4px;
    }

    .rtl .btn,
    .rtl .sp-container button {
        margin-left: 0px;
    }

    .rtl .main-contact-action {
        left: 0px !important;
        right: inherit !important;
    }
}

.rtl .main-sidemenu .slide.is-expanded i.angle {
    left: 20px;
}

.rtl .main-sidemenu i.angle {
    left: 20px;
    right: inherit;
}

@media (max-width: 600px) {
    .rtl .main-nav-line .nav-link+.nav-link {
        margin-right: 0;
    }
}

@media (max-width: 991px) {
    .rtl .profile-cover__info .nav li:not(:first-child) {
        margin-right: 0px !important;
    }
}

@media (max-width: 568px) {

    .rtl .btn.btn-rounded.plus,
    .rtl .btn.btn-rounded.comment {
        margin-left: -25px;
    }
}

.rtl ul.inbox-pagination {
    float: left;
}

.rtl .inbox-pagination li span {
    margin-left: 5px;
}

.rtl .nav.panel-tabs .side-menu__icon {
    margin-left: 5px;
}

@media (max-width: 768px) {
    .rtl .responsive-navbar .collapse.show .dropdown .dropdown-menu.header-search {
        right: 0;
        left: 0;
    }

    .rtl .responsive-navbar .collapse.show .dropdown .show.dropdown-menu {
        right: 5px !important;
        left: 5px !important;
    }
}

.rtl .circle-icon {
    left: 40px;
    right: inherit;
}

.rtl .dot-label-1 {
    margin-left: 5px;
}

@media (max-width: 767px) {
    .rtl .demo-icon .nav-link {
        padding: 12px 10px 4px 4px !important;
    }
}

.rtl .pos-absolute.bg-black-9 {
    border-radius: 0px 0px 5px 5px;
}

.rtl div.dt-button-info {
    right: 50%;
    margin-right: -200px;
}

@media (min-width: 768px) {
    .rtl .main-footer {
        padding: 0.9rem 250px 0.9rem 1rem !important;
    }
}

@media (max-width: 768px) {
    .rtl .main-footer {
        padding: 0.9rem 10px 0.9rem 1rem;
    }
}

.rtl .bootstrap-tagsinput .badge [data-role=remove]:after {
    margin-right: 6px;
    margin-left: inherit;
}

.rtl .img-card-circle1 {
    margin-left: 0px;
    text-align: left;
    left: 0;
}

.rtl .demo-icon {
    margin-left: 20px !important;
    margin-right: inherit !important;
}

.rtl .demo-icon.nav-link {
    padding: 0rem 0.2rem 0rem 0rem !important;
}

.rtl .intl-tel-input .flag-dropdown .selected-flag {
    padding: 10px 7px 9px 18px;
}

.rtl .background-image-blog {
    border-top-right-radius: calc(0.25rem - 1px);
    border-top-left-radius: calc(0.25rem - 1px);
}

.rtl .attached-file-image .pic-2 {
    right: 0;
    left: 0;
}

.rtl .attached-file-grid6:hover .icons {
    right: 0;
    left: 0;
}

.rtl .image-pic {
    left: 0;
    right: 0;
    padding: 0px 10px 20px 0px;
}

.rtl .tag.tag-attachments {
    padding: 4px 15px 2px 15px;
}

.rtl .tag.tag-attachments-lg {
    padding: 5px 17px 3px 17px;
}

.rtl .tag.tag-attachments-sm {
    padding: 3px 10px 1px 10px;
}

.rtl .tags .tag-files span {
    margin-left: 104px;
}

.rtl .attached-file-grid6 .icons {
    right: 0;
    left: 0;
}

.rtl .owl-nav .owl-next {
    left: -25px;
    right: inherit;
}

.rtl .owl-nav .owl-prev {
    right: -25px;
    left: inherit;
}

.rtl .about-team {
    left: 0;
    right: 0;
}

.rtl .apexcharts-legend-marker {
    margin-left: 10px !important;
}

.rtl .notification:before {
    right: 20%;
    margin-right: -2.5px;
}

.rtl .notification .notification-time {
    right: -25px;
    text-align: left;
}

.rtl .notification .notification-icon {
    right: 15%;
    left: inherit;
}

.rtl .notification .notification-body {
    margin-right: 24%;
    margin-left: 18%;
}

.rtl .notification .notification-body:before {
    border-left-color: #fff;
    right: -20px;
    left: inherit;
    border-right-color: transparent;
}

@media (max-width: 576px) {
    .rtl .notification .notification-time {
        left: 5px !important;
        right: inherit !important;
    }

    .rtl .notification .notification-body {
        margin-right: 0px;
        margin-left: 0px;
    }

    .rtl .notification-badge {
        right: 10px;
        left: inherit !important;
    }

    .rtl .notification .notification-time .time {
        margin-right: 5px;
        margin-left: 10px;
    }
}

.rtl *::-ms-backdrop,
.rtl .horizontalMenucontainer .main-header-left {
    margin-right: 0 !important;
}

.rtl *::-ms-backdrop,
.rtl .chat .action-header {
    padding: 10px 17px 1px 13px;
}

.rtl *::-ms-backdrop,
.rtl .sidebar-mini .main-header-left .btn {
    margin-right: 60px;
    right: 250px;
}

.rtl *::-ms-backdrop,
.rtl .sidebar-mini .main-content.app-content .main-header-left {
    margin-right: 0;
}

.rtl *::-ms-backdrop,
.rtl .datetimepicker table th.next {
    border-top-left-radius: 0;
}

.rtl *::-ms-backdrop,
.rtl .datetimepicker table th.prev {
    border-top-right-radius: 0;
}

@media (min-width: 992px) {

    .rtl *::-ms-backdrop,
    .rtl .sidebar-mini .main-header-left {
        margin-right: 80px;
    }

    .rtl *::-ms-backdrop,
    .rtl .sidebar-mini .main-header .main-header-left .header-brand {
        margin-left: 80px;
    }
}

.rtl *::-ms-backdrop,
.rtl .btn-icon-list .btn {
    margin-left: 10px;
}

.rtl *::-ms-backdrop,
.rtl .fc-datepicker.main-datepicker.hasDatepicker {
    border-left: 0 !important;
}

@media (min-width: 992px) {

    .rtl *::-ms-backdrop,
    .rtl .tasks .ckbox {
        margin-left: 10px;
    }

    .rtl *::-ms-backdrop,
    .rtl .app-sidebar__toggle {
        margin-left: 30px !important;
    }
}

@media (max-width: 767px) {

    .rtl *::-ms-backdrop,
    .rtl .main-header-left .responsive-logo {
        margin-right: 40px !important;
    }

    .rtl *::-ms-backdrop,
    .rtl .sidebar-mini .main-header .main-header-left .header-brand {
        margin-right: 30px;
    }
}

.rtl *::-ms-backdrop,
.rtl #list3 .me-4 {
    margin-left: 0 !important;
}

.rtl .adtocart {
    right: 40%;
}

.rtl .prod-cat li ul {
    margin-right: 44px;
}

.rtl .nav.prod-cat li a i {
    margin-left: 14px;
}

.rtl .product-sale {
    right: 13px;
    left: 5px;
}

.rtl .product-sale .wishlist {
    float: left;
    left: 26px;
}

.rtl .shopping-cart .product-item .product-thumb,
.rtl .wishlist-table .product-item .product-thumb,
.rtl .order-table .product-item .product-thumb {
    padding-left: 0;
}

.rtl .shopping-cart .product-item .product-title small,
.rtl .wishlist-table .product-item .product-title small,
.rtl .order-table .product-item .product-title small {
    margin-right: 6px;
}

.rtl .shopping-cart-footer>.column:last-child {
    text-align: left;
}

.rtl .shopping-cart-footer>.column:last-child .btn {
    margin-left: 0;
    margin-right: 5px;
}

.rtl .coupon-form .form-control {
    margin-left: 12px;
}

.rtl #checkoutsteps .item .purchase {
    margin-right: 21px;
}

.rtl #checkoutsteps .total {
    float: left;
}

.rtl .card-pay .tabs-menu.nav li {
    border-left: 1px solid #d3dfea;
}

.rtl .widget-user .widget-user-image {
    right: 50%;
    margin-right: -45px;
}

.rtl .user-wideget-footer {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.rtl .user-wideget .border-right {
    border-left: 1px solid #f4f4f4;
}

.rtl .main-footer .container {
    padding: 38px 20px 0;
}

.rtl .main-footer .container-fluid {
    padding: 38px 20px 0;
    padding-right: 20px;
    padding-left: 20px;
}

@media (min-width: 992px) {
    .rtl .main-footer .container {
        padding: 10px 0 0;
    }
}

@media (max-width: 480px) {
    .rtl .main-footer .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (max-width: 633px) {
    .rtl .profile-name.ms-2 {
        margin-right: 0;
    }
}

.rtl .main-header-left .list-items li i {
    margin-left: 15px;
}

.rtl .main-logo i {
    margin-left: 8px;
}

.rtl .main-header-menu-icon {
    margin-left: 10px;
}

.rtl .main-header-menu-icon span::before,
.rtl .main-header-menu-icon span::after {
    right: 0;
}

@media (min-width: 992px) {
    .rtl .main-header-menu-icon {
        margin-left: 25px;
    }
}

.rtl .main-header-menu {
    right: 0;
}

.rtl .main-header-menu .nav-item::before {
    margin-right: 25px;
    border-right: 2px solid rgba(28, 39, 60, 0.12);
}

.rtl .main-header-menu .nav-item>.nav-link .typcn {
    margin-left: 15px;
}

.rtl .main-header-menu .nav-item.active>.nav-link::before {
    right: -20px;
}

@media (min-width: 992px) {
    .rtl .main-header-menu {
        border-left: 0;
    }
}

@media (min-width: 992px) {
    .rtl .main-header-menu .nav-item+.nav-item {
        margin-right: 30px;
    }
}

@media (min-width: 992px) {
    .rtl .main-header-menu .nav-item>.nav-link.with-sub::after {
        margin-right: 5px;
    }
}

@media (min-width: 992px) {
    .rtl .main-header-menu .nav-item>.nav-link .typcn {
        margin-left: 7px;
    }
}

@media (min-width: 992px) {
    .rtl .main-header-menu .nav-item.active>.nav-link::before {
        right: 0;
        left: 0;
    }
}

.rtl .main-menu-sub {
    margin-right: 32px;
}

@media (min-width: 992px) {
    .rtl .main-menu-sub {
        margin-right: 0;
    }
}

.rtl .main-menu-sub-mega {
    right: 0;
    left: 0;
}

@media (min-width: 992px) {
    .rtl .main-menu-sub-mega .container>div+div {
        border-right: 1px solid rgba(28, 39, 60, 0.05);
    }
}

@media (min-width: 992px) {
    .rtl .main-menu-sub-mega .nav+.nav {
        margin-right: 20px;
    }
}

@media (min-width: 1200px) {
    .rtl .main-menu-sub-mega .nav+.nav {
        margin-right: 25px;
    }
}

.rtl .main-header-right .btn-social+.btn-social {
    margin-right: 10px;
}

.rtl .main-header-search-link {
    margin-left: 15px;
}

.rtl .main-profile-menu>.profile-name::after {
    right: 50%;
    margin-right: -7px;
}

.rtl .main-header-notification .dropdown-menu {
    right: auto;
    left: -10px;
}

.rtl .main-profile-menu .dropdown-menu:before {
    right: auto !important;
    left: 15px !important;
}

.rtl .main-header-right .dropdown-menu:before {
    left: 28px;
    right: auto;
}

.rtl.light-theme .main-sidebar-header {
    border-left: 1px solid #ededf5;
}

.rtl .main-header-message .dropdown-menu {
    right: auto;
    left: -10px;
}

.rtl .main-notification-list .media:hover::before,
.rtl .main-notification-list .media:focus::before {
    right: -20px;
    left: -20px;
}

.rtl .main-notification-list .media-body {
    margin-right: 15px;
}

.rtl .main-profile-menu>.main-img-user::before {
    left: -90px;
    margin-right: -7px;
}

.rtl .main-profile-menu .dropdown-menu {
    right: 0;
    left: 0;
    right: auto;
    left: 0px;
}

.rtl .main-profile-menu .dropdown-item i {
    margin-left: 10px;
    margin-right: inherit;
}

.rtl .main-header-sticky {
    right: 0;
    left: 0;
}

.rtl .main-header-right form[role=search] {
    left: 0;
}

@media (min-width: 992px) {
    .rtl .main-header-menu-icon {
        margin-left: 25px;
        margin-right: auto;
        margin-left: 8px !important;
    }
}

.rtl form[role=search].active {
    left: 0;
    border-right: 0px solid #e3e3f7;
    right: -1px;
}

.rtl .main-header-message .dropdown-footer,
.rtl .main-header-notification .dropdown-footer {
    border-radius: 0px 10px 10px 0px;
}

@media (min-width: 992px) {
    .rtl .main-header-left .btn {
        left: 0px;
        right: inherit;
        border-right: inherit;
        border-left: 1px solid #ededf5 !important;
        border-radius: 0px 0px 5px 5px;
    }
}

.rtl .header-megamenu-dropdown i {
    margin-left: 3px;
}

.rtl .main-header.hor-header>.container-fluid {
    padding-right: 25px;
    padding-left: 25px;
}

.rtl .main-header-arrow {
    left: auto;
}

@media (max-width: 480px) {
    .rtl .main-header-right .nav .nav-item .dropdown-menu {
        right: auto;
        left: auto;
        right: 5px;
        left: 5px;
    }
}

@media (min-width: 768px) {
    .rtl .main-header {
        right: 0;
        padding-right: 250px;
        padding-left: inherit;
    }

    .rtl .app.sidenav-toggled .main-header {
        padding-right: 80px;
        padding-right: 80px;
    }
}

.rtl .horizontalMenucontainer .main-header.hor-header {
    padding-right: 0;
}

@media (max-width: 633px) and (min-width: 321px) {
    .rtl .main-profile-menu {
        margin-right: 0 !important;
        margin-left: 0 !important;
        padding-left: 7px !important;
        padding-right: 7px !important;
    }
}

@media (max-width: 320px) {
    .rtl .main-profile-menu {
        margin-right: 0 !important;
        margin-left: 0 !important;
        padding-left: 2px !important;
        padding-right: 2px !important;
    }
}

.rtl .desktoplogo {
    float: right;
}

.rtl .horizontalMenu>.horizontalMenu-list {
    text-align: right;
}

.rtl .horizontalMenu>.horizontalMenu-list>li {
    float: right;
}

.rtl .horizontalMenu>.horizontalMenu-list>li>a {
    padding: 17px 13px 17px 13px;
}

.rtl .horizontalMenu>.horizontalMenu-list>li:last-child>a {
    border-left: 0px;
}

.rtl .horizontalMenu>.horizontalMenu-list>li a.menuhomeicon {
    padding-right: 25px;
    padding-left: 25px;
}

.rtl .horizontalMenu>.horizontalMenu-list>li>a i {
    margin-left: 5px;
}

.rtl .horizontalMenu>.horizontalMenu-list>li>a .arrow:after {
    border-right: 4px solid rgba(0, 0, 0, 0);
    border-left: 4px solid rgba(0, 0, 0, 0);
    float: left;
    left: 11px;
    margin: 0 14px 0 0;
    text-align: left;
}

.rtl .horizontalMenu>.horizontalMenu-list>li.rightmenu {
    float: left;
    float: left;
}

.rtl .horizontalMenu>.horizontalMenu-list>li.rightmenu a {
    padding: 0px 20px 0px 30px;
    border-left: none;
}

.rtl .horizontalMenu>.horizontalMenu-list>li.rightmenu>.topmenusearch {
    float: left;
    margin: 16px 0px 0px 0px;
}

.rtl .horizontalMenu>.horizontalMenu-list>li.rightmenu>.topmenusearch input {
    float: left;
    left: 0;
}

.rtl .horizontalMenu>.horizontalMenu-list>li.rightmenu>.topmenusearch .btnstyle {
    left: 0;
}

.rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a {
    border-left: 0 none;
    text-align: right;
}

.rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a>i {
    margin-left: 9px;
}

.rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu {
    right: 100%;
}

.rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a {
    border-left: 0 none;
    text-align: right;
}

.rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a>i {
    margin-left: 9px;
}

.rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu {
    right: 100%;
}

.rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a {
    border-left: 0 none;
    text-align: right;
}

.rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a>i {
    margin-left: 9px;
}

.rtl .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu {
    right: 0px;
    text-align: right;
}

.rtl .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .title {
    padding: 5px 0px 5px 5px;
    margin: 0px 0px 4px 0px;
    text-align: right;
}

.rtl .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li {
    text-align: right;
    padding: 5px 10px 5px 10px;
}

.rtl .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a {
    border-left: none;
    text-align: right;
}

.rtl .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu li i {
    margin-left: 5px;
}

.rtl .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu li i {
    margin-left: 5px;
}

.rtl .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu.halfmenu {
    left: auto;
    right: auto;
}

.rtl .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu.halfdiv {
    left: auto;
    right: auto;
}

.rtl .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .menu_form input[type=submit] {
    float: left;
    margin-left: 15px;
}

.rtl .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .menu_form input[type=button] {
    float: left;
}

.rtl .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .carousel-caption {
    right: 0;
    left: 0;
}

@media only screen and (max-width: 991px) {
    .rtl {
        /* ================== Mobile Mega Menus CSS  ================== */
        /* ================== Mobile Header CSS ================== */
        /* Mobile Toggle Menu icon (X ICON) */
        /* ================== Mobile Overlay/Drawer CSS ================== */
        /* ================== Mobile Sub Menu Expander Arrows  ================== */
        /*End Media Query*/
    }

    .rtl .horizontalMenu {
        right: 0;
    }

    .rtl .horizontalMenu>.horizontalMenu-list {
        margin-right: -240px;
    }

    .rtl .horizontalMenu>.horizontalMenu-list>li {
        border-left: none;
        clear: left;
    }

    .rtl .horizontalMenu>.horizontalMenu-list>li>a {
        padding: 8px 17px 8px 32px;
        text-align: right;
        border-left: solid 0px;
    }

    .rtl .horizontalMenu>.horizontalMenu-list>li a.menuhomeicon {
        padding-right: 17px;
        padding-left: 17px;
    }

    .rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a {
        padding: 10px 36px 10px 0px;
    }

    .rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li span+a {
        padding-left: 30px;
    }

    .rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu {
        right: 100%;
    }

    .rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li {
        margin: 0px 0px 0px 0px;
    }

    .rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a {
        padding: 10px 62px 10px 0px;
    }

    .rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a:before {
        right: 45px;
    }

    .rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li span+a {
        padding-left: 30px;
    }

    .rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu {
        right: 100%;
    }

    .rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li {
        margin: 0px 0px 0px 0px;
    }

    .rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a {
        padding: 10px 34px 10px 0px;
    }

    .rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li span+a {
        padding-left: 30px;
    }

    .rtl .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu.halfmenu {
        padding: 5px 0px 10px 0px;
    }

    .rtl .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .title {
        padding: 10px 0px 10px 8px;
    }

    .rtl .horizontal-header {
        right: 0;
    }

    .rtl .callusbtn {
        left: 5px;
    }

    .rtl .animated-arrow {
        right: 0;
        padding: 14px 0px 16px 35px;
        margin: 18px 25px 0 0;
    }

    .rtl .horizontal-overlapbg {
        left: 0;
    }

    .rtl .active .horizontalMenucontainer {
        margin-right: 240px;
    }

    .rtl .active .horizontalMenu>.horizontalMenu-list {
        margin-right: 0;
    }

    .rtl .horizontalMenu>.horizontalMenu-list>li>.horizontalMenu-click {
        left: 0;
    }

    .rtl .horizontalMenu>.horizontalMenu-list>li>.horizontalMenu-click>i {
        margin-left: 10px;
        float: left;
    }

    .rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click02 {
        left: 0;
    }

    .rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click02>i {
        margin: 0px 6px 8px 6px;
        float: left;
    }

    .rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click03 {
        left: 0;
    }

    .rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click03>i {
        margin: 0px 6px 8px 6px;
        float: left;
    }

    .rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click04 {
        left: 0;
    }

    .rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click04>i {
        margin: 0px 6px 8px 6px;
        float: left;
    }

    .rtl .mega-menubg.hor-mega-menu ul {
        margin-right: 0;
        padding-right: 0;
    }

    .rtl .desktop-logo {
        margin-right: 2rem !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1162px) {
    .rtl .horizontalMenu>.horizontalMenu-list>li a.menuhomeicon {
        padding-right: 22px;
        padding-left: 22px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .rtl .horizontalMenu>.horizontalMenu-list>li>a {
        padding: 10px 10px 10px 10px;
    }
}

@media (max-width: 767px) {
    .rtl .desktop-logo-1 {
        margin-right: 0;
    }

    .rtl .horizontalMenucontainer .header-brand {
        margin-right: 3rem;
    }

    .rtl .main-header-left .header-brand {
        right: 0;
        left: 0;
    }
}

@media (min-width: 992px) {
    .rtl .side-badge {
        left: 17px;
    }
}

.rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu .sub-menu-sub:after {
    left: 14px;
}

.rtl .sub-menu li a:before {
    right: 15px;
}

.rtl .mega-menubg li a:before {
    right: 9px;
}

.rtl .horizontalMenu>.horizontalMenu-list>li>a i.horizontal-icon {
    margin-left: 0;
}

.rtl .hor-icon {
    margin-left: 3px;
}

.rtl .horizontal-badge {
    margin-right: 1px;
}

.rtl .mega-menubg.hor-mega-menu h3 {
    padding: 0 2px 0 20px;
}

.rtl .horizontalMenucontainer .side-menu__icon {
    margin-left: 7px;
}

@media only screen and (max-width: 991px) {
    .rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a {
        padding: 10px 80px 10px 0px;
    }

    .rtl .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a:before {
        right: 62px;
    }
}

.rtl .main-iconbar {
    right: 0;
}

.rtl .main-iconbar-logo::after {
    right: 20px;
}

.rtl .main-iconbar-aside {
    border-left: 1px solid #d6d6e6;
    right: 64px;
}

@media (min-width: 1200px) {
    .rtl .main-iconbar-aside {
        border-left: 0;
    }
}

@media (min-width: 1200px) {
    .rtl .main-iconbar-aside.show+.main-content {
        margin-right: 294px;
    }
}

.rtl .main-iconbar-header {
    padding-right: 0;
}

@media (min-width: 1200px) {
    .rtl .main-iconbar-toggle-menu::before {
        right: -1px;
        border-right: 2px solid #323251;
    }
}

.rtl .main-iconbar-body {
    padding-right: 0;
}

.rtl .main-iconbar-body .nav {
    border-right: 2px solid #f0f0f8;
    padding-right: 20px;
}

.rtl .main-iconbar-body .nav-link i {
    margin-left: 10px;
}

.rtl .main-iconbar-body .nav-link.with-sub::after {
    margin-right: auto;
}

@media (min-width: 992px) {
    .rtl .main-iconbar-body .nav-link.with-sub::after {
        margin-right: 4px;
    }
}

@media (max-width: 991.98px) {
    .rtl .main-content-left-show .main-content-left {
        right: 0;
        border-left: 1px solid #ededf5;
    }
}

.rtl .main-content .container,
.rtl .main-content .container-fluid {
    padding-right: 20px;
    padding-left: 20px;
}

.rtl .main-content-right {
    padding-right: 25px;
    margin-right: 25px;
}

.rtl .main-content.horizontal-content {
    padding-top: 0 !important;
    margin-right: 0;
}

@media (min-width: 992px) {
    .rtl .main-content {
        padding-top: 0 !important;
        margin-right: 240px;
        margin-left: inherit;
    }

    .rtl .main-body.main-sidebar-hide .main-content {
        margin-right: 80px !important;
    }
}

.rtl .main-content-left-components {
    border-left: 1px solid #f0f0f8;
}

.rtl .main-content-choose-demo .card figcaption {
    right: 0;
    left: 0;
}

.rtl .main-header-profile .main-img-user::after {
    left: 6px;
}

.rtl .main-profile-menu .profile-user img {
    margin-right: 0;
}

.rtl .main-content-left-profile {
    padding-right: 0;
    padding-left: 0;
}

@media (min-width: 992px) {
    .rtl .main-content-left-profile {
        padding-left: 20px;
        border-left: 1px solid #ededf5;
    }
}

@media (min-width: 1200px) {
    .rtl .main-content-left-profile {
        padding-left: 25px;
    }
}

.rtl .main-profile-social-list .media-body {
    margin-right: 20px;
}

@media (min-width: 576px) {
    .rtl .main-content-body-profile .nav {
        padding: 18px 30px 17px 14px;
    }
}

@media (min-width: 992px) {
    .rtl .main-content-body-profile .nav {
        padding-right: 20px;
    }
}

@media (min-width: 1200px) {
    .rtl .main-content-body-profile .nav {
        padding-right: 25px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .rtl .main-content-body-profile .main-nav-line .nav-link.active::before {
        right: 0;
        left: 0;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .rtl .main-content-body-profile .main-nav-line .nav-link+.nav-link {
        margin-right: 20px;
    }
}

.rtl .main-profile-body {
    padding: 15px 0 0;
}

@media (min-width: 992px) {
    .rtl .main-profile-body {
        padding: 25px 20px 0 0px;
    }
}

@media (min-width: 1200px) {
    .rtl .main-profile-body {
        padding-right: 25px;
    }
}

.rtl .main-profile-view-info {
    right: 0;
}

.rtl .main-profile-view-info span {
    margin-right: 5px;
}

.rtl .main-profile-work-list .media-body {
    margin-right: 20px;
}

.rtl .main-profile-contact-list .media-body {
    margin-right: 25px;
}

@media (max-width: 1134px) {
    .rtl .main-profile-body .media.profile-footer .media-body {
        padding: 3px 0 15px 0;
    }
}

@media (max-width: 578px) {
    .rtl .main-profile-menu .profile-user img {
        margin-right: 0;
    }
}

.rtl .profile-user .profile-edit {
    left: 0;
}

.rtl .main-profile-body .card-header {
    padding-right: 0;
}

.rtl .drop-down-profile span.assigned-task {
    left: 0;
    right: inherit;
}

@media (max-width: 575px) {
    .rtl .main-content-body-profile .main-nav-line .nav-link.active::before {
        right: 0px;
    }
}

@media (max-width: 767.98px) {
    .rtl .main-profile-menu .profile-user img {
        margin-right: 0 !important;
    }
}

.rtl .profile.navtab-custom {
    padding: 11px 0 10px 0;
}

.rtl .profile.navtab-custom li a {
    margin-left: 7px;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
}

.rtl .profile-cover__img {
    right: 50px;
}

@media (min-width: 601px) {
    .rtl .profile-cover__info .nav {
        padding: 15px 170px 10px 0px;
    }
}

.rtl .profile-cover__info .nav li:not(:first-child) {
    margin-right: 30px;
    padding-right: 30px;
    border-right: 1px solid rgba(238, 238, 238, 0.2);
}

@media (min-width: 769px) {
    .rtl .main-nav-line .nav-link+.nav-link {
        margin-right: 5px;
    }
}

@media (max-width: 768px) {
    .rtl .main-nav-line .nav-link+.nav-link {
        margin-right: 0 !important;
    }

    .rtl .profile-cover__img {
        right: 0;
        left: 0;
    }
}

.rtl .profile-online {
    left: -10px;
    right: inherit;
}

.rtl a.option-dots {
    margin-left: 5px;
    margin-right: inherit;
}

.rtl .main-sidebar-body .nav-sub-item {
    margin-right: 55px;
}

@media (min-width: 992px) {
    .rtl .main-body-sidebar .main-header .container-fluid {
        padding-right: 20px;
        padding-left: 20px;
    }
}

.rtl .main-sidebar {
    border-left: 0 solid #d8dfea;
}

@media (max-width: 991.98px) {
    .rtl .main-sidebar {
        right: 0;
    }
}

@media (min-width: 992px) {
    .rtl .main-sidebar-hide .main-sidebar-body .nav-link {
        margin-left: 0;
    }
}

.rtl .main-sidebar-sticky {
    right: 0;
}

@media (min-width: 992px) {
    .rtl .main-sidebar-sticky+.main-content {
        margin-right: 220px;
    }
}

.rtl .main-sidebar .nav-pills-circle.nav .nav-item .dropdown-menu {
    right: -30px;
}

.rtl .main-sidebar .nav-pills-circle.nav .nav-item .dropdown-menu .dropdown-item i {
    margin-left: 3px;
}

.rtl .main-sidebar-loggedin .media-body {
    margin-right: 12px;
}

.rtl .main-sidebar-body .nav-item.active .nav-link .nav-badge {
    left: 12px !important;
}

.rtl .main-sidebar-body .nav-sub {
    margin-right: 32px;
}

.rtl .main-sidebar-body .nav-item+.nav-item::before {
    right: 30px;
    left: 0;
}

.rtl .nav-link.with-sub .angle {
    left: 23px;
}

.rtl .nav-link.with-sub .side-badge {
    left: 23px;
}

.rtl .main-sidebar-body .nav-sub {
    margin: 0 7px 0 0px;
}

.rtl .main-sidebar-body .nav-sub-item .nav-sub {
    padding: 0 18px 0 0px;
    border-left-width: 1px;
    margin-right: 0;
}

.rtl .main-sidebar-body .nav-sub-link.with-sub::after {
    margin-right: auto;
}

@media (min-width: 992px) {
    .rtl .main-sidebar-body .nav-sub-link.with-sub::after {
        margin-right: 4px;
    }
}

.rtl .main-sidebar-body .nav-label {
    padding: 20px 29px 10px;
}

.rtl .main-sidebar-body .nav-sub li .nav-sub-link::after {
    right: -30px;
}

.rtl .main-sidebar-body .nav-label:after {
    right: 13px;
}

@media (min-width: 992px) {
    .rtl .main-sidebar-hide .main-sidebar-body .nav-link i.typcn {
        margin-left: 0;
    }
}

.rtl .sidebar-right .main-nav-line .nav-link {
    padding: 10px 21px 10px 18px;
}

.rtl .main-sidebar-body .nav-sub-item {
    margin-right: 0;
    text-align: right;
    padding: 2.2px 52px 2.2px 25px;
}

.rtl .app-sidebar__user {
    background-position: right;
}

.rtl .main-sidebar-loggedin {
    padding: 20px 20px 15px;
}

.rtl .main-sidebar-loggedin .media-body {
    margin-right: 13px;
}

.rtl .main-sidebar-body .nav-item.active:before {
    right: 0px;
}

.rtl .main-sidebar-body .nav .nav-item .nav-sub:before {
    right: 0px;
}

.rtl .main-sidebar-body .nav-sub .nav-sub-item .nav-sub-link:before::hover {
    right: -27px;
}

.rtl .main-sidebar .search .btn {
    left: 0;
}

.rtl .sidebar {
    border-right: 1px solid #ededf5;
}

.rtl .tabs-menu ul li a {
    padding: 10px 13px 11px 12px;
}

.rtl .sidebar .nav.panel-tabs .side-menu__icon {
    margin-left: 2px;
}

.rtl .main-donut-chart .slice {
    right: 0;
}

.rtl .legend {
    margin-left: 9px;
}

.rtl .dot-label {
    right: 9px;
}

.rtl .morris-wrapper-demo {
    margin-right: -15px;
    margin-left: -15px;
}

.rtl .chart-legend {
    right: 0;
    left: 0;
}

.rtl .chart-legend div+div {
    margin-right: 15px;
}

.rtl .chart-legend span {
    margin-left: 5px;
}

@media (min-width: 576px) {
    .rtl .chart-legend div+div {
        margin-right: 30px;
    }
}

@media (min-width: 576px) {
    .rtl .chart-legend span {
        margin-left: 10px;
    }
}

.rtl .chart-wrapper {
    margin-right: -13px;
    margin-left: -13px;
}

@media (min-width: 576px) {
    .rtl .chart-wrapper {
        margin-right: -10px;
        margin-left: -15px;
    }
}

.rtl table.dataTable thead .sorting_asc::after,
.rtl table.dataTable thead .sorting_desc::after {
    left: 10px;
}

.rtl table.dataTable thead .sorting::before,
.rtl table.dataTable thead .sorting::after {
    left: 10px;
}

.rtl table.dataTable.dtr-inline.collapsed>tbody>tr[role=row]>td:first-child::before {
    right: 7px;
}

.rtl .dataTables_wrapper .dataTables_length {
    text-align: right;
}

.rtl .dataTables_wrapper .dataTables_length .select2-container--default {
    margin-left: 5px;
}

.rtl .dataTables_wrapper .dataTables_filter {
    text-align: right;
}

.rtl .dataTables_wrapper .dataTables_filter input {
    margin-right: 0;
    margin-left: inherit;
}

.rtl .dataTables_wrapper .dataTables_info {
    text-align: right;
}

.rtl .dataTables_wrapper .dataTables_paginate {
    text-align: right;
}

.rtl .dataTables_wrapper .dataTables_paginate .paginate_button+.paginate_button {
    margin-right: 0px;
}

.rtl .dataTables_wrapper .dataTables_paginate .paginate_button.previous {
    margin-left: 3px;
}

.rtl .dataTables_wrapper .dataTables_paginate .paginate_button.next {
    margin-right: 3px;
}

@media (min-width: 576px) {
    .rtl .dataTables_wrapper .dataTables_filter {
        float: left;
    }
}

@media (min-width: 576px) {
    .rtl .dataTables_wrapper .dataTables_info {
        float: right;
    }
}

@media (min-width: 576px) {
    .rtl .dataTables_wrapper .dataTables_paginate {
        float: left;
    }
}

@media (max-width: 414px) {
    .rtl div.dataTables_wrapper div.dataTables_filter input {
        margin-right: -12px;
    }
}

.rtl table.dataTable tfoot th,
.rtl table.dataTable tfoot td {
    padding: 10px 18px 6px 18px;
}

.rtl .hoverable-table .btn-primary {
    margin-right: 98px;
}

.rtl .ui-datepicker {
    margin: 1px 0 0;
}

.rtl .ui-datepicker .ui-datepicker-header {
    padding: 0 0 5px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.rtl .ui-datepicker .ui-datepicker-header .ui-datepicker-next:before {
    left: 5px;
    right: auto;
    transform: rotate(180deg);
}

.rtl .ui-datepicker .ui-datepicker-header .ui-datepicker-prev:before {
    right: 5px;
    left: auto;
    transform: rotate(180deg);
}

.rtl .ui-datepicker .ui-datepicker-calendar {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.rtl .datetimepicker table th.prev span,
.rtl .datetimepicker table th.next span {
    left: 0;
    right: 0;
    transform: rotate(180deg);
}

.rtl .datetimepicker table th.prev span::before {
    right: 0;
}

.rtl .datetimepicker table th.next span::before {
    left: 0;
}

.rtl .main-datetimepicker>.datepicker_header a:nth-child(2)::before {
    right: 0;
}

.rtl .main-datetimepicker>.datepicker_header a:last-child::before {
    left: 0;
}

.rtl .main-datetimepicker>.datepicker_inner_container>.datepicker_timelist {
    border-right: 1px solid #ededf5;
}

.rtl .main-datetimepicker>.datepicker_inner_container>.datepicker_timelist>div.timelist_item {
    margin: 0 1px 0 0;
}

.rtl .wizard>.steps>ul li .title {
    margin-right: 5px;
    margin-left: inherit;
}

.rtl .wizard>.steps>ul li+li {
    margin-right: 5px;
    margin-left: inherit;
}

.rtl .wizard>.actions a {
    margin-left: 10px;
    margin-right: inherit;
}

.rtl .wizard.vertical>.steps ul li+li {
    margin-right: 10px;
    margin-left: inherit;
}

@media (min-width: 576px) {
    .rtl .wizard>.steps>ul li .title {
        margin-right: 10px;
        margin-left: inherit;
    }
}

@media (min-width: 576px) {
    .rtl .wizard>.steps>ul li+li {
        margin-right: 20px;
        margin-left: inherit;
    }
}

@media (min-width: 992px) {
    .rtl .wizard>.steps>ul li+li {
        margin-right: 30px;
        margin-left: inherit;
    }
}

@media (min-width: 576px) {
    .rtl .wizard.vertical>.steps {
        float: right;
    }
}

@media (min-width: 576px) {
    .rtl .wizard.vertical>.steps ul li+li {
        margin-right: 0;
        margin-left: inherit;
    }
}

@media (min-width: 576px) {
    .rtl .wizard.vertical>.content {
        float: left;
        border-right: 1px solid #ededf5;
    }
}

@media (min-width: 576px) {
    .rtl .wizard.vertical>.actions {
        float: left;
        border-right: 1px solid #ededf5;
    }
}

.rtl .wizard-style-1>.steps>ul a .title,
.rtl .wizard-style-1>.steps>ul a:hover .title,
.rtl .wizard-style-1>.steps>ul a:active .title {
    margin-left: 20px;
    margin-right: 20px;
}

.rtl .wizard-style-2>.steps>ul a .title,
.rtl .wizard-style-2>.steps>ul a:hover .title,
.rtl .wizard-style-2>.steps>ul a:active .title {
    margin-left: 20px;
    margin-right: inherit;
}

.rtl #jqvmap1_ca_pin {
    margin-right: -2%;
}

.rtl #jqvmap1_ct_pin {
    margin-right: -0.25%;
}

.rtl #jqvmap1_fl_pin {
    margin-right: 5%;
}

.rtl #jqvmap1_id_pin {
    margin-right: -1%;
}

.rtl #jqvmap1_ky_pin {
    margin-right: 2%;
}

.rtl #jqvmap1_la_pin {
    margin-right: -2%;
}

.rtl #jqvmap1_mi_pin {
    margin-right: 3%;
}

.rtl #jqvmap1_mn_pin {
    margin-right: -2%;
}

.rtl #jqvmap1_nh_pin {
    margin-right: -0.25%;
}

.rtl #jqvmap1_ok_pin,
.rtl #jqvmap1_va_pin {
    margin-right: 2%;
}

.rtl #jqvmap1_wv_pin {
    margin-right: -1%;
}

.rtl .ql-bubble .ql-toolbar {
    padding: 0 8px 8px;
}

.rtl .ql-bubble .ql-toolbar .ql-formats {
    margin: 8px 0 0 8px;
}

.rtl .ql-bubble .ql-toolbar .ql-formats:first-child {
    margin-right: 0;
}

@media (min-width: 576px) {
    .rtl .ql-bubble .ql-toolbar .ql-formats {
        margin: 8px 0 0;
    }

    .rtl .ql-bubble .ql-toolbar .ql-formats:first-child {
        margin-right: 0;
    }
}

.rtl .ql-snow.ql-toolbar {
    padding: 5px 10px 10px !important;
}

.rtl .ql-snow.ql-toolbar .ql-formats {
    margin-top: 5px;
    margin-left: 5px;
}

.rtl .ql-snow .ql-formats button+button {
    margin: 0 2px 0 0;
}

.rtl .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    left: 3px;
    right: inherit;
}

.rtl .ql-editor {
    text-align: right;
}

.rtl .ql-snow.ql-toolbar button,
.rtl .ql-snow .ql-toolbar button {
    float: right;
}

.rtl .ql-snow .ql-picker-label {
    padding-right: 10px;
}

@media (min-width: 768px) {
    .rtl .ql-scrolling-demo {
        padding-right: 0;
    }
}

.rtl .parsley-errors-list li {
    margin: 5px 0 0;
}

.rtl .parsley-style-1 .parsley-checkbox.parsley-error {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.rtl .parsley-style-1 .parsley-select.parsley-error .select2-container--default .select2-selection--single {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .parsley-style-1 .parsley-errors-list.filled {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.rtl .irs-bar-edge {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .irs-line-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .irs-line-right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .irs-slider:before {
    right: 50%;
    margin-right: -1px;
}

.rtl .irs-modern .irs-slider::before {
    border-right: 1px solid #38cab3;
    border-left: 1px solid #38cab3;
    margin-right: -2px;
}

.rtl .irs-outline .irs-slider::before {
    margin-right: -4px;
}

.rtl .select2-container--default .select2-selection--multiple .select2-selection__choice {
    margin-left: 4px;
    padding: 3px 20px 3px 10px;
    float: right;
}

.rtl .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    right: 7px;
}

.rtl .select2-container--default .select2-search--inline .select2-search__field {
    padding-right: 7px;
}

.rtl .select2-container--default.select2-container--disabled .select2-selection__choice {
    padding-right: 10px;
}

.rtl .select2-container--open .select2-dropdown--above {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.rtl .select2-container--open .select2-dropdown--below {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .select2-lg .select2-container .select2-selection--single .select2-selection__rendered {
    padding-right: 4px !important;
    padding-left: inherit;
}

.rtl .sp-picker-container {
    border-right: 0;
}

.rtl .sp-dd::before {
    right: 0;
}

.rtl .card--calendar .card--events .list-group-item {
    padding: 13px 40px 13px 20px;
}

.rtl .card--calendar .card--events {
    margin-right: -20px;
    margin-left: -20px;
}

.rtl .card--calendar .card--events .list-group-item {
    padding: 13px 40px 13px 20px;
}

.rtl .card--events .list-group-item {
    padding: 10px 40px 10px 18px;
    border-left-width: 0;
    border-right-width: 0;
}

.rtl .card--events .event-indicator {
    right: 20px;
}

.rtl .card--events .event-user .main-img-user+.main-img-user {
    margin-right: -6px;
}

.rtl .card--events .event-user a:last-child {
    margin-right: 10px;
}

.rtl .ui-datepicker .ui-datepicker-calendar td:last-child {
    border-left: 0;
}

.rtl .ui-datepicker-multi .ui-datepicker-group {
    float: right;
}

.rtl .ui-datepicker-multi .ui-datepicker-group .ui-datepicker-prev::before {
    right: 10px;
}

.rtl .ui-datepicker-multi .ui-datepicker-group .ui-datepicker-next::before {
    left: 10px;
}

.rtl .ui-datepicker-multi .ui-datepicker-group-last {
    padding-left: 0;
}

.rtl .main-content-calendar .container,
.rtl .main-content-calendar .container-fluid {
    padding: 20px 0 0;
}

.rtl .main-nav-calendar-event .nav-link i {
    margin-left: 10px;
}

.rtl .main-nav-calendar-event .nav-link.exclude::before {
    right: 0;
    left: 0;
}

@media (min-width: 992px) {
    .rtl .main-content-body-calendar {
        padding-right: 25px;
    }
}

.rtl .main-calendar .fc-view .fc-day-number {
    margin: 2px 0px 0 2px;
}

.rtl .main-calendar .fc-view.fc-listMonth-view .fc-scroller,
.rtl .main-calendar .fc-view.fc-listWeek-view .fc-scroller {
    padding: 0 20px 20px;
}

.rtl .main-calendar .fc-view.fc-listMonth-view .fc-list-item>td.fc-list-item-time,
.rtl .main-calendar .fc-view.fc-listWeek-view .fc-list-item>td.fc-list-item-time {
    border-left: 1px solid #f0f0f8;
}

.rtl .main-calendar .fc-view.fc-listMonth-view .fc-list-item>td.fc-list-item-title,
.rtl .main-calendar .fc-view.fc-listWeek-view .fc-list-item>td.fc-list-item-title {
    border-left: 1px solid #f0f0f8;
}

.rtl .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
.rtl .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
    margin-right: 20%;
}

.rtl .main-calendar .fc-view.fc-listMonth-view .fc-list-item-time,
.rtl .main-calendar .fc-view.fc-listWeek-view .fc-list-item-time {
    padding: 12px 15px 2px;
}

.rtl .main-calendar .fc-view.fc-listMonth-view .fc-list-item-title,
.rtl .main-calendar .fc-view.fc-listWeek-view .fc-list-item-title {
    padding: 0 15px 12px;
}

.rtl .main-calendar .fc-view.fc-listMonth-view .fc-list-item-title .fc-desc,
.rtl .main-calendar .fc-view.fc-listWeek-view .fc-list-item-title .fc-desc {
    font-size: 13px;
    line-height: 1.375;
    display: block;
}

.rtl .main-calendar .fc-time-grid-event {
    border-left-width: 0;
}

@media (max-width: 575px) {
    .rtl .main-calendar .fc-header-toolbar button.fc-today-button {
        padding-right: 10px;
        padding-left: 10px;
    }

    .rtl .main-calendar .fc-header-toolbar button.fc-month-button::before,
    .rtl .main-calendar .fc-header-toolbar button.fc-agendaWeek-button::before,
    .rtl .main-calendar .fc-header-toolbar button.fc-agendaDay-button::before,
    .rtl .main-calendar .fc-header-toolbar button.fc-listWeek-button::before,
    .rtl .main-calendar .fc-header-toolbar button.fc-listMonth-button::before {
        right: 10px;
    }

    .rtl .main-calendar .fc-header-toolbar button.fc-listWeek-button::before,
    .rtl .main-calendar .fc-header-toolbar button.fc-listMonth-button::before {
        right: 11px;
    }
}

@media (max-width: 991.98px) {

    .rtl .main-calendar .fc-view>table>thead th:first-child,
    .rtl .main-calendar .fc-view>table>thead td:first-child {
        border-left-width: 0;
    }

    .rtl .main-calendar .fc-view>table>thead th:last-child,
    .rtl .main-calendar .fc-view>table>thead td:last-child {
        border-right-width: 0;
    }
}

@media (max-width: 991.98px) {
    .rtl .main-calendar .fc-view>table>tbody>tr>td:first-child {
        border-left-width: 0;
    }

    .rtl .main-calendar .fc-view>table>tbody>tr>td:last-child {
        border-right-width: 0;
    }
}

@media (min-width: 576px) {

    .rtl .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
    .rtl .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
        margin-right: 15%;
    }
}

@media (min-width: 768px) {

    .rtl .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
    .rtl .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
        margin-right: 12%;
    }
}

@media (min-width: 992px) {

    .rtl .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
    .rtl .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
        margin-right: 10%;
    }
}

@media (min-width: 1200px) {

    .rtl .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
    .rtl .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
        margin-right: 8%;
    }
}

@media (min-width: 576px) {
    .rtl .main-calendar .fc-event {
        border-left-width: 2px;
    }
}

.rtl .main-modal-calendar-schedule .modal-content>.close {
    left: 25px;
}

.rtl .main-modal-calendar-schedule .form-group-date>i {
    margin-left: 10px;
}

.rtl .main-modal-calendar-event .modal-header {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.rtl .main-modal-calendar-event .nav-modal-event .nav-link+.nav-link {
    margin-right: 15px;
}

@media (max-width: 320px) {
    .rtl .main-calendar .fc-header-toolbar .fc-right {
        justify-content: right;
        margin-top: 20px;
    }

    .rtl .main-calendar .fc-header-toolbar .fc-left {
        justify-content: right;
    }
}

.rtl .main-nav-line-chat {
    padding-right: 20px;
}

.rtl .main-nav-line-chat .nav-link+.nav-link {
    margin-right: 5px;
}

.rtl .main-chat-contacts-wrapper .main-img-user::after,
.rtl .main-chat-list .main-img-user::after,
.rtl .main-chat-header .main-img-user::after,
.rtl .main-chat-body .main-img-user::after {
    left: 1px;
}

.rtl .main-chat-contacts-wrapper .main-content-label,
.rtl .main-chat-contacts-wrapper .card-table-two .card-title {
    padding-right: 20px;
}

@media (min-width: 992px) {
    .rtl .main-chat-contacts-wrapper {
        padding: 10px 0px 10px 15px;
    }
}

.rtl .card-table-two .main-chat-contacts-wrapper .card-title,
.rtl .main-chat-contacts-wrapper .card-dashboard-eight .card-title,
.rtl .card-dashboard-eight .main-chat-contacts-wrapper .card-title {
    padding-right: 20px;
}

.rtl .main-chat-contacts {
    padding-right: 20px;
}

.rtl .main-chat-list .media {
    border-right: 0;
    border-left: 0;
}

.rtl .main-chat-list .media::after {
    right: -1px;
}

.rtl .main-chat-list .media+.media::before {
    right: 65px;
    left: 0;
}

.rtl .main-chat-list .media.selected {
    border-right: 1px solid #38cab3;
    border-left: inherit;
}

.rtl .main-chat-list .main-img-user span {
    right: -2px;
}

.rtl .main-chat-list .media-body {
    margin-right: 15px;
    margin-left: inherit;
}

.rtl .main-chat-header .nav {
    margin-right: auto;
    margin-left: inherit;
}

.rtl .main-chat-header .nav-link+.nav-link {
    margin-right: 10px;
    margin-left: inherit;
}

.rtl .main-chat-msg-name {
    margin-right: 15px;
}

.rtl .main-chat-body .media.flex-row-reverse .media-body {
    margin-right: 0;
    margin-left: 20px;
}

.rtl .main-chat-body .media-body {
    margin-right: 20px;
}

@media (min-width: 576px) {
    .rtl .main-chat-body .media.flex-row-reverse .media-body {
        margin-right: 55px;
    }
}

@media (min-width: 576px) {
    .rtl .main-chat-body .media-body {
        margin-left: 55px;
    }
}

.rtl .main-chat-footer {
    right: 0;
    left: 0;
}

.rtl .main-chat-footer .nav-link+.nav-link {
    margin-right: 10px;
}

.rtl .main-chat-footer .nav-link:last-child {
    margin-right: 0;
}

@media (min-width: 992px) {
    .rtl .main-chat-footer {
        right: auto;
        left: auto;
    }
}

@media (min-width: 576px) {
    .rtl .main-chat-footer .form-control {
        margin-right: 20px;
    }
}

@media (min-width: 768px) {
    .rtl .main-chat-footer .form-control {
        margin: 0 0px 0 20px;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    .rtl .app.sidebar-mini .main-chat-footer {
        margin-right: 0px;
    }

    .rtl .app.sidebar-mini.sidenav-toggled .main-chat-footer {
        margin-right: 0;
    }
}

@media (max-width: 380px) and (min-width: 305px) {
    .rtl .main-nav-line-chat .nav-link+.nav-link {
        margin-right: 0px;
    }
}

.rtl #action_menu_btn {
    left: 36px;
}

.rtl .action_menu {
    left: 15px;
}

.rtl .action-header {
    padding: 15px 17px 15px 13px;
}

.rtl .ah-actions {
    float: left !important;
}

.rtl .chat .dropdown-menu.dropdown-menu-right.show {
    right: 0px;
}

.rtl .chat .msb-reply textarea {
    margin-right: -42px;
}

.rtl .chat-box-single-line {
    margin: 7px 0 30px;
}

.rtl .chat .video_cam {
    margin-right: 50px;
}

.rtl .chat .video_cam span {
    margin-left: 20px;
}

.rtl .chat .msg_cotainer {
    margin-right: 10px;
}

.rtl .chat .msg_cotainer_send {
    margin-left: 10px;
}

.rtl .chat .msg_time {
    right: 0;
}

.rtl .chat .msg_time_send {
    left: 0;
}

@media (max-width: 449px) {
    .rtl .actions {
        float: left !important;
    }
}

.rtl .main-chat-footer .form-control {
    padding-right: 10px !important;
    padding-left: 0;
}

.rtl .main-msg-wrapper.left:after,
.rtl .main-msg-wrapper.left:before {
    left: 100%;
    right: inherit;
}

.rtl .main-msg-wrapper.left:nth-child(1):before {
    border-left-color: #38cab3;
}

.rtl .main-msg-wrapper.left:nth-child(1):after {
    border-left-color: var(--primary-50);
    border-right-color: transparent !important;
}

.rtl .main-msg-wrapper.right:after,
.rtl .main-msg-wrapper.right:before {
    right: 100%;
}

.rtl .main-msg-wrapper.right:nth-child(1):before {
    border-right-color: rgba(56, 202, 179, 0.08);
}

.rtl .main-msg-wrapper.right:nth-child(1):after {
    border-right-color: var(--primary);
    border-left-color: transparent;
}

.rtl .main-content-left-contacts .main-content-breadcrumb,
.rtl .main-content-left-contacts .main-content-title {
    padding-right: 20px;
}

@media (min-width: 992px) {

    .rtl .main-content-left-contacts .main-content-breadcrumb,
    .rtl .main-content-left-contacts .main-content-title {
        padding-right: 0;
    }
}

.rtl .main-contact-label {
    padding-right: 20px;
    margin-right: -10px;
}

.rtl .main-contact-label::after {
    right: 0;
    left: 0;
}

@media (min-width: 992px) {
    .rtl .main-contact-label {
        padding-right: 30px;
    }
}

.rtl .main-contact-item {
    border-right-width: 0;
}

.rtl .main-contact-item+.main-contact-item::before {
    right: 0px;
    left: 0;
}

.rtl .main-contact-item.selected {
    border-left-color: #38cab3;
}

.rtl .main-contact-body {
    margin-right: 15px;
}

.rtl .main-contact-info-header {
    padding-right: 20px;
}

.rtl .main-contact-info-header .main-img-user a {
    left: 0;
}

.rtl .main-contact-info-header .media-body .nav-link i {
    margin-left: 5px;
}

.rtl .main-contact-info-header .media-body .nav-link+.nav-link {
    margin-right: 10px;
}

@media (min-width: 992px) {
    .rtl .main-contact-info-header {
        padding-right: 30px;
    }
}

@media (min-width: 992px) {
    .rtl .main-contact-info-header .media-body {
        margin-right: 30px;
    }
}

@media (min-width: 576px) {
    .rtl .main-contact-info-header .media-body .nav-link i {
        margin-left: 7px;
    }
}

@media (min-width: 576px) {
    .rtl .main-contact-info-header .media-body .nav-link+.nav-link {
        margin-right: 25px;
    }
}

.rtl .main-contact-action {
    left: 20px;
    right: inherit;
}

@media (min-width: 992px) {
    .rtl .main-contact-action {
        left: 20px;
    }
}

.rtl .main-contact-info-body {
    padding-right: 20px;
}

.rtl .main-contact-info-body .media+.media::before {
    right: -19px;
    left: 0;
}

.rtl .main-contact-info-body .media:last-child {
    margin-bottom: 15px;
}

.rtl .main-contact-info-body .media-body {
    margin-right: 0;
}

.rtl .main-contact-item .main-img-user::after {
    left: 0;
}

.rtl .contact-icon {
    margin-right: 3px;
    margin-left: inherit;
}

.rtl .page-signin-style:before {
    right: 0;
}

.rtl .page-signin-style {
    right: 0;
}

.rtl .main-column-signup-left .btn,
.rtl .main-column-signup-left .sp-container button {
    padding-right: 25px;
    padding-left: 25px;
}

.rtl .sp-container .main-column-signup-left button {
    padding-right: 25px;
    padding-left: 25px;
}

@media (min-width: 992px) {
    .rtl .main-column-signup {
        border-right: 1px solid #e3e3e3;
    }
}

.rtl .main-signup-header .row>div .btn i,
.rtl .main-signup-header .row>div .sp-container button i {
    margin-left: 5px;
}

.rtl .sp-container .main-signup-header .row>div button i {
    margin-left: 5px;
}

.rtl .construction .input-group-prepend,
.rtl .construction .input-group-append {
    left: 0;
}

.rtl .construction .input-group>.input-group-append>.btn {
    border-radius: 0 0px 4px 4px;
    float: left;
}

.rtl .construction .input-group>.form-control:not(:last-child),
.rtl .construction .input-group>.custom-select:not(:last-child) {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.rtl .main-content-left-invoice .main-content-breadcrumb,
.rtl .main-content-left-invoice .main-content-title {
    padding-right: 20px;
}

@media (min-width: 992px) {
    .rtl .main-content-left-invoice {
        border-left: 1px solid #ededf5;
    }
}

@media (min-width: 992px) {

    .rtl .main-content-left-invoice .main-content-breadcrumb,
    .rtl .main-content-left-invoice .main-content-title {
        padding-right: 0;
    }
}

.rtl .main-invoice-list .media+.media::before {
    right: 55px;
    left: 0;
}

.rtl .main-invoice-list .media-body {
    margin-right: 15px;
}

.rtl .main-invoice-list .selected {
    border-left-color: #38cab3;
}

.rtl .main-content-mail .container,
.rtl .main-content-mail .container-fluid {
    padding: 20px 0 0;
}

@media (min-width: 992px) {
    .rtl .main-content-left-mail .btn-compose {
        margin-left: 0;
    }
}

.rtl .main-mail-menu {
    padding-left: 0;
}

@media (min-width: 992px) {
    .rtl .main-mail-menu {
        padding-left: 0;
    }
}

.rtl .main-mail-header>div:last-child>span {
    margin-left: 10px;
}

@media (min-width: 992px) {
    .rtl .main-mail-header {
        padding: 0 25px 0 0px;
    }
}

.rtl .main-mail-header .btn-group .btn+.btn,
.rtl .main-mail-header .btn-group .sp-container button+.btn {
    margin-right: -2px;
}

.rtl .sp-container .main-mail-header .btn-group button+.btn,
.rtl .main-mail-header .btn-group .sp-container .btn+button,
.rtl .sp-container .main-mail-header .btn-group .btn+button,
.rtl .main-mail-header .btn-group .sp-container button+button,
.rtl .sp-container .main-mail-header .btn-group button+button {
    margin-right: -2px;
}

.rtl .main-mail-options {
    padding: 5px 20px 5px 10px;
}

@media (min-width: 992px) {
    .rtl .main-mail-options {
        padding-right: 25px;
    }
}

.rtl .main-mail-item .main-img-user,
.rtl .main-mail-item .main-avatar {
    margin-left: 15px;
    margin-right: inherit;
}

.rtl .main-mail-checkbox {
    margin-left: 15px;
}

.rtl .main-mail-star {
    margin-left: 15px;
    margin-right: inherit;
    left: 0;
    right: inherit;
}

@media (min-width: 992px) {
    .rtl .main-mail-body {
        margin-left: 15px;
        margin-right: inherit;
    }
}

.rtl .main-mail-attachment {
    margin-left: 15px;
}

@media (min-width: 992px) {
    .rtl .main-mail-attachment {
        left: 63px;
    }
}

.rtl .main-mail-date {
    left: 15px;
    right: inherit;
    margin-right: auto;
}

.rtl .main-mail-compose {
    right: 0;
}

.rtl .main-mail-compose-header {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.rtl .main-mail-compose-header .nav-link+.nav-link {
    margin-right: 15px;
}

.rtl .main-mail-compose-body {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.rtl .main-mail-compose-body .form-group>div {
    margin-right: 10px;
}

.rtl .main-mail-compose-body .form-group:last-child .btn,
.rtl .main-mail-compose-body .form-group:last-child .sp-container button {
    padding-right: 20px;
    padding-left: 20px;
}

.rtl .sp-container .main-mail-compose-body .form-group:last-child button {
    padding-right: 20px;
    padding-left: 20px;
}

.rtl .main-mail-compose-body .form-group .nav-link+.nav-link {
    margin-right: 15px;
}

.rtl .main-mail-compose-compress,
.rtl .main-mail-compose-minimize {
    right: auto;
    left: 30px;
}

.rtl .main-mail-two .main-header-menu-icon {
    margin-left: 0;
}

.rtl .main-mail-left .main-mail-menu {
    padding-left: 0;
}

.rtl .gutters-xs>.col,
.rtl .gutters-xs>[class*=col-] {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.rtl .border-end {
    border-left: 1px solid #ededf5 !important;
    border-right: inherit !important;
}

.rtl .border-start {
    border-right: 1px solid #ededf5 !important;
}

.rtl .border-end-0 {
    border-left: 0 !important;
}

.rtl .border-start-0 {
    border-right: 0 !important;
}

.rtl .rounded-top {
    border-top-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
}

.rtl .rounded-end {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.rtl .rounded-bottom {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.rtl .rounded-start {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.rtl .bd-e {
    border-left: 1px solid #ededf5;
    border-right: inherit;
}

.rtl .bd-s {
    border-right: 1px solid #ededf5;
    border-left: inherit;
}

.rtl .bd-b {
    border-bottom: 1px solid #ededf5;
}

.rtl .bd-l {
    border-right: 1px solid #ededf5;
    border-left: inherit;
}

.rtl .bd-y {
    border-top: 1px solid #ededf5;
    border-bottom: 1px solid #ededf5;
}

.rtl .bd-x {
    border-right: 1px solid #ededf5;
    border-left: 1px solid #ededf5;
}

.rtl .bd-e-0 {
    border-left: 0;
}

.rtl .bd-b-0 {
    border-bottom: 0 !important;
}

.rtl .bd-s-0 {
    border-right: 0;
}

.rtl .bd-t-0-f {
    border-top: 0 !important;
}

.rtl .bd-e-0-f {
    border-left: 0 !important;
}

.rtl .bd-s-0-f {
    border-right: 0 !important;
}

.rtl .bd-x-0 {
    border-left-width: 0;
    border-right-width: 0;
}

.rtl .bd-x-1 {
    border-left-width: 1px;
    border-right-width: 1px;
}

@media (min-width: 480px) {
    .rtl .bd-xs-e {
        border-left: 1px solid #ededf5;
    }

    .rtl .bd-xs-e {
        border-right: 1px solid #ededf5;
    }

    .rtl .bd-xs-x {
        border-right: 1px solid #ededf5;
        border-left: 1px solid #ededf5;
    }
}

@media (min-width: 576px) {
    .rtl .bd-sm-e {
        border-left: 1px solid #ededf5;
    }

    .rtl .bd-sm-s {
        border-right: 1px solid #ededf5;
    }

    .rtl .bd-sm-x {
        border-right: 1px solid #ededf5;
        border-left: 1px solid #ededf5;
    }
}

@media (min-width: 768px) {
    .rtl .bd-md-e {
        border-left: 1px solid #ededf5;
    }

    .rtl .bd-md-s {
        border-right: 1px solid #ededf5;
    }

    .rtl .bd-md-x {
        border-right: 1px solid #ededf5;
        border-left: 1px solid #ededf5;
    }
}

@media (min-width: 992px) {
    .rtl .bd-lg-enf {
        border-left: 1px solid #ededf5;
    }

    .rtl .bd-lg-s {
        border-right: 1px solid #ededf5;
    }

    .rtl .bd-lg-x {
        border-right: 1px solid #ededf5;
        border-left: 1px solid #ededf5;
    }
}

@media (min-width: 1200px) {
    .rtl .bd-xl-e {
        border-left: 1px solid #ededf5;
    }

    .rtl .bd-xl-s {
        border-right: 1px solid #ededf5;
    }

    .rtl .bd-xl-x {
        border-right: 1px solid #ededf5;
        border-left: 1px solid #ededf5;
    }
}

.rtl .br-ts-0 {
    border-top-right-radius: 0 !important;
}

.rtl .br-bs-0 {
    border-bottom-right-radius: 0 !important;
}

.rtl .br-te-0 {
    border-top-left-radius: 0 !important;
}

.rtl .br-be-0 {
    border-bottom-left-radius: 0 !important;
}

.rtl .br-ts-5 {
    border-top-right-radius: 5px !important;
}

.rtl .br-te-5 {
    border-top-left-radius: 5px !important;
}

.rtl .br-be-5 {
    border-bottom-left-radius: 5px !important;
}

.rtl .br-bs-5 {
    border-bottom-right-radius: 5px !important;
}

.rtl .border-start-primary {
    border-left-color: #38cab3 !important;
}

.rtl .border-start-success {
    border-left-color: #1a9c86 !important;
}

.rtl .border-start-warning {
    border-left-color: #ffbd5a !important;
}

.rtl .me-0 {
    margin-left: 0 !important;
    margin-right: inherit !important;
}

.rtl .ms-0 {
    margin-right: 0 !important;
    margin-left: inherit !important;
}

.rtl .me-1 {
    margin-left: 0.25rem !important;
    margin-right: inherit !important;
}

.rtl .ms-1 {
    margin-right: 0.25rem !important;
    margin-left: inherit !important;
}

.rtl .me-2 {
    margin-left: 0.5rem !important;
    margin-right: inherit !important;
}

.rtl .ms-2 {
    margin-right: 0.5rem !important;
    margin-left: inherit !important;
}

.rtl .me-3 {
    margin-left: 1rem !important;
    margin-right: inherit !important;
}

.rtl .ms-3 {
    margin-right: 1rem !important;
}

.rtl .me-4 {
    margin-left: 1.5rem !important;
    margin-right: inherit !important;
}

.rtl .ms-4 {
    margin-right: 1.5rem !important;
    margin-left: inherit !important;
}

.rtl .me-5 {
    margin-left: 3rem !important;
    margin-right: inherit !important;
}

.rtl .ms-5 {
    margin-right: 3rem !important;
}

.rtl .me-n1 {
    margin-left: -0.25rem !important;
}

.rtl .ms-n1 {
    margin-right: -0.25rem !important;
}

.rtl .me-n2 {
    margin-left: -0.5rem !important;
}

.rtl .ms-n2 {
    margin-right: -0.5rem !important;
}

.rtl .me-n3 {
    margin-left: -1rem !important;
}

.rtl .ms-n3 {
    margin-right: -1rem !important;
}

.rtl .me-n4 {
    margin-left: -1.5rem !important;
}

.rtl .ms-n4 {
    margin-right: -1.5rem !important;
}

.rtl .me-n5 {
    margin-left: -3rem !important;
}

.rtl .ms-sm-n5 {
    margin-right: -3rem !important;
}

.rtl .me-auto {
    margin-left: auto !important;
    margin-right: inherit !important;
}

.rtl .ms-auto {
    margin-right: auto !important;
    margin-left: inherit !important;
}

@media (max-width: 767px) {
    .rtl .me-sm-0 {
        margin-left: 0 !important;
    }

    .rtl .ms-sm-0 {
        margin-right: 0 !important;
    }

    .rtl .me-sm-1 {
        margin-left: 0.25rem !important;
    }

    .rtl .ms-sm-1 {
        margin-right: 0.25rem !important;
    }

    .rtl .me-sm-2 {
        margin-left: 0.5rem !important;
    }

    .rtl .ms-sm-2 {
        margin-right: 0.5rem !important;
    }

    .rtl .me-sm-3 {
        margin-left: 1rem !important;
    }

    .rtl .ms-sm-3 {
        margin-right: 1rem !important;
    }

    .rtl .me-sm-4 {
        margin-left: 1.5rem !important;
    }

    .rtl .ms-sm-4 {
        margin-right: 1.5rem !important;
    }

    .rtl .me-sm-5 {
        margin-left: 3rem !important;
    }

    .rtl .ms-sm-5 {
        margin-right: 3rem !important;
    }

    .rtl .me-sm-n1 {
        margin-left: -0.25rem !important;
    }

    .rtl .ms-sm-n1 {
        margin-right: -0.25rem !important;
    }

    .rtl .me-sm-n2 {
        margin-left: -0.5rem !important;
    }

    .rtl .ms-sm-n2 {
        margin-right: -0.5rem !important;
    }

    .rtl .me-sm-n3 {
        margin-left: -1rem !important;
    }

    .rtl .ms-sm-n3 {
        margin-right: -1rem !important;
    }

    .rtl .me-sm-n4 {
        margin-left: -1.5rem !important;
    }

    .rtl .ms-sm-n4 {
        margin-right: -1.5rem !important;
    }

    .rtl .me-sm-n5 {
        margin-left: -3rem !important;
    }

    .rtl .ms-sm-n5 {
        margin-right: -3rem !important;
    }

    .rtl .me-sm-auto {
        margin-left: auto !important;
    }

    .rtl .ms-sm-auto {
        margin-right: auto !important;
    }
}

@media (min-width: 768px) {
    .rtl .me-md-0 {
        margin-left: 0 !important;
    }

    .rtl .ms-md-0 {
        margin-right: 0 !important;
    }

    .rtl .me-md-1 {
        margin-left: 0.25rem !important;
    }

    .rtl .ms-md-1 {
        margin-right: 0.25rem !important;
    }

    .rtl .me-md-2 {
        margin-left: 0.5rem !important;
    }

    .rtl .ms-md-2 {
        margin-right: 0.5rem !important;
    }

    .rtl .me-md-3 {
        margin-left: 1rem !important;
    }

    .rtl .ms-md-3 {
        margin-right: 1rem !important;
    }

    .rtl .me-md-4 {
        margin-left: 1.5rem !important;
    }

    .rtl .ms-md-4 {
        margin-right: 1.5rem !important;
    }

    .rtl .me-md-5 {
        margin-left: 3rem !important;
    }

    .rtl .ms-md-5 {
        margin-right: 3rem !important;
    }

    .rtl .me-md-n1 {
        margin-left: -0.25rem !important;
    }

    .rtl .ms-md-n1 {
        margin-right: -0.25rem !important;
    }

    .rtl .me-md-n2 {
        margin-left: -0.5rem !important;
    }

    .rtl .ms-md-n2 {
        margin-right: -0.5rem !important;
    }

    .rtl .me-md-n3 {
        margin-left: -1rem !important;
    }

    .rtl .ms-md-n3 {
        margin-right: -1rem !important;
    }

    .rtl .me-md-n4 {
        margin-left: -1.5rem !important;
    }

    .rtl .ms-md-n4 {
        margin-right: -1.5rem !important;
    }

    .rtl .me-md-n5 {
        margin-left: -3rem !important;
    }

    .rtl .ms-md-n5 {
        margin-right: -3rem !important;
    }

    .rtl .me-md-auto {
        margin-left: auto !important;
    }

    .rtl .ms-md-auto {
        margin-right: auto !important;
    }
}

@media (min-width: 992px) {
    .rtl .me-lg-0 {
        margin-left: 0 !important;
    }

    .rtl .ms-lg-0 {
        margin-right: 0 !important;
    }

    .rtl .me-lg-1 {
        margin-left: 0.25rem !important;
    }

    .rtl .ms-lg-1 {
        margin-right: 0.25rem !important;
    }

    .rtl .me-lg-2 {
        margin-left: 0.5rem !important;
    }

    .rtl .ms-lg-2 {
        margin-right: 0.5rem !important;
    }

    .rtl .me-lg-3 {
        margin-left: 1rem !important;
    }

    .rtl .ms-lg-3 {
        margin-right: 1rem !important;
    }

    .rtl .me-lg-4 {
        margin-left: 1.5rem !important;
    }

    .rtl .ms-lg-4 {
        margin-right: 1.5rem !important;
    }

    .rtl .me-lg-5 {
        margin-left: 3rem !important;
    }

    .rtl .ms-lg-5 {
        margin-right: 3rem !important;
    }

    .rtl .me-lg-n1 {
        margin-left: -0.25rem !important;
    }

    .rtl .ms-lg-n1 {
        margin-right: -0.25rem !important;
    }

    .rtl .me-lg-n2 {
        margin-left: -0.5rem !important;
    }

    .rtl .ms-lg-n2 {
        margin-right: -0.5rem !important;
    }

    .rtl .me-lg-n3 {
        margin-left: -1rem !important;
    }

    .rtl .ms-lg-n3 {
        margin-right: -1rem !important;
    }

    .rtl .me-lg-n4 {
        margin-left: -1.5rem !important;
    }

    .rtl .ms-lg-n4 {
        margin-right: -1.5rem !important;
    }

    .rtl .me-lg-n5 {
        margin-left: -3rem !important;
    }

    .rtl .ms-lg-n5 {
        margin-right: -3rem !important;
    }

    .rtl .me-lg-auto {
        margin-left: auto !important;
    }

    .rtl .ms-lg-auto {
        margin-right: auto !important;
    }
}

@media (min-width: 1200px) {
    .rtl .me-xl-0 {
        margin-left: 0 !important;
    }

    .rtl .ms-xl-0 {
        margin-right: 0 !important;
    }

    .rtl .me-xl-1-1 {
        margin-left: 0.25rem !important;
    }

    .rtl .ms-xl-1 {
        margin-right: 0.25rem !important;
    }

    .rtl .me-xl-2 {
        margin-left: 0.5rem !important;
    }

    .rtl .ms-xl-2 {
        margin-right: 0.5rem !important;
    }

    .rtl .me-xl-3 {
        margin-left: 1rem !important;
    }

    .rtl .ms-xl-3 {
        margin-right: 1rem !important;
    }

    .rtl .me-xl-4 {
        margin-left: 1.5rem !important;
    }

    .rtl .ms-xl-4 {
        margin-right: 1.5rem !important;
    }

    .rtl .me-xl-5 {
        margin-left: 3rem !important;
    }

    .rtl .ms-xl-5 {
        margin-right: 3rem !important;
    }

    .rtl .me-xl-n1 {
        margin-left: -0.25rem !important;
    }

    .rtl .ms-xl-n1 {
        margin-right: -0.25rem !important;
    }

    .rtl .me-xl-n2 {
        margin-left: -0.5rem !important;
    }

    .rtl .ms-xl-n2 {
        margin-right: -0.5rem !important;
    }

    .rtl .me-xl-n3 {
        margin-left: -1rem !important;
    }

    .rtl .ms-xl-n3 {
        margin-right: -1rem !important;
    }

    .rtl .me-xl-n4 {
        margin-left: -1.5rem !important;
    }

    .rtl .ms-xl-n4 {
        margin-right: -1.5rem !important;
    }

    .rtl .me-xl-n5 {
        margin-left: -3rem !important;
    }

    .rtl .ms-xl-n5 {
        margin-right: -3rem !important;
    }

    .rtl .me-xl-auto {
        margin-left: auto !important;
    }

    .rtl .ms-xl-auto {
        margin-right: auto !important;
    }
}

.rtl .mg-x-0 {
    margin-right: 0px;
    margin-left: 0px;
}

.rtl .mg-x-0-f {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.rtl .mg-r-0 {
    margin-left: 0px;
}

.rtl .mg-l-0 {
    margin-right: 0px;
}

.rtl .mg-r-0-f {
    margin-left: 0px !important;
}

.rtl .mg-l-0-f {
    margin-right: 0px !important;
}

.rtl .mg-x-1 {
    margin-right: 1px;
    margin-left: 1px;
}

.rtl .mg-x-1-f {
    margin-right: 1px !important;
    margin-left: 1px !important;
}

.rtl .mg-r-1 {
    margin-left: 1px;
}

.rtl .mg-l-1 {
    margin-right: 1px;
}

.rtl .mg-r-1-f {
    margin-left: 1px !important;
}

.rtl .mg-l-1-f {
    margin-right: 1px !important;
}

.rtl .mg-x-2 {
    margin-right: 2px;
    margin-left: 2px;
}

.rtl .mg-x-2-f {
    margin-right: 2px !important;
    margin-left: 2px !important;
}

.rtl .mg-r-2 {
    margin-left: 2px;
}

.rtl .mg-l-2 {
    margin-right: 2px;
}

.rtl .mg-r-2-f {
    margin-left: 2px !important;
}

.rtl .mg-l-2-f {
    margin-right: 2px !important;
}

.rtl .mg-x-3 {
    margin-right: 3px;
    margin-left: 3px;
}

.rtl .mg-x-3-f {
    margin-right: 3px !important;
    margin-left: 3px !important;
}

.rtl .mg-r-3 {
    margin-left: 3px;
}

.rtl .mg-l-3 {
    margin-right: 3px;
}

.rtl .mg-r-3-f {
    margin-left: 3px !important;
}

.rtl .mg-l-3-f {
    margin-right: 3px !important;
}

.rtl .mg-x-4 {
    margin-right: 4px;
    margin-left: 4px;
}

.rtl .mg-x-4-f {
    margin-right: 4px !important;
    margin-left: 4px !important;
}

.rtl .mg-r-4 {
    margin-left: 4px;
}

.rtl .mg-l-4 {
    margin-right: 4px;
}

.rtl .mg-r-4-f {
    margin-left: 4px !important;
}

.rtl .mg-l-4-f {
    margin-right: 4px !important;
}

.rtl .mg-x-5 {
    margin-right: 5px;
    margin-left: 5px;
}

.rtl .mg-x-5-f {
    margin-right: 5px !important;
    margin-left: 5px !important;
}

.rtl .mg-r-5 {
    margin-left: 5px;
}

.rtl .mg-l-5 {
    margin-right: 5px;
}

.rtl .mg-r-5-f {
    margin-left: 5px !important;
}

.rtl .mg-l-5-f {
    margin-right: 5px !important;
}

.rtl .mg-x-6 {
    margin-right: 6px;
    margin-left: 6px;
}

.rtl .mg-x-6-f {
    margin-right: 6px !important;
    margin-left: 6px !important;
}

.rtl .mg-r-6 {
    margin-left: 6px;
}

.rtl .mg-l-6 {
    margin-right: 6px;
}

.rtl .mg-r-6-f {
    margin-left: 6px !important;
}

.rtl .mg-l-6-f {
    margin-right: 6px !important;
}

.rtl .mg-x-7 {
    margin-right: 7px;
    margin-left: 7px;
}

.rtl .mg-x-7-f {
    margin-right: 7px !important;
    margin-left: 7px !important;
}

.rtl .mg-r-7 {
    margin-left: 7px;
}

.rtl .mg-l-7 {
    margin-right: 7px;
}

.rtl .mg-r-7-f {
    margin-left: 7px !important;
}

.rtl .mg-l-7-f {
    margin-right: 7px !important;
}

.rtl .mg-x-8 {
    margin-right: 8px;
    margin-left: 8px;
}

.rtl .mg-x-8-f {
    margin-right: 8px !important;
    margin-left: 8px !important;
}

.rtl .mg-r-8 {
    margin-left: 8px;
}

.rtl .mg-l-8 {
    margin-right: 8px;
}

.rtl .mg-r-8-f {
    margin-left: 8px !important;
}

.rtl .mg-l-8-f {
    margin-right: 8px !important;
}

.rtl .mg-x-9 {
    margin-right: 9px;
    margin-left: 9px;
}

.rtl .mg-x-9-f {
    margin-right: 9px !important;
    margin-left: 9px !important;
}

.rtl .mg-r-9 {
    margin-left: 9px;
}

.rtl .mg-l-9 {
    margin-right: 9px;
}

.rtl .mg-r-9-f {
    margin-left: 9px !important;
}

.rtl .mg-l-9-f {
    margin-right: 9px !important;
}

.rtl .mg-x-10 {
    margin-right: 10px;
    margin-left: 10px;
}

.rtl .mg-x-10-f {
    margin-right: 10px !important;
    margin-left: 10px !important;
}

.rtl .mg-r-10 {
    margin-left: 10px;
    margin-right: inherit;
}

.rtl .mg-l-10 {
    margin-right: 10px;
}

.rtl .mg-r-10-f {
    margin-left: 10px !important;
}

.rtl .mg-l-10-f {
    margin-right: 10px !important;
}

.rtl .mg-x-15 {
    margin-right: 15px;
    margin-left: 15px;
}

.rtl .mg-x-15-f {
    margin-right: 15px !important;
    margin-left: 15px !important;
}

.rtl .mg-r-15 {
    margin-left: 15px;
}

.rtl .mg-l-15 {
    margin-right: 15px;
}

.rtl .mg-r-15-f {
    margin-left: 15px !important;
}

.rtl .mg-l-15-f {
    margin-right: 15px !important;
}

.rtl .mg-x-20 {
    margin-right: 20px;
    margin-left: 20px;
}

.rtl .mg-x-20-f {
    margin-right: 20px !important;
    margin-left: 20px !important;
}

.rtl .mg-r-20 {
    margin-left: 20px;
}

.rtl .mg-l-20 {
    margin-right: 20px;
}

.rtl .mg-r-20-f {
    margin-left: 20px !important;
}

.rtl .mg-l-20-f {
    margin-right: 20px !important;
}

.rtl .mg-x-25 {
    margin-right: 25px;
    margin-left: 25px;
}

.rtl .mg-x-25-f {
    margin-right: 25px !important;
    margin-left: 25px !important;
}

.rtl .mg-r-25 {
    margin-left: 25px;
}

.rtl .mg-l-25 {
    margin-right: 25px;
}

.rtl .mg-r-25-f {
    margin-left: 25px !important;
}

.rtl .mg-l-25-f {
    margin-right: 25px !important;
}

.rtl .mg-x-30 {
    margin-right: 30px;
    margin-left: 30px;
}

.rtl .mg-x-30-f {
    margin-right: 30px !important;
    margin-left: 30px !important;
}

.rtl .mg-r-30 {
    margin-left: 30px;
}

.rtl .mg-b-30 {
    margin-bottom: 30px;
}

.rtl .mg-l-30 {
    margin-right: 30px;
}

.rtl .mg-r-30-f {
    margin-left: 30px !important;
}

.rtl .mg-l-30-f {
    margin-right: 30px !important;
}

.rtl .mg-x-35 {
    margin-right: 35px;
    margin-left: 35px;
}

.rtl .mg-x-35-f {
    margin-right: 35px !important;
    margin-left: 35px !important;
}

.rtl .mg-r-35 {
    margin-left: 35px;
}

.rtl .mg-l-35 {
    margin-right: 35px;
}

.rtl .mg-r-35-f {
    margin-left: 35px !important;
}

.rtl .mg-l-35-f {
    margin-right: 35px !important;
}

.rtl .mg-x-40 {
    margin-right: 40px;
    margin-left: 40px;
}

.rtl .mg-x-40-f {
    margin-right: 40px !important;
    margin-left: 40px !important;
}

.rtl .mg-r-40 {
    margin-left: 40px;
}

.rtl .mg-l-40 {
    margin-right: 40px;
}

.rtl .mg-r-40-f {
    margin-left: 40px !important;
}

.rtl .mg-l-40-f {
    margin-right: 40px !important;
}

.rtl .mg-x-45 {
    margin-right: 45px;
    margin-left: 45px;
}

.rtl .mg-x-45-f {
    margin-right: 45px !important;
    margin-left: 45px !important;
}

.rtl .mg-r-45 {
    margin-left: 45px;
}

.rtl .mg-l-45 {
    margin-right: 45px;
}

.rtl .mg-r-45-f {
    margin-left: 45px !important;
}

.rtl .mg-l-45-f {
    margin-right: 45px !important;
}

.rtl .mg-x-50 {
    margin-right: 50px;
    margin-left: 50px;
}

.rtl .mg-x-50-f {
    margin-right: 50px !important;
    margin-left: 50px !important;
}

.rtl .mg-r-50 {
    margin-left: 50px;
}

.rtl .mg-l-50 {
    margin-right: 50px;
}

.rtl .mg-r-50-f {
    margin-left: 50px !important;
}

.rtl .mg-l-50-f {
    margin-right: 50px !important;
}

.rtl .mg-x-55 {
    margin-right: 55px;
    margin-left: 55px;
}

.rtl .mg-x-55-f {
    margin-right: 55px !important;
    margin-left: 55px !important;
}

.rtl .mg-r-55 {
    margin-left: 55px;
}

.rtl .mg-l-55 {
    margin-right: 55px;
}

.rtl .mg-r-55-f {
    margin-left: 55px !important;
}

.rtl .mg-l-55-f {
    margin-right: 55px !important;
}

.rtl .mg-x-60 {
    margin-right: 60px;
    margin-left: 60px;
}

.rtl .mg-x-60-f {
    margin-right: 60px !important;
    margin-left: 60px !important;
}

.rtl .mg-r-60 {
    margin-left: 60px;
}

.rtl .mg-l-60 {
    margin-right: 60px;
}

.rtl .mg-r-60-f {
    margin-left: 60px !important;
}

.rtl .mg-l-60-f {
    margin-right: 60px !important;
}

.rtl .mg-x-65 {
    margin-right: 65px;
    margin-left: 65px;
}

.rtl .mg-x-65-f {
    margin-right: 65px !important;
    margin-left: 65px !important;
}

.rtl .mg-r-65 {
    margin-left: 65px;
}

.rtl .mg-l-65 {
    margin-right: 65px;
}

.rtl .mg-r-65-f {
    margin-left: 65px !important;
}

.rtl .mg-l-65-f {
    margin-right: 65px !important;
}

.rtl .mg-x-70 {
    margin-right: 70px;
    margin-left: 70px;
}

.rtl .mg-x-70-f {
    margin-right: 70px !important;
    margin-left: 70px !important;
}

.rtl .mg-r-70 {
    margin-left: 70px;
}

.rtl .mg-l-70 {
    margin-right: 70px;
}

.rtl .mg-r-70-f {
    margin-left: 70px !important;
}

.rtl .mg-l-70-f {
    margin-right: 70px !important;
}

.rtl .mg-x-75 {
    margin-right: 75px;
    margin-left: 75px;
}

.rtl .mg-x-75-f {
    margin-right: 75px !important;
    margin-left: 75px !important;
}

.rtl .mg-r-75 {
    margin-left: 75px;
}

.rtl .mg-l-75 {
    margin-right: 75px;
}

.rtl .mg-r-75-f {
    margin-left: 75px !important;
}

.rtl .mg-l-75-f {
    margin-right: 75px !important;
}

.rtl .mg-x-80 {
    margin-right: 80px;
    margin-left: 80px;
}

.rtl .mg-x-80-f {
    margin-right: 80px !important;
    margin-left: 80px !important;
}

.rtl .mg-r-80 {
    margin-left: 80px;
}

.rtl .mg-l-80 {
    margin-right: 80px;
}

.rtl .mg-r-80-f {
    margin-left: 80px !important;
}

.rtl .mg-l-80-f {
    margin-right: 80px !important;
}

.rtl .mg-x-85 {
    margin-right: 85px;
    margin-left: 85px;
}

.rtl .mg-x-85-f {
    margin-right: 85px !important;
    margin-left: 85px !important;
}

.rtl .mg-r-85 {
    margin-left: 85px;
}

.rtl .mg-l-85 {
    margin-right: 85px;
}

.rtl .mg-r-85-f {
    margin-left: 85px !important;
}

.rtl .mg-l-85-f {
    margin-right: 85px !important;
}

.rtl .mg-x-90 {
    margin-right: 90px;
    margin-left: 90px;
}

.rtl .mg-x-90-f {
    margin-right: 90px !important;
    margin-left: 90px !important;
}

.rtl .mg-r-90 {
    margin-left: 90px;
}

.rtl .mg-l-90 {
    margin-right: 90px;
}

.rtl .mg-r-90-f {
    margin-left: 90px !important;
}

.rtl .mg-l-90-f {
    margin-right: 90px !important;
}

.rtl .mg-x-95 {
    margin-right: 95px;
    margin-left: 95px;
}

.rtl .mg-x-95-f {
    margin-right: 95px !important;
    margin-left: 95px !important;
}

.rtl .mg-r-95 {
    margin-left: 95px;
}

.rtl .mg-l-95 {
    margin-right: 95px;
}

.rtl .mg-r-95-f {
    margin-left: 95px !important;
}

.rtl .mg-l-95-f {
    margin-right: 95px !important;
}

.rtl .mg-x-100 {
    margin-right: 100px;
    margin-left: 100px;
}

.rtl .mg-x-100-f {
    margin-right: 100px !important;
    margin-left: 100px !important;
}

.rtl .mg-r-100 {
    margin-left: 100px;
}

.rtl .mg-l-100 {
    margin-right: 100px;
}

.rtl .mg-r-100-f {
    margin-left: 100px !important;
}

.rtl .mg-l-100-f {
    margin-right: 100px !important;
}

.rtl .mg-x-105 {
    margin-right: 105px;
    margin-left: 105px;
}

.rtl .mg-x-105-f {
    margin-right: 105px !important;
    margin-left: 105px !important;
}

.rtl .mg-r-105 {
    margin-left: 105px;
}

.rtl .mg-l-105 {
    margin-right: 105px;
}

.rtl .mg-r-105-f {
    margin-left: 105px !important;
}

.rtl .mg-l-105-f {
    margin-right: 105px !important;
}

.rtl .mg-x-110 {
    margin-right: 110px;
    margin-left: 110px;
}

.rtl .mg-x-110-f {
    margin-right: 110px !important;
    margin-left: 110px !important;
}

.rtl .mg-r-110 {
    margin-left: 110px;
}

.rtl .mg-l-110 {
    margin-right: 110px;
}

.rtl .mg-r-110-f {
    margin-left: 110px !important;
}

.rtl .mg-l-110-f {
    margin-right: 110px !important;
}

.rtl .mg-x-115 {
    margin-right: 115px;
    margin-left: 115px;
}

.rtl .mg-x-115-f {
    margin-right: 115px !important;
    margin-left: 115px !important;
}

.rtl .mg-r-115 {
    margin-left: 115px;
}

.rtl .mg-l-115 {
    margin-right: 115px;
}

.rtl .mg-r-115-f {
    margin-left: 115px !important;
}

.rtl .mg-l-115-f {
    margin-right: 115px !important;
}

.rtl .mg-x-120 {
    margin-right: 120px;
    margin-left: 120px;
}

.rtl .mg-x-120-f {
    margin-right: 120px !important;
    margin-left: 120px !important;
}

.rtl .mg-r-120 {
    margin-left: 120px;
}

.rtl .mg-l-120 {
    margin-right: 120px;
}

.rtl .mg-r-120-f {
    margin-left: 120px !important;
}

.rtl .mg-l-120-f {
    margin-right: 120px !important;
}

.rtl .mg-r-auto {
    margin-left: auto;
}

.rtl .mg-l-auto {
    margin-right: auto;
}

@media (min-width: 480px) {
    .rtl .mg-xs-x-0 {
        margin-right: 0px;
        margin-left: 0px;
    }

    .rtl .mg-xs-x-0-f {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }

    .rtl .mg-xs-r-0 {
        margin-left: 0px;
    }

    .rtl .mg-xs-l-0 {
        margin-right: 0px;
    }

    .rtl .mg-xs-r-0-f {
        margin-left: 0px !important;
    }

    .rtl .mg-xs-l-0-f {
        margin-right: 0px !important;
    }

    .rtl .mg-xs-x-1 {
        margin-right: 1px;
        margin-left: 1px;
    }

    .rtl .mg-xs-x-1-f {
        margin-right: 1px !important;
        margin-left: 1px !important;
    }

    .rtl .mg-xs-r-1 {
        margin-left: 1px;
    }

    .rtl .mg-xs-l-1 {
        margin-right: 1px;
    }

    .rtl .mg-xs-r-1-f {
        margin-left: 1px !important;
    }

    .rtl .mg-xs-l-1-f {
        margin-right: 1px !important;
    }

    .rtl .mg-xs-x-2 {
        margin-right: 2px;
        margin-left: 2px;
    }

    .rtl .mg-xs-x-2-f {
        margin-right: 2px !important;
        margin-left: 2px !important;
    }

    .rtl .mg-xs-r-2 {
        margin-left: 2px;
    }

    .rtl .mg-xs-l-2 {
        margin-right: 2px;
    }

    .rtl .mg-xs-r-2-f {
        margin-left: 2px !important;
    }

    .rtl .mg-xs-l-2-f {
        margin-right: 2px !important;
    }

    .rtl .mg-xs-x-3 {
        margin-right: 3px;
        margin-left: 3px;
    }

    .rtl .mg-xs-x-3-f {
        margin-right: 3px !important;
        margin-left: 3px !important;
    }

    .rtl .mg-xs-r-3 {
        margin-left: 3px;
    }

    .rtl .mg-xs-l-3 {
        margin-right: 3px;
    }

    .rtl .mg-xs-r-3-f {
        margin-left: 3px !important;
    }

    .rtl .mg-xs-l-3-f {
        margin-right: 3px !important;
    }

    .rtl .mg-xs-x-4 {
        margin-right: 4px;
        margin-left: 4px;
    }

    .rtl .mg-xs-x-4-f {
        margin-right: 4px !important;
        margin-left: 4px !important;
    }

    .rtl .mg-xs-r-4 {
        margin-left: 4px;
    }

    .rtl .mg-xs-l-4 {
        margin-right: 4px;
    }

    .rtl .mg-xs-r-4-f {
        margin-left: 4px !important;
    }

    .rtl .mg-xs-l-4-f {
        margin-right: 4px !important;
    }

    .rtl .mg-xs-x-5 {
        margin-right: 5px;
        margin-left: 5px;
    }

    .rtl .mg-xs-x-5-f {
        margin-right: 5px !important;
        margin-left: 5px !important;
    }

    .rtl .mg-xs-r-5 {
        margin-left: 5px;
    }

    .rtl .mg-xs-l-5 {
        margin-right: 5px;
    }

    .rtl .mg-xs-r-5-f {
        margin-left: 5px !important;
    }

    .rtl .mg-xs-l-5-f {
        margin-right: 5px !important;
    }

    .rtl .mg-xs-x-6 {
        margin-right: 6px;
        margin-left: 6px;
    }

    .rtl .mg-xs-x-6-f {
        margin-right: 6px !important;
        margin-left: 6px !important;
    }

    .rtl .mg-xs-r-6 {
        margin-left: 6px;
    }

    .rtl .mg-xs-l-6 {
        margin-right: 6px;
    }

    .rtl .mg-xs-r-6-f {
        margin-left: 6px !important;
    }

    .rtl .mg-xs-l-6-f {
        margin-right: 6px !important;
    }

    .rtl .mg-xs-x-7 {
        margin-right: 7px;
        margin-left: 7px;
    }

    .rtl .mg-xs-x-7-f {
        margin-right: 7px !important;
        margin-left: 7px !important;
    }

    .rtl .mg-xs-r-7 {
        margin-left: 7px;
    }

    .rtl .mg-xs-l-7 {
        margin-right: 7px;
    }

    .rtl .mg-xs-r-7-f {
        margin-left: 7px !important;
    }

    .rtl .mg-xs-l-7-f {
        margin-right: 7px !important;
    }

    .rtl .mg-xs-x-8 {
        margin-right: 8px;
        margin-left: 8px;
    }

    .rtl .mg-xs-x-8-f {
        margin-right: 8px !important;
        margin-left: 8px !important;
    }

    .rtl .mg-xs-r-8 {
        margin-left: 8px;
    }

    .rtl .mg-xs-l-8 {
        margin-right: 8px;
    }

    .rtl .mg-xs-r-8-f {
        margin-left: 8px !important;
    }

    .rtl .mg-xs-l-8-f {
        margin-right: 8px !important;
    }

    .rtl .mg-xs-x-9 {
        margin-right: 9px;
        margin-left: 9px;
    }

    .rtl .mg-xs-x-9-f {
        margin-right: 9px !important;
        margin-left: 9px !important;
    }

    .rtl .mg-xs-r-9 {
        margin-left: 9px;
    }

    .rtl .mg-xs-l-9 {
        margin-right: 9px;
    }

    .rtl .mg-xs-r-9-f {
        margin-left: 9px !important;
    }

    .rtl .mg-xs-l-9-f {
        margin-right: 9px !important;
    }

    .rtl .mg-xs-x-10 {
        margin-right: 10px;
        margin-left: 10px;
    }

    .rtl .mg-xs-x-10-f {
        margin-right: 10px !important;
        margin-left: 10px !important;
    }

    .rtl .mg-xs-r-10 {
        margin-left: 10px;
    }

    .rtl .mg-xs-l-10 {
        margin-right: 10px;
    }

    .rtl .mg-xs-r-10-f {
        margin-left: 10px !important;
    }

    .rtl .mg-xs-l-10-f {
        margin-right: 10px !important;
    }

    .rtl .mg-xs-x-15 {
        margin-right: 15px;
        margin-left: 15px;
    }

    .rtl .mg-xs-x-15-f {
        margin-right: 15px !important;
        margin-left: 15px !important;
    }

    .rtl .mg-xs-r-15 {
        margin-left: 15px;
    }

    .rtl .mg-xs-l-15 {
        margin-right: 15px;
    }

    .rtl .mg-xs-r-15-f {
        margin-left: 15px !important;
    }

    .rtl .mg-xs-l-15-f {
        margin-right: 15px !important;
    }

    .rtl .mg-xs-x-20 {
        margin-right: 20px;
        margin-left: 20px;
    }

    .rtl .mg-xs-x-20-f {
        margin-right: 20px !important;
        margin-left: 20px !important;
    }

    .rtl .mg-xs-r-20 {
        margin-left: 20px;
    }

    .rtl .mg-xs-l-20 {
        margin-right: 20px;
    }

    .rtl .mg-xs-r-20-f {
        margin-left: 20px !important;
    }

    .rtl .mg-xs-l-20-f {
        margin-right: 20px !important;
    }

    .rtl .mg-xs-x-25 {
        margin-right: 25px;
        margin-left: 25px;
    }

    .rtl .mg-xs-x-25-f {
        margin-right: 25px !important;
        margin-left: 25px !important;
    }

    .rtl .mg-xs-r-25 {
        margin-left: 25px;
    }

    .rtl .mg-xs-l-25 {
        margin-right: 25px;
    }

    .rtl .mg-xs-r-25-f {
        margin-left: 25px !important;
    }

    .rtl .mg-xs-l-25-f {
        margin-right: 25px !important;
    }

    .rtl .mg-xs-x-30 {
        margin-right: 30px;
        margin-left: 30px;
    }

    .rtl .mg-xs-x-30-f {
        margin-right: 30px !important;
        margin-left: 30px !important;
    }

    .rtl .mg-xs-r-30 {
        margin-left: 30px;
    }

    .rtl .mg-xs-l-30 {
        margin-right: 30px;
    }

    .rtl .mg-xs-r-30-f {
        margin-left: 30px !important;
    }

    .rtl .mg-xs-l-30-f {
        margin-right: 30px !important;
    }

    .rtl .mg-xs-x-35 {
        margin-right: 35px;
        margin-left: 35px;
    }

    .rtl .mg-xs-x-35-f {
        margin-right: 35px !important;
        margin-left: 35px !important;
    }

    .rtl .mg-xs-r-35 {
        margin-left: 35px;
    }

    .rtl .mg-xs-l-35 {
        margin-right: 35px;
    }

    .rtl .mg-xs-r-35-f {
        margin-left: 35px !important;
    }

    .rtl .mg-xs-l-35-f {
        margin-right: 35px !important;
    }

    .rtl .mg-xs-x-40 {
        margin-right: 40px;
        margin-left: 40px;
    }

    .rtl .mg-xs-x-40-f {
        margin-right: 40px !important;
        margin-left: 40px !important;
    }

    .rtl .mg-xs-r-40 {
        margin-left: 40px;
    }

    .rtl .mg-xs-l-40 {
        margin-right: 40px;
    }

    .rtl .mg-xs-r-40-f {
        margin-left: 40px !important;
    }

    .rtl .mg-xs-l-40-f {
        margin-right: 40px !important;
    }

    .rtl .mg-xs-x-45 {
        margin-right: 45px;
        margin-left: 45px;
    }

    .rtl .mg-xs-x-45-f {
        margin-right: 45px !important;
        margin-left: 45px !important;
    }

    .rtl .mg-xs-r-45 {
        margin-left: 45px;
    }

    .rtl .mg-xs-l-45 {
        margin-right: 45px;
    }

    .rtl .mg-xs-r-45-f {
        margin-left: 45px !important;
    }

    .rtl .mg-xs-l-45-f {
        margin-right: 45px !important;
    }

    .rtl .mg-xs-x-50 {
        margin-right: 50px;
        margin-left: 50px;
    }

    .rtl .mg-xs-x-50-f {
        margin-right: 50px !important;
        margin-left: 50px !important;
    }

    .rtl .mg-xs-r-50 {
        margin-left: 50px;
    }

    .rtl .mg-xs-l-50 {
        margin-right: 50px;
    }

    .rtl .mg-xs-r-50-f {
        margin-left: 50px !important;
    }

    .rtl .mg-xs-l-50-f {
        margin-right: 50px !important;
    }

    .rtl .mg-xs-x-55 {
        margin-right: 55px;
        margin-left: 55px;
    }

    .rtl .mg-xs-x-55-f {
        margin-right: 55px !important;
        margin-left: 55px !important;
    }

    .rtl .mg-xs-r-55 {
        margin-left: 55px;
    }

    .rtl .mg-xs-l-55 {
        margin-right: 55px;
    }

    .rtl .mg-xs-r-55-f {
        margin-left: 55px !important;
    }

    .rtl .mg-xs-l-55-f {
        margin-right: 55px !important;
    }

    .rtl .mg-xs-x-60 {
        margin-right: 60px;
        margin-left: 60px;
    }

    .rtl .mg-xs-x-60-f {
        margin-right: 60px !important;
        margin-left: 60px !important;
    }

    .rtl .mg-xs-r-60 {
        margin-left: 60px;
    }

    .rtl .mg-xs-l-60 {
        margin-right: 60px;
    }

    .rtl .mg-xs-r-60-f {
        margin-left: 60px !important;
    }

    .rtl .mg-xs-l-60-f {
        margin-right: 60px !important;
    }

    .rtl .mg-xs-x-65 {
        margin-right: 65px;
        margin-left: 65px;
    }

    .rtl .mg-xs-x-65-f {
        margin-right: 65px !important;
        margin-left: 65px !important;
    }

    .rtl .mg-xs-r-65 {
        margin-left: 65px;
    }

    .rtl .mg-xs-l-65 {
        margin-right: 65px;
    }

    .rtl .mg-xs-r-65-f {
        margin-left: 65px !important;
    }

    .rtl .mg-xs-l-65-f {
        margin-right: 65px !important;
    }

    .rtl .mg-xs-x-70 {
        margin-right: 70px;
        margin-left: 70px;
    }

    .rtl .mg-xs-x-70-f {
        margin-right: 70px !important;
        margin-left: 70px !important;
    }

    .rtl .mg-xs-r-70 {
        margin-left: 70px;
    }

    .rtl .mg-xs-l-70 {
        margin-right: 70px;
    }

    .rtl .mg-xs-r-70-f {
        margin-left: 70px !important;
    }

    .rtl .mg-xs-l-70-f {
        margin-right: 70px !important;
    }

    .rtl .mg-xs-x-75 {
        margin-right: 75px;
        margin-left: 75px;
    }

    .rtl .mg-xs-x-75-f {
        margin-right: 75px !important;
        margin-left: 75px !important;
    }

    .rtl .mg-xs-r-75 {
        margin-left: 75px;
    }

    .rtl .mg-xs-l-75 {
        margin-right: 75px;
    }

    .rtl .mg-xs-r-75-f {
        margin-left: 75px !important;
    }

    .rtl .mg-xs-l-75-f {
        margin-right: 75px !important;
    }

    .rtl .mg-xs-x-80 {
        margin-right: 80px;
        margin-left: 80px;
    }

    .rtl .mg-xs-x-80-f {
        margin-right: 80px !important;
        margin-left: 80px !important;
    }

    .rtl .mg-xs-r-80 {
        margin-left: 80px;
    }

    .rtl .mg-xs-l-80 {
        margin-right: 80px;
    }

    .rtl .mg-xs-r-80-f {
        margin-left: 80px !important;
    }

    .rtl .mg-xs-l-80-f {
        margin-right: 80px !important;
    }

    .rtl .mg-xs-x-85 {
        margin-right: 85px;
        margin-left: 85px;
    }

    .rtl .mg-xs-x-85-f {
        margin-right: 85px !important;
        margin-left: 85px !important;
    }

    .rtl .mg-xs-t-85 {
        margin-top: 85px;
    }

    .rtl .mg-xs-r-85 {
        margin-left: 85px;
    }

    .rtl .mg-xs-l-85 {
        margin-right: 85px;
    }

    .rtl .mg-xs-r-85-f {
        margin-left: 85px !important;
    }

    .rtl .mg-xs-l-85-f {
        margin-right: 85px !important;
    }

    .rtl .mg-xs-x-90 {
        margin-right: 90px;
        margin-left: 90px;
    }

    .rtl .mg-xs-x-90-f {
        margin-right: 90px !important;
        margin-left: 90px !important;
    }

    .rtl .mg-xs-r-90 {
        margin-left: 90px;
    }

    .rtl .mg-xs-l-90 {
        margin-right: 90px;
    }

    .rtl .mg-xs-r-90-f {
        margin-left: 90px !important;
    }

    .rtl .mg-xs-l-90-f {
        margin-right: 90px !important;
    }

    .rtl .mg-xs-x-95 {
        margin-right: 95px;
        margin-left: 95px;
    }

    .rtl .mg-xs-x-95-f {
        margin-right: 95px !important;
        margin-left: 95px !important;
    }

    .rtl .mg-xs-r-95 {
        margin-left: 95px;
    }

    .rtl .mg-xs-l-95 {
        margin-right: 95px;
    }

    .rtl .mg-xs-r-95-f {
        margin-left: 95px !important;
    }

    .rtl .mg-xs-l-95-f {
        margin-right: 95px !important;
    }

    .rtl .mg-xs-x-100 {
        margin-right: 100px;
        margin-left: 100px;
    }

    .rtl .mg-xs-x-100-f {
        margin-right: 100px !important;
        margin-left: 100px !important;
    }

    .rtl .mg-xs-r-100 {
        margin-left: 100px;
    }

    .rtl .mg-xs-l-100 {
        margin-right: 100px;
    }

    .rtl .mg-xs-r-100-f {
        margin-left: 100px !important;
    }

    .rtl .mg-xs-l-100-f {
        margin-right: 100px !important;
    }

    .rtl .mg-xs-r-auto {
        margin-left: auto;
    }

    .rtl .mg-xs-l-auto {
        margin-right: auto;
    }

    .rtl .mg-xs-x-auto {
        margin-right: auto;
        margin-left: auto;
    }
}

@media (min-width: 576px) {
    .rtl .mg-sm-x-0 {
        margin-right: 0px;
        margin-left: 0px;
    }

    .rtl .mg-sm-x-0-f {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }

    .rtl .mg-sm-r-0 {
        margin-left: 0px;
    }

    .rtl .mg-sm-l-0 {
        margin-right: 0px;
    }

    .rtl .mg-sm-r-0-f {
        margin-left: 0px !important;
    }

    .rtl .mg-sm-l-0-f {
        margin-right: 0px !important;
    }

    .rtl .mg-sm-x-1 {
        margin-right: 1px;
        margin-left: 1px;
    }

    .rtl .mg-sm-x-1-f {
        margin-right: 1px !important;
        margin-left: 1px !important;
    }

    .rtl .mg-sm-r-1 {
        margin-left: 1px;
    }

    .rtl .mg-sm-l-1 {
        margin-right: 1px;
    }

    .rtl .mg-sm-r-1-f {
        margin-left: 1px !important;
    }

    .rtl .mg-sm-l-1-f {
        margin-right: 1px !important;
    }

    .rtl .mg-sm-x-2 {
        margin-right: 2px;
        margin-left: 2px;
    }

    .rtl .mg-sm-x-2-f {
        margin-right: 2px !important;
        margin-left: 2px !important;
    }

    .rtl .mg-sm-r-2 {
        margin-left: 2px;
    }

    .rtl .mg-sm-l-2 {
        margin-right: 2px;
    }

    .rtl .mg-sm-r-2-f {
        margin-left: 2px !important;
    }

    .rtl .mg-sm-l-2-f {
        margin-right: 2px !important;
    }

    .rtl .mg-sm-x-3 {
        margin-right: 3px;
        margin-left: 3px;
    }

    .rtl .mg-sm-x-3-f {
        margin-right: 3px !important;
        margin-left: 3px !important;
    }

    .rtl .mg-sm-r-3 {
        margin-left: 3px;
    }

    .rtl .mg-sm-l-3 {
        margin-right: 3px;
    }

    .rtl .mg-sm-r-3-f {
        margin-left: 3px !important;
    }

    .rtl .mg-sm-l-3-f {
        margin-right: 3px !important;
    }

    .rtl .mg-sm-x-4 {
        margin-right: 4px;
        margin-left: 4px;
    }

    .rtl .mg-sm-x-4-f {
        margin-right: 4px !important;
        margin-left: 4px !important;
    }

    .rtl .mg-sm-r-4 {
        margin-left: 4px;
    }

    .rtl .mg-sm-l-4 {
        margin-right: 4px;
    }

    .rtl .mg-sm-r-4-f {
        margin-left: 4px !important;
    }

    .rtl .mg-sm-l-4-f {
        margin-right: 4px !important;
    }

    .rtl .mg-sm-x-5 {
        margin-right: 5px;
        margin-left: 5px;
    }

    .rtl .mg-sm-x-5-f {
        margin-right: 5px !important;
        margin-left: 5px !important;
    }

    .rtl .mg-sm-r-5 {
        margin-left: 5px;
    }

    .rtl .mg-sm-l-5 {
        margin-right: 5px;
    }

    .rtl .mg-sm-r-5-f {
        margin-left: 5px !important;
    }

    .rtl .mg-sm-l-5-f {
        margin-right: 5px !important;
    }

    .rtl .mg-sm-x-6 {
        margin-right: 6px;
        margin-left: 6px;
    }

    .rtl .mg-sm-x-6-f {
        margin-right: 6px !important;
        margin-left: 6px !important;
    }

    .rtl .mg-sm-r-6 {
        margin-left: 6px;
    }

    .rtl .mg-sm-l-6 {
        margin-right: 6px;
    }

    .rtl .mg-sm-r-6-f {
        margin-left: 6px !important;
    }

    .rtl .mg-sm-l-6-f {
        margin-right: 6px !important;
    }

    .rtl .mg-sm-x-7 {
        margin-right: 7px;
        margin-left: 7px;
    }

    .rtl .mg-sm-x-7-f {
        margin-right: 7px !important;
        margin-left: 7px !important;
    }

    .rtl .mg-sm-r-7 {
        margin-left: 7px;
    }

    .rtl .mg-sm-l-7 {
        margin-right: 7px;
    }

    .rtl .mg-sm-r-7-f {
        margin-left: 7px !important;
    }

    .rtl .mg-sm-l-7-f {
        margin-right: 7px !important;
    }

    .rtl .mg-sm-x-8 {
        margin-right: 8px;
        margin-left: 8px;
    }

    .rtl .mg-sm-x-8-f {
        margin-right: 8px !important;
        margin-left: 8px !important;
    }

    .rtl .mg-sm-r-8 {
        margin-left: 8px;
    }

    .rtl .mg-sm-l-8 {
        margin-right: 8px;
    }

    .rtl .mg-sm-r-8-f {
        margin-left: 8px !important;
    }

    .rtl .mg-sm-l-8-f {
        margin-right: 8px !important;
    }

    .rtl .mg-sm-x-9 {
        margin-right: 9px;
        margin-left: 9px;
    }

    .rtl .mg-sm-x-9-f {
        margin-right: 9px !important;
        margin-left: 9px !important;
    }

    .rtl .mg-sm-r-9 {
        margin-left: 9px;
    }

    .rtl .mg-sm-l-9 {
        margin-right: 9px;
    }

    .rtl .mg-sm-r-9-f {
        margin-left: 9px !important;
    }

    .rtl .mg-sm-l-9-f {
        margin-right: 9px !important;
    }

    .rtl .mg-sm-x-10 {
        margin-right: 10px;
        margin-left: 10px;
    }

    .rtl .mg-sm-x-10-f {
        margin-right: 10px !important;
        margin-left: 10px !important;
    }

    .rtl .mg-sm-r-10 {
        margin-left: 10px;
    }

    .rtl .mg-sm-l-10 {
        margin-right: 10px;
    }

    .rtl .mg-sm-r-10-f {
        margin-left: 10px !important;
    }

    .rtl .mg-sm-l-10-f {
        margin-right: 10px !important;
    }

    .rtl .mg-sm-x-15 {
        margin-right: 15px;
        margin-left: 15px;
    }

    .rtl .mg-sm-x-15-f {
        margin-right: 15px !important;
        margin-left: 15px !important;
    }

    .rtl .mg-sm-r-15 {
        margin-left: 15px;
    }

    .rtl .mg-sm-l-15 {
        margin-right: 15px;
    }

    .rtl .mg-sm-r-15-f {
        margin-left: 15px !important;
    }

    .rtl .mg-sm-l-15-f {
        margin-right: 15px !important;
    }

    .rtl .mg-sm-x-20 {
        margin-right: 20px;
        margin-left: 20px;
    }

    .rtl .mg-sm-x-20-f {
        margin-right: 20px !important;
        margin-left: 20px !important;
    }

    .rtl .mg-sm-r-20 {
        margin-left: 20px;
        margin-right: inherit;
    }

    .rtl .mg-sm-l-20 {
        margin-right: 20px;
    }

    .rtl .mg-sm-r-20-f {
        margin-left: 20px !important;
    }

    .rtl .mg-sm-l-20-f {
        margin-right: 20px !important;
    }

    .rtl .mg-sm-x-25 {
        margin-right: 25px;
        margin-left: 25px;
    }

    .rtl .mg-sm-x-25-f {
        margin-right: 25px !important;
        margin-left: 25px !important;
    }

    .rtl .mg-sm-r-25 {
        margin-left: 25px;
    }

    .rtl .mg-sm-l-25 {
        margin-right: 25px;
    }

    .rtl .mg-sm-r-25-f {
        margin-left: 25px !important;
    }

    .rtl .mg-sm-l-25-f {
        margin-right: 25px !important;
    }

    .rtl .mg-sm-x-30 {
        margin-right: 30px;
        margin-left: 30px;
    }

    .rtl .mg-sm-x-30-f {
        margin-right: 30px !important;
        margin-left: 30px !important;
    }

    .rtl .mg-sm-r-30 {
        margin-left: 30px;
    }

    .rtl .mg-sm-l-30 {
        margin-right: 30px;
    }

    .rtl .mg-sm-r-30-f {
        margin-left: 30px !important;
    }

    .rtl .mg-sm-l-30-f {
        margin-right: 30px !important;
    }

    .rtl .mg-sm-x-35 {
        margin-right: 35px;
        margin-left: 35px;
    }

    .rtl .mg-sm-x-35-f {
        margin-right: 35px !important;
        margin-left: 35px !important;
    }

    .rtl .mg-sm-r-35 {
        margin-left: 35px;
    }

    .rtl .mg-sm-l-35 {
        margin-right: 35px;
    }

    .rtl .mg-sm-r-35-f {
        margin-left: 35px !important;
    }

    .rtl .mg-sm-l-35-f {
        margin-right: 35px !important;
    }

    .rtl .mg-sm-x-40 {
        margin-right: 40px;
        margin-left: 40px;
    }

    .rtl .mg-sm-x-40-f {
        margin-right: 40px !important;
        margin-left: 40px !important;
    }

    .rtl .mg-sm-r-40 {
        margin-left: 40px;
    }

    .rtl .mg-sm-l-40 {
        margin-right: 40px;
    }

    .rtl .mg-sm-r-40-f {
        margin-left: 40px !important;
    }

    .rtl .mg-sm-l-40-f {
        margin-right: 40px !important;
    }

    .rtl .mg-sm-x-45 {
        margin-right: 45px;
        margin-left: 45px;
    }

    .rtl .mg-sm-x-45-f {
        margin-right: 45px !important;
        margin-left: 45px !important;
    }

    .rtl .mg-sm-r-45 {
        margin-left: 45px;
    }

    .rtl .mg-sm-l-45 {
        margin-right: 45px;
    }

    .rtl .mg-sm-r-45-f {
        margin-left: 45px !important;
    }

    .rtl .mg-sm-l-45-f {
        margin-right: 45px !important;
    }

    .rtl .mg-sm-x-50 {
        margin-right: 50px;
        margin-left: 50px;
    }

    .rtl .mg-sm-x-50-f {
        margin-right: 50px !important;
        margin-left: 50px !important;
    }

    .rtl .mg-sm-r-50 {
        margin-left: 50px;
    }

    .rtl .mg-sm-l-50 {
        margin-right: 50px;
    }

    .rtl .mg-sm-r-50-f {
        margin-left: 50px !important;
    }

    .rtl .mg-sm-l-50-f {
        margin-right: 50px !important;
    }

    .rtl .mg-sm-x-55 {
        margin-right: 55px;
        margin-left: 55px;
    }

    .rtl .mg-sm-x-55-f {
        margin-right: 55px !important;
        margin-left: 55px !important;
    }

    .rtl .mg-sm-r-55 {
        margin-left: 55px;
    }

    .rtl .mg-sm-l-55 {
        margin-right: 55px;
    }

    .rtl .mg-sm-r-55-f {
        margin-left: 55px !important;
    }

    .rtl .mg-sm-l-55-f {
        margin-right: 55px !important;
    }

    .rtl .mg-sm-x-60 {
        margin-right: 60px;
        margin-left: 60px;
    }

    .rtl .mg-sm-x-60-f {
        margin-right: 60px !important;
        margin-left: 60px !important;
    }

    .rtl .mg-sm-r-60 {
        margin-left: 60px;
    }

    .rtl .mg-sm-l-60 {
        margin-right: 60px;
    }

    .rtl .mg-sm-r-60-f {
        margin-left: 60px !important;
    }

    .rtl .mg-sm-l-60-f {
        margin-right: 60px !important;
    }

    .rtl .mg-sm-x-65 {
        margin-right: 65px;
        margin-left: 65px;
    }

    .rtl .mg-sm-x-65-f {
        margin-right: 65px !important;
        margin-left: 65px !important;
    }

    .rtl .mg-sm-r-65 {
        margin-left: 65px;
    }

    .rtl .mg-sm-l-65 {
        margin-right: 65px;
    }

    .rtl .mg-sm-r-65-f {
        margin-left: 65px !important;
    }

    .rtl .mg-sm-l-65-f {
        margin-right: 65px !important;
    }

    .rtl .mg-sm-x-70 {
        margin-right: 70px;
        margin-left: 70px;
    }

    .rtl .mg-sm-x-70-f {
        margin-right: 70px !important;
        margin-left: 70px !important;
    }

    .rtl .mg-sm-r-70 {
        margin-left: 70px;
    }

    .rtl .mg-sm-l-70 {
        margin-right: 70px;
    }

    .rtl .mg-sm-r-70-f {
        margin-left: 70px !important;
    }

    .rtl .mg-sm-l-70-f {
        margin-right: 70px !important;
    }

    .rtl .mg-sm-x-75 {
        margin-right: 75px;
        margin-left: 75px;
    }

    .rtl .mg-sm-x-75-f {
        margin-right: 75px !important;
        margin-left: 75px !important;
    }

    .rtl .mg-sm-r-75 {
        margin-left: 75px;
    }

    .rtl .mg-sm-l-75 {
        margin-right: 75px;
    }

    .rtl .mg-sm-r-75-f {
        margin-left: 75px !important;
    }

    .rtl .mg-sm-l-75-f {
        margin-right: 75px !important;
    }

    .rtl .mg-sm-x-80 {
        margin-right: 80px;
        margin-left: 80px;
    }

    .rtl .mg-sm-x-80-f {
        margin-right: 80px !important;
        margin-left: 80px !important;
    }

    .rtl .mg-sm-r-80 {
        margin-left: 80px;
    }

    .rtl .mg-sm-l-80 {
        margin-right: 80px;
    }

    .rtl .mg-sm-r-80-f {
        margin-left: 80px !important;
    }

    .rtl .mg-sm-l-80-f {
        margin-right: 80px !important;
    }

    .rtl .mg-sm-x-85 {
        margin-right: 85px;
        margin-left: 85px;
    }

    .rtl .mg-sm-x-85-f {
        margin-right: 85px !important;
        margin-left: 85px !important;
    }

    .rtl .mg-sm-r-85 {
        margin-left: 85px;
    }

    .rtl .mg-sm-l-85 {
        margin-right: 85px;
    }

    .rtl .mg-sm-r-85-f {
        margin-left: 85px !important;
    }

    .rtl .mg-sm-l-85-f {
        margin-right: 85px !important;
    }

    .rtl .mg-sm-x-90 {
        margin-right: 90px;
        margin-left: 90px;
    }

    .rtl .mg-sm-x-90-f {
        margin-right: 90px !important;
        margin-left: 90px !important;
    }

    .rtl .mg-sm-r-90 {
        margin-left: 90px;
    }

    .rtl .mg-sm-l-90 {
        margin-right: 90px;
    }

    .rtl .mg-sm-r-90-f {
        margin-left: 90px !important;
    }

    .rtl .mg-sm-l-90-f {
        margin-right: 90px !important;
    }

    .rtl .mg-sm-x-95 {
        margin-right: 95px;
        margin-left: 95px;
    }

    .rtl .mg-sm-x-95-f {
        margin-right: 95px !important;
        margin-left: 95px !important;
    }

    .rtl .mg-sm-r-95 {
        margin-left: 95px;
    }

    .rtl .mg-sm-l-95 {
        margin-right: 95px;
    }

    .rtl .mg-sm-r-95-f {
        margin-left: 95px !important;
    }

    .rtl .mg-sm-l-95-f {
        margin-right: 95px !important;
    }

    .rtl .mg-sm-x-100 {
        margin-right: 100px;
        margin-left: 100px;
    }

    .rtl .mg-sm-x-100-f {
        margin-right: 100px !important;
        margin-left: 100px !important;
    }

    .rtl .mg-sm-r-100 {
        margin-left: 100px;
    }

    .rtl .mg-sm-l-100 {
        margin-right: 100px;
    }

    .rtl .mg-sm-r-100-f {
        margin-left: 100px !important;
    }

    .rtl .mg-sm-l-100-f {
        margin-right: 100px !important;
    }

    .rtl .mg-sm-r-auto {
        margin-left: auto;
    }

    .rtl .mg-sm-l-auto {
        margin-right: auto;
    }

    .rtl .mg-sm-x-auto {
        margin-right: auto;
        margin-left: auto;
    }
}

@media (min-width: 768px) {
    .rtl .mg-md-x-0 {
        margin-right: 0px;
        margin-left: 0px;
    }

    .rtl .mg-md-x-0-f {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }

    .rtl .mg-md-r-0 {
        margin-left: 0px;
    }

    .rtl .mg-md-l-0 {
        margin-right: 0px;
    }

    .rtl .mg-md-r-0-f {
        margin-left: 0px !important;
    }

    .rtl .mg-md-l-0-f {
        margin-right: 0px !important;
    }

    .rtl .mg-md-x-1 {
        margin-right: 1px;
        margin-left: 1px;
    }

    .rtl .mg-md-x-1-f {
        margin-right: 1px !important;
        margin-left: 1px !important;
    }

    .rtl .mg-md-r-1 {
        margin-left: 1px;
    }

    .rtl .mg-md-l-1 {
        margin-right: 1px;
    }

    .rtl .mg-md-r-1-f {
        margin-left: 1px !important;
    }

    .rtl .mg-md-l-1-f {
        margin-right: 1px !important;
    }

    .rtl .mg-md-x-2 {
        margin-right: 2px;
        margin-left: 2px;
    }

    .rtl .mg-md-x-2-f {
        margin-right: 2px !important;
        margin-left: 2px !important;
    }

    .rtl .mg-md-r-2 {
        margin-left: 2px;
    }

    .rtl .mg-md-l-2 {
        margin-right: 2px;
    }

    .rtl .mg-md-r-2-f {
        margin-left: 2px !important;
    }

    .rtl .mg-md-l-2-f {
        margin-right: 2px !important;
    }

    .rtl .mg-md-x-3 {
        margin-right: 3px;
        margin-left: 3px;
    }

    .rtl .mg-md-x-3-f {
        margin-right: 3px !important;
        margin-left: 3px !important;
    }

    .rtl .mg-md-r-3 {
        margin-left: 3px;
    }

    .rtl .mg-md-l-3 {
        margin-right: 3px;
    }

    .rtl .mg-md-r-3-f {
        margin-left: 3px !important;
    }

    .rtl .mg-md-l-3-f {
        margin-right: 3px !important;
    }

    .rtl .mg-md-x-4 {
        margin-right: 4px;
        margin-left: 4px;
    }

    .rtl .mg-md-x-4-f {
        margin-right: 4px !important;
        margin-left: 4px !important;
    }

    .rtl .mg-md-r-4 {
        margin-left: 4px;
    }

    .rtl .mg-md-l-4 {
        margin-right: 4px;
    }

    .rtl .mg-md-r-4-f {
        margin-left: 4px !important;
    }

    .rtl .mg-md-l-4-f {
        margin-right: 4px !important;
    }

    .rtl .mg-md-x-5 {
        margin-right: 5px;
        margin-left: 5px;
    }

    .rtl .mg-md-x-5-f {
        margin-right: 5px !important;
        margin-left: 5px !important;
    }

    .rtl .mg-md-r-5 {
        margin-left: 5px;
    }

    .rtl .mg-md-l-5 {
        margin-right: 5px;
    }

    .rtl .mg-md-r-5-f {
        margin-left: 5px !important;
    }

    .rtl .mg-md-l-5-f {
        margin-right: 5px !important;
    }

    .rtl .mg-md-x-6 {
        margin-right: 6px;
        margin-left: 6px;
    }

    .rtl .mg-md-x-6-f {
        margin-right: 6px !important;
        margin-left: 6px !important;
    }

    .rtl .mg-md-r-6 {
        margin-left: 6px;
    }

    .rtl .mg-md-l-6 {
        margin-right: 6px;
    }

    .rtl .mg-md-r-6-f {
        margin-left: 6px !important;
    }

    .rtl .mg-md-l-6-f {
        margin-right: 6px !important;
    }

    .rtl .mg-md-x-7 {
        margin-right: 7px;
        margin-left: 7px;
    }

    .rtl .mg-md-x-7-f {
        margin-right: 7px !important;
        margin-left: 7px !important;
    }

    .rtl .mg-md-r-7 {
        margin-left: 7px;
    }

    .rtl .mg-md-l-7 {
        margin-right: 7px;
    }

    .rtl .mg-md-r-7-f {
        margin-left: 7px !important;
    }

    .rtl .mg-md-l-7-f {
        margin-right: 7px !important;
    }

    .rtl .mg-md-x-8 {
        margin-right: 8px;
        margin-left: 8px;
    }

    .rtl .mg-md-x-8-f {
        margin-right: 8px !important;
        margin-left: 8px !important;
    }

    .rtl .mg-md-r-8 {
        margin-left: 8px;
    }

    .rtl .mg-md-l-8 {
        margin-right: 8px;
    }

    .rtl .mg-md-r-8-f {
        margin-left: 8px !important;
    }

    .rtl .mg-md-l-8-f {
        margin-right: 8px !important;
    }

    .rtl .mg-md-x-9 {
        margin-right: 9px;
        margin-left: 9px;
    }

    .rtl .mg-md-x-9-f {
        margin-right: 9px !important;
        margin-left: 9px !important;
    }

    .rtl .mg-md-r-9 {
        margin-left: 9px;
    }

    .rtl .mg-md-l-9 {
        margin-right: 9px;
    }

    .rtl .mg-md-r-9-f {
        margin-left: 9px !important;
    }

    .rtl .mg-md-l-9-f {
        margin-right: 9px !important;
    }

    .rtl .mg-md-x-10 {
        margin-right: 10px;
        margin-left: 10px;
    }

    .rtl .mg-md-x-10-f {
        margin-right: 10px !important;
        margin-left: 10px !important;
    }

    .rtl .mg-md-r-10 {
        margin-left: 10px;
    }

    .rtl .mg-md-l-10 {
        margin-right: 10px;
    }

    .rtl .mg-md-r-10-f {
        margin-left: 10px !important;
    }

    .rtl .mg-md-l-10-f {
        margin-right: 10px !important;
    }

    .rtl .mg-md-x-15 {
        margin-right: 15px;
        margin-left: 15px;
    }

    .rtl .mg-md-x-15-f {
        margin-right: 15px !important;
        margin-left: 15px !important;
    }

    .rtl .mg-md-r-15 {
        margin-left: 15px;
    }

    .rtl .mg-md-l-15 {
        margin-right: 15px;
    }

    .rtl .mg-md-r-15-f {
        margin-left: 15px !important;
    }

    .rtl .mg-md-l-15-f {
        margin-right: 15px !important;
    }

    .rtl .mg-md-x-20 {
        margin-right: 20px;
        margin-left: 20px;
    }

    .rtl .mg-md-x-20-f {
        margin-right: 20px !important;
        margin-left: 20px !important;
    }

    .rtl .mg-md-r-20 {
        margin-left: 20px;
        margin-right: inherit;
    }

    .rtl .mg-md-l-20 {
        margin-right: 20px;
    }

    .rtl .mg-md-r-20-f {
        margin-left: 20px !important;
    }

    .rtl .mg-md-l-20-f {
        margin-right: 20px !important;
    }

    .rtl .mg-md-x-25 {
        margin-right: 25px;
        margin-left: 25px;
    }

    .rtl .mg-md-x-25-f {
        margin-right: 25px !important;
        margin-left: 25px !important;
    }

    .rtl .mg-md-r-25 {
        margin-left: 25px;
    }

    .rtl .mg-md-l-25 {
        margin-right: 25px;
    }

    .rtl .mg-md-r-25-f {
        margin-left: 25px !important;
    }

    .rtl .mg-md-l-25-f {
        margin-right: 25px !important;
    }

    .rtl .mg-md-x-30 {
        margin-right: 30px;
        margin-left: 30px;
    }

    .rtl .mg-md-x-30-f {
        margin-right: 30px !important;
        margin-left: 30px !important;
    }

    .rtl .mg-md-r-30 {
        margin-left: 30px;
    }

    .rtl .mg-md-l-30 {
        margin-right: 30px;
    }

    .rtl .mg-md-r-30-f {
        margin-left: 30px !important;
    }

    .rtl .mg-md-l-30-f {
        margin-right: 30px !important;
    }

    .rtl .mg-md-x-35 {
        margin-right: 35px;
        margin-left: 35px;
    }

    .rtl .mg-md-x-35-f {
        margin-right: 35px !important;
        margin-left: 35px !important;
    }

    .rtl .mg-md-r-35 {
        margin-left: 35px;
    }

    .rtl .mg-md-l-35 {
        margin-right: 35px;
    }

    .rtl .mg-md-r-35-f {
        margin-left: 35px !important;
    }

    .rtl .mg-md-l-35-f {
        margin-right: 35px !important;
    }

    .rtl .mg-md-x-40 {
        margin-right: 40px;
        margin-left: 40px;
    }

    .rtl .mg-md-x-40-f {
        margin-right: 40px !important;
        margin-left: 40px !important;
    }

    .rtl .mg-md-r-40 {
        margin-left: 40px;
    }

    .rtl .mg-md-l-40 {
        margin-right: 40px;
    }

    .rtl .mg-md-r-40-f {
        margin-left: 40px !important;
    }

    .rtl .mg-md-l-40-f {
        margin-right: 40px !important;
    }

    .rtl .mg-md-x-45 {
        margin-right: 45px;
        margin-left: 45px;
    }

    .rtl .mg-md-x-45-f {
        margin-right: 45px !important;
        margin-left: 45px !important;
    }

    .rtl .mg-md-r-45 {
        margin-left: 45px;
    }

    .rtl .mg-md-l-45 {
        margin-right: 45px;
    }

    .rtl .mg-md-r-45-f {
        margin-left: 45px !important;
    }

    .rtl .mg-md-l-45-f {
        margin-right: 45px !important;
    }

    .rtl .mg-md-x-50 {
        margin-right: 50px;
        margin-left: 50px;
    }

    .rtl .mg-md-x-50-f {
        margin-right: 50px !important;
        margin-left: 50px !important;
    }

    .rtl .mg-md-r-50 {
        margin-left: 50px;
    }

    .rtl .mg-md-l-50 {
        margin-right: 50px;
    }

    .rtl .mg-md-r-50-f {
        margin-left: 50px !important;
    }

    .rtl .mg-md-l-50-f {
        margin-right: 50px !important;
    }

    .rtl .mg-md-x-55 {
        margin-right: 55px;
        margin-left: 55px;
    }

    .rtl .mg-md-x-55-f {
        margin-right: 55px !important;
        margin-left: 55px !important;
    }

    .rtl .mg-md-r-55 {
        margin-left: 55px;
    }

    .rtl .mg-md-l-55 {
        margin-right: 55px;
    }

    .rtl .mg-md-r-55-f {
        margin-left: 55px !important;
    }

    .rtl .mg-md-l-55-f {
        margin-right: 55px !important;
    }

    .rtl .mg-md-x-60 {
        margin-right: 60px;
        margin-left: 60px;
    }

    .rtl .mg-md-x-60-f {
        margin-right: 60px !important;
        margin-left: 60px !important;
    }

    .rtl .mg-md-r-60 {
        margin-left: 60px;
    }

    .rtl .mg-md-l-60 {
        margin-right: 60px;
    }

    .rtl .mg-md-r-60-f {
        margin-left: 60px !important;
    }

    .rtl .mg-md-l-60-f {
        margin-right: 60px !important;
    }

    .rtl .mg-md-x-65 {
        margin-right: 65px;
        margin-left: 65px;
    }

    .rtl .mg-md-x-65-f {
        margin-right: 65px !important;
        margin-left: 65px !important;
    }

    .rtl .mg-md-r-65 {
        margin-left: 65px;
    }

    .rtl .mg-md-l-65 {
        margin-right: 65px;
    }

    .rtl .mg-md-r-65-f {
        margin-left: 65px !important;
    }

    .rtl .mg-md-l-65-f {
        margin-right: 65px !important;
    }

    .rtl .mg-md-x-70 {
        margin-right: 70px;
        margin-left: 70px;
    }

    .rtl .mg-md-x-70-f {
        margin-right: 70px !important;
        margin-left: 70px !important;
    }

    .rtl .mg-md-r-70 {
        margin-left: 70px;
    }

    .rtl .mg-md-l-70 {
        margin-right: 70px;
    }

    .rtl .mg-md-r-70-f {
        margin-left: 70px !important;
    }

    .rtl .mg-md-l-70-f {
        margin-right: 70px !important;
    }

    .rtl .mg-md-x-75 {
        margin-right: 75px;
        margin-left: 75px;
    }

    .rtl .mg-md-x-75-f {
        margin-right: 75px !important;
        margin-left: 75px !important;
    }

    .rtl .mg-md-r-75 {
        margin-left: 75px;
    }

    .rtl .mg-md-l-75 {
        margin-right: 75px;
    }

    .rtl .mg-md-r-75-f {
        margin-left: 75px !important;
    }

    .rtl .mg-md-l-75-f {
        margin-right: 75px !important;
    }

    .rtl .mg-md-x-80 {
        margin-right: 80px;
        margin-left: 80px;
    }

    .rtl .mg-md-x-80-f {
        margin-right: 80px !important;
        margin-left: 80px !important;
    }

    .rtl .mg-md-r-80 {
        margin-left: 80px;
    }

    .rtl .mg-md-l-80 {
        margin-right: 80px;
    }

    .rtl .mg-md-r-80-f {
        margin-left: 80px !important;
    }

    .rtl .mg-md-l-80-f {
        margin-right: 80px !important;
    }

    .rtl .mg-md-x-85 {
        margin-right: 85px;
        margin-left: 85px;
    }

    .rtl .mg-md-x-85-f {
        margin-right: 85px !important;
        margin-left: 85px !important;
    }

    .rtl .mg-md-r-85 {
        margin-left: 85px;
    }

    .rtl .mg-md-l-85 {
        margin-right: 85px;
    }

    .rtl .mg-md-r-85-f {
        margin-left: 85px !important;
    }

    .rtl .mg-md-l-85-f {
        margin-right: 85px !important;
    }

    .rtl .mg-md-x-90 {
        margin-right: 90px;
        margin-left: 90px;
    }

    .rtl .mg-md-x-90-f {
        margin-right: 90px !important;
        margin-left: 90px !important;
    }

    .rtl .mg-md-r-90 {
        margin-left: 90px;
    }

    .rtl .mg-md-l-90 {
        margin-right: 90px;
    }

    .rtl .mg-md-r-90-f {
        margin-left: 90px !important;
    }

    .rtl .mg-md-l-90-f {
        margin-right: 90px !important;
    }

    .rtl .mg-md-x-95 {
        margin-right: 95px;
        margin-left: 95px;
    }

    .rtl .mg-md-x-95-f {
        margin-right: 95px !important;
        margin-left: 95px !important;
    }

    .rtl .mg-md-r-95 {
        margin-left: 95px;
    }

    .rtl .mg-md-l-95 {
        margin-right: 95px;
    }

    .rtl .mg-md-r-95-f {
        margin-left: 95px !important;
    }

    .rtl .mg-md-l-95-f {
        margin-right: 95px !important;
    }

    .rtl .mg-md-x-100 {
        margin-right: 100px;
        margin-left: 100px;
    }

    .rtl .mg-md-x-100-f {
        margin-right: 100px !important;
        margin-left: 100px !important;
    }

    .rtl .mg-md-r-100 {
        margin-left: 100px;
    }

    .rtl .mg-md-l-100 {
        margin-right: 100px;
    }

    .rtl .mg-md-r-100-f {
        margin-left: 100px !important;
    }

    .rtl .mg-md-l-100-f {
        margin-right: 100px !important;
    }

    .rtl .mg-md-r-auto {
        margin-left: auto;
    }

    .rtl .mg-md-l-auto {
        margin-right: auto;
    }

    .rtl .mg-md-x-auto {
        margin-right: auto;
        margin-left: auto;
    }
}

@media (min-width: 992px) {
    .rtl .mg-lg-x-0 {
        margin-right: 0px;
        margin-left: 0px;
    }

    .rtl .mg-lg-x-0-f {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }

    .rtl .mg-lg-r-0 {
        margin-left: 0px;
    }

    .rtl .mg-lg-l-0 {
        margin-right: 0px;
    }

    .rtl .mg-lg-r-0-f {
        margin-left: 0px !important;
    }

    .rtl .mg-lg-l-0-f {
        margin-right: 0px !important;
    }

    .rtl .mg-lg-x-1 {
        margin-right: 1px;
        margin-left: 1px;
    }

    .rtl .mg-lg-x-1-f {
        margin-right: 1px !important;
        margin-left: 1px !important;
    }

    .rtl .mg-lg-r-1 {
        margin-left: 1px;
    }

    .rtl .mg-lg-l-1 {
        margin-right: 1px;
    }

    .rtl .mg-lg-r-1-f {
        margin-left: 1px !important;
    }

    .rtl .mg-lg-l-1-f {
        margin-right: 1px !important;
    }

    .rtl .mg-lg-x-2 {
        margin-right: 2px;
        margin-left: 2px;
    }

    .rtl .mg-lg-x-2-f {
        margin-right: 2px !important;
        margin-left: 2px !important;
    }

    .rtl .mg-lg-r-2 {
        margin-left: 2px;
    }

    .rtl .mg-lg-l-2 {
        margin-right: 2px;
    }

    .rtl .mg-lg-r-2-f {
        margin-left: 2px !important;
    }

    .rtl .mg-lg-l-2-f {
        margin-right: 2px !important;
    }

    .rtl .mg-lg-x-3 {
        margin-right: 3px;
        margin-left: 3px;
    }

    .rtl .mg-lg-x-3-f {
        margin-right: 3px !important;
        margin-left: 3px !important;
    }

    .rtl .mg-lg-r-3 {
        margin-left: 3px;
    }

    .rtl .mg-lg-l-3 {
        margin-right: 3px;
    }

    .rtl .mg-lg-r-3-f {
        margin-left: 3px !important;
    }

    .rtl .mg-lg-l-3-f {
        margin-right: 3px !important;
    }

    .rtl .mg-lg-x-4 {
        margin-right: 4px;
        margin-left: 4px;
    }

    .rtl .mg-lg-x-4-f {
        margin-right: 4px !important;
        margin-left: 4px !important;
    }

    .rtl .mg-lg-r-4 {
        margin-left: 4px;
    }

    .rtl .mg-lg-l-4 {
        margin-right: 4px;
    }

    .rtl .mg-lg-r-4-f {
        margin-left: 4px !important;
    }

    .rtl .mg-lg-l-4-f {
        margin-right: 4px !important;
    }

    .rtl .mg-lg-x-5 {
        margin-right: 5px;
        margin-left: 5px;
    }

    .rtl .mg-lg-x-5-f {
        margin-right: 5px !important;
        margin-left: 5px !important;
    }

    .rtl .mg-lg-r-5 {
        margin-left: 5px;
    }

    .rtl .mg-lg-l-5 {
        margin-right: 5px;
    }

    .rtl .mg-lg-r-5-f {
        margin-left: 5px !important;
    }

    .rtl .mg-lg-l-5-f {
        margin-right: 5px !important;
    }

    .rtl .mg-lg-x-6 {
        margin-right: 6px;
        margin-left: 6px;
    }

    .rtl .mg-lg-x-6-f {
        margin-right: 6px !important;
        margin-left: 6px !important;
    }

    .rtl .mg-lg-r-6 {
        margin-left: 6px;
    }

    .rtl .mg-lg-l-6 {
        margin-right: 6px;
    }

    .rtl .mg-lg-r-6-f {
        margin-left: 6px !important;
    }

    .rtl .mg-lg-l-6-f {
        margin-right: 6px !important;
    }

    .rtl .mg-lg-x-7 {
        margin-right: 7px;
        margin-left: 7px;
    }

    .rtl .mg-lg-x-7-f {
        margin-right: 7px !important;
        margin-left: 7px !important;
    }

    .rtl .mg-lg-r-7 {
        margin-left: 7px;
    }

    .rtl .mg-lg-l-7 {
        margin-right: 7px;
    }

    .rtl .mg-lg-r-7-f {
        margin-left: 7px !important;
    }

    .rtl .mg-lg-l-7-f {
        margin-right: 7px !important;
    }

    .rtl .mg-lg-x-8 {
        margin-right: 8px;
        margin-left: 8px;
    }

    .rtl .mg-lg-x-8-f {
        margin-right: 8px !important;
        margin-left: 8px !important;
    }

    .rtl .mg-lg-r-8 {
        margin-left: 8px;
    }

    .rtl .mg-lg-l-8 {
        margin-right: 8px;
    }

    .rtl .mg-lg-r-8-f {
        margin-left: 8px !important;
    }

    .rtl .mg-lg-l-8-f {
        margin-right: 8px !important;
    }

    .rtl .mg-lg-x-9 {
        margin-right: 9px;
        margin-left: 9px;
    }

    .rtl .mg-lg-x-9-f {
        margin-right: 9px !important;
        margin-left: 9px !important;
    }

    .rtl .mg-lg-r-9 {
        margin-left: 9px;
    }

    .rtl .mg-lg-l-9 {
        margin-right: 9px;
    }

    .rtl .mg-lg-r-9-f {
        margin-left: 9px !important;
    }

    .rtl .mg-lg-l-9-f {
        margin-right: 9px !important;
    }

    .rtl .mg-lg-x-10 {
        margin-right: 10px;
        margin-left: 10px;
    }

    .rtl .mg-lg-x-10-f {
        margin-right: 10px !important;
        margin-left: 10px !important;
    }

    .rtl .mg-lg-r-10 {
        margin-left: 10px;
    }

    .rtl .mg-lg-l-10 {
        margin-right: 10px;
    }

    .rtl .mg-lg-r-10-f {
        margin-left: 10px !important;
    }

    .rtl .mg-lg-l-10-f {
        margin-right: 10px !important;
    }

    .rtl .mg-lg-x-15 {
        margin-right: 15px;
        margin-left: 15px;
    }

    .rtl .mg-lg-x-15-f {
        margin-right: 15px !important;
        margin-left: 15px !important;
    }

    .rtl .mg-lg-r-15 {
        margin-left: 15px;
    }

    .rtl .mg-lg-l-15 {
        margin-right: 15px;
    }

    .rtl .mg-lg-r-15-f {
        margin-left: 15px !important;
    }

    .rtl .mg-lg-l-15-f {
        margin-right: 15px !important;
    }

    .rtl .mg-lg-x-20 {
        margin-right: 20px;
        margin-left: 20px;
    }

    .rtl .mg-lg-x-20-f {
        margin-right: 20px !important;
        margin-left: 20px !important;
    }

    .rtl .mg-lg-r-20 {
        margin-left: 20px;
    }

    .rtl .mg-lg-l-20 {
        margin-right: 20px;
    }

    .rtl .mg-lg-r-20-f {
        margin-left: 20px !important;
    }

    .rtl .mg-lg-l-20-f {
        margin-right: 20px !important;
    }

    .rtl .mg-lg-x-25 {
        margin-right: 25px;
        margin-left: 25px;
    }

    .rtl .mg-lg-x-25-f {
        margin-right: 25px !important;
        margin-left: 25px !important;
    }

    .rtl .mg-lg-r-25 {
        margin-left: 25px;
    }

    .rtl .mg-lg-l-25 {
        margin-right: 25px;
    }

    .rtl .mg-lg-r-25-f {
        margin-left: 25px !important;
    }

    .rtl .mg-lg-l-25-f {
        margin-right: 25px !important;
    }

    .rtl .mg-lg-x-30 {
        margin-right: 30px;
        margin-left: 30px;
    }

    .rtl .mg-lg-x-30-f {
        margin-right: 30px !important;
        margin-left: 30px !important;
    }

    .rtl .mg-lg-r-30 {
        margin-left: 30px;
    }

    .rtl .mg-lg-l-30 {
        margin-right: 30px;
    }

    .rtl .mg-lg-r-30-f {
        margin-left: 30px !important;
    }

    .rtl .mg-lg-l-30-f {
        margin-right: 30px !important;
    }

    .rtl .mg-lg-x-35 {
        margin-right: 35px;
        margin-left: 35px;
    }

    .rtl .mg-lg-x-35-f {
        margin-right: 35px !important;
        margin-left: 35px !important;
    }

    .rtl .mg-lg-r-35 {
        margin-left: 35px;
    }

    .rtl .mg-lg-l-35 {
        margin-right: 35px;
    }

    .rtl .mg-lg-r-35-f {
        margin-left: 35px !important;
    }

    .rtl .mg-lg-l-35-f {
        margin-right: 35px !important;
    }

    .rtl .mg-lg-x-40 {
        margin-right: 40px;
        margin-left: 40px;
    }

    .rtl .mg-lg-x-40-f {
        margin-right: 40px !important;
        margin-left: 40px !important;
    }

    .rtl .mg-lg-r-40 {
        margin-left: 40px;
    }

    .rtl .mg-lg-l-40 {
        margin-right: 40px;
    }

    .rtl .mg-lg-r-40-f {
        margin-left: 40px !important;
    }

    .rtl .mg-lg-l-40-f {
        margin-right: 40px !important;
    }

    .rtl .mg-lg-x-45 {
        margin-right: 45px;
        margin-left: 45px;
    }

    .rtl .mg-lg-x-45-f {
        margin-right: 45px !important;
        margin-left: 45px !important;
    }

    .rtl .mg-lg-r-45 {
        margin-left: 45px;
    }

    .rtl .mg-lg-l-45 {
        margin-right: 45px;
    }

    .rtl .mg-lg-r-45-f {
        margin-left: 45px !important;
    }

    .rtl .mg-lg-l-45-f {
        margin-right: 45px !important;
    }

    .rtl .mg-lg-x-50 {
        margin-right: 50px;
        margin-left: 50px;
    }

    .rtl .mg-lg-x-50-f {
        margin-right: 50px !important;
        margin-left: 50px !important;
    }

    .rtl .mg-lg-r-50 {
        margin-left: 50px;
    }

    .rtl .mg-lg-l-50 {
        margin-right: 50px;
    }

    .rtl .mg-lg-r-50-f {
        margin-left: 50px !important;
    }

    .rtl .mg-lg-l-50-f {
        margin-right: 50px !important;
    }

    .rtl .mg-lg-x-55 {
        margin-right: 55px;
        margin-left: 55px;
    }

    .rtl .mg-lg-x-55-f {
        margin-right: 55px !important;
        margin-left: 55px !important;
    }

    .rtl .mg-lg-r-55 {
        margin-left: 55px;
    }

    .rtl .mg-lg-l-55 {
        margin-right: 55px;
    }

    .rtl .mg-lg-r-55-f {
        margin-left: 55px !important;
    }

    .rtl .mg-lg-l-55-f {
        margin-right: 55px !important;
    }

    .rtl .mg-lg-x-60 {
        margin-right: 60px;
        margin-left: 60px;
    }

    .rtl .mg-lg-x-60-f {
        margin-right: 60px !important;
        margin-left: 60px !important;
    }

    .rtl .mg-lg-r-60 {
        margin-left: 60px;
    }

    .rtl .mg-lg-l-60 {
        margin-right: 60px;
    }

    .rtl .mg-lg-r-60-f {
        margin-left: 60px !important;
    }

    .rtl .mg-lg-l-60-f {
        margin-right: 60px !important;
    }

    .rtl .mg-lg-x-65 {
        margin-right: 65px;
        margin-left: 65px;
    }

    .rtl .mg-lg-x-65-f {
        margin-right: 65px !important;
        margin-left: 65px !important;
    }

    .rtl .mg-lg-r-65 {
        margin-left: 65px;
    }

    .rtl .mg-lg-l-65 {
        margin-right: 65px;
    }

    .rtl .mg-lg-r-65-f {
        margin-left: 65px !important;
    }

    .rtl .mg-lg-l-65-f {
        margin-right: 65px !important;
    }

    .rtl .mg-lg-x-70 {
        margin-right: 70px;
        margin-left: 70px;
    }

    .rtl .mg-lg-x-70-f {
        margin-right: 70px !important;
        margin-left: 70px !important;
    }

    .rtl .mg-lg-r-70 {
        margin-left: 70px;
    }

    .rtl .mg-lg-l-70 {
        margin-right: 70px;
    }

    .rtl .mg-lg-r-70-f {
        margin-left: 70px !important;
    }

    .rtl .mg-lg-l-70-f {
        margin-right: 70px !important;
    }

    .rtl .mg-lg-x-75 {
        margin-right: 75px;
        margin-left: 75px;
    }

    .rtl .mg-lg-x-75-f {
        margin-right: 75px !important;
        margin-left: 75px !important;
    }

    .rtl .mg-lg-r-75 {
        margin-left: 75px;
    }

    .rtl .mg-lg-l-75 {
        margin-right: 75px;
    }

    .rtl .mg-lg-r-75-f {
        margin-left: 75px !important;
    }

    .rtl .mg-lg-l-75-f {
        margin-right: 75px !important;
    }

    .rtl .mg-lg-x-80 {
        margin-right: 80px;
        margin-left: 80px;
    }

    .rtl .mg-lg-x-80-f {
        margin-right: 80px !important;
        margin-left: 80px !important;
    }

    .rtl .mg-lg-r-80 {
        margin-left: 80px;
    }

    .rtl .mg-lg-l-80 {
        margin-right: 80px;
    }

    .rtl .mg-lg-r-80-f {
        margin-left: 80px !important;
    }

    .rtl .mg-lg-l-80-f {
        margin-right: 80px !important;
    }

    .rtl .mg-lg-x-85 {
        margin-right: 85px;
        margin-left: 85px;
    }

    .rtl .mg-lg-x-85-f {
        margin-right: 85px !important;
        margin-left: 85px !important;
    }

    .rtl .mg-lg-r-85 {
        margin-left: 85px;
    }

    .rtl .mg-lg-l-85 {
        margin-right: 85px;
    }

    .rtl .mg-lg-r-85-f {
        margin-left: 85px !important;
    }

    .rtl .mg-lg-l-85-f {
        margin-right: 85px !important;
    }

    .rtl .mg-lg-x-90 {
        margin-right: 90px;
        margin-left: 90px;
    }

    .rtl .mg-lg-x-90-f {
        margin-right: 90px !important;
        margin-left: 90px !important;
    }

    .rtl .mg-lg-r-90 {
        margin-left: 90px;
    }

    .rtl .mg-lg-l-90 {
        margin-right: 90px;
    }

    .rtl .mg-lg-r-90-f {
        margin-left: 90px !important;
    }

    .rtl .mg-lg-l-90-f {
        margin-right: 90px !important;
    }

    .rtl .mg-lg-x-95 {
        margin-right: 95px;
        margin-left: 95px;
    }

    .rtl .mg-lg-x-95-f {
        margin-right: 95px !important;
        margin-left: 95px !important;
    }

    .rtl .mg-lg-r-95 {
        margin-left: 95px;
    }

    .rtl .mg-lg-l-95 {
        margin-right: 95px;
    }

    .rtl .mg-lg-r-95-f {
        margin-left: 95px !important;
    }

    .rtl .mg-lg-l-95-f {
        margin-right: 95px !important;
    }

    .rtl .mg-lg-x-100 {
        margin-right: 100px;
        margin-left: 100px;
    }

    .rtl .mg-lg-x-100-f {
        margin-right: 100px !important;
        margin-left: 100px !important;
    }

    .rtl .mg-lg-r-100 {
        margin-left: 100px;
    }

    .rtl .mg-lg-l-100 {
        margin-right: 100px;
    }

    .rtl .mg-lg-r-100-f {
        margin-left: 100px !important;
    }

    .rtl .mg-lg-l-100-f {
        margin-right: 100px !important;
    }

    .rtl .mg-lg-r-auto {
        margin-left: auto;
    }

    .rtl .mg-lg-l-auto {
        margin-right: auto;
    }

    .rtl .mg-lg-x-auto {
        margin-right: auto;
        margin-left: auto;
    }
}

@media (min-width: 1200px) {
    .rtl .mg-xl-x-0 {
        margin-right: 0px;
        margin-left: 0px;
    }

    .rtl .mg-xl-x-0-f {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }

    .rtl .mg-xl-r-0 {
        margin-left: 0px;
    }

    .rtl .mg-xl-l-0 {
        margin-right: 0px;
    }

    .rtl .mg-xl-r-0-f {
        margin-left: 0px !important;
    }

    .rtl .mg-xl-l-0-f {
        margin-right: 0px !important;
    }

    .rtl .mg-xl-x-1 {
        margin-right: 1px;
        margin-left: 1px;
    }

    .rtl .mg-xl-x-1-f {
        margin-right: 1px !important;
        margin-left: 1px !important;
    }

    .rtl .mg-xl-r-1 {
        margin-left: 1px;
    }

    .rtl .mg-xl-l-1 {
        margin-right: 1px;
    }

    .rtl .mg-xl-r-1-f {
        margin-left: 1px !important;
    }

    .rtl .mg-xl-l-1-f {
        margin-right: 1px !important;
    }

    .rtl .mg-xl-x-2 {
        margin-right: 2px;
        margin-left: 2px;
    }

    .rtl .mg-xl-x-2-f {
        margin-right: 2px !important;
        margin-left: 2px !important;
    }

    .rtl .mg-xl-r-2 {
        margin-left: 2px;
    }

    .rtl .mg-xl-l-2 {
        margin-right: 2px;
    }

    .rtl .mg-xl-r-2-f {
        margin-left: 2px !important;
    }

    .rtl .mg-xl-l-2-f {
        margin-right: 2px !important;
    }

    .rtl .mg-xl-x-3 {
        margin-right: 3px;
        margin-left: 3px;
    }

    .rtl .mg-xl-x-3-f {
        margin-right: 3px !important;
        margin-left: 3px !important;
    }

    .rtl .mg-xl-r-3 {
        margin-left: 3px;
    }

    .rtl .mg-xl-l-3 {
        margin-right: 3px;
    }

    .rtl .mg-xl-r-3-f {
        margin-left: 3px !important;
    }

    .rtl .mg-xl-l-3-f {
        margin-right: 3px !important;
    }

    .rtl .mg-xl-x-4 {
        margin-right: 4px;
        margin-left: 4px;
    }

    .rtl .mg-xl-x-4-f {
        margin-right: 4px !important;
        margin-left: 4px !important;
    }

    .rtl .mg-xl-r-4 {
        margin-left: 4px;
    }

    .rtl .mg-xl-l-4 {
        margin-right: 4px;
    }

    .rtl .mg-xl-r-4-f {
        margin-left: 4px !important;
    }

    .rtl .mg-xl-l-4-f {
        margin-right: 4px !important;
    }

    .rtl .mg-xl-x-5 {
        margin-right: 5px;
        margin-left: 5px;
    }

    .rtl .mg-xl-x-5-f {
        margin-right: 5px !important;
        margin-left: 5px !important;
    }

    .rtl .mg-xl-r-5 {
        margin-left: 5px;
    }

    .rtl .mg-xl-l-5 {
        margin-right: 5px;
    }

    .rtl .mg-xl-r-5-f {
        margin-left: 5px !important;
    }

    .rtl .mg-xl-l-5-f {
        margin-right: 5px !important;
    }

    .rtl .mg-xl-x-6 {
        margin-right: 6px;
        margin-left: 6px;
    }

    .rtl .mg-xl-x-6-f {
        margin-right: 6px !important;
        margin-left: 6px !important;
    }

    .rtl .mg-xl-r-6 {
        margin-left: 6px;
    }

    .rtl .mg-xl-l-6 {
        margin-right: 6px;
    }

    .rtl .mg-xl-r-6-f {
        margin-left: 6px !important;
    }

    .rtl .mg-xl-l-6-f {
        margin-right: 6px !important;
    }

    .rtl .mg-xl-x-7 {
        margin-right: 7px;
        margin-left: 7px;
    }

    .rtl .mg-xl-x-7-f {
        margin-right: 7px !important;
        margin-left: 7px !important;
    }

    .rtl .mg-xl-r-7 {
        margin-left: 7px;
    }

    .rtl .mg-xl-l-7 {
        margin-right: 7px;
    }

    .rtl .mg-xl-r-7-f {
        margin-left: 7px !important;
    }

    .rtl .mg-xl-l-7-f {
        margin-right: 7px !important;
    }

    .rtl .mg-xl-x-8 {
        margin-right: 8px;
        margin-left: 8px;
    }

    .rtl .mg-xl-x-8-f {
        margin-right: 8px !important;
        margin-left: 8px !important;
    }

    .rtl .mg-xl-r-8 {
        margin-left: 8px;
    }

    .rtl .mg-xl-l-8 {
        margin-right: 8px;
    }

    .rtl .mg-xl-r-8-f {
        margin-left: 8px !important;
    }

    .rtl .mg-xl-l-8-f {
        margin-right: 8px !important;
    }

    .rtl .mg-xl-x-9 {
        margin-right: 9px;
        margin-left: 9px;
    }

    .rtl .mg-xl-x-9-f {
        margin-right: 9px !important;
        margin-left: 9px !important;
    }

    .rtl .mg-xl-r-9 {
        margin-left: 9px;
    }

    .rtl .mg-xl-l-9 {
        margin-right: 9px;
    }

    .rtl .mg-xl-r-9-f {
        margin-left: 9px !important;
    }

    .rtl .mg-xl-l-9-f {
        margin-right: 9px !important;
    }

    .rtl .mg-xl-x-10 {
        margin-right: 10px;
        margin-left: 10px;
    }

    .rtl .mg-xl-x-10-f {
        margin-right: 10px !important;
        margin-left: 10px !important;
    }

    .rtl .mg-xl-r-10 {
        margin-left: 10px;
    }

    .rtl .mg-xl-l-10 {
        margin-right: 10px;
    }

    .rtl .mg-xl-r-10-f {
        margin-left: 10px !important;
    }

    .rtl .mg-xl-l-10-f {
        margin-right: 10px !important;
    }

    .rtl .mg-xl-x-15 {
        margin-right: 15px;
        margin-left: 15px;
    }

    .rtl .mg-xl-x-15-f {
        margin-right: 15px !important;
        margin-left: 15px !important;
    }

    .rtl .mg-xl-r-15 {
        margin-left: 15px;
    }

    .rtl .mg-xl-l-15 {
        margin-right: 15px;
    }

    .rtl .mg-xl-r-15-f {
        margin-left: 15px !important;
    }

    .rtl .mg-xl-l-15-f {
        margin-right: 15px !important;
    }

    .rtl .mg-xl-x-20 {
        margin-right: 20px;
        margin-left: 20px;
    }

    .rtl .mg-xl-x-20-f {
        margin-right: 20px !important;
        margin-left: 20px !important;
    }

    .rtl .mg-xl-r-20 {
        margin-left: 20px;
    }

    .rtl .mg-xl-l-20 {
        margin-right: 20px;
    }

    .rtl .mg-xl-r-20-f {
        margin-left: 20px !important;
    }

    .rtl .mg-xl-l-20-f {
        margin-right: 20px !important;
    }

    .rtl .mg-xl-x-25 {
        margin-right: 25px;
        margin-left: 25px;
    }

    .rtl .mg-xl-x-25-f {
        margin-right: 25px !important;
        margin-left: 25px !important;
    }

    .rtl .mg-xl-r-25 {
        margin-left: 25px;
    }

    .rtl .mg-xl-l-25 {
        margin-right: 25px;
    }

    .rtl .mg-xl-r-25-f {
        margin-left: 25px !important;
    }

    .rtl .mg-xl-l-25-f {
        margin-right: 25px !important;
    }

    .rtl .mg-xl-x-30 {
        margin-right: 30px;
        margin-left: 30px;
    }

    .rtl .mg-xl-x-30-f {
        margin-right: 30px !important;
        margin-left: 30px !important;
    }

    .rtl .mg-xl-r-30 {
        margin-left: 30px;
    }

    .rtl .mg-xl-l-30 {
        margin-right: 30px;
    }

    .rtl .mg-xl-r-30-f {
        margin-left: 30px !important;
    }

    .rtl .mg-xl-l-30-f {
        margin-right: 30px !important;
    }

    .rtl .mg-xl-x-35 {
        margin-right: 35px;
        margin-left: 35px;
    }

    .rtl .mg-xl-x-35-f {
        margin-right: 35px !important;
        margin-left: 35px !important;
    }

    .rtl .mg-xl-r-35 {
        margin-left: 35px;
    }

    .rtl .mg-xl-l-35 {
        margin-right: 35px;
    }

    .rtl .mg-xl-r-35-f {
        margin-left: 35px !important;
    }

    .rtl .mg-xl-l-35-f {
        margin-right: 35px !important;
    }

    .rtl .mg-xl-x-40 {
        margin-right: 40px;
        margin-left: 40px;
    }

    .rtl .mg-xl-x-40-f {
        margin-right: 40px !important;
        margin-left: 40px !important;
    }

    .rtl .mg-xl-r-40 {
        margin-left: 40px;
    }

    .rtl .mg-xl-l-40 {
        margin-right: 40px;
    }

    .rtl .mg-xl-r-40-f {
        margin-left: 40px !important;
    }

    .rtl .mg-xl-l-40-f {
        margin-right: 40px !important;
    }

    .rtl .mg-xl-x-45 {
        margin-right: 45px;
        margin-left: 45px;
    }

    .rtl .mg-xl-x-45-f {
        margin-right: 45px !important;
        margin-left: 45px !important;
    }

    .rtl .mg-xl-r-45 {
        margin-left: 45px;
    }

    .rtl .mg-xl-l-45 {
        margin-right: 45px;
    }

    .rtl .mg-xl-r-45-f {
        margin-left: 45px !important;
    }

    .rtl .mg-xl-l-45-f {
        margin-right: 45px !important;
    }

    .rtl .mg-xl-x-50 {
        margin-right: 50px;
        margin-left: 50px;
    }

    .rtl .mg-xl-x-50-f {
        margin-right: 50px !important;
        margin-left: 50px !important;
    }

    .rtl .mg-xl-r-50 {
        margin-left: 50px;
    }

    .rtl .mg-xl-l-50 {
        margin-right: 50px;
    }

    .rtl .mg-xl-r-50-f {
        margin-left: 50px !important;
    }

    .rtl .mg-xl-l-50-f {
        margin-right: 50px !important;
    }

    .rtl .mg-xl-x-55 {
        margin-right: 55px;
        margin-left: 55px;
    }

    .rtl .mg-xl-x-55-f {
        margin-right: 55px !important;
        margin-left: 55px !important;
    }

    .rtl .mg-xl-r-55 {
        margin-left: 55px;
    }

    .rtl .mg-xl-l-55 {
        margin-right: 55px;
    }

    .rtl .mg-xl-r-55-f {
        margin-left: 55px !important;
    }

    .rtl .mg-xl-l-55-f {
        margin-right: 55px !important;
    }

    .rtl .mg-xl-x-60 {
        margin-right: 60px;
        margin-left: 60px;
    }

    .rtl .mg-xl-x-60-f {
        margin-right: 60px !important;
        margin-left: 60px !important;
    }

    .rtl .mg-xl-r-60 {
        margin-left: 60px;
    }

    .rtl .mg-xl-l-60 {
        margin-right: 60px;
    }

    .rtl .mg-xl-r-60-f {
        margin-left: 60px !important;
    }

    .rtl .mg-xl-l-60-f {
        margin-right: 60px !important;
    }

    .rtl .mg-xl-x-65 {
        margin-right: 65px;
        margin-left: 65px;
    }

    .rtl .mg-xl-x-65-f {
        margin-right: 65px !important;
        margin-left: 65px !important;
    }

    .rtl .mg-xl-r-65 {
        margin-left: 65px;
    }

    .rtl .mg-xl-l-65 {
        margin-right: 65px;
    }

    .rtl .mg-xl-r-65-f {
        margin-left: 65px !important;
    }

    .rtl .mg-xl-l-65-f {
        margin-right: 65px !important;
    }

    .rtl .mg-xl-x-70 {
        margin-right: 70px;
        margin-left: 70px;
    }

    .rtl .mg-xl-x-70-f {
        margin-right: 70px !important;
        margin-left: 70px !important;
    }

    .rtl .mg-xl-r-70 {
        margin-left: 70px;
    }

    .rtl .mg-xl-l-70 {
        margin-right: 70px;
    }

    .rtl .mg-xl-r-70-f {
        margin-left: 70px !important;
    }

    .rtl .mg-xl-l-70-f {
        margin-right: 70px !important;
    }

    .rtl .mg-xl-x-75 {
        margin-right: 75px;
        margin-left: 75px;
    }

    .rtl .mg-xl-x-75-f {
        margin-right: 75px !important;
        margin-left: 75px !important;
    }

    .rtl .mg-xl-r-75 {
        margin-left: 75px;
    }

    .rtl .mg-xl-l-75 {
        margin-right: 75px;
    }

    .rtl .mg-xl-r-75-f {
        margin-left: 75px !important;
    }

    .rtl .mg-xl-l-75-f {
        margin-right: 75px !important;
    }

    .rtl .mg-xl-x-80 {
        margin-right: 80px;
        margin-left: 80px;
    }

    .rtl .mg-xl-x-80-f {
        margin-right: 80px !important;
        margin-left: 80px !important;
    }

    .rtl .mg-xl-r-80 {
        margin-left: 80px;
    }

    .rtl .mg-xl-l-80 {
        margin-right: 80px;
    }

    .rtl .mg-xl-r-80-f {
        margin-left: 80px !important;
    }

    .rtl .mg-xl-l-80-f {
        margin-right: 80px !important;
    }

    .rtl .mg-xl-x-85 {
        margin-right: 85px;
        margin-left: 85px;
    }

    .rtl .mg-xl-x-85-f {
        margin-right: 85px !important;
        margin-left: 85px !important;
    }

    .rtl .mg-xl-r-85 {
        margin-left: 85px;
    }

    .rtl .mg-xl-l-85 {
        margin-right: 85px;
    }

    .rtl .mg-xl-r-85-f {
        margin-left: 85px !important;
    }

    .rtl .mg-xl-l-85-f {
        margin-right: 85px !important;
    }

    .rtl .mg-xl-x-90 {
        margin-right: 90px;
        margin-left: 90px;
    }

    .rtl .mg-xl-x-90-f {
        margin-right: 90px !important;
        margin-left: 90px !important;
    }

    .rtl .mg-xl-r-90 {
        margin-left: 90px;
    }

    .rtl .mg-xl-l-90 {
        margin-right: 90px;
    }

    .rtl .mg-xl-r-90-f {
        margin-left: 90px !important;
    }

    .rtl .mg-xl-l-90-f {
        margin-right: 90px !important;
    }

    .rtl .mg-xl-x-95 {
        margin-right: 95px;
        margin-left: 95px;
    }

    .rtl .mg-xl-x-95-f {
        margin-right: 95px !important;
        margin-left: 95px !important;
    }

    .rtl .mg-xl-r-95 {
        margin-left: 95px;
    }

    .rtl .mg-xl-l-95 {
        margin-right: 95px;
    }

    .rtl .mg-xl-r-95-f {
        margin-left: 95px !important;
    }

    .rtl .mg-xl-l-95-f {
        margin-right: 95px !important;
    }

    .rtl .mg-xl-x-100 {
        margin-right: 100px;
        margin-left: 100px;
    }

    .rtl .mg-xl-x-100-f {
        margin-right: 100px !important;
        margin-left: 100px !important;
    }

    .rtl .mg-xl-r-100 {
        margin-left: 100px;
    }

    .rtl .mg-xl-l-100 {
        margin-right: 100px;
    }

    .rtl .mg-xl-r-100-f {
        margin-left: 100px !important;
    }

    .rtl .mg-xl-l-100-f {
        margin-right: 100px !important;
    }

    .rtl .mg-xl-r-auto {
        margin-left: auto;
    }

    .rtl .mg-xl-l-auto {
        margin-right: auto;
    }

    .rtl .mg-xl-x-auto {
        margin-right: auto;
        margin-left: auto;
    }
}

.rtl .pe-xl-0 {
    padding-left: 0 !important;
}

.rtl .ps-0 {
    padding-right: 0 !important;
}

.rtl .pe-1 {
    padding-left: 0.25rem !important;
}

.rtl .ps-1 {
    padding-right: 0.25rem !important;
}

.rtl .pe-2 {
    padding-left: 0.5rem !important;
}

.rtl .ps-2 {
    padding-right: 0.5rem !important;
}

.rtl .pe-3 {
    padding-left: 1rem !important;
}

.rtl .ps-3 {
    padding-right: 1rem !important;
}

.rtl .pe-xl-4 {
    padding-left: 1.5rem !important;
}

.rtl .ps-4 {
    padding-right: 1.5rem !important;
}

.rtl .pe-5 {
    padding-left: 3rem !important;
    padding-right: inherit !important;
}

.rtl .ps-5 {
    padding-right: 3rem !important;
}

@media (min-width: 576px) {
    .rtl .pe-sm-0 {
        padding-left: 0 !important;
    }

    .rtl .ps-sm-0 {
        padding-right: 0 !important;
    }

    .rtl .pe-sm-1 {
        padding-left: 0.25rem !important;
    }

    .rtl .ps-sm-1 {
        padding-right: 0.25rem !important;
    }

    .rtl .pe-sm-22 {
        padding-left: 0.5rem !important;
    }

    .rtl .ps-sm-2 {
        padding-right: 0.5rem !important;
    }

    .rtl .pe-sm-3 {
        padding-left: 1rem !important;
    }

    .rtl .ps-sm-3 {
        padding-right: 1rem !important;
    }

    .rtl .pe-sm-4 {
        padding-left: 1.5rem !important;
    }

    .rtl .ps-sm-4 {
        padding-right: 1.5rem !important;
    }

    .rtl .pe-sm-5 {
        padding-left: 3rem !important;
    }

    .rtl .ps-sm-5 {
        padding-right: 3rem !important;
    }
}

@media (min-width: 768px) {
    .rtl .pe-md-0 {
        padding-left: 0 !important;
    }

    .rtl .ps-md-0 {
        padding-right: 0 !important;
    }

    .rtl .pe-md-1 {
        padding-left: 0.25rem !important;
    }

    .rtl .ps-md-1 {
        padding-right: 0.25rem !important;
    }

    .rtl .pe-md-2 {
        padding-left: 0.5rem !important;
    }

    .rtl .ps-md-2 {
        padding-right: 0.5rem !important;
    }

    .rtl .pe-md-3 {
        padding-left: 1rem !important;
    }

    .rtl .ps-md-3 {
        padding-right: 1rem !important;
    }

    .rtl .pe-md-4 {
        padding-left: 1.5rem !important;
    }

    .rtl .ps-md-4 {
        padding-right: 1.5rem !important;
    }

    .rtl .pe-md-5 {
        padding-left: 3rem !important;
    }

    .rtl .ps-md-5 {
        padding-right: 3rem !important;
    }
}

@media (min-width: 992px) {
    .rtl .pe-lg-0 {
        padding-left: 0 !important;
    }

    .rtl .ps-lg-0 {
        padding-right: 0 !important;
    }

    .rtl .pe-lg-1 {
        padding-left: 0.25rem !important;
    }

    .rtl .ps-lg-1 {
        padding-right: 0.25rem !important;
    }

    .rtl .pe-lg-2 {
        padding-left: 0.5rem !important;
    }

    .rtl .ps-lg-2 {
        padding-right: 0.5rem !important;
    }

    .rtl .pe-lg-3 {
        padding-left: 1rem !important;
    }

    .rtl .ps-lg-3 {
        padding-right: 1rem !important;
    }

    .rtl .pe-lg-4 {
        padding-left: 1.5rem !important;
    }

    .rtl .ps-lg-4 {
        padding-right: 1.5rem !important;
    }

    .rtl .pe-lg-5 {
        padding-left: 3rem !important;
    }

    .rtl .ps-lg-5 {
        padding-right: 3rem !important;
    }
}

@media (min-width: 1200px) {
    .rtl .pe-xl-0 {
        padding-left: 0 !important;
    }

    .rtl .ps-xl-0 {
        padding-right: 0 !important;
    }

    .rtl .pe-xl-1 {
        padding-left: 0.25rem !important;
    }

    .rtl .ps-xl-1 {
        padding-right: 0.25rem !important;
    }

    .rtl .pe-xl-2 {
        padding-left: 0.5rem !important;
    }

    .rtl .ps-xl-2 {
        padding-right: 0.5rem !important;
    }

    .rtl .pe-xl-3 {
        padding-left: 1rem !important;
    }

    .rtl .ps-xl-3 {
        padding-right: 1rem !important;
    }

    .rtl .pe-xl-4 {
        padding-left: 1.5rem !important;
    }

    .rtl .ps-xl-4 {
        padding-right: 1.5rem !important;
    }

    .rtl .pe-xl-5 {
        padding-left: 3rem !important;
    }

    .rtl .ps-xl-5 {
        padding-right: 3rem !important;
    }
}

.rtl .pd-x-0 {
    padding-right: 0px;
    padding-left: 0px;
}

.rtl .pd-x-0-f {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.rtl .pd-r-0 {
    padding-left: 0px;
}

.rtl .pd-l-0 {
    padding-right: 0px;
}

.rtl .pd-r-0-f {
    padding-left: 0px !important;
}

.rtl .pd-l-0-f {
    padding-right: 0px !important;
}

.rtl .pd-x-1 {
    padding-right: 1px;
    padding-left: 1px;
}

.rtl .pd-x-1-f {
    padding-right: 1px !important;
    padding-left: 1px !important;
}

.rtl .pd-r-1 {
    padding-left: 1px;
}

.rtl .pd-l-1 {
    padding-right: 1px;
}

.rtl .pd-r-1-f {
    padding-left: 1px !important;
}

.rtl .pd-b-1-f {
    padding-bottom: 1px !important;
}

.rtl .pd-l-1-f {
    padding-right: 1px !important;
}

.rtl .pd-x-2 {
    padding-right: 2px;
    padding-left: 2px;
}

.rtl .pd-x-2-f {
    padding-right: 2px !important;
    padding-left: 2px !important;
}

.rtl .pd-r-2 {
    padding-left: 2px;
}

.rtl .pd-l-2 {
    padding-right: 2px;
}

.rtl .pd-r-2-f {
    padding-left: 2px !important;
}

.rtl .pd-l-2-f {
    padding-right: 2px !important;
}

.rtl .pd-x-3 {
    padding-right: 3px;
    padding-left: 3px;
}

.rtl .pd-x-3-f {
    padding-right: 3px !important;
    padding-left: 3px !important;
}

.rtl .pd-r-3 {
    padding-left: 3px;
}

.rtl .pd-l-3 {
    padding-right: 3px;
}

.rtl .pd-r-3-f {
    padding-left: 3px !important;
}

.rtl .pd-l-3-f {
    padding-right: 3px !important;
}

.rtl .pd-x-4 {
    padding-right: 4px;
    padding-left: 4px;
}

.rtl .pd-x-4-f {
    padding-right: 4px !important;
    padding-left: 4px !important;
}

.rtl .pd-r-4 {
    padding-left: 4px;
}

.rtl .pd-l-4 {
    padding-right: 4px;
}

.rtl .pd-r-4-f {
    padding-left: 4px !important;
}

.rtl .pd-l-4-f {
    padding-right: 4px !important;
}

.rtl .pd-x-5 {
    padding-right: 5px;
    padding-left: 5px;
}

.rtl .pd-x-5-f {
    padding-right: 5px !important;
    padding-left: 5px !important;
}

.rtl .pd-r-5 {
    padding-left: 5px;
}

.rtl .pd-l-5 {
    padding-right: 5px;
}

.rtl .pd-r-5-f {
    padding-left: 5px !important;
}

.rtl .pd-l-5-f {
    padding-right: 5px !important;
}

.rtl .pd-x-6 {
    padding-right: 6px;
    padding-left: 6px;
}

.rtl .pd-x-6-f {
    padding-right: 6px !important;
    padding-left: 6px !important;
}

.rtl .pd-r-6 {
    padding-left: 6px;
}

.rtl .pd-l-6 {
    padding-right: 6px;
}

.rtl .pd-r-6-f {
    padding-left: 6px !important;
}

.rtl .pd-l-6-f {
    padding-right: 6px !important;
}

.rtl .pd-x-7 {
    padding-right: 7px;
    padding-left: 7px;
}

.rtl .pd-x-7-f {
    padding-right: 7px !important;
    padding-left: 7px !important;
}

.rtl .pd-r-7 {
    padding-left: 7px;
}

.rtl .pd-l-7 {
    padding-right: 7px;
}

.rtl .pd-r-7-f {
    padding-left: 7px !important;
}

.rtl .pd-l-7-f {
    padding-right: 7px !important;
}

.rtl .pd-x-8 {
    padding-right: 8px;
    padding-left: 8px;
}

.rtl .pd-x-8-f {
    padding-right: 8px !important;
    padding-left: 8px !important;
}

.rtl .pd-r-8 {
    padding-left: 8px;
}

.rtl .pd-l-8 {
    padding-right: 8px;
}

.rtl .pd-r-8-f {
    padding-left: 8px !important;
}

.rtl .pd-l-8-f {
    padding-right: 8px !important;
}

.rtl .pd-x-9 {
    padding-right: 9px;
    padding-left: 9px;
}

.rtl .pd-x-9-f {
    padding-right: 9px !important;
    padding-left: 9px !important;
}

.rtl .pd-r-9 {
    padding-left: 9px;
}

.rtl .pd-l-9 {
    padding-right: 9px;
}

.rtl .pd-r-9-f {
    padding-left: 9px !important;
}

.rtl .pd-l-9-f {
    padding-right: 9px !important;
}

.rtl .pd-x-10 {
    padding-right: 10px;
    padding-left: 10px;
}

.rtl .pd-x-10-f {
    padding-right: 10px !important;
    padding-left: 10px !important;
}

.rtl .pd-r-10 {
    padding-left: 10px;
}

.rtl .pd-l-10 {
    padding-right: 10px;
}

.rtl .pd-r-10-f {
    padding-left: 10px !important;
}

.rtl .pd-l-10-f {
    padding-right: 10px !important;
}

.rtl .pd-x-15 {
    padding-right: 15px;
    padding-left: 15px;
}

.rtl .pd-x-15-f {
    padding-right: 15px !important;
    padding-left: 15px !important;
}

.rtl .pd-r-15 {
    padding-left: 15px;
}

.rtl .pd-l-15 {
    padding-right: 15px;
}

.rtl .pd-r-15-f {
    padding-left: 15px !important;
}

.rtl .pd-l-15-f {
    padding-right: 15px !important;
}

.rtl .pd-x-20 {
    padding-right: 20px;
    padding-left: 20px;
}

.rtl .pd-x-20-f {
    padding-right: 20px !important;
    padding-left: 20px !important;
}

.rtl .pd-r-20 {
    padding-left: 20px;
}

.rtl .pd-l-20 {
    padding-right: 20px;
}

.rtl .pd-r-20-f {
    padding-left: 20px !important;
}

.rtl .pd-l-20-f {
    padding-right: 20px !important;
}

.rtl .pd-x-25 {
    padding-right: 25px;
    padding-left: 25px;
}

.rtl .pd-x-25-f {
    padding-right: 25px !important;
    padding-left: 25px !important;
}

.rtl .pd-r-25 {
    padding-left: 25px;
}

.rtl .pd-l-25 {
    padding-right: 25px;
}

.rtl .pd-r-25-f {
    padding-left: 25px !important;
}

.rtl .pd-l-25-f {
    padding-right: 25px !important;
}

.rtl .pd-x-30 {
    padding-right: 30px;
    padding-left: 30px;
}

.rtl .pd-x-30-f {
    padding-right: 30px !important;
    padding-left: 30px !important;
}

.rtl .pd-r-30-f {
    padding-left: 30px !important;
}

.rtl .pd-l-30-f {
    padding-right: 30px !important;
}

.rtl .pd-x-35 {
    padding-right: 35px;
    padding-left: 35px;
}

.rtl .pd-x-35-f {
    padding-right: 35px !important;
    padding-left: 35px !important;
}

.rtl .pd-r-35 {
    padding-left: 35px;
}

.rtl .pd-l-35 {
    padding-right: 35px;
}

.rtl .pd-r-35-f {
    padding-left: 35px !important;
}

.rtl .pd-l-35-f {
    padding-right: 35px !important;
}

.rtl .pd-x-40 {
    padding-right: 40px;
    padding-left: 40px;
}

.rtl .pd-x-40-f {
    padding-right: 40px !important;
    padding-left: 40px !important;
}

.rtl .pd-r-40 {
    padding-left: 40px;
}

.rtl .pd-l-40 {
    padding-right: 40px;
}

.rtl .pd-r-40-f {
    padding-left: 40px !important;
}

.rtl .pd-l-40-f {
    padding-right: 40px !important;
}

.rtl .pd-x-45 {
    padding-right: 45px;
    padding-left: 45px;
}

.rtl .pd-x-45-f {
    padding-right: 45px !important;
    padding-left: 45px !important;
}

.rtl .pd-r-45 {
    padding-left: 45px;
}

.rtl .pd-l-45 {
    padding-right: 45px;
}

.rtl .pd-r-45-f {
    padding-left: 45px !important;
}

.rtl .pd-l-45-f {
    padding-right: 45px !important;
}

.rtl .pd-x-50 {
    padding-right: 50px;
    padding-left: 50px;
}

.rtl .pd-x-50-f {
    padding-right: 50px !important;
    padding-left: 50px !important;
}

.rtl .pd-r-50-f {
    padding-left: 50px !important;
}

.rtl .pd-l-50-f {
    padding-right: 50px !important;
}

.rtl .pd-x-55 {
    padding-right: 55px;
    padding-left: 55px;
}

.rtl .pd-x-55-f {
    padding-right: 55px !important;
    padding-left: 55px !important;
}

.rtl .pd-r-55 {
    padding-left: 55px;
}

.rtl .pd-l-55 {
    padding-right: 55px;
}

.rtl .pd-r-55-f {
    padding-left: 55px !important;
}

.rtl .pd-l-55-f {
    padding-right: 55px !important;
}

.rtl .pd-x-60 {
    padding-right: 60px;
    padding-left: 60px;
}

.rtl .pd-x-60-f {
    padding-right: 60px !important;
    padding-left: 60px !important;
}

.rtl .pd-r-60 {
    padding-left: 60px;
}

.rtl .pd-l-60 {
    padding-right: 60px;
}

.rtl .pd-r-60-f {
    padding-left: 60px !important;
}

.rtl .pd-l-60-f {
    padding-right: 60px !important;
}

.rtl .pd-x-65 {
    padding-right: 65px;
    padding-left: 65px;
}

.rtl .pd-x-65-f {
    padding-right: 65px !important;
    padding-left: 65px !important;
}

.rtl .pd-r-65 {
    padding-left: 65px;
}

.rtl .pd-l-65 {
    padding-right: 65px;
}

.rtl .pd-r-65-f {
    padding-left: 65px !important;
}

.rtl .pd-l-65-f {
    padding-right: 65px !important;
}

.rtl .pd-x-70 {
    padding-right: 70px;
    padding-left: 70px;
}

.rtl .pd-x-70-f {
    padding-right: 70px !important;
    padding-left: 70px !important;
}

.rtl .pd-r-70 {
    padding-left: 70px;
}

.rtl .pd-l-70 {
    padding-right: 70px;
}

.rtl .pd-r-70-f {
    padding-left: 70px !important;
}

.rtl .pd-l-70-f {
    padding-right: 70px !important;
}

.rtl .pd-x-75 {
    padding-right: 75px;
    padding-left: 75px;
}

.rtl .pd-x-75-f {
    padding-right: 75px !important;
    padding-left: 75px !important;
}

.rtl .pd-r-75 {
    padding-left: 75px;
}

.rtl .pd-l-75 {
    padding-right: 75px;
}

.rtl .pd-r-75-f {
    padding-left: 75px !important;
}

.rtl .pd-l-75-f {
    padding-right: 75px !important;
}

.rtl .pd-x-80 {
    padding-right: 80px;
    padding-left: 80px;
}

.rtl .pd-x-80-f {
    padding-right: 80px !important;
    padding-left: 80px !important;
}

.rtl .pd-r-80 {
    padding-left: 80px;
}

.rtl .pd-l-80 {
    padding-right: 80px;
}

.rtl .pd-r-80-f {
    padding-left: 80px !important;
}

.rtl .pd-l-80-f {
    padding-right: 80px !important;
}

.rtl .pd-x-85 {
    padding-right: 85px;
    padding-left: 85px;
}

.rtl .pd-x-85-f {
    padding-right: 85px !important;
    padding-left: 85px !important;
}

.rtl .pd-r-85 {
    padding-left: 85px;
}

.rtl .pd-l-85 {
    padding-right: 85px;
}

.rtl .pd-r-85-f {
    padding-left: 85px !important;
}

.rtl .pd-l-85-f {
    padding-right: 85px !important;
}

.rtl .pd-x-90 {
    padding-right: 90px;
    padding-left: 90px;
}

.rtl .pd-x-90-f {
    padding-right: 90px !important;
    padding-left: 90px !important;
}

.rtl .pd-r-90 {
    padding-left: 90px;
}

.rtl .pd-l-90 {
    padding-right: 90px;
}

.rtl .pd-r-90-f {
    padding-left: 90px !important;
}

.rtl .pd-l-90-f {
    padding-right: 90px !important;
}

.rtl .pd-x-95 {
    padding-right: 95px;
    padding-left: 95px;
}

.rtl .pd-x-95-f {
    padding-right: 95px !important;
    padding-left: 95px !important;
}

.rtl .pd-r-95 {
    padding-left: 95px;
}

.rtl .pd-l-95 {
    padding-right: 95px;
}

.rtl .pd-r-95-f {
    padding-left: 95px !important;
}

.rtl .pd-l-95-f {
    padding-right: 95px !important;
}

.rtl .pd-x-100 {
    padding-right: 100px;
    padding-left: 100px;
}

.rtl .pd-x-100-f {
    padding-right: 100px !important;
    padding-left: 100px !important;
}

.rtl .pd-r-100 {
    padding-left: 100px;
}

.rtl .pd-l-100 {
    padding-right: 100px;
}

.rtl .pd-r-100-f {
    padding-left: 100px !important;
}

.rtl .pd-l-100-f {
    padding-right: 100px !important;
}

.rtl .pd-x-110 {
    padding-right: 110px;
    padding-left: 110px;
}

.rtl .pd-x-110-f {
    padding-right: 110px !important;
    padding-left: 110px !important;
}

.rtl .pd-r-110 {
    padding-left: 110px;
}

.rtl .pd-l-110 {
    padding-right: 110px;
}

.rtl .pd-r-110-f {
    padding-left: 110px !important;
}

.rtl .pd-l-110-f {
    padding-right: 110px !important;
}

.rtl .pd-x-120 {
    padding-right: 120px;
    padding-left: 120px;
}

.rtl .pd-x-120-f {
    padding-right: 120px !important;
    padding-left: 120px !important;
}

.rtl .pd-r-120-f {
    padding-left: 120px !important;
}

.rtl .pd-l-120-f {
    padding-right: 120px !important;
}

.rtl .pd-x-130 {
    padding-right: 130px;
    padding-left: 130px;
}

.rtl .pd-x-130-f {
    padding-right: 130px !important;
    padding-left: 130px !important;
}

.rtl .pd-r-130 {
    padding-left: 130px;
}

.rtl .pd-l-130 {
    padding-right: 130px;
}

.rtl .pd-r-130-f {
    padding-left: 130px !important;
}

.rtl .pd-l-130-f {
    padding-right: 130px !important;
}

.rtl .pd-x-140 {
    padding-right: 140px;
    padding-left: 140px;
}

.rtl .pd-x-140-f {
    padding-right: 140px !important;
    padding-left: 140px !important;
}

.rtl .pd-r-140 {
    padding-left: 140px;
}

.rtl .pd-l-140 {
    padding-right: 140px;
}

.rtl .pd-r-140-f {
    padding-left: 140px !important;
}

.rtl .pd-l-140-f {
    padding-right: 140px !important;
}

.rtl .pd-x-150 {
    padding-right: 150px;
    padding-left: 150px;
}

.rtl .pd-x-150-f {
    padding-right: 150px !important;
    padding-left: 150px !important;
}

.rtl .pd-r-150 {
    padding-left: 150px;
}

.rtl .pd-l-150 {
    padding-right: 150px;
}

.rtl .pd-r-150-f {
    padding-left: 150px !important;
}

.rtl .pd-l-150-f {
    padding-right: 150px !important;
}

.rtl .pd-x-160 {
    padding-right: 160px;
    padding-left: 160px;
}

.rtl .pd-x-160-f {
    padding-right: 160px !important;
    padding-left: 160px !important;
}

.rtl .pd-r-160 {
    padding-left: 160px;
}

.rtl .pd-l-160 {
    padding-right: 160px;
}

.rtl .pd-r-160-f {
    padding-left: 160px !important;
}

.rtl .pd-l-160-f {
    padding-right: 160px !important;
}

.rtl .pd-x-170 {
    padding-right: 170px;
    padding-left: 170px;
}

.rtl .pd-x-170-f {
    padding-right: 170px !important;
    padding-left: 170px !important;
}

.rtl .pd-r-170 {
    padding-left: 170px;
}

.rtl .pd-l-170 {
    padding-right: 170px;
}

.rtl .pd-r-170-f {
    padding-left: 170px !important;
}

.rtl .pd-l-170-f {
    padding-right: 170px !important;
}

.rtl .pd-x-180 {
    padding-right: 180px;
    padding-left: 180px;
}

.rtl .pd-x-180-f {
    padding-right: 180px !important;
    padding-left: 180px !important;
}

.rtl .pd-r-180 {
    padding-left: 180px;
}

.rtl .pd-l-180 {
    padding-right: 180px;
}

.rtl .pd-r-180-f {
    padding-left: 180px !important;
}

.rtl .pd-l-180-f {
    padding-right: 180px !important;
}

.rtl .pd-x-190 {
    padding-right: 190px;
    padding-left: 190px;
}

.rtl .pd-x-190-f {
    padding-right: 190px !important;
    padding-left: 190px !important;
}

.rtl .pd-r-190 {
    padding-left: 190px;
}

.rtl .pd-l-190 {
    padding-right: 190px;
}

.rtl .pd-r-190-f {
    padding-left: 190px !important;
}

.rtl .pd-l-190-f {
    padding-right: 190px !important;
}

.rtl .pd-x-200 {
    padding-right: 200px;
    padding-left: 200px;
}

.rtl .pd-x-200-f {
    padding-right: 200px !important;
    padding-left: 200px !important;
}

.rtl .pd-r-200 {
    padding-left: 200px;
}

.rtl .pd-l-200 {
    padding-right: 200px;
}

.rtl .pd-r-200-f {
    padding-left: 200px !important;
}

.rtl .pd-l-200-f {
    padding-right: 200px !important;
}

@media (min-width: 480px) {
    .rtl .pd-xs-x-0 {
        padding-right: 0px;
        padding-left: 0px;
    }

    .rtl .pd-xs-x-0-f {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .rtl .pd-xs-r-0 {
        padding-left: 0px;
    }

    .rtl .pd-xs-l-0 {
        padding-right: 0px;
    }

    .rtl .pd-xs-r-0-f {
        padding-left: 0px !important;
    }

    .rtl .pd-xs-l-0-f {
        padding-right: 0px !important;
    }

    .rtl .pd-xs-x-1 {
        padding-right: 1px;
        padding-left: 1px;
    }

    .rtl .pd-xs-x-1-f {
        padding-right: 1px !important;
        padding-left: 1px !important;
    }

    .rtl .pd-xs-r-1 {
        padding-left: 1px;
    }

    .rtl .pd-xs-l-1 {
        padding-right: 1px;
    }

    .rtl .pd-xs-r-1-f {
        padding-left: 1px !important;
    }

    .rtl .pd-xs-l-1-f {
        padding-right: 1px !important;
    }

    .rtl .pd-xs-x-2 {
        padding-right: 2px;
        padding-left: 2px;
    }

    .rtl .pd-xs-x-2-f {
        padding-right: 2px !important;
        padding-left: 2px !important;
    }

    .rtl .pd-xs-r-2 {
        padding-left: 2px;
    }

    .rtl .pd-xs-l-2 {
        padding-right: 2px;
    }

    .rtl .pd-xs-r-2-f {
        padding-left: 2px !important;
    }

    .rtl .pd-xs-l-2-f {
        padding-right: 2px !important;
    }

    .rtl .pd-xs-x-3 {
        padding-right: 3px;
        padding-left: 3px;
    }

    .rtl .pd-xs-x-3-f {
        padding-right: 3px !important;
        padding-left: 3px !important;
    }

    .rtl .pd-xs-r-3 {
        padding-left: 3px;
    }

    .rtl .pd-xs-l-3 {
        padding-right: 3px;
    }

    .rtl .pd-xs-r-3-f {
        padding-left: 3px !important;
    }

    .rtl .pd-xs-l-3-f {
        padding-right: 3px !important;
    }

    .rtl .pd-xs-x-4 {
        padding-right: 4px;
        padding-left: 4px;
    }

    .rtl .pd-xs-x-4-f {
        padding-right: 4px !important;
        padding-left: 4px !important;
    }

    .rtl .pd-xs-r-4 {
        padding-left: 4px;
    }

    .rtl .pd-xs-l-4 {
        padding-right: 4px;
    }

    .rtl .pd-xs-r-4-f {
        padding-left: 4px !important;
    }

    .rtl .pd-xs-l-4-f {
        padding-right: 4px !important;
    }

    .rtl .pd-xs-x-5 {
        padding-right: 5px;
        padding-left: 5px;
    }

    .rtl .pd-xs-x-5-f {
        padding-right: 5px !important;
        padding-left: 5px !important;
    }

    .rtl .pd-xs-r-5 {
        padding-left: 5px;
    }

    .rtl .pd-xs-l-5 {
        padding-right: 5px;
    }

    .rtl .pd-xs-r-5-f {
        padding-left: 5px !important;
    }

    .rtl .pd-xs-l-5-f {
        padding-right: 5px !important;
    }

    .rtl .pd-xs-x-6 {
        padding-right: 6px;
        padding-left: 6px;
    }

    .rtl .pd-xs-x-6-f {
        padding-right: 6px !important;
        padding-left: 6px !important;
    }

    .rtl .pd-xs-r-6 {
        padding-left: 6px;
    }

    .rtl .pd-xs-l-6 {
        padding-right: 6px;
    }

    .rtl .pd-xs-r-6-f {
        padding-left: 6px !important;
    }

    .rtl .pd-xs-l-6-f {
        padding-right: 6px !important;
    }

    .rtl .pd-xs-x-7 {
        padding-right: 7px;
        padding-left: 7px;
    }

    .rtl .pd-xs-x-7-f {
        padding-right: 7px !important;
        padding-left: 7px !important;
    }

    .rtl .pd-xs-r-7 {
        padding-left: 7px;
    }

    .rtl .pd-xs-l-7 {
        padding-right: 7px;
    }

    .rtl .pd-xs-r-7-f {
        padding-left: 7px !important;
    }

    .rtl .pd-xs-l-7-f {
        padding-right: 7px !important;
    }

    .rtl .pd-xs-x-8 {
        padding-right: 8px;
        padding-left: 8px;
    }

    .rtl .pd-xs-x-8-f {
        padding-right: 8px !important;
        padding-left: 8px !important;
    }

    .rtl .pd-xs-r-8 {
        padding-left: 8px;
    }

    .rtl .pd-xs-l-8 {
        padding-right: 8px;
    }

    .rtl .pd-xs-r-8-f {
        padding-left: 8px !important;
    }

    .rtl .pd-xs-l-8-f {
        padding-right: 8px !important;
    }

    .rtl .pd-xs-x-9 {
        padding-right: 9px;
        padding-left: 9px;
    }

    .rtl .pd-xs-x-9-f {
        padding-right: 9px !important;
        padding-left: 9px !important;
    }

    .rtl .pd-xs-r-9 {
        padding-left: 9px;
    }

    .rtl .pd-xs-l-9 {
        padding-right: 9px;
    }

    .rtl .pd-xs-r-9-f {
        padding-left: 9px !important;
    }

    .rtl .pd-xs-l-9-f {
        padding-right: 9px !important;
    }

    .rtl .pd-xs-x-10 {
        padding-right: 10px;
        padding-left: 10px;
    }

    .rtl .pd-xs-x-10-f {
        padding-right: 10px !important;
        padding-left: 10px !important;
    }

    .rtl .pd-xs-r-10 {
        padding-left: 10px;
    }

    .rtl .pd-xs-l-10 {
        padding-right: 10px;
    }

    .rtl .pd-xs-r-10-f {
        padding-left: 10px !important;
    }

    .rtl .pd-xs-l-10-f {
        padding-right: 10px !important;
    }

    .rtl .pd-xs-x-15 {
        padding-right: 15px;
        padding-left: 15px;
    }

    .rtl .pd-xs-x-15-f {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }

    .rtl .pd-xs-r-15 {
        padding-left: 15px;
    }

    .rtl .pd-xs-l-15 {
        padding-right: 15px;
    }

    .rtl .pd-xs-r-15-f {
        padding-left: 15px !important;
    }

    .rtl .pd-xs-l-15-f {
        padding-right: 15px !important;
    }

    .rtl .pd-xs-x-20 {
        padding-right: 20px;
        padding-left: 20px;
    }

    .rtl .pd-xs-x-20-f {
        padding-right: 20px !important;
        padding-left: 20px !important;
    }

    .rtl .pd-xs-r-20 {
        padding-left: 20px;
    }

    .rtl .pd-xs-l-20 {
        padding-right: 20px;
    }

    .rtl .pd-xs-r-20-f {
        padding-left: 20px !important;
    }

    .rtl .pd-xs-l-20-f {
        padding-right: 20px !important;
    }

    .rtl .pd-xs-x-25 {
        padding-right: 25px;
        padding-left: 25px;
    }

    .rtl .pd-xs-x-25-f {
        padding-right: 25px !important;
        padding-left: 25px !important;
    }

    .rtl .pd-xs-r-25 {
        padding-left: 25px;
    }

    .rtl .pd-xs-l-25 {
        padding-right: 25px;
    }

    .rtl .pd-xs-r-25-f {
        padding-left: 25px !important;
    }

    .rtl .pd-xs-l-25-f {
        padding-right: 25px !important;
    }

    .rtl .pd-xs-x-30 {
        padding-right: 30px;
        padding-left: 30px;
    }

    .rtl .pd-xs-x-30-f {
        padding-right: 30px !important;
        padding-left: 30px !important;
    }

    .rtl .pd-xs-r-30 {
        padding-left: 30px;
    }

    .rtl .pd-xs-l-30 {
        padding-right: 30px;
    }

    .rtl .pd-xs-r-30-f {
        padding-left: 30px !important;
    }

    .rtl .pd-xs-l-30-f {
        padding-right: 30px !important;
    }

    .rtl .pd-xs-x-35 {
        padding-right: 35px;
        padding-left: 35px;
    }

    .rtl .pd-xs-x-35-f {
        padding-right: 35px !important;
        padding-left: 35px !important;
    }

    .rtl .pd-xs-r-35 {
        padding-left: 35px;
    }

    .rtl .pd-xs-l-35 {
        padding-right: 35px;
    }

    .rtl .pd-xs-r-35-f {
        padding-left: 35px !important;
    }

    .rtl .pd-xs-l-35-f {
        padding-right: 35px !important;
    }

    .rtl .pd-xs-x-40 {
        padding-right: 40px;
        padding-left: 40px;
    }

    .rtl .pd-xs-x-40-f {
        padding-right: 40px !important;
        padding-left: 40px !important;
    }

    .rtl .pd-xs-r-40 {
        padding-left: 40px;
    }

    .rtl .pd-xs-l-40 {
        padding-right: 40px;
    }

    .rtl .pd-xs-r-40-f {
        padding-left: 40px !important;
    }

    .rtl .pd-xs-l-40-f {
        padding-right: 40px !important;
    }

    .rtl .pd-xs-x-45 {
        padding-right: 45px;
        padding-left: 45px;
    }

    .rtl .pd-xs-x-45-f {
        padding-right: 45px !important;
        padding-left: 45px !important;
    }

    .rtl .pd-xs-r-45 {
        padding-left: 45px;
    }

    .rtl .pd-xs-l-45 {
        padding-right: 45px;
    }

    .rtl .pd-xs-r-45-f {
        padding-left: 45px !important;
    }

    .rtl .pd-xs-l-45-f {
        padding-right: 45px !important;
    }

    .rtl .pd-xs-x-50 {
        padding-right: 50px;
        padding-left: 50px;
    }

    .rtl .pd-xs-x-50-f {
        padding-right: 50px !important;
        padding-left: 50px !important;
    }

    .rtl .pd-xs-r-50 {
        padding-left: 50px;
    }

    .rtl .pd-xs-l-50 {
        padding-right: 50px;
    }

    .rtl .pd-xs-r-50-f {
        padding-left: 50px !important;
    }

    .rtl .pd-xs-l-50-f {
        padding-right: 50px !important;
    }

    .rtl .pd-xs-x-55 {
        padding-right: 55px;
        padding-left: 55px;
    }

    .rtl .pd-xs-x-55-f {
        padding-right: 55px !important;
        padding-left: 55px !important;
    }

    .rtl .pd-xs-r-55 {
        padding-left: 55px;
    }

    .rtl .pd-xs-l-55 {
        padding-right: 55px;
    }

    .rtl .pd-xs-r-55-f {
        padding-left: 55px !important;
    }

    .rtl .pd-xs-l-55-f {
        padding-right: 55px !important;
    }

    .rtl .pd-xs-x-60 {
        padding-right: 60px;
        padding-left: 60px;
    }

    .rtl .pd-xs-x-60-f {
        padding-right: 60px !important;
        padding-left: 60px !important;
    }

    .rtl .pd-xs-r-60 {
        padding-left: 60px;
    }

    .rtl .pd-xs-l-60 {
        padding-right: 60px;
    }

    .rtl .pd-xs-r-60-f {
        padding-left: 60px !important;
    }

    .rtl .pd-xs-l-60-f {
        padding-right: 60px !important;
    }

    .rtl .pd-xs-x-65 {
        padding-right: 65px;
        padding-left: 65px;
    }

    .rtl .pd-xs-x-65-f {
        padding-right: 65px !important;
        padding-left: 65px !important;
    }

    .rtl .pd-xs-r-65 {
        padding-left: 65px;
    }

    .rtl .pd-xs-l-65 {
        padding-right: 65px;
    }

    .rtl .pd-xs-r-65-f {
        padding-left: 65px !important;
    }

    .rtl .pd-xs-l-65-f {
        padding-right: 65px !important;
    }

    .rtl .pd-xs-x-70 {
        padding-right: 70px;
        padding-left: 70px;
    }

    .rtl .pd-xs-x-70-f {
        padding-right: 70px !important;
        padding-left: 70px !important;
    }

    .rtl .pd-xs-r-70 {
        padding-left: 70px;
    }

    .rtl .pd-xs-l-70 {
        padding-right: 70px;
    }

    .rtl .pd-xs-r-70-f {
        padding-left: 70px !important;
    }

    .rtl .pd-xs-l-70-f {
        padding-right: 70px !important;
    }

    .rtl .pd-xs-x-75 {
        padding-right: 75px;
        padding-left: 75px;
    }

    .rtl .pd-xs-x-75-f {
        padding-right: 75px !important;
        padding-left: 75px !important;
    }

    .rtl .pd-xs-r-75 {
        padding-left: 75px;
    }

    .rtl .pd-xs-l-75 {
        padding-right: 75px;
    }

    .rtl .pd-xs-r-75-f {
        padding-left: 75px !important;
    }

    .rtl .pd-xs-l-75-f {
        padding-right: 75px !important;
    }

    .rtl .pd-xs-x-80 {
        padding-right: 80px;
        padding-left: 80px;
    }

    .rtl .pd-xs-x-80-f {
        padding-right: 80px !important;
        padding-left: 80px !important;
    }

    .rtl .pd-xs-r-80 {
        padding-left: 80px;
    }

    .rtl .pd-xs-l-80 {
        padding-right: 80px;
    }

    .rtl .pd-xs-r-80-f {
        padding-left: 80px !important;
    }

    .rtl .pd-xs-l-80-f {
        padding-right: 80px !important;
    }

    .rtl .pd-xs-x-85 {
        padding-right: 85px;
        padding-left: 85px;
    }

    .rtl .pd-xs-x-85-f {
        padding-right: 85px !important;
        padding-left: 85px !important;
    }

    .rtl .pd-xs-r-85 {
        padding-left: 85px;
    }

    .rtl .pd-xs-l-85 {
        padding-right: 85px;
    }

    .rtl .pd-xs-r-85-f {
        padding-left: 85px !important;
    }

    .rtl .pd-xs-l-85-f {
        padding-right: 85px !important;
    }

    .rtl .pd-xs-x-90 {
        padding-right: 90px;
        padding-left: 90px;
    }

    .rtl .pd-xs-x-90-f {
        padding-right: 90px !important;
        padding-left: 90px !important;
    }

    .rtl .pd-xs-r-90 {
        padding-left: 90px;
    }

    .rtl .pd-xs-l-90 {
        padding-right: 90px;
    }

    .rtl .pd-xs-r-90-f {
        padding-left: 90px !important;
    }

    .rtl .pd-xs-l-90-f {
        padding-right: 90px !important;
    }

    .rtl .pd-xs-x-95 {
        padding-right: 95px;
        padding-left: 95px;
    }

    .rtl .pd-xs-x-95-f {
        padding-right: 95px !important;
        padding-left: 95px !important;
    }

    .rtl .pd-xs-r-95 {
        padding-left: 95px;
    }

    .rtl .pd-xs-l-95 {
        padding-right: 95px;
    }

    .rtl .pd-xs-r-95-f {
        padding-left: 95px !important;
    }

    .rtl .pd-xs-l-95-f {
        padding-right: 95px !important;
    }

    .rtl .pd-xs-x-100 {
        padding-right: 100px;
        padding-left: 100px;
    }

    .rtl .pd-xs-x-100-f {
        padding-right: 100px !important;
        padding-left: 100px !important;
    }

    .rtl .pd-xs-r-100 {
        padding-left: 100px;
    }

    .rtl .pd-xs-l-100 {
        padding-right: 100px;
    }

    .rtl .pd-xs-r-100-f {
        padding-left: 100px !important;
    }

    .rtl .pd-xs-l-100-f {
        padding-right: 100px !important;
    }

    .rtl .pd-xs-x-110 {
        padding-right: 110px;
        padding-left: 110px;
    }

    .rtl .pd-xs-x-110-f {
        padding-right: 110px !important;
        padding-left: 110px !important;
    }

    .rtl .pd-xs-r-110 {
        padding-left: 110px;
    }

    .rtl .pd-xs-l-110 {
        padding-right: 110px;
    }

    .rtl .pd-xs-r-110-f {
        padding-left: 110px !important;
    }

    .rtl .pd-xs-l-110-f {
        padding-right: 110px !important;
    }

    .rtl .pd-xs-x-120 {
        padding-right: 120px;
        padding-left: 120px;
    }

    .rtl .pd-xs-x-120-f {
        padding-right: 120px !important;
        padding-left: 120px !important;
    }

    .rtl .pd-xs-r-120 {
        padding-left: 120px;
    }

    .rtl .pd-xs-l-120 {
        padding-right: 120px;
    }

    .rtl .pd-xs-r-120-f {
        padding-left: 120px !important;
    }

    .rtl .pd-xs-l-120-f {
        padding-right: 120px !important;
    }

    .rtl .pd-xs-x-130 {
        padding-right: 130px;
        padding-left: 130px;
    }

    .rtl .pd-xs-x-130-f {
        padding-right: 130px !important;
        padding-left: 130px !important;
    }

    .rtl .pd-xs-r-130 {
        padding-left: 130px;
    }

    .rtl .pd-xs-l-130 {
        padding-right: 130px;
    }

    .rtl .pd-xs-r-130-f {
        padding-left: 130px !important;
    }

    .rtl .pd-xs-l-130-f {
        padding-right: 130px !important;
    }

    .rtl .pd-xs-x-140 {
        padding-right: 140px;
        padding-left: 140px;
    }

    .rtl .pd-xs-x-140-f {
        padding-right: 140px !important;
        padding-left: 140px !important;
    }

    .rtl .pd-xs-r-140 {
        padding-left: 140px;
    }

    .rtl .pd-xs-l-140 {
        padding-right: 140px;
    }

    .rtl .pd-xs-r-140-f {
        padding-left: 140px !important;
    }

    .rtl .pd-xs-l-140-f {
        padding-right: 140px !important;
    }

    .rtl .pd-xs-x-150 {
        padding-right: 150px;
        padding-left: 150px;
    }

    .rtl .pd-xs-x-150-f {
        padding-right: 150px !important;
        padding-left: 150px !important;
    }

    .rtl .pd-xs-r-150 {
        padding-left: 150px;
    }

    .rtl .pd-xs-l-150 {
        padding-right: 150px;
    }

    .rtl .pd-xs-r-150-f {
        padding-left: 150px !important;
    }

    .rtl .pd-xs-l-150-f {
        padding-right: 150px !important;
    }

    .rtl .pd-xs-x-160 {
        padding-right: 160px;
        padding-left: 160px;
    }

    .rtl .pd-xs-x-160-f {
        padding-right: 160px !important;
        padding-left: 160px !important;
    }

    .rtl .pd-xs-r-160 {
        padding-left: 160px;
    }

    .rtl .pd-xs-l-160 {
        padding-right: 160px;
    }

    .rtl .pd-xs-r-160-f {
        padding-left: 160px !important;
    }

    .rtl .pd-xs-l-160-f {
        padding-right: 160px !important;
    }

    .rtl .pd-xs-x-170 {
        padding-right: 170px;
        padding-left: 170px;
    }

    .rtl .pd-xs-x-170-f {
        padding-right: 170px !important;
        padding-left: 170px !important;
    }

    .rtl .pd-xs-r-170 {
        padding-left: 170px;
    }

    .rtl .pd-xs-l-170 {
        padding-right: 170px;
    }

    .rtl .pd-xs-r-170-f {
        padding-left: 170px !important;
    }

    .rtl .pd-xs-l-170-f {
        padding-right: 170px !important;
    }

    .rtl .pd-xs-x-180 {
        padding-right: 180px;
        padding-left: 180px;
    }

    .rtl .pd-xs-x-180-f {
        padding-right: 180px !important;
        padding-left: 180px !important;
    }

    .rtl .pd-xs-r-180 {
        padding-left: 180px;
    }

    .rtl .pd-xs-l-180 {
        padding-right: 180px;
    }

    .rtl .pd-xs-r-180-f {
        padding-left: 180px !important;
    }

    .rtl .pd-xs-l-180-f {
        padding-right: 180px !important;
    }

    .rtl .pd-xs-x-190 {
        padding-right: 190px;
        padding-left: 190px;
    }

    .rtl .pd-xs-x-190-f {
        padding-right: 190px !important;
        padding-left: 190px !important;
    }

    .rtl .pd-xs-r-190 {
        padding-left: 190px;
    }

    .rtl .pd-xs-l-190 {
        padding-right: 190px;
    }

    .rtl .pd-xs-r-190-f {
        padding-left: 190px !important;
    }

    .rtl .pd-xs-l-190-f {
        padding-right: 190px !important;
    }

    .rtl .pd-xs-x-200 {
        padding-right: 200px;
        padding-left: 200px;
    }

    .rtl .pd-xs-x-200-f {
        padding-right: 200px !important;
        padding-left: 200px !important;
    }

    .rtl .pd-xs-r-200 {
        padding-left: 200px;
    }

    .rtl .pd-xs-l-200 {
        padding-right: 200px;
    }

    .rtl .pd-xs-r-200-f {
        padding-left: 200px !important;
    }

    .rtl .pd-xs-l-200-f {
        padding-right: 200px !important;
    }
}

@media (min-width: 576px) {
    .rtl .pd-sm-x-0 {
        padding-right: 0px;
        padding-left: 0px;
    }

    .rtl .pd-sm-x-0-f {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .rtl .pd-sm-r-0 {
        padding-left: 0px;
    }

    .rtl .pd-sm-l-0 {
        padding-right: 0px;
    }

    .rtl .pd-sm-r-0-f {
        padding-left: 0px !important;
    }

    .rtl .pd-sm-l-0-f {
        padding-right: 0px !important;
    }

    .rtl .pd-sm-x-1 {
        padding-right: 1px;
        padding-left: 1px;
    }

    .rtl .pd-sm-x-1-f {
        padding-right: 1px !important;
        padding-left: 1px !important;
    }

    .rtl .pd-sm-r-1 {
        padding-left: 1px;
    }

    .rtl .pd-sm-l-1 {
        padding-right: 1px;
    }

    .rtl .pd-sm-r-1-f {
        padding-left: 1px !important;
    }

    .rtl .pd-sm-l-1-f {
        padding-right: 1px !important;
    }

    .rtl .pd-sm-x-2 {
        padding-right: 2px;
        padding-left: 2px;
    }

    .rtl .pd-sm-x-2-f {
        padding-right: 2px !important;
        padding-left: 2px !important;
    }

    .rtl .pd-sm-r-2 {
        padding-left: 2px;
    }

    .rtl .pd-sm-l-2 {
        padding-right: 2px;
    }

    .rtl .pd-sm-r-2-f {
        padding-left: 2px !important;
    }

    .rtl .pd-sm-l-2-f {
        padding-right: 2px !important;
    }

    .rtl .pd-sm-x-3 {
        padding-right: 3px;
        padding-left: 3px;
    }

    .rtl .pd-sm-x-3-f {
        padding-right: 3px !important;
        padding-left: 3px !important;
    }

    .rtl .pd-sm-r-3 {
        padding-left: 3px;
    }

    .rtl .pd-sm-l-3 {
        padding-right: 3px;
    }

    .rtl .pd-sm-r-3-f {
        padding-left: 3px !important;
    }

    .rtl .pd-sm-l-3-f {
        padding-right: 3px !important;
    }

    .rtl .pd-sm-x-4 {
        padding-right: 4px;
        padding-left: 4px;
    }

    .rtl .pd-sm-x-4-f {
        padding-right: 4px !important;
        padding-left: 4px !important;
    }

    .rtl .pd-sm-r-4 {
        padding-left: 4px;
    }

    .rtl .pd-sm-l-4 {
        padding-right: 4px;
    }

    .rtl .pd-sm-r-4-f {
        padding-left: 4px !important;
    }

    .rtl .pd-sm-l-4-f {
        padding-right: 4px !important;
    }

    .rtl .pd-sm-x-5 {
        padding-right: 5px;
        padding-left: 5px;
    }

    .rtl .pd-sm-x-5-f {
        padding-right: 5px !important;
        padding-left: 5px !important;
    }

    .rtl .pd-sm-r-5 {
        padding-left: 5px;
    }

    .rtl .pd-sm-l-5 {
        padding-right: 5px;
    }

    .rtl .pd-sm-r-5-f {
        padding-left: 5px !important;
    }

    .rtl .pd-sm-l-5-f {
        padding-right: 5px !important;
    }

    .rtl .pd-sm-x-6 {
        padding-right: 6px;
        padding-left: 6px;
    }

    .rtl .pd-sm-x-6-f {
        padding-right: 6px !important;
        padding-left: 6px !important;
    }

    .rtl .pd-sm-r-6 {
        padding-left: 6px;
    }

    .rtl .pd-sm-l-6 {
        padding-right: 6px;
    }

    .rtl .pd-sm-r-6-f {
        padding-left: 6px !important;
    }

    .rtl .pd-sm-l-6-f {
        padding-right: 6px !important;
    }

    .rtl .pd-sm-x-7 {
        padding-right: 7px;
        padding-left: 7px;
    }

    .rtl .pd-sm-x-7-f {
        padding-right: 7px !important;
        padding-left: 7px !important;
    }

    .rtl .pd-sm-r-7 {
        padding-left: 7px;
    }

    .rtl .pd-sm-l-7 {
        padding-right: 7px;
    }

    .rtl .pd-sm-r-7-f {
        padding-left: 7px !important;
    }

    .rtl .pd-sm-l-7-f {
        padding-right: 7px !important;
    }

    .rtl .pd-sm-x-8 {
        padding-right: 8px;
        padding-left: 8px;
    }

    .rtl .pd-sm-x-8-f {
        padding-right: 8px !important;
        padding-left: 8px !important;
    }

    .rtl .pd-sm-r-8 {
        padding-left: 8px;
    }

    .rtl .pd-sm-l-8 {
        padding-right: 8px;
    }

    .rtl .pd-sm-r-8-f {
        padding-left: 8px !important;
    }

    .rtl .pd-sm-l-8-f {
        padding-right: 8px !important;
    }

    .rtl .pd-sm-x-9 {
        padding-right: 9px;
        padding-left: 9px;
    }

    .rtl .pd-sm-x-9-f {
        padding-right: 9px !important;
        padding-left: 9px !important;
    }

    .rtl .pd-sm-r-9 {
        padding-left: 9px;
    }

    .rtl .pd-sm-l-9 {
        padding-right: 9px;
    }

    .rtl .pd-sm-r-9-f {
        padding-left: 9px !important;
    }

    .rtl .pd-sm-l-9-f {
        padding-right: 9px !important;
    }

    .rtl .pd-sm-x-10 {
        padding-right: 10px;
        padding-left: 10px;
    }

    .rtl .pd-sm-x-10-f {
        padding-right: 10px !important;
        padding-left: 10px !important;
    }

    .rtl .pd-sm-r-10 {
        padding-left: 10px;
    }

    .rtl .pd-sm-l-10 {
        padding-right: 10px;
    }

    .rtl .pd-sm-r-10-f {
        padding-left: 10px !important;
    }

    .rtl .pd-sm-l-10-f {
        padding-right: 10px !important;
    }

    .rtl .pd-sm-x-15 {
        padding-right: 15px;
        padding-left: 15px;
    }

    .rtl .pd-sm-x-15-f {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }

    .rtl .pd-sm-r-15 {
        padding-left: 15px;
    }

    .rtl .pd-sm-l-15 {
        padding-right: 15px;
    }

    .rtl .pd-sm-r-15-f {
        padding-left: 15px !important;
    }

    .rtl .pd-sm-l-15-f {
        padding-right: 15px !important;
    }

    .rtl .pd-sm-x-20 {
        padding-right: 20px;
        padding-left: 20px;
    }

    .rtl .pd-sm-x-20-f {
        padding-right: 20px !important;
        padding-left: 20px !important;
    }

    .rtl .pd-sm-r-20 {
        padding-left: 20px;
    }

    .rtl .pd-sm-l-20 {
        padding-right: 20px;
    }

    .rtl .pd-sm-r-20-f {
        padding-left: 20px !important;
    }

    .rtl .pd-sm-l-20-f {
        padding-right: 20px !important;
    }

    .rtl .pd-sm-x-25 {
        padding-right: 25px;
        padding-left: 25px;
    }

    .rtl .pd-sm-x-25-f {
        padding-right: 25px !important;
        padding-left: 25px !important;
    }

    .rtl .pd-sm-r-25 {
        padding-left: 25px;
    }

    .rtl .pd-sm-l-25 {
        padding-right: 25px;
    }

    .rtl .pd-sm-r-25-f {
        padding-left: 25px !important;
    }

    .rtl .pd-sm-l-25-f {
        padding-right: 25px !important;
    }

    .rtl .pd-sm-x-30 {
        padding-right: 30px;
        padding-left: 30px;
    }

    .rtl .pd-sm-x-30-f {
        padding-right: 30px !important;
        padding-left: 30px !important;
    }

    .rtl .pd-sm-r-30 {
        padding-left: 30px;
    }

    .rtl .pd-sm-l-30 {
        padding-right: 30px;
    }

    .rtl .pd-sm-r-30-f {
        padding-left: 30px !important;
    }

    .rtl .pd-sm-l-30-f {
        padding-right: 30px !important;
    }

    .rtl .pd-sm-x-35 {
        padding-right: 35px;
        padding-left: 35px;
    }

    .rtl .pd-sm-x-35-f {
        padding-right: 35px !important;
        padding-left: 35px !important;
    }

    .rtl .pd-sm-r-35 {
        padding-left: 35px;
    }

    .rtl .pd-sm-l-35 {
        padding-right: 35px;
    }

    .rtl .pd-sm-r-35-f {
        padding-left: 35px !important;
    }

    .rtl .pd-sm-l-35-f {
        padding-right: 35px !important;
    }

    .rtl .pd-sm-x-40 {
        padding-right: 40px;
        padding-left: 40px;
    }

    .rtl .pd-sm-x-40-f {
        padding-right: 40px !important;
        padding-left: 40px !important;
    }

    .rtl .pd-sm-r-40 {
        padding-left: 40px;
    }

    .rtl .pd-sm-l-40 {
        padding-right: 40px;
    }

    .rtl .pd-sm-r-40-f {
        padding-left: 40px !important;
    }

    .rtl .pd-sm-l-40-f {
        padding-right: 40px !important;
    }

    .rtl .pd-sm-x-45 {
        padding-right: 45px;
        padding-left: 45px;
    }

    .rtl .pd-sm-x-45-f {
        padding-right: 45px !important;
        padding-left: 45px !important;
    }

    .rtl .pd-sm-r-45 {
        padding-left: 45px;
    }

    .rtl .pd-sm-l-45 {
        padding-right: 45px;
    }

    .rtl .pd-sm-r-45-f {
        padding-left: 45px !important;
    }

    .rtl .pd-sm-l-45-f {
        padding-right: 45px !important;
    }

    .rtl .pd-sm-x-50 {
        padding-right: 50px;
        padding-left: 50px;
    }

    .rtl .pd-sm-x-50-f {
        padding-right: 50px !important;
        padding-left: 50px !important;
    }

    .rtl .pd-sm-r-50 {
        padding-left: 50px;
    }

    .rtl .pd-sm-l-50 {
        padding-right: 50px;
    }

    .rtl .pd-sm-r-50-f {
        padding-left: 50px !important;
    }

    .rtl .pd-sm-l-50-f {
        padding-right: 50px !important;
    }

    .rtl .pd-sm-x-55 {
        padding-right: 55px;
        padding-left: 55px;
    }

    .rtl .pd-sm-x-55-f {
        padding-right: 55px !important;
        padding-left: 55px !important;
    }

    .rtl .pd-sm-r-55 {
        padding-left: 55px;
    }

    .rtl .pd-sm-l-55 {
        padding-right: 55px;
    }

    .rtl .pd-sm-r-55-f {
        padding-left: 55px !important;
    }

    .rtl .pd-sm-l-55-f {
        padding-right: 55px !important;
    }

    .rtl .pd-sm-x-60 {
        padding-right: 60px;
        padding-left: 60px;
    }

    .rtl .pd-sm-x-60-f {
        padding-right: 60px !important;
        padding-left: 60px !important;
    }

    .rtl .pd-sm-r-60 {
        padding-left: 60px;
    }

    .rtl .pd-sm-l-60 {
        padding-right: 60px;
    }

    .rtl .pd-sm-r-60-f {
        padding-left: 60px !important;
    }

    .rtl .pd-sm-l-60-f {
        padding-right: 60px !important;
    }

    .rtl .pd-sm-x-65 {
        padding-right: 65px;
        padding-left: 65px;
    }

    .rtl .pd-sm-x-65-f {
        padding-right: 65px !important;
        padding-left: 65px !important;
    }

    .rtl .pd-sm-r-65 {
        padding-left: 65px;
    }

    .rtl .pd-sm-l-65 {
        padding-right: 65px;
    }

    .rtl .pd-sm-r-65-f {
        padding-left: 65px !important;
    }

    .rtl .pd-sm-l-65-f {
        padding-right: 65px !important;
    }

    .rtl .pd-sm-x-70 {
        padding-right: 70px;
        padding-left: 70px;
    }

    .rtl .pd-sm-x-70-f {
        padding-right: 70px !important;
        padding-left: 70px !important;
    }

    .rtl .pd-sm-r-70 {
        padding-left: 70px;
    }

    .rtl .pd-sm-l-70 {
        padding-right: 70px;
    }

    .rtl .pd-sm-r-70-f {
        padding-left: 70px !important;
    }

    .rtl .pd-sm-l-70-f {
        padding-right: 70px !important;
    }

    .rtl .pd-sm-x-75 {
        padding-right: 75px;
        padding-left: 75px;
    }

    .rtl .pd-sm-x-75-f {
        padding-right: 75px !important;
        padding-left: 75px !important;
    }

    .rtl .pd-sm-r-75 {
        padding-left: 75px;
    }

    .rtl .pd-sm-l-75 {
        padding-right: 75px;
    }

    .rtl .pd-sm-r-75-f {
        padding-left: 75px !important;
    }

    .rtl .pd-sm-l-75-f {
        padding-right: 75px !important;
    }

    .rtl .pd-sm-x-80 {
        padding-right: 80px;
        padding-left: 80px;
    }

    .rtl .pd-sm-x-80-f {
        padding-right: 80px !important;
        padding-left: 80px !important;
    }

    .rtl .pd-sm-r-80 {
        padding-left: 80px;
    }

    .rtl .pd-sm-l-80 {
        padding-right: 80px;
    }

    .rtl .pd-sm-r-80-f {
        padding-left: 80px !important;
    }

    .rtl .pd-sm-l-80-f {
        padding-right: 80px !important;
    }

    .rtl .pd-sm-x-85 {
        padding-right: 85px;
        padding-left: 85px;
    }

    .rtl .pd-sm-x-85-f {
        padding-right: 85px !important;
        padding-left: 85px !important;
    }

    .rtl .pd-sm-r-85 {
        padding-left: 85px;
    }

    .rtl .pd-sm-l-85 {
        padding-right: 85px;
    }

    .rtl .pd-sm-r-85-f {
        padding-left: 85px !important;
    }

    .rtl .pd-sm-l-85-f {
        padding-right: 85px !important;
    }

    .rtl .pd-sm-x-90 {
        padding-right: 90px;
        padding-left: 90px;
    }

    .rtl .pd-sm-x-90-f {
        padding-right: 90px !important;
        padding-left: 90px !important;
    }

    .rtl .pd-sm-r-90 {
        padding-left: 90px;
    }

    .rtl .pd-sm-l-90 {
        padding-right: 90px;
    }

    .rtl .pd-sm-r-90-f {
        padding-left: 90px !important;
    }

    .rtl .pd-sm-l-90-f {
        padding-right: 90px !important;
    }

    .rtl .pd-sm-x-95 {
        padding-right: 95px;
        padding-left: 95px;
    }

    .rtl .pd-sm-x-95-f {
        padding-right: 95px !important;
        padding-left: 95px !important;
    }

    .rtl .pd-sm-r-95 {
        padding-left: 95px;
    }

    .rtl .pd-sm-l-95 {
        padding-right: 95px;
    }

    .rtl .pd-sm-r-95-f {
        padding-left: 95px !important;
    }

    .rtl .pd-sm-l-95-f {
        padding-right: 95px !important;
    }

    .rtl .pd-sm-x-100 {
        padding-right: 100px;
        padding-left: 100px;
    }

    .rtl .pd-sm-x-100-f {
        padding-right: 100px !important;
        padding-left: 100px !important;
    }

    .rtl .pd-sm-r-100 {
        padding-left: 100px;
    }

    .rtl .pd-sm-l-100 {
        padding-right: 100px;
    }

    .rtl .pd-sm-r-100-f {
        padding-left: 100px !important;
    }

    .rtl .pd-sm-l-100-f {
        padding-right: 100px !important;
    }

    .rtl .pd-sm-x-110 {
        padding-right: 110px;
        padding-left: 110px;
    }

    .rtl .pd-sm-x-110-f {
        padding-right: 110px !important;
        padding-left: 110px !important;
    }

    .rtl .pd-sm-r-110 {
        padding-left: 110px;
    }

    .rtl .pd-sm-l-110 {
        padding-right: 110px;
    }

    .rtl .pd-sm-r-110-f {
        padding-left: 110px !important;
    }

    .rtl .pd-sm-l-110-f {
        padding-right: 110px !important;
    }

    .rtl .pd-sm-x-120 {
        padding-right: 120px;
        padding-left: 120px;
    }

    .rtl .pd-sm-x-120-f {
        padding-right: 120px !important;
        padding-left: 120px !important;
    }

    .rtl .pd-sm-r-120 {
        padding-left: 120px;
    }

    .rtl .pd-sm-l-120 {
        padding-right: 120px;
    }

    .rtl .pd-sm-r-120-f {
        padding-left: 120px !important;
    }

    .rtl .pd-sm-l-120-f {
        padding-right: 120px !important;
    }

    .rtl .pd-sm-x-130 {
        padding-right: 130px;
        padding-left: 130px;
    }

    .rtl .pd-sm-x-130-f {
        padding-right: 130px !important;
        padding-left: 130px !important;
    }

    .rtl .pd-sm-r-130 {
        padding-left: 130px;
    }

    .rtl .pd-sm-l-130 {
        padding-right: 130px;
    }

    .rtl .pd-sm-r-130-f {
        padding-left: 130px !important;
    }

    .rtl .pd-sm-l-130-f {
        padding-right: 130px !important;
    }

    .rtl .pd-sm-x-140 {
        padding-right: 140px;
        padding-left: 140px;
    }

    .rtl .pd-sm-x-140-f {
        padding-right: 140px !important;
        padding-left: 140px !important;
    }

    .rtl .pd-sm-r-140 {
        padding-left: 140px;
    }

    .rtl .pd-sm-l-140 {
        padding-right: 140px;
    }

    .rtl .pd-sm-r-140-f {
        padding-left: 140px !important;
    }

    .rtl .pd-sm-l-140-f {
        padding-right: 140px !important;
    }

    .rtl .pd-sm-x-150 {
        padding-right: 150px;
        padding-left: 150px;
    }

    .rtl .pd-sm-x-150-f {
        padding-right: 150px !important;
        padding-left: 150px !important;
    }

    .rtl .pd-sm-r-150 {
        padding-left: 150px;
    }

    .rtl .pd-sm-l-150 {
        padding-right: 150px;
    }

    .rtl .pd-sm-r-150-f {
        padding-left: 150px !important;
    }

    .rtl .pd-sm-l-150-f {
        padding-right: 150px !important;
    }

    .rtl .pd-sm-x-160 {
        padding-right: 160px;
        padding-left: 160px;
    }

    .rtl .pd-sm-x-160-f {
        padding-right: 160px !important;
        padding-left: 160px !important;
    }

    .rtl .pd-sm-r-160 {
        padding-left: 160px;
    }

    .rtl .pd-sm-l-160 {
        padding-right: 160px;
    }

    .rtl .pd-sm-r-160-f {
        padding-left: 160px !important;
    }

    .rtl .pd-sm-l-160-f {
        padding-right: 160px !important;
    }

    .rtl .pd-sm-x-170 {
        padding-right: 170px;
        padding-left: 170px;
    }

    .rtl .pd-sm-x-170-f {
        padding-right: 170px !important;
        padding-left: 170px !important;
    }

    .rtl .pd-sm-r-170 {
        padding-left: 170px;
    }

    .rtl .pd-sm-l-170 {
        padding-right: 170px;
    }

    .rtl .pd-sm-r-170-f {
        padding-left: 170px !important;
    }

    .rtl .pd-sm-l-170-f {
        padding-right: 170px !important;
    }

    .rtl .pd-sm-x-180 {
        padding-right: 180px;
        padding-left: 180px;
    }

    .rtl .pd-sm-x-180-f {
        padding-right: 180px !important;
        padding-left: 180px !important;
    }

    .rtl .pd-sm-r-180 {
        padding-left: 180px;
    }

    .rtl .pd-sm-l-180 {
        padding-right: 180px;
    }

    .rtl .pd-sm-r-180-f {
        padding-left: 180px !important;
    }

    .rtl .pd-sm-l-180-f {
        padding-right: 180px !important;
    }

    .rtl .pd-sm-x-190 {
        padding-right: 190px;
        padding-left: 190px;
    }

    .rtl .pd-sm-x-190-f {
        padding-right: 190px !important;
        padding-left: 190px !important;
    }

    .rtl .pd-sm-r-190 {
        padding-left: 190px;
    }

    .rtl .pd-sm-l-190 {
        padding-right: 190px;
    }

    .rtl .pd-sm-r-190-f {
        padding-left: 190px !important;
    }

    .rtl .pd-sm-l-190-f {
        padding-right: 190px !important;
    }

    .rtl .pd-sm-x-200 {
        padding-right: 200px;
        padding-left: 200px;
    }

    .rtl .pd-sm-x-200-f {
        padding-right: 200px !important;
        padding-left: 200px !important;
    }

    .rtl .pd-sm-r-200 {
        padding-left: 200px;
    }

    .rtl .pd-sm-l-200 {
        padding-right: 200px;
    }

    .rtl .pd-sm-r-200-f {
        padding-left: 200px !important;
    }

    .rtl .pd-sm-l-200-f {
        padding-right: 200px !important;
    }
}

@media (min-width: 768px) {
    .rtl .pd-md-x-0 {
        padding-right: 0px;
        padding-left: 0px;
    }

    .rtl .pd-md-x-0-f {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .rtl .pd-md-r-0 {
        padding-left: 0px;
    }

    .rtl .pd-md-l-0 {
        padding-right: 0px;
    }

    .rtl .pd-md-r-0-f {
        padding-left: 0px !important;
    }

    .rtl .pd-md-l-0-f {
        padding-right: 0px !important;
    }

    .rtl .pd-md-x-1 {
        padding-right: 1px;
        padding-left: 1px;
    }

    .rtl .pd-md-x-1-f {
        padding-right: 1px !important;
        padding-left: 1px !important;
    }

    .rtl .pd-md-r-1 {
        padding-left: 1px;
    }

    .rtl .pd-md-l-1 {
        padding-right: 1px;
    }

    .rtl .pd-md-r-1-f {
        padding-left: 1px !important;
    }

    .rtl .pd-md-l-1-f {
        padding-right: 1px !important;
    }

    .rtl .pd-md-x-2 {
        padding-right: 2px;
        padding-left: 2px;
    }

    .rtl .pd-md-x-2-f {
        padding-right: 2px !important;
        padding-left: 2px !important;
    }

    .rtl .pd-md-r-2 {
        padding-left: 2px;
    }

    .rtl .pd-md-l-2 {
        padding-right: 2px;
    }

    .rtl .pd-md-r-2-f {
        padding-left: 2px !important;
    }

    .rtl .pd-md-l-2-f {
        padding-right: 2px !important;
    }

    .rtl .pd-md-x-3 {
        padding-right: 3px;
        padding-left: 3px;
    }

    .rtl .pd-md-x-3-f {
        padding-right: 3px !important;
        padding-left: 3px !important;
    }

    .rtl .pd-md-r-3 {
        padding-left: 3px;
    }

    .rtl .pd-md-l-3 {
        padding-right: 3px;
    }

    .rtl .pd-md-r-3-f {
        padding-left: 3px !important;
    }

    .rtl .pd-md-l-3-f {
        padding-right: 3px !important;
    }

    .rtl .pd-md-x-4 {
        padding-right: 4px;
        padding-left: 4px;
    }

    .rtl .pd-md-x-4-f {
        padding-right: 4px !important;
        padding-left: 4px !important;
    }

    .rtl .pd-md-r-4 {
        padding-left: 4px;
    }

    .rtl .pd-md-l-4 {
        padding-right: 4px;
    }

    .rtl .pd-md-r-4-f {
        padding-left: 4px !important;
    }

    .rtl .pd-md-l-4-f {
        padding-right: 4px !important;
    }

    .rtl .pd-md-x-5 {
        padding-right: 5px;
        padding-left: 5px;
    }

    .rtl .pd-md-x-5-f {
        padding-right: 5px !important;
        padding-left: 5px !important;
    }

    .rtl .pd-md-r-5 {
        padding-left: 5px;
    }

    .rtl .pd-md-l-5 {
        padding-right: 5px;
    }

    .rtl .pd-md-r-5-f {
        padding-left: 5px !important;
    }

    .rtl .pd-md-l-5-f {
        padding-right: 5px !important;
    }

    .rtl .pd-md-x-6 {
        padding-right: 6px;
        padding-left: 6px;
    }

    .rtl .pd-md-x-6-f {
        padding-right: 6px !important;
        padding-left: 6px !important;
    }

    .rtl .pd-md-r-6 {
        padding-left: 6px;
    }

    .rtl .pd-md-l-6 {
        padding-right: 6px;
    }

    .rtl .pd-md-r-6-f {
        padding-left: 6px !important;
    }

    .rtl .pd-md-l-6-f {
        padding-right: 6px !important;
    }

    .rtl .pd-md-x-7 {
        padding-right: 7px;
        padding-left: 7px;
    }

    .rtl .pd-md-x-7-f {
        padding-right: 7px !important;
        padding-left: 7px !important;
    }

    .rtl .pd-md-r-7 {
        padding-left: 7px;
    }

    .rtl .pd-md-l-7 {
        padding-right: 7px;
    }

    .rtl .pd-md-r-7-f {
        padding-left: 7px !important;
    }

    .rtl .pd-md-l-7-f {
        padding-right: 7px !important;
    }

    .rtl .pd-md-x-8 {
        padding-right: 8px;
        padding-left: 8px;
    }

    .rtl .pd-md-x-8-f {
        padding-right: 8px !important;
        padding-left: 8px !important;
    }

    .rtl .pd-md-r-8 {
        padding-left: 8px;
    }

    .rtl .pd-md-l-8 {
        padding-right: 8px;
    }

    .rtl .pd-md-r-8-f {
        padding-left: 8px !important;
    }

    .rtl .pd-md-l-8-f {
        padding-right: 8px !important;
    }

    .rtl .pd-md-x-9 {
        padding-right: 9px;
        padding-left: 9px;
    }

    .rtl .pd-md-x-9-f {
        padding-right: 9px !important;
        padding-left: 9px !important;
    }

    .rtl .pd-md-r-9 {
        padding-left: 9px;
    }

    .rtl .pd-md-l-9 {
        padding-right: 9px;
    }

    .rtl .pd-md-r-9-f {
        padding-left: 9px !important;
    }

    .rtl .pd-md-l-9-f {
        padding-right: 9px !important;
    }

    .rtl .pd-md-x-10 {
        padding-right: 10px;
        padding-left: 10px;
    }

    .rtl .pd-md-x-10-f {
        padding-right: 10px !important;
        padding-left: 10px !important;
    }

    .rtl .pd-md-r-10 {
        padding-left: 10px;
    }

    .rtl .pd-md-l-10 {
        padding-right: 10px;
    }

    .rtl .pd-md-r-10-f {
        padding-left: 10px !important;
    }

    .rtl .pd-md-l-10-f {
        padding-right: 10px !important;
    }

    .rtl .pd-md-x-15 {
        padding-right: 15px;
        padding-left: 15px;
    }

    .rtl .pd-md-x-15-f {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }

    .rtl .pd-md-r-15 {
        padding-left: 15px;
    }

    .rtl .pd-md-l-15 {
        padding-right: 15px;
    }

    .rtl .pd-md-r-15-f {
        padding-left: 15px !important;
    }

    .rtl .pd-md-l-15-f {
        padding-right: 15px !important;
    }

    .rtl .pd-md-x-20 {
        padding-right: 20px;
        padding-left: 20px;
    }

    .rtl .pd-md-x-20-f {
        padding-right: 20px !important;
        padding-left: 20px !important;
    }

    .rtl .pd-md-r-20 {
        padding-left: 20px;
    }

    .rtl .pd-md-l-20 {
        padding-right: 20px;
    }

    .rtl .pd-md-r-20-f {
        padding-left: 20px !important;
    }

    .rtl .pd-md-l-20-f {
        padding-right: 20px !important;
    }

    .rtl .pd-md-x-25 {
        padding-right: 25px;
        padding-left: 25px;
    }

    .rtl .pd-md-x-25-f {
        padding-right: 25px !important;
        padding-left: 25px !important;
    }

    .rtl .pd-md-r-25 {
        padding-left: 25px;
    }

    .rtl .pd-md-l-25 {
        padding-right: 25px;
    }

    .rtl .pd-md-r-25-f {
        padding-left: 25px !important;
    }

    .rtl .pd-md-l-25-f {
        padding-right: 25px !important;
    }

    .rtl .pd-md-x-30 {
        padding-right: 30px;
        padding-left: 30px;
    }

    .rtl .pd-md-x-30-f {
        padding-right: 30px !important;
        padding-left: 30px !important;
    }

    .rtl .pd-md-r-30 {
        padding-left: 30px;
    }

    .rtl .pd-md-l-30 {
        padding-right: 30px;
    }

    .rtl .pd-md-r-30-f {
        padding-left: 30px !important;
    }

    .rtl .pd-md-l-30-f {
        padding-right: 30px !important;
    }

    .rtl .pd-md-x-35 {
        padding-right: 35px;
        padding-left: 35px;
    }

    .rtl .pd-md-x-35-f {
        padding-right: 35px !important;
        padding-left: 35px !important;
    }

    .rtl .pd-md-r-35 {
        padding-left: 35px;
    }

    .rtl .pd-md-l-35 {
        padding-right: 35px;
    }

    .rtl .pd-md-r-35-f {
        padding-left: 35px !important;
    }

    .rtl .pd-md-l-35-f {
        padding-right: 35px !important;
    }

    .rtl .pd-md-x-40 {
        padding-right: 40px;
        padding-left: 40px;
    }

    .rtl .pd-md-x-40-f {
        padding-right: 40px !important;
        padding-left: 40px !important;
    }

    .rtl .pd-md-r-40 {
        padding-left: 40px;
    }

    .rtl .pd-md-l-40 {
        padding-right: 40px;
    }

    .rtl .pd-md-r-40-f {
        padding-left: 40px !important;
    }

    .rtl .pd-md-l-40-f {
        padding-right: 40px !important;
    }

    .rtl .pd-md-x-45 {
        padding-right: 45px;
        padding-left: 45px;
    }

    .rtl .pd-md-x-45-f {
        padding-right: 45px !important;
        padding-left: 45px !important;
    }

    .rtl .pd-md-r-45 {
        padding-left: 45px;
    }

    .rtl .pd-md-l-45 {
        padding-right: 45px;
    }

    .rtl .pd-md-r-45-f {
        padding-left: 45px !important;
    }

    .rtl .pd-md-l-45-f {
        padding-right: 45px !important;
    }

    .rtl .pd-md-x-50 {
        padding-right: 50px;
        padding-left: 50px;
    }

    .rtl .pd-md-x-50-f {
        padding-right: 50px !important;
        padding-left: 50px !important;
    }

    .rtl .pd-md-r-50 {
        padding-left: 50px;
    }

    .rtl .pd-md-l-50 {
        padding-right: 50px;
    }

    .rtl .pd-md-r-50-f {
        padding-left: 50px !important;
    }

    .rtl .pd-md-l-50-f {
        padding-right: 50px !important;
    }

    .rtl .pd-md-x-55 {
        padding-right: 55px;
        padding-left: 55px;
    }

    .rtl .pd-md-x-55-f {
        padding-right: 55px !important;
        padding-left: 55px !important;
    }

    .rtl .pd-md-r-55 {
        padding-left: 55px;
    }

    .rtl .pd-md-l-55 {
        padding-right: 55px;
    }

    .rtl .pd-md-r-55-f {
        padding-left: 55px !important;
    }

    .rtl .pd-md-l-55-f {
        padding-right: 55px !important;
    }

    .rtl .pd-md-x-60 {
        padding-right: 60px;
        padding-left: 60px;
    }

    .rtl .pd-md-x-60-f {
        padding-right: 60px !important;
        padding-left: 60px !important;
    }

    .rtl .pd-md-r-60 {
        padding-left: 60px;
    }

    .rtl .pd-md-l-60 {
        padding-right: 60px;
    }

    .rtl .pd-md-r-60-f {
        padding-left: 60px !important;
    }

    .rtl .pd-md-l-60-f {
        padding-right: 60px !important;
    }

    .rtl .pd-md-x-65 {
        padding-right: 65px;
        padding-left: 65px;
    }

    .rtl .pd-md-x-65-f {
        padding-right: 65px !important;
        padding-left: 65px !important;
    }

    .rtl .pd-md-r-65 {
        padding-left: 65px;
    }

    .rtl .pd-md-l-65 {
        padding-right: 65px;
    }

    .rtl .pd-md-r-65-f {
        padding-left: 65px !important;
    }

    .rtl .pd-md-l-65-f {
        padding-right: 65px !important;
    }

    .rtl .pd-md-x-70 {
        padding-right: 70px;
        padding-left: 70px;
    }

    .rtl .pd-md-x-70-f {
        padding-right: 70px !important;
        padding-left: 70px !important;
    }

    .rtl .pd-md-r-70 {
        padding-left: 70px;
    }

    .rtl .pd-md-l-70 {
        padding-right: 70px;
    }

    .rtl .pd-md-r-70-f {
        padding-left: 70px !important;
    }

    .rtl .pd-md-l-70-f {
        padding-right: 70px !important;
    }

    .rtl .pd-md-x-75 {
        padding-right: 75px;
        padding-left: 75px;
    }

    .rtl .pd-md-x-75-f {
        padding-right: 75px !important;
        padding-left: 75px !important;
    }

    .rtl .pd-md-r-75 {
        padding-left: 75px;
    }

    .rtl .pd-md-l-75 {
        padding-right: 75px;
    }

    .rtl .pd-md-r-75-f {
        padding-left: 75px !important;
    }

    .rtl .pd-md-l-75-f {
        padding-right: 75px !important;
    }

    .rtl .pd-md-x-80 {
        padding-right: 80px;
        padding-left: 80px;
    }

    .rtl .pd-md-x-80-f {
        padding-right: 80px !important;
        padding-left: 80px !important;
    }

    .rtl .pd-md-r-80 {
        padding-left: 80px;
    }

    .rtl .pd-md-l-80 {
        padding-right: 80px;
    }

    .rtl .pd-md-r-80-f {
        padding-left: 80px !important;
    }

    .rtl .pd-md-l-80-f {
        padding-right: 80px !important;
    }

    .rtl .pd-md-x-85 {
        padding-right: 85px;
        padding-left: 85px;
    }

    .rtl .pd-md-x-85-f {
        padding-right: 85px !important;
        padding-left: 85px !important;
    }

    .rtl .pd-md-r-85 {
        padding-left: 85px;
    }

    .rtl .pd-md-l-85 {
        padding-right: 85px;
    }

    .rtl .pd-md-r-85-f {
        padding-left: 85px !important;
    }

    .rtl .pd-md-l-85-f {
        padding-right: 85px !important;
    }

    .rtl .pd-md-x-90 {
        padding-right: 90px;
        padding-left: 90px;
    }

    .rtl .pd-md-x-90-f {
        padding-right: 90px !important;
        padding-left: 90px !important;
    }

    .rtl .pd-md-r-90 {
        padding-left: 90px;
    }

    .rtl .pd-md-l-90 {
        padding-right: 90px;
    }

    .rtl .pd-md-r-90-f {
        padding-left: 90px !important;
    }

    .rtl .pd-md-l-90-f {
        padding-right: 90px !important;
    }

    .rtl .pd-md-x-95 {
        padding-right: 95px;
        padding-left: 95px;
    }

    .rtl .pd-md-x-95-f {
        padding-right: 95px !important;
        padding-left: 95px !important;
    }

    .rtl .pd-md-r-95 {
        padding-left: 95px;
    }

    .rtl .pd-md-l-95 {
        padding-right: 95px;
    }

    .rtl .pd-md-r-95-f {
        padding-left: 95px !important;
    }

    .rtl .pd-md-l-95-f {
        padding-right: 95px !important;
    }

    .rtl .pd-md-x-100 {
        padding-right: 100px;
        padding-left: 100px;
    }

    .rtl .pd-md-x-100-f {
        padding-right: 100px !important;
        padding-left: 100px !important;
    }

    .rtl .pd-md-r-100 {
        padding-left: 100px;
    }

    .rtl .pd-md-l-100 {
        padding-right: 100px;
    }

    .rtl .pd-md-r-100-f {
        padding-left: 100px !important;
    }

    .rtl .pd-md-l-100-f {
        padding-right: 100px !important;
    }

    .rtl .pd-md-x-110 {
        padding-right: 110px;
        padding-left: 110px;
    }

    .rtl .pd-md-x-110-f {
        padding-right: 110px !important;
        padding-left: 110px !important;
    }

    .rtl .pd-md-r-110 {
        padding-left: 110px;
    }

    .rtl .pd-md-l-110 {
        padding-right: 110px;
    }

    .rtl .pd-md-r-110-f {
        padding-left: 110px !important;
    }

    .rtl .pd-md-l-110-f {
        padding-right: 110px !important;
    }

    .rtl .pd-md-x-120 {
        padding-right: 120px;
        padding-left: 120px;
    }

    .rtl .pd-md-x-120-f {
        padding-right: 120px !important;
        padding-left: 120px !important;
    }

    .rtl .pd-md-r-120 {
        padding-left: 120px;
    }

    .rtl .pd-md-l-120 {
        padding-right: 120px;
    }

    .rtl .pd-md-r-120-f {
        padding-left: 120px !important;
    }

    .rtl .pd-md-l-120-f {
        padding-right: 120px !important;
    }

    .rtl .pd-md-x-130 {
        padding-right: 130px;
        padding-left: 130px;
    }

    .rtl .pd-md-x-130-f {
        padding-right: 130px !important;
        padding-left: 130px !important;
    }

    .rtl .pd-md-r-130 {
        padding-left: 130px;
    }

    .rtl .pd-md-l-130 {
        padding-right: 130px;
    }

    .rtl .pd-md-r-130-f {
        padding-left: 130px !important;
    }

    .rtl .pd-md-l-130-f {
        padding-right: 130px !important;
    }

    .rtl .pd-md-x-140 {
        padding-right: 140px;
        padding-left: 140px;
    }

    .rtl .pd-md-x-140-f {
        padding-right: 140px !important;
        padding-left: 140px !important;
    }

    .rtl .pd-md-r-140 {
        padding-left: 140px;
    }

    .rtl .pd-md-l-140 {
        padding-right: 140px;
    }

    .rtl .pd-md-r-140-f {
        padding-left: 140px !important;
    }

    .rtl .pd-md-l-140-f {
        padding-right: 140px !important;
    }

    .rtl .pd-md-x-150 {
        padding-right: 150px;
        padding-left: 150px;
    }

    .rtl .pd-md-x-150-f {
        padding-right: 150px !important;
        padding-left: 150px !important;
    }

    .rtl .pd-md-r-150 {
        padding-left: 150px;
    }

    .rtl .pd-md-l-150 {
        padding-right: 150px;
    }

    .rtl .pd-md-r-150-f {
        padding-left: 150px !important;
    }

    .rtl .pd-md-l-150-f {
        padding-right: 150px !important;
    }

    .rtl .pd-md-x-160 {
        padding-right: 160px;
        padding-left: 160px;
    }

    .rtl .pd-md-x-160-f {
        padding-right: 160px !important;
        padding-left: 160px !important;
    }

    .rtl .pd-md-r-160 {
        padding-left: 160px;
    }

    .rtl .pd-md-l-160 {
        padding-right: 160px;
    }

    .rtl .pd-md-r-160-f {
        padding-left: 160px !important;
    }

    .rtl .pd-md-l-160-f {
        padding-right: 160px !important;
    }

    .rtl .pd-md-x-170 {
        padding-right: 170px;
        padding-left: 170px;
    }

    .rtl .pd-md-x-170-f {
        padding-right: 170px !important;
        padding-left: 170px !important;
    }

    .rtl .pd-md-r-170 {
        padding-left: 170px;
    }

    .rtl .pd-md-l-170 {
        padding-right: 170px;
    }

    .rtl .pd-md-r-170-f {
        padding-left: 170px !important;
    }

    .rtl .pd-md-l-170-f {
        padding-right: 170px !important;
    }

    .rtl .pd-md-x-180 {
        padding-right: 180px;
        padding-left: 180px;
    }

    .rtl .pd-md-x-180-f {
        padding-right: 180px !important;
        padding-left: 180px !important;
    }

    .rtl .pd-md-r-180 {
        padding-left: 180px;
    }

    .rtl .pd-md-l-180 {
        padding-right: 180px;
    }

    .rtl .pd-md-r-180-f {
        padding-left: 180px !important;
    }

    .rtl .pd-md-l-180-f {
        padding-right: 180px !important;
    }

    .rtl .pd-md-x-190 {
        padding-right: 190px;
        padding-left: 190px;
    }

    .rtl .pd-md-x-190-f {
        padding-right: 190px !important;
        padding-left: 190px !important;
    }

    .rtl .pd-md-r-190 {
        padding-left: 190px;
    }

    .rtl .pd-md-l-190 {
        padding-right: 190px;
    }

    .rtl .pd-md-r-190-f {
        padding-left: 190px !important;
    }

    .rtl .pd-md-l-190-f {
        padding-right: 190px !important;
    }

    .rtl .pd-md-x-200 {
        padding-right: 200px;
        padding-left: 200px;
    }

    .rtl .pd-md-x-200-f {
        padding-right: 200px !important;
        padding-left: 200px !important;
    }

    .rtl .pd-md-r-200 {
        padding-left: 200px;
    }

    .rtl .pd-md-l-200 {
        padding-right: 200px;
    }

    .rtl .pd-md-r-200-f {
        padding-left: 200px !important;
    }

    .rtl .pd-md-l-200-f {
        padding-right: 200px !important;
    }
}

@media (min-width: 992px) {
    .rtl .pd-lg-x-0 {
        padding-right: 0px;
        padding-left: 0px;
    }

    .rtl .pd-lg-x-0-f {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .rtl .pd-lg-r-0 {
        padding-left: 0px;
    }

    .rtl .pd-lg-l-0 {
        padding-right: 0px;
    }

    .rtl .pd-lg-r-0-f {
        padding-left: 0px !important;
    }

    .rtl .pd-lg-l-0-f {
        padding-right: 0px !important;
    }

    .rtl .pd-lg-x-1 {
        padding-right: 1px;
        padding-left: 1px;
    }

    .rtl .pd-lg-x-1-f {
        padding-right: 1px !important;
        padding-left: 1px !important;
    }

    .rtl .pd-lg-r-1 {
        padding-left: 1px;
    }

    .rtl .pd-lg-l-1 {
        padding-right: 1px;
    }

    .rtl .pd-lg-r-1-f {
        padding-left: 1px !important;
    }

    .rtl .pd-lg-l-1-f {
        padding-right: 1px !important;
    }

    .rtl .pd-lg-x-2 {
        padding-right: 2px;
        padding-left: 2px;
    }

    .rtl .pd-lg-x-2-f {
        padding-right: 2px !important;
        padding-left: 2px !important;
    }

    .rtl .pd-lg-r-2 {
        padding-left: 2px;
    }

    .rtl .pd-lg-l-2 {
        padding-right: 2px;
    }

    .rtl .pd-lg-r-2-f {
        padding-left: 2px !important;
    }

    .rtl .pd-lg-l-2-f {
        padding-right: 2px !important;
    }

    .rtl .pd-lg-x-3 {
        padding-right: 3px;
        padding-left: 3px;
    }

    .rtl .pd-lg-x-3-f {
        padding-right: 3px !important;
        padding-left: 3px !important;
    }

    .rtl .pd-lg-r-3 {
        padding-left: 3px;
    }

    .rtl .pd-lg-l-3 {
        padding-right: 3px;
    }

    .rtl .pd-lg-r-3-f {
        padding-left: 3px !important;
    }

    .rtl .pd-lg-l-3-f {
        padding-right: 3px !important;
    }

    .rtl .pd-lg-x-4 {
        padding-right: 4px;
        padding-left: 4px;
    }

    .rtl .pd-lg-x-4-f {
        padding-right: 4px !important;
        padding-left: 4px !important;
    }

    .rtl .pd-lg-r-4 {
        padding-left: 4px;
    }

    .rtl .pd-lg-l-4 {
        padding-right: 4px;
    }

    .rtl .pd-lg-r-4-f {
        padding-left: 4px !important;
    }

    .rtl .pd-lg-l-4-f {
        padding-right: 4px !important;
    }

    .rtl .pd-lg-x-5 {
        padding-right: 5px;
        padding-left: 5px;
    }

    .rtl .pd-lg-x-5-f {
        padding-right: 5px !important;
        padding-left: 5px !important;
    }

    .rtl .pd-lg-r-5 {
        padding-left: 5px;
    }

    .rtl .pd-lg-l-5 {
        padding-right: 5px;
    }

    .rtl .pd-lg-r-5-f {
        padding-left: 5px !important;
    }

    .rtl .pd-lg-l-5-f {
        padding-right: 5px !important;
    }

    .rtl .pd-lg-x-6 {
        padding-right: 6px;
        padding-left: 6px;
    }

    .rtl .pd-lg-x-6-f {
        padding-right: 6px !important;
        padding-left: 6px !important;
    }

    .rtl .pd-lg-r-6 {
        padding-left: 6px;
    }

    .rtl .pd-lg-l-6 {
        padding-right: 6px;
    }

    .rtl .pd-lg-r-6-f {
        padding-left: 6px !important;
    }

    .rtl .pd-lg-l-6-f {
        padding-right: 6px !important;
    }

    .rtl .pd-lg-x-7 {
        padding-right: 7px;
        padding-left: 7px;
    }

    .rtl .pd-lg-x-7-f {
        padding-right: 7px !important;
        padding-left: 7px !important;
    }

    .rtl .pd-lg-r-7 {
        padding-left: 7px;
    }

    .rtl .pd-lg-l-7 {
        padding-right: 7px;
    }

    .rtl .pd-lg-r-7-f {
        padding-left: 7px !important;
    }

    .rtl .pd-lg-l-7-f {
        padding-right: 7px !important;
    }

    .rtl .pd-lg-x-8 {
        padding-right: 8px;
        padding-left: 8px;
    }

    .rtl .pd-lg-x-8-f {
        padding-right: 8px !important;
        padding-left: 8px !important;
    }

    .rtl .pd-lg-r-8 {
        padding-left: 8px;
    }

    .rtl .pd-lg-l-8 {
        padding-right: 8px;
    }

    .rtl .pd-lg-r-8-f {
        padding-left: 8px !important;
    }

    .rtl .pd-lg-l-8-f {
        padding-right: 8px !important;
    }

    .rtl .pd-lg-x-9 {
        padding-right: 9px;
        padding-left: 9px;
    }

    .rtl .pd-lg-x-9-f {
        padding-right: 9px !important;
        padding-left: 9px !important;
    }

    .rtl .pd-lg-r-9 {
        padding-left: 9px;
    }

    .rtl .pd-lg-l-9 {
        padding-right: 9px;
    }

    .rtl .pd-lg-r-9-f {
        padding-left: 9px !important;
    }

    .rtl .pd-lg-l-9-f {
        padding-right: 9px !important;
    }

    .rtl .pd-lg-x-10 {
        padding-right: 10px;
        padding-left: 10px;
    }

    .rtl .pd-lg-x-10-f {
        padding-right: 10px !important;
        padding-left: 10px !important;
    }

    .rtl .pd-lg-r-10 {
        padding-left: 10px;
    }

    .rtl .pd-lg-l-10 {
        padding-right: 10px;
    }

    .rtl .pd-lg-r-10-f {
        padding-left: 10px !important;
    }

    .rtl .pd-lg-l-10-f {
        padding-right: 10px !important;
    }

    .rtl .pd-lg-x-15 {
        padding-right: 15px;
        padding-left: 15px;
    }

    .rtl .pd-lg-x-15-f {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }

    .rtl .pd-lg-r-15 {
        padding-left: 15px;
    }

    .rtl .pd-lg-l-15 {
        padding-right: 15px;
    }

    .rtl .pd-lg-r-15-f {
        padding-left: 15px !important;
    }

    .rtl .pd-lg-l-15-f {
        padding-right: 15px !important;
    }

    .rtl .pd-lg-x-20 {
        padding-right: 20px;
        padding-left: 20px;
    }

    .rtl .pd-lg-x-20-f {
        padding-right: 20px !important;
        padding-left: 20px !important;
    }

    .rtl .pd-lg-r-20 {
        padding-left: 20px;
    }

    .rtl .pd-lg-l-20 {
        padding-right: 20px;
    }

    .rtl .pd-lg-r-20-f {
        padding-left: 20px !important;
    }

    .rtl .pd-lg-l-20-f {
        padding-right: 20px !important;
    }

    .rtl .pd-lg-x-25 {
        padding-right: 25px;
        padding-left: 25px;
    }

    .rtl .pd-lg-x-25-f {
        padding-right: 25px !important;
        padding-left: 25px !important;
    }

    .rtl .pd-lg-r-25 {
        padding-left: 25px;
    }

    .rtl .pd-lg-l-25 {
        padding-right: 25px;
    }

    .rtl .pd-lg-r-25-f {
        padding-left: 25px !important;
    }

    .rtl .pd-lg-l-25-f {
        padding-right: 25px !important;
    }

    .rtl .pd-lg-x-30 {
        padding-right: 30px;
        padding-left: 30px;
    }

    .rtl .pd-lg-x-30-f {
        padding-right: 30px !important;
        padding-left: 30px !important;
    }

    .rtl .pd-lg-r-30 {
        padding-left: 30px;
    }

    .rtl .pd-lg-l-30 {
        padding-right: 30px;
    }

    .rtl .pd-lg-r-30-f {
        padding-left: 30px !important;
    }

    .rtl .pd-lg-l-30-f {
        padding-right: 30px !important;
    }

    .rtl .pd-lg-x-35 {
        padding-right: 35px;
        padding-left: 35px;
    }

    .rtl .pd-lg-x-35-f {
        padding-right: 35px !important;
        padding-left: 35px !important;
    }

    .rtl .pd-lg-r-35 {
        padding-left: 35px;
    }

    .rtl .pd-lg-l-35 {
        padding-right: 35px;
    }

    .rtl .pd-lg-r-35-f {
        padding-left: 35px !important;
    }

    .rtl .pd-lg-l-35-f {
        padding-right: 35px !important;
    }

    .rtl .pd-lg-x-40 {
        padding-right: 40px;
        padding-left: 40px;
    }

    .rtl .pd-lg-x-40-f {
        padding-right: 40px !important;
        padding-left: 40px !important;
    }

    .rtl .pd-lg-r-40 {
        padding-left: 40px;
    }

    .rtl .pd-lg-l-40 {
        padding-right: 40px;
    }

    .rtl .pd-lg-r-40-f {
        padding-left: 40px !important;
    }

    .rtl .pd-lg-l-40-f {
        padding-right: 40px !important;
    }

    .rtl .pd-lg-x-45 {
        padding-right: 45px;
        padding-left: 45px;
    }

    .rtl .pd-lg-x-45-f {
        padding-right: 45px !important;
        padding-left: 45px !important;
    }

    .rtl .pd-lg-r-45 {
        padding-left: 45px;
    }

    .rtl .pd-lg-l-45 {
        padding-right: 45px;
    }

    .rtl .pd-lg-r-45-f {
        padding-left: 45px !important;
    }

    .rtl .pd-lg-l-45-f {
        padding-right: 45px !important;
    }

    .rtl .pd-lg-x-50 {
        padding-right: 50px;
        padding-left: 50px;
    }

    .rtl .pd-lg-x-50-f {
        padding-right: 50px !important;
        padding-left: 50px !important;
    }

    .rtl .pd-lg-r-50 {
        padding-left: 50px;
    }

    .rtl .pd-lg-l-50 {
        padding-right: 50px;
    }

    .rtl .pd-lg-r-50-f {
        padding-left: 50px !important;
    }

    .rtl .pd-lg-l-50-f {
        padding-right: 50px !important;
    }

    .rtl .pd-lg-x-55 {
        padding-right: 55px;
        padding-left: 55px;
    }

    .rtl .pd-lg-x-55-f {
        padding-right: 55px !important;
        padding-left: 55px !important;
    }

    .rtl .pd-lg-r-55 {
        padding-left: 55px;
    }

    .rtl .pd-lg-l-55 {
        padding-right: 55px;
    }

    .rtl .pd-lg-r-55-f {
        padding-left: 55px !important;
    }

    .rtl .pd-lg-l-55-f {
        padding-right: 55px !important;
    }

    .rtl .pd-lg-x-60 {
        padding-right: 60px;
        padding-left: 60px;
    }

    .rtl .pd-lg-x-60-f {
        padding-right: 60px !important;
        padding-left: 60px !important;
    }

    .rtl .pd-lg-r-60 {
        padding-left: 60px;
    }

    .rtl .pd-lg-l-60 {
        padding-right: 60px;
    }

    .rtl .pd-lg-r-60-f {
        padding-left: 60px !important;
    }

    .rtl .pd-lg-l-60-f {
        padding-right: 60px !important;
    }

    .rtl .pd-lg-x-65 {
        padding-right: 65px;
        padding-left: 65px;
    }

    .rtl .pd-lg-x-65-f {
        padding-right: 65px !important;
        padding-left: 65px !important;
    }

    .rtl .pd-lg-r-65 {
        padding-left: 65px;
    }

    .rtl .pd-lg-l-65 {
        padding-right: 65px;
    }

    .rtl .pd-lg-r-65-f {
        padding-left: 65px !important;
    }

    .rtl .pd-lg-l-65-f {
        padding-right: 65px !important;
    }

    .rtl .pd-lg-x-70 {
        padding-right: 70px;
        padding-left: 70px;
    }

    .rtl .pd-lg-x-70-f {
        padding-right: 70px !important;
        padding-left: 70px !important;
    }

    .rtl .pd-lg-r-70 {
        padding-left: 70px;
    }

    .rtl .pd-lg-l-70 {
        padding-right: 70px;
    }

    .rtl .pd-lg-r-70-f {
        padding-left: 70px !important;
    }

    .rtl .pd-lg-l-70-f {
        padding-right: 70px !important;
    }

    .rtl .pd-lg-x-75 {
        padding-right: 75px;
        padding-left: 75px;
    }

    .rtl .pd-lg-x-75-f {
        padding-right: 75px !important;
        padding-left: 75px !important;
    }

    .rtl .pd-lg-r-75 {
        padding-left: 75px;
    }

    .rtl .pd-lg-l-75 {
        padding-right: 75px;
    }

    .rtl .pd-lg-r-75-f {
        padding-left: 75px !important;
    }

    .rtl .pd-lg-l-75-f {
        padding-right: 75px !important;
    }

    .rtl .pd-lg-x-80 {
        padding-right: 80px;
        padding-left: 80px;
    }

    .rtl .pd-lg-x-80-f {
        padding-right: 80px !important;
        padding-left: 80px !important;
    }

    .rtl .pd-lg-r-80 {
        padding-left: 80px;
    }

    .rtl .pd-lg-l-80 {
        padding-right: 80px;
    }

    .rtl .pd-lg-r-80-f {
        padding-left: 80px !important;
    }

    .rtl .pd-lg-l-80-f {
        padding-right: 80px !important;
    }

    .rtl .pd-lg-x-85 {
        padding-right: 85px;
        padding-left: 85px;
    }

    .rtl .pd-lg-x-85-f {
        padding-right: 85px !important;
        padding-left: 85px !important;
    }

    .rtl .pd-lg-r-85 {
        padding-left: 85px;
    }

    .rtl .pd-lg-l-85 {
        padding-right: 85px;
    }

    .rtl .pd-lg-r-85-f {
        padding-left: 85px !important;
    }

    .rtl .pd-lg-l-85-f {
        padding-right: 85px !important;
    }

    .rtl .pd-lg-x-90 {
        padding-right: 90px;
        padding-left: 90px;
    }

    .rtl .pd-lg-x-90-f {
        padding-right: 90px !important;
        padding-left: 90px !important;
    }

    .rtl .pd-lg-r-90 {
        padding-left: 90px;
    }

    .rtl .pd-lg-l-90 {
        padding-right: 90px;
    }

    .rtl .pd-lg-r-90-f {
        padding-left: 90px !important;
    }

    .rtl .pd-lg-l-90-f {
        padding-right: 90px !important;
    }

    .rtl .pd-lg-x-95 {
        padding-right: 95px;
        padding-left: 95px;
    }

    .rtl .pd-lg-x-95-f {
        padding-right: 95px !important;
        padding-left: 95px !important;
    }

    .rtl .pd-lg-r-95 {
        padding-left: 95px;
    }

    .rtl .pd-lg-l-95 {
        padding-right: 95px;
    }

    .rtl .pd-lg-r-95-f {
        padding-left: 95px !important;
    }

    .rtl .pd-lg-l-95-f {
        padding-right: 95px !important;
    }

    .rtl .pd-lg-x-100 {
        padding-right: 100px;
        padding-left: 100px;
    }

    .rtl .pd-lg-x-100-f {
        padding-right: 100px !important;
        padding-left: 100px !important;
    }

    .rtl .pd-lg-r-100 {
        padding-left: 100px;
    }

    .rtl .pd-lg-l-100 {
        padding-right: 100px;
    }

    .rtl .pd-lg-r-100-f {
        padding-left: 100px !important;
    }

    .rtl .pd-lg-l-100-f {
        padding-right: 100px !important;
    }

    .rtl .pd-lg-x-110 {
        padding-right: 110px;
        padding-left: 110px;
    }

    .rtl .pd-lg-x-110-f {
        padding-right: 110px !important;
        padding-left: 110px !important;
    }

    .rtl .pd-lg-r-110 {
        padding-left: 110px;
    }

    .rtl .pd-lg-l-110 {
        padding-right: 110px;
    }

    .rtl .pd-lg-r-110-f {
        padding-left: 110px !important;
    }

    .rtl .pd-lg-l-110-f {
        padding-right: 110px !important;
    }

    .rtl .pd-lg-x-120 {
        padding-right: 120px;
        padding-left: 120px;
    }

    .rtl .pd-lg-x-120-f {
        padding-right: 120px !important;
        padding-left: 120px !important;
    }

    .rtl .pd-lg-r-120 {
        padding-left: 120px;
    }

    .rtl .pd-lg-l-120 {
        padding-right: 120px;
    }

    .rtl .pd-lg-r-120-f {
        padding-left: 120px !important;
    }

    .rtl .pd-lg-l-120-f {
        padding-right: 120px !important;
    }

    .rtl .pd-lg-x-130 {
        padding-right: 130px;
        padding-left: 130px;
    }

    .rtl .pd-lg-x-130-f {
        padding-right: 130px !important;
        padding-left: 130px !important;
    }

    .rtl .pd-lg-r-130 {
        padding-left: 130px;
    }

    .rtl .pd-lg-l-130 {
        padding-right: 130px;
    }

    .rtl .pd-lg-r-130-f {
        padding-left: 130px !important;
    }

    .rtl .pd-lg-l-130-f {
        padding-right: 130px !important;
    }

    .rtl .pd-lg-x-140 {
        padding-right: 140px;
        padding-left: 140px;
    }

    .rtl .pd-lg-x-140-f {
        padding-right: 140px !important;
        padding-left: 140px !important;
    }

    .rtl .pd-lg-r-140 {
        padding-left: 140px;
    }

    .rtl .pd-lg-l-140 {
        padding-right: 140px;
    }

    .rtl .pd-lg-r-140-f {
        padding-left: 140px !important;
    }

    .rtl .pd-lg-l-140-f {
        padding-right: 140px !important;
    }

    .rtl .pd-lg-x-150 {
        padding-right: 150px;
        padding-left: 150px;
    }

    .rtl .pd-lg-x-150-f {
        padding-right: 150px !important;
        padding-left: 150px !important;
    }

    .rtl .pd-lg-r-150 {
        padding-left: 150px;
    }

    .rtl .pd-lg-l-150 {
        padding-right: 150px;
    }

    .rtl .pd-lg-r-150-f {
        padding-left: 150px !important;
    }

    .rtl .pd-lg-l-150-f {
        padding-right: 150px !important;
    }

    .rtl .pd-lg-x-160 {
        padding-right: 160px;
        padding-left: 160px;
    }

    .rtl .pd-lg-x-160-f {
        padding-right: 160px !important;
        padding-left: 160px !important;
    }

    .rtl .pd-lg-r-160 {
        padding-left: 160px;
    }

    .rtl .pd-lg-l-160 {
        padding-right: 160px;
    }

    .rtl .pd-lg-r-160-f {
        padding-left: 160px !important;
    }

    .rtl .pd-lg-l-160-f {
        padding-right: 160px !important;
    }

    .rtl .pd-lg-x-170 {
        padding-right: 170px;
        padding-left: 170px;
    }

    .rtl .pd-lg-x-170-f {
        padding-right: 170px !important;
        padding-left: 170px !important;
    }

    .rtl .pd-lg-r-170 {
        padding-left: 170px;
    }

    .rtl .pd-lg-l-170 {
        padding-right: 170px;
    }

    .rtl .pd-lg-r-170-f {
        padding-left: 170px !important;
    }

    .rtl .pd-lg-l-170-f {
        padding-right: 170px !important;
    }

    .rtl .pd-lg-x-180 {
        padding-right: 180px;
        padding-left: 180px;
    }

    .rtl .pd-lg-x-180-f {
        padding-right: 180px !important;
        padding-left: 180px !important;
    }

    .rtl .pd-lg-r-180 {
        padding-left: 180px;
    }

    .rtl .pd-lg-l-180 {
        padding-right: 180px;
    }

    .rtl .pd-lg-r-180-f {
        padding-left: 180px !important;
    }

    .rtl .pd-lg-l-180-f {
        padding-right: 180px !important;
    }

    .rtl .pd-lg-x-190 {
        padding-right: 190px;
        padding-left: 190px;
    }

    .rtl .pd-lg-x-190-f {
        padding-right: 190px !important;
        padding-left: 190px !important;
    }

    .rtl .pd-lg-r-190 {
        padding-left: 190px;
    }

    .rtl .pd-lg-l-190 {
        padding-right: 190px;
    }

    .rtl .pd-lg-r-190-f {
        padding-left: 190px !important;
    }

    .rtl .pd-lg-l-190-f {
        padding-right: 190px !important;
    }

    .rtl .pd-lg-x-200 {
        padding-right: 200px;
        padding-left: 200px;
    }

    .rtl .pd-lg-x-200-f {
        padding-right: 200px !important;
        padding-left: 200px !important;
    }

    .rtl .pd-lg-r-200 {
        padding-left: 200px;
    }

    .rtl .pd-lg-l-200 {
        padding-right: 200px;
    }

    .rtl .pd-lg-r-200-f {
        padding-left: 200px !important;
    }

    .rtl .pd-lg-l-200-f {
        padding-right: 200px !important;
    }
}

@media (min-width: 1200px) {
    .rtl .pd-xl-x-0 {
        padding-right: 0px;
        padding-left: 0px;
    }

    .rtl .pd-xl-x-0-f {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .rtl .pd-xl-r-0 {
        padding-left: 0px;
    }

    .rtl .pd-xl-l-0 {
        padding-right: 0px;
    }

    .rtl .pd-xl-r-0-f {
        padding-left: 0px !important;
    }

    .rtl .pd-xl-l-0-f {
        padding-right: 0px !important;
    }

    .rtl .pd-xl-x-1 {
        padding-right: 1px;
        padding-left: 1px;
    }

    .rtl .pd-xl-x-1-f {
        padding-right: 1px !important;
        padding-left: 1px !important;
    }

    .rtl .pd-xl-r-1 {
        padding-left: 1px;
    }

    .rtl .pd-xl-l-1 {
        padding-right: 1px;
    }

    .rtl .pd-xl-r-1-f {
        padding-left: 1px !important;
    }

    .rtl .pd-xl-l-1-f {
        padding-right: 1px !important;
    }

    .rtl .pd-xl-x-2 {
        padding-right: 2px;
        padding-left: 2px;
    }

    .rtl .pd-xl-x-2-f {
        padding-right: 2px !important;
        padding-left: 2px !important;
    }

    .rtl .pd-xl-r-2 {
        padding-left: 2px;
    }

    .rtl .pd-xl-l-2 {
        padding-right: 2px;
    }

    .rtl .pd-xl-r-2-f {
        padding-left: 2px !important;
    }

    .rtl .pd-xl-l-2-f {
        padding-right: 2px !important;
    }

    .rtl .pd-xl-x-3 {
        padding-right: 3px;
        padding-left: 3px;
    }

    .rtl .pd-xl-x-3-f {
        padding-right: 3px !important;
        padding-left: 3px !important;
    }

    .rtl .pd-xl-r-3 {
        padding-left: 3px;
    }

    .rtl .pd-xl-l-3 {
        padding-right: 3px;
    }

    .rtl .pd-xl-r-3-f {
        padding-left: 3px !important;
    }

    .rtl .pd-xl-l-3-f {
        padding-right: 3px !important;
    }

    .rtl .pd-xl-x-4 {
        padding-right: 4px;
        padding-left: 4px;
    }

    .rtl .pd-xl-x-4-f {
        padding-right: 4px !important;
        padding-left: 4px !important;
    }

    .rtl .pd-xl-r-4 {
        padding-left: 4px;
    }

    .rtl .pd-xl-l-4 {
        padding-right: 4px;
    }

    .rtl .pd-xl-r-4-f {
        padding-left: 4px !important;
    }

    .rtl .pd-xl-l-4-f {
        padding-right: 4px !important;
    }

    .rtl .pd-xl-x-5 {
        padding-right: 5px;
        padding-left: 5px;
    }

    .rtl .pd-xl-x-5-f {
        padding-right: 5px !important;
        padding-left: 5px !important;
    }

    .rtl .pd-xl-r-5 {
        padding-left: 5px;
    }

    .rtl .pd-xl-l-5 {
        padding-right: 5px;
    }

    .rtl .pd-xl-r-5-f {
        padding-left: 5px !important;
    }

    .rtl .pd-xl-l-5-f {
        padding-right: 5px !important;
    }

    .rtl .pd-xl-x-6 {
        padding-right: 6px;
        padding-left: 6px;
    }

    .rtl .pd-xl-x-6-f {
        padding-right: 6px !important;
        padding-left: 6px !important;
    }

    .rtl .pd-xl-r-6 {
        padding-left: 6px;
    }

    .rtl .pd-xl-l-6 {
        padding-right: 6px;
    }

    .rtl .pd-xl-r-6-f {
        padding-left: 6px !important;
    }

    .rtl .pd-xl-l-6-f {
        padding-right: 6px !important;
    }

    .rtl .pd-xl-x-7 {
        padding-right: 7px;
        padding-left: 7px;
    }

    .rtl .pd-xl-x-7-f {
        padding-right: 7px !important;
        padding-left: 7px !important;
    }

    .rtl .pd-xl-r-7 {
        padding-left: 7px;
    }

    .rtl .pd-xl-l-7 {
        padding-right: 7px;
    }

    .rtl .pd-xl-r-7-f {
        padding-left: 7px !important;
    }

    .rtl .pd-xl-l-7-f {
        padding-right: 7px !important;
    }

    .rtl .pd-xl-x-8 {
        padding-right: 8px;
        padding-left: 8px;
    }

    .rtl .pd-xl-x-8-f {
        padding-right: 8px !important;
        padding-left: 8px !important;
    }

    .rtl .pd-xl-r-8 {
        padding-left: 8px;
    }

    .rtl .pd-xl-l-8 {
        padding-right: 8px;
    }

    .rtl .pd-xl-r-8-f {
        padding-left: 8px !important;
    }

    .rtl .pd-xl-l-8-f {
        padding-right: 8px !important;
    }

    .rtl .pd-xl-x-9 {
        padding-right: 9px;
        padding-left: 9px;
    }

    .rtl .pd-xl-x-9-f {
        padding-right: 9px !important;
        padding-left: 9px !important;
    }

    .rtl .pd-xl-r-9 {
        padding-left: 9px;
    }

    .rtl .pd-xl-l-9 {
        padding-right: 9px;
    }

    .rtl .pd-xl-r-9-f {
        padding-left: 9px !important;
    }

    .rtl .pd-xl-l-9-f {
        padding-right: 9px !important;
    }

    .rtl .pd-xl-x-10 {
        padding-right: 10px;
        padding-left: 10px;
    }

    .rtl .pd-xl-x-10-f {
        padding-right: 10px !important;
        padding-left: 10px !important;
    }

    .rtl .pd-xl-r-10 {
        padding-left: 10px;
    }

    .rtl .pd-xl-l-10 {
        padding-right: 10px;
    }

    .rtl .pd-xl-r-10-f {
        padding-left: 10px !important;
    }

    .rtl .pd-xl-l-10-f {
        padding-right: 10px !important;
    }

    .rtl .pd-xl-x-15 {
        padding-right: 15px;
        padding-left: 15px;
    }

    .rtl .pd-xl-x-15-f {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }

    .rtl .pd-xl-r-15 {
        padding-left: 15px;
    }

    .rtl .pd-xl-l-15 {
        padding-right: 15px;
    }

    .rtl .pd-xl-r-15-f {
        padding-left: 15px !important;
    }

    .rtl .pd-xl-l-15-f {
        padding-right: 15px !important;
    }

    .rtl .pd-xl-x-20 {
        padding-right: 20px;
        padding-left: 20px;
    }

    .rtl .pd-xl-x-20-f {
        padding-right: 20px !important;
        padding-left: 20px !important;
    }

    .rtl .pd-xl-r-20 {
        padding-left: 20px;
    }

    .rtl .pd-xl-l-20 {
        padding-right: 20px;
    }

    .rtl .pd-xl-r-20-f {
        padding-left: 20px !important;
    }

    .rtl .pd-xl-l-20-f {
        padding-right: 20px !important;
    }

    .rtl .pd-xl-x-25 {
        padding-right: 25px;
        padding-left: 25px;
    }

    .rtl .pd-xl-x-25-f {
        padding-right: 25px !important;
        padding-left: 25px !important;
    }

    .rtl .pd-xl-r-25 {
        padding-left: 25px;
    }

    .rtl .pd-xl-l-25 {
        padding-right: 25px;
    }

    .rtl .pd-xl-r-25-f {
        padding-left: 25px !important;
    }

    .rtl .pd-xl-l-25-f {
        padding-right: 25px !important;
    }

    .rtl .pd-xl-x-30 {
        padding-right: 30px;
        padding-left: 30px;
    }

    .rtl .pd-xl-x-30-f {
        padding-right: 30px !important;
        padding-left: 30px !important;
    }

    .rtl .pd-xl-r-30 {
        padding-left: 30px;
    }

    .rtl .pd-xl-l-30 {
        padding-right: 30px;
    }

    .rtl .pd-xl-r-30-f {
        padding-left: 30px !important;
    }

    .rtl .pd-xl-l-30-f {
        padding-right: 30px !important;
    }

    .rtl .pd-xl-x-35 {
        padding-right: 35px;
        padding-left: 35px;
    }

    .rtl .pd-xl-x-35-f {
        padding-right: 35px !important;
        padding-left: 35px !important;
    }

    .rtl .pd-xl-r-35 {
        padding-left: 35px;
    }

    .rtl .pd-xl-l-35 {
        padding-right: 35px;
    }

    .rtl .pd-xl-r-35-f {
        padding-left: 35px !important;
    }

    .rtl .pd-xl-l-35-f {
        padding-right: 35px !important;
    }

    .rtl .pd-xl-x-40 {
        padding-right: 40px;
        padding-left: 40px;
    }

    .rtl .pd-xl-x-40-f {
        padding-right: 40px !important;
        padding-left: 40px !important;
    }

    .rtl .pd-xl-r-40 {
        padding-left: 40px;
    }

    .rtl .pd-xl-l-40 {
        padding-right: 40px;
    }

    .rtl .pd-xl-r-40-f {
        padding-left: 40px !important;
    }

    .rtl .pd-xl-l-40-f {
        padding-right: 40px !important;
    }

    .rtl .pd-xl-x-45 {
        padding-right: 45px;
        padding-left: 45px;
    }

    .rtl .pd-xl-x-45-f {
        padding-right: 45px !important;
        padding-left: 45px !important;
    }

    .rtl .pd-xl-r-45 {
        padding-left: 45px;
    }

    .rtl .pd-xl-l-45 {
        padding-right: 45px;
    }

    .rtl .pd-xl-r-45-f {
        padding-left: 45px !important;
    }

    .rtl .pd-xl-l-45-f {
        padding-right: 45px !important;
    }

    .rtl .pd-xl-x-50 {
        padding-right: 50px;
        padding-left: 50px;
    }

    .rtl .pd-xl-x-50-f {
        padding-right: 50px !important;
        padding-left: 50px !important;
    }

    .rtl .pd-xl-r-50 {
        padding-left: 50px;
    }

    .rtl .pd-xl-l-50 {
        padding-right: 50px;
    }

    .rtl .pd-xl-r-50-f {
        padding-left: 50px !important;
    }

    .rtl .pd-xl-l-50-f {
        padding-right: 50px !important;
    }

    .rtl .pd-xl-x-55 {
        padding-right: 55px;
        padding-left: 55px;
    }

    .rtl .pd-xl-x-55-f {
        padding-right: 55px !important;
        padding-left: 55px !important;
    }

    .rtl .pd-xl-r-55 {
        padding-left: 55px;
    }

    .rtl .pd-xl-l-55 {
        padding-right: 55px;
    }

    .rtl .pd-xl-r-55-f {
        padding-left: 55px !important;
    }

    .rtl .pd-xl-l-55-f {
        padding-right: 55px !important;
    }

    .rtl .pd-xl-x-60 {
        padding-right: 60px;
        padding-left: 60px;
    }

    .rtl .pd-xl-x-60-f {
        padding-right: 60px !important;
        padding-left: 60px !important;
    }

    .rtl .pd-xl-r-60 {
        padding-left: 60px;
    }

    .rtl .pd-xl-l-60 {
        padding-right: 60px;
    }

    .rtl .pd-xl-r-60-f {
        padding-left: 60px !important;
    }

    .rtl .pd-xl-l-60-f {
        padding-right: 60px !important;
    }

    .rtl .pd-xl-x-65 {
        padding-right: 65px;
        padding-left: 65px;
    }

    .rtl .pd-xl-x-65-f {
        padding-right: 65px !important;
        padding-left: 65px !important;
    }

    .rtl .pd-xl-r-65 {
        padding-left: 65px;
    }

    .rtl .pd-xl-l-65 {
        padding-right: 65px;
    }

    .rtl .pd-xl-r-65-f {
        padding-left: 65px !important;
    }

    .rtl .pd-xl-l-65-f {
        padding-right: 65px !important;
    }

    .rtl .pd-xl-x-70 {
        padding-right: 70px;
        padding-left: 70px;
    }

    .rtl .pd-xl-x-70-f {
        padding-right: 70px !important;
        padding-left: 70px !important;
    }

    .rtl .pd-xl-r-70 {
        padding-left: 70px;
    }

    .rtl .pd-xl-l-70 {
        padding-right: 70px;
    }

    .rtl .pd-xl-r-70-f {
        padding-left: 70px !important;
    }

    .rtl .pd-xl-l-70-f {
        padding-right: 70px !important;
    }

    .rtl .pd-xl-x-75 {
        padding-right: 75px;
        padding-left: 75px;
    }

    .rtl .pd-xl-x-75-f {
        padding-right: 75px !important;
        padding-left: 75px !important;
    }

    .rtl .pd-xl-r-75 {
        padding-left: 75px;
    }

    .rtl .pd-xl-l-75 {
        padding-right: 75px;
    }

    .rtl .pd-xl-r-75-f {
        padding-left: 75px !important;
    }

    .rtl .pd-xl-l-75-f {
        padding-right: 75px !important;
    }

    .rtl .pd-xl-x-80 {
        padding-right: 80px;
        padding-left: 80px;
    }

    .rtl .pd-xl-x-80-f {
        padding-right: 80px !important;
        padding-left: 80px !important;
    }

    .rtl .pd-xl-r-80 {
        padding-left: 80px;
    }

    .rtl .pd-xl-l-80 {
        padding-right: 80px;
    }

    .rtl .pd-xl-r-80-f {
        padding-left: 80px !important;
    }

    .rtl .pd-xl-l-80-f {
        padding-right: 80px !important;
    }

    .rtl .pd-xl-x-85 {
        padding-right: 85px;
        padding-left: 85px;
    }

    .rtl .pd-xl-x-85-f {
        padding-right: 85px !important;
        padding-left: 85px !important;
    }

    .rtl .pd-xl-r-85 {
        padding-left: 85px;
    }

    .rtl .pd-xl-l-85 {
        padding-right: 85px;
    }

    .rtl .pd-xl-r-85-f {
        padding-left: 85px !important;
    }

    .rtl .pd-xl-l-85-f {
        padding-right: 85px !important;
    }

    .rtl .pd-xl-x-90 {
        padding-right: 90px;
        padding-left: 90px;
    }

    .rtl .pd-xl-x-90-f {
        padding-right: 90px !important;
        padding-left: 90px !important;
    }

    .rtl .pd-xl-r-90 {
        padding-left: 90px;
    }

    .rtl .pd-xl-l-90 {
        padding-right: 90px;
    }

    .rtl .pd-xl-r-90-f {
        padding-left: 90px !important;
    }

    .rtl .pd-xl-l-90-f {
        padding-right: 90px !important;
    }

    .rtl .pd-xl-x-95 {
        padding-right: 95px;
        padding-left: 95px;
    }

    .rtl .pd-xl-x-95-f {
        padding-right: 95px !important;
        padding-left: 95px !important;
    }

    .rtl .pd-xl-r-95 {
        padding-left: 95px;
    }

    .rtl .pd-xl-l-95 {
        padding-right: 95px;
    }

    .rtl .pd-xl-r-95-f {
        padding-left: 95px !important;
    }

    .rtl .pd-xl-l-95-f {
        padding-right: 95px !important;
    }

    .rtl .pd-xl-x-100 {
        padding-right: 100px;
        padding-left: 100px;
    }

    .rtl .pd-xl-x-100-f {
        padding-right: 100px !important;
        padding-left: 100px !important;
    }

    .rtl .pd-xl-r-100 {
        padding-left: 100px;
    }

    .rtl .pd-xl-l-100 {
        padding-right: 100px;
    }

    .rtl .pd-xl-r-100-f {
        padding-left: 100px !important;
    }

    .rtl .pd-xl-l-100-f {
        padding-right: 100px !important;
    }

    .rtl .pd-xl-x-110 {
        padding-right: 110px;
        padding-left: 110px;
    }

    .rtl .pd-xl-x-110-f {
        padding-right: 110px !important;
        padding-left: 110px !important;
    }

    .rtl .pd-xl-r-110 {
        padding-left: 110px;
    }

    .rtl .pd-xl-l-110 {
        padding-right: 110px;
    }

    .rtl .pd-xl-r-110-f {
        padding-left: 110px !important;
    }

    .rtl .pd-xl-l-110-f {
        padding-right: 110px !important;
    }

    .rtl .pd-xl-x-120 {
        padding-right: 120px;
        padding-left: 120px;
    }

    .rtl .pd-xl-x-120-f {
        padding-right: 120px !important;
        padding-left: 120px !important;
    }

    .rtl .pd-xl-r-120 {
        padding-left: 120px;
    }

    .rtl .pd-xl-l-120 {
        padding-right: 120px;
    }

    .rtl .pd-xl-r-120-f {
        padding-left: 120px !important;
    }

    .rtl .pd-xl-l-120-f {
        padding-right: 120px !important;
    }

    .rtl .pd-xl-x-130 {
        padding-right: 130px;
        padding-left: 130px;
    }

    .rtl .pd-xl-x-130-f {
        padding-right: 130px !important;
        padding-left: 130px !important;
    }

    .rtl .pd-xl-r-130 {
        padding-left: 130px;
    }

    .rtl .pd-xl-l-130 {
        padding-right: 130px;
    }

    .rtl .pd-xl-r-130-f {
        padding-left: 130px !important;
    }

    .rtl .pd-xl-l-130-f {
        padding-right: 130px !important;
    }

    .rtl .pd-xl-x-140 {
        padding-right: 140px;
        padding-left: 140px;
    }

    .rtl .pd-xl-x-140-f {
        padding-right: 140px !important;
        padding-left: 140px !important;
    }

    .rtl .pd-xl-r-140 {
        padding-left: 140px;
    }

    .rtl .pd-xl-l-140 {
        padding-right: 140px;
    }

    .rtl .pd-xl-r-140-f {
        padding-left: 140px !important;
    }

    .rtl .pd-xl-l-140-f {
        padding-right: 140px !important;
    }

    .rtl .pd-xl-x-150 {
        padding-right: 150px;
        padding-left: 150px;
    }

    .rtl .pd-xl-x-150-f {
        padding-right: 150px !important;
        padding-left: 150px !important;
    }

    .rtl .pd-xl-r-150 {
        padding-left: 150px;
    }

    .rtl .pd-xl-l-150 {
        padding-right: 150px;
    }

    .rtl .pd-xl-r-150-f {
        padding-left: 150px !important;
    }

    .rtl .pd-xl-l-150-f {
        padding-right: 150px !important;
    }

    .rtl .pd-xl-x-160 {
        padding-right: 160px;
        padding-left: 160px;
    }

    .rtl .pd-xl-x-160-f {
        padding-right: 160px !important;
        padding-left: 160px !important;
    }

    .rtl .pd-xl-r-160 {
        padding-left: 160px;
    }

    .rtl .pd-xl-l-160 {
        padding-right: 160px;
    }

    .rtl .pd-xl-r-160-f {
        padding-left: 160px !important;
    }

    .rtl .pd-xl-l-160-f {
        padding-right: 160px !important;
    }

    .rtl .pd-xl-x-170 {
        padding-right: 170px;
        padding-left: 170px;
    }

    .rtl .pd-xl-x-170-f {
        padding-right: 170px !important;
        padding-left: 170px !important;
    }

    .rtl .pd-xl-r-170 {
        padding-left: 170px;
    }

    .rtl .pd-xl-l-170 {
        padding-right: 170px;
    }

    .rtl .pd-xl-r-170-f {
        padding-left: 170px !important;
    }

    .rtl .pd-xl-l-170-f {
        padding-right: 170px !important;
    }

    .rtl .pd-xl-x-180 {
        padding-right: 180px;
        padding-left: 180px;
    }

    .rtl .pd-xl-x-180-f {
        padding-right: 180px !important;
        padding-left: 180px !important;
    }

    .rtl .pd-xl-r-180 {
        padding-left: 180px;
    }

    .rtl .pd-xl-l-180 {
        padding-right: 180px;
    }

    .rtl .pd-xl-r-180-f {
        padding-left: 180px !important;
    }

    .rtl .pd-xl-l-180-f {
        padding-right: 180px !important;
    }

    .rtl .pd-xl-x-190 {
        padding-right: 190px;
        padding-left: 190px;
    }

    .rtl .pd-xl-x-190-f {
        padding-right: 190px !important;
        padding-left: 190px !important;
    }

    .rtl .pd-xl-r-190 {
        padding-left: 190px;
    }

    .rtl .pd-xl-l-190 {
        padding-right: 190px;
    }

    .rtl .pd-xl-r-190-f {
        padding-left: 190px !important;
    }

    .rtl .pd-xl-l-190-f {
        padding-right: 190px !important;
    }

    .rtl .pd-xl-x-200 {
        padding-right: 200px;
        padding-left: 200px;
    }

    .rtl .pd-xl-x-200-f {
        padding-right: 200px !important;
        padding-left: 200px !important;
    }

    .rtl .pd-xl-r-200 {
        padding-left: 200px;
    }

    .rtl .pd-xl-l-200 {
        padding-right: 200px;
    }

    .rtl .pd-xl-r-200-f {
        padding-left: 200px !important;
    }

    .rtl .pd-xl-l-200-f {
        padding-right: 200px !important;
    }
}

.rtl .pd-x-12 {
    padding-right: 12px;
    padding-left: 12px;
}

.rtl .pd-x-12-f {
    padding-right: 12px !important;
    padding-left: 12px !important;
}

.rtl .pd-r-12 {
    padding-left: 12px;
}

.rtl .pd-l-12 {
    padding-right: 12px;
}

.rtl .pd-r-12-f {
    padding-left: 12px !important;
}

.rtl .pd-l-12-f {
    padding-right: 12px !important;
}

.rtl .pd-x-13 {
    padding-right: 13px;
    padding-left: 13px;
}

.rtl .pd-x-13-f {
    padding-right: 13px !important;
    padding-left: 13px !important;
}

.rtl .pd-r-13 {
    padding-left: 13px;
}

.rtl .pd-l-13 {
    padding-right: 13px;
}

.rtl .pd-r-13-f {
    padding-left: 13px !important;
}

.rtl .pd-l-13-f {
    padding-right: 13px !important;
}

.rtl .r-0 {
    left: 0px;
}

.rtl .l-0 {
    right: 0px;
}

.rtl .r--0 {
    left: 0px;
}

.rtl .l--0 {
    right: 0px;
}

.rtl .a-0 {
    left: 0px;
    right: 0px;
}

.rtl .x-0,
.rtl .y-0 {
    right: 0px;
    left: 0px;
}

.rtl .r-5 {
    left: 5px;
}

.rtl .l-5 {
    right: 5px;
}

.rtl .r--5 {
    left: -5px;
}

.rtl .l--5 {
    right: -5px;
}

.rtl .a-5 {
    left: 5px;
    right: 5px;
}

.rtl .x-5,
.rtl .y-5 {
    right: 5px;
    left: 5px;
}

.rtl .r-10 {
    left: 10px;
}

.rtl .l-10 {
    right: 10px;
}

.rtl .r--10 {
    left: -10px;
}

.rtl .l--10 {
    right: -10px;
}

.rtl .a-10 {
    left: 10px;
    right: 10px;
}

.rtl .x-10,
.rtl .y-10 {
    right: 10px;
    left: 10px;
}

.rtl .r-15 {
    left: 15px;
}

.rtl .l-15 {
    right: 15px;
}

.rtl .r--15 {
    left: -15px;
}

.rtl .l--15 {
    right: -15px;
}

.rtl .a-15 {
    left: 15px;
    right: 15px;
}

.rtl .x-15,
.rtl .y-15 {
    right: 15px;
    left: 15px;
}

.rtl .r-20 {
    left: 20px;
    right: inherit;
}

.rtl .l-20 {
    right: 20px;
}

.rtl .r--20 {
    left: -20px;
}

.rtl .l--20 {
    right: -20px;
}

.rtl .a-20 {
    left: 20px;
    right: 20px;
}

.rtl .x-20,
.rtl .y-20 {
    right: 20px;
    left: 20px;
}

.rtl .r-25 {
    left: 25px;
}

.rtl .l-25 {
    right: 25px;
}

.rtl .r--25 {
    left: -25px;
}

.rtl .l--25 {
    right: -25px;
}

.rtl .a-25 {
    left: 25px;
    right: 25px;
}

.rtl .x-25,
.rtl .y-25 {
    right: 25px;
    left: 25px;
}

.rtl .r-30 {
    left: 30px;
}

.rtl .l-30 {
    right: 30px;
}

.rtl .r--30 {
    left: -30px;
}

.rtl .l--30 {
    right: -30px;
}

.rtl .a-30 {
    left: 30px;
    right: 30px;
}

.rtl .x-30,
.rtl .y-30 {
    right: 30px;
    left: 30px;
}

.rtl .r-35 {
    left: 35px;
}

.rtl .l-35 {
    right: 35px;
}

.rtl .r--35 {
    left: -35px;
}

.rtl .l--35 {
    right: -35px;
}

.rtl .a-35 {
    left: 35px;
    right: 35px;
}

.rtl .x-35,
.rtl .y-35 {
    right: 35px;
    left: 35px;
}

.rtl .r-40 {
    left: 40px;
}

.rtl .l-40 {
    right: 40px;
}

.rtl .r--40 {
    left: -40px;
}

.rtl .l--40 {
    right: -40px;
}

.rtl .a-40 {
    left: 40px;
    right: 40px;
}

.rtl .x-40,
.rtl .y-40 {
    right: 40px;
    left: 40px;
}

.rtl .r-45 {
    left: 45px;
}

.rtl .l-45 {
    right: 45px;
}

.rtl .r--45 {
    left: -45px;
}

.rtl .l--45 {
    right: -45px;
}

.rtl .a-45 {
    left: 45px;
    right: 45px;
}

.rtl .x-45,
.rtl .y-45 {
    right: 45px;
    left: 45px;
}

.rtl .r-50 {
    left: 50px;
}

.rtl .l-50 {
    right: 50px;
}

.rtl .r--50 {
    left: -50px;
}

.rtl .l--50 {
    right: -50px;
}

.rtl .a-50 {
    left: 50px;
    right: 50px;
}

.rtl .x-50,
.rtl .y-50 {
    right: 50px;
    left: 50px;
}

.rtl .r-55 {
    left: 55px;
}

.rtl .l-55 {
    right: 55px;
}

.rtl .r--55 {
    left: -55px;
}

.rtl .l--55 {
    right: -55px;
}

.rtl .a-55 {
    left: 55px;
    right: 55px;
}

.rtl .x-55,
.rtl .y-55 {
    right: 55px;
    left: 55px;
}

.rtl .r-60 {
    left: 60px;
}

.rtl .l-60 {
    right: 60px;
}

.rtl .r--60 {
    left: -60px;
}

.rtl .l--60 {
    right: -60px;
}

.rtl .a-60 {
    left: 60px;
    right: 60px;
}

.rtl .x-60,
.rtl .y-60 {
    right: 60px;
    left: 60px;
}

.rtl .r-65 {
    left: 65px;
}

.rtl .l-65 {
    right: 65px;
}

.rtl .r--65 {
    left: -65px;
}

.rtl .l--65 {
    right: -65px;
}

.rtl .a-65 {
    left: 65px;
    right: 65px;
}

.rtl .x-65,
.rtl .y-65 {
    right: 65px;
    left: 65px;
}

.rtl .r-70 {
    left: 70px;
}

.rtl .l-70 {
    right: 70px;
}

.rtl .r--70 {
    left: -70px;
}

.rtl .l--70 {
    right: -70px;
}

.rtl .a-70 {
    left: 70px;
    right: 70px;
}

.rtl .x-70,
.rtl .y-70 {
    right: 70px;
    left: 70px;
}

.rtl .r-75 {
    left: 75px;
}

.rtl .l-75 {
    right: 75px;
}

.rtl .r--75 {
    left: -75px;
}

.rtl .l--75 {
    right: -75px;
}

.rtl .a-75 {
    left: 75px;
    right: 75px;
}

.rtl .x-75,
.rtl .y-75 {
    right: 75px;
    left: 75px;
}

.rtl .r-80 {
    left: 80px;
}

.rtl .l-80 {
    right: 80px;
}

.rtl .r--80 {
    left: -80px;
}

.rtl .l--80 {
    right: -80px;
}

.rtl .a-80 {
    left: 80px;
    right: 80px;
}

.rtl .x-80,
.rtl .y-80 {
    right: 80px;
    left: 80px;
}

.rtl .r-85 {
    left: 85px;
}

.rtl .l-85 {
    right: 85px;
}

.rtl .r--85 {
    left: -85px;
}

.rtl .l--85 {
    right: -85px;
}

.rtl .a-85 {
    left: 85px;
    right: 85px;
}

.rtl .x-85,
.rtl .y-85 {
    right: 85px;
    left: 85px;
}

.rtl .r-90 {
    left: 90px;
}

.rtl .l-90 {
    right: 90px;
}

.rtl .r--90 {
    left: -90px;
}

.rtl .l--90 {
    right: -90px;
}

.rtl .a-90 {
    left: 90px;
    right: 90px;
}

.rtl .x-90,
.rtl .y-90 {
    right: 90px;
    left: 90px;
}

.rtl .r-95 {
    left: 95px;
}

.rtl .l-95 {
    right: 95px;
}

.rtl .r--95 {
    left: -95px;
}

.rtl .l--95 {
    right: -95px;
}

.rtl .a-95 {
    left: 95px;
    right: 95px;
}

.rtl .x-95,
.rtl .y-95 {
    right: 95px;
    left: 95px;
}

.rtl .r-100 {
    left: 100px;
}

.rtl .l-100 {
    right: 100px;
}

.rtl .r--100 {
    left: -100px;
}

.rtl .l--100 {
    right: -100px;
}

.rtl .a-100 {
    left: 100px;
    right: 100px;
}

.rtl .x-100,
.rtl .y-100 {
    right: 100px;
    left: 100px;
}

.rtl .r--5 {
    left: -5px;
}

@media (min-width: 480px) {
    .rtl .r-xs-auto {
        left: auto;
    }

    .rtl .l-xs-auto {
        right: auto;
    }
}

@media (min-width: 576px) {
    .rtl .r-sm-auto {
        left: auto;
    }

    .rtl .l-sm-auto {
        right: auto;
    }
}

@media (min-width: 768px) {
    .rtl .r-md-auto {
        left: auto;
    }

    .rtl .l-md-auto {
        right: auto;
    }
}

@media (min-width: 992px) {
    .rtl .r-lg-auto {
        left: auto;
    }

    .rtl .l-lg-auto {
        right: auto;
    }
}

@media (min-width: 1200px) {
    .rtl .r-xl-auto {
        left: auto;
    }

    .rtl .l-xl-auto {
        right: auto;
    }
}

.rtl .tx-right {
    text-align: left;
}

.rtl .tx-left {
    text-align: right;
}

.rtl .tx-right-f {
    text-align: right !important;
}

.rtl .tx-left-f {
    text-align: left !important;
}

@media (min-width: 480px) {
    .rtl .tx-xs-right {
        text-align: left;
    }

    .rtl .tx-xs-left {
        text-align: right;
    }

    .rtl .tx-xs-right-f {
        text-align: right !important;
    }

    .rtl .tx-xs-left-f {
        text-align: left !important;
    }
}

@media (min-width: 576px) {
    .rtl .tx-sm-right {
        text-align: left;
    }

    .rtl .tx-sm-left {
        text-align: right;
    }

    .rtl .tx-sm-right-f {
        text-align: right !important;
    }

    .rtl .tx-sm-left-f {
        text-align: left !important;
    }
}

@media (min-width: 768px) {
    .rtl .tx-md-right {
        text-align: left;
    }

    .rtl .tx-md-left {
        text-align: right;
    }

    .rtl .tx-md-right-f {
        text-align: right !important;
    }

    .rtl .tx-md-left-f {
        text-align: left !important;
    }
}

@media (min-width: 992px) {
    .rtl .tx-lg-right {
        text-align: left;
    }

    .rtl .tx-lg-left {
        text-align: right;
    }

    .rtl .tx-lg-right-f {
        text-align: right !important;
    }

    .rtl .tx-lg-left-f {
        text-align: left !important;
    }
}

@media (min-width: 1200px) {
    .rtl .tx-xl-right {
        text-align: left;
    }

    .rtl .tx-xl-left {
        text-align: right;
    }

    .rtl .tx-xl-right-f {
        text-align: right !important;
    }

    .rtl .tx-xl-left-f {
        text-align: left !important;
    }
}

.rtl .text-start {
    text-align: right !important;
}

.rtl .text-end {
    text-align: left !important;
}

@media (min-width: 576px) {
    .rtl .text-sm-left {
        text-align: left !important;
    }

    .rtl .text-sm-right {
        text-align: right !important;
    }
}

@media (min-width: 768px) {
    .rtl .text-md-left {
        text-align: left !important;
    }

    .rtl .text-md-right {
        text-align: right !important;
    }
}

@media (min-width: 992px) {
    .rtl .text-lg-left {
        text-align: left !important;
    }

    .rtl .text-lg-right {
        text-align: right !important;
    }
}

@media (min-width: 1200px) {
    .rtl .text-xl-left {
        text-align: left !important;
    }

    .rtl .text-xl-right {
        text-align: right !important;
    }
}

.rtl .app-sidebar .mCSB_draggerContainer {
    left: -11px !important;
}

@media (min-width: 768px) {
    .rtl .app.sidenav-toggled .app-content {
        margin-right: 80px;
    }

    .rtl .app.sidenav-toggled .app-sidebar {
        right: 0;
    }

    .rtl .side-header {
        left: 0;
        right: 0;
    }
}

@media (max-width: 767px) {
    .rtl .app .app-sidebar {
        right: -240px;
    }

    .rtl .app.sidenav-toggled .app-content {
        margin-right: 0;
    }

    .rtl .app.sidenav-toggled .app-sidebar {
        right: 0;
    }

    .rtl .app.sidebar-gone.sidenav-toggled .app-sidebar {
        right: 0;
    }

    .rtl .app.sidenav-toggled .side-menu .side-menu__icon {
        margin-left: 13px !important;
        margin-right: 0;
    }
}

.rtl .footer .container {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .rtl .app-content {
        margin-right: 240px;
        margin-left: inherit;
    }
}

.rtl .app-header {
    left: 0;
    right: 0;
    padding-left: 15px;
}

@media (min-width: 768px) {
    .rtl .app-header {
        padding-left: 30px;
    }
}

.rtl .app-search {
    margin-left: 15px;
}

.rtl .app-search__input {
    padding-left: 30px;
}

.rtl .app-search__button {
    left: 0;
}

.rtl .app-notification__icon {
    padding-left: 10px;
}

.rtl .app-sidebar {
    right: 0;
    left: inherit !important;
    border-left: 1px solid #ededf5;
    border-right: 0px;
    transition: right 320ms ease, width 320ms ease;
}

.rtl.dark-theme .card-pay .tabs-menu.nav li {
    border-left: 1px solid #404353;
}

.rtl.dark-theme .app-sidebar {
    border-left: 1px solid #404353;
    border-right: inherit;
}

.rtl.dark-theme .main-sidebar-header {
    border-left: 1px solid #404353;
}

@media (min-width: 992px) {
    .rtl.dark-theme .main-header-left .btn {
        border-left: 1px solid transparent !important;
    }
}

@media (max-width: 767px) {
    .rtl .app-sidebar__overlay {
        left: 0;
        right: 0;
    }
}

.rtl .app-sidebar__user-avatar {
    margin-left: 15px;
}

.rtl .side-menu .slide .side-menu__item {
    padding: 10px 30px 10px 91px;
}

.rtl .side-menu .slide .side-menu__item.active {
    border-left: 3px solid var(--primary);
    border-right: inherit;
}

@media (min-width: 768px) {
    .rtl .app.sidebar-mini.sidenav-toggled .profile-status {
        left: 12px;
    }

    .rtl .app.sidenav-toggled .side-menu__label {
        right: 0;
    }
}

.rtl .sub-slide .sub-angle {
    margin-right: auto;
    float: left;
}

.rtl .slide.is-expanded .sub-side-menu__item:before {
    right: 15px;
}

.rtl .slide.is-expanded .sub-slide-item2:before {
    right: 35px !important;
}

.rtl .side-menu .side-menu__icon {
    margin-left: 10px;
    margin-right: inherit;
}

.rtl .app-sidebar .slide .side-menu__item.active::before {
    right: 0;
}

.rtl .app-sidebar .side-item.side-item-category {
    padding: 0 25px 0 20px;
    text-align: right;
}

.rtl .slide-menu {
    padding-right: 29px;
    padding-left: inherit;
}

.rtl .slide-item {
    padding: 8px 35px 8px 43px;
}

.rtl .slide-item .icon {
    margin-left: 5px;
}

@media (min-width: 768px) {
    .rtl .sidenav-toggled .app-sidebar__user {
        padding: 8px 0px 8px 0;
    }

    .rtl .sidenav-toggled .profile-img {
        left: 19px;
    }

    .rtl .sidenav-toggled .app-content {
        margin-right: 0;
    }

    .rtl .sidenav-toggled .app-sidebar {
        right: 0;
    }

    .rtl .sidenav-toggled .side-menu__label {
        right: 50px;
        padding: 12px 20px 12px 5px;
        margin-right: -3px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .rtl .sidenav-toggled .slide-menu {
        right: 90px;
    }

    .rtl .app.sidenav-toggled .side-menu__item {
        padding: 11px 0 11px 0;
        border-right: 0;
    }

    .rtl .app.sidenav-toggled .side-menu__label {
        right: 0;
    }

    .rtl .app.sidenav-toggled .nav-badge {
        left: 28px;
    }
}

.rtl .app.sidenav-toggled .side-menu .side-menu__icon {
    margin-right: 0;
    margin-left: 0;
}

.rtl .dropdown-menu.dropdown-menu-right {
    right: auto;
}

.rtl .app-breadcrumb {
    text-align: left;
}

.rtl .sidenav-toggled .main-wrapper .side-menu .side-menu__item .nav-badge {
    right: 57px;
}

.rtl .side-header .header-brand-img.desktop-logo {
    margin-left: 0;
}

.rtl .sidenav-toggled .app-sidebar .side-header .header-brand-img.toggle-logo {
    margin-left: 0;
}

.rtl .app-sidebar .sidebar-navs {
    padding: 0 10px 10px 10px;
}

.rtl .sidebar-navs .nav li:last-child a {
    margin-left: 0 !important;
}

.rtl .side-menu h3:after {
    right: 21px;
}

.rtl .sub-slide-item {
    padding: 8px 25px 8px 0px !important;
}

.rtl .sub-slide-menu {
    padding-right: 0;
}

.rtl .sub-side-menu__item {
    padding-right: 40px !important;
    padding-left: 20px !important;
}

.rtl .sub-slide.is-expanded .sub-slide-item {
    padding-right: 42px !important;
}

.rtl .sub-slide-menu .sub-slide-item2 {
    padding: 6px 48px 6px 0px !important;
}

.rtl .sub-slide .sub-angle2 {
    margin-right: 68px;
}

.rtl .app-sidebar .slide-menu .sub-slide-menu a:before {
    right: 25px;
}

.rtl .app-sidebar .slide-menu .slide-item:before {
    right: 20px;
}

@media (min-width: 768px) {
    .rtl .app.sidebar-mini.sidenav-toggled-open .app-sidebar {
        right: 0;
    }

    .rtl .app.sidebar-mini.sidenav-toggled-open .side-menu .slide .side-menu__item {
        padding: 10px 22px 10px 20px;
        text-align: right;
    }

    .rtl .app.sidebar-mini.sidenav-toggled-open .side-menu .slide .side-menu__item.active {
        padding: 11px 15px 11px 15px !important;
    }

    .rtl .app.sidebar-mini.sidenav-toggled-open .side-menu .side-menu__icon {
        margin-left: 12px;
    }

    .rtl .app.sidebar-mini.sidenav-toggled-open .ps>.ps__rail-y>.ps__thumb-y {
        right: 3px;
    }

    .rtl .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .profile-status {
        left: 19px;
    }

    .rtl .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu {
        right: 0;
    }
}

@media (min-width: 1200px) {
    .rtl .main-sidebar-sticky+.main-content {
        margin-right: 240px;
    }
}

.rtl .sub-slide .sub-angle2 {
    margin-right: auto;
    float: left;
}

.rtl .side-menu .side-menu__icon:before {
    left: 3px;
}

.rtl .sidebar-mini.sidenav-toggled .side-menu .slide .side-menu__item.active {
    border-left: 0 !important;
}

.rtl .sidebar-mini .side-menu {
    margin-right: 0 !important;
}

@media (min-width: 768px) {
    .rtl .icontext-menu.app.sidenav-toggled .main-header {
        padding-right: 110px !important;
    }

    .rtl .icontext-menu.app.sidenav-toggled .app-content {
        margin-right: 110px;
    }

    .rtl .icontext-menu.sidebar-mini.sidenav-toggled .side-menu .slide .side-menu__item.active {
        padding: 0px 0px 11px 0px;
    }
}

@media (min-width: 768px) {
    .rtl .closed-menu.app.sidenav-toggled .app-content {
        margin-right: 0px !important;
    }

    .rtl .closed-menu.app.sidenav-toggled .main-header {
        padding-right: 0px !important;
    }
}

@media (min-width: 768px) {
    .rtl .hover-submenu .slide-item {
        padding: 8px 35px 8px 18px;
    }

    .rtl .hover-submenu .slide-menu {
        border-radius: 0px 0px 5px 5px;
    }

    .rtl .hover-submenu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
        right: 108px;
    }

    .rtl .hover-submenu.app.sidenav-toggled-open .slide-menu {
        padding-right: 0 !important;
        right: 110px;
    }

    .rtl .hover-submenu.app.sidenav-toggled-open .side-menu__label {
        right: 0;
    }

    .rtl .hover-submenu.app.sidenav-toggled-open .side-menu .slide .side-menu__item.active {
        border-left: 0 !important;
    }

    .rtl .hover-submenu.app.sidenav-toggled-open .side-menu__icon {
        margin-right: 0;
        margin-left: 0;
    }

    .rtl .hover-submenu.app.sidenav-toggled-open .side-menu .slide .side-menu__item {
        padding: 11px 0px 11px 0px !important;
    }

    .rtl .hover-submenu.app.sidenav-toggled .main-header {
        padding-right: 110px;
    }

    .rtl .hover-submenu.app.sidenav-toggled .app-content {
        margin-right: 110px;
    }

    .rtl .hover-submenu.app.sidenav-toggled .app-sidebar {
        right: 0;
    }

    .rtl .hover-submenu .side-header {
        left: 0;
        right: 0;
    }
}

@media (min-width: 768px) {
    .rtl .hover-submenu1 .slide-item {
        padding: 8px 35px 8px 18px;
    }

    .rtl .hover-submenu1.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
        right: 108px;
    }

    .rtl .hover-submenu1.app.sidenav-toggled-open .slide-menu {
        padding-right: 0 !important;
        right: 110px;
    }

    .rtl .hover-submenu1.app.sidenav-toggled-open .side-menu__label {
        right: 0;
    }

    .rtl .hover-submenu1.app.sidenav-toggled-open .side-menu .slide .side-menu__item.active {
        border-left: 0 !important;
    }

    .rtl .hover-submenu1.app.sidenav-toggled-open .side-menu__icon {
        margin-right: 0;
        margin-left: 0;
    }

    .rtl .hover-submenu1.app.sidenav-toggled-open .side-menu .slide .side-menu__item {
        padding: 11px 0px 11px 0px !important;
    }

    .rtl .hover-submenu1.app.sidenav-toggled .main-header {
        padding-right: 110px;
    }

    .rtl .hover-submenu1.app.sidenav-toggled .app-content {
        margin-right: 110px;
    }

    .rtl .hover-submenu1 .side-header {
        left: 0;
        right: 0;
    }
}

@media (min-width: 768px) {
    .rtl.app.sidenav-toggled .app-content {
        margin-right: 80px;
        margin-left: 0;
    }

    .rtl.app.sidenav-toggled .main-header {
        padding-right: 80px;
        padding-left: 0px;
    }
}

.rtl .example div.group-btn .row .btn-group>.btn:nth-child(n+3) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.rtl .example .group-btn .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: inherit !important;
    border-bottom-right-radius: inherit !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

body.rtl {
    text-align: right !important;
}

.rtl.dark-theme .select2-selection__rendered {
    float: right;
}

.rtl .main-body.app.sidebar-mini.rtl.dark-theme.light-header.light-menu .app-sidebar {
    border-left: 1px solid #ededf5;
}

.rtl .main-body.app.sidebar-mini.rtl.dark-theme.light-header.light-menu .main-sidebar-header {
    border-left: 1px solid #ededf5;
}

.rtl .main-sidebar-header {
    border-left: 1px solid #ededf5;
    border-right: inherit !important;
}

@media (min-width: 768px) {
    .rtl .horizontal .horizontal-main .sub-slide2 .sub-slide-menu1 {
        right: 180px;
    }
}

@media (max-width: 767px) {
    .rtl .table.table-bordered.dataTable {
        border-left-width: 2px !important;
    }
}

.rtl.transparent-theme .main-nav .nav-link+.nav-link {
    border-right: 1px solid rgba(255, 255, 255, 0.15);
    border-left: inherit;
}

.rtl .feature-1 .feature i {
    float: right;
}

@media (min-width: 768px) {
    .rtl.horizontal .horizontal-main .sub-slide2 .sub-slide-menu1 {
        right: 180px;
        left: auto;
    }
}

.rtl .featured_icon1 {
    margin-right: -34px !important;
}

.rtl.dark-theme .border-end {
    border-left: 1px solid #3c3c60 !important;
    border-right: inherit !important;
}

.rtl table.table-bordered.dataTable th:first-child,
.rtl table.table-bordered.dataTable th:first-child,
.rtl table.table-bordered.dataTable td:first-child,
.rtl table.table-bordered.dataTable td:first-child {
    border-right-width: 1px !important;
}

.rtl.dark-theme table.table-bordered.dataTable th:first-child,
.rtl.dark-theme table.table-bordered.dataTable th:first-child,
.rtl.dark-theme table.table-bordered.dataTable td:first-child,
.rtl.dark-theme table.table-bordered.dataTable td:first-child {
    border-right-width: 0px !important;
}

.rtl.transparent-theme table.table-bordered.dataTable th:first-child,
.rtl.transparent-theme table.table-bordered.dataTable th:first-child,
.rtl.transparent-theme table.table-bordered.dataTable td:first-child,
.rtl.transparent-theme table.table-bordered.dataTable td:first-child {
    border-right-width: 0px !important;
}

.rtl .icon.ion-ios-arrow-forward {
    transform: rotate(180deg);
}

.rtl .icon.ion-ios-arrow-back {
    transform: rotate(180deg);
}

.rtl .bootstrap-tagsinput .badge [data-role=remove] {
    margin-right: 8px;
    margin-left: inherit;
}

.rtl .tab_wrapper .content_wrapper .accordian_header.active .arrow {
    transform: rotate(-45deg);
}

.rtl .tab_wrapper .content_wrapper .accordian_header .arrow {
    transform: rotate(135deg);
}

.rtl .vtimeline .timeline-wrapper {
    padding-left: 90px;
    padding-right: inherit;
}

@media (max-width: 992px) {
    .rtl.dark-theme .vtimeline .timeline-wrapper .timeline-panel:after {
        border-left: 14px solid #353949 !important;
        border-right: 0 solid #2a2e3f !important;
    }

    .rtl.transparent-theme .vtimeline .timeline-wrapper .timeline-panel:after {
        border-left: 14px solid rgba(0, 0, 0, 0.2) !important;
        border-right: 0 solid #2a2e3f !important;
    }
}

.rtl div.table-responsive>div.dataTables_wrapper>div.row>div[class^=col-]:last-child {
    padding-left: 0;
    padding-right: inherit;
}

.rtl .file-image-1 .icons {
    left: 7px;
    right: inherit;
    text-align: left;
}

.rtl .main-signup-header .nav.panel-tabs {
    padding-right: 0;
}

.rtl ol,
.rtl ul {
    padding-right: 2rem;
    padding-left: inherit;
}

.rtl .smartphoto-dismiss {
    left: 10px;
    right: inherit;
}

.rtl .smartphoto-dismiss {
    left: 22px !important;
    right: inherit !important;
}

.rtl .dropify-wrapper.touch-fallback .dropify-preview .dropify-infos .dropify-infos-inner {
    padding: 5px 0px 5px 90px;
}

.rtl .dropify-wrapper.touch-fallback .dropify-preview .dropify-infos .dropify-infos-inner p {
    text-align: right;
}

.rtl .tree ul:before {
    right: -10px;
    left: inherit;
    border-right: 1px solid #ededf5;
    border-left: inherit;
}

.rtl .tree ul {
    margin-right: 1em;
    margin-left: inherit;
}

.rtl .tree ul li:before {
    right: -10px;
    left: inherit;
}

.rtl perfect-scrollbar>.ps.ps--active-y>.ps__rail-y {
    right: auto !important;
    left: 0 !important;
    top: 0 !important;
}

.rtl .MS-content {
    direction: rtl;
}

/* ######## LIB-STYLES ######## */
.fc .fc-button-primary {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
    box-shadow: 0 5px 10px rgba(108, 95, 252, 0.18);
}

.fc .fc-button-primary:hover {
    color: #fff;
    background-color: var(--primary-75);
    border-color: var(--primary);
}

.fc .fc-button-primary:disabled {
    /* not DRY */
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
    opacity: 0.5;
}

.fc-daygrid-event.fc-event {
    background: var(--primary);
    color: #fff !important;
}

.fc .fc-list-event-dot {
    display: inline-block;
    box-sizing: content-box;
    width: 0;
    height: 0;
    border: 5px solid var(--primary);
    border: calc(var(--fc-list-event-dot-width, 10px) / 2) solid var(--fc-event-border-color, var(--primary));
    border-radius: 5px;
    border-radius: calc(var(--fc-list-event-dot-width, 10px) / 2);
}

.fc-v-event {
    /* allowed to be top-level */
    display: block;
    border: 1px solid var(--primary);
    border: 1px solid var(--fc-event-border-color, var(--primary));
    background-color: var(--primary);
    background-color: var(--fc-event-bg-color, var(--primary));
}

.fc-daygrid-event-dot {
    /* the actual dot */
    display: none;
    margin: 4px;
    box-sizing: content-box;
    width: 0;
    height: 0;
    border: 4px solid var(--primary);
    border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid var(--fc-event-border-color, var(--primary));
    border-radius: 4px;
    border-radius: calc(var(--fc-daygrid-event-dot-width, 8px) / 2);
}

.fc-h-event {
    /* allowed to be top-level */
    display: block;
    border: 1px solid var(--primary);
    border: 1px solid var(--primary);
    background-color: var(--primary);
    background-color: var(--primary);
}

/* ###### 4.5 Morris ###### */
.morris-hover.morris-default-style {
    border-radius: 0;
}

/* ###### 5.2 Charts  ###### */
.main-donut-chart {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
}

.main-donut-chart .slice {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.main-donut-chart .chart-center {
    position: absolute;
    border-radius: 50%;
}

.main-donut-chart .chart-center span {
    display: block;
    text-align: center;
}

#ecom-chart {
    height: 200px !important;
}

.legend {
    width: 15px;
    height: 10px;
    display: block;
    border-radius: 2px;
    margin-right: 9px;
    margin-top: 6px;
}

.list-unstyled .legend-content {
    display: inline-block;
    vertical-align: top;
}

.list-unstyled li {
    display: flex;
    margin-bottom: 20px;
}

.dot-label {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    animation: ani 1s linear infinite;
    left: 9px;
    bottom: -12px;
    position: relative;
}

#summary-chart {
    height: 204px;
    width: 100%;
}

.summary.chart-legend {
    top: 169px;
}

.morris-wrapper-demo {
    height: 200px;
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
}

@media (min-width: 576px) {
    .morris-wrapper-demo {
        height: 300px;
    }
}

.morris-donut-wrapper-demo {
    height: 200px;
}

@media (min-width: 576px) {
    .morris-donut-wrapper-demo {
        height: 250px;
    }
}

.chartjs-wrapper-demo {
    height: 250px;
}

@media (max-width: 330px) {
    .chartjs-wrapper-demo {
        width: 290px;
    }
}

@media (min-width: 992px) {
    .chartjs-wrapper-demo {
        height: 300px;
    }
}

.chartist-wrapper-demo {
    height: 200px;
}

@media (min-width: 768px) {
    .chartist-wrapper-demo {
        height: 300px;
    }
}

.flotChart6 {
    width: 100%;
    height: 180px;
}

.chart-icons {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
}

.sales-flot {
    position: relative;
}

.sales-flot .flot-chart .flot-x-axis>div span:first-child {
    display: block;
    text-transform: uppercase;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 500;
    font-size: 10px;
    color: #7987a1;
    top: 10px;
    position: relative;
}

.sales-flot .flot-chart .flot-x-axis>div span:last-child {
    display: block;
    font-size: 16px;
    font-weight: 700;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #323251;
    line-height: 1;
    top: 15px;
    position: relative;
}

.sales-bar-chart {
    position: relative;
    width: auto;
    height: 160px;
}

.chart-legend {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    z-index: 10;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.chart-legend div {
    display: flex;
    align-items: center;
}

.chart-legend div+div {
    margin-left: 15px;
}

.chart-legend span {
    width: 10px;
    height: 4px;
    display: block;
    margin-right: 5px;
}

@media (min-width: 576px) {
    .chart-legend div+div {
        margin-left: 30px;
    }
}

@media (min-width: 576px) {
    .chart-legend span {
        width: 40px;
        margin-right: 10px;
    }
}

.chart-wrapper {
    position: relative;
    margin-left: -13px;
    margin-right: -13px;
}

@media (min-width: 576px) {
    .chart-wrapper {
        margin-left: -10px;
        margin-right: -15px;
    }
}

.card-dashboard-twelve .flot-chart {
    width: 100%;
    height: 200px;
    position: relative;
}

.card-dashboard-twelve .flot-chart .flot-x-axis>div span:first-child {
    display: block;
    text-transform: uppercase;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 500;
    font-size: 10px;
    color: #7987a1;
}

.card-dashboard-twelve .flot-chart .flot-x-axis>div span:last-child {
    display: block;
    font-size: 16px;
    font-weight: 700;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    line-height: 1;
}

@media (min-width: 768px) {
    .card-dashboard-twelve .flot-chart {
        height: 250px;
    }
}

@media (min-width: 992px) {
    .card-dashboard-twelve .flot-chart {
        height: 300px;
    }
}

/* ######## TEMP-STYLES ######## */
.card--calendar .card-title {
    color: #14112d;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.card--calendar .card-body {
    padding: 0;
}

.card--calendar .ui-datepicker-inline {
    border-width: 0;
    max-width: none;
    padding: 0;
    margin: 0;
}

.card--calendar .ui-datepicker .ui-datepicker-header {
    border-bottom: 1px solid #ededf5;
    padding: 10px;
    margin-bottom: 5px;
}

.card--calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next::before,
.card--calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-prev::before {
    top: 8px;
}

.card--calendar .ui-datepicker .ui-datepicker-month {
    color: #134cf7;
}

.card--calendar .ui-datepicker .ui-datepicker-calendar {
    width: calc(100% - 20px);
}

.card--calendar .ui-datepicker .ui-datepicker-calendar th {
    font-size: 10px;
    text-align: center;
}

.card--calendar .ui-datepicker .ui-datepicker-calendar td {
    text-align: center;
}

.card--calendar .ui-datepicker .ui-datepicker-calendar td a {
    display: block;
    width: 34px;
    height: 34px;
    font-size: 12px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
}

.card--calendar .ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a {
    background-color: transparent;
    border: 1px solid #134cf7;
    color: #fff;
    background: #134cf7;
    font-weight: 700;
}

.card--calendar .card--events .list-group-item {
    padding: 13px 20px 13px 40px;
}

@media (min-width: 576px) {

    .card--calendar .ui-datepicker .ui-datepicker-calendar,
    .card--twelve .flot-chart {
        width: 100%;
    }
}

.card--events {
    border-color: #e3e3e3;
    box-shadow: 5px 7px 26px -5px #d5d4e0;
}

.card--events .card-header {
    padding: 20px;
    background-color: transparent;
}

.card--calendar .card--events {
    margin-left: -20px;
    margin-right: -20px;
}

.card--calendar .card--events .list-group-item {
    padding: 13px 20px 13px 40px;
}

.card--events .card-title {
    color: #a5a0b1;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px;
}

.card--events .card-subtitle {
    color: #14112d;
    font-weight: 400;
    font-size: 18px;
}

.card--events .card-body {
    padding: 0;
}

.card--events .list-group-item {
    padding: 10px 18px 10px 40px;
    border-left-width: 0;
    border-right-width: 0;
    position: relative;
}

.card--events .list-group-item:first-child,
.card--events .list-group-item:last-child {
    border-radius: 0;
}

.card--events .list-group-item label {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    color: #37326d;
    margin-bottom: 8px;
    letter-spacing: 0.5px;
}

.card--events .list-group-item label span {
    color: #a5a0b1;
    font-weight: 500;
}

.card--events .list-group-item h6 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}

.card--events .list-group-item p {
    margin-bottom: 5px;
}

.card--events .list-group-item p strong {
    font-weight: 500;
}

.card--events .list-group-item small {
    font-size: 11px;
}

.card--events .list-group-item small span {
    font-weight: 700;
    text-transform: uppercase;
}

.card--events .event-indicator {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 17px;
    left: 20px;
    border-radius: 100%;
}

.card--events .event-user {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.card--events .event-user .main-img-user {
    width: 28px;
    height: 28px;
}

.card--events .event-user .main-img-user::after {
    display: none;
}

.card--events .event-user .main-img-user+.main-img-user {
    margin-left: -6px;
}

.card--events .event-user a:last-child {
    margin-left: 10px;
    color: #a5a0b1;
    font-weight: 500;
    font-size: 11px;
}

.card--events .event-user a:last-child:hover,
.card--events .event-user a:last-child:focus {
    color: #37326d;
}

@media (max-width: 320px) {
    .ui-datepicker .ui-datepicker-calendar th {
        padding: 4px 0;
        letter-spacing: normal;
    }
}

.ui-datepicker .ui-datepicker-calendar td {
    border: 1px solid #fff;
    padding: 0;
    background-color: #fff;
    text-align: center;
}

.ui-datepicker .ui-datepicker-calendar td:last-child {
    border-right: 0;
}

.ui-datepicker .ui-datepicker-calendar td.ui-datepicker-other-month .ui-state-default {
    color: #7987a1;
}

.ui-datepicker .ui-datepicker-calendar td span {
    transition: all 0.2s ease-in-out;
    padding: 5px;
    color: #4a4a69;
    padding: 6px 10px;
    display: block;
    font-weight: 400;
    font-size: 12px;
    border: 0;
    border-radius: 5px;
}

.ui-datepicker .ui-datepicker-calendar td a {
    transition: all 0.2s ease-in-out;
    padding: 5px;
    color: #4a4a69;
    padding: 10px 13px;
    display: block;
    font-weight: 400;
    font-size: 12px;
    border: 0;
    border-radius: 5px;
}

.ui-datepicker .ui-datepicker-calendar td a:hover {
    background-color: #fff;
    color: #4a4a69;
}

.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a {
    background-color: var(--primary);
    color: #fff;
}

.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a:hover,
.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a:focus {
    background-color: var(--primary);
    color: #fff;
}

@media (prefers-reduced-motion: reduce) {

    .ui-datepicker .ui-datepicker-calendar td span,
    .ui-datepicker .ui-datepicker-calendar td a {
        transition: none;
    }
}

.ui-datepicker-multi .ui-datepicker-group {
    padding-right: 15px;
    width: auto;
    float: left;
}

.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-title {
    margin: auto;
}

.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-prev::before {
    left: 10px;
}

.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-next::before {
    right: 10px;
}

.ui-datepicker-multi .ui-datepicker-group table {
    margin: 0;
}

.ui-datepicker-multi .ui-datepicker-group-last {
    padding-right: 0;
}

.ui-datepicker-inline {
    border-color: #e4e8f3;
}

@media (min-width: 576px) {
    .ui-datepicker-inline {
        max-width: 310px;
    }
}

/* ###### 7.2 Calendar  ###### */
@media (min-width: 992px) {
    .main-content-calendar {
        padding: 25px 0;
    }
}

.main-content-calendar .container,
.main-content-calendar .container-fluid {
    padding: 20px 0 0;
}

@media (min-width: 992px) {

    .main-content-calendar .container,
    .main-content-calendar .container-fluid {
        padding-top: 0;
    }
}

@media (min-width: 576px) {
    .main-content-left-calendar {
        width: 300px;
    }
}

@media (min-width: 992px) {
    .main-content-left-calendar {
        width: 230px;
    }
}

.main-datepicker .ui-datepicker-inline {
    margin-top: 0;
}

.main-datepicker .ui-datepicker .ui-datepicker-title {
    color: var(--primary);
    letter-spacing: -0.2px;
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar {
    width: 100%;
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar th {
    padding: 5px;
    font-size: 10px;
    color: #323251;
    text-align: center;
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar td {
    text-align: center;
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar th.ui-datepicker-week-end:first-child:not(.ui-datepicker-other-month) a {
    color: #f34343;
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar td {
    padding: 0 1px;
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-week-end:first-child:not(.ui-datepicker-other-month) a {
    color: #f34343;
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar td span,
.main-datepicker .ui-datepicker .ui-datepicker-calendar td a {
    padding: 4px 5px;
    font-family: Verdana, sans-serif;
    font-size: 11px;
    color: #4d5875;
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a {
    background-color: var(--primary);
    color: #fff;
    border-radius: 5px;
}

.main-nav-calendar-event .nav-link {
    font-size: 13px;
    position: relative;
}

.main-nav-calendar-event .nav-link i {
    font-size: 21px;
    position: relative;
    margin-right: 10px;
    top: -0.5px;
}

.main-nav-calendar-event .nav-link.exclude {
    color: #7987a1;
}

.main-nav-calendar-event .nav-link.exclude i {
    color: #7987a1;
}

.main-nav-calendar-event .nav-link.exclude::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-top: 1px solid #7987a1;
}

@media (min-width: 992px) {
    .main-content-body-calendar {
        padding-left: 25px;
    }
}

.main-calendar {
    border-color: #e4e8f3;
}

.main-calendar .fc-content,
.main-calendar .fc-divider,
.main-calendar .fc-list-heading td,
.main-calendar .fc-list-view,
.main-calendar .fc-popover,
.main-calendar .fc-row,
.main-calendar tbody,
.main-calendar td,
.main-calendar th,
.main-calendar thead {
    border-color: #e4e8f3;
}

.main-calendar .fc-header-toolbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 0 20px;
}

.main-calendar .fc-header-toolbar h2 {
    margin-bottom: 0;
    color: #323251;
    font-size: 20px;
    font-weight: 700;
}

.main-calendar .fc-header-toolbar .fc-right {
    order: 3;
}

.main-calendar .fc-header-toolbar .fc-clear {
    display: none;
}

.main-calendar .fc-header-toolbar button {
    height: 38px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ededf5;
    box-shadow: none;
    padding: 0 15px;
    display: flex;
    align-items: center;
    color: #323251;
    font-size: 13px;
    text-transform: capitalize;
    outline: none;
    text-shadow: none;
    border-radius: 0;
}

.main-calendar .fc-header-toolbar button:hover,
.main-calendar .fc-header-toolbar button:focus {
    background-color: #f0f0f8;
}

.main-calendar .fc-header-toolbar button.fc-prev-button,
.main-calendar .fc-header-toolbar button.fc-next-button {
    padding: 0;
    width: 38px;
    justify-content: center;
}

.main-calendar .fc-header-toolbar button.fc-prev-button span,
.main-calendar .fc-header-toolbar button.fc-next-button span {
    margin: 0;
}

.main-calendar .fc-header-toolbar button.fc-state-active {
    background-color: #f0f0f8;
}

.main-calendar .fc-header-toolbar button.fc-today-button {
    color: #fff;
    background-color: var(--primary);
    border-color: #1a53ff;
}

.main-calendar .fc-header-toolbar button.fc-today-button.fc-state-disabled {
    border-color: #e4e8f3;
    background-color: #f9f9fb;
    color: #949eb7;
    cursor: default;
}

.main-calendar .fc-icon-left-single-arrow,
.main-calendar .fc-icon-right-single-arrow {
    top: 0;
}

.main-calendar .fc-icon-left-single-arrow::after,
.main-calendar .fc-icon-right-single-arrow::after {
    font-family: "Ionicons";
    font-size: 18px;
    font-weight: 400;
    line-height: 1;
}

.main-calendar .fc-icon-left-single-arrow::after {
    content: "\f3cf";
}

.main-calendar .fc-icon-right-single-arrow::after {
    content: "\f3d1";
}

.main-calendar .fc-head-container .fc-day-header {
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 14px 0;
    color: #323251;
}

.main-calendar .fc-view>table {
    background-color: #fff;
}

.main-calendar .fc-view>table>thead th,
.main-calendar .fc-view>table>thead td {
    border-color: #e4e8f3;
}

.main-calendar .fc-view>table>tbody>tr>td {
    border-color: #e4e8f3;
}

.main-calendar .fc-view .fc-other-month {
    background-color: #eff3fd;
}

.main-calendar .fc-view .fc-day-number {
    font-size: 14px;
    font-weight: 400;
    font-family: Arial, sans-serif;
    color: #4d5875;
    display: inline-block;
    padding: 5px 8px;
    position: relative;
    transition: all 0.2s ease-in-out;
    margin: 2px 2px 0 0;
    min-width: 20px;
    text-align: center;
}

.main-calendar .fc-view .fc-day-number:hover,
.main-calendar .fc-view .fc-day-number:focus {
    color: #323251;
    background-color: #f9f9fb;
}

.main-calendar .fc-view .fc-scroller {
    height: auto !important;
    overflow: visible !important;
}

.main-calendar .fc-view.fc-agenda-view .fc-day-header>a>span {
    display: block;
}

.main-calendar .fc-view.fc-agenda-view .fc-day-header>a>span:first-child {
    color: #7987a1;
    font-size: 8px;
    font-weight: 400;
    line-height: 1.2;
}

.main-calendar .fc-view.fc-agenda-view .fc-day-header>a>span:last-child {
    font-weight: 700;
    font-size: 14px;
    font-family: Helvetica, Arial, sans-serif;
    line-height: 1;
}

.main-calendar .fc-view.fc-agenda-view .fc-day-header.fc-today>a {
    color: var(--primary);
}

.main-calendar .fc-view.fc-listMonth-view,
.main-calendar .fc-view.fc-listWeek-view {
    border-width: 0;
}

.main-calendar .fc-view.fc-listMonth-view .fc-scroller,
.main-calendar .fc-view.fc-listWeek-view .fc-scroller {
    padding: 0 20px 20px;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-table,
.main-calendar .fc-view.fc-listWeek-view .fc-list-table {
    border-collapse: separate;
    display: block;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-table>tbody,
.main-calendar .fc-view.fc-listWeek-view .fc-list-table>tbody {
    display: flex;
    flex-wrap: wrap;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-heading,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading {
    flex: 0 0 20%;
    max-width: 20%;
    margin-top: 15px;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-heading:first-child,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading:first-child,
.main-calendar .fc-view.fc-listMonth-view .fc-list-heading:first-child+.fc-list-item,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading:first-child+.fc-list-item {
    margin-top: 0;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-heading td,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading td {
    background-color: transparent;
    border-width: 0;
    padding: 0;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-heading-main,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading-main {
    display: block;
    font-size: 11px;
    text-transform: uppercase;
    color: #7987a1;
    font-weight: 600;
    padding: 5px;
    text-align: center;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-heading-main span:last-child,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading-main span:last-child {
    display: block;
    font-size: 30px;
    font-weight: 700;
    font-family: Helvetica, Arial, sans-serif;
    letter-spacing: -1px;
    color: #323251;
    line-height: 1;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-heading-main.now,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading-main.now,
.main-calendar .fc-view.fc-listMonth-view .fc-list-heading-main.now span:last-child,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading-main.now span:last-child {
    color: var(--primary);
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item {
    flex: 0 0 calc(80% - 5px);
    max-width: calc(80% - 5px);
    display: flex;
    flex-direction: column;
    border-left: 4px solid transparent;
    background-color: #fff;
    margin-top: 15px;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item:hover,
.main-calendar .fc-view.fc-listMonth-view .fc-list-item:focus {
    cursor: pointer;
}

.main-calendar .fc-view.fc-listWeek-view .fc-list-item:hover,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item:focus {
    cursor: pointer;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item:hover td,
.main-calendar .fc-view.fc-listMonth-view .fc-list-item:focus td {
    background-color: transparent;
}

.main-calendar .fc-view.fc-listWeek-view .fc-list-item:hover td,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item:focus td {
    background-color: transparent;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item>td,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item>td {
    border-width: 0;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item>td.fc-list-item-time,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item>td.fc-list-item-time {
    border-top: 1px solid #f0f0f8;
    border-right: 1px solid #f0f0f8;
    box-sizing: border-box;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item>td.fc-list-item-title,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item>td.fc-list-item-title {
    border-bottom: 1px solid #f0f0f8;
    border-right: 1px solid #f0f0f8;
    box-sizing: border-box;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
    margin-left: 20%;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item-marker,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item-marker {
    display: none;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item-time,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item-time {
    padding: 12px 15px 2px;
    font-size: 13px;
    text-transform: capitalize;
    width: 100%;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item-title,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item-title {
    padding: 0 15px 12px;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item-title a,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item-title a {
    display: block;
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 5px;
    color: #323251;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item-title .fc-desc,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item-title .fc-desc {
    font-size: 13px;
    line-height: 1.375;
    display: block;
}

.main-calendar td.fc-today {
    background-color: var(--primary);
}

.main-calendar td.fc-today .fc-day-number {
    background-color: var(--primary);
    color: #fff;
    border-radius: 1px;
}

.main-calendar td.fc-today .fc-day-number:hover,
.main-calendar td.fc-today .fc-day-number:focus {
    background-color: var(--primary);
    color: #fff;
    border-radius: 1px;
}

.main-calendar .fc-axis {
    font-size: 11px;
}

.main-calendar .fc-event {
    border-width: 0;
    border-top-width: 5px;
    border-radius: 0;
}

.main-calendar .fc-event.fc-day-grid-event>div {
    display: none;
}

.main-calendar .fc-event.fc-day-grid-event>div .fc-time {
    font-weight: 500;
}

.main-calendar .fc-event.fc-day-grid-event>div .fc-desc {
    display: none;
}

.main-calendar .fc-time-grid-event {
    padding: 5px;
    border-left-width: 0;
    border-top-width: 2px;
}

.main-calendar .fc-time-grid-event .fc-time {
    font-size: 12px;
}

.main-calendar .fc-time-grid-event .fc-title {
    font-size: 14px;
    font-weight: 700;
    color: #323251;
    margin-bottom: 10px;
}

.main-calendar .fc-time-grid-event .fc-desc {
    font-size: 13px;
    line-height: 1.3;
    color: rgba(28, 39, 60, 0.8);
}

.main-calendar .fc-agendaWeek-view .fc-time-grid-event>div {
    display: none;
}

.main-calendar .fc-agendaWeek-view .fc-time-grid-event>div .fc-time {
    font-size: 11px;
}

.main-calendar .fc-agendaWeek-view .fc-time-grid-event>div .fc-title {
    font-size: 13px;
}

.main-calendar .fc-agendaWeek-view .fc-time-grid-event>div .fc-desc {
    display: none;
}

.main-calendar .fc-list-empty {
    padding: 20px 0;
    background-color: #fff;
    border: 1px solid #ededf5;
}

@media (min-width: 992px) {
    .main-calendar .fc-header-toolbar {
        padding: 0;
        margin-bottom: 24px;
    }
}

@media (min-width: 576px) {
    .main-calendar .fc-header-toolbar h2 {
        font-size: 22px;
    }
}

@media (max-width: 767.98px) {
    .main-calendar .fc-header-toolbar .fc-center {
        order: 1;
        flex: 0 0 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }

    .main-calendar .fc-header-toolbar .fc-left {
        order: 2;
        flex: 0 0 50%;
    }

    .main-calendar .fc-header-toolbar .fc-right {
        flex: 0 0 50%;
        display: flex;
        justify-content: flex-end;
    }
}

@media (max-width: 575px) {

    .main-calendar .fc-header-toolbar button.fc-prev-button,
    .main-calendar .fc-header-toolbar button.fc-next-button {
        width: 32px;
        height: 32px;
    }

    .main-calendar .fc-header-toolbar button.fc-today-button {
        height: 32px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 11px;
    }

    .main-calendar .fc-header-toolbar button.fc-month-button,
    .main-calendar .fc-header-toolbar button.fc-agendaWeek-button,
    .main-calendar .fc-header-toolbar button.fc-agendaDay-button,
    .main-calendar .fc-header-toolbar button.fc-listWeek-button,
    .main-calendar .fc-header-toolbar button.fc-listMonth-button {
        text-indent: -9999px;
        width: 32px;
        height: 32px;
        position: relative;
        color: transparent;
    }

    .main-calendar .fc-header-toolbar button.fc-month-button::before,
    .main-calendar .fc-header-toolbar button.fc-agendaWeek-button::before,
    .main-calendar .fc-header-toolbar button.fc-agendaDay-button::before,
    .main-calendar .fc-header-toolbar button.fc-listWeek-button::before,
    .main-calendar .fc-header-toolbar button.fc-listMonth-button::before {
        position: absolute;
        top: 6px;
        left: 10px;
        z-index: 100;
        display: inline-block;
        text-indent: 0;
        font-size: 12px;
        font-weight: 700;
        color: #4a4a69;
    }

    .main-calendar .fc-header-toolbar button.fc-month-button::before {
        content: "M";
    }

    .main-calendar .fc-header-toolbar button.fc-agendaWeek-button::before {
        content: "W";
    }

    .main-calendar .fc-header-toolbar button.fc-agendaDay-button::before {
        content: "D";
    }

    .main-calendar .fc-header-toolbar button.fc-listWeek-button::before,
    .main-calendar .fc-header-toolbar button.fc-listMonth-button::before {
        content: "L";
        left: 11px;
    }
}

@media (min-width: 992px) {

    .main-calendar .fc-icon-left-single-arrow::after,
    .main-calendar .fc-icon-right-single-arrow::after {
        line-height: 0.75;
    }
}

@media (min-width: 576px) {
    .main-calendar .fc-head-container .fc-day-header {
        font-size: 12px;
    }
}

@media (max-width: 991.98px) {

    .main-calendar .fc-view>table>thead th:first-child,
    .main-calendar .fc-view>table>thead td:first-child {
        border-left-width: 0;
    }

    .main-calendar .fc-view>table>thead th:last-child,
    .main-calendar .fc-view>table>thead td:last-child {
        border-right-width: 0;
    }
}

@media (max-width: 991.98px) {
    .main-calendar .fc-view>table>tbody>tr>td:first-child {
        border-left-width: 0;
    }

    .main-calendar .fc-view>table>tbody>tr>td:last-child {
        border-right-width: 0;
    }
}

@media (prefers-reduced-motion: reduce) {
    .main-calendar .fc-view .fc-day-number {
        transition: none;
    }
}

@media (min-width: 576px) {
    .main-calendar .fc-view.fc-agenda-view .fc-day-header>a>span:first-child {
        font-size: 11px;
        font-weight: 600;
    }
}

@media (min-width: 576px) {
    .main-calendar .fc-view.fc-agenda-view .fc-day-header>a>span:last-child {
        font-size: 25px;
    }
}

@media (min-width: 992px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-scroller,
    .main-calendar .fc-view.fc-listWeek-view .fc-scroller {
        padding: 0;
    }
}

@media (min-width: 576px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-heading,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-heading {
        flex: 0 0 15%;
        max-width: 15%;
    }
}

@media (min-width: 768px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-heading,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-heading {
        flex: 0 0 12%;
        max-width: 12%;
    }
}

@media (min-width: 992px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-heading,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-heading {
        flex: 0 0 10%;
        max-width: 10%;
    }
}

@media (min-width: 1200px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-heading,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-heading {
        flex: 0 0 8%;
        max-width: 8%;
    }
}

@media (min-width: 576px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item {
        flex: 0 0 calc(85% - 5px);
        max-width: calc(85% - 5px);
    }
}

@media (min-width: 768px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item {
        flex: 0 0 calc(88% - 5px);
        max-width: calc(88% - 5px);
    }
}

@media (min-width: 992px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item {
        flex: 0 0 calc(90% - 5px);
        max-width: calc(90% - 5px);
    }
}

@media (min-width: 1200px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item {
        flex: 0 0 calc(92% - 5px);
        max-width: calc(92% - 5px);
    }
}

@media (min-width: 576px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
        margin-left: 15%;
    }
}

@media (min-width: 768px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
        margin-left: 12%;
    }
}

@media (min-width: 992px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
        margin-left: 10%;
    }
}

@media (min-width: 1200px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
        margin-left: 8%;
    }
}

@media (min-width: 576px) {
    .main-calendar .fc-axis {
        font-size: 13px;
    }
}

@media (min-width: 576px) {
    .main-calendar .fc-event {
        border-top-width: 0;
        border-left-width: 2px;
        padding: 3px 4px;
    }
}

@media (min-width: 576px) {
    .main-calendar .fc-event.fc-day-grid-event>div {
        display: block;
    }
}

@media (min-width: 576px) {
    .main-calendar .fc-agendaWeek-view .fc-time-grid-event>div {
        display: block;
    }
}

@media (min-width: 992px) {
    .main-calendar .fc-agendaWeek-view .fc-time-grid-event>div .fc-desc {
        display: block;
    }
}

.main-modal-calendar-schedule .modal-content {
    border-width: 0;
    position: relative;
}

.main-modal-calendar-schedule .modal-content>.close {
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 28px;
    font-weight: 700;
    color: #7987a1;
    text-shadow: none;
}

.main-modal-calendar-schedule .modal-header {
    padding: 20px 25px;
}

.main-modal-calendar-schedule .modal-body {
    padding: 30px 25px;
}

.main-modal-calendar-schedule .form-group-date {
    display: flex;
    align-items: center;
    border: 1px solid #ededf5;
    background-color: #f9f9fb;
    padding: 10px 5px;
    color: #323251;
    font-size: 13px;
    font-weight: 600;
}

.main-modal-calendar-schedule .form-group-date>i {
    font-size: 32px;
    line-height: 0;
    margin-right: 10px;
    opacity: 0.5;
}

.main-modal-calendar-schedule .select2-container {
    width: 100% !important;
}

.main-modal-calendar-event .modal-content {
    border-width: 0;
    position: relative;
    background-color: transparent;
}

.main-modal-calendar-event .modal-content>.close {
    font-size: 18px;
    text-shadow: none;
}

.main-modal-calendar-event .modal-header {
    position: relative;
    padding: 20px;
    border-bottom-width: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.main-modal-calendar-event .nav-modal-event {
    align-items: center;
}

.main-modal-calendar-event .nav-modal-event .nav-link {
    padding: 0;
    color: rgba(255, 255, 255, 0.75);
    font-size: 21px;
    line-height: 0;
    position: relative;
    transition: all 0.2s ease-in-out;
}

.main-modal-calendar-event .nav-modal-event .nav-link:hover,
.main-modal-calendar-event .nav-modal-event .nav-link:focus {
    color: #fff;
}

.main-modal-calendar-event .nav-modal-event .nav-link+.nav-link {
    margin-left: 15px;
}

.main-modal-calendar-event .nav-modal-event .nav-link:last-child {
    font-size: 24px;
}

.main-modal-calendar-event .event-title {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 1;
}

.main-modal-calendar-event .modal-body {
    padding: 20px;
    background-color: #fff;
}

.main-modal-calendar-event .event-start-date,
.main-modal-calendar-event .event-end-date {
    color: #323251;
    font-weight: 500;
}

.main-modal-calendar-event .event-desc {
    margin-top: 5px;
}

.main-modal-calendar-event .event-desc:empty {
    margin-bottom: 5px;
}

@media (min-width: 576px) {
    .main-modal-calendar-event .modal-header {
        padding: 20px 25px;
    }
}

@media (prefers-reduced-motion: reduce) {
    .main-modal-calendar-event .nav-modal-event .nav-link {
        transition: none;
    }
}

@media (min-width: 576px) {
    .main-modal-calendar-event .modal-body {
        padding: 30px;
    }
}

@media (max-width: 605px) {
    .main-calendar .fc-header-toolbar {
        padding: 0;
    }

    .fc-view {
        border: 1px solid #ededf5;
    }

    .fc-view>table {
        border: 1px solid #ededf5;
    }

    .card--calendar .ui-datepicker .ui-datepicker-calendar td a {
        width: 28px;
        height: 28px;
    }

    .main-content-body.main-content-body-calendar.card.p-4 {
        padding: 1rem !important;
    }
}

@media (max-width: 320px) {
    .main-calendar .fc-header-toolbar .fc-right {
        flex: 0 0 100%;
        display: flex;
        justify-content: left;
        margin-top: 20px;
    }

    .main-calendar .fc-header-toolbar .fc-left {
        flex: 0 0 100%;
        display: flex;
        justify-content: left;
    }
}

.fc-datepicker.main-datepicker.hasDatepicker {
    border: 1px solid #ededf5;
}

@media (min-width: 320px) and (max-width: 568px) {
    .ui-datepicker-calendar {
        width: 100%;
    }

    .ui-datepicker .ui-datepicker-calendar td,
    .ui-datepicker .ui-datepicker-calendar th {
        text-align: center;
    }
}

/* ###### 7.3 Chat  ###### */
@media (max-width: 991.98px) {
    .main-content-body-show .main-header-menu-icon {
        display: none;
    }

    .main-content-body-show .main-header-left .main-header-arrow {
        display: block;
    }

    .main-content-body-show .main-content-left-chat {
        display: none;
    }

    .main-content-body-show .main-content-body-chat {
        display: flex;
    }
}

.main-nav-line-chat {
    border-bottom: 1px solid #ededf5;
    padding-bottom: 15px;
    padding-top: 15px;
    flex-shrink: 0;
    flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;
}

.main-nav-line-chat .nav-link {
    font-size: 12px;
    font-weight: 500;
    color: #383853;
    text-transform: uppercase;
    padding: 8px 20px;
    border: 1px solid #ededf5;
    border-radius: 5px;
    margin-bottom: 5px;
}

.main-nav-line-chat .nav-link:hover,
.main-nav-line-chat .nav-link:focus,
.main-nav-line-chat .nav-link:active {
    outline: none;
}

.main-nav-line-chat .nav-link+.nav-link {
    margin-top: 0;
    margin-left: 5px;
}

.main-nav-line-chat .nav-link.active {
    color: #fff;
    background: var(--primary);
}

@media (min-width: 992px) {
    .main-nav-line-chat {
        padding-left: 0;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 15px 20px;
        border-radius: 5px;
        border-bottom: 1px solid #ededf5;
    }
}

.main-chat-contacts-wrapper .main-img-user::after,
.main-chat-list .main-img-user::after,
.main-chat-header .main-img-user::after,
.main-chat-body .main-img-user::after {
    right: 1px;
    bottom: 3px;
}

.main-chat-contacts-wrapper {
    padding: 10px 20px;
    flex-shrink: 0;
    border-bottom: 1px solid #ededf5;
    overflow: hidden;
}

.main-chat-contacts-wrapper .main-content-label,
.main-chat-contacts-wrapper .card-table-two .card-title {
    margin-bottom: 15px;
    line-height: 1;
    padding-left: 20px;
}

.card-table-two {
    padding: 20px;
}

.card-table-two .card-title {
    font-size: 13px;
}

@media (min-width: 992px) {
    .main-chat-contacts-wrapper {
        padding: 10px 15px 10px 0;
    }
}

.card-table-two .main-chat-contacts-wrapper .card-title,
.main-chat-contacts-wrapper .card-dashboard-eight .card-title,
.card-dashboard-eight .main-chat-contacts-wrapper .card-title {
    margin-bottom: 15px;
    line-height: 1;
    padding-left: 20px;
}

.main-chat-contacts-wrapper .lSSlideOuter,
.main-chat-contacts-wrapper .lSSlideWrapper {
    overflow: visible;
}

.main-chat-contacts {
    padding-left: 20px;
    display: flex;
}

.main-chat-contacts .lslide {
    display: flex;
    flex-direction: column;
    align-items: center;
    float: none;
}

.main-chat-contacts small {
    font-size: 11px;
    width: 36px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 3px;
    text-align: center;
}

.main-chat-contacts-more {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: var(--primary);
    font-size: 12px;
    font-weight: 500;
    color: #fff;
}

@media (min-width: 992px) {
    .main-chat-list {
        max-height: 100%;
        position: relative;
    }
}

.main-chat-list .media {
    padding: 12px 20px;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #ededf5;
    position: relative;
}

.main-chat-list .media .main-img-user {
    flex-shrink: 0;
    top: 3px;
}

.main-chat-list .media::after {
    content: "";
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    width: 1px;
    background-color: var(--primary);
    display: none;
}

.main-chat-list .media+.media::before {
    content: "";
    position: absolute;
    top: -1px;
    left: 65px;
    right: 0;
}

.main-chat-list .media.new {
    background-color: #fff;
}

.main-chat-list .media.new .main-img-user span {
    display: flex;
}

.main-chat-list .media.new .media-contact-name span:first-child {
    font-weight: 500;
    color: #323251;
}

.main-chat-list .media.new .media-body p {
    color: #4a4a69;
}

.main-chat-list .media:hover,
.main-chat-list .media:focus {
    cursor: pointer;
    background-color: var(--primary02);
    border-top-color: #ededf5;
    border-bottom-color: #ededf5;
    z-index: 1;
}

.main-chat-list .media:hover:first-child,
.main-chat-list .media:focus:first-child {
    border-top-color: transparent;
}

.main-chat-list .media.selected {
    background-color: var(--primary02);
    border-top-color: #ededf5;
    border-bottom-color: #ededf5;
    border-left: 1px solid var(--primary);
    z-index: 0;
}

.main-chat-list .media.selected:first-child {
    border-top-color: transparent;
}

.main-chat-list .media.selected::after {
    display: block;
}

.main-chat-list .media.selected .media-contact-name span:first-child {
    color: #323251;
}

.main-chat-list .media.selected .media-body p {
    color: #4a4a69;
}

.main-chat-list .main-img-user span {
    position: absolute;
    top: 0;
    left: -2px;
    width: 14px;
    height: 14px;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    font-size: 9px;
    font-weight: 500;
    color: #fff;
    background-color: #f34343;
    box-shadow: 0 0 0 2px #fff;
}

.main-chat-list .media-body {
    margin-left: 15px;
}

.main-chat-list .media-body p {
    font-size: 12px;
    margin-bottom: 0;
    color: #7987a1;
    line-height: 1.35;
}

.main-chat-list .media-contact-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;
}

.main-chat-list .media-contact-name span:first-child {
    display: block;
    font-size: 13px;
    font-weight: 500;
    color: #323251;
}

.main-chat-list .media-contact-name span:last-child {
    display: block;
    font-size: 11px;
    color: #949eb7;
}

@media (min-width: 992px) {
    .main-chat-list .media {
        padding: 20px 15px;
    }
}

.main-content-body-chat {
    flex-direction: column;
    display: none;
}

@media (min-width: 992px) {
    .main-content-body-chat {
        display: flex;
    }
}

.main-chat-header {
    flex-shrink: 0;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    border-bottom: 1px solid #ededf5;
}

.main-chat-header .nav {
    margin-left: auto;
    align-items: center;
}

.main-chat-header .nav-link {
    color: #7987a1;
    font-size: 15px;
    line-height: 1;
    display: none;
    border: solid #ededf5 1px;
    border-radius: 5px;
    padding: 9px 9px;
    background: #fff;
}

.main-chat-header .nav-link:first-child {
    display: block;
}

.main-chat-header .nav-link i {
    line-height: 0;
}

.main-chat-header .nav-link .typcn {
    line-height: 0.9;
}

.main-chat-header .nav-link .typcn::before {
    width: auto;
}

.main-chat-header .nav-link:hover,
.main-chat-header .nav-link:focus {
    color: var(--primary);
}

.main-chat-header .nav-link+.nav-link {
    margin-left: 10px;
}

@media (min-width: 576px) {
    .main-chat-header .nav-link {
        display: block;
    }

    .main-chat-header .nav-link:first-child {
        display: none;
    }
}

.main-chat-msg-name {
    margin-left: 15px;
}

.main-chat-msg-name h6 {
    margin-bottom: 2px;
    font-size: 15px;
    font-weight: 600;
    color: #323251;
}

.main-chat-msg-name small {
    font-size: 12px;
    color: #7987a1;
}

.main-chat-body {
    position: relative;
    padding-bottom: 50px;
}

.main-chat-body .content-inner {
    padding: 20px;
}

.main-chat-body .media+.media {
    margin-top: 20px;
}

.main-chat-body .media.flex-row-reverse .media-body {
    margin-left: 0;
    margin-right: 20px;
    align-items: flex-end;
}

.main-chat-body .media.flex-row-reverse .main-msg-wrapper {
    background-color: var(--primary);
    color: #fff;
    border-radius: 5px;
}

.main-chat-body .media-body {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.main-chat-body .media-body>div:last-child {
    font-size: 11px;
    color: #7987a1;
    display: flex;
    align-items: center;
}

@media (min-width: 992px) {
    .main-chat-body {
        padding-bottom: 0;
        max-height: auto;
        overflow: hidden;
    }
}

@media (min-width: 576px) {
    .main-chat-body .media.flex-row-reverse .media-body {
        margin-left: 55px;
    }
}

@media (min-width: 576px) {
    .main-chat-body .media-body {
        margin-right: 55px;
    }
}

.main-chat-time {
    display: block;
    position: relative;
    text-align: center;
    margin: 20px 0;
}

.main-chat-time:first-of-type {
    margin-top: 0;
}

.main-chat-time span {
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.main-msg-wrapper {
    padding: 10px 15px;
    background-color: var(--primary02);
    font-size: 13px;
    margin-bottom: 5px;
    border-radius: 5px;
    display: inline-block;
}

.main-chat-footer {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: 70px;
    padding: 0 20px;
    border-top: 1px solid #ededf5;
    background-color: #fff;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
}

.main-chat-footer .nav {
    align-items: center;
}

.main-chat-footer .nav-link {
    padding: 0;
    color: #949eb7;
    font-size: 16px;
    display: none;
}

.main-chat-footer .nav-link+.nav-link {
    margin-left: 10px;
}

.main-chat-footer .nav-link:last-child {
    display: block;
    margin-left: 0;
}

.main-chat-footer .form-control {
    flex: 1;
    margin: 0 10px;
    padding-left: 10px !important;
    padding-right: 0;
    border-width: 0;
    border: 1px solid #ededf5;
}

.main-chat-footer .form-control:hover,
.main-chat-footer .form-control:focus {
    box-shadow: none;
}

@media (min-width: 992px) {
    .main-chat-footer {
        position: relative;
        bottom: auto;
        left: auto;
        right: auto;
    }
}

@media (min-width: 576px) {
    .main-chat-footer .nav-link {
        display: block;
    }

    .main-chat-footer .nav-link:last-child {
        display: none;
    }
}

@media (min-width: 576px) {
    .main-chat-footer .form-control {
        margin-left: 20px;
    }
}

@media (min-width: 768px) {
    .main-chat-footer .form-control {
        margin: 0 20px 0 0;
    }
}

.main-msg-send {
    color: #fff;
    font-size: 15px;
    border: solid #ededf5 1px;
    border-radius: 5px;
    padding: 5px 10px;
    background: var(--primary);
}

.main-msg-send i {
    line-height: 1.5;
}

@media (max-width: 991px) and (min-width: 768px) {
    .app.sidebar-mini .main-chat-footer {
        margin-left: 0px;
    }

    .app.sidebar-mini.sidenav-toggled .main-chat-footer {
        margin-left: 0;
    }
}

@media (max-width: 380px) and (min-width: 305px) {
    .main-nav-line-chat .nav-link+.nav-link {
        margin-top: 8px;
        margin-left: 0px;
    }
}

/*--chat--*/
.chat {
    margin-bottom: auto;
}

.chat .card {
    height: 600px;
}

.chat .card-header {
    padding: 1rem;
}

.contacts_body {
    white-space: nowrap;
}

.msg_card_body {
    overflow-y: auto;
}

.contacts_body .contacts {
    list-style: none;
    padding: 0;
}

.contacts_body .contacts li {
    width: 100% !important;
    padding: 15px;
    border-bottom: 1px solid #ebeaf1;
}

.contacts_body .active {
    background-color: #f5f6fb;
}

#action_menu_btn {
    position: absolute;
    right: 36px;
    top: 21px;
    color: white;
    cursor: pointer;
    font-size: 20px;
}

.action_menu {
    z-index: 1;
    position: absolute;
    padding: 15px 0;
    background-color: #fff;
    color: #646367;
    top: 40px;
    right: 15px;
    display: none;
}

.action_menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.action-header {
    position: relative;
    background: var(--primary);
    padding: 15px 13px 15px 17px;
}

.ah-actions {
    z-index: 3;
    float: right !important;
    top: 3px;
    position: relative;
}

.actions {
    list-style: none;
    padding: 0;
    margin: 0;
}

.actions>li {
    display: inline-block;
}

.actions:not(.a-alt)>li>a>i {
    color: #a7a8c6;
}

.actions>li>a {
    display: block;
    /* padding: 10px; */
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 auto;
    line-height: 40px;
    text-align: center;
}

.actions>li>a>i {
    font-size: 17px;
}

.dash-5 {
    text-align: center;
}

.dash-5 h3 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.well {
    color: #5e629c;
}

.icon.icon-shape i {
    height: 3rem;
    width: 3rem;
    background: #000;
    border-radius: 50%;
    line-height: 3rem;
    background: rgba(9, 22, 49, 0.2);
    color: var(--primary);
}

.user-img-circle {
    box-shadow: 0 0 0 rgba(81, 15, 167, 0.4);
    animation: pulse 2s infinite;
    height: 90px;
}

.chat .action-header {
    position: relative;
    padding: 12px 13px 12px 13px;
    box-shadow: 0 1px 15px 1px rgba(58, 58, 62, 0.5);
}

.chat .dropdown-menu.dropdown-menu-right.show {
    width: 100%;
    margin-bottom: 5px;
    position: absolute;
    transform: translate3d(-176px, 22px, 0px);
    top: 10px !important;
    left: 0px;
    will-change: transform;
}

.chat .msb-reply textarea {
    width: 100%;
    font-size: 13px;
    padding: 12px 47px;
    resize: none;
    height: 44px;
    border: 1px solid #ebeaf1;
    background: var(--primary02);
    margin-left: -42px;
}

.chat .dropdown-menu li {
    padding: 7px;
}

.chat .dropdown-menu li:hover {
    background: var(--primary02);
}

.chat .dropdown-menu li i {
    padding: 7px;
}

.action_menu ul li:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
}

.chat .actions>li {
    display: inline-block;
}

.chat .actions:not(.a-alt)>li>a>i {
    color: #fff;
    line-height: unset;
}

.chat .justify-content-start,
.chat .justify-content-end {
    margin-bottom: 25px;
}

.chatbox .user_img {
    height: 50px;
    width: 50px;
}

.chatbox .user_img_msg {
    height: 40px;
    width: 40px;
    border: 0px solid #ebeaf1;
}

.chatbox .img_cont {
    position: relative;
    height: 50px;
    width: 50px;
}

.chatbox .img_cont_msg {
    height: 40px;
    width: 40px;
    display: contents;
}

.chat-box-single-line {
    height: 12px;
    margin: 7px 0 30px;
    position: relative;
    text-align: center;
}

.chat abbr.timestamp {
    padding: 4px 14px;
    border-radius: 4px;
    color: #000;
}

.chatbox .user_info {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 15px;
}

.chatbox .user_info span {
    color: #464444;
}

.chatbox .action-header small {
    font-size: 10px;
}

.chat .video_cam {
    margin-left: 50px;
    margin-top: 5px;
}

.chat .video_cam span {
    color: white;
    font-size: 20px;
    cursor: pointer;
    margin-right: 20px;
}

.chat .msg_cotainer {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    padding: 9px;
    position: relative;
    border-radius: 6px;
}

.chat .msg_cotainer {
    background-color: var(--primary-25);
}

.chat .msg_cotainer_send {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    background-color: #e4edf9;
    padding: 9px;
    position: relative;
    border-radius: 6px;
}

.chat .msg_time {
    position: absolute;
    left: 0;
    bottom: -18px;
    color: #3a374e;
    font-size: 10px;
}

.chat .msg_time_send {
    position: absolute;
    right: 0;
    bottom: -18px;
    color: #3a374e;
    font-size: 10px;
}

.chat .msg_head {
    position: relative;
    background: #60529f;
    box-shadow: 0 1px 15px 1px rgba(58, 58, 62, 0.4);
}

@media (max-width: 449px) {
    .actions {
        float: left !important;
    }
}

.main-msg-wrapper.right {
    position: relative;
    background: var(--primary);
    border: 1px solid var(--primary);
}

.main-msg-wrapper.right:after,
.main-msg-wrapper.right:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.main-msg-wrapper.right:nth-child(1):before {
    border-color: transparent;
    border-left-color: var(--primary);
    border-width: 0px;
    margin-top: -15px;
}

.main-msg-wrapper.right:nth-child(1):after {
    border-color: transparent;
    border-left-color: var(--primary);
    border-width: 8px;
    margin-top: -10px;
}

.main-msg-wrapper.left {
    position: relative;
}

.main-msg-wrapper.left:after,
.main-msg-wrapper.left:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.main-msg-wrapper.left:nth-child(1):before {
    border-color: transparent;
    border-right-color: var(--primary02);
    border-width: 0px;
    margin-top: -15px;
}

.main-msg-wrapper.left:nth-child(1):after {
    border-color: transparent;
    border-right-color: #f4f4fb;
    border-width: 8px;
    margin-top: -10px;
}

@media (max-width: 991px) {
    .main-nav-line-chat .nav-link {
        padding: 10px 10px;
    }
}

.modal-dialog.modal-dialog-right.chatbox .card-footer {
    padding: 1.25rem;
}

.main-chat-footer .nav-link {
    color: #7987a1;
    font-size: 15px;
    border: solid #ededf5 1px;
    border-radius: 5px;
    line-height: 15px;
    padding: 8px 10px;
    background: #fff;
}

.main-chat-footer .main-msg-send {
    color: #fff;
    font-size: 15px;
    border: solid #ededf5 1px;
    border-radius: 5px;
    padding: 5px 10px;
    background: var(--primary);
}

.main-chat-footer .main-msg-send i {
    line-height: 1.5;
}

.chat-profile {
    padding: 8px;
    line-height: 1;
    color: #4a4a69;
}

.chat-profile i {
    font-size: 14px;
}

.chat-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.shared-files {
    padding: 12px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #ededf5;
}

@media (min-width: 992px) {
    .main-content-app {
        height: calc(100vh - 250px);
        display: flex;
    }

    .main-chat-list {
        height: calc(100% - 68px);
        position: relative;
    }

    .main-chat-body {
        height: calc(100% - 105px);
    }

    .chat-main {
        overflow: hidden;
    }
}

/* ###### 7.4 Contacts  ###### */
@media (min-width: 768px) and (max-width: 991.98px) {
    .main-content-body-show .main-header-contacts .main-header-menu-icon {
        display: flex;
    }

    .main-content-body-show .main-content-left-contacts {
        display: block;
    }
}

.main-content-left-contacts {
    width: 100%;
    display: block;
    padding: 0;
}

.main-content-left-contacts .main-content-breadcrumb,
.main-content-left-contacts .main-content-title {
    padding-left: 20px;
}

@media (min-width: 992px) {

    .main-content-left-contacts .main-content-breadcrumb,
    .main-content-left-contacts .main-content-title {
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    .main-contacts-list {
        height: 730px;
        position: relative;
    }
}

.main-contact-label {
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    font-weight: 700;
    font-size: 15px;
    color: var(--primary);
    position: relative;
    border-top: 1px solid #ededf5;
    margin-left: -10px;
}

.main-contact-label::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    border-bottom: 1px solid #ededf5;
    z-index: 5;
}

@media (min-width: 992px) {
    .main-contact-label {
        padding-left: 30px;
    }
}

.main-contact-item {
    padding: 10px 20px;
    border: 1px solid transparent;
    border-right-width: 0;
    display: flex;
    position: relative;
    cursor: pointer;
}

.main-contact-item+.main-contact-item {
    margin-top: -1px;
    border-top-color: #f0f0f8;
}

.main-contact-item+.main-contact-item::before {
    content: "";
    position: absolute;
    top: -1px;
    left: 0px;
    right: 0;
    border-top: 1px solid #f0f0f8;
}

.main-contact-item:hover,
.main-contact-item:focus {
    background-color: #f9f9fb;
    border-top-color: #f0f0f8;
    border-bottom-color: #f0f0f8;
}

.main-contact-item.selected {
    z-index: 1;
    border-left-color: var(--primary);
    border-top-color: #f0f0f8;
    border-bottom-color: #f0f0f8;
    background-color: #f9f9fb;
}

@media (min-width: 992px) {
    .main-contact-item {
        padding: 15px 30px;
    }
}

.main-contact-body {
    flex: 1;
    margin-left: 15px;
}

.main-contact-body h6 {
    color: #7987a1;
    font-weight: 500;
    margin-bottom: 0px;
}

.main-contact-body span {
    font-size: 13px;
    color: #7987a1;
}

.main-contact-star {
    display: block;
    font-size: 20px;
    color: #7987a1;
    position: relative;
    outline: none;
    margin: auto;
    text-align: center;
}

.main-contact-star a {
    color: #7987a1;
}

.main-contact-star:hover,
.main-contact-star:focus {
    color: #7987a1;
}

.main-contact-star.active {
    color: var(--warning);
}

@media (min-width: 768px) {
    .main-content-body-contacts {
        display: block;
        overflow-y: auto;
    }
}

@media (min-width: 992px) {
    .main-content-body-contacts {
        overflow: visible;
    }
}

.main-contact-info-header {
    padding-top: 40px;
    padding-left: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ededf5;
    position: relative;
}

.main-contact-info-header .media {
    display: block;
}

.main-contact-info-header .main-img-user {
    width: 100px;
    height: 100px;
}

.main-contact-info-header .main-img-user a {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: #7987a1;
    color: #fff;
    font-size: 18px;
    line-height: 0.9;
    box-shadow: 0 0 0 2px #fff;
    border-radius: 100%;
}

.main-contact-info-header .main-img-user a:hover,
.main-contact-info-header .main-img-user a:focus {
    background-color: var(--primary);
}

.main-contact-info-header .media-body {
    margin-top: 30px;
}

.main-contact-info-header .media-body h4 {
    color: #323251;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 8px;
    line-height: 1;
}

.main-contact-info-header .media-body p {
    color: #4a4a69;
    margin-bottom: 15px;
}

.main-contact-info-header .media-body .nav-link {
    padding: 0;
    display: flex;
    align-items: center;
    color: #7987a1;
    font-size: 13px;
}

.main-contact-info-header .media-body .nav-link i {
    font-size: 0.875rem;
    line-height: 0;
    margin-right: 5px;
}

.main-contact-info-header .media-body .nav-link i.typcn {
    line-height: 0.9;
}

.main-contact-info-header .media-body .nav-link i.typcn::before {
    width: auto;
}

.main-contact-info-header .media-body .nav-link:hover,
.main-contact-info-header .media-body .nav-link:focus {
    color: var(--primary);
}

.main-contact-info-header .media-body .nav-link+.nav-link {
    margin-left: 10px;
}

@media (min-width: 576px) {
    .main-contact-info-header {
        padding-top: 20px;
    }
}

@media (min-width: 992px) {
    .main-contact-info-header {
        padding-left: 30px;
        padding-bottom: 25px;
    }
}

@media (min-width: 992px) {
    .main-contact-info-header .media {
        display: flex;
        align-items: center;
    }
}

@media (min-width: 992px) {
    .main-contact-info-header .media-body {
        margin-top: 0;
        margin-left: 30px;
    }
}

@media (min-width: 576px) {
    .main-contact-info-header .media-body .nav-link {
        font-size: 0.875rem;
    }
}

@media (min-width: 576px) {
    .main-contact-info-header .media-body .nav-link i {
        margin-right: 7px;
    }
}

@media (min-width: 576px) {
    .main-contact-info-header .media-body .nav-link+.nav-link {
        margin-left: 25px;
    }
}

.main-contact-action {
    position: absolute;
    top: 24px;
    right: 20px;
    display: flex;
}

.main-contact-action a {
    display: flex;
    align-items: center;
    color: #7987a1;
    font-size: 12px;
}

.main-contact-action a:hover,
.main-contact-action a:focus {
    color: var(--primary);
}

.main-contact-action a i {
    font-size: 15px;
    line-height: 0;
}

@media (min-width: 992px) {
    .main-contact-action {
        right: 20px;
    }
}

.main-contact-info-body {
    padding-left: 20px;
}

.main-contact-info-body .media-list {
    padding: 20px 0;
}

.main-contact-info-body .media {
    align-items: center;
    position: relative;
}

.main-contact-info-body .media+.media {
    margin-top: 30px;
}

.main-contact-info-body .media+.media::before {
    content: "";
    position: absolute;
    top: -15px;
    left: -19px;
    right: 0;
    border-top: 1px dotted #e1e1ef;
}

.main-contact-info-body .media:last-child {
    margin-bottom: 15px;
}

.main-contact-info-body .media-icon {
    font-size: 32px;
    color: #949eb7;
    line-height: 1;
    width: 40px;
    text-align: center;
}

.main-contact-info-body .media-body {
    margin-left: 0;
}

.main-contact-info-body .media-body>div+div {
    margin-top: 15px;
}

.main-contact-info-body .media-body label {
    color: #7987a1;
    margin-bottom: 0;
    display: block;
    font-size: 13px;
}

.main-contact-info-body .media-body span {
    font-size: 12px;
    display: block;
    font-weight: 400;
}

@media (min-width: 992px) {
    .main-contact-info-body {
        height: calc(100% - 126px);
        position: relative;
    }
}

@media (min-width: 992px) {
    .main-contact-info-body .media-list {
        padding: 25px 0;
    }
}

@media (min-width: 992px) {
    .main-contact-info-body .media-icon {
        width: 100px;
    }
}

@media (min-width: 992px) {
    .main-contact-info-body .media-body {
        display: flex;
    }
}

@media (min-width: 992px) {
    .main-contact-info-body .media-body>div {
        flex: 1;
    }
}

@media (min-width: 992px) {
    .main-contact-info-body .media-body>div+div {
        margin-top: 0;
    }
}

@media (min-width: 992px) {
    .main-contact-info-body {
        height: calc(100% - 126px);
        position: relative;
    }
}

.main-contact-info-header .main-img-user img {
    width: 100px !important;
    height: 100px !important;
}

@media (min-width: 769px) and (max-width: 991px) {
    .main-contacts-list {
        height: 627px;
        position: relative;
    }
}

@media (max-width: 768px) {
    .main-contacts-list {
        height: 600px;
        position: relative;
    }
}

.main-contact-item .main-img-user::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 6px;
    height: 6px;
    background-color: #17b86a;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95);
    border-radius: 100%;
    bottom: 3px;
    display: block !important;
}

.main-content-body-contacts .main-contact-info-header .main-img-user::after {
    display: none !important;
}

.contact-icon {
    display: inline-flex;
    width: 35px;
    height: 35px;
    text-align: center;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    margin-left: 3px;
    border-radius: 5px;
}

.main-contact-star .dropdown-menu.dropdown-menu-right {
    top: -20px;
    box-shadow: 0px 0px 10px 0px #e5edff;
}

/* ###### 7.11 Error  ###### */
.main-error-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    text-align: center;
}

.main-error-wrapper h1 {
    line-height: 1;
    font-size: 105px;
    font-weight: 700;
    color: #323251;
}

.main-error-wrapper h2 {
    font-weight: 500;
    color: #383853;
    letter-spacing: -0.5px;
    margin-bottom: 15px;
    margin-top: 20px;
}

.main-error-wrapper h6 {
    margin-bottom: 40px;
    font-size: 14px;
}

.main-error-wrapper .btn,
.main-error-wrapper .sp-container button {
    border-width: 2px;
    font-weight: 700;
}

.sp-container .main-error-wrapper button {
    border-width: 2px;
    font-weight: 700;
}

/* ###### 7.9 Signin  ###### */
.main-signin-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 20px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-card-signin {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    position: relative;
}

.main-card-signup {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.5);
    position: relative;
}

@media (max-width: 767px) {
    .main-card-signin {
        display: block !important;
        padding: 0;
    }

    .page-signin-style:before {
        display: none;
    }

    .app-sidebar {
        z-index: 1029 !important;
        top: 62px !important;
    }

    .main-nav-line-chat {
        padding: 20px !important;
    }
}

@media (min-width: 480px) {
    .main-card-signin {
        padding: 0;
        max-width: 750px;
    }

    .main-card-signin.construction {
        max-width: 1000px;
    }

    .main-card-signup {
        border: 1px solid #e3e3e3;
        padding: 0;
        max-width: 850px;
    }

    .sign-up-body {
        padding: 2.6rem !important;
    }
}

.main-signin-header h2 {
    font-weight: 500;
    color: var(--primary);
    letter-spacing: -1px;
}

.main-signin-header h4 {
    font-weight: 500;
    color: #14112d;
    margin-bottom: 25px;
    font-size: 16px;
}

.main-signin-header label {
    color: #a5a0b1;
}

.main-signin-header .form-control {
    color: #14112d;
    font-weight: 500;
    border-width: 1px;
    border-color: #e3e3e3;
}

.main-signin-header .form-control:focus {
    border-color: #d6d6e6;
    box-shadow: none;
}

.main-signin-header .form-control::placeholder {
    font-weight: 400;
    color: #a5a0b1;
}

.page-signin-style:before {
    content: "";
    background: rgba(109, 110, 243, 0.92);
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    left: 0;
    top: 0;
}

.authentication-pages {
    position: relative;
    z-index: 99;
}

.main-card-signin {
    box-shadow: none;
}

.main-signin-header .btn,
.main-signin-header .sp-container button {
    margin-top: 25px;
}

.sp-container .main-signin-header button {
    margin-top: 25px;
}

.main-signin-footer p {
    color: #a5a0b1;
}

.main-signin-footer p:first-child {
    margin-bottom: 5px;
}

.main-signin-footer p:last-child {
    margin-bottom: 0;
}

.main-signin-footer a {
    color: #14112d;
    font-weight: 700;
}

.main-signin-footer a:hover,
.main-signin-footer a:focus {
    color: var(--primary);
}

/* ###### 7.10 Signup  ###### */
.main-signup-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    background-color: rgba(244, 245, 248, 0.2);
}

@media (min-width: 992px) {
    .main-signup-wrapper {
        justify-content: flex-end;
    }

    .page-signin-style {
        height: auto !important;
        height: auto !important;
    }
}

.page-signin-style {
    width: 100%;
    height: 100%;
    background: url(../img/media/4.jpg);
    left: 0;
    top: 0;
    bottom: 0;
    position: relative;
}

.main-column-signup-left {
    flex: 1;
    padding: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: none;
}

.main-column-signup-left>div {
    max-width: 500px;
}

.main-column-signup-left .typcn {
    font-size: 80px;
    margin-bottom: 50px;
}

.main-column-signup-left .typcn::before {
    width: auto;
}

.main-column-signup-left .main-logo {
    font-size: 40px;
    line-height: 1;
}

.main-column-signup-left h5 {
    color: #14112d;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 25px;
}

.main-column-signup-left p {
    line-height: 1.7;
    margin-bottom: 25px;
}

.main-column-signup-left .btn,
.main-column-signup-left .sp-container button {
    border-width: 2px;
    padding-left: 25px;
    padding-right: 25px;
    font-weight: 700;
    text-transform: uppercase;
    height: 38px;
}

@media (min-width: 576px) {
    .main-column-signup-left {
        padding: 40px;
    }
}

@media (min-width: 992px) {
    .main-column-signup-left {
        display: flex;
    }
}

.sp-container .main-column-signup-left button {
    border-width: 2px;
    padding-left: 25px;
    padding-right: 25px;
    font-weight: 700;
    text-transform: uppercase;
    height: 38px;
}

.main-column-signup {
    background-color: #fff;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (min-width: 576px) {
    .main-column-signup {
        padding: 40px;
        width: 500px;
    }
}

@media (min-width: 992px) {
    .main-column-signup {
        border-left: 1px solid #e3e3e3;
    }
}

.main-signup-header h2 {
    font-weight: 500;
    color: var(--primary) !important;
    letter-spacing: -1px;
}

.main-signup-header h4 {
    font-size: 20px;
    font-weight: 400;
    color: #14112d;
    margin-bottom: 25px;
}

.main-signup-header label {
    color: #212529;
}

.main-signup-header .form-control {
    color: #14112d;
    font-weight: 500;
    border-width: 1px;
    border-color: #e3e3e3;
}

.main-signup-header .form-control:focus {
    border-color: #d6d6e6;
    box-shadow: none;
}

.main-signup-header .form-control::placeholder {
    font-weight: 400;
    color: #a5a0b1;
}

.main-signup-header>.btn {
    margin-top: 25px;
}

.sp-container .main-signup-header>button {
    margin-top: 25px;
}

.main-signup-header .row {
    margin-top: 20px;
}

.main-signup-header .row>div .btn i,
.main-signup-header .row>div .sp-container button i {
    font-size: 15px;
    line-height: 0;
    margin-right: 5px;
}

.sp-container .main-signup-header .row>div button i {
    font-size: 15px;
    line-height: 0;
    margin-right: 5px;
}

.main-signup-header .row>div:first-child .btn,
.main-signup-header .row>div:first-child .sp-container button {
    background-color: #19c0c0;
    color: #fff;
}

.sp-container .main-signup-header .row>div:first-child button {
    background-color: #4267b2;
    color: #fff;
}

.main-signup-header .row>div:first-child .btn:hover,
.main-signup-header .row>div:first-child .sp-container button:hover {
    background-color: #1bd0d0;
    color: #fff;
}

.sp-container .main-signup-header .row>div:first-child button:hover {
    background-color: #1bd0d0;
    color: #fff;
}

.main-signup-header .row>div:first-child .btn:focus,
.main-signup-header .row>div:first-child .sp-container button:focus {
    background-color: #1bd0d0;
    color: #fff;
}

.sp-container .main-signup-header .row>div:first-child button:focus {
    background-color: #1bd0d0;
    color: #fff;
}

.main-signup-footer p {
    color: #a5a0b1;
    margin-bottom: 0;
}

.main-signup-footer a {
    color: #14112d;
    font-weight: 700;
}

.main-signup-footer a:hover,
.main-signup-footer a:focus {
    color: var(--primary);
}

.construction .form-control {
    height: 39px;
    padding: 0.375rem 1rem;
}

.construction .btn.btn-icon {
    border: 0;
    text-align: center;
    padding: 0;
    background: 0 0;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.9);
    margin: 0 5px;
    border-radius: 3px;
}

.login,
.image {
    min-height: 100vh;
}

.construction .form-control {
    border-radius: 4px;
    width: 100%;
}

.construction .input-group-prepend,
.construction .input-group-append {
    position: absolute;
    right: 0;
}

.construction .input-group>.input-group-append>.btn {
    border-radius: 0 4px 4px 0;
    margin: 0;
    padding: 0 25px;
    height: 38px;
    line-height: 39px;
    float: right;
    border: 0;
    font-size: 14px;
    color: #fff;
    box-shadow: none;
    position: relative;
    overflow: hidden;
    z-index: 99;
}

.construction .input-group>.form-control:not(:last-child),
.construction .input-group>.custom-select:not(:last-child) {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

@media (max-width: 767px) {
    .error-page {
        height: 70%;
        width: 70%;
    }
}

@media (min-width: 768px) {
    .main-card-signin {
        border: 0 !important;
        box-shadow: none !important;
    }

    .main-signin-header {
        box-shadow: none !important;
    }

    .error-page {
        height: 30%;
        width: 30%;
    }
}

@media (max-width: 400px) {
    .main-error-wrapper .tx-130 {
        font-size: 95px;
    }
}

/* ###### 7.5 Invoice  ###### */
.main-content-left-invoice {
    display: block;
    padding: 0;
}

.main-content-left-invoice .main-content-breadcrumb,
.main-content-left-invoice .main-content-title {
    padding-left: 20px;
}

@media (max-width: 991.98px) {
    .main-content-left-invoice {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .main-content-left-invoice {
        border-right: 1px solid #ededf5;
    }
}

@media (min-width: 992px) {

    .main-content-left-invoice .main-content-breadcrumb,
    .main-content-left-invoice .main-content-title {
        padding-left: 0;
    }
}

.main-invoice-list {
    position: relative;
    border-top: 1px solid #ededf5;
}

.main-invoice-list .media {
    align-items: center;
    border: 1px solid transparent;
    border-right-width: 0;
    padding: 10px 20px;
    position: relative;
}

.main-invoice-list .media:hover,
.main-invoice-list .media:focus {
    cursor: pointer;
    background-color: #f9f9fb;
}

.main-invoice-list .media+.media {
    margin-top: -1px;
}

.main-invoice-list .media+.media::before {
    content: "";
    position: absolute;
    top: -1px;
    left: 55px;
    right: 0;
    border-top: 1px dotted #e1e1ef;
}

.main-invoice-list .media-icon {
    width: 24px;
    text-align: center;
    font-size: 32px;
    color: #949eb7;
}

.main-invoice-list .media-body {
    margin-left: 15px;
}

.main-invoice-list .media-body h6 {
    font-weight: 500;
    color: #323251;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-invoice-list .media-body h6 span:last-child {
    color: var(--primary);
}

.main-invoice-list .media-body div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main-invoice-list .media-body p {
    margin-bottom: 0;
    font-size: 11px;
    font-family: Arial, sans-serif;
    color: #383853;
}

.main-invoice-list .media-body p span {
    color: #7987a1;
}

.main-invoice-list .selected {
    background-color: #f9f9fb;
    border-top-color: #ededf5;
    border-bottom-color: #ededf5;
    border-left-color: var(--primary);
}

.main-invoice-list .selected:first-child {
    border-top-color: transparent;
}

.card-invoice hr {
    border-top: 0 !important;
}

@media (min-width: 992px) {
    .main-invoice-list {
        height: calc(100% - 65px);
    }
}

@media (min-width: 992px) {
    .main-invoice-list .media {
        padding: 10px 15px;
    }
}

.main-content-body-invoice {
    position: relative;
}

@media (min-width: 992px) {
    .main-content-body-invoice {
        display: block;
    }
}

.card-invoice {
    background-color: transparent;
    border-width: 0;
}

.card-invoice .card-body {
    padding: 20px;
}

@media (min-width: 768px) {
    .invoice-header {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
    }
}

.invoice-title {
    margin-bottom: 0;
    text-transform: uppercase;
    color: #676767;
    font-weight: 700;
}

.billed-from {
    margin-top: 25px;
    margin-bottom: 25px;
}

.billed-from h6 {
    color: #323251;
    font-size: 14px;
    font-weight: 700;
}

.billed-from p {
    font-size: 13px;
    margin-bottom: 0;
}

@media (min-width: 768px) {
    .billed-from {
        margin-top: 0;
    }
}

.billed-to h6 {
    color: #323251;
    font-size: 14px;
    font-weight: 700;
}

.billed-to p {
    font-size: 13px;
    margin-bottom: 0;
}

.invoice-info-row {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    margin-bottom: 0;
    font-size: 13px;
}

.invoice-info-row+.invoice-info-row {
    border-top: 1px dotted #e1e1ef;
}

.invoice-info-row span:first-child {
    color: #4d5875;
}

.table-invoice thead>tr>th,
.table-invoice thead>tr>td {
    font-size: 13px;
    font-weight: 700;
    color: #37374e;
}

.table-invoice tbody>tr>th:first-child,
.table-invoice tbody>tr>td:first-child {
    color: #323251;
    font-weight: 500;
}

.table-invoice tbody>tr>th:first-child .invoice-notes,
.table-invoice tbody>tr>td:first-child .invoice-notes {
    margin-right: 20px;
}

.table-invoice tbody>tr>th:first-child .invoice-notes .section-label-sm,
.table-invoice tbody>tr>td:first-child .invoice-notes .section-label-sm {
    font-weight: 600;
}

.table-invoice tbody>tr>th:first-child .invoice-notes p,
.table-invoice tbody>tr>td:first-child .invoice-notes p {
    font-size: 13px;
    font-weight: 400;
    color: #4a4a69;
}

.table-invoice.table thead th,
.table-invoice.table thead td {
    padding: 9px 15px;
}

/* ###### 7.6 Mail  ###### */
.main-content-mail .container,
.main-content-mail .container-fluid {
    padding: 20px 0 0;
}

@media (min-width: 992px) {

    .main-content-mail .container,
    .main-content-mail .container-fluid {
        padding: 0;
    }
}

@media (min-width: 992px) {
    .main-content-left-mail {
        display: block;
    }
}

.main-content-left-mail .btn-compose {
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 11px;
    padding: 0 20px;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
}

@media (min-width: 992px) {
    .main-content-left-mail .btn-compose {
        margin-right: 0;
    }
}

.main-mail-menu {
    position: relative;
    padding-right: 0;
}

.main-mail-menu .nav-link {
    height: 38px;
}

.main-mail-menu .nav-link i {
    font-size: 15px;
}

@media (min-width: 992px) {
    .main-mail-menu {
        padding-right: 0;
    }
}

@media (min-width: 992px) {
    .main-mail-menu .nav-link {
        font-size: 13.5px;
    }
}

.main-mail-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 20px;
}

.main-mail-header>div:first-child p {
    font-size: 13px;
    margin-bottom: 0;
}

.main-mail-header>div:last-child {
    display: none;
}

.main-mail-header>div:last-child>span {
    font-size: 12px;
    margin-right: 10px;
}

.main-mail-header .btn-group .btn,
.main-mail-header .btn-group .sp-container button {
    font-size: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    line-height: 0;
    padding: 0;
    position: relative;
    z-index: 1;
    border-color: #e1e1ef;
    background-color: #fff;
}

@media (min-width: 992px) {
    .main-mail-header {
        padding: 0 0 0 25px;
        margin-bottom: 25px;
    }
}

@media (min-width: 768px) {
    .main-mail-header>div:last-child {
        display: flex;
        align-items: center;
    }
}

.sp-container .main-mail-header .btn-group button {
    font-size: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    line-height: 0;
    padding: 0;
    position: relative;
    z-index: 1;
    border-color: #e1e1ef;
    background-color: #fff;
}

.main-mail-header .btn-group .btn:hover,
.main-mail-header .btn-group .sp-container button:hover {
    color: #323251;
    background-color: #f9f9fb;
}

.sp-container .main-mail-header .btn-group button:hover {
    color: #323251;
    background-color: #f9f9fb;
}

.main-mail-header .btn-group .btn:focus,
.main-mail-header .btn-group .sp-container button:focus {
    color: #323251;
    background-color: #f9f9fb;
}

.sp-container .main-mail-header .btn-group button:focus {
    color: #323251;
    background-color: #f9f9fb;
}

.main-mail-header .btn-group .btn.disabled,
.main-mail-header .btn-group .sp-container button.disabled {
    background-color: #fff;
    color: #e1e1ef;
    border-color: #e1e1ef;
    z-index: 0;
}

.sp-container .main-mail-header .btn-group button.disabled {
    background-color: #fff;
    color: #e1e1ef;
    border-color: #e1e1ef;
    z-index: 0;
}

.main-mail-header .btn-group .btn.disabled:focus,
.main-mail-header .btn-group .sp-container button.disabled:focus {
    box-shadow: none;
}

.sp-container .main-mail-header .btn-group button.disabled:focus {
    box-shadow: none;
}

.main-mail-header .btn-group .btn.disabled:active,
.main-mail-header .btn-group .sp-container button.disabled:active {
    box-shadow: none;
}

.sp-container .main-mail-header .btn-group button.disabled:active {
    box-shadow: none;
}

.main-mail-header .btn-group .btn+.btn,
.main-mail-header .btn-group .sp-container button+.btn {
    margin-left: -2px;
}

.sp-container .main-mail-header .btn-group button+.btn,
.main-mail-header .btn-group .sp-container .btn+button,
.sp-container .main-mail-header .btn-group .btn+button,
.main-mail-header .btn-group .sp-container button+button,
.sp-container .main-mail-header .btn-group button+button {
    margin-left: -2px;
}

.main-mail-options {
    padding: 5px 10px 5px 20px;
    border: 1px solid #ededf5;
    display: none;
    align-items: center;
    justify-content: flex-end;
}

.main-mail-options .btn,
.main-mail-options .sp-container button {
    font-size: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background-color: transparent;
}

@media (min-width: 992px) {
    .main-mail-options {
        padding-left: 25px;
        display: flex;
        justify-content: space-between;
    }
}

.sp-container .main-mail-options button {
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background-color: transparent;
}

.main-mail-options .btn:hover,
.main-mail-options .sp-container button:hover {
    background-color: #f0f0f8;
}

.sp-container .main-mail-options button:hover {
    background-color: #f0f0f8;
}

.main-mail-options .btn:focus,
.main-mail-options .sp-container button:focus {
    background-color: #f0f0f8;
}

.sp-container .main-mail-options button:focus {
    background-color: #f0f0f8;
}

.main-mail-options .btn i,
.main-mail-options .sp-container button i {
    line-height: 0;
}

.sp-container .main-mail-options button i {
    line-height: 0;
}

.main-mail-options .btn i.typcn,
.main-mail-options .sp-container button i.typcn {
    line-height: 0.75;
}

.sp-container .main-mail-options button i.typcn {
    line-height: 0.75;
}

.main-mail-options .btn.disabled,
.main-mail-options .sp-container button.disabled {
    background-color: transparent;
    color: #7987a1;
}

.sp-container .main-mail-options button.disabled {
    background-color: transparent;
    color: #7987a1;
}

@media (min-width: 992px) {
    .main-mail-list {
        border-top-width: 0;
    }
}

.main-mail-item {
    padding: 10px 15px;
    border-top: 1px solid #ededf5;
    border-bottom: 1px solid #ededf5;
    background-color: transparent;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.main-mail-item+.main-mail-item {
    margin-top: -1px;
}

.main-mail-item .main-img-user,
.main-mail-item .main-avatar {
    flex-shrink: 0;
    margin-right: 15px;
}

.main-mail-item:hover,
.main-mail-item:focus {
    background-color: #fcfcff;
}

.main-mail-item.unread {
    background-color: rgba(var(--primary), 0.04);
}

.main-mail-item.selected {
    background-color: white;
}

@media (min-width: 576px) {
    .main-mail-item {
        padding: 10px 20px;
    }
}

@media (min-width: 992px) {
    .main-mail-item {
        width: auto;
        padding: 20px 25px;
    }
}

.main-mail-checkbox {
    margin-right: 15px;
    display: none;
}

@media (min-width: 992px) {
    .main-mail-checkbox {
        display: block;
    }
}

.main-mail-star {
    margin-right: 15px;
    font-size: 18px;
    line-height: 0.9;
    color: #e1e1ef;
    position: absolute;
    bottom: 10px;
    right: 0;
}

.main-mail-star.active {
    color: var(--warning);
}

@media (min-width: 992px) {
    .main-mail-star {
        position: relative;
        bottom: auto;
        top: 2px;
    }
}

.main-mail-body {
    width: calc(100% - 80px);
    cursor: pointer;
}

@media (min-width: 992px) {
    .main-mail-body {
        max-width: 460px;
        margin-right: 15px;
        flex: 1;
    }
}

@media (min-width: 1200px) {
    .main-mail-body {
        max-width: 640px;
    }
}

.main-mail-from {
    font-size: 13px;
}

@media (min-width: 576px) {
    .main-mail-subject {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }
}

.main-mail-subject strong {
    font-weight: 600;
    font-size: 14px;
    color: #323251;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}

.main-mail-subject span {
    font-size: 13px;
    color: #7987a1;
    display: none;
}

@media (min-width: 576px) {
    .main-mail-subject strong {
        display: inline;
        width: auto;
        white-space: normal;
        text-overflow: inherit;
        overflow: visible;
    }
}

@media (min-width: 576px) {
    .main-mail-subject span {
        display: inline;
    }
}

.main-mail-attachment {
    margin-right: 15px;
    font-size: 21px;
    line-height: 0.9;
    display: none;
}

@media (min-width: 992px) {
    .main-mail-attachment {
        display: block;
        position: absolute;
        right: 63px;
        top: 8px;
    }
}

.main-mail-date {
    font-size: 11px;
    position: absolute;
    top: 12px;
    right: 15px;
    color: #949eb7;
    margin-left: auto;
}

@media (min-width: 992px) {
    .main-mail-date {
        position: absolute;
        font-size: 13px;
        top: 8px;
    }
}

.main-mail-compose {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(28, 39, 60, 0.5);
    z-index: 9999;
    display: none;
}

.main-mail-compose>div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

@media (min-width: 992px) {
    .main-mail-compose .container {
        padding: 0;
    }
}

.main-mail-compose-box {
    box-shadow: 0 0 30px rgba(28, 39, 60, 0.2);
    border-radius: 3px;
}

.main-mail-compose-header {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #323251;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.main-mail-compose-header .nav-link {
    color: rgba(255, 255, 255, 0.3);
    font-size: 14px;
    line-height: 1;
    padding: 0;
    transition: all 0.2s ease-in-out;
}

.main-mail-compose-header .nav-link:hover,
.main-mail-compose-header .nav-link:focus {
    color: #fff;
}

.main-mail-compose-header .nav-link+.nav-link {
    margin-left: 15px;
}

.main-mail-compose-header .nav-link:nth-child(2) {
    display: none;
}

@media (min-width: 992px) {
    .main-mail-compose-header {
        padding: 20px 25px;
    }
}

@media (prefers-reduced-motion: reduce) {
    .main-mail-compose-header .nav-link {
        transition: none;
    }
}

@media (min-width: 768px) {
    .main-mail-compose-header .nav-link:nth-child(2) {
        display: block;
    }
}

.main-mail-compose-body {
    background-color: #fff;
    padding: 20px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.main-mail-compose-body .form-group {
    display: flex;
    align-items: center;
}

.main-mail-compose-body .form-group>div {
    flex: 1;
    margin-left: 10px;
}

.main-mail-compose-body .form-group .form-label {
    margin: 0;
    color: #323251;
}

.main-mail-compose-body .form-group .form-control {
    border-width: 0;
    border-radius: 0;
    padding: 0;
}

.main-mail-compose-body .form-group .form-control:focus {
    box-shadow: none !important;
}

.main-mail-compose-body .form-group+.form-group {
    border-top: 1px dotted #e1e1ef;
    padding-top: 1rem;
}

.main-mail-compose-body .form-group:last-child {
    display: block;
}

.main-mail-compose-body .form-group:last-child .btn,
.main-mail-compose-body .form-group:last-child .sp-container button {
    width: 100%;
    margin-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
}

@media (min-width: 992px) {
    .main-mail-compose-body {
        padding: 25px;
    }
}

@media (min-width: 576px) {
    .main-mail-compose-body .form-group:last-child {
        display: flex;
        justify-content: space-between;
    }
}

.sp-container .main-mail-compose-body .form-group:last-child button {
    width: 100%;
    margin-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
}

@media (min-width: 576px) {

    .main-mail-compose-body .form-group:last-child .btn,
    .main-mail-compose-body .form-group:last-child .sp-container button {
        width: auto;
        margin-top: 0;
    }

    .sp-container .main-mail-compose-body .form-group:last-child button {
        width: auto;
        margin-top: 0;
    }
}

.main-mail-compose-body .form-group .nav-link {
    padding: 0;
    font-size: 18px;
    line-height: 0;
    color: #4a4a69;
    position: relative;
    transition: all 0.2s ease-in-out;
}

.main-mail-compose-body .form-group .nav-link:hover,
.main-mail-compose-body .form-group .nav-link:focus {
    color: #323251;
}

.main-mail-compose-body .form-group .nav-link+.nav-link {
    margin-left: 15px;
}

@media (prefers-reduced-motion: reduce) {
    .main-mail-compose-body .form-group .nav-link {
        transition: none;
    }
}

.main-mail-compose-compress,
.main-mail-compose-minimize {
    top: auto;
    left: auto;
    bottom: 0;
    right: 30px;
    width: 310px;
    height: auto;
    background-color: transparent;
}

.main-mail-compose-compress .container {
    max-width: none;
    padding: 0;
}

.main-mail-compose-minimize .container {
    max-width: none;
    padding: 0;
}

.main-mail-compose-minimize .main-mail-compose-body {
    display: none;
}

/* ###### 7.7 Mail Two  ###### */
.main-mail-two .main-content {
    display: flex;
    flex-direction: column;
}

.main-mail-two .main-header {
    width: 100%;
    border-bottom: 1px solid #ededf5;
}

.main-mail-two .main-footer {
    width: 100%;
}

.main-mail-two .main-header-menu-icon {
    margin-right: 0;
}

.main-mail-two .main-content-body {
    display: flex;
    padding: 0;
}

.main-mail-left {
    background-color: #f9f9f9;
    width: 240px;
    border-right: 1px solid #d6d6e6;
    padding: 20px;
    display: none;
}

.main-mail-left .btn-compose {
    display: block;
    margin-bottom: 20px;
}

.main-mail-left .main-mail-menu {
    padding-right: 0;
}

@media (min-width: 1200px) {
    .main-mail-left {
        display: block;
    }
}

.main-mail-content {
    background-color: var(--primary02);
    flex: 1;
    max-width: 100vw -480px;
}

.main-mail-content .main-mail-header {
    margin-bottom: 0;
    padding: 20px;
}

.main-mail-content .main-mail-body {
    max-width: 590px;
}

/* ######## UTILITIES-STYLES ######## */
.bg-primary {
    background-color: var(--primary) !important;
}

.bg-primary-dark {
    background-color: var(--primary-darker) !important;
}

a.bg-primary:hover,
a.bg-primary:focus {
    background-color: var(--primary) !important;
}

.bg-transparent {
    background: transaprent !important;
}

button.bg-primary:hover,
button.bg-primary:focus {
    background-color: var(--primary) !important;
}

.bg-secondary {
    background-color: var(--secondary) !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus {
    background-color: var(--secondary) !important;
}

button.bg-secondary:hover,
button.bg-secondary:focus {
    background-color: var(--secondary) !important;
}

.bg-success {
    background-color: var(--success) !important;
}

a.bg-success:hover,
a.bg-success:focus {
    background-color: #05957d !important;
}

button.bg-success:hover,
button.bg-success:focus {
    background-color: #00927a !important;
}

.bg-info {
    background-color: var(--info) !important;
}

a.bg-info:hover,
a.bg-info:focus {
    background-color: var(--info) !important;
}

button.bg-info:hover,
button.bg-info:focus {
    background-color: var(--info) !important;
}

.bg-warning {
    background-color: var(--warning) !important;
}

a.bg-warning:hover,
a.bg-warning:focus {
    background-color: #f3a024 !important;
}

button.bg-warning:hover,
button.bg-warning:focus {
    background-color: #f3a024 !important;
}

.bg-danger {
    background-color: #f34343 !important;
}

a.bg-danger:hover,
a.bg-danger:focus {
    background-color: #ee1f1f !important;
}

button.bg-danger:hover,
button.bg-danger:focus {
    background-color: #ee1f1f !important;
}

.bg-purple {
    background-color: #ad4ffa !important;
}

a.bg-purple:hover,
a.bg-purple:focus {
    background-color: #5930a2 !important;
}

button.bg-purple:hover,
button.bg-purple:focus {
    background-color: #5930a2 !important;
}

.bg-light {
    background-color: #f9f9fb !important;
}

a.bg-light:hover,
a.bg-light:focus {
    background-color: #d5d9e4 !important;
}

button.bg-light:hover,
button.bg-light:focus {
    background-color: #d5d9e4 !important;
}

.bg-dark {
    background-color: #383853 !important;
}

a.bg-dark:hover,
a.bg-dark:focus {
    background-color: #283143 !important;
}

button.bg-dark:hover,
button.bg-dark:focus {
    background-color: #283143 !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.bg-gray+.select2-container--default .select2-selection--single {
    background-color: #455473;
}

.bg-gray+.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #e1e1ef;
}

/* ###### 9.1 Background  ###### */
.bg-transparent {
    background-color: transparent;
}

.bg-gray-100 {
    background-color: #f9f9fb;
}

.bg-gray-200 {
    background-color: #f0f0f8;
}

.bg-gray-300 {
    background-color: #e1e1ef;
}

.bg-gray-400 {
    background-color: #d6d6e6;
}

.bg-gray-500 {
    background-color: #949eb7;
}

.bg-gray-600 {
    background-color: #7987a1;
}

.bg-gray-700 {
    background-color: #4d5875;
}

.bg-gray-800 {
    background-color: #383853;
}

.bg-gray-900 {
    background-color: #323251;
}

.bg-white-1 {
    background-color: rgba(255, 255, 255, 0.1);
}

.bg-white-2 {
    background-color: rgba(255, 255, 255, 0.2);
}

.bg-white-3 {
    background-color: rgba(255, 255, 255, 0.3);
}

.bg-white-4 {
    background-color: rgba(255, 255, 255, 0.4);
}

.bg-white-5 {
    background-color: rgba(255, 255, 255, 0.5);
}

.bg-white-6 {
    background-color: rgba(255, 255, 255, 0.6);
}

.bg-white-7 {
    background-color: rgba(255, 255, 255, 0.7);
}

.bg-white-8 {
    background-color: rgba(255, 255, 255, 0.8);
}

.bg-white-9 {
    background-color: rgba(255, 255, 255, 0.9);
}

.bg-black-1 {
    background-color: rgba(0, 0, 0, 0.1);
}

.bg-black-2 {
    background-color: rgba(0, 0, 0, 0.2);
}

.bg-black-3 {
    background-color: rgba(0, 0, 0, 0.3);
}

.bg-black-4 {
    background-color: rgba(0, 0, 0, 0.4);
}

.bg-black-5 {
    background-color: rgba(0, 0, 0, 0.5);
}

.bg-black-6 {
    background-color: rgba(0, 0, 0, 0.6);
}

.bg-black-7 {
    background-color: rgba(0, 0, 0, 0.7);
}

.bg-black-8 {
    background-color: rgba(0, 0, 0, 0.8);
}

.bg-black-9 {
    background: rgba(0, 0, 0, 0.9);
}

.bg-indigo {
    background: #5b67c7;
}

.bg-purple {
    background: #ad4ffa;
}

.bg-pink {
    background: #f754fa !important;
}

.bg-orange {
    background: #fd7e14;
}

.bg-teal {
    background: #29ddee !important;
}

.bg-purple-dark {
    background: #59339d;
}

.bg-transparent {
    background: transparent;
}

/* ###### 4.8 transparent colors ###### */
.bg-success-transparent {
    background-color: rgba(26, 156, 134, 0.2) !important;
}

.bg-danger-transparent {
    background-color: rgba(243, 67, 67, 0.2) !important;
}

.bg-primary-transparent {
    background: var(--primary-50) !important;
}

.bg-secondary-transparent {
    background-color: rgba(247, 79, 117, 0.2) !important;
}

.bg-white-transparent {
    background-color: rgba(255, 255, 255, 0.2) !important;
}

.bg-warning-transparent {
    background-color: rgba(255, 189, 90, 0.2) !important;
}

.bg-pink-transparent {
    background-color: rgba(247, 84, 250, 0.2) !important;
}

.bg-purple-transparent {
    background-color: rgba(173, 79, 250, 0.13) !important;
}

.bg-teal-transparent {
    background-color: rgba(25, 192, 192, 0.2) !important;
}

.bg-info-transparent {
    background-color: rgba(78, 194, 240, 0.2) !important;
}

.bg-orange-transparent {
    background-color: rgba(253, 126, 20, 0.2);
}

.bg-gradient-blue {
    background-image: linear-gradient(to right, #1976d2 0, #64b5f6 100%);
}

.bg-primary {
    background-color: var(--primary) !important;
}

.bg-primary-gradient {
    background-image: linear-gradient(to right, var(--primary) 0%, var(--primary-25) 100%) !important;
}

.bg-danger-gradient {
    background-image: linear-gradient(to right, #f34343 0%, rgba(243, 67, 67, 0.5) 100%) !important;
}

.bg-success-gradient {
    background-image: linear-gradient(to right, var(--success) 0%, rgba(var(--success), 0.5) 100%) !important;
}

.bg-warning-gradient {
    background-image: linear-gradient(to right, var(--warning) 0%, rgba(var(--warning), 0.5) 100%) !important;
}

.bg-primary-gradient1 {
    background-image: linear-gradient(to top, #64c5eb 0%, var(--primary) 100%) !important;
}

.bg-info-gradient1 {
    background-image: linear-gradient(to top, #4ec2f0 0%, #31eccd 100%) !important;
}

.bg-purple-gradient {
    background-image: linear-gradient(to right, #673AB7 0%, #ba7bfb 100%) !important;
}

.bg-info-gradient {
    background-image: linear-gradient(to right, var(--info) 0%, rgba(var(--info), 0.5) 100%) !important;
}

.bg-secondary-gradient {
    background-image: linear-gradient(to right, var(--secondary) 0%, rgba(var(--secondary), 0.5) 100%) !important;
}

.bg-pink-gradient {
    background-image: linear-gradient(to right, #f754fa 0%, #f36eae 100%) !important;
}

.bg-purple-gradient {
    background-image: linear-gradient(to right, #ad4ffa 0%, #884af1 100%) !important;
}

.bg-teal-gradient {
    background-image: linear-gradient(to right, #29ddee 0%, #96e8e8 100%) !important;
}

.bg-azure {
    background-color: #45aaf2 !important;
}

.bg-red {
    background-color: #dc0441 !important;
    color: #fff !important;
}

.bg-yellow {
    background-color: #ecb403 !important;
    color: #fff !important;
}

.bg-lime {
    background-color: #1e74a0 !important;
}

.bg-green {
    background-color: #2dce89 !important;
    color: #fff !important;
}

.gutters-xs>.col,
.gutters-xs>[class*=col-] {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
}

.gutters-xs {
    margin-top: -25px;
}

.tag-outline-primary {
    color: var(--primary) !important;
    border-color: var(--primary) !important;
}

.tag-outline-secondary {
    color: var(--secondary) !important;
    border-color: var(--secondary) !important;
}

.tag-outline-success {
    color: var(--success) !important;
    border-color: var(--success) !important;
}

.tag-outline-warning {
    color: var(--warning) !important;
    border-color: var(--warning) !important;
}

.tag-outline-info {
    color: var(--info) !important;
    border-color: var(--info) !important;
}

.tag-outline-danger {
    color: #f34343 !important;
    border-color: #f34343 !important;
}

.bg-chartcustom {
    background-color: #ebeff5;
}

.border {
    border: 1px solid #ededf5 !important;
}

.border-top {
    border-top: 1px solid #ededf5 !important;
}

.border-end {
    border-right: 1px solid #ededf5 !important;
}

.border-bottom {
    border-bottom: 1px solid #ededf5 !important;
}

.border-start {
    border-left: 1px solid #ededf5 !important;
}

.border-0 {
    border: 0 !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-end-0 {
    border-right: 0 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-start-0 {
    border-left: 0 !important;
}

.border-primary {
    border-color: var(--primary) !important;
}

.border-secondary {
    border-color: var(--secondary) !important;
}

.border-success {
    border-color: var(--success) !important;
}

.border-info {
    border-color: var(--info) !important;
}

.border-warning {
    border-color: var(--warning) !important;
}

.border-danger {
    border-color: #f34343 !important;
}

.border-light {
    border-color: #f9f9fb !important;
}

.border-dark {
    border-color: #383853 !important;
}

.border-white {
    border-color: #fff !important;
}

.rounded-sm {
    border-radius: 0.2rem !important;
}

.rounded {
    border-radius: 5px !important;
}

.rounded-top {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}

.rounded-end {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.rounded-bottom {
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.rounded-start {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.rounded-lg {
    border-radius: 0.3rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

/* ###### 9.2 Border   ###### */
.bd-transparent {
    border-color: transparent;
}

.bd {
    border: 1px solid #ededf5;
}

.bd-t {
    border-top: 1px solid #ededf5;
}

.bd-e {
    border-right: 1px solid #ededf5;
}

.bd-s {
    border-left: 1px solid #ededf5;
}

.bd-b {
    border-bottom: 1px solid #ededf5;
}

.bd-l {
    border-left: 1px solid #ededf5;
}

.bd-y {
    border-top: 1px solid #ededf5;
    border-bottom: 1px solid #ededf5;
}

.bd-x {
    border-left: 1px solid #ededf5;
    border-right: 1px solid #ededf5;
}

.bd-0 {
    border-width: 0;
}

.bd-1 {
    border-width: 1px;
}

.bd-2 {
    border-width: 2px;
}

.bd-3 {
    border-width: 3px;
}

.bd-4 {
    border-width: 4px;
}

.bd-5 {
    border-width: 5px;
}

.bd-t-0 {
    border-top: 0 !important;
}

.bd-e-0 {
    border-right: 0;
}

.bd-b-0 {
    border-bottom: 0 !important;
}

.bd-s-0 {
    border-left: 0;
}

.bd-t-0-f {
    border-top: 0 !important;
}

.bd-e-0-f {
    border-right: 0 !important;
}

.bd-b-0-f {
    border-bottom: 0 !important;
}

.bd-s-0-f {
    border-left: 0 !important;
}

.bd-y-0 {
    border-top-width: 0;
    border-bottom-width: 0;
}

.bd-y-1 {
    border-top-width: 1px;
    border-bottom-width: 1px;
}

.bd-x-0 {
    border-left-width: 0;
    border-right-width: 0;
}

.bd-x-1 {
    border-left-width: 1px;
    border-right-width: 1px;
}

.bd-primary {
    border-color: var(--primary) !important;
}

.bd-success {
    border-color: var(--success) !important;
}

.bd-warning {
    border-color: var(--warning) !important;
}

.bd-danger {
    border-color: #f34343 !important;
}

.bd-info {
    border-color: var(--info) !important;
}

.bd-secondary {
    border-color: var(--secondary) !important;
}

.bd-teal {
    border-color: #29ddee !important;
}

.bd-white {
    border-color: #fff !important;
}

.bd-gray-100 {
    border-color: #f9f9fb;
}

.bd-gray-200 {
    border-color: #f0f0f8;
}

.bd-gray-300 {
    border-color: #e1e1ef;
}

.bd-gray-400 {
    border-color: #d6d6e6;
}

.bd-gray-500 {
    border-color: #949eb7;
}

.bd-gray-600 {
    border-color: #7987a1;
}

.bd-gray-700 {
    border-color: #4d5875;
}

.bd-gray-800 {
    border-color: #383853;
}

.bd-gray-900 {
    border-color: #323251;
}

.bd-white-1 {
    border-color: rgba(255, 255, 255, 0.1);
}

.bd-white-2 {
    border-color: rgba(255, 255, 255, 0.2);
}

.bd-white-3 {
    border-color: rgba(255, 255, 255, 0.3);
}

.bd-white-4 {
    border-color: rgba(255, 255, 255, 0.4);
}

.bd-white-5 {
    border-color: rgba(255, 255, 255, 0.5);
}

.bd-white-6 {
    border-color: rgba(255, 255, 255, 0.6);
}

.bd-white-7 {
    border-color: rgba(255, 255, 255, 0.7);
}

.bd-white-8 {
    border-color: rgba(255, 255, 255, 0.8);
}

radius-1 {
    border-radius: 1px;
}

.radius-5 {
    border-radius: 5px;
}

.radius-10 {
    border-radius: 10px;
}

.radius-20 {
    border-radius: 20px;
}

.radius-30 {
    border-radius: 30px;
}

.radius-40 {
    border-radius: 40px;
}

.radius-50 {
    border-radius: 50px !important;
}

.bd-dashed {
    border-style: dashed;
}

.bd-dotted {
    border-style: dotted;
}

@media (min-width: 480px) {
    .bd-xs {
        border: 1px solid #ededf5;
    }

    .bd-xs-t {
        border-top: 1px solid #ededf5;
    }

    .bd-xs-e {
        border-right: 1px solid #ededf5;
    }

    .bd-xs-b {
        border-bottom: 1px solid #ededf5;
    }

    .bd-xs-s {
        border-left: 1px solid #ededf5;
    }

    .bd-xs-y {
        border-top: 1px solid #ededf5;
        border-bottom: 1px solid #ededf5;
    }

    .bd-xs-x {
        border-left: 1px solid #ededf5;
        border-right: 1px solid #ededf5;
    }
}

@media (min-width: 576px) {
    .bd-sm {
        border: 1px solid #ededf5;
    }

    .bd-sm-t {
        border-top: 1px solid #ededf5;
    }

    .bd-sm-e {
        border-right: 1px solid #ededf5;
    }

    .bd-sm-e-0 {
        border-right: 0 !important;
    }

    .bd-sm-b {
        border-bottom: 1px solid #ededf5;
    }

    .bd-sm-s {
        border-left: 1px solid #ededf5;
    }

    .bd-sm-s-0 {
        border-left: 0 !important;
    }

    .bd-sm-y {
        border-top: 1px solid #ededf5;
        border-bottom: 1px solid #ededf5;
    }

    .bd-sm-x {
        border-left: 1px solid #ededf5;
        border-right: 1px solid #ededf5;
    }
}

@media (min-width: 768px) {
    .bd-md {
        border: 1px solid #ededf5;
    }

    .bd-md-t {
        border-top: 1px solid #ededf5;
    }

    .bd-md-e {
        border-right: 1px solid #ededf5;
    }

    .bd-md-b {
        border-bottom: 1px solid #ededf5;
    }

    .bd-md-s {
        border-left: 1px solid #ededf5;
    }

    .bd-md-y {
        border-top: 1px solid #ededf5;
        border-bottom: 1px solid #ededf5;
    }

    .bd-md-x {
        border-left: 1px solid #ededf5;
        border-right: 1px solid #ededf5;
    }
}

@media (min-width: 992px) {
    .bd-lg {
        border: 1px solid #ededf5;
    }

    .bd-lg-t {
        border-top: 1px solid #ededf5;
    }

    .bd-lg-enf {
        border-right: 1px solid #ededf5;
    }

    .bd-lg-b {
        border-bottom: 1px solid #ededf5;
    }

    .bd-lg-s {
        border-left: 1px solid #ededf5;
    }

    .bd-lg-y {
        border-top: 1px solid #ededf5;
        border-bottom: 1px solid #ededf5;
    }

    .bd-lg-x {
        border-left: 1px solid #ededf5;
        border-right: 1px solid #ededf5;
    }
}

@media (min-width: 1200px) {
    .bd-xl {
        border: 1px solid #ededf5;
    }

    .bd-xl-t {
        border-top: 1px solid #ededf5;
    }

    .bd-xl-e {
        border-right: 1px solid #ededf5;
    }

    .bd-xl-b {
        border-bottom: 1px solid #ededf5;
    }

    .bd-xl-s {
        border-left: 1px solid #ededf5;
    }

    .bd-xl-y {
        border-top: 1px solid #ededf5;
        border-bottom: 1px solid #ededf5;
    }

    .bd-xl-x {
        border-left: 1px solid #ededf5;
        border-right: 1px solid #ededf5;
    }
}

.br-ts-0 {
    border-top-left-radius: 0 !important;
}

.br-bs-0 {
    border-bottom-left-radius: 0 !important;
}

.br-te-0 {
    border-top-right-radius: 0 !important;
}

.br-be-0 {
    border-bottom-right-radius: 0 !important;
}

.br-ts-5 {
    border-top-left-radius: 5px !important;
}

.br-te-5 {
    border-top-right-radius: 5px !important;
}

.br-be-5 {
    border-bottom-right-radius: 5px !important;
}

.br-bs-5 {
    border-bottom-left-radius: 5px !important;
}

.border-start-primary {
    border-left-color: var(--primary) !important;
}

.border-start-success {
    border-left-color: var(--success) !important;
}

.border-start-warning {
    border-left-color: var(--warning) !important;
}

.border-primary-light {
    border: 1px solid #d4d4f7;
}

.border-pink-light {
    border: 1px solid #f3a7cc;
}

.border-secondary-light {
    border: 1px solid #f1cbd3;
}

.br-5 {
    border-radius: 5px !important;
}

@media (max-width: 480px) {
    .bd-xs-e-0 {
        border-right: 0 !important;
    }

    .bd-xs-s-0 {
        border-left: 0 !important;
    }
}

@media (max-width: 991px) {
    .bd-md-e-0 {
        border-right: 0 !important;
    }
}

@media (max-width: 1024px) {
    .bd-lg-e-0 {
        border-right: 0 !important;
    }
}

@media (max-width: 1366px) {
    .bd-xl-e-0 {
        border-right: 0 !important;
    }
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.mw-100 {
    max-width: 100% !important;
}

.mh-100 {
    max-height: 100% !important;
}

.min-vw-100 {
    min-width: 100vw !important;
}

.min-vh-100 {
    min-height: 100vh !important;
}

.vw-100 {
    width: 100vw !important;
}

.vh-100 {
    height: 100vh !important;
}

/* ###### 9.3 Height   ###### */
.ht-5 {
    height: 5px;
}

.ht-5p {
    height: 5%;
}

.mx-ht-5p {
    max-height: 5%;
}

.mn-ht-5p {
    min-height: 5%;
}

.ht-5-f {
    height: 5px !important;
}

.ht-5p-f {
    height: 5% !important;
}

.ht-10 {
    height: 10px;
}

.ht-10p {
    height: 10%;
}

.mx-ht-10p {
    max-height: 10%;
}

.mn-ht-10p {
    min-height: 10%;
}

.ht-10-f {
    height: 10px !important;
}

.ht-10p-f {
    height: 10% !important;
}

.ht-15 {
    height: 15px;
}

.ht-15p {
    height: 15%;
}

.mx-ht-15p {
    max-height: 15%;
}

.mn-ht-15p {
    min-height: 15%;
}

.ht-15-f {
    height: 15px !important;
}

.ht-15p-f {
    height: 15% !important;
}

.ht-20 {
    height: 20px;
}

.ht-20p {
    height: 20%;
}

.mx-ht-20p {
    max-height: 20%;
}

.mn-ht-20p {
    min-height: 20%;
}

.ht-20-f {
    height: 20px !important;
}

.ht-20p-f {
    height: 20% !important;
}

.ht-25 {
    height: 25px;
}

.ht-25p {
    height: 25%;
}

.mx-ht-25p {
    max-height: 25%;
}

.mn-ht-25p {
    min-height: 25%;
}

.ht-25-f {
    height: 25px !important;
}

.ht-25p-f {
    height: 25% !important;
}

.ht-30 {
    height: 30px;
}

.ht-30p {
    height: 30%;
}

.mx-ht-30p {
    max-height: 30%;
}

.mn-ht-30p {
    min-height: 30%;
}

.ht-30-f {
    height: 30px !important;
}

.ht-30p-f {
    height: 30% !important;
}

.ht-35 {
    height: 35px;
}

.ht-35p {
    height: 35%;
}

.mx-ht-35p {
    max-height: 35%;
}

.mn-ht-35p {
    min-height: 35%;
}

.ht-35-f {
    height: 35px !important;
}

.ht-35p-f {
    height: 35% !important;
}

.ht-40 {
    height: 40px;
}

.ht-40p {
    height: 40%;
}

.mx-ht-40p {
    max-height: 40%;
}

.mn-ht-40p {
    min-height: 40%;
}

.ht-40-f {
    height: 40px !important;
}

.ht-40p-f {
    height: 40% !important;
}

.ht-45 {
    height: 45px;
}

.ht-45p {
    height: 45%;
}

.mx-ht-45p {
    max-height: 45%;
}

.mn-ht-45p {
    min-height: 45%;
}

.ht-45-f {
    height: 45px !important;
}

.ht-45p-f {
    height: 45% !important;
}

.ht-50 {
    height: 50px;
}

.ht-50p {
    height: 50%;
}

.mx-ht-50p {
    max-height: 50%;
}

.mn-ht-50p {
    min-height: 50%;
}

.ht-50-f {
    height: 50px !important;
}

.ht-50p-f {
    height: 50% !important;
}

.ht-55 {
    height: 55px;
}

.ht-55p {
    height: 55%;
}

.mx-ht-55p {
    max-height: 55%;
}

.mn-ht-55p {
    min-height: 55%;
}

.ht-55-f {
    height: 55px !important;
}

.ht-55p-f {
    height: 55% !important;
}

.ht-60 {
    height: 60px;
}

.ht-60p {
    height: 60%;
}

.mx-ht-60p {
    max-height: 60%;
}

.mn-ht-60p {
    min-height: 60%;
}

.ht-60-f {
    height: 60px !important;
}

.ht-60p-f {
    height: 60% !important;
}

.ht-65 {
    height: 65px;
}

.ht-65p {
    height: 65%;
}

.mx-ht-65p {
    max-height: 65%;
}

.mn-ht-65p {
    min-height: 65%;
}

.ht-65-f {
    height: 65px !important;
}

.ht-65p-f {
    height: 65% !important;
}

.ht-70 {
    height: 70px;
}

.ht-70p {
    height: 70%;
}

.mx-ht-70p {
    max-height: 70%;
}

.mn-ht-70p {
    min-height: 70%;
}

.ht-70-f {
    height: 70px !important;
}

.ht-70p-f {
    height: 70% !important;
}

.ht-75 {
    height: 75px;
}

.ht-75p {
    height: 75%;
}

.mx-ht-75p {
    max-height: 75%;
}

.mn-ht-75p {
    min-height: 75%;
}

.ht-75-f {
    height: 75px !important;
}

.ht-75p-f {
    height: 75% !important;
}

.ht-80 {
    height: 80px;
}

.ht-80p {
    height: 80%;
}

.mx-ht-80p {
    max-height: 80%;
}

.mn-ht-80p {
    min-height: 80%;
}

.ht-80-f {
    height: 80px !important;
}

.ht-80p-f {
    height: 80% !important;
}

.ht-85 {
    height: 85px;
}

.ht-85p {
    height: 85%;
}

.mx-ht-85p {
    max-height: 85%;
}

.mn-ht-85p {
    min-height: 85%;
}

.ht-85-f {
    height: 85px !important;
}

.ht-85p-f {
    height: 85% !important;
}

.ht-90 {
    height: 90px;
}

.ht-90p {
    height: 90%;
}

.mx-ht-90p {
    max-height: 90%;
}

.mn-ht-90p {
    min-height: 90%;
}

.ht-90-f {
    height: 90px !important;
}

.ht-90p-f {
    height: 90% !important;
}

.ht-95 {
    height: 95px;
}

.ht-95p {
    height: 95%;
}

.mx-ht-95p {
    max-height: 95%;
}

.mn-ht-95p {
    min-height: 95%;
}

.ht-95-f {
    height: 95px !important;
}

.ht-95p-f {
    height: 95% !important;
}

.ht-100 {
    height: 100px !important;
}

.ht-100p {
    height: 100%;
}

.mx-ht-100p {
    max-height: 100%;
}

.mn-ht-100p {
    min-height: 100%;
}

.ht-100-f {
    height: 100px !important;
}

.ht-100p-f {
    height: 100% !important;
}

.ht-136 {
    height: 136px;
}

.ht-150 {
    height: 150px;
}

.ht-175 {
    height: 175px;
}

.ht-180 {
    height: 180px;
}

.ht-200 {
    height: 200px;
}

.ht-210 {
    height: 210px;
}

.ht-220 {
    height: 220px;
}

.ht-226 {
    height: 226px;
}

.ht-230 {
    height: 230px !important;
}

.ht-238 {
    height: 238px !important;
}

.wd-230 {
    width: 230px !important;
}

.ht-250 {
    height: 250px;
}

.ht-270 {
    height: 270px;
}

.ht-300 {
    height: 300px;
}

.ht-350 {
    height: 350px;
}

.ht-400 {
    height: 400px;
}

.ht-450 {
    height: 450px;
}

.ht-500 {
    height: 500px;
}

.ht-550 {
    height: 550px;
}

.ht-600 {
    height: 600px;
}

.ht-650 {
    height: 650px;
}

.ht-700 {
    height: 700px;
}

.ht-750 {
    height: 750px;
}

.ht-800 {
    height: 800px;
}

.ht-100v {
    height: 100vh;
}

.ht-1 {
    height: 1px;
}

.ht-2 {
    height: 2px;
}

.ht-3 {
    height: 3px;
}

.ht-4 {
    height: 4px;
}

.ht-6 {
    height: 6px;
}

.ht-7 {
    height: 7px;
}

.ht-8 {
    height: 8px;
}

.ht-9 {
    height: 9px;
}

.ht-auto {
    height: auto;
}

.ht-16 {
    height: 16px;
}

.ht-32 {
    height: 32px;
}

.ht-36 {
    height: 36px;
}

.ht-48 {
    height: 48px;
}

.ht-64 {
    height: 64px;
}

.ht-72 {
    height: 72px;
}

.ht-115 {
    height: 115px;
}

.ht-120 {
    height: 120px;
}

.ht-160 {
    height: 160px;
}

@media (min-width: 480px) {
    .ht-xs-5 {
        height: 5px;
    }

    .ht-xs-5p {
        height: 5%;
    }

    .mx-ht-xs-5p {
        max-height: 5%;
    }

    .mn-ht-xs-5p {
        min-height: 5%;
    }

    .ht-xs-5-f {
        height: 5px !important;
    }

    .ht-xs-5p-f {
        height: 5% !important;
    }

    .ht-xs-10 {
        height: 10px;
    }

    .ht-xs-10p {
        height: 10%;
    }

    .mx-ht-xs-10p {
        max-height: 10%;
    }

    .mn-ht-xs-10p {
        min-height: 10%;
    }

    .ht-xs-10-f {
        height: 10px !important;
    }

    .ht-xs-10p-f {
        height: 10% !important;
    }

    .ht-xs-15 {
        height: 15px;
    }

    .ht-xs-15p {
        height: 15%;
    }

    .mx-ht-xs-15p {
        max-height: 15%;
    }

    .mn-ht-xs-15p {
        min-height: 15%;
    }

    .ht-xs-15-f {
        height: 15px !important;
    }

    .ht-xs-15p-f {
        height: 15% !important;
    }

    .ht-xs-20 {
        height: 20px;
    }

    .ht-xs-20p {
        height: 20%;
    }

    .mx-ht-xs-20p {
        max-height: 20%;
    }

    .mn-ht-xs-20p {
        min-height: 20%;
    }

    .ht-xs-20-f {
        height: 20px !important;
    }

    .ht-xs-20p-f {
        height: 20% !important;
    }

    .ht-xs-25 {
        height: 25px;
    }

    .ht-xs-25p {
        height: 25%;
    }

    .mx-ht-xs-25p {
        max-height: 25%;
    }

    .mn-ht-xs-25p {
        min-height: 25%;
    }

    .ht-xs-25-f {
        height: 25px !important;
    }

    .ht-xs-25p-f {
        height: 25% !important;
    }

    .ht-xs-30 {
        height: 30px;
    }

    .ht-xs-30p {
        height: 30%;
    }

    .mx-ht-xs-30p {
        max-height: 30%;
    }

    .mn-ht-xs-30p {
        min-height: 30%;
    }

    .ht-xs-30-f {
        height: 30px !important;
    }

    .ht-xs-30p-f {
        height: 30% !important;
    }

    .ht-xs-35 {
        height: 35px;
    }

    .ht-xs-35p {
        height: 35%;
    }

    .mx-ht-xs-35p {
        max-height: 35%;
    }

    .mn-ht-xs-35p {
        min-height: 35%;
    }

    .ht-xs-35-f {
        height: 35px !important;
    }

    .ht-xs-35p-f {
        height: 35% !important;
    }

    .ht-xs-40 {
        height: 40px;
    }

    .ht-xs-40p {
        height: 40%;
    }

    .mx-ht-xs-40p {
        max-height: 40%;
    }

    .mn-ht-xs-40p {
        min-height: 40%;
    }

    .ht-xs-40-f {
        height: 40px !important;
    }

    .ht-xs-40p-f {
        height: 40% !important;
    }

    .ht-xs-45 {
        height: 45px;
    }

    .ht-xs-45p {
        height: 45%;
    }

    .mx-ht-xs-45p {
        max-height: 45%;
    }

    .mn-ht-xs-45p {
        min-height: 45%;
    }

    .ht-xs-45-f {
        height: 45px !important;
    }

    .ht-xs-45p-f {
        height: 45% !important;
    }

    .ht-xs-50 {
        height: 50px;
    }

    .ht-xs-50p {
        height: 50%;
    }

    .mx-ht-xs-50p {
        max-height: 50%;
    }

    .mn-ht-xs-50p {
        min-height: 50%;
    }

    .ht-xs-50-f {
        height: 50px !important;
    }

    .ht-xs-50p-f {
        height: 50% !important;
    }

    .ht-xs-55 {
        height: 55px;
    }

    .ht-xs-55p {
        height: 55%;
    }

    .mx-ht-xs-55p {
        max-height: 55%;
    }

    .mn-ht-xs-55p {
        min-height: 55%;
    }

    .ht-xs-55-f {
        height: 55px !important;
    }

    .ht-xs-55p-f {
        height: 55% !important;
    }

    .ht-xs-60 {
        height: 60px;
    }

    .ht-xs-60p {
        height: 60%;
    }

    .mx-ht-xs-60p {
        max-height: 60%;
    }

    .mn-ht-xs-60p {
        min-height: 60%;
    }

    .ht-xs-60-f {
        height: 60px !important;
    }

    .ht-xs-60p-f {
        height: 60% !important;
    }

    .ht-xs-65 {
        height: 65px;
    }

    .ht-xs-65p {
        height: 65%;
    }

    .mx-ht-xs-65p {
        max-height: 65%;
    }

    .mn-ht-xs-65p {
        min-height: 65%;
    }

    .ht-xs-65-f {
        height: 65px !important;
    }

    .ht-xs-65p-f {
        height: 65% !important;
    }

    .ht-xs-70 {
        height: 70px;
    }

    .ht-xs-70p {
        height: 70%;
    }

    .mx-ht-xs-70p {
        max-height: 70%;
    }

    .mn-ht-xs-70p {
        min-height: 70%;
    }

    .ht-xs-70-f {
        height: 70px !important;
    }

    .ht-xs-70p-f {
        height: 70% !important;
    }

    .ht-xs-75 {
        height: 75px;
    }

    .ht-xs-75p {
        height: 75%;
    }

    .mx-ht-xs-75p {
        max-height: 75%;
    }

    .mn-ht-xs-75p {
        min-height: 75%;
    }

    .ht-xs-75-f {
        height: 75px !important;
    }

    .ht-xs-75p-f {
        height: 75% !important;
    }

    .ht-xs-80 {
        height: 80px;
    }

    .ht-xs-80p {
        height: 80%;
    }

    .mx-ht-xs-80p {
        max-height: 80%;
    }

    .mn-ht-xs-80p {
        min-height: 80%;
    }

    .ht-xs-80-f {
        height: 80px !important;
    }

    .ht-xs-80p-f {
        height: 80% !important;
    }

    .ht-xs-85 {
        height: 85px;
    }

    .ht-xs-85p {
        height: 85%;
    }

    .mx-ht-xs-85p {
        max-height: 85%;
    }

    .mn-ht-xs-85p {
        min-height: 85%;
    }

    .ht-xs-85-f {
        height: 85px !important;
    }

    .ht-xs-85p-f {
        height: 85% !important;
    }

    .ht-xs-90 {
        height: 90px;
    }

    .ht-xs-90p {
        height: 90%;
    }

    .mx-ht-xs-90p {
        max-height: 90%;
    }

    .mn-ht-xs-90p {
        min-height: 90%;
    }

    .ht-xs-90-f {
        height: 90px !important;
    }

    .ht-xs-90p-f {
        height: 90% !important;
    }

    .ht-xs-95 {
        height: 95px;
    }

    .ht-xs-95p {
        height: 95%;
    }

    .mx-ht-xs-95p {
        max-height: 95%;
    }

    .mn-ht-xs-95p {
        min-height: 95%;
    }

    .ht-xs-95-f {
        height: 95px !important;
    }

    .ht-xs-95p-f {
        height: 95% !important;
    }

    .ht-xs-100 {
        height: 100px;
    }

    .ht-xs-100p {
        height: 100%;
    }

    .mx-ht-xs-100p {
        max-height: 100%;
    }

    .mn-ht-xs-100p {
        min-height: 100%;
    }

    .ht-xs-100-f {
        height: 100px !important;
    }

    .ht-xs-100p-f {
        height: 100% !important;
    }

    .ht-xs-150 {
        height: 150px;
    }

    .ht-xs-150p {
        height: 150%;
    }

    .mx-ht-xs-150p {
        max-height: 150%;
    }

    .mn-ht-xs-150p {
        min-height: 150%;
    }

    .ht-xs-150-f {
        height: 150px !important;
    }

    .ht-xs-150p-f {
        height: 150% !important;
    }

    .ht-xs-200 {
        height: 200px;
    }

    .ht-xs-200p {
        height: 200%;
    }

    .mx-ht-xs-200p {
        max-height: 200%;
    }

    .mn-ht-xs-200p {
        min-height: 200%;
    }

    .ht-xs-200-f {
        height: 200px !important;
    }

    .ht-xs-200p-f {
        height: 200% !important;
    }

    .ht-xs-250 {
        height: 250px;
    }

    .ht-xs-250p {
        height: 250%;
    }

    .mx-ht-xs-250p {
        max-height: 250%;
    }

    .mn-ht-xs-250p {
        min-height: 250%;
    }

    .ht-xs-250-f {
        height: 250px !important;
    }

    .ht-xs-250p-f {
        height: 250% !important;
    }

    .ht-xs-300 {
        height: 300px;
    }

    .ht-xs-300p {
        height: 300%;
    }

    .mx-ht-xs-300p {
        max-height: 300%;
    }

    .mn-ht-xs-300p {
        min-height: 300%;
    }

    .ht-xs-300-f {
        height: 300px !important;
    }

    .ht-xs-300p-f {
        height: 300% !important;
    }

    .ht-xs-350 {
        height: 350px;
    }

    .ht-xs-350p {
        height: 350%;
    }

    .mx-ht-xs-350p {
        max-height: 350%;
    }

    .mn-ht-xs-350p {
        min-height: 350%;
    }

    .ht-xs-350-f {
        height: 350px !important;
    }

    .ht-xs-350p-f {
        height: 350% !important;
    }

    .ht-xs-400 {
        height: 400px;
    }

    .ht-xs-400p {
        height: 400%;
    }

    .mx-ht-xs-400p {
        max-height: 400%;
    }

    .mn-ht-xs-400p {
        min-height: 400%;
    }

    .ht-xs-400-f {
        height: 400px !important;
    }

    .ht-xs-400p-f {
        height: 400% !important;
    }

    .ht-xs-450 {
        height: 450px;
    }

    .ht-xs-450p {
        height: 450%;
    }

    .mx-ht-xs-450p {
        max-height: 450%;
    }

    .mn-ht-xs-450p {
        min-height: 450%;
    }

    .ht-xs-450-f {
        height: 450px !important;
    }

    .ht-xs-450p-f {
        height: 450% !important;
    }

    .ht-xs-500 {
        height: 500px;
    }

    .ht-xs-500p {
        height: 500%;
    }

    .mx-ht-xs-500p {
        max-height: 500%;
    }

    .mn-ht-xs-500p {
        min-height: 500%;
    }

    .ht-xs-500-f {
        height: 500px !important;
    }

    .ht-xs-500p-f {
        height: 500% !important;
    }

    .ht-xs-550 {
        height: 550px;
    }

    .ht-xs-550p {
        height: 550%;
    }

    .mx-ht-xs-550p {
        max-height: 550%;
    }

    .mn-ht-xs-550p {
        min-height: 550%;
    }

    .ht-xs-550-f {
        height: 550px !important;
    }

    .ht-xs-550p-f {
        height: 550% !important;
    }

    .ht-xs-600 {
        height: 600px;
    }

    .ht-xs-600p {
        height: 600%;
    }

    .mx-ht-xs-600p {
        max-height: 600%;
    }

    .mn-ht-xs-600p {
        min-height: 600%;
    }

    .ht-xs-600-f {
        height: 600px !important;
    }

    .ht-xs-600p-f {
        height: 600% !important;
    }

    .ht-xs-650 {
        height: 650px;
    }

    .ht-xs-650p {
        height: 650%;
    }

    .mx-ht-xs-650p {
        max-height: 650%;
    }

    .mn-ht-xs-650p {
        min-height: 650%;
    }

    .ht-xs-650-f {
        height: 650px !important;
    }

    .ht-xs-650p-f {
        height: 650% !important;
    }

    .ht-xs-700 {
        height: 700px;
    }

    .ht-xs-700p {
        height: 700%;
    }

    .mx-ht-xs-700p {
        max-height: 700%;
    }

    .mn-ht-xs-700p {
        min-height: 700%;
    }

    .ht-xs-700-f {
        height: 700px !important;
    }

    .ht-xs-700p-f {
        height: 700% !important;
    }

    .ht-xs-750 {
        height: 750px;
    }

    .ht-xs-750p {
        height: 750%;
    }

    .mx-ht-xs-750p {
        max-height: 750%;
    }

    .mn-ht-xs-750p {
        min-height: 750%;
    }

    .ht-xs-750-f {
        height: 750px !important;
    }

    .ht-xs-750p-f {
        height: 750% !important;
    }

    .ht-xs-800 {
        height: 800px;
    }

    .ht-xs-800p {
        height: 800%;
    }

    .mx-ht-xs-800p {
        max-height: 800%;
    }

    .mn-ht-xs-800p {
        min-height: 800%;
    }

    .ht-xs-800-f {
        height: 800px !important;
    }

    .ht-xs-800p-f {
        height: 800% !important;
    }

    .ht-xs-850 {
        height: 850px;
    }

    .ht-xs-850p {
        height: 850%;
    }

    .mx-ht-xs-850p {
        max-height: 850%;
    }

    .mn-ht-xs-850p {
        min-height: 850%;
    }

    .ht-xs-850-f {
        height: 850px !important;
    }

    .ht-xs-850p-f {
        height: 850% !important;
    }

    .ht-xs-100v {
        height: 100vh;
    }
}

@media (min-width: 576px) {
    .ht-sm-5 {
        height: 5px;
    }

    .ht-sm-5p {
        height: 5%;
    }

    .mx-ht-sm-5p {
        max-height: 5%;
    }

    .mn-ht-sm-5p {
        min-height: 5%;
    }

    .ht-sm-5-f {
        height: 5px !important;
    }

    .ht-sm-5p-f {
        height: 5% !important;
    }

    .ht-sm-10 {
        height: 10px;
    }

    .ht-sm-10p {
        height: 10%;
    }

    .mx-ht-sm-10p {
        max-height: 10%;
    }

    .mn-ht-sm-10p {
        min-height: 10%;
    }

    .ht-sm-10-f {
        height: 10px !important;
    }

    .ht-sm-10p-f {
        height: 10% !important;
    }

    .ht-sm-15 {
        height: 15px;
    }

    .ht-sm-15p {
        height: 15%;
    }

    .mx-ht-sm-15p {
        max-height: 15%;
    }

    .mn-ht-sm-15p {
        min-height: 15%;
    }

    .ht-sm-15-f {
        height: 15px !important;
    }

    .ht-sm-15p-f {
        height: 15% !important;
    }

    .ht-sm-20 {
        height: 20px;
    }

    .ht-sm-20p {
        height: 20%;
    }

    .mx-ht-sm-20p {
        max-height: 20%;
    }

    .mn-ht-sm-20p {
        min-height: 20%;
    }

    .ht-sm-20-f {
        height: 20px !important;
    }

    .ht-sm-20p-f {
        height: 20% !important;
    }

    .ht-sm-25 {
        height: 25px;
    }

    .ht-sm-25p {
        height: 25%;
    }

    .mx-ht-sm-25p {
        max-height: 25%;
    }

    .mn-ht-sm-25p {
        min-height: 25%;
    }

    .ht-sm-25-f {
        height: 25px !important;
    }

    .ht-sm-25p-f {
        height: 25% !important;
    }

    .ht-sm-30 {
        height: 30px;
    }

    .ht-sm-30p {
        height: 30%;
    }

    .mx-ht-sm-30p {
        max-height: 30%;
    }

    .mn-ht-sm-30p {
        min-height: 30%;
    }

    .ht-sm-30-f {
        height: 30px !important;
    }

    .ht-sm-30p-f {
        height: 30% !important;
    }

    .ht-sm-35 {
        height: 35px;
    }

    .ht-sm-35p {
        height: 35%;
    }

    .mx-ht-sm-35p {
        max-height: 35%;
    }

    .mn-ht-sm-35p {
        min-height: 35%;
    }

    .ht-sm-35-f {
        height: 35px !important;
    }

    .ht-sm-35p-f {
        height: 35% !important;
    }

    .ht-sm-40 {
        height: 40px;
    }

    .ht-sm-40p {
        height: 40%;
    }

    .mx-ht-sm-40p {
        max-height: 40%;
    }

    .mn-ht-sm-40p {
        min-height: 40%;
    }

    .ht-sm-40-f {
        height: 40px !important;
    }

    .ht-sm-40p-f {
        height: 40% !important;
    }

    .ht-sm-45 {
        height: 45px;
    }

    .ht-sm-45p {
        height: 45%;
    }

    .mx-ht-sm-45p {
        max-height: 45%;
    }

    .mn-ht-sm-45p {
        min-height: 45%;
    }

    .ht-sm-45-f {
        height: 45px !important;
    }

    .ht-sm-45p-f {
        height: 45% !important;
    }

    .ht-sm-50 {
        height: 50px;
    }

    .ht-sm-50p {
        height: 50%;
    }

    .mx-ht-sm-50p {
        max-height: 50%;
    }

    .mn-ht-sm-50p {
        min-height: 50%;
    }

    .ht-sm-50-f {
        height: 50px !important;
    }

    .ht-sm-50p-f {
        height: 50% !important;
    }

    .ht-sm-55 {
        height: 55px;
    }

    .ht-sm-55p {
        height: 55%;
    }

    .mx-ht-sm-55p {
        max-height: 55%;
    }

    .mn-ht-sm-55p {
        min-height: 55%;
    }

    .ht-sm-55-f {
        height: 55px !important;
    }

    .ht-sm-55p-f {
        height: 55% !important;
    }

    .ht-sm-60 {
        height: 60px;
    }

    .ht-sm-60p {
        height: 60%;
    }

    .mx-ht-sm-60p {
        max-height: 60%;
    }

    .mn-ht-sm-60p {
        min-height: 60%;
    }

    .ht-sm-60-f {
        height: 60px !important;
    }

    .ht-sm-60p-f {
        height: 60% !important;
    }

    .ht-sm-65 {
        height: 65px;
    }

    .ht-sm-65p {
        height: 65%;
    }

    .mx-ht-sm-65p {
        max-height: 65%;
    }

    .mn-ht-sm-65p {
        min-height: 65%;
    }

    .ht-sm-65-f {
        height: 65px !important;
    }

    .ht-sm-65p-f {
        height: 65% !important;
    }

    .ht-sm-70 {
        height: 70px;
    }

    .ht-sm-70p {
        height: 70%;
    }

    .mx-ht-sm-70p {
        max-height: 70%;
    }

    .mn-ht-sm-70p {
        min-height: 70%;
    }

    .ht-sm-70-f {
        height: 70px !important;
    }

    .ht-sm-70p-f {
        height: 70% !important;
    }

    .ht-sm-75 {
        height: 75px;
    }

    .ht-sm-75p {
        height: 75%;
    }

    .mx-ht-sm-75p {
        max-height: 75%;
    }

    .mn-ht-sm-75p {
        min-height: 75%;
    }

    .ht-sm-75-f {
        height: 75px !important;
    }

    .ht-sm-75p-f {
        height: 75% !important;
    }

    .ht-sm-80 {
        height: 80px;
    }

    .ht-sm-80p {
        height: 80%;
    }

    .mx-ht-sm-80p {
        max-height: 80%;
    }

    .mn-ht-sm-80p {
        min-height: 80%;
    }

    .ht-sm-80-f {
        height: 80px !important;
    }

    .ht-sm-80p-f {
        height: 80% !important;
    }

    .ht-sm-85 {
        height: 85px;
    }

    .ht-sm-85p {
        height: 85%;
    }

    .mx-ht-sm-85p {
        max-height: 85%;
    }

    .mn-ht-sm-85p {
        min-height: 85%;
    }

    .ht-sm-85-f {
        height: 85px !important;
    }

    .ht-sm-85p-f {
        height: 85% !important;
    }

    .ht-sm-90 {
        height: 90px;
    }

    .ht-sm-90p {
        height: 90%;
    }

    .mx-ht-sm-90p {
        max-height: 90%;
    }

    .mn-ht-sm-90p {
        min-height: 90%;
    }

    .ht-sm-90-f {
        height: 90px !important;
    }

    .ht-sm-90p-f {
        height: 90% !important;
    }

    .ht-sm-95 {
        height: 95px;
    }

    .ht-sm-95p {
        height: 95%;
    }

    .mx-ht-sm-95p {
        max-height: 95%;
    }

    .mn-ht-sm-95p {
        min-height: 95%;
    }

    .ht-sm-95-f {
        height: 95px !important;
    }

    .ht-sm-95p-f {
        height: 95% !important;
    }

    .ht-sm-100 {
        height: 100px;
    }

    .ht-sm-100p {
        height: 100%;
    }

    .mx-ht-sm-100p {
        max-height: 100%;
    }

    .mn-ht-sm-100p {
        min-height: 100%;
    }

    .ht-sm-100-f {
        height: 100px !important;
    }

    .ht-sm-100p-f {
        height: 100% !important;
    }

    .ht-sm-150 {
        height: 150px;
    }

    .ht-sm-150p {
        height: 150%;
    }

    .mx-ht-sm-150p {
        max-height: 150%;
    }

    .mn-ht-sm-150p {
        min-height: 150%;
    }

    .ht-sm-150-f {
        height: 150px !important;
    }

    .ht-sm-150p-f {
        height: 150% !important;
    }

    .ht-sm-200 {
        height: 200px;
    }

    .ht-sm-200p {
        height: 200%;
    }

    .mx-ht-sm-200p {
        max-height: 200%;
    }

    .mn-ht-sm-200p {
        min-height: 200%;
    }

    .ht-sm-200-f {
        height: 200px !important;
    }

    .ht-sm-200p-f {
        height: 200% !important;
    }

    .ht-sm-250 {
        height: 250px;
    }

    .ht-sm-250p {
        height: 250%;
    }

    .mx-ht-sm-250p {
        max-height: 250%;
    }

    .mn-ht-sm-250p {
        min-height: 250%;
    }

    .ht-sm-250-f {
        height: 250px !important;
    }

    .ht-sm-250p-f {
        height: 250% !important;
    }

    .ht-sm-300 {
        height: 300px;
    }

    .ht-sm-300p {
        height: 300%;
    }

    .mx-ht-sm-300p {
        max-height: 300%;
    }

    .mn-ht-sm-300p {
        min-height: 300%;
    }

    .ht-sm-300-f {
        height: 300px !important;
    }

    .ht-sm-300p-f {
        height: 300% !important;
    }

    .ht-sm-350 {
        height: 350px;
    }

    .ht-sm-350p {
        height: 350%;
    }

    .mx-ht-sm-350p {
        max-height: 350%;
    }

    .mn-ht-sm-350p {
        min-height: 350%;
    }

    .ht-sm-350-f {
        height: 350px !important;
    }

    .ht-sm-350p-f {
        height: 350% !important;
    }

    .ht-sm-400 {
        height: 400px;
    }

    .ht-sm-400p {
        height: 400%;
    }

    .mx-ht-sm-400p {
        max-height: 400%;
    }

    .mn-ht-sm-400p {
        min-height: 400%;
    }

    .ht-sm-400-f {
        height: 400px !important;
    }

    .ht-sm-400p-f {
        height: 400% !important;
    }

    .ht-sm-450 {
        height: 450px;
    }

    .ht-sm-450p {
        height: 450%;
    }

    .mx-ht-sm-450p {
        max-height: 450%;
    }

    .mn-ht-sm-450p {
        min-height: 450%;
    }

    .ht-sm-450-f {
        height: 450px !important;
    }

    .ht-sm-450p-f {
        height: 450% !important;
    }

    .ht-sm-500 {
        height: 500px;
    }

    .ht-sm-500p {
        height: 500%;
    }

    .mx-ht-sm-500p {
        max-height: 500%;
    }

    .mn-ht-sm-500p {
        min-height: 500%;
    }

    .ht-sm-500-f {
        height: 500px !important;
    }

    .ht-sm-500p-f {
        height: 500% !important;
    }

    .ht-sm-550 {
        height: 550px;
    }

    .ht-sm-550p {
        height: 550%;
    }

    .mx-ht-sm-550p {
        max-height: 550%;
    }

    .mn-ht-sm-550p {
        min-height: 550%;
    }

    .ht-sm-550-f {
        height: 550px !important;
    }

    .ht-sm-550p-f {
        height: 550% !important;
    }

    .ht-sm-600 {
        height: 600px;
    }

    .ht-sm-600p {
        height: 600%;
    }

    .mx-ht-sm-600p {
        max-height: 600%;
    }

    .mn-ht-sm-600p {
        min-height: 600%;
    }

    .ht-sm-600-f {
        height: 600px !important;
    }

    .ht-sm-600p-f {
        height: 600% !important;
    }

    .ht-sm-650 {
        height: 650px;
    }

    .ht-sm-650p {
        height: 650%;
    }

    .mx-ht-sm-650p {
        max-height: 650%;
    }

    .mn-ht-sm-650p {
        min-height: 650%;
    }

    .ht-sm-650-f {
        height: 650px !important;
    }

    .ht-sm-650p-f {
        height: 650% !important;
    }

    .ht-sm-700 {
        height: 700px;
    }

    .ht-sm-700p {
        height: 700%;
    }

    .mx-ht-sm-700p {
        max-height: 700%;
    }

    .mn-ht-sm-700p {
        min-height: 700%;
    }

    .ht-sm-700-f {
        height: 700px !important;
    }

    .ht-sm-700p-f {
        height: 700% !important;
    }

    .ht-sm-750 {
        height: 750px;
    }

    .ht-sm-750p {
        height: 750%;
    }

    .mx-ht-sm-750p {
        max-height: 750%;
    }

    .mn-ht-sm-750p {
        min-height: 750%;
    }

    .ht-sm-750-f {
        height: 750px !important;
    }

    .ht-sm-750p-f {
        height: 750% !important;
    }

    .ht-sm-800 {
        height: 800px;
    }

    .ht-sm-800p {
        height: 800%;
    }

    .mx-ht-sm-800p {
        max-height: 800%;
    }

    .mn-ht-sm-800p {
        min-height: 800%;
    }

    .ht-sm-800-f {
        height: 800px !important;
    }

    .ht-sm-800p-f {
        height: 800% !important;
    }

    .ht-sm-850 {
        height: 850px;
    }

    .ht-sm-850p {
        height: 850%;
    }

    .mx-ht-sm-850p {
        max-height: 850%;
    }

    .mn-ht-sm-850p {
        min-height: 850%;
    }

    .ht-sm-850-f {
        height: 850px !important;
    }

    .ht-sm-850p-f {
        height: 850% !important;
    }

    .ht-sm-100v {
        height: 100vh;
    }
}

@media (min-width: 768px) {
    .ht-md-5 {
        height: 5px;
    }

    .ht-md-5p {
        height: 5%;
    }

    .mx-ht-md-5p {
        max-height: 5%;
    }

    .mn-ht-md-5p {
        min-height: 5%;
    }

    .ht-md-5-f {
        height: 5px !important;
    }

    .ht-md-5p-f {
        height: 5% !important;
    }

    .ht-md-10 {
        height: 10px;
    }

    .ht-md-10p {
        height: 10%;
    }

    .mx-ht-md-10p {
        max-height: 10%;
    }

    .mn-ht-md-10p {
        min-height: 10%;
    }

    .ht-md-10-f {
        height: 10px !important;
    }

    .ht-md-10p-f {
        height: 10% !important;
    }

    .ht-md-15 {
        height: 15px;
    }

    .ht-md-15p {
        height: 15%;
    }

    .mx-ht-md-15p {
        max-height: 15%;
    }

    .mn-ht-md-15p {
        min-height: 15%;
    }

    .ht-md-15-f {
        height: 15px !important;
    }

    .ht-md-15p-f {
        height: 15% !important;
    }

    .ht-md-20 {
        height: 20px;
    }

    .ht-md-20p {
        height: 20%;
    }

    .mx-ht-md-20p {
        max-height: 20%;
    }

    .mn-ht-md-20p {
        min-height: 20%;
    }

    .ht-md-20-f {
        height: 20px !important;
    }

    .ht-md-20p-f {
        height: 20% !important;
    }

    .ht-md-25 {
        height: 25px;
    }

    .ht-md-25p {
        height: 25%;
    }

    .mx-ht-md-25p {
        max-height: 25%;
    }

    .mn-ht-md-25p {
        min-height: 25%;
    }

    .ht-md-25-f {
        height: 25px !important;
    }

    .ht-md-25p-f {
        height: 25% !important;
    }

    .ht-md-30 {
        height: 30px;
    }

    .ht-md-30p {
        height: 30%;
    }

    .mx-ht-md-30p {
        max-height: 30%;
    }

    .mn-ht-md-30p {
        min-height: 30%;
    }

    .ht-md-30-f {
        height: 30px !important;
    }

    .ht-md-30p-f {
        height: 30% !important;
    }

    .ht-md-35 {
        height: 35px;
    }

    .ht-md-35p {
        height: 35%;
    }

    .mx-ht-md-35p {
        max-height: 35%;
    }

    .mn-ht-md-35p {
        min-height: 35%;
    }

    .ht-md-35-f {
        height: 35px !important;
    }

    .ht-md-35p-f {
        height: 35% !important;
    }

    .ht-md-40 {
        height: 40px;
    }

    .ht-md-40p {
        height: 40%;
    }

    .mx-ht-md-40p {
        max-height: 40%;
    }

    .mn-ht-md-40p {
        min-height: 40%;
    }

    .ht-md-40-f {
        height: 40px !important;
    }

    .ht-md-40p-f {
        height: 40% !important;
    }

    .ht-md-45 {
        height: 45px;
    }

    .ht-md-45p {
        height: 45%;
    }

    .mx-ht-md-45p {
        max-height: 45%;
    }

    .mn-ht-md-45p {
        min-height: 45%;
    }

    .ht-md-45-f {
        height: 45px !important;
    }

    .ht-md-45p-f {
        height: 45% !important;
    }

    .ht-md-50 {
        height: 50px;
    }

    .ht-md-50p {
        height: 50%;
    }

    .mx-ht-md-50p {
        max-height: 50%;
    }

    .mn-ht-md-50p {
        min-height: 50%;
    }

    .ht-md-50-f {
        height: 50px !important;
    }

    .ht-md-50p-f {
        height: 50% !important;
    }

    .ht-md-55 {
        height: 55px;
    }

    .ht-md-55p {
        height: 55%;
    }

    .mx-ht-md-55p {
        max-height: 55%;
    }

    .mn-ht-md-55p {
        min-height: 55%;
    }

    .ht-md-55-f {
        height: 55px !important;
    }

    .ht-md-55p-f {
        height: 55% !important;
    }

    .ht-md-60 {
        height: 60px;
    }

    .ht-md-60p {
        height: 60%;
    }

    .mx-ht-md-60p {
        max-height: 60%;
    }

    .mn-ht-md-60p {
        min-height: 60%;
    }

    .ht-md-60-f {
        height: 60px !important;
    }

    .ht-md-60p-f {
        height: 60% !important;
    }

    .ht-md-65 {
        height: 65px;
    }

    .ht-md-65p {
        height: 65%;
    }

    .mx-ht-md-65p {
        max-height: 65%;
    }

    .mn-ht-md-65p {
        min-height: 65%;
    }

    .ht-md-65-f {
        height: 65px !important;
    }

    .ht-md-65p-f {
        height: 65% !important;
    }

    .ht-md-70 {
        height: 70px;
    }

    .ht-md-70p {
        height: 70%;
    }

    .mx-ht-md-70p {
        max-height: 70%;
    }

    .mn-ht-md-70p {
        min-height: 70%;
    }

    .ht-md-70-f {
        height: 70px !important;
    }

    .ht-md-70p-f {
        height: 70% !important;
    }

    .ht-md-75 {
        height: 75px;
    }

    .ht-md-75p {
        height: 75%;
    }

    .mx-ht-md-75p {
        max-height: 75%;
    }

    .mn-ht-md-75p {
        min-height: 75%;
    }

    .ht-md-75-f {
        height: 75px !important;
    }

    .ht-md-75p-f {
        height: 75% !important;
    }

    .ht-md-80 {
        height: 80px;
    }

    .ht-md-80p {
        height: 80%;
    }

    .mx-ht-md-80p {
        max-height: 80%;
    }

    .mn-ht-md-80p {
        min-height: 80%;
    }

    .ht-md-80-f {
        height: 80px !important;
    }

    .ht-md-80p-f {
        height: 80% !important;
    }

    .ht-md-85 {
        height: 85px;
    }

    .ht-md-85p {
        height: 85%;
    }

    .mx-ht-md-85p {
        max-height: 85%;
    }

    .mn-ht-md-85p {
        min-height: 85%;
    }

    .ht-md-85-f {
        height: 85px !important;
    }

    .ht-md-85p-f {
        height: 85% !important;
    }

    .ht-md-90 {
        height: 90px;
    }

    .ht-md-90p {
        height: 90%;
    }

    .mx-ht-md-90p {
        max-height: 90%;
    }

    .mn-ht-md-90p {
        min-height: 90%;
    }

    .ht-md-90-f {
        height: 90px !important;
    }

    .ht-md-90p-f {
        height: 90% !important;
    }

    .ht-md-95 {
        height: 95px;
    }

    .ht-md-95p {
        height: 95%;
    }

    .mx-ht-md-95p {
        max-height: 95%;
    }

    .mn-ht-md-95p {
        min-height: 95%;
    }

    .ht-md-95-f {
        height: 95px !important;
    }

    .ht-md-95p-f {
        height: 95% !important;
    }

    .ht-md-100 {
        height: 100px;
    }

    .ht-md-100p {
        height: 100%;
    }

    .mx-ht-md-100p {
        max-height: 100%;
    }

    .mn-ht-md-100p {
        min-height: 100%;
    }

    .ht-md-100-f {
        height: 100px !important;
    }

    .ht-md-100p-f {
        height: 100% !important;
    }

    .ht-md-150 {
        height: 150px;
    }

    .ht-md-150p {
        height: 150%;
    }

    .mx-ht-md-150p {
        max-height: 150%;
    }

    .mn-ht-md-150p {
        min-height: 150%;
    }

    .ht-md-150-f {
        height: 150px !important;
    }

    .ht-md-150p-f {
        height: 150% !important;
    }

    .ht-md-200 {
        height: 200px;
    }

    .ht-md-200p {
        height: 200%;
    }

    .mx-ht-md-200p {
        max-height: 200%;
    }

    .mn-ht-md-200p {
        min-height: 200%;
    }

    .ht-md-200-f {
        height: 200px !important;
    }

    .ht-md-200p-f {
        height: 200% !important;
    }

    .ht-md-250 {
        height: 250px;
    }

    .ht-md-250p {
        height: 250%;
    }

    .mx-ht-md-250p {
        max-height: 250%;
    }

    .mn-ht-md-250p {
        min-height: 250%;
    }

    .ht-md-250-f {
        height: 250px !important;
    }

    .ht-md-250p-f {
        height: 250% !important;
    }

    .ht-md-300 {
        height: 300px;
    }

    .ht-md-300p {
        height: 300%;
    }

    .mx-ht-md-300p {
        max-height: 300%;
    }

    .mn-ht-md-300p {
        min-height: 300%;
    }

    .ht-md-300-f {
        height: 300px !important;
    }

    .ht-md-300p-f {
        height: 300% !important;
    }

    .ht-md-350 {
        height: 350px;
    }

    .ht-md-350p {
        height: 350%;
    }

    .mx-ht-md-350p {
        max-height: 350%;
    }

    .mn-ht-md-350p {
        min-height: 350%;
    }

    .ht-md-350-f {
        height: 350px !important;
    }

    .ht-md-350p-f {
        height: 350% !important;
    }

    .ht-md-400 {
        height: 400px;
    }

    .ht-md-400p {
        height: 400%;
    }

    .mx-ht-md-400p {
        max-height: 400%;
    }

    .mn-ht-md-400p {
        min-height: 400%;
    }

    .ht-md-400-f {
        height: 400px !important;
    }

    .ht-md-400p-f {
        height: 400% !important;
    }

    .ht-md-450 {
        height: 450px;
    }

    .ht-md-450p {
        height: 450%;
    }

    .mx-ht-md-450p {
        max-height: 450%;
    }

    .mn-ht-md-450p {
        min-height: 450%;
    }

    .ht-md-450-f {
        height: 450px !important;
    }

    .ht-md-450p-f {
        height: 450% !important;
    }

    .ht-md-500 {
        height: 500px;
    }

    .ht-md-500p {
        height: 500%;
    }

    .mx-ht-md-500p {
        max-height: 500%;
    }

    .mn-ht-md-500p {
        min-height: 500%;
    }

    .ht-md-500-f {
        height: 500px !important;
    }

    .ht-md-500p-f {
        height: 500% !important;
    }

    .ht-md-550 {
        height: 550px;
    }

    .ht-md-550p {
        height: 550%;
    }

    .mx-ht-md-550p {
        max-height: 550%;
    }

    .mn-ht-md-550p {
        min-height: 550%;
    }

    .ht-md-550-f {
        height: 550px !important;
    }

    .ht-md-550p-f {
        height: 550% !important;
    }

    .ht-md-600 {
        height: 600px;
    }

    .ht-md-600p {
        height: 600%;
    }

    .mx-ht-md-600p {
        max-height: 600%;
    }

    .mn-ht-md-600p {
        min-height: 600%;
    }

    .ht-md-600-f {
        height: 600px !important;
    }

    .ht-md-600p-f {
        height: 600% !important;
    }

    .ht-md-650 {
        height: 650px;
    }

    .ht-md-650p {
        height: 650%;
    }

    .mx-ht-md-650p {
        max-height: 650%;
    }

    .mn-ht-md-650p {
        min-height: 650%;
    }

    .ht-md-650-f {
        height: 650px !important;
    }

    .ht-md-650p-f {
        height: 650% !important;
    }

    .ht-md-700 {
        height: 700px;
    }

    .ht-md-700p {
        height: 700%;
    }

    .mx-ht-md-700p {
        max-height: 700%;
    }

    .mn-ht-md-700p {
        min-height: 700%;
    }

    .ht-md-700-f {
        height: 700px !important;
    }

    .ht-md-700p-f {
        height: 700% !important;
    }

    .ht-md-750 {
        height: 750px;
    }

    .ht-md-750p {
        height: 750%;
    }

    .mx-ht-md-750p {
        max-height: 750%;
    }

    .mn-ht-md-750p {
        min-height: 750%;
    }

    .ht-md-750-f {
        height: 750px !important;
    }

    .ht-md-750p-f {
        height: 750% !important;
    }

    .ht-md-800 {
        height: 800px;
    }

    .ht-md-800p {
        height: 800%;
    }

    .mx-ht-md-800p {
        max-height: 800%;
    }

    .mn-ht-md-800p {
        min-height: 800%;
    }

    .ht-md-800-f {
        height: 800px !important;
    }

    .ht-md-800p-f {
        height: 800% !important;
    }

    .ht-md-850 {
        height: 850px;
    }

    .ht-md-850p {
        height: 850%;
    }

    .mx-ht-md-850p {
        max-height: 850%;
    }

    .mn-ht-md-850p {
        min-height: 850%;
    }

    .ht-md-850-f {
        height: 850px !important;
    }

    .ht-md-850p-f {
        height: 850% !important;
    }

    .ht-md-100v {
        height: 100vh;
    }
}

@media (min-width: 992px) {
    .ht-lg-5 {
        height: 5px;
    }

    .ht-lg-5p {
        height: 5%;
    }

    .mx-ht-lg-5p {
        max-height: 5%;
    }

    .mn-ht-lg-5p {
        min-height: 5%;
    }

    .ht-lg-5-f {
        height: 5px !important;
    }

    .ht-lg-5p-f {
        height: 5% !important;
    }

    .ht-lg-10 {
        height: 10px;
    }

    .ht-lg-10p {
        height: 10%;
    }

    .mx-ht-lg-10p {
        max-height: 10%;
    }

    .mn-ht-lg-10p {
        min-height: 10%;
    }

    .ht-lg-10-f {
        height: 10px !important;
    }

    .ht-lg-10p-f {
        height: 10% !important;
    }

    .ht-lg-15 {
        height: 15px;
    }

    .ht-lg-15p {
        height: 15%;
    }

    .mx-ht-lg-15p {
        max-height: 15%;
    }

    .mn-ht-lg-15p {
        min-height: 15%;
    }

    .ht-lg-15-f {
        height: 15px !important;
    }

    .ht-lg-15p-f {
        height: 15% !important;
    }

    .ht-lg-20 {
        height: 20px;
    }

    .ht-lg-20p {
        height: 20%;
    }

    .mx-ht-lg-20p {
        max-height: 20%;
    }

    .mn-ht-lg-20p {
        min-height: 20%;
    }

    .ht-lg-20-f {
        height: 20px !important;
    }

    .ht-lg-20p-f {
        height: 20% !important;
    }

    .ht-lg-25 {
        height: 25px;
    }

    .ht-lg-25p {
        height: 25%;
    }

    .mx-ht-lg-25p {
        max-height: 25%;
    }

    .mn-ht-lg-25p {
        min-height: 25%;
    }

    .ht-lg-25-f {
        height: 25px !important;
    }

    .ht-lg-25p-f {
        height: 25% !important;
    }

    .ht-lg-30 {
        height: 30px;
    }

    .ht-lg-30p {
        height: 30%;
    }

    .mx-ht-lg-30p {
        max-height: 30%;
    }

    .mn-ht-lg-30p {
        min-height: 30%;
    }

    .ht-lg-30-f {
        height: 30px !important;
    }

    .ht-lg-30p-f {
        height: 30% !important;
    }

    .ht-lg-35 {
        height: 35px;
    }

    .ht-lg-35p {
        height: 35%;
    }

    .mx-ht-lg-35p {
        max-height: 35%;
    }

    .mn-ht-lg-35p {
        min-height: 35%;
    }

    .ht-lg-35-f {
        height: 35px !important;
    }

    .ht-lg-35p-f {
        height: 35% !important;
    }

    .ht-lg-40 {
        height: 40px;
    }

    .ht-lg-40p {
        height: 40%;
    }

    .mx-ht-lg-40p {
        max-height: 40%;
    }

    .mn-ht-lg-40p {
        min-height: 40%;
    }

    .ht-lg-40-f {
        height: 40px !important;
    }

    .ht-lg-40p-f {
        height: 40% !important;
    }

    .ht-lg-45 {
        height: 45px;
    }

    .ht-lg-45p {
        height: 45%;
    }

    .mx-ht-lg-45p {
        max-height: 45%;
    }

    .mn-ht-lg-45p {
        min-height: 45%;
    }

    .ht-lg-45-f {
        height: 45px !important;
    }

    .ht-lg-45p-f {
        height: 45% !important;
    }

    .ht-lg-50 {
        height: 50px;
    }

    .ht-lg-50p {
        height: 50%;
    }

    .mx-ht-lg-50p {
        max-height: 50%;
    }

    .mn-ht-lg-50p {
        min-height: 50%;
    }

    .ht-lg-50-f {
        height: 50px !important;
    }

    .ht-lg-50p-f {
        height: 50% !important;
    }

    .ht-lg-55 {
        height: 55px;
    }

    .ht-lg-55p {
        height: 55%;
    }

    .mx-ht-lg-55p {
        max-height: 55%;
    }

    .mn-ht-lg-55p {
        min-height: 55%;
    }

    .ht-lg-55-f {
        height: 55px !important;
    }

    .ht-lg-55p-f {
        height: 55% !important;
    }

    .ht-lg-60 {
        height: 60px;
    }

    .ht-lg-60p {
        height: 60%;
    }

    .mx-ht-lg-60p {
        max-height: 60%;
    }

    .mn-ht-lg-60p {
        min-height: 60%;
    }

    .ht-lg-60-f {
        height: 60px !important;
    }

    .ht-lg-60p-f {
        height: 60% !important;
    }

    .ht-lg-65 {
        height: 65px;
    }

    .ht-lg-65p {
        height: 65%;
    }

    .mx-ht-lg-65p {
        max-height: 65%;
    }

    .mn-ht-lg-65p {
        min-height: 65%;
    }

    .ht-lg-65-f {
        height: 65px !important;
    }

    .ht-lg-65p-f {
        height: 65% !important;
    }

    .ht-lg-70 {
        height: 70px;
    }

    .ht-lg-70p {
        height: 70%;
    }

    .mx-ht-lg-70p {
        max-height: 70%;
    }

    .mn-ht-lg-70p {
        min-height: 70%;
    }

    .ht-lg-70-f {
        height: 70px !important;
    }

    .ht-lg-70p-f {
        height: 70% !important;
    }

    .ht-lg-75 {
        height: 75px;
    }

    .ht-lg-75p {
        height: 75%;
    }

    .mx-ht-lg-75p {
        max-height: 75%;
    }

    .mn-ht-lg-75p {
        min-height: 75%;
    }

    .ht-lg-75-f {
        height: 75px !important;
    }

    .ht-lg-75p-f {
        height: 75% !important;
    }

    .ht-lg-80 {
        height: 80px;
    }

    .ht-lg-80p {
        height: 80%;
    }

    .mx-ht-lg-80p {
        max-height: 80%;
    }

    .mn-ht-lg-80p {
        min-height: 80%;
    }

    .ht-lg-80-f {
        height: 80px !important;
    }

    .ht-lg-80p-f {
        height: 80% !important;
    }

    .ht-lg-85 {
        height: 85px;
    }

    .ht-lg-85p {
        height: 85%;
    }

    .mx-ht-lg-85p {
        max-height: 85%;
    }

    .mn-ht-lg-85p {
        min-height: 85%;
    }

    .ht-lg-85-f {
        height: 85px !important;
    }

    .ht-lg-85p-f {
        height: 85% !important;
    }

    .ht-lg-90 {
        height: 90px;
    }

    .ht-lg-90p {
        height: 90%;
    }

    .mx-ht-lg-90p {
        max-height: 90%;
    }

    .mn-ht-lg-90p {
        min-height: 90%;
    }

    .ht-lg-90-f {
        height: 90px !important;
    }

    .ht-lg-90p-f {
        height: 90% !important;
    }

    .ht-lg-95 {
        height: 95px;
    }

    .ht-lg-95p {
        height: 95%;
    }

    .mx-ht-lg-95p {
        max-height: 95%;
    }

    .mn-ht-lg-95p {
        min-height: 95%;
    }

    .ht-lg-95-f {
        height: 95px !important;
    }

    .ht-lg-95p-f {
        height: 95% !important;
    }

    .ht-lg-100 {
        height: 100px;
    }

    .ht-lg-100p {
        height: 100%;
    }

    .mx-ht-lg-100p {
        max-height: 100%;
    }

    .mn-ht-lg-100p {
        min-height: 100%;
    }

    .ht-lg-100-f {
        height: 100px !important;
    }

    .ht-lg-100p-f {
        height: 100% !important;
    }

    .ht-lg-150 {
        height: 150px;
    }

    .ht-lg-150p {
        height: 150%;
    }

    .mx-ht-lg-150p {
        max-height: 150%;
    }

    .mn-ht-lg-150p {
        min-height: 150%;
    }

    .ht-lg-150-f {
        height: 150px !important;
    }

    .ht-lg-150p-f {
        height: 150% !important;
    }

    .ht-lg-200 {
        height: 200px;
    }

    .ht-lg-200p {
        height: 200%;
    }

    .mx-ht-lg-200p {
        max-height: 200%;
    }

    .mn-ht-lg-200p {
        min-height: 200%;
    }

    .ht-lg-200-f {
        height: 200px !important;
    }

    .ht-lg-200p-f {
        height: 200% !important;
    }

    .ht-lg-250 {
        height: 250px;
    }

    .ht-lg-250p {
        height: 250%;
    }

    .mx-ht-lg-250p {
        max-height: 250%;
    }

    .mn-ht-lg-250p {
        min-height: 250%;
    }

    .ht-lg-250-f {
        height: 250px !important;
    }

    .ht-lg-250p-f {
        height: 250% !important;
    }

    .ht-lg-300 {
        height: 300px;
    }

    .ht-lg-300p {
        height: 300%;
    }

    .mx-ht-lg-300p {
        max-height: 300%;
    }

    .mn-ht-lg-300p {
        min-height: 300%;
    }

    .ht-lg-300-f {
        height: 300px !important;
    }

    .ht-lg-300p-f {
        height: 300% !important;
    }

    .ht-lg-350 {
        height: 350px;
    }

    .ht-lg-350p {
        height: 350%;
    }

    .mx-ht-lg-350p {
        max-height: 350%;
    }

    .mn-ht-lg-350p {
        min-height: 350%;
    }

    .ht-lg-350-f {
        height: 350px !important;
    }

    .ht-lg-350p-f {
        height: 350% !important;
    }

    .ht-lg-400 {
        height: 400px;
    }

    .ht-lg-400p {
        height: 400%;
    }

    .mx-ht-lg-400p {
        max-height: 400%;
    }

    .mn-ht-lg-400p {
        min-height: 400%;
    }

    .ht-lg-400-f {
        height: 400px !important;
    }

    .ht-lg-400p-f {
        height: 400% !important;
    }

    .ht-lg-450 {
        height: 450px;
    }

    .ht-lg-450p {
        height: 450%;
    }

    .mx-ht-lg-450p {
        max-height: 450%;
    }

    .mn-ht-lg-450p {
        min-height: 450%;
    }

    .ht-lg-450-f {
        height: 450px !important;
    }

    .ht-lg-450p-f {
        height: 450% !important;
    }

    .ht-lg-500 {
        height: 500px;
    }

    .ht-lg-500p {
        height: 500%;
    }

    .mx-ht-lg-500p {
        max-height: 500%;
    }

    .mn-ht-lg-500p {
        min-height: 500%;
    }

    .ht-lg-500-f {
        height: 500px !important;
    }

    .ht-lg-500p-f {
        height: 500% !important;
    }

    .ht-lg-550 {
        height: 550px;
    }

    .ht-lg-550p {
        height: 550%;
    }

    .mx-ht-lg-550p {
        max-height: 550%;
    }

    .mn-ht-lg-550p {
        min-height: 550%;
    }

    .ht-lg-550-f {
        height: 550px !important;
    }

    .ht-lg-550p-f {
        height: 550% !important;
    }

    .ht-lg-600 {
        height: 600px;
    }

    .ht-lg-600p {
        height: 600%;
    }

    .mx-ht-lg-600p {
        max-height: 600%;
    }

    .mn-ht-lg-600p {
        min-height: 600%;
    }

    .ht-lg-600-f {
        height: 600px !important;
    }

    .ht-lg-600p-f {
        height: 600% !important;
    }

    .ht-lg-650 {
        height: 650px;
    }

    .ht-lg-650p {
        height: 650%;
    }

    .mx-ht-lg-650p {
        max-height: 650%;
    }

    .mn-ht-lg-650p {
        min-height: 650%;
    }

    .ht-lg-650-f {
        height: 650px !important;
    }

    .ht-lg-650p-f {
        height: 650% !important;
    }

    .ht-lg-700 {
        height: 700px;
    }

    .ht-lg-700p {
        height: 700%;
    }

    .mx-ht-lg-700p {
        max-height: 700%;
    }

    .mn-ht-lg-700p {
        min-height: 700%;
    }

    .ht-lg-700-f {
        height: 700px !important;
    }

    .ht-lg-700p-f {
        height: 700% !important;
    }

    .ht-lg-750 {
        height: 750px;
    }

    .ht-lg-750p {
        height: 750%;
    }

    .mx-ht-lg-750p {
        max-height: 750%;
    }

    .mn-ht-lg-750p {
        min-height: 750%;
    }

    .ht-lg-750-f {
        height: 750px !important;
    }

    .ht-lg-750p-f {
        height: 750% !important;
    }

    .ht-lg-800 {
        height: 800px;
    }

    .ht-lg-800p {
        height: 800%;
    }

    .mx-ht-lg-800p {
        max-height: 800%;
    }

    .mn-ht-lg-800p {
        min-height: 800%;
    }

    .ht-lg-800-f {
        height: 800px !important;
    }

    .ht-lg-800p-f {
        height: 800% !important;
    }

    .ht-lg-850 {
        height: 850px;
    }

    .ht-lg-850p {
        height: 850%;
    }

    .mx-ht-lg-850p {
        max-height: 850%;
    }

    .mn-ht-lg-850p {
        min-height: 850%;
    }

    .ht-lg-850-f {
        height: 850px !important;
    }

    .ht-lg-850p-f {
        height: 850% !important;
    }

    .ht-lg-100v {
        height: 100vh;
    }
}

@media (min-width: 1200px) {
    .ht-xl-5 {
        height: 5px;
    }

    .ht-xl-5p {
        height: 5%;
    }

    .mx-ht-xl-5p {
        max-height: 5%;
    }

    .mn-ht-xl-5p {
        min-height: 5%;
    }

    .ht-xl-5-f {
        height: 5px !important;
    }

    .ht-xl-5p-f {
        height: 5% !important;
    }

    .ht-xl-10 {
        height: 10px;
    }

    .ht-xl-10p {
        height: 10%;
    }

    .mx-ht-xl-10p {
        max-height: 10%;
    }

    .mn-ht-xl-10p {
        min-height: 10%;
    }

    .ht-xl-10-f {
        height: 10px !important;
    }

    .ht-xl-10p-f {
        height: 10% !important;
    }

    .ht-xl-15 {
        height: 15px;
    }

    .ht-xl-15p {
        height: 15%;
    }

    .mx-ht-xl-15p {
        max-height: 15%;
    }

    .mn-ht-xl-15p {
        min-height: 15%;
    }

    .ht-xl-15-f {
        height: 15px !important;
    }

    .ht-xl-15p-f {
        height: 15% !important;
    }

    .ht-xl-20 {
        height: 20px;
    }

    .ht-xl-20p {
        height: 20%;
    }

    .mx-ht-xl-20p {
        max-height: 20%;
    }

    .mn-ht-xl-20p {
        min-height: 20%;
    }

    .ht-xl-20-f {
        height: 20px !important;
    }

    .ht-xl-20p-f {
        height: 20% !important;
    }

    .ht-xl-25 {
        height: 25px;
    }

    .ht-xl-25p {
        height: 25%;
    }

    .mx-ht-xl-25p {
        max-height: 25%;
    }

    .mn-ht-xl-25p {
        min-height: 25%;
    }

    .ht-xl-25-f {
        height: 25px !important;
    }

    .ht-xl-25p-f {
        height: 25% !important;
    }

    .ht-xl-30 {
        height: 30px;
    }

    .ht-xl-30p {
        height: 30%;
    }

    .mx-ht-xl-30p {
        max-height: 30%;
    }

    .mn-ht-xl-30p {
        min-height: 30%;
    }

    .ht-xl-30-f {
        height: 30px !important;
    }

    .ht-xl-30p-f {
        height: 30% !important;
    }

    .ht-xl-35 {
        height: 35px;
    }

    .ht-xl-35p {
        height: 35%;
    }

    .mx-ht-xl-35p {
        max-height: 35%;
    }

    .mn-ht-xl-35p {
        min-height: 35%;
    }

    .ht-xl-35-f {
        height: 35px !important;
    }

    .ht-xl-35p-f {
        height: 35% !important;
    }

    .ht-xl-40 {
        height: 40px;
    }

    .ht-xl-40p {
        height: 40%;
    }

    .mx-ht-xl-40p {
        max-height: 40%;
    }

    .mn-ht-xl-40p {
        min-height: 40%;
    }

    .ht-xl-40-f {
        height: 40px !important;
    }

    .ht-xl-40p-f {
        height: 40% !important;
    }

    .ht-xl-45 {
        height: 45px;
    }

    .ht-xl-45p {
        height: 45%;
    }

    .mx-ht-xl-45p {
        max-height: 45%;
    }

    .mn-ht-xl-45p {
        min-height: 45%;
    }

    .ht-xl-45-f {
        height: 45px !important;
    }

    .ht-xl-45p-f {
        height: 45% !important;
    }

    .ht-xl-50 {
        height: 50px;
    }

    .ht-xl-50p {
        height: 50%;
    }

    .mx-ht-xl-50p {
        max-height: 50%;
    }

    .mn-ht-xl-50p {
        min-height: 50%;
    }

    .ht-xl-50-f {
        height: 50px !important;
    }

    .ht-xl-50p-f {
        height: 50% !important;
    }

    .ht-xl-55 {
        height: 55px;
    }

    .ht-xl-55p {
        height: 55%;
    }

    .mx-ht-xl-55p {
        max-height: 55%;
    }

    .mn-ht-xl-55p {
        min-height: 55%;
    }

    .ht-xl-55-f {
        height: 55px !important;
    }

    .ht-xl-55p-f {
        height: 55% !important;
    }

    .ht-xl-60 {
        height: 60px;
    }

    .ht-xl-60p {
        height: 60%;
    }

    .mx-ht-xl-60p {
        max-height: 60%;
    }

    .mn-ht-xl-60p {
        min-height: 60%;
    }

    .ht-xl-60-f {
        height: 60px !important;
    }

    .ht-xl-60p-f {
        height: 60% !important;
    }

    .ht-xl-65 {
        height: 65px;
    }

    .ht-xl-65p {
        height: 65%;
    }

    .mx-ht-xl-65p {
        max-height: 65%;
    }

    .mn-ht-xl-65p {
        min-height: 65%;
    }

    .ht-xl-65-f {
        height: 65px !important;
    }

    .ht-xl-65p-f {
        height: 65% !important;
    }

    .ht-xl-70 {
        height: 70px;
    }

    .ht-xl-70p {
        height: 70%;
    }

    .mx-ht-xl-70p {
        max-height: 70%;
    }

    .mn-ht-xl-70p {
        min-height: 70%;
    }

    .ht-xl-70-f {
        height: 70px !important;
    }

    .ht-xl-70p-f {
        height: 70% !important;
    }

    .ht-xl-75 {
        height: 75px;
    }

    .ht-xl-75p {
        height: 75%;
    }

    .mx-ht-xl-75p {
        max-height: 75%;
    }

    .mn-ht-xl-75p {
        min-height: 75%;
    }

    .ht-xl-75-f {
        height: 75px !important;
    }

    .ht-xl-75p-f {
        height: 75% !important;
    }

    .ht-xl-80 {
        height: 80px;
    }

    .ht-xl-80p {
        height: 80%;
    }

    .mx-ht-xl-80p {
        max-height: 80%;
    }

    .mn-ht-xl-80p {
        min-height: 80%;
    }

    .ht-xl-80-f {
        height: 80px !important;
    }

    .ht-xl-80p-f {
        height: 80% !important;
    }

    .ht-xl-85 {
        height: 85px;
    }

    .ht-xl-85p {
        height: 85%;
    }

    .mx-ht-xl-85p {
        max-height: 85%;
    }

    .mn-ht-xl-85p {
        min-height: 85%;
    }

    .ht-xl-85-f {
        height: 85px !important;
    }

    .ht-xl-85p-f {
        height: 85% !important;
    }

    .ht-xl-90 {
        height: 90px;
    }

    .ht-xl-90p {
        height: 90%;
    }

    .mx-ht-xl-90p {
        max-height: 90%;
    }

    .mn-ht-xl-90p {
        min-height: 90%;
    }

    .ht-xl-90-f {
        height: 90px !important;
    }

    .ht-xl-90p-f {
        height: 90% !important;
    }

    .ht-xl-95 {
        height: 95px;
    }

    .ht-xl-95p {
        height: 95%;
    }

    .mx-ht-xl-95p {
        max-height: 95%;
    }

    .mn-ht-xl-95p {
        min-height: 95%;
    }

    .ht-xl-95-f {
        height: 95px !important;
    }

    .ht-xl-95p-f {
        height: 95% !important;
    }

    .ht-xl-100 {
        height: 100px;
    }

    .ht-xl-100p {
        height: 100%;
    }

    .mx-ht-xl-100p {
        max-height: 100%;
    }

    .mn-ht-xl-100p {
        min-height: 100%;
    }

    .ht-xl-100-f {
        height: 100px !important;
    }

    .ht-xl-100p-f {
        height: 100% !important;
    }

    .ht-xl-150 {
        height: 150px;
    }

    .ht-xl-150p {
        height: 150%;
    }

    .mx-ht-xl-150p {
        max-height: 150%;
    }

    .mn-ht-xl-150p {
        min-height: 150%;
    }

    .ht-xl-150-f {
        height: 150px !important;
    }

    .ht-xl-150p-f {
        height: 150% !important;
    }

    .ht-xl-200 {
        height: 200px;
    }

    .ht-xl-200p {
        height: 200%;
    }

    .mx-ht-xl-200p {
        max-height: 200%;
    }

    .mn-ht-xl-200p {
        min-height: 200%;
    }

    .ht-xl-200-f {
        height: 200px !important;
    }

    .ht-xl-200p-f {
        height: 200% !important;
    }

    .ht-xl-250 {
        height: 250px;
    }

    .ht-xl-250p {
        height: 250%;
    }

    .mx-ht-xl-250p {
        max-height: 250%;
    }

    .mn-ht-xl-250p {
        min-height: 250%;
    }

    .ht-xl-250-f {
        height: 250px !important;
    }

    .ht-xl-250p-f {
        height: 250% !important;
    }

    .ht-xl-300 {
        height: 300px;
    }

    .ht-xl-300p {
        height: 300%;
    }

    .mx-ht-xl-300p {
        max-height: 300%;
    }

    .mn-ht-xl-300p {
        min-height: 300%;
    }

    .ht-xl-300-f {
        height: 300px !important;
    }

    .ht-xl-300p-f {
        height: 300% !important;
    }

    .ht-xl-350 {
        height: 350px;
    }

    .ht-xl-350p {
        height: 350%;
    }

    .mx-ht-xl-350p {
        max-height: 350%;
    }

    .mn-ht-xl-350p {
        min-height: 350%;
    }

    .ht-xl-350-f {
        height: 350px !important;
    }

    .ht-xl-350p-f {
        height: 350% !important;
    }

    .ht-xl-400 {
        height: 400px;
    }

    .ht-xl-400p {
        height: 400%;
    }

    .mx-ht-xl-400p {
        max-height: 400%;
    }

    .mn-ht-xl-400p {
        min-height: 400%;
    }

    .ht-xl-400-f {
        height: 400px !important;
    }

    .ht-xl-400p-f {
        height: 400% !important;
    }

    .ht-xl-450 {
        height: 450px;
    }

    .ht-xl-450p {
        height: 450%;
    }

    .mx-ht-xl-450p {
        max-height: 450%;
    }

    .mn-ht-xl-450p {
        min-height: 450%;
    }

    .ht-xl-450-f {
        height: 450px !important;
    }

    .ht-xl-450p-f {
        height: 450% !important;
    }

    .ht-xl-500 {
        height: 500px;
    }

    .ht-xl-500p {
        height: 500%;
    }

    .mx-ht-xl-500p {
        max-height: 500%;
    }

    .mn-ht-xl-500p {
        min-height: 500%;
    }

    .ht-xl-500-f {
        height: 500px !important;
    }

    .ht-xl-500p-f {
        height: 500% !important;
    }

    .ht-xl-550 {
        height: 550px;
    }

    .ht-xl-550p {
        height: 550%;
    }

    .mx-ht-xl-550p {
        max-height: 550%;
    }

    .mn-ht-xl-550p {
        min-height: 550%;
    }

    .ht-xl-550-f {
        height: 550px !important;
    }

    .ht-xl-550p-f {
        height: 550% !important;
    }

    .ht-xl-600 {
        height: 600px;
    }

    .ht-xl-600p {
        height: 600%;
    }

    .mx-ht-xl-600p {
        max-height: 600%;
    }

    .mn-ht-xl-600p {
        min-height: 600%;
    }

    .ht-xl-600-f {
        height: 600px !important;
    }

    .ht-xl-600p-f {
        height: 600% !important;
    }

    .ht-xl-650 {
        height: 650px;
    }

    .ht-xl-650p {
        height: 650%;
    }

    .mx-ht-xl-650p {
        max-height: 650%;
    }

    .mn-ht-xl-650p {
        min-height: 650%;
    }

    .ht-xl-650-f {
        height: 650px !important;
    }

    .ht-xl-650p-f {
        height: 650% !important;
    }

    .ht-xl-700 {
        height: 700px;
    }

    .ht-xl-700p {
        height: 700%;
    }

    .mx-ht-xl-700p {
        max-height: 700%;
    }

    .mn-ht-xl-700p {
        min-height: 700%;
    }

    .ht-xl-700-f {
        height: 700px !important;
    }

    .ht-xl-700p-f {
        height: 700% !important;
    }

    .ht-xl-750 {
        height: 750px;
    }

    .ht-xl-750p {
        height: 750%;
    }

    .mx-ht-xl-750p {
        max-height: 750%;
    }

    .mn-ht-xl-750p {
        min-height: 750%;
    }

    .ht-xl-750-f {
        height: 750px !important;
    }

    .ht-xl-750p-f {
        height: 750% !important;
    }

    .ht-xl-800 {
        height: 800px;
    }

    .ht-xl-800p {
        height: 800%;
    }

    .mx-ht-xl-800p {
        max-height: 800%;
    }

    .mn-ht-xl-800p {
        min-height: 800%;
    }

    .ht-xl-800-f {
        height: 800px !important;
    }

    .ht-xl-800p-f {
        height: 800% !important;
    }

    .ht-xl-850 {
        height: 850px;
    }

    .ht-xl-850p {
        height: 850%;
    }

    .mx-ht-xl-850p {
        max-height: 850%;
    }

    .mn-ht-xl-850p {
        min-height: 850%;
    }

    .ht-xl-850-f {
        height: 850px !important;
    }

    .ht-xl-850p-f {
        height: 850% !important;
    }

    .ht-xl-100v {
        height: 100vh;
    }
}

.ht-250 {
    height: 250px !important;
}

.h-100vh {
    height: 100vh !important;
}

.m-0 {
    margin: 0 !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.me-0,
.mx-0 {
    margin-right: 0 !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.ms-0,
.mx-0 {
    margin-left: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}

.me-1,
.mx-1 {
    margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}

.ms-1,
.mx-1 {
    margin-left: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.mt-2,
.my-2 {
    margin-top: 0.5rem !important;
}

.me-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}

.ms-2,
.mx-2 {
    margin-left: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.mt-3,
.my-3 {
    margin-top: 1rem !important;
}

.me-3,
.mx-3 {
    margin-right: 1rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.ms-3,
.mx-3 {
    margin-left: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}

.me-4,
.mx-4 {
    margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.ms-4,
.mx-4 {
    margin-left: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.mt-5,
.my-5 {
    margin-top: 3rem !important;
}

.me-5,
.mx-5 {
    margin-right: 3rem !important;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}

.ms-5,
.mx-5 {
    margin-left: 3rem !important;
}

.m-n1 {
    margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
    margin-top: -0.25rem !important;
}

.me-n1,
.mx-n1 {
    margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
    margin-bottom: -0.25rem !important;
}

.ms-n1,
.mx-n1 {
    margin-left: -0.25rem !important;
}

.m-n2 {
    margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
    margin-top: -0.5rem !important;
}

.me-n2,
.mx-n2 {
    margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
    margin-bottom: -0.5rem !important;
}

.ms-n2,
.mx-n2 {
    margin-left: -0.5rem !important;
}

.m-n3 {
    margin: -1rem !important;
}

.mt-n3,
.my-n3 {
    margin-top: -1rem !important;
}

.me-n3,
.mx-n3 {
    margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
    margin-bottom: -1rem !important;
}

.ms-n3,
.mx-n3 {
    margin-left: -1rem !important;
}

.m-n4 {
    margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
    margin-top: -1.5rem !important;
}

.me-n4,
.mx-n4 {
    margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
    margin-bottom: -1.5rem !important;
}

.ms-n4,
.mx-n4 {
    margin-left: -1.5rem !important;
}

.m-n5 {
    margin: -3rem !important;
}

.mt-n5,
.my-n5 {
    margin-top: -3rem !important;
}

.me-n5,
.mx-n5 {
    margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
    margin-bottom: -3rem !important;
}

.ms-n5,
.mx-n5 {
    margin-left: -3rem !important;
}

.m-auto {
    margin: auto !important;
}

.mt-auto,
.my-auto {
    margin-top: auto !important;
}

.me-auto,
.mx-auto {
    margin-right: auto !important;
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}

.ms-auto,
.mx-auto {
    margin-left: auto !important;
}

@media (max-width: 767px) {
    .m-sm-0 {
        margin: 0 !important;
    }

    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important;
    }

    .me-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important;
    }

    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important;
    }

    .ms-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important;
    }

    .m-sm-1 {
        margin: 0.25rem !important;
    }

    .mt-sm-1,
    .my-sm-1 {
        margin-top: 0.25rem !important;
    }

    .me-sm-1,
    .mx-sm-1 {
        margin-right: 0.25rem !important;
    }

    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: 0.25rem !important;
    }

    .ms-sm-1,
    .mx-sm-1 {
        margin-left: 0.25rem !important;
    }

    .m-sm-2 {
        margin: 0.5rem !important;
    }

    .mt-sm-2,
    .my-sm-2 {
        margin-top: 0.5rem !important;
    }

    .me-sm-2,
    .mx-sm-2 {
        margin-right: 0.5rem !important;
    }

    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: 0.5rem !important;
    }

    .ms-sm-2,
    .mx-sm-2 {
        margin-left: 0.5rem !important;
    }

    .m-sm-3 {
        margin: 1rem !important;
    }

    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem !important;
    }

    .me-sm-3,
    .mx-sm-3 {
        margin-right: 1rem !important;
    }

    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem !important;
    }

    .ms-sm-3,
    .mx-sm-3 {
        margin-left: 1rem !important;
    }

    .m-sm-4 {
        margin: 1.5rem !important;
    }

    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem !important;
    }

    .me-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem !important;
    }

    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem !important;
    }

    .ms-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem !important;
    }

    .m-sm-5 {
        margin: 3rem !important;
    }

    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem !important;
    }

    .me-sm-5,
    .mx-sm-5 {
        margin-right: 3rem !important;
    }

    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem !important;
    }

    .ms-sm-5,
    .mx-sm-5 {
        margin-left: 3rem !important;
    }

    .m-sm-n1 {
        margin: -0.25rem !important;
    }

    .mt-sm-n1,
    .my-sm-n1 {
        margin-top: -0.25rem !important;
    }

    .me-sm-n1,
    .mx-sm-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-sm-n1,
    .my-sm-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ms-sm-n1,
    .mx-sm-n1 {
        margin-left: -0.25rem !important;
    }

    .m-sm-n2 {
        margin: -0.5rem !important;
    }

    .mt-sm-n2,
    .my-sm-n2 {
        margin-top: -0.5rem !important;
    }

    .me-sm-n2,
    .mx-sm-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-sm-n2,
    .my-sm-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ms-sm-n2,
    .mx-sm-n2 {
        margin-left: -0.5rem !important;
    }

    .m-sm-n3 {
        margin: -1rem !important;
    }

    .mt-sm-n3,
    .my-sm-n3 {
        margin-top: -1rem !important;
    }

    .me-sm-n3,
    .mx-sm-n3 {
        margin-right: -1rem !important;
    }

    .mb-sm-n3,
    .my-sm-n3 {
        margin-bottom: -1rem !important;
    }

    .ms-sm-n3,
    .mx-sm-n3 {
        margin-left: -1rem !important;
    }

    .m-sm-n4 {
        margin: -1.5rem !important;
    }

    .mt-sm-n4,
    .my-sm-n4 {
        margin-top: -1.5rem !important;
    }

    .me-sm-n4,
    .mx-sm-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-sm-n4,
    .my-sm-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ms-sm-n4,
    .mx-sm-n4 {
        margin-left: -1.5rem !important;
    }

    .m-sm-n5 {
        margin: -3rem !important;
    }

    .mt-sm-n5,
    .my-sm-n5 {
        margin-top: -3rem !important;
    }

    .me-sm-n5,
    .mx-sm-n5 {
        margin-right: -3rem !important;
    }

    .mb-sm-n5,
    .my-sm-n5 {
        margin-bottom: -3rem !important;
    }

    .ms-sm-n5,
    .mx-sm-n5 {
        margin-left: -3rem !important;
    }

    .m-sm-auto {
        margin: auto !important;
    }

    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important;
    }

    .me-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important;
    }

    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important;
    }

    .ms-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 768px) {
    .m-md-0 {
        margin: 0 !important;
    }

    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important;
    }

    .me-md-0,
    .mx-md-0 {
        margin-right: 0 !important;
    }

    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important;
    }

    .ms-md-0,
    .mx-md-0 {
        margin-left: 0 !important;
    }

    .m-md-1 {
        margin: 0.25rem !important;
    }

    .mt-md-1,
    .my-md-1 {
        margin-top: 0.25rem !important;
    }

    .me-md-1,
    .mx-md-1 {
        margin-right: 0.25rem !important;
    }

    .mb-md-1,
    .my-md-1 {
        margin-bottom: 0.25rem !important;
    }

    .ms-md-1,
    .mx-md-1 {
        margin-left: 0.25rem !important;
    }

    .m-md-2 {
        margin: 0.5rem !important;
    }

    .mt-md-2,
    .my-md-2 {
        margin-top: 0.5rem !important;
    }

    .me-md-2,
    .mx-md-2 {
        margin-right: 0.5rem !important;
    }

    .mb-md-2,
    .my-md-2 {
        margin-bottom: 0.5rem !important;
    }

    .ms-md-2,
    .mx-md-2 {
        margin-left: 0.5rem !important;
    }

    .m-md-3 {
        margin: 1rem !important;
    }

    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem !important;
    }

    .me-md-3,
    .mx-md-3 {
        margin-right: 1rem !important;
    }

    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem !important;
    }

    .ms-md-3,
    .mx-md-3 {
        margin-left: 1rem !important;
    }

    .m-md-4 {
        margin: 1.5rem !important;
    }

    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem !important;
    }

    .me-md-4,
    .mx-md-4 {
        margin-right: 1.5rem !important;
    }

    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem !important;
    }

    .ms-md-4,
    .mx-md-4 {
        margin-left: 1.5rem !important;
    }

    .m-md-5 {
        margin: 3rem !important;
    }

    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem !important;
    }

    .me-md-5,
    .mx-md-5 {
        margin-right: 3rem !important;
    }

    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem !important;
    }

    .ms-md-5,
    .mx-md-5 {
        margin-left: 3rem !important;
    }

    .m-md-n1 {
        margin: -0.25rem !important;
    }

    .mt-md-n1,
    .my-md-n1 {
        margin-top: -0.25rem !important;
    }

    .me-md-n1,
    .mx-md-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-md-n1,
    .my-md-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ms-md-n1,
    .mx-md-n1 {
        margin-left: -0.25rem !important;
    }

    .m-md-n2 {
        margin: -0.5rem !important;
    }

    .mt-md-n2,
    .my-md-n2 {
        margin-top: -0.5rem !important;
    }

    .me-md-n2,
    .mx-md-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-md-n2,
    .my-md-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ms-md-n2,
    .mx-md-n2 {
        margin-left: -0.5rem !important;
    }

    .m-md-n3 {
        margin: -1rem !important;
    }

    .mt-md-n3,
    .my-md-n3 {
        margin-top: -1rem !important;
    }

    .me-md-n3,
    .mx-md-n3 {
        margin-right: -1rem !important;
    }

    .mb-md-n3,
    .my-md-n3 {
        margin-bottom: -1rem !important;
    }

    .ms-md-n3,
    .mx-md-n3 {
        margin-left: -1rem !important;
    }

    .m-md-n4 {
        margin: -1.5rem !important;
    }

    .mt-md-n4,
    .my-md-n4 {
        margin-top: -1.5rem !important;
    }

    .me-md-n4,
    .mx-md-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-md-n4,
    .my-md-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ms-md-n4,
    .mx-md-n4 {
        margin-left: -1.5rem !important;
    }

    .m-md-n5 {
        margin: -3rem !important;
    }

    .mt-md-n5,
    .my-md-n5 {
        margin-top: -3rem !important;
    }

    .me-md-n5,
    .mx-md-n5 {
        margin-right: -3rem !important;
    }

    .mb-md-n5,
    .my-md-n5 {
        margin-bottom: -3rem !important;
    }

    .ms-md-n5,
    .mx-md-n5 {
        margin-left: -3rem !important;
    }

    .m-md-auto {
        margin: auto !important;
    }

    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important;
    }

    .me-md-auto,
    .mx-md-auto {
        margin-right: auto !important;
    }

    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important;
    }

    .ms-md-auto,
    .mx-md-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 992px) {
    .m-lg-0 {
        margin: 0 !important;
    }

    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important;
    }

    .me-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important;
    }

    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important;
    }

    .ms-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important;
    }

    .m-lg-1 {
        margin: 0.25rem !important;
    }

    .mt-lg-1,
    .my-lg-1 {
        margin-top: 0.25rem !important;
    }

    .me-lg-1,
    .mx-lg-1 {
        margin-right: 0.25rem !important;
    }

    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: 0.25rem !important;
    }

    .ms-lg-1,
    .mx-lg-1 {
        margin-left: 0.25rem !important;
    }

    .m-lg-2 {
        margin: 0.5rem !important;
    }

    .mt-lg-2,
    .my-lg-2 {
        margin-top: 0.5rem !important;
    }

    .me-lg-2,
    .mx-lg-2 {
        margin-right: 0.5rem !important;
    }

    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: 0.5rem !important;
    }

    .ms-lg-2,
    .mx-lg-2 {
        margin-left: 0.5rem !important;
    }

    .m-lg-3 {
        margin: 1rem !important;
    }

    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem !important;
    }

    .me-lg-3,
    .mx-lg-3 {
        margin-right: 1rem !important;
    }

    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem !important;
    }

    .ms-lg-3,
    .mx-lg-3 {
        margin-left: 1rem !important;
    }

    .m-lg-4 {
        margin: 1.5rem !important;
    }

    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem !important;
    }

    .me-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem !important;
    }

    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem !important;
    }

    .ms-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem !important;
    }

    .m-lg-5 {
        margin: 3rem !important;
    }

    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem !important;
    }

    .me-lg-5,
    .mx-lg-5 {
        margin-right: 3rem !important;
    }

    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem !important;
    }

    .ms-lg-5,
    .mx-lg-5 {
        margin-left: 3rem !important;
    }

    .m-lg-n1 {
        margin: -0.25rem !important;
    }

    .mt-lg-n1,
    .my-lg-n1 {
        margin-top: -0.25rem !important;
    }

    .me-lg-n1,
    .mx-lg-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-lg-n1,
    .my-lg-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ms-lg-n1,
    .mx-lg-n1 {
        margin-left: -0.25rem !important;
    }

    .m-lg-n2 {
        margin: -0.5rem !important;
    }

    .mt-lg-n2,
    .my-lg-n2 {
        margin-top: -0.5rem !important;
    }

    .me-lg-n2,
    .mx-lg-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-lg-n2,
    .my-lg-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ms-lg-n2,
    .mx-lg-n2 {
        margin-left: -0.5rem !important;
    }

    .m-lg-n3 {
        margin: -1rem !important;
    }

    .mt-lg-n3,
    .my-lg-n3 {
        margin-top: -1rem !important;
    }

    .me-lg-n3,
    .mx-lg-n3 {
        margin-right: -1rem !important;
    }

    .mb-lg-n3,
    .my-lg-n3 {
        margin-bottom: -1rem !important;
    }

    .ms-lg-n3,
    .mx-lg-n3 {
        margin-left: -1rem !important;
    }

    .m-lg-n4 {
        margin: -1.5rem !important;
    }

    .mt-lg-n4,
    .my-lg-n4 {
        margin-top: -1.5rem !important;
    }

    .me-lg-n4,
    .mx-lg-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-lg-n4,
    .my-lg-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ms-lg-n4,
    .mx-lg-n4 {
        margin-left: -1.5rem !important;
    }

    .m-lg-n5 {
        margin: -3rem !important;
    }

    .mt-lg-n5,
    .my-lg-n5 {
        margin-top: -3rem !important;
    }

    .me-lg-n5,
    .mx-lg-n5 {
        margin-right: -3rem !important;
    }

    .mb-lg-n5,
    .my-lg-n5 {
        margin-bottom: -3rem !important;
    }

    .ms-lg-n5,
    .mx-lg-n5 {
        margin-left: -3rem !important;
    }

    .m-lg-auto {
        margin: auto !important;
    }

    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important;
    }

    .me-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important;
    }

    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important;
    }

    .ms-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1200px) {
    .m-xl-0 {
        margin: 0 !important;
    }

    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important;
    }

    .me-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important;
    }

    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important;
    }

    .ms-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important;
    }

    .m-xl-1 {
        margin: 0.25rem !important;
    }

    .mt-xl-1,
    .my-xl-1 {
        margin-top: 0.25rem !important;
    }

    .me-xl-1,
    .mx-xl-1 {
        margin-right: 0.25rem !important;
    }

    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: 0.25rem !important;
    }

    .ms-xl-1,
    .mx-xl-1 {
        margin-left: 0.25rem !important;
    }

    .m-xl-2 {
        margin: 0.5rem !important;
    }

    .mt-xl-2,
    .my-xl-2 {
        margin-top: 0.5rem !important;
    }

    .me-xl-2,
    .mx-xl-2 {
        margin-right: 0.5rem !important;
    }

    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: 0.5rem !important;
    }

    .ms-xl-2,
    .mx-xl-2 {
        margin-left: 0.5rem !important;
    }

    .m-xl-3 {
        margin: 1rem !important;
    }

    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem !important;
    }

    .me-xl-3,
    .mx-xl-3 {
        margin-right: 1rem !important;
    }

    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem !important;
    }

    .ms-xl-3,
    .mx-xl-3 {
        margin-left: 1rem !important;
    }

    .m-xl-4 {
        margin: 1.5rem !important;
    }

    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem !important;
    }

    .me-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem !important;
    }

    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem !important;
    }

    .ms-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem !important;
    }

    .m-xl-5 {
        margin: 3rem !important;
    }

    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem !important;
    }

    .me-xl-5,
    .mx-xl-5 {
        margin-right: 3rem !important;
    }

    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem !important;
    }

    .ms-xl-5,
    .mx-xl-5 {
        margin-left: 3rem !important;
    }

    .m-xl-n1 {
        margin: -0.25rem !important;
    }

    .mt-xl-n1,
    .my-xl-n1 {
        margin-top: -0.25rem !important;
    }

    .me-xl-n1,
    .mx-xl-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-xl-n1,
    .my-xl-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ms-xl-n1,
    .mx-xl-n1 {
        margin-left: -0.25rem !important;
    }

    .m-xl-n2 {
        margin: -0.5rem !important;
    }

    .mt-xl-n2,
    .my-xl-n2 {
        margin-top: -0.5rem !important;
    }

    .me-xl-n2,
    .mx-xl-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-xl-n2,
    .my-xl-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ms-xl-n2,
    .mx-xl-n2 {
        margin-left: -0.5rem !important;
    }

    .m-xl-n3 {
        margin: -1rem !important;
    }

    .mt-xl-n3,
    .my-xl-n3 {
        margin-top: -1rem !important;
    }

    .me-xl-n3,
    .mx-xl-n3 {
        margin-right: -1rem !important;
    }

    .mb-xl-n3,
    .my-xl-n3 {
        margin-bottom: -1rem !important;
    }

    .ms-xl-n3,
    .mx-xl-n3 {
        margin-left: -1rem !important;
    }

    .m-xl-n4 {
        margin: -1.5rem !important;
    }

    .mt-xl-n4,
    .my-xl-n4 {
        margin-top: -1.5rem !important;
    }

    .me-xl-n4,
    .mx-xl-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-xl-n4,
    .my-xl-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ms-xl-n4,
    .mx-xl-n4 {
        margin-left: -1.5rem !important;
    }

    .m-xl-n5 {
        margin: -3rem !important;
    }

    .mt-xl-n5,
    .my-xl-n5 {
        margin-top: -3rem !important;
    }

    .me-xl-n5,
    .mx-xl-n5 {
        margin-right: -3rem !important;
    }

    .mb-xl-n5,
    .my-xl-n5 {
        margin-bottom: -3rem !important;
    }

    .ms-xl-n5,
    .mx-xl-n5 {
        margin-left: -3rem !important;
    }

    .m-xl-auto {
        margin: auto !important;
    }

    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important;
    }

    .me-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important;
    }

    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important;
    }

    .ms-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important;
    }
}

/* ###### 9.4 Margin   ###### */
.mg-0 {
    margin: 0px;
}

.mg-0-f {
    margin: 0px !important;
}

.mg-y-0 {
    margin-top: 0px;
    margin-bottom: 0px;
}

.mg-y-0-f {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.mg-x-0 {
    margin-left: 0px;
    margin-right: 0px;
}

.mg-x-0-f {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.mg-t-0 {
    margin-top: 0px;
}

.mg-r-0 {
    margin-right: 0px;
}

.mg-b-0 {
    margin-bottom: 0px;
}

.mg-l-0 {
    margin-left: 0px;
}

.mg-t-0-f {
    margin-top: 0px !important;
}

.mg-r-0-f {
    margin-right: 0px !important;
}

.mg-b-0-f {
    margin-bottom: 0px !important;
}

.mg-l-0-f {
    margin-left: 0px !important;
}

.mg-1 {
    margin: 1px;
}

.mg-1-f {
    margin: 1px !important;
}

.mg-y-1 {
    margin-top: 1px;
    margin-bottom: 1px;
}

.mg-y-1-f {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
}

.mg-x-1 {
    margin-left: 1px;
    margin-right: 1px;
}

.mg-x-1-f {
    margin-left: 1px !important;
    margin-right: 1px !important;
}

.mg-t-1 {
    margin-top: 1px;
}

.mg-r-1 {
    margin-right: 1px;
}

.mg-b-1 {
    margin-bottom: 1px;
}

.mg-l-1 {
    margin-left: 1px;
}

.mg-t-1-f {
    margin-top: 1px !important;
}

.mg-r-1-f {
    margin-right: 1px !important;
}

.mg-b-1-f {
    margin-bottom: 1px !important;
}

.mg-l-1-f {
    margin-left: 1px !important;
}

.mg-2 {
    margin: 2px;
}

.mg-2-f {
    margin: 2px !important;
}

.mg-y-2 {
    margin-top: 2px;
    margin-bottom: 2px;
}

.mg-y-2-f {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
}

.mg-x-2 {
    margin-left: 2px;
    margin-right: 2px;
}

.mg-x-2-f {
    margin-left: 2px !important;
    margin-right: 2px !important;
}

.mg-t-2 {
    margin-top: 2px;
}

.mg-r-2 {
    margin-right: 2px;
}

.mg-b-2 {
    margin-bottom: 2px;
}

.mg-l-2 {
    margin-left: 2px;
}

.mg-t-2-f {
    margin-top: 2px !important;
}

.mg-r-2-f {
    margin-right: 2px !important;
}

.mg-b-2-f {
    margin-bottom: 2px !important;
}

.mg-l-2-f {
    margin-left: 2px !important;
}

.mg-3 {
    margin: 3px;
}

.mg-3-f {
    margin: 3px !important;
}

.mg-y-3 {
    margin-top: 3px;
    margin-bottom: 3px;
}

.mg-y-3-f {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
}

.mg-x-3 {
    margin-left: 3px;
    margin-right: 3px;
}

.mg-x-3-f {
    margin-left: 3px !important;
    margin-right: 3px !important;
}

.mg-t-3 {
    margin-top: 3px;
}

.mg-r-3 {
    margin-right: 3px;
}

.mg-b-3 {
    margin-bottom: 3px;
}

.mg-l-3 {
    margin-left: 3px;
}

.mg-t-3-f {
    margin-top: 3px !important;
}

.mg-r-3-f {
    margin-right: 3px !important;
}

.mg-b-3-f {
    margin-bottom: 3px !important;
}

.mg-l-3-f {
    margin-left: 3px !important;
}

.mg-4 {
    margin: 4px;
}

.mg-4-f {
    margin: 4px !important;
}

.mg-y-4 {
    margin-top: 4px;
    margin-bottom: 4px;
}

.mg-y-4-f {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
}

.mg-x-4 {
    margin-left: 4px;
    margin-right: 4px;
}

.mg-x-4-f {
    margin-left: 4px !important;
    margin-right: 4px !important;
}

.mg-t-4 {
    margin-top: 4px;
}

.mg-r-4 {
    margin-right: 4px;
}

.mg-b-4 {
    margin-bottom: 4px;
}

.mg-l-4 {
    margin-left: 4px;
}

.mg-t-4-f {
    margin-top: 4px !important;
}

.mg-r-4-f {
    margin-right: 4px !important;
}

.mg-b-4-f {
    margin-bottom: 4px !important;
}

.mg-l-4-f {
    margin-left: 4px !important;
}

.mg-5 {
    margin: 5px;
}

.mg-5-f {
    margin: 5px !important;
}

.mg-y-5 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.mg-y-5-f {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.mg-x-5 {
    margin-left: 5px;
    margin-right: 5px;
}

.mg-x-5-f {
    margin-left: 5px !important;
    margin-right: 5px !important;
}

.mg-t-5 {
    margin-top: 5px;
}

.mg-r-5 {
    margin-right: 5px;
}

.mg-b-5 {
    margin-bottom: 5px;
}

.mg-l-5 {
    margin-left: 5px;
}

.mg-t-5-f {
    margin-top: 5px !important;
}

.mg-r-5-f {
    margin-right: 5px !important;
}

.mg-b-5-f {
    margin-bottom: 5px !important;
}

.mg-l-5-f {
    margin-left: 5px !important;
}

.mg-6 {
    margin: 6px;
}

.mg-6-f {
    margin: 6px !important;
}

.mg-y-6 {
    margin-top: 6px;
    margin-bottom: 6px;
}

.mg-y-6-f {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
}

.mg-x-6 {
    margin-left: 6px;
    margin-right: 6px;
}

.mg-x-6-f {
    margin-left: 6px !important;
    margin-right: 6px !important;
}

.mg-t-6 {
    margin-top: 6px;
}

.mg-r-6 {
    margin-right: 6px;
}

.mg-b-6 {
    margin-bottom: 6px;
}

.mg-l-6 {
    margin-left: 6px;
}

.mg-t-6-f {
    margin-top: 6px !important;
}

.mg-r-6-f {
    margin-right: 6px !important;
}

.mg-b-6-f {
    margin-bottom: 6px !important;
}

.mg-l-6-f {
    margin-left: 6px !important;
}

.mg-7 {
    margin: 7px;
}

.mg-7-f {
    margin: 7px !important;
}

.mg-y-7 {
    margin-top: 7px;
    margin-bottom: 7px;
}

.mg-y-7-f {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
}

.mg-x-7 {
    margin-left: 7px;
    margin-right: 7px;
}

.mg-x-7-f {
    margin-left: 7px !important;
    margin-right: 7px !important;
}

.mg-t-7 {
    margin-top: 7px;
}

.mg-r-7 {
    margin-right: 7px;
}

.mg-b-7 {
    margin-bottom: 7px;
}

.mg-l-7 {
    margin-left: 7px;
}

.mg-t-7-f {
    margin-top: 7px !important;
}

.mg-r-7-f {
    margin-right: 7px !important;
}

.mg-b-7-f {
    margin-bottom: 7px !important;
}

.mg-l-7-f {
    margin-left: 7px !important;
}

.mg-8 {
    margin: 8px;
}

.mg-8-f {
    margin: 8px !important;
}

.mg-y-8 {
    margin-top: 8px;
    margin-bottom: 8px;
}

.mg-y-8-f {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
}

.mg-x-8 {
    margin-left: 8px;
    margin-right: 8px;
}

.mg-x-8-f {
    margin-left: 8px !important;
    margin-right: 8px !important;
}

.mg-t-8 {
    margin-top: 8px;
}

.mg-r-8 {
    margin-right: 8px;
}

.mg-b-8 {
    margin-bottom: 8px;
}

.mg-l-8 {
    margin-left: 8px;
}

.mg-t-8-f {
    margin-top: 8px !important;
}

.mg-r-8-f {
    margin-right: 8px !important;
}

.mg-b-8-f {
    margin-bottom: 8px !important;
}

.mg-l-8-f {
    margin-left: 8px !important;
}

.mg-9 {
    margin: 9px;
}

.mg-9-f {
    margin: 9px !important;
}

.mg-y-9 {
    margin-top: 9px;
    margin-bottom: 9px;
}

.mg-y-9-f {
    margin-top: 9px !important;
    margin-bottom: 9px !important;
}

.mg-x-9 {
    margin-left: 9px;
    margin-right: 9px;
}

.mg-x-9-f {
    margin-left: 9px !important;
    margin-right: 9px !important;
}

.mg-t-9 {
    margin-top: 9px;
}

.mg-r-9 {
    margin-right: 9px;
}

.mg-b-9 {
    margin-bottom: 9px;
}

.mg-l-9 {
    margin-left: 9px;
}

.mg-t-9-f {
    margin-top: 9px !important;
}

.mg-r-9-f {
    margin-right: 9px !important;
}

.mg-b-9-f {
    margin-bottom: 9px !important;
}

.mg-l-9-f {
    margin-left: 9px !important;
}

.mg-10 {
    margin: 10px;
}

.mg-10-f {
    margin: 10px !important;
}

.mg-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.mg-y-10-f {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.mg-x-10 {
    margin-left: 10px;
    margin-right: 10px;
}

.mg-x-10-f {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.mg-t-10 {
    margin-top: 10px;
}

.mg-r-10 {
    margin-right: 10px;
}

.mg-b-10 {
    margin-bottom: 10px;
}

.mg-l-10 {
    margin-left: 10px;
}

.mg-t-10-f {
    margin-top: 10px !important;
}

.mg-r-10-f {
    margin-right: 10px !important;
}

.mg-b-10-f {
    margin-bottom: 10px !important;
}

.mg-l-10-f {
    margin-left: 10px !important;
}

.mg-15 {
    margin: 15px;
}

.mg-15-f {
    margin: 15px !important;
}

.mg-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.mg-y-15-f {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.mg-x-15 {
    margin-left: 15px;
    margin-right: 15px;
}

.mg-x-15-f {
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.mg-t-15 {
    margin-top: 15px;
}

.mg-r-15 {
    margin-right: 15px;
}

.mg-b-15 {
    margin-bottom: 15px;
}

.mg-l-15 {
    margin-left: 15px;
}

.mg-t-15-f {
    margin-top: 15px !important;
}

.mg-r-15-f {
    margin-right: 15px !important;
}

.mg-b-15-f {
    margin-bottom: 15px !important;
}

.mg-l-15-f {
    margin-left: 15px !important;
}

.mg-20 {
    margin: 20px;
}

.mg-20-f {
    margin: 20px !important;
}

.mg-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.mg-y-20-f {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.mg-x-20 {
    margin-left: 20px;
    margin-right: 20px;
}

.mg-x-20-f {
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.mg-t-20 {
    margin-top: 20px;
}

.mg-r-20 {
    margin-right: 20px;
}

.mg-b-20 {
    margin-bottom: 20px;
}

.mg-l-20 {
    margin-left: 20px;
}

.mg-t-20-f {
    margin-top: 20px !important;
}

.mg-r-20-f {
    margin-right: 20px !important;
}

.mg-b-20-f {
    margin-bottom: 20px !important;
}

.mg-l-20-f {
    margin-left: 20px !important;
}

.mg-25 {
    margin: 25px;
}

.mg-25-f {
    margin: 25px !important;
}

.mg-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.mg-y-25-f {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
}

.mg-x-25 {
    margin-left: 25px;
    margin-right: 25px;
}

.mg-x-25-f {
    margin-left: 25px !important;
    margin-right: 25px !important;
}

.mg-t-25 {
    margin-top: 25px;
}

.mg-r-25 {
    margin-right: 25px;
}

.mg-b-25 {
    margin-bottom: 25px;
}

.mg-l-25 {
    margin-left: 25px;
}

.mg-t-25-f {
    margin-top: 25px !important;
}

.mg-r-25-f {
    margin-right: 25px !important;
}

.mg-b-25-f {
    margin-bottom: 25px !important;
}

.mg-l-25-f {
    margin-left: 25px !important;
}

.mg-30 {
    margin: 30px;
}

.mg-30-f {
    margin: 30px !important;
}

.mg-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}

.mg-y-30-f {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}

.mg-x-30 {
    margin-left: 30px;
    margin-right: 30px;
}

.mg-x-30-f {
    margin-left: 30px !important;
    margin-right: 30px !important;
}

.mg-t-30 {
    margin-top: 30px;
}

.mg-r-30 {
    margin-right: 30px;
}

.mg-b-30 {
    margin-bottom: 30px;
}

.mg-l-30 {
    margin-left: 30px;
}

.mg-t-30-f {
    margin-top: 30px !important;
}

.mg-r-30-f {
    margin-right: 30px !important;
}

.mg-b-30-f {
    margin-bottom: 30px !important;
}

.mg-l-30-f {
    margin-left: 30px !important;
}

.mg-35 {
    margin: 35px;
}

.mg-35-f {
    margin: 35px !important;
}

.mg-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.mg-y-35-f {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
}

.mg-x-35 {
    margin-left: 35px;
    margin-right: 35px;
}

.mg-x-35-f {
    margin-left: 35px !important;
    margin-right: 35px !important;
}

.mg-t-35 {
    margin-top: 35px;
}

.mg-r-35 {
    margin-right: 35px;
}

.mg-b-35 {
    margin-bottom: 35px;
}

.mg-l-35 {
    margin-left: 35px;
}

.mg-t-35-f {
    margin-top: 35px !important;
}

.mg-r-35-f {
    margin-right: 35px !important;
}

.mg-b-35-f {
    margin-bottom: 35px !important;
}

.mg-l-35-f {
    margin-left: 35px !important;
}

.mg-40 {
    margin: 40px;
}

.mg-40-f {
    margin: 40px !important;
}

.mg-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.mg-y-40-f {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}

.mg-x-40 {
    margin-left: 40px;
    margin-right: 40px;
}

.mg-x-40-f {
    margin-left: 40px !important;
    margin-right: 40px !important;
}

.mg-t-40 {
    margin-top: 40px;
}

.mg-r-40 {
    margin-right: 40px;
}

.mg-b-40 {
    margin-bottom: 40px;
}

.mg-l-40 {
    margin-left: 40px;
}

.mg-t-40-f {
    margin-top: 40px !important;
}

.mg-r-40-f {
    margin-right: 40px !important;
}

.mg-b-40-f {
    margin-bottom: 40px !important;
}

.mg-l-40-f {
    margin-left: 40px !important;
}

.mg-45 {
    margin: 45px;
}

.mg-45-f {
    margin: 45px !important;
}

.mg-y-45 {
    margin-top: 45px;
    margin-bottom: 45px;
}

.mg-y-45-f {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
}

.mg-x-45 {
    margin-left: 45px;
    margin-right: 45px;
}

.mg-x-45-f {
    margin-left: 45px !important;
    margin-right: 45px !important;
}

.mg-t-45 {
    margin-top: 45px;
}

.mg-r-45 {
    margin-right: 45px;
}

.mg-b-45 {
    margin-bottom: 45px;
}

.mg-l-45 {
    margin-left: 45px;
}

.mg-t-45-f {
    margin-top: 45px !important;
}

.mg-r-45-f {
    margin-right: 45px !important;
}

.mg-b-45-f {
    margin-bottom: 45px !important;
}

.mg-l-45-f {
    margin-left: 45px !important;
}

.mg-50 {
    margin: 50px;
}

.mg-50-f {
    margin: 50px !important;
}

.mg-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.mg-y-50-f {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
}

.mg-x-50 {
    margin-left: 50px;
    margin-right: 50px;
}

.mg-x-50-f {
    margin-left: 50px !important;
    margin-right: 50px !important;
}

.mg-t-50 {
    margin-top: 50px;
}

.mg-r-50 {
    margin-right: 50px;
}

.mg-b-50 {
    margin-bottom: 50px;
}

.mg-l-50 {
    margin-left: 50px;
}

.mg-t-50-f {
    margin-top: 50px !important;
}

.mg-r-50-f {
    margin-right: 50px !important;
}

.mg-b-50-f {
    margin-bottom: 50px !important;
}

.mg-l-50-f {
    margin-left: 50px !important;
}

.mg-55 {
    margin: 55px;
}

.mg-55-f {
    margin: 55px !important;
}

.mg-y-55 {
    margin-top: 55px;
    margin-bottom: 55px;
}

.mg-y-55-f {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
}

.mg-x-55 {
    margin-left: 55px;
    margin-right: 55px;
}

.mg-x-55-f {
    margin-left: 55px !important;
    margin-right: 55px !important;
}

.mg-t-55 {
    margin-top: 55px;
}

.mg-r-55 {
    margin-right: 55px;
}

.mg-b-55 {
    margin-bottom: 55px;
}

.mg-l-55 {
    margin-left: 55px;
}

.mg-t-55-f {
    margin-top: 55px !important;
}

.mg-r-55-f {
    margin-right: 55px !important;
}

.mg-b-55-f {
    margin-bottom: 55px !important;
}

.mg-l-55-f {
    margin-left: 55px !important;
}

.mg-60 {
    margin: 60px;
}

.mg-60-f {
    margin: 60px !important;
}

.mg-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.mg-y-60-f {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
}

.mg-x-60 {
    margin-left: 60px;
    margin-right: 60px;
}

.mg-x-60-f {
    margin-left: 60px !important;
    margin-right: 60px !important;
}

.mg-t-60 {
    margin-top: 60px;
}

.mg-r-60 {
    margin-right: 60px;
}

.mg-b-60 {
    margin-bottom: 60px;
}

.mg-l-60 {
    margin-left: 60px;
}

.mg-t-60-f {
    margin-top: 60px !important;
}

.mg-r-60-f {
    margin-right: 60px !important;
}

.mg-b-60-f {
    margin-bottom: 60px !important;
}

.mg-l-60-f {
    margin-left: 60px !important;
}

.mg-65 {
    margin: 65px;
}

.mg-65-f {
    margin: 65px !important;
}

.mg-y-65 {
    margin-top: 65px;
    margin-bottom: 65px;
}

.mg-y-65-f {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
}

.mg-x-65 {
    margin-left: 65px;
    margin-right: 65px;
}

.mg-x-65-f {
    margin-left: 65px !important;
    margin-right: 65px !important;
}

.mg-t-65 {
    margin-top: 65px;
}

.mg-r-65 {
    margin-right: 65px;
}

.mg-b-65 {
    margin-bottom: 65px;
}

.mg-l-65 {
    margin-left: 65px;
}

.mg-t-65-f {
    margin-top: 65px !important;
}

.mg-r-65-f {
    margin-right: 65px !important;
}

.mg-b-65-f {
    margin-bottom: 65px !important;
}

.mg-l-65-f {
    margin-left: 65px !important;
}

.mg-70 {
    margin: 70px;
}

.mg-70-f {
    margin: 70px !important;
}

.mg-y-70 {
    margin-top: 70px;
    margin-bottom: 70px;
}

.mg-y-70-f {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
}

.mg-x-70 {
    margin-left: 70px;
    margin-right: 70px;
}

.mg-x-70-f {
    margin-left: 70px !important;
    margin-right: 70px !important;
}

.mg-t-70 {
    margin-top: 70px;
}

.mg-r-70 {
    margin-right: 70px;
}

.mg-b-70 {
    margin-bottom: 70px;
}

.mg-l-70 {
    margin-left: 70px;
}

.mg-t-70-f {
    margin-top: 70px !important;
}

.mg-r-70-f {
    margin-right: 70px !important;
}

.mg-b-70-f {
    margin-bottom: 70px !important;
}

.mg-l-70-f {
    margin-left: 70px !important;
}

.mg-75 {
    margin: 75px;
}

.mg-75-f {
    margin: 75px !important;
}

.mg-y-75 {
    margin-top: 75px;
    margin-bottom: 75px;
}

.mg-y-75-f {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
}

.mg-x-75 {
    margin-left: 75px;
    margin-right: 75px;
}

.mg-x-75-f {
    margin-left: 75px !important;
    margin-right: 75px !important;
}

.mg-t-75 {
    margin-top: 75px;
}

.mg-r-75 {
    margin-right: 75px;
}

.mg-b-75 {
    margin-bottom: 75px;
}

.mg-l-75 {
    margin-left: 75px;
}

.mg-t-75-f {
    margin-top: 75px !important;
}

.mg-r-75-f {
    margin-right: 75px !important;
}

.mg-b-75-f {
    margin-bottom: 75px !important;
}

.mg-l-75-f {
    margin-left: 75px !important;
}

.mg-80 {
    margin: 80px;
}

.mg-80-f {
    margin: 80px !important;
}

.mg-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.mg-y-80-f {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
}

.mg-x-80 {
    margin-left: 80px;
    margin-right: 80px;
}

.mg-x-80-f {
    margin-left: 80px !important;
    margin-right: 80px !important;
}

.mg-t-80 {
    margin-top: 80px;
}

.mg-r-80 {
    margin-right: 80px;
}

.mg-b-80 {
    margin-bottom: 80px;
}

.mg-l-80 {
    margin-left: 80px;
}

.mg-t-80-f {
    margin-top: 80px !important;
}

.mg-r-80-f {
    margin-right: 80px !important;
}

.mg-b-80-f {
    margin-bottom: 80px !important;
}

.mg-l-80-f {
    margin-left: 80px !important;
}

.mg-85 {
    margin: 85px;
}

.mg-85-f {
    margin: 85px !important;
}

.mg-y-85 {
    margin-top: 85px;
    margin-bottom: 85px;
}

.mg-y-85-f {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
}

.mg-x-85 {
    margin-left: 85px;
    margin-right: 85px;
}

.mg-x-85-f {
    margin-left: 85px !important;
    margin-right: 85px !important;
}

.mg-t-85 {
    margin-top: 85px;
}

.mg-r-85 {
    margin-right: 85px;
}

.mg-b-85 {
    margin-bottom: 85px;
}

.mg-l-85 {
    margin-left: 85px;
}

.mg-t-85-f {
    margin-top: 85px !important;
}

.mg-r-85-f {
    margin-right: 85px !important;
}

.mg-b-85-f {
    margin-bottom: 85px !important;
}

.mg-l-85-f {
    margin-left: 85px !important;
}

.mg-90 {
    margin: 90px;
}

.mg-90-f {
    margin: 90px !important;
}

.mg-y-90 {
    margin-top: 90px;
    margin-bottom: 90px;
}

.mg-y-90-f {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
}

.mg-x-90 {
    margin-left: 90px;
    margin-right: 90px;
}

.mg-x-90-f {
    margin-left: 90px !important;
    margin-right: 90px !important;
}

.mg-t-90 {
    margin-top: 90px;
}

.mg-r-90 {
    margin-right: 90px;
}

.mg-b-90 {
    margin-bottom: 90px;
}

.mg-l-90 {
    margin-left: 90px;
}

.mg-t-90-f {
    margin-top: 90px !important;
}

.mg-r-90-f {
    margin-right: 90px !important;
}

.mg-b-90-f {
    margin-bottom: 90px !important;
}

.mg-l-90-f {
    margin-left: 90px !important;
}

.mg-95 {
    margin: 95px;
}

.mg-95-f {
    margin: 95px !important;
}

.mg-y-95 {
    margin-top: 95px;
    margin-bottom: 95px;
}

.mg-y-95-f {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
}

.mg-x-95 {
    margin-left: 95px;
    margin-right: 95px;
}

.mg-x-95-f {
    margin-left: 95px !important;
    margin-right: 95px !important;
}

.mg-t-95 {
    margin-top: 95px;
}

.mg-r-95 {
    margin-right: 95px;
}

.mg-b-95 {
    margin-bottom: 95px;
}

.mg-l-95 {
    margin-left: 95px;
}

.mg-t-95-f {
    margin-top: 95px !important;
}

.mg-r-95-f {
    margin-right: 95px !important;
}

.mg-b-95-f {
    margin-bottom: 95px !important;
}

.mg-l-95-f {
    margin-left: 95px !important;
}

.mg-100 {
    margin: 100px;
}

.mg-100-f {
    margin: 100px !important;
}

.mg-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

.mg-y-100-f {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
}

.mg-x-100 {
    margin-left: 100px;
    margin-right: 100px;
}

.mg-x-100-f {
    margin-left: 100px !important;
    margin-right: 100px !important;
}

.mg-t-100 {
    margin-top: 100px;
}

.mg-r-100 {
    margin-right: 100px;
}

.mg-b-100 {
    margin-bottom: 100px;
}

.mg-l-100 {
    margin-left: 100px;
}

.mg-t-100-f {
    margin-top: 100px !important;
}

.mg-r-100-f {
    margin-right: 100px !important;
}

.mg-b-100-f {
    margin-bottom: 100px !important;
}

.mg-l-100-f {
    margin-left: 100px !important;
}

.mg-105 {
    margin: 105px;
}

.mg-105-f {
    margin: 105px !important;
}

.mg-y-105 {
    margin-top: 105px;
    margin-bottom: 105px;
}

.mg-y-105-f {
    margin-top: 105px !important;
    margin-bottom: 105px !important;
}

.mg-x-105 {
    margin-left: 105px;
    margin-right: 105px;
}

.mg-x-105-f {
    margin-left: 105px !important;
    margin-right: 105px !important;
}

.mg-t-105 {
    margin-top: 105px;
}

.mg-r-105 {
    margin-right: 105px;
}

.mg-b-105 {
    margin-bottom: 105px;
}

.mg-l-105 {
    margin-left: 105px;
}

.mg-t-105-f {
    margin-top: 105px !important;
}

.mg-r-105-f {
    margin-right: 105px !important;
}

.mg-b-105-f {
    margin-bottom: 105px !important;
}

.mg-l-105-f {
    margin-left: 105px !important;
}

.mg-110 {
    margin: 110px;
}

.mg-110-f {
    margin: 110px !important;
}

.mg-y-110 {
    margin-top: 110px;
    margin-bottom: 110px;
}

.mg-y-110-f {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
}

.mg-x-110 {
    margin-left: 110px;
    margin-right: 110px;
}

.mg-x-110-f {
    margin-left: 110px !important;
    margin-right: 110px !important;
}

.mg-t-110 {
    margin-top: 110px;
}

.mg-r-110 {
    margin-right: 110px;
}

.mg-b-110 {
    margin-bottom: 110px;
}

.mg-l-110 {
    margin-left: 110px;
}

.mg-t-110-f {
    margin-top: 110px !important;
}

.mg-r-110-f {
    margin-right: 110px !important;
}

.mg-b-110-f {
    margin-bottom: 110px !important;
}

.mg-l-110-f {
    margin-left: 110px !important;
}

.mg-115 {
    margin: 115px;
}

.mg-115-f {
    margin: 115px !important;
}

.mg-y-115 {
    margin-top: 115px;
    margin-bottom: 115px;
}

.mg-y-115-f {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
}

.mg-x-115 {
    margin-left: 115px;
    margin-right: 115px;
}

.mg-x-115-f {
    margin-left: 115px !important;
    margin-right: 115px !important;
}

.mg-t-115 {
    margin-top: 115px;
}

.mg-r-115 {
    margin-right: 115px;
}

.mg-b-115 {
    margin-bottom: 115px;
}

.mg-l-115 {
    margin-left: 115px;
}

.mg-t-115-f {
    margin-top: 115px !important;
}

.mg-r-115-f {
    margin-right: 115px !important;
}

.mg-b-115-f {
    margin-bottom: 115px !important;
}

.mg-l-115-f {
    margin-left: 115px !important;
}

.mg-120 {
    margin: 120px;
}

.mg-120-f {
    margin: 120px !important;
}

.mg-y-120 {
    margin-top: 120px;
    margin-bottom: 120px;
}

.mg-y-120-f {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
}

.mg-x-120 {
    margin-left: 120px;
    margin-right: 120px;
}

.mg-x-120-f {
    margin-left: 120px !important;
    margin-right: 120px !important;
}

.mg-t-120 {
    margin-top: 120px;
}

.mg-r-120 {
    margin-right: 120px;
}

.mg-b-120 {
    margin-bottom: 120px;
}

.mg-l-120 {
    margin-left: 120px;
}

.mg-t-120-f {
    margin-top: 120px !important;
}

.mg-r-120-f {
    margin-right: 120px !important;
}

.mg-b-120-f {
    margin-bottom: 120px !important;
}

.mg-l-120-f {
    margin-left: 120px !important;
}

.mg-t-auto {
    margin-top: auto;
}

.mg-r-auto {
    margin-right: auto;
}

.mg-b-auto {
    margin-bottom: auto;
}

.mg-l-auto {
    margin-left: auto;
}

.mg-x-auto {
    margin: auto;
}

@media (min-width: 480px) {
    .mg-xs-0 {
        margin: 0px;
    }

    .mg-xs-0-f {
        margin: 0px !important;
    }

    .mg-xs-y-0 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .mg-xs-y-0-f {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .mg-xs-x-0 {
        margin-left: 0px;
        margin-right: 0px;
    }

    .mg-xs-x-0-f {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .mg-xs-t-0 {
        margin-top: 0px;
    }

    .mg-xs-r-0 {
        margin-right: 0px;
    }

    .mg-xs-b-0 {
        margin-bottom: 0px;
    }

    .mg-xs-l-0 {
        margin-left: 0px;
    }

    .mg-xs-t-0-f {
        margin-top: 0px !important;
    }

    .mg-xs-r-0-f {
        margin-right: 0px !important;
    }

    .mg-xs-b-0-f {
        margin-bottom: 0px !important;
    }

    .mg-xs-l-0-f {
        margin-left: 0px !important;
    }

    .mg-xs-1 {
        margin: 1px;
    }

    .mg-xs-1-f {
        margin: 1px !important;
    }

    .mg-xs-y-1 {
        margin-top: 1px;
        margin-bottom: 1px;
    }

    .mg-xs-y-1-f {
        margin-top: 1px !important;
        margin-bottom: 1px !important;
    }

    .mg-xs-x-1 {
        margin-left: 1px;
        margin-right: 1px;
    }

    .mg-xs-x-1-f {
        margin-left: 1px !important;
        margin-right: 1px !important;
    }

    .mg-xs-t-1 {
        margin-top: 1px;
    }

    .mg-xs-r-1 {
        margin-right: 1px;
    }

    .mg-xs-b-1 {
        margin-bottom: 1px;
    }

    .mg-xs-l-1 {
        margin-left: 1px;
    }

    .mg-xs-t-1-f {
        margin-top: 1px !important;
    }

    .mg-xs-r-1-f {
        margin-right: 1px !important;
    }

    .mg-xs-b-1-f {
        margin-bottom: 1px !important;
    }

    .mg-xs-l-1-f {
        margin-left: 1px !important;
    }

    .mg-xs-2 {
        margin: 2px;
    }

    .mg-xs-2-f {
        margin: 2px !important;
    }

    .mg-xs-y-2 {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .mg-xs-y-2-f {
        margin-top: 2px !important;
        margin-bottom: 2px !important;
    }

    .mg-xs-x-2 {
        margin-left: 2px;
        margin-right: 2px;
    }

    .mg-xs-x-2-f {
        margin-left: 2px !important;
        margin-right: 2px !important;
    }

    .mg-xs-t-2 {
        margin-top: 2px;
    }

    .mg-xs-r-2 {
        margin-right: 2px;
    }

    .mg-xs-b-2 {
        margin-bottom: 2px;
    }

    .mg-xs-l-2 {
        margin-left: 2px;
    }

    .mg-xs-t-2-f {
        margin-top: 2px !important;
    }

    .mg-xs-r-2-f {
        margin-right: 2px !important;
    }

    .mg-xs-b-2-f {
        margin-bottom: 2px !important;
    }

    .mg-xs-l-2-f {
        margin-left: 2px !important;
    }

    .mg-xs-3 {
        margin: 3px;
    }

    .mg-xs-3-f {
        margin: 3px !important;
    }

    .mg-xs-y-3 {
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .mg-xs-y-3-f {
        margin-top: 3px !important;
        margin-bottom: 3px !important;
    }

    .mg-xs-x-3 {
        margin-left: 3px;
        margin-right: 3px;
    }

    .mg-xs-x-3-f {
        margin-left: 3px !important;
        margin-right: 3px !important;
    }

    .mg-xs-t-3 {
        margin-top: 3px;
    }

    .mg-xs-r-3 {
        margin-right: 3px;
    }

    .mg-xs-b-3 {
        margin-bottom: 3px;
    }

    .mg-xs-l-3 {
        margin-left: 3px;
    }

    .mg-xs-t-3-f {
        margin-top: 3px !important;
    }

    .mg-xs-r-3-f {
        margin-right: 3px !important;
    }

    .mg-xs-b-3-f {
        margin-bottom: 3px !important;
    }

    .mg-xs-l-3-f {
        margin-left: 3px !important;
    }

    .mg-xs-4 {
        margin: 4px;
    }

    .mg-xs-4-f {
        margin: 4px !important;
    }

    .mg-xs-y-4 {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .mg-xs-y-4-f {
        margin-top: 4px !important;
        margin-bottom: 4px !important;
    }

    .mg-xs-x-4 {
        margin-left: 4px;
        margin-right: 4px;
    }

    .mg-xs-x-4-f {
        margin-left: 4px !important;
        margin-right: 4px !important;
    }

    .mg-xs-t-4 {
        margin-top: 4px;
    }

    .mg-xs-r-4 {
        margin-right: 4px;
    }

    .mg-xs-b-4 {
        margin-bottom: 4px;
    }

    .mg-xs-l-4 {
        margin-left: 4px;
    }

    .mg-xs-t-4-f {
        margin-top: 4px !important;
    }

    .mg-xs-r-4-f {
        margin-right: 4px !important;
    }

    .mg-xs-b-4-f {
        margin-bottom: 4px !important;
    }

    .mg-xs-l-4-f {
        margin-left: 4px !important;
    }

    .mg-xs-5 {
        margin: 5px;
    }

    .mg-xs-5-f {
        margin: 5px !important;
    }

    .mg-xs-y-5 {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .mg-xs-y-5-f {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }

    .mg-xs-x-5 {
        margin-left: 5px;
        margin-right: 5px;
    }

    .mg-xs-x-5-f {
        margin-left: 5px !important;
        margin-right: 5px !important;
    }

    .mg-xs-t-5 {
        margin-top: 5px;
    }

    .mg-xs-r-5 {
        margin-right: 5px;
    }

    .mg-xs-b-5 {
        margin-bottom: 5px;
    }

    .mg-xs-l-5 {
        margin-left: 5px;
    }

    .mg-xs-t-5-f {
        margin-top: 5px !important;
    }

    .mg-xs-r-5-f {
        margin-right: 5px !important;
    }

    .mg-xs-b-5-f {
        margin-bottom: 5px !important;
    }

    .mg-xs-l-5-f {
        margin-left: 5px !important;
    }

    .mg-xs-6 {
        margin: 6px;
    }

    .mg-xs-6-f {
        margin: 6px !important;
    }

    .mg-xs-y-6 {
        margin-top: 6px;
        margin-bottom: 6px;
    }

    .mg-xs-y-6-f {
        margin-top: 6px !important;
        margin-bottom: 6px !important;
    }

    .mg-xs-x-6 {
        margin-left: 6px;
        margin-right: 6px;
    }

    .mg-xs-x-6-f {
        margin-left: 6px !important;
        margin-right: 6px !important;
    }

    .mg-xs-t-6 {
        margin-top: 6px;
    }

    .mg-xs-r-6 {
        margin-right: 6px;
    }

    .mg-xs-b-6 {
        margin-bottom: 6px;
    }

    .mg-xs-l-6 {
        margin-left: 6px;
    }

    .mg-xs-t-6-f {
        margin-top: 6px !important;
    }

    .mg-xs-r-6-f {
        margin-right: 6px !important;
    }

    .mg-xs-b-6-f {
        margin-bottom: 6px !important;
    }

    .mg-xs-l-6-f {
        margin-left: 6px !important;
    }

    .mg-xs-7 {
        margin: 7px;
    }

    .mg-xs-7-f {
        margin: 7px !important;
    }

    .mg-xs-y-7 {
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .mg-xs-y-7-f {
        margin-top: 7px !important;
        margin-bottom: 7px !important;
    }

    .mg-xs-x-7 {
        margin-left: 7px;
        margin-right: 7px;
    }

    .mg-xs-x-7-f {
        margin-left: 7px !important;
        margin-right: 7px !important;
    }

    .mg-xs-t-7 {
        margin-top: 7px;
    }

    .mg-xs-r-7 {
        margin-right: 7px;
    }

    .mg-xs-b-7 {
        margin-bottom: 7px;
    }

    .mg-xs-l-7 {
        margin-left: 7px;
    }

    .mg-xs-t-7-f {
        margin-top: 7px !important;
    }

    .mg-xs-r-7-f {
        margin-right: 7px !important;
    }

    .mg-xs-b-7-f {
        margin-bottom: 7px !important;
    }

    .mg-xs-l-7-f {
        margin-left: 7px !important;
    }

    .mg-xs-8 {
        margin: 8px;
    }

    .mg-xs-8-f {
        margin: 8px !important;
    }

    .mg-xs-y-8 {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .mg-xs-y-8-f {
        margin-top: 8px !important;
        margin-bottom: 8px !important;
    }

    .mg-xs-x-8 {
        margin-left: 8px;
        margin-right: 8px;
    }

    .mg-xs-x-8-f {
        margin-left: 8px !important;
        margin-right: 8px !important;
    }

    .mg-xs-t-8 {
        margin-top: 8px;
    }

    .mg-xs-r-8 {
        margin-right: 8px;
    }

    .mg-xs-b-8 {
        margin-bottom: 8px;
    }

    .mg-xs-l-8 {
        margin-left: 8px;
    }

    .mg-xs-t-8-f {
        margin-top: 8px !important;
    }

    .mg-xs-r-8-f {
        margin-right: 8px !important;
    }

    .mg-xs-b-8-f {
        margin-bottom: 8px !important;
    }

    .mg-xs-l-8-f {
        margin-left: 8px !important;
    }

    .mg-xs-9 {
        margin: 9px;
    }

    .mg-xs-9-f {
        margin: 9px !important;
    }

    .mg-xs-y-9 {
        margin-top: 9px;
        margin-bottom: 9px;
    }

    .mg-xs-y-9-f {
        margin-top: 9px !important;
        margin-bottom: 9px !important;
    }

    .mg-xs-x-9 {
        margin-left: 9px;
        margin-right: 9px;
    }

    .mg-xs-x-9-f {
        margin-left: 9px !important;
        margin-right: 9px !important;
    }

    .mg-xs-t-9 {
        margin-top: 9px;
    }

    .mg-xs-r-9 {
        margin-right: 9px;
    }

    .mg-xs-b-9 {
        margin-bottom: 9px;
    }

    .mg-xs-l-9 {
        margin-left: 9px;
    }

    .mg-xs-t-9-f {
        margin-top: 9px !important;
    }

    .mg-xs-r-9-f {
        margin-right: 9px !important;
    }

    .mg-xs-b-9-f {
        margin-bottom: 9px !important;
    }

    .mg-xs-l-9-f {
        margin-left: 9px !important;
    }

    .mg-xs-10 {
        margin: 10px;
    }

    .mg-xs-10-f {
        margin: 10px !important;
    }

    .mg-xs-y-10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .mg-xs-y-10-f {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    .mg-xs-x-10 {
        margin-left: 10px;
        margin-right: 10px;
    }

    .mg-xs-x-10-f {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }

    .mg-xs-t-10 {
        margin-top: 10px;
    }

    .mg-xs-r-10 {
        margin-right: 10px;
    }

    .mg-xs-b-10 {
        margin-bottom: 10px;
    }

    .mg-xs-l-10 {
        margin-left: 10px;
    }

    .mg-xs-t-10-f {
        margin-top: 10px !important;
    }

    .mg-xs-r-10-f {
        margin-right: 10px !important;
    }

    .mg-xs-b-10-f {
        margin-bottom: 10px !important;
    }

    .mg-xs-l-10-f {
        margin-left: 10px !important;
    }

    .mg-xs-15 {
        margin: 15px;
    }

    .mg-xs-15-f {
        margin: 15px !important;
    }

    .mg-xs-y-15 {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .mg-xs-y-15-f {
        margin-top: 15px !important;
        margin-bottom: 15px !important;
    }

    .mg-xs-x-15 {
        margin-left: 15px;
        margin-right: 15px;
    }

    .mg-xs-x-15-f {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }

    .mg-xs-t-15 {
        margin-top: 15px;
    }

    .mg-xs-r-15 {
        margin-right: 15px;
    }

    .mg-xs-b-15 {
        margin-bottom: 15px;
    }

    .mg-xs-l-15 {
        margin-left: 15px;
    }

    .mg-xs-t-15-f {
        margin-top: 15px !important;
    }

    .mg-xs-r-15-f {
        margin-right: 15px !important;
    }

    .mg-xs-b-15-f {
        margin-bottom: 15px !important;
    }

    .mg-xs-l-15-f {
        margin-left: 15px !important;
    }

    .mg-xs-20 {
        margin: 20px;
    }

    .mg-xs-20-f {
        margin: 20px !important;
    }

    .mg-xs-y-20 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .mg-xs-y-20-f {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .mg-xs-x-20 {
        margin-left: 20px;
        margin-right: 20px;
    }

    .mg-xs-x-20-f {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }

    .mg-xs-t-20 {
        margin-top: 20px;
    }

    .mg-xs-r-20 {
        margin-right: 20px;
    }

    .mg-xs-b-20 {
        margin-bottom: 20px;
    }

    .mg-xs-l-20 {
        margin-left: 20px;
    }

    .mg-xs-t-20-f {
        margin-top: 20px !important;
    }

    .mg-xs-r-20-f {
        margin-right: 20px !important;
    }

    .mg-xs-b-20-f {
        margin-bottom: 20px !important;
    }

    .mg-xs-l-20-f {
        margin-left: 20px !important;
    }

    .mg-xs-25 {
        margin: 25px;
    }

    .mg-xs-25-f {
        margin: 25px !important;
    }

    .mg-xs-y-25 {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .mg-xs-y-25-f {
        margin-top: 25px !important;
        margin-bottom: 25px !important;
    }

    .mg-xs-x-25 {
        margin-left: 25px;
        margin-right: 25px;
    }

    .mg-xs-x-25-f {
        margin-left: 25px !important;
        margin-right: 25px !important;
    }

    .mg-xs-t-25 {
        margin-top: 25px;
    }

    .mg-xs-r-25 {
        margin-right: 25px;
    }

    .mg-xs-b-25 {
        margin-bottom: 25px;
    }

    .mg-xs-l-25 {
        margin-left: 25px;
    }

    .mg-xs-t-25-f {
        margin-top: 25px !important;
    }

    .mg-xs-r-25-f {
        margin-right: 25px !important;
    }

    .mg-xs-b-25-f {
        margin-bottom: 25px !important;
    }

    .mg-xs-l-25-f {
        margin-left: 25px !important;
    }

    .mg-xs-30 {
        margin: 30px;
    }

    .mg-xs-30-f {
        margin: 30px !important;
    }

    .mg-xs-y-30 {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .mg-xs-y-30-f {
        margin-top: 30px !important;
        margin-bottom: 30px !important;
    }

    .mg-xs-x-30 {
        margin-left: 30px;
        margin-right: 30px;
    }

    .mg-xs-x-30-f {
        margin-left: 30px !important;
        margin-right: 30px !important;
    }

    .mg-xs-t-30 {
        margin-top: 30px;
    }

    .mg-xs-r-30 {
        margin-right: 30px;
    }

    .mg-xs-b-30 {
        margin-bottom: 30px;
    }

    .mg-xs-l-30 {
        margin-left: 30px;
    }

    .mg-xs-t-30-f {
        margin-top: 30px !important;
    }

    .mg-xs-r-30-f {
        margin-right: 30px !important;
    }

    .mg-xs-b-30-f {
        margin-bottom: 30px !important;
    }

    .mg-xs-l-30-f {
        margin-left: 30px !important;
    }

    .mg-xs-35 {
        margin: 35px;
    }

    .mg-xs-35-f {
        margin: 35px !important;
    }

    .mg-xs-y-35 {
        margin-top: 35px;
        margin-bottom: 35px;
    }

    .mg-xs-y-35-f {
        margin-top: 35px !important;
        margin-bottom: 35px !important;
    }

    .mg-xs-x-35 {
        margin-left: 35px;
        margin-right: 35px;
    }

    .mg-xs-x-35-f {
        margin-left: 35px !important;
        margin-right: 35px !important;
    }

    .mg-xs-t-35 {
        margin-top: 35px;
    }

    .mg-xs-r-35 {
        margin-right: 35px;
    }

    .mg-xs-b-35 {
        margin-bottom: 35px;
    }

    .mg-xs-l-35 {
        margin-left: 35px;
    }

    .mg-xs-t-35-f {
        margin-top: 35px !important;
    }

    .mg-xs-r-35-f {
        margin-right: 35px !important;
    }

    .mg-xs-b-35-f {
        margin-bottom: 35px !important;
    }

    .mg-xs-l-35-f {
        margin-left: 35px !important;
    }

    .mg-xs-40 {
        margin: 40px;
    }

    .mg-xs-40-f {
        margin: 40px !important;
    }

    .mg-xs-y-40 {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .mg-xs-y-40-f {
        margin-top: 40px !important;
        margin-bottom: 40px !important;
    }

    .mg-xs-x-40 {
        margin-left: 40px;
        margin-right: 40px;
    }

    .mg-xs-x-40-f {
        margin-left: 40px !important;
        margin-right: 40px !important;
    }

    .mg-xs-t-40 {
        margin-top: 40px;
    }

    .mg-xs-r-40 {
        margin-right: 40px;
    }

    .mg-xs-b-40 {
        margin-bottom: 40px;
    }

    .mg-xs-l-40 {
        margin-left: 40px;
    }

    .mg-xs-t-40-f {
        margin-top: 40px !important;
    }

    .mg-xs-r-40-f {
        margin-right: 40px !important;
    }

    .mg-xs-b-40-f {
        margin-bottom: 40px !important;
    }

    .mg-xs-l-40-f {
        margin-left: 40px !important;
    }

    .mg-xs-45 {
        margin: 45px;
    }

    .mg-xs-45-f {
        margin: 45px !important;
    }

    .mg-xs-y-45 {
        margin-top: 45px;
        margin-bottom: 45px;
    }

    .mg-xs-y-45-f {
        margin-top: 45px !important;
        margin-bottom: 45px !important;
    }

    .mg-xs-x-45 {
        margin-left: 45px;
        margin-right: 45px;
    }

    .mg-xs-x-45-f {
        margin-left: 45px !important;
        margin-right: 45px !important;
    }

    .mg-xs-t-45 {
        margin-top: 45px;
    }

    .mg-xs-r-45 {
        margin-right: 45px;
    }

    .mg-xs-b-45 {
        margin-bottom: 45px;
    }

    .mg-xs-l-45 {
        margin-left: 45px;
    }

    .mg-xs-t-45-f {
        margin-top: 45px !important;
    }

    .mg-xs-r-45-f {
        margin-right: 45px !important;
    }

    .mg-xs-b-45-f {
        margin-bottom: 45px !important;
    }

    .mg-xs-l-45-f {
        margin-left: 45px !important;
    }

    .mg-xs-50 {
        margin: 50px;
    }

    .mg-xs-50-f {
        margin: 50px !important;
    }

    .mg-xs-y-50 {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .mg-xs-y-50-f {
        margin-top: 50px !important;
        margin-bottom: 50px !important;
    }

    .mg-xs-x-50 {
        margin-left: 50px;
        margin-right: 50px;
    }

    .mg-xs-x-50-f {
        margin-left: 50px !important;
        margin-right: 50px !important;
    }

    .mg-xs-t-50 {
        margin-top: 50px;
    }

    .mg-xs-r-50 {
        margin-right: 50px;
    }

    .mg-xs-b-50 {
        margin-bottom: 50px;
    }

    .mg-xs-l-50 {
        margin-left: 50px;
    }

    .mg-xs-t-50-f {
        margin-top: 50px !important;
    }

    .mg-xs-r-50-f {
        margin-right: 50px !important;
    }

    .mg-xs-b-50-f {
        margin-bottom: 50px !important;
    }

    .mg-xs-l-50-f {
        margin-left: 50px !important;
    }

    .mg-xs-55 {
        margin: 55px;
    }

    .mg-xs-55-f {
        margin: 55px !important;
    }

    .mg-xs-y-55 {
        margin-top: 55px;
        margin-bottom: 55px;
    }

    .mg-xs-y-55-f {
        margin-top: 55px !important;
        margin-bottom: 55px !important;
    }

    .mg-xs-x-55 {
        margin-left: 55px;
        margin-right: 55px;
    }

    .mg-xs-x-55-f {
        margin-left: 55px !important;
        margin-right: 55px !important;
    }

    .mg-xs-t-55 {
        margin-top: 55px;
    }

    .mg-xs-r-55 {
        margin-right: 55px;
    }

    .mg-xs-b-55 {
        margin-bottom: 55px;
    }

    .mg-xs-l-55 {
        margin-left: 55px;
    }

    .mg-xs-t-55-f {
        margin-top: 55px !important;
    }

    .mg-xs-r-55-f {
        margin-right: 55px !important;
    }

    .mg-xs-b-55-f {
        margin-bottom: 55px !important;
    }

    .mg-xs-l-55-f {
        margin-left: 55px !important;
    }

    .mg-xs-60 {
        margin: 60px;
    }

    .mg-xs-60-f {
        margin: 60px !important;
    }

    .mg-xs-y-60 {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .mg-xs-y-60-f {
        margin-top: 60px !important;
        margin-bottom: 60px !important;
    }

    .mg-xs-x-60 {
        margin-left: 60px;
        margin-right: 60px;
    }

    .mg-xs-x-60-f {
        margin-left: 60px !important;
        margin-right: 60px !important;
    }

    .mg-xs-t-60 {
        margin-top: 60px;
    }

    .mg-xs-r-60 {
        margin-right: 60px;
    }

    .mg-xs-b-60 {
        margin-bottom: 60px;
    }

    .mg-xs-l-60 {
        margin-left: 60px;
    }

    .mg-xs-t-60-f {
        margin-top: 60px !important;
    }

    .mg-xs-r-60-f {
        margin-right: 60px !important;
    }

    .mg-xs-b-60-f {
        margin-bottom: 60px !important;
    }

    .mg-xs-l-60-f {
        margin-left: 60px !important;
    }

    .mg-xs-65 {
        margin: 65px;
    }

    .mg-xs-65-f {
        margin: 65px !important;
    }

    .mg-xs-y-65 {
        margin-top: 65px;
        margin-bottom: 65px;
    }

    .mg-xs-y-65-f {
        margin-top: 65px !important;
        margin-bottom: 65px !important;
    }

    .mg-xs-x-65 {
        margin-left: 65px;
        margin-right: 65px;
    }

    .mg-xs-x-65-f {
        margin-left: 65px !important;
        margin-right: 65px !important;
    }

    .mg-xs-t-65 {
        margin-top: 65px;
    }

    .mg-xs-r-65 {
        margin-right: 65px;
    }

    .mg-xs-b-65 {
        margin-bottom: 65px;
    }

    .mg-xs-l-65 {
        margin-left: 65px;
    }

    .mg-xs-t-65-f {
        margin-top: 65px !important;
    }

    .mg-xs-r-65-f {
        margin-right: 65px !important;
    }

    .mg-xs-b-65-f {
        margin-bottom: 65px !important;
    }

    .mg-xs-l-65-f {
        margin-left: 65px !important;
    }

    .mg-xs-70 {
        margin: 70px;
    }

    .mg-xs-70-f {
        margin: 70px !important;
    }

    .mg-xs-y-70 {
        margin-top: 70px;
        margin-bottom: 70px;
    }

    .mg-xs-y-70-f {
        margin-top: 70px !important;
        margin-bottom: 70px !important;
    }

    .mg-xs-x-70 {
        margin-left: 70px;
        margin-right: 70px;
    }

    .mg-xs-x-70-f {
        margin-left: 70px !important;
        margin-right: 70px !important;
    }

    .mg-xs-t-70 {
        margin-top: 70px;
    }

    .mg-xs-r-70 {
        margin-right: 70px;
    }

    .mg-xs-b-70 {
        margin-bottom: 70px;
    }

    .mg-xs-l-70 {
        margin-left: 70px;
    }

    .mg-xs-t-70-f {
        margin-top: 70px !important;
    }

    .mg-xs-r-70-f {
        margin-right: 70px !important;
    }

    .mg-xs-b-70-f {
        margin-bottom: 70px !important;
    }

    .mg-xs-l-70-f {
        margin-left: 70px !important;
    }

    .mg-xs-75 {
        margin: 75px;
    }

    .mg-xs-75-f {
        margin: 75px !important;
    }

    .mg-xs-y-75 {
        margin-top: 75px;
        margin-bottom: 75px;
    }

    .mg-xs-y-75-f {
        margin-top: 75px !important;
        margin-bottom: 75px !important;
    }

    .mg-xs-x-75 {
        margin-left: 75px;
        margin-right: 75px;
    }

    .mg-xs-x-75-f {
        margin-left: 75px !important;
        margin-right: 75px !important;
    }

    .mg-xs-t-75 {
        margin-top: 75px;
    }

    .mg-xs-r-75 {
        margin-right: 75px;
    }

    .mg-xs-b-75 {
        margin-bottom: 75px;
    }

    .mg-xs-l-75 {
        margin-left: 75px;
    }

    .mg-xs-t-75-f {
        margin-top: 75px !important;
    }

    .mg-xs-r-75-f {
        margin-right: 75px !important;
    }

    .mg-xs-b-75-f {
        margin-bottom: 75px !important;
    }

    .mg-xs-l-75-f {
        margin-left: 75px !important;
    }

    .mg-xs-80 {
        margin: 80px;
    }

    .mg-xs-80-f {
        margin: 80px !important;
    }

    .mg-xs-y-80 {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .mg-xs-y-80-f {
        margin-top: 80px !important;
        margin-bottom: 80px !important;
    }

    .mg-xs-x-80 {
        margin-left: 80px;
        margin-right: 80px;
    }

    .mg-xs-x-80-f {
        margin-left: 80px !important;
        margin-right: 80px !important;
    }

    .mg-xs-t-80 {
        margin-top: 80px;
    }

    .mg-xs-r-80 {
        margin-right: 80px;
    }

    .mg-xs-b-80 {
        margin-bottom: 80px;
    }

    .mg-xs-l-80 {
        margin-left: 80px;
    }

    .mg-xs-t-80-f {
        margin-top: 80px !important;
    }

    .mg-xs-r-80-f {
        margin-right: 80px !important;
    }

    .mg-xs-b-80-f {
        margin-bottom: 80px !important;
    }

    .mg-xs-l-80-f {
        margin-left: 80px !important;
    }

    .mg-xs-85 {
        margin: 85px;
    }

    .mg-xs-85-f {
        margin: 85px !important;
    }

    .mg-xs-y-85 {
        margin-top: 85px;
        margin-bottom: 85px;
    }

    .mg-xs-y-85-f {
        margin-top: 85px !important;
        margin-bottom: 85px !important;
    }

    .mg-xs-x-85 {
        margin-left: 85px;
        margin-right: 85px;
    }

    .mg-xs-x-85-f {
        margin-left: 85px !important;
        margin-right: 85px !important;
    }

    .mg-xs-t-85 {
        margin-top: 85px;
    }

    .mg-xs-r-85 {
        margin-right: 85px;
    }

    .mg-xs-b-85 {
        margin-bottom: 85px;
    }

    .mg-xs-l-85 {
        margin-left: 85px;
    }

    .mg-xs-t-85-f {
        margin-top: 85px !important;
    }

    .mg-xs-r-85-f {
        margin-right: 85px !important;
    }

    .mg-xs-b-85-f {
        margin-bottom: 85px !important;
    }

    .mg-xs-l-85-f {
        margin-left: 85px !important;
    }

    .mg-xs-90 {
        margin: 90px;
    }

    .mg-xs-90-f {
        margin: 90px !important;
    }

    .mg-xs-y-90 {
        margin-top: 90px;
        margin-bottom: 90px;
    }

    .mg-xs-y-90-f {
        margin-top: 90px !important;
        margin-bottom: 90px !important;
    }

    .mg-xs-x-90 {
        margin-left: 90px;
        margin-right: 90px;
    }

    .mg-xs-x-90-f {
        margin-left: 90px !important;
        margin-right: 90px !important;
    }

    .mg-xs-t-90 {
        margin-top: 90px;
    }

    .mg-xs-r-90 {
        margin-right: 90px;
    }

    .mg-xs-b-90 {
        margin-bottom: 90px;
    }

    .mg-xs-l-90 {
        margin-left: 90px;
    }

    .mg-xs-t-90-f {
        margin-top: 90px !important;
    }

    .mg-xs-r-90-f {
        margin-right: 90px !important;
    }

    .mg-xs-b-90-f {
        margin-bottom: 90px !important;
    }

    .mg-xs-l-90-f {
        margin-left: 90px !important;
    }

    .mg-xs-95 {
        margin: 95px;
    }

    .mg-xs-95-f {
        margin: 95px !important;
    }

    .mg-xs-y-95 {
        margin-top: 95px;
        margin-bottom: 95px;
    }

    .mg-xs-y-95-f {
        margin-top: 95px !important;
        margin-bottom: 95px !important;
    }

    .mg-xs-x-95 {
        margin-left: 95px;
        margin-right: 95px;
    }

    .mg-xs-x-95-f {
        margin-left: 95px !important;
        margin-right: 95px !important;
    }

    .mg-xs-t-95 {
        margin-top: 95px;
    }

    .mg-xs-r-95 {
        margin-right: 95px;
    }

    .mg-xs-b-95 {
        margin-bottom: 95px;
    }

    .mg-xs-l-95 {
        margin-left: 95px;
    }

    .mg-xs-t-95-f {
        margin-top: 95px !important;
    }

    .mg-xs-r-95-f {
        margin-right: 95px !important;
    }

    .mg-xs-b-95-f {
        margin-bottom: 95px !important;
    }

    .mg-xs-l-95-f {
        margin-left: 95px !important;
    }

    .mg-xs-100 {
        margin: 100px;
    }

    .mg-xs-100-f {
        margin: 100px !important;
    }

    .mg-xs-y-100 {
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .mg-xs-y-100-f {
        margin-top: 100px !important;
        margin-bottom: 100px !important;
    }

    .mg-xs-x-100 {
        margin-left: 100px;
        margin-right: 100px;
    }

    .mg-xs-x-100-f {
        margin-left: 100px !important;
        margin-right: 100px !important;
    }

    .mg-xs-t-100 {
        margin-top: 100px;
    }

    .mg-xs-r-100 {
        margin-right: 100px;
    }

    .mg-xs-b-100 {
        margin-bottom: 100px;
    }

    .mg-xs-l-100 {
        margin-left: 100px;
    }

    .mg-xs-t-100-f {
        margin-top: 100px !important;
    }

    .mg-xs-r-100-f {
        margin-right: 100px !important;
    }

    .mg-xs-b-100-f {
        margin-bottom: 100px !important;
    }

    .mg-xs-l-100-f {
        margin-left: 100px !important;
    }

    .mg-xs-t-auto {
        margin-top: auto;
    }

    .mg-xs-r-auto {
        margin-right: auto;
    }

    .mg-xs-b-auto {
        margin-bottom: auto;
    }

    .mg-xs-l-auto {
        margin-left: auto;
    }

    .mg-xs-auto {
        margin: auto;
    }

    .mg-xs-x-auto {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 576px) {
    .mg-sm-0 {
        margin: 0px;
    }

    .mg-sm-0-f {
        margin: 0px !important;
    }

    .mg-sm-y-0 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .mg-sm-y-0-f {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .mg-sm-x-0 {
        margin-left: 0px;
        margin-right: 0px;
    }

    .mg-sm-x-0-f {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .mg-sm-t-0 {
        margin-top: 0px;
    }

    .mg-sm-r-0 {
        margin-right: 0px;
    }

    .mg-sm-b-0 {
        margin-bottom: 0px;
    }

    .mg-sm-l-0 {
        margin-left: 0px;
    }

    .mg-sm-t-0-f {
        margin-top: 0px !important;
    }

    .mg-sm-r-0-f {
        margin-right: 0px !important;
    }

    .mg-sm-b-0-f {
        margin-bottom: 0px !important;
    }

    .mg-sm-l-0-f {
        margin-left: 0px !important;
    }

    .mg-sm-1 {
        margin: 1px;
    }

    .mg-sm-1-f {
        margin: 1px !important;
    }

    .mg-sm-y-1 {
        margin-top: 1px;
        margin-bottom: 1px;
    }

    .mg-sm-y-1-f {
        margin-top: 1px !important;
        margin-bottom: 1px !important;
    }

    .mg-sm-x-1 {
        margin-left: 1px;
        margin-right: 1px;
    }

    .mg-sm-x-1-f {
        margin-left: 1px !important;
        margin-right: 1px !important;
    }

    .mg-sm-t-1 {
        margin-top: 1px;
    }

    .mg-sm-r-1 {
        margin-right: 1px;
    }

    .mg-sm-b-1 {
        margin-bottom: 1px;
    }

    .mg-sm-l-1 {
        margin-left: 1px;
    }

    .mg-sm-t-1-f {
        margin-top: 1px !important;
    }

    .mg-sm-r-1-f {
        margin-right: 1px !important;
    }

    .mg-sm-b-1-f {
        margin-bottom: 1px !important;
    }

    .mg-sm-l-1-f {
        margin-left: 1px !important;
    }

    .mg-sm-2 {
        margin: 2px;
    }

    .mg-sm-2-f {
        margin: 2px !important;
    }

    .mg-sm-y-2 {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .mg-sm-y-2-f {
        margin-top: 2px !important;
        margin-bottom: 2px !important;
    }

    .mg-sm-x-2 {
        margin-left: 2px;
        margin-right: 2px;
    }

    .mg-sm-x-2-f {
        margin-left: 2px !important;
        margin-right: 2px !important;
    }

    .mg-sm-t-2 {
        margin-top: 2px;
    }

    .mg-sm-r-2 {
        margin-right: 2px;
    }

    .mg-sm-b-2 {
        margin-bottom: 2px;
    }

    .mg-sm-l-2 {
        margin-left: 2px;
    }

    .mg-sm-t-2-f {
        margin-top: 2px !important;
    }

    .mg-sm-r-2-f {
        margin-right: 2px !important;
    }

    .mg-sm-b-2-f {
        margin-bottom: 2px !important;
    }

    .mg-sm-l-2-f {
        margin-left: 2px !important;
    }

    .mg-sm-3 {
        margin: 3px;
    }

    .mg-sm-3-f {
        margin: 3px !important;
    }

    .mg-sm-y-3 {
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .mg-sm-y-3-f {
        margin-top: 3px !important;
        margin-bottom: 3px !important;
    }

    .mg-sm-x-3 {
        margin-left: 3px;
        margin-right: 3px;
    }

    .mg-sm-x-3-f {
        margin-left: 3px !important;
        margin-right: 3px !important;
    }

    .mg-sm-t-3 {
        margin-top: 3px;
    }

    .mg-sm-r-3 {
        margin-right: 3px;
    }

    .mg-sm-b-3 {
        margin-bottom: 3px;
    }

    .mg-sm-l-3 {
        margin-left: 3px;
    }

    .mg-sm-t-3-f {
        margin-top: 3px !important;
    }

    .mg-sm-r-3-f {
        margin-right: 3px !important;
    }

    .mg-sm-b-3-f {
        margin-bottom: 3px !important;
    }

    .mg-sm-l-3-f {
        margin-left: 3px !important;
    }

    .mg-sm-4 {
        margin: 4px;
    }

    .mg-sm-4-f {
        margin: 4px !important;
    }

    .mg-sm-y-4 {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .mg-sm-y-4-f {
        margin-top: 4px !important;
        margin-bottom: 4px !important;
    }

    .mg-sm-x-4 {
        margin-left: 4px;
        margin-right: 4px;
    }

    .mg-sm-x-4-f {
        margin-left: 4px !important;
        margin-right: 4px !important;
    }

    .mg-sm-t-4 {
        margin-top: 4px;
    }

    .mg-sm-r-4 {
        margin-right: 4px;
    }

    .mg-sm-b-4 {
        margin-bottom: 4px;
    }

    .mg-sm-l-4 {
        margin-left: 4px;
    }

    .mg-sm-t-4-f {
        margin-top: 4px !important;
    }

    .mg-sm-r-4-f {
        margin-right: 4px !important;
    }

    .mg-sm-b-4-f {
        margin-bottom: 4px !important;
    }

    .mg-sm-l-4-f {
        margin-left: 4px !important;
    }

    .mg-sm-5 {
        margin: 5px;
    }

    .mg-sm-5-f {
        margin: 5px !important;
    }

    .mg-sm-y-5 {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .mg-sm-y-5-f {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }

    .mg-sm-x-5 {
        margin-left: 5px;
        margin-right: 5px;
    }

    .mg-sm-x-5-f {
        margin-left: 5px !important;
        margin-right: 5px !important;
    }

    .mg-sm-t-5 {
        margin-top: 5px;
    }

    .mg-sm-r-5 {
        margin-right: 5px;
    }

    .mg-sm-b-5 {
        margin-bottom: 5px;
    }

    .mg-sm-l-5 {
        margin-left: 5px;
    }

    .mg-sm-t-5-f {
        margin-top: 5px !important;
    }

    .mg-sm-r-5-f {
        margin-right: 5px !important;
    }

    .mg-sm-b-5-f {
        margin-bottom: 5px !important;
    }

    .mg-sm-l-5-f {
        margin-left: 5px !important;
    }

    .mg-sm-6 {
        margin: 6px;
    }

    .mg-sm-6-f {
        margin: 6px !important;
    }

    .mg-sm-y-6 {
        margin-top: 6px;
        margin-bottom: 6px;
    }

    .mg-sm-y-6-f {
        margin-top: 6px !important;
        margin-bottom: 6px !important;
    }

    .mg-sm-x-6 {
        margin-left: 6px;
        margin-right: 6px;
    }

    .mg-sm-x-6-f {
        margin-left: 6px !important;
        margin-right: 6px !important;
    }

    .mg-sm-t-6 {
        margin-top: 6px;
    }

    .mg-sm-r-6 {
        margin-right: 6px;
    }

    .mg-sm-b-6 {
        margin-bottom: 6px;
    }

    .mg-sm-l-6 {
        margin-left: 6px;
    }

    .mg-sm-t-6-f {
        margin-top: 6px !important;
    }

    .mg-sm-r-6-f {
        margin-right: 6px !important;
    }

    .mg-sm-b-6-f {
        margin-bottom: 6px !important;
    }

    .mg-sm-l-6-f {
        margin-left: 6px !important;
    }

    .mg-sm-7 {
        margin: 7px;
    }

    .mg-sm-7-f {
        margin: 7px !important;
    }

    .mg-sm-y-7 {
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .mg-sm-y-7-f {
        margin-top: 7px !important;
        margin-bottom: 7px !important;
    }

    .mg-sm-x-7 {
        margin-left: 7px;
        margin-right: 7px;
    }

    .mg-sm-x-7-f {
        margin-left: 7px !important;
        margin-right: 7px !important;
    }

    .mg-sm-t-7 {
        margin-top: 7px;
    }

    .mg-sm-r-7 {
        margin-right: 7px;
    }

    .mg-sm-b-7 {
        margin-bottom: 7px;
    }

    .mg-sm-l-7 {
        margin-left: 7px;
    }

    .mg-sm-t-7-f {
        margin-top: 7px !important;
    }

    .mg-sm-r-7-f {
        margin-right: 7px !important;
    }

    .mg-sm-b-7-f {
        margin-bottom: 7px !important;
    }

    .mg-sm-l-7-f {
        margin-left: 7px !important;
    }

    .mg-sm-8 {
        margin: 8px;
    }

    .mg-sm-8-f {
        margin: 8px !important;
    }

    .mg-sm-y-8 {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .mg-sm-y-8-f {
        margin-top: 8px !important;
        margin-bottom: 8px !important;
    }

    .mg-sm-x-8 {
        margin-left: 8px;
        margin-right: 8px;
    }

    .mg-sm-x-8-f {
        margin-left: 8px !important;
        margin-right: 8px !important;
    }

    .mg-sm-t-8 {
        margin-top: 8px;
    }

    .mg-sm-r-8 {
        margin-right: 8px;
    }

    .mg-sm-b-8 {
        margin-bottom: 8px;
    }

    .mg-sm-l-8 {
        margin-left: 8px;
    }

    .mg-sm-t-8-f {
        margin-top: 8px !important;
    }

    .mg-sm-r-8-f {
        margin-right: 8px !important;
    }

    .mg-sm-b-8-f {
        margin-bottom: 8px !important;
    }

    .mg-sm-l-8-f {
        margin-left: 8px !important;
    }

    .mg-sm-9 {
        margin: 9px;
    }

    .mg-sm-9-f {
        margin: 9px !important;
    }

    .mg-sm-y-9 {
        margin-top: 9px;
        margin-bottom: 9px;
    }

    .mg-sm-y-9-f {
        margin-top: 9px !important;
        margin-bottom: 9px !important;
    }

    .mg-sm-x-9 {
        margin-left: 9px;
        margin-right: 9px;
    }

    .mg-sm-x-9-f {
        margin-left: 9px !important;
        margin-right: 9px !important;
    }

    .mg-sm-t-9 {
        margin-top: 9px;
    }

    .mg-sm-r-9 {
        margin-right: 9px;
    }

    .mg-sm-b-9 {
        margin-bottom: 9px;
    }

    .mg-sm-l-9 {
        margin-left: 9px;
    }

    .mg-sm-t-9-f {
        margin-top: 9px !important;
    }

    .mg-sm-r-9-f {
        margin-right: 9px !important;
    }

    .mg-sm-b-9-f {
        margin-bottom: 9px !important;
    }

    .mg-sm-l-9-f {
        margin-left: 9px !important;
    }

    .mg-sm-10 {
        margin: 10px;
    }

    .mg-sm-10-f {
        margin: 10px !important;
    }

    .mg-sm-y-10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .mg-sm-y-10-f {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    .mg-sm-x-10 {
        margin-left: 10px;
        margin-right: 10px;
    }

    .mg-sm-x-10-f {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }

    .mg-sm-t-10 {
        margin-top: 10px;
    }

    .mg-sm-r-10 {
        margin-right: 10px;
    }

    .mg-sm-b-10 {
        margin-bottom: 10px;
    }

    .mg-sm-l-10 {
        margin-left: 10px;
    }

    .mg-sm-t-10-f {
        margin-top: 10px !important;
    }

    .mg-sm-r-10-f {
        margin-right: 10px !important;
    }

    .mg-sm-b-10-f {
        margin-bottom: 10px !important;
    }

    .mg-sm-l-10-f {
        margin-left: 10px !important;
    }

    .mg-sm-15 {
        margin: 15px;
    }

    .mg-sm-15-f {
        margin: 15px !important;
    }

    .mg-sm-y-15 {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .mg-sm-y-15-f {
        margin-top: 15px !important;
        margin-bottom: 15px !important;
    }

    .mg-sm-x-15 {
        margin-left: 15px;
        margin-right: 15px;
    }

    .mg-sm-x-15-f {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }

    .mg-sm-t-15 {
        margin-top: 15px;
    }

    .mg-sm-r-15 {
        margin-right: 15px;
    }

    .mg-sm-b-15 {
        margin-bottom: 15px;
    }

    .mg-sm-l-15 {
        margin-left: 15px;
    }

    .mg-sm-t-15-f {
        margin-top: 15px !important;
    }

    .mg-sm-r-15-f {
        margin-right: 15px !important;
    }

    .mg-sm-b-15-f {
        margin-bottom: 15px !important;
    }

    .mg-sm-l-15-f {
        margin-left: 15px !important;
    }

    .mg-sm-20 {
        margin: 20px;
    }

    .mg-sm-20-f {
        margin: 20px !important;
    }

    .mg-sm-y-20 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .mg-sm-y-20-f {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .mg-sm-x-20 {
        margin-left: 20px;
        margin-right: 20px;
    }

    .mg-sm-x-20-f {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }

    .mg-sm-t-20 {
        margin-top: 20px;
    }

    .mg-sm-r-20 {
        margin-right: 20px;
    }

    .mg-sm-b-20 {
        margin-bottom: 20px;
    }

    .mg-sm-l-20 {
        margin-left: 20px;
    }

    .mg-sm-t-20-f {
        margin-top: 20px !important;
    }

    .mg-sm-r-20-f {
        margin-right: 20px !important;
    }

    .mg-sm-b-20-f {
        margin-bottom: 20px !important;
    }

    .mg-sm-l-20-f {
        margin-left: 20px !important;
    }

    .mg-sm-25 {
        margin: 25px;
    }

    .mg-sm-25-f {
        margin: 25px !important;
    }

    .mg-sm-y-25 {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .mg-sm-y-25-f {
        margin-top: 25px !important;
        margin-bottom: 25px !important;
    }

    .mg-sm-x-25 {
        margin-left: 25px;
        margin-right: 25px;
    }

    .mg-sm-x-25-f {
        margin-left: 25px !important;
        margin-right: 25px !important;
    }

    .mg-sm-t-25 {
        margin-top: 25px;
    }

    .mg-sm-r-25 {
        margin-right: 25px;
    }

    .mg-sm-b-25 {
        margin-bottom: 25px;
    }

    .mg-sm-l-25 {
        margin-left: 25px;
    }

    .mg-sm-t-25-f {
        margin-top: 25px !important;
    }

    .mg-sm-r-25-f {
        margin-right: 25px !important;
    }

    .mg-sm-b-25-f {
        margin-bottom: 25px !important;
    }

    .mg-sm-l-25-f {
        margin-left: 25px !important;
    }

    .mg-sm-30 {
        margin: 30px;
    }

    .mg-sm-30-f {
        margin: 30px !important;
    }

    .mg-sm-y-30 {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .mg-sm-y-30-f {
        margin-top: 30px !important;
        margin-bottom: 30px !important;
    }

    .mg-sm-x-30 {
        margin-left: 30px;
        margin-right: 30px;
    }

    .mg-sm-x-30-f {
        margin-left: 30px !important;
        margin-right: 30px !important;
    }

    .mg-sm-t-30 {
        margin-top: 30px;
    }

    .mg-sm-r-30 {
        margin-right: 30px;
    }

    .mg-sm-b-30 {
        margin-bottom: 30px;
    }

    .mg-sm-l-30 {
        margin-left: 30px;
    }

    .mg-sm-t-30-f {
        margin-top: 30px !important;
    }

    .mg-sm-r-30-f {
        margin-right: 30px !important;
    }

    .mg-sm-b-30-f {
        margin-bottom: 30px !important;
    }

    .mg-sm-l-30-f {
        margin-left: 30px !important;
    }

    .mg-sm-35 {
        margin: 35px;
    }

    .mg-sm-35-f {
        margin: 35px !important;
    }

    .mg-sm-y-35 {
        margin-top: 35px;
        margin-bottom: 35px;
    }

    .mg-sm-y-35-f {
        margin-top: 35px !important;
        margin-bottom: 35px !important;
    }

    .mg-sm-x-35 {
        margin-left: 35px;
        margin-right: 35px;
    }

    .mg-sm-x-35-f {
        margin-left: 35px !important;
        margin-right: 35px !important;
    }

    .mg-sm-t-35 {
        margin-top: 35px;
    }

    .mg-sm-r-35 {
        margin-right: 35px;
    }

    .mg-sm-b-35 {
        margin-bottom: 35px;
    }

    .mg-sm-l-35 {
        margin-left: 35px;
    }

    .mg-sm-t-35-f {
        margin-top: 35px !important;
    }

    .mg-sm-r-35-f {
        margin-right: 35px !important;
    }

    .mg-sm-b-35-f {
        margin-bottom: 35px !important;
    }

    .mg-sm-l-35-f {
        margin-left: 35px !important;
    }

    .mg-sm-40 {
        margin: 40px;
    }

    .mg-sm-40-f {
        margin: 40px !important;
    }

    .mg-sm-y-40 {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .mg-sm-y-40-f {
        margin-top: 40px !important;
        margin-bottom: 40px !important;
    }

    .mg-sm-x-40 {
        margin-left: 40px;
        margin-right: 40px;
    }

    .mg-sm-x-40-f {
        margin-left: 40px !important;
        margin-right: 40px !important;
    }

    .mg-sm-t-40 {
        margin-top: 40px;
    }

    .mg-sm-r-40 {
        margin-right: 40px;
    }

    .mg-sm-b-40 {
        margin-bottom: 40px;
    }

    .mg-sm-l-40 {
        margin-left: 40px;
    }

    .mg-sm-t-40-f {
        margin-top: 40px !important;
    }

    .mg-sm-r-40-f {
        margin-right: 40px !important;
    }

    .mg-sm-b-40-f {
        margin-bottom: 40px !important;
    }

    .mg-sm-l-40-f {
        margin-left: 40px !important;
    }

    .mg-sm-45 {
        margin: 45px;
    }

    .mg-sm-45-f {
        margin: 45px !important;
    }

    .mg-sm-y-45 {
        margin-top: 45px;
        margin-bottom: 45px;
    }

    .mg-sm-y-45-f {
        margin-top: 45px !important;
        margin-bottom: 45px !important;
    }

    .mg-sm-x-45 {
        margin-left: 45px;
        margin-right: 45px;
    }

    .mg-sm-x-45-f {
        margin-left: 45px !important;
        margin-right: 45px !important;
    }

    .mg-sm-t-45 {
        margin-top: 45px;
    }

    .mg-sm-r-45 {
        margin-right: 45px;
    }

    .mg-sm-b-45 {
        margin-bottom: 45px;
    }

    .mg-sm-l-45 {
        margin-left: 45px;
    }

    .mg-sm-t-45-f {
        margin-top: 45px !important;
    }

    .mg-sm-r-45-f {
        margin-right: 45px !important;
    }

    .mg-sm-b-45-f {
        margin-bottom: 45px !important;
    }

    .mg-sm-l-45-f {
        margin-left: 45px !important;
    }

    .mg-sm-50 {
        margin: 50px;
    }

    .mg-sm-50-f {
        margin: 50px !important;
    }

    .mg-sm-y-50 {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .mg-sm-y-50-f {
        margin-top: 50px !important;
        margin-bottom: 50px !important;
    }

    .mg-sm-x-50 {
        margin-left: 50px;
        margin-right: 50px;
    }

    .mg-sm-x-50-f {
        margin-left: 50px !important;
        margin-right: 50px !important;
    }

    .mg-sm-t-50 {
        margin-top: 50px;
    }

    .mg-sm-r-50 {
        margin-right: 50px;
    }

    .mg-sm-b-50 {
        margin-bottom: 50px;
    }

    .mg-sm-l-50 {
        margin-left: 50px;
    }

    .mg-sm-t-50-f {
        margin-top: 50px !important;
    }

    .mg-sm-r-50-f {
        margin-right: 50px !important;
    }

    .mg-sm-b-50-f {
        margin-bottom: 50px !important;
    }

    .mg-sm-l-50-f {
        margin-left: 50px !important;
    }

    .mg-sm-55 {
        margin: 55px;
    }

    .mg-sm-55-f {
        margin: 55px !important;
    }

    .mg-sm-y-55 {
        margin-top: 55px;
        margin-bottom: 55px;
    }

    .mg-sm-y-55-f {
        margin-top: 55px !important;
        margin-bottom: 55px !important;
    }

    .mg-sm-x-55 {
        margin-left: 55px;
        margin-right: 55px;
    }

    .mg-sm-x-55-f {
        margin-left: 55px !important;
        margin-right: 55px !important;
    }

    .mg-sm-t-55 {
        margin-top: 55px;
    }

    .mg-sm-r-55 {
        margin-right: 55px;
    }

    .mg-sm-b-55 {
        margin-bottom: 55px;
    }

    .mg-sm-l-55 {
        margin-left: 55px;
    }

    .mg-sm-t-55-f {
        margin-top: 55px !important;
    }

    .mg-sm-r-55-f {
        margin-right: 55px !important;
    }

    .mg-sm-b-55-f {
        margin-bottom: 55px !important;
    }

    .mg-sm-l-55-f {
        margin-left: 55px !important;
    }

    .mg-sm-60 {
        margin: 60px;
    }

    .mg-sm-60-f {
        margin: 60px !important;
    }

    .mg-sm-y-60 {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .mg-sm-y-60-f {
        margin-top: 60px !important;
        margin-bottom: 60px !important;
    }

    .mg-sm-x-60 {
        margin-left: 60px;
        margin-right: 60px;
    }

    .mg-sm-x-60-f {
        margin-left: 60px !important;
        margin-right: 60px !important;
    }

    .mg-sm-t-60 {
        margin-top: 60px;
    }

    .mg-sm-r-60 {
        margin-right: 60px;
    }

    .mg-sm-b-60 {
        margin-bottom: 60px;
    }

    .mg-sm-l-60 {
        margin-left: 60px;
    }

    .mg-sm-t-60-f {
        margin-top: 60px !important;
    }

    .mg-sm-r-60-f {
        margin-right: 60px !important;
    }

    .mg-sm-b-60-f {
        margin-bottom: 60px !important;
    }

    .mg-sm-l-60-f {
        margin-left: 60px !important;
    }

    .mg-sm-65 {
        margin: 65px;
    }

    .mg-sm-65-f {
        margin: 65px !important;
    }

    .mg-sm-y-65 {
        margin-top: 65px;
        margin-bottom: 65px;
    }

    .mg-sm-y-65-f {
        margin-top: 65px !important;
        margin-bottom: 65px !important;
    }

    .mg-sm-x-65 {
        margin-left: 65px;
        margin-right: 65px;
    }

    .mg-sm-x-65-f {
        margin-left: 65px !important;
        margin-right: 65px !important;
    }

    .mg-sm-t-65 {
        margin-top: 65px;
    }

    .mg-sm-r-65 {
        margin-right: 65px;
    }

    .mg-sm-b-65 {
        margin-bottom: 65px;
    }

    .mg-sm-l-65 {
        margin-left: 65px;
    }

    .mg-sm-t-65-f {
        margin-top: 65px !important;
    }

    .mg-sm-r-65-f {
        margin-right: 65px !important;
    }

    .mg-sm-b-65-f {
        margin-bottom: 65px !important;
    }

    .mg-sm-l-65-f {
        margin-left: 65px !important;
    }

    .mg-sm-70 {
        margin: 70px;
    }

    .mg-sm-70-f {
        margin: 70px !important;
    }

    .mg-sm-y-70 {
        margin-top: 70px;
        margin-bottom: 70px;
    }

    .mg-sm-y-70-f {
        margin-top: 70px !important;
        margin-bottom: 70px !important;
    }

    .mg-sm-x-70 {
        margin-left: 70px;
        margin-right: 70px;
    }

    .mg-sm-x-70-f {
        margin-left: 70px !important;
        margin-right: 70px !important;
    }

    .mg-sm-t-70 {
        margin-top: 70px;
    }

    .mg-sm-r-70 {
        margin-right: 70px;
    }

    .mg-sm-b-70 {
        margin-bottom: 70px;
    }

    .mg-sm-l-70 {
        margin-left: 70px;
    }

    .mg-sm-t-70-f {
        margin-top: 70px !important;
    }

    .mg-sm-r-70-f {
        margin-right: 70px !important;
    }

    .mg-sm-b-70-f {
        margin-bottom: 70px !important;
    }

    .mg-sm-l-70-f {
        margin-left: 70px !important;
    }

    .mg-sm-75 {
        margin: 75px;
    }

    .mg-sm-75-f {
        margin: 75px !important;
    }

    .mg-sm-y-75 {
        margin-top: 75px;
        margin-bottom: 75px;
    }

    .mg-sm-y-75-f {
        margin-top: 75px !important;
        margin-bottom: 75px !important;
    }

    .mg-sm-x-75 {
        margin-left: 75px;
        margin-right: 75px;
    }

    .mg-sm-x-75-f {
        margin-left: 75px !important;
        margin-right: 75px !important;
    }

    .mg-sm-t-75 {
        margin-top: 75px;
    }

    .mg-sm-r-75 {
        margin-right: 75px;
    }

    .mg-sm-b-75 {
        margin-bottom: 75px;
    }

    .mg-sm-l-75 {
        margin-left: 75px;
    }

    .mg-sm-t-75-f {
        margin-top: 75px !important;
    }

    .mg-sm-r-75-f {
        margin-right: 75px !important;
    }

    .mg-sm-b-75-f {
        margin-bottom: 75px !important;
    }

    .mg-sm-l-75-f {
        margin-left: 75px !important;
    }

    .mg-sm-80 {
        margin: 80px;
    }

    .mg-sm-80-f {
        margin: 80px !important;
    }

    .mg-sm-y-80 {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .mg-sm-y-80-f {
        margin-top: 80px !important;
        margin-bottom: 80px !important;
    }

    .mg-sm-x-80 {
        margin-left: 80px;
        margin-right: 80px;
    }

    .mg-sm-x-80-f {
        margin-left: 80px !important;
        margin-right: 80px !important;
    }

    .mg-sm-t-80 {
        margin-top: 80px;
    }

    .mg-sm-r-80 {
        margin-right: 80px;
    }

    .mg-sm-b-80 {
        margin-bottom: 80px;
    }

    .mg-sm-l-80 {
        margin-left: 80px;
    }

    .mg-sm-t-80-f {
        margin-top: 80px !important;
    }

    .mg-sm-r-80-f {
        margin-right: 80px !important;
    }

    .mg-sm-b-80-f {
        margin-bottom: 80px !important;
    }

    .mg-sm-l-80-f {
        margin-left: 80px !important;
    }

    .mg-sm-85 {
        margin: 85px;
    }

    .mg-sm-85-f {
        margin: 85px !important;
    }

    .mg-sm-y-85 {
        margin-top: 85px;
        margin-bottom: 85px;
    }

    .mg-sm-y-85-f {
        margin-top: 85px !important;
        margin-bottom: 85px !important;
    }

    .mg-sm-x-85 {
        margin-left: 85px;
        margin-right: 85px;
    }

    .mg-sm-x-85-f {
        margin-left: 85px !important;
        margin-right: 85px !important;
    }

    .mg-sm-t-85 {
        margin-top: 85px;
    }

    .mg-sm-r-85 {
        margin-right: 85px;
    }

    .mg-sm-b-85 {
        margin-bottom: 85px;
    }

    .mg-sm-l-85 {
        margin-left: 85px;
    }

    .mg-sm-t-85-f {
        margin-top: 85px !important;
    }

    .mg-sm-r-85-f {
        margin-right: 85px !important;
    }

    .mg-sm-b-85-f {
        margin-bottom: 85px !important;
    }

    .mg-sm-l-85-f {
        margin-left: 85px !important;
    }

    .mg-sm-90 {
        margin: 90px;
    }

    .mg-sm-90-f {
        margin: 90px !important;
    }

    .mg-sm-y-90 {
        margin-top: 90px;
        margin-bottom: 90px;
    }

    .mg-sm-y-90-f {
        margin-top: 90px !important;
        margin-bottom: 90px !important;
    }

    .mg-sm-x-90 {
        margin-left: 90px;
        margin-right: 90px;
    }

    .mg-sm-x-90-f {
        margin-left: 90px !important;
        margin-right: 90px !important;
    }

    .mg-sm-t-90 {
        margin-top: 90px;
    }

    .mg-sm-r-90 {
        margin-right: 90px;
    }

    .mg-sm-b-90 {
        margin-bottom: 90px;
    }

    .mg-sm-l-90 {
        margin-left: 90px;
    }

    .mg-sm-t-90-f {
        margin-top: 90px !important;
    }

    .mg-sm-r-90-f {
        margin-right: 90px !important;
    }

    .mg-sm-b-90-f {
        margin-bottom: 90px !important;
    }

    .mg-sm-l-90-f {
        margin-left: 90px !important;
    }

    .mg-sm-95 {
        margin: 95px;
    }

    .mg-sm-95-f {
        margin: 95px !important;
    }

    .mg-sm-y-95 {
        margin-top: 95px;
        margin-bottom: 95px;
    }

    .mg-sm-y-95-f {
        margin-top: 95px !important;
        margin-bottom: 95px !important;
    }

    .mg-sm-x-95 {
        margin-left: 95px;
        margin-right: 95px;
    }

    .mg-sm-x-95-f {
        margin-left: 95px !important;
        margin-right: 95px !important;
    }

    .mg-sm-t-95 {
        margin-top: 95px;
    }

    .mg-sm-r-95 {
        margin-right: 95px;
    }

    .mg-sm-b-95 {
        margin-bottom: 95px;
    }

    .mg-sm-l-95 {
        margin-left: 95px;
    }

    .mg-sm-t-95-f {
        margin-top: 95px !important;
    }

    .mg-sm-r-95-f {
        margin-right: 95px !important;
    }

    .mg-sm-b-95-f {
        margin-bottom: 95px !important;
    }

    .mg-sm-l-95-f {
        margin-left: 95px !important;
    }

    .mg-sm-100 {
        margin: 100px;
    }

    .mg-sm-100-f {
        margin: 100px !important;
    }

    .mg-sm-y-100 {
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .mg-sm-y-100-f {
        margin-top: 100px !important;
        margin-bottom: 100px !important;
    }

    .mg-sm-x-100 {
        margin-left: 100px;
        margin-right: 100px;
    }

    .mg-sm-x-100-f {
        margin-left: 100px !important;
        margin-right: 100px !important;
    }

    .mg-sm-t-100 {
        margin-top: 100px;
    }

    .mg-sm-r-100 {
        margin-right: 100px;
    }

    .mg-sm-b-100 {
        margin-bottom: 100px;
    }

    .mg-sm-l-100 {
        margin-left: 100px;
    }

    .mg-sm-t-100-f {
        margin-top: 100px !important;
    }

    .mg-sm-r-100-f {
        margin-right: 100px !important;
    }

    .mg-sm-b-100-f {
        margin-bottom: 100px !important;
    }

    .mg-sm-l-100-f {
        margin-left: 100px !important;
    }

    .mg-sm-t-auto {
        margin-top: auto;
    }

    .mg-sm-r-auto {
        margin-right: auto;
    }

    .mg-sm-b-auto {
        margin-bottom: auto;
    }

    .mg-sm-l-auto {
        margin-left: auto;
    }

    .mg-sm-auto {
        margin: auto;
    }

    .mg-sm-x-auto {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 768px) {
    .mg-md-0 {
        margin: 0px;
    }

    .mg-md-0-f {
        margin: 0px !important;
    }

    .mg-md-y-0 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .mg-md-y-0-f {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .mg-md-x-0 {
        margin-left: 0px;
        margin-right: 0px;
    }

    .mg-md-x-0-f {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .mg-md-t-0 {
        margin-top: 0px;
    }

    .mg-md-r-0 {
        margin-right: 0px;
    }

    .mg-md-b-0 {
        margin-bottom: 0px;
    }

    .mg-md-l-0 {
        margin-left: 0px;
    }

    .mg-md-t-0-f {
        margin-top: 0px !important;
    }

    .mg-md-r-0-f {
        margin-right: 0px !important;
    }

    .mg-md-b-0-f {
        margin-bottom: 0px !important;
    }

    .mg-md-l-0-f {
        margin-left: 0px !important;
    }

    .mg-md-1 {
        margin: 1px;
    }

    .mg-md-1-f {
        margin: 1px !important;
    }

    .mg-md-y-1 {
        margin-top: 1px;
        margin-bottom: 1px;
    }

    .mg-md-y-1-f {
        margin-top: 1px !important;
        margin-bottom: 1px !important;
    }

    .mg-md-x-1 {
        margin-left: 1px;
        margin-right: 1px;
    }

    .mg-md-x-1-f {
        margin-left: 1px !important;
        margin-right: 1px !important;
    }

    .mg-md-t-1 {
        margin-top: 1px;
    }

    .mg-md-r-1 {
        margin-right: 1px;
    }

    .mg-md-b-1 {
        margin-bottom: 1px;
    }

    .mg-md-l-1 {
        margin-left: 1px;
    }

    .mg-md-t-1-f {
        margin-top: 1px !important;
    }

    .mg-md-r-1-f {
        margin-right: 1px !important;
    }

    .mg-md-b-1-f {
        margin-bottom: 1px !important;
    }

    .mg-md-l-1-f {
        margin-left: 1px !important;
    }

    .mg-md-2 {
        margin: 2px;
    }

    .mg-md-2-f {
        margin: 2px !important;
    }

    .mg-md-y-2 {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .mg-md-y-2-f {
        margin-top: 2px !important;
        margin-bottom: 2px !important;
    }

    .mg-md-x-2 {
        margin-left: 2px;
        margin-right: 2px;
    }

    .mg-md-x-2-f {
        margin-left: 2px !important;
        margin-right: 2px !important;
    }

    .mg-md-t-2 {
        margin-top: 2px;
    }

    .mg-md-r-2 {
        margin-right: 2px;
    }

    .mg-md-b-2 {
        margin-bottom: 2px;
    }

    .mg-md-l-2 {
        margin-left: 2px;
    }

    .mg-md-t-2-f {
        margin-top: 2px !important;
    }

    .mg-md-r-2-f {
        margin-right: 2px !important;
    }

    .mg-md-b-2-f {
        margin-bottom: 2px !important;
    }

    .mg-md-l-2-f {
        margin-left: 2px !important;
    }

    .mg-md-3 {
        margin: 3px;
    }

    .mg-md-3-f {
        margin: 3px !important;
    }

    .mg-md-y-3 {
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .mg-md-y-3-f {
        margin-top: 3px !important;
        margin-bottom: 3px !important;
    }

    .mg-md-x-3 {
        margin-left: 3px;
        margin-right: 3px;
    }

    .mg-md-x-3-f {
        margin-left: 3px !important;
        margin-right: 3px !important;
    }

    .mg-md-t-3 {
        margin-top: 3px;
    }

    .mg-md-r-3 {
        margin-right: 3px;
    }

    .mg-md-b-3 {
        margin-bottom: 3px;
    }

    .mg-md-l-3 {
        margin-left: 3px;
    }

    .mg-md-t-3-f {
        margin-top: 3px !important;
    }

    .mg-md-r-3-f {
        margin-right: 3px !important;
    }

    .mg-md-b-3-f {
        margin-bottom: 3px !important;
    }

    .mg-md-l-3-f {
        margin-left: 3px !important;
    }

    .mg-md-4 {
        margin: 4px;
    }

    .mg-md-4-f {
        margin: 4px !important;
    }

    .mg-md-y-4 {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .mg-md-y-4-f {
        margin-top: 4px !important;
        margin-bottom: 4px !important;
    }

    .mg-md-x-4 {
        margin-left: 4px;
        margin-right: 4px;
    }

    .mg-md-x-4-f {
        margin-left: 4px !important;
        margin-right: 4px !important;
    }

    .mg-md-t-4 {
        margin-top: 4px;
    }

    .mg-md-r-4 {
        margin-right: 4px;
    }

    .mg-md-b-4 {
        margin-bottom: 4px;
    }

    .mg-md-l-4 {
        margin-left: 4px;
    }

    .mg-md-t-4-f {
        margin-top: 4px !important;
    }

    .mg-md-r-4-f {
        margin-right: 4px !important;
    }

    .mg-md-b-4-f {
        margin-bottom: 4px !important;
    }

    .mg-md-l-4-f {
        margin-left: 4px !important;
    }

    .mg-md-5 {
        margin: 5px;
    }

    .mg-md-5-f {
        margin: 5px !important;
    }

    .mg-md-y-5 {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .mg-md-y-5-f {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }

    .mg-md-x-5 {
        margin-left: 5px;
        margin-right: 5px;
    }

    .mg-md-x-5-f {
        margin-left: 5px !important;
        margin-right: 5px !important;
    }

    .mg-md-t-5 {
        margin-top: 5px;
    }

    .mg-md-r-5 {
        margin-right: 5px;
    }

    .mg-md-b-5 {
        margin-bottom: 5px;
    }

    .mg-md-l-5 {
        margin-left: 5px;
    }

    .mg-md-t-5-f {
        margin-top: 5px !important;
    }

    .mg-md-r-5-f {
        margin-right: 5px !important;
    }

    .mg-md-b-5-f {
        margin-bottom: 5px !important;
    }

    .mg-md-l-5-f {
        margin-left: 5px !important;
    }

    .mg-md-6 {
        margin: 6px;
    }

    .mg-md-6-f {
        margin: 6px !important;
    }

    .mg-md-y-6 {
        margin-top: 6px;
        margin-bottom: 6px;
    }

    .mg-md-y-6-f {
        margin-top: 6px !important;
        margin-bottom: 6px !important;
    }

    .mg-md-x-6 {
        margin-left: 6px;
        margin-right: 6px;
    }

    .mg-md-x-6-f {
        margin-left: 6px !important;
        margin-right: 6px !important;
    }

    .mg-md-t-6 {
        margin-top: 6px;
    }

    .mg-md-r-6 {
        margin-right: 6px;
    }

    .mg-md-b-6 {
        margin-bottom: 6px;
    }

    .mg-md-l-6 {
        margin-left: 6px;
    }

    .mg-md-t-6-f {
        margin-top: 6px !important;
    }

    .mg-md-r-6-f {
        margin-right: 6px !important;
    }

    .mg-md-b-6-f {
        margin-bottom: 6px !important;
    }

    .mg-md-l-6-f {
        margin-left: 6px !important;
    }

    .mg-md-7 {
        margin: 7px;
    }

    .mg-md-7-f {
        margin: 7px !important;
    }

    .mg-md-y-7 {
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .mg-md-y-7-f {
        margin-top: 7px !important;
        margin-bottom: 7px !important;
    }

    .mg-md-x-7 {
        margin-left: 7px;
        margin-right: 7px;
    }

    .mg-md-x-7-f {
        margin-left: 7px !important;
        margin-right: 7px !important;
    }

    .mg-md-t-7 {
        margin-top: 7px;
    }

    .mg-md-r-7 {
        margin-right: 7px;
    }

    .mg-md-b-7 {
        margin-bottom: 7px;
    }

    .mg-md-l-7 {
        margin-left: 7px;
    }

    .mg-md-t-7-f {
        margin-top: 7px !important;
    }

    .mg-md-r-7-f {
        margin-right: 7px !important;
    }

    .mg-md-b-7-f {
        margin-bottom: 7px !important;
    }

    .mg-md-l-7-f {
        margin-left: 7px !important;
    }

    .mg-md-8 {
        margin: 8px;
    }

    .mg-md-8-f {
        margin: 8px !important;
    }

    .mg-md-y-8 {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .mg-md-y-8-f {
        margin-top: 8px !important;
        margin-bottom: 8px !important;
    }

    .mg-md-x-8 {
        margin-left: 8px;
        margin-right: 8px;
    }

    .mg-md-x-8-f {
        margin-left: 8px !important;
        margin-right: 8px !important;
    }

    .mg-md-t-8 {
        margin-top: 8px;
    }

    .mg-md-r-8 {
        margin-right: 8px;
    }

    .mg-md-b-8 {
        margin-bottom: 8px;
    }

    .mg-md-l-8 {
        margin-left: 8px;
    }

    .mg-md-t-8-f {
        margin-top: 8px !important;
    }

    .mg-md-r-8-f {
        margin-right: 8px !important;
    }

    .mg-md-b-8-f {
        margin-bottom: 8px !important;
    }

    .mg-md-l-8-f {
        margin-left: 8px !important;
    }

    .mg-md-9 {
        margin: 9px;
    }

    .mg-md-9-f {
        margin: 9px !important;
    }

    .mg-md-y-9 {
        margin-top: 9px;
        margin-bottom: 9px;
    }

    .mg-md-y-9-f {
        margin-top: 9px !important;
        margin-bottom: 9px !important;
    }

    .mg-md-x-9 {
        margin-left: 9px;
        margin-right: 9px;
    }

    .mg-md-x-9-f {
        margin-left: 9px !important;
        margin-right: 9px !important;
    }

    .mg-md-t-9 {
        margin-top: 9px;
    }

    .mg-md-r-9 {
        margin-right: 9px;
    }

    .mg-md-b-9 {
        margin-bottom: 9px;
    }

    .mg-md-l-9 {
        margin-left: 9px;
    }

    .mg-md-t-9-f {
        margin-top: 9px !important;
    }

    .mg-md-r-9-f {
        margin-right: 9px !important;
    }

    .mg-md-b-9-f {
        margin-bottom: 9px !important;
    }

    .mg-md-l-9-f {
        margin-left: 9px !important;
    }

    .mg-md-10 {
        margin: 10px;
    }

    .mg-md-10-f {
        margin: 10px !important;
    }

    .mg-md-y-10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .mg-md-y-10-f {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    .mg-md-x-10 {
        margin-left: 10px;
        margin-right: 10px;
    }

    .mg-md-x-10-f {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }

    .mg-md-t-10 {
        margin-top: 10px;
    }

    .mg-md-r-10 {
        margin-right: 10px;
    }

    .mg-md-b-10 {
        margin-bottom: 10px;
    }

    .mg-md-l-10 {
        margin-left: 10px;
    }

    .mg-md-t-10-f {
        margin-top: 10px !important;
    }

    .mg-md-r-10-f {
        margin-right: 10px !important;
    }

    .mg-md-b-10-f {
        margin-bottom: 10px !important;
    }

    .mg-md-l-10-f {
        margin-left: 10px !important;
    }

    .mg-md-15 {
        margin: 15px;
    }

    .mg-md-15-f {
        margin: 15px !important;
    }

    .mg-md-y-15 {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .mg-md-y-15-f {
        margin-top: 15px !important;
        margin-bottom: 15px !important;
    }

    .mg-md-x-15 {
        margin-left: 15px;
        margin-right: 15px;
    }

    .mg-md-x-15-f {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }

    .mg-md-t-15 {
        margin-top: 15px;
    }

    .mg-md-r-15 {
        margin-right: 15px;
    }

    .mg-md-b-15 {
        margin-bottom: 15px;
    }

    .mg-md-l-15 {
        margin-left: 15px;
    }

    .mg-md-t-15-f {
        margin-top: 15px !important;
    }

    .mg-md-r-15-f {
        margin-right: 15px !important;
    }

    .mg-md-b-15-f {
        margin-bottom: 15px !important;
    }

    .mg-md-l-15-f {
        margin-left: 15px !important;
    }

    .mg-md-20 {
        margin: 20px;
    }

    .mg-md-20-f {
        margin: 20px !important;
    }

    .mg-md-y-20 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .mg-md-y-20-f {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .mg-md-x-20 {
        margin-left: 20px;
        margin-right: 20px;
    }

    .mg-md-x-20-f {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }

    .mg-md-t-20 {
        margin-top: 20px;
    }

    .mg-md-r-20 {
        margin-right: 20px;
    }

    .mg-md-b-20 {
        margin-bottom: 20px;
    }

    .mg-md-l-20 {
        margin-left: 20px;
    }

    .mg-md-t-20-f {
        margin-top: 20px !important;
    }

    .mg-md-r-20-f {
        margin-right: 20px !important;
    }

    .mg-md-b-20-f {
        margin-bottom: 20px !important;
    }

    .mg-md-l-20-f {
        margin-left: 20px !important;
    }

    .mg-md-25 {
        margin: 25px;
    }

    .mg-md-25-f {
        margin: 25px !important;
    }

    .mg-md-y-25 {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .mg-md-y-25-f {
        margin-top: 25px !important;
        margin-bottom: 25px !important;
    }

    .mg-md-x-25 {
        margin-left: 25px;
        margin-right: 25px;
    }

    .mg-md-x-25-f {
        margin-left: 25px !important;
        margin-right: 25px !important;
    }

    .mg-md-t-25 {
        margin-top: 25px;
    }

    .mg-md-r-25 {
        margin-right: 25px;
    }

    .mg-md-b-25 {
        margin-bottom: 25px;
    }

    .mg-md-l-25 {
        margin-left: 25px;
    }

    .mg-md-t-25-f {
        margin-top: 25px !important;
    }

    .mg-md-r-25-f {
        margin-right: 25px !important;
    }

    .mg-md-b-25-f {
        margin-bottom: 25px !important;
    }

    .mg-md-l-25-f {
        margin-left: 25px !important;
    }

    .mg-md-30 {
        margin: 30px;
    }

    .mg-md-30-f {
        margin: 30px !important;
    }

    .mg-md-y-30 {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .mg-md-y-30-f {
        margin-top: 30px !important;
        margin-bottom: 30px !important;
    }

    .mg-md-x-30 {
        margin-left: 30px;
        margin-right: 30px;
    }

    .mg-md-x-30-f {
        margin-left: 30px !important;
        margin-right: 30px !important;
    }

    .mg-md-t-30 {
        margin-top: 30px;
    }

    .mg-md-r-30 {
        margin-right: 30px;
    }

    .mg-md-b-30 {
        margin-bottom: 30px;
    }

    .mg-md-l-30 {
        margin-left: 30px;
    }

    .mg-md-t-30-f {
        margin-top: 30px !important;
    }

    .mg-md-r-30-f {
        margin-right: 30px !important;
    }

    .mg-md-b-30-f {
        margin-bottom: 30px !important;
    }

    .mg-md-l-30-f {
        margin-left: 30px !important;
    }

    .mg-md-35 {
        margin: 35px;
    }

    .mg-md-35-f {
        margin: 35px !important;
    }

    .mg-md-y-35 {
        margin-top: 35px;
        margin-bottom: 35px;
    }

    .mg-md-y-35-f {
        margin-top: 35px !important;
        margin-bottom: 35px !important;
    }

    .mg-md-x-35 {
        margin-left: 35px;
        margin-right: 35px;
    }

    .mg-md-x-35-f {
        margin-left: 35px !important;
        margin-right: 35px !important;
    }

    .mg-md-t-35 {
        margin-top: 35px;
    }

    .mg-md-r-35 {
        margin-right: 35px;
    }

    .mg-md-b-35 {
        margin-bottom: 35px;
    }

    .mg-md-l-35 {
        margin-left: 35px;
    }

    .mg-md-t-35-f {
        margin-top: 35px !important;
    }

    .mg-md-r-35-f {
        margin-right: 35px !important;
    }

    .mg-md-b-35-f {
        margin-bottom: 35px !important;
    }

    .mg-md-l-35-f {
        margin-left: 35px !important;
    }

    .mg-md-40 {
        margin: 40px;
    }

    .mg-md-40-f {
        margin: 40px !important;
    }

    .mg-md-y-40 {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .mg-md-y-40-f {
        margin-top: 40px !important;
        margin-bottom: 40px !important;
    }

    .mg-md-x-40 {
        margin-left: 40px;
        margin-right: 40px;
    }

    .mg-md-x-40-f {
        margin-left: 40px !important;
        margin-right: 40px !important;
    }

    .mg-md-t-40 {
        margin-top: 40px;
    }

    .mg-md-r-40 {
        margin-right: 40px;
    }

    .mg-md-b-40 {
        margin-bottom: 40px;
    }

    .mg-md-l-40 {
        margin-left: 40px;
    }

    .mg-md-t-40-f {
        margin-top: 40px !important;
    }

    .mg-md-r-40-f {
        margin-right: 40px !important;
    }

    .mg-md-b-40-f {
        margin-bottom: 40px !important;
    }

    .mg-md-l-40-f {
        margin-left: 40px !important;
    }

    .mg-md-45 {
        margin: 45px;
    }

    .mg-md-45-f {
        margin: 45px !important;
    }

    .mg-md-y-45 {
        margin-top: 45px;
        margin-bottom: 45px;
    }

    .mg-md-y-45-f {
        margin-top: 45px !important;
        margin-bottom: 45px !important;
    }

    .mg-md-x-45 {
        margin-left: 45px;
        margin-right: 45px;
    }

    .mg-md-x-45-f {
        margin-left: 45px !important;
        margin-right: 45px !important;
    }

    .mg-md-t-45 {
        margin-top: 45px;
    }

    .mg-md-r-45 {
        margin-right: 45px;
    }

    .mg-md-b-45 {
        margin-bottom: 45px;
    }

    .mg-md-l-45 {
        margin-left: 45px;
    }

    .mg-md-t-45-f {
        margin-top: 45px !important;
    }

    .mg-md-r-45-f {
        margin-right: 45px !important;
    }

    .mg-md-b-45-f {
        margin-bottom: 45px !important;
    }

    .mg-md-l-45-f {
        margin-left: 45px !important;
    }

    .mg-md-50 {
        margin: 50px;
    }

    .mg-md-50-f {
        margin: 50px !important;
    }

    .mg-md-y-50 {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .mg-md-y-50-f {
        margin-top: 50px !important;
        margin-bottom: 50px !important;
    }

    .mg-md-x-50 {
        margin-left: 50px;
        margin-right: 50px;
    }

    .mg-md-x-50-f {
        margin-left: 50px !important;
        margin-right: 50px !important;
    }

    .mg-md-t-50 {
        margin-top: 50px;
    }

    .mg-md-r-50 {
        margin-right: 50px;
    }

    .mg-md-b-50 {
        margin-bottom: 50px;
    }

    .mg-md-l-50 {
        margin-left: 50px;
    }

    .mg-md-t-50-f {
        margin-top: 50px !important;
    }

    .mg-md-r-50-f {
        margin-right: 50px !important;
    }

    .mg-md-b-50-f {
        margin-bottom: 50px !important;
    }

    .mg-md-l-50-f {
        margin-left: 50px !important;
    }

    .mg-md-55 {
        margin: 55px;
    }

    .mg-md-55-f {
        margin: 55px !important;
    }

    .mg-md-y-55 {
        margin-top: 55px;
        margin-bottom: 55px;
    }

    .mg-md-y-55-f {
        margin-top: 55px !important;
        margin-bottom: 55px !important;
    }

    .mg-md-x-55 {
        margin-left: 55px;
        margin-right: 55px;
    }

    .mg-md-x-55-f {
        margin-left: 55px !important;
        margin-right: 55px !important;
    }

    .mg-md-t-55 {
        margin-top: 55px;
    }

    .mg-md-r-55 {
        margin-right: 55px;
    }

    .mg-md-b-55 {
        margin-bottom: 55px;
    }

    .mg-md-l-55 {
        margin-left: 55px;
    }

    .mg-md-t-55-f {
        margin-top: 55px !important;
    }

    .mg-md-r-55-f {
        margin-right: 55px !important;
    }

    .mg-md-b-55-f {
        margin-bottom: 55px !important;
    }

    .mg-md-l-55-f {
        margin-left: 55px !important;
    }

    .mg-md-60 {
        margin: 60px;
    }

    .mg-md-60-f {
        margin: 60px !important;
    }

    .mg-md-y-60 {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .mg-md-y-60-f {
        margin-top: 60px !important;
        margin-bottom: 60px !important;
    }

    .mg-md-x-60 {
        margin-left: 60px;
        margin-right: 60px;
    }

    .mg-md-x-60-f {
        margin-left: 60px !important;
        margin-right: 60px !important;
    }

    .mg-md-t-60 {
        margin-top: 60px;
    }

    .mg-md-r-60 {
        margin-right: 60px;
    }

    .mg-md-b-60 {
        margin-bottom: 60px;
    }

    .mg-md-l-60 {
        margin-left: 60px;
    }

    .mg-md-t-60-f {
        margin-top: 60px !important;
    }

    .mg-md-r-60-f {
        margin-right: 60px !important;
    }

    .mg-md-b-60-f {
        margin-bottom: 60px !important;
    }

    .mg-md-l-60-f {
        margin-left: 60px !important;
    }

    .mg-md-65 {
        margin: 65px;
    }

    .mg-md-65-f {
        margin: 65px !important;
    }

    .mg-md-y-65 {
        margin-top: 65px;
        margin-bottom: 65px;
    }

    .mg-md-y-65-f {
        margin-top: 65px !important;
        margin-bottom: 65px !important;
    }

    .mg-md-x-65 {
        margin-left: 65px;
        margin-right: 65px;
    }

    .mg-md-x-65-f {
        margin-left: 65px !important;
        margin-right: 65px !important;
    }

    .mg-md-t-65 {
        margin-top: 65px;
    }

    .mg-md-r-65 {
        margin-right: 65px;
    }

    .mg-md-b-65 {
        margin-bottom: 65px;
    }

    .mg-md-l-65 {
        margin-left: 65px;
    }

    .mg-md-t-65-f {
        margin-top: 65px !important;
    }

    .mg-md-r-65-f {
        margin-right: 65px !important;
    }

    .mg-md-b-65-f {
        margin-bottom: 65px !important;
    }

    .mg-md-l-65-f {
        margin-left: 65px !important;
    }

    .mg-md-70 {
        margin: 70px;
    }

    .mg-md-70-f {
        margin: 70px !important;
    }

    .mg-md-y-70 {
        margin-top: 70px;
        margin-bottom: 70px;
    }

    .mg-md-y-70-f {
        margin-top: 70px !important;
        margin-bottom: 70px !important;
    }

    .mg-md-x-70 {
        margin-left: 70px;
        margin-right: 70px;
    }

    .mg-md-x-70-f {
        margin-left: 70px !important;
        margin-right: 70px !important;
    }

    .mg-md-t-70 {
        margin-top: 70px;
    }

    .mg-md-r-70 {
        margin-right: 70px;
    }

    .mg-md-b-70 {
        margin-bottom: 70px;
    }

    .mg-md-l-70 {
        margin-left: 70px;
    }

    .mg-md-t-70-f {
        margin-top: 70px !important;
    }

    .mg-md-r-70-f {
        margin-right: 70px !important;
    }

    .mg-md-b-70-f {
        margin-bottom: 70px !important;
    }

    .mg-md-l-70-f {
        margin-left: 70px !important;
    }

    .mg-md-75 {
        margin: 75px;
    }

    .mg-md-75-f {
        margin: 75px !important;
    }

    .mg-md-y-75 {
        margin-top: 75px;
        margin-bottom: 75px;
    }

    .mg-md-y-75-f {
        margin-top: 75px !important;
        margin-bottom: 75px !important;
    }

    .mg-md-x-75 {
        margin-left: 75px;
        margin-right: 75px;
    }

    .mg-md-x-75-f {
        margin-left: 75px !important;
        margin-right: 75px !important;
    }

    .mg-md-t-75 {
        margin-top: 75px;
    }

    .mg-md-r-75 {
        margin-right: 75px;
    }

    .mg-md-b-75 {
        margin-bottom: 75px;
    }

    .mg-md-l-75 {
        margin-left: 75px;
    }

    .mg-md-t-75-f {
        margin-top: 75px !important;
    }

    .mg-md-r-75-f {
        margin-right: 75px !important;
    }

    .mg-md-b-75-f {
        margin-bottom: 75px !important;
    }

    .mg-md-l-75-f {
        margin-left: 75px !important;
    }

    .mg-md-80 {
        margin: 80px;
    }

    .mg-md-80-f {
        margin: 80px !important;
    }

    .mg-md-y-80 {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .mg-md-y-80-f {
        margin-top: 80px !important;
        margin-bottom: 80px !important;
    }

    .mg-md-x-80 {
        margin-left: 80px;
        margin-right: 80px;
    }

    .mg-md-x-80-f {
        margin-left: 80px !important;
        margin-right: 80px !important;
    }

    .mg-md-t-80 {
        margin-top: 80px;
    }

    .mg-md-r-80 {
        margin-right: 80px;
    }

    .mg-md-b-80 {
        margin-bottom: 80px;
    }

    .mg-md-l-80 {
        margin-left: 80px;
    }

    .mg-md-t-80-f {
        margin-top: 80px !important;
    }

    .mg-md-r-80-f {
        margin-right: 80px !important;
    }

    .mg-md-b-80-f {
        margin-bottom: 80px !important;
    }

    .mg-md-l-80-f {
        margin-left: 80px !important;
    }

    .mg-md-85 {
        margin: 85px;
    }

    .mg-md-85-f {
        margin: 85px !important;
    }

    .mg-md-y-85 {
        margin-top: 85px;
        margin-bottom: 85px;
    }

    .mg-md-y-85-f {
        margin-top: 85px !important;
        margin-bottom: 85px !important;
    }

    .mg-md-x-85 {
        margin-left: 85px;
        margin-right: 85px;
    }

    .mg-md-x-85-f {
        margin-left: 85px !important;
        margin-right: 85px !important;
    }

    .mg-md-t-85 {
        margin-top: 85px;
    }

    .mg-md-r-85 {
        margin-right: 85px;
    }

    .mg-md-b-85 {
        margin-bottom: 85px;
    }

    .mg-md-l-85 {
        margin-left: 85px;
    }

    .mg-md-t-85-f {
        margin-top: 85px !important;
    }

    .mg-md-r-85-f {
        margin-right: 85px !important;
    }

    .mg-md-b-85-f {
        margin-bottom: 85px !important;
    }

    .mg-md-l-85-f {
        margin-left: 85px !important;
    }

    .mg-md-90 {
        margin: 90px;
    }

    .mg-md-90-f {
        margin: 90px !important;
    }

    .mg-md-y-90 {
        margin-top: 90px;
        margin-bottom: 90px;
    }

    .mg-md-y-90-f {
        margin-top: 90px !important;
        margin-bottom: 90px !important;
    }

    .mg-md-x-90 {
        margin-left: 90px;
        margin-right: 90px;
    }

    .mg-md-x-90-f {
        margin-left: 90px !important;
        margin-right: 90px !important;
    }

    .mg-md-t-90 {
        margin-top: 90px;
    }

    .mg-md-r-90 {
        margin-right: 90px;
    }

    .mg-md-b-90 {
        margin-bottom: 90px;
    }

    .mg-md-l-90 {
        margin-left: 90px;
    }

    .mg-md-t-90-f {
        margin-top: 90px !important;
    }

    .mg-md-r-90-f {
        margin-right: 90px !important;
    }

    .mg-md-b-90-f {
        margin-bottom: 90px !important;
    }

    .mg-md-l-90-f {
        margin-left: 90px !important;
    }

    .mg-md-95 {
        margin: 95px;
    }

    .mg-md-95-f {
        margin: 95px !important;
    }

    .mg-md-y-95 {
        margin-top: 95px;
        margin-bottom: 95px;
    }

    .mg-md-y-95-f {
        margin-top: 95px !important;
        margin-bottom: 95px !important;
    }

    .mg-md-x-95 {
        margin-left: 95px;
        margin-right: 95px;
    }

    .mg-md-x-95-f {
        margin-left: 95px !important;
        margin-right: 95px !important;
    }

    .mg-md-t-95 {
        margin-top: 95px;
    }

    .mg-md-r-95 {
        margin-right: 95px;
    }

    .mg-md-b-95 {
        margin-bottom: 95px;
    }

    .mg-md-l-95 {
        margin-left: 95px;
    }

    .mg-md-t-95-f {
        margin-top: 95px !important;
    }

    .mg-md-r-95-f {
        margin-right: 95px !important;
    }

    .mg-md-b-95-f {
        margin-bottom: 95px !important;
    }

    .mg-md-l-95-f {
        margin-left: 95px !important;
    }

    .mg-md-100 {
        margin: 100px;
    }

    .mg-md-100-f {
        margin: 100px !important;
    }

    .mg-md-y-100 {
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .mg-md-y-100-f {
        margin-top: 100px !important;
        margin-bottom: 100px !important;
    }

    .mg-md-x-100 {
        margin-left: 100px;
        margin-right: 100px;
    }

    .mg-md-x-100-f {
        margin-left: 100px !important;
        margin-right: 100px !important;
    }

    .mg-md-t-100 {
        margin-top: 100px;
    }

    .mg-md-r-100 {
        margin-right: 100px;
    }

    .mg-md-b-100 {
        margin-bottom: 100px;
    }

    .mg-md-l-100 {
        margin-left: 100px;
    }

    .mg-md-t-100-f {
        margin-top: 100px !important;
    }

    .mg-md-r-100-f {
        margin-right: 100px !important;
    }

    .mg-md-b-100-f {
        margin-bottom: 100px !important;
    }

    .mg-md-l-100-f {
        margin-left: 100px !important;
    }

    .mg-md-t-auto {
        margin-top: auto;
    }

    .mg-md-r-auto {
        margin-right: auto;
    }

    .mg-md-b-auto {
        margin-bottom: auto;
    }

    .mg-md-l-auto {
        margin-left: auto;
    }

    .mg-md-auto {
        margin: auto;
    }

    .mg-md-x-auto {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 992px) {
    .mg-lg-0 {
        margin: 0px;
    }

    .mg-lg-0-f {
        margin: 0px !important;
    }

    .mg-lg-y-0 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .mg-lg-y-0-f {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .mg-lg-x-0 {
        margin-left: 0px;
        margin-right: 0px;
    }

    .mg-lg-x-0-f {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .mg-lg-t-0 {
        margin-top: 0px;
    }

    .mg-lg-r-0 {
        margin-right: 0px;
    }

    .mg-lg-b-0 {
        margin-bottom: 0px;
    }

    .mg-lg-l-0 {
        margin-left: 0px;
    }

    .mg-lg-t-0-f {
        margin-top: 0px !important;
    }

    .mg-lg-r-0-f {
        margin-right: 0px !important;
    }

    .mg-lg-b-0-f {
        margin-bottom: 0px !important;
    }

    .mg-lg-l-0-f {
        margin-left: 0px !important;
    }

    .mg-lg-1 {
        margin: 1px;
    }

    .mg-lg-1-f {
        margin: 1px !important;
    }

    .mg-lg-y-1 {
        margin-top: 1px;
        margin-bottom: 1px;
    }

    .mg-lg-y-1-f {
        margin-top: 1px !important;
        margin-bottom: 1px !important;
    }

    .mg-lg-x-1 {
        margin-left: 1px;
        margin-right: 1px;
    }

    .mg-lg-x-1-f {
        margin-left: 1px !important;
        margin-right: 1px !important;
    }

    .mg-lg-t-1 {
        margin-top: 1px;
    }

    .mg-lg-r-1 {
        margin-right: 1px;
    }

    .mg-lg-b-1 {
        margin-bottom: 1px;
    }

    .mg-lg-l-1 {
        margin-left: 1px;
    }

    .mg-lg-t-1-f {
        margin-top: 1px !important;
    }

    .mg-lg-r-1-f {
        margin-right: 1px !important;
    }

    .mg-lg-b-1-f {
        margin-bottom: 1px !important;
    }

    .mg-lg-l-1-f {
        margin-left: 1px !important;
    }

    .mg-lg-2 {
        margin: 2px;
    }

    .mg-lg-2-f {
        margin: 2px !important;
    }

    .mg-lg-y-2 {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .mg-lg-y-2-f {
        margin-top: 2px !important;
        margin-bottom: 2px !important;
    }

    .mg-lg-x-2 {
        margin-left: 2px;
        margin-right: 2px;
    }

    .mg-lg-x-2-f {
        margin-left: 2px !important;
        margin-right: 2px !important;
    }

    .mg-lg-t-2 {
        margin-top: 2px;
    }

    .mg-lg-r-2 {
        margin-right: 2px;
    }

    .mg-lg-b-2 {
        margin-bottom: 2px;
    }

    .mg-lg-l-2 {
        margin-left: 2px;
    }

    .mg-lg-t-2-f {
        margin-top: 2px !important;
    }

    .mg-lg-r-2-f {
        margin-right: 2px !important;
    }

    .mg-lg-b-2-f {
        margin-bottom: 2px !important;
    }

    .mg-lg-l-2-f {
        margin-left: 2px !important;
    }

    .mg-lg-3 {
        margin: 3px;
    }

    .mg-lg-3-f {
        margin: 3px !important;
    }

    .mg-lg-y-3 {
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .mg-lg-y-3-f {
        margin-top: 3px !important;
        margin-bottom: 3px !important;
    }

    .mg-lg-x-3 {
        margin-left: 3px;
        margin-right: 3px;
    }

    .mg-lg-x-3-f {
        margin-left: 3px !important;
        margin-right: 3px !important;
    }

    .mg-lg-t-3 {
        margin-top: 3px;
    }

    .mg-lg-r-3 {
        margin-right: 3px;
    }

    .mg-lg-b-3 {
        margin-bottom: 3px;
    }

    .mg-lg-l-3 {
        margin-left: 3px;
    }

    .mg-lg-t-3-f {
        margin-top: 3px !important;
    }

    .mg-lg-r-3-f {
        margin-right: 3px !important;
    }

    .mg-lg-b-3-f {
        margin-bottom: 3px !important;
    }

    .mg-lg-l-3-f {
        margin-left: 3px !important;
    }

    .mg-lg-4 {
        margin: 4px;
    }

    .mg-lg-4-f {
        margin: 4px !important;
    }

    .mg-lg-y-4 {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .mg-lg-y-4-f {
        margin-top: 4px !important;
        margin-bottom: 4px !important;
    }

    .mg-lg-x-4 {
        margin-left: 4px;
        margin-right: 4px;
    }

    .mg-lg-x-4-f {
        margin-left: 4px !important;
        margin-right: 4px !important;
    }

    .mg-lg-t-4 {
        margin-top: 4px;
    }

    .mg-lg-r-4 {
        margin-right: 4px;
    }

    .mg-lg-b-4 {
        margin-bottom: 4px;
    }

    .mg-lg-l-4 {
        margin-left: 4px;
    }

    .mg-lg-t-4-f {
        margin-top: 4px !important;
    }

    .mg-lg-r-4-f {
        margin-right: 4px !important;
    }

    .mg-lg-b-4-f {
        margin-bottom: 4px !important;
    }

    .mg-lg-l-4-f {
        margin-left: 4px !important;
    }

    .mg-lg-5 {
        margin: 5px;
    }

    .mg-lg-5-f {
        margin: 5px !important;
    }

    .mg-lg-y-5 {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .mg-lg-y-5-f {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }

    .mg-lg-x-5 {
        margin-left: 5px;
        margin-right: 5px;
    }

    .mg-lg-x-5-f {
        margin-left: 5px !important;
        margin-right: 5px !important;
    }

    .mg-lg-t-5 {
        margin-top: 5px;
    }

    .mg-lg-r-5 {
        margin-right: 5px;
    }

    .mg-lg-b-5 {
        margin-bottom: 5px;
    }

    .mg-lg-l-5 {
        margin-left: 5px;
    }

    .mg-lg-t-5-f {
        margin-top: 5px !important;
    }

    .mg-lg-r-5-f {
        margin-right: 5px !important;
    }

    .mg-lg-b-5-f {
        margin-bottom: 5px !important;
    }

    .mg-lg-l-5-f {
        margin-left: 5px !important;
    }

    .mg-lg-6 {
        margin: 6px;
    }

    .mg-lg-6-f {
        margin: 6px !important;
    }

    .mg-lg-y-6 {
        margin-top: 6px;
        margin-bottom: 6px;
    }

    .mg-lg-y-6-f {
        margin-top: 6px !important;
        margin-bottom: 6px !important;
    }

    .mg-lg-x-6 {
        margin-left: 6px;
        margin-right: 6px;
    }

    .mg-lg-x-6-f {
        margin-left: 6px !important;
        margin-right: 6px !important;
    }

    .mg-lg-t-6 {
        margin-top: 6px;
    }

    .mg-lg-r-6 {
        margin-right: 6px;
    }

    .mg-lg-b-6 {
        margin-bottom: 6px;
    }

    .mg-lg-l-6 {
        margin-left: 6px;
    }

    .mg-lg-t-6-f {
        margin-top: 6px !important;
    }

    .mg-lg-r-6-f {
        margin-right: 6px !important;
    }

    .mg-lg-b-6-f {
        margin-bottom: 6px !important;
    }

    .mg-lg-l-6-f {
        margin-left: 6px !important;
    }

    .mg-lg-7 {
        margin: 7px;
    }

    .mg-lg-7-f {
        margin: 7px !important;
    }

    .mg-lg-y-7 {
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .mg-lg-y-7-f {
        margin-top: 7px !important;
        margin-bottom: 7px !important;
    }

    .mg-lg-x-7 {
        margin-left: 7px;
        margin-right: 7px;
    }

    .mg-lg-x-7-f {
        margin-left: 7px !important;
        margin-right: 7px !important;
    }

    .mg-lg-t-7 {
        margin-top: 7px;
    }

    .mg-lg-r-7 {
        margin-right: 7px;
    }

    .mg-lg-b-7 {
        margin-bottom: 7px;
    }

    .mg-lg-l-7 {
        margin-left: 7px;
    }

    .mg-lg-t-7-f {
        margin-top: 7px !important;
    }

    .mg-lg-r-7-f {
        margin-right: 7px !important;
    }

    .mg-lg-b-7-f {
        margin-bottom: 7px !important;
    }

    .mg-lg-l-7-f {
        margin-left: 7px !important;
    }

    .mg-lg-8 {
        margin: 8px;
    }

    .mg-lg-8-f {
        margin: 8px !important;
    }

    .mg-lg-y-8 {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .mg-lg-y-8-f {
        margin-top: 8px !important;
        margin-bottom: 8px !important;
    }

    .mg-lg-x-8 {
        margin-left: 8px;
        margin-right: 8px;
    }

    .mg-lg-x-8-f {
        margin-left: 8px !important;
        margin-right: 8px !important;
    }

    .mg-lg-t-8 {
        margin-top: 8px;
    }

    .mg-lg-r-8 {
        margin-right: 8px;
    }

    .mg-lg-b-8 {
        margin-bottom: 8px;
    }

    .mg-lg-l-8 {
        margin-left: 8px;
    }

    .mg-lg-t-8-f {
        margin-top: 8px !important;
    }

    .mg-lg-r-8-f {
        margin-right: 8px !important;
    }

    .mg-lg-b-8-f {
        margin-bottom: 8px !important;
    }

    .mg-lg-l-8-f {
        margin-left: 8px !important;
    }

    .mg-lg-9 {
        margin: 9px;
    }

    .mg-lg-9-f {
        margin: 9px !important;
    }

    .mg-lg-y-9 {
        margin-top: 9px;
        margin-bottom: 9px;
    }

    .mg-lg-y-9-f {
        margin-top: 9px !important;
        margin-bottom: 9px !important;
    }

    .mg-lg-x-9 {
        margin-left: 9px;
        margin-right: 9px;
    }

    .mg-lg-x-9-f {
        margin-left: 9px !important;
        margin-right: 9px !important;
    }

    .mg-lg-t-9 {
        margin-top: 9px;
    }

    .mg-lg-r-9 {
        margin-right: 9px;
    }

    .mg-lg-b-9 {
        margin-bottom: 9px;
    }

    .mg-lg-l-9 {
        margin-left: 9px;
    }

    .mg-lg-t-9-f {
        margin-top: 9px !important;
    }

    .mg-lg-r-9-f {
        margin-right: 9px !important;
    }

    .mg-lg-b-9-f {
        margin-bottom: 9px !important;
    }

    .mg-lg-l-9-f {
        margin-left: 9px !important;
    }

    .mg-lg-10 {
        margin: 10px;
    }

    .mg-lg-10-f {
        margin: 10px !important;
    }

    .mg-lg-y-10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .mg-lg-y-10-f {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    .mg-lg-x-10 {
        margin-left: 10px;
        margin-right: 10px;
    }

    .mg-lg-x-10-f {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }

    .mg-lg-t-10 {
        margin-top: 10px;
    }

    .mg-lg-r-10 {
        margin-right: 10px;
    }

    .mg-lg-b-10 {
        margin-bottom: 10px;
    }

    .mg-lg-l-10 {
        margin-left: 10px;
    }

    .mg-lg-t-10-f {
        margin-top: 10px !important;
    }

    .mg-lg-r-10-f {
        margin-right: 10px !important;
    }

    .mg-lg-b-10-f {
        margin-bottom: 10px !important;
    }

    .mg-lg-l-10-f {
        margin-left: 10px !important;
    }

    .mg-lg-15 {
        margin: 15px;
    }

    .mg-lg-15-f {
        margin: 15px !important;
    }

    .mg-lg-y-15 {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .mg-lg-y-15-f {
        margin-top: 15px !important;
        margin-bottom: 15px !important;
    }

    .mg-lg-x-15 {
        margin-left: 15px;
        margin-right: 15px;
    }

    .mg-lg-x-15-f {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }

    .mg-lg-t-15 {
        margin-top: 15px;
    }

    .mg-lg-r-15 {
        margin-right: 15px;
    }

    .mg-lg-b-15 {
        margin-bottom: 15px;
    }

    .mg-lg-l-15 {
        margin-left: 15px;
    }

    .mg-lg-t-15-f {
        margin-top: 15px !important;
    }

    .mg-lg-r-15-f {
        margin-right: 15px !important;
    }

    .mg-lg-b-15-f {
        margin-bottom: 15px !important;
    }

    .mg-lg-l-15-f {
        margin-left: 15px !important;
    }

    .mg-lg-20 {
        margin: 20px;
    }

    .mg-lg-20-f {
        margin: 20px !important;
    }

    .mg-lg-y-20 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .mg-lg-y-20-f {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .mg-lg-x-20 {
        margin-left: 20px;
        margin-right: 20px;
    }

    .mg-lg-x-20-f {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }

    .mg-lg-t-20 {
        margin-top: 20px;
    }

    .mg-lg-r-20 {
        margin-right: 20px;
    }

    .mg-lg-b-20 {
        margin-bottom: 20px;
    }

    .mg-lg-l-20 {
        margin-left: 20px;
    }

    .mg-lg-t-20-f {
        margin-top: 20px !important;
    }

    .mg-lg-r-20-f {
        margin-right: 20px !important;
    }

    .mg-lg-b-20-f {
        margin-bottom: 20px !important;
    }

    .mg-lg-l-20-f {
        margin-left: 20px !important;
    }

    .mg-lg-25 {
        margin: 25px;
    }

    .mg-lg-25-f {
        margin: 25px !important;
    }

    .mg-lg-y-25 {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .mg-lg-y-25-f {
        margin-top: 25px !important;
        margin-bottom: 25px !important;
    }

    .mg-lg-x-25 {
        margin-left: 25px;
        margin-right: 25px;
    }

    .mg-lg-x-25-f {
        margin-left: 25px !important;
        margin-right: 25px !important;
    }

    .mg-lg-t-25 {
        margin-top: 25px;
    }

    .mg-lg-r-25 {
        margin-right: 25px;
    }

    .mg-lg-b-25 {
        margin-bottom: 25px;
    }

    .mg-lg-l-25 {
        margin-left: 25px;
    }

    .mg-lg-t-25-f {
        margin-top: 25px !important;
    }

    .mg-lg-r-25-f {
        margin-right: 25px !important;
    }

    .mg-lg-b-25-f {
        margin-bottom: 25px !important;
    }

    .mg-lg-l-25-f {
        margin-left: 25px !important;
    }

    .mg-lg-30 {
        margin: 30px;
    }

    .mg-lg-30-f {
        margin: 30px !important;
    }

    .mg-lg-y-30 {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .mg-lg-y-30-f {
        margin-top: 30px !important;
        margin-bottom: 30px !important;
    }

    .mg-lg-x-30 {
        margin-left: 30px;
        margin-right: 30px;
    }

    .mg-lg-x-30-f {
        margin-left: 30px !important;
        margin-right: 30px !important;
    }

    .mg-lg-t-30 {
        margin-top: 30px;
    }

    .mg-lg-r-30 {
        margin-right: 30px;
    }

    .mg-lg-b-30 {
        margin-bottom: 30px;
    }

    .mg-lg-l-30 {
        margin-left: 30px;
    }

    .mg-lg-t-30-f {
        margin-top: 30px !important;
    }

    .mg-lg-r-30-f {
        margin-right: 30px !important;
    }

    .mg-lg-b-30-f {
        margin-bottom: 30px !important;
    }

    .mg-lg-l-30-f {
        margin-left: 30px !important;
    }

    .mg-lg-35 {
        margin: 35px;
    }

    .mg-lg-35-f {
        margin: 35px !important;
    }

    .mg-lg-y-35 {
        margin-top: 35px;
        margin-bottom: 35px;
    }

    .mg-lg-y-35-f {
        margin-top: 35px !important;
        margin-bottom: 35px !important;
    }

    .mg-lg-x-35 {
        margin-left: 35px;
        margin-right: 35px;
    }

    .mg-lg-x-35-f {
        margin-left: 35px !important;
        margin-right: 35px !important;
    }

    .mg-lg-t-35 {
        margin-top: 35px;
    }

    .mg-lg-r-35 {
        margin-right: 35px;
    }

    .mg-lg-b-35 {
        margin-bottom: 35px;
    }

    .mg-lg-l-35 {
        margin-left: 35px;
    }

    .mg-lg-t-35-f {
        margin-top: 35px !important;
    }

    .mg-lg-r-35-f {
        margin-right: 35px !important;
    }

    .mg-lg-b-35-f {
        margin-bottom: 35px !important;
    }

    .mg-lg-l-35-f {
        margin-left: 35px !important;
    }

    .mg-lg-40 {
        margin: 40px;
    }

    .mg-lg-40-f {
        margin: 40px !important;
    }

    .mg-lg-y-40 {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .mg-lg-y-40-f {
        margin-top: 40px !important;
        margin-bottom: 40px !important;
    }

    .mg-lg-x-40 {
        margin-left: 40px;
        margin-right: 40px;
    }

    .mg-lg-x-40-f {
        margin-left: 40px !important;
        margin-right: 40px !important;
    }

    .mg-lg-t-40 {
        margin-top: 40px;
    }

    .mg-lg-r-40 {
        margin-right: 40px;
    }

    .mg-lg-b-40 {
        margin-bottom: 40px;
    }

    .mg-lg-l-40 {
        margin-left: 40px;
    }

    .mg-lg-t-40-f {
        margin-top: 40px !important;
    }

    .mg-lg-r-40-f {
        margin-right: 40px !important;
    }

    .mg-lg-b-40-f {
        margin-bottom: 40px !important;
    }

    .mg-lg-l-40-f {
        margin-left: 40px !important;
    }

    .mg-lg-45 {
        margin: 45px;
    }

    .mg-lg-45-f {
        margin: 45px !important;
    }

    .mg-lg-y-45 {
        margin-top: 45px;
        margin-bottom: 45px;
    }

    .mg-lg-y-45-f {
        margin-top: 45px !important;
        margin-bottom: 45px !important;
    }

    .mg-lg-x-45 {
        margin-left: 45px;
        margin-right: 45px;
    }

    .mg-lg-x-45-f {
        margin-left: 45px !important;
        margin-right: 45px !important;
    }

    .mg-lg-t-45 {
        margin-top: 45px;
    }

    .mg-lg-r-45 {
        margin-right: 45px;
    }

    .mg-lg-b-45 {
        margin-bottom: 45px;
    }

    .mg-lg-l-45 {
        margin-left: 45px;
    }

    .mg-lg-t-45-f {
        margin-top: 45px !important;
    }

    .mg-lg-r-45-f {
        margin-right: 45px !important;
    }

    .mg-lg-b-45-f {
        margin-bottom: 45px !important;
    }

    .mg-lg-l-45-f {
        margin-left: 45px !important;
    }

    .mg-lg-50 {
        margin: 50px;
    }

    .mg-lg-50-f {
        margin: 50px !important;
    }

    .mg-lg-y-50 {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .mg-lg-y-50-f {
        margin-top: 50px !important;
        margin-bottom: 50px !important;
    }

    .mg-lg-x-50 {
        margin-left: 50px;
        margin-right: 50px;
    }

    .mg-lg-x-50-f {
        margin-left: 50px !important;
        margin-right: 50px !important;
    }

    .mg-lg-t-50 {
        margin-top: 50px;
    }

    .mg-lg-r-50 {
        margin-right: 50px;
    }

    .mg-lg-b-50 {
        margin-bottom: 50px;
    }

    .mg-lg-l-50 {
        margin-left: 50px;
    }

    .mg-lg-t-50-f {
        margin-top: 50px !important;
    }

    .mg-lg-r-50-f {
        margin-right: 50px !important;
    }

    .mg-lg-b-50-f {
        margin-bottom: 50px !important;
    }

    .mg-lg-l-50-f {
        margin-left: 50px !important;
    }

    .mg-lg-55 {
        margin: 55px;
    }

    .mg-lg-55-f {
        margin: 55px !important;
    }

    .mg-lg-y-55 {
        margin-top: 55px;
        margin-bottom: 55px;
    }

    .mg-lg-y-55-f {
        margin-top: 55px !important;
        margin-bottom: 55px !important;
    }

    .mg-lg-x-55 {
        margin-left: 55px;
        margin-right: 55px;
    }

    .mg-lg-x-55-f {
        margin-left: 55px !important;
        margin-right: 55px !important;
    }

    .mg-lg-t-55 {
        margin-top: 55px;
    }

    .mg-lg-r-55 {
        margin-right: 55px;
    }

    .mg-lg-b-55 {
        margin-bottom: 55px;
    }

    .mg-lg-l-55 {
        margin-left: 55px;
    }

    .mg-lg-t-55-f {
        margin-top: 55px !important;
    }

    .mg-lg-r-55-f {
        margin-right: 55px !important;
    }

    .mg-lg-b-55-f {
        margin-bottom: 55px !important;
    }

    .mg-lg-l-55-f {
        margin-left: 55px !important;
    }

    .mg-lg-60 {
        margin: 60px;
    }

    .mg-lg-60-f {
        margin: 60px !important;
    }

    .mg-lg-y-60 {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .mg-lg-y-60-f {
        margin-top: 60px !important;
        margin-bottom: 60px !important;
    }

    .mg-lg-x-60 {
        margin-left: 60px;
        margin-right: 60px;
    }

    .mg-lg-x-60-f {
        margin-left: 60px !important;
        margin-right: 60px !important;
    }

    .mg-lg-t-60 {
        margin-top: 60px;
    }

    .mg-lg-r-60 {
        margin-right: 60px;
    }

    .mg-lg-b-60 {
        margin-bottom: 60px;
    }

    .mg-lg-l-60 {
        margin-left: 60px;
    }

    .mg-lg-t-60-f {
        margin-top: 60px !important;
    }

    .mg-lg-r-60-f {
        margin-right: 60px !important;
    }

    .mg-lg-b-60-f {
        margin-bottom: 60px !important;
    }

    .mg-lg-l-60-f {
        margin-left: 60px !important;
    }

    .mg-lg-65 {
        margin: 65px;
    }

    .mg-lg-65-f {
        margin: 65px !important;
    }

    .mg-lg-y-65 {
        margin-top: 65px;
        margin-bottom: 65px;
    }

    .mg-lg-y-65-f {
        margin-top: 65px !important;
        margin-bottom: 65px !important;
    }

    .mg-lg-x-65 {
        margin-left: 65px;
        margin-right: 65px;
    }

    .mg-lg-x-65-f {
        margin-left: 65px !important;
        margin-right: 65px !important;
    }

    .mg-lg-t-65 {
        margin-top: 65px;
    }

    .mg-lg-r-65 {
        margin-right: 65px;
    }

    .mg-lg-b-65 {
        margin-bottom: 65px;
    }

    .mg-lg-l-65 {
        margin-left: 65px;
    }

    .mg-lg-t-65-f {
        margin-top: 65px !important;
    }

    .mg-lg-r-65-f {
        margin-right: 65px !important;
    }

    .mg-lg-b-65-f {
        margin-bottom: 65px !important;
    }

    .mg-lg-l-65-f {
        margin-left: 65px !important;
    }

    .mg-lg-70 {
        margin: 70px;
    }

    .mg-lg-70-f {
        margin: 70px !important;
    }

    .mg-lg-y-70 {
        margin-top: 70px;
        margin-bottom: 70px;
    }

    .mg-lg-y-70-f {
        margin-top: 70px !important;
        margin-bottom: 70px !important;
    }

    .mg-lg-x-70 {
        margin-left: 70px;
        margin-right: 70px;
    }

    .mg-lg-x-70-f {
        margin-left: 70px !important;
        margin-right: 70px !important;
    }

    .mg-lg-t-70 {
        margin-top: 70px;
    }

    .mg-lg-r-70 {
        margin-right: 70px;
    }

    .mg-lg-b-70 {
        margin-bottom: 70px;
    }

    .mg-lg-l-70 {
        margin-left: 70px;
    }

    .mg-lg-t-70-f {
        margin-top: 70px !important;
    }

    .mg-lg-r-70-f {
        margin-right: 70px !important;
    }

    .mg-lg-b-70-f {
        margin-bottom: 70px !important;
    }

    .mg-lg-l-70-f {
        margin-left: 70px !important;
    }

    .mg-lg-75 {
        margin: 75px;
    }

    .mg-lg-75-f {
        margin: 75px !important;
    }

    .mg-lg-y-75 {
        margin-top: 75px;
        margin-bottom: 75px;
    }

    .mg-lg-y-75-f {
        margin-top: 75px !important;
        margin-bottom: 75px !important;
    }

    .mg-lg-x-75 {
        margin-left: 75px;
        margin-right: 75px;
    }

    .mg-lg-x-75-f {
        margin-left: 75px !important;
        margin-right: 75px !important;
    }

    .mg-lg-t-75 {
        margin-top: 75px;
    }

    .mg-lg-r-75 {
        margin-right: 75px;
    }

    .mg-lg-b-75 {
        margin-bottom: 75px;
    }

    .mg-lg-l-75 {
        margin-left: 75px;
    }

    .mg-lg-t-75-f {
        margin-top: 75px !important;
    }

    .mg-lg-r-75-f {
        margin-right: 75px !important;
    }

    .mg-lg-b-75-f {
        margin-bottom: 75px !important;
    }

    .mg-lg-l-75-f {
        margin-left: 75px !important;
    }

    .mg-lg-80 {
        margin: 80px;
    }

    .mg-lg-80-f {
        margin: 80px !important;
    }

    .mg-lg-y-80 {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .mg-lg-y-80-f {
        margin-top: 80px !important;
        margin-bottom: 80px !important;
    }

    .mg-lg-x-80 {
        margin-left: 80px;
        margin-right: 80px;
    }

    .mg-lg-x-80-f {
        margin-left: 80px !important;
        margin-right: 80px !important;
    }

    .mg-lg-t-80 {
        margin-top: 80px;
    }

    .mg-lg-r-80 {
        margin-right: 80px;
    }

    .mg-lg-b-80 {
        margin-bottom: 80px;
    }

    .mg-lg-l-80 {
        margin-left: 80px;
    }

    .mg-lg-t-80-f {
        margin-top: 80px !important;
    }

    .mg-lg-r-80-f {
        margin-right: 80px !important;
    }

    .mg-lg-b-80-f {
        margin-bottom: 80px !important;
    }

    .mg-lg-l-80-f {
        margin-left: 80px !important;
    }

    .mg-lg-85 {
        margin: 85px;
    }

    .mg-lg-85-f {
        margin: 85px !important;
    }

    .mg-lg-y-85 {
        margin-top: 85px;
        margin-bottom: 85px;
    }

    .mg-lg-y-85-f {
        margin-top: 85px !important;
        margin-bottom: 85px !important;
    }

    .mg-lg-x-85 {
        margin-left: 85px;
        margin-right: 85px;
    }

    .mg-lg-x-85-f {
        margin-left: 85px !important;
        margin-right: 85px !important;
    }

    .mg-lg-t-85 {
        margin-top: 85px;
    }

    .mg-lg-r-85 {
        margin-right: 85px;
    }

    .mg-lg-b-85 {
        margin-bottom: 85px;
    }

    .mg-lg-l-85 {
        margin-left: 85px;
    }

    .mg-lg-t-85-f {
        margin-top: 85px !important;
    }

    .mg-lg-r-85-f {
        margin-right: 85px !important;
    }

    .mg-lg-b-85-f {
        margin-bottom: 85px !important;
    }

    .mg-lg-l-85-f {
        margin-left: 85px !important;
    }

    .mg-lg-90 {
        margin: 90px;
    }

    .mg-lg-90-f {
        margin: 90px !important;
    }

    .mg-lg-y-90 {
        margin-top: 90px;
        margin-bottom: 90px;
    }

    .mg-lg-y-90-f {
        margin-top: 90px !important;
        margin-bottom: 90px !important;
    }

    .mg-lg-x-90 {
        margin-left: 90px;
        margin-right: 90px;
    }

    .mg-lg-x-90-f {
        margin-left: 90px !important;
        margin-right: 90px !important;
    }

    .mg-lg-t-90 {
        margin-top: 90px;
    }

    .mg-lg-r-90 {
        margin-right: 90px;
    }

    .mg-lg-b-90 {
        margin-bottom: 90px;
    }

    .mg-lg-l-90 {
        margin-left: 90px;
    }

    .mg-lg-t-90-f {
        margin-top: 90px !important;
    }

    .mg-lg-r-90-f {
        margin-right: 90px !important;
    }

    .mg-lg-b-90-f {
        margin-bottom: 90px !important;
    }

    .mg-lg-l-90-f {
        margin-left: 90px !important;
    }

    .mg-lg-95 {
        margin: 95px;
    }

    .mg-lg-95-f {
        margin: 95px !important;
    }

    .mg-lg-y-95 {
        margin-top: 95px;
        margin-bottom: 95px;
    }

    .mg-lg-y-95-f {
        margin-top: 95px !important;
        margin-bottom: 95px !important;
    }

    .mg-lg-x-95 {
        margin-left: 95px;
        margin-right: 95px;
    }

    .mg-lg-x-95-f {
        margin-left: 95px !important;
        margin-right: 95px !important;
    }

    .mg-lg-t-95 {
        margin-top: 95px;
    }

    .mg-lg-r-95 {
        margin-right: 95px;
    }

    .mg-lg-b-95 {
        margin-bottom: 95px;
    }

    .mg-lg-l-95 {
        margin-left: 95px;
    }

    .mg-lg-t-95-f {
        margin-top: 95px !important;
    }

    .mg-lg-r-95-f {
        margin-right: 95px !important;
    }

    .mg-lg-b-95-f {
        margin-bottom: 95px !important;
    }

    .mg-lg-l-95-f {
        margin-left: 95px !important;
    }

    .mg-lg-100 {
        margin: 100px;
    }

    .mg-lg-100-f {
        margin: 100px !important;
    }

    .mg-lg-y-100 {
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .mg-lg-y-100-f {
        margin-top: 100px !important;
        margin-bottom: 100px !important;
    }

    .mg-lg-x-100 {
        margin-left: 100px;
        margin-right: 100px;
    }

    .mg-lg-x-100-f {
        margin-left: 100px !important;
        margin-right: 100px !important;
    }

    .mg-lg-t-100 {
        margin-top: 100px;
    }

    .mg-lg-r-100 {
        margin-right: 100px;
    }

    .mg-lg-b-100 {
        margin-bottom: 100px;
    }

    .mg-lg-l-100 {
        margin-left: 100px;
    }

    .mg-lg-t-100-f {
        margin-top: 100px !important;
    }

    .mg-lg-r-100-f {
        margin-right: 100px !important;
    }

    .mg-lg-b-100-f {
        margin-bottom: 100px !important;
    }

    .mg-lg-l-100-f {
        margin-left: 100px !important;
    }

    .mg-lg-t-auto {
        margin-top: auto;
    }

    .mg-lg-r-auto {
        margin-right: auto;
    }

    .mg-lg-b-auto {
        margin-bottom: auto;
    }

    .mg-lg-l-auto {
        margin-left: auto;
    }

    .mg-lg-auto {
        margin: auto;
    }

    .mg-lg-x-auto {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 1200px) {
    .mg-xl-0 {
        margin: 0px;
    }

    .mg-xl-0-f {
        margin: 0px !important;
    }

    .mg-xl-y-0 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .mg-xl-y-0-f {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .mg-xl-x-0 {
        margin-left: 0px;
        margin-right: 0px;
    }

    .mg-xl-x-0-f {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .mg-xl-t-0 {
        margin-top: 0px;
    }

    .mg-xl-r-0 {
        margin-right: 0px;
    }

    .mg-xl-b-0 {
        margin-bottom: 0px;
    }

    .mg-xl-l-0 {
        margin-left: 0px;
    }

    .mg-xl-t-0-f {
        margin-top: 0px !important;
    }

    .mg-xl-r-0-f {
        margin-right: 0px !important;
    }

    .mg-xl-b-0-f {
        margin-bottom: 0px !important;
    }

    .mg-xl-l-0-f {
        margin-left: 0px !important;
    }

    .mg-xl-1 {
        margin: 1px;
    }

    .mg-xl-1-f {
        margin: 1px !important;
    }

    .mg-xl-y-1 {
        margin-top: 1px;
        margin-bottom: 1px;
    }

    .mg-xl-y-1-f {
        margin-top: 1px !important;
        margin-bottom: 1px !important;
    }

    .mg-xl-x-1 {
        margin-left: 1px;
        margin-right: 1px;
    }

    .mg-xl-x-1-f {
        margin-left: 1px !important;
        margin-right: 1px !important;
    }

    .mg-xl-t-1 {
        margin-top: 1px;
    }

    .mg-xl-r-1 {
        margin-right: 1px;
    }

    .mg-xl-b-1 {
        margin-bottom: 1px;
    }

    .mg-xl-l-1 {
        margin-left: 1px;
    }

    .mg-xl-t-1-f {
        margin-top: 1px !important;
    }

    .mg-xl-r-1-f {
        margin-right: 1px !important;
    }

    .mg-xl-b-1-f {
        margin-bottom: 1px !important;
    }

    .mg-xl-l-1-f {
        margin-left: 1px !important;
    }

    .mg-xl-2 {
        margin: 2px;
    }

    .mg-xl-2-f {
        margin: 2px !important;
    }

    .mg-xl-y-2 {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .mg-xl-y-2-f {
        margin-top: 2px !important;
        margin-bottom: 2px !important;
    }

    .mg-xl-x-2 {
        margin-left: 2px;
        margin-right: 2px;
    }

    .mg-xl-x-2-f {
        margin-left: 2px !important;
        margin-right: 2px !important;
    }

    .mg-xl-t-2 {
        margin-top: 2px;
    }

    .mg-xl-r-2 {
        margin-right: 2px;
    }

    .mg-xl-b-2 {
        margin-bottom: 2px;
    }

    .mg-xl-l-2 {
        margin-left: 2px;
    }

    .mg-xl-t-2-f {
        margin-top: 2px !important;
    }

    .mg-xl-r-2-f {
        margin-right: 2px !important;
    }

    .mg-xl-b-2-f {
        margin-bottom: 2px !important;
    }

    .mg-xl-l-2-f {
        margin-left: 2px !important;
    }

    .mg-xl-3 {
        margin: 3px;
    }

    .mg-xl-3-f {
        margin: 3px !important;
    }

    .mg-xl-y-3 {
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .mg-xl-y-3-f {
        margin-top: 3px !important;
        margin-bottom: 3px !important;
    }

    .mg-xl-x-3 {
        margin-left: 3px;
        margin-right: 3px;
    }

    .mg-xl-x-3-f {
        margin-left: 3px !important;
        margin-right: 3px !important;
    }

    .mg-xl-t-3 {
        margin-top: 3px;
    }

    .mg-xl-r-3 {
        margin-right: 3px;
    }

    .mg-xl-b-3 {
        margin-bottom: 3px;
    }

    .mg-xl-l-3 {
        margin-left: 3px;
    }

    .mg-xl-t-3-f {
        margin-top: 3px !important;
    }

    .mg-xl-r-3-f {
        margin-right: 3px !important;
    }

    .mg-xl-b-3-f {
        margin-bottom: 3px !important;
    }

    .mg-xl-l-3-f {
        margin-left: 3px !important;
    }

    .mg-xl-4 {
        margin: 4px;
    }

    .mg-xl-4-f {
        margin: 4px !important;
    }

    .mg-xl-y-4 {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .mg-xl-y-4-f {
        margin-top: 4px !important;
        margin-bottom: 4px !important;
    }

    .mg-xl-x-4 {
        margin-left: 4px;
        margin-right: 4px;
    }

    .mg-xl-x-4-f {
        margin-left: 4px !important;
        margin-right: 4px !important;
    }

    .mg-xl-t-4 {
        margin-top: 4px;
    }

    .mg-xl-r-4 {
        margin-right: 4px;
    }

    .mg-xl-b-4 {
        margin-bottom: 4px;
    }

    .mg-xl-l-4 {
        margin-left: 4px;
    }

    .mg-xl-t-4-f {
        margin-top: 4px !important;
    }

    .mg-xl-r-4-f {
        margin-right: 4px !important;
    }

    .mg-xl-b-4-f {
        margin-bottom: 4px !important;
    }

    .mg-xl-l-4-f {
        margin-left: 4px !important;
    }

    .mg-xl-5 {
        margin: 5px;
    }

    .mg-xl-5-f {
        margin: 5px !important;
    }

    .mg-xl-y-5 {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .mg-xl-y-5-f {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }

    .mg-xl-x-5 {
        margin-left: 5px;
        margin-right: 5px;
    }

    .mg-xl-x-5-f {
        margin-left: 5px !important;
        margin-right: 5px !important;
    }

    .mg-xl-t-5 {
        margin-top: 5px;
    }

    .mg-xl-r-5 {
        margin-right: 5px;
    }

    .mg-xl-b-5 {
        margin-bottom: 5px;
    }

    .mg-xl-l-5 {
        margin-left: 5px;
    }

    .mg-xl-t-5-f {
        margin-top: 5px !important;
    }

    .mg-xl-r-5-f {
        margin-right: 5px !important;
    }

    .mg-xl-b-5-f {
        margin-bottom: 5px !important;
    }

    .mg-xl-l-5-f {
        margin-left: 5px !important;
    }

    .mg-xl-6 {
        margin: 6px;
    }

    .mg-xl-6-f {
        margin: 6px !important;
    }

    .mg-xl-y-6 {
        margin-top: 6px;
        margin-bottom: 6px;
    }

    .mg-xl-y-6-f {
        margin-top: 6px !important;
        margin-bottom: 6px !important;
    }

    .mg-xl-x-6 {
        margin-left: 6px;
        margin-right: 6px;
    }

    .mg-xl-x-6-f {
        margin-left: 6px !important;
        margin-right: 6px !important;
    }

    .mg-xl-t-6 {
        margin-top: 6px;
    }

    .mg-xl-r-6 {
        margin-right: 6px;
    }

    .mg-xl-b-6 {
        margin-bottom: 6px;
    }

    .mg-xl-l-6 {
        margin-left: 6px;
    }

    .mg-xl-t-6-f {
        margin-top: 6px !important;
    }

    .mg-xl-r-6-f {
        margin-right: 6px !important;
    }

    .mg-xl-b-6-f {
        margin-bottom: 6px !important;
    }

    .mg-xl-l-6-f {
        margin-left: 6px !important;
    }

    .mg-xl-7 {
        margin: 7px;
    }

    .mg-xl-7-f {
        margin: 7px !important;
    }

    .mg-xl-y-7 {
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .mg-xl-y-7-f {
        margin-top: 7px !important;
        margin-bottom: 7px !important;
    }

    .mg-xl-x-7 {
        margin-left: 7px;
        margin-right: 7px;
    }

    .mg-xl-x-7-f {
        margin-left: 7px !important;
        margin-right: 7px !important;
    }

    .mg-xl-t-7 {
        margin-top: 7px;
    }

    .mg-xl-r-7 {
        margin-right: 7px;
    }

    .mg-xl-b-7 {
        margin-bottom: 7px;
    }

    .mg-xl-l-7 {
        margin-left: 7px;
    }

    .mg-xl-t-7-f {
        margin-top: 7px !important;
    }

    .mg-xl-r-7-f {
        margin-right: 7px !important;
    }

    .mg-xl-b-7-f {
        margin-bottom: 7px !important;
    }

    .mg-xl-l-7-f {
        margin-left: 7px !important;
    }

    .mg-xl-8 {
        margin: 8px;
    }

    .mg-xl-8-f {
        margin: 8px !important;
    }

    .mg-xl-y-8 {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .mg-xl-y-8-f {
        margin-top: 8px !important;
        margin-bottom: 8px !important;
    }

    .mg-xl-x-8 {
        margin-left: 8px;
        margin-right: 8px;
    }

    .mg-xl-x-8-f {
        margin-left: 8px !important;
        margin-right: 8px !important;
    }

    .mg-xl-t-8 {
        margin-top: 8px;
    }

    .mg-xl-r-8 {
        margin-right: 8px;
    }

    .mg-xl-b-8 {
        margin-bottom: 8px;
    }

    .mg-xl-l-8 {
        margin-left: 8px;
    }

    .mg-xl-t-8-f {
        margin-top: 8px !important;
    }

    .mg-xl-r-8-f {
        margin-right: 8px !important;
    }

    .mg-xl-b-8-f {
        margin-bottom: 8px !important;
    }

    .mg-xl-l-8-f {
        margin-left: 8px !important;
    }

    .mg-xl-9 {
        margin: 9px;
    }

    .mg-xl-9-f {
        margin: 9px !important;
    }

    .mg-xl-y-9 {
        margin-top: 9px;
        margin-bottom: 9px;
    }

    .mg-xl-y-9-f {
        margin-top: 9px !important;
        margin-bottom: 9px !important;
    }

    .mg-xl-x-9 {
        margin-left: 9px;
        margin-right: 9px;
    }

    .mg-xl-x-9-f {
        margin-left: 9px !important;
        margin-right: 9px !important;
    }

    .mg-xl-t-9 {
        margin-top: 9px;
    }

    .mg-xl-r-9 {
        margin-right: 9px;
    }

    .mg-xl-b-9 {
        margin-bottom: 9px;
    }

    .mg-xl-l-9 {
        margin-left: 9px;
    }

    .mg-xl-t-9-f {
        margin-top: 9px !important;
    }

    .mg-xl-r-9-f {
        margin-right: 9px !important;
    }

    .mg-xl-b-9-f {
        margin-bottom: 9px !important;
    }

    .mg-xl-l-9-f {
        margin-left: 9px !important;
    }

    .mg-xl-10 {
        margin: 10px;
    }

    .mg-xl-10-f {
        margin: 10px !important;
    }

    .mg-xl-y-10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .mg-xl-y-10-f {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    .mg-xl-x-10 {
        margin-left: 10px;
        margin-right: 10px;
    }

    .mg-xl-x-10-f {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }

    .mg-xl-t-10 {
        margin-top: 10px;
    }

    .mg-xl-r-10 {
        margin-right: 10px;
    }

    .mg-xl-b-10 {
        margin-bottom: 10px;
    }

    .mg-xl-l-10 {
        margin-left: 10px;
    }

    .mg-xl-t-10-f {
        margin-top: 10px !important;
    }

    .mg-xl-r-10-f {
        margin-right: 10px !important;
    }

    .mg-xl-b-10-f {
        margin-bottom: 10px !important;
    }

    .mg-xl-l-10-f {
        margin-left: 10px !important;
    }

    .mg-xl-15 {
        margin: 15px;
    }

    .mg-xl-15-f {
        margin: 15px !important;
    }

    .mg-xl-y-15 {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .mg-xl-y-15-f {
        margin-top: 15px !important;
        margin-bottom: 15px !important;
    }

    .mg-xl-x-15 {
        margin-left: 15px;
        margin-right: 15px;
    }

    .mg-xl-x-15-f {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }

    .mg-xl-t-15 {
        margin-top: 15px;
    }

    .mg-xl-r-15 {
        margin-right: 15px;
    }

    .mg-xl-b-15 {
        margin-bottom: 15px;
    }

    .mg-xl-l-15 {
        margin-left: 15px;
    }

    .mg-xl-t-15-f {
        margin-top: 15px !important;
    }

    .mg-xl-r-15-f {
        margin-right: 15px !important;
    }

    .mg-xl-b-15-f {
        margin-bottom: 15px !important;
    }

    .mg-xl-l-15-f {
        margin-left: 15px !important;
    }

    .mg-xl-20 {
        margin: 20px;
    }

    .mg-xl-20-f {
        margin: 20px !important;
    }

    .mg-xl-y-20 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .mg-xl-y-20-f {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .mg-xl-x-20 {
        margin-left: 20px;
        margin-right: 20px;
    }

    .mg-xl-x-20-f {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }

    .mg-xl-t-20 {
        margin-top: 20px;
    }

    .mg-xl-r-20 {
        margin-right: 20px;
    }

    .mg-xl-b-20 {
        margin-bottom: 20px;
    }

    .mg-xl-l-20 {
        margin-left: 20px;
    }

    .mg-xl-t-20-f {
        margin-top: 20px !important;
    }

    .mg-xl-r-20-f {
        margin-right: 20px !important;
    }

    .mg-xl-b-20-f {
        margin-bottom: 20px !important;
    }

    .mg-xl-l-20-f {
        margin-left: 20px !important;
    }

    .mg-xl-25 {
        margin: 25px;
    }

    .mg-xl-25-f {
        margin: 25px !important;
    }

    .mg-xl-y-25 {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .mg-xl-y-25-f {
        margin-top: 25px !important;
        margin-bottom: 25px !important;
    }

    .mg-xl-x-25 {
        margin-left: 25px;
        margin-right: 25px;
    }

    .mg-xl-x-25-f {
        margin-left: 25px !important;
        margin-right: 25px !important;
    }

    .mg-xl-t-25 {
        margin-top: 25px;
    }

    .mg-xl-r-25 {
        margin-right: 25px;
    }

    .mg-xl-b-25 {
        margin-bottom: 25px;
    }

    .mg-xl-l-25 {
        margin-left: 25px;
    }

    .mg-xl-t-25-f {
        margin-top: 25px !important;
    }

    .mg-xl-r-25-f {
        margin-right: 25px !important;
    }

    .mg-xl-b-25-f {
        margin-bottom: 25px !important;
    }

    .mg-xl-l-25-f {
        margin-left: 25px !important;
    }

    .mg-xl-30 {
        margin: 30px;
    }

    .mg-xl-30-f {
        margin: 30px !important;
    }

    .mg-xl-y-30 {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .mg-xl-y-30-f {
        margin-top: 30px !important;
        margin-bottom: 30px !important;
    }

    .mg-xl-x-30 {
        margin-left: 30px;
        margin-right: 30px;
    }

    .mg-xl-x-30-f {
        margin-left: 30px !important;
        margin-right: 30px !important;
    }

    .mg-xl-t-30 {
        margin-top: 30px;
    }

    .mg-xl-r-30 {
        margin-right: 30px;
    }

    .mg-xl-b-30 {
        margin-bottom: 30px;
    }

    .mg-xl-l-30 {
        margin-left: 30px;
    }

    .mg-xl-t-30-f {
        margin-top: 30px !important;
    }

    .mg-xl-r-30-f {
        margin-right: 30px !important;
    }

    .mg-xl-b-30-f {
        margin-bottom: 30px !important;
    }

    .mg-xl-l-30-f {
        margin-left: 30px !important;
    }

    .mg-xl-35 {
        margin: 35px;
    }

    .mg-xl-35-f {
        margin: 35px !important;
    }

    .mg-xl-y-35 {
        margin-top: 35px;
        margin-bottom: 35px;
    }

    .mg-xl-y-35-f {
        margin-top: 35px !important;
        margin-bottom: 35px !important;
    }

    .mg-xl-x-35 {
        margin-left: 35px;
        margin-right: 35px;
    }

    .mg-xl-x-35-f {
        margin-left: 35px !important;
        margin-right: 35px !important;
    }

    .mg-xl-t-35 {
        margin-top: 35px;
    }

    .mg-xl-r-35 {
        margin-right: 35px;
    }

    .mg-xl-b-35 {
        margin-bottom: 35px;
    }

    .mg-xl-l-35 {
        margin-left: 35px;
    }

    .mg-xl-t-35-f {
        margin-top: 35px !important;
    }

    .mg-xl-r-35-f {
        margin-right: 35px !important;
    }

    .mg-xl-b-35-f {
        margin-bottom: 35px !important;
    }

    .mg-xl-l-35-f {
        margin-left: 35px !important;
    }

    .mg-xl-40 {
        margin: 40px;
    }

    .mg-xl-40-f {
        margin: 40px !important;
    }

    .mg-xl-y-40 {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .mg-xl-y-40-f {
        margin-top: 40px !important;
        margin-bottom: 40px !important;
    }

    .mg-xl-x-40 {
        margin-left: 40px;
        margin-right: 40px;
    }

    .mg-xl-x-40-f {
        margin-left: 40px !important;
        margin-right: 40px !important;
    }

    .mg-xl-t-40 {
        margin-top: 40px;
    }

    .mg-xl-r-40 {
        margin-right: 40px;
    }

    .mg-xl-b-40 {
        margin-bottom: 40px;
    }

    .mg-xl-l-40 {
        margin-left: 40px;
    }

    .mg-xl-t-40-f {
        margin-top: 40px !important;
    }

    .mg-xl-r-40-f {
        margin-right: 40px !important;
    }

    .mg-xl-b-40-f {
        margin-bottom: 40px !important;
    }

    .mg-xl-l-40-f {
        margin-left: 40px !important;
    }

    .mg-xl-45 {
        margin: 45px;
    }

    .mg-xl-45-f {
        margin: 45px !important;
    }

    .mg-xl-y-45 {
        margin-top: 45px;
        margin-bottom: 45px;
    }

    .mg-xl-y-45-f {
        margin-top: 45px !important;
        margin-bottom: 45px !important;
    }

    .mg-xl-x-45 {
        margin-left: 45px;
        margin-right: 45px;
    }

    .mg-xl-x-45-f {
        margin-left: 45px !important;
        margin-right: 45px !important;
    }

    .mg-xl-t-45 {
        margin-top: 45px;
    }

    .mg-xl-r-45 {
        margin-right: 45px;
    }

    .mg-xl-b-45 {
        margin-bottom: 45px;
    }

    .mg-xl-l-45 {
        margin-left: 45px;
    }

    .mg-xl-t-45-f {
        margin-top: 45px !important;
    }

    .mg-xl-r-45-f {
        margin-right: 45px !important;
    }

    .mg-xl-b-45-f {
        margin-bottom: 45px !important;
    }

    .mg-xl-l-45-f {
        margin-left: 45px !important;
    }

    .mg-xl-50 {
        margin: 50px;
    }

    .mg-xl-50-f {
        margin: 50px !important;
    }

    .mg-xl-y-50 {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .mg-xl-y-50-f {
        margin-top: 50px !important;
        margin-bottom: 50px !important;
    }

    .mg-xl-x-50 {
        margin-left: 50px;
        margin-right: 50px;
    }

    .mg-xl-x-50-f {
        margin-left: 50px !important;
        margin-right: 50px !important;
    }

    .mg-xl-t-50 {
        margin-top: 50px;
    }

    .mg-xl-r-50 {
        margin-right: 50px;
    }

    .mg-xl-b-50 {
        margin-bottom: 50px;
    }

    .mg-xl-l-50 {
        margin-left: 50px;
    }

    .mg-xl-t-50-f {
        margin-top: 50px !important;
    }

    .mg-xl-r-50-f {
        margin-right: 50px !important;
    }

    .mg-xl-b-50-f {
        margin-bottom: 50px !important;
    }

    .mg-xl-l-50-f {
        margin-left: 50px !important;
    }

    .mg-xl-55 {
        margin: 55px;
    }

    .mg-xl-55-f {
        margin: 55px !important;
    }

    .mg-xl-y-55 {
        margin-top: 55px;
        margin-bottom: 55px;
    }

    .mg-xl-y-55-f {
        margin-top: 55px !important;
        margin-bottom: 55px !important;
    }

    .mg-xl-x-55 {
        margin-left: 55px;
        margin-right: 55px;
    }

    .mg-xl-x-55-f {
        margin-left: 55px !important;
        margin-right: 55px !important;
    }

    .mg-xl-t-55 {
        margin-top: 55px;
    }

    .mg-xl-r-55 {
        margin-right: 55px;
    }

    .mg-xl-b-55 {
        margin-bottom: 55px;
    }

    .mg-xl-l-55 {
        margin-left: 55px;
    }

    .mg-xl-t-55-f {
        margin-top: 55px !important;
    }

    .mg-xl-r-55-f {
        margin-right: 55px !important;
    }

    .mg-xl-b-55-f {
        margin-bottom: 55px !important;
    }

    .mg-xl-l-55-f {
        margin-left: 55px !important;
    }

    .mg-xl-60 {
        margin: 60px;
    }

    .mg-xl-60-f {
        margin: 60px !important;
    }

    .mg-xl-y-60 {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .mg-xl-y-60-f {
        margin-top: 60px !important;
        margin-bottom: 60px !important;
    }

    .mg-xl-x-60 {
        margin-left: 60px;
        margin-right: 60px;
    }

    .mg-xl-x-60-f {
        margin-left: 60px !important;
        margin-right: 60px !important;
    }

    .mg-xl-t-60 {
        margin-top: 60px;
    }

    .mg-xl-r-60 {
        margin-right: 60px;
    }

    .mg-xl-b-60 {
        margin-bottom: 60px;
    }

    .mg-xl-l-60 {
        margin-left: 60px;
    }

    .mg-xl-t-60-f {
        margin-top: 60px !important;
    }

    .mg-xl-r-60-f {
        margin-right: 60px !important;
    }

    .mg-xl-b-60-f {
        margin-bottom: 60px !important;
    }

    .mg-xl-l-60-f {
        margin-left: 60px !important;
    }

    .mg-xl-65 {
        margin: 65px;
    }

    .mg-xl-65-f {
        margin: 65px !important;
    }

    .mg-xl-y-65 {
        margin-top: 65px;
        margin-bottom: 65px;
    }

    .mg-xl-y-65-f {
        margin-top: 65px !important;
        margin-bottom: 65px !important;
    }

    .mg-xl-x-65 {
        margin-left: 65px;
        margin-right: 65px;
    }

    .mg-xl-x-65-f {
        margin-left: 65px !important;
        margin-right: 65px !important;
    }

    .mg-xl-t-65 {
        margin-top: 65px;
    }

    .mg-xl-r-65 {
        margin-right: 65px;
    }

    .mg-xl-b-65 {
        margin-bottom: 65px;
    }

    .mg-xl-l-65 {
        margin-left: 65px;
    }

    .mg-xl-t-65-f {
        margin-top: 65px !important;
    }

    .mg-xl-r-65-f {
        margin-right: 65px !important;
    }

    .mg-xl-b-65-f {
        margin-bottom: 65px !important;
    }

    .mg-xl-l-65-f {
        margin-left: 65px !important;
    }

    .mg-xl-70 {
        margin: 70px;
    }

    .mg-xl-70-f {
        margin: 70px !important;
    }

    .mg-xl-y-70 {
        margin-top: 70px;
        margin-bottom: 70px;
    }

    .mg-xl-y-70-f {
        margin-top: 70px !important;
        margin-bottom: 70px !important;
    }

    .mg-xl-x-70 {
        margin-left: 70px;
        margin-right: 70px;
    }

    .mg-xl-x-70-f {
        margin-left: 70px !important;
        margin-right: 70px !important;
    }

    .mg-xl-t-70 {
        margin-top: 70px;
    }

    .mg-xl-r-70 {
        margin-right: 70px;
    }

    .mg-xl-b-70 {
        margin-bottom: 70px;
    }

    .mg-xl-l-70 {
        margin-left: 70px;
    }

    .mg-xl-t-70-f {
        margin-top: 70px !important;
    }

    .mg-xl-r-70-f {
        margin-right: 70px !important;
    }

    .mg-xl-b-70-f {
        margin-bottom: 70px !important;
    }

    .mg-xl-l-70-f {
        margin-left: 70px !important;
    }

    .mg-xl-75 {
        margin: 75px;
    }

    .mg-xl-75-f {
        margin: 75px !important;
    }

    .mg-xl-y-75 {
        margin-top: 75px;
        margin-bottom: 75px;
    }

    .mg-xl-y-75-f {
        margin-top: 75px !important;
        margin-bottom: 75px !important;
    }

    .mg-xl-x-75 {
        margin-left: 75px;
        margin-right: 75px;
    }

    .mg-xl-x-75-f {
        margin-left: 75px !important;
        margin-right: 75px !important;
    }

    .mg-xl-t-75 {
        margin-top: 75px;
    }

    .mg-xl-r-75 {
        margin-right: 75px;
    }

    .mg-xl-b-75 {
        margin-bottom: 75px;
    }

    .mg-xl-l-75 {
        margin-left: 75px;
    }

    .mg-xl-t-75-f {
        margin-top: 75px !important;
    }

    .mg-xl-r-75-f {
        margin-right: 75px !important;
    }

    .mg-xl-b-75-f {
        margin-bottom: 75px !important;
    }

    .mg-xl-l-75-f {
        margin-left: 75px !important;
    }

    .mg-xl-80 {
        margin: 80px;
    }

    .mg-xl-80-f {
        margin: 80px !important;
    }

    .mg-xl-y-80 {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .mg-xl-y-80-f {
        margin-top: 80px !important;
        margin-bottom: 80px !important;
    }

    .mg-xl-x-80 {
        margin-left: 80px;
        margin-right: 80px;
    }

    .mg-xl-x-80-f {
        margin-left: 80px !important;
        margin-right: 80px !important;
    }

    .mg-xl-t-80 {
        margin-top: 80px;
    }

    .mg-xl-r-80 {
        margin-right: 80px;
    }

    .mg-xl-b-80 {
        margin-bottom: 80px;
    }

    .mg-xl-l-80 {
        margin-left: 80px;
    }

    .mg-xl-t-80-f {
        margin-top: 80px !important;
    }

    .mg-xl-r-80-f {
        margin-right: 80px !important;
    }

    .mg-xl-b-80-f {
        margin-bottom: 80px !important;
    }

    .mg-xl-l-80-f {
        margin-left: 80px !important;
    }

    .mg-xl-85 {
        margin: 85px;
    }

    .mg-xl-85-f {
        margin: 85px !important;
    }

    .mg-xl-y-85 {
        margin-top: 85px;
        margin-bottom: 85px;
    }

    .mg-xl-y-85-f {
        margin-top: 85px !important;
        margin-bottom: 85px !important;
    }

    .mg-xl-x-85 {
        margin-left: 85px;
        margin-right: 85px;
    }

    .mg-xl-x-85-f {
        margin-left: 85px !important;
        margin-right: 85px !important;
    }

    .mg-xl-t-85 {
        margin-top: 85px;
    }

    .mg-xl-r-85 {
        margin-right: 85px;
    }

    .mg-xl-b-85 {
        margin-bottom: 85px;
    }

    .mg-xl-l-85 {
        margin-left: 85px;
    }

    .mg-xl-t-85-f {
        margin-top: 85px !important;
    }

    .mg-xl-r-85-f {
        margin-right: 85px !important;
    }

    .mg-xl-b-85-f {
        margin-bottom: 85px !important;
    }

    .mg-xl-l-85-f {
        margin-left: 85px !important;
    }

    .mg-xl-90 {
        margin: 90px;
    }

    .mg-xl-90-f {
        margin: 90px !important;
    }

    .mg-xl-y-90 {
        margin-top: 90px;
        margin-bottom: 90px;
    }

    .mg-xl-y-90-f {
        margin-top: 90px !important;
        margin-bottom: 90px !important;
    }

    .mg-xl-x-90 {
        margin-left: 90px;
        margin-right: 90px;
    }

    .mg-xl-x-90-f {
        margin-left: 90px !important;
        margin-right: 90px !important;
    }

    .mg-xl-t-90 {
        margin-top: 90px;
    }

    .mg-xl-r-90 {
        margin-right: 90px;
    }

    .mg-xl-b-90 {
        margin-bottom: 90px;
    }

    .mg-xl-l-90 {
        margin-left: 90px;
    }

    .mg-xl-t-90-f {
        margin-top: 90px !important;
    }

    .mg-xl-r-90-f {
        margin-right: 90px !important;
    }

    .mg-xl-b-90-f {
        margin-bottom: 90px !important;
    }

    .mg-xl-l-90-f {
        margin-left: 90px !important;
    }

    .mg-xl-95 {
        margin: 95px;
    }

    .mg-xl-95-f {
        margin: 95px !important;
    }

    .mg-xl-y-95 {
        margin-top: 95px;
        margin-bottom: 95px;
    }

    .mg-xl-y-95-f {
        margin-top: 95px !important;
        margin-bottom: 95px !important;
    }

    .mg-xl-x-95 {
        margin-left: 95px;
        margin-right: 95px;
    }

    .mg-xl-x-95-f {
        margin-left: 95px !important;
        margin-right: 95px !important;
    }

    .mg-xl-t-95 {
        margin-top: 95px;
    }

    .mg-xl-r-95 {
        margin-right: 95px;
    }

    .mg-xl-b-95 {
        margin-bottom: 95px;
    }

    .mg-xl-l-95 {
        margin-left: 95px;
    }

    .mg-xl-t-95-f {
        margin-top: 95px !important;
    }

    .mg-xl-r-95-f {
        margin-right: 95px !important;
    }

    .mg-xl-b-95-f {
        margin-bottom: 95px !important;
    }

    .mg-xl-l-95-f {
        margin-left: 95px !important;
    }

    .mg-xl-100 {
        margin: 100px;
    }

    .mg-xl-100-f {
        margin: 100px !important;
    }

    .mg-xl-y-100 {
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .mg-xl-y-100-f {
        margin-top: 100px !important;
        margin-bottom: 100px !important;
    }

    .mg-xl-x-100 {
        margin-left: 100px;
        margin-right: 100px;
    }

    .mg-xl-x-100-f {
        margin-left: 100px !important;
        margin-right: 100px !important;
    }

    .mg-xl-t-100 {
        margin-top: 100px;
    }

    .mg-xl-r-100 {
        margin-right: 100px;
    }

    .mg-xl-b-100 {
        margin-bottom: 100px;
    }

    .mg-xl-l-100 {
        margin-left: 100px;
    }

    .mg-xl-t-100-f {
        margin-top: 100px !important;
    }

    .mg-xl-r-100-f {
        margin-right: 100px !important;
    }

    .mg-xl-b-100-f {
        margin-bottom: 100px !important;
    }

    .mg-xl-l-100-f {
        margin-left: 100px !important;
    }

    .mg-xl-t-auto {
        margin-top: auto;
    }

    .mg-xl-r-auto {
        margin-right: auto;
    }

    .mg-xl-b-auto {
        margin-bottom: auto;
    }

    .mg-xl-l-auto {
        margin-left: auto;
    }

    .mg-xl-auto {
        margin: auto;
    }

    .mg-xl-x-auto {
        margin-left: auto;
        margin-right: auto;
    }
}

/* ###### 9.5 Misc   ###### */
.op-0 {
    opacity: 0;
}

.op-0-f {
    opacity: 0;
}

.op-1 {
    opacity: 0.1;
}

.op-1-f {
    opacity: 0.1;
}

.op-2 {
    opacity: 0.2;
}

.op-2-f {
    opacity: 0.2;
}

.op-3 {
    opacity: 0.3;
}

.op-3-f {
    opacity: 0.3;
}

.op-4 {
    opacity: 0.4;
}

.op-4-f {
    opacity: 0.4;
}

.op-5 {
    opacity: 0.5;
}

.op-5-f {
    opacity: 0.5;
}

.op-6 {
    opacity: 0.6;
}

.op-6-f {
    opacity: 0.6;
}

.op-7 {
    opacity: 0.7;
}

.op-7-f {
    opacity: 0.7;
}

.op-8 {
    opacity: 0.8;
}

.op-8-f {
    opacity: 0.8;
}

.op-9 {
    opacity: 0.9;
}

.op-9-f {
    opacity: 0.9;
}

.opacity-1 {
    opacity: 1;
}

.shadow-base {
    box-shadow: 0 0 2px 2px rgba(28, 39, 60, 0.03);
}

.shadow-1 {
    box-shadow: 0 0 7px 7px rgba(28, 39, 60, 0.1);
}

.shadow-2 {
    box-shadow: 0 0 30px rgba(28, 39, 60, 0.08);
}

.shadow-none {
    box-shadow: none;
}

.overflow-y-auto {
    overflow-y: auto;
}

.overflow-hidden {
    overflow: hidden;
}

.flex-1 {
    flex: 1;
}

.flex-0 {
    flex: 0;
}

.img-fit-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (min-width: 480px) {
    .op-xs-0 {
        opacity: 0;
    }

    .op-xs-0-f {
        opacity: 0;
    }

    .op-xs-1 {
        opacity: 0.1;
    }

    .op-xs-1-f {
        opacity: 0.1;
    }

    .op-xs-2 {
        opacity: 0.2;
    }

    .op-xs-2-f {
        opacity: 0.2;
    }

    .op-xs-3 {
        opacity: 0.3;
    }

    .op-xs-3-f {
        opacity: 0.3;
    }

    .op-xs-4 {
        opacity: 0.4;
    }

    .op-xs-4-f {
        opacity: 0.4;
    }

    .op-xs-5 {
        opacity: 0.5;
    }

    .op-xs-5-f {
        opacity: 0.5;
    }

    .op-xs-6 {
        opacity: 0.6;
    }

    .op-xs-6-f {
        opacity: 0.6;
    }

    .op-xs-7 {
        opacity: 0.7;
    }

    .op-xs-7-f {
        opacity: 0.7;
    }

    .op-xs-8 {
        opacity: 0.8;
    }

    .op-xs-8-f {
        opacity: 0.8;
    }

    .op-xs-9 {
        opacity: 0.9;
    }

    .op-xs-9-f {
        opacity: 0.9;
    }
}

@media (min-width: 576px) {
    .op-sm-0 {
        opacity: 0;
    }

    .op-sm-0-f {
        opacity: 0;
    }

    .op-sm-1 {
        opacity: 0.1;
    }

    .op-sm-1-f {
        opacity: 0.1;
    }

    .op-sm-2 {
        opacity: 0.2;
    }

    .op-sm-2-f {
        opacity: 0.2;
    }

    .op-sm-3 {
        opacity: 0.3;
    }

    .op-sm-3-f {
        opacity: 0.3;
    }

    .op-sm-4 {
        opacity: 0.4;
    }

    .op-sm-4-f {
        opacity: 0.4;
    }

    .op-sm-5 {
        opacity: 0.5;
    }

    .op-sm-5-f {
        opacity: 0.5;
    }

    .op-sm-6 {
        opacity: 0.6;
    }

    .op-sm-6-f {
        opacity: 0.6;
    }

    .op-sm-7 {
        opacity: 0.7;
    }

    .op-sm-7-f {
        opacity: 0.7;
    }

    .op-sm-8 {
        opacity: 0.8;
    }

    .op-sm-8-f {
        opacity: 0.8;
    }

    .op-sm-9 {
        opacity: 0.9;
    }

    .op-sm-9-f {
        opacity: 0.9;
    }
}

@media (min-width: 768px) {
    .op-md-0 {
        opacity: 0;
    }

    .op-md-0-f {
        opacity: 0;
    }

    .op-md-1 {
        opacity: 0.1;
    }

    .op-md-1-f {
        opacity: 0.1;
    }

    .op-md-2 {
        opacity: 0.2;
    }

    .op-md-2-f {
        opacity: 0.2;
    }

    .op-md-3 {
        opacity: 0.3;
    }

    .op-md-3-f {
        opacity: 0.3;
    }

    .op-md-4 {
        opacity: 0.4;
    }

    .op-md-4-f {
        opacity: 0.4;
    }

    .op-md-5 {
        opacity: 0.5;
    }

    .op-md-5-f {
        opacity: 0.5;
    }

    .op-md-6 {
        opacity: 0.6;
    }

    .op-md-6-f {
        opacity: 0.6;
    }

    .op-md-7 {
        opacity: 0.7;
    }

    .op-md-7-f {
        opacity: 0.7;
    }

    .op-md-8 {
        opacity: 0.8;
    }

    .op-md-8-f {
        opacity: 0.8;
    }

    .op-md-9 {
        opacity: 0.9;
    }

    .op-md-9-f {
        opacity: 0.9;
    }
}

@media (min-width: 992px) {
    .op-lg-0 {
        opacity: 0;
    }

    .op-lg-0-f {
        opacity: 0;
    }

    .op-lg-1 {
        opacity: 0.1;
    }

    .op-lg-1-f {
        opacity: 0.1;
    }

    .op-lg-2 {
        opacity: 0.2;
    }

    .op-lg-2-f {
        opacity: 0.2;
    }

    .op-lg-3 {
        opacity: 0.3;
    }

    .op-lg-3-f {
        opacity: 0.3;
    }

    .op-lg-4 {
        opacity: 0.4;
    }

    .op-lg-4-f {
        opacity: 0.4;
    }

    .op-lg-5 {
        opacity: 0.5;
    }

    .op-lg-5-f {
        opacity: 0.5;
    }

    .op-lg-6 {
        opacity: 0.6;
    }

    .op-lg-6-f {
        opacity: 0.6;
    }

    .op-lg-7 {
        opacity: 0.7;
    }

    .op-lg-7-f {
        opacity: 0.7;
    }

    .op-lg-8 {
        opacity: 0.8;
    }

    .op-lg-8-f {
        opacity: 0.8;
    }

    .op-lg-9 {
        opacity: 0.9;
    }

    .op-lg-9-f {
        opacity: 0.9;
    }
}

@media (min-width: 1200px) {
    .op-xl-0 {
        opacity: 0;
    }

    .op-xl-0-f {
        opacity: 0;
    }

    .op-xl-1 {
        opacity: 0.1;
    }

    .op-xl-1-f {
        opacity: 0.1;
    }

    .op-xl-2 {
        opacity: 0.2;
    }

    .op-xl-2-f {
        opacity: 0.2;
    }

    .op-xl-3 {
        opacity: 0.3;
    }

    .op-xl-3-f {
        opacity: 0.3;
    }

    .op-xl-4 {
        opacity: 0.4;
    }

    .op-xl-4-f {
        opacity: 0.4;
    }

    .op-xl-5 {
        opacity: 0.5;
    }

    .op-xl-5-f {
        opacity: 0.5;
    }

    .op-xl-6 {
        opacity: 0.6;
    }

    .op-xl-6-f {
        opacity: 0.6;
    }

    .op-xl-7 {
        opacity: 0.7;
    }

    .op-xl-7-f {
        opacity: 0.7;
    }

    .op-xl-8 {
        opacity: 0.8;
    }

    .op-xl-8-f {
        opacity: 0.8;
    }

    .op-xl-9 {
        opacity: 0.9;
    }

    .op-xl-9-f {
        opacity: 0.9;
    }
}

.p-0 {
    padding: 0 !important;
}

.pt-0,
.py-0 {
    padding-top: 0 !important;
}

.pe-0,
.px-0 {
    padding-right: 0 !important;
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.ps-0,
.px-0 {
    padding-left: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}

.pe-1,
.px-1 {
    padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}

.ps-1,
.px-1 {
    padding-left: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}

.pe-2,
.px-2 {
    padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}

.ps-2,
.px-2 {
    padding-left: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.pt-3,
.py-3 {
    padding-top: 1rem !important;
}

.pe-3,
.px-3 {
    padding-right: 1rem !important;
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}

.ps-3,
.px-3 {
    padding-left: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important;
}

.pe-4,
.px-4 {
    padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}

.ps-4,
.px-4 {
    padding-left: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-5,
.py-5 {
    padding-top: 3rem !important;
}

.pe-5,
.px-5 {
    padding-right: 3rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}

.ps-5,
.px-5 {
    padding-left: 3rem !important;
}

@media (min-width: 576px) {
    .p-sm-0 {
        padding: 0 !important;
    }

    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important;
    }

    .pe-sm-0,
    .px-sm-0 {
        padding-right: 0 !important;
    }

    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important;
    }

    .ps-sm-0,
    .px-sm-0 {
        padding-left: 0 !important;
    }

    .p-sm-1 {
        padding: 0.25rem !important;
    }

    .pt-sm-1,
    .py-sm-1 {
        padding-top: 0.25rem !important;
    }

    .pe-sm-1,
    .px-sm-1 {
        padding-right: 0.25rem !important;
    }

    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: 0.25rem !important;
    }

    .ps-sm-1,
    .px-sm-1 {
        padding-left: 0.25rem !important;
    }

    .p-sm-2 {
        padding: 0.5rem !important;
    }

    .pt-sm-2,
    .py-sm-2 {
        padding-top: 0.5rem !important;
    }

    .pe-sm-2,
    .px-sm-2 {
        padding-right: 0.5rem !important;
    }

    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: 0.5rem !important;
    }

    .ps-sm-2,
    .px-sm-2 {
        padding-left: 0.5rem !important;
    }

    .p-sm-3 {
        padding: 1rem !important;
    }

    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem !important;
    }

    .pe-sm-3,
    .px-sm-3 {
        padding-right: 1rem !important;
    }

    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem !important;
    }

    .ps-sm-3,
    .px-sm-3 {
        padding-left: 1rem !important;
    }

    .p-sm-4 {
        padding: 1.5rem !important;
    }

    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem !important;
    }

    .pe-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem !important;
    }

    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem !important;
    }

    .ps-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem !important;
    }

    .p-sm-5 {
        padding: 3rem !important;
    }

    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem !important;
    }

    .pe-sm-5,
    .px-sm-5 {
        padding-right: 3rem !important;
    }

    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem !important;
    }

    .ps-sm-5,
    .px-sm-5 {
        padding-left: 3rem !important;
    }
}

@media (min-width: 768px) {
    .p-md-0 {
        padding: 0 !important;
    }

    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important;
    }

    .pe-md-0,
    .px-md-0 {
        padding-right: 0 !important;
    }

    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important;
    }

    .ps-md-0,
    .px-md-0 {
        padding-left: 0 !important;
    }

    .p-md-1 {
        padding: 0.25rem !important;
    }

    .pt-md-1,
    .py-md-1 {
        padding-top: 0.25rem !important;
    }

    .pe-md-1,
    .px-md-1 {
        padding-right: 0.25rem !important;
    }

    .pb-md-1,
    .py-md-1 {
        padding-bottom: 0.25rem !important;
    }

    .ps-md-1,
    .px-md-1 {
        padding-left: 0.25rem !important;
    }

    .p-md-2 {
        padding: 0.5rem !important;
    }

    .pt-md-2,
    .py-md-2 {
        padding-top: 0.5rem !important;
    }

    .pe-md-2,
    .px-md-2 {
        padding-right: 0.5rem !important;
    }

    .pb-md-2,
    .py-md-2 {
        padding-bottom: 0.5rem !important;
    }

    .ps-md-2,
    .px-md-2 {
        padding-left: 0.5rem !important;
    }

    .p-md-3 {
        padding: 1rem !important;
    }

    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem !important;
    }

    .pe-md-3,
    .px-md-3 {
        padding-right: 1rem !important;
    }

    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem !important;
    }

    .ps-md-3,
    .px-md-3 {
        padding-left: 1rem !important;
    }

    .p-md-4 {
        padding: 1.5rem !important;
    }

    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem !important;
    }

    .pe-md-4,
    .px-md-4 {
        padding-right: 1.5rem !important;
    }

    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem !important;
    }

    .ps-md-4,
    .px-md-4 {
        padding-left: 1.5rem !important;
    }

    .p-md-5 {
        padding: 3rem !important;
    }

    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem !important;
    }

    .pe-md-5,
    .px-md-5 {
        padding-right: 3rem !important;
    }

    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem !important;
    }

    .ps-md-5,
    .px-md-5 {
        padding-left: 3rem !important;
    }
}

@media (min-width: 992px) {
    .p-lg-0 {
        padding: 0 !important;
    }

    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important;
    }

    .pe-lg-0,
    .px-lg-0 {
        padding-right: 0 !important;
    }

    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important;
    }

    .ps-lg-0,
    .px-lg-0 {
        padding-left: 0 !important;
    }

    .p-lg-1 {
        padding: 0.25rem !important;
    }

    .pt-lg-1,
    .py-lg-1 {
        padding-top: 0.25rem !important;
    }

    .pe-lg-1,
    .px-lg-1 {
        padding-right: 0.25rem !important;
    }

    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: 0.25rem !important;
    }

    .ps-lg-1,
    .px-lg-1 {
        padding-left: 0.25rem !important;
    }

    .p-lg-2 {
        padding: 0.5rem !important;
    }

    .pt-lg-2,
    .py-lg-2 {
        padding-top: 0.5rem !important;
    }

    .pe-lg-2,
    .px-lg-2 {
        padding-right: 0.5rem !important;
    }

    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: 0.5rem !important;
    }

    .ps-lg-2,
    .px-lg-2 {
        padding-left: 0.5rem !important;
    }

    .p-lg-3 {
        padding: 1rem !important;
    }

    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem !important;
    }

    .pe-lg-3,
    .px-lg-3 {
        padding-right: 1rem !important;
    }

    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem !important;
    }

    .ps-lg-3,
    .px-lg-3 {
        padding-left: 1rem !important;
    }

    .p-lg-4 {
        padding: 1.5rem !important;
    }

    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem !important;
    }

    .pe-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem !important;
    }

    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem !important;
    }

    .ps-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem !important;
    }

    .p-lg-5 {
        padding: 3rem !important;
    }

    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem !important;
    }

    .pe-lg-5,
    .px-lg-5 {
        padding-right: 3rem !important;
    }

    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem !important;
    }

    .ps-lg-5,
    .px-lg-5 {
        padding-left: 3rem !important;
    }
}

@media (min-width: 1200px) {
    .p-xl-0 {
        padding: 0 !important;
    }

    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important;
    }

    .pe-xl-0,
    .px-xl-0 {
        padding-right: 0 !important;
    }

    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important;
    }

    .ps-xl-0,
    .px-xl-0 {
        padding-left: 0 !important;
    }

    .p-xl-1 {
        padding: 0.25rem !important;
    }

    .pt-xl-1,
    .py-xl-1 {
        padding-top: 0.25rem !important;
    }

    .pe-xl-1,
    .px-xl-1 {
        padding-right: 0.25rem !important;
    }

    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: 0.25rem !important;
    }

    .ps-xl-1,
    .px-xl-1 {
        padding-left: 0.25rem !important;
    }

    .p-xl-2 {
        padding: 0.5rem !important;
    }

    .pt-xl-2,
    .py-xl-2 {
        padding-top: 0.5rem !important;
    }

    .pe-xl-2,
    .px-xl-2 {
        padding-right: 0.5rem !important;
    }

    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: 0.5rem !important;
    }

    .ps-xl-2,
    .px-xl-2 {
        padding-left: 0.5rem !important;
    }

    .p-xl-3 {
        padding: 1rem !important;
    }

    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem !important;
    }

    .pe-xl-3,
    .px-xl-3 {
        padding-right: 1rem !important;
    }

    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem !important;
    }

    .ps-xl-3,
    .px-xl-3 {
        padding-left: 1rem !important;
    }

    .p-xl-4 {
        padding: 1.5rem !important;
    }

    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem !important;
    }

    .pe-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem !important;
    }

    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem !important;
    }

    .ps-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem !important;
    }

    .p-xl-5 {
        padding: 3rem !important;
    }

    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem !important;
    }

    .pe-xl-5,
    .px-xl-5 {
        padding-right: 3rem !important;
    }

    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem !important;
    }

    .ps-xl-5,
    .px-xl-5 {
        padding-left: 3rem !important;
    }
}

/* ###### 9.6 Padding   ###### */
.pd-0 {
    padding: 0px;
}

.pd-0-f {
    padding: 0px !important;
}

.pd-y-0 {
    padding-top: 0px;
    padding-bottom: 0px;
}

.pd-y-0-f {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.pd-x-0 {
    padding-left: 0px;
    padding-right: 0px;
}

.pd-x-0-f {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.pd-t-0 {
    padding-top: 0px;
}

.pd-r-0 {
    padding-right: 0px;
}

.pd-b-0 {
    padding-bottom: 0px;
}

.pd-l-0 {
    padding-left: 0px;
}

.pd-t-0-f {
    padding-top: 0px !important;
}

.pd-r-0-f {
    padding-right: 0px !important;
}

.pd-b-0-f {
    padding-bottom: 0px !important;
}

.pd-l-0-f {
    padding-left: 0px !important;
}

.pd-1 {
    padding: 1px;
}

.pd-1-f {
    padding: 1px !important;
}

.pd-y-1 {
    padding-top: 1px;
    padding-bottom: 1px;
}

.pd-y-1-f {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
}

.pd-x-1 {
    padding-left: 1px;
    padding-right: 1px;
}

.pd-x-1-f {
    padding-left: 1px !important;
    padding-right: 1px !important;
}

.pd-t-1 {
    padding-top: 1px;
}

.pd-r-1 {
    padding-right: 1px;
}

.pd-b-1 {
    padding-bottom: 1px;
}

.pd-l-1 {
    padding-left: 1px;
}

.pd-t-1-f {
    padding-top: 1px !important;
}

.pd-r-1-f {
    padding-right: 1px !important;
}

.pd-b-1-f {
    padding-bottom: 1px !important;
}

.pd-l-1-f {
    padding-left: 1px !important;
}

.pd-2 {
    padding: 2px;
}

.pd-2-f {
    padding: 2px !important;
}

.pd-y-2 {
    padding-top: 2px;
    padding-bottom: 2px;
}

.pd-y-2-f {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

.pd-x-2 {
    padding-left: 2px;
    padding-right: 2px;
}

.pd-x-2-f {
    padding-left: 2px !important;
    padding-right: 2px !important;
}

.pd-t-2 {
    padding-top: 2px;
}

.pd-r-2 {
    padding-right: 2px;
}

.pd-b-2 {
    padding-bottom: 2px;
}

.pd-l-2 {
    padding-left: 2px;
}

.pd-t-2-f {
    padding-top: 2px !important;
}

.pd-r-2-f {
    padding-right: 2px !important;
}

.pd-b-2-f {
    padding-bottom: 2px !important;
}

.pd-l-2-f {
    padding-left: 2px !important;
}

.pd-3 {
    padding: 3px;
}

.pd-3-f {
    padding: 3px !important;
}

.pd-y-3 {
    padding-top: 3px;
    padding-bottom: 3px;
}

.pd-y-3-f {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}

.pd-x-3 {
    padding-left: 3px;
    padding-right: 3px;
}

.pd-x-3-f {
    padding-left: 3px !important;
    padding-right: 3px !important;
}

.pd-t-3 {
    padding-top: 3px;
}

.pd-r-3 {
    padding-right: 3px;
}

.pd-b-3 {
    padding-bottom: 3px;
}

.pd-l-3 {
    padding-left: 3px;
}

.pd-t-3-f {
    padding-top: 3px !important;
}

.pd-r-3-f {
    padding-right: 3px !important;
}

.pd-b-3-f {
    padding-bottom: 3px !important;
}

.pd-l-3-f {
    padding-left: 3px !important;
}

.pd-4 {
    padding: 4px;
}

.pd-4-f {
    padding: 4px !important;
}

.pd-y-4 {
    padding-top: 4px;
    padding-bottom: 4px;
}

.pd-y-4-f {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.pd-x-4 {
    padding-left: 4px;
    padding-right: 4px;
}

.pd-x-4-f {
    padding-left: 4px !important;
    padding-right: 4px !important;
}

.pd-t-4 {
    padding-top: 4px;
}

.pd-r-4 {
    padding-right: 4px;
}

.pd-b-4 {
    padding-bottom: 4px;
}

.pd-l-4 {
    padding-left: 4px;
}

.pd-t-4-f {
    padding-top: 4px !important;
}

.pd-r-4-f {
    padding-right: 4px !important;
}

.pd-b-4-f {
    padding-bottom: 4px !important;
}

.pd-l-4-f {
    padding-left: 4px !important;
}

.pd-5 {
    padding: 5px;
}

.pd-5-f {
    padding: 5px !important;
}

.pd-y-5 {
    padding-top: 5px;
    padding-bottom: 5px;
}

.pd-y-5-f {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.pd-x-5 {
    padding-left: 5px;
    padding-right: 5px;
}

.pd-x-5-f {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.pd-t-5 {
    padding-top: 5px;
}

.pd-r-5 {
    padding-right: 5px;
}

.pd-b-5 {
    padding-bottom: 5px;
}

.pd-l-5 {
    padding-left: 5px;
}

.pd-t-5-f {
    padding-top: 5px !important;
}

.pd-r-5-f {
    padding-right: 5px !important;
}

.pd-b-5-f {
    padding-bottom: 5px !important;
}

.pd-l-5-f {
    padding-left: 5px !important;
}

.pd-6 {
    padding: 6px;
}

.pd-6-f {
    padding: 6px !important;
}

.pd-y-6 {
    padding-top: 6px;
    padding-bottom: 6px;
}

.pd-y-6-f {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

.pd-x-6 {
    padding-left: 6px;
    padding-right: 6px;
}

.pd-x-6-f {
    padding-left: 6px !important;
    padding-right: 6px !important;
}

.pd-t-6 {
    padding-top: 6px !important;
}

.pd-r-6 {
    padding-right: 6px;
}

.pd-b-6 {
    padding-bottom: 6px;
}

.pd-l-6 {
    padding-left: 6px;
}

.pd-t-6-f {
    padding-top: 6px !important;
}

.pd-r-6-f {
    padding-right: 6px !important;
}

.pd-b-6-f {
    padding-bottom: 6px !important;
}

.pd-l-6-f {
    padding-left: 6px !important;
}

.pd-7 {
    padding: 7px;
}

.pd-7-f {
    padding: 7px !important;
}

.pd-y-7 {
    padding-top: 7px;
    padding-bottom: 7px;
}

.pd-y-7-f {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.pd-x-7 {
    padding-left: 7px;
    padding-right: 7px;
}

.pd-x-7-f {
    padding-left: 7px !important;
    padding-right: 7px !important;
}

.pd-t-7 {
    padding-top: 7px;
}

.pd-r-7 {
    padding-right: 7px;
}

.pd-b-7 {
    padding-bottom: 7px;
}

.pd-l-7 {
    padding-left: 7px;
}

.pd-t-7-f {
    padding-top: 7px !important;
}

.pd-r-7-f {
    padding-right: 7px !important;
}

.pd-b-7-f {
    padding-bottom: 7px !important;
}

.pd-l-7-f {
    padding-left: 7px !important;
}

.pd-8 {
    padding: 8px;
}

.pd-8-f {
    padding: 8px !important;
}

.pd-y-8 {
    padding-top: 8px;
    padding-bottom: 8px;
}

.pd-y-8-f {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.pd-x-8 {
    padding-left: 8px;
    padding-right: 8px;
}

.pd-x-8-f {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.pd-t-8 {
    padding-top: 8px;
}

.pd-r-8 {
    padding-right: 8px;
}

.pd-b-8 {
    padding-bottom: 8px;
}

.pd-l-8 {
    padding-left: 8px;
}

.pd-t-8-f {
    padding-top: 8px !important;
}

.pd-r-8-f {
    padding-right: 8px !important;
}

.pd-b-8-f {
    padding-bottom: 8px !important;
}

.pd-l-8-f {
    padding-left: 8px !important;
}

.pd-9 {
    padding: 9px;
}

.pd-9-f {
    padding: 9px !important;
}

.pd-y-9 {
    padding-top: 9px;
    padding-bottom: 9px;
}

.pd-y-9-f {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
}

.pd-x-9 {
    padding-left: 9px;
    padding-right: 9px;
}

.pd-x-9-f {
    padding-left: 9px !important;
    padding-right: 9px !important;
}

.pd-t-9 {
    padding-top: 9px;
}

.pd-r-9 {
    padding-right: 9px;
}

.pd-b-9 {
    padding-bottom: 9px;
}

.pd-l-9 {
    padding-left: 9px;
}

.pd-t-9-f {
    padding-top: 9px !important;
}

.pd-r-9-f {
    padding-right: 9px !important;
}

.pd-b-9-f {
    padding-bottom: 9px !important;
}

.pd-l-9-f {
    padding-left: 9px !important;
}

.pd-10 {
    padding: 10px;
}

.pd-10-f {
    padding: 10px !important;
}

.pd-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.pd-y-10-f {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.pd-x-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.pd-x-10-f {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.pd-t-10 {
    padding-top: 10px;
}

.pd-r-10 {
    padding-right: 10px;
}

.pd-b-10 {
    padding-bottom: 10px;
}

.pd-l-10 {
    padding-left: 10px;
}

.pd-t-10-f {
    padding-top: 10px !important;
}

.pd-r-10-f {
    padding-right: 10px !important;
}

.pd-b-10-f {
    padding-bottom: 10px !important;
}

.pd-l-10-f {
    padding-left: 10px !important;
}

.pd-15 {
    padding: 15px;
}

.pd-15-f {
    padding: 15px !important;
}

.pd-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.pd-y-15-f {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.pd-x-15 {
    padding-left: 15px;
    padding-right: 15px;
}

.pd-x-15-f {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.pd-t-15 {
    padding-top: 15px;
}

.pd-r-15 {
    padding-right: 15px;
}

.pd-b-15 {
    padding-bottom: 15px;
}

.pd-l-15 {
    padding-left: 15px;
}

.pd-t-15-f {
    padding-top: 15px !important;
}

.pd-r-15-f {
    padding-right: 15px !important;
}

.pd-b-15-f {
    padding-bottom: 15px !important;
}

.pd-l-15-f {
    padding-left: 15px !important;
}

.pd-20 {
    padding: 20px;
}

.pd-20-f {
    padding: 20px !important;
}

.pd-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.pd-y-20-f {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.pd-x-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.pd-x-20-f {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.pd-t-20 {
    padding-top: 20px;
}

.pd-r-20 {
    padding-right: 20px;
}

.pd-b-20 {
    padding-bottom: 20px;
}

.pd-l-20 {
    padding-left: 20px;
}

.pd-t-20-f {
    padding-top: 20px !important;
}

.pd-r-20-f {
    padding-right: 20px !important;
}

.pd-b-20-f {
    padding-bottom: 20px !important;
}

.pd-l-20-f {
    padding-left: 20px !important;
}

.pd-25 {
    padding: 25px;
}

.pd-25-f {
    padding: 25px !important;
}

.pd-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.pd-y-25-f {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
}

.pd-x-25 {
    padding-left: 25px;
    padding-right: 25px;
}

.pd-x-25-f {
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.pd-t-25 {
    padding-top: 25px;
}

.pd-r-25 {
    padding-right: 25px;
}

.pd-b-25 {
    padding-bottom: 25px;
}

.pd-l-25 {
    padding-left: 25px;
}

.pd-t-25-f {
    padding-top: 25px !important;
}

.pd-r-25-f {
    padding-right: 25px !important;
}

.pd-b-25-f {
    padding-bottom: 25px !important;
}

.pd-l-25-f {
    padding-left: 25px !important;
}

.pd-30 {
    padding: 30px;
}

.pd-30-f {
    padding: 30px !important;
}

.pd-y-30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.pd-y-30-f {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.pd-x-30 {
    padding-left: 30px;
    padding-right: 30px;
}

.pd-x-30-f {
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.pd-t-30 {
    padding-top: 30px;
}

.pd-r-30 {
    padding-inline-end: 30px;
}

.pd-b-30 {
    padding-bottom: 30px;
}

.pd-l-30 {
    padding-inline-start: 30px;
}

.pd-t-30-f {
    padding-top: 30px !important;
}

.pd-r-30-f {
    padding-right: 30px !important;
}

.pd-b-30-f {
    padding-bottom: 30px !important;
}

.pd-l-30-f {
    padding-left: 30px !important;
}

.pd-35 {
    padding: 35px;
}

.pd-35-f {
    padding: 35px !important;
}

.pd-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.pd-y-35-f {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
}

.pd-x-35 {
    padding-left: 35px;
    padding-right: 35px;
}

.pd-x-35-f {
    padding-left: 35px !important;
    padding-right: 35px !important;
}

.pd-t-35 {
    padding-top: 35px;
}

.pd-r-35 {
    padding-right: 35px;
}

.pd-b-35 {
    padding-bottom: 35px;
}

.pd-l-35 {
    padding-left: 35px;
}

.pd-t-35-f {
    padding-top: 35px !important;
}

.pd-r-35-f {
    padding-right: 35px !important;
}

.pd-b-35-f {
    padding-bottom: 35px !important;
}

.pd-l-35-f {
    padding-left: 35px !important;
}

.pd-40 {
    padding: 40px;
}

.pd-40-f {
    padding: 40px !important;
}

.pd-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.pd-y-40-f {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
}

.pd-x-40 {
    padding-left: 40px;
    padding-right: 40px;
}

.pd-x-40-f {
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.pd-t-40 {
    padding-top: 40px;
}

.pd-r-40 {
    padding-right: 40px;
}

.pd-b-40 {
    padding-bottom: 40px;
}

.pd-l-40 {
    padding-left: 40px;
}

.pd-t-40-f {
    padding-top: 40px !important;
}

.pd-r-40-f {
    padding-right: 40px !important;
}

.pd-b-40-f {
    padding-bottom: 40px !important;
}

.pd-l-40-f {
    padding-left: 40px !important;
}

.pd-45 {
    padding: 45px;
}

.pd-45-f {
    padding: 45px !important;
}

.pd-y-45 {
    padding-top: 45px;
    padding-bottom: 45px;
}

.pd-y-45-f {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
}

.pd-x-45 {
    padding-left: 45px;
    padding-right: 45px;
}

.pd-x-45-f {
    padding-left: 45px !important;
    padding-right: 45px !important;
}

.pd-t-45 {
    padding-top: 45px;
}

.pd-r-45 {
    padding-right: 45px;
}

.pd-b-45 {
    padding-bottom: 45px;
}

.pd-l-45 {
    padding-left: 45px;
}

.pd-t-45-f {
    padding-top: 45px !important;
}

.pd-r-45-f {
    padding-right: 45px !important;
}

.pd-b-45-f {
    padding-bottom: 45px !important;
}

.pd-l-45-f {
    padding-left: 45px !important;
}

.pd-50 {
    padding: 50px;
}

.pd-50-f {
    padding: 50px !important;
}

.pd-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.pd-y-50-f {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
}

.pd-x-50 {
    padding-left: 50px;
    padding-right: 50px;
}

.pd-x-50-f {
    padding-left: 50px !important;
    padding-right: 50px !important;
}

.pd-t-50 {
    padding-top: 50px;
}

.pd-r-50 {
    padding-inline-end: 50px;
}

.pd-b-50 {
    padding-bottom: 50px;
}

.pd-l-50 {
    padding-inline-start: 50px;
}

.pd-t-50-f {
    padding-top: 50px !important;
}

.pd-r-50-f {
    padding-right: 50px !important;
}

.pd-b-50-f {
    padding-bottom: 50px !important;
}

.pd-l-50-f {
    padding-left: 50px !important;
}

.pd-55 {
    padding: 55px;
}

.pd-55-f {
    padding: 55px !important;
}

.pd-y-55 {
    padding-top: 55px;
    padding-bottom: 55px;
}

.pd-y-55-f {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
}

.pd-x-55 {
    padding-left: 55px;
    padding-right: 55px;
}

.pd-x-55-f {
    padding-left: 55px !important;
    padding-right: 55px !important;
}

.pd-t-55 {
    padding-top: 55px;
}

.pd-r-55 {
    padding-right: 55px;
}

.pd-b-55 {
    padding-bottom: 55px;
}

.pd-l-55 {
    padding-left: 55px;
}

.pd-t-55-f {
    padding-top: 55px !important;
}

.pd-r-55-f {
    padding-right: 55px !important;
}

.pd-b-55-f {
    padding-bottom: 55px !important;
}

.pd-l-55-f {
    padding-left: 55px !important;
}

.pd-60 {
    padding: 60px;
}

.pd-60-f {
    padding: 60px !important;
}

.pd-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.pd-y-60-f {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
}

.pd-x-60 {
    padding-left: 60px;
    padding-right: 60px;
}

.pd-x-60-f {
    padding-left: 60px !important;
    padding-right: 60px !important;
}

.pd-t-60 {
    padding-top: 60px;
}

.pd-r-60 {
    padding-right: 60px;
}

.pd-b-60 {
    padding-bottom: 60px;
}

.pd-l-60 {
    padding-left: 60px;
}

.pd-t-60-f {
    padding-top: 60px !important;
}

.pd-r-60-f {
    padding-right: 60px !important;
}

.pd-b-60-f {
    padding-bottom: 60px !important;
}

.pd-l-60-f {
    padding-left: 60px !important;
}

.pd-65 {
    padding: 65px;
}

.pd-65-f {
    padding: 65px !important;
}

.pd-y-65 {
    padding-top: 65px;
    padding-bottom: 65px;
}

.pd-y-65-f {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
}

.pd-x-65 {
    padding-left: 65px;
    padding-right: 65px;
}

.pd-x-65-f {
    padding-left: 65px !important;
    padding-right: 65px !important;
}

.pd-t-65 {
    padding-top: 65px;
}

.pd-r-65 {
    padding-right: 65px;
}

.pd-b-65 {
    padding-bottom: 65px;
}

.pd-l-65 {
    padding-left: 65px;
}

.pd-t-65-f {
    padding-top: 65px !important;
}

.pd-r-65-f {
    padding-right: 65px !important;
}

.pd-b-65-f {
    padding-bottom: 65px !important;
}

.pd-l-65-f {
    padding-left: 65px !important;
}

.pd-70 {
    padding: 70px;
}

.pd-70-f {
    padding: 70px !important;
}

.pd-y-70 {
    padding-top: 70px;
    padding-bottom: 70px;
}

.pd-y-70-f {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
}

.pd-x-70 {
    padding-left: 70px;
    padding-right: 70px;
}

.pd-x-70-f {
    padding-left: 70px !important;
    padding-right: 70px !important;
}

.pd-t-70 {
    padding-top: 70px;
}

.pd-r-70 {
    padding-right: 70px;
}

.pd-b-70 {
    padding-bottom: 70px;
}

.pd-l-70 {
    padding-left: 70px;
}

.pd-t-70-f {
    padding-top: 70px !important;
}

.pd-r-70-f {
    padding-right: 70px !important;
}

.pd-b-70-f {
    padding-bottom: 70px !important;
}

.pd-l-70-f {
    padding-left: 70px !important;
}

.pd-75 {
    padding: 75px;
}

.pd-75-f {
    padding: 75px !important;
}

.pd-y-75 {
    padding-top: 75px;
    padding-bottom: 75px;
}

.pd-y-75-f {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
}

.pd-x-75 {
    padding-left: 75px;
    padding-right: 75px;
}

.pd-x-75-f {
    padding-left: 75px !important;
    padding-right: 75px !important;
}

.pd-t-75 {
    padding-top: 75px;
}

.pd-r-75 {
    padding-right: 75px;
}

.pd-b-75 {
    padding-bottom: 75px;
}

.pd-l-75 {
    padding-left: 75px;
}

.pd-t-75-f {
    padding-top: 75px !important;
}

.pd-r-75-f {
    padding-right: 75px !important;
}

.pd-b-75-f {
    padding-bottom: 75px !important;
}

.pd-l-75-f {
    padding-left: 75px !important;
}

.pd-80 {
    padding: 80px;
}

.pd-80-f {
    padding: 80px !important;
}

.pd-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.pd-y-80-f {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
}

.pd-x-80 {
    padding-left: 80px;
    padding-right: 80px;
}

.pd-x-80-f {
    padding-left: 80px !important;
    padding-right: 80px !important;
}

.pd-t-80 {
    padding-top: 80px;
}

.pd-r-80 {
    padding-right: 80px;
}

.pd-b-80 {
    padding-bottom: 80px;
}

.pd-l-80 {
    padding-left: 80px;
}

.pd-t-80-f {
    padding-top: 80px !important;
}

.pd-r-80-f {
    padding-right: 80px !important;
}

.pd-b-80-f {
    padding-bottom: 80px !important;
}

.pd-l-80-f {
    padding-left: 80px !important;
}

.pd-85 {
    padding: 85px;
}

.pd-85-f {
    padding: 85px !important;
}

.pd-y-85 {
    padding-top: 85px;
    padding-bottom: 85px;
}

.pd-y-85-f {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
}

.pd-x-85 {
    padding-left: 85px;
    padding-right: 85px;
}

.pd-x-85-f {
    padding-left: 85px !important;
    padding-right: 85px !important;
}

.pd-t-85 {
    padding-top: 85px;
}

.pd-r-85 {
    padding-right: 85px;
}

.pd-b-85 {
    padding-bottom: 85px;
}

.pd-l-85 {
    padding-left: 85px;
}

.pd-t-85-f {
    padding-top: 85px !important;
}

.pd-r-85-f {
    padding-right: 85px !important;
}

.pd-b-85-f {
    padding-bottom: 85px !important;
}

.pd-l-85-f {
    padding-left: 85px !important;
}

.pd-90 {
    padding: 90px;
}

.pd-90-f {
    padding: 90px !important;
}

.pd-y-90 {
    padding-top: 90px;
    padding-bottom: 90px;
}

.pd-y-90-f {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
}

.pd-x-90 {
    padding-left: 90px;
    padding-right: 90px;
}

.pd-x-90-f {
    padding-left: 90px !important;
    padding-right: 90px !important;
}

.pd-t-90 {
    padding-top: 90px;
}

.pd-r-90 {
    padding-right: 90px;
}

.pd-b-90 {
    padding-bottom: 90px;
}

.pd-l-90 {
    padding-left: 90px;
}

.pd-t-90-f {
    padding-top: 90px !important;
}

.pd-r-90-f {
    padding-right: 90px !important;
}

.pd-b-90-f {
    padding-bottom: 90px !important;
}

.pd-l-90-f {
    padding-left: 90px !important;
}

.pd-95 {
    padding: 95px;
}

.pd-95-f {
    padding: 95px !important;
}

.pd-y-95 {
    padding-top: 95px;
    padding-bottom: 95px;
}

.pd-y-95-f {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
}

.pd-x-95 {
    padding-left: 95px;
    padding-right: 95px;
}

.pd-x-95-f {
    padding-left: 95px !important;
    padding-right: 95px !important;
}

.pd-t-95 {
    padding-top: 95px;
}

.pd-r-95 {
    padding-right: 95px;
}

.pd-b-95 {
    padding-bottom: 95px;
}

.pd-l-95 {
    padding-left: 95px;
}

.pd-t-95-f {
    padding-top: 95px !important;
}

.pd-r-95-f {
    padding-right: 95px !important;
}

.pd-b-95-f {
    padding-bottom: 95px !important;
}

.pd-l-95-f {
    padding-left: 95px !important;
}

.pd-100 {
    padding: 100px;
}

.pd-100-f {
    padding: 100px !important;
}

.pd-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.pd-y-100-f {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
}

.pd-x-100 {
    padding-left: 100px;
    padding-right: 100px;
}

.pd-x-100-f {
    padding-left: 100px !important;
    padding-right: 100px !important;
}

.pd-t-100 {
    padding-top: 100px;
}

.pd-r-100 {
    padding-right: 100px;
}

.pd-b-100 {
    padding-bottom: 100px;
}

.pd-l-100 {
    padding-left: 100px;
}

.pd-t-100-f {
    padding-top: 100px !important;
}

.pd-r-100-f {
    padding-right: 100px !important;
}

.pd-b-100-f {
    padding-bottom: 100px !important;
}

.pd-l-100-f {
    padding-left: 100px !important;
}

.pd-110 {
    padding: 110px;
}

.pd-110-f {
    padding: 110px !important;
}

.pd-y-110 {
    padding-top: 110px;
    padding-bottom: 110px;
}

.pd-y-110-f {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
}

.pd-x-110 {
    padding-left: 110px;
    padding-right: 110px;
}

.pd-x-110-f {
    padding-left: 110px !important;
    padding-right: 110px !important;
}

.pd-t-110 {
    padding-top: 110px;
}

.pd-r-110 {
    padding-right: 110px;
}

.pd-b-110 {
    padding-bottom: 110px;
}

.pd-l-110 {
    padding-left: 110px;
}

.pd-t-110-f {
    padding-top: 110px !important;
}

.pd-r-110-f {
    padding-right: 110px !important;
}

.pd-b-110-f {
    padding-bottom: 110px !important;
}

.pd-l-110-f {
    padding-left: 110px !important;
}

.pd-120 {
    padding: 120px;
}

.pd-120-f {
    padding: 120px !important;
}

.pd-y-120 {
    padding-top: 120px;
    padding-bottom: 120px;
}

.pd-y-120-f {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
}

.pd-x-120 {
    padding-left: 120px;
    padding-right: 120px;
}

.pd-x-120-f {
    padding-left: 120px !important;
    padding-right: 120px !important;
}

.pd-t-120 {
    padding-top: 120px;
}

.pd-r-120 {
    padding-inline-end: 120px;
}

.pd-b-120 {
    padding-bottom: 120px;
}

.pd-l-120 {
    padding-inline-start: 120px;
}

.pd-t-120-f {
    padding-top: 120px !important;
}

.pd-r-120-f {
    padding-right: 120px !important;
}

.pd-b-120-f {
    padding-bottom: 120px !important;
}

.pd-l-120-f {
    padding-left: 120px !important;
}

.pd-130 {
    padding: 130px;
}

.pd-130-f {
    padding: 130px !important;
}

.pd-y-130 {
    padding-top: 130px;
    padding-bottom: 130px;
}

.pd-y-130-f {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
}

.pd-x-130 {
    padding-left: 130px;
    padding-right: 130px;
}

.pd-x-130-f {
    padding-left: 130px !important;
    padding-right: 130px !important;
}

.pd-t-130 {
    padding-top: 130px;
}

.pd-r-130 {
    padding-right: 130px;
}

.pd-b-130 {
    padding-bottom: 130px;
}

.pd-l-130 {
    padding-left: 130px;
}

.pd-t-130-f {
    padding-top: 130px !important;
}

.pd-r-130-f {
    padding-right: 130px !important;
}

.pd-b-130-f {
    padding-bottom: 130px !important;
}

.pd-l-130-f {
    padding-left: 130px !important;
}

.pd-140 {
    padding: 140px;
}

.pd-140-f {
    padding: 140px !important;
}

.pd-y-140 {
    padding-top: 140px;
    padding-bottom: 140px;
}

.pd-y-140-f {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
}

.pd-x-140 {
    padding-left: 140px;
    padding-right: 140px;
}

.pd-x-140-f {
    padding-left: 140px !important;
    padding-right: 140px !important;
}

.pd-t-140 {
    padding-top: 140px;
}

.pd-r-140 {
    padding-right: 140px;
}

.pd-b-140 {
    padding-bottom: 140px;
}

.pd-l-140 {
    padding-left: 140px;
}

.pd-t-140-f {
    padding-top: 140px !important;
}

.pd-r-140-f {
    padding-right: 140px !important;
}

.pd-b-140-f {
    padding-bottom: 140px !important;
}

.pd-l-140-f {
    padding-left: 140px !important;
}

.pd-150 {
    padding: 150px;
}

.pd-150-f {
    padding: 150px !important;
}

.pd-y-150 {
    padding-top: 150px;
    padding-bottom: 150px;
}

.pd-y-150-f {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
}

.pd-x-150 {
    padding-left: 150px;
    padding-right: 150px;
}

.pd-x-150-f {
    padding-left: 150px !important;
    padding-right: 150px !important;
}

.pd-t-150 {
    padding-top: 150px;
}

.pd-r-150 {
    padding-right: 150px;
}

.pd-b-150 {
    padding-bottom: 150px;
}

.pd-l-150 {
    padding-left: 150px;
}

.pd-t-150-f {
    padding-top: 150px !important;
}

.pd-r-150-f {
    padding-right: 150px !important;
}

.pd-b-150-f {
    padding-bottom: 150px !important;
}

.pd-l-150-f {
    padding-left: 150px !important;
}

.pd-160 {
    padding: 160px;
}

.pd-160-f {
    padding: 160px !important;
}

.pd-y-160 {
    padding-top: 160px;
    padding-bottom: 160px;
}

.pd-y-160-f {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
}

.pd-x-160 {
    padding-left: 160px;
    padding-right: 160px;
}

.pd-x-160-f {
    padding-left: 160px !important;
    padding-right: 160px !important;
}

.pd-t-160 {
    padding-top: 160px;
}

.pd-r-160 {
    padding-right: 160px;
}

.pd-b-160 {
    padding-bottom: 160px;
}

.pd-l-160 {
    padding-left: 160px;
}

.pd-t-160-f {
    padding-top: 160px !important;
}

.pd-r-160-f {
    padding-right: 160px !important;
}

.pd-b-160-f {
    padding-bottom: 160px !important;
}

.pd-l-160-f {
    padding-left: 160px !important;
}

.pd-170 {
    padding: 170px;
}

.pd-170-f {
    padding: 170px !important;
}

.pd-y-170 {
    padding-top: 170px;
    padding-bottom: 170px;
}

.pd-y-170-f {
    padding-top: 170px !important;
    padding-bottom: 170px !important;
}

.pd-x-170 {
    padding-left: 170px;
    padding-right: 170px;
}

.pd-x-170-f {
    padding-left: 170px !important;
    padding-right: 170px !important;
}

.pd-t-170 {
    padding-top: 170px;
}

.pd-r-170 {
    padding-right: 170px;
}

.pd-b-170 {
    padding-bottom: 170px;
}

.pd-l-170 {
    padding-left: 170px;
}

.pd-t-170-f {
    padding-top: 170px !important;
}

.pd-r-170-f {
    padding-right: 170px !important;
}

.pd-b-170-f {
    padding-bottom: 170px !important;
}

.pd-l-170-f {
    padding-left: 170px !important;
}

.pd-180 {
    padding: 180px;
}

.pd-180-f {
    padding: 180px !important;
}

.pd-y-180 {
    padding-top: 180px;
    padding-bottom: 180px;
}

.pd-y-180-f {
    padding-top: 180px !important;
    padding-bottom: 180px !important;
}

.pd-x-180 {
    padding-left: 180px;
    padding-right: 180px;
}

.pd-x-180-f {
    padding-left: 180px !important;
    padding-right: 180px !important;
}

.pd-t-180 {
    padding-top: 180px;
}

.pd-r-180 {
    padding-right: 180px;
}

.pd-b-180 {
    padding-bottom: 180px;
}

.pd-l-180 {
    padding-left: 180px;
}

.pd-t-180-f {
    padding-top: 180px !important;
}

.pd-r-180-f {
    padding-right: 180px !important;
}

.pd-b-180-f {
    padding-bottom: 180px !important;
}

.pd-l-180-f {
    padding-left: 180px !important;
}

.pd-190 {
    padding: 190px;
}

.pd-190-f {
    padding: 190px !important;
}

.pd-y-190 {
    padding-top: 190px;
    padding-bottom: 190px;
}

.pd-y-190-f {
    padding-top: 190px !important;
    padding-bottom: 190px !important;
}

.pd-x-190 {
    padding-left: 190px;
    padding-right: 190px;
}

.pd-x-190-f {
    padding-left: 190px !important;
    padding-right: 190px !important;
}

.pd-t-190 {
    padding-top: 190px;
}

.pd-r-190 {
    padding-right: 190px;
}

.pd-b-190 {
    padding-bottom: 190px;
}

.pd-l-190 {
    padding-left: 190px;
}

.pd-t-190-f {
    padding-top: 190px !important;
}

.pd-r-190-f {
    padding-right: 190px !important;
}

.pd-b-190-f {
    padding-bottom: 190px !important;
}

.pd-l-190-f {
    padding-left: 190px !important;
}

.pd-200 {
    padding: 200px;
}

.pd-200-f {
    padding: 200px !important;
}

.pd-y-200 {
    padding-top: 200px;
    padding-bottom: 200px;
}

.pd-y-200-f {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
}

.pd-x-200 {
    padding-left: 200px;
    padding-right: 200px;
}

.pd-x-200-f {
    padding-left: 200px !important;
    padding-right: 200px !important;
}

.pd-t-200 {
    padding-top: 200px;
}

.pd-r-200 {
    padding-right: 200px;
}

.pd-b-200 {
    padding-bottom: 200px;
}

.pd-l-200 {
    padding-left: 200px;
}

.pd-t-200-f {
    padding-top: 200px !important;
}

.pd-r-200-f {
    padding-right: 200px !important;
}

.pd-b-200-f {
    padding-bottom: 200px !important;
}

.pd-l-200-f {
    padding-left: 200px !important;
}

@media (min-width: 480px) {
    .pd-xs-0 {
        padding: 0px;
    }

    .pd-xs-0-f {
        padding: 0px !important;
    }

    .pd-xs-y-0 {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .pd-xs-y-0-f {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .pd-xs-x-0 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .pd-xs-x-0-f {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .pd-xs-t-0 {
        padding-top: 0px;
    }

    .pd-xs-r-0 {
        padding-right: 0px;
    }

    .pd-xs-b-0 {
        padding-bottom: 0px;
    }

    .pd-xs-l-0 {
        padding-left: 0px;
    }

    .pd-xs-t-0-f {
        padding-top: 0px !important;
    }

    .pd-xs-r-0-f {
        padding-right: 0px !important;
    }

    .pd-xs-b-0-f {
        padding-bottom: 0px !important;
    }

    .pd-xs-l-0-f {
        padding-left: 0px !important;
    }

    .pd-xs-1 {
        padding: 1px;
    }

    .pd-xs-1-f {
        padding: 1px !important;
    }

    .pd-xs-y-1 {
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .pd-xs-y-1-f {
        padding-top: 1px !important;
        padding-bottom: 1px !important;
    }

    .pd-xs-x-1 {
        padding-left: 1px;
        padding-right: 1px;
    }

    .pd-xs-x-1-f {
        padding-left: 1px !important;
        padding-right: 1px !important;
    }

    .pd-xs-t-1 {
        padding-top: 1px;
    }

    .pd-xs-r-1 {
        padding-right: 1px;
    }

    .pd-xs-b-1 {
        padding-bottom: 1px;
    }

    .pd-xs-l-1 {
        padding-left: 1px;
    }

    .pd-xs-t-1-f {
        padding-top: 1px !important;
    }

    .pd-xs-r-1-f {
        padding-right: 1px !important;
    }

    .pd-xs-b-1-f {
        padding-bottom: 1px !important;
    }

    .pd-xs-l-1-f {
        padding-left: 1px !important;
    }

    .pd-xs-2 {
        padding: 2px;
    }

    .pd-xs-2-f {
        padding: 2px !important;
    }

    .pd-xs-y-2 {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .pd-xs-y-2-f {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }

    .pd-xs-x-2 {
        padding-left: 2px;
        padding-right: 2px;
    }

    .pd-xs-x-2-f {
        padding-left: 2px !important;
        padding-right: 2px !important;
    }

    .pd-xs-t-2 {
        padding-top: 2px;
    }

    .pd-xs-r-2 {
        padding-right: 2px;
    }

    .pd-xs-b-2 {
        padding-bottom: 2px;
    }

    .pd-xs-l-2 {
        padding-left: 2px;
    }

    .pd-xs-t-2-f {
        padding-top: 2px !important;
    }

    .pd-xs-r-2-f {
        padding-right: 2px !important;
    }

    .pd-xs-b-2-f {
        padding-bottom: 2px !important;
    }

    .pd-xs-l-2-f {
        padding-left: 2px !important;
    }

    .pd-xs-3 {
        padding: 3px;
    }

    .pd-xs-3-f {
        padding: 3px !important;
    }

    .pd-xs-y-3 {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .pd-xs-y-3-f {
        padding-top: 3px !important;
        padding-bottom: 3px !important;
    }

    .pd-xs-x-3 {
        padding-left: 3px;
        padding-right: 3px;
    }

    .pd-xs-x-3-f {
        padding-left: 3px !important;
        padding-right: 3px !important;
    }

    .pd-xs-t-3 {
        padding-top: 3px;
    }

    .pd-xs-r-3 {
        padding-right: 3px;
    }

    .pd-xs-b-3 {
        padding-bottom: 3px;
    }

    .pd-xs-l-3 {
        padding-left: 3px;
    }

    .pd-xs-t-3-f {
        padding-top: 3px !important;
    }

    .pd-xs-r-3-f {
        padding-right: 3px !important;
    }

    .pd-xs-b-3-f {
        padding-bottom: 3px !important;
    }

    .pd-xs-l-3-f {
        padding-left: 3px !important;
    }

    .pd-xs-4 {
        padding: 4px;
    }

    .pd-xs-4-f {
        padding: 4px !important;
    }

    .pd-xs-y-4 {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .pd-xs-y-4-f {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
    }

    .pd-xs-x-4 {
        padding-left: 4px;
        padding-right: 4px;
    }

    .pd-xs-x-4-f {
        padding-left: 4px !important;
        padding-right: 4px !important;
    }

    .pd-xs-t-4 {
        padding-top: 4px;
    }

    .pd-xs-r-4 {
        padding-right: 4px;
    }

    .pd-xs-b-4 {
        padding-bottom: 4px;
    }

    .pd-xs-l-4 {
        padding-left: 4px;
    }

    .pd-xs-t-4-f {
        padding-top: 4px !important;
    }

    .pd-xs-r-4-f {
        padding-right: 4px !important;
    }

    .pd-xs-b-4-f {
        padding-bottom: 4px !important;
    }

    .pd-xs-l-4-f {
        padding-left: 4px !important;
    }

    .pd-xs-5 {
        padding: 5px;
    }

    .pd-xs-5-f {
        padding: 5px !important;
    }

    .pd-xs-y-5 {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .pd-xs-y-5-f {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }

    .pd-xs-x-5 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .pd-xs-x-5-f {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    .pd-xs-t-5 {
        padding-top: 5px;
    }

    .pd-xs-r-5 {
        padding-right: 5px;
    }

    .pd-xs-b-5 {
        padding-bottom: 5px;
    }

    .pd-xs-l-5 {
        padding-left: 5px;
    }

    .pd-xs-t-5-f {
        padding-top: 5px !important;
    }

    .pd-xs-r-5-f {
        padding-right: 5px !important;
    }

    .pd-xs-b-5-f {
        padding-bottom: 5px !important;
    }

    .pd-xs-l-5-f {
        padding-left: 5px !important;
    }

    .pd-xs-6 {
        padding: 6px;
    }

    .pd-xs-6-f {
        padding: 6px !important;
    }

    .pd-xs-y-6 {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .pd-xs-y-6-f {
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }

    .pd-xs-x-6 {
        padding-left: 6px;
        padding-right: 6px;
    }

    .pd-xs-x-6-f {
        padding-left: 6px !important;
        padding-right: 6px !important;
    }

    .pd-xs-t-6 {
        padding-top: 6px;
    }

    .pd-xs-r-6 {
        padding-right: 6px;
    }

    .pd-xs-b-6 {
        padding-bottom: 6px;
    }

    .pd-xs-l-6 {
        padding-left: 6px;
    }

    .pd-xs-t-6-f {
        padding-top: 6px !important;
    }

    .pd-xs-r-6-f {
        padding-right: 6px !important;
    }

    .pd-xs-b-6-f {
        padding-bottom: 6px !important;
    }

    .pd-xs-l-6-f {
        padding-left: 6px !important;
    }

    .pd-xs-7 {
        padding: 7px;
    }

    .pd-xs-7-f {
        padding: 7px !important;
    }

    .pd-xs-y-7 {
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .pd-xs-y-7-f {
        padding-top: 7px !important;
        padding-bottom: 7px !important;
    }

    .pd-xs-x-7 {
        padding-left: 7px;
        padding-right: 7px;
    }

    .pd-xs-x-7-f {
        padding-left: 7px !important;
        padding-right: 7px !important;
    }

    .pd-xs-t-7 {
        padding-top: 7px;
    }

    .pd-xs-r-7 {
        padding-right: 7px;
    }

    .pd-xs-b-7 {
        padding-bottom: 7px;
    }

    .pd-xs-l-7 {
        padding-left: 7px;
    }

    .pd-xs-t-7-f {
        padding-top: 7px !important;
    }

    .pd-xs-r-7-f {
        padding-right: 7px !important;
    }

    .pd-xs-b-7-f {
        padding-bottom: 7px !important;
    }

    .pd-xs-l-7-f {
        padding-left: 7px !important;
    }

    .pd-xs-8 {
        padding: 8px;
    }

    .pd-xs-8-f {
        padding: 8px !important;
    }

    .pd-xs-y-8 {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .pd-xs-y-8-f {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }

    .pd-xs-x-8 {
        padding-left: 8px;
        padding-right: 8px;
    }

    .pd-xs-x-8-f {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }

    .pd-xs-t-8 {
        padding-top: 8px;
    }

    .pd-xs-r-8 {
        padding-right: 8px;
    }

    .pd-xs-b-8 {
        padding-bottom: 8px;
    }

    .pd-xs-l-8 {
        padding-left: 8px;
    }

    .pd-xs-t-8-f {
        padding-top: 8px !important;
    }

    .pd-xs-r-8-f {
        padding-right: 8px !important;
    }

    .pd-xs-b-8-f {
        padding-bottom: 8px !important;
    }

    .pd-xs-l-8-f {
        padding-left: 8px !important;
    }

    .pd-xs-9 {
        padding: 9px;
    }

    .pd-xs-9-f {
        padding: 9px !important;
    }

    .pd-xs-y-9 {
        padding-top: 9px;
        padding-bottom: 9px;
    }

    .pd-xs-y-9-f {
        padding-top: 9px !important;
        padding-bottom: 9px !important;
    }

    .pd-xs-x-9 {
        padding-left: 9px;
        padding-right: 9px;
    }

    .pd-xs-x-9-f {
        padding-left: 9px !important;
        padding-right: 9px !important;
    }

    .pd-xs-t-9 {
        padding-top: 9px;
    }

    .pd-xs-r-9 {
        padding-right: 9px;
    }

    .pd-xs-b-9 {
        padding-bottom: 9px;
    }

    .pd-xs-l-9 {
        padding-left: 9px;
    }

    .pd-xs-t-9-f {
        padding-top: 9px !important;
    }

    .pd-xs-r-9-f {
        padding-right: 9px !important;
    }

    .pd-xs-b-9-f {
        padding-bottom: 9px !important;
    }

    .pd-xs-l-9-f {
        padding-left: 9px !important;
    }

    .pd-xs-10 {
        padding: 10px;
    }

    .pd-xs-10-f {
        padding: 10px !important;
    }

    .pd-xs-y-10 {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .pd-xs-y-10-f {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    .pd-xs-x-10 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .pd-xs-x-10-f {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .pd-xs-t-10 {
        padding-top: 10px;
    }

    .pd-xs-r-10 {
        padding-right: 10px;
    }

    .pd-xs-b-10 {
        padding-bottom: 10px;
    }

    .pd-xs-l-10 {
        padding-left: 10px;
    }

    .pd-xs-t-10-f {
        padding-top: 10px !important;
    }

    .pd-xs-r-10-f {
        padding-right: 10px !important;
    }

    .pd-xs-b-10-f {
        padding-bottom: 10px !important;
    }

    .pd-xs-l-10-f {
        padding-left: 10px !important;
    }

    .pd-xs-15 {
        padding: 15px;
    }

    .pd-xs-15-f {
        padding: 15px !important;
    }

    .pd-xs-y-15 {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .pd-xs-y-15-f {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }

    .pd-xs-x-15 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .pd-xs-x-15-f {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .pd-xs-t-15 {
        padding-top: 15px;
    }

    .pd-xs-r-15 {
        padding-right: 15px;
    }

    .pd-xs-b-15 {
        padding-bottom: 15px;
    }

    .pd-xs-l-15 {
        padding-left: 15px;
    }

    .pd-xs-t-15-f {
        padding-top: 15px !important;
    }

    .pd-xs-r-15-f {
        padding-right: 15px !important;
    }

    .pd-xs-b-15-f {
        padding-bottom: 15px !important;
    }

    .pd-xs-l-15-f {
        padding-left: 15px !important;
    }

    .pd-xs-20 {
        padding: 20px;
    }

    .pd-xs-20-f {
        padding: 20px !important;
    }

    .pd-xs-y-20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .pd-xs-y-20-f {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    .pd-xs-x-20 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .pd-xs-x-20-f {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .pd-xs-t-20 {
        padding-top: 20px;
    }

    .pd-xs-r-20 {
        padding-right: 20px;
    }

    .pd-xs-b-20 {
        padding-bottom: 20px;
    }

    .pd-xs-l-20 {
        padding-left: 20px;
    }

    .pd-xs-t-20-f {
        padding-top: 20px !important;
    }

    .pd-xs-r-20-f {
        padding-right: 20px !important;
    }

    .pd-xs-b-20-f {
        padding-bottom: 20px !important;
    }

    .pd-xs-l-20-f {
        padding-left: 20px !important;
    }

    .pd-xs-25 {
        padding: 25px;
    }

    .pd-xs-25-f {
        padding: 25px !important;
    }

    .pd-xs-y-25 {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .pd-xs-y-25-f {
        padding-top: 25px !important;
        padding-bottom: 25px !important;
    }

    .pd-xs-x-25 {
        padding-left: 25px;
        padding-right: 25px;
    }

    .pd-xs-x-25-f {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }

    .pd-xs-t-25 {
        padding-top: 25px;
    }

    .pd-xs-r-25 {
        padding-right: 25px;
    }

    .pd-xs-b-25 {
        padding-bottom: 25px;
    }

    .pd-xs-l-25 {
        padding-left: 25px;
    }

    .pd-xs-t-25-f {
        padding-top: 25px !important;
    }

    .pd-xs-r-25-f {
        padding-right: 25px !important;
    }

    .pd-xs-b-25-f {
        padding-bottom: 25px !important;
    }

    .pd-xs-l-25-f {
        padding-left: 25px !important;
    }

    .pd-xs-30 {
        padding: 30px;
    }

    .pd-xs-30-f {
        padding: 30px !important;
    }

    .pd-xs-y-30 {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .pd-xs-y-30-f {
        padding-top: 30px !important;
        padding-bottom: 30px !important;
    }

    .pd-xs-x-30 {
        padding-left: 30px;
        padding-right: 30px;
    }

    .pd-xs-x-30-f {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    .pd-xs-t-30 {
        padding-top: 30px;
    }

    .pd-xs-r-30 {
        padding-right: 30px;
    }

    .pd-xs-b-30 {
        padding-bottom: 30px;
    }

    .pd-xs-l-30 {
        padding-left: 30px;
    }

    .pd-xs-t-30-f {
        padding-top: 30px !important;
    }

    .pd-xs-r-30-f {
        padding-right: 30px !important;
    }

    .pd-xs-b-30-f {
        padding-bottom: 30px !important;
    }

    .pd-xs-l-30-f {
        padding-left: 30px !important;
    }

    .pd-xs-35 {
        padding: 35px;
    }

    .pd-xs-35-f {
        padding: 35px !important;
    }

    .pd-xs-y-35 {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .pd-xs-y-35-f {
        padding-top: 35px !important;
        padding-bottom: 35px !important;
    }

    .pd-xs-x-35 {
        padding-left: 35px;
        padding-right: 35px;
    }

    .pd-xs-x-35-f {
        padding-left: 35px !important;
        padding-right: 35px !important;
    }

    .pd-xs-t-35 {
        padding-top: 35px;
    }

    .pd-xs-r-35 {
        padding-right: 35px;
    }

    .pd-xs-b-35 {
        padding-bottom: 35px;
    }

    .pd-xs-l-35 {
        padding-left: 35px;
    }

    .pd-xs-t-35-f {
        padding-top: 35px !important;
    }

    .pd-xs-r-35-f {
        padding-right: 35px !important;
    }

    .pd-xs-b-35-f {
        padding-bottom: 35px !important;
    }

    .pd-xs-l-35-f {
        padding-left: 35px !important;
    }

    .pd-xs-40 {
        padding: 40px;
    }

    .pd-xs-40-f {
        padding: 40px !important;
    }

    .pd-xs-y-40 {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .pd-xs-y-40-f {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }

    .pd-xs-x-40 {
        padding-left: 40px;
        padding-right: 40px;
    }

    .pd-xs-x-40-f {
        padding-left: 40px !important;
        padding-right: 40px !important;
    }

    .pd-xs-t-40 {
        padding-top: 40px;
    }

    .pd-xs-r-40 {
        padding-right: 40px;
    }

    .pd-xs-b-40 {
        padding-bottom: 40px;
    }

    .pd-xs-l-40 {
        padding-left: 40px;
    }

    .pd-xs-t-40-f {
        padding-top: 40px !important;
    }

    .pd-xs-r-40-f {
        padding-right: 40px !important;
    }

    .pd-xs-b-40-f {
        padding-bottom: 40px !important;
    }

    .pd-xs-l-40-f {
        padding-left: 40px !important;
    }

    .pd-xs-45 {
        padding: 45px;
    }

    .pd-xs-45-f {
        padding: 45px !important;
    }

    .pd-xs-y-45 {
        padding-top: 45px;
        padding-bottom: 45px;
    }

    .pd-xs-y-45-f {
        padding-top: 45px !important;
        padding-bottom: 45px !important;
    }

    .pd-xs-x-45 {
        padding-left: 45px;
        padding-right: 45px;
    }

    .pd-xs-x-45-f {
        padding-left: 45px !important;
        padding-right: 45px !important;
    }

    .pd-xs-t-45 {
        padding-top: 45px;
    }

    .pd-xs-r-45 {
        padding-right: 45px;
    }

    .pd-xs-b-45 {
        padding-bottom: 45px;
    }

    .pd-xs-l-45 {
        padding-left: 45px;
    }

    .pd-xs-t-45-f {
        padding-top: 45px !important;
    }

    .pd-xs-r-45-f {
        padding-right: 45px !important;
    }

    .pd-xs-b-45-f {
        padding-bottom: 45px !important;
    }

    .pd-xs-l-45-f {
        padding-left: 45px !important;
    }

    .pd-xs-50 {
        padding: 50px;
    }

    .pd-xs-50-f {
        padding: 50px !important;
    }

    .pd-xs-y-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .pd-xs-y-50-f {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }

    .pd-xs-x-50 {
        padding-left: 50px;
        padding-right: 50px;
    }

    .pd-xs-x-50-f {
        padding-left: 50px !important;
        padding-right: 50px !important;
    }

    .pd-xs-t-50 {
        padding-top: 50px;
    }

    .pd-xs-r-50 {
        padding-right: 50px;
    }

    .pd-xs-b-50 {
        padding-bottom: 50px;
    }

    .pd-xs-l-50 {
        padding-left: 50px;
    }

    .pd-xs-t-50-f {
        padding-top: 50px !important;
    }

    .pd-xs-r-50-f {
        padding-right: 50px !important;
    }

    .pd-xs-b-50-f {
        padding-bottom: 50px !important;
    }

    .pd-xs-l-50-f {
        padding-left: 50px !important;
    }

    .pd-xs-55 {
        padding: 55px;
    }

    .pd-xs-55-f {
        padding: 55px !important;
    }

    .pd-xs-y-55 {
        padding-top: 55px;
        padding-bottom: 55px;
    }

    .pd-xs-y-55-f {
        padding-top: 55px !important;
        padding-bottom: 55px !important;
    }

    .pd-xs-x-55 {
        padding-left: 55px;
        padding-right: 55px;
    }

    .pd-xs-x-55-f {
        padding-left: 55px !important;
        padding-right: 55px !important;
    }

    .pd-xs-t-55 {
        padding-top: 55px;
    }

    .pd-xs-r-55 {
        padding-right: 55px;
    }

    .pd-xs-b-55 {
        padding-bottom: 55px;
    }

    .pd-xs-l-55 {
        padding-left: 55px;
    }

    .pd-xs-t-55-f {
        padding-top: 55px !important;
    }

    .pd-xs-r-55-f {
        padding-right: 55px !important;
    }

    .pd-xs-b-55-f {
        padding-bottom: 55px !important;
    }

    .pd-xs-l-55-f {
        padding-left: 55px !important;
    }

    .pd-xs-60 {
        padding: 60px;
    }

    .pd-xs-60-f {
        padding: 60px !important;
    }

    .pd-xs-y-60 {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .pd-xs-y-60-f {
        padding-top: 60px !important;
        padding-bottom: 60px !important;
    }

    .pd-xs-x-60 {
        padding-left: 60px;
        padding-right: 60px;
    }

    .pd-xs-x-60-f {
        padding-left: 60px !important;
        padding-right: 60px !important;
    }

    .pd-xs-t-60 {
        padding-top: 60px;
    }

    .pd-xs-r-60 {
        padding-right: 60px;
    }

    .pd-xs-b-60 {
        padding-bottom: 60px;
    }

    .pd-xs-l-60 {
        padding-left: 60px;
    }

    .pd-xs-t-60-f {
        padding-top: 60px !important;
    }

    .pd-xs-r-60-f {
        padding-right: 60px !important;
    }

    .pd-xs-b-60-f {
        padding-bottom: 60px !important;
    }

    .pd-xs-l-60-f {
        padding-left: 60px !important;
    }

    .pd-xs-65 {
        padding: 65px;
    }

    .pd-xs-65-f {
        padding: 65px !important;
    }

    .pd-xs-y-65 {
        padding-top: 65px;
        padding-bottom: 65px;
    }

    .pd-xs-y-65-f {
        padding-top: 65px !important;
        padding-bottom: 65px !important;
    }

    .pd-xs-x-65 {
        padding-left: 65px;
        padding-right: 65px;
    }

    .pd-xs-x-65-f {
        padding-left: 65px !important;
        padding-right: 65px !important;
    }

    .pd-xs-t-65 {
        padding-top: 65px;
    }

    .pd-xs-r-65 {
        padding-right: 65px;
    }

    .pd-xs-b-65 {
        padding-bottom: 65px;
    }

    .pd-xs-l-65 {
        padding-left: 65px;
    }

    .pd-xs-t-65-f {
        padding-top: 65px !important;
    }

    .pd-xs-r-65-f {
        padding-right: 65px !important;
    }

    .pd-xs-b-65-f {
        padding-bottom: 65px !important;
    }

    .pd-xs-l-65-f {
        padding-left: 65px !important;
    }

    .pd-xs-70 {
        padding: 70px;
    }

    .pd-xs-70-f {
        padding: 70px !important;
    }

    .pd-xs-y-70 {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .pd-xs-y-70-f {
        padding-top: 70px !important;
        padding-bottom: 70px !important;
    }

    .pd-xs-x-70 {
        padding-left: 70px;
        padding-right: 70px;
    }

    .pd-xs-x-70-f {
        padding-left: 70px !important;
        padding-right: 70px !important;
    }

    .pd-xs-t-70 {
        padding-top: 70px;
    }

    .pd-xs-r-70 {
        padding-right: 70px;
    }

    .pd-xs-b-70 {
        padding-bottom: 70px;
    }

    .pd-xs-l-70 {
        padding-left: 70px;
    }

    .pd-xs-t-70-f {
        padding-top: 70px !important;
    }

    .pd-xs-r-70-f {
        padding-right: 70px !important;
    }

    .pd-xs-b-70-f {
        padding-bottom: 70px !important;
    }

    .pd-xs-l-70-f {
        padding-left: 70px !important;
    }

    .pd-xs-75 {
        padding: 75px;
    }

    .pd-xs-75-f {
        padding: 75px !important;
    }

    .pd-xs-y-75 {
        padding-top: 75px;
        padding-bottom: 75px;
    }

    .pd-xs-y-75-f {
        padding-top: 75px !important;
        padding-bottom: 75px !important;
    }

    .pd-xs-x-75 {
        padding-left: 75px;
        padding-right: 75px;
    }

    .pd-xs-x-75-f {
        padding-left: 75px !important;
        padding-right: 75px !important;
    }

    .pd-xs-t-75 {
        padding-top: 75px;
    }

    .pd-xs-r-75 {
        padding-right: 75px;
    }

    .pd-xs-b-75 {
        padding-bottom: 75px;
    }

    .pd-xs-l-75 {
        padding-left: 75px;
    }

    .pd-xs-t-75-f {
        padding-top: 75px !important;
    }

    .pd-xs-r-75-f {
        padding-right: 75px !important;
    }

    .pd-xs-b-75-f {
        padding-bottom: 75px !important;
    }

    .pd-xs-l-75-f {
        padding-left: 75px !important;
    }

    .pd-xs-80 {
        padding: 80px;
    }

    .pd-xs-80-f {
        padding: 80px !important;
    }

    .pd-xs-y-80 {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .pd-xs-y-80-f {
        padding-top: 80px !important;
        padding-bottom: 80px !important;
    }

    .pd-xs-x-80 {
        padding-left: 80px;
        padding-right: 80px;
    }

    .pd-xs-x-80-f {
        padding-left: 80px !important;
        padding-right: 80px !important;
    }

    .pd-xs-t-80 {
        padding-top: 80px;
    }

    .pd-xs-r-80 {
        padding-right: 80px;
    }

    .pd-xs-b-80 {
        padding-bottom: 80px;
    }

    .pd-xs-l-80 {
        padding-left: 80px;
    }

    .pd-xs-t-80-f {
        padding-top: 80px !important;
    }

    .pd-xs-r-80-f {
        padding-right: 80px !important;
    }

    .pd-xs-b-80-f {
        padding-bottom: 80px !important;
    }

    .pd-xs-l-80-f {
        padding-left: 80px !important;
    }

    .pd-xs-85 {
        padding: 85px;
    }

    .pd-xs-85-f {
        padding: 85px !important;
    }

    .pd-xs-y-85 {
        padding-top: 85px;
        padding-bottom: 85px;
    }

    .pd-xs-y-85-f {
        padding-top: 85px !important;
        padding-bottom: 85px !important;
    }

    .pd-xs-x-85 {
        padding-left: 85px;
        padding-right: 85px;
    }

    .pd-xs-x-85-f {
        padding-left: 85px !important;
        padding-right: 85px !important;
    }

    .pd-xs-t-85 {
        padding-top: 85px;
    }

    .pd-xs-r-85 {
        padding-right: 85px;
    }

    .pd-xs-b-85 {
        padding-bottom: 85px;
    }

    .pd-xs-l-85 {
        padding-left: 85px;
    }

    .pd-xs-t-85-f {
        padding-top: 85px !important;
    }

    .pd-xs-r-85-f {
        padding-right: 85px !important;
    }

    .pd-xs-b-85-f {
        padding-bottom: 85px !important;
    }

    .pd-xs-l-85-f {
        padding-left: 85px !important;
    }

    .pd-xs-90 {
        padding: 90px;
    }

    .pd-xs-90-f {
        padding: 90px !important;
    }

    .pd-xs-y-90 {
        padding-top: 90px;
        padding-bottom: 90px;
    }

    .pd-xs-y-90-f {
        padding-top: 90px !important;
        padding-bottom: 90px !important;
    }

    .pd-xs-x-90 {
        padding-left: 90px;
        padding-right: 90px;
    }

    .pd-xs-x-90-f {
        padding-left: 90px !important;
        padding-right: 90px !important;
    }

    .pd-xs-t-90 {
        padding-top: 90px;
    }

    .pd-xs-r-90 {
        padding-right: 90px;
    }

    .pd-xs-b-90 {
        padding-bottom: 90px;
    }

    .pd-xs-l-90 {
        padding-left: 90px;
    }

    .pd-xs-t-90-f {
        padding-top: 90px !important;
    }

    .pd-xs-r-90-f {
        padding-right: 90px !important;
    }

    .pd-xs-b-90-f {
        padding-bottom: 90px !important;
    }

    .pd-xs-l-90-f {
        padding-left: 90px !important;
    }

    .pd-xs-95 {
        padding: 95px;
    }

    .pd-xs-95-f {
        padding: 95px !important;
    }

    .pd-xs-y-95 {
        padding-top: 95px;
        padding-bottom: 95px;
    }

    .pd-xs-y-95-f {
        padding-top: 95px !important;
        padding-bottom: 95px !important;
    }

    .pd-xs-x-95 {
        padding-left: 95px;
        padding-right: 95px;
    }

    .pd-xs-x-95-f {
        padding-left: 95px !important;
        padding-right: 95px !important;
    }

    .pd-xs-t-95 {
        padding-top: 95px;
    }

    .pd-xs-r-95 {
        padding-right: 95px;
    }

    .pd-xs-b-95 {
        padding-bottom: 95px;
    }

    .pd-xs-l-95 {
        padding-left: 95px;
    }

    .pd-xs-t-95-f {
        padding-top: 95px !important;
    }

    .pd-xs-r-95-f {
        padding-right: 95px !important;
    }

    .pd-xs-b-95-f {
        padding-bottom: 95px !important;
    }

    .pd-xs-l-95-f {
        padding-left: 95px !important;
    }

    .pd-xs-100 {
        padding: 100px;
    }

    .pd-xs-100-f {
        padding: 100px !important;
    }

    .pd-xs-y-100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .pd-xs-y-100-f {
        padding-top: 100px !important;
        padding-bottom: 100px !important;
    }

    .pd-xs-x-100 {
        padding-left: 100px;
        padding-right: 100px;
    }

    .pd-xs-x-100-f {
        padding-left: 100px !important;
        padding-right: 100px !important;
    }

    .pd-xs-t-100 {
        padding-top: 100px;
    }

    .pd-xs-r-100 {
        padding-right: 100px;
    }

    .pd-xs-b-100 {
        padding-bottom: 100px;
    }

    .pd-xs-l-100 {
        padding-left: 100px;
    }

    .pd-xs-t-100-f {
        padding-top: 100px !important;
    }

    .pd-xs-r-100-f {
        padding-right: 100px !important;
    }

    .pd-xs-b-100-f {
        padding-bottom: 100px !important;
    }

    .pd-xs-l-100-f {
        padding-left: 100px !important;
    }

    .pd-xs-110 {
        padding: 110px;
    }

    .pd-xs-110-f {
        padding: 110px !important;
    }

    .pd-xs-y-110 {
        padding-top: 110px;
        padding-bottom: 110px;
    }

    .pd-xs-y-110-f {
        padding-top: 110px !important;
        padding-bottom: 110px !important;
    }

    .pd-xs-x-110 {
        padding-left: 110px;
        padding-right: 110px;
    }

    .pd-xs-x-110-f {
        padding-left: 110px !important;
        padding-right: 110px !important;
    }

    .pd-xs-t-110 {
        padding-top: 110px;
    }

    .pd-xs-r-110 {
        padding-right: 110px;
    }

    .pd-xs-b-110 {
        padding-bottom: 110px;
    }

    .pd-xs-l-110 {
        padding-left: 110px;
    }

    .pd-xs-t-110-f {
        padding-top: 110px !important;
    }

    .pd-xs-r-110-f {
        padding-right: 110px !important;
    }

    .pd-xs-b-110-f {
        padding-bottom: 110px !important;
    }

    .pd-xs-l-110-f {
        padding-left: 110px !important;
    }

    .pd-xs-120 {
        padding: 120px;
    }

    .pd-xs-120-f {
        padding: 120px !important;
    }

    .pd-xs-y-120 {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .pd-xs-y-120-f {
        padding-top: 120px !important;
        padding-bottom: 120px !important;
    }

    .pd-xs-x-120 {
        padding-left: 120px;
        padding-right: 120px;
    }

    .pd-xs-x-120-f {
        padding-left: 120px !important;
        padding-right: 120px !important;
    }

    .pd-xs-t-120 {
        padding-top: 120px;
    }

    .pd-xs-r-120 {
        padding-right: 120px;
    }

    .pd-xs-b-120 {
        padding-bottom: 120px;
    }

    .pd-xs-l-120 {
        padding-left: 120px;
    }

    .pd-xs-t-120-f {
        padding-top: 120px !important;
    }

    .pd-xs-r-120-f {
        padding-right: 120px !important;
    }

    .pd-xs-b-120-f {
        padding-bottom: 120px !important;
    }

    .pd-xs-l-120-f {
        padding-left: 120px !important;
    }

    .pd-xs-130 {
        padding: 130px;
    }

    .pd-xs-130-f {
        padding: 130px !important;
    }

    .pd-xs-y-130 {
        padding-top: 130px;
        padding-bottom: 130px;
    }

    .pd-xs-y-130-f {
        padding-top: 130px !important;
        padding-bottom: 130px !important;
    }

    .pd-xs-x-130 {
        padding-left: 130px;
        padding-right: 130px;
    }

    .pd-xs-x-130-f {
        padding-left: 130px !important;
        padding-right: 130px !important;
    }

    .pd-xs-t-130 {
        padding-top: 130px;
    }

    .pd-xs-r-130 {
        padding-right: 130px;
    }

    .pd-xs-b-130 {
        padding-bottom: 130px;
    }

    .pd-xs-l-130 {
        padding-left: 130px;
    }

    .pd-xs-t-130-f {
        padding-top: 130px !important;
    }

    .pd-xs-r-130-f {
        padding-right: 130px !important;
    }

    .pd-xs-b-130-f {
        padding-bottom: 130px !important;
    }

    .pd-xs-l-130-f {
        padding-left: 130px !important;
    }

    .pd-xs-140 {
        padding: 140px;
    }

    .pd-xs-140-f {
        padding: 140px !important;
    }

    .pd-xs-y-140 {
        padding-top: 140px;
        padding-bottom: 140px;
    }

    .pd-xs-y-140-f {
        padding-top: 140px !important;
        padding-bottom: 140px !important;
    }

    .pd-xs-x-140 {
        padding-left: 140px;
        padding-right: 140px;
    }

    .pd-xs-x-140-f {
        padding-left: 140px !important;
        padding-right: 140px !important;
    }

    .pd-xs-t-140 {
        padding-top: 140px;
    }

    .pd-xs-r-140 {
        padding-right: 140px;
    }

    .pd-xs-b-140 {
        padding-bottom: 140px;
    }

    .pd-xs-l-140 {
        padding-left: 140px;
    }

    .pd-xs-t-140-f {
        padding-top: 140px !important;
    }

    .pd-xs-r-140-f {
        padding-right: 140px !important;
    }

    .pd-xs-b-140-f {
        padding-bottom: 140px !important;
    }

    .pd-xs-l-140-f {
        padding-left: 140px !important;
    }

    .pd-xs-150 {
        padding: 150px;
    }

    .pd-xs-150-f {
        padding: 150px !important;
    }

    .pd-xs-y-150 {
        padding-top: 150px;
        padding-bottom: 150px;
    }

    .pd-xs-y-150-f {
        padding-top: 150px !important;
        padding-bottom: 150px !important;
    }

    .pd-xs-x-150 {
        padding-left: 150px;
        padding-right: 150px;
    }

    .pd-xs-x-150-f {
        padding-left: 150px !important;
        padding-right: 150px !important;
    }

    .pd-xs-t-150 {
        padding-top: 150px;
    }

    .pd-xs-r-150 {
        padding-right: 150px;
    }

    .pd-xs-b-150 {
        padding-bottom: 150px;
    }

    .pd-xs-l-150 {
        padding-left: 150px;
    }

    .pd-xs-t-150-f {
        padding-top: 150px !important;
    }

    .pd-xs-r-150-f {
        padding-right: 150px !important;
    }

    .pd-xs-b-150-f {
        padding-bottom: 150px !important;
    }

    .pd-xs-l-150-f {
        padding-left: 150px !important;
    }

    .pd-xs-160 {
        padding: 160px;
    }

    .pd-xs-160-f {
        padding: 160px !important;
    }

    .pd-xs-y-160 {
        padding-top: 160px;
        padding-bottom: 160px;
    }

    .pd-xs-y-160-f {
        padding-top: 160px !important;
        padding-bottom: 160px !important;
    }

    .pd-xs-x-160 {
        padding-left: 160px;
        padding-right: 160px;
    }

    .pd-xs-x-160-f {
        padding-left: 160px !important;
        padding-right: 160px !important;
    }

    .pd-xs-t-160 {
        padding-top: 160px;
    }

    .pd-xs-r-160 {
        padding-right: 160px;
    }

    .pd-xs-b-160 {
        padding-bottom: 160px;
    }

    .pd-xs-l-160 {
        padding-left: 160px;
    }

    .pd-xs-t-160-f {
        padding-top: 160px !important;
    }

    .pd-xs-r-160-f {
        padding-right: 160px !important;
    }

    .pd-xs-b-160-f {
        padding-bottom: 160px !important;
    }

    .pd-xs-l-160-f {
        padding-left: 160px !important;
    }

    .pd-xs-170 {
        padding: 170px;
    }

    .pd-xs-170-f {
        padding: 170px !important;
    }

    .pd-xs-y-170 {
        padding-top: 170px;
        padding-bottom: 170px;
    }

    .pd-xs-y-170-f {
        padding-top: 170px !important;
        padding-bottom: 170px !important;
    }

    .pd-xs-x-170 {
        padding-left: 170px;
        padding-right: 170px;
    }

    .pd-xs-x-170-f {
        padding-left: 170px !important;
        padding-right: 170px !important;
    }

    .pd-xs-t-170 {
        padding-top: 170px;
    }

    .pd-xs-r-170 {
        padding-right: 170px;
    }

    .pd-xs-b-170 {
        padding-bottom: 170px;
    }

    .pd-xs-l-170 {
        padding-left: 170px;
    }

    .pd-xs-t-170-f {
        padding-top: 170px !important;
    }

    .pd-xs-r-170-f {
        padding-right: 170px !important;
    }

    .pd-xs-b-170-f {
        padding-bottom: 170px !important;
    }

    .pd-xs-l-170-f {
        padding-left: 170px !important;
    }

    .pd-xs-180 {
        padding: 180px;
    }

    .pd-xs-180-f {
        padding: 180px !important;
    }

    .pd-xs-y-180 {
        padding-top: 180px;
        padding-bottom: 180px;
    }

    .pd-xs-y-180-f {
        padding-top: 180px !important;
        padding-bottom: 180px !important;
    }

    .pd-xs-x-180 {
        padding-left: 180px;
        padding-right: 180px;
    }

    .pd-xs-x-180-f {
        padding-left: 180px !important;
        padding-right: 180px !important;
    }

    .pd-xs-t-180 {
        padding-top: 180px;
    }

    .pd-xs-r-180 {
        padding-right: 180px;
    }

    .pd-xs-b-180 {
        padding-bottom: 180px;
    }

    .pd-xs-l-180 {
        padding-left: 180px;
    }

    .pd-xs-t-180-f {
        padding-top: 180px !important;
    }

    .pd-xs-r-180-f {
        padding-right: 180px !important;
    }

    .pd-xs-b-180-f {
        padding-bottom: 180px !important;
    }

    .pd-xs-l-180-f {
        padding-left: 180px !important;
    }

    .pd-xs-190 {
        padding: 190px;
    }

    .pd-xs-190-f {
        padding: 190px !important;
    }

    .pd-xs-y-190 {
        padding-top: 190px;
        padding-bottom: 190px;
    }

    .pd-xs-y-190-f {
        padding-top: 190px !important;
        padding-bottom: 190px !important;
    }

    .pd-xs-x-190 {
        padding-left: 190px;
        padding-right: 190px;
    }

    .pd-xs-x-190-f {
        padding-left: 190px !important;
        padding-right: 190px !important;
    }

    .pd-xs-t-190 {
        padding-top: 190px;
    }

    .pd-xs-r-190 {
        padding-right: 190px;
    }

    .pd-xs-b-190 {
        padding-bottom: 190px;
    }

    .pd-xs-l-190 {
        padding-left: 190px;
    }

    .pd-xs-t-190-f {
        padding-top: 190px !important;
    }

    .pd-xs-r-190-f {
        padding-right: 190px !important;
    }

    .pd-xs-b-190-f {
        padding-bottom: 190px !important;
    }

    .pd-xs-l-190-f {
        padding-left: 190px !important;
    }

    .pd-xs-200 {
        padding: 200px;
    }

    .pd-xs-200-f {
        padding: 200px !important;
    }

    .pd-xs-y-200 {
        padding-top: 200px;
        padding-bottom: 200px;
    }

    .pd-xs-y-200-f {
        padding-top: 200px !important;
        padding-bottom: 200px !important;
    }

    .pd-xs-x-200 {
        padding-left: 200px;
        padding-right: 200px;
    }

    .pd-xs-x-200-f {
        padding-left: 200px !important;
        padding-right: 200px !important;
    }

    .pd-xs-t-200 {
        padding-top: 200px;
    }

    .pd-xs-r-200 {
        padding-right: 200px;
    }

    .pd-xs-b-200 {
        padding-bottom: 200px;
    }

    .pd-xs-l-200 {
        padding-left: 200px;
    }

    .pd-xs-t-200-f {
        padding-top: 200px !important;
    }

    .pd-xs-r-200-f {
        padding-right: 200px !important;
    }

    .pd-xs-b-200-f {
        padding-bottom: 200px !important;
    }

    .pd-xs-l-200-f {
        padding-left: 200px !important;
    }
}

@media (min-width: 576px) {
    .pd-sm-0 {
        padding: 0px;
    }

    .pd-sm-0-f {
        padding: 0px !important;
    }

    .pd-sm-y-0 {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .pd-sm-y-0-f {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .pd-sm-x-0 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .pd-sm-x-0-f {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .pd-sm-t-0 {
        padding-top: 0px;
    }

    .pd-sm-r-0 {
        padding-right: 0px;
    }

    .pd-sm-b-0 {
        padding-bottom: 0px;
    }

    .pd-sm-l-0 {
        padding-left: 0px;
    }

    .pd-sm-t-0-f {
        padding-top: 0px !important;
    }

    .pd-sm-r-0-f {
        padding-right: 0px !important;
    }

    .pd-sm-b-0-f {
        padding-bottom: 0px !important;
    }

    .pd-sm-l-0-f {
        padding-left: 0px !important;
    }

    .pd-sm-1 {
        padding: 1px;
    }

    .pd-sm-1-f {
        padding: 1px !important;
    }

    .pd-sm-y-1 {
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .pd-sm-y-1-f {
        padding-top: 1px !important;
        padding-bottom: 1px !important;
    }

    .pd-sm-x-1 {
        padding-left: 1px;
        padding-right: 1px;
    }

    .pd-sm-x-1-f {
        padding-left: 1px !important;
        padding-right: 1px !important;
    }

    .pd-sm-t-1 {
        padding-top: 1px;
    }

    .pd-sm-r-1 {
        padding-right: 1px;
    }

    .pd-sm-b-1 {
        padding-bottom: 1px;
    }

    .pd-sm-l-1 {
        padding-left: 1px;
    }

    .pd-sm-t-1-f {
        padding-top: 1px !important;
    }

    .pd-sm-r-1-f {
        padding-right: 1px !important;
    }

    .pd-sm-b-1-f {
        padding-bottom: 1px !important;
    }

    .pd-sm-l-1-f {
        padding-left: 1px !important;
    }

    .pd-sm-2 {
        padding: 2px;
    }

    .pd-sm-2-f {
        padding: 2px !important;
    }

    .pd-sm-y-2 {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .pd-sm-y-2-f {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }

    .pd-sm-x-2 {
        padding-left: 2px;
        padding-right: 2px;
    }

    .pd-sm-x-2-f {
        padding-left: 2px !important;
        padding-right: 2px !important;
    }

    .pd-sm-t-2 {
        padding-top: 2px;
    }

    .pd-sm-r-2 {
        padding-right: 2px;
    }

    .pd-sm-b-2 {
        padding-bottom: 2px;
    }

    .pd-sm-l-2 {
        padding-left: 2px;
    }

    .pd-sm-t-2-f {
        padding-top: 2px !important;
    }

    .pd-sm-r-2-f {
        padding-right: 2px !important;
    }

    .pd-sm-b-2-f {
        padding-bottom: 2px !important;
    }

    .pd-sm-l-2-f {
        padding-left: 2px !important;
    }

    .pd-sm-3 {
        padding: 3px;
    }

    .pd-sm-3-f {
        padding: 3px !important;
    }

    .pd-sm-y-3 {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .pd-sm-y-3-f {
        padding-top: 3px !important;
        padding-bottom: 3px !important;
    }

    .pd-sm-x-3 {
        padding-left: 3px;
        padding-right: 3px;
    }

    .pd-sm-x-3-f {
        padding-left: 3px !important;
        padding-right: 3px !important;
    }

    .pd-sm-t-3 {
        padding-top: 3px;
    }

    .pd-sm-r-3 {
        padding-right: 3px;
    }

    .pd-sm-b-3 {
        padding-bottom: 3px;
    }

    .pd-sm-l-3 {
        padding-left: 3px;
    }

    .pd-sm-t-3-f {
        padding-top: 3px !important;
    }

    .pd-sm-r-3-f {
        padding-right: 3px !important;
    }

    .pd-sm-b-3-f {
        padding-bottom: 3px !important;
    }

    .pd-sm-l-3-f {
        padding-left: 3px !important;
    }

    .pd-sm-4 {
        padding: 4px;
    }

    .pd-sm-4-f {
        padding: 4px !important;
    }

    .pd-sm-y-4 {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .pd-sm-y-4-f {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
    }

    .pd-sm-x-4 {
        padding-left: 4px;
        padding-right: 4px;
    }

    .pd-sm-x-4-f {
        padding-left: 4px !important;
        padding-right: 4px !important;
    }

    .pd-sm-t-4 {
        padding-top: 4px;
    }

    .pd-sm-r-4 {
        padding-right: 4px;
    }

    .pd-sm-b-4 {
        padding-bottom: 4px;
    }

    .pd-sm-l-4 {
        padding-left: 4px;
    }

    .pd-sm-t-4-f {
        padding-top: 4px !important;
    }

    .pd-sm-r-4-f {
        padding-right: 4px !important;
    }

    .pd-sm-b-4-f {
        padding-bottom: 4px !important;
    }

    .pd-sm-l-4-f {
        padding-left: 4px !important;
    }

    .pd-sm-5 {
        padding: 5px;
    }

    .pd-sm-5-f {
        padding: 5px !important;
    }

    .pd-sm-y-5 {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .pd-sm-y-5-f {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }

    .pd-sm-x-5 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .pd-sm-x-5-f {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    .pd-sm-t-5 {
        padding-top: 5px;
    }

    .pd-sm-r-5 {
        padding-right: 5px;
    }

    .pd-sm-b-5 {
        padding-bottom: 5px;
    }

    .pd-sm-l-5 {
        padding-left: 5px;
    }

    .pd-sm-t-5-f {
        padding-top: 5px !important;
    }

    .pd-sm-r-5-f {
        padding-right: 5px !important;
    }

    .pd-sm-b-5-f {
        padding-bottom: 5px !important;
    }

    .pd-sm-l-5-f {
        padding-left: 5px !important;
    }

    .pd-sm-6 {
        padding: 6px;
    }

    .pd-sm-6-f {
        padding: 6px !important;
    }

    .pd-sm-y-6 {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .pd-sm-y-6-f {
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }

    .pd-sm-x-6 {
        padding-left: 6px;
        padding-right: 6px;
    }

    .pd-sm-x-6-f {
        padding-left: 6px !important;
        padding-right: 6px !important;
    }

    .pd-sm-t-6 {
        padding-top: 6px;
    }

    .pd-sm-r-6 {
        padding-right: 6px;
    }

    .pd-sm-b-6 {
        padding-bottom: 6px;
    }

    .pd-sm-l-6 {
        padding-left: 6px;
    }

    .pd-sm-t-6-f {
        padding-top: 6px !important;
    }

    .pd-sm-r-6-f {
        padding-right: 6px !important;
    }

    .pd-sm-b-6-f {
        padding-bottom: 6px !important;
    }

    .pd-sm-l-6-f {
        padding-left: 6px !important;
    }

    .pd-sm-7 {
        padding: 7px;
    }

    .pd-sm-7-f {
        padding: 7px !important;
    }

    .pd-sm-y-7 {
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .pd-sm-y-7-f {
        padding-top: 7px !important;
        padding-bottom: 7px !important;
    }

    .pd-sm-x-7 {
        padding-left: 7px;
        padding-right: 7px;
    }

    .pd-sm-x-7-f {
        padding-left: 7px !important;
        padding-right: 7px !important;
    }

    .pd-sm-t-7 {
        padding-top: 7px;
    }

    .pd-sm-r-7 {
        padding-right: 7px;
    }

    .pd-sm-b-7 {
        padding-bottom: 7px;
    }

    .pd-sm-l-7 {
        padding-left: 7px;
    }

    .pd-sm-t-7-f {
        padding-top: 7px !important;
    }

    .pd-sm-r-7-f {
        padding-right: 7px !important;
    }

    .pd-sm-b-7-f {
        padding-bottom: 7px !important;
    }

    .pd-sm-l-7-f {
        padding-left: 7px !important;
    }

    .pd-sm-8 {
        padding: 8px;
    }

    .pd-sm-8-f {
        padding: 8px !important;
    }

    .pd-sm-y-8 {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .pd-sm-y-8-f {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }

    .pd-sm-x-8 {
        padding-left: 8px;
        padding-right: 8px;
    }

    .pd-sm-x-8-f {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }

    .pd-sm-t-8 {
        padding-top: 8px;
    }

    .pd-sm-r-8 {
        padding-right: 8px;
    }

    .pd-sm-b-8 {
        padding-bottom: 8px;
    }

    .pd-sm-l-8 {
        padding-left: 8px;
    }

    .pd-sm-t-8-f {
        padding-top: 8px !important;
    }

    .pd-sm-r-8-f {
        padding-right: 8px !important;
    }

    .pd-sm-b-8-f {
        padding-bottom: 8px !important;
    }

    .pd-sm-l-8-f {
        padding-left: 8px !important;
    }

    .pd-sm-9 {
        padding: 9px;
    }

    .pd-sm-9-f {
        padding: 9px !important;
    }

    .pd-sm-y-9 {
        padding-top: 9px;
        padding-bottom: 9px;
    }

    .pd-sm-y-9-f {
        padding-top: 9px !important;
        padding-bottom: 9px !important;
    }

    .pd-sm-x-9 {
        padding-left: 9px;
        padding-right: 9px;
    }

    .pd-sm-x-9-f {
        padding-left: 9px !important;
        padding-right: 9px !important;
    }

    .pd-sm-t-9 {
        padding-top: 9px;
    }

    .pd-sm-r-9 {
        padding-right: 9px;
    }

    .pd-sm-b-9 {
        padding-bottom: 9px;
    }

    .pd-sm-l-9 {
        padding-left: 9px;
    }

    .pd-sm-t-9-f {
        padding-top: 9px !important;
    }

    .pd-sm-r-9-f {
        padding-right: 9px !important;
    }

    .pd-sm-b-9-f {
        padding-bottom: 9px !important;
    }

    .pd-sm-l-9-f {
        padding-left: 9px !important;
    }

    .pd-sm-10 {
        padding: 10px;
    }

    .pd-sm-10-f {
        padding: 10px !important;
    }

    .pd-sm-y-10 {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .pd-sm-y-10-f {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    .pd-sm-x-10 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .pd-sm-x-10-f {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .pd-sm-t-10 {
        padding-top: 10px;
    }

    .pd-sm-r-10 {
        padding-right: 10px;
    }

    .pd-sm-b-10 {
        padding-bottom: 10px;
    }

    .pd-sm-l-10 {
        padding-left: 10px;
    }

    .pd-sm-t-10-f {
        padding-top: 10px !important;
    }

    .pd-sm-r-10-f {
        padding-right: 10px !important;
    }

    .pd-sm-b-10-f {
        padding-bottom: 10px !important;
    }

    .pd-sm-l-10-f {
        padding-left: 10px !important;
    }

    .pd-sm-15 {
        padding: 15px;
    }

    .pd-sm-15-f {
        padding: 15px !important;
    }

    .pd-sm-y-15 {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .pd-sm-y-15-f {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }

    .pd-sm-x-15 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .pd-sm-x-15-f {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .pd-sm-t-15 {
        padding-top: 15px;
    }

    .pd-sm-r-15 {
        padding-right: 15px;
    }

    .pd-sm-b-15 {
        padding-bottom: 15px;
    }

    .pd-sm-l-15 {
        padding-left: 15px;
    }

    .pd-sm-t-15-f {
        padding-top: 15px !important;
    }

    .pd-sm-r-15-f {
        padding-right: 15px !important;
    }

    .pd-sm-b-15-f {
        padding-bottom: 15px !important;
    }

    .pd-sm-l-15-f {
        padding-left: 15px !important;
    }

    .pd-sm-20 {
        padding: 20px;
    }

    .pd-sm-20-f {
        padding: 20px !important;
    }

    .pd-sm-y-20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .pd-sm-y-20-f {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    .pd-sm-x-20 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .pd-sm-x-20-f {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .pd-sm-t-20 {
        padding-top: 20px;
    }

    .pd-sm-r-20 {
        padding-right: 20px;
    }

    .pd-sm-b-20 {
        padding-bottom: 20px;
    }

    .pd-sm-l-20 {
        padding-left: 20px;
    }

    .pd-sm-t-20-f {
        padding-top: 20px !important;
    }

    .pd-sm-r-20-f {
        padding-right: 20px !important;
    }

    .pd-sm-b-20-f {
        padding-bottom: 20px !important;
    }

    .pd-sm-l-20-f {
        padding-left: 20px !important;
    }

    .pd-sm-25 {
        padding: 25px;
    }

    .pd-sm-25-f {
        padding: 25px !important;
    }

    .pd-sm-y-25 {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .pd-sm-y-25-f {
        padding-top: 25px !important;
        padding-bottom: 25px !important;
    }

    .pd-sm-x-25 {
        padding-left: 25px;
        padding-right: 25px;
    }

    .pd-sm-x-25-f {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }

    .pd-sm-t-25 {
        padding-top: 25px;
    }

    .pd-sm-r-25 {
        padding-right: 25px;
    }

    .pd-sm-b-25 {
        padding-bottom: 25px;
    }

    .pd-sm-l-25 {
        padding-left: 25px;
    }

    .pd-sm-t-25-f {
        padding-top: 25px !important;
    }

    .pd-sm-r-25-f {
        padding-right: 25px !important;
    }

    .pd-sm-b-25-f {
        padding-bottom: 25px !important;
    }

    .pd-sm-l-25-f {
        padding-left: 25px !important;
    }

    .pd-sm-30 {
        padding: 30px;
    }

    .pd-sm-30-f {
        padding: 30px !important;
    }

    .pd-sm-y-30 {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .pd-sm-y-30-f {
        padding-top: 30px !important;
        padding-bottom: 30px !important;
    }

    .pd-sm-x-30 {
        padding-left: 30px;
        padding-right: 30px;
    }

    .pd-sm-x-30-f {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    .pd-sm-t-30 {
        padding-top: 30px;
    }

    .pd-sm-r-30 {
        padding-right: 30px;
    }

    .pd-sm-b-30 {
        padding-bottom: 30px;
    }

    .pd-sm-l-30 {
        padding-left: 30px;
    }

    .pd-sm-t-30-f {
        padding-top: 30px !important;
    }

    .pd-sm-r-30-f {
        padding-right: 30px !important;
    }

    .pd-sm-b-30-f {
        padding-bottom: 30px !important;
    }

    .pd-sm-l-30-f {
        padding-left: 30px !important;
    }

    .pd-sm-35 {
        padding: 35px;
    }

    .pd-sm-35-f {
        padding: 35px !important;
    }

    .pd-sm-y-35 {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .pd-sm-y-35-f {
        padding-top: 35px !important;
        padding-bottom: 35px !important;
    }

    .pd-sm-x-35 {
        padding-left: 35px;
        padding-right: 35px;
    }

    .pd-sm-x-35-f {
        padding-left: 35px !important;
        padding-right: 35px !important;
    }

    .pd-sm-t-35 {
        padding-top: 35px;
    }

    .pd-sm-r-35 {
        padding-right: 35px;
    }

    .pd-sm-b-35 {
        padding-bottom: 35px;
    }

    .pd-sm-l-35 {
        padding-left: 35px;
    }

    .pd-sm-t-35-f {
        padding-top: 35px !important;
    }

    .pd-sm-r-35-f {
        padding-right: 35px !important;
    }

    .pd-sm-b-35-f {
        padding-bottom: 35px !important;
    }

    .pd-sm-l-35-f {
        padding-left: 35px !important;
    }

    .pd-sm-40 {
        padding: 40px;
    }

    .pd-sm-40-f {
        padding: 40px !important;
    }

    .pd-sm-y-40 {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .pd-sm-y-40-f {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }

    .pd-sm-x-40 {
        padding-left: 40px;
        padding-right: 40px;
    }

    .pd-sm-x-40-f {
        padding-left: 40px !important;
        padding-right: 40px !important;
    }

    .pd-sm-t-40 {
        padding-top: 40px;
    }

    .pd-sm-r-40 {
        padding-right: 40px;
    }

    .pd-sm-b-40 {
        padding-bottom: 40px;
    }

    .pd-sm-l-40 {
        padding-left: 40px;
    }

    .pd-sm-t-40-f {
        padding-top: 40px !important;
    }

    .pd-sm-r-40-f {
        padding-right: 40px !important;
    }

    .pd-sm-b-40-f {
        padding-bottom: 40px !important;
    }

    .pd-sm-l-40-f {
        padding-left: 40px !important;
    }

    .pd-sm-45 {
        padding: 45px;
    }

    .pd-sm-45-f {
        padding: 45px !important;
    }

    .pd-sm-y-45 {
        padding-top: 45px;
        padding-bottom: 45px;
    }

    .pd-sm-y-45-f {
        padding-top: 45px !important;
        padding-bottom: 45px !important;
    }

    .pd-sm-x-45 {
        padding-left: 45px;
        padding-right: 45px;
    }

    .pd-sm-x-45-f {
        padding-left: 45px !important;
        padding-right: 45px !important;
    }

    .pd-sm-t-45 {
        padding-top: 45px;
    }

    .pd-sm-r-45 {
        padding-right: 45px;
    }

    .pd-sm-b-45 {
        padding-bottom: 45px;
    }

    .pd-sm-l-45 {
        padding-left: 45px;
    }

    .pd-sm-t-45-f {
        padding-top: 45px !important;
    }

    .pd-sm-r-45-f {
        padding-right: 45px !important;
    }

    .pd-sm-b-45-f {
        padding-bottom: 45px !important;
    }

    .pd-sm-l-45-f {
        padding-left: 45px !important;
    }

    .pd-sm-50 {
        padding: 50px;
    }

    .pd-sm-50-f {
        padding: 50px !important;
    }

    .pd-sm-y-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .pd-sm-y-50-f {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }

    .pd-sm-x-50 {
        padding-left: 50px;
        padding-right: 50px;
    }

    .pd-sm-x-50-f {
        padding-left: 50px !important;
        padding-right: 50px !important;
    }

    .pd-sm-t-50 {
        padding-top: 50px;
    }

    .pd-sm-r-50 {
        padding-right: 50px;
    }

    .pd-sm-b-50 {
        padding-bottom: 50px;
    }

    .pd-sm-l-50 {
        padding-left: 50px;
    }

    .pd-sm-t-50-f {
        padding-top: 50px !important;
    }

    .pd-sm-r-50-f {
        padding-right: 50px !important;
    }

    .pd-sm-b-50-f {
        padding-bottom: 50px !important;
    }

    .pd-sm-l-50-f {
        padding-left: 50px !important;
    }

    .pd-sm-55 {
        padding: 55px;
    }

    .pd-sm-55-f {
        padding: 55px !important;
    }

    .pd-sm-y-55 {
        padding-top: 55px;
        padding-bottom: 55px;
    }

    .pd-sm-y-55-f {
        padding-top: 55px !important;
        padding-bottom: 55px !important;
    }

    .pd-sm-x-55 {
        padding-left: 55px;
        padding-right: 55px;
    }

    .pd-sm-x-55-f {
        padding-left: 55px !important;
        padding-right: 55px !important;
    }

    .pd-sm-t-55 {
        padding-top: 55px;
    }

    .pd-sm-r-55 {
        padding-right: 55px;
    }

    .pd-sm-b-55 {
        padding-bottom: 55px;
    }

    .pd-sm-l-55 {
        padding-left: 55px;
    }

    .pd-sm-t-55-f {
        padding-top: 55px !important;
    }

    .pd-sm-r-55-f {
        padding-right: 55px !important;
    }

    .pd-sm-b-55-f {
        padding-bottom: 55px !important;
    }

    .pd-sm-l-55-f {
        padding-left: 55px !important;
    }

    .pd-sm-60 {
        padding: 60px;
    }

    .pd-sm-60-f {
        padding: 60px !important;
    }

    .pd-sm-y-60 {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .pd-sm-y-60-f {
        padding-top: 60px !important;
        padding-bottom: 60px !important;
    }

    .pd-sm-x-60 {
        padding-left: 60px;
        padding-right: 60px;
    }

    .pd-sm-x-60-f {
        padding-left: 60px !important;
        padding-right: 60px !important;
    }

    .pd-sm-t-60 {
        padding-top: 60px;
    }

    .pd-sm-r-60 {
        padding-right: 60px;
    }

    .pd-sm-b-60 {
        padding-bottom: 60px;
    }

    .pd-sm-l-60 {
        padding-left: 60px;
    }

    .pd-sm-t-60-f {
        padding-top: 60px !important;
    }

    .pd-sm-r-60-f {
        padding-right: 60px !important;
    }

    .pd-sm-b-60-f {
        padding-bottom: 60px !important;
    }

    .pd-sm-l-60-f {
        padding-left: 60px !important;
    }

    .pd-sm-65 {
        padding: 65px;
    }

    .pd-sm-65-f {
        padding: 65px !important;
    }

    .pd-sm-y-65 {
        padding-top: 65px;
        padding-bottom: 65px;
    }

    .pd-sm-y-65-f {
        padding-top: 65px !important;
        padding-bottom: 65px !important;
    }

    .pd-sm-x-65 {
        padding-left: 65px;
        padding-right: 65px;
    }

    .pd-sm-x-65-f {
        padding-left: 65px !important;
        padding-right: 65px !important;
    }

    .pd-sm-t-65 {
        padding-top: 65px;
    }

    .pd-sm-r-65 {
        padding-right: 65px;
    }

    .pd-sm-b-65 {
        padding-bottom: 65px;
    }

    .pd-sm-l-65 {
        padding-left: 65px;
    }

    .pd-sm-t-65-f {
        padding-top: 65px !important;
    }

    .pd-sm-r-65-f {
        padding-right: 65px !important;
    }

    .pd-sm-b-65-f {
        padding-bottom: 65px !important;
    }

    .pd-sm-l-65-f {
        padding-left: 65px !important;
    }

    .pd-sm-70 {
        padding: 70px;
    }

    .pd-sm-70-f {
        padding: 70px !important;
    }

    .pd-sm-y-70 {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .pd-sm-y-70-f {
        padding-top: 70px !important;
        padding-bottom: 70px !important;
    }

    .pd-sm-x-70 {
        padding-left: 70px;
        padding-right: 70px;
    }

    .pd-sm-x-70-f {
        padding-left: 70px !important;
        padding-right: 70px !important;
    }

    .pd-sm-t-70 {
        padding-top: 70px;
    }

    .pd-sm-r-70 {
        padding-right: 70px;
    }

    .pd-sm-b-70 {
        padding-bottom: 70px;
    }

    .pd-sm-l-70 {
        padding-left: 70px;
    }

    .pd-sm-t-70-f {
        padding-top: 70px !important;
    }

    .pd-sm-r-70-f {
        padding-right: 70px !important;
    }

    .pd-sm-b-70-f {
        padding-bottom: 70px !important;
    }

    .pd-sm-l-70-f {
        padding-left: 70px !important;
    }

    .pd-sm-75 {
        padding: 75px;
    }

    .pd-sm-75-f {
        padding: 75px !important;
    }

    .pd-sm-y-75 {
        padding-top: 75px;
        padding-bottom: 75px;
    }

    .pd-sm-y-75-f {
        padding-top: 75px !important;
        padding-bottom: 75px !important;
    }

    .pd-sm-x-75 {
        padding-left: 75px;
        padding-right: 75px;
    }

    .pd-sm-x-75-f {
        padding-left: 75px !important;
        padding-right: 75px !important;
    }

    .pd-sm-t-75 {
        padding-top: 75px;
    }

    .pd-sm-r-75 {
        padding-right: 75px;
    }

    .pd-sm-b-75 {
        padding-bottom: 75px;
    }

    .pd-sm-l-75 {
        padding-left: 75px;
    }

    .pd-sm-t-75-f {
        padding-top: 75px !important;
    }

    .pd-sm-r-75-f {
        padding-right: 75px !important;
    }

    .pd-sm-b-75-f {
        padding-bottom: 75px !important;
    }

    .pd-sm-l-75-f {
        padding-left: 75px !important;
    }

    .pd-sm-80 {
        padding: 80px;
    }

    .pd-sm-80-f {
        padding: 80px !important;
    }

    .pd-sm-y-80 {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .pd-sm-y-80-f {
        padding-top: 80px !important;
        padding-bottom: 80px !important;
    }

    .pd-sm-x-80 {
        padding-left: 80px;
        padding-right: 80px;
    }

    .pd-sm-x-80-f {
        padding-left: 80px !important;
        padding-right: 80px !important;
    }

    .pd-sm-t-80 {
        padding-top: 80px;
    }

    .pd-sm-r-80 {
        padding-right: 80px;
    }

    .pd-sm-b-80 {
        padding-bottom: 80px;
    }

    .pd-sm-l-80 {
        padding-left: 80px;
    }

    .pd-sm-t-80-f {
        padding-top: 80px !important;
    }

    .pd-sm-r-80-f {
        padding-right: 80px !important;
    }

    .pd-sm-b-80-f {
        padding-bottom: 80px !important;
    }

    .pd-sm-l-80-f {
        padding-left: 80px !important;
    }

    .pd-sm-85 {
        padding: 85px;
    }

    .pd-sm-85-f {
        padding: 85px !important;
    }

    .pd-sm-y-85 {
        padding-top: 85px;
        padding-bottom: 85px;
    }

    .pd-sm-y-85-f {
        padding-top: 85px !important;
        padding-bottom: 85px !important;
    }

    .pd-sm-x-85 {
        padding-left: 85px;
        padding-right: 85px;
    }

    .pd-sm-x-85-f {
        padding-left: 85px !important;
        padding-right: 85px !important;
    }

    .pd-sm-t-85 {
        padding-top: 85px;
    }

    .pd-sm-r-85 {
        padding-right: 85px;
    }

    .pd-sm-b-85 {
        padding-bottom: 85px;
    }

    .pd-sm-l-85 {
        padding-left: 85px;
    }

    .pd-sm-t-85-f {
        padding-top: 85px !important;
    }

    .pd-sm-r-85-f {
        padding-right: 85px !important;
    }

    .pd-sm-b-85-f {
        padding-bottom: 85px !important;
    }

    .pd-sm-l-85-f {
        padding-left: 85px !important;
    }

    .pd-sm-90 {
        padding: 90px;
    }

    .pd-sm-90-f {
        padding: 90px !important;
    }

    .pd-sm-y-90 {
        padding-top: 90px;
        padding-bottom: 90px;
    }

    .pd-sm-y-90-f {
        padding-top: 90px !important;
        padding-bottom: 90px !important;
    }

    .pd-sm-x-90 {
        padding-left: 90px;
        padding-right: 90px;
    }

    .pd-sm-x-90-f {
        padding-left: 90px !important;
        padding-right: 90px !important;
    }

    .pd-sm-t-90 {
        padding-top: 90px;
    }

    .pd-sm-r-90 {
        padding-right: 90px;
    }

    .pd-sm-b-90 {
        padding-bottom: 90px;
    }

    .pd-sm-l-90 {
        padding-left: 90px;
    }

    .pd-sm-t-90-f {
        padding-top: 90px !important;
    }

    .pd-sm-r-90-f {
        padding-right: 90px !important;
    }

    .pd-sm-b-90-f {
        padding-bottom: 90px !important;
    }

    .pd-sm-l-90-f {
        padding-left: 90px !important;
    }

    .pd-sm-95 {
        padding: 95px;
    }

    .pd-sm-95-f {
        padding: 95px !important;
    }

    .pd-sm-y-95 {
        padding-top: 95px;
        padding-bottom: 95px;
    }

    .pd-sm-y-95-f {
        padding-top: 95px !important;
        padding-bottom: 95px !important;
    }

    .pd-sm-x-95 {
        padding-left: 95px;
        padding-right: 95px;
    }

    .pd-sm-x-95-f {
        padding-left: 95px !important;
        padding-right: 95px !important;
    }

    .pd-sm-t-95 {
        padding-top: 95px;
    }

    .pd-sm-r-95 {
        padding-right: 95px;
    }

    .pd-sm-b-95 {
        padding-bottom: 95px;
    }

    .pd-sm-l-95 {
        padding-left: 95px;
    }

    .pd-sm-t-95-f {
        padding-top: 95px !important;
    }

    .pd-sm-r-95-f {
        padding-right: 95px !important;
    }

    .pd-sm-b-95-f {
        padding-bottom: 95px !important;
    }

    .pd-sm-l-95-f {
        padding-left: 95px !important;
    }

    .pd-sm-100 {
        padding: 100px;
    }

    .pd-sm-100-f {
        padding: 100px !important;
    }

    .pd-sm-y-100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .pd-sm-y-100-f {
        padding-top: 100px !important;
        padding-bottom: 100px !important;
    }

    .pd-sm-x-100 {
        padding-left: 100px;
        padding-right: 100px;
    }

    .pd-sm-x-100-f {
        padding-left: 100px !important;
        padding-right: 100px !important;
    }

    .pd-sm-t-100 {
        padding-top: 100px;
    }

    .pd-sm-r-100 {
        padding-right: 100px;
    }

    .pd-sm-b-100 {
        padding-bottom: 100px;
    }

    .pd-sm-l-100 {
        padding-left: 100px;
    }

    .pd-sm-t-100-f {
        padding-top: 100px !important;
    }

    .pd-sm-r-100-f {
        padding-right: 100px !important;
    }

    .pd-sm-b-100-f {
        padding-bottom: 100px !important;
    }

    .pd-sm-l-100-f {
        padding-left: 100px !important;
    }

    .pd-sm-110 {
        padding: 110px;
    }

    .pd-sm-110-f {
        padding: 110px !important;
    }

    .pd-sm-y-110 {
        padding-top: 110px;
        padding-bottom: 110px;
    }

    .pd-sm-y-110-f {
        padding-top: 110px !important;
        padding-bottom: 110px !important;
    }

    .pd-sm-x-110 {
        padding-left: 110px;
        padding-right: 110px;
    }

    .pd-sm-x-110-f {
        padding-left: 110px !important;
        padding-right: 110px !important;
    }

    .pd-sm-t-110 {
        padding-top: 110px;
    }

    .pd-sm-r-110 {
        padding-right: 110px;
    }

    .pd-sm-b-110 {
        padding-bottom: 110px;
    }

    .pd-sm-l-110 {
        padding-left: 110px;
    }

    .pd-sm-t-110-f {
        padding-top: 110px !important;
    }

    .pd-sm-r-110-f {
        padding-right: 110px !important;
    }

    .pd-sm-b-110-f {
        padding-bottom: 110px !important;
    }

    .pd-sm-l-110-f {
        padding-left: 110px !important;
    }

    .pd-sm-120 {
        padding: 120px;
    }

    .pd-sm-120-f {
        padding: 120px !important;
    }

    .pd-sm-y-120 {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .pd-sm-y-120-f {
        padding-top: 120px !important;
        padding-bottom: 120px !important;
    }

    .pd-sm-x-120 {
        padding-left: 120px;
        padding-right: 120px;
    }

    .pd-sm-x-120-f {
        padding-left: 120px !important;
        padding-right: 120px !important;
    }

    .pd-sm-t-120 {
        padding-top: 120px;
    }

    .pd-sm-r-120 {
        padding-right: 120px;
    }

    .pd-sm-b-120 {
        padding-bottom: 120px;
    }

    .pd-sm-l-120 {
        padding-left: 120px;
    }

    .pd-sm-t-120-f {
        padding-top: 120px !important;
    }

    .pd-sm-r-120-f {
        padding-right: 120px !important;
    }

    .pd-sm-b-120-f {
        padding-bottom: 120px !important;
    }

    .pd-sm-l-120-f {
        padding-left: 120px !important;
    }

    .pd-sm-130 {
        padding: 130px;
    }

    .pd-sm-130-f {
        padding: 130px !important;
    }

    .pd-sm-y-130 {
        padding-top: 130px;
        padding-bottom: 130px;
    }

    .pd-sm-y-130-f {
        padding-top: 130px !important;
        padding-bottom: 130px !important;
    }

    .pd-sm-x-130 {
        padding-left: 130px;
        padding-right: 130px;
    }

    .pd-sm-x-130-f {
        padding-left: 130px !important;
        padding-right: 130px !important;
    }

    .pd-sm-t-130 {
        padding-top: 130px;
    }

    .pd-sm-r-130 {
        padding-right: 130px;
    }

    .pd-sm-b-130 {
        padding-bottom: 130px;
    }

    .pd-sm-l-130 {
        padding-left: 130px;
    }

    .pd-sm-t-130-f {
        padding-top: 130px !important;
    }

    .pd-sm-r-130-f {
        padding-right: 130px !important;
    }

    .pd-sm-b-130-f {
        padding-bottom: 130px !important;
    }

    .pd-sm-l-130-f {
        padding-left: 130px !important;
    }

    .pd-sm-140 {
        padding: 140px;
    }

    .pd-sm-140-f {
        padding: 140px !important;
    }

    .pd-sm-y-140 {
        padding-top: 140px;
        padding-bottom: 140px;
    }

    .pd-sm-y-140-f {
        padding-top: 140px !important;
        padding-bottom: 140px !important;
    }

    .pd-sm-x-140 {
        padding-left: 140px;
        padding-right: 140px;
    }

    .pd-sm-x-140-f {
        padding-left: 140px !important;
        padding-right: 140px !important;
    }

    .pd-sm-t-140 {
        padding-top: 140px;
    }

    .pd-sm-r-140 {
        padding-right: 140px;
    }

    .pd-sm-b-140 {
        padding-bottom: 140px;
    }

    .pd-sm-l-140 {
        padding-left: 140px;
    }

    .pd-sm-t-140-f {
        padding-top: 140px !important;
    }

    .pd-sm-r-140-f {
        padding-right: 140px !important;
    }

    .pd-sm-b-140-f {
        padding-bottom: 140px !important;
    }

    .pd-sm-l-140-f {
        padding-left: 140px !important;
    }

    .pd-sm-150 {
        padding: 150px;
    }

    .pd-sm-150-f {
        padding: 150px !important;
    }

    .pd-sm-y-150 {
        padding-top: 150px;
        padding-bottom: 150px;
    }

    .pd-sm-y-150-f {
        padding-top: 150px !important;
        padding-bottom: 150px !important;
    }

    .pd-sm-x-150 {
        padding-left: 150px;
        padding-right: 150px;
    }

    .pd-sm-x-150-f {
        padding-left: 150px !important;
        padding-right: 150px !important;
    }

    .pd-sm-t-150 {
        padding-top: 150px;
    }

    .pd-sm-r-150 {
        padding-right: 150px;
    }

    .pd-sm-b-150 {
        padding-bottom: 150px;
    }

    .pd-sm-l-150 {
        padding-left: 150px;
    }

    .pd-sm-t-150-f {
        padding-top: 150px !important;
    }

    .pd-sm-r-150-f {
        padding-right: 150px !important;
    }

    .pd-sm-b-150-f {
        padding-bottom: 150px !important;
    }

    .pd-sm-l-150-f {
        padding-left: 150px !important;
    }

    .pd-sm-160 {
        padding: 160px;
    }

    .pd-sm-160-f {
        padding: 160px !important;
    }

    .pd-sm-y-160 {
        padding-top: 160px;
        padding-bottom: 160px;
    }

    .pd-sm-y-160-f {
        padding-top: 160px !important;
        padding-bottom: 160px !important;
    }

    .pd-sm-x-160 {
        padding-left: 160px;
        padding-right: 160px;
    }

    .pd-sm-x-160-f {
        padding-left: 160px !important;
        padding-right: 160px !important;
    }

    .pd-sm-t-160 {
        padding-top: 160px;
    }

    .pd-sm-r-160 {
        padding-right: 160px;
    }

    .pd-sm-b-160 {
        padding-bottom: 160px;
    }

    .pd-sm-l-160 {
        padding-left: 160px;
    }

    .pd-sm-t-160-f {
        padding-top: 160px !important;
    }

    .pd-sm-r-160-f {
        padding-right: 160px !important;
    }

    .pd-sm-b-160-f {
        padding-bottom: 160px !important;
    }

    .pd-sm-l-160-f {
        padding-left: 160px !important;
    }

    .pd-sm-170 {
        padding: 170px;
    }

    .pd-sm-170-f {
        padding: 170px !important;
    }

    .pd-sm-y-170 {
        padding-top: 170px;
        padding-bottom: 170px;
    }

    .pd-sm-y-170-f {
        padding-top: 170px !important;
        padding-bottom: 170px !important;
    }

    .pd-sm-x-170 {
        padding-left: 170px;
        padding-right: 170px;
    }

    .pd-sm-x-170-f {
        padding-left: 170px !important;
        padding-right: 170px !important;
    }

    .pd-sm-t-170 {
        padding-top: 170px;
    }

    .pd-sm-r-170 {
        padding-right: 170px;
    }

    .pd-sm-b-170 {
        padding-bottom: 170px;
    }

    .pd-sm-l-170 {
        padding-left: 170px;
    }

    .pd-sm-t-170-f {
        padding-top: 170px !important;
    }

    .pd-sm-r-170-f {
        padding-right: 170px !important;
    }

    .pd-sm-b-170-f {
        padding-bottom: 170px !important;
    }

    .pd-sm-l-170-f {
        padding-left: 170px !important;
    }

    .pd-sm-180 {
        padding: 180px;
    }

    .pd-sm-180-f {
        padding: 180px !important;
    }

    .pd-sm-y-180 {
        padding-top: 180px;
        padding-bottom: 180px;
    }

    .pd-sm-y-180-f {
        padding-top: 180px !important;
        padding-bottom: 180px !important;
    }

    .pd-sm-x-180 {
        padding-left: 180px;
        padding-right: 180px;
    }

    .pd-sm-x-180-f {
        padding-left: 180px !important;
        padding-right: 180px !important;
    }

    .pd-sm-t-180 {
        padding-top: 180px;
    }

    .pd-sm-r-180 {
        padding-right: 180px;
    }

    .pd-sm-b-180 {
        padding-bottom: 180px;
    }

    .pd-sm-l-180 {
        padding-left: 180px;
    }

    .pd-sm-t-180-f {
        padding-top: 180px !important;
    }

    .pd-sm-r-180-f {
        padding-right: 180px !important;
    }

    .pd-sm-b-180-f {
        padding-bottom: 180px !important;
    }

    .pd-sm-l-180-f {
        padding-left: 180px !important;
    }

    .pd-sm-190 {
        padding: 190px;
    }

    .pd-sm-190-f {
        padding: 190px !important;
    }

    .pd-sm-y-190 {
        padding-top: 190px;
        padding-bottom: 190px;
    }

    .pd-sm-y-190-f {
        padding-top: 190px !important;
        padding-bottom: 190px !important;
    }

    .pd-sm-x-190 {
        padding-left: 190px;
        padding-right: 190px;
    }

    .pd-sm-x-190-f {
        padding-left: 190px !important;
        padding-right: 190px !important;
    }

    .pd-sm-t-190 {
        padding-top: 190px;
    }

    .pd-sm-r-190 {
        padding-right: 190px;
    }

    .pd-sm-b-190 {
        padding-bottom: 190px;
    }

    .pd-sm-l-190 {
        padding-left: 190px;
    }

    .pd-sm-t-190-f {
        padding-top: 190px !important;
    }

    .pd-sm-r-190-f {
        padding-right: 190px !important;
    }

    .pd-sm-b-190-f {
        padding-bottom: 190px !important;
    }

    .pd-sm-l-190-f {
        padding-left: 190px !important;
    }

    .pd-sm-200 {
        padding: 200px;
    }

    .pd-sm-200-f {
        padding: 200px !important;
    }

    .pd-sm-y-200 {
        padding-top: 200px;
        padding-bottom: 200px;
    }

    .pd-sm-y-200-f {
        padding-top: 200px !important;
        padding-bottom: 200px !important;
    }

    .pd-sm-x-200 {
        padding-left: 200px;
        padding-right: 200px;
    }

    .pd-sm-x-200-f {
        padding-left: 200px !important;
        padding-right: 200px !important;
    }

    .pd-sm-t-200 {
        padding-top: 200px;
    }

    .pd-sm-r-200 {
        padding-right: 200px;
    }

    .pd-sm-b-200 {
        padding-bottom: 200px;
    }

    .pd-sm-l-200 {
        padding-left: 200px;
    }

    .pd-sm-t-200-f {
        padding-top: 200px !important;
    }

    .pd-sm-r-200-f {
        padding-right: 200px !important;
    }

    .pd-sm-b-200-f {
        padding-bottom: 200px !important;
    }

    .pd-sm-l-200-f {
        padding-left: 200px !important;
    }
}

@media (min-width: 768px) {
    .pd-md-0 {
        padding: 0px;
    }

    .pd-md-0-f {
        padding: 0px !important;
    }

    .pd-md-y-0 {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .pd-md-y-0-f {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .pd-md-x-0 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .pd-md-x-0-f {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .pd-md-t-0 {
        padding-top: 0px;
    }

    .pd-md-r-0 {
        padding-right: 0px;
    }

    .pd-md-b-0 {
        padding-bottom: 0px;
    }

    .pd-md-l-0 {
        padding-left: 0px;
    }

    .pd-md-t-0-f {
        padding-top: 0px !important;
    }

    .pd-md-r-0-f {
        padding-right: 0px !important;
    }

    .pd-md-b-0-f {
        padding-bottom: 0px !important;
    }

    .pd-md-l-0-f {
        padding-left: 0px !important;
    }

    .pd-md-1 {
        padding: 1px;
    }

    .pd-md-1-f {
        padding: 1px !important;
    }

    .pd-md-y-1 {
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .pd-md-y-1-f {
        padding-top: 1px !important;
        padding-bottom: 1px !important;
    }

    .pd-md-x-1 {
        padding-left: 1px;
        padding-right: 1px;
    }

    .pd-md-x-1-f {
        padding-left: 1px !important;
        padding-right: 1px !important;
    }

    .pd-md-t-1 {
        padding-top: 1px;
    }

    .pd-md-r-1 {
        padding-right: 1px;
    }

    .pd-md-b-1 {
        padding-bottom: 1px;
    }

    .pd-md-l-1 {
        padding-left: 1px;
    }

    .pd-md-t-1-f {
        padding-top: 1px !important;
    }

    .pd-md-r-1-f {
        padding-right: 1px !important;
    }

    .pd-md-b-1-f {
        padding-bottom: 1px !important;
    }

    .pd-md-l-1-f {
        padding-left: 1px !important;
    }

    .pd-md-2 {
        padding: 2px;
    }

    .pd-md-2-f {
        padding: 2px !important;
    }

    .pd-md-y-2 {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .pd-md-y-2-f {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }

    .pd-md-x-2 {
        padding-left: 2px;
        padding-right: 2px;
    }

    .pd-md-x-2-f {
        padding-left: 2px !important;
        padding-right: 2px !important;
    }

    .pd-md-t-2 {
        padding-top: 2px;
    }

    .pd-md-r-2 {
        padding-right: 2px;
    }

    .pd-md-b-2 {
        padding-bottom: 2px;
    }

    .pd-md-l-2 {
        padding-left: 2px;
    }

    .pd-md-t-2-f {
        padding-top: 2px !important;
    }

    .pd-md-r-2-f {
        padding-right: 2px !important;
    }

    .pd-md-b-2-f {
        padding-bottom: 2px !important;
    }

    .pd-md-l-2-f {
        padding-left: 2px !important;
    }

    .pd-md-3 {
        padding: 3px;
    }

    .pd-md-3-f {
        padding: 3px !important;
    }

    .pd-md-y-3 {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .pd-md-y-3-f {
        padding-top: 3px !important;
        padding-bottom: 3px !important;
    }

    .pd-md-x-3 {
        padding-left: 3px;
        padding-right: 3px;
    }

    .pd-md-x-3-f {
        padding-left: 3px !important;
        padding-right: 3px !important;
    }

    .pd-md-t-3 {
        padding-top: 3px;
    }

    .pd-md-r-3 {
        padding-right: 3px;
    }

    .pd-md-b-3 {
        padding-bottom: 3px;
    }

    .pd-md-l-3 {
        padding-left: 3px;
    }

    .pd-md-t-3-f {
        padding-top: 3px !important;
    }

    .pd-md-r-3-f {
        padding-right: 3px !important;
    }

    .pd-md-b-3-f {
        padding-bottom: 3px !important;
    }

    .pd-md-l-3-f {
        padding-left: 3px !important;
    }

    .pd-md-4 {
        padding: 4px;
    }

    .pd-md-4-f {
        padding: 4px !important;
    }

    .pd-md-y-4 {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .pd-md-y-4-f {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
    }

    .pd-md-x-4 {
        padding-left: 4px;
        padding-right: 4px;
    }

    .pd-md-x-4-f {
        padding-left: 4px !important;
        padding-right: 4px !important;
    }

    .pd-md-t-4 {
        padding-top: 4px;
    }

    .pd-md-r-4 {
        padding-right: 4px;
    }

    .pd-md-b-4 {
        padding-bottom: 4px;
    }

    .pd-md-l-4 {
        padding-left: 4px;
    }

    .pd-md-t-4-f {
        padding-top: 4px !important;
    }

    .pd-md-r-4-f {
        padding-right: 4px !important;
    }

    .pd-md-b-4-f {
        padding-bottom: 4px !important;
    }

    .pd-md-l-4-f {
        padding-left: 4px !important;
    }

    .pd-md-5 {
        padding: 5px;
    }

    .pd-md-5-f {
        padding: 5px !important;
    }

    .pd-md-y-5 {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .pd-md-y-5-f {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }

    .pd-md-x-5 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .pd-md-x-5-f {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    .pd-md-t-5 {
        padding-top: 5px;
    }

    .pd-md-r-5 {
        padding-right: 5px;
    }

    .pd-md-b-5 {
        padding-bottom: 5px;
    }

    .pd-md-l-5 {
        padding-left: 5px;
    }

    .pd-md-t-5-f {
        padding-top: 5px !important;
    }

    .pd-md-r-5-f {
        padding-right: 5px !important;
    }

    .pd-md-b-5-f {
        padding-bottom: 5px !important;
    }

    .pd-md-l-5-f {
        padding-left: 5px !important;
    }

    .pd-md-6 {
        padding: 6px;
    }

    .pd-md-6-f {
        padding: 6px !important;
    }

    .pd-md-y-6 {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .pd-md-y-6-f {
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }

    .pd-md-x-6 {
        padding-left: 6px;
        padding-right: 6px;
    }

    .pd-md-x-6-f {
        padding-left: 6px !important;
        padding-right: 6px !important;
    }

    .pd-md-t-6 {
        padding-top: 6px;
    }

    .pd-md-r-6 {
        padding-right: 6px;
    }

    .pd-md-b-6 {
        padding-bottom: 6px;
    }

    .pd-md-l-6 {
        padding-left: 6px;
    }

    .pd-md-t-6-f {
        padding-top: 6px !important;
    }

    .pd-md-r-6-f {
        padding-right: 6px !important;
    }

    .pd-md-b-6-f {
        padding-bottom: 6px !important;
    }

    .pd-md-l-6-f {
        padding-left: 6px !important;
    }

    .pd-md-7 {
        padding: 7px;
    }

    .pd-md-7-f {
        padding: 7px !important;
    }

    .pd-md-y-7 {
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .pd-md-y-7-f {
        padding-top: 7px !important;
        padding-bottom: 7px !important;
    }

    .pd-md-x-7 {
        padding-left: 7px;
        padding-right: 7px;
    }

    .pd-md-x-7-f {
        padding-left: 7px !important;
        padding-right: 7px !important;
    }

    .pd-md-t-7 {
        padding-top: 7px;
    }

    .pd-md-r-7 {
        padding-right: 7px;
    }

    .pd-md-b-7 {
        padding-bottom: 7px;
    }

    .pd-md-l-7 {
        padding-left: 7px;
    }

    .pd-md-t-7-f {
        padding-top: 7px !important;
    }

    .pd-md-r-7-f {
        padding-right: 7px !important;
    }

    .pd-md-b-7-f {
        padding-bottom: 7px !important;
    }

    .pd-md-l-7-f {
        padding-left: 7px !important;
    }

    .pd-md-8 {
        padding: 8px;
    }

    .pd-md-8-f {
        padding: 8px !important;
    }

    .pd-md-y-8 {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .pd-md-y-8-f {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }

    .pd-md-x-8 {
        padding-left: 8px;
        padding-right: 8px;
    }

    .pd-md-x-8-f {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }

    .pd-md-t-8 {
        padding-top: 8px;
    }

    .pd-md-r-8 {
        padding-right: 8px;
    }

    .pd-md-b-8 {
        padding-bottom: 8px;
    }

    .pd-md-l-8 {
        padding-left: 8px;
    }

    .pd-md-t-8-f {
        padding-top: 8px !important;
    }

    .pd-md-r-8-f {
        padding-right: 8px !important;
    }

    .pd-md-b-8-f {
        padding-bottom: 8px !important;
    }

    .pd-md-l-8-f {
        padding-left: 8px !important;
    }

    .pd-md-9 {
        padding: 9px;
    }

    .pd-md-9-f {
        padding: 9px !important;
    }

    .pd-md-y-9 {
        padding-top: 9px;
        padding-bottom: 9px;
    }

    .pd-md-y-9-f {
        padding-top: 9px !important;
        padding-bottom: 9px !important;
    }

    .pd-md-x-9 {
        padding-left: 9px;
        padding-right: 9px;
    }

    .pd-md-x-9-f {
        padding-left: 9px !important;
        padding-right: 9px !important;
    }

    .pd-md-t-9 {
        padding-top: 9px;
    }

    .pd-md-r-9 {
        padding-right: 9px;
    }

    .pd-md-b-9 {
        padding-bottom: 9px;
    }

    .pd-md-l-9 {
        padding-left: 9px;
    }

    .pd-md-t-9-f {
        padding-top: 9px !important;
    }

    .pd-md-r-9-f {
        padding-right: 9px !important;
    }

    .pd-md-b-9-f {
        padding-bottom: 9px !important;
    }

    .pd-md-l-9-f {
        padding-left: 9px !important;
    }

    .pd-md-10 {
        padding: 10px;
    }

    .pd-md-10-f {
        padding: 10px !important;
    }

    .pd-md-y-10 {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .pd-md-y-10-f {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    .pd-md-x-10 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .pd-md-x-10-f {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .pd-md-t-10 {
        padding-top: 10px;
    }

    .pd-md-r-10 {
        padding-right: 10px;
    }

    .pd-md-b-10 {
        padding-bottom: 10px;
    }

    .pd-md-l-10 {
        padding-left: 10px;
    }

    .pd-md-t-10-f {
        padding-top: 10px !important;
    }

    .pd-md-r-10-f {
        padding-right: 10px !important;
    }

    .pd-md-b-10-f {
        padding-bottom: 10px !important;
    }

    .pd-md-l-10-f {
        padding-left: 10px !important;
    }

    .pd-md-15 {
        padding: 15px;
    }

    .pd-md-15-f {
        padding: 15px !important;
    }

    .pd-md-y-15 {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .pd-md-y-15-f {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }

    .pd-md-x-15 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .pd-md-x-15-f {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .pd-md-t-15 {
        padding-top: 15px;
    }

    .pd-md-r-15 {
        padding-right: 15px;
    }

    .pd-md-b-15 {
        padding-bottom: 15px;
    }

    .pd-md-l-15 {
        padding-left: 15px;
    }

    .pd-md-t-15-f {
        padding-top: 15px !important;
    }

    .pd-md-r-15-f {
        padding-right: 15px !important;
    }

    .pd-md-b-15-f {
        padding-bottom: 15px !important;
    }

    .pd-md-l-15-f {
        padding-left: 15px !important;
    }

    .pd-md-20 {
        padding: 20px;
    }

    .pd-md-20-f {
        padding: 20px !important;
    }

    .pd-md-y-20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .pd-md-y-20-f {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    .pd-md-x-20 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .pd-md-x-20-f {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .pd-md-t-20 {
        padding-top: 20px;
    }

    .pd-md-r-20 {
        padding-right: 20px;
    }

    .pd-md-b-20 {
        padding-bottom: 20px;
    }

    .pd-md-l-20 {
        padding-left: 20px;
    }

    .pd-md-t-20-f {
        padding-top: 20px !important;
    }

    .pd-md-r-20-f {
        padding-right: 20px !important;
    }

    .pd-md-b-20-f {
        padding-bottom: 20px !important;
    }

    .pd-md-l-20-f {
        padding-left: 20px !important;
    }

    .pd-md-25 {
        padding: 25px;
    }

    .pd-md-25-f {
        padding: 25px !important;
    }

    .pd-md-y-25 {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .pd-md-y-25-f {
        padding-top: 25px !important;
        padding-bottom: 25px !important;
    }

    .pd-md-x-25 {
        padding-left: 25px;
        padding-right: 25px;
    }

    .pd-md-x-25-f {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }

    .pd-md-t-25 {
        padding-top: 25px;
    }

    .pd-md-r-25 {
        padding-right: 25px;
    }

    .pd-md-b-25 {
        padding-bottom: 25px;
    }

    .pd-md-l-25 {
        padding-left: 25px;
    }

    .pd-md-t-25-f {
        padding-top: 25px !important;
    }

    .pd-md-r-25-f {
        padding-right: 25px !important;
    }

    .pd-md-b-25-f {
        padding-bottom: 25px !important;
    }

    .pd-md-l-25-f {
        padding-left: 25px !important;
    }

    .pd-md-30 {
        padding: 30px;
    }

    .pd-md-30-f {
        padding: 30px !important;
    }

    .pd-md-y-30 {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .pd-md-y-30-f {
        padding-top: 30px !important;
        padding-bottom: 30px !important;
    }

    .pd-md-x-30 {
        padding-left: 30px;
        padding-right: 30px;
    }

    .pd-md-x-30-f {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    .pd-md-t-30 {
        padding-top: 30px;
    }

    .pd-md-r-30 {
        padding-right: 30px;
    }

    .pd-md-b-30 {
        padding-bottom: 30px;
    }

    .pd-md-l-30 {
        padding-left: 30px;
    }

    .pd-md-t-30-f {
        padding-top: 30px !important;
    }

    .pd-md-r-30-f {
        padding-right: 30px !important;
    }

    .pd-md-b-30-f {
        padding-bottom: 30px !important;
    }

    .pd-md-l-30-f {
        padding-left: 30px !important;
    }

    .pd-md-35 {
        padding: 35px;
    }

    .pd-md-35-f {
        padding: 35px !important;
    }

    .pd-md-y-35 {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .pd-md-y-35-f {
        padding-top: 35px !important;
        padding-bottom: 35px !important;
    }

    .pd-md-x-35 {
        padding-left: 35px;
        padding-right: 35px;
    }

    .pd-md-x-35-f {
        padding-left: 35px !important;
        padding-right: 35px !important;
    }

    .pd-md-t-35 {
        padding-top: 35px;
    }

    .pd-md-r-35 {
        padding-right: 35px;
    }

    .pd-md-b-35 {
        padding-bottom: 35px;
    }

    .pd-md-l-35 {
        padding-left: 35px;
    }

    .pd-md-t-35-f {
        padding-top: 35px !important;
    }

    .pd-md-r-35-f {
        padding-right: 35px !important;
    }

    .pd-md-b-35-f {
        padding-bottom: 35px !important;
    }

    .pd-md-l-35-f {
        padding-left: 35px !important;
    }

    .pd-md-40 {
        padding: 40px;
    }

    .pd-md-40-f {
        padding: 40px !important;
    }

    .pd-md-y-40 {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .pd-md-y-40-f {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }

    .pd-md-x-40 {
        padding-left: 40px;
        padding-right: 40px;
    }

    .pd-md-x-40-f {
        padding-left: 40px !important;
        padding-right: 40px !important;
    }

    .pd-md-t-40 {
        padding-top: 40px;
    }

    .pd-md-r-40 {
        padding-right: 40px;
    }

    .pd-md-b-40 {
        padding-bottom: 40px;
    }

    .pd-md-l-40 {
        padding-left: 40px;
    }

    .pd-md-t-40-f {
        padding-top: 40px !important;
    }

    .pd-md-r-40-f {
        padding-right: 40px !important;
    }

    .pd-md-b-40-f {
        padding-bottom: 40px !important;
    }

    .pd-md-l-40-f {
        padding-left: 40px !important;
    }

    .pd-md-45 {
        padding: 45px;
    }

    .pd-md-45-f {
        padding: 45px !important;
    }

    .pd-md-y-45 {
        padding-top: 45px;
        padding-bottom: 45px;
    }

    .pd-md-y-45-f {
        padding-top: 45px !important;
        padding-bottom: 45px !important;
    }

    .pd-md-x-45 {
        padding-left: 45px;
        padding-right: 45px;
    }

    .pd-md-x-45-f {
        padding-left: 45px !important;
        padding-right: 45px !important;
    }

    .pd-md-t-45 {
        padding-top: 45px;
    }

    .pd-md-r-45 {
        padding-right: 45px;
    }

    .pd-md-b-45 {
        padding-bottom: 45px;
    }

    .pd-md-l-45 {
        padding-left: 45px;
    }

    .pd-md-t-45-f {
        padding-top: 45px !important;
    }

    .pd-md-r-45-f {
        padding-right: 45px !important;
    }

    .pd-md-b-45-f {
        padding-bottom: 45px !important;
    }

    .pd-md-l-45-f {
        padding-left: 45px !important;
    }

    .pd-md-50 {
        padding: 50px;
    }

    .pd-md-50-f {
        padding: 50px !important;
    }

    .pd-md-y-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .pd-md-y-50-f {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }

    .pd-md-x-50 {
        padding-left: 50px;
        padding-right: 50px;
    }

    .pd-md-x-50-f {
        padding-left: 50px !important;
        padding-right: 50px !important;
    }

    .pd-md-t-50 {
        padding-top: 50px;
    }

    .pd-md-r-50 {
        padding-right: 50px;
    }

    .pd-md-b-50 {
        padding-bottom: 50px;
    }

    .pd-md-l-50 {
        padding-left: 50px;
    }

    .pd-md-t-50-f {
        padding-top: 50px !important;
    }

    .pd-md-r-50-f {
        padding-right: 50px !important;
    }

    .pd-md-b-50-f {
        padding-bottom: 50px !important;
    }

    .pd-md-l-50-f {
        padding-left: 50px !important;
    }

    .pd-md-55 {
        padding: 55px;
    }

    .pd-md-55-f {
        padding: 55px !important;
    }

    .pd-md-y-55 {
        padding-top: 55px;
        padding-bottom: 55px;
    }

    .pd-md-y-55-f {
        padding-top: 55px !important;
        padding-bottom: 55px !important;
    }

    .pd-md-x-55 {
        padding-left: 55px;
        padding-right: 55px;
    }

    .pd-md-x-55-f {
        padding-left: 55px !important;
        padding-right: 55px !important;
    }

    .pd-md-t-55 {
        padding-top: 55px;
    }

    .pd-md-r-55 {
        padding-right: 55px;
    }

    .pd-md-b-55 {
        padding-bottom: 55px;
    }

    .pd-md-l-55 {
        padding-left: 55px;
    }

    .pd-md-t-55-f {
        padding-top: 55px !important;
    }

    .pd-md-r-55-f {
        padding-right: 55px !important;
    }

    .pd-md-b-55-f {
        padding-bottom: 55px !important;
    }

    .pd-md-l-55-f {
        padding-left: 55px !important;
    }

    .pd-md-60 {
        padding: 60px;
    }

    .pd-md-60-f {
        padding: 60px !important;
    }

    .pd-md-y-60 {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .pd-md-y-60-f {
        padding-top: 60px !important;
        padding-bottom: 60px !important;
    }

    .pd-md-x-60 {
        padding-left: 60px;
        padding-right: 60px;
    }

    .pd-md-x-60-f {
        padding-left: 60px !important;
        padding-right: 60px !important;
    }

    .pd-md-t-60 {
        padding-top: 60px;
    }

    .pd-md-r-60 {
        padding-right: 60px;
    }

    .pd-md-b-60 {
        padding-bottom: 60px;
    }

    .pd-md-l-60 {
        padding-left: 60px;
    }

    .pd-md-t-60-f {
        padding-top: 60px !important;
    }

    .pd-md-r-60-f {
        padding-right: 60px !important;
    }

    .pd-md-b-60-f {
        padding-bottom: 60px !important;
    }

    .pd-md-l-60-f {
        padding-left: 60px !important;
    }

    .pd-md-65 {
        padding: 65px;
    }

    .pd-md-65-f {
        padding: 65px !important;
    }

    .pd-md-y-65 {
        padding-top: 65px;
        padding-bottom: 65px;
    }

    .pd-md-y-65-f {
        padding-top: 65px !important;
        padding-bottom: 65px !important;
    }

    .pd-md-x-65 {
        padding-left: 65px;
        padding-right: 65px;
    }

    .pd-md-x-65-f {
        padding-left: 65px !important;
        padding-right: 65px !important;
    }

    .pd-md-t-65 {
        padding-top: 65px;
    }

    .pd-md-r-65 {
        padding-right: 65px;
    }

    .pd-md-b-65 {
        padding-bottom: 65px;
    }

    .pd-md-l-65 {
        padding-left: 65px;
    }

    .pd-md-t-65-f {
        padding-top: 65px !important;
    }

    .pd-md-r-65-f {
        padding-right: 65px !important;
    }

    .pd-md-b-65-f {
        padding-bottom: 65px !important;
    }

    .pd-md-l-65-f {
        padding-left: 65px !important;
    }

    .pd-md-70 {
        padding: 70px;
    }

    .pd-md-70-f {
        padding: 70px !important;
    }

    .pd-md-y-70 {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .pd-md-y-70-f {
        padding-top: 70px !important;
        padding-bottom: 70px !important;
    }

    .pd-md-x-70 {
        padding-left: 70px;
        padding-right: 70px;
    }

    .pd-md-x-70-f {
        padding-left: 70px !important;
        padding-right: 70px !important;
    }

    .pd-md-t-70 {
        padding-top: 70px;
    }

    .pd-md-r-70 {
        padding-right: 70px;
    }

    .pd-md-b-70 {
        padding-bottom: 70px;
    }

    .pd-md-l-70 {
        padding-left: 70px;
    }

    .pd-md-t-70-f {
        padding-top: 70px !important;
    }

    .pd-md-r-70-f {
        padding-right: 70px !important;
    }

    .pd-md-b-70-f {
        padding-bottom: 70px !important;
    }

    .pd-md-l-70-f {
        padding-left: 70px !important;
    }

    .pd-md-75 {
        padding: 75px;
    }

    .pd-md-75-f {
        padding: 75px !important;
    }

    .pd-md-y-75 {
        padding-top: 75px;
        padding-bottom: 75px;
    }

    .pd-md-y-75-f {
        padding-top: 75px !important;
        padding-bottom: 75px !important;
    }

    .pd-md-x-75 {
        padding-left: 75px;
        padding-right: 75px;
    }

    .pd-md-x-75-f {
        padding-left: 75px !important;
        padding-right: 75px !important;
    }

    .pd-md-t-75 {
        padding-top: 75px;
    }

    .pd-md-r-75 {
        padding-right: 75px;
    }

    .pd-md-b-75 {
        padding-bottom: 75px;
    }

    .pd-md-l-75 {
        padding-left: 75px;
    }

    .pd-md-t-75-f {
        padding-top: 75px !important;
    }

    .pd-md-r-75-f {
        padding-right: 75px !important;
    }

    .pd-md-b-75-f {
        padding-bottom: 75px !important;
    }

    .pd-md-l-75-f {
        padding-left: 75px !important;
    }

    .pd-md-80 {
        padding: 80px;
    }

    .pd-md-80-f {
        padding: 80px !important;
    }

    .pd-md-y-80 {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .pd-md-y-80-f {
        padding-top: 80px !important;
        padding-bottom: 80px !important;
    }

    .pd-md-x-80 {
        padding-left: 80px;
        padding-right: 80px;
    }

    .pd-md-x-80-f {
        padding-left: 80px !important;
        padding-right: 80px !important;
    }

    .pd-md-t-80 {
        padding-top: 80px;
    }

    .pd-md-r-80 {
        padding-right: 80px;
    }

    .pd-md-b-80 {
        padding-bottom: 80px;
    }

    .pd-md-l-80 {
        padding-left: 80px;
    }

    .pd-md-t-80-f {
        padding-top: 80px !important;
    }

    .pd-md-r-80-f {
        padding-right: 80px !important;
    }

    .pd-md-b-80-f {
        padding-bottom: 80px !important;
    }

    .pd-md-l-80-f {
        padding-left: 80px !important;
    }

    .pd-md-85 {
        padding: 85px;
    }

    .pd-md-85-f {
        padding: 85px !important;
    }

    .pd-md-y-85 {
        padding-top: 85px;
        padding-bottom: 85px;
    }

    .pd-md-y-85-f {
        padding-top: 85px !important;
        padding-bottom: 85px !important;
    }

    .pd-md-x-85 {
        padding-left: 85px;
        padding-right: 85px;
    }

    .pd-md-x-85-f {
        padding-left: 85px !important;
        padding-right: 85px !important;
    }

    .pd-md-t-85 {
        padding-top: 85px;
    }

    .pd-md-r-85 {
        padding-right: 85px;
    }

    .pd-md-b-85 {
        padding-bottom: 85px;
    }

    .pd-md-l-85 {
        padding-left: 85px;
    }

    .pd-md-t-85-f {
        padding-top: 85px !important;
    }

    .pd-md-r-85-f {
        padding-right: 85px !important;
    }

    .pd-md-b-85-f {
        padding-bottom: 85px !important;
    }

    .pd-md-l-85-f {
        padding-left: 85px !important;
    }

    .pd-md-90 {
        padding: 90px;
    }

    .pd-md-90-f {
        padding: 90px !important;
    }

    .pd-md-y-90 {
        padding-top: 90px;
        padding-bottom: 90px;
    }

    .pd-md-y-90-f {
        padding-top: 90px !important;
        padding-bottom: 90px !important;
    }

    .pd-md-x-90 {
        padding-left: 90px;
        padding-right: 90px;
    }

    .pd-md-x-90-f {
        padding-left: 90px !important;
        padding-right: 90px !important;
    }

    .pd-md-t-90 {
        padding-top: 90px;
    }

    .pd-md-r-90 {
        padding-right: 90px;
    }

    .pd-md-b-90 {
        padding-bottom: 90px;
    }

    .pd-md-l-90 {
        padding-left: 90px;
    }

    .pd-md-t-90-f {
        padding-top: 90px !important;
    }

    .pd-md-r-90-f {
        padding-right: 90px !important;
    }

    .pd-md-b-90-f {
        padding-bottom: 90px !important;
    }

    .pd-md-l-90-f {
        padding-left: 90px !important;
    }

    .pd-md-95 {
        padding: 95px;
    }

    .pd-md-95-f {
        padding: 95px !important;
    }

    .pd-md-y-95 {
        padding-top: 95px;
        padding-bottom: 95px;
    }

    .pd-md-y-95-f {
        padding-top: 95px !important;
        padding-bottom: 95px !important;
    }

    .pd-md-x-95 {
        padding-left: 95px;
        padding-right: 95px;
    }

    .pd-md-x-95-f {
        padding-left: 95px !important;
        padding-right: 95px !important;
    }

    .pd-md-t-95 {
        padding-top: 95px;
    }

    .pd-md-r-95 {
        padding-right: 95px;
    }

    .pd-md-b-95 {
        padding-bottom: 95px;
    }

    .pd-md-l-95 {
        padding-left: 95px;
    }

    .pd-md-t-95-f {
        padding-top: 95px !important;
    }

    .pd-md-r-95-f {
        padding-right: 95px !important;
    }

    .pd-md-b-95-f {
        padding-bottom: 95px !important;
    }

    .pd-md-l-95-f {
        padding-left: 95px !important;
    }

    .pd-md-100 {
        padding: 100px;
    }

    .pd-md-100-f {
        padding: 100px !important;
    }

    .pd-md-y-100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .pd-md-y-100-f {
        padding-top: 100px !important;
        padding-bottom: 100px !important;
    }

    .pd-md-x-100 {
        padding-left: 100px;
        padding-right: 100px;
    }

    .pd-md-x-100-f {
        padding-left: 100px !important;
        padding-right: 100px !important;
    }

    .pd-md-t-100 {
        padding-top: 100px;
    }

    .pd-md-r-100 {
        padding-right: 100px;
    }

    .pd-md-b-100 {
        padding-bottom: 100px;
    }

    .pd-md-l-100 {
        padding-left: 100px;
    }

    .pd-md-t-100-f {
        padding-top: 100px !important;
    }

    .pd-md-r-100-f {
        padding-right: 100px !important;
    }

    .pd-md-b-100-f {
        padding-bottom: 100px !important;
    }

    .pd-md-l-100-f {
        padding-left: 100px !important;
    }

    .pd-md-110 {
        padding: 110px;
    }

    .pd-md-110-f {
        padding: 110px !important;
    }

    .pd-md-y-110 {
        padding-top: 110px;
        padding-bottom: 110px;
    }

    .pd-md-y-110-f {
        padding-top: 110px !important;
        padding-bottom: 110px !important;
    }

    .pd-md-x-110 {
        padding-left: 110px;
        padding-right: 110px;
    }

    .pd-md-x-110-f {
        padding-left: 110px !important;
        padding-right: 110px !important;
    }

    .pd-md-t-110 {
        padding-top: 110px;
    }

    .pd-md-r-110 {
        padding-right: 110px;
    }

    .pd-md-b-110 {
        padding-bottom: 110px;
    }

    .pd-md-l-110 {
        padding-left: 110px;
    }

    .pd-md-t-110-f {
        padding-top: 110px !important;
    }

    .pd-md-r-110-f {
        padding-right: 110px !important;
    }

    .pd-md-b-110-f {
        padding-bottom: 110px !important;
    }

    .pd-md-l-110-f {
        padding-left: 110px !important;
    }

    .pd-md-120 {
        padding: 120px;
    }

    .pd-md-120-f {
        padding: 120px !important;
    }

    .pd-md-y-120 {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .pd-md-y-120-f {
        padding-top: 120px !important;
        padding-bottom: 120px !important;
    }

    .pd-md-x-120 {
        padding-left: 120px;
        padding-right: 120px;
    }

    .pd-md-x-120-f {
        padding-left: 120px !important;
        padding-right: 120px !important;
    }

    .pd-md-t-120 {
        padding-top: 120px;
    }

    .pd-md-r-120 {
        padding-right: 120px;
    }

    .pd-md-b-120 {
        padding-bottom: 120px;
    }

    .pd-md-l-120 {
        padding-left: 120px;
    }

    .pd-md-t-120-f {
        padding-top: 120px !important;
    }

    .pd-md-r-120-f {
        padding-right: 120px !important;
    }

    .pd-md-b-120-f {
        padding-bottom: 120px !important;
    }

    .pd-md-l-120-f {
        padding-left: 120px !important;
    }

    .pd-md-130 {
        padding: 130px;
    }

    .pd-md-130-f {
        padding: 130px !important;
    }

    .pd-md-y-130 {
        padding-top: 130px;
        padding-bottom: 130px;
    }

    .pd-md-y-130-f {
        padding-top: 130px !important;
        padding-bottom: 130px !important;
    }

    .pd-md-x-130 {
        padding-left: 130px;
        padding-right: 130px;
    }

    .pd-md-x-130-f {
        padding-left: 130px !important;
        padding-right: 130px !important;
    }

    .pd-md-t-130 {
        padding-top: 130px;
    }

    .pd-md-r-130 {
        padding-right: 130px;
    }

    .pd-md-b-130 {
        padding-bottom: 130px;
    }

    .pd-md-l-130 {
        padding-left: 130px;
    }

    .pd-md-t-130-f {
        padding-top: 130px !important;
    }

    .pd-md-r-130-f {
        padding-right: 130px !important;
    }

    .pd-md-b-130-f {
        padding-bottom: 130px !important;
    }

    .pd-md-l-130-f {
        padding-left: 130px !important;
    }

    .pd-md-140 {
        padding: 140px;
    }

    .pd-md-140-f {
        padding: 140px !important;
    }

    .pd-md-y-140 {
        padding-top: 140px;
        padding-bottom: 140px;
    }

    .pd-md-y-140-f {
        padding-top: 140px !important;
        padding-bottom: 140px !important;
    }

    .pd-md-x-140 {
        padding-left: 140px;
        padding-right: 140px;
    }

    .pd-md-x-140-f {
        padding-left: 140px !important;
        padding-right: 140px !important;
    }

    .pd-md-t-140 {
        padding-top: 140px;
    }

    .pd-md-r-140 {
        padding-right: 140px;
    }

    .pd-md-b-140 {
        padding-bottom: 140px;
    }

    .pd-md-l-140 {
        padding-left: 140px;
    }

    .pd-md-t-140-f {
        padding-top: 140px !important;
    }

    .pd-md-r-140-f {
        padding-right: 140px !important;
    }

    .pd-md-b-140-f {
        padding-bottom: 140px !important;
    }

    .pd-md-l-140-f {
        padding-left: 140px !important;
    }

    .pd-md-150 {
        padding: 150px;
    }

    .pd-md-150-f {
        padding: 150px !important;
    }

    .pd-md-y-150 {
        padding-top: 150px;
        padding-bottom: 150px;
    }

    .pd-md-y-150-f {
        padding-top: 150px !important;
        padding-bottom: 150px !important;
    }

    .pd-md-x-150 {
        padding-left: 150px;
        padding-right: 150px;
    }

    .pd-md-x-150-f {
        padding-left: 150px !important;
        padding-right: 150px !important;
    }

    .pd-md-t-150 {
        padding-top: 150px;
    }

    .pd-md-r-150 {
        padding-right: 150px;
    }

    .pd-md-b-150 {
        padding-bottom: 150px;
    }

    .pd-md-l-150 {
        padding-left: 150px;
    }

    .pd-md-t-150-f {
        padding-top: 150px !important;
    }

    .pd-md-r-150-f {
        padding-right: 150px !important;
    }

    .pd-md-b-150-f {
        padding-bottom: 150px !important;
    }

    .pd-md-l-150-f {
        padding-left: 150px !important;
    }

    .pd-md-160 {
        padding: 160px;
    }

    .pd-md-160-f {
        padding: 160px !important;
    }

    .pd-md-y-160 {
        padding-top: 160px;
        padding-bottom: 160px;
    }

    .pd-md-y-160-f {
        padding-top: 160px !important;
        padding-bottom: 160px !important;
    }

    .pd-md-x-160 {
        padding-left: 160px;
        padding-right: 160px;
    }

    .pd-md-x-160-f {
        padding-left: 160px !important;
        padding-right: 160px !important;
    }

    .pd-md-t-160 {
        padding-top: 160px;
    }

    .pd-md-r-160 {
        padding-right: 160px;
    }

    .pd-md-b-160 {
        padding-bottom: 160px;
    }

    .pd-md-l-160 {
        padding-left: 160px;
    }

    .pd-md-t-160-f {
        padding-top: 160px !important;
    }

    .pd-md-r-160-f {
        padding-right: 160px !important;
    }

    .pd-md-b-160-f {
        padding-bottom: 160px !important;
    }

    .pd-md-l-160-f {
        padding-left: 160px !important;
    }

    .pd-md-170 {
        padding: 170px;
    }

    .pd-md-170-f {
        padding: 170px !important;
    }

    .pd-md-y-170 {
        padding-top: 170px;
        padding-bottom: 170px;
    }

    .pd-md-y-170-f {
        padding-top: 170px !important;
        padding-bottom: 170px !important;
    }

    .pd-md-x-170 {
        padding-left: 170px;
        padding-right: 170px;
    }

    .pd-md-x-170-f {
        padding-left: 170px !important;
        padding-right: 170px !important;
    }

    .pd-md-t-170 {
        padding-top: 170px;
    }

    .pd-md-r-170 {
        padding-right: 170px;
    }

    .pd-md-b-170 {
        padding-bottom: 170px;
    }

    .pd-md-l-170 {
        padding-left: 170px;
    }

    .pd-md-t-170-f {
        padding-top: 170px !important;
    }

    .pd-md-r-170-f {
        padding-right: 170px !important;
    }

    .pd-md-b-170-f {
        padding-bottom: 170px !important;
    }

    .pd-md-l-170-f {
        padding-left: 170px !important;
    }

    .pd-md-180 {
        padding: 180px;
    }

    .pd-md-180-f {
        padding: 180px !important;
    }

    .pd-md-y-180 {
        padding-top: 180px;
        padding-bottom: 180px;
    }

    .pd-md-y-180-f {
        padding-top: 180px !important;
        padding-bottom: 180px !important;
    }

    .pd-md-x-180 {
        padding-left: 180px;
        padding-right: 180px;
    }

    .pd-md-x-180-f {
        padding-left: 180px !important;
        padding-right: 180px !important;
    }

    .pd-md-t-180 {
        padding-top: 180px;
    }

    .pd-md-r-180 {
        padding-right: 180px;
    }

    .pd-md-b-180 {
        padding-bottom: 180px;
    }

    .pd-md-l-180 {
        padding-left: 180px;
    }

    .pd-md-t-180-f {
        padding-top: 180px !important;
    }

    .pd-md-r-180-f {
        padding-right: 180px !important;
    }

    .pd-md-b-180-f {
        padding-bottom: 180px !important;
    }

    .pd-md-l-180-f {
        padding-left: 180px !important;
    }

    .pd-md-190 {
        padding: 190px;
    }

    .pd-md-190-f {
        padding: 190px !important;
    }

    .pd-md-y-190 {
        padding-top: 190px;
        padding-bottom: 190px;
    }

    .pd-md-y-190-f {
        padding-top: 190px !important;
        padding-bottom: 190px !important;
    }

    .pd-md-x-190 {
        padding-left: 190px;
        padding-right: 190px;
    }

    .pd-md-x-190-f {
        padding-left: 190px !important;
        padding-right: 190px !important;
    }

    .pd-md-t-190 {
        padding-top: 190px;
    }

    .pd-md-r-190 {
        padding-right: 190px;
    }

    .pd-md-b-190 {
        padding-bottom: 190px;
    }

    .pd-md-l-190 {
        padding-left: 190px;
    }

    .pd-md-t-190-f {
        padding-top: 190px !important;
    }

    .pd-md-r-190-f {
        padding-right: 190px !important;
    }

    .pd-md-b-190-f {
        padding-bottom: 190px !important;
    }

    .pd-md-l-190-f {
        padding-left: 190px !important;
    }

    .pd-md-200 {
        padding: 200px;
    }

    .pd-md-200-f {
        padding: 200px !important;
    }

    .pd-md-y-200 {
        padding-top: 200px;
        padding-bottom: 200px;
    }

    .pd-md-y-200-f {
        padding-top: 200px !important;
        padding-bottom: 200px !important;
    }

    .pd-md-x-200 {
        padding-left: 200px;
        padding-right: 200px;
    }

    .pd-md-x-200-f {
        padding-left: 200px !important;
        padding-right: 200px !important;
    }

    .pd-md-t-200 {
        padding-top: 200px;
    }

    .pd-md-r-200 {
        padding-right: 200px;
    }

    .pd-md-b-200 {
        padding-bottom: 200px;
    }

    .pd-md-l-200 {
        padding-left: 200px;
    }

    .pd-md-t-200-f {
        padding-top: 200px !important;
    }

    .pd-md-r-200-f {
        padding-right: 200px !important;
    }

    .pd-md-b-200-f {
        padding-bottom: 200px !important;
    }

    .pd-md-l-200-f {
        padding-left: 200px !important;
    }
}

@media (min-width: 992px) {
    .pd-lg-0 {
        padding: 0px;
    }

    .pd-lg-0-f {
        padding: 0px !important;
    }

    .pd-lg-y-0 {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .pd-lg-y-0-f {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .pd-lg-x-0 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .pd-lg-x-0-f {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .pd-lg-t-0 {
        padding-top: 0px;
    }

    .pd-lg-r-0 {
        padding-right: 0px;
    }

    .pd-lg-b-0 {
        padding-bottom: 0px;
    }

    .pd-lg-l-0 {
        padding-left: 0px;
    }

    .pd-lg-t-0-f {
        padding-top: 0px !important;
    }

    .pd-lg-r-0-f {
        padding-right: 0px !important;
    }

    .pd-lg-b-0-f {
        padding-bottom: 0px !important;
    }

    .pd-lg-l-0-f {
        padding-left: 0px !important;
    }

    .pd-lg-1 {
        padding: 1px;
    }

    .pd-lg-1-f {
        padding: 1px !important;
    }

    .pd-lg-y-1 {
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .pd-lg-y-1-f {
        padding-top: 1px !important;
        padding-bottom: 1px !important;
    }

    .pd-lg-x-1 {
        padding-left: 1px;
        padding-right: 1px;
    }

    .pd-lg-x-1-f {
        padding-left: 1px !important;
        padding-right: 1px !important;
    }

    .pd-lg-t-1 {
        padding-top: 1px;
    }

    .pd-lg-r-1 {
        padding-right: 1px;
    }

    .pd-lg-b-1 {
        padding-bottom: 1px;
    }

    .pd-lg-l-1 {
        padding-left: 1px;
    }

    .pd-lg-t-1-f {
        padding-top: 1px !important;
    }

    .pd-lg-r-1-f {
        padding-right: 1px !important;
    }

    .pd-lg-b-1-f {
        padding-bottom: 1px !important;
    }

    .pd-lg-l-1-f {
        padding-left: 1px !important;
    }

    .pd-lg-2 {
        padding: 2px;
    }

    .pd-lg-2-f {
        padding: 2px !important;
    }

    .pd-lg-y-2 {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .pd-lg-y-2-f {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }

    .pd-lg-x-2 {
        padding-left: 2px;
        padding-right: 2px;
    }

    .pd-lg-x-2-f {
        padding-left: 2px !important;
        padding-right: 2px !important;
    }

    .pd-lg-t-2 {
        padding-top: 2px;
    }

    .pd-lg-r-2 {
        padding-right: 2px;
    }

    .pd-lg-b-2 {
        padding-bottom: 2px;
    }

    .pd-lg-l-2 {
        padding-left: 2px;
    }

    .pd-lg-t-2-f {
        padding-top: 2px !important;
    }

    .pd-lg-r-2-f {
        padding-right: 2px !important;
    }

    .pd-lg-b-2-f {
        padding-bottom: 2px !important;
    }

    .pd-lg-l-2-f {
        padding-left: 2px !important;
    }

    .pd-lg-3 {
        padding: 3px;
    }

    .pd-lg-3-f {
        padding: 3px !important;
    }

    .pd-lg-y-3 {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .pd-lg-y-3-f {
        padding-top: 3px !important;
        padding-bottom: 3px !important;
    }

    .pd-lg-x-3 {
        padding-left: 3px;
        padding-right: 3px;
    }

    .pd-lg-x-3-f {
        padding-left: 3px !important;
        padding-right: 3px !important;
    }

    .pd-lg-t-3 {
        padding-top: 3px;
    }

    .pd-lg-r-3 {
        padding-right: 3px;
    }

    .pd-lg-b-3 {
        padding-bottom: 3px;
    }

    .pd-lg-l-3 {
        padding-left: 3px;
    }

    .pd-lg-t-3-f {
        padding-top: 3px !important;
    }

    .pd-lg-r-3-f {
        padding-right: 3px !important;
    }

    .pd-lg-b-3-f {
        padding-bottom: 3px !important;
    }

    .pd-lg-l-3-f {
        padding-left: 3px !important;
    }

    .pd-lg-4 {
        padding: 4px;
    }

    .pd-lg-4-f {
        padding: 4px !important;
    }

    .pd-lg-y-4 {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .pd-lg-y-4-f {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
    }

    .pd-lg-x-4 {
        padding-left: 4px;
        padding-right: 4px;
    }

    .pd-lg-x-4-f {
        padding-left: 4px !important;
        padding-right: 4px !important;
    }

    .pd-lg-t-4 {
        padding-top: 4px;
    }

    .pd-lg-r-4 {
        padding-right: 4px;
    }

    .pd-lg-b-4 {
        padding-bottom: 4px;
    }

    .pd-lg-l-4 {
        padding-left: 4px;
    }

    .pd-lg-t-4-f {
        padding-top: 4px !important;
    }

    .pd-lg-r-4-f {
        padding-right: 4px !important;
    }

    .pd-lg-b-4-f {
        padding-bottom: 4px !important;
    }

    .pd-lg-l-4-f {
        padding-left: 4px !important;
    }

    .pd-lg-5 {
        padding: 5px;
    }

    .pd-lg-5-f {
        padding: 5px !important;
    }

    .pd-lg-y-5 {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .pd-lg-y-5-f {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }

    .pd-lg-x-5 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .pd-lg-x-5-f {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    .pd-lg-t-5 {
        padding-top: 5px;
    }

    .pd-lg-r-5 {
        padding-right: 5px;
    }

    .pd-lg-b-5 {
        padding-bottom: 5px;
    }

    .pd-lg-l-5 {
        padding-left: 5px;
    }

    .pd-lg-t-5-f {
        padding-top: 5px !important;
    }

    .pd-lg-r-5-f {
        padding-right: 5px !important;
    }

    .pd-lg-b-5-f {
        padding-bottom: 5px !important;
    }

    .pd-lg-l-5-f {
        padding-left: 5px !important;
    }

    .pd-lg-6 {
        padding: 6px;
    }

    .pd-lg-6-f {
        padding: 6px !important;
    }

    .pd-lg-y-6 {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .pd-lg-y-6-f {
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }

    .pd-lg-x-6 {
        padding-left: 6px;
        padding-right: 6px;
    }

    .pd-lg-x-6-f {
        padding-left: 6px !important;
        padding-right: 6px !important;
    }

    .pd-lg-t-6 {
        padding-top: 6px;
    }

    .pd-lg-r-6 {
        padding-right: 6px;
    }

    .pd-lg-b-6 {
        padding-bottom: 6px;
    }

    .pd-lg-l-6 {
        padding-left: 6px;
    }

    .pd-lg-t-6-f {
        padding-top: 6px !important;
    }

    .pd-lg-r-6-f {
        padding-right: 6px !important;
    }

    .pd-lg-b-6-f {
        padding-bottom: 6px !important;
    }

    .pd-lg-l-6-f {
        padding-left: 6px !important;
    }

    .pd-lg-7 {
        padding: 7px;
    }

    .pd-lg-7-f {
        padding: 7px !important;
    }

    .pd-lg-y-7 {
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .pd-lg-y-7-f {
        padding-top: 7px !important;
        padding-bottom: 7px !important;
    }

    .pd-lg-x-7 {
        padding-left: 7px;
        padding-right: 7px;
    }

    .pd-lg-x-7-f {
        padding-left: 7px !important;
        padding-right: 7px !important;
    }

    .pd-lg-t-7 {
        padding-top: 7px;
    }

    .pd-lg-r-7 {
        padding-right: 7px;
    }

    .pd-lg-b-7 {
        padding-bottom: 7px;
    }

    .pd-lg-l-7 {
        padding-left: 7px;
    }

    .pd-lg-t-7-f {
        padding-top: 7px !important;
    }

    .pd-lg-r-7-f {
        padding-right: 7px !important;
    }

    .pd-lg-b-7-f {
        padding-bottom: 7px !important;
    }

    .pd-lg-l-7-f {
        padding-left: 7px !important;
    }

    .pd-lg-8 {
        padding: 8px;
    }

    .pd-lg-8-f {
        padding: 8px !important;
    }

    .pd-lg-y-8 {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .pd-lg-y-8-f {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }

    .pd-lg-x-8 {
        padding-left: 8px;
        padding-right: 8px;
    }

    .pd-lg-x-8-f {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }

    .pd-lg-t-8 {
        padding-top: 8px;
    }

    .pd-lg-r-8 {
        padding-right: 8px;
    }

    .pd-lg-b-8 {
        padding-bottom: 8px;
    }

    .pd-lg-l-8 {
        padding-left: 8px;
    }

    .pd-lg-t-8-f {
        padding-top: 8px !important;
    }

    .pd-lg-r-8-f {
        padding-right: 8px !important;
    }

    .pd-lg-b-8-f {
        padding-bottom: 8px !important;
    }

    .pd-lg-l-8-f {
        padding-left: 8px !important;
    }

    .pd-lg-9 {
        padding: 9px;
    }

    .pd-lg-9-f {
        padding: 9px !important;
    }

    .pd-lg-y-9 {
        padding-top: 9px;
        padding-bottom: 9px;
    }

    .pd-lg-y-9-f {
        padding-top: 9px !important;
        padding-bottom: 9px !important;
    }

    .pd-lg-x-9 {
        padding-left: 9px;
        padding-right: 9px;
    }

    .pd-lg-x-9-f {
        padding-left: 9px !important;
        padding-right: 9px !important;
    }

    .pd-lg-t-9 {
        padding-top: 9px;
    }

    .pd-lg-r-9 {
        padding-right: 9px;
    }

    .pd-lg-b-9 {
        padding-bottom: 9px;
    }

    .pd-lg-l-9 {
        padding-left: 9px;
    }

    .pd-lg-t-9-f {
        padding-top: 9px !important;
    }

    .pd-lg-r-9-f {
        padding-right: 9px !important;
    }

    .pd-lg-b-9-f {
        padding-bottom: 9px !important;
    }

    .pd-lg-l-9-f {
        padding-left: 9px !important;
    }

    .pd-lg-10 {
        padding: 10px;
    }

    .pd-lg-10-f {
        padding: 10px !important;
    }

    .pd-lg-y-10 {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .pd-lg-y-10-f {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    .pd-lg-x-10 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .pd-lg-x-10-f {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .pd-lg-t-10 {
        padding-top: 10px;
    }

    .pd-lg-r-10 {
        padding-right: 10px;
    }

    .pd-lg-b-10 {
        padding-bottom: 10px;
    }

    .pd-lg-l-10 {
        padding-left: 10px;
    }

    .pd-lg-t-10-f {
        padding-top: 10px !important;
    }

    .pd-lg-r-10-f {
        padding-right: 10px !important;
    }

    .pd-lg-b-10-f {
        padding-bottom: 10px !important;
    }

    .pd-lg-l-10-f {
        padding-left: 10px !important;
    }

    .pd-lg-15 {
        padding: 15px;
    }

    .pd-lg-15-f {
        padding: 15px !important;
    }

    .pd-lg-y-15 {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .pd-lg-y-15-f {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }

    .pd-lg-x-15 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .pd-lg-x-15-f {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .pd-lg-t-15 {
        padding-top: 15px;
    }

    .pd-lg-r-15 {
        padding-right: 15px;
    }

    .pd-lg-b-15 {
        padding-bottom: 15px;
    }

    .pd-lg-l-15 {
        padding-left: 15px;
    }

    .pd-lg-t-15-f {
        padding-top: 15px !important;
    }

    .pd-lg-r-15-f {
        padding-right: 15px !important;
    }

    .pd-lg-b-15-f {
        padding-bottom: 15px !important;
    }

    .pd-lg-l-15-f {
        padding-left: 15px !important;
    }

    .pd-lg-20 {
        padding: 20px;
    }

    .pd-lg-20-f {
        padding: 20px !important;
    }

    .pd-lg-y-20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .pd-lg-y-20-f {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    .pd-lg-x-20 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .pd-lg-x-20-f {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .pd-lg-t-20 {
        padding-top: 20px;
    }

    .pd-lg-r-20 {
        padding-right: 20px;
    }

    .pd-lg-b-20 {
        padding-bottom: 20px;
    }

    .pd-lg-l-20 {
        padding-left: 20px;
    }

    .pd-lg-t-20-f {
        padding-top: 20px !important;
    }

    .pd-lg-r-20-f {
        padding-right: 20px !important;
    }

    .pd-lg-b-20-f {
        padding-bottom: 20px !important;
    }

    .pd-lg-l-20-f {
        padding-left: 20px !important;
    }

    .pd-lg-25 {
        padding: 25px;
    }

    .pd-lg-25-f {
        padding: 25px !important;
    }

    .pd-lg-y-25 {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .pd-lg-y-25-f {
        padding-top: 25px !important;
        padding-bottom: 25px !important;
    }

    .pd-lg-x-25 {
        padding-left: 25px;
        padding-right: 25px;
    }

    .pd-lg-x-25-f {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }

    .pd-lg-t-25 {
        padding-top: 25px;
    }

    .pd-lg-r-25 {
        padding-right: 25px;
    }

    .pd-lg-b-25 {
        padding-bottom: 25px;
    }

    .pd-lg-l-25 {
        padding-left: 25px;
    }

    .pd-lg-t-25-f {
        padding-top: 25px !important;
    }

    .pd-lg-r-25-f {
        padding-right: 25px !important;
    }

    .pd-lg-b-25-f {
        padding-bottom: 25px !important;
    }

    .pd-lg-l-25-f {
        padding-left: 25px !important;
    }

    .pd-lg-30 {
        padding: 30px;
    }

    .pd-lg-30-f {
        padding: 30px !important;
    }

    .pd-lg-y-30 {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .pd-lg-y-30-f {
        padding-top: 30px !important;
        padding-bottom: 30px !important;
    }

    .pd-lg-x-30 {
        padding-left: 30px;
        padding-right: 30px;
    }

    .pd-lg-x-30-f {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    .pd-lg-t-30 {
        padding-top: 30px;
    }

    .pd-lg-r-30 {
        padding-right: 30px;
    }

    .pd-lg-b-30 {
        padding-bottom: 30px;
    }

    .pd-lg-l-30 {
        padding-left: 30px;
    }

    .pd-lg-t-30-f {
        padding-top: 30px !important;
    }

    .pd-lg-r-30-f {
        padding-right: 30px !important;
    }

    .pd-lg-b-30-f {
        padding-bottom: 30px !important;
    }

    .pd-lg-l-30-f {
        padding-left: 30px !important;
    }

    .pd-lg-35 {
        padding: 35px;
    }

    .pd-lg-35-f {
        padding: 35px !important;
    }

    .pd-lg-y-35 {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .pd-lg-y-35-f {
        padding-top: 35px !important;
        padding-bottom: 35px !important;
    }

    .pd-lg-x-35 {
        padding-left: 35px;
        padding-right: 35px;
    }

    .pd-lg-x-35-f {
        padding-left: 35px !important;
        padding-right: 35px !important;
    }

    .pd-lg-t-35 {
        padding-top: 35px;
    }

    .pd-lg-r-35 {
        padding-right: 35px;
    }

    .pd-lg-b-35 {
        padding-bottom: 35px;
    }

    .pd-lg-l-35 {
        padding-left: 35px;
    }

    .pd-lg-t-35-f {
        padding-top: 35px !important;
    }

    .pd-lg-r-35-f {
        padding-right: 35px !important;
    }

    .pd-lg-b-35-f {
        padding-bottom: 35px !important;
    }

    .pd-lg-l-35-f {
        padding-left: 35px !important;
    }

    .pd-lg-40 {
        padding: 40px;
    }

    .pd-lg-40-f {
        padding: 40px !important;
    }

    .pd-lg-y-40 {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .pd-lg-y-40-f {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }

    .pd-lg-x-40 {
        padding-left: 40px;
        padding-right: 40px;
    }

    .pd-lg-x-40-f {
        padding-left: 40px !important;
        padding-right: 40px !important;
    }

    .pd-lg-t-40 {
        padding-top: 40px;
    }

    .pd-lg-r-40 {
        padding-right: 40px;
    }

    .pd-lg-b-40 {
        padding-bottom: 40px;
    }

    .pd-lg-l-40 {
        padding-left: 40px;
    }

    .pd-lg-t-40-f {
        padding-top: 40px !important;
    }

    .pd-lg-r-40-f {
        padding-right: 40px !important;
    }

    .pd-lg-b-40-f {
        padding-bottom: 40px !important;
    }

    .pd-lg-l-40-f {
        padding-left: 40px !important;
    }

    .pd-lg-45 {
        padding: 45px;
    }

    .pd-lg-45-f {
        padding: 45px !important;
    }

    .pd-lg-y-45 {
        padding-top: 45px;
        padding-bottom: 45px;
    }

    .pd-lg-y-45-f {
        padding-top: 45px !important;
        padding-bottom: 45px !important;
    }

    .pd-lg-x-45 {
        padding-left: 45px;
        padding-right: 45px;
    }

    .pd-lg-x-45-f {
        padding-left: 45px !important;
        padding-right: 45px !important;
    }

    .pd-lg-t-45 {
        padding-top: 45px;
    }

    .pd-lg-r-45 {
        padding-right: 45px;
    }

    .pd-lg-b-45 {
        padding-bottom: 45px;
    }

    .pd-lg-l-45 {
        padding-left: 45px;
    }

    .pd-lg-t-45-f {
        padding-top: 45px !important;
    }

    .pd-lg-r-45-f {
        padding-right: 45px !important;
    }

    .pd-lg-b-45-f {
        padding-bottom: 45px !important;
    }

    .pd-lg-l-45-f {
        padding-left: 45px !important;
    }

    .pd-lg-50 {
        padding: 50px;
    }

    .pd-lg-50-f {
        padding: 50px !important;
    }

    .pd-lg-y-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .pd-lg-y-50-f {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }

    .pd-lg-x-50 {
        padding-left: 50px;
        padding-right: 50px;
    }

    .pd-lg-x-50-f {
        padding-left: 50px !important;
        padding-right: 50px !important;
    }

    .pd-lg-t-50 {
        padding-top: 50px;
    }

    .pd-lg-r-50 {
        padding-right: 50px;
    }

    .pd-lg-b-50 {
        padding-bottom: 50px;
    }

    .pd-lg-l-50 {
        padding-left: 50px;
    }

    .pd-lg-t-50-f {
        padding-top: 50px !important;
    }

    .pd-lg-r-50-f {
        padding-right: 50px !important;
    }

    .pd-lg-b-50-f {
        padding-bottom: 50px !important;
    }

    .pd-lg-l-50-f {
        padding-left: 50px !important;
    }

    .pd-lg-55 {
        padding: 55px;
    }

    .pd-lg-55-f {
        padding: 55px !important;
    }

    .pd-lg-y-55 {
        padding-top: 55px;
        padding-bottom: 55px;
    }

    .pd-lg-y-55-f {
        padding-top: 55px !important;
        padding-bottom: 55px !important;
    }

    .pd-lg-x-55 {
        padding-left: 55px;
        padding-right: 55px;
    }

    .pd-lg-x-55-f {
        padding-left: 55px !important;
        padding-right: 55px !important;
    }

    .pd-lg-t-55 {
        padding-top: 55px;
    }

    .pd-lg-r-55 {
        padding-right: 55px;
    }

    .pd-lg-b-55 {
        padding-bottom: 55px;
    }

    .pd-lg-l-55 {
        padding-left: 55px;
    }

    .pd-lg-t-55-f {
        padding-top: 55px !important;
    }

    .pd-lg-r-55-f {
        padding-right: 55px !important;
    }

    .pd-lg-b-55-f {
        padding-bottom: 55px !important;
    }

    .pd-lg-l-55-f {
        padding-left: 55px !important;
    }

    .pd-lg-60 {
        padding: 60px;
    }

    .pd-lg-60-f {
        padding: 60px !important;
    }

    .pd-lg-y-60 {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .pd-lg-y-60-f {
        padding-top: 60px !important;
        padding-bottom: 60px !important;
    }

    .pd-lg-x-60 {
        padding-left: 60px;
        padding-right: 60px;
    }

    .pd-lg-x-60-f {
        padding-left: 60px !important;
        padding-right: 60px !important;
    }

    .pd-lg-t-60 {
        padding-top: 60px;
    }

    .pd-lg-r-60 {
        padding-right: 60px;
    }

    .pd-lg-b-60 {
        padding-bottom: 60px;
    }

    .pd-lg-l-60 {
        padding-left: 60px;
    }

    .pd-lg-t-60-f {
        padding-top: 60px !important;
    }

    .pd-lg-r-60-f {
        padding-right: 60px !important;
    }

    .pd-lg-b-60-f {
        padding-bottom: 60px !important;
    }

    .pd-lg-l-60-f {
        padding-left: 60px !important;
    }

    .pd-lg-65 {
        padding: 65px;
    }

    .pd-lg-65-f {
        padding: 65px !important;
    }

    .pd-lg-y-65 {
        padding-top: 65px;
        padding-bottom: 65px;
    }

    .pd-lg-y-65-f {
        padding-top: 65px !important;
        padding-bottom: 65px !important;
    }

    .pd-lg-x-65 {
        padding-left: 65px;
        padding-right: 65px;
    }

    .pd-lg-x-65-f {
        padding-left: 65px !important;
        padding-right: 65px !important;
    }

    .pd-lg-t-65 {
        padding-top: 65px;
    }

    .pd-lg-r-65 {
        padding-right: 65px;
    }

    .pd-lg-b-65 {
        padding-bottom: 65px;
    }

    .pd-lg-l-65 {
        padding-left: 65px;
    }

    .pd-lg-t-65-f {
        padding-top: 65px !important;
    }

    .pd-lg-r-65-f {
        padding-right: 65px !important;
    }

    .pd-lg-b-65-f {
        padding-bottom: 65px !important;
    }

    .pd-lg-l-65-f {
        padding-left: 65px !important;
    }

    .pd-lg-70 {
        padding: 70px;
    }

    .pd-lg-70-f {
        padding: 70px !important;
    }

    .pd-lg-y-70 {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .pd-lg-y-70-f {
        padding-top: 70px !important;
        padding-bottom: 70px !important;
    }

    .pd-lg-x-70 {
        padding-left: 70px;
        padding-right: 70px;
    }

    .pd-lg-x-70-f {
        padding-left: 70px !important;
        padding-right: 70px !important;
    }

    .pd-lg-t-70 {
        padding-top: 70px;
    }

    .pd-lg-r-70 {
        padding-right: 70px;
    }

    .pd-lg-b-70 {
        padding-bottom: 70px;
    }

    .pd-lg-l-70 {
        padding-left: 70px;
    }

    .pd-lg-t-70-f {
        padding-top: 70px !important;
    }

    .pd-lg-r-70-f {
        padding-right: 70px !important;
    }

    .pd-lg-b-70-f {
        padding-bottom: 70px !important;
    }

    .pd-lg-l-70-f {
        padding-left: 70px !important;
    }

    .pd-lg-75 {
        padding: 75px;
    }

    .pd-lg-75-f {
        padding: 75px !important;
    }

    .pd-lg-y-75 {
        padding-top: 75px;
        padding-bottom: 75px;
    }

    .pd-lg-y-75-f {
        padding-top: 75px !important;
        padding-bottom: 75px !important;
    }

    .pd-lg-x-75 {
        padding-left: 75px;
        padding-right: 75px;
    }

    .pd-lg-x-75-f {
        padding-left: 75px !important;
        padding-right: 75px !important;
    }

    .pd-lg-t-75 {
        padding-top: 75px;
    }

    .pd-lg-r-75 {
        padding-right: 75px;
    }

    .pd-lg-b-75 {
        padding-bottom: 75px;
    }

    .pd-lg-l-75 {
        padding-left: 75px;
    }

    .pd-lg-t-75-f {
        padding-top: 75px !important;
    }

    .pd-lg-r-75-f {
        padding-right: 75px !important;
    }

    .pd-lg-b-75-f {
        padding-bottom: 75px !important;
    }

    .pd-lg-l-75-f {
        padding-left: 75px !important;
    }

    .pd-lg-80 {
        padding: 80px;
    }

    .pd-lg-80-f {
        padding: 80px !important;
    }

    .pd-lg-y-80 {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .pd-lg-y-80-f {
        padding-top: 80px !important;
        padding-bottom: 80px !important;
    }

    .pd-lg-x-80 {
        padding-left: 80px;
        padding-right: 80px;
    }

    .pd-lg-x-80-f {
        padding-left: 80px !important;
        padding-right: 80px !important;
    }

    .pd-lg-t-80 {
        padding-top: 80px;
    }

    .pd-lg-r-80 {
        padding-right: 80px;
    }

    .pd-lg-b-80 {
        padding-bottom: 80px;
    }

    .pd-lg-l-80 {
        padding-left: 80px;
    }

    .pd-lg-t-80-f {
        padding-top: 80px !important;
    }

    .pd-lg-r-80-f {
        padding-right: 80px !important;
    }

    .pd-lg-b-80-f {
        padding-bottom: 80px !important;
    }

    .pd-lg-l-80-f {
        padding-left: 80px !important;
    }

    .pd-lg-85 {
        padding: 85px;
    }

    .pd-lg-85-f {
        padding: 85px !important;
    }

    .pd-lg-y-85 {
        padding-top: 85px;
        padding-bottom: 85px;
    }

    .pd-lg-y-85-f {
        padding-top: 85px !important;
        padding-bottom: 85px !important;
    }

    .pd-lg-x-85 {
        padding-left: 85px;
        padding-right: 85px;
    }

    .pd-lg-x-85-f {
        padding-left: 85px !important;
        padding-right: 85px !important;
    }

    .pd-lg-t-85 {
        padding-top: 85px;
    }

    .pd-lg-r-85 {
        padding-right: 85px;
    }

    .pd-lg-b-85 {
        padding-bottom: 85px;
    }

    .pd-lg-l-85 {
        padding-left: 85px;
    }

    .pd-lg-t-85-f {
        padding-top: 85px !important;
    }

    .pd-lg-r-85-f {
        padding-right: 85px !important;
    }

    .pd-lg-b-85-f {
        padding-bottom: 85px !important;
    }

    .pd-lg-l-85-f {
        padding-left: 85px !important;
    }

    .pd-lg-90 {
        padding: 90px;
    }

    .pd-lg-90-f {
        padding: 90px !important;
    }

    .pd-lg-y-90 {
        padding-top: 90px;
        padding-bottom: 90px;
    }

    .pd-lg-y-90-f {
        padding-top: 90px !important;
        padding-bottom: 90px !important;
    }

    .pd-lg-x-90 {
        padding-left: 90px;
        padding-right: 90px;
    }

    .pd-lg-x-90-f {
        padding-left: 90px !important;
        padding-right: 90px !important;
    }

    .pd-lg-t-90 {
        padding-top: 90px;
    }

    .pd-lg-r-90 {
        padding-right: 90px;
    }

    .pd-lg-b-90 {
        padding-bottom: 90px;
    }

    .pd-lg-l-90 {
        padding-left: 90px;
    }

    .pd-lg-t-90-f {
        padding-top: 90px !important;
    }

    .pd-lg-r-90-f {
        padding-right: 90px !important;
    }

    .pd-lg-b-90-f {
        padding-bottom: 90px !important;
    }

    .pd-lg-l-90-f {
        padding-left: 90px !important;
    }

    .pd-lg-95 {
        padding: 95px;
    }

    .pd-lg-95-f {
        padding: 95px !important;
    }

    .pd-lg-y-95 {
        padding-top: 95px;
        padding-bottom: 95px;
    }

    .pd-lg-y-95-f {
        padding-top: 95px !important;
        padding-bottom: 95px !important;
    }

    .pd-lg-x-95 {
        padding-left: 95px;
        padding-right: 95px;
    }

    .pd-lg-x-95-f {
        padding-left: 95px !important;
        padding-right: 95px !important;
    }

    .pd-lg-t-95 {
        padding-top: 95px;
    }

    .pd-lg-r-95 {
        padding-right: 95px;
    }

    .pd-lg-b-95 {
        padding-bottom: 95px;
    }

    .pd-lg-l-95 {
        padding-left: 95px;
    }

    .pd-lg-t-95-f {
        padding-top: 95px !important;
    }

    .pd-lg-r-95-f {
        padding-right: 95px !important;
    }

    .pd-lg-b-95-f {
        padding-bottom: 95px !important;
    }

    .pd-lg-l-95-f {
        padding-left: 95px !important;
    }

    .pd-lg-100 {
        padding: 100px;
    }

    .pd-lg-100-f {
        padding: 100px !important;
    }

    .pd-lg-y-100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .pd-lg-y-100-f {
        padding-top: 100px !important;
        padding-bottom: 100px !important;
    }

    .pd-lg-x-100 {
        padding-left: 100px;
        padding-right: 100px;
    }

    .pd-lg-x-100-f {
        padding-left: 100px !important;
        padding-right: 100px !important;
    }

    .pd-lg-t-100 {
        padding-top: 100px;
    }

    .pd-lg-r-100 {
        padding-right: 100px;
    }

    .pd-lg-b-100 {
        padding-bottom: 100px;
    }

    .pd-lg-l-100 {
        padding-left: 100px;
    }

    .pd-lg-t-100-f {
        padding-top: 100px !important;
    }

    .pd-lg-r-100-f {
        padding-right: 100px !important;
    }

    .pd-lg-b-100-f {
        padding-bottom: 100px !important;
    }

    .pd-lg-l-100-f {
        padding-left: 100px !important;
    }

    .pd-lg-110 {
        padding: 110px;
    }

    .pd-lg-110-f {
        padding: 110px !important;
    }

    .pd-lg-y-110 {
        padding-top: 110px;
        padding-bottom: 110px;
    }

    .pd-lg-y-110-f {
        padding-top: 110px !important;
        padding-bottom: 110px !important;
    }

    .pd-lg-x-110 {
        padding-left: 110px;
        padding-right: 110px;
    }

    .pd-lg-x-110-f {
        padding-left: 110px !important;
        padding-right: 110px !important;
    }

    .pd-lg-t-110 {
        padding-top: 110px;
    }

    .pd-lg-r-110 {
        padding-right: 110px;
    }

    .pd-lg-b-110 {
        padding-bottom: 110px;
    }

    .pd-lg-l-110 {
        padding-left: 110px;
    }

    .pd-lg-t-110-f {
        padding-top: 110px !important;
    }

    .pd-lg-r-110-f {
        padding-right: 110px !important;
    }

    .pd-lg-b-110-f {
        padding-bottom: 110px !important;
    }

    .pd-lg-l-110-f {
        padding-left: 110px !important;
    }

    .pd-lg-120 {
        padding: 120px;
    }

    .pd-lg-120-f {
        padding: 120px !important;
    }

    .pd-lg-y-120 {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .pd-lg-y-120-f {
        padding-top: 120px !important;
        padding-bottom: 120px !important;
    }

    .pd-lg-x-120 {
        padding-left: 120px;
        padding-right: 120px;
    }

    .pd-lg-x-120-f {
        padding-left: 120px !important;
        padding-right: 120px !important;
    }

    .pd-lg-t-120 {
        padding-top: 120px;
    }

    .pd-lg-r-120 {
        padding-right: 120px;
    }

    .pd-lg-b-120 {
        padding-bottom: 120px;
    }

    .pd-lg-l-120 {
        padding-left: 120px;
    }

    .pd-lg-t-120-f {
        padding-top: 120px !important;
    }

    .pd-lg-r-120-f {
        padding-right: 120px !important;
    }

    .pd-lg-b-120-f {
        padding-bottom: 120px !important;
    }

    .pd-lg-l-120-f {
        padding-left: 120px !important;
    }

    .pd-lg-130 {
        padding: 130px;
    }

    .pd-lg-130-f {
        padding: 130px !important;
    }

    .pd-lg-y-130 {
        padding-top: 130px;
        padding-bottom: 130px;
    }

    .pd-lg-y-130-f {
        padding-top: 130px !important;
        padding-bottom: 130px !important;
    }

    .pd-lg-x-130 {
        padding-left: 130px;
        padding-right: 130px;
    }

    .pd-lg-x-130-f {
        padding-left: 130px !important;
        padding-right: 130px !important;
    }

    .pd-lg-t-130 {
        padding-top: 130px;
    }

    .pd-lg-r-130 {
        padding-right: 130px;
    }

    .pd-lg-b-130 {
        padding-bottom: 130px;
    }

    .pd-lg-l-130 {
        padding-left: 130px;
    }

    .pd-lg-t-130-f {
        padding-top: 130px !important;
    }

    .pd-lg-r-130-f {
        padding-right: 130px !important;
    }

    .pd-lg-b-130-f {
        padding-bottom: 130px !important;
    }

    .pd-lg-l-130-f {
        padding-left: 130px !important;
    }

    .pd-lg-140 {
        padding: 140px;
    }

    .pd-lg-140-f {
        padding: 140px !important;
    }

    .pd-lg-y-140 {
        padding-top: 140px;
        padding-bottom: 140px;
    }

    .pd-lg-y-140-f {
        padding-top: 140px !important;
        padding-bottom: 140px !important;
    }

    .pd-lg-x-140 {
        padding-left: 140px;
        padding-right: 140px;
    }

    .pd-lg-x-140-f {
        padding-left: 140px !important;
        padding-right: 140px !important;
    }

    .pd-lg-t-140 {
        padding-top: 140px;
    }

    .pd-lg-r-140 {
        padding-right: 140px;
    }

    .pd-lg-b-140 {
        padding-bottom: 140px;
    }

    .pd-lg-l-140 {
        padding-left: 140px;
    }

    .pd-lg-t-140-f {
        padding-top: 140px !important;
    }

    .pd-lg-r-140-f {
        padding-right: 140px !important;
    }

    .pd-lg-b-140-f {
        padding-bottom: 140px !important;
    }

    .pd-lg-l-140-f {
        padding-left: 140px !important;
    }

    .pd-lg-150 {
        padding: 150px;
    }

    .pd-lg-150-f {
        padding: 150px !important;
    }

    .pd-lg-y-150 {
        padding-top: 150px;
        padding-bottom: 150px;
    }

    .pd-lg-y-150-f {
        padding-top: 150px !important;
        padding-bottom: 150px !important;
    }

    .pd-lg-x-150 {
        padding-left: 150px;
        padding-right: 150px;
    }

    .pd-lg-x-150-f {
        padding-left: 150px !important;
        padding-right: 150px !important;
    }

    .pd-lg-t-150 {
        padding-top: 150px;
    }

    .pd-lg-r-150 {
        padding-right: 150px;
    }

    .pd-lg-b-150 {
        padding-bottom: 150px;
    }

    .pd-lg-l-150 {
        padding-left: 150px;
    }

    .pd-lg-t-150-f {
        padding-top: 150px !important;
    }

    .pd-lg-r-150-f {
        padding-right: 150px !important;
    }

    .pd-lg-b-150-f {
        padding-bottom: 150px !important;
    }

    .pd-lg-l-150-f {
        padding-left: 150px !important;
    }

    .pd-lg-160 {
        padding: 160px;
    }

    .pd-lg-160-f {
        padding: 160px !important;
    }

    .pd-lg-y-160 {
        padding-top: 160px;
        padding-bottom: 160px;
    }

    .pd-lg-y-160-f {
        padding-top: 160px !important;
        padding-bottom: 160px !important;
    }

    .pd-lg-x-160 {
        padding-left: 160px;
        padding-right: 160px;
    }

    .pd-lg-x-160-f {
        padding-left: 160px !important;
        padding-right: 160px !important;
    }

    .pd-lg-t-160 {
        padding-top: 160px;
    }

    .pd-lg-r-160 {
        padding-right: 160px;
    }

    .pd-lg-b-160 {
        padding-bottom: 160px;
    }

    .pd-lg-l-160 {
        padding-left: 160px;
    }

    .pd-lg-t-160-f {
        padding-top: 160px !important;
    }

    .pd-lg-r-160-f {
        padding-right: 160px !important;
    }

    .pd-lg-b-160-f {
        padding-bottom: 160px !important;
    }

    .pd-lg-l-160-f {
        padding-left: 160px !important;
    }

    .pd-lg-170 {
        padding: 170px;
    }

    .pd-lg-170-f {
        padding: 170px !important;
    }

    .pd-lg-y-170 {
        padding-top: 170px;
        padding-bottom: 170px;
    }

    .pd-lg-y-170-f {
        padding-top: 170px !important;
        padding-bottom: 170px !important;
    }

    .pd-lg-x-170 {
        padding-left: 170px;
        padding-right: 170px;
    }

    .pd-lg-x-170-f {
        padding-left: 170px !important;
        padding-right: 170px !important;
    }

    .pd-lg-t-170 {
        padding-top: 170px;
    }

    .pd-lg-r-170 {
        padding-right: 170px;
    }

    .pd-lg-b-170 {
        padding-bottom: 170px;
    }

    .pd-lg-l-170 {
        padding-left: 170px;
    }

    .pd-lg-t-170-f {
        padding-top: 170px !important;
    }

    .pd-lg-r-170-f {
        padding-right: 170px !important;
    }

    .pd-lg-b-170-f {
        padding-bottom: 170px !important;
    }

    .pd-lg-l-170-f {
        padding-left: 170px !important;
    }

    .pd-lg-180 {
        padding: 180px;
    }

    .pd-lg-180-f {
        padding: 180px !important;
    }

    .pd-lg-y-180 {
        padding-top: 180px;
        padding-bottom: 180px;
    }

    .pd-lg-y-180-f {
        padding-top: 180px !important;
        padding-bottom: 180px !important;
    }

    .pd-lg-x-180 {
        padding-left: 180px;
        padding-right: 180px;
    }

    .pd-lg-x-180-f {
        padding-left: 180px !important;
        padding-right: 180px !important;
    }

    .pd-lg-t-180 {
        padding-top: 180px;
    }

    .pd-lg-r-180 {
        padding-right: 180px;
    }

    .pd-lg-b-180 {
        padding-bottom: 180px;
    }

    .pd-lg-l-180 {
        padding-left: 180px;
    }

    .pd-lg-t-180-f {
        padding-top: 180px !important;
    }

    .pd-lg-r-180-f {
        padding-right: 180px !important;
    }

    .pd-lg-b-180-f {
        padding-bottom: 180px !important;
    }

    .pd-lg-l-180-f {
        padding-left: 180px !important;
    }

    .pd-lg-190 {
        padding: 190px;
    }

    .pd-lg-190-f {
        padding: 190px !important;
    }

    .pd-lg-y-190 {
        padding-top: 190px;
        padding-bottom: 190px;
    }

    .pd-lg-y-190-f {
        padding-top: 190px !important;
        padding-bottom: 190px !important;
    }

    .pd-lg-x-190 {
        padding-left: 190px;
        padding-right: 190px;
    }

    .pd-lg-x-190-f {
        padding-left: 190px !important;
        padding-right: 190px !important;
    }

    .pd-lg-t-190 {
        padding-top: 190px;
    }

    .pd-lg-r-190 {
        padding-right: 190px;
    }

    .pd-lg-b-190 {
        padding-bottom: 190px;
    }

    .pd-lg-l-190 {
        padding-left: 190px;
    }

    .pd-lg-t-190-f {
        padding-top: 190px !important;
    }

    .pd-lg-r-190-f {
        padding-right: 190px !important;
    }

    .pd-lg-b-190-f {
        padding-bottom: 190px !important;
    }

    .pd-lg-l-190-f {
        padding-left: 190px !important;
    }

    .pd-lg-200 {
        padding: 200px;
    }

    .pd-lg-200-f {
        padding: 200px !important;
    }

    .pd-lg-y-200 {
        padding-top: 200px;
        padding-bottom: 200px;
    }

    .pd-lg-y-200-f {
        padding-top: 200px !important;
        padding-bottom: 200px !important;
    }

    .pd-lg-x-200 {
        padding-left: 200px;
        padding-right: 200px;
    }

    .pd-lg-x-200-f {
        padding-left: 200px !important;
        padding-right: 200px !important;
    }

    .pd-lg-t-200 {
        padding-top: 200px;
    }

    .pd-lg-r-200 {
        padding-right: 200px;
    }

    .pd-lg-b-200 {
        padding-bottom: 200px;
    }

    .pd-lg-l-200 {
        padding-left: 200px;
    }

    .pd-lg-t-200-f {
        padding-top: 200px !important;
    }

    .pd-lg-r-200-f {
        padding-right: 200px !important;
    }

    .pd-lg-b-200-f {
        padding-bottom: 200px !important;
    }

    .pd-lg-l-200-f {
        padding-left: 200px !important;
    }
}

@media (min-width: 1200px) {
    .pd-xl-0 {
        padding: 0px;
    }

    .pd-xl-0-f {
        padding: 0px !important;
    }

    .pd-xl-y-0 {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .pd-xl-y-0-f {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .pd-xl-x-0 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .pd-xl-x-0-f {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .pd-xl-t-0 {
        padding-top: 0px;
    }

    .pd-xl-r-0 {
        padding-right: 0px;
    }

    .pd-xl-b-0 {
        padding-bottom: 0px;
    }

    .pd-xl-l-0 {
        padding-left: 0px;
    }

    .pd-xl-t-0-f {
        padding-top: 0px !important;
    }

    .pd-xl-r-0-f {
        padding-right: 0px !important;
    }

    .pd-xl-b-0-f {
        padding-bottom: 0px !important;
    }

    .pd-xl-l-0-f {
        padding-left: 0px !important;
    }

    .pd-xl-1 {
        padding: 1px;
    }

    .pd-xl-1-f {
        padding: 1px !important;
    }

    .pd-xl-y-1 {
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .pd-xl-y-1-f {
        padding-top: 1px !important;
        padding-bottom: 1px !important;
    }

    .pd-xl-x-1 {
        padding-left: 1px;
        padding-right: 1px;
    }

    .pd-xl-x-1-f {
        padding-left: 1px !important;
        padding-right: 1px !important;
    }

    .pd-xl-t-1 {
        padding-top: 1px;
    }

    .pd-xl-r-1 {
        padding-right: 1px;
    }

    .pd-xl-b-1 {
        padding-bottom: 1px;
    }

    .pd-xl-l-1 {
        padding-left: 1px;
    }

    .pd-xl-t-1-f {
        padding-top: 1px !important;
    }

    .pd-xl-r-1-f {
        padding-right: 1px !important;
    }

    .pd-xl-b-1-f {
        padding-bottom: 1px !important;
    }

    .pd-xl-l-1-f {
        padding-left: 1px !important;
    }

    .pd-xl-2 {
        padding: 2px;
    }

    .pd-xl-2-f {
        padding: 2px !important;
    }

    .pd-xl-y-2 {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .pd-xl-y-2-f {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }

    .pd-xl-x-2 {
        padding-left: 2px;
        padding-right: 2px;
    }

    .pd-xl-x-2-f {
        padding-left: 2px !important;
        padding-right: 2px !important;
    }

    .pd-xl-t-2 {
        padding-top: 2px;
    }

    .pd-xl-r-2 {
        padding-right: 2px;
    }

    .pd-xl-b-2 {
        padding-bottom: 2px;
    }

    .pd-xl-l-2 {
        padding-left: 2px;
    }

    .pd-xl-t-2-f {
        padding-top: 2px !important;
    }

    .pd-xl-r-2-f {
        padding-right: 2px !important;
    }

    .pd-xl-b-2-f {
        padding-bottom: 2px !important;
    }

    .pd-xl-l-2-f {
        padding-left: 2px !important;
    }

    .pd-xl-3 {
        padding: 3px;
    }

    .pd-xl-3-f {
        padding: 3px !important;
    }

    .pd-xl-y-3 {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .pd-xl-y-3-f {
        padding-top: 3px !important;
        padding-bottom: 3px !important;
    }

    .pd-xl-x-3 {
        padding-left: 3px;
        padding-right: 3px;
    }

    .pd-xl-x-3-f {
        padding-left: 3px !important;
        padding-right: 3px !important;
    }

    .pd-xl-t-3 {
        padding-top: 3px;
    }

    .pd-xl-r-3 {
        padding-right: 3px;
    }

    .pd-xl-b-3 {
        padding-bottom: 3px;
    }

    .pd-xl-l-3 {
        padding-left: 3px;
    }

    .pd-xl-t-3-f {
        padding-top: 3px !important;
    }

    .pd-xl-r-3-f {
        padding-right: 3px !important;
    }

    .pd-xl-b-3-f {
        padding-bottom: 3px !important;
    }

    .pd-xl-l-3-f {
        padding-left: 3px !important;
    }

    .pd-xl-4 {
        padding: 4px;
    }

    .pd-xl-4-f {
        padding: 4px !important;
    }

    .pd-xl-y-4 {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .pd-xl-y-4-f {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
    }

    .pd-xl-x-4 {
        padding-left: 4px;
        padding-right: 4px;
    }

    .pd-xl-x-4-f {
        padding-left: 4px !important;
        padding-right: 4px !important;
    }

    .pd-xl-t-4 {
        padding-top: 4px;
    }

    .pd-xl-r-4 {
        padding-right: 4px;
    }

    .pd-xl-b-4 {
        padding-bottom: 4px;
    }

    .pd-xl-l-4 {
        padding-left: 4px;
    }

    .pd-xl-t-4-f {
        padding-top: 4px !important;
    }

    .pd-xl-r-4-f {
        padding-right: 4px !important;
    }

    .pd-xl-b-4-f {
        padding-bottom: 4px !important;
    }

    .pd-xl-l-4-f {
        padding-left: 4px !important;
    }

    .pd-xl-5 {
        padding: 5px;
    }

    .pd-xl-5-f {
        padding: 5px !important;
    }

    .pd-xl-y-5 {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .pd-xl-y-5-f {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }

    .pd-xl-x-5 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .pd-xl-x-5-f {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    .pd-xl-t-5 {
        padding-top: 5px;
    }

    .pd-xl-r-5 {
        padding-right: 5px;
    }

    .pd-xl-b-5 {
        padding-bottom: 5px;
    }

    .pd-xl-l-5 {
        padding-left: 5px;
    }

    .pd-xl-t-5-f {
        padding-top: 5px !important;
    }

    .pd-xl-r-5-f {
        padding-right: 5px !important;
    }

    .pd-xl-b-5-f {
        padding-bottom: 5px !important;
    }

    .pd-xl-l-5-f {
        padding-left: 5px !important;
    }

    .pd-xl-6 {
        padding: 6px;
    }

    .pd-xl-6-f {
        padding: 6px !important;
    }

    .pd-xl-y-6 {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .pd-xl-y-6-f {
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }

    .pd-xl-x-6 {
        padding-left: 6px;
        padding-right: 6px;
    }

    .pd-xl-x-6-f {
        padding-left: 6px !important;
        padding-right: 6px !important;
    }

    .pd-xl-t-6 {
        padding-top: 6px;
    }

    .pd-xl-r-6 {
        padding-right: 6px;
    }

    .pd-xl-b-6 {
        padding-bottom: 6px;
    }

    .pd-xl-l-6 {
        padding-left: 6px;
    }

    .pd-xl-t-6-f {
        padding-top: 6px !important;
    }

    .pd-xl-r-6-f {
        padding-right: 6px !important;
    }

    .pd-xl-b-6-f {
        padding-bottom: 6px !important;
    }

    .pd-xl-l-6-f {
        padding-left: 6px !important;
    }

    .pd-xl-7 {
        padding: 7px;
    }

    .pd-xl-7-f {
        padding: 7px !important;
    }

    .pd-xl-y-7 {
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .pd-xl-y-7-f {
        padding-top: 7px !important;
        padding-bottom: 7px !important;
    }

    .pd-xl-x-7 {
        padding-left: 7px;
        padding-right: 7px;
    }

    .pd-xl-x-7-f {
        padding-left: 7px !important;
        padding-right: 7px !important;
    }

    .pd-xl-t-7 {
        padding-top: 7px;
    }

    .pd-xl-r-7 {
        padding-right: 7px;
    }

    .pd-xl-b-7 {
        padding-bottom: 7px;
    }

    .pd-xl-l-7 {
        padding-left: 7px;
    }

    .pd-xl-t-7-f {
        padding-top: 7px !important;
    }

    .pd-xl-r-7-f {
        padding-right: 7px !important;
    }

    .pd-xl-b-7-f {
        padding-bottom: 7px !important;
    }

    .pd-xl-l-7-f {
        padding-left: 7px !important;
    }

    .pd-xl-8 {
        padding: 8px;
    }

    .pd-xl-8-f {
        padding: 8px !important;
    }

    .pd-xl-y-8 {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .pd-xl-y-8-f {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }

    .pd-xl-x-8 {
        padding-left: 8px;
        padding-right: 8px;
    }

    .pd-xl-x-8-f {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }

    .pd-xl-t-8 {
        padding-top: 8px;
    }

    .pd-xl-r-8 {
        padding-right: 8px;
    }

    .pd-xl-b-8 {
        padding-bottom: 8px;
    }

    .pd-xl-l-8 {
        padding-left: 8px;
    }

    .pd-xl-t-8-f {
        padding-top: 8px !important;
    }

    .pd-xl-r-8-f {
        padding-right: 8px !important;
    }

    .pd-xl-b-8-f {
        padding-bottom: 8px !important;
    }

    .pd-xl-l-8-f {
        padding-left: 8px !important;
    }

    .pd-xl-9 {
        padding: 9px;
    }

    .pd-xl-9-f {
        padding: 9px !important;
    }

    .pd-xl-y-9 {
        padding-top: 9px;
        padding-bottom: 9px;
    }

    .pd-xl-y-9-f {
        padding-top: 9px !important;
        padding-bottom: 9px !important;
    }

    .pd-xl-x-9 {
        padding-left: 9px;
        padding-right: 9px;
    }

    .pd-xl-x-9-f {
        padding-left: 9px !important;
        padding-right: 9px !important;
    }

    .pd-xl-t-9 {
        padding-top: 9px;
    }

    .pd-xl-r-9 {
        padding-right: 9px;
    }

    .pd-xl-b-9 {
        padding-bottom: 9px;
    }

    .pd-xl-l-9 {
        padding-left: 9px;
    }

    .pd-xl-t-9-f {
        padding-top: 9px !important;
    }

    .pd-xl-r-9-f {
        padding-right: 9px !important;
    }

    .pd-xl-b-9-f {
        padding-bottom: 9px !important;
    }

    .pd-xl-l-9-f {
        padding-left: 9px !important;
    }

    .pd-xl-10 {
        padding: 10px;
    }

    .pd-xl-10-f {
        padding: 10px !important;
    }

    .pd-xl-y-10 {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .pd-xl-y-10-f {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    .pd-xl-x-10 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .pd-xl-x-10-f {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .pd-xl-t-10 {
        padding-top: 10px;
    }

    .pd-xl-r-10 {
        padding-right: 10px;
    }

    .pd-xl-b-10 {
        padding-bottom: 10px;
    }

    .pd-xl-l-10 {
        padding-left: 10px;
    }

    .pd-xl-t-10-f {
        padding-top: 10px !important;
    }

    .pd-xl-r-10-f {
        padding-right: 10px !important;
    }

    .pd-xl-b-10-f {
        padding-bottom: 10px !important;
    }

    .pd-xl-l-10-f {
        padding-left: 10px !important;
    }

    .pd-xl-15 {
        padding: 15px;
    }

    .pd-xl-15-f {
        padding: 15px !important;
    }

    .pd-xl-y-15 {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .pd-xl-y-15-f {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }

    .pd-xl-x-15 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .pd-xl-x-15-f {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .pd-xl-t-15 {
        padding-top: 15px;
    }

    .pd-xl-r-15 {
        padding-right: 15px;
    }

    .pd-xl-b-15 {
        padding-bottom: 15px;
    }

    .pd-xl-l-15 {
        padding-left: 15px;
    }

    .pd-xl-t-15-f {
        padding-top: 15px !important;
    }

    .pd-xl-r-15-f {
        padding-right: 15px !important;
    }

    .pd-xl-b-15-f {
        padding-bottom: 15px !important;
    }

    .pd-xl-l-15-f {
        padding-left: 15px !important;
    }

    .pd-xl-20 {
        padding: 20px;
    }

    .pd-xl-20-f {
        padding: 20px !important;
    }

    .pd-xl-y-20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .pd-xl-y-20-f {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    .pd-xl-x-20 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .pd-xl-x-20-f {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .pd-xl-t-20 {
        padding-top: 20px;
    }

    .pd-xl-r-20 {
        padding-right: 20px;
    }

    .pd-xl-b-20 {
        padding-bottom: 20px;
    }

    .pd-xl-l-20 {
        padding-left: 20px;
    }

    .pd-xl-t-20-f {
        padding-top: 20px !important;
    }

    .pd-xl-r-20-f {
        padding-right: 20px !important;
    }

    .pd-xl-b-20-f {
        padding-bottom: 20px !important;
    }

    .pd-xl-l-20-f {
        padding-left: 20px !important;
    }

    .pd-xl-25 {
        padding: 25px;
    }

    .pd-xl-25-f {
        padding: 25px !important;
    }

    .pd-xl-y-25 {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .pd-xl-y-25-f {
        padding-top: 25px !important;
        padding-bottom: 25px !important;
    }

    .pd-xl-x-25 {
        padding-left: 25px;
        padding-right: 25px;
    }

    .pd-xl-x-25-f {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }

    .pd-xl-t-25 {
        padding-top: 25px;
    }

    .pd-xl-r-25 {
        padding-right: 25px;
    }

    .pd-xl-b-25 {
        padding-bottom: 25px;
    }

    .pd-xl-l-25 {
        padding-left: 25px;
    }

    .pd-xl-t-25-f {
        padding-top: 25px !important;
    }

    .pd-xl-r-25-f {
        padding-right: 25px !important;
    }

    .pd-xl-b-25-f {
        padding-bottom: 25px !important;
    }

    .pd-xl-l-25-f {
        padding-left: 25px !important;
    }

    .pd-xl-30 {
        padding: 30px;
    }

    .pd-xl-30-f {
        padding: 30px !important;
    }

    .pd-xl-y-30 {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .pd-xl-y-30-f {
        padding-top: 30px !important;
        padding-bottom: 30px !important;
    }

    .pd-xl-x-30 {
        padding-left: 30px;
        padding-right: 30px;
    }

    .pd-xl-x-30-f {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    .pd-xl-t-30 {
        padding-top: 30px;
    }

    .pd-xl-r-30 {
        padding-right: 30px;
    }

    .pd-xl-b-30 {
        padding-bottom: 30px;
    }

    .pd-xl-l-30 {
        padding-left: 30px;
    }

    .pd-xl-t-30-f {
        padding-top: 30px !important;
    }

    .pd-xl-r-30-f {
        padding-right: 30px !important;
    }

    .pd-xl-b-30-f {
        padding-bottom: 30px !important;
    }

    .pd-xl-l-30-f {
        padding-left: 30px !important;
    }

    .pd-xl-35 {
        padding: 35px;
    }

    .pd-xl-35-f {
        padding: 35px !important;
    }

    .pd-xl-y-35 {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .pd-xl-y-35-f {
        padding-top: 35px !important;
        padding-bottom: 35px !important;
    }

    .pd-xl-x-35 {
        padding-left: 35px;
        padding-right: 35px;
    }

    .pd-xl-x-35-f {
        padding-left: 35px !important;
        padding-right: 35px !important;
    }

    .pd-xl-t-35 {
        padding-top: 35px;
    }

    .pd-xl-r-35 {
        padding-right: 35px;
    }

    .pd-xl-b-35 {
        padding-bottom: 35px;
    }

    .pd-xl-l-35 {
        padding-left: 35px;
    }

    .pd-xl-t-35-f {
        padding-top: 35px !important;
    }

    .pd-xl-r-35-f {
        padding-right: 35px !important;
    }

    .pd-xl-b-35-f {
        padding-bottom: 35px !important;
    }

    .pd-xl-l-35-f {
        padding-left: 35px !important;
    }

    .pd-xl-40 {
        padding: 40px;
    }

    .pd-xl-40-f {
        padding: 40px !important;
    }

    .pd-xl-y-40 {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .pd-xl-y-40-f {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }

    .pd-xl-x-40 {
        padding-left: 40px;
        padding-right: 40px;
    }

    .pd-xl-x-40-f {
        padding-left: 40px !important;
        padding-right: 40px !important;
    }

    .pd-xl-t-40 {
        padding-top: 40px;
    }

    .pd-xl-r-40 {
        padding-right: 40px;
    }

    .pd-xl-b-40 {
        padding-bottom: 40px;
    }

    .pd-xl-l-40 {
        padding-left: 40px;
    }

    .pd-xl-t-40-f {
        padding-top: 40px !important;
    }

    .pd-xl-r-40-f {
        padding-right: 40px !important;
    }

    .pd-xl-b-40-f {
        padding-bottom: 40px !important;
    }

    .pd-xl-l-40-f {
        padding-left: 40px !important;
    }

    .pd-xl-45 {
        padding: 45px;
    }

    .pd-xl-45-f {
        padding: 45px !important;
    }

    .pd-xl-y-45 {
        padding-top: 45px;
        padding-bottom: 45px;
    }

    .pd-xl-y-45-f {
        padding-top: 45px !important;
        padding-bottom: 45px !important;
    }

    .pd-xl-x-45 {
        padding-left: 45px;
        padding-right: 45px;
    }

    .pd-xl-x-45-f {
        padding-left: 45px !important;
        padding-right: 45px !important;
    }

    .pd-xl-t-45 {
        padding-top: 45px;
    }

    .pd-xl-r-45 {
        padding-right: 45px;
    }

    .pd-xl-b-45 {
        padding-bottom: 45px;
    }

    .pd-xl-l-45 {
        padding-left: 45px;
    }

    .pd-xl-t-45-f {
        padding-top: 45px !important;
    }

    .pd-xl-r-45-f {
        padding-right: 45px !important;
    }

    .pd-xl-b-45-f {
        padding-bottom: 45px !important;
    }

    .pd-xl-l-45-f {
        padding-left: 45px !important;
    }

    .pd-xl-50 {
        padding: 50px;
    }

    .pd-xl-50-f {
        padding: 50px !important;
    }

    .pd-xl-y-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .pd-xl-y-50-f {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }

    .pd-xl-x-50 {
        padding-left: 50px;
        padding-right: 50px;
    }

    .pd-xl-x-50-f {
        padding-left: 50px !important;
        padding-right: 50px !important;
    }

    .pd-xl-t-50 {
        padding-top: 50px;
    }

    .pd-xl-r-50 {
        padding-right: 50px;
    }

    .pd-xl-b-50 {
        padding-bottom: 50px;
    }

    .pd-xl-l-50 {
        padding-left: 50px;
    }

    .pd-xl-t-50-f {
        padding-top: 50px !important;
    }

    .pd-xl-r-50-f {
        padding-right: 50px !important;
    }

    .pd-xl-b-50-f {
        padding-bottom: 50px !important;
    }

    .pd-xl-l-50-f {
        padding-left: 50px !important;
    }

    .pd-xl-55 {
        padding: 55px;
    }

    .pd-xl-55-f {
        padding: 55px !important;
    }

    .pd-xl-y-55 {
        padding-top: 55px;
        padding-bottom: 55px;
    }

    .pd-xl-y-55-f {
        padding-top: 55px !important;
        padding-bottom: 55px !important;
    }

    .pd-xl-x-55 {
        padding-left: 55px;
        padding-right: 55px;
    }

    .pd-xl-x-55-f {
        padding-left: 55px !important;
        padding-right: 55px !important;
    }

    .pd-xl-t-55 {
        padding-top: 55px;
    }

    .pd-xl-r-55 {
        padding-right: 55px;
    }

    .pd-xl-b-55 {
        padding-bottom: 55px;
    }

    .pd-xl-l-55 {
        padding-left: 55px;
    }

    .pd-xl-t-55-f {
        padding-top: 55px !important;
    }

    .pd-xl-r-55-f {
        padding-right: 55px !important;
    }

    .pd-xl-b-55-f {
        padding-bottom: 55px !important;
    }

    .pd-xl-l-55-f {
        padding-left: 55px !important;
    }

    .pd-xl-60 {
        padding: 60px;
    }

    .pd-xl-60-f {
        padding: 60px !important;
    }

    .pd-xl-y-60 {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .pd-xl-y-60-f {
        padding-top: 60px !important;
        padding-bottom: 60px !important;
    }

    .pd-xl-x-60 {
        padding-left: 60px;
        padding-right: 60px;
    }

    .pd-xl-x-60-f {
        padding-left: 60px !important;
        padding-right: 60px !important;
    }

    .pd-xl-t-60 {
        padding-top: 60px;
    }

    .pd-xl-r-60 {
        padding-right: 60px;
    }

    .pd-xl-b-60 {
        padding-bottom: 60px;
    }

    .pd-xl-l-60 {
        padding-left: 60px;
    }

    .pd-xl-t-60-f {
        padding-top: 60px !important;
    }

    .pd-xl-r-60-f {
        padding-right: 60px !important;
    }

    .pd-xl-b-60-f {
        padding-bottom: 60px !important;
    }

    .pd-xl-l-60-f {
        padding-left: 60px !important;
    }

    .pd-xl-65 {
        padding: 65px;
    }

    .pd-xl-65-f {
        padding: 65px !important;
    }

    .pd-xl-y-65 {
        padding-top: 65px;
        padding-bottom: 65px;
    }

    .pd-xl-y-65-f {
        padding-top: 65px !important;
        padding-bottom: 65px !important;
    }

    .pd-xl-x-65 {
        padding-left: 65px;
        padding-right: 65px;
    }

    .pd-xl-x-65-f {
        padding-left: 65px !important;
        padding-right: 65px !important;
    }

    .pd-xl-t-65 {
        padding-top: 65px;
    }

    .pd-xl-r-65 {
        padding-right: 65px;
    }

    .pd-xl-b-65 {
        padding-bottom: 65px;
    }

    .pd-xl-l-65 {
        padding-left: 65px;
    }

    .pd-xl-t-65-f {
        padding-top: 65px !important;
    }

    .pd-xl-r-65-f {
        padding-right: 65px !important;
    }

    .pd-xl-b-65-f {
        padding-bottom: 65px !important;
    }

    .pd-xl-l-65-f {
        padding-left: 65px !important;
    }

    .pd-xl-70 {
        padding: 70px;
    }

    .pd-xl-70-f {
        padding: 70px !important;
    }

    .pd-xl-y-70 {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .pd-xl-y-70-f {
        padding-top: 70px !important;
        padding-bottom: 70px !important;
    }

    .pd-xl-x-70 {
        padding-left: 70px;
        padding-right: 70px;
    }

    .pd-xl-x-70-f {
        padding-left: 70px !important;
        padding-right: 70px !important;
    }

    .pd-xl-t-70 {
        padding-top: 70px;
    }

    .pd-xl-r-70 {
        padding-right: 70px;
    }

    .pd-xl-b-70 {
        padding-bottom: 70px;
    }

    .pd-xl-l-70 {
        padding-left: 70px;
    }

    .pd-xl-t-70-f {
        padding-top: 70px !important;
    }

    .pd-xl-r-70-f {
        padding-right: 70px !important;
    }

    .pd-xl-b-70-f {
        padding-bottom: 70px !important;
    }

    .pd-xl-l-70-f {
        padding-left: 70px !important;
    }

    .pd-xl-75 {
        padding: 75px;
    }

    .pd-xl-75-f {
        padding: 75px !important;
    }

    .pd-xl-y-75 {
        padding-top: 75px;
        padding-bottom: 75px;
    }

    .pd-xl-y-75-f {
        padding-top: 75px !important;
        padding-bottom: 75px !important;
    }

    .pd-xl-x-75 {
        padding-left: 75px;
        padding-right: 75px;
    }

    .pd-xl-x-75-f {
        padding-left: 75px !important;
        padding-right: 75px !important;
    }

    .pd-xl-t-75 {
        padding-top: 75px;
    }

    .pd-xl-r-75 {
        padding-right: 75px;
    }

    .pd-xl-b-75 {
        padding-bottom: 75px;
    }

    .pd-xl-l-75 {
        padding-left: 75px;
    }

    .pd-xl-t-75-f {
        padding-top: 75px !important;
    }

    .pd-xl-r-75-f {
        padding-right: 75px !important;
    }

    .pd-xl-b-75-f {
        padding-bottom: 75px !important;
    }

    .pd-xl-l-75-f {
        padding-left: 75px !important;
    }

    .pd-xl-80 {
        padding: 80px;
    }

    .pd-xl-80-f {
        padding: 80px !important;
    }

    .pd-xl-y-80 {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .pd-xl-y-80-f {
        padding-top: 80px !important;
        padding-bottom: 80px !important;
    }

    .pd-xl-x-80 {
        padding-left: 80px;
        padding-right: 80px;
    }

    .pd-xl-x-80-f {
        padding-left: 80px !important;
        padding-right: 80px !important;
    }

    .pd-xl-t-80 {
        padding-top: 80px;
    }

    .pd-xl-r-80 {
        padding-right: 80px;
    }

    .pd-xl-b-80 {
        padding-bottom: 80px;
    }

    .pd-xl-l-80 {
        padding-left: 80px;
    }

    .pd-xl-t-80-f {
        padding-top: 80px !important;
    }

    .pd-xl-r-80-f {
        padding-right: 80px !important;
    }

    .pd-xl-b-80-f {
        padding-bottom: 80px !important;
    }

    .pd-xl-l-80-f {
        padding-left: 80px !important;
    }

    .pd-xl-85 {
        padding: 85px;
    }

    .pd-xl-85-f {
        padding: 85px !important;
    }

    .pd-xl-y-85 {
        padding-top: 85px;
        padding-bottom: 85px;
    }

    .pd-xl-y-85-f {
        padding-top: 85px !important;
        padding-bottom: 85px !important;
    }

    .pd-xl-x-85 {
        padding-left: 85px;
        padding-right: 85px;
    }

    .pd-xl-x-85-f {
        padding-left: 85px !important;
        padding-right: 85px !important;
    }

    .pd-xl-t-85 {
        padding-top: 85px;
    }

    .pd-xl-r-85 {
        padding-right: 85px;
    }

    .pd-xl-b-85 {
        padding-bottom: 85px;
    }

    .pd-xl-l-85 {
        padding-left: 85px;
    }

    .pd-xl-t-85-f {
        padding-top: 85px !important;
    }

    .pd-xl-r-85-f {
        padding-right: 85px !important;
    }

    .pd-xl-b-85-f {
        padding-bottom: 85px !important;
    }

    .pd-xl-l-85-f {
        padding-left: 85px !important;
    }

    .pd-xl-90 {
        padding: 90px;
    }

    .pd-xl-90-f {
        padding: 90px !important;
    }

    .pd-xl-y-90 {
        padding-top: 90px;
        padding-bottom: 90px;
    }

    .pd-xl-y-90-f {
        padding-top: 90px !important;
        padding-bottom: 90px !important;
    }

    .pd-xl-x-90 {
        padding-left: 90px;
        padding-right: 90px;
    }

    .pd-xl-x-90-f {
        padding-left: 90px !important;
        padding-right: 90px !important;
    }

    .pd-xl-t-90 {
        padding-top: 90px;
    }

    .pd-xl-r-90 {
        padding-right: 90px;
    }

    .pd-xl-b-90 {
        padding-bottom: 90px;
    }

    .pd-xl-l-90 {
        padding-left: 90px;
    }

    .pd-xl-t-90-f {
        padding-top: 90px !important;
    }

    .pd-xl-r-90-f {
        padding-right: 90px !important;
    }

    .pd-xl-b-90-f {
        padding-bottom: 90px !important;
    }

    .pd-xl-l-90-f {
        padding-left: 90px !important;
    }

    .pd-xl-95 {
        padding: 95px;
    }

    .pd-xl-95-f {
        padding: 95px !important;
    }

    .pd-xl-y-95 {
        padding-top: 95px;
        padding-bottom: 95px;
    }

    .pd-xl-y-95-f {
        padding-top: 95px !important;
        padding-bottom: 95px !important;
    }

    .pd-xl-x-95 {
        padding-left: 95px;
        padding-right: 95px;
    }

    .pd-xl-x-95-f {
        padding-left: 95px !important;
        padding-right: 95px !important;
    }

    .pd-xl-t-95 {
        padding-top: 95px;
    }

    .pd-xl-r-95 {
        padding-right: 95px;
    }

    .pd-xl-b-95 {
        padding-bottom: 95px;
    }

    .pd-xl-l-95 {
        padding-left: 95px;
    }

    .pd-xl-t-95-f {
        padding-top: 95px !important;
    }

    .pd-xl-r-95-f {
        padding-right: 95px !important;
    }

    .pd-xl-b-95-f {
        padding-bottom: 95px !important;
    }

    .pd-xl-l-95-f {
        padding-left: 95px !important;
    }

    .pd-xl-100 {
        padding: 100px;
    }

    .pd-xl-100-f {
        padding: 100px !important;
    }

    .pd-xl-y-100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .pd-xl-y-100-f {
        padding-top: 100px !important;
        padding-bottom: 100px !important;
    }

    .pd-xl-x-100 {
        padding-left: 100px;
        padding-right: 100px;
    }

    .pd-xl-x-100-f {
        padding-left: 100px !important;
        padding-right: 100px !important;
    }

    .pd-xl-t-100 {
        padding-top: 100px;
    }

    .pd-xl-r-100 {
        padding-right: 100px;
    }

    .pd-xl-b-100 {
        padding-bottom: 100px;
    }

    .pd-xl-l-100 {
        padding-left: 100px;
    }

    .pd-xl-t-100-f {
        padding-top: 100px !important;
    }

    .pd-xl-r-100-f {
        padding-right: 100px !important;
    }

    .pd-xl-b-100-f {
        padding-bottom: 100px !important;
    }

    .pd-xl-l-100-f {
        padding-left: 100px !important;
    }

    .pd-xl-110 {
        padding: 110px;
    }

    .pd-xl-110-f {
        padding: 110px !important;
    }

    .pd-xl-y-110 {
        padding-top: 110px;
        padding-bottom: 110px;
    }

    .pd-xl-y-110-f {
        padding-top: 110px !important;
        padding-bottom: 110px !important;
    }

    .pd-xl-x-110 {
        padding-left: 110px;
        padding-right: 110px;
    }

    .pd-xl-x-110-f {
        padding-left: 110px !important;
        padding-right: 110px !important;
    }

    .pd-xl-t-110 {
        padding-top: 110px;
    }

    .pd-xl-r-110 {
        padding-right: 110px;
    }

    .pd-xl-b-110 {
        padding-bottom: 110px;
    }

    .pd-xl-l-110 {
        padding-left: 110px;
    }

    .pd-xl-t-110-f {
        padding-top: 110px !important;
    }

    .pd-xl-r-110-f {
        padding-right: 110px !important;
    }

    .pd-xl-b-110-f {
        padding-bottom: 110px !important;
    }

    .pd-xl-l-110-f {
        padding-left: 110px !important;
    }

    .pd-xl-120 {
        padding: 120px;
    }

    .pd-xl-120-f {
        padding: 120px !important;
    }

    .pd-xl-y-120 {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .pd-xl-y-120-f {
        padding-top: 120px !important;
        padding-bottom: 120px !important;
    }

    .pd-xl-x-120 {
        padding-left: 120px;
        padding-right: 120px;
    }

    .pd-xl-x-120-f {
        padding-left: 120px !important;
        padding-right: 120px !important;
    }

    .pd-xl-t-120 {
        padding-top: 120px;
    }

    .pd-xl-r-120 {
        padding-right: 120px;
    }

    .pd-xl-b-120 {
        padding-bottom: 120px;
    }

    .pd-xl-l-120 {
        padding-left: 120px;
    }

    .pd-xl-t-120-f {
        padding-top: 120px !important;
    }

    .pd-xl-r-120-f {
        padding-right: 120px !important;
    }

    .pd-xl-b-120-f {
        padding-bottom: 120px !important;
    }

    .pd-xl-l-120-f {
        padding-left: 120px !important;
    }

    .pd-xl-130 {
        padding: 130px;
    }

    .pd-xl-130-f {
        padding: 130px !important;
    }

    .pd-xl-y-130 {
        padding-top: 130px;
        padding-bottom: 130px;
    }

    .pd-xl-y-130-f {
        padding-top: 130px !important;
        padding-bottom: 130px !important;
    }

    .pd-xl-x-130 {
        padding-left: 130px;
        padding-right: 130px;
    }

    .pd-xl-x-130-f {
        padding-left: 130px !important;
        padding-right: 130px !important;
    }

    .pd-xl-t-130 {
        padding-top: 130px;
    }

    .pd-xl-r-130 {
        padding-right: 130px;
    }

    .pd-xl-b-130 {
        padding-bottom: 130px;
    }

    .pd-xl-l-130 {
        padding-left: 130px;
    }

    .pd-xl-t-130-f {
        padding-top: 130px !important;
    }

    .pd-xl-r-130-f {
        padding-right: 130px !important;
    }

    .pd-xl-b-130-f {
        padding-bottom: 130px !important;
    }

    .pd-xl-l-130-f {
        padding-left: 130px !important;
    }

    .pd-xl-140 {
        padding: 140px;
    }

    .pd-xl-140-f {
        padding: 140px !important;
    }

    .pd-xl-y-140 {
        padding-top: 140px;
        padding-bottom: 140px;
    }

    .pd-xl-y-140-f {
        padding-top: 140px !important;
        padding-bottom: 140px !important;
    }

    .pd-xl-x-140 {
        padding-left: 140px;
        padding-right: 140px;
    }

    .pd-xl-x-140-f {
        padding-left: 140px !important;
        padding-right: 140px !important;
    }

    .pd-xl-t-140 {
        padding-top: 140px;
    }

    .pd-xl-r-140 {
        padding-right: 140px;
    }

    .pd-xl-b-140 {
        padding-bottom: 140px;
    }

    .pd-xl-l-140 {
        padding-left: 140px;
    }

    .pd-xl-t-140-f {
        padding-top: 140px !important;
    }

    .pd-xl-r-140-f {
        padding-right: 140px !important;
    }

    .pd-xl-b-140-f {
        padding-bottom: 140px !important;
    }

    .pd-xl-l-140-f {
        padding-left: 140px !important;
    }

    .pd-xl-150 {
        padding: 150px;
    }

    .pd-xl-150-f {
        padding: 150px !important;
    }

    .pd-xl-y-150 {
        padding-top: 150px;
        padding-bottom: 150px;
    }

    .pd-xl-y-150-f {
        padding-top: 150px !important;
        padding-bottom: 150px !important;
    }

    .pd-xl-x-150 {
        padding-left: 150px;
        padding-right: 150px;
    }

    .pd-xl-x-150-f {
        padding-left: 150px !important;
        padding-right: 150px !important;
    }

    .pd-xl-t-150 {
        padding-top: 150px;
    }

    .pd-xl-r-150 {
        padding-right: 150px;
    }

    .pd-xl-b-150 {
        padding-bottom: 150px;
    }

    .pd-xl-l-150 {
        padding-left: 150px;
    }

    .pd-xl-t-150-f {
        padding-top: 150px !important;
    }

    .pd-xl-r-150-f {
        padding-right: 150px !important;
    }

    .pd-xl-b-150-f {
        padding-bottom: 150px !important;
    }

    .pd-xl-l-150-f {
        padding-left: 150px !important;
    }

    .pd-xl-160 {
        padding: 160px;
    }

    .pd-xl-160-f {
        padding: 160px !important;
    }

    .pd-xl-y-160 {
        padding-top: 160px;
        padding-bottom: 160px;
    }

    .pd-xl-y-160-f {
        padding-top: 160px !important;
        padding-bottom: 160px !important;
    }

    .pd-xl-x-160 {
        padding-left: 160px;
        padding-right: 160px;
    }

    .pd-xl-x-160-f {
        padding-left: 160px !important;
        padding-right: 160px !important;
    }

    .pd-xl-t-160 {
        padding-top: 160px;
    }

    .pd-xl-r-160 {
        padding-right: 160px;
    }

    .pd-xl-b-160 {
        padding-bottom: 160px;
    }

    .pd-xl-l-160 {
        padding-left: 160px;
    }

    .pd-xl-t-160-f {
        padding-top: 160px !important;
    }

    .pd-xl-r-160-f {
        padding-right: 160px !important;
    }

    .pd-xl-b-160-f {
        padding-bottom: 160px !important;
    }

    .pd-xl-l-160-f {
        padding-left: 160px !important;
    }

    .pd-xl-170 {
        padding: 170px;
    }

    .pd-xl-170-f {
        padding: 170px !important;
    }

    .pd-xl-y-170 {
        padding-top: 170px;
        padding-bottom: 170px;
    }

    .pd-xl-y-170-f {
        padding-top: 170px !important;
        padding-bottom: 170px !important;
    }

    .pd-xl-x-170 {
        padding-left: 170px;
        padding-right: 170px;
    }

    .pd-xl-x-170-f {
        padding-left: 170px !important;
        padding-right: 170px !important;
    }

    .pd-xl-t-170 {
        padding-top: 170px;
    }

    .pd-xl-r-170 {
        padding-right: 170px;
    }

    .pd-xl-b-170 {
        padding-bottom: 170px;
    }

    .pd-xl-l-170 {
        padding-left: 170px;
    }

    .pd-xl-t-170-f {
        padding-top: 170px !important;
    }

    .pd-xl-r-170-f {
        padding-right: 170px !important;
    }

    .pd-xl-b-170-f {
        padding-bottom: 170px !important;
    }

    .pd-xl-l-170-f {
        padding-left: 170px !important;
    }

    .pd-xl-180 {
        padding: 180px;
    }

    .pd-xl-180-f {
        padding: 180px !important;
    }

    .pd-xl-y-180 {
        padding-top: 180px;
        padding-bottom: 180px;
    }

    .pd-xl-y-180-f {
        padding-top: 180px !important;
        padding-bottom: 180px !important;
    }

    .pd-xl-x-180 {
        padding-left: 180px;
        padding-right: 180px;
    }

    .pd-xl-x-180-f {
        padding-left: 180px !important;
        padding-right: 180px !important;
    }

    .pd-xl-t-180 {
        padding-top: 180px;
    }

    .pd-xl-r-180 {
        padding-right: 180px;
    }

    .pd-xl-b-180 {
        padding-bottom: 180px;
    }

    .pd-xl-l-180 {
        padding-left: 180px;
    }

    .pd-xl-t-180-f {
        padding-top: 180px !important;
    }

    .pd-xl-r-180-f {
        padding-right: 180px !important;
    }

    .pd-xl-b-180-f {
        padding-bottom: 180px !important;
    }

    .pd-xl-l-180-f {
        padding-left: 180px !important;
    }

    .pd-xl-190 {
        padding: 190px;
    }

    .pd-xl-190-f {
        padding: 190px !important;
    }

    .pd-xl-y-190 {
        padding-top: 190px;
        padding-bottom: 190px;
    }

    .pd-xl-y-190-f {
        padding-top: 190px !important;
        padding-bottom: 190px !important;
    }

    .pd-xl-x-190 {
        padding-left: 190px;
        padding-right: 190px;
    }

    .pd-xl-x-190-f {
        padding-left: 190px !important;
        padding-right: 190px !important;
    }

    .pd-xl-t-190 {
        padding-top: 190px;
    }

    .pd-xl-r-190 {
        padding-right: 190px;
    }

    .pd-xl-b-190 {
        padding-bottom: 190px;
    }

    .pd-xl-l-190 {
        padding-left: 190px;
    }

    .pd-xl-t-190-f {
        padding-top: 190px !important;
    }

    .pd-xl-r-190-f {
        padding-right: 190px !important;
    }

    .pd-xl-b-190-f {
        padding-bottom: 190px !important;
    }

    .pd-xl-l-190-f {
        padding-left: 190px !important;
    }

    .pd-xl-200 {
        padding: 200px;
    }

    .pd-xl-200-f {
        padding: 200px !important;
    }

    .pd-xl-y-200 {
        padding-top: 200px;
        padding-bottom: 200px;
    }

    .pd-xl-y-200-f {
        padding-top: 200px !important;
        padding-bottom: 200px !important;
    }

    .pd-xl-x-200 {
        padding-left: 200px;
        padding-right: 200px;
    }

    .pd-xl-x-200-f {
        padding-left: 200px !important;
        padding-right: 200px !important;
    }

    .pd-xl-t-200 {
        padding-top: 200px;
    }

    .pd-xl-r-200 {
        padding-right: 200px;
    }

    .pd-xl-b-200 {
        padding-bottom: 200px;
    }

    .pd-xl-l-200 {
        padding-left: 200px;
    }

    .pd-xl-t-200-f {
        padding-top: 200px !important;
    }

    .pd-xl-r-200-f {
        padding-right: 200px !important;
    }

    .pd-xl-b-200-f {
        padding-bottom: 200px !important;
    }

    .pd-xl-l-200-f {
        padding-left: 200px !important;
    }
}

.pd-12 {
    padding: 12px;
}

.pd-12-f {
    padding: 12px !important;
}

.pd-y-12 {
    padding-top: 12px;
    padding-bottom: 12px;
}

.pd-y-12-f {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.pd-x-12 {
    padding-left: 12px;
    padding-right: 12px;
}

.pd-x-12-f {
    padding-left: 12px !important;
    padding-right: 12px !important;
}

.pd-t-12 {
    padding-top: 12px;
}

.pd-r-12 {
    padding-right: 12px;
}

.pd-b-12 {
    padding-bottom: 12px;
}

.pd-l-12 {
    padding-left: 12px;
}

.pd-t-12-f {
    padding-top: 12px !important;
}

.pd-r-12-f {
    padding-right: 12px !important;
}

.pd-b-12-f {
    padding-bottom: 12px !important;
}

.pd-l-12-f {
    padding-left: 12px !important;
}

.pd-13 {
    padding: 13px;
}

.pd-13-f {
    padding: 13px !important;
}

.pd-y-13 {
    padding-top: 13px;
    padding-bottom: 13px;
}

.pd-y-13-f {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
}

.pd-x-13 {
    padding-left: 13px;
    padding-right: 13px;
}

.pd-x-13-f {
    padding-left: 13px !important;
    padding-right: 13px !important;
}

.pd-t-13 {
    padding-top: 13px;
}

.pd-r-13 {
    padding-right: 13px;
}

.pd-b-13 {
    padding-bottom: 13px;
}

.pd-l-13 {
    padding-left: 13px;
}

.pd-t-13-f {
    padding-top: 13px !important;
}

.pd-r-13-f {
    padding-right: 13px !important;
}

.pd-b-13-f {
    padding-bottom: 13px !important;
}

.pd-l-13-f {
    padding-left: 13px !important;
}

/* ###### 9.7 Position   ###### */
.pos-absolute {
    position: absolute;
}

.pos-relative {
    position: relative;
}

.pos-fixed {
    position: fixed;
}

.pos-static {
    position: static;
}

.pos-absolute-f {
    position: absolute;
}

.pos-relative-f {
    position: relative;
}

.pos-fixed-f {
    position: fixed;
}

.pos-static-f {
    position: static;
}

.t-0 {
    top: 0px;
}

.r-0 {
    right: 0px;
}

.b-0 {
    bottom: 0px;
}

.l-0 {
    left: 0px;
}

.t--0 {
    top: 0px;
}

.r--0 {
    right: 0px;
}

.b--0 {
    bottom: 0px;
}

.l--0 {
    left: 0px;
}

.a-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.x-0,
.y-0 {
    left: 0px;
    right: 0px;
}

.t-5 {
    top: 5px;
}

.r-5 {
    right: 5px;
}

.b-5 {
    bottom: 5px;
}

.l-5 {
    left: 5px;
}

.t--5 {
    top: -5px;
}

.r--5 {
    right: -5px;
}

.b--5 {
    bottom: -5px;
}

.l--5 {
    left: -5px;
}

.a-5 {
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
}

.x-5,
.y-5 {
    left: 5px;
    right: 5px;
}

.t-10 {
    top: 10px;
}

.r-10 {
    right: 10px;
}

.b-10 {
    bottom: 10px;
}

.l-10 {
    left: 10px;
}

.t--10 {
    top: -10px;
}

.r--10 {
    right: -10px;
}

.b--10 {
    bottom: -10px;
}

.l--10 {
    left: -10px;
}

.a-10 {
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
}

.x-10,
.y-10 {
    left: 10px;
    right: 10px;
}

.t-15 {
    top: 15px;
}

.r-15 {
    right: 15px;
}

.b-15 {
    bottom: 15px;
}

.l-15 {
    left: 15px;
}

.t--15 {
    top: -15px;
}

.r--15 {
    right: -15px;
}

.b--15 {
    bottom: -15px;
}

.l--15 {
    left: -15px;
}

.a-15 {
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
}

.x-15,
.y-15 {
    left: 15px;
    right: 15px;
}

.t-20 {
    top: 20px;
}

.r-20 {
    right: 20px;
}

.b-20 {
    bottom: 20px;
}

.l-20 {
    left: 20px;
}

.t--20 {
    top: -20px;
}

.r--20 {
    right: -20px;
}

.b--20 {
    bottom: -20px;
}

.l--20 {
    left: -20px;
}

.a-20 {
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
}

.x-20,
.y-20 {
    left: 20px;
    right: 20px;
}

.t-25 {
    top: 25px;
}

.r-25 {
    right: 25px;
}

.b-25 {
    bottom: 25px;
}

.l-25 {
    left: 25px;
}

.t--25 {
    top: -25px;
}

.r--25 {
    right: -25px;
}

.b--25 {
    bottom: -25px;
}

.l--25 {
    left: -25px;
}

.a-25 {
    top: 25px;
    right: 25px;
    bottom: 25px;
    left: 25px;
}

.x-25,
.y-25 {
    left: 25px;
    right: 25px;
}

.t-30 {
    top: 30px;
}

.r-30 {
    right: 30px;
}

.b-30 {
    bottom: 30px;
}

.l-30 {
    left: 30px;
}

.t--30 {
    top: -30px;
}

.r--30 {
    right: -30px;
}

.b--30 {
    bottom: -30px;
}

.l--30 {
    left: -30px;
}

.a-30 {
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
}

.x-30,
.y-30 {
    left: 30px;
    right: 30px;
}

.t-35 {
    top: 35px;
}

.r-35 {
    right: 35px;
}

.b-35 {
    bottom: 35px;
}

.l-35 {
    left: 35px;
}

.t--35 {
    top: -35px;
}

.r--35 {
    right: -35px;
}

.b--35 {
    bottom: -35px;
}

.l--35 {
    left: -35px;
}

.a-35 {
    top: 35px;
    right: 35px;
    bottom: 35px;
    left: 35px;
}

.x-35,
.y-35 {
    left: 35px;
    right: 35px;
}

.t-40 {
    top: 40px;
}

.r-40 {
    right: 40px;
}

.b-40 {
    bottom: 40px;
}

.l-40 {
    left: 40px;
}

.t--40 {
    top: -40px;
}

.r--40 {
    right: -40px;
}

.b--40 {
    bottom: -40px;
}

.l--40 {
    left: -40px;
}

.a-40 {
    top: 40px;
    right: 40px;
    bottom: 40px;
    left: 40px;
}

.x-40,
.y-40 {
    left: 40px;
    right: 40px;
}

.t-45 {
    top: 45px;
}

.r-45 {
    right: 45px;
}

.b-45 {
    bottom: 45px;
}

.l-45 {
    left: 45px;
}

.t--45 {
    top: -45px;
}

.r--45 {
    right: -45px;
}

.b--45 {
    bottom: -45px;
}

.l--45 {
    left: -45px;
}

.a-45 {
    top: 45px;
    right: 45px;
    bottom: 45px;
    left: 45px;
}

.x-45,
.y-45 {
    left: 45px;
    right: 45px;
}

.t-50 {
    top: 50px;
}

.r-50 {
    right: 50px;
}

.b-50 {
    bottom: 50px;
}

.l-50 {
    left: 50px;
}

.t--50 {
    top: -50px;
}

.r--50 {
    right: -50px;
}

.b--50 {
    bottom: -50px;
}

.l--50 {
    left: -50px;
}

.a-50 {
    top: 50px;
    right: 50px;
    bottom: 50px;
    left: 50px;
}

.x-50,
.y-50 {
    left: 50px;
    right: 50px;
}

.t-55 {
    top: 55px;
}

.r-55 {
    right: 55px;
}

.b-55 {
    bottom: 55px;
}

.l-55 {
    left: 55px;
}

.t--55 {
    top: -55px;
}

.r--55 {
    right: -55px;
}

.b--55 {
    bottom: -55px;
}

.l--55 {
    left: -55px;
}

.a-55 {
    top: 55px;
    right: 55px;
    bottom: 55px;
    left: 55px;
}

.x-55,
.y-55 {
    left: 55px;
    right: 55px;
}

.t-60 {
    top: 60px;
}

.r-60 {
    right: 60px;
}

.b-60 {
    bottom: 60px;
}

.l-60 {
    left: 60px;
}

.t--60 {
    top: -60px;
}

.r--60 {
    right: -60px;
}

.b--60 {
    bottom: -60px;
}

.l--60 {
    left: -60px;
}

.a-60 {
    top: 60px;
    right: 60px;
    bottom: 60px;
    left: 60px;
}

.x-60,
.y-60 {
    left: 60px;
    right: 60px;
}

.t-65 {
    top: 65px;
}

.r-65 {
    right: 65px;
}

.b-65 {
    bottom: 65px;
}

.l-65 {
    left: 65px;
}

.t--65 {
    top: -65px;
}

.r--65 {
    right: -65px;
}

.b--65 {
    bottom: -65px;
}

.l--65 {
    left: -65px;
}

.a-65 {
    top: 65px;
    right: 65px;
    bottom: 65px;
    left: 65px;
}

.x-65,
.y-65 {
    left: 65px;
    right: 65px;
}

.t-70 {
    top: 70px;
}

.r-70 {
    right: 70px;
}

.b-70 {
    bottom: 70px;
}

.l-70 {
    left: 70px;
}

.t--70 {
    top: -70px;
}

.r--70 {
    right: -70px;
}

.b--70 {
    bottom: -70px;
}

.l--70 {
    left: -70px;
}

.a-70 {
    top: 70px;
    right: 70px;
    bottom: 70px;
    left: 70px;
}

.x-70,
.y-70 {
    left: 70px;
    right: 70px;
}

.t-75 {
    top: 75px;
}

.r-75 {
    right: 75px;
}

.b-75 {
    bottom: 75px;
}

.l-75 {
    left: 75px;
}

.t--75 {
    top: -75px;
}

.r--75 {
    right: -75px;
}

.b--75 {
    bottom: -75px;
}

.l--75 {
    left: -75px;
}

.a-75 {
    top: 75px;
    right: 75px;
    bottom: 75px;
    left: 75px;
}

.x-75,
.y-75 {
    left: 75px;
    right: 75px;
}

.t-80 {
    top: 80px;
}

.r-80 {
    right: 80px;
}

.b-80 {
    bottom: 80px;
}

.l-80 {
    left: 80px;
}

.t--80 {
    top: -80px;
}

.r--80 {
    right: -80px;
}

.b--80 {
    bottom: -80px;
}

.l--80 {
    left: -80px;
}

.a-80 {
    top: 80px;
    right: 80px;
    bottom: 80px;
    left: 80px;
}

.x-80,
.y-80 {
    left: 80px;
    right: 80px;
}

.t-85 {
    top: 85px;
}

.r-85 {
    right: 85px;
}

.b-85 {
    bottom: 85px;
}

.l-85 {
    left: 85px;
}

.t--85 {
    top: -85px;
}

.r--85 {
    right: -85px;
}

.b--85 {
    bottom: -85px;
}

.l--85 {
    left: -85px;
}

.a-85 {
    top: 85px;
    right: 85px;
    bottom: 85px;
    left: 85px;
}

.x-85,
.y-85 {
    left: 85px;
    right: 85px;
}

.t-90 {
    top: 90px;
}

.r-90 {
    right: 90px;
}

.b-90 {
    bottom: 90px;
}

.l-90 {
    left: 90px;
}

.t--90 {
    top: -90px;
}

.r--90 {
    right: -90px;
}

.b--90 {
    bottom: -90px;
}

.l--90 {
    left: -90px;
}

.a-90 {
    top: 90px;
    right: 90px;
    bottom: 90px;
    left: 90px;
}

.x-90,
.y-90 {
    left: 90px;
    right: 90px;
}

.t-95 {
    top: 95px;
}

.r-95 {
    right: 95px;
}

.b-95 {
    bottom: 95px;
}

.l-95 {
    left: 95px;
}

.t--95 {
    top: -95px;
}

.r--95 {
    right: -95px;
}

.b--95 {
    bottom: -95px;
}

.l--95 {
    left: -95px;
}

.a-95 {
    top: 95px;
    right: 95px;
    bottom: 95px;
    left: 95px;
}

.x-95,
.y-95 {
    left: 95px;
    right: 95px;
}

.t-100 {
    top: 100px;
}

.r-100 {
    right: 100px;
}

.b-100 {
    bottom: 100px;
}

.l-100 {
    left: 100px;
}

.t--100 {
    top: -100px;
}

.r--100 {
    right: -100px;
}

.b--100 {
    bottom: -100px;
}

.l--100 {
    left: -100px;
}

.a-100 {
    top: 100px;
    right: 100px;
    bottom: 100px;
    left: 100px;
}

.x-100,
.y-100 {
    left: 100px;
    right: 100px;
}

.t-2 {
    top: 2px;
}

.r--5 {
    right: -5px;
}

.z-index-0 {
    z-index: 0;
}

.z-index-10 {
    z-index: 10;
}

.z-index-50 {
    z-index: 50;
}

.z-index-100 {
    z-index: 100;
}

.z-index-150 {
    z-index: 150;
}

.z-index-200 {
    z-index: 200;
}

@media (min-width: 480px) {
    .pos-xs-absolute {
        position: absolute;
    }

    .pos-xs-relative {
        position: relative;
    }

    .pos-xs-fixed {
        position: fixed;
    }

    .pos-xs-static {
        position: static;
    }

    .t-xs-auto {
        top: auto;
    }

    .r-xs-auto {
        right: auto;
    }

    .b-xs-auto {
        bottom: auto;
    }

    .l-xs-auto {
        left: auto;
    }
}

@media (min-width: 576px) {
    .pos-sm-absolute {
        position: absolute;
    }

    .pos-sm-relative {
        position: relative;
    }

    .pos-sm-fixed {
        position: fixed;
    }

    .pos-sm-static {
        position: static;
    }

    .t-sm-auto {
        top: auto;
    }

    .r-sm-auto {
        right: auto;
    }

    .b-sm-auto {
        bottom: auto;
    }

    .l-sm-auto {
        left: auto;
    }
}

@media (min-width: 768px) {
    .pos-md-absolute {
        position: absolute;
    }

    .pos-md-relative {
        position: relative;
    }

    .pos-md-fixed {
        position: fixed;
    }

    .pos-md-static {
        position: static;
    }

    .t-md-auto {
        top: auto;
    }

    .r-md-auto {
        right: auto;
    }

    .b-md-auto {
        bottom: auto;
    }

    .l-md-auto {
        left: auto;
    }
}

@media (min-width: 992px) {
    .pos-lg-absolute {
        position: absolute;
    }

    .pos-lg-relative {
        position: relative;
    }

    .pos-lg-fixed {
        position: fixed;
    }

    .pos-lg-static {
        position: static;
    }

    .t-lg-auto {
        top: auto;
    }

    .r-lg-auto {
        right: auto;
    }

    .b-lg-auto {
        bottom: auto;
    }

    .l-lg-auto {
        left: auto;
    }
}

@media (min-width: 1200px) {
    .pos-xl-absolute {
        position: absolute;
    }

    .pos-xl-relative {
        position: relative;
    }

    .pos-xl-fixed {
        position: fixed;
    }

    .pos-xl-static {
        position: static;
    }

    .t-xl-auto {
        top: auto;
    }

    .r-xl-auto {
        right: auto;
    }

    .b-xl-auto {
        bottom: auto;
    }

    .l-xl-auto {
        left: auto;
    }
}

.position-static {
    position: static;
}

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.position-fixed {
    position: fixed;
}

.position-sticky {
    position: sticky;
}

/* ###### 9.8 Typography   ###### */
/* Estilos de cabeceras */
.heading-1,
.heading-1-medium,
.heading-1-bold {
    font-size: 60px;
    line-height: 68px;
    font-weight: 400;
}

.heading-1-medium {
    font-weight: 500;
}

.heading-1-bold {
    font-weight: 700;
}

.heading-2,
.heading-2-medium,
.heading-2-bold {
    font-size: 40px;
    line-height: 48px;
    font-weight: 400;
}

.heading-2-medium {
    font-weight: 500;
}

.heading-2-bold {
    font-weight: 700;
}

.heading-3,
.heading-3-medium,
.heading-3-bold {
    font-size: 32px;
    line-height: 40px;
    font-weight: 400;
}

.heading-3-medium {
    font-weight: 500;
}

.heading-3-bold {
    font-weight: 700;
}

.heading-4,
.heading-4-medium,
.heading-4-bold {
    font-size: 22px;
    line-height: 30px;
    font-weight: 400;
}

.heading-4-medium {
    font-weight: 500;
}

.heading-4-bold {
    font-weight: 700;
}

.heading-5,
.heading-5-medium,
.heading-5-bold {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
}

.heading-5-medium {
    font-weight: 500;
}

.heading-5-bold {
    font-weight: 700;
}

.heading-6,
.heading-6-medium,
.heading-6-bold {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
}

.heading-6-medium {
    font-weight: 500;
}

.heading-6-bold {
    font-weight: 700;
}

/* BODY STYLES */
.tx-body-1,
.tx-body-1-medium,
.tx-body-1-bold,
.accordion-button,
.panel-heading1 .panel-title1 a,
.table thead th,
.table thead td,
.table tbody tr th,
.table tbody tr td,
.ngx-datatable.custom-datatable .datatable-header .datatable-header-cell,
.ngx-datatable.custom-datatable .datatable-body .datatable-body-cell {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}

.tx-body-1-medium {
    font-weight: 500;
}

.tx-body-1-bold,
.accordion-button,
.panel-heading1 .panel-title1 a,
.table thead th,
.table thead td,
.table tbody tr th,
.ngx-datatable.custom-datatable .datatable-header .datatable-header-cell {
    font-weight: 700;
}

.tx-body-2,
.tx-body-2-medium,
.tx-body-2-bold,
.modal-accordion .accordion-button,
.pagination .page-link {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
}

.tx-body-2-medium {
    font-weight: 500;
}

.tx-body-2-bold,
.modal-accordion .accordion-button {
    font-weight: 700;
}

/* TAG STYLES */
.tx-tag {
    font-size: 10px;
    line-height: 14px;
    font-weight: 400;
}

.tx-tag-medium {
    font-size: 10px;
    line-height: 14px;
    font-weight: 500;
}

.tx-tag-card {
    font-size: 8px;
    line-height: 10px;
    font-weight: 400;
}

.tx-tag-card-medium {
    font-size: 8px;
    line-height: 10px;
    font-weight: 500;
}

/* FORM STYLES */
.tx-button {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
}

.tx-black {
    font-weight: 900;
}

.tx-bold {
    font-weight: 700;
}

.tx-semibold {
    font-weight: 600;
}

.tx-medium {
    font-weight: 500;
}

.tx-normal {
    font-weight: 400;
}

.tx-light {
    font-weight: 300;
}

.tx-thin {
    font-weight: 200;
}

.tx-xthin {
    font-weight: 100;
}

.tx-sserif {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.tx-roboto {
    font-family: "Roboto", sans-serif;
}

.tx-white {
    color: #fff;
}

.tx-facebook {
    color: #3b5998;
}

.tx-pinterest {
    color: #cd2029;
}

.tx-google-plus {
    color: #d34836;
}

.tx-twitter {
    color: #429cd6;
}

.tx-black {
    color: #000;
}

.tx-primary {
    color: var(--primary);
}

.tx-primary-75 {
    color: var(--primary-75);
}

.tx-success {
    color: var(--success);
}

.tx-warning {
    color: var(--warning);
}

.tx-danger {
    color: #f34343;
}

.tx-info {
    color: var(--info);
}

.tx-inverse {
    color: #7987a1;
}

.tx-teal {
    color: #29ddee;
}

.tx-dark {
    color: #323251;
}

.tx-indigo {
    color: var(--primary);
}

.tx-purple {
    color: #ad4ffa;
}

.tx-orange {
    color: #fd7e14;
}

.tx-pink {
    color: #f754fa;
}

.tx-gray-100 {
    color: #f9f9fb;
}

.tx-gray-200 {
    color: #f0f0f8;
}

.tx-gray-300 {
    color: #e1e1ef;
}

.tx-gray-400 {
    color: #d6d6e6;
}

.tx-gray-500 {
    color: #949eb7;
}

.tx-gray-600 {
    color: #7987a1;
}

.tx-gray-700 {
    color: #4d5875;
}

.tx-gray-800 {
    color: #383853;
}

.tx-gray-900 {
    color: #323251;
}

.tx-white-2 {
    color: rgba(255, 255, 255, 0.2);
}

.tx-white-3 {
    color: rgba(255, 255, 255, 0.3);
}

.tx-white-4 {
    color: rgba(255, 255, 255, 0.4);
}

.tx-white-5 {
    color: rgba(255, 255, 255, 0.5);
}

.tx-white-6 {
    color: rgba(255, 255, 255, 0.6);
}

.tx-white-7 {
    color: rgba(255, 255, 255, 0.7);
}

.tx-white-8 {
    color: rgba(255, 255, 255, 0.8);
}

.tx-neutral {
    color: var(--neutral-text);
}

.tx-neutral-20 {
    color: var(--neutral-20);
}

.tx-neutral-30 {
    color: var(--neutral-30);
}

.tx-neutral-40 {
    color: var(--neutral-40);
}

.tx-neutral-50 {
    color: var(--neutral-50);
}

.tx-neutral-60 {
    color: var(--neutral-60);
}

.tx-neutral-70 {
    color: var(--neutral-70);
}

.tx-neutral-80 {
    color: var(--neutral-80);
}

.tx-neutral-90 {
    color: var(--neutral-90);
}

.tx-neutral-darker {
    color: var(--neutral-darker);
}

.tx-spacing-1 {
    letter-spacing: 0.5px;
}

.tx-spacing-2 {
    letter-spacing: 1px;
}

.tx-spacing-3 {
    letter-spacing: 1.5px;
}

.tx-spacing-4 {
    letter-spacing: 2px;
}

.tx-spacing-5 {
    letter-spacing: 2.5px;
}

.tx-spacing-6 {
    letter-spacing: 3px;
}

.tx-spacing-7 {
    letter-spacing: 3.5px;
}

.tx-spacing-8 {
    letter-spacing: 4px;
}

.tx-spacing--1 {
    letter-spacing: -0.5px;
}

.tx-spacing--2 {
    letter-spacing: -1px;
}

.tx-spacing--3 {
    letter-spacing: -1.5px;
}

.tx-spacing--4 {
    letter-spacing: -2px;
}

.tx-spacing--5 {
    letter-spacing: -2.5px;
}

.tx-spacing--6 {
    letter-spacing: -3px;
}

.tx-spacing--7 {
    letter-spacing: -3.5px;
}

.tx-spacing--8 {
    letter-spacing: -4px;
}

.tx-uppercase {
    text-transform: uppercase;
}

.tx-lowercase {
    text-transform: lowercase;
}

.tx-transform-none {
    text-transform: none;
}

.tx-center {
    text-align: center;
}

.tx-right {
    text-align: right;
}

.tx-left {
    text-align: left;
}

.tx-center-f {
    text-align: center;
}

.tx-right-f {
    text-align: right;
}

.tx-left-f {
    text-align: left;
}

.tx-italic {
    font-style: italic;
}

.tx-style-normal {
    font-style: normal;
}

.lh-base {
    line-height: 1.5;
}

.lh-normal {
    line-height: normal;
}

.lh-0 {
    line-height: 0;
}

.lh--1 {
    line-height: 0.1;
}

.lh--2 {
    line-height: 0.2;
}

.lh--3 {
    line-height: 0.3;
}

.lh--4 {
    line-height: 0.4;
}

.lh--5 {
    line-height: 0.5;
}

.lh--6 {
    line-height: 0.6;
}

.lh--7 {
    line-height: 0.7;
}

.lh--8 {
    line-height: 0.8;
}

.lh--9 {
    line-height: 0.9;
}

.lh-1 {
    line-height: 1.1;
}

.lh-2 {
    line-height: 1.2;
}

.lh-3 {
    line-height: 1.3;
}

.lh-4 {
    line-height: 1.4;
}

.lh-5 {
    line-height: 1.5;
}

.lh-6 {
    line-height: 1.6;
}

.lh-7 {
    line-height: 1.7;
}

.lh-8 {
    line-height: 1.8;
}

.lh-9 {
    line-height: 1.9;
}

.lh-10 {
    line-height: 2;
}

.lh-11 {
    line-height: 2.1;
}

.lh-12 {
    line-height: 2.2;
}

.lh-13 {
    line-height: 2.3;
}

.lh-14 {
    line-height: 2.4;
}

.lh-15 {
    line-height: 2.5;
}

.valign-top {
    vertical-align: top;
}

.valign-middle {
    vertical-align: middle;
}

.valign-bottom {
    vertical-align: baseline;
}

.valign-top-f {
    vertical-align: top;
}

.valign-middle-f {
    vertical-align: middle;
}

.valign-bottom-f {
    vertical-align: baseline;
}

.tx-base {
    font-size: 0.875rem;
}

.tx-8 {
    font-size: 8px;
}

.tx-8-f {
    font-size: 8px;
}

.tx-9 {
    font-size: 9px;
}

.tx-9-f {
    font-size: 9px;
}

.tx-10 {
    font-size: 10px;
}

.tx-10-f {
    font-size: 10px;
}

.tx-11 {
    font-size: 11px;
}

.tx-11-f {
    font-size: 11px;
}

.tx-12 {
    font-size: 12px;
}

.tx-12-f {
    font-size: 12px;
}

.tx-13 {
    font-size: 13px;
}

.tx-13-f {
    font-size: 13px;
}

.tx-14 {
    font-size: 14px;
}

.tx-14-f {
    font-size: 14px;
}

.tx-15 {
    font-size: 15px !important;
}

.tx-15-f {
    font-size: 15px;
}

.tx-16 {
    font-size: 16px;
}

.tx-16-f {
    font-size: 16px;
}

.tx-17 {
    font-size: 17px;
}

.tx-18 {
    font-size: 18px !important;
}

.tx-18-f {
    font-size: 18px;
}

.tx-20 {
    font-size: 20px !important;
}

.tx-20-f {
    font-size: 20px;
}

.tx-21 {
    font-size: 21px;
}

.tx-21-f {
    font-size: 21px;
}

.tx-22 {
    font-size: 22px;
}

.tx-22-f {
    font-size: 22px;
}

.tx-24 {
    font-size: 24px;
}

.tx-24-f {
    font-size: 24px;
}

.tx-26 {
    font-size: 26px;
}

.tx-26-f {
    font-size: 26px;
}

.tx-28 {
    font-size: 28px;
}

.tx-28-f {
    font-size: 28px;
}

.tx-30 {
    font-size: 30px;
}

.tx-30-f {
    font-size: 30px;
}

.tx-32 {
    font-size: 32px;
}

.tx-32-f {
    font-size: 32px;
}

.tx-34 {
    font-size: 34px;
}

.tx-34-f {
    font-size: 34px;
}

.tx-36 {
    font-size: 36px;
}

.tx-36-f {
    font-size: 36px;
}

.tx-38 {
    font-size: 38px;
}

.tx-38-f {
    font-size: 38px;
}

.tx-40 {
    font-size: 40px;
}

.tx-40-f {
    font-size: 40px;
}

.tx-42 {
    font-size: 42px;
}

.tx-42-f {
    font-size: 42px;
}

.tx-44 {
    font-size: 44px;
}

.tx-44-f {
    font-size: 44px;
}

.tx-46 {
    font-size: 46px;
}

.tx-46-f {
    font-size: 46px;
}

.tx-48 {
    font-size: 48px;
}

.tx-48-f {
    font-size: 48px;
}

.tx-50 {
    font-size: 50px;
}

.tx-50-f {
    font-size: 50px;
}

.tx-52 {
    font-size: 52px;
}

.tx-52-f {
    font-size: 52px;
}

.tx-54 {
    font-size: 54px;
}

.tx-54-f {
    font-size: 54px;
}

.tx-56 {
    font-size: 56px;
}

.tx-56-f {
    font-size: 56px;
}

.tx-58 {
    font-size: 58px;
}

.tx-58-f {
    font-size: 58px;
}

.tx-60 {
    font-size: 60px;
}

.tx-60-f {
    font-size: 60px;
}

.tx-62 {
    font-size: 62px;
}

.tx-62-f {
    font-size: 62px;
}

.tx-64 {
    font-size: 64px;
}

.tx-64-f {
    font-size: 64px;
}

.tx-66 {
    font-size: 66px;
}

.tx-66-f {
    font-size: 66px;
}

.tx-68 {
    font-size: 68px;
}

.tx-68-f {
    font-size: 68px;
}

.tx-70 {
    font-size: 70px;
}

.tx-70-f {
    font-size: 70px;
}

.tx-72 {
    font-size: 72px;
}

.tx-72-f {
    font-size: 72px;
}

.tx-74 {
    font-size: 74px;
}

.tx-74-f {
    font-size: 74px;
}

.tx-76 {
    font-size: 76px;
}

.tx-76-f {
    font-size: 76px;
}

.tx-78 {
    font-size: 78px;
}

.tx-78-f {
    font-size: 78px;
}

.tx-80 {
    font-size: 80px;
}

.tx-80-f {
    font-size: 80px;
}

.tx-82 {
    font-size: 82px;
}

.tx-82-f {
    font-size: 82px;
}

.tx-84 {
    font-size: 84px;
}

.tx-84-f {
    font-size: 84px;
}

.tx-86 {
    font-size: 86px;
}

.tx-86-f {
    font-size: 86px;
}

.tx-88 {
    font-size: 88px;
}

.tx-88-f {
    font-size: 88px;
}

.tx-90 {
    font-size: 90px;
}

.tx-90-f {
    font-size: 90px;
}

.tx-92 {
    font-size: 92px;
}

.tx-92-f {
    font-size: 92px;
}

.tx-94 {
    font-size: 94px;
}

.tx-94-f {
    font-size: 94px;
}

.tx-96 {
    font-size: 96px;
}

.tx-96-f {
    font-size: 96px;
}

.tx-98 {
    font-size: 98px;
}

.tx-98-f {
    font-size: 98px;
}

.tx-100 {
    font-size: 100px;
}

.tx-100-f {
    font-size: 100px;
}

.tx-102 {
    font-size: 102px;
}

.tx-102-f {
    font-size: 102px;
}

.tx-104 {
    font-size: 104px;
}

.tx-104-f {
    font-size: 104px;
}

.tx-106 {
    font-size: 106px;
}

.tx-106-f {
    font-size: 106px;
}

.tx-108 {
    font-size: 108px;
}

.tx-108-f {
    font-size: 108px;
}

.tx-110 {
    font-size: 110px;
}

.tx-110-f {
    font-size: 110px;
}

.tx-112 {
    font-size: 112px;
}

.tx-112-f {
    font-size: 112px;
}

.tx-114 {
    font-size: 114px;
}

.tx-114-f {
    font-size: 114px;
}

.tx-116 {
    font-size: 116px;
}

.tx-116-f {
    font-size: 116px;
}

.tx-118 {
    font-size: 118px;
}

.tx-118-f {
    font-size: 118px;
}

.tx-120 {
    font-size: 120px;
}

.tx-120-f {
    font-size: 120px;
}

.tx-122 {
    font-size: 122px;
}

.tx-122-f {
    font-size: 122px;
}

.tx-124 {
    font-size: 124px;
}

.tx-124-f {
    font-size: 124px;
}

.tx-126 {
    font-size: 126px;
}

.tx-126-f {
    font-size: 126px;
}

.tx-128 {
    font-size: 128px;
}

.tx-128-f {
    font-size: 128px;
}

.tx-130 {
    font-size: 130px;
}

.tx-130-f {
    font-size: 130px;
}

.tx-132 {
    font-size: 132px;
}

.tx-132-f {
    font-size: 132px;
}

.tx-134 {
    font-size: 134px;
}

.tx-134-f {
    font-size: 134px;
}

.tx-136 {
    font-size: 136px;
}

.tx-136-f {
    font-size: 136px;
}

.tx-138 {
    font-size: 138px;
}

.tx-138-f {
    font-size: 138px;
}

.tx-140 {
    font-size: 140px;
}

.tx-140-f {
    font-size: 140px;
}

@media (min-width: 480px) {
    .tx-xs-8 {
        font-size: 8px;
    }

    .tx-xs-8-f {
        font-size: 8px;
    }

    .tx-xs-9 {
        font-size: 9px;
    }

    .tx-xs-9-f {
        font-size: 9px;
    }

    .tx-xs-10 {
        font-size: 10px;
    }

    .tx-xs-10-f {
        font-size: 10px;
    }

    .tx-xs-11 {
        font-size: 11px;
    }

    .tx-xs-11-f {
        font-size: 11px;
    }

    .tx-xs-12 {
        font-size: 12px;
    }

    .tx-xs-12-f {
        font-size: 12px;
    }

    .tx-xs-13 {
        font-size: 13px;
    }

    .tx-xs-13-f {
        font-size: 13px;
    }

    .tx-xs-14 {
        font-size: 14px;
    }

    .tx-xs-14-f {
        font-size: 14px;
    }

    .tx-xs-15 {
        font-size: 15px;
    }

    .tx-xs-15-f {
        font-size: 15px;
    }

    .tx-xs-16 {
        font-size: 16px;
    }

    .tx-xs-16-f {
        font-size: 16px;
    }

    .tx-xs-18 {
        font-size: 18px;
    }

    .tx-xs-18-f {
        font-size: 18px;
    }

    .tx-xs-20 {
        font-size: 20px;
    }

    .tx-xs-20-f {
        font-size: 20px;
    }

    .tx-xs-22 {
        font-size: 22px;
    }

    .tx-xs-22-f {
        font-size: 22px;
    }

    .tx-xs-24 {
        font-size: 24px;
    }

    .tx-xs-24-f {
        font-size: 24px;
    }

    .tx-xs-26 {
        font-size: 26px;
    }

    .tx-xs-26-f {
        font-size: 26px;
    }

    .tx-xs-28 {
        font-size: 28px;
    }

    .tx-xs-28-f {
        font-size: 28px;
    }

    .tx-xs-30 {
        font-size: 30px;
    }

    .tx-xs-30-f {
        font-size: 30px;
    }

    .tx-xs-32 {
        font-size: 32px;
    }

    .tx-xs-32-f {
        font-size: 32px;
    }

    .tx-xs-34 {
        font-size: 34px;
    }

    .tx-xs-34-f {
        font-size: 34px;
    }

    .tx-xs-36 {
        font-size: 36px;
    }

    .tx-xs-36-f {
        font-size: 36px;
    }

    .tx-xs-38 {
        font-size: 38px;
    }

    .tx-xs-38-f {
        font-size: 38px;
    }

    .tx-xs-40 {
        font-size: 40px;
    }

    .tx-xs-40-f {
        font-size: 40px;
    }

    .tx-xs-42 {
        font-size: 42px;
    }

    .tx-xs-42-f {
        font-size: 42px;
    }

    .tx-xs-44 {
        font-size: 44px;
    }

    .tx-xs-44-f {
        font-size: 44px;
    }

    .tx-xs-46 {
        font-size: 46px;
    }

    .tx-xs-46-f {
        font-size: 46px;
    }

    .tx-xs-48 {
        font-size: 48px;
    }

    .tx-xs-48-f {
        font-size: 48px;
    }

    .tx-xs-50 {
        font-size: 50px;
    }

    .tx-xs-50-f {
        font-size: 50px;
    }

    .tx-xs-52 {
        font-size: 52px;
    }

    .tx-xs-52-f {
        font-size: 52px;
    }

    .tx-xs-54 {
        font-size: 54px;
    }

    .tx-xs-54-f {
        font-size: 54px;
    }

    .tx-xs-56 {
        font-size: 56px;
    }

    .tx-xs-56-f {
        font-size: 56px;
    }

    .tx-xs-58 {
        font-size: 58px;
    }

    .tx-xs-58-f {
        font-size: 58px;
    }

    .tx-xs-60 {
        font-size: 60px;
    }

    .tx-xs-60-f {
        font-size: 60px;
    }

    .tx-xs-62 {
        font-size: 62px;
    }

    .tx-xs-62-f {
        font-size: 62px;
    }

    .tx-xs-64 {
        font-size: 64px;
    }

    .tx-xs-64-f {
        font-size: 64px;
    }

    .tx-xs-66 {
        font-size: 66px;
    }

    .tx-xs-66-f {
        font-size: 66px;
    }

    .tx-xs-68 {
        font-size: 68px;
    }

    .tx-xs-68-f {
        font-size: 68px;
    }

    .tx-xs-70 {
        font-size: 70px;
    }

    .tx-xs-70-f {
        font-size: 70px;
    }

    .tx-xs-72 {
        font-size: 72px;
    }

    .tx-xs-72-f {
        font-size: 72px;
    }

    .tx-xs-74 {
        font-size: 74px;
    }

    .tx-xs-74-f {
        font-size: 74px;
    }

    .tx-xs-76 {
        font-size: 76px;
    }

    .tx-xs-76-f {
        font-size: 76px;
    }

    .tx-xs-78 {
        font-size: 78px;
    }

    .tx-xs-78-f {
        font-size: 78px;
    }

    .tx-xs-80 {
        font-size: 80px;
    }

    .tx-xs-80-f {
        font-size: 80px;
    }

    .tx-xs-82 {
        font-size: 82px;
    }

    .tx-xs-82-f {
        font-size: 82px;
    }

    .tx-xs-84 {
        font-size: 84px;
    }

    .tx-xs-84-f {
        font-size: 84px;
    }

    .tx-xs-86 {
        font-size: 86px;
    }

    .tx-xs-86-f {
        font-size: 86px;
    }

    .tx-xs-88 {
        font-size: 88px;
    }

    .tx-xs-88-f {
        font-size: 88px;
    }

    .tx-xs-90 {
        font-size: 90px;
    }

    .tx-xs-90-f {
        font-size: 90px;
    }

    .tx-xs-92 {
        font-size: 92px;
    }

    .tx-xs-92-f {
        font-size: 92px;
    }

    .tx-xs-94 {
        font-size: 94px;
    }

    .tx-xs-94-f {
        font-size: 94px;
    }

    .tx-xs-96 {
        font-size: 96px;
    }

    .tx-xs-96-f {
        font-size: 96px;
    }

    .tx-xs-98 {
        font-size: 98px;
    }

    .tx-xs-98-f {
        font-size: 98px;
    }

    .tx-xs-100 {
        font-size: 100px;
    }

    .tx-xs-100-f {
        font-size: 100px;
    }

    .tx-xs-102 {
        font-size: 102px;
    }

    .tx-xs-102-f {
        font-size: 102px;
    }

    .tx-xs-104 {
        font-size: 104px;
    }

    .tx-xs-104-f {
        font-size: 104px;
    }

    .tx-xs-106 {
        font-size: 106px;
    }

    .tx-xs-106-f {
        font-size: 106px;
    }

    .tx-xs-108 {
        font-size: 108px;
    }

    .tx-xs-108-f {
        font-size: 108px;
    }

    .tx-xs-110 {
        font-size: 110px;
    }

    .tx-xs-110-f {
        font-size: 110px;
    }

    .tx-xs-112 {
        font-size: 112px;
    }

    .tx-xs-112-f {
        font-size: 112px;
    }

    .tx-xs-114 {
        font-size: 114px;
    }

    .tx-xs-114-f {
        font-size: 114px;
    }

    .tx-xs-116 {
        font-size: 116px;
    }

    .tx-xs-116-f {
        font-size: 116px;
    }

    .tx-xs-118 {
        font-size: 118px;
    }

    .tx-xs-118-f {
        font-size: 118px;
    }

    .tx-xs-120 {
        font-size: 120px;
    }

    .tx-xs-120-f {
        font-size: 120px;
    }

    .tx-xs-122 {
        font-size: 122px;
    }

    .tx-xs-122-f {
        font-size: 122px;
    }

    .tx-xs-124 {
        font-size: 124px;
    }

    .tx-xs-124-f {
        font-size: 124px;
    }

    .tx-xs-126 {
        font-size: 126px;
    }

    .tx-xs-126-f {
        font-size: 126px;
    }

    .tx-xs-128 {
        font-size: 128px;
    }

    .tx-xs-128-f {
        font-size: 128px;
    }

    .tx-xs-130 {
        font-size: 130px;
    }

    .tx-xs-130-f {
        font-size: 130px;
    }

    .tx-xs-132 {
        font-size: 132px;
    }

    .tx-xs-132-f {
        font-size: 132px;
    }

    .tx-xs-134 {
        font-size: 134px;
    }

    .tx-xs-134-f {
        font-size: 134px;
    }

    .tx-xs-136 {
        font-size: 136px;
    }

    .tx-xs-136-f {
        font-size: 136px;
    }

    .tx-xs-138 {
        font-size: 138px;
    }

    .tx-xs-138-f {
        font-size: 138px;
    }

    .tx-xs-140 {
        font-size: 140px;
    }

    .tx-xs-140-f {
        font-size: 140px;
    }

    .tx-xs-base {
        font-size: 0.875rem;
    }

    .tx-xs-bold {
        font-weight: 700;
    }

    .tx-xs-semibold {
        font-weight: 600;
    }

    .tx-xs-medium {
        font-weight: 500;
    }

    .tx-xs-normal {
        font-weight: 400;
    }

    .tx-xs-light {
        font-weight: 300;
    }

    .tx-xs-thin {
        font-weight: 200;
    }

    .tx-xs-xthin {
        font-weight: 100;
    }

    .lh-xs-0 {
        line-height: 0;
    }

    .lh-xs-1 {
        line-height: 1.1;
    }

    .lh-xs-2 {
        line-height: 1.2;
    }

    .lh-xs-3 {
        line-height: 1.3;
    }

    .lh-xs-4 {
        line-height: 1.4;
    }

    .lh-xs-5 {
        line-height: 1.5;
    }

    .lh-xs-6 {
        line-height: 1.6;
    }

    .lh-xs-7 {
        line-height: 1.7;
    }

    .lh-xs-8 {
        line-height: 1.8;
    }

    .lh-xs-9 {
        line-height: 1.9;
    }

    .lh-xs-10 {
        line-height: 2;
    }

    .lh-xs-11 {
        line-height: 2.1;
    }

    .lh-xs-12 {
        line-height: 2.2;
    }

    .lh-xs-13 {
        line-height: 2.3;
    }

    .lh-xs-14 {
        line-height: 2.4;
    }

    .lh-xs-15 {
        line-height: 2.5;
    }

    .tx-xs-center {
        text-align: center;
    }

    .tx-xs-right {
        text-align: right;
    }

    .tx-xs-left {
        text-align: left;
    }

    .tx-xs-center-f {
        text-align: center;
    }

    .tx-xs-right-f {
        text-align: right;
    }

    .tx-xs-left-f {
        text-align: left;
    }
}

@media (min-width: 576px) {
    .tx-sm-8 {
        font-size: 8px;
    }

    .tx-sm-8-f {
        font-size: 8px;
    }

    .tx-sm-9 {
        font-size: 9px;
    }

    .tx-sm-9-f {
        font-size: 9px;
    }

    .tx-sm-10 {
        font-size: 10px;
    }

    .tx-sm-10-f {
        font-size: 10px;
    }

    .tx-sm-11 {
        font-size: 11px;
    }

    .tx-sm-11-f {
        font-size: 11px;
    }

    .tx-sm-12 {
        font-size: 12px;
    }

    .tx-sm-12-f {
        font-size: 12px;
    }

    .tx-sm-13 {
        font-size: 13px;
    }

    .tx-sm-13-f {
        font-size: 13px;
    }

    .tx-sm-14 {
        font-size: 14px;
    }

    .tx-sm-14-f {
        font-size: 14px;
    }

    .tx-sm-15 {
        font-size: 15px;
    }

    .tx-sm-15-f {
        font-size: 15px;
    }

    .tx-sm-16 {
        font-size: 16px;
    }

    .tx-sm-16-f {
        font-size: 16px;
    }

    .tx-sm-18 {
        font-size: 18px;
    }

    .tx-sm-18-f {
        font-size: 18px;
    }

    .tx-sm-20 {
        font-size: 20px;
    }

    .tx-sm-20-f {
        font-size: 20px;
    }

    .tx-sm-22 {
        font-size: 22px;
    }

    .tx-sm-22-f {
        font-size: 22px;
    }

    .tx-sm-24 {
        font-size: 24px;
    }

    .tx-sm-24-f {
        font-size: 24px;
    }

    .tx-sm-26 {
        font-size: 26px;
    }

    .tx-sm-26-f {
        font-size: 26px;
    }

    .tx-sm-28 {
        font-size: 28px;
    }

    .tx-sm-28-f {
        font-size: 28px;
    }

    .tx-sm-30 {
        font-size: 30px;
    }

    .tx-sm-30-f {
        font-size: 30px;
    }

    .tx-sm-32 {
        font-size: 32px;
    }

    .tx-sm-32-f {
        font-size: 32px;
    }

    .tx-sm-34 {
        font-size: 34px;
    }

    .tx-sm-34-f {
        font-size: 34px;
    }

    .tx-sm-36 {
        font-size: 36px;
    }

    .tx-sm-36-f {
        font-size: 36px;
    }

    .tx-sm-38 {
        font-size: 38px;
    }

    .tx-sm-38-f {
        font-size: 38px;
    }

    .tx-sm-40 {
        font-size: 40px;
    }

    .tx-sm-40-f {
        font-size: 40px;
    }

    .tx-sm-42 {
        font-size: 42px;
    }

    .tx-sm-42-f {
        font-size: 42px;
    }

    .tx-sm-44 {
        font-size: 44px;
    }

    .tx-sm-44-f {
        font-size: 44px;
    }

    .tx-sm-46 {
        font-size: 46px;
    }

    .tx-sm-46-f {
        font-size: 46px;
    }

    .tx-sm-48 {
        font-size: 48px;
    }

    .tx-sm-48-f {
        font-size: 48px;
    }

    .tx-sm-50 {
        font-size: 50px;
    }

    .tx-sm-50-f {
        font-size: 50px;
    }

    .tx-sm-52 {
        font-size: 52px;
    }

    .tx-sm-52-f {
        font-size: 52px;
    }

    .tx-sm-54 {
        font-size: 54px;
    }

    .tx-sm-54-f {
        font-size: 54px;
    }

    .tx-sm-56 {
        font-size: 56px;
    }

    .tx-sm-56-f {
        font-size: 56px;
    }

    .tx-sm-58 {
        font-size: 58px;
    }

    .tx-sm-58-f {
        font-size: 58px;
    }

    .tx-sm-60 {
        font-size: 60px;
    }

    .tx-sm-60-f {
        font-size: 60px;
    }

    .tx-sm-62 {
        font-size: 62px;
    }

    .tx-sm-62-f {
        font-size: 62px;
    }

    .tx-sm-64 {
        font-size: 64px;
    }

    .tx-sm-64-f {
        font-size: 64px;
    }

    .tx-sm-66 {
        font-size: 66px;
    }

    .tx-sm-66-f {
        font-size: 66px;
    }

    .tx-sm-68 {
        font-size: 68px;
    }

    .tx-sm-68-f {
        font-size: 68px;
    }

    .tx-sm-70 {
        font-size: 70px;
    }

    .tx-sm-70-f {
        font-size: 70px;
    }

    .tx-sm-72 {
        font-size: 72px;
    }

    .tx-sm-72-f {
        font-size: 72px;
    }

    .tx-sm-74 {
        font-size: 74px;
    }

    .tx-sm-74-f {
        font-size: 74px;
    }

    .tx-sm-76 {
        font-size: 76px;
    }

    .tx-sm-76-f {
        font-size: 76px;
    }

    .tx-sm-78 {
        font-size: 78px;
    }

    .tx-sm-78-f {
        font-size: 78px;
    }

    .tx-sm-80 {
        font-size: 80px;
    }

    .tx-sm-80-f {
        font-size: 80px;
    }

    .tx-sm-82 {
        font-size: 82px;
    }

    .tx-sm-82-f {
        font-size: 82px;
    }

    .tx-sm-84 {
        font-size: 84px;
    }

    .tx-sm-84-f {
        font-size: 84px;
    }

    .tx-sm-86 {
        font-size: 86px;
    }

    .tx-sm-86-f {
        font-size: 86px;
    }

    .tx-sm-88 {
        font-size: 88px;
    }

    .tx-sm-88-f {
        font-size: 88px;
    }

    .tx-sm-90 {
        font-size: 90px;
    }

    .tx-sm-90-f {
        font-size: 90px;
    }

    .tx-sm-92 {
        font-size: 92px;
    }

    .tx-sm-92-f {
        font-size: 92px;
    }

    .tx-sm-94 {
        font-size: 94px;
    }

    .tx-sm-94-f {
        font-size: 94px;
    }

    .tx-sm-96 {
        font-size: 96px;
    }

    .tx-sm-96-f {
        font-size: 96px;
    }

    .tx-sm-98 {
        font-size: 98px;
    }

    .tx-sm-98-f {
        font-size: 98px;
    }

    .tx-sm-100 {
        font-size: 100px;
    }

    .tx-sm-100-f {
        font-size: 100px;
    }

    .tx-sm-102 {
        font-size: 102px;
    }

    .tx-sm-102-f {
        font-size: 102px;
    }

    .tx-sm-104 {
        font-size: 104px;
    }

    .tx-sm-104-f {
        font-size: 104px;
    }

    .tx-sm-106 {
        font-size: 106px;
    }

    .tx-sm-106-f {
        font-size: 106px;
    }

    .tx-sm-108 {
        font-size: 108px;
    }

    .tx-sm-108-f {
        font-size: 108px;
    }

    .tx-sm-110 {
        font-size: 110px;
    }

    .tx-sm-110-f {
        font-size: 110px;
    }

    .tx-sm-112 {
        font-size: 112px;
    }

    .tx-sm-112-f {
        font-size: 112px;
    }

    .tx-sm-114 {
        font-size: 114px;
    }

    .tx-sm-114-f {
        font-size: 114px;
    }

    .tx-sm-116 {
        font-size: 116px;
    }

    .tx-sm-116-f {
        font-size: 116px;
    }

    .tx-sm-118 {
        font-size: 118px;
    }

    .tx-sm-118-f {
        font-size: 118px;
    }

    .tx-sm-120 {
        font-size: 120px;
    }

    .tx-sm-120-f {
        font-size: 120px;
    }

    .tx-sm-122 {
        font-size: 122px;
    }

    .tx-sm-122-f {
        font-size: 122px;
    }

    .tx-sm-124 {
        font-size: 124px;
    }

    .tx-sm-124-f {
        font-size: 124px;
    }

    .tx-sm-126 {
        font-size: 126px;
    }

    .tx-sm-126-f {
        font-size: 126px;
    }

    .tx-sm-128 {
        font-size: 128px;
    }

    .tx-sm-128-f {
        font-size: 128px;
    }

    .tx-sm-130 {
        font-size: 130px;
    }

    .tx-sm-130-f {
        font-size: 130px;
    }

    .tx-sm-132 {
        font-size: 132px;
    }

    .tx-sm-132-f {
        font-size: 132px;
    }

    .tx-sm-134 {
        font-size: 134px;
    }

    .tx-sm-134-f {
        font-size: 134px;
    }

    .tx-sm-136 {
        font-size: 136px;
    }

    .tx-sm-136-f {
        font-size: 136px;
    }

    .tx-sm-138 {
        font-size: 138px;
    }

    .tx-sm-138-f {
        font-size: 138px;
    }

    .tx-sm-140 {
        font-size: 140px;
    }

    .tx-sm-140-f {
        font-size: 140px;
    }

    .tx-sm-base {
        font-size: 0.875rem;
    }

    .tx-sm-bold {
        font-weight: 700;
    }

    .tx-sm-semibold {
        font-weight: 600;
    }

    .tx-sm-medium {
        font-weight: 500;
    }

    .tx-sm-normal {
        font-weight: 400;
    }

    .tx-sm-light {
        font-weight: 300;
    }

    .tx-sm-thin {
        font-weight: 200;
    }

    .tx-sm-xthin {
        font-weight: 100;
    }

    .lh-sm-0 {
        line-height: 0;
    }

    .lh-sm-1 {
        line-height: 1.1;
    }

    .lh-sm-2 {
        line-height: 1.2;
    }

    .lh-sm-3 {
        line-height: 1.3;
    }

    .lh-sm-4 {
        line-height: 1.4;
    }

    .lh-sm-5 {
        line-height: 1.5;
    }

    .lh-sm-6 {
        line-height: 1.6;
    }

    .lh-sm-7 {
        line-height: 1.7;
    }

    .lh-sm-8 {
        line-height: 1.8;
    }

    .lh-sm-9 {
        line-height: 1.9;
    }

    .lh-sm-10 {
        line-height: 2;
    }

    .lh-sm-11 {
        line-height: 2.1;
    }

    .lh-sm-12 {
        line-height: 2.2;
    }

    .lh-sm-13 {
        line-height: 2.3;
    }

    .lh-sm-14 {
        line-height: 2.4;
    }

    .lh-sm-15 {
        line-height: 2.5;
    }

    .tx-sm-center {
        text-align: center;
    }

    .tx-sm-right {
        text-align: right;
    }

    .tx-sm-left {
        text-align: left;
    }

    .tx-sm-center-f {
        text-align: center;
    }

    .tx-sm-right-f {
        text-align: right;
    }

    .tx-sm-left-f {
        text-align: left;
    }
}

@media (min-width: 768px) {
    .tx-md-8 {
        font-size: 8px;
    }

    .tx-md-8-f {
        font-size: 8px;
    }

    .tx-md-9 {
        font-size: 9px;
    }

    .tx-md-9-f {
        font-size: 9px;
    }

    .tx-md-10 {
        font-size: 10px;
    }

    .tx-md-10-f {
        font-size: 10px;
    }

    .tx-md-11 {
        font-size: 11px;
    }

    .tx-md-11-f {
        font-size: 11px;
    }

    .tx-md-12 {
        font-size: 12px;
    }

    .tx-md-12-f {
        font-size: 12px;
    }

    .tx-md-13 {
        font-size: 13px;
    }

    .tx-md-13-f {
        font-size: 13px;
    }

    .tx-md-14 {
        font-size: 14px;
    }

    .tx-md-14-f {
        font-size: 14px;
    }

    .tx-md-15 {
        font-size: 15px;
    }

    .tx-md-15-f {
        font-size: 15px;
    }

    .tx-md-16 {
        font-size: 16px;
    }

    .tx-md-16-f {
        font-size: 16px;
    }

    .tx-md-18 {
        font-size: 18px;
    }

    .tx-md-18-f {
        font-size: 18px;
    }

    .tx-md-20 {
        font-size: 20px;
    }

    .tx-md-20-f {
        font-size: 20px;
    }

    .tx-md-22 {
        font-size: 22px;
    }

    .tx-md-22-f {
        font-size: 22px;
    }

    .tx-md-24 {
        font-size: 24px;
    }

    .tx-md-24-f {
        font-size: 24px;
    }

    .tx-md-26 {
        font-size: 26px;
    }

    .tx-md-26-f {
        font-size: 26px;
    }

    .tx-md-28 {
        font-size: 28px;
    }

    .tx-md-28-f {
        font-size: 28px;
    }

    .tx-md-30 {
        font-size: 30px;
    }

    .tx-md-30-f {
        font-size: 30px;
    }

    .tx-md-32 {
        font-size: 32px;
    }

    .tx-md-32-f {
        font-size: 32px;
    }

    .tx-md-34 {
        font-size: 34px;
    }

    .tx-md-34-f {
        font-size: 34px;
    }

    .tx-md-36 {
        font-size: 36px;
    }

    .tx-md-36-f {
        font-size: 36px;
    }

    .tx-md-38 {
        font-size: 38px;
    }

    .tx-md-38-f {
        font-size: 38px;
    }

    .tx-md-40 {
        font-size: 40px;
    }

    .tx-md-40-f {
        font-size: 40px;
    }

    .tx-md-42 {
        font-size: 42px;
    }

    .tx-md-42-f {
        font-size: 42px;
    }

    .tx-md-44 {
        font-size: 44px;
    }

    .tx-md-44-f {
        font-size: 44px;
    }

    .tx-md-46 {
        font-size: 46px;
    }

    .tx-md-46-f {
        font-size: 46px;
    }

    .tx-md-48 {
        font-size: 48px;
    }

    .tx-md-48-f {
        font-size: 48px;
    }

    .tx-md-50 {
        font-size: 50px;
    }

    .tx-md-50-f {
        font-size: 50px;
    }

    .tx-md-52 {
        font-size: 52px;
    }

    .tx-md-52-f {
        font-size: 52px;
    }

    .tx-md-54 {
        font-size: 54px;
    }

    .tx-md-54-f {
        font-size: 54px;
    }

    .tx-md-56 {
        font-size: 56px;
    }

    .tx-md-56-f {
        font-size: 56px;
    }

    .tx-md-58 {
        font-size: 58px;
    }

    .tx-md-58-f {
        font-size: 58px;
    }

    .tx-md-60 {
        font-size: 60px;
    }

    .tx-md-60-f {
        font-size: 60px;
    }

    .tx-md-62 {
        font-size: 62px;
    }

    .tx-md-62-f {
        font-size: 62px;
    }

    .tx-md-64 {
        font-size: 64px;
    }

    .tx-md-64-f {
        font-size: 64px;
    }

    .tx-md-66 {
        font-size: 66px;
    }

    .tx-md-66-f {
        font-size: 66px;
    }

    .tx-md-68 {
        font-size: 68px;
    }

    .tx-md-68-f {
        font-size: 68px;
    }

    .tx-md-70 {
        font-size: 70px;
    }

    .tx-md-70-f {
        font-size: 70px;
    }

    .tx-md-72 {
        font-size: 72px;
    }

    .tx-md-72-f {
        font-size: 72px;
    }

    .tx-md-74 {
        font-size: 74px;
    }

    .tx-md-74-f {
        font-size: 74px;
    }

    .tx-md-76 {
        font-size: 76px;
    }

    .tx-md-76-f {
        font-size: 76px;
    }

    .tx-md-78 {
        font-size: 78px;
    }

    .tx-md-78-f {
        font-size: 78px;
    }

    .tx-md-80 {
        font-size: 80px;
    }

    .tx-md-80-f {
        font-size: 80px;
    }

    .tx-md-82 {
        font-size: 82px;
    }

    .tx-md-82-f {
        font-size: 82px;
    }

    .tx-md-84 {
        font-size: 84px;
    }

    .tx-md-84-f {
        font-size: 84px;
    }

    .tx-md-86 {
        font-size: 86px;
    }

    .tx-md-86-f {
        font-size: 86px;
    }

    .tx-md-88 {
        font-size: 88px;
    }

    .tx-md-88-f {
        font-size: 88px;
    }

    .tx-md-90 {
        font-size: 90px;
    }

    .tx-md-90-f {
        font-size: 90px;
    }

    .tx-md-92 {
        font-size: 92px;
    }

    .tx-md-92-f {
        font-size: 92px;
    }

    .tx-md-94 {
        font-size: 94px;
    }

    .tx-md-94-f {
        font-size: 94px;
    }

    .tx-md-96 {
        font-size: 96px;
    }

    .tx-md-96-f {
        font-size: 96px;
    }

    .tx-md-98 {
        font-size: 98px;
    }

    .tx-md-98-f {
        font-size: 98px;
    }

    .tx-md-100 {
        font-size: 100px;
    }

    .tx-md-100-f {
        font-size: 100px;
    }

    .tx-md-102 {
        font-size: 102px;
    }

    .tx-md-102-f {
        font-size: 102px;
    }

    .tx-md-104 {
        font-size: 104px;
    }

    .tx-md-104-f {
        font-size: 104px;
    }

    .tx-md-106 {
        font-size: 106px;
    }

    .tx-md-106-f {
        font-size: 106px;
    }

    .tx-md-108 {
        font-size: 108px;
    }

    .tx-md-108-f {
        font-size: 108px;
    }

    .tx-md-110 {
        font-size: 110px;
    }

    .tx-md-110-f {
        font-size: 110px;
    }

    .tx-md-112 {
        font-size: 112px;
    }

    .tx-md-112-f {
        font-size: 112px;
    }

    .tx-md-114 {
        font-size: 114px;
    }

    .tx-md-114-f {
        font-size: 114px;
    }

    .tx-md-116 {
        font-size: 116px;
    }

    .tx-md-116-f {
        font-size: 116px;
    }

    .tx-md-118 {
        font-size: 118px;
    }

    .tx-md-118-f {
        font-size: 118px;
    }

    .tx-md-120 {
        font-size: 120px;
    }

    .tx-md-120-f {
        font-size: 120px;
    }

    .tx-md-122 {
        font-size: 122px;
    }

    .tx-md-122-f {
        font-size: 122px;
    }

    .tx-md-124 {
        font-size: 124px;
    }

    .tx-md-124-f {
        font-size: 124px;
    }

    .tx-md-126 {
        font-size: 126px;
    }

    .tx-md-126-f {
        font-size: 126px;
    }

    .tx-md-128 {
        font-size: 128px;
    }

    .tx-md-128-f {
        font-size: 128px;
    }

    .tx-md-130 {
        font-size: 130px;
    }

    .tx-md-130-f {
        font-size: 130px;
    }

    .tx-md-132 {
        font-size: 132px;
    }

    .tx-md-132-f {
        font-size: 132px;
    }

    .tx-md-134 {
        font-size: 134px;
    }

    .tx-md-134-f {
        font-size: 134px;
    }

    .tx-md-136 {
        font-size: 136px;
    }

    .tx-md-136-f {
        font-size: 136px;
    }

    .tx-md-138 {
        font-size: 138px;
    }

    .tx-md-138-f {
        font-size: 138px;
    }

    .tx-md-140 {
        font-size: 140px;
    }

    .tx-md-140-f {
        font-size: 140px;
    }

    .tx-md-base {
        font-size: 0.875rem;
    }

    .tx-md-bold {
        font-weight: 700;
    }

    .tx-md-semibold {
        font-weight: 600;
    }

    .tx-md-medium {
        font-weight: 500;
    }

    .tx-md-normal {
        font-weight: 400;
    }

    .tx-md-light {
        font-weight: 300;
    }

    .tx-md-thin {
        font-weight: 200;
    }

    .tx-md-xthin {
        font-weight: 100;
    }

    .lh-md-0 {
        line-height: 0;
    }

    .lh-md-1 {
        line-height: 1.1;
    }

    .lh-md-2 {
        line-height: 1.2;
    }

    .lh-md-3 {
        line-height: 1.3;
    }

    .lh-md-4 {
        line-height: 1.4;
    }

    .lh-md-5 {
        line-height: 1.5;
    }

    .lh-md-6 {
        line-height: 1.6;
    }

    .lh-md-7 {
        line-height: 1.7;
    }

    .lh-md-8 {
        line-height: 1.8;
    }

    .lh-md-9 {
        line-height: 1.9;
    }

    .lh-md-10 {
        line-height: 2;
    }

    .lh-md-11 {
        line-height: 2.1;
    }

    .lh-md-12 {
        line-height: 2.2;
    }

    .lh-md-13 {
        line-height: 2.3;
    }

    .lh-md-14 {
        line-height: 2.4;
    }

    .lh-md-15 {
        line-height: 2.5;
    }

    .tx-md-center {
        text-align: center;
    }

    .tx-md-right {
        text-align: right;
    }

    .tx-md-left {
        text-align: left;
    }

    .tx-md-center-f {
        text-align: center;
    }

    .tx-md-right-f {
        text-align: right;
    }

    .tx-md-left-f {
        text-align: left;
    }
}

@media (min-width: 992px) {
    .tx-lg-8 {
        font-size: 8px;
    }

    .tx-lg-8-f {
        font-size: 8px;
    }

    .tx-lg-9 {
        font-size: 9px;
    }

    .tx-lg-9-f {
        font-size: 9px;
    }

    .tx-lg-10 {
        font-size: 10px;
    }

    .tx-lg-10-f {
        font-size: 10px;
    }

    .tx-lg-11 {
        font-size: 11px;
    }

    .tx-lg-11-f {
        font-size: 11px;
    }

    .tx-lg-12 {
        font-size: 12px;
    }

    .tx-lg-12-f {
        font-size: 12px;
    }

    .tx-lg-13 {
        font-size: 13px;
    }

    .tx-lg-13-f {
        font-size: 13px;
    }

    .tx-lg-14 {
        font-size: 14px;
    }

    .tx-lg-14-f {
        font-size: 14px;
    }

    .tx-lg-15 {
        font-size: 15px;
    }

    .tx-lg-15-f {
        font-size: 15px;
    }

    .tx-lg-16 {
        font-size: 16px;
    }

    .tx-lg-16-f {
        font-size: 16px;
    }

    .tx-lg-18 {
        font-size: 18px;
    }

    .tx-lg-18-f {
        font-size: 18px;
    }

    .tx-lg-20 {
        font-size: 20px;
    }

    .tx-lg-20-f {
        font-size: 20px;
    }

    .tx-lg-22 {
        font-size: 22px;
    }

    .tx-lg-22-f {
        font-size: 22px;
    }

    .tx-lg-24 {
        font-size: 24px;
    }

    .tx-lg-24-f {
        font-size: 24px;
    }

    .tx-lg-26 {
        font-size: 26px;
    }

    .tx-lg-26-f {
        font-size: 26px;
    }

    .tx-lg-28 {
        font-size: 28px;
    }

    .tx-lg-28-f {
        font-size: 28px;
    }

    .tx-lg-30 {
        font-size: 30px;
    }

    .tx-lg-30-f {
        font-size: 30px;
    }

    .tx-lg-32 {
        font-size: 32px;
    }

    .tx-lg-32-f {
        font-size: 32px;
    }

    .tx-lg-34 {
        font-size: 34px;
    }

    .tx-lg-34-f {
        font-size: 34px;
    }

    .tx-lg-36 {
        font-size: 36px;
    }

    .tx-lg-36-f {
        font-size: 36px;
    }

    .tx-lg-38 {
        font-size: 38px;
    }

    .tx-lg-38-f {
        font-size: 38px;
    }

    .tx-lg-40 {
        font-size: 40px;
    }

    .tx-lg-40-f {
        font-size: 40px;
    }

    .tx-lg-42 {
        font-size: 42px;
    }

    .tx-lg-42-f {
        font-size: 42px;
    }

    .tx-lg-44 {
        font-size: 44px;
    }

    .tx-lg-44-f {
        font-size: 44px;
    }

    .tx-lg-46 {
        font-size: 46px;
    }

    .tx-lg-46-f {
        font-size: 46px;
    }

    .tx-lg-48 {
        font-size: 48px;
    }

    .tx-lg-48-f {
        font-size: 48px;
    }

    .tx-lg-50 {
        font-size: 50px;
    }

    .tx-lg-50-f {
        font-size: 50px;
    }

    .tx-lg-52 {
        font-size: 52px;
    }

    .tx-lg-52-f {
        font-size: 52px;
    }

    .tx-lg-54 {
        font-size: 54px;
    }

    .tx-lg-54-f {
        font-size: 54px;
    }

    .tx-lg-56 {
        font-size: 56px;
    }

    .tx-lg-56-f {
        font-size: 56px;
    }

    .tx-lg-58 {
        font-size: 58px;
    }

    .tx-lg-58-f {
        font-size: 58px;
    }

    .tx-lg-60 {
        font-size: 60px;
    }

    .tx-lg-60-f {
        font-size: 60px;
    }

    .tx-lg-62 {
        font-size: 62px;
    }

    .tx-lg-62-f {
        font-size: 62px;
    }

    .tx-lg-64 {
        font-size: 64px;
    }

    .tx-lg-64-f {
        font-size: 64px;
    }

    .tx-lg-66 {
        font-size: 66px;
    }

    .tx-lg-66-f {
        font-size: 66px;
    }

    .tx-lg-68 {
        font-size: 68px;
    }

    .tx-lg-68-f {
        font-size: 68px;
    }

    .tx-lg-70 {
        font-size: 70px;
    }

    .tx-lg-70-f {
        font-size: 70px;
    }

    .tx-lg-72 {
        font-size: 72px;
    }

    .tx-lg-72-f {
        font-size: 72px;
    }

    .tx-lg-74 {
        font-size: 74px;
    }

    .tx-lg-74-f {
        font-size: 74px;
    }

    .tx-lg-76 {
        font-size: 76px;
    }

    .tx-lg-76-f {
        font-size: 76px;
    }

    .tx-lg-78 {
        font-size: 78px;
    }

    .tx-lg-78-f {
        font-size: 78px;
    }

    .tx-lg-80 {
        font-size: 80px;
    }

    .tx-lg-80-f {
        font-size: 80px;
    }

    .tx-lg-82 {
        font-size: 82px;
    }

    .tx-lg-82-f {
        font-size: 82px;
    }

    .tx-lg-84 {
        font-size: 84px;
    }

    .tx-lg-84-f {
        font-size: 84px;
    }

    .tx-lg-86 {
        font-size: 86px;
    }

    .tx-lg-86-f {
        font-size: 86px;
    }

    .tx-lg-88 {
        font-size: 88px;
    }

    .tx-lg-88-f {
        font-size: 88px;
    }

    .tx-lg-90 {
        font-size: 90px;
    }

    .tx-lg-90-f {
        font-size: 90px;
    }

    .tx-lg-92 {
        font-size: 92px;
    }

    .tx-lg-92-f {
        font-size: 92px;
    }

    .tx-lg-94 {
        font-size: 94px;
    }

    .tx-lg-94-f {
        font-size: 94px;
    }

    .tx-lg-96 {
        font-size: 96px;
    }

    .tx-lg-96-f {
        font-size: 96px;
    }

    .tx-lg-98 {
        font-size: 98px;
    }

    .tx-lg-98-f {
        font-size: 98px;
    }

    .tx-lg-100 {
        font-size: 100px;
    }

    .tx-lg-100-f {
        font-size: 100px;
    }

    .tx-lg-102 {
        font-size: 102px;
    }

    .tx-lg-102-f {
        font-size: 102px;
    }

    .tx-lg-104 {
        font-size: 104px;
    }

    .tx-lg-104-f {
        font-size: 104px;
    }

    .tx-lg-106 {
        font-size: 106px;
    }

    .tx-lg-106-f {
        font-size: 106px;
    }

    .tx-lg-108 {
        font-size: 108px;
    }

    .tx-lg-108-f {
        font-size: 108px;
    }

    .tx-lg-110 {
        font-size: 110px;
    }

    .tx-lg-110-f {
        font-size: 110px;
    }

    .tx-lg-112 {
        font-size: 112px;
    }

    .tx-lg-112-f {
        font-size: 112px;
    }

    .tx-lg-114 {
        font-size: 114px;
    }

    .tx-lg-114-f {
        font-size: 114px;
    }

    .tx-lg-116 {
        font-size: 116px;
    }

    .tx-lg-116-f {
        font-size: 116px;
    }

    .tx-lg-118 {
        font-size: 118px;
    }

    .tx-lg-118-f {
        font-size: 118px;
    }

    .tx-lg-120 {
        font-size: 120px;
    }

    .tx-lg-120-f {
        font-size: 120px;
    }

    .tx-lg-122 {
        font-size: 122px;
    }

    .tx-lg-122-f {
        font-size: 122px;
    }

    .tx-lg-124 {
        font-size: 124px;
    }

    .tx-lg-124-f {
        font-size: 124px;
    }

    .tx-lg-126 {
        font-size: 126px;
    }

    .tx-lg-126-f {
        font-size: 126px;
    }

    .tx-lg-128 {
        font-size: 128px;
    }

    .tx-lg-128-f {
        font-size: 128px;
    }

    .tx-lg-130 {
        font-size: 130px;
    }

    .tx-lg-130-f {
        font-size: 130px;
    }

    .tx-lg-132 {
        font-size: 132px;
    }

    .tx-lg-132-f {
        font-size: 132px;
    }

    .tx-lg-134 {
        font-size: 134px;
    }

    .tx-lg-134-f {
        font-size: 134px;
    }

    .tx-lg-136 {
        font-size: 136px;
    }

    .tx-lg-136-f {
        font-size: 136px;
    }

    .tx-lg-138 {
        font-size: 138px;
    }

    .tx-lg-138-f {
        font-size: 138px;
    }

    .tx-lg-140 {
        font-size: 140px;
    }

    .tx-lg-140-f {
        font-size: 140px;
    }

    .tx-lg-base {
        font-size: 0.875rem;
    }

    .tx-lg-bold {
        font-weight: 700;
    }

    .tx-lg-semibold {
        font-weight: 600;
    }

    .tx-lg-medium {
        font-weight: 500;
    }

    .tx-lg-normal {
        font-weight: 400;
    }

    .tx-lg-light {
        font-weight: 300;
    }

    .tx-lg-thin {
        font-weight: 200;
    }

    .tx-lg-xthin {
        font-weight: 100;
    }

    .lh-lg-0 {
        line-height: 0;
    }

    .lh-lg-1 {
        line-height: 1.1;
    }

    .lh-lg-2 {
        line-height: 1.2;
    }

    .lh-lg-3 {
        line-height: 1.3;
    }

    .lh-lg-4 {
        line-height: 1.4;
    }

    .lh-lg-5 {
        line-height: 1.5;
    }

    .lh-lg-6 {
        line-height: 1.6;
    }

    .lh-lg-7 {
        line-height: 1.7;
    }

    .lh-lg-8 {
        line-height: 1.8;
    }

    .lh-lg-9 {
        line-height: 1.9;
    }

    .lh-lg-10 {
        line-height: 2;
    }

    .lh-lg-11 {
        line-height: 2.1;
    }

    .lh-lg-12 {
        line-height: 2.2;
    }

    .lh-lg-13 {
        line-height: 2.3;
    }

    .lh-lg-14 {
        line-height: 2.4;
    }

    .lh-lg-15 {
        line-height: 2.5;
    }

    .tx-lg-center {
        text-align: center;
    }

    .tx-lg-right {
        text-align: right;
    }

    .tx-lg-left {
        text-align: left;
    }

    .tx-lg-center-f {
        text-align: center;
    }

    .tx-lg-right-f {
        text-align: right;
    }

    .tx-lg-left-f {
        text-align: left;
    }
}

@media (min-width: 1200px) {
    .tx-xl-8 {
        font-size: 8px;
    }

    .tx-xl-8-f {
        font-size: 8px;
    }

    .tx-xl-9 {
        font-size: 9px;
    }

    .tx-xl-9-f {
        font-size: 9px;
    }

    .tx-xl-10 {
        font-size: 10px;
    }

    .tx-xl-10-f {
        font-size: 10px;
    }

    .tx-xl-11 {
        font-size: 11px;
    }

    .tx-xl-11-f {
        font-size: 11px;
    }

    .tx-xl-12 {
        font-size: 12px;
    }

    .tx-xl-12-f {
        font-size: 12px;
    }

    .tx-xl-13 {
        font-size: 13px;
    }

    .tx-xl-13-f {
        font-size: 13px;
    }

    .tx-xl-14 {
        font-size: 14px;
    }

    .tx-xl-14-f {
        font-size: 14px;
    }

    .tx-xl-15 {
        font-size: 15px;
    }

    .tx-xl-15-f {
        font-size: 15px;
    }

    .tx-xl-16 {
        font-size: 16px;
    }

    .tx-xl-16-f {
        font-size: 16px;
    }

    .tx-xl-18 {
        font-size: 18px;
    }

    .tx-xl-18-f {
        font-size: 18px;
    }

    .tx-xl-20 {
        font-size: 20px;
    }

    .tx-xl-20-f {
        font-size: 20px;
    }

    .tx-xl-22 {
        font-size: 22px;
    }

    .tx-xl-22-f {
        font-size: 22px;
    }

    .tx-xl-24 {
        font-size: 24px;
    }

    .tx-xl-24-f {
        font-size: 24px;
    }

    .tx-xl-26 {
        font-size: 26px;
    }

    .tx-xl-26-f {
        font-size: 26px;
    }

    .tx-xl-28 {
        font-size: 28px;
    }

    .tx-xl-28-f {
        font-size: 28px;
    }

    .tx-xl-30 {
        font-size: 30px;
    }

    .tx-xl-30-f {
        font-size: 30px;
    }

    .tx-xl-32 {
        font-size: 32px;
    }

    .tx-xl-32-f {
        font-size: 32px;
    }

    .tx-xl-34 {
        font-size: 34px;
    }

    .tx-xl-34-f {
        font-size: 34px;
    }

    .tx-xl-36 {
        font-size: 36px;
    }

    .tx-xl-36-f {
        font-size: 36px;
    }

    .tx-xl-38 {
        font-size: 38px;
    }

    .tx-xl-38-f {
        font-size: 38px;
    }

    .tx-xl-40 {
        font-size: 40px;
    }

    .tx-xl-40-f {
        font-size: 40px;
    }

    .tx-xl-42 {
        font-size: 42px;
    }

    .tx-xl-42-f {
        font-size: 42px;
    }

    .tx-xl-44 {
        font-size: 44px;
    }

    .tx-xl-44-f {
        font-size: 44px;
    }

    .tx-xl-46 {
        font-size: 46px;
    }

    .tx-xl-46-f {
        font-size: 46px;
    }

    .tx-xl-48 {
        font-size: 48px;
    }

    .tx-xl-48-f {
        font-size: 48px;
    }

    .tx-xl-50 {
        font-size: 50px;
    }

    .tx-xl-50-f {
        font-size: 50px;
    }

    .tx-xl-52 {
        font-size: 52px;
    }

    .tx-xl-52-f {
        font-size: 52px;
    }

    .tx-xl-54 {
        font-size: 54px;
    }

    .tx-xl-54-f {
        font-size: 54px;
    }

    .tx-xl-56 {
        font-size: 56px;
    }

    .tx-xl-56-f {
        font-size: 56px;
    }

    .tx-xl-58 {
        font-size: 58px;
    }

    .tx-xl-58-f {
        font-size: 58px;
    }

    .tx-xl-60 {
        font-size: 60px;
    }

    .tx-xl-60-f {
        font-size: 60px;
    }

    .tx-xl-62 {
        font-size: 62px;
    }

    .tx-xl-62-f {
        font-size: 62px;
    }

    .tx-xl-64 {
        font-size: 64px;
    }

    .tx-xl-64-f {
        font-size: 64px;
    }

    .tx-xl-66 {
        font-size: 66px;
    }

    .tx-xl-66-f {
        font-size: 66px;
    }

    .tx-xl-68 {
        font-size: 68px;
    }

    .tx-xl-68-f {
        font-size: 68px;
    }

    .tx-xl-70 {
        font-size: 70px;
    }

    .tx-xl-70-f {
        font-size: 70px;
    }

    .tx-xl-72 {
        font-size: 72px;
    }

    .tx-xl-72-f {
        font-size: 72px;
    }

    .tx-xl-74 {
        font-size: 74px;
    }

    .tx-xl-74-f {
        font-size: 74px;
    }

    .tx-xl-76 {
        font-size: 76px;
    }

    .tx-xl-76-f {
        font-size: 76px;
    }

    .tx-xl-78 {
        font-size: 78px;
    }

    .tx-xl-78-f {
        font-size: 78px;
    }

    .tx-xl-80 {
        font-size: 80px;
    }

    .tx-xl-80-f {
        font-size: 80px;
    }

    .tx-xl-82 {
        font-size: 82px;
    }

    .tx-xl-82-f {
        font-size: 82px;
    }

    .tx-xl-84 {
        font-size: 84px;
    }

    .tx-xl-84-f {
        font-size: 84px;
    }

    .tx-xl-86 {
        font-size: 86px;
    }

    .tx-xl-86-f {
        font-size: 86px;
    }

    .tx-xl-88 {
        font-size: 88px;
    }

    .tx-xl-88-f {
        font-size: 88px;
    }

    .tx-xl-90 {
        font-size: 90px;
    }

    .tx-xl-90-f {
        font-size: 90px;
    }

    .tx-xl-92 {
        font-size: 92px;
    }

    .tx-xl-92-f {
        font-size: 92px;
    }

    .tx-xl-94 {
        font-size: 94px;
    }

    .tx-xl-94-f {
        font-size: 94px;
    }

    .tx-xl-96 {
        font-size: 96px;
    }

    .tx-xl-96-f {
        font-size: 96px;
    }

    .tx-xl-98 {
        font-size: 98px;
    }

    .tx-xl-98-f {
        font-size: 98px;
    }

    .tx-xl-100 {
        font-size: 100px;
    }

    .tx-xl-100-f {
        font-size: 100px;
    }

    .tx-xl-102 {
        font-size: 102px;
    }

    .tx-xl-102-f {
        font-size: 102px;
    }

    .tx-xl-104 {
        font-size: 104px;
    }

    .tx-xl-104-f {
        font-size: 104px;
    }

    .tx-xl-106 {
        font-size: 106px;
    }

    .tx-xl-106-f {
        font-size: 106px;
    }

    .tx-xl-108 {
        font-size: 108px;
    }

    .tx-xl-108-f {
        font-size: 108px;
    }

    .tx-xl-110 {
        font-size: 110px;
    }

    .tx-xl-110-f {
        font-size: 110px;
    }

    .tx-xl-112 {
        font-size: 112px;
    }

    .tx-xl-112-f {
        font-size: 112px;
    }

    .tx-xl-114 {
        font-size: 114px;
    }

    .tx-xl-114-f {
        font-size: 114px;
    }

    .tx-xl-116 {
        font-size: 116px;
    }

    .tx-xl-116-f {
        font-size: 116px;
    }

    .tx-xl-118 {
        font-size: 118px;
    }

    .tx-xl-118-f {
        font-size: 118px;
    }

    .tx-xl-120 {
        font-size: 120px;
    }

    .tx-xl-120-f {
        font-size: 120px;
    }

    .tx-xl-122 {
        font-size: 122px;
    }

    .tx-xl-122-f {
        font-size: 122px;
    }

    .tx-xl-124 {
        font-size: 124px;
    }

    .tx-xl-124-f {
        font-size: 124px;
    }

    .tx-xl-126 {
        font-size: 126px;
    }

    .tx-xl-126-f {
        font-size: 126px;
    }

    .tx-xl-128 {
        font-size: 128px;
    }

    .tx-xl-128-f {
        font-size: 128px;
    }

    .tx-xl-130 {
        font-size: 130px;
    }

    .tx-xl-130-f {
        font-size: 130px;
    }

    .tx-xl-132 {
        font-size: 132px;
    }

    .tx-xl-132-f {
        font-size: 132px;
    }

    .tx-xl-134 {
        font-size: 134px;
    }

    .tx-xl-134-f {
        font-size: 134px;
    }

    .tx-xl-136 {
        font-size: 136px;
    }

    .tx-xl-136-f {
        font-size: 136px;
    }

    .tx-xl-138 {
        font-size: 138px;
    }

    .tx-xl-138-f {
        font-size: 138px;
    }

    .tx-xl-140 {
        font-size: 140px;
    }

    .tx-xl-140-f {
        font-size: 140px;
    }

    .tx-xl-base {
        font-size: 0.875rem;
    }

    .tx-xl-bold {
        font-weight: 700;
    }

    .tx-xl-semibold {
        font-weight: 600;
    }

    .tx-xl-medium {
        font-weight: 500;
    }

    .tx-xl-normal {
        font-weight: 400;
    }

    .tx-xl-light {
        font-weight: 300;
    }

    .tx-xl-thin {
        font-weight: 200;
    }

    .tx-xl-xthin {
        font-weight: 100;
    }

    .lh-xl-0 {
        line-height: 0;
    }

    .lh-xl-1 {
        line-height: 1.1;
    }

    .lh-xl-2 {
        line-height: 1.2;
    }

    .lh-xl-3 {
        line-height: 1.3;
    }

    .lh-xl-4 {
        line-height: 1.4;
    }

    .lh-xl-5 {
        line-height: 1.5;
    }

    .lh-xl-6 {
        line-height: 1.6;
    }

    .lh-xl-7 {
        line-height: 1.7;
    }

    .lh-xl-8 {
        line-height: 1.8;
    }

    .lh-xl-9 {
        line-height: 1.9;
    }

    .lh-xl-10 {
        line-height: 2;
    }

    .lh-xl-11 {
        line-height: 2.1;
    }

    .lh-xl-12 {
        line-height: 2.2;
    }

    .lh-xl-13 {
        line-height: 2.3;
    }

    .lh-xl-14 {
        line-height: 2.4;
    }

    .lh-xl-15 {
        line-height: 2.5;
    }

    .tx-xl-center {
        text-align: center;
    }

    .tx-xl-right {
        text-align: right;
    }

    .tx-xl-left {
        text-align: left;
    }

    .tx-xl-center-f {
        text-align: center;
    }

    .tx-xl-right-f {
        text-align: right;
    }

    .tx-xl-left-f {
        text-align: left;
    }
}

.text-nowrap {
    white-space: nowrap !important;
}

@media (max-width: 576px) {
    .text-sm-nowrap {
        white-space: nowrap;
    }
}

@media (max-width: 768px) {
    .text-md-nowrap {
        white-space: nowrap;
    }
}

@media (max-width: 992px) {
    .text-lg-nowrap {
        white-space: nowrap;
    }
}

@media (max-width: 1000px) {
    .text-xl-nowrap {
        white-space: nowrap;
    }
}

.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
    text-align: justify !important;
}

.text-wrap {
    white-space: normal !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-start {
    text-align: left !important;
}

.text-end {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

@media (min-width: 576px) {
    .text-sm-left {
        text-align: left;
    }

    .text-sm-right {
        text-align: right;
    }

    .text-sm-center {
        text-align: center;
    }
}

@media (min-width: 768px) {
    .text-md-left {
        text-align: left;
    }

    .text-md-right {
        text-align: right;
    }

    .text-md-center {
        text-align: center;
    }
}

@media (min-width: 992px) {
    .text-lg-left {
        text-align: left;
    }

    .text-lg-right {
        text-align: right;
    }

    .text-lg-center {
        text-align: center;
    }
}

@media (min-width: 1200px) {
    .text-xl-left {
        text-align: left;
    }

    .text-xl-right {
        text-align: right;
    }

    .text-xl-center {
        text-align: center;
    }
}

.text-lowercase {
    text-transform: lowercase;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.font-weight-light {
    font-weight: 300;
}

.font-weight-lighter {
    font-weight: lighter;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-weight-bolder {
    font-weight: bolder;
}

.font-italic {
    font-style: italic;
}

.text-white {
    color: #fff !important;
}

.text-white-80 {
    color: #fff;
    opacity: 0.8;
}

.text-primary {
    color: var(--primary) !important;
}

.text-primary-dark {
    color: var(--primary-darker) !important;
}

.text-primary-75 {
    color: var(--primary-75) !important;
}

.text-primary-50 {
    color: var(--primary-50) !important;
}

.text-primary-25 {
    color: var(--primary-25) !important;
}

a.text-primary:hover,
a.text-primary:focus {
    color: var(--primary-75) !important;
}

.text-secondary {
    color: var(--secondary) !important;
}

.text-secondary-dark {
    color: var(--secondary-darker) !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
    color: var(--secondary-50) !important;
}

.text-success {
    color: var(--success) !important;
}

.text-success-dark {
    color: var(--success-darker) !important;
}

a.text-success:hover,
a.text-success:focus {
    color: var(--success-50) !important;
}

.text-error {
    color: var(--error) !important;
}

.text-error-dark {
    color: var(--error-darker) !important;
}

.text-info {
    color: var(--info) !important;
}

.text-info-dark {
    color: var(--info-darker) !important;
}

a.text-info:hover,
a.text-info:focus {
    color: var(--info-50) !important;
}

.text-warning {
    color: var(--warning) !important;
}

.text-warning-dark {
    color: var(--warning-darker) !important;
}

a.text-warning:hover,
a.text-warning:focus {
    color: var(--warning-50) !important;
}

.text-purple {
    color: #ad4ffa !important;
}

a.text-purple:hover,
a.text-purple:focus {
    color: #ad4ffa !important;
}

.text-danger {
    color: var(--error) !important;
}

a.text-danger:hover,
a.text-danger:focus {
    color: var(--error-50) !important;
}

.text-pink {
    color: #f754fa !important;
}

a.text-pink:hover,
a.text-pink:focus {
    color: #e61078 !important;
}

.text-orange {
    color: #fd7e14 !important;
}

a.text-orange:hover,
a.text-orange:focus {
    color: #e46f0e !important;
}

.text-light {
    color: #f9f9fb !important;
}

a.text-light:hover,
a.text-light:focus {
    color: #c5cbda !important;
}

.text-teal {
    color: #29ddee !important;
}

a.text-teal:hover,
a.text-teal:focus {
    color: #06c1c1 !important;
}

.text-dark {
    color: #383853 !important;
}

a.text-dark:hover,
a.text-dark:focus {
    color: #1e2533 !important;
}

.text-body {
    color: #4a4a69;
}

.text-muted {
    color: #7987a1 !important;
}

.text-black-50 {
    color: rgba(0, 0, 0, 0.5);
}

.text-white-50 {
    color: rgba(255, 255, 255, 0.5);
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.text-decoration-none {
    text-decoration: none;
}

.text-break {
    word-break: break-word;
    overflow-wrap: break-word;
}

.text-reset {
    color: inherit;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden;
}

.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

.w-auto {
    width: auto !important;
}

/* ###### 9.9 Width   ###### */
.wd-1 {
    width: 1px;
}

.wd-2 {
    width: 2px;
}

.wd-3 {
    width: 3px;
}

.wd-4 {
    width: 4px;
}

.wd-6 {
    width: 6px;
}

.wd-7 {
    width: 7px;
}

.wd-8 {
    width: 8px;
}

.wd-9 {
    width: 9px;
}

.wd-280 {
    width: 280px;
}

.wd-5 {
    width: 5px;
}

.wd-5p {
    width: 5%;
}

.mx-wd-5p {
    max-width: 5%;
}

.mn-wd-5p {
    min-width: 5%;
}

.wd-5-f {
    width: 5px !important;
}

.wd-5p-f {
    width: 5% !important;
}

.mx-wd-5p-f {
    max-width: 5% !important;
}

.mn-wd-5p-f {
    min-width: 5% !important;
}

.wd-10 {
    width: 10px;
}

.wd-10p {
    width: 10%;
}

.mx-wd-10p {
    max-width: 10%;
}

.mn-wd-10p {
    min-width: 10%;
}

.wd-10-f {
    width: 10px !important;
}

.wd-10p-f {
    width: 10% !important;
}

.mx-wd-10p-f {
    max-width: 10% !important;
}

.mn-wd-10p-f {
    min-width: 10% !important;
}

.wd-15 {
    width: 15px;
}

.wd-15p {
    width: 15%;
}

.mx-wd-15p {
    max-width: 15%;
}

.mn-wd-15p {
    min-width: 15%;
}

.wd-15-f {
    width: 15px !important;
}

.wd-15p-f {
    width: 15% !important;
}

.mx-wd-15p-f {
    max-width: 15% !important;
}

.mn-wd-15p-f {
    min-width: 15% !important;
}

.wd-20 {
    width: 20px;
}

.wd-20p {
    width: 20%;
}

.mx-wd-20p {
    max-width: 20%;
}

.mn-wd-20p {
    min-width: 20%;
}

.wd-20-f {
    width: 20px !important;
}

.wd-20p-f {
    width: 20% !important;
}

.mx-wd-20p-f {
    max-width: 20% !important;
}

.mn-wd-20p-f {
    min-width: 20% !important;
}

.wd-25 {
    width: 25px;
}

.wd-25p {
    width: 25%;
}

.mx-wd-25p {
    max-width: 25%;
}

.mn-wd-25p {
    min-width: 25%;
}

.wd-25-f {
    width: 25px !important;
}

.wd-25p-f {
    width: 25% !important;
}

.mx-wd-25p-f {
    max-width: 25% !important;
}

.mn-wd-25p-f {
    min-width: 25% !important;
}

.wd-30 {
    width: 30px;
}

.wd-30p {
    width: 30%;
}

.mx-wd-30p {
    max-width: 30%;
}

.mn-wd-30p {
    min-width: 30%;
}

.wd-30-f {
    width: 30px !important;
}

.wd-30p-f {
    width: 30% !important;
}

.mx-wd-30p-f {
    max-width: 30% !important;
}

.mn-wd-30p-f {
    min-width: 30% !important;
}

.wd-35 {
    width: 35px;
}

.wd-35p {
    width: 35%;
}

.mx-wd-35p {
    max-width: 35%;
}

.mn-wd-35p {
    min-width: 35%;
}

.wd-35-f {
    width: 35px !important;
}

.wd-35p-f {
    width: 35% !important;
}

.mx-wd-35p-f {
    max-width: 35% !important;
}

.mn-wd-35p-f {
    min-width: 35% !important;
}

.wd-40 {
    width: 40px;
}

.wd-40p {
    width: 40%;
}

.mx-wd-40p {
    max-width: 40%;
}

.mn-wd-40p {
    min-width: 40%;
}

.wd-40-f {
    width: 40px !important;
}

.wd-40p-f {
    width: 40% !important;
}

.mx-wd-40p-f {
    max-width: 40% !important;
}

.mn-wd-40p-f {
    min-width: 40% !important;
}

.wd-45 {
    width: 45px;
}

.wd-45p {
    width: 45%;
}

.mx-wd-45p {
    max-width: 45%;
}

.mn-wd-45p {
    min-width: 45%;
}

.wd-45-f {
    width: 45px !important;
}

.wd-45p-f {
    width: 45% !important;
}

.mx-wd-45p-f {
    max-width: 45% !important;
}

.mn-wd-45p-f {
    min-width: 45% !important;
}

.wd-50 {
    width: 50px;
}

.wd-50p {
    width: 50%;
}

.mx-wd-50p {
    max-width: 50%;
}

.mn-wd-50p {
    min-width: 50%;
}

.wd-50-f {
    width: 50px !important;
}

.wd-50p-f {
    width: 50% !important;
}

.mx-wd-50p-f {
    max-width: 50% !important;
}

.mn-wd-50p-f {
    min-width: 50% !important;
}

.wd-55 {
    width: 55px;
}

.wd-55p {
    width: 55%;
}

.mx-wd-55p {
    max-width: 55%;
}

.mn-wd-55p {
    min-width: 55%;
}

.wd-55-f {
    width: 55px !important;
}

.wd-55p-f {
    width: 55% !important;
}

.mx-wd-55p-f {
    max-width: 55% !important;
}

.mn-wd-55p-f {
    min-width: 55% !important;
}

.wd-60 {
    width: 60px;
}

.wd-60p {
    width: 60%;
}

.mx-wd-60p {
    max-width: 60%;
}

.mn-wd-60p {
    min-width: 60%;
}

.wd-60-f {
    width: 60px !important;
}

.wd-60p-f {
    width: 60% !important;
}

.mx-wd-60p-f {
    max-width: 60% !important;
}

.mn-wd-60p-f {
    min-width: 60% !important;
}

.wd-65 {
    width: 65px;
}

.wd-65p {
    width: 65%;
}

.mx-wd-65p {
    max-width: 65%;
}

.mn-wd-65p {
    min-width: 65%;
}

.wd-65-f {
    width: 65px !important;
}

.wd-65p-f {
    width: 65% !important;
}

.mx-wd-65p-f {
    max-width: 65% !important;
}

.mn-wd-65p-f {
    min-width: 65% !important;
}

.wd-70 {
    width: 70px;
}

.wd-70p {
    width: 70%;
}

.mx-wd-70p {
    max-width: 70%;
}

.mn-wd-70p {
    min-width: 70%;
}

.wd-70-f {
    width: 70px !important;
}

.wd-70p-f {
    width: 70% !important;
}

.mx-wd-70p-f {
    max-width: 70% !important;
}

.mn-wd-70p-f {
    min-width: 70% !important;
}

.wd-75 {
    width: 75px;
}

.wd-75p {
    width: 75%;
}

.mx-wd-75p {
    max-width: 75%;
}

.mn-wd-75p {
    min-width: 75%;
}

.wd-75-f {
    width: 75px !important;
}

.wd-75p-f {
    width: 75% !important;
}

.mx-wd-75p-f {
    max-width: 75% !important;
}

.mn-wd-75p-f {
    min-width: 75% !important;
}

.wd-80 {
    width: 80px;
}

.wd-80p {
    width: 80%;
}

.mx-wd-80p {
    max-width: 80%;
}

.mn-wd-80p {
    min-width: 80%;
}

.wd-80-f {
    width: 80px !important;
}

.wd-80p-f {
    width: 80% !important;
}

.mx-wd-80p-f {
    max-width: 80% !important;
}

.mn-wd-80p-f {
    min-width: 80% !important;
}

.wd-85 {
    width: 85px;
}

.wd-85p {
    width: 85%;
}

.mx-wd-85p {
    max-width: 85%;
}

.mn-wd-85p {
    min-width: 85%;
}

.wd-85-f {
    width: 85px !important;
}

.wd-85p-f {
    width: 85% !important;
}

.mx-wd-85p-f {
    max-width: 85% !important;
}

.mn-wd-85p-f {
    min-width: 85% !important;
}

.wd-90 {
    width: 90px;
}

.wd-90p {
    width: 90%;
}

.mx-wd-90p {
    max-width: 90%;
}

.mn-wd-90p {
    min-width: 90%;
}

.wd-90-f {
    width: 90px !important;
}

.wd-90p-f {
    width: 90% !important;
}

.mx-wd-90p-f {
    max-width: 90% !important;
}

.mn-wd-90p-f {
    min-width: 90% !important;
}

.wd-95 {
    width: 95px;
}

.wd-95p {
    width: 95%;
}

.mx-wd-95p {
    max-width: 95%;
}

.mn-wd-95p {
    min-width: 95%;
}

.wd-95-f {
    width: 95px !important;
}

.wd-95p-f {
    width: 95% !important;
}

.mx-wd-95p-f {
    max-width: 95% !important;
}

.mn-wd-95p-f {
    min-width: 95% !important;
}

.wd-100 {
    width: 100px !important;
}

.wd-100p {
    width: 100%;
}

.mx-wd-100p {
    max-width: 100%;
}

.mn-wd-100p {
    min-width: 100%;
}

.wd-100-f {
    width: 100px !important;
}

.wd-100p-f {
    width: 100% !important;
}

.mx-wd-100p-f {
    max-width: 100% !important;
}

.mn-wd-100p-f {
    min-width: 100% !important;
}

.wd-150 {
    width: 150px;
}

.wd-150-f {
    width: 150px !important;
}

.wd-200 {
    width: 200px !important;
}

.wd-200-f {
    width: 200px !important;
}

.wd-230 {
    width: 230px;
}

.wd-250 {
    width: 250px;
}

.wd-250-f {
    width: 250px !important;
}

.wd-300 {
    width: 300px;
}

.wd-300-f {
    width: 300px !important;
}

.wd-350 {
    width: 350px;
}

.wd-350-f {
    width: 350px !important;
}

.wd-400 {
    width: 400px;
}

.wd-400-f {
    width: 400px !important;
}

.wd-450 {
    width: 450px;
}

.wd-450-f {
    width: 450px !important;
}

.wd-500 {
    width: 500px;
}

.wd-500-f {
    width: 500px !important;
}

.wd-550 {
    width: 550px;
}

.wd-550-f {
    width: 550px !important;
}

.wd-600 {
    width: 600px;
}

.wd-600-f {
    width: 600px !important;
}

.wd-650 {
    width: 650px;
}

.wd-650-f {
    width: 650px !important;
}

.wd-700 {
    width: 700px;
}

.wd-700-f {
    width: 700px !important;
}

.wd-750 {
    width: 750px;
}

.wd-750-f {
    width: 750px !important;
}

.wd-800 {
    width: 800px;
}

.wd-800-f {
    width: 800px !important;
}

.wd-850 {
    width: 850px;
}

.wd-850-f {
    width: 850px !important;
}

.wd-900 {
    width: 900px;
}

.wd-900-f {
    width: 900px !important;
}

.wd-950 {
    width: 950px;
}

.wd-950-f {
    width: 950px !important;
}

.wd-1000 {
    width: 1000px;
}

.wd-1000-f {
    width: 1000px !important;
}

@media (min-width: 480px) {
    .wd-xs-5 {
        width: 5px;
    }

    .wd-xs-5p {
        width: 5%;
    }

    .mx-wd-xs-5p {
        max-width: 5%;
    }

    .mn-wd-xs-5p {
        min-width: 5%;
    }

    .wd-xs-5-f {
        width: 5px !important;
    }

    .wd-xs-5p-f {
        width: 5% !important;
    }

    .mx-wd-xs-5p-f {
        max-width: 5% !important;
    }

    .mn-wd-xs-5p-f {
        min-width: 5% !important;
    }

    .wd-xs-10 {
        width: 10px;
    }

    .wd-xs-10p {
        width: 10%;
    }

    .mx-wd-xs-10p {
        max-width: 10%;
    }

    .mn-wd-xs-10p {
        min-width: 10%;
    }

    .wd-xs-10-f {
        width: 10px !important;
    }

    .wd-xs-10p-f {
        width: 10% !important;
    }

    .mx-wd-xs-10p-f {
        max-width: 10% !important;
    }

    .mn-wd-xs-10p-f {
        min-width: 10% !important;
    }

    .wd-xs-15 {
        width: 15px;
    }

    .wd-xs-15p {
        width: 15%;
    }

    .mx-wd-xs-15p {
        max-width: 15%;
    }

    .mn-wd-xs-15p {
        min-width: 15%;
    }

    .wd-xs-15-f {
        width: 15px !important;
    }

    .wd-xs-15p-f {
        width: 15% !important;
    }

    .mx-wd-xs-15p-f {
        max-width: 15% !important;
    }

    .mn-wd-xs-15p-f {
        min-width: 15% !important;
    }

    .wd-xs-20 {
        width: 20px;
    }

    .wd-xs-20p {
        width: 20%;
    }

    .mx-wd-xs-20p {
        max-width: 20%;
    }

    .mn-wd-xs-20p {
        min-width: 20%;
    }

    .wd-xs-20-f {
        width: 20px !important;
    }

    .wd-xs-20p-f {
        width: 20% !important;
    }

    .mx-wd-xs-20p-f {
        max-width: 20% !important;
    }

    .mn-wd-xs-20p-f {
        min-width: 20% !important;
    }

    .wd-xs-25 {
        width: 25px;
    }

    .wd-xs-25p {
        width: 25%;
    }

    .mx-wd-xs-25p {
        max-width: 25%;
    }

    .mn-wd-xs-25p {
        min-width: 25%;
    }

    .wd-xs-25-f {
        width: 25px !important;
    }

    .wd-xs-25p-f {
        width: 25% !important;
    }

    .mx-wd-xs-25p-f {
        max-width: 25% !important;
    }

    .mn-wd-xs-25p-f {
        min-width: 25% !important;
    }

    .wd-xs-30 {
        width: 30px;
    }

    .wd-xs-30p {
        width: 30%;
    }

    .mx-wd-xs-30p {
        max-width: 30%;
    }

    .mn-wd-xs-30p {
        min-width: 30%;
    }

    .wd-xs-30-f {
        width: 30px !important;
    }

    .wd-xs-30p-f {
        width: 30% !important;
    }

    .mx-wd-xs-30p-f {
        max-width: 30% !important;
    }

    .mn-wd-xs-30p-f {
        min-width: 30% !important;
    }

    .wd-xs-35 {
        width: 35px;
    }

    .wd-xs-35p {
        width: 35%;
    }

    .mx-wd-xs-35p {
        max-width: 35%;
    }

    .mn-wd-xs-35p {
        min-width: 35%;
    }

    .wd-xs-35-f {
        width: 35px !important;
    }

    .wd-xs-35p-f {
        width: 35% !important;
    }

    .mx-wd-xs-35p-f {
        max-width: 35% !important;
    }

    .mn-wd-xs-35p-f {
        min-width: 35% !important;
    }

    .wd-xs-40 {
        width: 40px;
    }

    .wd-xs-40p {
        width: 40%;
    }

    .mx-wd-xs-40p {
        max-width: 40%;
    }

    .mn-wd-xs-40p {
        min-width: 40%;
    }

    .wd-xs-40-f {
        width: 40px !important;
    }

    .wd-xs-40p-f {
        width: 40% !important;
    }

    .mx-wd-xs-40p-f {
        max-width: 40% !important;
    }

    .mn-wd-xs-40p-f {
        min-width: 40% !important;
    }

    .wd-xs-45 {
        width: 45px;
    }

    .wd-xs-45p {
        width: 45%;
    }

    .mx-wd-xs-45p {
        max-width: 45%;
    }

    .mn-wd-xs-45p {
        min-width: 45%;
    }

    .wd-xs-45-f {
        width: 45px !important;
    }

    .wd-xs-45p-f {
        width: 45% !important;
    }

    .mx-wd-xs-45p-f {
        max-width: 45% !important;
    }

    .mn-wd-xs-45p-f {
        min-width: 45% !important;
    }

    .wd-xs-50 {
        width: 50px;
    }

    .wd-xs-50p {
        width: 50%;
    }

    .mx-wd-xs-50p {
        max-width: 50%;
    }

    .mn-wd-xs-50p {
        min-width: 50%;
    }

    .wd-xs-50-f {
        width: 50px !important;
    }

    .wd-xs-50p-f {
        width: 50% !important;
    }

    .mx-wd-xs-50p-f {
        max-width: 50% !important;
    }

    .mn-wd-xs-50p-f {
        min-width: 50% !important;
    }

    .wd-xs-55 {
        width: 55px;
    }

    .wd-xs-55p {
        width: 55%;
    }

    .mx-wd-xs-55p {
        max-width: 55%;
    }

    .mn-wd-xs-55p {
        min-width: 55%;
    }

    .wd-xs-55-f {
        width: 55px !important;
    }

    .wd-xs-55p-f {
        width: 55% !important;
    }

    .mx-wd-xs-55p-f {
        max-width: 55% !important;
    }

    .mn-wd-xs-55p-f {
        min-width: 55% !important;
    }

    .wd-xs-60 {
        width: 60px;
    }

    .wd-xs-60p {
        width: 60%;
    }

    .mx-wd-xs-60p {
        max-width: 60%;
    }

    .mn-wd-xs-60p {
        min-width: 60%;
    }

    .wd-xs-60-f {
        width: 60px !important;
    }

    .wd-xs-60p-f {
        width: 60% !important;
    }

    .mx-wd-xs-60p-f {
        max-width: 60% !important;
    }

    .mn-wd-xs-60p-f {
        min-width: 60% !important;
    }

    .wd-xs-65 {
        width: 65px;
    }

    .wd-xs-65p {
        width: 65%;
    }

    .mx-wd-xs-65p {
        max-width: 65%;
    }

    .mn-wd-xs-65p {
        min-width: 65%;
    }

    .wd-xs-65-f {
        width: 65px !important;
    }

    .wd-xs-65p-f {
        width: 65% !important;
    }

    .mx-wd-xs-65p-f {
        max-width: 65% !important;
    }

    .mn-wd-xs-65p-f {
        min-width: 65% !important;
    }

    .wd-xs-70 {
        width: 70px;
    }

    .wd-xs-70p {
        width: 70%;
    }

    .mx-wd-xs-70p {
        max-width: 70%;
    }

    .mn-wd-xs-70p {
        min-width: 70%;
    }

    .wd-xs-70-f {
        width: 70px !important;
    }

    .wd-xs-70p-f {
        width: 70% !important;
    }

    .mx-wd-xs-70p-f {
        max-width: 70% !important;
    }

    .mn-wd-xs-70p-f {
        min-width: 70% !important;
    }

    .wd-xs-75 {
        width: 75px;
    }

    .wd-xs-75p {
        width: 75%;
    }

    .mx-wd-xs-75p {
        max-width: 75%;
    }

    .mn-wd-xs-75p {
        min-width: 75%;
    }

    .wd-xs-75-f {
        width: 75px !important;
    }

    .wd-xs-75p-f {
        width: 75% !important;
    }

    .mx-wd-xs-75p-f {
        max-width: 75% !important;
    }

    .mn-wd-xs-75p-f {
        min-width: 75% !important;
    }

    .wd-xs-80 {
        width: 80px;
    }

    .wd-xs-80p {
        width: 80%;
    }

    .mx-wd-xs-80p {
        max-width: 80%;
    }

    .mn-wd-xs-80p {
        min-width: 80%;
    }

    .wd-xs-80-f {
        width: 80px !important;
    }

    .wd-xs-80p-f {
        width: 80% !important;
    }

    .mx-wd-xs-80p-f {
        max-width: 80% !important;
    }

    .mn-wd-xs-80p-f {
        min-width: 80% !important;
    }

    .wd-xs-85 {
        width: 85px;
    }

    .wd-xs-85p {
        width: 85%;
    }

    .mx-wd-xs-85p {
        max-width: 85%;
    }

    .mn-wd-xs-85p {
        min-width: 85%;
    }

    .wd-xs-85-f {
        width: 85px !important;
    }

    .wd-xs-85p-f {
        width: 85% !important;
    }

    .mx-wd-xs-85p-f {
        max-width: 85% !important;
    }

    .mn-wd-xs-85p-f {
        min-width: 85% !important;
    }

    .wd-xs-90 {
        width: 90px;
    }

    .wd-xs-90p {
        width: 90%;
    }

    .mx-wd-xs-90p {
        max-width: 90%;
    }

    .mn-wd-xs-90p {
        min-width: 90%;
    }

    .wd-xs-90-f {
        width: 90px !important;
    }

    .wd-xs-90p-f {
        width: 90% !important;
    }

    .mx-wd-xs-90p-f {
        max-width: 90% !important;
    }

    .mn-wd-xs-90p-f {
        min-width: 90% !important;
    }

    .wd-xs-95 {
        width: 95px;
    }

    .wd-xs-95p {
        width: 95%;
    }

    .mx-wd-xs-95p {
        max-width: 95%;
    }

    .mn-wd-xs-95p {
        min-width: 95%;
    }

    .wd-xs-95-f {
        width: 95px !important;
    }

    .wd-xs-95p-f {
        width: 95% !important;
    }

    .mx-wd-xs-95p-f {
        max-width: 95% !important;
    }

    .mn-wd-xs-95p-f {
        min-width: 95% !important;
    }

    .wd-xs-100 {
        width: 100px;
    }

    .wd-xs-100p {
        width: 100%;
    }

    .mx-wd-xs-100p {
        max-width: 100%;
    }

    .mn-wd-xs-100p {
        min-width: 100%;
    }

    .wd-xs-100-f {
        width: 100px !important;
    }

    .wd-xs-100p-f {
        width: 100% !important;
    }

    .mx-wd-xs-100p-f {
        max-width: 100% !important;
    }

    .mn-wd-xs-100p-f {
        min-width: 100% !important;
    }

    .wd-xs-150 {
        width: 150px;
    }

    .wd-xs-150p {
        width: 150%;
    }

    .mx-wd-xs-150p {
        max-width: 150%;
    }

    .mn-wd-xs-150p {
        min-width: 150%;
    }

    .wd-xs-150-f {
        width: 150px !important;
    }

    .wd-xs-150p-f {
        width: 150% !important;
    }

    .mx-wd-xs-150p-f {
        max-width: 150% !important;
    }

    .mn-wd-xs-150p-f {
        min-width: 150% !important;
    }

    .wd-xs-200 {
        width: 200px;
    }

    .wd-xs-200p {
        width: 200%;
    }

    .mx-wd-xs-200p {
        max-width: 200%;
    }

    .mn-wd-xs-200p {
        min-width: 200%;
    }

    .wd-xs-200-f {
        width: 200px !important;
    }

    .wd-xs-200p-f {
        width: 200% !important;
    }

    .mx-wd-xs-200p-f {
        max-width: 200% !important;
    }

    .mn-wd-xs-200p-f {
        min-width: 200% !important;
    }

    .wd-xs-250 {
        width: 250px;
    }

    .wd-xs-250p {
        width: 250%;
    }

    .mx-wd-xs-250p {
        max-width: 250%;
    }

    .mn-wd-xs-250p {
        min-width: 250%;
    }

    .wd-xs-250-f {
        width: 250px !important;
    }

    .wd-xs-250p-f {
        width: 250% !important;
    }

    .mx-wd-xs-250p-f {
        max-width: 250% !important;
    }

    .mn-wd-xs-250p-f {
        min-width: 250% !important;
    }

    .wd-xs-300 {
        width: 300px;
    }

    .wd-xs-300p {
        width: 300%;
    }

    .mx-wd-xs-300p {
        max-width: 300%;
    }

    .mn-wd-xs-300p {
        min-width: 300%;
    }

    .wd-xs-300-f {
        width: 300px !important;
    }

    .wd-xs-300p-f {
        width: 300% !important;
    }

    .mx-wd-xs-300p-f {
        max-width: 300% !important;
    }

    .mn-wd-xs-300p-f {
        min-width: 300% !important;
    }

    .wd-xs-350 {
        width: 350px;
    }

    .wd-xs-350p {
        width: 350%;
    }

    .mx-wd-xs-350p {
        max-width: 350%;
    }

    .mn-wd-xs-350p {
        min-width: 350%;
    }

    .wd-xs-350-f {
        width: 350px !important;
    }

    .wd-xs-350p-f {
        width: 350% !important;
    }

    .mx-wd-xs-350p-f {
        max-width: 350% !important;
    }

    .mn-wd-xs-350p-f {
        min-width: 350% !important;
    }

    .wd-xs-400 {
        width: 400px;
    }

    .wd-xs-400p {
        width: 400%;
    }

    .mx-wd-xs-400p {
        max-width: 400%;
    }

    .mn-wd-xs-400p {
        min-width: 400%;
    }

    .wd-xs-400-f {
        width: 400px !important;
    }

    .wd-xs-400p-f {
        width: 400% !important;
    }

    .mx-wd-xs-400p-f {
        max-width: 400% !important;
    }

    .mn-wd-xs-400p-f {
        min-width: 400% !important;
    }

    .wd-xs-450 {
        width: 450px;
    }

    .wd-xs-450p {
        width: 450%;
    }

    .mx-wd-xs-450p {
        max-width: 450%;
    }

    .mn-wd-xs-450p {
        min-width: 450%;
    }

    .wd-xs-450-f {
        width: 450px !important;
    }

    .wd-xs-450p-f {
        width: 450% !important;
    }

    .mx-wd-xs-450p-f {
        max-width: 450% !important;
    }

    .mn-wd-xs-450p-f {
        min-width: 450% !important;
    }

    .wd-xs-500 {
        width: 500px;
    }

    .wd-xs-500p {
        width: 500%;
    }

    .mx-wd-xs-500p {
        max-width: 500%;
    }

    .mn-wd-xs-500p {
        min-width: 500%;
    }

    .wd-xs-500-f {
        width: 500px !important;
    }

    .wd-xs-500p-f {
        width: 500% !important;
    }

    .mx-wd-xs-500p-f {
        max-width: 500% !important;
    }

    .mn-wd-xs-500p-f {
        min-width: 500% !important;
    }

    .wd-xs-550 {
        width: 550px;
    }

    .wd-xs-550p {
        width: 550%;
    }

    .mx-wd-xs-550p {
        max-width: 550%;
    }

    .mn-wd-xs-550p {
        min-width: 550%;
    }

    .wd-xs-550-f {
        width: 550px !important;
    }

    .wd-xs-550p-f {
        width: 550% !important;
    }

    .mx-wd-xs-550p-f {
        max-width: 550% !important;
    }

    .mn-wd-xs-550p-f {
        min-width: 550% !important;
    }

    .wd-xs-600 {
        width: 600px;
    }

    .wd-xs-600p {
        width: 600%;
    }

    .mx-wd-xs-600p {
        max-width: 600%;
    }

    .mn-wd-xs-600p {
        min-width: 600%;
    }

    .wd-xs-600-f {
        width: 600px !important;
    }

    .wd-xs-600p-f {
        width: 600% !important;
    }

    .mx-wd-xs-600p-f {
        max-width: 600% !important;
    }

    .mn-wd-xs-600p-f {
        min-width: 600% !important;
    }

    .wd-xs-650 {
        width: 650px;
    }

    .wd-xs-650p {
        width: 650%;
    }

    .mx-wd-xs-650p {
        max-width: 650%;
    }

    .mn-wd-xs-650p {
        min-width: 650%;
    }

    .wd-xs-650-f {
        width: 650px !important;
    }

    .wd-xs-650p-f {
        width: 650% !important;
    }

    .mx-wd-xs-650p-f {
        max-width: 650% !important;
    }

    .mn-wd-xs-650p-f {
        min-width: 650% !important;
    }

    .wd-xs-700 {
        width: 700px;
    }

    .wd-xs-700p {
        width: 700%;
    }

    .mx-wd-xs-700p {
        max-width: 700%;
    }

    .mn-wd-xs-700p {
        min-width: 700%;
    }

    .wd-xs-700-f {
        width: 700px !important;
    }

    .wd-xs-700p-f {
        width: 700% !important;
    }

    .mx-wd-xs-700p-f {
        max-width: 700% !important;
    }

    .mn-wd-xs-700p-f {
        min-width: 700% !important;
    }

    .wd-xs-750 {
        width: 750px;
    }

    .wd-xs-750p {
        width: 750%;
    }

    .mx-wd-xs-750p {
        max-width: 750%;
    }

    .mn-wd-xs-750p {
        min-width: 750%;
    }

    .wd-xs-750-f {
        width: 750px !important;
    }

    .wd-xs-750p-f {
        width: 750% !important;
    }

    .mx-wd-xs-750p-f {
        max-width: 750% !important;
    }

    .mn-wd-xs-750p-f {
        min-width: 750% !important;
    }

    .wd-xs-800 {
        width: 800px;
    }

    .wd-xs-800p {
        width: 800%;
    }

    .mx-wd-xs-800p {
        max-width: 800%;
    }

    .mn-wd-xs-800p {
        min-width: 800%;
    }

    .wd-xs-800-f {
        width: 800px !important;
    }

    .wd-xs-800p-f {
        width: 800% !important;
    }

    .mx-wd-xs-800p-f {
        max-width: 800% !important;
    }

    .mn-wd-xs-800p-f {
        min-width: 800% !important;
    }

    .wd-xs-850 {
        width: 850px;
    }

    .wd-xs-850p {
        width: 850%;
    }

    .mx-wd-xs-850p {
        max-width: 850%;
    }

    .mn-wd-xs-850p {
        min-width: 850%;
    }

    .wd-xs-850-f {
        width: 850px !important;
    }

    .wd-xs-850p-f {
        width: 850% !important;
    }

    .mx-wd-xs-850p-f {
        max-width: 850% !important;
    }

    .mn-wd-xs-850p-f {
        min-width: 850% !important;
    }

    .wd-xs-900 {
        width: 900px;
    }

    .wd-xs-900p {
        width: 900%;
    }

    .mx-wd-xs-900p {
        max-width: 900%;
    }

    .mn-wd-xs-900p {
        min-width: 900%;
    }

    .wd-xs-900-f {
        width: 900px !important;
    }

    .wd-xs-900p-f {
        width: 900% !important;
    }

    .mx-wd-xs-900p-f {
        max-width: 900% !important;
    }

    .mn-wd-xs-900p-f {
        min-width: 900% !important;
    }

    .wd-xs-950 {
        width: 950px;
    }

    .wd-xs-950p {
        width: 950%;
    }

    .mx-wd-xs-950p {
        max-width: 950%;
    }

    .mn-wd-xs-950p {
        min-width: 950%;
    }

    .wd-xs-950-f {
        width: 950px !important;
    }

    .wd-xs-950p-f {
        width: 950% !important;
    }

    .mx-wd-xs-950p-f {
        max-width: 950% !important;
    }

    .mn-wd-xs-950p-f {
        min-width: 950% !important;
    }

    .wd-xs-1000 {
        width: 1000px;
    }

    .wd-xs-1000p {
        width: 1000%;
    }

    .mx-wd-xs-1000p {
        max-width: 1000%;
    }

    .mn-wd-xs-1000p {
        min-width: 1000%;
    }

    .wd-xs-1000-f {
        width: 1000px !important;
    }

    .wd-xs-1000p-f {
        width: 1000% !important;
    }

    .mx-wd-xs-1000p-f {
        max-width: 1000% !important;
    }

    .mn-wd-xs-1000p-f {
        min-width: 1000% !important;
    }

    .wd-xs-auto {
        width: auto;
    }

    .wd-xs-auto-f {
        width: auto !important;
    }
}

@media (min-width: 576px) {
    .wd-sm-5 {
        width: 5px;
    }

    .wd-sm-5p {
        width: 5%;
    }

    .mx-wd-sm-5p {
        max-width: 5%;
    }

    .mn-wd-sm-5p {
        min-width: 5%;
    }

    .wd-sm-5-f {
        width: 5px !important;
    }

    .wd-sm-5p-f {
        width: 5% !important;
    }

    .mx-wd-sm-5p-f {
        max-width: 5% !important;
    }

    .mn-wd-sm-5p-f {
        min-width: 5% !important;
    }

    .wd-sm-10 {
        width: 10px;
    }

    .wd-sm-10p {
        width: 10%;
    }

    .mx-wd-sm-10p {
        max-width: 10%;
    }

    .mn-wd-sm-10p {
        min-width: 10%;
    }

    .wd-sm-10-f {
        width: 10px !important;
    }

    .wd-sm-10p-f {
        width: 10% !important;
    }

    .mx-wd-sm-10p-f {
        max-width: 10% !important;
    }

    .mn-wd-sm-10p-f {
        min-width: 10% !important;
    }

    .wd-sm-15 {
        width: 15px;
    }

    .wd-sm-15p {
        width: 15%;
    }

    .mx-wd-sm-15p {
        max-width: 15%;
    }

    .mn-wd-sm-15p {
        min-width: 15%;
    }

    .wd-sm-15-f {
        width: 15px !important;
    }

    .wd-sm-15p-f {
        width: 15% !important;
    }

    .mx-wd-sm-15p-f {
        max-width: 15% !important;
    }

    .mn-wd-sm-15p-f {
        min-width: 15% !important;
    }

    .wd-sm-20 {
        width: 20px;
    }

    .wd-sm-20p {
        width: 20%;
    }

    .mx-wd-sm-20p {
        max-width: 20%;
    }

    .mn-wd-sm-20p {
        min-width: 20%;
    }

    .wd-sm-20-f {
        width: 20px !important;
    }

    .wd-sm-20p-f {
        width: 20% !important;
    }

    .mx-wd-sm-20p-f {
        max-width: 20% !important;
    }

    .mn-wd-sm-20p-f {
        min-width: 20% !important;
    }

    .wd-sm-25 {
        width: 25px;
    }

    .wd-sm-25p {
        width: 25%;
    }

    .mx-wd-sm-25p {
        max-width: 25%;
    }

    .mn-wd-sm-25p {
        min-width: 25%;
    }

    .wd-sm-25-f {
        width: 25px !important;
    }

    .wd-sm-25p-f {
        width: 25% !important;
    }

    .mx-wd-sm-25p-f {
        max-width: 25% !important;
    }

    .mn-wd-sm-25p-f {
        min-width: 25% !important;
    }

    .wd-sm-30 {
        width: 30px;
    }

    .wd-sm-30p {
        width: 30%;
    }

    .mx-wd-sm-30p {
        max-width: 30%;
    }

    .mn-wd-sm-30p {
        min-width: 30%;
    }

    .wd-sm-30-f {
        width: 30px !important;
    }

    .wd-sm-30p-f {
        width: 30% !important;
    }

    .mx-wd-sm-30p-f {
        max-width: 30% !important;
    }

    .mn-wd-sm-30p-f {
        min-width: 30% !important;
    }

    .wd-sm-35 {
        width: 35px;
    }

    .wd-sm-35p {
        width: 35%;
    }

    .mx-wd-sm-35p {
        max-width: 35%;
    }

    .mn-wd-sm-35p {
        min-width: 35%;
    }

    .wd-sm-35-f {
        width: 35px !important;
    }

    .wd-sm-35p-f {
        width: 35% !important;
    }

    .mx-wd-sm-35p-f {
        max-width: 35% !important;
    }

    .mn-wd-sm-35p-f {
        min-width: 35% !important;
    }

    .wd-sm-40 {
        width: 40px;
    }

    .wd-sm-40p {
        width: 40%;
    }

    .mx-wd-sm-40p {
        max-width: 40%;
    }

    .mn-wd-sm-40p {
        min-width: 40%;
    }

    .wd-sm-40-f {
        width: 40px !important;
    }

    .wd-sm-40p-f {
        width: 40% !important;
    }

    .mx-wd-sm-40p-f {
        max-width: 40% !important;
    }

    .mn-wd-sm-40p-f {
        min-width: 40% !important;
    }

    .wd-sm-45 {
        width: 45px;
    }

    .wd-sm-45p {
        width: 45%;
    }

    .mx-wd-sm-45p {
        max-width: 45%;
    }

    .mn-wd-sm-45p {
        min-width: 45%;
    }

    .wd-sm-45-f {
        width: 45px !important;
    }

    .wd-sm-45p-f {
        width: 45% !important;
    }

    .mx-wd-sm-45p-f {
        max-width: 45% !important;
    }

    .mn-wd-sm-45p-f {
        min-width: 45% !important;
    }

    .wd-sm-50 {
        width: 50px;
    }

    .wd-sm-50p {
        width: 50%;
    }

    .mx-wd-sm-50p {
        max-width: 50%;
    }

    .mn-wd-sm-50p {
        min-width: 50%;
    }

    .wd-sm-50-f {
        width: 50px !important;
    }

    .wd-sm-50p-f {
        width: 50% !important;
    }

    .mx-wd-sm-50p-f {
        max-width: 50% !important;
    }

    .mn-wd-sm-50p-f {
        min-width: 50% !important;
    }

    .wd-sm-55 {
        width: 55px;
    }

    .wd-sm-55p {
        width: 55%;
    }

    .mx-wd-sm-55p {
        max-width: 55%;
    }

    .mn-wd-sm-55p {
        min-width: 55%;
    }

    .wd-sm-55-f {
        width: 55px !important;
    }

    .wd-sm-55p-f {
        width: 55% !important;
    }

    .mx-wd-sm-55p-f {
        max-width: 55% !important;
    }

    .mn-wd-sm-55p-f {
        min-width: 55% !important;
    }

    .wd-sm-60 {
        width: 60px;
    }

    .wd-sm-60p {
        width: 60%;
    }

    .mx-wd-sm-60p {
        max-width: 60%;
    }

    .mn-wd-sm-60p {
        min-width: 60%;
    }

    .wd-sm-60-f {
        width: 60px !important;
    }

    .wd-sm-60p-f {
        width: 60% !important;
    }

    .mx-wd-sm-60p-f {
        max-width: 60% !important;
    }

    .mn-wd-sm-60p-f {
        min-width: 60% !important;
    }

    .wd-sm-65 {
        width: 65px;
    }

    .wd-sm-65p {
        width: 65%;
    }

    .mx-wd-sm-65p {
        max-width: 65%;
    }

    .mn-wd-sm-65p {
        min-width: 65%;
    }

    .wd-sm-65-f {
        width: 65px !important;
    }

    .wd-sm-65p-f {
        width: 65% !important;
    }

    .mx-wd-sm-65p-f {
        max-width: 65% !important;
    }

    .mn-wd-sm-65p-f {
        min-width: 65% !important;
    }

    .wd-sm-70 {
        width: 70px;
    }

    .wd-sm-70p {
        width: 70%;
    }

    .mx-wd-sm-70p {
        max-width: 70%;
    }

    .mn-wd-sm-70p {
        min-width: 70%;
    }

    .wd-sm-70-f {
        width: 70px !important;
    }

    .wd-sm-70p-f {
        width: 70% !important;
    }

    .mx-wd-sm-70p-f {
        max-width: 70% !important;
    }

    .mn-wd-sm-70p-f {
        min-width: 70% !important;
    }

    .wd-sm-75 {
        width: 75px;
    }

    .wd-sm-75p {
        width: 75%;
    }

    .mx-wd-sm-75p {
        max-width: 75%;
    }

    .mn-wd-sm-75p {
        min-width: 75%;
    }

    .wd-sm-75-f {
        width: 75px !important;
    }

    .wd-sm-75p-f {
        width: 75% !important;
    }

    .mx-wd-sm-75p-f {
        max-width: 75% !important;
    }

    .mn-wd-sm-75p-f {
        min-width: 75% !important;
    }

    .wd-sm-80 {
        width: 80px;
    }

    .wd-sm-80p {
        width: 80%;
    }

    .mx-wd-sm-80p {
        max-width: 80%;
    }

    .mn-wd-sm-80p {
        min-width: 80%;
    }

    .wd-sm-80-f {
        width: 80px !important;
    }

    .wd-sm-80p-f {
        width: 80% !important;
    }

    .mx-wd-sm-80p-f {
        max-width: 80% !important;
    }

    .mn-wd-sm-80p-f {
        min-width: 80% !important;
    }

    .wd-sm-85 {
        width: 85px;
    }

    .wd-sm-85p {
        width: 85%;
    }

    .mx-wd-sm-85p {
        max-width: 85%;
    }

    .mn-wd-sm-85p {
        min-width: 85%;
    }

    .wd-sm-85-f {
        width: 85px !important;
    }

    .wd-sm-85p-f {
        width: 85% !important;
    }

    .mx-wd-sm-85p-f {
        max-width: 85% !important;
    }

    .mn-wd-sm-85p-f {
        min-width: 85% !important;
    }

    .wd-sm-90 {
        width: 90px;
    }

    .wd-sm-90p {
        width: 90%;
    }

    .mx-wd-sm-90p {
        max-width: 90%;
    }

    .mn-wd-sm-90p {
        min-width: 90%;
    }

    .wd-sm-90-f {
        width: 90px !important;
    }

    .wd-sm-90p-f {
        width: 90% !important;
    }

    .mx-wd-sm-90p-f {
        max-width: 90% !important;
    }

    .mn-wd-sm-90p-f {
        min-width: 90% !important;
    }

    .wd-sm-95 {
        width: 95px;
    }

    .wd-sm-95p {
        width: 95%;
    }

    .mx-wd-sm-95p {
        max-width: 95%;
    }

    .mn-wd-sm-95p {
        min-width: 95%;
    }

    .wd-sm-95-f {
        width: 95px !important;
    }

    .wd-sm-95p-f {
        width: 95% !important;
    }

    .mx-wd-sm-95p-f {
        max-width: 95% !important;
    }

    .mn-wd-sm-95p-f {
        min-width: 95% !important;
    }

    .wd-sm-100 {
        width: 100px;
    }

    .wd-sm-100p {
        width: 100%;
    }

    .mx-wd-sm-100p {
        max-width: 100%;
    }

    .mn-wd-sm-100p {
        min-width: 100%;
    }

    .wd-sm-100-f {
        width: 100px !important;
    }

    .wd-sm-100p-f {
        width: 100% !important;
    }

    .mx-wd-sm-100p-f {
        max-width: 100% !important;
    }

    .mn-wd-sm-100p-f {
        min-width: 100% !important;
    }

    .wd-sm-150 {
        width: 150px;
    }

    .wd-sm-150p {
        width: 150%;
    }

    .mx-wd-sm-150p {
        max-width: 150%;
    }

    .mn-wd-sm-150p {
        min-width: 150%;
    }

    .wd-sm-150-f {
        width: 150px !important;
    }

    .wd-sm-150p-f {
        width: 150% !important;
    }

    .mx-wd-sm-150p-f {
        max-width: 150% !important;
    }

    .mn-wd-sm-150p-f {
        min-width: 150% !important;
    }

    .wd-sm-200 {
        width: 200px;
    }

    .wd-sm-200p {
        width: 200%;
    }

    .mx-wd-sm-200p {
        max-width: 200%;
    }

    .mn-wd-sm-200p {
        min-width: 200%;
    }

    .wd-sm-200-f {
        width: 200px !important;
    }

    .wd-sm-200p-f {
        width: 200% !important;
    }

    .mx-wd-sm-200p-f {
        max-width: 200% !important;
    }

    .mn-wd-sm-200p-f {
        min-width: 200% !important;
    }

    .wd-sm-250 {
        width: 250px;
    }

    .wd-sm-250p {
        width: 250%;
    }

    .mx-wd-sm-250p {
        max-width: 250%;
    }

    .mn-wd-sm-250p {
        min-width: 250%;
    }

    .wd-sm-250-f {
        width: 250px !important;
    }

    .wd-sm-250p-f {
        width: 250% !important;
    }

    .mx-wd-sm-250p-f {
        max-width: 250% !important;
    }

    .mn-wd-sm-250p-f {
        min-width: 250% !important;
    }

    .wd-sm-300 {
        width: 300px;
    }

    .wd-sm-300p {
        width: 300%;
    }

    .mx-wd-sm-300p {
        max-width: 300%;
    }

    .mn-wd-sm-300p {
        min-width: 300%;
    }

    .wd-sm-300-f {
        width: 300px !important;
    }

    .wd-sm-300p-f {
        width: 300% !important;
    }

    .mx-wd-sm-300p-f {
        max-width: 300% !important;
    }

    .mn-wd-sm-300p-f {
        min-width: 300% !important;
    }

    .wd-sm-350 {
        width: 350px;
    }

    .wd-sm-350p {
        width: 350%;
    }

    .mx-wd-sm-350p {
        max-width: 350%;
    }

    .mn-wd-sm-350p {
        min-width: 350%;
    }

    .wd-sm-350-f {
        width: 350px !important;
    }

    .wd-sm-350p-f {
        width: 350% !important;
    }

    .mx-wd-sm-350p-f {
        max-width: 350% !important;
    }

    .mn-wd-sm-350p-f {
        min-width: 350% !important;
    }

    .wd-sm-400 {
        width: 400px;
    }

    .wd-sm-400p {
        width: 400%;
    }

    .mx-wd-sm-400p {
        max-width: 400%;
    }

    .mn-wd-sm-400p {
        min-width: 400%;
    }

    .wd-sm-400-f {
        width: 400px !important;
    }

    .wd-sm-400p-f {
        width: 400% !important;
    }

    .mx-wd-sm-400p-f {
        max-width: 400% !important;
    }

    .mn-wd-sm-400p-f {
        min-width: 400% !important;
    }

    .wd-sm-450 {
        width: 450px;
    }

    .wd-sm-450p {
        width: 450%;
    }

    .mx-wd-sm-450p {
        max-width: 450%;
    }

    .mn-wd-sm-450p {
        min-width: 450%;
    }

    .wd-sm-450-f {
        width: 450px !important;
    }

    .wd-sm-450p-f {
        width: 450% !important;
    }

    .mx-wd-sm-450p-f {
        max-width: 450% !important;
    }

    .mn-wd-sm-450p-f {
        min-width: 450% !important;
    }

    .wd-sm-500 {
        width: 500px;
    }

    .wd-sm-500p {
        width: 500%;
    }

    .mx-wd-sm-500p {
        max-width: 500%;
    }

    .mn-wd-sm-500p {
        min-width: 500%;
    }

    .wd-sm-500-f {
        width: 500px !important;
    }

    .wd-sm-500p-f {
        width: 500% !important;
    }

    .mx-wd-sm-500p-f {
        max-width: 500% !important;
    }

    .mn-wd-sm-500p-f {
        min-width: 500% !important;
    }

    .wd-sm-550 {
        width: 550px;
    }

    .wd-sm-550p {
        width: 550%;
    }

    .mx-wd-sm-550p {
        max-width: 550%;
    }

    .mn-wd-sm-550p {
        min-width: 550%;
    }

    .wd-sm-550-f {
        width: 550px !important;
    }

    .wd-sm-550p-f {
        width: 550% !important;
    }

    .mx-wd-sm-550p-f {
        max-width: 550% !important;
    }

    .mn-wd-sm-550p-f {
        min-width: 550% !important;
    }

    .wd-sm-600 {
        width: 600px;
    }

    .wd-sm-600p {
        width: 600%;
    }

    .mx-wd-sm-600p {
        max-width: 600%;
    }

    .mn-wd-sm-600p {
        min-width: 600%;
    }

    .wd-sm-600-f {
        width: 600px !important;
    }

    .wd-sm-600p-f {
        width: 600% !important;
    }

    .mx-wd-sm-600p-f {
        max-width: 600% !important;
    }

    .mn-wd-sm-600p-f {
        min-width: 600% !important;
    }

    .wd-sm-650 {
        width: 650px;
    }

    .wd-sm-650p {
        width: 650%;
    }

    .mx-wd-sm-650p {
        max-width: 650%;
    }

    .mn-wd-sm-650p {
        min-width: 650%;
    }

    .wd-sm-650-f {
        width: 650px !important;
    }

    .wd-sm-650p-f {
        width: 650% !important;
    }

    .mx-wd-sm-650p-f {
        max-width: 650% !important;
    }

    .mn-wd-sm-650p-f {
        min-width: 650% !important;
    }

    .wd-sm-700 {
        width: 700px;
    }

    .wd-sm-700p {
        width: 700%;
    }

    .mx-wd-sm-700p {
        max-width: 700%;
    }

    .mn-wd-sm-700p {
        min-width: 700%;
    }

    .wd-sm-700-f {
        width: 700px !important;
    }

    .wd-sm-700p-f {
        width: 700% !important;
    }

    .mx-wd-sm-700p-f {
        max-width: 700% !important;
    }

    .mn-wd-sm-700p-f {
        min-width: 700% !important;
    }

    .wd-sm-750 {
        width: 750px;
    }

    .wd-sm-750p {
        width: 750%;
    }

    .mx-wd-sm-750p {
        max-width: 750%;
    }

    .mn-wd-sm-750p {
        min-width: 750%;
    }

    .wd-sm-750-f {
        width: 750px !important;
    }

    .wd-sm-750p-f {
        width: 750% !important;
    }

    .mx-wd-sm-750p-f {
        max-width: 750% !important;
    }

    .mn-wd-sm-750p-f {
        min-width: 750% !important;
    }

    .wd-sm-800 {
        width: 800px;
    }

    .wd-sm-800p {
        width: 800%;
    }

    .mx-wd-sm-800p {
        max-width: 800%;
    }

    .mn-wd-sm-800p {
        min-width: 800%;
    }

    .wd-sm-800-f {
        width: 800px !important;
    }

    .wd-sm-800p-f {
        width: 800% !important;
    }

    .mx-wd-sm-800p-f {
        max-width: 800% !important;
    }

    .mn-wd-sm-800p-f {
        min-width: 800% !important;
    }

    .wd-sm-850 {
        width: 850px;
    }

    .wd-sm-850p {
        width: 850%;
    }

    .mx-wd-sm-850p {
        max-width: 850%;
    }

    .mn-wd-sm-850p {
        min-width: 850%;
    }

    .wd-sm-850-f {
        width: 850px !important;
    }

    .wd-sm-850p-f {
        width: 850% !important;
    }

    .mx-wd-sm-850p-f {
        max-width: 850% !important;
    }

    .mn-wd-sm-850p-f {
        min-width: 850% !important;
    }

    .wd-sm-900 {
        width: 900px;
    }

    .wd-sm-900p {
        width: 900%;
    }

    .mx-wd-sm-900p {
        max-width: 900%;
    }

    .mn-wd-sm-900p {
        min-width: 900%;
    }

    .wd-sm-900-f {
        width: 900px !important;
    }

    .wd-sm-900p-f {
        width: 900% !important;
    }

    .mx-wd-sm-900p-f {
        max-width: 900% !important;
    }

    .mn-wd-sm-900p-f {
        min-width: 900% !important;
    }

    .wd-sm-950 {
        width: 950px;
    }

    .wd-sm-950p {
        width: 950%;
    }

    .mx-wd-sm-950p {
        max-width: 950%;
    }

    .mn-wd-sm-950p {
        min-width: 950%;
    }

    .wd-sm-950-f {
        width: 950px !important;
    }

    .wd-sm-950p-f {
        width: 950% !important;
    }

    .mx-wd-sm-950p-f {
        max-width: 950% !important;
    }

    .mn-wd-sm-950p-f {
        min-width: 950% !important;
    }

    .wd-sm-1000 {
        width: 1000px;
    }

    .wd-sm-1000p {
        width: 1000%;
    }

    .mx-wd-sm-1000p {
        max-width: 1000%;
    }

    .mn-wd-sm-1000p {
        min-width: 1000%;
    }

    .wd-sm-1000-f {
        width: 1000px !important;
    }

    .wd-sm-1000p-f {
        width: 1000% !important;
    }

    .mx-wd-sm-1000p-f {
        max-width: 1000% !important;
    }

    .mn-wd-sm-1000p-f {
        min-width: 1000% !important;
    }

    .wd-sm-auto {
        width: auto;
    }

    .wd-sm-auto-f {
        width: auto !important;
    }
}

@media (min-width: 768px) {
    .wd-md-5 {
        width: 5px;
    }

    .wd-md-5p {
        width: 5%;
    }

    .mx-wd-md-5p {
        max-width: 5%;
    }

    .mn-wd-md-5p {
        min-width: 5%;
    }

    .wd-md-5-f {
        width: 5px !important;
    }

    .wd-md-5p-f {
        width: 5% !important;
    }

    .mx-wd-md-5p-f {
        max-width: 5% !important;
    }

    .mn-wd-md-5p-f {
        min-width: 5% !important;
    }

    .wd-md-10 {
        width: 10px;
    }

    .wd-md-10p {
        width: 10%;
    }

    .mx-wd-md-10p {
        max-width: 10%;
    }

    .mn-wd-md-10p {
        min-width: 10%;
    }

    .wd-md-10-f {
        width: 10px !important;
    }

    .wd-md-10p-f {
        width: 10% !important;
    }

    .mx-wd-md-10p-f {
        max-width: 10% !important;
    }

    .mn-wd-md-10p-f {
        min-width: 10% !important;
    }

    .wd-md-15 {
        width: 15px;
    }

    .wd-md-15p {
        width: 15%;
    }

    .mx-wd-md-15p {
        max-width: 15%;
    }

    .mn-wd-md-15p {
        min-width: 15%;
    }

    .wd-md-15-f {
        width: 15px !important;
    }

    .wd-md-15p-f {
        width: 15% !important;
    }

    .mx-wd-md-15p-f {
        max-width: 15% !important;
    }

    .mn-wd-md-15p-f {
        min-width: 15% !important;
    }

    .wd-md-20 {
        width: 20px;
    }

    .wd-md-20p {
        width: 20%;
    }

    .mx-wd-md-20p {
        max-width: 20%;
    }

    .mn-wd-md-20p {
        min-width: 20%;
    }

    .wd-md-20-f {
        width: 20px !important;
    }

    .wd-md-20p-f {
        width: 20% !important;
    }

    .mx-wd-md-20p-f {
        max-width: 20% !important;
    }

    .mn-wd-md-20p-f {
        min-width: 20% !important;
    }

    .wd-md-25 {
        width: 25px;
    }

    .wd-md-25p {
        width: 25%;
    }

    .mx-wd-md-25p {
        max-width: 25%;
    }

    .mn-wd-md-25p {
        min-width: 25%;
    }

    .wd-md-25-f {
        width: 25px !important;
    }

    .wd-md-25p-f {
        width: 25% !important;
    }

    .mx-wd-md-25p-f {
        max-width: 25% !important;
    }

    .mn-wd-md-25p-f {
        min-width: 25% !important;
    }

    .wd-md-30 {
        width: 30px;
    }

    .wd-md-30p {
        width: 30%;
    }

    .mx-wd-md-30p {
        max-width: 30%;
    }

    .mn-wd-md-30p {
        min-width: 30%;
    }

    .wd-md-30-f {
        width: 30px !important;
    }

    .wd-md-30p-f {
        width: 30% !important;
    }

    .mx-wd-md-30p-f {
        max-width: 30% !important;
    }

    .mn-wd-md-30p-f {
        min-width: 30% !important;
    }

    .wd-md-35 {
        width: 35px;
    }

    .wd-md-35p {
        width: 35%;
    }

    .mx-wd-md-35p {
        max-width: 35%;
    }

    .mn-wd-md-35p {
        min-width: 35%;
    }

    .wd-md-35-f {
        width: 35px !important;
    }

    .wd-md-35p-f {
        width: 35% !important;
    }

    .mx-wd-md-35p-f {
        max-width: 35% !important;
    }

    .mn-wd-md-35p-f {
        min-width: 35% !important;
    }

    .wd-md-40 {
        width: 40px;
    }

    .wd-md-40p {
        width: 40%;
    }

    .mx-wd-md-40p {
        max-width: 40%;
    }

    .mn-wd-md-40p {
        min-width: 40%;
    }

    .wd-md-40-f {
        width: 40px !important;
    }

    .wd-md-40p-f {
        width: 40% !important;
    }

    .mx-wd-md-40p-f {
        max-width: 40% !important;
    }

    .mn-wd-md-40p-f {
        min-width: 40% !important;
    }

    .wd-md-45 {
        width: 45px;
    }

    .wd-md-45p {
        width: 45%;
    }

    .mx-wd-md-45p {
        max-width: 45%;
    }

    .mn-wd-md-45p {
        min-width: 45%;
    }

    .wd-md-45-f {
        width: 45px !important;
    }

    .wd-md-45p-f {
        width: 45% !important;
    }

    .mx-wd-md-45p-f {
        max-width: 45% !important;
    }

    .mn-wd-md-45p-f {
        min-width: 45% !important;
    }

    .wd-md-50 {
        width: 50px;
    }

    .wd-md-50p {
        width: 50%;
    }

    .mx-wd-md-50p {
        max-width: 50%;
    }

    .mn-wd-md-50p {
        min-width: 50%;
    }

    .wd-md-50-f {
        width: 50px !important;
    }

    .wd-md-50p-f {
        width: 50% !important;
    }

    .mx-wd-md-50p-f {
        max-width: 50% !important;
    }

    .mn-wd-md-50p-f {
        min-width: 50% !important;
    }

    .wd-md-55 {
        width: 55px;
    }

    .wd-md-55p {
        width: 55%;
    }

    .mx-wd-md-55p {
        max-width: 55%;
    }

    .mn-wd-md-55p {
        min-width: 55%;
    }

    .wd-md-55-f {
        width: 55px !important;
    }

    .wd-md-55p-f {
        width: 55% !important;
    }

    .mx-wd-md-55p-f {
        max-width: 55% !important;
    }

    .mn-wd-md-55p-f {
        min-width: 55% !important;
    }

    .wd-md-60 {
        width: 60px;
    }

    .wd-md-60p {
        width: 60%;
    }

    .mx-wd-md-60p {
        max-width: 60%;
    }

    .mn-wd-md-60p {
        min-width: 60%;
    }

    .wd-md-60-f {
        width: 60px !important;
    }

    .wd-md-60p-f {
        width: 60% !important;
    }

    .mx-wd-md-60p-f {
        max-width: 60% !important;
    }

    .mn-wd-md-60p-f {
        min-width: 60% !important;
    }

    .wd-md-65 {
        width: 65px;
    }

    .wd-md-65p {
        width: 65%;
    }

    .mx-wd-md-65p {
        max-width: 65%;
    }

    .mn-wd-md-65p {
        min-width: 65%;
    }

    .wd-md-65-f {
        width: 65px !important;
    }

    .wd-md-65p-f {
        width: 65% !important;
    }

    .mx-wd-md-65p-f {
        max-width: 65% !important;
    }

    .mn-wd-md-65p-f {
        min-width: 65% !important;
    }

    .wd-md-70 {
        width: 70px;
    }

    .wd-md-70p {
        width: 70%;
    }

    .mx-wd-md-70p {
        max-width: 70%;
    }

    .mn-wd-md-70p {
        min-width: 70%;
    }

    .wd-md-70-f {
        width: 70px !important;
    }

    .wd-md-70p-f {
        width: 70% !important;
    }

    .mx-wd-md-70p-f {
        max-width: 70% !important;
    }

    .mn-wd-md-70p-f {
        min-width: 70% !important;
    }

    .wd-md-75 {
        width: 75px;
    }

    .wd-md-75p {
        width: 75%;
    }

    .mx-wd-md-75p {
        max-width: 75%;
    }

    .mn-wd-md-75p {
        min-width: 75%;
    }

    .wd-md-75-f {
        width: 75px !important;
    }

    .wd-md-75p-f {
        width: 75% !important;
    }

    .mx-wd-md-75p-f {
        max-width: 75% !important;
    }

    .mn-wd-md-75p-f {
        min-width: 75% !important;
    }

    .wd-md-80 {
        width: 80px;
    }

    .wd-md-80p {
        width: 80%;
    }

    .mx-wd-md-80p {
        max-width: 80%;
    }

    .mn-wd-md-80p {
        min-width: 80%;
    }

    .wd-md-80-f {
        width: 80px !important;
    }

    .wd-md-80p-f {
        width: 80% !important;
    }

    .mx-wd-md-80p-f {
        max-width: 80% !important;
    }

    .mn-wd-md-80p-f {
        min-width: 80% !important;
    }

    .wd-md-85 {
        width: 85px;
    }

    .wd-md-85p {
        width: 85%;
    }

    .mx-wd-md-85p {
        max-width: 85%;
    }

    .mn-wd-md-85p {
        min-width: 85%;
    }

    .wd-md-85-f {
        width: 85px !important;
    }

    .wd-md-85p-f {
        width: 85% !important;
    }

    .mx-wd-md-85p-f {
        max-width: 85% !important;
    }

    .mn-wd-md-85p-f {
        min-width: 85% !important;
    }

    .wd-md-90 {
        width: 90px;
    }

    .wd-md-90p {
        width: 90%;
    }

    .mx-wd-md-90p {
        max-width: 90%;
    }

    .mn-wd-md-90p {
        min-width: 90%;
    }

    .wd-md-90-f {
        width: 90px !important;
    }

    .wd-md-90p-f {
        width: 90% !important;
    }

    .mx-wd-md-90p-f {
        max-width: 90% !important;
    }

    .mn-wd-md-90p-f {
        min-width: 90% !important;
    }

    .wd-md-95 {
        width: 95px;
    }

    .wd-md-95p {
        width: 95%;
    }

    .mx-wd-md-95p {
        max-width: 95%;
    }

    .mn-wd-md-95p {
        min-width: 95%;
    }

    .wd-md-95-f {
        width: 95px !important;
    }

    .wd-md-95p-f {
        width: 95% !important;
    }

    .mx-wd-md-95p-f {
        max-width: 95% !important;
    }

    .mn-wd-md-95p-f {
        min-width: 95% !important;
    }

    .wd-md-100 {
        width: 100px;
    }

    .wd-md-100p {
        width: 100%;
    }

    .mx-wd-md-100p {
        max-width: 100%;
    }

    .mn-wd-md-100p {
        min-width: 100%;
    }

    .wd-md-100-f {
        width: 100px !important;
    }

    .wd-md-100p-f {
        width: 100% !important;
    }

    .mx-wd-md-100p-f {
        max-width: 100% !important;
    }

    .mn-wd-md-100p-f {
        min-width: 100% !important;
    }

    .wd-md-150 {
        width: 150px;
    }

    .wd-md-150p {
        width: 150%;
    }

    .mx-wd-md-150p {
        max-width: 150%;
    }

    .mn-wd-md-150p {
        min-width: 150%;
    }

    .wd-md-150-f {
        width: 150px !important;
    }

    .wd-md-150p-f {
        width: 150% !important;
    }

    .mx-wd-md-150p-f {
        max-width: 150% !important;
    }

    .mn-wd-md-150p-f {
        min-width: 150% !important;
    }

    .wd-md-200 {
        width: 200px;
    }

    .wd-md-200p {
        width: 200%;
    }

    .mx-wd-md-200p {
        max-width: 200%;
    }

    .mn-wd-md-200p {
        min-width: 200%;
    }

    .wd-md-200-f {
        width: 200px !important;
    }

    .wd-md-200p-f {
        width: 200% !important;
    }

    .mx-wd-md-200p-f {
        max-width: 200% !important;
    }

    .mn-wd-md-200p-f {
        min-width: 200% !important;
    }

    .wd-md-250 {
        width: 250px;
    }

    .wd-md-250p {
        width: 250%;
    }

    .mx-wd-md-250p {
        max-width: 250%;
    }

    .mn-wd-md-250p {
        min-width: 250%;
    }

    .wd-md-250-f {
        width: 250px !important;
    }

    .wd-md-250p-f {
        width: 250% !important;
    }

    .mx-wd-md-250p-f {
        max-width: 250% !important;
    }

    .mn-wd-md-250p-f {
        min-width: 250% !important;
    }

    .wd-md-300 {
        width: 300px;
    }

    .wd-md-300p {
        width: 300%;
    }

    .mx-wd-md-300p {
        max-width: 300%;
    }

    .mn-wd-md-300p {
        min-width: 300%;
    }

    .wd-md-300-f {
        width: 300px !important;
    }

    .wd-md-300p-f {
        width: 300% !important;
    }

    .mx-wd-md-300p-f {
        max-width: 300% !important;
    }

    .mn-wd-md-300p-f {
        min-width: 300% !important;
    }

    .wd-md-350 {
        width: 350px;
    }

    .wd-md-350p {
        width: 350%;
    }

    .mx-wd-md-350p {
        max-width: 350%;
    }

    .mn-wd-md-350p {
        min-width: 350%;
    }

    .wd-md-350-f {
        width: 350px !important;
    }

    .wd-md-350p-f {
        width: 350% !important;
    }

    .mx-wd-md-350p-f {
        max-width: 350% !important;
    }

    .mn-wd-md-350p-f {
        min-width: 350% !important;
    }

    .wd-md-400 {
        width: 400px;
    }

    .wd-md-400p {
        width: 400%;
    }

    .mx-wd-md-400p {
        max-width: 400%;
    }

    .mn-wd-md-400p {
        min-width: 400%;
    }

    .wd-md-400-f {
        width: 400px !important;
    }

    .wd-md-400p-f {
        width: 400% !important;
    }

    .mx-wd-md-400p-f {
        max-width: 400% !important;
    }

    .mn-wd-md-400p-f {
        min-width: 400% !important;
    }

    .wd-md-450 {
        width: 450px;
    }

    .wd-md-450p {
        width: 450%;
    }

    .mx-wd-md-450p {
        max-width: 450%;
    }

    .mn-wd-md-450p {
        min-width: 450%;
    }

    .wd-md-450-f {
        width: 450px !important;
    }

    .wd-md-450p-f {
        width: 450% !important;
    }

    .mx-wd-md-450p-f {
        max-width: 450% !important;
    }

    .mn-wd-md-450p-f {
        min-width: 450% !important;
    }

    .wd-md-500 {
        width: 500px;
    }

    .wd-md-500p {
        width: 500%;
    }

    .mx-wd-md-500p {
        max-width: 500%;
    }

    .mn-wd-md-500p {
        min-width: 500%;
    }

    .wd-md-500-f {
        width: 500px !important;
    }

    .wd-md-500p-f {
        width: 500% !important;
    }

    .mx-wd-md-500p-f {
        max-width: 500% !important;
    }

    .mn-wd-md-500p-f {
        min-width: 500% !important;
    }

    .wd-md-550 {
        width: 550px;
    }

    .wd-md-550p {
        width: 550%;
    }

    .mx-wd-md-550p {
        max-width: 550%;
    }

    .mn-wd-md-550p {
        min-width: 550%;
    }

    .wd-md-550-f {
        width: 550px !important;
    }

    .wd-md-550p-f {
        width: 550% !important;
    }

    .mx-wd-md-550p-f {
        max-width: 550% !important;
    }

    .mn-wd-md-550p-f {
        min-width: 550% !important;
    }

    .wd-md-600 {
        width: 600px;
    }

    .wd-md-600p {
        width: 600%;
    }

    .mx-wd-md-600p {
        max-width: 600%;
    }

    .mn-wd-md-600p {
        min-width: 600%;
    }

    .wd-md-600-f {
        width: 600px !important;
    }

    .wd-md-600p-f {
        width: 600% !important;
    }

    .mx-wd-md-600p-f {
        max-width: 600% !important;
    }

    .mn-wd-md-600p-f {
        min-width: 600% !important;
    }

    .wd-md-650 {
        width: 650px;
    }

    .wd-md-650p {
        width: 650%;
    }

    .mx-wd-md-650p {
        max-width: 650%;
    }

    .mn-wd-md-650p {
        min-width: 650%;
    }

    .wd-md-650-f {
        width: 650px !important;
    }

    .wd-md-650p-f {
        width: 650% !important;
    }

    .mx-wd-md-650p-f {
        max-width: 650% !important;
    }

    .mn-wd-md-650p-f {
        min-width: 650% !important;
    }

    .wd-md-700 {
        width: 700px;
    }

    .wd-md-700p {
        width: 700%;
    }

    .mx-wd-md-700p {
        max-width: 700%;
    }

    .mn-wd-md-700p {
        min-width: 700%;
    }

    .wd-md-700-f {
        width: 700px !important;
    }

    .wd-md-700p-f {
        width: 700% !important;
    }

    .mx-wd-md-700p-f {
        max-width: 700% !important;
    }

    .mn-wd-md-700p-f {
        min-width: 700% !important;
    }

    .wd-md-750 {
        width: 750px;
    }

    .wd-md-750p {
        width: 750%;
    }

    .mx-wd-md-750p {
        max-width: 750%;
    }

    .mn-wd-md-750p {
        min-width: 750%;
    }

    .wd-md-750-f {
        width: 750px !important;
    }

    .wd-md-750p-f {
        width: 750% !important;
    }

    .mx-wd-md-750p-f {
        max-width: 750% !important;
    }

    .mn-wd-md-750p-f {
        min-width: 750% !important;
    }

    .wd-md-800 {
        width: 800px;
    }

    .wd-md-800p {
        width: 800%;
    }

    .mx-wd-md-800p {
        max-width: 800%;
    }

    .mn-wd-md-800p {
        min-width: 800%;
    }

    .wd-md-800-f {
        width: 800px !important;
    }

    .wd-md-800p-f {
        width: 800% !important;
    }

    .mx-wd-md-800p-f {
        max-width: 800% !important;
    }

    .mn-wd-md-800p-f {
        min-width: 800% !important;
    }

    .wd-md-850 {
        width: 850px;
    }

    .wd-md-850p {
        width: 850%;
    }

    .mx-wd-md-850p {
        max-width: 850%;
    }

    .mn-wd-md-850p {
        min-width: 850%;
    }

    .wd-md-850-f {
        width: 850px !important;
    }

    .wd-md-850p-f {
        width: 850% !important;
    }

    .mx-wd-md-850p-f {
        max-width: 850% !important;
    }

    .mn-wd-md-850p-f {
        min-width: 850% !important;
    }

    .wd-md-900 {
        width: 900px;
    }

    .wd-md-900p {
        width: 900%;
    }

    .mx-wd-md-900p {
        max-width: 900%;
    }

    .mn-wd-md-900p {
        min-width: 900%;
    }

    .wd-md-900-f {
        width: 900px !important;
    }

    .wd-md-900p-f {
        width: 900% !important;
    }

    .mx-wd-md-900p-f {
        max-width: 900% !important;
    }

    .mn-wd-md-900p-f {
        min-width: 900% !important;
    }

    .wd-md-950 {
        width: 950px;
    }

    .wd-md-950p {
        width: 950%;
    }

    .mx-wd-md-950p {
        max-width: 950%;
    }

    .mn-wd-md-950p {
        min-width: 950%;
    }

    .wd-md-950-f {
        width: 950px !important;
    }

    .wd-md-950p-f {
        width: 950% !important;
    }

    .mx-wd-md-950p-f {
        max-width: 950% !important;
    }

    .mn-wd-md-950p-f {
        min-width: 950% !important;
    }

    .wd-md-1000 {
        width: 1000px;
    }

    .wd-md-1000p {
        width: 1000%;
    }

    .mx-wd-md-1000p {
        max-width: 1000%;
    }

    .mn-wd-md-1000p {
        min-width: 1000%;
    }

    .wd-md-1000-f {
        width: 1000px !important;
    }

    .wd-md-1000p-f {
        width: 1000% !important;
    }

    .mx-wd-md-1000p-f {
        max-width: 1000% !important;
    }

    .mn-wd-md-1000p-f {
        min-width: 1000% !important;
    }

    .wd-md-auto {
        width: auto;
    }

    .wd-md-auto-f {
        width: auto !important;
    }

    .wd-md-120 {
        width: 120px;
    }
}

@media (min-width: 992px) {
    .wd-lg-5 {
        width: 5px;
    }

    .wd-lg-5p {
        width: 5%;
    }

    .mx-wd-lg-5p {
        max-width: 5%;
    }

    .mn-wd-lg-5p {
        min-width: 5%;
    }

    .wd-lg-5-f {
        width: 5px !important;
    }

    .wd-lg-5p-f {
        width: 5% !important;
    }

    .mx-wd-lg-5p-f {
        max-width: 5% !important;
    }

    .mn-wd-lg-5p-f {
        min-width: 5% !important;
    }

    .wd-lg-10 {
        width: 10px;
    }

    .wd-lg-10p {
        width: 10%;
    }

    .mx-wd-lg-10p {
        max-width: 10%;
    }

    .mn-wd-lg-10p {
        min-width: 10%;
    }

    .wd-lg-10-f {
        width: 10px !important;
    }

    .wd-lg-10p-f {
        width: 10% !important;
    }

    .mx-wd-lg-10p-f {
        max-width: 10% !important;
    }

    .mn-wd-lg-10p-f {
        min-width: 10% !important;
    }

    .wd-lg-15 {
        width: 15px;
    }

    .wd-lg-15p {
        width: 15%;
    }

    .mx-wd-lg-15p {
        max-width: 15%;
    }

    .mn-wd-lg-15p {
        min-width: 15%;
    }

    .wd-lg-15-f {
        width: 15px !important;
    }

    .wd-lg-15p-f {
        width: 15% !important;
    }

    .mx-wd-lg-15p-f {
        max-width: 15% !important;
    }

    .mn-wd-lg-15p-f {
        min-width: 15% !important;
    }

    .wd-lg-20 {
        width: 20px;
    }

    .wd-lg-20p {
        width: 20%;
    }

    .mx-wd-lg-20p {
        max-width: 20%;
    }

    .mn-wd-lg-20p {
        min-width: 20%;
    }

    .wd-lg-20-f {
        width: 20px !important;
    }

    .wd-lg-20p-f {
        width: 20% !important;
    }

    .mx-wd-lg-20p-f {
        max-width: 20% !important;
    }

    .mn-wd-lg-20p-f {
        min-width: 20% !important;
    }

    .wd-lg-25 {
        width: 25px;
    }

    .wd-lg-25p {
        width: 25%;
    }

    .mx-wd-lg-25p {
        max-width: 25%;
    }

    .mn-wd-lg-25p {
        min-width: 25%;
    }

    .wd-lg-25-f {
        width: 25px !important;
    }

    .wd-lg-25p-f {
        width: 25% !important;
    }

    .mx-wd-lg-25p-f {
        max-width: 25% !important;
    }

    .mn-wd-lg-25p-f {
        min-width: 25% !important;
    }

    .wd-lg-30 {
        width: 30px;
    }

    .wd-lg-30p {
        width: 30%;
    }

    .mx-wd-lg-30p {
        max-width: 30%;
    }

    .mn-wd-lg-30p {
        min-width: 30%;
    }

    .wd-lg-30-f {
        width: 30px !important;
    }

    .wd-lg-30p-f {
        width: 30% !important;
    }

    .mx-wd-lg-30p-f {
        max-width: 30% !important;
    }

    .mn-wd-lg-30p-f {
        min-width: 30% !important;
    }

    .wd-lg-35 {
        width: 35px;
    }

    .wd-lg-35p {
        width: 35%;
    }

    .mx-wd-lg-35p {
        max-width: 35%;
    }

    .mn-wd-lg-35p {
        min-width: 35%;
    }

    .wd-lg-35-f {
        width: 35px !important;
    }

    .wd-lg-35p-f {
        width: 35% !important;
    }

    .mx-wd-lg-35p-f {
        max-width: 35% !important;
    }

    .mn-wd-lg-35p-f {
        min-width: 35% !important;
    }

    .wd-lg-40 {
        width: 40px;
    }

    .wd-lg-40p {
        width: 40%;
    }

    .mx-wd-lg-40p {
        max-width: 40%;
    }

    .mn-wd-lg-40p {
        min-width: 40%;
    }

    .wd-lg-40-f {
        width: 40px !important;
    }

    .wd-lg-40p-f {
        width: 40% !important;
    }

    .mx-wd-lg-40p-f {
        max-width: 40% !important;
    }

    .mn-wd-lg-40p-f {
        min-width: 40% !important;
    }

    .wd-lg-45 {
        width: 45px;
    }

    .wd-lg-45p {
        width: 45%;
    }

    .mx-wd-lg-45p {
        max-width: 45%;
    }

    .mn-wd-lg-45p {
        min-width: 45%;
    }

    .wd-lg-45-f {
        width: 45px !important;
    }

    .wd-lg-45p-f {
        width: 45% !important;
    }

    .mx-wd-lg-45p-f {
        max-width: 45% !important;
    }

    .mn-wd-lg-45p-f {
        min-width: 45% !important;
    }

    .wd-lg-50 {
        width: 50px;
    }

    .wd-lg-50p {
        width: 50%;
    }

    .mx-wd-lg-50p {
        max-width: 50%;
    }

    .mn-wd-lg-50p {
        min-width: 50%;
    }

    .wd-lg-50-f {
        width: 50px !important;
    }

    .wd-lg-50p-f {
        width: 50% !important;
    }

    .mx-wd-lg-50p-f {
        max-width: 50% !important;
    }

    .mn-wd-lg-50p-f {
        min-width: 50% !important;
    }

    .wd-lg-55 {
        width: 55px;
    }

    .wd-lg-55p {
        width: 55%;
    }

    .mx-wd-lg-55p {
        max-width: 55%;
    }

    .mn-wd-lg-55p {
        min-width: 55%;
    }

    .wd-lg-55-f {
        width: 55px !important;
    }

    .wd-lg-55p-f {
        width: 55% !important;
    }

    .mx-wd-lg-55p-f {
        max-width: 55% !important;
    }

    .mn-wd-lg-55p-f {
        min-width: 55% !important;
    }

    .wd-lg-60 {
        width: 60px;
    }

    .wd-lg-60p {
        width: 60%;
    }

    .mx-wd-lg-60p {
        max-width: 60%;
    }

    .mn-wd-lg-60p {
        min-width: 60%;
    }

    .wd-lg-60-f {
        width: 60px !important;
    }

    .wd-lg-60p-f {
        width: 60% !important;
    }

    .mx-wd-lg-60p-f {
        max-width: 60% !important;
    }

    .mn-wd-lg-60p-f {
        min-width: 60% !important;
    }

    .wd-lg-65 {
        width: 65px;
    }

    .wd-lg-65p {
        width: 65%;
    }

    .mx-wd-lg-65p {
        max-width: 65%;
    }

    .mn-wd-lg-65p {
        min-width: 65%;
    }

    .wd-lg-65-f {
        width: 65px !important;
    }

    .wd-lg-65p-f {
        width: 65% !important;
    }

    .mx-wd-lg-65p-f {
        max-width: 65% !important;
    }

    .mn-wd-lg-65p-f {
        min-width: 65% !important;
    }

    .wd-lg-70 {
        width: 70px;
    }

    .wd-lg-70p {
        width: 70%;
    }

    .mx-wd-lg-70p {
        max-width: 70%;
    }

    .mn-wd-lg-70p {
        min-width: 70%;
    }

    .wd-lg-70-f {
        width: 70px !important;
    }

    .wd-lg-70p-f {
        width: 70% !important;
    }

    .mx-wd-lg-70p-f {
        max-width: 70% !important;
    }

    .mn-wd-lg-70p-f {
        min-width: 70% !important;
    }

    .wd-lg-75 {
        width: 75px;
    }

    .wd-lg-75p {
        width: 75%;
    }

    .mx-wd-lg-75p {
        max-width: 75%;
    }

    .mn-wd-lg-75p {
        min-width: 75%;
    }

    .wd-lg-75-f {
        width: 75px !important;
    }

    .wd-lg-75p-f {
        width: 75% !important;
    }

    .mx-wd-lg-75p-f {
        max-width: 75% !important;
    }

    .mn-wd-lg-75p-f {
        min-width: 75% !important;
    }

    .wd-lg-80 {
        width: 80px;
    }

    .wd-lg-80p {
        width: 80%;
    }

    .mx-wd-lg-80p {
        max-width: 80%;
    }

    .mn-wd-lg-80p {
        min-width: 80%;
    }

    .wd-lg-80-f {
        width: 80px !important;
    }

    .wd-lg-80p-f {
        width: 80% !important;
    }

    .mx-wd-lg-80p-f {
        max-width: 80% !important;
    }

    .mn-wd-lg-80p-f {
        min-width: 80% !important;
    }

    .wd-lg-85 {
        width: 85px;
    }

    .wd-lg-85p {
        width: 85%;
    }

    .mx-wd-lg-85p {
        max-width: 85%;
    }

    .mn-wd-lg-85p {
        min-width: 85%;
    }

    .wd-lg-85-f {
        width: 85px !important;
    }

    .wd-lg-85p-f {
        width: 85% !important;
    }

    .mx-wd-lg-85p-f {
        max-width: 85% !important;
    }

    .mn-wd-lg-85p-f {
        min-width: 85% !important;
    }

    .wd-lg-90 {
        width: 90px;
    }

    .wd-lg-90p {
        width: 90%;
    }

    .mx-wd-lg-90p {
        max-width: 90%;
    }

    .mn-wd-lg-90p {
        min-width: 90%;
    }

    .wd-lg-90-f {
        width: 90px !important;
    }

    .wd-lg-90p-f {
        width: 90% !important;
    }

    .mx-wd-lg-90p-f {
        max-width: 90% !important;
    }

    .mn-wd-lg-90p-f {
        min-width: 90% !important;
    }

    .wd-lg-95 {
        width: 95px;
    }

    .wd-lg-95p {
        width: 95%;
    }

    .mx-wd-lg-95p {
        max-width: 95%;
    }

    .mn-wd-lg-95p {
        min-width: 95%;
    }

    .wd-lg-95-f {
        width: 95px !important;
    }

    .wd-lg-95p-f {
        width: 95% !important;
    }

    .mx-wd-lg-95p-f {
        max-width: 95% !important;
    }

    .mn-wd-lg-95p-f {
        min-width: 95% !important;
    }

    .wd-lg-100 {
        width: 100px;
    }

    .wd-lg-100p {
        width: 100%;
    }

    .mx-wd-lg-100p {
        max-width: 100%;
    }

    .mn-wd-lg-100p {
        min-width: 100%;
    }

    .wd-lg-100-f {
        width: 100px !important;
    }

    .wd-lg-100p-f {
        width: 100% !important;
    }

    .mx-wd-lg-100p-f {
        max-width: 100% !important;
    }

    .mn-wd-lg-100p-f {
        min-width: 100% !important;
    }

    .wd-lg-150 {
        width: 150px;
    }

    .wd-lg-150p {
        width: 150%;
    }

    .mx-wd-lg-150p {
        max-width: 150%;
    }

    .mn-wd-lg-150p {
        min-width: 150%;
    }

    .wd-lg-150-f {
        width: 150px !important;
    }

    .wd-lg-150p-f {
        width: 150% !important;
    }

    .mx-wd-lg-150p-f {
        max-width: 150% !important;
    }

    .mn-wd-lg-150p-f {
        min-width: 150% !important;
    }

    .wd-lg-200 {
        width: 200px;
    }

    .wd-lg-200p {
        width: 200%;
    }

    .mx-wd-lg-200p {
        max-width: 200%;
    }

    .mn-wd-lg-200p {
        min-width: 200%;
    }

    .wd-lg-200-f {
        width: 200px !important;
    }

    .wd-lg-200p-f {
        width: 200% !important;
    }

    .mx-wd-lg-200p-f {
        max-width: 200% !important;
    }

    .mn-wd-lg-200p-f {
        min-width: 200% !important;
    }

    .wd-lg-250 {
        width: 250px;
    }

    .wd-lg-250p {
        width: 250%;
    }

    .mx-wd-lg-250p {
        max-width: 250%;
    }

    .mn-wd-lg-250p {
        min-width: 250%;
    }

    .wd-lg-250-f {
        width: 250px !important;
    }

    .wd-lg-250p-f {
        width: 250% !important;
    }

    .mx-wd-lg-250p-f {
        max-width: 250% !important;
    }

    .mn-wd-lg-250p-f {
        min-width: 250% !important;
    }

    .wd-lg-300 {
        width: 300px;
    }

    .wd-lg-300p {
        width: 300%;
    }

    .mx-wd-lg-300p {
        max-width: 300%;
    }

    .mn-wd-lg-300p {
        min-width: 300%;
    }

    .wd-lg-300-f {
        width: 300px !important;
    }

    .wd-lg-300p-f {
        width: 300% !important;
    }

    .mx-wd-lg-300p-f {
        max-width: 300% !important;
    }

    .mn-wd-lg-300p-f {
        min-width: 300% !important;
    }

    .wd-lg-350 {
        width: 350px;
    }

    .wd-lg-350p {
        width: 350%;
    }

    .mx-wd-lg-350p {
        max-width: 350%;
    }

    .mn-wd-lg-350p {
        min-width: 350%;
    }

    .wd-lg-350-f {
        width: 350px !important;
    }

    .wd-lg-350p-f {
        width: 350% !important;
    }

    .mx-wd-lg-350p-f {
        max-width: 350% !important;
    }

    .mn-wd-lg-350p-f {
        min-width: 350% !important;
    }

    .wd-lg-400 {
        width: 400px;
    }

    .wd-lg-400p {
        width: 400%;
    }

    .mx-wd-lg-400p {
        max-width: 400%;
    }

    .mn-wd-lg-400p {
        min-width: 400%;
    }

    .wd-lg-400-f {
        width: 400px !important;
    }

    .wd-lg-400p-f {
        width: 400% !important;
    }

    .mx-wd-lg-400p-f {
        max-width: 400% !important;
    }

    .mn-wd-lg-400p-f {
        min-width: 400% !important;
    }

    .wd-lg-450 {
        width: 450px;
    }

    .wd-lg-450p {
        width: 450%;
    }

    .mx-wd-lg-450p {
        max-width: 450%;
    }

    .mn-wd-lg-450p {
        min-width: 450%;
    }

    .wd-lg-450-f {
        width: 450px !important;
    }

    .wd-lg-450p-f {
        width: 450% !important;
    }

    .mx-wd-lg-450p-f {
        max-width: 450% !important;
    }

    .mn-wd-lg-450p-f {
        min-width: 450% !important;
    }

    .wd-lg-500 {
        width: 500px;
    }

    .wd-lg-500p {
        width: 500%;
    }

    .mx-wd-lg-500p {
        max-width: 500%;
    }

    .mn-wd-lg-500p {
        min-width: 500%;
    }

    .wd-lg-500-f {
        width: 500px !important;
    }

    .wd-lg-500p-f {
        width: 500% !important;
    }

    .mx-wd-lg-500p-f {
        max-width: 500% !important;
    }

    .mn-wd-lg-500p-f {
        min-width: 500% !important;
    }

    .wd-lg-550 {
        width: 550px;
    }

    .wd-lg-550p {
        width: 550%;
    }

    .mx-wd-lg-550p {
        max-width: 550%;
    }

    .mn-wd-lg-550p {
        min-width: 550%;
    }

    .wd-lg-550-f {
        width: 550px !important;
    }

    .wd-lg-550p-f {
        width: 550% !important;
    }

    .mx-wd-lg-550p-f {
        max-width: 550% !important;
    }

    .mn-wd-lg-550p-f {
        min-width: 550% !important;
    }

    .wd-lg-600 {
        width: 600px;
    }

    .wd-lg-600p {
        width: 600%;
    }

    .mx-wd-lg-600p {
        max-width: 600%;
    }

    .mn-wd-lg-600p {
        min-width: 600%;
    }

    .wd-lg-600-f {
        width: 600px !important;
    }

    .wd-lg-600p-f {
        width: 600% !important;
    }

    .mx-wd-lg-600p-f {
        max-width: 600% !important;
    }

    .mn-wd-lg-600p-f {
        min-width: 600% !important;
    }

    .wd-lg-650 {
        width: 650px;
    }

    .wd-lg-650p {
        width: 650%;
    }

    .mx-wd-lg-650p {
        max-width: 650%;
    }

    .mn-wd-lg-650p {
        min-width: 650%;
    }

    .wd-lg-650-f {
        width: 650px !important;
    }

    .wd-lg-650p-f {
        width: 650% !important;
    }

    .mx-wd-lg-650p-f {
        max-width: 650% !important;
    }

    .mn-wd-lg-650p-f {
        min-width: 650% !important;
    }

    .wd-lg-700 {
        width: 700px;
    }

    .wd-lg-700p {
        width: 700%;
    }

    .mx-wd-lg-700p {
        max-width: 700%;
    }

    .mn-wd-lg-700p {
        min-width: 700%;
    }

    .wd-lg-700-f {
        width: 700px !important;
    }

    .wd-lg-700p-f {
        width: 700% !important;
    }

    .mx-wd-lg-700p-f {
        max-width: 700% !important;
    }

    .mn-wd-lg-700p-f {
        min-width: 700% !important;
    }

    .wd-lg-750 {
        width: 750px;
    }

    .wd-lg-750p {
        width: 750%;
    }

    .mx-wd-lg-750p {
        max-width: 750%;
    }

    .mn-wd-lg-750p {
        min-width: 750%;
    }

    .wd-lg-750-f {
        width: 750px !important;
    }

    .wd-lg-750p-f {
        width: 750% !important;
    }

    .mx-wd-lg-750p-f {
        max-width: 750% !important;
    }

    .mn-wd-lg-750p-f {
        min-width: 750% !important;
    }

    .wd-lg-800 {
        width: 800px;
    }

    .wd-lg-800p {
        width: 800%;
    }

    .mx-wd-lg-800p {
        max-width: 800%;
    }

    .mn-wd-lg-800p {
        min-width: 800%;
    }

    .wd-lg-800-f {
        width: 800px !important;
    }

    .wd-lg-800p-f {
        width: 800% !important;
    }

    .mx-wd-lg-800p-f {
        max-width: 800% !important;
    }

    .mn-wd-lg-800p-f {
        min-width: 800% !important;
    }

    .wd-lg-850 {
        width: 850px;
    }

    .wd-lg-850p {
        width: 850%;
    }

    .mx-wd-lg-850p {
        max-width: 850%;
    }

    .mn-wd-lg-850p {
        min-width: 850%;
    }

    .wd-lg-850-f {
        width: 850px !important;
    }

    .wd-lg-850p-f {
        width: 850% !important;
    }

    .mx-wd-lg-850p-f {
        max-width: 850% !important;
    }

    .mn-wd-lg-850p-f {
        min-width: 850% !important;
    }

    .wd-lg-900 {
        width: 900px;
    }

    .wd-lg-900p {
        width: 900%;
    }

    .mx-wd-lg-900p {
        max-width: 900%;
    }

    .mn-wd-lg-900p {
        min-width: 900%;
    }

    .wd-lg-900-f {
        width: 900px !important;
    }

    .wd-lg-900p-f {
        width: 900% !important;
    }

    .mx-wd-lg-900p-f {
        max-width: 900% !important;
    }

    .mn-wd-lg-900p-f {
        min-width: 900% !important;
    }

    .wd-lg-950 {
        width: 950px;
    }

    .wd-lg-950p {
        width: 950%;
    }

    .mx-wd-lg-950p {
        max-width: 950%;
    }

    .mn-wd-lg-950p {
        min-width: 950%;
    }

    .wd-lg-950-f {
        width: 950px !important;
    }

    .wd-lg-950p-f {
        width: 950% !important;
    }

    .mx-wd-lg-950p-f {
        max-width: 950% !important;
    }

    .mn-wd-lg-950p-f {
        min-width: 950% !important;
    }

    .wd-lg-1000 {
        width: 1000px;
    }

    .wd-lg-1000p {
        width: 1000%;
    }

    .mx-wd-lg-1000p {
        max-width: 1000%;
    }

    .mn-wd-lg-1000p {
        min-width: 1000%;
    }

    .wd-lg-1000-f {
        width: 1000px !important;
    }

    .wd-lg-1000p-f {
        width: 1000% !important;
    }

    .mx-wd-lg-1000p-f {
        max-width: 1000% !important;
    }

    .mn-wd-lg-1000p-f {
        min-width: 1000% !important;
    }

    .wd-lg-auto {
        width: auto;
    }

    .wd-lg-auto-f {
        width: auto !important;
    }
}

@media (min-width: 1200px) {
    .wd-xl-5 {
        width: 5px;
    }

    .wd-xl-5p {
        width: 5%;
    }

    .mx-wd-xl-5p {
        max-width: 5%;
    }

    .mn-wd-xl-5p {
        min-width: 5%;
    }

    .wd-xl-5-f {
        width: 5px !important;
    }

    .wd-xl-5p-f {
        width: 5% !important;
    }

    .mx-wd-xl-5p-f {
        max-width: 5% !important;
    }

    .mn-wd-xl-5p-f {
        min-width: 5% !important;
    }

    .wd-xl-10 {
        width: 10px;
    }

    .wd-xl-10p {
        width: 10%;
    }

    .mx-wd-xl-10p {
        max-width: 10%;
    }

    .mn-wd-xl-10p {
        min-width: 10%;
    }

    .wd-xl-10-f {
        width: 10px !important;
    }

    .wd-xl-10p-f {
        width: 10% !important;
    }

    .mx-wd-xl-10p-f {
        max-width: 10% !important;
    }

    .mn-wd-xl-10p-f {
        min-width: 10% !important;
    }

    .wd-xl-15 {
        width: 15px;
    }

    .wd-xl-15p {
        width: 15%;
    }

    .mx-wd-xl-15p {
        max-width: 15%;
    }

    .mn-wd-xl-15p {
        min-width: 15%;
    }

    .wd-xl-15-f {
        width: 15px !important;
    }

    .wd-xl-15p-f {
        width: 15% !important;
    }

    .mx-wd-xl-15p-f {
        max-width: 15% !important;
    }

    .mn-wd-xl-15p-f {
        min-width: 15% !important;
    }

    .wd-xl-20 {
        width: 20px;
    }

    .wd-xl-20p {
        width: 20%;
    }

    .mx-wd-xl-20p {
        max-width: 20%;
    }

    .mn-wd-xl-20p {
        min-width: 20%;
    }

    .wd-xl-20-f {
        width: 20px !important;
    }

    .wd-xl-20p-f {
        width: 20% !important;
    }

    .mx-wd-xl-20p-f {
        max-width: 20% !important;
    }

    .mn-wd-xl-20p-f {
        min-width: 20% !important;
    }

    .wd-xl-25 {
        width: 25px;
    }

    .wd-xl-25p {
        width: 25%;
    }

    .mx-wd-xl-25p {
        max-width: 25%;
    }

    .mn-wd-xl-25p {
        min-width: 25%;
    }

    .wd-xl-25-f {
        width: 25px !important;
    }

    .wd-xl-25p-f {
        width: 25% !important;
    }

    .mx-wd-xl-25p-f {
        max-width: 25% !important;
    }

    .mn-wd-xl-25p-f {
        min-width: 25% !important;
    }

    .wd-xl-30 {
        width: 30px;
    }

    .wd-xl-30p {
        width: 30%;
    }

    .mx-wd-xl-30p {
        max-width: 30%;
    }

    .mn-wd-xl-30p {
        min-width: 30%;
    }

    .wd-xl-30-f {
        width: 30px !important;
    }

    .wd-xl-30p-f {
        width: 30% !important;
    }

    .mx-wd-xl-30p-f {
        max-width: 30% !important;
    }

    .mn-wd-xl-30p-f {
        min-width: 30% !important;
    }

    .wd-xl-35 {
        width: 35px;
    }

    .wd-xl-35p {
        width: 35%;
    }

    .mx-wd-xl-35p {
        max-width: 35%;
    }

    .mn-wd-xl-35p {
        min-width: 35%;
    }

    .wd-xl-35-f {
        width: 35px !important;
    }

    .wd-xl-35p-f {
        width: 35% !important;
    }

    .mx-wd-xl-35p-f {
        max-width: 35% !important;
    }

    .mn-wd-xl-35p-f {
        min-width: 35% !important;
    }

    .wd-xl-40 {
        width: 40px;
    }

    .wd-xl-40p {
        width: 40%;
    }

    .mx-wd-xl-40p {
        max-width: 40%;
    }

    .mn-wd-xl-40p {
        min-width: 40%;
    }

    .wd-xl-40-f {
        width: 40px !important;
    }

    .wd-xl-40p-f {
        width: 40% !important;
    }

    .mx-wd-xl-40p-f {
        max-width: 40% !important;
    }

    .mn-wd-xl-40p-f {
        min-width: 40% !important;
    }

    .wd-xl-45 {
        width: 45px;
    }

    .wd-xl-45p {
        width: 45%;
    }

    .mx-wd-xl-45p {
        max-width: 45%;
    }

    .mn-wd-xl-45p {
        min-width: 45%;
    }

    .wd-xl-45-f {
        width: 45px !important;
    }

    .wd-xl-45p-f {
        width: 45% !important;
    }

    .mx-wd-xl-45p-f {
        max-width: 45% !important;
    }

    .mn-wd-xl-45p-f {
        min-width: 45% !important;
    }

    .wd-xl-50 {
        width: 50px;
    }

    .wd-xl-50p {
        width: 50%;
    }

    .mx-wd-xl-50p {
        max-width: 50%;
    }

    .mn-wd-xl-50p {
        min-width: 50%;
    }

    .wd-xl-50-f {
        width: 50px !important;
    }

    .wd-xl-50p-f {
        width: 50% !important;
    }

    .mx-wd-xl-50p-f {
        max-width: 50% !important;
    }

    .mn-wd-xl-50p-f {
        min-width: 50% !important;
    }

    .wd-xl-55 {
        width: 55px;
    }

    .wd-xl-55p {
        width: 55%;
    }

    .mx-wd-xl-55p {
        max-width: 55%;
    }

    .mn-wd-xl-55p {
        min-width: 55%;
    }

    .wd-xl-55-f {
        width: 55px !important;
    }

    .wd-xl-55p-f {
        width: 55% !important;
    }

    .mx-wd-xl-55p-f {
        max-width: 55% !important;
    }

    .mn-wd-xl-55p-f {
        min-width: 55% !important;
    }

    .wd-xl-60 {
        width: 60px;
    }

    .wd-xl-60p {
        width: 60%;
    }

    .mx-wd-xl-60p {
        max-width: 60%;
    }

    .mn-wd-xl-60p {
        min-width: 60%;
    }

    .wd-xl-60-f {
        width: 60px !important;
    }

    .wd-xl-60p-f {
        width: 60% !important;
    }

    .mx-wd-xl-60p-f {
        max-width: 60% !important;
    }

    .mn-wd-xl-60p-f {
        min-width: 60% !important;
    }

    .wd-xl-65 {
        width: 65px;
    }

    .wd-xl-65p {
        width: 65%;
    }

    .mx-wd-xl-65p {
        max-width: 65%;
    }

    .mn-wd-xl-65p {
        min-width: 65%;
    }

    .wd-xl-65-f {
        width: 65px !important;
    }

    .wd-xl-65p-f {
        width: 65% !important;
    }

    .mx-wd-xl-65p-f {
        max-width: 65% !important;
    }

    .mn-wd-xl-65p-f {
        min-width: 65% !important;
    }

    .wd-xl-70 {
        width: 70px;
    }

    .wd-xl-70p {
        width: 70%;
    }

    .mx-wd-xl-70p {
        max-width: 70%;
    }

    .mn-wd-xl-70p {
        min-width: 70%;
    }

    .wd-xl-70-f {
        width: 70px !important;
    }

    .wd-xl-70p-f {
        width: 70% !important;
    }

    .mx-wd-xl-70p-f {
        max-width: 70% !important;
    }

    .mn-wd-xl-70p-f {
        min-width: 70% !important;
    }

    .wd-xl-75 {
        width: 75px;
    }

    .wd-xl-75p {
        width: 75%;
    }

    .mx-wd-xl-75p {
        max-width: 75%;
    }

    .mn-wd-xl-75p {
        min-width: 75%;
    }

    .wd-xl-75-f {
        width: 75px !important;
    }

    .wd-xl-75p-f {
        width: 75% !important;
    }

    .mx-wd-xl-75p-f {
        max-width: 75% !important;
    }

    .mn-wd-xl-75p-f {
        min-width: 75% !important;
    }

    .wd-xl-80 {
        width: 80px;
    }

    .wd-xl-80p {
        width: 80%;
    }

    .mx-wd-xl-80p {
        max-width: 80%;
    }

    .mn-wd-xl-80p {
        min-width: 80%;
    }

    .wd-xl-80-f {
        width: 80px !important;
    }

    .wd-xl-80p-f {
        width: 80% !important;
    }

    .mx-wd-xl-80p-f {
        max-width: 80% !important;
    }

    .mn-wd-xl-80p-f {
        min-width: 80% !important;
    }

    .wd-xl-85 {
        width: 85px;
    }

    .wd-xl-85p {
        width: 85%;
    }

    .mx-wd-xl-85p {
        max-width: 85%;
    }

    .mn-wd-xl-85p {
        min-width: 85%;
    }

    .wd-xl-85-f {
        width: 85px !important;
    }

    .wd-xl-85p-f {
        width: 85% !important;
    }

    .mx-wd-xl-85p-f {
        max-width: 85% !important;
    }

    .mn-wd-xl-85p-f {
        min-width: 85% !important;
    }

    .wd-xl-90 {
        width: 90px;
    }

    .wd-xl-90p {
        width: 90%;
    }

    .mx-wd-xl-90p {
        max-width: 90%;
    }

    .mn-wd-xl-90p {
        min-width: 90%;
    }

    .wd-xl-90-f {
        width: 90px !important;
    }

    .wd-xl-90p-f {
        width: 90% !important;
    }

    .mx-wd-xl-90p-f {
        max-width: 90% !important;
    }

    .mn-wd-xl-90p-f {
        min-width: 90% !important;
    }

    .wd-xl-95 {
        width: 95px;
    }

    .wd-xl-95p {
        width: 95%;
    }

    .mx-wd-xl-95p {
        max-width: 95%;
    }

    .mn-wd-xl-95p {
        min-width: 95%;
    }

    .wd-xl-95-f {
        width: 95px !important;
    }

    .wd-xl-95p-f {
        width: 95% !important;
    }

    .mx-wd-xl-95p-f {
        max-width: 95% !important;
    }

    .mn-wd-xl-95p-f {
        min-width: 95% !important;
    }

    .wd-xl-100 {
        width: 100px;
    }

    .wd-xl-100p {
        width: 100%;
    }

    .mx-wd-xl-100p {
        max-width: 100%;
    }

    .mn-wd-xl-100p {
        min-width: 100%;
    }

    .wd-xl-100-f {
        width: 100px !important;
    }

    .wd-xl-100p-f {
        width: 100% !important;
    }

    .mx-wd-xl-100p-f {
        max-width: 100% !important;
    }

    .mn-wd-xl-100p-f {
        min-width: 100% !important;
    }

    .wd-xl-150 {
        width: 150px;
    }

    .wd-xl-150p {
        width: 150%;
    }

    .mx-wd-xl-150p {
        max-width: 150%;
    }

    .mn-wd-xl-150p {
        min-width: 150%;
    }

    .wd-xl-150-f {
        width: 150px !important;
    }

    .wd-xl-150p-f {
        width: 150% !important;
    }

    .mx-wd-xl-150p-f {
        max-width: 150% !important;
    }

    .mn-wd-xl-150p-f {
        min-width: 150% !important;
    }

    .wd-xl-200 {
        width: 200px;
    }

    .wd-xl-200p {
        width: 200%;
    }

    .mx-wd-xl-200p {
        max-width: 200%;
    }

    .mn-wd-xl-200p {
        min-width: 200%;
    }

    .wd-xl-200-f {
        width: 200px !important;
    }

    .wd-xl-200p-f {
        width: 200% !important;
    }

    .mx-wd-xl-200p-f {
        max-width: 200% !important;
    }

    .mn-wd-xl-200p-f {
        min-width: 200% !important;
    }

    .wd-xl-250 {
        width: 250px;
    }

    .wd-xl-250p {
        width: 250%;
    }

    .mx-wd-xl-250p {
        max-width: 250%;
    }

    .mn-wd-xl-250p {
        min-width: 250%;
    }

    .wd-xl-250-f {
        width: 250px !important;
    }

    .wd-xl-250p-f {
        width: 250% !important;
    }

    .mx-wd-xl-250p-f {
        max-width: 250% !important;
    }

    .mn-wd-xl-250p-f {
        min-width: 250% !important;
    }

    .wd-xl-300 {
        width: 300px;
    }

    .wd-xl-300p {
        width: 300%;
    }

    .mx-wd-xl-300p {
        max-width: 300%;
    }

    .mn-wd-xl-300p {
        min-width: 300%;
    }

    .wd-xl-300-f {
        width: 300px !important;
    }

    .wd-xl-300p-f {
        width: 300% !important;
    }

    .mx-wd-xl-300p-f {
        max-width: 300% !important;
    }

    .mn-wd-xl-300p-f {
        min-width: 300% !important;
    }

    .wd-xl-350 {
        width: 350px;
    }

    .wd-xl-350p {
        width: 350%;
    }

    .mx-wd-xl-350p {
        max-width: 350%;
    }

    .mn-wd-xl-350p {
        min-width: 350%;
    }

    .wd-xl-350-f {
        width: 350px !important;
    }

    .wd-xl-350p-f {
        width: 350% !important;
    }

    .mx-wd-xl-350p-f {
        max-width: 350% !important;
    }

    .mn-wd-xl-350p-f {
        min-width: 350% !important;
    }

    .wd-xl-400 {
        width: 400px;
    }

    .wd-xl-400p {
        width: 400%;
    }

    .mx-wd-xl-400p {
        max-width: 400%;
    }

    .mn-wd-xl-400p {
        min-width: 400%;
    }

    .wd-xl-400-f {
        width: 400px !important;
    }

    .wd-xl-400p-f {
        width: 400% !important;
    }

    .mx-wd-xl-400p-f {
        max-width: 400% !important;
    }

    .mn-wd-xl-400p-f {
        min-width: 400% !important;
    }

    .wd-xl-450 {
        width: 450px;
    }

    .wd-xl-450p {
        width: 450%;
    }

    .mx-wd-xl-450p {
        max-width: 450%;
    }

    .mn-wd-xl-450p {
        min-width: 450%;
    }

    .wd-xl-450-f {
        width: 450px !important;
    }

    .wd-xl-450p-f {
        width: 450% !important;
    }

    .mx-wd-xl-450p-f {
        max-width: 450% !important;
    }

    .mn-wd-xl-450p-f {
        min-width: 450% !important;
    }

    .wd-xl-500 {
        width: 500px;
    }

    .wd-xl-500p {
        width: 500%;
    }

    .mx-wd-xl-500p {
        max-width: 500%;
    }

    .mn-wd-xl-500p {
        min-width: 500%;
    }

    .wd-xl-500-f {
        width: 500px !important;
    }

    .wd-xl-500p-f {
        width: 500% !important;
    }

    .mx-wd-xl-500p-f {
        max-width: 500% !important;
    }

    .mn-wd-xl-500p-f {
        min-width: 500% !important;
    }

    .wd-xl-550 {
        width: 550px;
    }

    .wd-xl-550p {
        width: 550%;
    }

    .mx-wd-xl-550p {
        max-width: 550%;
    }

    .mn-wd-xl-550p {
        min-width: 550%;
    }

    .wd-xl-550-f {
        width: 550px !important;
    }

    .wd-xl-550p-f {
        width: 550% !important;
    }

    .mx-wd-xl-550p-f {
        max-width: 550% !important;
    }

    .mn-wd-xl-550p-f {
        min-width: 550% !important;
    }

    .wd-xl-600 {
        width: 600px;
    }

    .wd-xl-600p {
        width: 600%;
    }

    .mx-wd-xl-600p {
        max-width: 600%;
    }

    .mn-wd-xl-600p {
        min-width: 600%;
    }

    .wd-xl-600-f {
        width: 600px !important;
    }

    .wd-xl-600p-f {
        width: 600% !important;
    }

    .mx-wd-xl-600p-f {
        max-width: 600% !important;
    }

    .mn-wd-xl-600p-f {
        min-width: 600% !important;
    }

    .wd-xl-650 {
        width: 650px;
    }

    .wd-xl-650p {
        width: 650%;
    }

    .mx-wd-xl-650p {
        max-width: 650%;
    }

    .mn-wd-xl-650p {
        min-width: 650%;
    }

    .wd-xl-650-f {
        width: 650px !important;
    }

    .wd-xl-650p-f {
        width: 650% !important;
    }

    .mx-wd-xl-650p-f {
        max-width: 650% !important;
    }

    .mn-wd-xl-650p-f {
        min-width: 650% !important;
    }

    .wd-xl-700 {
        width: 700px;
    }

    .wd-xl-700p {
        width: 700%;
    }

    .mx-wd-xl-700p {
        max-width: 700%;
    }

    .mn-wd-xl-700p {
        min-width: 700%;
    }

    .wd-xl-700-f {
        width: 700px !important;
    }

    .wd-xl-700p-f {
        width: 700% !important;
    }

    .mx-wd-xl-700p-f {
        max-width: 700% !important;
    }

    .mn-wd-xl-700p-f {
        min-width: 700% !important;
    }

    .wd-xl-750 {
        width: 750px;
    }

    .wd-xl-750p {
        width: 750%;
    }

    .mx-wd-xl-750p {
        max-width: 750%;
    }

    .mn-wd-xl-750p {
        min-width: 750%;
    }

    .wd-xl-750-f {
        width: 750px !important;
    }

    .wd-xl-750p-f {
        width: 750% !important;
    }

    .mx-wd-xl-750p-f {
        max-width: 750% !important;
    }

    .mn-wd-xl-750p-f {
        min-width: 750% !important;
    }

    .wd-xl-800 {
        width: 800px;
    }

    .wd-xl-800p {
        width: 800%;
    }

    .mx-wd-xl-800p {
        max-width: 800%;
    }

    .mn-wd-xl-800p {
        min-width: 800%;
    }

    .wd-xl-800-f {
        width: 800px !important;
    }

    .wd-xl-800p-f {
        width: 800% !important;
    }

    .mx-wd-xl-800p-f {
        max-width: 800% !important;
    }

    .mn-wd-xl-800p-f {
        min-width: 800% !important;
    }

    .wd-xl-850 {
        width: 850px;
    }

    .wd-xl-850p {
        width: 850%;
    }

    .mx-wd-xl-850p {
        max-width: 850%;
    }

    .mn-wd-xl-850p {
        min-width: 850%;
    }

    .wd-xl-850-f {
        width: 850px !important;
    }

    .wd-xl-850p-f {
        width: 850% !important;
    }

    .mx-wd-xl-850p-f {
        max-width: 850% !important;
    }

    .mn-wd-xl-850p-f {
        min-width: 850% !important;
    }

    .wd-xl-900 {
        width: 900px;
    }

    .wd-xl-900p {
        width: 900%;
    }

    .mx-wd-xl-900p {
        max-width: 900%;
    }

    .mn-wd-xl-900p {
        min-width: 900%;
    }

    .wd-xl-900-f {
        width: 900px !important;
    }

    .wd-xl-900p-f {
        width: 900% !important;
    }

    .mx-wd-xl-900p-f {
        max-width: 900% !important;
    }

    .mn-wd-xl-900p-f {
        min-width: 900% !important;
    }

    .wd-xl-950 {
        width: 950px;
    }

    .wd-xl-950p {
        width: 950%;
    }

    .mx-wd-xl-950p {
        max-width: 950%;
    }

    .mn-wd-xl-950p {
        min-width: 950%;
    }

    .wd-xl-950-f {
        width: 950px !important;
    }

    .wd-xl-950p-f {
        width: 950% !important;
    }

    .mx-wd-xl-950p-f {
        max-width: 950% !important;
    }

    .mn-wd-xl-950p-f {
        min-width: 950% !important;
    }

    .wd-xl-1000 {
        width: 1000px;
    }

    .wd-xl-1000p {
        width: 1000%;
    }

    .mx-wd-xl-1000p {
        max-width: 1000%;
    }

    .mn-wd-xl-1000p {
        min-width: 1000%;
    }

    .wd-xl-1000-f {
        width: 1000px !important;
    }

    .wd-xl-1000p-f {
        width: 1000% !important;
    }

    .mx-wd-xl-1000p-f {
        max-width: 1000% !important;
    }

    .mn-wd-xl-1000p-f {
        min-width: 1000% !important;
    }

    .wd-xl-auto {
        width: auto;
        width: auto !important;
    }
}

.wd-100v {
    width: 100vw;
}

.btn-w-xs {
    min-width: 90px;
}

.btn-w-sm {
    min-width: 110px;
}

.btn-w-md {
    min-width: 130px;
}

.btn-w-lg {
    min-width: 150px;
}
/*# sourceMappingURL=style.css.map */

.row-disable {
  opacity: 0.5;
}

.custom-table [class^='datatable-icon-']::before,
.custom-table [class*=' datatable-icon-']::before {
  font-family: 'Material Symbols Outlined' !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  font-size: 14px !important;
  padding-left: 6px !important;
  display: inline-flex;
  align-items: center;
}

.custom-table .datatable-icon-filter::before {
  content: 'filter_list';
}

.custom-table .datatable-icon-collapse::before {
  content: 'keyboard_arrow_up';
}

.custom-table .datatable-icon-expand::before {
  content: 'keyboard_arrow_down';
}

.custom-table .datatable-icon-close::before {
  content: 'close';
}

.custom-table .datatable-icon-up::before {
  content: 'keyboard_arrow_up';
}

.custom-table .datatable-icon-down::before {
  content: 'keyboard_arrow_down';
}

.custom-table .datatable-icon-sort-unset::before {
  content: 'expand_all';
}

.custom-table .datatable-icon-sort::before {
  content: 'swap_vert';
}

.custom-table .datatable-icon-done::before {
  content: 'done';
}

.custom-table .datatable-icon-done-all::before {
  content: 'done_all';
}

.custom-table .datatable-icon-search::before {
  content: 'search';
}

.custom-table .datatable-icon-pin::before {
  content: 'push_pin';
}

.custom-table .datatable-icon-add::before {
  content: 'add';
}

.custom-table .datatable-icon-left::before {
  content: 'chevron_left';
}

.custom-table .datatable-icon-right::before {
  content: 'chevron_right';
}

.custom-table .datatable-icon-skip::before {
  content: 'last_page';
}

.custom-table .datatable-icon-prev::before {
  content: 'first_page';
}

.custom-table .resize-handle {
  border-right: solid 2px #3C8FB2;
  opacity: 0.5;
  transition: opacity 0.5s ease, border-color 0.5s ease;
}

.custom-table .resize-handle:hover {
  opacity: 1;
  border-right-color: #1E6F91;
}
