  /* Checkbox */
  .ckbox {
    font-weight: normal;
    position: relative;
    display: block;
    line-height: 1;
    margin-bottom: 0;
    width: fit-content;

    span {
      cursor: pointer;
    }

    > span {
      padding-left: 15px;
      height      : 16px;

      &:empty {
        float: left;
      }

      &:before, &:after {
        line-height: 18px;
        position: absolute;
      }


      &:before {
        content: '';
        width: 16px;
        height: 16px;
        background-color: $neutral-30;
        border: 1px solid $neutral-60;
        top: 2px;
        left: 0;
        border-radius:3px;
      }

      &:after {
        top: 2px;
        left: 0;
        width: 16px;
        height: 16px;
        content: 'check';
        font-family: 'Material Symbols Outlined';
        color: $white;
        font-size: 16px;
        line-height: 16px;
        display: none;
        border-radius:3px;
      }

      &:empty {
        padding-left: 0;
        width: 3px;
      }
    }

    input[type='checkbox'] {
      opacity: 0;
      margin: 0;

      &[disabled] + span:before {
        background-color: $primary-10;
      }

      &:checked {
        &+ span {
          &:before {
            background-color: $secondary;
            border-color    : $secondary;
          }
          &:after {
            display: block;
          }
        }

        &[disabled] + span {
          &:before {
            background-color: $secondary-25;
            border-color    : $secondary-25;
          }
          &:after {
            color: $secondary;
          }
        }
      }
    }
  }



  .ckbox-inline {
    display: inline-block;
  }